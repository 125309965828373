import { Row, Col, Container } from 'react-grid-system';
import styled from 'styled-components';
import { AiFillFilePdf } from 'react-icons/ai';
import { MdModeEdit, MdDelete } from 'react-icons/md';
import { SiMicrosoftexcel } from 'react-icons/si';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { FaCalendarAlt, FaCartPlus } from 'react-icons/fa';

dayjs.extend(localizedFormat);

const CardViewWrapper = styled.div`
    margin-top: 30px;

    .card-content {
        border: 3px solid #ff740f;
        border-radius: 5px;
        background: #fff;
        margin-bottom: 15px;
        .title {
            text-align: center;
            padding: 0.625rem 0;
            font-weight: 800;
        }

        .main-body {
            padding: 0 2rem;
            color: #8b8b8b;
            font-weight: 500;
            .card-icons {
                color: #ff740f;
            }
        }

        .card-actions {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: -15px;
            margin-bottom: 10px;
            .icon-phone {
                color: #ff740f;
            }
        }
    }
`;

const CardViewList = ({ rows, handlePdfPreview, handleExcelDataExport, deleteBatcthOrderById, handleBatchEdit }) => {
    return (
        <CardViewWrapper>
            <Container>
                <Row>
                    {rows.map((row, index) => {
                        const { _id, batchDate, orderInfo, batchNo } = row;
                        return (
                            <Col xl={3} lg={4} md={6} sm={6} xs={12} key={index}>
                                <div className="card-content">
                                    <h6 className="title">Batch no: #{batchNo}</h6>
                                    <div className="main-body">
                                        <p>
                                            <FaCalendarAlt className="card-icons" /> &nbsp; {dayjs(batchDate).format('l')}
                                        </p>
                                        <p>
                                            <FaCartPlus className="card-icons" /> &nbsp; Order no:
                                            {orderInfo.map((item) => `${item.orderNo}, `)}
                                        </p>
                                    </div>
                                    <hr />
                                    <div className="card-actions">
                                        <Stack direction="row" spacing={0}>
                                            <IconButton
                                                aria-label="preview"
                                                className="icon-phone"
                                                href={`/order-batches/${_id}`}
                                                title="Preview"
                                            >
                                                <VisibilityIcon />
                                            </IconButton>
                                            <IconButton
                                                aria-label="call lead phone"
                                                onClick={() => handleBatchEdit(_id)}
                                                title="Edit batch"
                                            >
                                                <a href="#!" className="icon-phone">
                                                    <MdModeEdit />
                                                </a>
                                            </IconButton>
                                            <IconButton
                                                aria-label="call lead phone"
                                                onClick={() => handlePdfPreview(row)}
                                                title="Generate Pdf"
                                            >
                                                <a href="#!" className="icon-phone">
                                                    <AiFillFilePdf />
                                                </a>
                                            </IconButton>
                                            <IconButton
                                                aria-label="call lead phone"
                                                onClick={() => handleExcelDataExport(row.ordersDetails)}
                                                title="Generate Excel"
                                            >
                                                <a href="#!" className="icon-phone">
                                                    <SiMicrosoftexcel />
                                                </a>
                                            </IconButton>
                                            <IconButton
                                                aria-label="call lead phone"
                                                onClick={() => deleteBatcthOrderById(_id)}
                                                title="Delete batch"
                                            >
                                                <a href="#!" className="icon-phone">
                                                    <MdDelete />
                                                </a>
                                            </IconButton>
                                        </Stack>
                                    </div>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </CardViewWrapper>
    );
};
export default CardViewList;
