import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { encrypt, decrypt } from '../../utils/encrypt';
import ApiUrl from 'network';
import { Table, Divider, Paper, Space, Select, Spin, DatePicker, Modal, Input, Dropdown, Popover, Button } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LoadingOutlined, DownloadOutlined, FileAddOutlined } from '@ant-design/icons';
import Avatar from '@mui/material/Avatar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/material/styles';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useAppContext } from '../../context/appContext';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from '@mui/styles';
import RmaReceiptUpdateModal from 'components/RmaOrdersTable/RmaReceiptUpdateModal';
import RmaBulkModal from 'components/RmaOrdersTable/RmaBulkModal';
import RmaReceiptUpdateBulkModal from 'components/RmaOrdersTable/RmaReceiptUpdateBulkModal';

const CollectionPaymentStatus = () => {
    const [confirmedList, setConfirmedList] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [countries, setCountries] = useState([]);
    const [clearedRma, setClearedRma] = useState([]);
    const [accumulatedFilters, setAccumulatedFilters] = useState({});
    const [limit, setLimit] = useState(10);
    const [loading, setLoading] = useState(false);
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [isReceiptUpdateOpen, setIsReceiptUpdateOpen] = useState(false);
    const [isReceiptUpdateBulkOpen, setIsReceiptUpdateBulkOpen] = useState(false);
    const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
    const [getOrderId, setGetOrderId] = useState('');
    const [collections, setCollections] = useState([]);
    const [updateReceiptSuccess, setUpdatReceiptSuccess] = useState(false);
    const [updateReceiptBulkSuccess, setUpdatReceiptBulkSuccess] = useState(false);
    const [getOrderNo, setGetOrderNo] = useState('');
    const [rmaOrders, setRmaOrders] = useState([]);
    const country = localStorage.getItem('country');

    const useStyles = makeStyles(() => ({
        tableHeaderCell: {
            fontWeight: 'bold'
        },
        item: {
            padding: 5
        }
    }));

    // receipt Update modal
    const showReceiptUpdateModal = () => {
        setIsReceiptUpdateOpen(true);
    };

    const handleReceiptUpdateOk = () => {
        setIsReceiptUpdateOpen(false);
    };

    const handleReceiptUpdateCancel = () => {
        setIsReceiptUpdateOpen(false);
    };

    // receipt Update bulk modal
    const showReceiptUpdateBulkModal = () => {
        setIsReceiptUpdateBulkOpen(true);
    };

    const handleReceiptUpdateBulkOk = () => {
        setIsReceiptUpdateBulkOpen(false);
    };

    const handleReceiptUpdateBulkCancel = () => {
        setIsReceiptUpdateBulkOpen(false);
    };

    // bulk modal
    const showBulkeModal = () => {
        setIsBulkModalOpen(true);
    };

    const handleBulkOk = () => {
        setIsBulkModalOpen(false);
    };

    const handleBulkCancel = () => {
        setIsBulkModalOpen(false);
    };

    const limitNumber = 100;

    const limitOptions = [
        {
            label: '100',
            value: limitNumber * 1
        },
        {
            label: '200',
            value: limitNumber * 2
        },
        {
            label: '300',
            value: limitNumber * 3
        },
        {
            label: '400',
            value: limitNumber * 4
        },
        {
            label: '500',
            value: limitNumber * 5
        },
        {
            label: '600',
            value: limitNumber * 6
        },
        {
            label: '700',
            value: limitNumber * 7
        },
        {
            label: '800',
            value: limitNumber * 8
        },
        {
            label: '900',
            value: limitNumber * 9
        },
        {
            label: '1000',
            value: limitNumber * 10
        }
    ];

    // get all orders
    const getRmaOrders = async (data) => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.MyMainOrdersUrl}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            // Append accumulated filters to URL
            Object.entries(accumulatedFilters).forEach(([key, value]) => {
                if (value) {
                    urlParams.append(key, value);
                }
            });

            url = `${url}?${urlParams.toString()}`;

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        // const result = decrypt(data.data, keyResult, ivResult);
                        // const answer = JSON.parse(result);
                        setRmaOrders(data.data);
                    } else {
                        // const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // get query data
    const getQueryData = async (data) => {
        try {
            setLoading(true);
            const url = `${ApiUrl.BaseUrl}/mainorders/querydata?country=${accumulatedFilters?.country || country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setCountries(answer.country);
                        setClearedRma(answer.relationshipManagers);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const countryOptions = countries?.map((item) => {
        return { label: item?.name, value: item?.name };
    });

    const rmaOptions = clearedRma?.map((item) => {
        return { label: item?.name, value: item?._id };
    });

    // get all confirmed orders
    const getConfirmedOrders = async (data) => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/collection/rmacccollectionlist`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            // Append accumulated filters to URL
            Object.entries(accumulatedFilters).forEach(([key, value]) => {
                if (value) {
                    urlParams.append(key, value);
                }
            });

            url = `${url}?${urlParams.toString()}`;

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);

                        setConfirmedList(answer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // get all collections
    const getCollections = async () => {
        try {
            setLoading(true);
            const url = `${ApiUrl.BaseUrl}/collection/financecollection`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setCollections(answer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getConfirmedOrders();
        getQueryData();
        getRmaOrders();
        getCollections();
    }, [accumulatedFilters, updateReceiptSuccess, updateReceiptBulkSuccess]);

    // filter country
    const filterCountry = (value) => {
        const data = {
            country: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter RMA
    const filterRmaPerson = (value) => {
        const data = {
            rmcc: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    const handleLimit = (value) => {
        setLimit(value);
        const data = {
            limit: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter date
    const filterDate = (value, dateString) => {
        setSelectedDate(value);
        const data = {
            startDate: dateString[0],
            endDate: dateString[1]
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter Order Status
    const filterOrderStatus = (value) => {
        const data = {
            status: 'value'
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter by partner
    const filterPartner = (value) => {
        const data = {
            partner: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    const [columnWidth, setColumnWidth] = useState('100px');

    useEffect(() => {
        const handleResize = () => {
            // Adjust the column width based on the window width
            const newWidth = window.innerWidth > 1200 ? '200px' : '100px';
            setColumnWidth(newWidth);
        };

        // Initial setup
        handleResize();

        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54
            }}
            spin
        />
    );

    const { RangePicker } = DatePicker;

    const navigate = useNavigate();

    const theme = useTheme();
    const classes = useStyles();

    const dateConverter = (value) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };

        return new Date(value).toLocaleDateString('en-US', options);
    };

    const userRole = localStorage.getItem('userRole');

    // 2 decimal places
    const twoDecimalPlaces = (value) => {
        return parseFloat(value?.toFixed(2)).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    };

    const columns = [
        {
            title: '#',
            dataIndex: '#',
            key: 'id',

            render: (id, record, index) => {
                return index + 1;
            },
            width: '30px'
        },

        {
            title: 'Order Date',
            dataIndex: 'orderDate',
            key: 'orderDate',
            width: columnWidth
        },
        {
            title: 'Order No',
            dataIndex: 'orderNo',
            key: 'orderNo',
            width: '200px'
        },
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'customerName',
            width: columnWidth
        },
        {
            title: 'Sales Value',
            dataIndex: 'salesvalue',
            key: 'salesvalue',
            width: '200px'
        },
        {
            title: 'Cash Collected',
            dataIndex: 'cashcollected',
            key: 'cashcollected',
            width: '100px'
        },

        {
            title: 'Payment Status',
            dataIndex: 'paymentstatus',
            key: 'paymentstatus',
            width: '170px'
        },

        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: columnWidth
        }
    ];

    const oderDetails = confirmedList?.map((result) => ({
        key: result?._id,
        orderDate: dateConverter(result?.collectionDate),
        orderNo:
            result?.ordersBulk.length > 0
                ? result?.ordersBulk.map((orders) => (
                      <Box sx={{ paddingTop: '0px', margin: '0 0 5px 0' }} key={orders._id}>
                          <Typography sx={{ fontSize: '1rem', color: '#233E4C', fontWeight: 400 }}>{orders.orderNo}</Typography>
                      </Box>
                  ))
                : result?.orderNo,
        customerName: result?.country === 'Ghana' ? result?.orderIdGh?.channelPartnerId : result?.orderIdNg?.channelPartnerId,

        cashcollected: '',
        paymentstatus: result?.status === 'Approved' ? 'Confirmed' : result?.status,
        salesvalue:
            result?.ordersBulk.length > 0
                ? result?.ordersBulk.map((orders) => (
                      <Box sx={{ paddingTop: '0px', margin: '0 0 5px 0' }} key={orders._id}>
                          <Typography sx={{ fontSize: '1rem', color: '#233E4C', fontWeight: 400 }}>
                              {twoDecimalPlaces(orders?.orderSales)}
                          </Typography>
                      </Box>
                  ))
                : result?.country === 'Ghana'
                ? twoDecimalPlaces(result?.orderIdGh?.expCashCollected)
                : twoDecimalPlaces(result?.orderIdNg?.expCashCollected),

        action: (
            <>
                {' '}
                {result.status === 'Approved' ? (
                    <Typography
                        sx={{
                            borderBottom: '1px solid #FEF3E7',
                            height: '40px',
                            width: '100%',
                            padding: '10px 12px',
                            borderRadius: '8px',
                            color: '#FFBA87',
                            fontWeight: '500',
                            textAlign: 'center',
                            cursor: 'disabled',
                            fontSize: '14px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '10px 0'
                        }}
                    >
                        Update payment <KeyboardArrowRightIcon sx={{ width: '16px' }} />
                    </Typography>
                ) : result.status !== 'Approved' && result?.collectionType === 'Bulk' ? (
                    <Typography
                        onClick={() => {
                            setGetOrderId(result?._id);
                            setGetOrderNo(result?.ordersBulk);
                            showReceiptUpdateBulkModal();
                        }}
                        sx={{
                            borderBottom: ' 1px solid #FEF3E7',
                            height: '40px',
                            width: '100%',
                            padding: '10px 12px',
                            borderRadius: '8px',
                            color: '#FF740F',
                            fontWeight: '500',
                            textAlign: 'center',
                            cursor: 'pointer',
                            fontSize: '14px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '10px 0'
                        }}
                    >
                        Update payment <KeyboardArrowRightIcon sx={{ width: '16px' }} />
                    </Typography>
                ) : result.status !== 'Approved' && result?.ordersBulk.length === 0 ? (
                    <Typography
                        onClick={() => {
                            setGetOrderId(result?.country === 'Ghana' ? result?.orderIdGh?._id : result?.orderIdNg?._id);
                            setGetOrderNo(result?.orderNo);
                            showReceiptUpdateModal();
                        }}
                        sx={{
                            borderBottom: ' 1px solid #FEF3E7',
                            height: '40px',
                            width: '100%',
                            padding: '10px 12px',
                            borderRadius: '8px',
                            color: '#FF740F',
                            fontWeight: '500',
                            textAlign: 'center',
                            cursor: 'pointer',
                            fontSize: '14px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '10px 0'
                        }}
                    >
                        Update payment <KeyboardArrowRightIcon sx={{ width: '16px' }} />
                    </Typography>
                ) : null}
            </>
        )
    }));

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    padding: '0 0 16px 0',
                    borderBottom: '1px dashed #E7EAEB',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    margin: '32px auto 24px'
                }}
            >
                <Typography sx={{ fontWeight: 600, fontSize: '1.875rem', color: '#0A2938', padding: 0 }}>Payment status</Typography>
            </Box>
            <div>
                {' '}
                <Table
                    loading={loading ? { indicator: <Spin indicator={antIcon} />, spinning: true / false } : ''}
                    columns={columns}
                    dataSource={oderDetails}
                    pagination={{
                        pageSize: limit,
                        hideOnSinglePage: true
                    }}
                    size="middle"
                    scroll={{
                        x: 'calc(700px + 50%)',
                        y: 580
                    }}
                    title={() => (
                        <>
                            <Space
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    width: '100%'
                                }}
                            >
                                <Box
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        width: '100%'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: ' 277px',
                                            margin: '24px 10px 0 0px'
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>
                                            Search Partner Name
                                        </Typography>

                                        <Input
                                            style={{
                                                width: '100%',
                                                border: '1px solid #E7EAEB'
                                            }}
                                            size="large"
                                            placeholder="Partner Name"
                                            onChange={(e) => filterPartner(e.target.value.toLocaleUpperCase())}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            width: ' 277px',
                                            margin: '24px 10px 0 0px'
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Search by Date</Typography>
                                        <RangePicker
                                            format="YYYY-MM-DD"
                                            style={{
                                                width: '100%',
                                                border: '1px solid #E7EAEB'
                                            }}
                                            size="large"
                                            value={selectedDate}
                                            onChange={filterDate}
                                        />
                                    </Box>

                                    <Box
                                        sx={{
                                            width: ' 277px',
                                            margin: '24px 10px 0 0px'
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Limit</Typography>

                                        <Select
                                            style={{
                                                width: '100%'
                                            }}
                                            size="large"
                                            placeholder="Select limit"
                                            options={limitOptions}
                                            onChange={(value) => handleLimit(value)}
                                            filterOption={(inputValue, option) => {
                                                if (typeof option.label === 'string') {
                                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                }

                                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }}
                                            showSearch
                                            allowClear
                                        />
                                    </Box>
                                    {/* <Box
                                    sx={{
                                        width: ' 277px',
                                        margin: '24px 10px 0 0px'
                                    }}
                                >
                                    <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Payment Proof</Typography>

                                    <Select
                                        style={{
                                            width: '100%'
                                        }}
                                        size="large"
                                        placeholder="Payment Proof"
                                        options={[
                                            {
                                                value: 'Complete Payment',
                                                label: 'Complete Payment'
                                            },
                                            {
                                                value: 'InComplete Payment',
                                                label: 'InComplete Payment'
                                            },
                                            {
                                                value: 'Without Payment',
                                                label: 'Without Payment'
                                            }
                                        ]}
                                        onChange={(value) => handleLimit(value)}
                                        filterOption={(inputValue, option) => {
                                            if (typeof option.label === 'string') {
                                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }

                                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }}
                                        showSearch
                                        allowClear
                                    />
                                </Box> */}
                                </Box>

                                <Button
                                    type="primary"
                                    size="large"
                                    style={{
                                        backgroundColor: '#4B6EFF',
                                        borderRadius: '12px',
                                        fontSize: '16px',
                                        fontWeight: '500',
                                        color: '#fff',
                                        width: '222px',
                                        border: '1px solid #4B6EFF',
                                        margin: '44px 0 0 0px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                    onClick={() => {
                                        return userRole === 'CustomerSuccess' ? navigate(`/cssorderrequestform`) : navigate(`/sales`);
                                    }}
                                >
                                    New parts request form
                                </Button>
                                <Button
                                    type="primary"
                                    size="large"
                                    style={{
                                        backgroundColor: '#FF740F',
                                        borderRadius: '12px',
                                        fontSize: '16px',
                                        fontWeight: '500',
                                        color: '#fff',
                                        width: '222px',
                                        border: '1px solid #FF740F',
                                        margin: '44px 0 0 0px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                    onClick={() => {
                                        showBulkeModal();
                                    }}
                                >
                                    Add bulk payment
                                </Button>
                            </Space>
                        </>
                    )}
                />
            </div>
            <RmaBulkModal
                handleBulkOk={handleBulkOk}
                handleBulkCancel={handleBulkCancel}
                isBulkModalOpen={isBulkModalOpen}
                confirmedList={confirmedList}
                setUpdatReceiptBulkSuccess={setUpdatReceiptBulkSuccess}
                updateReceiptBulkSuccess={updateReceiptBulkSuccess}
                rmaOrders={rmaOrders}
            />
            <RmaReceiptUpdateModal
                handleReceiptUpdateCancel={handleReceiptUpdateCancel}
                handleReceiptUpdateOk={handleReceiptUpdateOk}
                isReceiptUpdateOpen={isReceiptUpdateOpen}
                collections={collections}
                getOrderId={getOrderId}
                getOrderNo={getOrderNo}
                setUpdatReceiptSuccess={setUpdatReceiptSuccess}
                updateReceiptSuccess={updateReceiptSuccess}
                confirmedList={confirmedList}
            />
            <RmaReceiptUpdateBulkModal
                handleReceiptUpdateBulkCancel={handleReceiptUpdateBulkCancel}
                handleReceiptUpdateBulkOk={handleReceiptUpdateBulkOk}
                isReceiptUpdateBulkOpen={isReceiptUpdateBulkOpen}
                collections={collections}
                getOrderId={getOrderId}
                getOrderNo={getOrderNo}
                setUpdatReceiptSuccess={setUpdatReceiptSuccess}
                updateReceiptSuccess={updateReceiptSuccess}
                confirmedList={confirmedList}
                rmaOrders={rmaOrders}
            />
        </div>
    );
};

export default CollectionPaymentStatus;
