import React from 'react';
import { DatePicker, Select, Space, Checkbox, Table, Spin, Input, Button, Modal } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';

const CartModal = ({ isDelete, handleCancel, handleOk, isOpen, removeFromCart, getId, successMsg, failedMsg }) => {
    return (
        <>
            {' '}
            <Modal
                open={isOpen}
                onOk={handleOk}
                top
                width={515}
                zIndex={2050}
                onCancel={handleCancel}
                style={{
                    top: 500
                }}
                footer={
                    isDelete ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '24px 0 0 0' }}>
                            <Button
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: '12px',
                                    border: '1px solid #E7EAEB',
                                    height: '48px',
                                    fontSize: '16px',
                                    fontWeight: '700',
                                    color: '#FF740F ',
                                    width: '154px',
                                    textAlign: 'center'
                                }}
                                onClick={() => {
                                    handleCancel();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: '12px',
                                    border: '1px solid #E7EAEB',
                                    height: '48px',
                                    fontSize: '16px',
                                    fontWeight: '700',
                                    color: '#FF740F ',
                                    width: '154px',
                                    textAlign: 'center'
                                }}
                                onClick={() => {
                                    removeFromCart(getId);
                                    handleOk();
                                }}
                            >
                                Yes
                            </Button>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                bottom: '0',
                                width: '100%'
                            }}
                        />
                    )
                }
            >
                <Box>
                    {isDelete ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#0A2938',
                                    fontSize: '20px',
                                    fontWeight: '500'
                                }}
                            >
                                Confirm action
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#546974',
                                    fontSize: '14px',
                                    textAlign: 'center'
                                }}
                            >
                                Are you sure you want to delete this item?
                            </Typography>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#0A2938',
                                    fontSize: '20px',
                                    fontWeight: '500'
                                }}
                            >
                                {' '}
                                {successMsg ? 'Successful!' : 'Failed!'}
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#546974',
                                    fontSize: '14px',
                                    textAlign: 'center'
                                }}
                            >
                                {successMsg || failedMsg}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Modal>
        </>
    );
};

export default CartModal;
