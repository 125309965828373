import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
import useScriptRef from 'hooks/useScriptRef';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControl, InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { toast } from 'react-toastify';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';

const EditLoyaltyList = () => {
    const [name, setName] = useState('');
    const [reward, setReward] = useState('');
    const [amount, setAmount] = useState('');
    const [nooforders, setNooforders] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState('');
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [status, setStatus] = useState('');
    const [type, setType] = useState('');
    const [airtime, setAirtime] = useState('');
    const [startDate, setstartDate] = useState(new Date());
    const statusData = [
        {
            _id: 1,
            name: 'Active'
        },
        {
            _id: 1,
            name: 'InActive'
        }
    ];
    const typeData = [
        {
            _id: 1,
            name: 'EPL'
        },
        {
            _id: 1,
            name: 'NonEPL'
        }
    ];

    const { id } = useParams();

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };
    const handleSignupChange = (newValue) => {
        setstartDate(newValue);
    };

    const retrieveAllActiveCountry = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            // setLoading(true);
            fetch(ApiUrl.ActiveCountryUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        retrieveAllActiveCountry();
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.EditLoyaltyListUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setName(result?.data?.name ?? '');
                    setReward(result?.data?.reward ?? '');
                    setAmount(result?.data?.amount ?? '');
                    setNooforders(result?.data?.nooforders ?? '');
                    setDescription(result?.data?.description ?? '');
                    setCountry(result?.data?.country ?? '');
                    setStatus(result?.data?.status ?? '');
                    setType(result?.data?.type ?? '');
                    setAirtime(result?.data?.airtime ?? '');
                    setstartDate(result?.data?.startDate);
                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                    // setLoading(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading2(false);
            });
    }, [id]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');

        const data = {
            id,
            name,
            amount,
            nooforders,
            description,
            reward,
            country,
            status,
            airtime,
            type,
            startDate
        };
        setLoading(true);
        fetch(ApiUrl.UpdateLoyaltyListUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setLoading(false);
                    toast.success(result.message);
                    setTimeout(() => {
                        if (scriptedRef.current) {
                            history('/loyaltylist');
                        }
                    }, 3000);
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <br />
                            <PageHeader title="Loyalty" subTitle="Home | Configuration | Setups | Loyalty" />
                            <br />
                            <MainCard title="Edit Loyalty">
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        autoComplete="off"
                                        name="name"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="name"
                                        label="Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        autoFocus
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="amount"
                                        variant="outlined"
                                        type="number"
                                        required
                                        fullWidth
                                        id="amount"
                                        label="Amount"
                                        value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="nooforders"
                                        variant="outlined"
                                        required
                                        type="number"
                                        fullWidth
                                        id="nooforders"
                                        label="No. Of Orders"
                                        value={nooforders}
                                        onChange={(e) => setNooforders(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="reward"
                                        variant="outlined"
                                        required
                                        type="number"
                                        fullWidth
                                        id="reward"
                                        label="Reward"
                                        value={reward}
                                        onChange={(e) => setReward(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="description"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="description"
                                        label="Description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="airtime"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="airtime"
                                        label="Airtime"
                                        value={airtime}
                                        onChange={(e) => setAirtime(e.target.value)}
                                    />
                                    <br />
                                    <br />

                                    <Autocomplete
                                        id="country-select-demo"
                                        autoHighlight
                                        value={country}
                                        onChange={(event, newValue) => {
                                            setCountry(newValue);
                                        }}
                                        options={countries.map((option) => option.name)}
                                        isOptionEqualToValue={(option, value) => option.code === value.code}
                                        renderInput={(params) => <TextField {...params} label="Choose a country" />}
                                    />
                                    <br />
                                    <br />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Choose Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={type}
                                            label="Type"
                                            onChange={handleTypeChange}
                                        >
                                            {typeData.map((attr) => (
                                                <MenuItem key={attr.name} value={attr.name}>
                                                    {attr?.name ?? ''}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <br />
                                    <br />
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Stack spacing={3}>
                                            <DesktopDatePicker
                                                label="Date Of Start"
                                                inputFormat="MM/dd/yyyy"
                                                value={startDate}
                                                onChange={handleSignupChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Stack>
                                    </LocalizationProvider>

                                    <br />
                                    <br />

                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Choose Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={status}
                                            label="Status"
                                            onChange={handleStatusChange}
                                        >
                                            {statusData.map((attr) => (
                                                <MenuItem key={attr.name} value={attr.name}>
                                                    {attr?.name ?? ''}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <br />
                                    <br />
                                    <Box display="flex" justifyContent="space-between">
                                        <Button component={Link} to="/loyaltylist" type="submit" variant="contained" color="error">
                                            Reset
                                        </Button>

                                        <Button type="submit" variant="contained" color="secondary">
                                            {loading ? 'Updating ..' : 'Update'}
                                        </Button>
                                    </Box>
                                </form>
                            </MainCard>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default EditLoyaltyList;
