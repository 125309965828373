import React, { useEffect, useRef, useState } from 'react';
import { Table, Divider, Paper, Space, Button, Select, Modal, Checkbox, Alert, Input, notification } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import ApiUrl from '../../network';
import { ToastContainer, toast } from 'react-toastify';
import { encrypt, decrypt } from '../../utils/encrypt';
import './rmaTable.css';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import RmaReceiptConfirmModal from './RmaReceiptConfirmModal';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Webcam from 'react-webcam';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { dateConverter } from 'utils/functions';

const arrayOfOrderNo = [];
const arrayOfSalesValue = [];
const ordersBulkArray = [];

const RmaBulkModal = ({
    handleBulkOk,
    handleBulkCancel,
    isBulkModalOpen,
    confirmedList,
    getOrderId,
    getOrderNo,
    setUpdatReceiptBulkSuccess,
    updateReceiptBulkSuccess,
    rmaOrders
}) => {
    const [amountDeposited, setAmountDeposited] = useState('');
    const [paymentType, setPaymentType] = useState('');
    const [totalSalesValue, setTotalSalesValue] = useState(0);
    const fileInputRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [getOrderValue, setGetOrderValue] = useState('');
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [extractLoading, setExtractLoading] = useState(false);
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [api, contextHolder] = notification.useNotification();
    const [openCamera, setOpenCamera] = useState(false);
    const webcamRef = useRef(null);
    const { Option } = Select;

    const openNotification = (placement) => {
        api.open({
            description: 'Payment has been added.',
            placement,
            style: {
                width: 267,
                height: 70,
                backgroundColor: '#0A2938',
                borderRadius: '12px',
                textAlign: 'center',
                color: '#fff',
                fontWeight: 500,
                fontSize: '18px'
            },
            closeIcon: false
        });
    };

    const [orders, setOrders] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const fetchOrders = async () => {
        const userInfo = localStorage.getItem('userToken');

        try {
            setLoading(true);
            const response = await fetch(`${ApiUrl.MyMainOrdersUrl}?page=${page}&limit=100`, {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            const newOrders = data.data;
            setOrders((prevOrders) => [...prevOrders, ...newOrders]);
            setPage((prevPage) => prevPage + 1);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching orders:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    const handlePopupScroll = () => {
        if (!loading && hasMore) {
            fetchOrders();
        }
    };

    const renderOptions = () => {
        return orders.map((order, index) => (
            <Option
                key={`${order?._id} - ${new Date(order?.orderDate).toLocaleDateString().replace(/\//g, '-')}`}
                value={`${order?.orderNo} - ${new Date(order?.orderDate).toLocaleDateString().replace(/\//g, '-')}`}
            >
                {order?.orderNo}
                {new Date(order?.orderDate).toLocaleDateString().replace(/\//g, '-')}
            </Option>
        ));
    };

    // select order Numbers for bulk collection
    const handleSelectOrders = (value) => {
        // get order numbers to be selected
        arrayOfOrderNo.push(value);

        if (getOrderValue.includes(value)) {
            toast.error(`${value} has already been selected!!`);
            return;
        }

        // accumulate sales value of selected orders
        if (!getOrderValue.includes(value)) {
            setGetOrderValue([...getOrderValue, value]);
        }

        // get selected order details when order number is selected
        const selectedOrderDetails = orders.filter((order) => {
            return order.orderNo === value;
        });

        // add all sales values selected to an array
        arrayOfSalesValue.push(selectedOrderDetails[0]?.expCashCollected);

        // calculate sum of sales values of all orders selected
        const sum = arrayOfSalesValue.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        setTotalSalesValue(sum);

        // get each orders id, order number and sales value
        const bulkOrderDetails = {
            orderId: selectedOrderDetails[0]?._id,
            orderNo: selectedOrderDetails[0]?.orderNo,
            orderSales: selectedOrderDetails[0]?.expCashCollected
        };

        // get each selected order
        ordersBulkArray.push(bulkOrderDetails);
    };

    // remove order number from bulk collection
    const handleRemoveOrder = (valueToRemove) => {
        const updatedOrders = getOrderValue.filter((order) => order !== valueToRemove);

        const removedValue = orders.find((order) => order.orderNo === valueToRemove);

        if (removedValue && removedValue.expCashCollected !== undefined) {
            setTotalSalesValue((prevTotal) => prevTotal - removedValue.expCashCollected);

            // Remove the subtracted value from arrayOfSalesValue
            const salesValueindexToRemove = arrayOfSalesValue.indexOf(removedValue.expCashCollected);
            if (salesValueindexToRemove !== -1) {
                arrayOfSalesValue.splice(salesValueindexToRemove, 1);
            }

            // Remove the subtracted value from ordersBulkArray
            if (salesValueindexToRemove !== -1) {
                ordersBulkArray.splice(salesValueindexToRemove, 1);
            }
        }

        setGetOrderValue(updatedOrders);
    };

    // text exraction
    const extractText = async (files) => {
        setExtractLoading(true);
        const userInfo = localStorage.getItem('userToken');

        const apiUrl = `${ApiUrl.BaseUrl}/llm/extract-amount`;

        const formData = new FormData();
        formData.append('file', files[0]);

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo
            },
            body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    toast.error(response);
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                // console.error(data);
                // console.error(data.result);
                setAmountDeposited(data.result);
                setExtractLoading(false);
                toast.success('Amount extracted successfully!!');
            })
            .catch((error) => {
                console.error('Error:', error);
                toast.error('Error during amount extraction');
                setExtractLoading(false);
            });
    };

    // capture image
    const capture = () => {
        const imageSrc = webcamRef.current.getScreenshot();

        if (imageSrc) {
            setSelectedFiles((prevFiles) => [...prevFiles, imageSrc]);
        }
    };

    // open device camera
    const handleOpenCamera = () => {
        setOpenCamera((prevOpenCamera) => !prevOpenCamera);
    };

    // Helper function to read a file as Data URL using FileReader
    const readFileAsDataURL = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();

            reader.onload = (e) => {
                resolve(e.target.result);
            };

            // Read the current file as Data URL
            reader.readAsDataURL(file);
        });
    };

    const handleFileChange = async (event) => {
        const files = event.target.files;

        // Use Promise.all to asynchronously read each file as a Data URL
        const dataURLs = await Promise.all(Array.from(files).map((file) => readFileAsDataURL(file)));

        // Update the state to store an array of selected files as Data URLs
        setSelectedFiles((prevFiles) => [...prevFiles, ...dataURLs]);

        // extractText(files);
    };

    const handleUploadButtonClick = () => {
        // Trigger the file input click when the button is clicked
        fileInputRef.current.click();
    };

    const handlePaymentType = (value) => {
        setPaymentType(value);
    };

    // add Collection
    const addBulkCollection = async (event) => {
        const userInfo = localStorage.getItem('userToken');
        setPaymentLoading(true);

        const data = {
            paymentType,
            totalSales: totalSalesValue,
            amount: amountDeposited,
            image: selectedFiles?.map((url) => ({ image: url })),
            ordersBulk: ordersBulkArray?.map((order) => ({ orderId: order.orderId, orderNo: order.orderNo, orderSales: order.orderSales }))
        };

        if (data?.image?.length === 0) {
            toast.error(`Add a receipt image `);
            setPaymentLoading(false);
            return;
        }

        const obj = JSON.stringify(data);
        const result = encrypt(obj, keyResult, ivResult);

        await fetch(`${ApiUrl.BaseUrl}/collection/bulkCollectPayment`, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ payload: result })
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    openNotification('bottom');
                    setUpdatReceiptBulkSuccess(true);
                    handleBulkOk();
                } else if (result.status === false) {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                    toast.error(answer);
                    handleBulkCancel();
                } else {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                    handleBulkCancel();
                }
            })
            .finally(() => {
                setPaymentLoading(false);
                setAmountDeposited('');
                setTotalSalesValue('');
                setPaymentType('');
                setSelectedFiles([]);
            });
    };

    // const ordeNoOptions = rmaOrders?.map((item) => {
    //     return { label: item?.orderNo, value: item?.orderNo };
    // });

    const ordeNoOptions = orders?.map((item) => {
        return { label: `${item?.orderNo} - ${new Date(item?.orderDate).toLocaleDateString().replace(/\//g, '-')}`, value: item?.orderNo };
    });

    return (
        <div>
            {contextHolder}
            <Modal
                open={isBulkModalOpen}
                onOk={handleBulkOk}
                centered
                width={321}
                // zIndex={4400}
                height={490}
                onCancel={handleBulkCancel}
                footer={
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                            style={{
                                backgroundColor: '#FFFFFF',
                                borderRadius: '8px',
                                border: '1px solid #FF740F',
                                height: '48px',
                                fontSize: '18px',
                                fontWeight: '700',
                                color: '#FF740F ',
                                width: '154px',
                                textAlign: 'center'
                            }}
                            onClick={() => {
                                handleBulkCancel();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{
                                backgroundColor: '#FF740F',
                                borderRadius: '8px',
                                height: '48px',
                                fontSize: '18px',
                                fontWeight: '700',
                                color: '#FFFFFF',
                                width: '154px',
                                textAlign: 'center'
                            }}
                            onClick={() => {
                                addBulkCollection();
                                handleBulkOk();
                                toast.success('Processing...');
                            }}
                        >
                            Add
                        </Button>
                    </Box>
                }
            >
                <div className="">
                    <Typography
                        sx={{
                            fontSize: '20px',
                            color: '#546974',
                            padding: '0',
                            fontWeight: '500',
                            // marginBottom: '40px',
                            textAlign: 'center'
                        }}
                    >
                        Add bulk payment
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            // backgroundColor: '#f6f6f6',
                            // borderRadius: '12px',
                            // border: '1px solid #e7eaeb',
                            height: '400px',
                            overflowY: 'scroll',
                            overflowX: 'hidden'
                            // paddingBottom: '20px'
                        }}
                        className="collection-modals-y"
                    >
                        <Box
                            sx={{
                                width: ' 100%',
                                margin: getOrderValue.length > 0 ? `${getOrderValue.length * 30 + 250}px 0 0 0px` : '250px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974', margin: '0px 0 10px 0px' }}>
                                Select orders
                            </Typography>
                            <Select
                                style={{
                                    width: '100%'
                                }}
                                showSearch
                                size="large"
                                allowClear
                                placeholder="Select orders"
                                optionFilterProp="children"
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                options={ordeNoOptions}
                                value={null}
                                onChange={handleSelectOrders}
                                loading={loading}
                                onPopupScroll={handlePopupScroll}
                            >
                                {renderOptions()}
                                {loading && (
                                    <Option key="loading" disabled>
                                        Loading...
                                    </Option>
                                )}
                            </Select>
                        </Box>
                        {getOrderValue.length > 0 ? (
                            <Box
                                sx={{
                                    width: ' 100%',
                                    margin: '8px 0 0 0px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flexWrap: 'wrap'
                                }}
                            >
                                {getOrderValue.map((value) => (
                                    <Box
                                        key={value}
                                        sx={{
                                            width: ' 124px',
                                            margin: '8px 0 0 0',
                                            height: '44px',
                                            borderRadius: '8px',
                                            padding: '8px',
                                            border: '1px solid #E9ECED',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: '#546974',
                                                fontSize: '16px'
                                            }}
                                        >
                                            {value}
                                        </Typography>

                                        <CloseIcon
                                            sx={{
                                                color: '#434A5B99',
                                                fontSize: '16px',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => {
                                                handleRemoveOrder(value);
                                            }}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        ) : null}

                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974', margin: '0px 0 10px 0px' }}>
                                Total Sales Value
                            </Typography>
                            <Input
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                placeholder="  Toatl Sales Value"
                                value={totalSalesValue}
                                onChange={(e) => setTotalSalesValue(e.target.value)}
                                loading={extractLoading.toString()}
                                type="number"
                                disabled
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974', margin: '0px 0 10px 0px' }}>
                                Amount Deposited
                            </Typography>
                            <Input
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                placeholder="Amount"
                                value={amountDeposited}
                                onChange={(e) => setAmountDeposited(e.target.value)}
                                loading={extractLoading.toString()}
                                type="number"
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974', margin: '0px 0 10px 0px' }}>
                                Payment Type
                            </Typography>
                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                allowClear
                                placeholder="Payment type"
                                optionFilterProp="children"
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                options={[
                                    {
                                        value: 'Cash',
                                        label: 'Cash'
                                    },
                                    {
                                        value: 'Cheque',
                                        label: 'Cheque'
                                    },
                                    {
                                        value: 'Bank account',
                                        label: 'Bank account'
                                    },
                                    {
                                        value: 'Momo',
                                        label: 'Momo'
                                    }
                                ]}
                                value={paymentType}
                                onChange={handlePaymentType}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 100%'
                                // margin: '24px 0 0 0px'
                            }}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '40px 0 0px 0px' }}>
                                {/* <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974', margin: '0px 0 10px 0px' }}>
                                    Receipt
                                </Typography> */}
                                {!openCamera ? (
                                    <Typography
                                        onClick={handleOpenCamera}
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            color: '#4B6EFF',
                                            margin: '0px 0 10px 0px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <CameraAltIcon />
                                        capture
                                    </Typography>
                                ) : null}

                                <Typography
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '16px',
                                        color: '#4B6EFF',
                                        margin: '0px 0 10px 0px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={handleUploadButtonClick}
                                >
                                    Upload receipt
                                </Typography>
                            </Box>

                            {selectedFiles.length > 0 ? (
                                <Box
                                    style={{
                                        display: 'flex',
                                        overflowX: 'scroll',
                                        scrollSnapType: 'x mandatory',
                                        whiteSpace: 'nowrap',
                                        width: '321px'
                                        // height: '100px'
                                    }}
                                    className="collection-modals-x"
                                >
                                    {selectedFiles.map((file, index) => (
                                        <Box
                                            sx={{
                                                width: ' 100%',
                                                height: '200px',
                                                backgroundColor: '#E7EAEB',
                                                borderRadius: '12px',
                                                cursor: 'pointer',
                                                overflow: 'hidden',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flex: '0 0 250px',
                                                scrollSnapAlign: 'center',
                                                marginRight: '5px'
                                            }}
                                            key={index}
                                            onClick={handleUploadButtonClick}
                                        >
                                            <img src={file} multiple className="receipt-image" alt="Selected" />
                                        </Box>
                                    ))}
                                    <input
                                        type="file"
                                        accept="image/*"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                </Box>
                            ) : (
                                <Box
                                    style={{
                                        display: 'flex',
                                        overflowX: 'scroll',
                                        scrollSnapType: 'x mandatory',
                                        whiteSpace: 'nowrap',
                                        width: '321px'
                                        // height: '100px'
                                    }}
                                    className="collection-modals-x"
                                >
                                    <Box
                                        sx={{
                                            width: ' 100%',
                                            height: '200px',
                                            backgroundColor: '#E7EAEB',
                                            borderRadius: '12px',
                                            cursor: 'pointer',
                                            overflow: 'hidden',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flex: '0 0 270px',
                                            scrollSnapAlign: 'center',
                                            marginRight: '5px'
                                        }}
                                        onClick={handleUploadButtonClick}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexDirection: 'column'
                                            }}
                                        >
                                            <UploadFileIcon
                                                sx={{
                                                    color: '#9747FF'
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    color: '#4B6EFF',
                                                    margin: '10px 0 0 0'
                                                }}
                                            >
                                                Click here to upload receipt
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <input
                                        type="file"
                                        accept="image/*"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                </Box>
                            )}
                            {openCamera ? (
                                <>
                                    <Button
                                        size="medium"
                                        type="primary"
                                        onClick={() => {
                                            setOpenCamera(!openCamera);
                                        }}
                                        style={{ backgroundColor: '#fff', color: '#FF740F', border: '1px solid #FF740F', margin: '20px 0' }}
                                    >
                                        Close Camera
                                    </Button>
                                </>
                            ) : null}

                            {openCamera ? (
                                <Box
                                    style={{
                                        display: 'flex',
                                        overflowX: 'scroll',
                                        scrollSnapType: 'x mandatory',
                                        whiteSpace: 'nowrap',
                                        width: '321px'
                                        // height: '100px'
                                    }}
                                    className="collection-modals-x"
                                >
                                    <Box
                                        sx={{
                                            width: ' 100%',
                                            height: '200px',
                                            backgroundColor: '#E7EAEB',
                                            borderRadius: '12px',
                                            cursor: 'pointer',
                                            overflow: 'hidden',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flex: '0 0 270px',
                                            scrollSnapAlign: 'center',
                                            marginRight: '5px'
                                        }}
                                        onClick={handleUploadButtonClick}
                                    >
                                        {openCamera ? (
                                            <>
                                                <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
                                            </>
                                        ) : (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <UploadFileIcon
                                                    sx={{
                                                        color: '#9747FF'
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        color: '#4B6EFF',
                                                        margin: '10px 0 0 0'
                                                    }}
                                                >
                                                    Click here to upload receipt
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>

                                    <input
                                        type="file"
                                        accept="image/*"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                </Box>
                            ) : (
                                <Box
                                    style={{
                                        display: 'flex',
                                        overflowX: 'scroll',
                                        scrollSnapType: 'x mandatory',
                                        whiteSpace: 'nowrap',
                                        width: '321px'
                                        // height: '100px'
                                    }}
                                    className="collection-modals-x"
                                >
                                    <Box onClick={handleUploadButtonClick} />

                                    <input
                                        type="file"
                                        accept="image/*"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '24px 0 0 0px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            {openCamera ? (
                                <>
                                    <Button
                                        size="medium"
                                        type="primary"
                                        onClick={() => {
                                            capture();
                                            setOpenCamera((prevOpenCamera) => !prevOpenCamera);
                                        }}
                                        style={{ backgroundColor: '#FF740F', color: '#fff', border: '1px solid #FF740F' }}
                                    >
                                        Capture Photo
                                    </Button>
                                </>
                            ) : null}
                            {selectedFiles.length > 0 ? (
                                <>
                                    <Button
                                        size="medium"
                                        type="primary"
                                        onClick={() => {
                                            setSelectedFiles([]);
                                        }}
                                        style={{ backgroundColor: '#fff', color: '#FF740F', border: '1px solid #FF740F' }}
                                    >
                                        Clear Photo
                                    </Button>
                                </>
                            ) : null}
                        </Box>
                    </Box>
                </div>
            </Modal>
        </div>
    );
};

export default RmaBulkModal;
