import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import MoonLoader from 'react-spinners/MoonLoader';

dayjs.extend(localizedFormat);

const ModalWrapper = styled.div`
    .title {
        color: #ff740f;
        font-weight: 800;
        font-size: 1.5rem;
        text-align: center;
        padding: 1rem 0;
    }

    .content {
        padding: 1rem 2rem;
        margin-bottom: 50px;
    }

    .preview-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .item-name {
        font-weight: 700;
    }
`;

const PreviewPriorityModal = ({ handleClose, show, singlePriority, isLoading }) => {
    const {
        priorityDate,
        batchNo,
        orderNo,
        size,
        orderType,
        distance,
        stagingArea,
        sizeQty,
        distancekm,
        location,
        priority,
        retailerlat,
        retailerlng
    } = singlePriority;
    return (
        <>
            <Modal show={show} onHide={handleClose} style={{ marginTop: '90px' }}>
                <ModalWrapper>
                    <Modal.Title>
                        <h5 className="title">View Priority</h5>
                        <hr />
                    </Modal.Title>
                    <Modal.Body>
                        {isLoading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '50px' }}>
                                <MoonLoader color="#ff740f" />
                            </div>
                        ) : (
                            <div className="content">
                                <div className="preview-items">
                                    <p className="item-name">Date</p>
                                    <p>{dayjs(priorityDate).format('l')}</p>
                                </div>
                                <div className="preview-items">
                                    <p className="item-name">Batch No. </p>
                                    <p>{batchNo}</p>
                                </div>
                                <div className="preview-items">
                                    <p className="item-name">Order No.</p>
                                    <p>{orderNo}</p>
                                </div>
                                <div className="preview-items">
                                    <p className="item-name">Size</p>
                                    <p>{size}</p>
                                </div>
                                <div className="preview-items">
                                    <p className="item-name">Order Type</p>
                                    {orderType === 'New' ? (
                                        <span className="badge bg-success">{orderType}</span>
                                    ) : (
                                        <span className="badge bg-danger">{orderType}</span>
                                    )}
                                </div>
                                <div className="preview-items">
                                    <p className="item-name">Distance</p>
                                    <p>{distance}</p>
                                </div>
                                <div className="preview-items">
                                    <p className="item-name">Location</p>
                                    <p>{location}</p>
                                </div>
                                <div className="preview-items">
                                    <p className="item-name">Priority</p>
                                    <p>{priority}</p>
                                </div>
                                <div className="preview-items">
                                    <p className="item-name">Size Quantity</p>
                                    <p>{sizeQty}</p>
                                </div>
                                <div className="preview-items">
                                    <p className="item-name">Staging Area</p>
                                    <p>{stagingArea}</p>
                                </div>
                                <div className="preview-items">
                                    <p className="item-name">Distance(KM)</p>
                                    <p>{distancekm}</p>
                                </div>
                                <div className="preview-items">
                                    <p className="item-name">Longitude</p>
                                    <p>{retailerlng}</p>
                                </div>
                                <div className="preview-items">
                                    <p className="item-name">Latitude)</p>
                                    <p>{retailerlat}</p>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                </ModalWrapper>
            </Modal>
        </>
    );
};
export default PreviewPriorityModal;
