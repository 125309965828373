import { CREATE_NOTE_SUCCESS, CREATE_NOTE_BEGIN } from './delivery-actions';

const deliveryReducer = (state, action) => {
    switch (action.type) {
        case CREATE_NOTE_BEGIN:
            return {
                isLoading: true
            };
        case CREATE_NOTE_SUCCESS:
            return {
                isLoading: false
            };

        default:
            throw new Error(`No such action ${action.type}`);
    }
};

export default deliveryReducer;
