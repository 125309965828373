import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import LeadTableWrapper from 'assets/Wrappers/LeadTableWrapper';
import { makeStyles } from '@mui/styles';
import TablePaginationActions from 'components/TablePaginationActions';
import MoonLoader from 'react-spinners/MoonLoader';
import { successToast, errorToast } from 'utils/toast';
import { authFetch } from 'utils/axios-config';
import { useAppContext } from 'context/appContext';
import { Row, Col } from 'react-grid-system';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import _ from 'lodash';
import { removeTags } from 'utils/functions';

const SaleDeliveryTable = ({ th1, th2, th3, th4, th5, th6, th7, th8 }) => {
    const { userId } = useAppContext();
    const [rows, setRows] = React.useState([]);
    const [orderStatus, setOrderStatus] = React.useState('All');

    const useStyles = makeStyles(() => ({
        tableHeaderCell: {
            fontWeight: 'bolder'
        }
    }));
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());

    const handleEvent = (e, picker) => {
        setStartDate(picker.startDate._d.toISOString());
        setEndDate(picker.endDate._d.toISOString());
    };

    const [isLoading, setLoading] = React.useState(false);

    async function getSalesNote() {
        const object = {
            salesPersonId: userId
        };

        setLoading(true);
        try {
            const { data } = await authFetch.post('/deliveryautomation/fetchsalesdeliverynotetoday', object);
            setRows(data.data);
            setLoading(false);
            successToast(`Data loaded successfully`);
        } catch (error) {
            const { message } = error.response.data;
            if (error.response.status !== 401) {
                errorToast(message);
            }
        }
    }

    /** Filter orders by date */
    const filterOrderByDate = async () => {
        const filterOptions = {
            from: startDate,
            to: endDate,
            salesPersonId: userId
        };
        setLoading(true);
        try {
            const response = await authFetch.post('/deliveryautomation/filtersalesdeliverynotebydate', filterOptions);
            const { deliverynote } = response.data;
            setRows(deliverynote);
            setLoading(false);
            successToast('Data filtered successfully');
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                errorToast(message);
            }
            console.log(error);
        }
    };

    /** Filter by status */
    const handleOrderStatusFilter = async () => {
        const filterOptions = {
            status: orderStatus,
            salesPersonId: userId
        };
        setLoading(true);
        try {
            const { data } = await authFetch.post('/deliveryautomation/filtersalesdeliverynotestatus', filterOptions);
            const { deliverynote } = data;
            setLoading(false);
            setRows(deliverynote);
        } catch (error) {
            console.log(error);
        }
    };

    React.useEffect(() => {
        handleOrderStatusFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderStatus]);

    const handleOrderFilterByDate = async () => {
        filterOrderByDate();
    };

    const handleFilterReset = () => {
        window.location = '/sales/deliveries';
    };

    React.useEffect(() => {
        getSalesNote();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <LeadTableWrapper>
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className="lead__main-table" elevation={0}>
                <div style={{ padding: '15px 30px' }}>
                    <h4 style={{ color: '#ff740f', fontWeight: '700' }}>{`Total Deliveries: ${rows.length}`}</h4>
                    <div style={{ padding: '10px' }}>
                        <Row>
                            <Col xs={12} xl={3} lg={3} md={6} sm={6}>
                                <FormControl fullWidth style={{ marginBottom: '10px' }}>
                                    <InputLabel id="demo-simple-select2-label">Filter status</InputLabel>
                                    <Select
                                        defaultValue=""
                                        labelId="demo-simple-select2-label"
                                        id="demo-simple-select2"
                                        value={orderStatus}
                                        label="Orders"
                                        onChange={(e) => setOrderStatus(e.target.value)}
                                    >
                                        <MenuItem value="All">All</MenuItem>
                                        <MenuItem value="Pending">Pending</MenuItem>
                                        <MenuItem value="Confirmed">Confirmed</MenuItem>
                                        <MenuItem value="Declined">Declined</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} xl={6} lg={6} md={6} sm={6}>
                                <Stack direction="row" spacing={1}>
                                    <DateRangePicker
                                        initialSettings={{ startDate, endDate }}
                                        onEvent={handleEvent}
                                        onApply={handleOrderFilterByDate}
                                        onCancel={handleFilterReset}
                                    >
                                        <Button
                                            variant="contained"
                                            disableElevation
                                            size="large"
                                            className="custom-date-picker"
                                            style={{ whiteSpace: 'nowrap' }}
                                        >
                                            Filter By Date
                                        </Button>
                                    </DateRangePicker>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        onClick={handleFilterReset}
                                        style={{ background: '#ff740f', whiteSpace: 'nowrap' }}
                                    >
                                        Reset Filter
                                    </Button>
                                </Stack>
                            </Col>
                        </Row>
                    </div>
                </div>
                {isLoading ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '50px'
                        }}
                    >
                        <MoonLoader color="#ff740f" />
                    </div>
                ) : (
                    <>
                        <TableContainer sx={{ maxHeight: 400 }}>
                            <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th1}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th2}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th3}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th4}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th5}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th6}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th7}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th8}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map(
                                        (row, index = 1) => {
                                            const { _id, orderId, noteNo, status, notes, deliverynoteDate } = row;
                                            const truncateLongNote =
                                                notes && notes.length > 100 ? _.truncate(notes, { length: 100 }) : notes;
                                            const noteText = truncateLongNote && truncateLongNote.length > 0 ? truncateLongNote : 'N/A';

                                            function assingOrderStatusColor() {
                                                switch (status) {
                                                    case 'Confirmed':
                                                        return '#D2F5CF';
                                                    case 'Pending':
                                                        return '#E4E4E4';
                                                    case 'Declined':
                                                        return '#FBD1D1';
                                                    default:
                                                        return '#3D3C42';
                                                }
                                            }
                                            return (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align="left" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell align="left" scope="row">
                                                        {new Date(deliverynoteDate).toDateString()}
                                                    </TableCell>
                                                    <TableCell align="left" scope="row">
                                                        {noteNo}
                                                    </TableCell>
                                                    <TableCell align="left" scope="row">
                                                        {orderId?.salesname}
                                                    </TableCell>
                                                    <TableCell align="left" scope="row">
                                                        {orderId?.fulOrderId}
                                                    </TableCell>
                                                    <TableCell align="left" scope="row">
                                                        <span
                                                            style={{
                                                                backgroundColor: `${assingOrderStatusColor()}`,
                                                                padding: '3px 25px',
                                                                color: '#222',
                                                                borderRadius: '15px',
                                                                fontSize: '12px',
                                                                fontWeight: '500',
                                                                whiteSpace: 'nowrap'
                                                            }}
                                                        >
                                                            {status}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell align="left" scope="row">
                                                        {removeTags(noteText)}
                                                    </TableCell>
                                                    <TableCell align="left" scope="row">
                                                        <Stack direction="row" spacing={1}>
                                                            <IconButton
                                                                aria-label="preview"
                                                                href={`/confirmdeliverynote/${_id}`}
                                                                style={{ color: '#ff740f' }}
                                                                title="Preview"
                                                            >
                                                                <VisibilityIcon />
                                                            </IconButton>
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }
                                    )}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            component="div"
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page'
                                },
                                native: true
                            }}
                            className="custom-paginator"
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </>
                )}
            </Paper>
        </LeadTableWrapper>
    );
};

export default SaleDeliveryTable;
