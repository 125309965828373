/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ApiUrl from 'network';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import CircularProgress from '@mui/material/CircularProgress';
// import { notification } from 'antd';
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import useScriptRef from 'hooks/useScriptRef';

const ViewTarget = () => {
    const [loading, setLoading] = useState(false);
    const [salesAssociateData, setsalesAssociateData] = useState([]);
    const [salesperson, setsalesperson] = useState('');
    const [leads, setleads] = useState('');
    const [userId, setuserId] = useState('');
    const [sales, setsales] = useState('');
    const [collections, setcollections] = useState('');
    const [country, setcountry] = useState('');
    const [deposits, setdeposits] = useState('');
    const [retailers, setretailers] = useState('');
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [loading2, setLoading2] = useState(false);

    // const [status, setStatus] = useState('');
    const history = useNavigate();
    const scriptedRef = useScriptRef();

    const retrieveAllSalesAssociate = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllSalesPersonTargetUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setsalesAssociateData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const { id } = useParams();

    useEffect(() => {
        // retrieve all sales associate;
        retrieveAllSalesAssociate();
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.ViewTargetUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    // console.log('result', result.data);
                    setsalesperson(result?.data?.salesperson ?? '');
                    setuserId(result?.data?.userId?._id ?? '');
                    setleads(result?.data?.leads ?? '');
                    setsales(result?.data?.sales ?? '');
                    setcollections(result?.data?.collections ?? '');
                    setcountry(result?.data?.country ?? '');
                    setdeposits(result?.data?.deposits ?? '');
                    setretailers(result?.data?.retailers ?? '');
                    setstartDate(result?.data?.startDate ?? '');
                    setendDate(result?.data?.endDate ?? '');
                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                    // setLoading(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading2(false);
            });
    }, [id]);

    const handleSalesChange = (event) => {
        setuserId(event.target.value);
        const newFilter = salesAssociateData.filter((value) =>
            (value._id || '').toLowerCase().includes((event.target.value || '').toLowerCase())
        );
        newFilter.forEach((option) => {
            const fullname = `${option?.firstName}${option?.lastName}`;
            setsalesperson(fullname);
            setcountry(option?.country ?? '');
        });
    };
    const handleStartDateChange = (newValue) => {
        setstartDate(newValue);
    };
    const handleEndDateChange = (newValue) => {
        setendDate(newValue);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const userInfo = localStorage.getItem('userToken');

        const data = {
            id,
            salesperson,
            leads,
            userId,
            sales,
            collections,
            country,
            deposits,
            retailers,
            startDate,
            endDate
        };

        fetch(ApiUrl.UpdateTargetUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                Accept: 'application/form-data',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    if (scriptedRef.current) {
                        setTimeout(() => {
                            history('/targetlist');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                    console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <br />
                            <PageHeader
                                title="Configuration | Sales Planner"
                                subTitle="Home | Configuration | Sales Planner | View Target"
                            />
                            <br />
                            <MainCard title="View Target">
                                <form onSubmit={handleSubmit}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Sales Associate</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={userId}
                                            required
                                            label="Sales Associate"
                                            onChange={handleSalesChange}
                                        >
                                            {salesAssociateData.map((sales) => (
                                                <MenuItem key={sales._id} value={sales._id}>
                                                    {sales?.firstName} {sales?.lastName} - {sales?.country}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="leads"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="leads"
                                        value={leads}
                                        label="Lead Target"
                                        onChange={(e) => setleads(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="sales"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="sales"
                                        value={sales}
                                        label="Sales Target"
                                        onChange={(e) => setsales(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="collections"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="collections"
                                        value={collections}
                                        label="Collection Target"
                                        onChange={(e) => setcollections(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="deposits"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="deposits"
                                        value={deposits}
                                        label="Deposit Target"
                                        onChange={(e) => setdeposits(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="retailers"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="deposits"
                                        value={retailers}
                                        label="New Retailer Target"
                                        onChange={(e) => setretailers(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                        <Grid item xs={6}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DesktopDatePicker
                                                    label="Start Date"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={startDate}
                                                    onChange={handleStartDateChange}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <br />
                                        <br />
                                        <Grid item xs={6}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DesktopDatePicker
                                                    label="End Date"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={endDate}
                                                    onChange={handleEndDateChange}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <br />

                                    <Box display="flex" justifyContent="space-between">
                                        <Button component={Link} to="/targetlist" type="submit" variant="contained" color="error">
                                            Reset
                                        </Button>

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            disabled={!leads || !sales || !collections || !deposits || !startDate || !endDate || loading}
                                        >
                                            {loading ? 'Submitting ..' : 'Submit'}
                                        </Button>
                                    </Box>
                                </form>
                            </MainCard>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default ViewTarget;
