import MoonLoader from 'react-spinners/MoonLoader';

const Loader = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '80px'
            }}
        >
            <MoonLoader color="#ff740f" />
        </div>
    );
};
export default Loader;
