import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import useScriptRef from 'hooks/useScriptRef';
import { FormHelperText } from '@mui/material';

const AddFleetEmail = () => {
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [email, setEmail] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && loading) {
            // console.log(email, password);
        }
    }, [formErrors, loading]);
    const validated = (email) => {
        const errors = {};

        if (!email) {
            errors.email = 'Email is required!';
        }

        return errors;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        // const channelPartnerId = localStorage.getItem('channelpartnerId');
        // console.log(userInfo);
        setFormErrors(validated(email));
        setLoading(true);

        const data = {
            email
        };

        fetch(ApiUrl.AddFleetEmailOrder, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/fleetemailorder');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Grid item xs={12} sm={6}>
                <PageHeader title="Emails" subTitle="Home | Email" />
                <MainCard title="Add Email">
                    <form onSubmit={handleSubmit}>
                        <TextField
                            autoComplete="off"
                            type="email"
                            name="email"
                            variant="outlined"
                            fullWidth
                            required
                            id="email"
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoFocus
                        />
                        <FormHelperText error id="standard-weight-helper-text-email-login">
                            {formErrors.email}
                        </FormHelperText>
                        <br />
                        <br />
                        <Box display="flex" justifyContent="space-between">
                            <Button component={Link} to="/fleetemailorder" type="submit" variant="contained" color="error">
                                Reset
                            </Button>

                            <Button type="submit" variant="contained" color="secondary">
                                {loading ? 'Submitting ..' : 'Submit'}
                            </Button>
                        </Box>
                    </form>
                </MainCard>
            </Grid>
        </>
    );
};

export default AddFleetEmail;
