// src/services/apiService.js
import { toast } from 'react-toastify';

// eslint-disable-next-line import/prefer-default-export
export const fetchWithAuth = async (url, method = 'GET', body = null) => {
    try {
        const userInfo = localStorage.getItem('userToken');
        if (!userInfo) {
            throw new Error('User not authenticated');
        }

        const config = {
            method,
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            ...(body && { body: JSON.stringify(body) })
        };

        const response = await fetch(url, config);
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Error fetching data');
        }

        return await response.json();
    } catch (error) {
        toast.error(error.message, {
            position: 'bottom-right'
        });
        throw error;
    }
};
