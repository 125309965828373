/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
// import { notification } from 'antd';
import { toast } from 'react-toastify';
import Autocomplete from '@mui/material/Autocomplete';
import useScriptRef from 'hooks/useScriptRef';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';

const AddFleetAccount = () => {
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    const [roleId, setroleId] = useState('');
    const [country, setCountry] = useState('');
    const [currency, setcurrency] = useState('');
    const [countries, setCountries] = useState([]);
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const handleRoleChange = (event) => {
        setroleId(event.target.value);
    };

    const retrieveAllActiveCountry = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.ActiveCountryUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const filterCountry = (event) => {
        const newFilter = countries.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        newFilter.forEach((option) => {
            setcurrency(option.currency);
        });
    };

    useEffect(() => {
        retrieveAllActiveCountry();
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        const channelPartnerId = localStorage.getItem('channelpartnerId');
        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        fetch(ApiUrl.FleetCreateAccountUrl, {
            method: 'POST',
            headers: { 'auth-token': userInfo, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                firstName,
                lastName,
                email,
                password,
                country,
                roleId,
                channelPartnerId,
                currency
            })
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/fleetaccount');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid item xs={12} sm={6}>
                        <PageHeader title="Users" subTitle="Home | Configuration | User Management | Users" />
                        <MainCard title="Add User">
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    autoComplete="off"
                                    name="firstName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="firstName"
                                    value={firstName}
                                    label="First Name"
                                    onChange={(e) => setfirstName(e.target.value)}
                                    autoFocus
                                />
                                <br />
                                <br />

                                <TextField
                                    autoComplete="off"
                                    name="lastName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="lastName"
                                    value={lastName}
                                    label="Last Name"
                                    onChange={(e) => setlastName(e.target.value)}
                                />
                                <br />
                                <br />
                                <TextField
                                    autoComplete="off"
                                    name="email"
                                    type="email"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    value={email}
                                    label="Email"
                                    onChange={(e) => setemail(e.target.value)}
                                />
                                <br />
                                <br />
                                <TextField
                                    autoComplete="off"
                                    name="password"
                                    type="password"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="password"
                                    value={password}
                                    label="Password"
                                    onChange={(e) => setpassword(e.target.value)}
                                />
                                <br />
                                <br />

                                <Autocomplete
                                    id="country-select-demo"
                                    autoHighlight
                                    value={country}
                                    onChange={(event, newValue) => {
                                        setCountry(newValue);
                                        filterCountry(newValue);
                                    }}
                                    options={countries.map((option) => option.name)}
                                    isOptionEqualToValue={(option, value) => option.code === value.code}
                                    renderInput={(params) => <TextField {...params} label="Choose a country" />}
                                />
                                <br />
                                <br />

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Account Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={roleId}
                                        label="roles"
                                        onChange={handleRoleChange}
                                    >
                                        <MenuItem value="Body">Select an option</MenuItem>
                                        <MenuItem value="6243ac1eae678df33550c36f">Fleet Driver</MenuItem>
                                        <MenuItem value="623849adf978bc24fe107f5e">Fleet</MenuItem>
                                    </Select>
                                </FormControl>

                                <br />
                                <br />

                                <br />
                                <br />

                                <Box display="flex" justifyContent="space-between">
                                    <Button component={Link} to="/fleetaccount" type="submit" variant="contained" color="error">
                                        Reset
                                    </Button>

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        disabled={!firstName || !lastName || !email || !password || !roleId}
                                    >
                                        {loading ? 'Submitting ..' : 'Submit'}
                                    </Button>
                                </Box>
                            </form>
                        </MainCard>
                    </Grid>
                </>
            )}
        </>
    );
};

export default AddFleetAccount;
