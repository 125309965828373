// assets
import { IconKey, IconSnowflake, IconTruckDelivery } from '@tabler/icons';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import CarCrashIcon from '@mui/icons-material/CarRental';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import Scale from '@mui/icons-material/Scale';
import DateRangeIcon from '@mui/icons-material/DateRange';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
// constant
const icons = {
    IconKey,
    IconTruckDelivery,
    IconSnowflake,
    DashboardIcon,
    CarRepairIcon,
    CarCrashIcon,
    DisplaySettingsIcon,
    MarkEmailReadIcon,
    PersonAddAltIcon,
    Scale,
    DateRangeIcon,
    UploadFileIcon,
    ShoppingBagIcon
};

// ==============================|| EXTRA FLEET MENU ITEMS ||============================== //
const userRole = localStorage.getItem('userRole');

const mychannelpartnerfleet = {
    id: 'fleet',
    title: 'Fleet',
    type: userRole,
    children: [
        {
            id: 'home',
            title: 'Dashboard',
            type: 'item',
            icon: icons.DashboardIcon,
            url: '/'
        },
        {
            id: 'fleetvehiclerequests',
            title: 'Add vehicle to fleet',
            type: 'item',
            icon: icons.Scale,
            url: '/fleetvehiclerequest'
        },

        {
            id: 'requestservicingfleet',
            title: 'Request for servicing',
            type: 'item',
            icon: icons.CarRepairIcon,
            url: '/requestservicingfleet'
        },
        {
            id: 'bulkvehicleupload',
            title: 'Bulk vehicle upload',
            type: 'item',
            icon: icons.UploadFileIcon,
            url: '/bulkvehicleupload'
        },
        {
            id: 'partorderedfleet',
            title: 'Parts catalog',
            type: 'collapse',
            icon: icons.ShoppingBagIcon,
            children: [
                {
                    id: 'partcatalogs',
                    title: 'Parts catalog',
                    type: 'item',
                    url: '/partorderedfleet'
                },
                {
                    id: 'orderpartsfleetlist',
                    title: 'Part orders',
                    type: 'item',
                    url: '/orderpartsfleetlist'
                }
            ]
        },
        {
            id: 'fleetmanagerreminder',
            title: 'Reminders',
            type: 'item',
            icon: icons.DateRangeIcon,
            url: '/fleetmanagerreminder'
        },
        {
            id: 'calendarrequestservicing',
            title: 'Calendar',
            type: 'item',
            icon: icons.DateRangeIcon,
            url: '/calendarrequestservicing'
        },
        // {
        //     id: 'setup',
        //     title: 'Vehicle Management',
        //     type: 'collapse',
        //     icon: icons.DisplaySettingsIcon,
        //     children: [
        //         {
        //             id: 'car',
        //             title: 'Car Make',
        //             type: 'item',
        //             url: '/fleetcar'
        //         },
        //         {
        //             id: 'carmodel',
        //             title: 'Model',
        //             type: 'item',
        //             url: '/fleetcarmodel'
        //         }
        //         // {
        //         //     id: 'fleetserviceid',
        //         //     title: 'Fleet Service Id',
        //         //     type: 'item',
        //         //     url: '/fleetserviceid'
        //         // },
        //         // {
        //         //     id: 'fleetservices',
        //         //     title: 'Fleet Service',
        //         //     type: 'item',
        //         //     url: '/fleetservice'
        //         // }
        //     ]
        // },

        // {
        //     id: 'orderparts',
        //     title: 'Order Parts',
        //     type: 'item',
        //     icon: icons.CarCrashIcon,
        //     url: '/channelpartnerfleetorderparts'
        // },
        // {
        //     id: 'requestservice',
        //     title: 'Request Servicing',
        //     type: 'item',
        //     icon: icons.CarRepairIcon,
        //     url: '/fleetrequestservice'
        // },

        {
            id: 'usermanage',
            title: 'User Management',
            type: 'item',
            icon: icons.PersonAddAltIcon,
            url: '/fleetaccount'
        }

        // {
        //     id: 'fleetemail',
        //     title: 'Add Email(For Notifications)',
        //     type: 'item',
        //     icon: icons.MarkEmailReadIcon,
        //     url: '/fleetemailorder'
        // }

        // {
        //     id: 'fleetdriverorder',
        //     title: 'Fleet Driver Order Parts',
        //     type: 'item',
        //     icon: icons.CarCrashIcon,
        //     url: '/fleetdriverorderparts'
        // }
    ]
};

export default mychannelpartnerfleet;
