/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';
import Controls from '../../components/controls/Controls';
import { Search } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import ViewIcon from '@mui/icons-material/ViewAgenda';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
// import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Form from 'react-bootstrap/Form';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
// import axios from 'axios';
// import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
// import Avatar from '@mui/material/Avatar';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
// import NoteAltIcon from '@mui/icons-material/NoteAlt';
// import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
// import SortIcon from '@mui/icons-material/Sort';
// import * as blobUtil from 'blob-util';
// import { Camera } from 'react-camera-pro';
// import { useTheme } from '@mui/material/styles';
// import { saveAs } from 'file-saver';
// import axios from 'axios';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    }
}));

const RetrieveDriverOrder = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [orderInfo, setOrder] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [driverOrderStatus, setdriverOrderStatus] = useState('');
    const [driverCashCollected, setdriverCashCollected] = useState('');
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [ids, setids] = useState('');

    const handleImageClick = (row) => {
        if (scriptedRef.current) {
            // console.log(row);
            history(`/adddriverorderimage/${row._id}`);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpdate = (row) => {
        handleClickOpen();
        setids(row?._id);
    };

    const handleStatus = (event) => {
        setdriverOrderStatus(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderInfo.length) : 0;

    const retrieveAllOrders = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.RetrieveDriversOrdersUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrder(data.data);
                        setOrderData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        retrieveAllOrders();
    }, []);

    // const handlePdfClick = (row) => {
    //     const userInfo = localStorage.getItem('userToken');
    //     const config = {
    //         headers: {
    //             'auth-token': userInfo,
    //             'Content-Type': 'application/json'
    //         }
    //     };
    //     // setLoading2(true);
    //     axios.post(ApiUrl.DownloadPdfOrdersUrl, row, config).then((res) => {
    //         window.open(res.data.data, '_blank');

    //         // setLoading2(false);
    //     });
    // };

    const onSubmitOrder = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        handleClose();
        const data = {
            ids,
            driverCashCollected,
            driverOrderStatus
        };
        fetch(ApiUrl.UpdateDriverOrdersUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading(false);
                    retrieveAllOrders();
                } else {
                    toast.error(result.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSearch = (event) => {
        const searchWord = event.target.value;

        const newFilter = orderInfo.filter((value) =>
            (value?.channelPartnerId || '').toLowerCase().includes((searchWord || '').toLowerCase())
        );

        if (searchWord === '') {
            setOrder(orderData);
        } else {
            setOrder(newFilter);
        }
    };
    // const handleReset = () => {
    //     retrieveAllOrders();
    // };

    return (
        <>
            <PageHeader title="My Orders" subTitle="Driver/Rider | Order " />

            <MainCard title="My Order List">
                <Box display="flex" justifyContent="flex-start">
                    <Controls.Input
                        label="Search Retailer Name"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearch}
                    />
                </Box>
                <br />

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order Number
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order Date
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Retailer
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Sales Person
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Amount
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Cash Collected
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Status
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 ? orderInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : orderInfo).map(
                                (row) => (
                                    <TableRow key={row._id}>
                                        <TableCell component="th" scope="row">
                                            {row?.fulOrderId ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {toTimestamp(row?.orderDate)}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row.channelPartnerId}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row?.salesname ?? ''}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {Number.isFinite(row?.expCashCollected) ? Number(row?.expCashCollected).toFixed(2) : 0}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {Number.isFinite(row?.driverCashCollected) ? Number(row?.driverCashCollected).toFixed(2) : 0}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.driverOrderStatus ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <IconButton aria-label="delete" color="primary" onClick={() => handleUpdate(row)}>
                                                update
                                            </IconButton>
                                            <IconButton aria-label="delete" color="error" onClick={() => handleImageClick(row)}>
                                                uploadImage
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[20, 30, 50, { label: 'All', value: -1 }]}
                    component="div"
                    count={orderInfo.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>

            <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title" fullWidth>
                <DialogTitle id="responsive-dialog-title">
                    <Divider>
                        <h6>Update order status information</h6>
                    </Divider>
                </DialogTitle>
                <Form onSubmit={onSubmitOrder}>
                    <DialogContent>
                        <Form.Group controlId="formFile" className="mb-3">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={driverOrderStatus}
                                    label="Status"
                                    onChange={handleStatus}
                                    className="mb-3"
                                >
                                    <MenuItem value="Delivered">Delivered</MenuItem>
                                    <MenuItem value="Cancelled">Cancelled</MenuItem>
                                </Select>
                            </FormControl>

                            <br />

                            <TextField
                                className="form-text mb-3"
                                autoComplete="off"
                                name="driverCashCollected"
                                variant="outlined"
                                id="driverCashCollected"
                                label="Cash collected from retailer"
                                value={driverCashCollected}
                                onChange={(e) => setdriverCashCollected(e.target.value)}
                                fullWidth
                            />

                            <br />
                        </Form.Group>

                        {/* </DialogContentText> */}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" size="sm" className={classes.item} autoFocus onClick={handleClose} color="error">
                            Cancel
                        </Button>
                        <Button autoFocus variant="contained" type="submit" size="sm" className={classes.item}>
                            Update
                        </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    );
};

export default RetrieveDriverOrder;
