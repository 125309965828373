import { useAppContext } from 'context/appContext';
import { useLoyaltyContext } from 'context/loyalty/loyalty-context';
import styled from 'styled-components';

const Wrapper = styled.div`
    margin-top: 40px;
    padding: 1rem 2rem;
    font-family: 'Roboto';

    .table-header {
        background: #193855;
        color: #fff;
    }

    .total {
        font-weight: 800;
        font-size: 1.2rem;
    }

    tr td {
        padding: 10px;
    }
`;

const ReceiptTable = () => {
    const { userCurrency } = useAppContext();
    const { totalSales, partsSold } = useLoyaltyContext();

    return (
        <Wrapper>
            <div className="table-responsive">
                <table className="table table-bordered table-striped table-hover">
                    <thead className="table-header">
                        <tr>
                            <th scope="col">ITEM DESCRIPTION</th>
                            <th scope="col">QUANTITY</th>
                            <th scope="col">RATE({userCurrency})</th>
                            <th scope="col">AMOUNT({userCurrency})</th>
                        </tr>
                    </thead>
                    <tbody>
                        {partsSold.map((el, i) => {
                            const { partid, qty, garagePrice } = el;
                            const amount = qty * garagePrice;
                            return (
                                <tr key={i}>
                                    <td>{partid}</td>
                                    <td>{qty}</td>
                                    <td>{garagePrice}</td>
                                    <td>{amount.toFixed(2)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr className="total">
                            <td colSpan={3}>Total</td>
                            <td>
                                {userCurrency}
                                {totalSales.toFixed(2)}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </Wrapper>
    );
};
export default ReceiptTable;
