import { Card, Divider, Select, Spin, Progress } from 'antd';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CallIcon from '@mui/icons-material/Call';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ApiUrl from '../../../network';
import { decrypt, encrypt } from '../../../utils/encrypt';
import { useParams, Link } from 'react-router-dom';
import DebounceSelect from '../../../components/debouceSelect/Index';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');
export const Index = () => {
    const [loading, setLoading] = useState(false);
    const [retailer, setRetailer] = useState({});
    const [retailers, setRetailers] = useState([]);
    const [value, setValue] = useState(null);
    const { id } = useParams();
    // eslint-disable-next-line consistent-return
    const fetchRetailer = async (id) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(`${ApiUrl.BaseUrl}/loyaltymanager/viewdetails/${id}`, config);
            await response.json().then((data) => {
                if (data.status === false) {
                    toast.error(data.message);
                    setLoading(false);
                } else {
                    // console.log(JSON.parse(decrypt(data.data, keyResult, ivResult)));
                    setRetailer(JSON.parse(decrypt(data.data, keyResult, ivResult)));
                    setLoading(false);
                }
            });
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };

    // eslint-disable-next-line consistent-return
    const fetchAllRetailers = async (filters) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/loyaltymanager/main`;

            if (filters && filters.search) {
                urlParams.set('retailer', filters.search);
            }
            if (urlParams.toString()) url += `?${urlParams.toString()}`;

            const response = await fetch(url, config);
            const body = await response.json();
            const results = JSON.parse(decrypt(body.data, keyResult, ivResult));
            const options = results.map((user) => ({
                label: user.retailerName,
                value: user.retailerName
            }));
            return options;
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (id) {
            fetchRetailer(id);
            fetchAllRetailers();
        }

        return () => {
            // cleanup function
            setLoading(false);
        };
    }, [id]);

    const retailersOptions = retailers.map((retailer) => {
        return { value: retailer._id, label: retailer.retailerName };
    });

    const fetchUserList = async (username) => {
        const userInfo = localStorage.getItem('userToken');
        if (!userInfo) {
            toast.error('User not authenticated');
            return false;
        }
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };
        return fetch(
            `
        ${ApiUrl.BaseUrl}/loyaltymanager/main?retailer=${username}`,
            config
        )
            .then((response) => response.json())
            .then((body) =>
                JSON.parse(decrypt(body.data, keyResult, ivResult)).map((user) => ({
                    label: user.retailerName,
                    value: user._id
                }))
            );
    };

    return (
        <div className="leadCrmContainer">
            <div className="d-flex justify-content-space-between align-items-center salesCardHolder">
                <Link to="/retailer-loyalty" style={{ color: '#0A2938' }}>
                    <p style={{ fontSize: '30px' }}>
                        {' '}
                        <KeyboardBackspaceIcon style={{ marginRight: '5px' }} /> Loyalty details
                    </p>
                </Link>
                <div>
                    <DebounceSelect
                        allowClear
                        showSearch
                        value={value}
                        placeholder="Select users"
                        fetchOptions={fetchUserList}
                        onChange={(newValue) => {
                            fetchRetailer(newValue.value);
                            setValue(newValue.label);
                        }}
                        style={{
                            width: '200px'
                        }}
                    />
                </div>
            </div>
            <Divider style={{ margin: '20px 0', border: '1px dash #e8e8e8' }} />
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 34
                                }}
                                spin
                            />
                        }
                    />
                </div>
            ) : (
                <div>
                    <div className="d-flex justify-content-space-between salesCardHolder">
                        <div className="salesCardHolder flex-grow-1">
                            <div className="d-flex justify-content-space-between mb-4 salesCardHolder">
                                <Card className="salesCard" style={{ borderRadius: '20px' }}>
                                    <div className="d-flex align-items-center mb-2">
                                        <span
                                            style={{
                                                backgroundColor: '#F6F6F6',
                                                padding: '10px',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '50px',
                                                height: '50px',
                                                color: '#8C9DAB',
                                                fontSize: '1.1rem',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            <UserOutlined />
                                        </span>
                                        <span className="ml-2">
                                            <p style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '0px', color: '#3B5460' }}>
                                                {' '}
                                                {retailer?.retailerName}{' '}
                                            </p>
                                            <small style={{ color: '#3B5460', fontSize: '14px' }}>Retailer’s name</small>
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <span
                                            style={{
                                                backgroundColor: '#F6F6F6',
                                                padding: '10px',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '40px',
                                                height: '40px',
                                                color: '#8C9DAB',
                                                fontSize: '0.9rem',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            <CallIcon />
                                        </span>
                                        <span className="ml-2">
                                            <p style={{ fontSize: '1rem', marginBottom: '0px', color: '#3B5460' }}>
                                                {' '}
                                                {retailer?.partnerContact}{' '}
                                            </p>
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <span
                                            style={{
                                                backgroundColor: '#F6F6F6',
                                                padding: '10px',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '40px',
                                                height: '40px',
                                                color: '#8C9DAB',
                                                fontSize: '0.9rem',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            <AddLocationIcon />
                                        </span>
                                        <span className="ml-2">
                                            <p style={{ fontSize: '0.8rem', marginBottom: '0px', color: '#3B5460' }}>
                                                {' '}
                                                {retailer?.location || 'No location added'}
                                            </p>
                                        </span>
                                    </div>
                                </Card>
                                <Card
                                    className="salesCard"
                                    style={{ borderRadius: '20px', backgroundColor: '#FFF2D7', textAlign: 'center' }}
                                >
                                    <p style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '0px', color: '#3B5460' }}>
                                        Current tier: {retailer?.currentloyaltyTier === 'TIER 0' ? '0' : retailer?.currentloyaltyTier}
                                    </p>
                                    <p className="mt-2">Discount received: {retailer?.discountReceived}</p>
                                    <Divider style={{ margin: '20px 0', border: '1px dash #e8e8e8' }} />
                                    <div
                                        style={{
                                            backgroundColor: '#FFFFFF',
                                            padding: '5px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            margin: '0 10px',
                                            width: '100%',
                                            height: 'fit-content',
                                            borderRadius: '10px'
                                        }}
                                    >
                                        <span
                                            style={{
                                                backgroundColor: '#F6F6F6',
                                                color: '#FFBE37',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '40px',
                                                height: '40px',
                                                fontSize: '0.8rem',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            <EmojiEventsIcon />
                                        </span>
                                        <span className="ml-2 mt-2">
                                            <p style={{ fontSize: '12px', textAlign: 'left', marginBottom: '0px', fontWeight: 'bold' }}>
                                                {retailer?.currentloyaltyTier || 'No tier'}
                                            </p>
                                            <p style={{ fontSize: '10px', textAlign: 'left' }} />
                                        </span>
                                    </div>
                                </Card>
                            </div>
                            <Card
                                className="salesCard mb-4"
                                style={{
                                    borderRadius: '10px',
                                    backgroundColor: '#FFFFFF',
                                    textAlign: 'center',
                                    height: '215px'
                                }}
                            >
                                <div>
                                    <p
                                        style={{
                                            fontSize: '24px',
                                            color: '#0A2938',
                                            fontWeight: 'bold',
                                            textAlign: 'left'
                                        }}
                                    >
                                        Loyalty program progress
                                    </p>
                                    <div>
                                        <div className="d-flex justify-content-space-between">
                                            <p style={{ fontSize: '18px' }}>{retailer?.currentloyaltyTier}</p>
                                            <p style={{ fontSize: '18px' }}>{retailer?.nextTier}</p>
                                        </div>
                                        <Progress percent={retailer?.percentage} />
                                        <div className="d-flex justify-content-space-between">
                                            <p style={{ fontSize: '18px' }}>
                                                {/* <span style={{ marginRight: '2px' }}>{localStorage.getItem('userCurrency')}</span> */}
                                                {retailer?.ordersLeftToNextTier} or more orders left
                                            </p>
                                            <p style={{ fontSize: '18px' }}>
                                                <span style={{ marginRight: '2px' }}>{localStorage.getItem('userCurrency')}</span>
                                                {retailer?.amountLeftToNextTier} away from {retailer?.nextTier}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="salesCardHolder flex-grow-1">
                            <Card
                                className="salesCard mb-4"
                                style={{
                                    borderRadius: '10px',
                                    backgroundColor: '#DBE2FF',
                                    textAlign: 'center',
                                    height: '130px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <div>
                                    <p
                                        style={{
                                            fontSize: '24px',
                                            color: '#0A2938',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        <span style={{ marginRight: '2px' }}>{localStorage.getItem('userCurrency')}</span>{' '}
                                        {retailer?.totalRevenueValue}
                                    </p>
                                    <p>Total revenue</p>
                                </div>
                            </Card>
                            <Card
                                className="salesCard mb-4"
                                style={{
                                    borderRadius: '10px',
                                    backgroundColor: '#CCEEDD',
                                    textAlign: 'center',
                                    height: '130px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <div>
                                    <p
                                        style={{
                                            fontSize: '24px',
                                            color: '#0A2938',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        <span style={{ marginRight: '2px' }}>{localStorage.getItem('userCurrency')}</span>{' '}
                                        {retailer?.averageOrderValue === null || retailer?.averageOrderValue === undefined
                                            ? 0
                                            : retailer?.averageOrderValue}
                                    </p>
                                    <p>Average order value</p>
                                </div>
                            </Card>
                            <Card
                                className="salesCard mb-4"
                                style={{
                                    borderRadius: '10px',
                                    backgroundColor: '#E8EBEE',
                                    textAlign: 'center',
                                    height: '130px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <div>
                                    <p
                                        style={{
                                            fontSize: '24px',
                                            color: '#0A2938',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {retailer?.totalSalesValue}
                                    </p>
                                    <p>Total Sales</p>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Index;
