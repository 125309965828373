// import dashboard from './dashboard';
import mychannelpartnerfleet from './mychannelpartnerfleet';
import settings from './mychannelpartnerfleetsettings';

// ==============================|| MENU ITEMS dashboard ||============================== //

const mychannelpartnerfleetItems = {
    items: [mychannelpartnerfleet, settings]
};

export default mychannelpartnerfleetItems;
