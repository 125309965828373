// timer.js
export const timeAgo = (timestamp) => {
    const now = new Date();
    const messageTime = new Date(timestamp);
    const diffInSeconds = Math.floor((now - messageTime) / 1000);

    const timeIntervals = {
        minute: 60,
        hour: 3600,
        day: 86400
    };

    if (diffInSeconds < timeIntervals.minute) {
        return `${diffInSeconds} seconds ago`;
    }
    if (diffInSeconds < timeIntervals.hour) {
        const minutes = Math.floor(diffInSeconds / timeIntervals.minute);
        return `${minutes} min ago`;
    }
    if (diffInSeconds < timeIntervals.day) {
        const hours = Math.floor(diffInSeconds / timeIntervals.hour);
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    }
    const days = Math.floor(diffInSeconds / timeIntervals.day);
    return `${days} day${days > 1 ? 's' : ''} ago`;
};

export default timeAgo;
