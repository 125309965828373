/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import { useState, useEffect } from 'react';
// import { makeStyles } from '@mui/styles';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import useScriptRef from 'hooks/useScriptRef';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import Button from '@mui/material/Button';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';

// const useStyles = makeStyles(() => ({
//     tableHeaderCell: {
//         fontWeight: 'bold'
//     },
//     item: {
//         padding: 5
//     }
// }));

const UpdateInventory = () => {
    // const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const { id } = useParams();
    const [parts, setParts] = useState([]);
    const [partId, setpartId] = useState('');
    const [partName, setpartName] = useState('');
    const [qty, setqty] = useState('');
    const [stockDate, setstockDate] = useState(new Date());
    const [source, setsource] = useState('');
    const [status, setstatus] = useState('');
    const [invHoldingDays, setinvHoldingDays] = useState('');
    const [supplier, setsupplier] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    // const theme = useTheme();

    const retrieveAllPart = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(ApiUrl.ActivePartUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setParts(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {});
        } catch (e) {
            console.log(e);
        }
    };

    const handleStockDateChange = (newValue) => {
        setstockDate(newValue);
    };

    const handleStockChange = (event) => {
        setstatus(event.target.value);
    };

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        fetch(ApiUrl.ViewInventoryUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setpartId(result?.data?.partId?._id ?? '');
                    setpartName(result?.data?.partId?.sku ?? '');
                    setqty(result?.data?.qty ?? '');
                    setstockDate(result?.data?.stockDate ?? '');
                    setsource(result?.data?.source ?? '');
                    setstatus(result?.data?.status ?? '');
                    setinvHoldingDays(result?.data?.invHoldingDays ?? '');
                    setsupplier(result?.data?._id ?? '');

                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading2(false);
            });
        retrieveAllPart();
    }, [id]);

    // Submit Order
    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        const data = {
            id,
            partId,
            partName,
            qty,
            stockDate,
            source,
            status,
            invHoldingDays,
            supplier
        };

        fetch(ApiUrl.UpdateInventoryUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/inventory');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const filterpartId = (event) => {
        const newFilter = parts.filter((value) => (value.sku || '').toLowerCase().includes((event || '').toLowerCase()));
        newFilter.forEach((option) => {
            setpartId(option?._id);
            setsupplier(option?.distributor?._id ?? '');
        });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <PageHeader title="Inventory" subTitle="Home | Edit Inventory" />
                    <MainCard title="">
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={partName}
                                            required
                                            onChange={(event, newValue) => {
                                                setpartName(newValue);
                                                filterpartId(newValue);
                                            }}
                                            options={parts.map((option) => option.sku)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Sku Name" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            name="qty"
                                            variant="outlined"
                                            required
                                            id="qty"
                                            label="Quantity"
                                            value={qty}
                                            onChange={(e) => setqty(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            {/* <Stack spacing={1}> */}
                                            <DesktopDatePicker
                                                label="In Stock date"
                                                inputFormat="MM/dd/yyyy"
                                                value={stockDate}
                                                onChange={handleStockDateChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            {/* </Stack> */}
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="source"
                                            variant="outlined"
                                            required
                                            id="source"
                                            label="Source"
                                            value={source}
                                            onChange={(e) => setsource(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={status}
                                                label="Status"
                                                onChange={handleStockChange}
                                            >
                                                <MenuItem value="Body">Select an option</MenuItem>
                                                <MenuItem value="InStock">In Stock</MenuItem>
                                                <MenuItem value="OutOFStock">Out Of Stock</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            name="invHoldingDays"
                                            variant="outlined"
                                            required
                                            id="invHoldingDays"
                                            label="Holding Days"
                                            value={invHoldingDays}
                                            onChange={(e) => setinvHoldingDays(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                            <br />
                            <Box display="flex" justifyContent="space-between">
                                <Button component={Link} to="/inventory" type="submit" variant="contained" color="error">
                                    Reset
                                </Button>
                                <Button type="submit" variant="contained" color="secondary">
                                    {loading ? 'Updating ..' : 'Update'}
                                </Button>
                            </Box>
                        </form>
                    </MainCard>
                </>
            )}
        </>
    );
};
export default UpdateInventory;
