import { Table, Divider, Paper, Space, Select, Spin, DatePicker, Modal, Input, Dropdown, Popover, Button } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LoadingOutlined, DownloadOutlined, FileAddOutlined } from '@ant-design/icons';
import Avatar from '@mui/material/Avatar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/material/styles';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useAppContext } from '../../context/appContext';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import './rmaTable.css';
import React, { useEffect, useState } from 'react';
import RmaBulkModal from './RmaBulkModal';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ApiUrl from 'network';
import IconButton from '@mui/material/IconButton';

const RmaOrderTable = ({
    rmaOrders,
    loading,
    handleEditClick,
    showModal,
    showReceiptUpdateModal,
    filterDate,
    filterPartner,
    selectedDate,
    handleReceiptClick,
    handlePdfClick,
    handleIssueClick,
    handleLimit,
    limitOptions,
    limit,
    handleShowClick,
    useStyles,
    setGetOrderId,
    setGetOrderNo,
    collections,
    confirmedList,
    setUpdatReceiptBulkSuccess,
    updateReceiptBulkSuccess,
    handlePage,
    tableParams
}) => {
    const [columnWidth, setColumnWidth] = useState('100px');
    const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
    const [orderModal, setOrderModal] = useState(false);
    const [orderDetails, setOrderDetails] = useState([]);

    // bulk modal
    const showBulkeModal = () => {
        setIsBulkModalOpen(true);
    };

    const handleBulkOk = () => {
        setIsBulkModalOpen(false);
    };

    const handleBulkCancel = () => {
        setIsBulkModalOpen(false);
    };

    useEffect(() => {
        const handleResize = () => {
            // Adjust the column width based on the window width
            const newWidth = window.innerWidth > 1200 ? '200px' : '100px';
            setColumnWidth(newWidth);
        };

        // Initial setup
        handleResize();

        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54
            }}
            spin
        />
    );

    const { RangePicker } = DatePicker;

    const navigate = useNavigate();

    const theme = useTheme();
    const classes = useStyles();

    const dateConverter = (value) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };

        return new Date(value).toLocaleDateString('en-US', options);
    };

    const userRole = localStorage.getItem('userRole');

    // 2 decimal places
    const twoDecimalPlaces = (value) => {
        return parseFloat(value?.toFixed(2)).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    };

    const collection = collections.map((collection) => {
        return collection?.country === 'Ghana' ? collection?.orderIdGh?._id : collection?.orderIdNg?._id;
    });

    const approvedList = confirmedList.map((collection) => {
        return collection?.country === 'Ghana' ? collection?.orderIdGh?._id : collection?.orderIdNg?._id;
    });

    const columns = [
        {
            title: '#',
            dataIndex: '#',
            key: 'id',

            render: (id, record, index) => {
                return index + 1;
            },
            width: '30px'
        },

        {
            title: 'Order Date',
            dataIndex: 'orderDate',
            key: 'orderDate',
            width: columnWidth
        },
        {
            title: 'Order No',
            dataIndex: 'orderNo',
            key: 'orderNo',
            width: columnWidth
        },
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'customerName',
            width: columnWidth
        },
        {
            title: 'Sales Value',
            dataIndex: 'salesvalue',
            key: 'salesvalue',
            width: '100px'
        },
        {
            title: 'Cash Collected',
            dataIndex: 'cashcollected',
            key: 'cashcollected',
            width: '100px'
        },

        {
            title: 'Cash OutStanding',
            dataIndex: 'cashoutStanding',
            key: 'cashoutStanding',
            width: columnWidth
        },
        {
            title: 'Order Status',
            dataIndex: 'orderStatus',
            key: 'orderStatus',
            width: '170px'
        },
        {
            title: 'Receipt Status',
            dataIndex: 'receiptStatus',
            key: 'receiptStatus',
            width: '170px'
        },

        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: '150px'
        }
    ];

    const oderDetails = rmaOrders?.map((result) => ({
        key: result?._id,
        orderType: result?.orderType === 'Bulk' ? 'Regular' : result?.orderType,
        orderDate: dateConverter(result?.orderDate),
        customerName: result?.channelPartnerId,
        salesPerson: result?.salesTeamId,
        orderNo: result?.orderNo,
        cashoutStanding: result?.cashOutStanding ? twoDecimalPlaces(result?.cashOutStanding) : twoDecimalPlaces(result.expCashCollected),
        cashcollected: twoDecimalPlaces(result?.actCashCollected),
        salesvalue: twoDecimalPlaces(result?.expCashCollected),
        orderStatus:
            result?.fulStatus === 'Delivered' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.success.light,
                        color: theme.palette.success.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.fulStatus ?? ''}</Typography>
                </Avatar>
            ) : result?.fulStatus === 'Cancelled' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.error.light,
                        color: theme.palette.error.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <DangerousRoundedIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.fulStatus ?? ''}</Typography>
                </Avatar>
            ) : result?.fulStatus === 'Received' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <RemoveCircleIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.fulStatus ?? ''}</Typography>
                </Avatar>
            ) : result?.fulStatus === 'Dispatched' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.warning.light,
                        color: theme.palette.warning.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <RemoveCircleIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.fulStatus ?? ''}</Typography>
                </Avatar>
            ) : result?.fulStatus === 'Processing' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.warning.light,
                        color: theme.palette.warning.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.fulStatus ?? ''}</Typography>
                </Avatar>
            ) : result?.fulStatus === 'ProformaInvoice' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <RemoveCircleIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.fulStatus ?? ''}</Typography>
                </Avatar>
            ) : result?.fulStatus === 'SalesDrive' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <RemoveCircleIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.fulStatus ?? ''}</Typography>
                </Avatar>
            ) : result?.fulStatus === 'OnHold' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <RemoveCircleIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.fulStatus ?? ''}</Typography>
                </Avatar>
            ) : result?.fulStatus === 'NextDay' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <RemoveCircleIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.fulStatus ?? ''}</Typography>
                </Avatar>
            ) : result?.fulStatus === 'PreSold' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <RemoveCircleIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.fulStatus ?? ''}</Typography>
                </Avatar>
            ) : (
                ''
            ),
        receiptStatus:
            result?.newOrderStatusId?._id === '6228632de5b218f4be037d70' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.success.light,
                        color: theme.palette.success.dark,
                        width: 155,
                        height: 40,
                        padding: 2
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.newOrderStatusId?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.newOrderStatusId?._id === '622b9bf0f3bcbc9eb0c203ea' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.error.light,
                        color: theme.palette.error.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <DangerousRoundedIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.newOrderStatusId?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.newOrderStatusId?._id === '620d76d63a9c8148935c696b' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <RemoveCircleIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.newOrderStatusId?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.newOrderStatusId?._id === '625311abbd74072935e58592' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <RemoveCircleIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.newOrderStatusId?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.newOrderStatusId?._id === '625739679a0af3ae9771daef' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.error.light,
                        color: theme.palette.error.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <DangerousRoundedIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.newOrderStatusId?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.newOrderStatusId?._id === '622b9bcdf3bcbc9eb0c203e2' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.warning.light,
                        color: theme.palette.warning.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.newOrderStatusId?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.newOrderStatusId?._id === '6334bab7afa1158e678a87ad' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.newOrderStatusId?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.newOrderStatusId?._id === '6342dc27f627d58acac98e0f' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.newOrderStatusId?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.newOrderStatusId?._id === '636c1abb26ac75c9880bddd6' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.newOrderStatusId?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.newOrderStatusId?._id === '636c1ab126ac75c9880bddd2' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.newOrderStatusId?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.newOrderStatusId?._id === '6466771d8328e2d254fa6ac3' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.newOrderStatusId?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.newOrderStatusId?._id === '651da6eb9d048db5123bc205' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.newOrderStatusId?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.newOrderStatusId?._id === '6527fe1dba6c46cb61943f5c' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.newOrderStatusId?.name ?? ''}</Typography>
                </Avatar>
            ) : (
                ''
            ),

        action: (
            <Popover
                placement="leftTop"
                trigger="click"
                arrow="Hide"
                id="popover"
                content={
                    <Box
                        sx={{
                            width: '235px',
                            padding: '12px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        {!collection.includes(result?._id) ? (
                            <Typography
                                onClick={() => {
                                    showModal();
                                    setGetOrderId(result?._id);
                                    setGetOrderNo(result?.orderNo);
                                }}
                                sx={{
                                    borderBottom: '1px solid #ECEDEF',
                                    height: '40px',
                                    width: '100%',
                                    padding: '10px 12px',
                                    color: '#ff740f',
                                    fontWight: '500',
                                    borderRadius: '8px 8px 0 0',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    margin: '10px 0'
                                }}
                                className="rma-action"
                            >
                                Add payment <KeyboardArrowRightIcon sx={{ width: '16px' }} />
                            </Typography>
                        ) : null}
                        {collection.includes(result?._id) ? (
                            <Typography
                                onClick={() => {
                                    showReceiptUpdateModal();
                                    setGetOrderId(result?._id);
                                    setGetOrderNo(result?.orderNo);
                                }}
                                sx={{
                                    borderBottom: '1px solid #ECEDEF',
                                    height: '40px',
                                    width: '100%',
                                    padding: '10px 12px',
                                    borderRadius: '8px 8px 0 0',
                                    color: '#ff740f',
                                    fontWight: '500',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    margin: '10px 0'
                                }}
                                className="rma-action"
                            >
                                Edit payment <KeyboardArrowRightIcon sx={{ width: '16px' }} />
                            </Typography>
                        ) : null}
                        <Typography
                            onClick={() => handlePdfClick(result)}
                            sx={{
                                borderBottom: '1px solid #ECEDEF',
                                height: '40px',
                                width: '100%',
                                padding: '10px 12px',
                                borderRadius: '8px 8px 0 0',
                                color: '#ff740f',
                                fontWight: '500',
                                textAlign: 'center',
                                cursor: 'pointer',
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                margin: '10px 0'
                            }}
                            className="rma-action"
                        >
                            Receipt <KeyboardArrowRightIcon sx={{ width: '16px' }} />
                        </Typography>
                        <Typography
                            onClick={() => handleIssueClick(result)}
                            sx={{
                                borderBottom: '1px solid #ECEDEF',
                                height: '40px',
                                width: '100%',
                                padding: '10px 12px',
                                color: '#ff740f',
                                fontWight: '500',
                                textAlign: 'center',
                                borderRadius: '8px 8px 0 0',
                                cursor: 'pointer',
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                margin: '10px 0'
                            }}
                            className="rma-action"
                        >
                            Issue <KeyboardArrowRightIcon sx={{ width: '16px' }} />
                        </Typography>
                        <Typography
                            onClick={() => handleShowClick(result)}
                            sx={{
                                borderBottom: '1px solid #ECEDEF',
                                height: '40px',
                                width: '100%',
                                padding: '10px 12px',
                                color: '#ff740f',
                                fontWight: '500',
                                textAlign: 'center',
                                borderRadius: '8px 8px 0 0',
                                cursor: 'pointer',
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                margin: '10px 0'
                            }}
                            className="rma-action"
                        >
                            Preview <KeyboardArrowRightIcon sx={{ width: '16px' }} />
                        </Typography>
                        {/* <Typography
                            onClick={() => handleEditClick(result)}
                            sx={{
                                borderBottom: '1px solid #ECEDEF',
                                height: '40px',
                                width: '100%',
                                padding: '10px 12px',
                                color: '#ff740f',
                                fontWight: '500',
                                textAlign: 'center',
                                borderRadius: '8px 8px 0 0',
                                cursor: 'pointer',
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                margin: '10px 0'
                            }}
                            className="rma-action"
                        >
                            Update <KeyboardArrowRightIcon sx={{ width: '16px' }} />
                        </Typography> */}
                        <Typography
                            onClick={() => (window.location.href = `/epl/cc-rma-request-add/${result?._id}`)}
                            sx={{
                                borderBottom: '1px solid #ECEDEF',
                                height: '40px',
                                width: '100%',
                                padding: '10px 12px',
                                color: '#ff740f',
                                fontWight: '500',
                                textAlign: 'center',
                                borderRadius: '8px 8px 0 0',
                                cursor: 'pointer',
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                margin: '10px 0'
                            }}
                            className="rma-action"
                        >
                            Epl Request <KeyboardArrowRightIcon sx={{ width: '16px' }} />
                        </Typography>
                        <Typography
                            sx={{
                                borderBottom: '1px solid #ECEDEF',
                                height: '40px',
                                width: '100%',
                                padding: '10px 12px',
                                color: '#ff740f',
                                fontWight: '500',
                                textAlign: 'center',
                                borderRadius: '8px 8px 0 0',
                                cursor: 'pointer',
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                margin: '10px 0'
                            }}
                            onClick={() => (window.location.href = `/payment-initiation/${result?._id}`)}
                        >
                            Payment Initiation <KeyboardArrowRightIcon sx={{ width: '16px' }} />
                        </Typography>
                        <Typography
                            onClick={() => {
                                document.getElementById('popover').style.display = 'none';
                                setOrderDetails(result.imageUploads);
                                setOrderModal(true);
                            }}
                            sx={{
                                borderBottom: '1px solid #ECEDEF',
                                height: '40px',
                                width: '100%',
                                padding: '10px 12px',
                                color: '#ff740f',
                                fontWight: '500',
                                textAlign: 'center',
                                borderRadius: '8px 8px 0 0',
                                cursor: 'pointer',
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                margin: '10px 0'
                            }}
                            className="rma-action"
                        >
                            Order Image <KeyboardArrowRightIcon sx={{ width: '16px' }} />
                        </Typography>
                    </Box>
                }
            >
                <Typography
                    sx={{
                        border: '1px solid #FFD5B7',
                        height: '40px',
                        width: '133px',
                        padding: '10px 12px',
                        borderRadius: '8px',
                        color: '#3B5460',
                        fontWight: '500',
                        textAlign: 'center',
                        cursor: 'pointer',
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        margin: '10px 0'
                    }}
                >
                    View actions <KeyboardArrowDownIcon sx={{ width: '16px' }} />
                </Typography>
            </Popover>
        )
    }));

    // download order list
    const { handleExcelDataExport } = useAppContext();
    const downloadExcel = () => {
        const orderFile = oderDetails.map((obj) => {
            return Object.fromEntries(Object.entries(obj).filter(([key]) => key !== 'key' && key !== 'value' && key !== 'action'));
        });

        if (orderFile?.length === 0) return;

        handleExcelDataExport(orderFile, 'Order');
    };

    return (
        <div>
            {' '}
            <Table
                loading={loading ? { indicator: <Spin indicator={antIcon} />, spinning: true / false } : ''}
                columns={columns}
                dataSource={oderDetails}
                pagination={tableParams.pagination}
                onChange={handlePage}
                size="middle"
                scroll={{
                    x: 'calc(700px + 50%)',
                    y: 580
                }}
                title={() => (
                    <>
                        <Space
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                width: '100%'
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    width: '100%'
                                }}
                            >
                                <Box
                                    sx={{
                                        width: ' 277px',
                                        margin: '24px 10px 0 0px'
                                    }}
                                >
                                    <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Search by Date</Typography>
                                    <RangePicker
                                        format="YYYY-MM-DD"
                                        style={{
                                            width: '100%',
                                            border: '1px solid #E7EAEB'
                                        }}
                                        size="large"
                                        value={selectedDate}
                                        onChange={filterDate}
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        width: ' 277px',
                                        margin: '24px 10px 0 0px'
                                    }}
                                >
                                    <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>
                                        Search Partner Name
                                    </Typography>

                                    <Input
                                        style={{
                                            width: '100%',
                                            border: '1px solid #E7EAEB'
                                        }}
                                        size="large"
                                        placeholder="Partner Name"
                                        onChange={(e) => filterPartner(e.target.value.toLocaleUpperCase())}
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        width: ' 277px',
                                        margin: '24px 10px 0 0px'
                                    }}
                                >
                                    <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Limit</Typography>

                                    <Select
                                        style={{
                                            width: '100%'
                                        }}
                                        size="large"
                                        placeholder="Select limit"
                                        options={limitOptions}
                                        onChange={(value) => handleLimit(value)}
                                        filterOption={(inputValue, option) => {
                                            if (typeof option.label === 'string') {
                                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }

                                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }}
                                        showSearch
                                        allowClear
                                    />
                                </Box>
                                {/* <Box
                                    sx={{
                                        width: ' 277px',
                                        margin: '24px 10px 0 0px'
                                    }}
                                >
                                    <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Payment Proof</Typography>

                                    <Select
                                        style={{
                                            width: '100%'
                                        }}
                                        size="large"
                                        placeholder="Payment Proof"
                                        options={[
                                            {
                                                value: 'Complete Payment',
                                                label: 'Complete Payment'
                                            },
                                            {
                                                value: 'InComplete Payment',
                                                label: 'InComplete Payment'
                                            },
                                            {
                                                value: 'Without Payment',
                                                label: 'Without Payment'
                                            }
                                        ]}
                                        onChange={(value) => handleLimit(value)}
                                        filterOption={(inputValue, option) => {
                                            if (typeof option.label === 'string') {
                                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }

                                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }}
                                        showSearch
                                        allowClear
                                    />
                                </Box> */}
                            </Box>

                            <Button
                                type="primary"
                                size="large"
                                style={{
                                    backgroundColor: '#4B6EFF',
                                    borderRadius: '12px',
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    color: '#fff',
                                    width: '222px',
                                    border: '1px solid #4B6EFF',
                                    margin: '44px 0 0 0px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                onClick={() => {
                                    navigate(`/order-request-form`);
                                }}
                            >
                                New parts request form
                            </Button>
                            <Button
                                type="primary"
                                size="large"
                                style={{
                                    backgroundColor: '#FF740F',
                                    borderRadius: '12px',
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    color: '#fff',
                                    width: '222px',
                                    border: '1px solid #FF740F',
                                    margin: '44px 0 0 0px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                onClick={() => {
                                    showBulkeModal();
                                }}
                            >
                                Add bulk payment
                            </Button>
                        </Space>
                    </>
                )}
            />
            <RmaBulkModal
                handleBulkOk={handleBulkOk}
                handleBulkCancel={handleBulkCancel}
                isBulkModalOpen={isBulkModalOpen}
                confirmedList={confirmedList}
                rmaOrders={rmaOrders}
                setUpdatReceiptBulkSuccess={setUpdatReceiptBulkSuccess}
                updateReceiptBulkSuccess={updateReceiptBulkSuccess}
            />
            <Modal
                title="Order Image"
                open={orderModal}
                onCancel={() => {
                    document.getElementById('popover').style.display = 'block';
                    setOrderModal(false);
                }}
                width={400}
                footer={null}
            >
                <div>
                    {orderDetails.map((item) => (
                        <div
                            key={item._id}
                            style={{
                                marginBottom: '20px',
                                padding: '10px',
                                border: '1px solid #ECEDEF',
                                borderRadius: '8px'
                            }}
                        >
                            <div className="mb-4" style={{ border: '1px solid #ECEDEF', padding: '10px', borderRadius: '8px' }}>
                                <Typography
                                    variant="h6"
                                    style={{
                                        color: '#546974',
                                        fontSize: '14px'
                                    }}
                                >
                                    <span style={{ color: '9B979E' }}> Captured By: </span>
                                    <span style={{ fontSize: '16px', color: '#FF740F' }}>{item?.capturedUser}</span>
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        borderRadius: '5px',
                                        fontSize: '14px'
                                    }}
                                >
                                    Captured Date:{' '}
                                    {new Date(item?.capturedDate).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    })}
                                </Typography>
                            </div>
                            <img
                                src={item?.imgFile}
                                alt="Order"
                                style={{
                                    width: '100%',
                                    borderRadius: '8px',
                                    height: '200px',
                                    objectFit: 'cover',
                                    border: '1px solid #ECEDEF'
                                }}
                            />
                        </div>
                    ))}
                    {orderDetails.length === 0 && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '200px',
                                border: '1px solid #ECEDEF',
                                borderRadius: '8px'
                            }}
                        >
                            <Typography
                                variant="h6"
                                style={{
                                    color: '#546974',
                                    fontSize: '14px'
                                }}
                            >
                                No Order Image found
                            </Typography>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default RmaOrderTable;
