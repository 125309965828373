import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
// import { notification } from 'antd';
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import useScriptRef from 'hooks/useScriptRef';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';

const EditCollectionEmail = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [status, setStatus] = useState('');
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState('');
    const history = useNavigate();
    const scriptedRef = useScriptRef();

    const handleChange = (event) => {
        setStatus(event.target.value);
    };
    const { id } = useParams();

    const retrieveAllActiveCountry = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            // setLoading(true);
            fetch(ApiUrl.ActiveCountryUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        retrieveAllActiveCountry();
        setLoading2(true);
        fetch(ApiUrl.ViewCollectionEmailUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setEmail(result?.data?.email ?? '');
                    setStatus(result?.data?.status ?? '');
                    setCountry(result?.data?.country ?? '');
                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                }
            })
            .finally(() => {
                setLoading2(false);
            });
    }, [id]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');

        const data = {
            id,
            email,
            status,
            country
        };
        setLoading(true);
        fetch(ApiUrl.UpdateCollectionEmail, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading(false);
                    setTimeout(() => {
                        if (scriptedRef.current) {
                            history('/collectionemail');
                        }
                    }, 3000);
                } else {
                    toast.error(result.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <br />
                            <PageHeader title="Collection Emails" subTitle="Home | Configuration | Setup  | Collection Email" />
                            <br />
                            <MainCard title="Edit Collection Email">
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        autoComplete="off"
                                        name="email"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="email"
                                        value={email}
                                        label="Email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        autoFocus
                                    />
                                    <br />
                                    <br />

                                    <Autocomplete
                                        id="country-select-demo"
                                        autoHighlight
                                        value={country}
                                        onChange={(event, newValue) => {
                                            setCountry(newValue);
                                        }}
                                        options={countries.map((option) => option.name)}
                                        isOptionEqualToValue={(option, value) => option.code === value.code}
                                        renderInput={(params) => <TextField {...params} label="Choose a country" />}
                                    />

                                    <br />
                                    <br />

                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={status}
                                            label="Status"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="Active">Active</MenuItem>
                                            <MenuItem value="InActive">InActive</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <br />
                                    <br />
                                    <Box display="flex" justifyContent="space-between">
                                        <Button component={Link} to="/collectionemail" type="submit" variant="contained" color="error">
                                            Reset
                                        </Button>

                                        <Button type="submit" variant="contained" color="secondary" disabled={!email}>
                                            {loading ? 'Updating ..' : 'Update'}
                                        </Button>
                                    </Box>
                                </form>
                            </MainCard>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};
export default EditCollectionEmail;
