import React, { useEffect, useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { decrypt, encrypt } from '../../../../utils/encrypt';
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from 'antd';
import JsPDF from 'jspdf';
import { renderToString } from 'react-dom/server';
import html2pdf from 'html2pdf.js';
import * as XLSX from 'xlsx';
import ApiUrl from '../../../../network';

export const Index = () => {
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const userRole = localStorage.getItem('userRole');
    const userFirstName = localStorage.getItem('userFirstName');
    const userLastName = localStorage.getItem('userLastName');
    const [order, setOrder] = useState({});
    const { id } = useParams();
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    const fetchOrder = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/waybill/viewwaybill/${id}`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setOrder(result);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        toast.error(error.message);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    const signWaybill = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            let signature = '';

            if (['Sales', 'shu', 'DataEntry', 'CustomerSuccess', 'Admin', 'fulfillment'].includes(userRole)) {
                signature = `${userFirstName} ${userLastName}`;
            }

            const datas = {
                waybillId: id,
                rma: userRole === 'Sales' ? signature : '',
                shu: userRole === 'shu' ? signature : '',
                inventoryManager: userRole === 'inventoryManager' ? signature : '',
                cc: userRole === 'CustomerSuccess' ? signature : '',
                dataentry: userRole === 'DataEntry' ? signature : '',
                admin: userRole === 'Admin' ? signature : '',
                fulfillment: userRole === 'fulfillment' ? signature : ''
            };

            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            await fetch(`${ApiUrl.BaseUrl}/waybill/signwaybill`, {
                method: 'PUT',
                headers: config.headers,
                body: JSON.stringify({ payload })
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        toast.success(data.message);
                        fetchOrder();
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        toast.error(error);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        if (id) {
            fetchOrder();
        } else {
            window.location.href = '/waybill';
            toast.error('No order found');
        }
    }, []);

    const printHTML = (id) => {
        const content = document.getElementById(id).innerHTML;
        const mywindow = window.open('', 'Print', 'height=600,width=800');
        mywindow.document.write('<html><head><title>Print</title>');
        mywindow.document.write('<style>@media print{body{width: 297mm;height: 420mm;}}</style>');
        mywindow.document.write('</head><body>');
        mywindow.document.write('<link rel="stylesheet" type="text/css" href="./salesplan.css" media="print">');
        mywindow.document.write(content);
        mywindow.document.write('</body></html>');
        mywindow.document.close();
        mywindow.focus();
        mywindow.print();
        mywindow.close();
    };

    const generatePDF = () => {
        const element = document.getElementById('printContainer');
        const img = document.getElementById('logo');
        img.style.width = '150px';
        img.style.height = '50px';
        img.style.objectFit = 'contain';
        img.style.marginBottom = '20px';
        img.style.marginTop = '20px';
        html2pdf(element, {
            margin: 1,
            filename: 'waybill.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { dpi: 192, letterRendering: true },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        });
    };

    const exportExcelHandler = () => {
        const ordersDetails = order?.ordersDetails;
        if (ordersDetails && ordersDetails.length > 0) {
            // Create a new workbook
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(ordersDetails);
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, 'waybill.xlsx');
        } else {
            toast.error('No order found');
        }
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Link to="/generated/waybill" className="createScheduleLink">
                    <p style={{ fontSize: '25px', marginLeft: '10px' }}>
                        <ArrowBackIcon sx={{ fontSize: '25px', color: '#000' }} /> Waybill
                    </p>
                </Link>

                {(order.rmakey || order.cckey) &&
                    !order.shuKey &&
                    (order.adminkey || order.dataentrykey || order.inventorykey || order.fulfillmentkey) && (
                        <div className="d-flex align-items-center">
                            <Button onClick={generatePDF} style={{ marginLeft: '10px', backgroundColor: '#FF740F', color: '#fff' }}>
                                Export as PDF
                            </Button>
                            <Button onClick={exportExcelHandler} style={{ marginLeft: '10px', backgroundColor: '#193B56', color: '#fff' }}>
                                Export as Excel sheet
                            </Button>
                        </div>
                    )}
            </div>
            <div id="printContainer" className="printwayContainer">
                <div className="bg-white">
                    <div className="text-center">
                        <img
                            id="logo"
                            src="/static/webLogo.png"
                            alt="logo"
                            style={{
                                width: '150px',
                                height: '150px',
                                objectFit: 'contain'
                            }}
                        />
                    </div>
                    <div>
                        <p className="text-center" style={{ fontSize: '20px' }}>
                            WAYBILL
                        </p>
                        <div>
                            <div className="address">
                                <div>
                                    <p style={{ fontSize: '12px' }}>
                                        Date : {new Date(order?.waybillDate).toLocaleDateString('en-US', options)}
                                    </p>
                                    <p style={{ fontSize: '12px' }}>Order Type : {order?.orderType}</p>
                                    <p style={{ fontSize: '12px' }}> Name : {order?.rmaName}</p>
                                    <p style={{ fontSize: '12px' }}>Driver : {order?.driverName}</p>
                                    <p style={{ fontSize: '12px' }}>Pickup Location : {order?.pickupName}</p>
                                </div>
                                <div>
                                    {localStorage.country === 'Ghana' ? (
                                        <div>
                                            <p style={{ fontSize: '12px' }}>Address</p>
                                            <p style={{ fontSize: '12px' }}>12 Calctus St, Tse Addo</p>
                                            <p style={{ fontSize: '12px' }}>Behind Queens Court Event Center</p>
                                            <p style={{ fontSize: '12px' }}>Accra-Ghana</p>
                                        </div>
                                    ) : (
                                        <div>
                                            <p style={{ fontSize: '12px' }}>Address</p>
                                            <p style={{ fontSize: '12px' }}>89, Obafemi Awolowo way</p>
                                            <p style={{ fontSize: '12px' }}>beside Access bank, Ikeja, Lagos.</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="p-4">
                            <table className="table table-bordered table-responsive table-sm">
                                <thead className="">
                                    <tr style={{ fontSize: '14px', padding: '10px' }}>
                                        <th>ITEMS</th>
                                        <th>QUANTITY</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {order?.ordersDetails?.map((item) => (
                                        <tr key={item._id} style={{ fontSize: '14px', padding: '20px' }}>
                                            <td>{item.partid}</td>
                                            <td>{item.qty} PCS</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="signPart">
                        <div>
                            {!order?.inventorykey && !order?.dataentrykey && !order?.adminkey && !order?.fulfillmentkey ? (
                                <div>
                                    <Button
                                        style={{ color: '#FF740F', border: '1px solid #FF740F' }}
                                        className={
                                            userRole === 'Sales' || userRole === 'CustomerSuccess' ? 'disabledButton' : 'activeButton'
                                        }
                                        onClick={userRole === 'Sales' ? null : signWaybill}
                                        disabled={userRole === 'Sales' && userRole === 'CustomerSuccess'}
                                    >
                                        Sign here
                                    </Button>
                                    {!(order?.adminkey || order?.dataentrykey || order?.inventorykey) && (
                                        <p className="mt-2">
                                            {userRole === 'DataEntry'
                                                ? "Data Entry's signature"
                                                : userRole === 'InventoryManager'
                                                ? 'Inventory’s manager signature'
                                                : userRole === 'fulfillment'
                                                ? "Fulfillment's signature"
                                                : userRole === 'Admin'
                                                ? "Admin's signature"
                                                : ''}
                                        </p>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    <Button
                                        style={{ color: '#193B56', border: '1px solid #E8EBEE' }}
                                        className="signedButton"
                                        disabled={
                                            userRole === 'Sales' ||
                                            userRole === 'CustomerSuccess' ||
                                            order?.inventorySigned ||
                                            order?.dataentrySigned ||
                                            order?.adminSigned ||
                                            order?.fulfillmentSigned
                                        }
                                    >
                                        Signed by{' '}
                                        {order?.inventorySigned || order?.dataentrySigned || order?.adminSigned || order?.fulfillmentSigned}
                                    </Button>
                                    {(order?.adminkey || order?.dataentrykey || order?.inventorykey || order?.fulfillmentkey) && (
                                        <p className="mt-2">
                                            {order.dataentrySigned ? `Data entry's signature` : ''}
                                            {order.adminSigned ? `Admin's signature` : ''}
                                            {order.inventorySigned ? `Inventory's signature` : ''}
                                            {order.fulfillmentSigned ? `Fulfillment's signature` : ''}
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>

                        <div>
                            {!order?.rmakey && !order?.shuKey && !order?.cckey ? (
                                <div>
                                    <Button
                                        style={{ color: '#FF740F', border: '1px solid #FF740F' }}
                                        className={
                                            userRole === 'Sales' || userRole === 'CustomerSuccess' ? 'activeButton' : 'disabledButton'
                                        }
                                        onClick={userRole === 'Sales' || userRole === 'CustomerSuccess' ? signWaybill : null}
                                        disabled={userRole !== 'Sales' && userRole !== 'CustomerSuccess'}
                                    >
                                        Awaiting signature
                                    </Button>
                                    {!(order?.rmakey || order?.shuKey) && (
                                        <p className="mt-2">
                                            {userRole === 'Sales' && userRole === 'CustomerSuccess' ? 'RMA’s signature' : 'CC’s signature'}
                                        </p>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    <Button
                                        disabled={
                                            userRole !== 'Sales' || userRole !== 'CustomerSuccess' || order?.rmaSigned || order?.shuSigned
                                        }
                                        style={{ color: '#193B56', border: '1px solid #E8EBEE' }}
                                        className="signedButton"
                                    >
                                        Signed by {order?.rmaSigned || order?.ccSigned || order?.shuSigned}
                                    </Button>
                                    {(order?.rmakey || order?.cckey || order?.shuKey) && (
                                        <p className="mt-2">
                                            {order.rmaSigned ? `RMA's signature` : ''}
                                            {order.ccSigned ? `CC's signature` : ''}
                                            {order.shuSigned ? `SHU's signature` : ''}
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="receiptFooter">
                        <div className="ml-2 mt-2">
                            {localStorage.country === 'Ghana' ? (
                                <div>
                                    <p>12 Calctus St, Tse Addo</p>
                                    <p>Behind Queens Court Event Center</p>
                                </div>
                            ) : (
                                <div>
                                    <p>89,Obafemi Awolowo way, Trinity.</p>
                                    <p>Mall, Ikeja, Lagos.</p>
                                </div>
                            )}
                        </div>
                        <div className="mt-2 d-flex align-items-center">
                            <p>Powered by</p>
                            <img
                                src="/static/webLogo.png"
                                alt="logo"
                                style={{ width: '50px', height: '30px', objectFit: 'contain', marginLeft: '4px' }}
                            />
                        </div>
                        <div className="mr-2 mt-2">
                            <p>+233(0) 200 235 277</p>
                            <p>www.garagemobility.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Index;
