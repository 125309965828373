import styled from 'styled-components';

const BatchCardWrapper = styled.div`
    .batch-card {
        background: #ff740f;
        text-align: center;
        .card-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0.875rem 2rem;

            .card-title {
                font-size: 2.2rem;
                font-weight: 900;
                font-family: 'Roboto';
            }

            .card-text {
                text-transform: capitalize;
                white-space: nowrap;
                font-weight: 600;
                font-size: 1rem;
                font-family: 'Roboto';
            }
        }
    }
`;

export default BatchCardWrapper;
