import { useState, useRef } from 'react';

const useChatState = () => {
    // Chat-related states
    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState(null);
    const [loading, setLoading] = useState(false);
    const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
    const [receiptLoading, setReceiptLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [socket, setSocket] = useState(null);
    const [messageType, setMessageType] = useState('text');
    const [imageUrl, setImageUrl] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [replyTo, setReplyTo] = useState({
        _id: null,
        profile: null,
        content: null,
        whatsapp_id: null
    });
    const [switchChat, setSwitchChat] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState({});
    const chatBoxRef = useRef(null);
    const [imageModal, setImageModal] = useState(false);
    const [replyType, setReplyType] = useState('text');
    const [accept, setAccept] = useState('image/*');
    const [isSenderListVisible, setSenderListVisible] = useState(true);
    const [isChatBoxVisible, setChatBoxVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [chatMenuVisible, setChatMenuVisible] = useState(false);
    const [chatMenuVisibleMobile, setChatMenuVisibleMobile] = useState(false);
    const [chatFilter, setChatFilter] = useState('all');
    const [placeOrder, setPlaceOrder] = useState(false);
    const [arrow, setArrow] = useState('Show');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState([]);
    const [productType, setProductType] = useState([]);
    const [tab, setTab] = useState('products');
    const [customerName, setCustomerName] = useState('');
    const [customerPhone, setCustomerPhone] = useState('');
    const [customerLocation, setCustomerLocation] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [orderNo, setOrderNo] = useState('');
    const [customerLoader, setCustomerLoader] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [isRecording, setIsRecording] = useState(false);
    const [recordingTime, setRecordingTime] = useState(0);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const recordingTimerRef = useRef(null);
    const [audioModal, setAudioModal] = useState(false);
    const [audioPreviewUrl, setAudioPreviewUrl] = useState('');
    const [audioChunks, setAudioChunks] = useState([]);
    const [audioFile, setAudioFile] = useState('');
    const fileInputRef = useRef(null);
    const [expiredAt, setExpiredAt] = useState('');
    const [timeRemaining, setTimeRemaining] = useState('');
    const [isAtBottom, setIsAtBottom] = useState(true);
    const [page, setPage] = useState(1);
    const [allMessagesLoaded, setAllMessagesLoaded] = useState(false);
    const [messageLoading, setMessageLoading] = useState(false);

    return {
        users,
        setUsers,
        open,
        setOpen,
        loading,
        setLoading,
        placeOrderLoading,
        setPlaceOrderLoading,
        receiptLoading,
        setReceiptLoading,
        selectedUser,
        setSelectedUser,
        messages,
        setMessages,
        message,
        setMessage,
        socket,
        setSocket,
        messageType,
        setMessageType,
        imageUrl,
        setImageUrl,
        fileList,
        setFileList,
        replyTo,
        setReplyTo,
        switchChat,
        setSwitchChat,
        phoneNumber,
        setPhoneNumber,
        chatBoxRef,
        imageModal,
        setImageModal,
        replyType,
        setReplyType,
        accept,
        setAccept,
        isSenderListVisible,
        setSenderListVisible,
        isChatBoxVisible,
        setChatBoxVisible,
        searchTerm,
        setSearchTerm,
        chatMenuVisible,
        setChatMenuVisible,
        chatMenuVisibleMobile,
        setChatMenuVisibleMobile,
        chatFilter,
        setChatFilter,
        placeOrder,
        setPlaceOrder,
        arrow,
        setArrow,
        selectedProducts,
        setSelectedProducts,
        products,
        setProducts,
        product,
        setProduct,
        productType,
        setProductType,
        tab,
        setTab,
        customerName,
        setCustomerName,
        customerPhone,
        setCustomerPhone,
        customerLocation,
        setCustomerLocation,
        customerId,
        setCustomerId,
        orderNo,
        setOrderNo,
        customerLoader,
        setCustomerLoader,
        filteredUsers,
        setFilteredUsers,
        isRecording,
        setIsRecording,
        recordingTime,
        setRecordingTime,
        mediaRecorderRef,
        audioChunksRef,
        recordingTimerRef,
        audioModal,
        setAudioModal,
        audioPreviewUrl,
        setAudioPreviewUrl,
        audioChunks,
        setAudioChunks,
        audioFile,
        setAudioFile,
        fileInputRef,
        expiredAt,
        setExpiredAt,
        timeRemaining,
        setTimeRemaining,
        isAtBottom,
        setIsAtBottom,
        page,
        setPage
    };
};

export default useChatState;
