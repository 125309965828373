/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import * as XLSX from 'xlsx';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';
import Controls from '../../components/controls/Controls';
import { Search } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
// import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import Form from 'react-bootstrap/Form';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const Mechanic = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [mechanicInfo, setMechanic] = useState([]);
    const [mechanicData, setMechanicData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [file, setFile] = useState('');
    const [filterstage, setfilterstage] = useState('');
    const [stageInfo, setStage] = useState([]);

    const handleEditClick = (row) => {
        if (scriptedRef.current) {
            history(`/editMechanic/${row._id}`);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const selectedFile = (e) => {
        setFile(e.target.files[0]);
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    const filterStageById = async (id) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.FilterMechanicStageUrl + id, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setMechanic(data.data);
                        setMechanicData(data.data);
                        setLoading(false);
                    } else {
                        toast.error(data.data);
                        setLoading(false);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllStage = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllStageUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setStage(data.data);
                        setLoading(false);
                    } else {
                        toast.error(data.data);
                        setLoading(false);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - mechanicInfo.length) : 0;

    const retrieveAllMechanic = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllMechanicUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        console.log('data', data.data);
                        setMechanic(data.data);
                        setMechanicData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handleFilterStageChange = (e) => {
        console.log('e', e.target.value);
        setfilterstage(e.target.value);
        const searchWord = e.target.value;
        if (searchWord === 'All') {
            retrieveAllMechanic();
        } else {
            filterStageById(e.target.value);
        }
    };

    useEffect(() => {
        retrieveAllMechanic();
        retrieveAllStage();
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault();
        // console.log('e', e);
        const formData = new FormData();
        formData.append('file', file);
        // console.log('file', file);
        // console.log('file type', file.type);
        const userInfo = localStorage.getItem('userToken');
        if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            try {
                const data = await file.arrayBuffer();
                const workbook = XLSX.read(data);
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                // console.log('workbook', JSON.stringify(jsonData));
                fetch(ApiUrl.UploadMechanicUrl, {
                    method: 'POST',
                    headers: {
                        'auth-token': userInfo,
                        'Content-Type': 'application/json'
                    },

                    body: JSON.stringify(jsonData)
                })
                    .then((res) => res.json())
                    .then((result) => {
                        if (result.status) {
                            toast.success(result.message);
                            setLoading(false);
                            if (scriptedRef) {
                                handleClose();
                                setTimeout(() => {
                                    retrieveAllMechanic();
                                }, 3000);
                            }
                        } else {
                            toast.error(result.data);
                            setLoading(false);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } catch (e) {
                console.log(e);
            }
        } else {
            toast.error('File Type not supported, kindly upload excel');
        }
    };

    // const handleDeleteClick = (row) => {
    //     swal({
    //         title: 'Are you sure?',
    //         text: `Once deleted, you will not be able to recover this Mechanic!(${row.partnerName})`,
    //         icon: 'warning',
    //         buttons: true,
    //         dangerMode: true
    //     }).then((willDelete) => {
    //         if (willDelete) {
    //             swal('Poof! The Mechanic has been deleted!', {
    //                 icon: 'success'
    //             });

    //             const data = {
    //                 id: row._id
    //             };
    //             const userInfo = localStorage.getItem('userToken');

    //             fetch(ApiUrl.DeleteChannelPartnerUrl, {
    //                 method: 'DELETE',
    //                 headers: {
    //                     'auth-token': userInfo,
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify(data)
    //             })
    //                 .then((res) => res.json())
    //                 .then((result) => {
    //                     if (result.status === true) {
    //                         if (scriptedRef.current) {
    //                             retrieveAllMechanic();
    //                             // window.location.href = '/stage';
    //                             // history('/stage');
    //                         }
    //                     } else {
    //                         toast.error(result.data);
    //                     }
    //                 });
    //         } else {
    //             swal('The Mechanic is safe!');
    //         }
    //     });
    // };

    const handleSearch = (event) => {
        const searchWord = event.target.value;

        const newFilter = mechanicInfo.filter((value) => value.partnerName.toLowerCase().includes(searchWord.toLowerCase()));

        if (searchWord === '') {
            setMechanic(mechanicData);
        } else {
            setMechanic(newFilter);
        }
    };

    return (
        <>
            <PageHeader title="Channel partners" subTitle="Home | Configuration | Channel Partners | Mechanic" />

            <MainCard title="Mechanic Partners">
                <Box display="flex" justifyContent="flex-start">
                    <Grid>
                        <Controls.Input
                            label="Search Mechanic Partner"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                        />
                    </Grid>
                    <Box width={10} />
                    <Box width={150} height={20}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Filter By Stage</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={filterstage}
                                label="Status"
                                onChange={handleFilterStageChange}
                            >
                                <MenuItem value="All">All</MenuItem>
                                {stageInfo.map((stage) => (
                                    <MenuItem key={stage._id} value={stage._id}>
                                        {stage.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box width={10} />

                    <Button variant="contained" onClick={handleClickOpen} size="sm" className={classes.item} color="secondary">
                        Upload
                    </Button>
                    <Box width={10} />

                    <Button component={Link} to="/addMechanic" variant="contained" size="sm" className={classes.item}>
                        Add
                    </Button>
                </Box>
                <br />

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress />{' '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Channel
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Partner Name
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Contact
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            GPS Coordinates
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Stage
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Sign up Date
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Credit Rating
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? mechanicInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : mechanicInfo
                            ).map((row, index) => (
                                <TableRow key={row._id}>
                                    <TableCell component="th" scope="row">
                                        {mechanicInfo.length - index}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.channelName ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.partnerName ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.partnerContact ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.locationLat ?? ''} , {row?.locationLng ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.stageId?.name ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {toTimestamp(row?.signupDate ?? '')}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.creditRating ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(row)}>
                                            <EditIcon />
                                        </IconButton>
                                        {/* <IconButton aria-label="delete" color="error" onClick={() => handleDeleteClick(row)}>
                                            <DeleteIcon />
                                        </IconButton> */}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    component="div"
                    count={mechanicInfo.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">Upload Mechanic Partners</DialogTitle>
                    <Form onSubmit={onSubmit}>
                        <DialogContent>
                            {/* <DialogContentText> */}

                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Control
                                    type="file"
                                    onChange={selectedFile}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                />
                                <br /> <br />
                                <Form.Label>Expected columns</Form.Label>
                                <br /> <br />
                                (Note: salesfocal,stage,customersuccess should be an ID from the Sales Team, Stage and Customer Success Team
                                Pages)
                                <br />
                                <br />
                                <Form.Label>
                                    partnername, datecontacted, phone, salesfocal, stage, dateonboarded,
                                    <br />
                                    partnertype,status,latitude,longitude,customersuccess,partlistcollected,
                                    <br />
                                    datefirstpartreceived,kyccompleted,notes
                                </Form.Label>
                            </Form.Group>

                            {/* </DialogContentText> */}
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" size="sm" className={classes.item} autoFocus onClick={handleClose} color="error">
                                Cancel
                            </Button>
                            <Button autoFocus variant="contained" type="submit" size="sm" className={classes.item}>
                                Upload
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </MainCard>
        </>
    );
};

export default Mechanic;
