import { Button, Modal, Input, Select, Spin } from 'antd';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ApiUrl from '../../../network';
import { decrypt, encrypt } from '../../../utils/encrypt';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const [editModal, setEditModal] = useState(false);
    const [amount, setAmount] = useState('');
    const [timeframe, setTimeframe] = useState('');
    const [actualTime, setActualTime] = useState('');
    const [fees, setFees] = useState('');
    const [tier, setTier] = useState('');
    const [tierType, setTierType] = useState([]);
    const [actionType, setActionType] = useState('');
    const [configId, setConfigId] = useState('');
    const [config, setConfig] = useState([]);
    const [loading, setLoading] = useState(false);

    // eslint-disable-next-line consistent-return
    const fetchConfigData = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(`${ApiUrl.BaseUrl}/epl/eplsconfigdata`, config);
            if (!response.ok) {
                toast.error('Failed to fetch retailers');
            }
            const data = await response.json();
            const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
            result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setConfig(result);
            setLoading(false);
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };

    // eslint-disable-next-line consistent-return
    const fetchEplTiersType = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(`${ApiUrl.BaseUrl}/epl/eplloyaltytypes`, config);
            if (!response.ok) {
                toast.error('Failed to fetch retailers');
            }
            const data = await response.json();
            const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
            setTierType(result);
            setLoading(false);
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchConfigData();
        fetchEplTiersType();
    }, []);

    const tierTypeOptions = tierType.map((item) => {
        return {
            value: item.name,
            label: item.name
        };
    });

    // eslint-disable-next-line consistent-return
    const addConfigHandler = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const datas = {
                qualifiedAmt: amount,
                creditTime: timeframe,
                fees,
                tier,
                actualTime
            };
            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            const response = await fetch(`${ApiUrl.BaseUrl}/epl/addepl`, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify({ payload })
            });
            if (!response.ok) {
                toast.error('Failed to add configuration');
            }
            const result = await response.json();
            if (result.message === 'success') {
                setEditModal(false);
                fetchConfigData();
                setLoading(false);
                toast.success(result.message);
            } else {
                toast.error('Failed to add configuration');
                setLoading(false);
            }
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };

    // eslint-disable-next-line consistent-return
    const editConfigHandler = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const datas = {
                id: configId,
                qualifiedAmt: amount,
                creditTime: timeframe,
                fees,
                tier,
                actualTime
            };
            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            const response = await fetch(`${ApiUrl.BaseUrl}/epl/updateeplconfig`, {
                method: 'PUT',
                headers: config.headers,
                body: JSON.stringify({ payload })
            });
            if (!response.ok) {
                toast.error('Failed to add configuration');
            }
            const result = await response.json();
            if (result.message === 'success') {
                setEditModal(false);
                fetchConfigData();
                setLoading(false);
            } else {
                toast.error('Failed to add configuration');
                setLoading(false);
            }
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };

    return (
        <div className="config-container">
            <div className="header">
                <h1 className="font-30">Epl configuration</h1>
                <div className="header-actions">
                    {/* <Button */}
                    {/*    onClick={() => { */}
                    {/*        setActionType('add'); */}
                    {/*        setAmount(0); */}
                    {/*        setTimeframe(0); */}
                    {/*        setFees(0); */}
                    {/*        setTier(''); */}
                    {/*        setEditModal(true); */}
                    {/*    }} */}
                    {/*    style={{ backgroundColor: '#FF740F', color: '#fff', width: 'fit-content' }} */}
                    {/* > */}
                    {/*    Add Configuration */}
                    {/* </Button> */}
                </div>
            </div>
            {loading ? (
                <div className="spinner-container">
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 34 }} spin />} />
                </div>
            ) : (
                <div>
                    {config.length > 0 ? (
                        <div className="content">
                            {config.map((item) => (
                                <div className="card border-0 p-4" style={{ borderRadius: '20px' }} key={item._id}>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p>
                                                <span>{item.tier}</span>
                                                <small>Tier</small>
                                            </p>
                                            <p>
                                                <span>
                                                    {item.currency?.name} {item.qualifiedAmt}
                                                </span>
                                                <small>Qualified amount</small>
                                            </p>
                                            <p>
                                                <span>{item.actualTime}</span>
                                                <small>Free Time Days</small>
                                            </p>
                                            <p>
                                                <span>{item.creditTime}</span>
                                                <small>Payment Term</small>
                                            </p>
                                            <p>
                                                <span>{item.fees}% </span>
                                                <small>Fees</small>
                                            </p>
                                        </div>
                                        <div>
                                            <Button
                                                onClick={() => {
                                                    setActionType('edit');
                                                    setConfigId(item._id);
                                                    setAmount(item.qualifiedAmt);
                                                    setTimeframe(item.creditTime);
                                                    setFees(item.fees);
                                                    setTier(item.tier);
                                                    setEditModal(true);
                                                }}
                                                style={{ backgroundColor: '#FFF1E7', color: '#FF740F', border: 'none' }}
                                            >
                                                Edit <EditOutlined />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div
                            className="card border-0 p-4 d-flex justify-content-center align-items-center not-found"
                            style={{
                                borderRadius: '20px',
                                width: '50%',
                                marginTop: '10vh',
                                margin: '0 auto',
                                height: '40vh'
                            }}
                        >
                            <p style={{ fontSize: '16', fontWeight: 'bold' }}>No EPL configuration available</p>
                        </div>
                    )}
                </div>
            )}

            <Modal
                title={actionType === 'edit' ? 'Edit configuration' : 'Add configuration'}
                open={editModal}
                onCancel={() => {
                    setEditModal(false);
                }}
                footer={null}
            >
                <div className="p-2 mt-4 mb-4 modal-Content">
                    <div className="form-group mb-3">
                        <span>Qualified amount</span>
                        <Input
                            type="text"
                            className="form-control mt-2"
                            id="qualifiedAmount"
                            value={amount}
                            onChange={(e) => {
                                setAmount(e.target.value);
                            }}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <span>Payment Term</span>
                        <Input
                            type="text"
                            className="form-control mt-2"
                            id="creditTimeframe"
                            value={timeframe}
                            onChange={(e) => {
                                setTimeframe(e.target.value);
                            }}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <span>Free Time Days</span>
                        <Input
                            type="text"
                            className="form-control mt-2"
                            id="creditTimeframe"
                            value={actualTime}
                            onChange={(e) => {
                                setActualTime(e.target.value);
                            }}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <span>Fees (In %)</span>
                        <Input
                            type="text"
                            className="form-control mt-2"
                            id="fees"
                            value={fees}
                            onChange={(e) => {
                                setFees(e.target.value);
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <span>Tier</span>
                        <Select
                            size="middle"
                            className="mt-2"
                            showSearch
                            options={tierTypeOptions}
                            defaultValue="Select option"
                            style={{ width: '100%' }}
                            value={tier}
                            onChange={(value) => {
                                setTier(value);
                            }}
                        />
                    </div>
                </div>
                <div className="modal-Footer">
                    <Button
                        onClick={() => {
                            setEditModal(false);
                        }}
                        style={{ backgroundColor: '#FFF', borderColor: '#FF740F' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={!amount || !timeframe || !fees || !actualTime || !tier}
                        style={{
                            backgroundColor: `${amount && timeframe && fees && actualTime && tier ? '#FF740F' : '#A1A5AD'}`,
                            borderColor: `${amount && timeframe && fees && actualTime && tier ? '#FF740F' : '#A1A5AD'}`,
                            color: '#FFF'
                        }}
                        loading={loading}
                        onClick={() => {
                            if (actionType === 'edit') {
                                editConfigHandler();
                            } else {
                                addConfigHandler();
                            }
                        }}
                    >
                        {actionType === 'edit' ? 'Save' : 'Save'}
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default Index;
