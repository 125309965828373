import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WestIcon from '@mui/icons-material/West';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, List, ListItem, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { DatePicker, Select, Space, Checkbox, Table, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { LoadingOutlined } from '@ant-design/icons';
import ApiUrl from 'network';
import { decrypt } from '../../utils/encrypt';
import { useNavigate, useParams } from 'react-router';
import { twoDecimalPlaces } from 'utils/functions';
import AllPaymentsModal from 'components/OrderTable/AllPaymentsModal';
import SleepOverModal from './SleepOverModal';

const SleepOver = () => {
    const [open, setOpen] = useState(true);
    const userRole = localStorage.getItem('userRole');
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [sleepOverList, setSleepOverList] = useState([]);
    const [mainRma, setMainRma] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [countries, setCountries] = useState([]);
    const [accumulatedFilters, setAccumulatedFilters] = useState({});
    const [isSleepOpen, setIsSleepOpen] = useState(false);
    const [sleepSuccess, setSleepSuccess] = useState(false);
    const [rmccName, setRmccName] = useState('');

    const [userId, setUserId] = useState('');
    const [limit, setLimit] = useState(100);
    const [page, setPage] = useState(1);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 25,
            total: 0
        }
    });
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const country = localStorage.getItem('country');

    const { RangePicker } = DatePicker;
    const toggleOpenClose = () => {
        setOpen(!open);
    };

    // sleep over modal
    const showSleepOverModal = () => {
        setIsSleepOpen(true);
    };

    const handleSleepOk = () => {
        setIsSleepOpen(false);
    };

    const handleSleepCancel = () => {
        setIsSleepOpen(false);
    };

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54
            }}
            spin
        />
    );

    const dateConverter = (value) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };

        return new Date(value).toLocaleDateString('en-US', options);
    };

    const limitNumber = 100;

    const limitOptions = [
        {
            label: '100',
            value: limitNumber * 1
        },
        {
            label: '200',
            value: limitNumber * 2
        },
        {
            label: '300',
            value: limitNumber * 3
        },
        {
            label: '400',
            value: limitNumber * 4
        },
        {
            label: '500',
            value: limitNumber * 5
        },
        {
            label: '600',
            value: limitNumber * 6
        },
        {
            label: '700',
            value: limitNumber * 7
        },
        {
            label: '800',
            value: limitNumber * 8
        },
        {
            label: '900',
            value: limitNumber * 9
        },
        {
            label: '1000',
            value: limitNumber * 10
        }
    ];

    const columns = [
        {
            title: 'S/N',
            dataIndex: '#',
            key: 'id',

            render: (id, record, index) => {
                return index + 1;
            },
            width: '30px'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '140px'
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            width: '100px'
        },
        {
            title: 'Start date',
            dataIndex: 'startdate',
            key: 'startdate',
            width: '180px'
        },
        {
            title: 'End date',
            dataIndex: 'enddate',
            key: 'enddate',
            width: '180px'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: '90px'
        }
    ];

    // get query data
    const getQueryData = async () => {
        try {
            // setLoading(true);
            const url = `${ApiUrl.BaseUrl}/mainorders/querydata?country=${accumulatedFilters?.country || country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setCountries(answer.country);
                        setMainRma(answer.relationshipManagers);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const countryOptions = countries?.map((item) => {
        return { label: item?.name, value: item?.name };
    });

    const rmaOptions = mainRma?.map((item) => {
        return { label: item?.name, value: item?._id };
    });

    const getSleepOverList = async () => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/salesplanning/sleepoverusers`;
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            // Append accumulated filters to URL
            Object.entries(accumulatedFilters).forEach(([key, value]) => {
                if (value) {
                    urlParams.append(key, value);
                }
            });

            url = `${url}?${urlParams.toString()}`;
            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setSleepOverList(answer);
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                total: data.totalcollection,
                                current: accumulatedFilters?.page || 1
                            }
                        });
                    } else {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        toast.error(answer);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getQueryData();
        getSleepOverList();
    }, [accumulatedFilters, sleepSuccess]);

    const filterRmaPerson = (value) => {
        const data = {
            rma: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    const filterCountry = (value) => {
        const data = {
            country: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    const onChange = (page) => {
        const data = {
            page: page.current,
            limit: page.pageSize
        };
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.page
            }
        });
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    const sleepOverData = sleepOverList?.map((result) => ({
        key: result?._id,
        startdate: result?.sleepoverStartDate
            ? dateConverter(result?.sleepoverStartDate, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  weekday: 'long',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit'
              })
            : '',
        enddate: result?.sleepoverEndDate
            ? dateConverter(result?.sleepoverEndDate, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  weekday: 'long',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit'
              })
            : '',
        name: `${result?.firstName} ${result?.lastName}`,
        role: result?.roleId?.name,

        action: (
            <Typography
                onClick={() => {
                    setUserId(result?._id);
                    setRmccName(`${result?.firstName} ${result?.lastName}`);
                    showSleepOverModal();
                }}
                sx={{
                    cursor: 'pointer',
                    border: '1px solid #ffe3cf',
                    height: '48px',
                    padding: '14px 16px',
                    borderRadius: '8px',
                    color: '#ff740f',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontSize: '14px',
                    width: '145px'
                }}
            >
                Edit <KeyboardArrowRightIcon sx={{ width: '16px', fontWeight: '500' }} />
            </Typography>
        )
    }));

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '1px dashed #E7EAEB',
                    alignItems: 'flex-end',
                    margin: '0px auto 15px',
                    flexWrap: 'wrap'
                }}
            >
                {' '}
                <List
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '0 0 16px 0',
                        alignItems: 'flex-start'
                        // flexDirection: 'column'
                        // margin: '0px auto 15px'
                    }}
                >
                    <WestIcon
                        sx={{
                            fontSize: '32px',
                            cursor: 'pointer',
                            margin: '0 10px 0 0',
                            '&:hover': {
                                color: '#546974'
                            },
                            '&:focus': {
                                color: '#9DA9AF'
                            }
                        }}
                        onClick={() => navigate(-1)}
                    />{' '}
                    <ListItem sx={{ fontWeight: 500, fontSize: '1.875rem', color: '#0A2938', padding: 0 }}>Sleep over</ListItem>
                </List>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    margin: '32px 0 0 0'
                }}
                onClick={toggleOpenClose}
            >
                <Typography
                    style={{
                        fontSize: '18px',
                        fontWeight: '500',
                        color: '#0A2938'
                    }}
                >
                    {open ? `Hide filters` : `Show filters`}
                </Typography>

                {open ? (
                    <KeyboardArrowUpIcon
                        style={{
                            margin: '0 0 0 2px',
                            color: '#0A2938'
                        }}
                    />
                ) : (
                    <ExpandMoreIcon
                        style={{
                            margin: '0 0 0 2px',
                            color: '#0A2938'
                        }}
                    />
                )}
            </Box>
            <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                sx={{
                    margin: '0 0 8px 0'
                }}
            >
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '20px',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            width: '100%'
                        }}
                    >
                        {userRole === 'Admin' ? (
                            <Box
                                sx={{
                                    width: ' 239px',
                                    margin: '8px 0 0 0px'
                                }}
                            >
                                {/* <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Select Country</Typography> */}

                                <Select
                                    style={{
                                        width: '100%'
                                    }}
                                    size="large"
                                    placeholder="Select Country"
                                    options={countryOptions}
                                    onChange={(value) => filterCountry(value)}
                                    filterOption={(inputValue, option) => {
                                        if (typeof option.label === 'string') {
                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }

                                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }}
                                    showSearch
                                    allowClear
                                />
                            </Box>
                        ) : null}

                        <Box
                            sx={{
                                width: ' 239px',
                                margin: '8px 0 0 0px'
                            }}
                        >
                            {/* <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>RMA/CC</Typography> */}

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="RMA/CC"
                                options={rmaOptions}
                                onChange={(value) => filterRmaPerson(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>
                    </Box>
                </>
            </Collapse>
            <Table
                loading={loading ? { indicator: <Spin indicator={antIcon} />, spinning: true / false } : ''}
                columns={columns}
                dataSource={sleepOverData}
                size="middle"
                style={{
                    margin: '24px 0 0 0'
                }}
                // pagination={tableParams.pagination}
                scroll={{
                    x: 'calc(700px + 50%)',
                    y: 580
                }}
                // onChange={onChange}
            />
            <SleepOverModal
                handleSleepCancel={handleSleepCancel}
                handleSleepOk={handleSleepOk}
                isSleepOpen={isSleepOpen}
                userId={userId}
                sleepSuccess={sleepSuccess}
                setSleepSuccess={setSleepSuccess}
                rmccName={rmccName}
            />
        </div>
    );
};

export default SleepOver;
