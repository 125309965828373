import { Button, Collapse, Divider, Input, Select, Spin, Table, DatePicker } from 'antd';
import { LoadingOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import ApiUrl from '../../../network';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { decrypt } from '../../../utils/encrypt';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { Link } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import dayjs from 'dayjs';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const { RangePicker } = DatePicker;
    const [retailerSummary, setRetailersSummary] = useState([]); // eslint-disable-line no-unused-vars
    const [loading, setLoading] = useState(false);
    const [tiers, setTiers] = useState([]);
    const [country, setCountry] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [tier, setTier] = useState(null);

    // eslint-disable-next-line consistent-return
    const fetchLoyaltySummary = async (filters) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/loyaltymanager/loyaltysummarydashboard`;

            if (filters) {
                if (filters.startDate && filters.endDate) {
                    urlParams.set('startDate', filters.startDate);
                    urlParams.set('endDate', filters.endDate);
                }
                if (filters.country) {
                    urlParams.set('country', filters.country);
                }
                if (filters.tier) {
                    urlParams.set('tier', filters.tier);
                }
            }
            if (urlParams.toString()) url += `?${urlParams.toString()}`;

            const response = await fetch(url, config);
            if (!response.ok) {
                toast.error('Failed to fetch retailers');
            }
            const data = await response.json();
            setRetailersSummary(JSON.parse(decrypt(data.data, keyResult, ivResult)));
            setLoading(false);
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };

    // eslint-disable-next-line consistent-return
    const fetchTiers = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(`${ApiUrl.BaseUrl}/loyaltymanager/tiers`, config);
            if (!response.ok) {
                toast.error('Failed to fetch tiers');
            }
            const data = await response.json();
            setTiers(JSON.parse(decrypt(data.data, keyResult, ivResult)));
            setLoading(false);
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        // eslint-disable-next-line consistent-return
        fetchLoyaltySummary();
        fetchTiers();

        return () => {
            // cleanup function
            setLoading(false);
        };
    }, []);

    const tierOptions = tiers.map((tier) => {
        return {
            value: tier.name,
            label: tier.name
        };
    });

    const columns = [
        {
            title: 'Tier',
            dataIndex: '_id',
            key: '_id'
        },
        {
            title: 'Retailers',
            dataIndex: 'totalRetailers',
            key: 'totalRetailers'
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount',
            render: (text) => (
                <span>
                    {localStorage.getItem('userCurrency')} {text}
                </span>
            )
        },
        {
            title: 'Discount redeemed',
            dataIndex: 'discountRedeemed',
            key: 'discountRedeemed',
            render: (text) => (
                <span>
                    {localStorage.getItem('userCurrency')} {text}
                </span>
            )
        },
        {
            title: 'Retailers redeemed ',
            dataIndex: 'totalRetailersRedeemed',
            key: 'totalRetailersRedeemed'
        }
    ];

    const handleDateChange = (date, dateString) => {
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
    };

    const appleyFilter = () => {
        const filterData = {
            tier,
            country,
            startDate,
            endDate
        };
        fetchLoyaltySummary(filterData);
    };

    return (
        <div className="leadCrmContainer">
            <div className="d-flex justify-content-space-between align-items-center salesCardHolder">
                <Link to="/retailer-loyalty" style={{ color: '#0A2938' }}>
                    <p style={{ fontSize: '30px' }}>
                        {' '}
                        <KeyboardBackspaceIcon style={{ marginRight: '5px' }} /> Summary dashboard
                    </p>
                </Link>
                <div className="d-flex align-items-center leadHeaders">
                    <Select
                        style={{ width: 200 }}
                        placeholder="Select tier"
                        className="mr-2 filterInput mt-2"
                        allowClear
                        showSearch
                        options={tierOptions}
                        onChange={(value) => {
                            setTier(value);
                        }}
                        value={tier}
                        optionFilterProp="children"
                        filterOption={(inputValue, option) => {
                            if (typeof option.label === 'string') {
                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }

                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        }}
                    />
                    <RangePicker
                        format="YYYY-MM-DD"
                        className="datePicker filterInput mt-2"
                        onChange={(date, dateString) => {
                            handleDateChange(date, dateString);
                        }}
                        value={startDate && endDate ? [dayjs(startDate, 'YYYY-MM-DD'), dayjs(endDate, 'YYYY-MM-DD')] : null}
                        style={{ width: 200 }}
                    />
                    {(startDate && endDate) || country || tier ? (
                        <div className="d-flex align-items-center leadHeaders ">
                            <Button
                                onClick={() => appleyFilter()}
                                style={{
                                    color: '#FF740F',
                                    border: '1px solid #FF740F'
                                }}
                                loading={loading}
                            >
                                Apply filter
                            </Button>
                            <Button
                                onClick={() => {
                                    setStartDate('');
                                    setEndDate('');
                                    setTier('');
                                    setCountry('');
                                    fetchLoyaltySummary();
                                }}
                                style={{
                                    color: '#FF740F',
                                    border: '1px solid #FF740F'
                                }}
                                className="ml-2"
                            >
                                Reset filter
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
            <Divider style={{ margin: '20px 0', border: '1px dash #e8e8e8' }} />
            <div className="table-responsive">
                <Table rowKey={(record) => record._id} columns={columns} dataSource={retailerSummary} />
            </div>
        </div>
    );
};
export default Index;
