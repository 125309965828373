import { useNavigate, useParams } from 'react-router';

import GenericPageHeader from 'components/GenericPageHeader';
import IconButton from '@mui/material/IconButton';
import Loader from 'components/Loader';
import LoadingButton from '@mui/lab/LoadingButton';
import { Paper } from '@mui/material';
import { SiMicrosoftexcel } from 'react-icons/si';
import moment from 'moment/moment';
import styled from 'styled-components';
import { useAppContext } from 'context/appContext';
import { useEffect } from 'react';
import { wait } from 'utils/functions';

const Wrapper = styled.div`
    .orders {
        padding: 2rem 4rem;
        border-radius: 0;

        tr td {
            padding: 1rem;
        }

        .excel-generator {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .batch-time {
            padding: 1rem;
            p {
                font-size: 1.2rem;
                font-weight: 800;
                color: #ff740f;
            }
            display: flex;
            align-items: center;
            justify-content: space-around;
        }

        @media screen and (max-width: 500px) {
            .batch-time {
                flex-direction: column;
                justify-content: flex-start;
                p {
                    font-size: 1rem;
                }
            }
        }
    }
`;

const PlaceBatchOrder = () => {
    const navigate = useNavigate();
    const {
        isLoading,
        orderedParts,
        currentBatchDate,
        currentBatchNo,
        batchStatusId,
        getSupplierBatchContentById,
        handleExcelDataExport,
        handleChange,
        generateBatchReceipt,
        batchSubmitLoading
    } = useAppContext();
    const { id } = useParams();
    const excelName = `orders-${currentBatchDate}`;

    useEffect(() => {
        getSupplierBatchContentById(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectedParts = orderedParts.filter((el) => el.isAvailable);

    console.log(selectedParts);

    const payload = {
        orderbatchId: id,
        batchStatusId,
        parts: selectedParts
    };

    const handleBatchReceipt = () => {
        generateBatchReceipt(payload);
        wait(3).then(() => {
            navigate('/suppliers/batch');
        });
    };

    return (
        <>
            <GenericPageHeader title="Order Batches" breadcrumbs="Home | Dashboard | Orders" />
            <Wrapper>
                <Paper elevation={1} className="orders">
                    <div className="excel-generator">
                        <IconButton
                            aria-label="Generate Excel"
                            style={{ color: '#76BA99', fontSize: '30px' }}
                            title="Excel Generator"
                            onClick={() => handleExcelDataExport(orderedParts, excelName)}
                        >
                            <SiMicrosoftexcel />
                        </IconButton>
                    </div>
                    <div className="batch-time">
                        <p>Batch No: {currentBatchNo} | Edit & Finalize Order </p>
                        <p>Sent: {moment(currentBatchDate).fromNow()}</p>
                    </div>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <div className="table-responsive">
                            <table className="table  table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Item Availability</th>
                                        <th scope="col">Part Name</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Part Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderedParts.map((el, i) => {
                                        const { partid, qty, partType, isAvailable } = el;
                                        return (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>
                                                    <div className="form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="flexSwitchCheckDefault"
                                                            checked={isAvailable}
                                                            onChange={() => handleChange(el)}
                                                        />
                                                    </div>
                                                </td>
                                                <td>{partid}</td>
                                                <td>{qty}</td>
                                                <td>{partType}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <div
                                className="send-receipt mt-2"
                                style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                            >
                                <LoadingButton
                                    variant="contained"
                                    onClick={handleBatchReceipt}
                                    style={{ backgroundColor: ' #ff740f' }}
                                    loading={batchSubmitLoading}
                                >
                                    Generate Receipt
                                </LoadingButton>
                            </div>
                        </div>
                    )}
                </Paper>
            </Wrapper>
        </>
    );
};

export default PlaceBatchOrder;
