import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, List, ListItem, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { DatePicker, Select, Space, Checkbox, Button, Input, Popover, Dropdown, notification, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import WestIcon from '@mui/icons-material/West';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { toast } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';
import ApiUrl from 'network';
import { encrypt, decrypt } from '../../utils/encrypt';
import { useNavigate } from 'react-router';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { dateConverter } from 'utils/functions';

const SleepOverModal = ({ handleSleepCancel, handleSleepOk, isSleepOpen, userId, sleepSuccess, setSleepSuccess, rmccName }) => {
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const country = localStorage.getItem('country');
    const [api, contextHolder] = notification.useNotification();
    const [isEditingSleep, setIsEditingSleep] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString().replace(/\//g, '-').split('-').reverse().join('-');
    };

    const openNotification = (placement) => {
        api.open({
            description: 'Schedule updated.',
            placement,
            style: {
                width: 267,
                height: 70,
                backgroundColor: '#0A2938',
                borderRadius: '12px',
                textAlign: 'center',
                color: '#fff',
                fontWeight: 500,
                fontSize: '18px'
            },
            closeIcon: false
        });
    };

    const onStartDateChange = (date, dateString) => {
        setSelectedStartDate(dateString);
    };
    const onEndDateChange = (date, dateString) => {
        setSelectedEndDate(dateString);
    };

    const editSleepOver = async () => {
        const userInfo = localStorage.getItem('userToken');
        setIsEditingSleep(true);

        const data = {
            userId,
            startDate: selectedStartDate,
            endDate: selectedEndDate
        };

        const obj = JSON.stringify(data);
        const result = encrypt(obj, keyResult, ivResult);

        await fetch(`${ApiUrl.BaseUrl}/salesplanning/updatesleepovers`, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ payload: result })
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    openNotification('bottom');
                    setSleepSuccess(!sleepSuccess);
                    handleSleepOk();
                } else if (result.status === false) {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                } else {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                }
            })
            .finally(() => {
                setIsEditingSleep(false);
            });
    };

    return (
        <div>
            {contextHolder}
            <Modal
                open={isSleepOpen}
                onOk={handleSleepOk}
                centered
                onCancel={handleSleepCancel}
                width={340}
                footer={
                    <Button
                        type="primary"
                        size="large"
                        loading={isEditingSleep}
                        style={{
                            backgroundColor: '#FF740F',
                            borderRadius: '12px',
                            fontSize: '14px',
                            fontWeight: '400',
                            color: '#fff',
                            margin: '10px 5px ',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '38px'
                        }}
                        onClick={() => {
                            editSleepOver();
                        }}
                    >
                        Save Changes
                    </Button>
                }
                mask={false}
            >
                <Box
                    style={{
                        backgroundColor: '#FFFFFF',
                        borderRadius: '16px',
                        border: '1px solid #FFFFFF',
                        margin: '5px',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Box
                        sx={{
                            // width: ' 100%',
                            margin: '10px 5px'
                        }}
                    >
                        <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Name</Typography>

                        <Input
                            style={{
                                width: '250px'
                            }}
                            size="large"
                            allowClear
                            value={rmccName}
                            disabled
                        />
                    </Box>
                    <Box
                        sx={{
                            margin: '10px 5px'
                        }}
                    >
                        <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Start date</Typography>

                        <DatePicker
                            style={{
                                width: '250px'
                            }}
                            size="large"
                            allowClear
                            onChange={onStartDateChange}
                        />
                    </Box>
                    <Box
                        sx={{
                            margin: '10px 5px'
                        }}
                    >
                        <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>End date</Typography>

                        <DatePicker
                            style={{
                                width: '250px'
                            }}
                            size="large"
                            allowClear
                            onChange={onEndDateChange}
                        />
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default SleepOverModal;
