import * as XLSX from 'xlsx';

import {
    BATCH_ORDERS_SUCCESS,
    BATCH_STATUS_SUCCESS,
    CREATE_BATCH_BEGIN,
    CREATE_BATCH_FAILURE,
    CREATE_BATCH_SUCCESS,
    DELETE_BATCH_BEGIN,
    FILTER_BATCH_BEGIN,
    FILTER_BATCH_ERROR,
    FILTER_BATCH_SUCCESS,
    GET_BATCH_BEGIN,
    GET_BATCH_FAILURE,
    GET_BATCH_SUCCESS,
    RESET_BATCH_BEGIN,
    SET_DASHBOARD_VIEW,
    SINGLE_BATCH_BEGIN,
    SINGLE_BATCH_FAILURE,
    SINGLE_BATCH_SUCCESS,
    UPDATE_BATCH_BEGIN,
    UPDATE_BATCH_FAILURE,
    UPDATE_BATCH_SUCCESS
} from './batch-actions';
import { createContext, useContext, useReducer } from 'react';
import { errorToast, successToast } from 'utils/toast';

import { authFetch } from 'utils/axios-config';
import batchReducer from './batchReducer';

const initOrderType = localStorage.getItem('currentOrderType');

const initialState = {
    isLoading: false,
    message: '',
    batchStatuses: [],
    todayOrders: [],
    // stats statistics
    batchdatatoday: [],
    createdbatchtoday: 0,
    fullysorcedbatchtoday: 0,
    partiallysourcedbatchtoday: 0,
    intransitbatchtoday: 0,
    deliveredbatchtoday: 0,
    verifiedbatchtoday: 0,
    handleOrderChange: () => {},
    singleBatchContent: {},
    view: 'table',
    orderType: initOrderType
};

const BatchContext = createContext({ ...initialState });

const BatchProvider = ({ children }) => {
    const [state, dispatch] = useReducer(batchReducer, initialState);

    const getAllBatchStatuses = async () => {
        try {
            const { data } = await authFetch.get('/orderbatch/batchstatuses');
            dispatch({ type: BATCH_STATUS_SUCCESS, payload: { batchStatuses: data.data } });
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                errorToast(message);
            }
        }
    };

    const getTodayOrders = async () => {
        const { orderType } = state;
        let url = `/orderbatch/ordertoday`;
        if (orderType) url += `?orderType=${orderType}`;
        try {
            const { data } = await authFetch.get(url);
            dispatch({ type: BATCH_ORDERS_SUCCESS, payload: { todayOrders: data.data } });
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                errorToast(message);
            }
        }
    };

    const getAllBatchOrders = async () => {
        const { orderType } = state;
        let url = `/orderbatch/allbatch`;
        if (orderType) url += `?orderType=${orderType}`;
        dispatch({ type: GET_BATCH_BEGIN });
        try {
            const { data } = await authFetch.get(url);
            const {
                batchdatatoday,
                createdbatchtoday,
                fullysorcedbatchtoday,
                partiallysourcedbatchtoday,
                intransitbatchtoday,
                deliveredbatchtoday,
                verifiedbatchtoday
            } = data;
            dispatch({
                type: GET_BATCH_SUCCESS,
                payload: {
                    batchdatatoday,
                    createdbatchtoday,
                    fullysorcedbatchtoday,
                    partiallysourcedbatchtoday,
                    intransitbatchtoday,
                    deliveredbatchtoday,
                    verifiedbatchtoday
                }
            });
            successToast('Batch data loaded successfully!');
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                dispatch({ type: GET_BATCH_FAILURE, payload: { message } });
                errorToast(message);
            }
        }
    };

    const createOrderBatch = async (batch) => {
        dispatch({ type: CREATE_BATCH_BEGIN });
        try {
            const response = await authFetch.post('/orderbatch/add', batch);
            const { message } = response.data;
            dispatch({ type: CREATE_BATCH_SUCCESS, payload: { message } });
            getAllBatchOrders();
            successToast('Batch created successfully');
        } catch (error) {
            const { message } = error.response.data;
            if (error.response.status !== 401) {
                dispatch({
                    type: CREATE_BATCH_FAILURE,
                    payload: { message }
                });
                errorToast(message);
            }
        }
    };

    const handlePdfPreview = async (tableRow) => {
        try {
            const response = await authFetch.post('/orderbatch/createbatchpdf', tableRow);
            const { data } = response.data;
            successToast('Pdf generated. Redirecting...');
            setTimeout(() => {
                window.open(data, '_blank');
            }, 2000);
        } catch (error) {
            const { message } = error.response.data;
            if (error.response.status !== 401) {
                dispatch({
                    type: CREATE_BATCH_FAILURE,
                    payload: { message }
                });
                errorToast(message);
            }
        }
    };

    const handlePdfPreviewDetailed = async (tableRow) => {
        try {
            console.log('data', tableRow);
            const response = await authFetch.post('/orderbatch/createdetailedbatchpdf', tableRow);
            const { data } = response.data;
            successToast('Pdf generated. Redirecting...');
            setTimeout(() => {
                window.open(data, '_blank');
            }, 2000);
        } catch (error) {
            const { message } = error.response.data;
            if (error.response.status !== 401) {
                dispatch({
                    type: CREATE_BATCH_FAILURE,
                    payload: { message }
                });
                errorToast(message);
            }
        }
    };

    const handleExcelDataExport = (tableRow) => {
        try {
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableRow);
            XLSX.utils.book_append_sheet(wb, ws, 'batches');
            XLSX.writeFile(wb, 'order-batch.xlsx');
            successToast('Excel generated successfully');
        } catch (error) {
            console.log(error);
        }
    };

    const deleteBatcthOrderById = async (id) => {
        const { orderType } = state;
        dispatch({ type: DELETE_BATCH_BEGIN });
        try {
            await authFetch.delete('/orderbatch/remove', { data: { id, orderType } });
            successToast('Batch Deleted successfully');
            getAllBatchOrders();
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                errorToast(message);
            }
        }
    };

    const filterBatch = async (filterOptions) => {
        const url = `/orderbatch/filterbatchdate`;
        dispatch({ type: FILTER_BATCH_BEGIN });
        try {
            const response = await authFetch.post(url, filterOptions);
            const {
                batchfilterdatedata,
                createdbatchdate,
                fullysorcedbatchdate,
                partiallysourcedbatchdate,
                intransitbatchdate,
                deliveredbatchdate,
                verifiedbatchdate,
                order
            } = response.data;

            dispatch({
                type: FILTER_BATCH_SUCCESS,
                payload: {
                    batchfilterdatedata,
                    createdbatchdate,
                    fullysorcedbatchdate,
                    partiallysourcedbatchdate,
                    intransitbatchdate,
                    deliveredbatchdate,
                    verifiedbatchdate,
                    order
                }
            });
            successToast('Data filtered successfully');
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                dispatch({ type: FILTER_BATCH_ERROR, payload: { message } });
                errorToast(message);
            }
            console.log(error);
        }
    };

    const resetBatch = async () => {
        dispatch({ type: RESET_BATCH_BEGIN });
        try {
            getAllBatchOrders();
            successToast('Dashboard refreshed sccessful.');
        } catch (error) {
            console.log(error.response.data);
        }
    };

    const getBatchDataById = async (id) => {
        const currentOrderType = localStorage.getItem('currentOrderType');
        const url = `/orderbatch/show/${id}?orderType=${currentOrderType}`;

        dispatch({ type: SINGLE_BATCH_BEGIN });
        try {
            const { data } = await authFetch.get(url);
            dispatch({ type: SINGLE_BATCH_SUCCESS, payload: { content: data.data } });
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                dispatch({ type: SINGLE_BATCH_FAILURE, payload: { message } });
                errorToast(message);
            }
            console.error(error);
        }
    };

    const updateOrderBatch = async (batch) => {
        dispatch({ type: UPDATE_BATCH_BEGIN });
        try {
            const response = await authFetch.put('/orderbatch/update', batch);
            const { message } = response.data;
            dispatch({ type: UPDATE_BATCH_SUCCESS, payload: { message } });
            getAllBatchOrders();
            successToast('Batch updated successfully');
        } catch (error) {
            const { message } = error.response.data;
            if (error.response.status !== 401) {
                dispatch({
                    type: UPDATE_BATCH_FAILURE,
                    payload: { message }
                });
                errorToast(message);
            }
        }
    };

    const handleDashboardView = (type) => {
        dispatch({ type: SET_DASHBOARD_VIEW, payload: { view: type } });
    };

    const handleOrderChange = (orderType) => {
        dispatch({ type: 'ORDER_CHANGE', payload: { orderType } });
    };

    return (
        <BatchContext.Provider
            value={{
                ...state,
                dispatch,
                getAllBatchStatuses,
                getTodayOrders,
                createOrderBatch,
                getAllBatchOrders,
                handlePdfPreview,
                handlePdfPreviewDetailed,
                handleExcelDataExport,
                deleteBatcthOrderById,
                filterBatch,
                resetBatch,
                getBatchDataById,
                updateOrderBatch,
                handleDashboardView,
                handleOrderChange
            }}
        >
            {children}
        </BatchContext.Provider>
    );
};

const useBatchContext = () => {
    return useContext(BatchContext);
};

export { BatchProvider, useBatchContext };
