import PageFilter from './PageFilter';
import PageHeader from './PageHeader';
import { Container, Row, Col } from 'react-grid-system';
import StatsCard from './StatsCard';
import BasicCard from './BasicCard';
import TopPartsTable from './TopPartsTable';
import SalesChart from './SalesChart';
import Inventory from './Inventory';
import BestSeller from './BestSeller';
import RecentOrder from './RecentOrder';
import { useEffect } from 'react';
import { useRetailerContext } from 'context/retailer-shop/retailer-context';
import { useAppContext } from 'context/appContext';
import Loader from 'components/Loader';

const Index = () => {
    const { userCurrency } = useAppContext();

    const {
        isLoading,
        getRetailerSalesById,
        currentRetailerId,
        cwtotalnumberoforders,
        pwtotalnumberoforders,
        cwtotalsales,
        pwtotalsales,
        cwaverageordervalue,
        pwaverageordervalue,
        ratetotalnumberoforders,
        ratesalespercentage,
        rateorderaveragepercentage,
        handleRetailerSalesChart
    } = useRetailerContext();

    const retailerReqObject = {
        id: currentRetailerId
    };

    const currentChartOptions = {
        id: currentRetailerId,
        type: 'monthly'
    };

    useEffect(() => {
        getRetailerSalesById(retailerReqObject);
        handleRetailerSalesChart(currentChartOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRetailerId]);

    return (
        <div>
            <PageHeader title="Dashboard | Retailer Shop" breadcrumbs="Home | Dashboard | Retailer" />
            <PageFilter />
            {isLoading ? (
                <Loader />
            ) : (
                <Container>
                    <Row>
                        <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                            <StatsCard
                                title="Total number of orders"
                                todayCount={cwtotalnumberoforders.toFixed(2)}
                                prevCount={pwtotalnumberoforders.toFixed(2)}
                                perChange={ratetotalnumberoforders}
                            />
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                            <StatsCard
                                title="Total sales"
                                todayCount={`${userCurrency}${cwtotalsales.toFixed(2)}`}
                                prevCount={`${userCurrency}${pwtotalsales.toFixed(2)}`}
                                perChange={Number(ratesalespercentage)}
                            />
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                            <StatsCard
                                title="Average order value"
                                todayCount={`${userCurrency}${cwaverageordervalue.toFixed(2)}`}
                                prevCount={`${userCurrency}${pwaverageordervalue.toFixed(2)}`}
                                perChange={Number(rateorderaveragepercentage)}
                            />
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                            <BasicCard />
                        </Col>
                    </Row>
                    <div>
                        <Row>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <TopPartsTable />
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <SalesChart />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Inventory />
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <BestSeller />
                                <RecentOrder />
                            </Col>
                        </Row>
                    </div>
                </Container>
            )}
        </div>
    );
};
export default Index;
