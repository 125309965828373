// import dashboard from './dashboard';

import partsuploadIndex from './partsupload';
import other from './other';

// ==============================|| MENU ITEMS dashboard ||============================== //

const partsuploadItems = {
    items: [partsuploadIndex, other]
};

export default partsuploadItems;
