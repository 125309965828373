/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
// import * as XLSX from 'xlsx';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';
import { Col } from 'react-grid-system';
// import Controls from '../../components/controls/Controls';
// import { Search } from '@mui/icons-material';
// import InputAdornment from '@mui/material/InputAdornment';
// import Button from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
// import ViewIcon from '@mui/icons-material/Preview';
// import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
// import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
// import Dialog from '@mui/material/Dialog';
import { Avatar } from '@mui/material';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
// import Form from 'react-bootstrap/Form';
// import Grid from '@mui/material/Grid';

// import styled from 'styled-components';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 3
    }
}));

const PartsUpload = () => {
    // page states

    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [partsInfo, setParts] = useState([]);

    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const [searched, setSearched] = useState('');
    // const [uploadedfile, setUploadedFile] = useState({});

    const retrieveAllParts = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.PartListUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        // console.log('data', data.data);
                        setParts(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    const handleEditClick = (row) => {
        if (scriptedRef.current) {
            history(`/partsuploadedit/${row._id}`);
        }
    };

    // const handlePriceChangesClick = (row) => {
    //     if (scriptedRef.current) {
    //         history(`/pricechanges/${row._id}`);
    //     }
    // };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // const handledataexport = () => {
    //     const wb = XLSX.utils.book_new();
    //     const ws = XLSX.utils.json_to_sheet(partsInfo);
    //     XLSX.utils.book_append_sheet(wb, ws, 'GaragePart');
    //     XLSX.writeFile(wb, 'Parts.xlsx');
    // };

    // const handledataproductsexport = () => {
    //     try {
    //         const userInfo = localStorage.getItem('userToken');
    //         const config = {
    //             headers: {
    //                 'auth-token': userInfo,
    //                 'Content-Type': 'application/json'
    //             }
    //         };
    //         setLoading(true);
    //         fetch(ApiUrl.GroupRetailerUrl, config)
    //             .then((data) => data.json())
    //             .then((data) => {
    //                 if (data.status === true) {
    //                     const wb = XLSX.utils.book_new();
    //                     const ws = XLSX.utils.json_to_sheet(data.data);
    //                     XLSX.utils.book_append_sheet(wb, ws, 'Parts');
    //                     XLSX.writeFile(wb, 'Parts.xlsx');
    //                     // setParts(data.data);
    //                     // setpartsData(data.data);
    //                 } else {
    //                     toast.error(data.data);
    //                 }
    //             })
    //             .finally(() => {
    //                 setLoading(false);
    //             });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    // function toTimestamp(strDate) {
    //     const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    //     const today = strDate ? new Date(strDate) : new Date();
    //     return today.toLocaleDateString('en-gh', options);
    // }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - partsInfo.length) : 0;

    useEffect(() => {
        retrieveAllParts();
    }, []);

    // const handleDeleteClick = (row) => {
    //     swal({
    //         title: 'Are you sure?',
    //         text: `Once deleted, you will not be able to recover this Part!(${row.sku})`,
    //         icon: 'warning',
    //         buttons: true,
    //         dangerMode: true
    //     }).then((willDelete) => {
    //         if (willDelete) {
    //             swal('Poof! The Part has been deleted!', {
    //                 icon: 'success'
    //             });

    //             const data = {
    //                 id: row._id
    //             };
    //             const userInfo = localStorage.getItem('userToken');

    //             fetch(ApiUrl.DeletePartsUrl, {
    //                 method: 'DELETE',
    //                 headers: {
    //                     'auth-token': userInfo,
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify(data)
    //             })
    //                 .then((res) => res.json())
    //                 .then((result) => {
    //                     if (result.status === true) {
    //                         if (scriptedRef.current) {
    //                             retrieveAllParts();
    //                             // window.location.href = '/stage';
    //                             // history('/stage');
    //                         }
    //                     } else {
    //                         toast.error(result.data);
    //                     }
    //                 });
    //         } else {
    //             swal('The Part is safe!');
    //         }
    //     });
    // };

    const filteredRows = partsInfo.filter((row) => row.sku.toLowerCase().includes(searched.toLowerCase()));
    const isPlural = filteredRows && filteredRows.length > 1 ? 's' : '';
    const searchCount = searched ? `${filteredRows.length} result${isPlural} found` : 'No Results';

    // const handleSearch = (event) => {
    //     const searchWord = event.target.value;

    //     const newFilter = partsInfo.filter((value) => value.sku.toLowerCase().includes(searchWord.toLowerCase()));

    //     if (searchWord === '') {
    //         setParts(partsData);
    //     } else {
    //         setParts(newFilter);
    //     }
    // };

    return (
        <>
            {/* <Section>
                {loading ? (
                    <div className="overlay">
                        <div className="overlay__inner">
                            <div className="overlay__content">
                                <span className="spinner" />
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )} */}
            <br />
            <PageHeader title="Parts" subTitle="Home | Configuration | Parts | Master Database" />
            <h4 style={{ color: '#ff740f', fontWeight: '700' }}>{searched ? searchCount : `Total Parts: ${partsInfo.length}`}</h4>

            <br />
            <MainCard title="Master Parts List">
                <Box display="flex" justifyContent="flex-start">
                    <Col xs={12} xl={3} lg={3} md={6} sm={6}>
                        <FormControl fullWidth style={{ marginBottom: '10px' }}>
                            <TextField
                                id="outlined-basic"
                                label="Search Product"
                                variant="outlined"
                                onChange={(e) => setSearched(e.target.value)}
                            />
                        </FormControl>
                    </Col>
                    <Box width={10} />

                    {/* <Button variant="contained" onClick={handleClickOpen} size="sm" className={classes.item} color="secondary">
                        Upload
                    </Button>

                    <Box width={10} />

                    <Button component={Link} to="/addPart" variant="contained" size="sm" className={classes.item}>
                        Add Part
                    </Button>
                    <Box width={10} />
                    <Button onClick={handledataexport} variant="contained" size="sm" className={classes.item} color="info">
                        Download Excel
                    </Button> */}

                    {/* <Box width={10} />

                    <Button onClick={handledataproductsexport} variant="contained" size="sm" className={classes.item} color="error">
                        Download Products Excel
                    </Button> */}
                </Box>
                <br />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Product Name
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Product Family
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Brand
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Wholesale Price
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Garage Price
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Quantity In Pieces
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Part Image
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Distributor
                                        </TableCell>

                                        {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Date
                                        </TableCell> */}

                                        {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Applicable VINs
                                        </TableCell> */}
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : partsInfo).map(
                                (row, index) => (
                                    <TableRow key={row._id}>
                                        <TableCell component="th" scope="row">
                                            {filteredRows.length - index}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.sku}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.productFamily}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.brand}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.wholeSalePrice}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.garagePrice}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row?.partQtyInPieces ?? ''}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            <Avatar variant="rounded" src={row?.partsImage} sx={{ width: 150, height: 150 }} />
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row.distributor?.partnerName ?? ''}
                                        </TableCell>

                                        {/* <TableCell component="th" scope="row">
                                            {toTimestamp(row.signupDate)}
                                        </TableCell> */}

                                        {/* <TableCell component="th" scope="row">
                                            {row.applicableVins}
                                        </TableCell> */}

                                        <TableCell component="th" scope="row">
                                            <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(row)}>
                                                <EditIcon />
                                            </IconButton>
                                            {/* <IconButton aria-label="delete" color="error" onClick={() => handleDeleteClick(row)}>
                                                <DeleteIcon />
                                            </IconButton> */}
                                            {/* <Tooltip title="Price Changes">
                                                <IconButton aria-label="view" color="success" onClick={() => handlePriceChangesClick(row)}>
                                                    <ViewIcon />
                                                </IconButton>
                                            </Tooltip> */}
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 100, 250, { label: 'All', value: -1 }]}
                    component="div"
                    count={partsInfo.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>

            {/* </Section> */}
        </>
    );
};

export default PartsUpload;
// const Section = styled.section`
//     .overlay {
//         left: 0;
//         top: 0;
//         width: 100%;
//         height: 100%;
//         position: fixed;
//         background: #222;
//         opacity: 0.7;
//         z-index: 250;
//     }

//     .overlay__inner {
//         left: 0;
//         top: 0;
//         width: 100%;
//         height: 100%;
//         position: absolute;
//     }

//     .overlay__content {
//         left: 50%;
//         position: absolute;
//         top: 50%;
//         transform: translate(-50%, -50%);
//     }

//     .spinner {
//         width: 75px;
//         height: 75px;
//         display: inline-block;
//         border-width: 2px;
//         border-color: rgba(255, 255, 255, 0.05);
//         border-top-color: #fff;
//         animation: spin 1s infinite linear;
//         border-radius: 100%;
//         border-style: solid;
//     }

//     @keyframes spin {
//         100% {
//             transform: rotate(360deg);
//         }
//     }
// `;
