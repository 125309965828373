import { LoadingOutlined } from '@ant-design/icons';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import CallMadeIcon from '@mui/icons-material/CallMade';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import PhoneIcon from '@mui/icons-material/Phone';
import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Button, Input, Select, notification } from 'antd';
import ApiUrl from '../../network';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { dateConverter, twoDecimalPlaces } from 'utils/functions';
import badge from '../../assets/images/badge.png';
import { decrypt, encrypt } from '../../utils/encrypt';
import './retailer.css';
import RetailerProfileModal from './retailerProfileModal';
import WestIcon from '@mui/icons-material/West';
import { Person } from '@mui/icons-material';
import StarIcon from '@mui/icons-material/Star';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import check from '../../assets/images/icons/checkbox.svg';
import { EmptyState } from 'components/EmptyState/EmptyState';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import NearMeIcon from '@mui/icons-material/NearMe';

const SingleRetailerProfile = () => {
    const [isRetailerOpen, setIsRetailerOpen] = useState(false);
    const [isCommentOpen, setIsCommentOpen] = useState(false);
    const [commentSuccess, setCommentSuccess] = useState(false);
    const [isOrderDetailsOpen, setIsOrderDetailsOpenOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [containerWidth, setContainerWidth] = useState('false');
    const [profile, setProfile] = useState([]);
    const [profileId, setProfileId] = useState('');
    const [orders, setOrders] = useState([]);
    const [mainRetailer, setMainRetailer] = useState([]);
    const [getOrderId, setGetOrderId] = useState('');
    const [comment, setComment] = useState('');
    const [analytics, setAnalytics] = useState([]);
    const [previousLoyalty, setPreviousLoyalty] = useState([]);
    const [currentsLoyalty, setCurrentsLoyalty] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const { TextArea } = Input;
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const navigate = useNavigate();
    const country = localStorage.getItem('country');
    const [api, contextHolder] = notification.useNotification();
    const params = useParams();
    const [openSource, setOpenSource] = useState(false);
    const [isAddingNote, setIsAddingNote] = useState(false);
    const [source, setSource] = useState('');
    const [note, setNote] = useState('');
    const [noteSuccess, setNoteSuccess] = useState(false);
    const [retailerNotes, setRetailerNotes] = useState([]);

    const [items, setItems] = useState([
        { key: 1, source: 'Phone Call', isHovered: false, isSelected: false },
        { key: 2, source: 'Messaging', isHovered: false, isSelected: false },
        { key: 3, source: 'Physical Conversation', isHovered: false, isSelected: false }
    ]);

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54
            }}
            spin
        />
    );

    const openNotification = (placement) => {
        api.open({
            description: 'Note has been added.',
            placement,
            style: {
                width: 267,
                height: 70,
                backgroundColor: '#0A2938',
                borderRadius: '12px',
                textAlign: 'center',
                color: '#fff',
                fontWeight: 500,
                fontSize: '18px'
            },
            closeIcon: false
        });
    };

    // modals
    const showRetailerModal = () => {
        setIsRetailerOpen(true);
    };

    const handleRetailerOk = () => {
        setIsRetailerOpen(false);
    };

    const handleRetailerCancel = () => {
        setIsRetailerOpen(false);
    };

    const showCommentModal = () => {
        setIsCommentOpen(true);
    };

    const handleCommentOk = () => {
        setIsCommentOpen(false);
    };

    const handleCommentCancel = () => {
        setIsCommentOpen(false);
    };

    const showOderDetailesModal = () => {
        setIsOrderDetailsOpenOpen(true);
    };

    const handleOrderDetailsOk = () => {
        setIsOrderDetailsOpenOpen(false);
    };

    const handleOrderDetailsCancel = () => {
        setIsOrderDetailsOpenOpen(false);
    };

    useEffect(() => {
        const handleResize = () => {
            // Adjust the column width based on the window width
            const newWidth = window.innerWidth < 1200 ? 'true' : 'false';
            setContainerWidth(newWidth);
        };

        // Initial setup
        handleResize();

        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // get query data
    const getQueryData = async () => {
        try {
            // setLoading(true);
            const url = `${ApiUrl.BaseUrl}/mainorders/querydata?country=${country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setMainRetailer(answer.customer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retailerOptions = mainRetailer?.map((item) => {
        return { label: item?.partnerName, value: item?._id };
    });

    // get retailer profile
    const getRetailerProfile = async (data) => {
        try {
            setLoading(true);
            const url = `${ApiUrl.BaseUrl}/retailerprofiles/retailerprofile?retailerId=${data}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const info = decrypt(data.encrypted, keyResult, ivResult);
                        const infoResult = JSON.parse(info);
                        const order = decrypt(data.encrypteds, keyResult, ivResult);
                        const orderResult = JSON.parse(order);
                        setProfile(infoResult);
                        setOrders(orderResult);
                        const analytics = decrypt(data.analyticsData, keyResult, ivResult);
                        const analyticResult = JSON.parse(analytics);
                        const currentsLoyalty = decrypt(data.currentsLoyalty, keyResult, ivResult);
                        const currentsLoyaltyResult = JSON.parse(currentsLoyalty);
                        const previousLoyalty = decrypt(data.previousLoyalty, keyResult, ivResult);
                        const previousLoyaltyResult = JSON.parse(previousLoyalty);
                        setCurrentsLoyalty(currentsLoyaltyResult);
                        setPreviousLoyalty(previousLoyaltyResult);
                        setAnalytics(analyticResult);
                        setProfileId(infoResult._id);
                    } else {
                        // const error = decrypt(data.data, keyResult, ivResult);
                        // const answer = JSON.parse(error);
                        // console.error(data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const getRetailerNotes = async (id) => {
        try {
            const url = `${ApiUrl.BaseUrl}/crm/fetch-partner-notes?retailer_id=${id}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setRetailerNotes(answer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    // setLoadingRetailerNotes(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getQueryData();
    }, []);

    useEffect(() => {
        const storedRetailerId = localStorage.getItem('selectedRetailerId');
        if (storedRetailerId) {
            getRetailerProfile(storedRetailerId); // Fetch retailer profile using the ID from localStorage
            getRetailerNotes(storedRetailerId);
        } else {
            // If no value is found in localStorage, set default value
            const defaultRetailerId = params?.id;
            getRetailerProfile(defaultRetailerId); // Fetch retailer profile using the default ID
            getRetailerNotes(defaultRetailerId);
        }
    }, [params?.id, noteSuccess, profileId]);

    // Function to handle profile selection
    const handleProfileSelection = (selectedRetailerId) => {
        // Set the selected profile ID to localStorage
        localStorage.setItem('selectedRetailerId', selectedRetailerId);
        // Fetch and set retailer profile
        getRetailerProfile(selectedRetailerId);
    };

    // filter retailer
    const filterRetailer = (value) => {
        handleProfileSelection(value); // Use handleProfileSelection instead of getRetailerProfile directly
    };

    // Function to move to the next shop
    const nextSlide = () => {
        setActiveIndex((prevIndex) => (prevIndex === profile?.shopsdata?.length - 1 ? 0 : prevIndex + 1));
    };

    const prevSlide = () => {
        setActiveIndex((prevIndex) => (prevIndex === 0 ? profile?.shopsdata?.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setActiveIndex((prevIndex) => Math.min(prevIndex + 1, 3)); // Assuming 4 items in total
    };

    const handlePrevious = () => {
        setActiveIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    // add Comment
    const addComment = async () => {
        const userInfo = localStorage.getItem('userToken');

        const data = {
            retailerId: profile?._id,
            notes: comment
        };

        const obj = JSON.stringify(data);
        const result = encrypt(obj, keyResult, ivResult);

        await fetch(`${ApiUrl.BaseUrl}/retailerprofiles/addcomment`, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ payload: result })
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setCommentSuccess(!commentSuccess);
                    openNotification('bottom');
                    handleCommentOk();
                } else if (result.status === false) {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(result);
                    toast.error(answer);

                    handleCommentCancel();
                } else {
                    console.error(result, 'error');

                    handleCommentCancel();
                }
            })
            .finally(() => {
                setComment('');
            });
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            addComment();
        }
    };

    const handleMouseEnter = (index) => {
        setItems((prevItems) => prevItems.map((item, i) => (i === index ? { ...item, isHovered: true } : item)));
    };

    const handleMouseLeave = (index) => {
        setItems((prevItems) => prevItems.map((item, i) => (i === index ? { ...item, isHovered: false } : item)));
    };

    const handleSourceSelect = (index) => {
        setItems((prevItems) => {
            return prevItems.map((item, i) => {
                if (i === index) {
                    return { ...item, isSelected: true };
                }
                return { ...item, isSelected: false }; // Deselect other sources
            });
        });
    };

    // useEffect(() => {
    //     const handleKeyUp = (event) => {
    //         handleKeyPress(event); // Call handleKeyPress directly
    //     };

    //     document.addEventListener('keyup', handleKeyUp);

    //     // Cleanup the event listener on component unmount
    //     return () => {
    //         document.removeEventListener('keyup', handleKeyUp);
    //     };
    // }, [addComment]);

    const AddNewNote = async (event) => {
        const userInfo = localStorage.getItem('userToken');
        setIsAddingNote(true);

        const data = {
            source,
            note,
            country,
            retailer_id: profile?._id
        };

        const obj = JSON.stringify(data);
        const result = encrypt(obj, keyResult, ivResult);

        await fetch(`${ApiUrl.BaseUrl}/crm/store-note`, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ payload: result })
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    openNotification('bottom');
                    setNoteSuccess(!noteSuccess);
                } else if (result.status === false) {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                } else {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                }
            })
            .finally(() => {
                setIsAddingNote(false);
                setNote('');
                setOpenSource(false);
            });
    };

    const retailerInfoBody = (
        <Box
            style={{
                borderRadius: '16px',
                margin: '5px',
                display: 'flex',
                padding: '18px 0px',
                flexDirection: 'column',
                height: '100%',
                overflowY: 'scroll',
                overflowX: 'hidden',
                position: 'relative'
            }}
            className="collection-modals-y"
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {' '}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AccountCircleIcon sx={{ fontSize: '48px', color: '#8C9DAB' }} />
                    <Box
                        sx={{
                            margin: '0px 0 0 5px'
                        }}
                    >
                        <Box
                            sx={{
                                fontSize: '20px',
                                fontWeight: '500',
                                color: '#3B5460'
                            }}
                        >
                            {profile?.retailerName}
                        </Box>
                        <Box
                            sx={{
                                fontSize: '14px',
                                fontWeight: '400',
                                color: '#546974'
                            }}
                        >
                            Retailer’s name
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    // justifyContent: 'flex-start',
                    // alignItems: 'flex-start',
                    flexDirection: 'column'
                    // margin: '0px auto 15px'
                }}
            >
                {' '}
                <Box sx={{ display: 'flex', alignItems: 'center', margin: '11px 0 0 5px' }}>
                    <PhoneIcon
                        sx={{
                            fontSize: '32px',
                            color: '#3B5460',
                            backgroundColor: '#F6F6F6',
                            padding: '5px',
                            borderRadius: '100%'
                        }}
                    />
                    <Box>
                        <Box
                            sx={{
                                fontSize: '14px',
                                fontWeight: '400',
                                color: '#3B5460',
                                margin: '0 0 0 16px'
                            }}
                        >
                            {profile?.contactNumber1}
                        </Box>
                    </Box>
                </Box>
                {profile?.contactNumber2 && (
                    <Box sx={{ display: 'flex', alignItems: 'center', margin: '11px 0 0 5px' }}>
                        <PhoneIcon
                            sx={{
                                fontSize: '32px',
                                color: '#3B5460',
                                backgroundColor: '#F6F6F6',
                                padding: '5px',
                                borderRadius: '100%'
                            }}
                        />
                        <Box>
                            <Box
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    color: '#3B5460',
                                    margin: '0 0 0 16px'
                                }}
                            >
                                {profile?.contactNumber2}
                            </Box>
                        </Box>
                    </Box>
                )}
                {profile?.contactNumber3 && (
                    <Box sx={{ display: 'flex', alignItems: 'center', margin: '11px 0 0 5px' }}>
                        <PhoneIcon
                            sx={{
                                fontSize: '32px',
                                color: '#3B5460',
                                backgroundColor: '#F6F6F6',
                                padding: '5px',
                                borderRadius: '100%'
                            }}
                        />
                        <Box>
                            <Box
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    color: '#3B5460',
                                    margin: '0 0 0 16px'
                                }}
                            >
                                {profile?.contactNumber3}
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {' '}
                <Box sx={{ display: 'flex', alignItems: 'center', margin: '11px 0 0 5px' }}>
                    <AccountCircleIcon
                        sx={{
                            fontSize: '32px',
                            color: '#3B5460',
                            backgroundColor: '#F6F6F6',
                            padding: '5px',
                            borderRadius: '100%'
                        }}
                    />
                    <Box>
                        <Box
                            sx={{
                                fontSize: '14px',
                                fontWeight: '400',
                                color: '#3B5460',
                                margin: '0 0 0 16px'
                            }}
                        >
                            {profile?.gender}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',

                    flexDirection: 'column'
                }}
            >
                {' '}
                <Box sx={{ display: 'flex', alignItems: 'center', margin: '11px 0 0 5px' }}>
                    <CalendarTodayRoundedIcon
                        sx={{
                            fontSize: '32px',
                            color: '#3B5460',
                            backgroundColor: '#F6F6F6',
                            padding: '5px',
                            borderRadius: '100%'
                        }}
                    />
                    <Box>
                        <Box
                            sx={{
                                fontSize: '14px',
                                fontWeight: '400',
                                color: '#3B5460',
                                margin: '0 0 0 16px'
                            }}
                        >
                            Date of birth: N/A
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    width: '100%',
                    margin: '24px 0 0 0'
                }}
            >
                <Box
                    sx={{
                        fontSize: '16px',
                        color: '#3B5460',
                        margin: '0 0 0 10px ',
                        fontWeight: '400'
                    }}
                >
                    ID Card
                </Box>
                <Box
                    style={{
                        backgroundColor: '#ECEDEF',
                        borderRadius: '16px',
                        margin: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        height: '165px',
                        width: '100%'
                    }}
                />
            </Box>
            {/* <Box
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    width: '100%',
                    margin: '24px 0 0 0'
                }}
            >
                <Box
                    sx={{
                        fontSize: '16px',
                        color: '#3B5460',
                        margin: '0 0 0 10px ',
                        fontWeight: '400'
                    }}
                >
                    KC
                </Box>
                <Box
                    style={{
                        backgroundColor: '#ECEDEF',
                        borderRadius: '16px',
                        // width: '300px',
                        margin: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        height: '165px',
                        width: '100%'
                    }}
                />
            </Box> */}
        </Box>
    );

    const commentBody = (
        <Box
            sx={{
                margin: '5px'
            }}
        >
            <TextArea
                rows={4}
                placeholder="Type here ..."
                onChange={(e) => {
                    setComment(e.target.value);
                }}
                onKeyUp={handleKeyPress}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', margin: '20px 0 0 0' }}>
                <Button
                    style={{
                        backgroundColor: '#FF740F',
                        borderRadius: '8px',
                        height: '48px',
                        fontSize: '18px',
                        fontWeight: '700',
                        color: '#FFFFFF',
                        width: '154px',
                        textAlign: 'center'
                    }}
                    onClick={addComment}
                >
                    Add comment
                </Button>
            </Box>
        </Box>
    );

    const orderDetailsBody = (
        <div
            style={{ height: '500px', width: '100%', overflowY: 'scroll', overflowX: 'hidden', position: 'relative' }}
            className="collection-modals-y"
        >
            <table id="retailer-details-table" style={{ width: '100%', margin: '24px 0 0 0' }}>
                <thead style={{ position: 'sticky', backgroundColor: '#fff', width: '100%', top: '0' }}>
                    <tr>
                        <th>Product Name</th>
                        <th>Quantity</th>
                        <th> Value</th>
                    </tr>
                </thead>
                {orders?.length > 0 ? (
                    <tbody>
                        {orders
                            ?.filter((order) => order._id === getOrderId)
                            .map((order) =>
                                order.items?.map((item) => (
                                    <tr key={item?._id}>
                                        <td>{item?.partid}</td>
                                        <td>{item?.qty}</td>
                                        <td>{twoDecimalPlaces(item?.qty * item.garagePrice)}</td>
                                    </tr>
                                ))
                            )}
                    </tbody>
                ) : (
                    <EmptyState height="204px" width="80%" text="No results yet" />
                )}
            </table>
        </div>
    );

    const otherRetailerShops = (
        <Box
            sx={{
                flexGrow: '1 ',
                display: 'flex',
                overflowX: 'hidden'
            }}
        >
            {profile?.shopsdata?.map((shop, index) => (
                <Box
                    style={{
                        backgroundColor: '#FFFFFF',
                        borderRadius: '16px',

                        // width: '387px',
                        border: '1px solid #FFFFFF',
                        margin: '5px',
                        display: 'flex',

                        // alignItems: 'center',
                        // justifyContent: 'flex-start',
                        height: '170px',
                        padding: '18px 20px',
                        flexDirection: 'column',
                        transform: `translateX(${(index - activeIndex) * 100}%)`, // Apply translation for sliding effect
                        transition: 'transform 0.5s ease-in-out' // Smooth transition animation

                        // flexBasis: '200px'
                    }}
                    key={shop?._id}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            // justifyContent: 'flex-start',
                            // alignItems: 'flex-start',
                            flexDirection: 'column'
                            // margin: '0px auto 15px'
                        }}
                    >
                        {' '}
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <StorefrontRoundedIcon sx={{ fontSize: '48px', color: '#8C9DAB' }} />
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: '20px',
                                            fontWeight: '500',
                                            color: '#3B5460'
                                        }}
                                    >
                                        {shop?.shopname || 'N/A'}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: '#546974'
                                        }}
                                    >
                                        Shop name
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography sx={{ fontSize: '14px', color: '#434A5B' }}>
                                {profile?.shopsdata?.length === 0
                                    ? ''
                                    : !Number(profile?.shopsdata?.length)
                                    ? null
                                    : profile?.shopsdata?.length + 1}{' '}
                                {profile?.shopsdata?.length > 0 ? 'shops' : null}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            // justifyContent: 'flex-start',
                            // alignItems: 'flex-start',
                            flexDirection: 'column',
                            margin: '0px 0 0 15px'
                        }}
                    >
                        {' '}
                        <Box sx={{ display: 'flex', alignItems: 'center', margin: '11px 0 0 0' }}>
                            <LocationOnRoundedIcon
                                sx={{
                                    fontSize: '20px',
                                    color: '#3B5460',
                                    backgroundColor: '#F6F6F6',
                                    padding: '5px',
                                    borderRadius: '100%'
                                }}
                            />
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#3B5460',
                                        margin: '0 0 0 16px'
                                    }}
                                >
                                    {shop?.shoplocation}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            flexDirection: 'column'
                            // margin: '0px auto 15px'
                        }}
                    >
                        {' '}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '12px 0 0 0',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}
                        >
                            {/* <CallMadeIcon
                sx={{
                    fontSize: '20px',
                    color: '#FF740F',
                    padding: '5px',
                    borderRadius: '100%',
                    backgroundColor: '#F6F6F6'
                }}
            /> */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: '400',
                                        color: '#FF740F',
                                        margin: '0 0 0 16px'
                                    }}
                                >
                                    View location
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: '400',
                                        color: '#FF740F',
                                        margin: '0 0 0 16px',
                                        backgroundColor: '#FFF1E7',
                                        height: '40px',
                                        width: '60px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '4px'
                                    }}
                                >
                                    <NavigateBeforeRoundedIcon
                                        sx={{
                                            color: '#FF740F',
                                            fontSize: '18px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={prevSlide}
                                    />
                                    <NavigateNextRoundedIcon
                                        sx={{
                                            color: '#FF740F',
                                            fontSize: '18px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={nextSlide}
                                    />
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            ))}
        </Box>
    );

    return (
        <>
            {contextHolder}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '1px dashed #E7EAEB',
                    alignItems: 'flex-start',
                    margin: '0px auto 15px',
                    flexWrap: 'wrap'
                }}
            >
                {' '}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '0 0 16px 0',
                        alignItems: 'flex-start'
                        // flexDirection: 'column'
                        // margin: '0px auto 15px'
                    }}
                >
                    <WestIcon
                        sx={{
                            fontSize: '32px',
                            cursor: 'pointer',
                            margin: '12px 10px 0 0',
                            '&:hover': {
                                color: '#546974'
                            },
                            '&:focus': {
                                color: '#9DA9AF'
                            }
                        }}
                        onClick={() => navigate(-1)}
                    />{' '}
                    <List
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            // padding: '0 0 16px 0',
                            alignItems: 'flex-start',
                            flexDirection: 'column'
                            // margin: '0px auto 15px'
                        }}
                    >
                        <ListItem sx={{ fontWeight: 500, fontSize: '1.75rem', color: '#0A2938', padding: 0 }}>
                            Retailer’s profile{' '}
                            <span role="img" aria-label="👋🏽">
                                👋🏽
                            </span>
                        </ListItem>
                        <ListItem sx={{ fontSize: '0.75rem', color: '#565C6B', padding: 0 }}>View information about our retailers</ListItem>
                    </List>
                </Box>
                <Box
                    sx={{
                        // margin: '20px 0',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        padding: '0 0 16px 0'
                    }}
                >
                    <Box
                        sx={{
                            width: ' 277px',
                            margin: '24px 5px 0 5px',
                            flexGrow: '1 '
                            // flexBasis: '200px'
                        }}
                    >
                        <Select
                            style={{
                                width: '100%'
                            }}
                            size="large"
                            placeholder="Search retailer"
                            options={retailerOptions}
                            onChange={(value) => filterRetailer(value)}
                            value={profileId || 'Search retailer'}
                            filterOption={(inputValue, option) => {
                                if (typeof option.label === 'string') {
                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }

                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }}
                            showSearch
                        />
                    </Box>
                    <Button
                        onClick={() => navigate(`/retailers/analytics/${profile?._id}`)}
                        type="primary"
                        size="large"
                        style={{
                            backgroundColor: '#0A2938',
                            borderRadius: '12px',
                            fontSize: '16px',
                            fontWeight: '400',
                            color: '#fff',
                            width: '161px',
                            margin: '24px 5px 0 5px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '38px',
                            flexGrow: '1 '
                            // flexBasis: '200px'
                        }}
                        className="hover-secondary-button-dark"
                    >
                        View analytics
                    </Button>

                    <Button
                        type="primary"
                        size="large"
                        style={{
                            backgroundColor: '#FF740F',
                            borderRadius: '12px',
                            fontSize: '16px',
                            fontWeight: '400',
                            color: '#fff',
                            width: '161px',
                            margin: '24px 5px 0 5px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '38px',
                            flexGrow: '1 '
                            // flexBasis: '200px'
                        }}
                        className="hover-secondary-button-orange"
                        onClick={() => navigate(`/retailers/report/${profile?._id}`)}
                    >
                        Generate report
                    </Button>
                </Box>
            </Box>
            {loading ? (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '4000'
                    }}
                >
                    {antIcon}
                </Box>
            ) : profile?._id?.length > 0 ? (
                <>
                    <Box
                        sx={{
                            margin: '24px 0px 0 0px',
                            // display: 'flex',
                            // alignItems: 'center',
                            // flexWrap: 'wrap'
                            display: 'grid',
                            gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
                            gap: '10px'
                        }}
                    >
                        <Box
                            style={{
                                backgroundColor: '#FFFFFF',
                                borderRadius: '16px',
                                // flexGrow: '1 ',
                                border: '1px solid #FFFFFF',
                                margin: '5px',
                                display: 'flex',

                                // height: '170px',
                                padding: '18px 20px',
                                flexDirection: 'column'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                {' '}
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <PersonOutlineIcon
                                        sx={{
                                            fontSize: '48px',
                                            color: '#8C9DAB',
                                            backgroundColor: '#F6F6F6',
                                            padding: '10px',
                                            borderRadius: '100%'
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            margin: '0 0 0 10px'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '20px',
                                                fontWeight: '500',
                                                color: '#3B5460'
                                            }}
                                        >
                                            {profile?.retailerName}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                fontWeight: '400',
                                                color: '#546974'
                                            }}
                                        >
                                            Retailer’s name
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',

                                    flexDirection: 'column'
                                }}
                            >
                                {' '}
                                <Box sx={{ display: 'flex', alignItems: 'center', margin: '11px 0 0 15px' }}>
                                    <PhoneIcon
                                        sx={{
                                            fontSize: '32px',
                                            color: '#8C9DAB',
                                            backgroundColor: '#F6F6F6',
                                            padding: '7px',
                                            borderRadius: '100%',
                                            transform: ' translateX(-5px)'
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            margin: '0 0 0 10px'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: '400',
                                                color: '#3B5460'
                                            }}
                                        >
                                            {profile?.contactNumber1}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    showRetailerModal();
                                }}
                            >
                                {' '}
                                <Box sx={{ display: 'flex', alignItems: 'center', margin: '12px 0 0 15px' }}>
                                    <CallMadeIcon
                                        sx={{
                                            fontSize: '32px',
                                            color: '#FF740F',
                                            padding: '7px',
                                            borderRadius: '100%',
                                            backgroundColor: '#F6F6F6',
                                            transform: ' translateX(-5px)'
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            margin: '0 0 0 10px'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: '400',
                                                color: '#FF740F'
                                            }}
                                        >
                                            View more details
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            style={{
                                backgroundColor: '#FFFFFF',
                                borderRadius: '16px',
                                // flexGrow: '1 ',
                                border: '1px solid #FFFFFF',
                                margin: '5px',
                                display: 'flex',

                                // height: '170px',
                                padding: '18px 20px',
                                flexDirection: 'column'
                                // transform: `translateX(${(profile?.shopsdata?.length - activeIndex) * 100}%)`,
                                // transition: 'transform 0.5s ease-in-out'

                                // flexBasis: '200px'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    // justifyContent: 'flex-start',
                                    // alignItems: 'flex-start',
                                    flexDirection: 'column'
                                    // margin: '0px auto 15px'
                                }}
                            >
                                {' '}
                                <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <StorefrontRoundedIcon
                                            sx={{
                                                fontSize: '48px',
                                                color: '#8C9DAB',
                                                margin: '0 8px 0 0 ',
                                                backgroundColor: '#F6F6F6',
                                                padding: '10px',
                                                borderRadius: '100%'
                                            }}
                                        />
                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontSize: '20px',
                                                    fontWeight: '500',
                                                    color: '#3B5460'
                                                }}
                                            >
                                                {profile?.shopName || 'N/A'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: '400',
                                                    color: '#546974'
                                                }}
                                            >
                                                Shop name
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Typography sx={{ fontSize: '14px', color: '#434A5B' }}>
                                        {profile?.shopsdata?.length === 0
                                            ? ''
                                            : !Number(profile?.shopsdata?.length)
                                            ? null
                                            : profile?.shopsdata?.length + 1}{' '}
                                        {profile?.shopsdata?.length > 0 ? 'shops' : null}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',

                                    flexDirection: 'column',
                                    margin: '0px 0 0 15px'
                                }}
                            >
                                {' '}
                                <Box sx={{ display: 'flex', alignItems: 'center', margin: '11px 0 0 0' }}>
                                    <LocationOnRoundedIcon
                                        sx={{
                                            color: '#8C9DAB',
                                            backgroundColor: '#F6F6F6',
                                            borderRadius: '100%',
                                            fontSize: '32px',
                                            padding: '7px',
                                            transform: ' translateX(-5px)'
                                        }}
                                    />
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: '400',
                                                color: '#3B5460',
                                                margin: '0 0 0 10px'
                                            }}
                                        >
                                            {profile?.address}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',

                                    // flexDirection: 'column',
                                    flexWrap: 'wrap',
                                    margin: '0px 0 0 15px'
                                }}
                                className="retailer-pod-continer"
                            >
                                {' '}
                                <Box sx={{ display: 'flex', alignItems: 'center', margin: '11px 0 0 0' }}>
                                    <CallMadeIcon
                                        sx={{
                                            color: '#FF740F',
                                            backgroundColor: '#F6F6F6',
                                            borderRadius: '100%',
                                            fontSize: '32px',
                                            padding: '7px',
                                            transform: ' translateX(-5px)'
                                        }}
                                    />
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: '400',
                                                color: '#FF740F',
                                                margin: '0 0 0 10px',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => {
                                                navigate(`/retailers/map/${profile?._id}`);
                                            }}
                                        >
                                            View location
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', margin: '11px 0 0 15px' }} className="retailer-pod ">
                                    <NearMeIcon
                                        sx={{
                                            color: '#FF740F',
                                            backgroundColor: '#F6F6F6',
                                            borderRadius: '100%',
                                            fontSize: '32px',
                                            padding: '7px',
                                            transform: ' translateX(-5px)'
                                        }}
                                    />
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: '500',
                                                color: '#3B5460',
                                                margin: '0 0 0 10px',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => {
                                                navigate(`/retailers/map/${profile?._id}`);
                                            }}
                                        >
                                            Pod: {profile?.pod?.name}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column'
                                }}
                            >
                                {/* {profile?.shopsdata?.length > 0 ? (
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: '400',
                                            color: '#FF740F',
                                            margin: '0 0 0 16px',
                                            backgroundColor: '#FFF1E7',
                                            height: '40px',
                                            width: '60px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: '4px'
                                        }}
                                    >
                                        <NavigateBeforeRoundedIcon
                                            sx={{
                                                color: '#FF740F',
                                                fontSize: '18px',
                                                cursor: 'pointer'
                                            }}
                                            onClick={prevSlide}
                                        />
                                        <NavigateNextRoundedIcon
                                            sx={{
                                                color: '#FF740F',
                                                fontSize: '18px',
                                                cursor: 'pointer'
                                            }}
                                            onClick={nextSlide}
                                        />
                                    </Typography>
                                ) : null} */}
                            </Box>
                        </Box>

                        {/* {profile?.shopsdata?.length > 0 ? otherRetailerShops : null} */}
                        <Box
                            style={{
                                backgroundColor: '#FFF2D7',
                                borderRadius: '16px',
                                // flexGrow: '1 ',
                                // width: '300px',
                                border: '1px solid #FFF2D7',
                                margin: '5px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                flexDirection: 'column'
                                // height: '170px',
                                // flexBasis: '360px'
                            }}
                        >
                            <Box
                                sx={{
                                    borderBottom: '1px dashed #E7EAEB',
                                    padding: '16px 70px'
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: '#0A2938',
                                        fontSize: '20px',
                                        fontWeight: '600',
                                        textAlign: 'center'
                                    }}
                                >
                                    Current tier: {currentsLoyalty?.title ? currentsLoyalty?.title : `TIER 0`}
                                </Typography>
                                <Typography
                                    sx={{
                                        color: '#546974',
                                        fontSize: '14px',
                                        textAlign: 'center'
                                    }}
                                >
                                    Total discount received: {currentsLoyalty?.reward ? currentsLoyalty?.reward : 0.0}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    padding: '16px 10px',
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: '#FFFFFF',
                                        color: '#0A2938',
                                        fontSize: '20px',
                                        fontWeight: '600',
                                        padding: '8px 12px',
                                        borderRadius: '12px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: '#F6F6F6',
                                            margin: '0 8px 0 0',
                                            width: '32px',
                                            height: '32px',
                                            borderRadius: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <img src={badge} alt="" />
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'flex-start',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: '#0A2938',
                                                fontSize: '14px',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {currentsLoyalty?.title ? currentsLoyalty?.title : `TIER 0`}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: '#546974',
                                                fontSize: '12px',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {currentsLoyalty?.year ? currentsLoyalty?.year : new Date().getFullYear()} loyalty tier
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        // backgroundColor: '#FFFFFF',
                                        color: '#0A2938',
                                        fontSize: '20px',
                                        fontWeight: '600',
                                        padding: '8px 12px',
                                        borderRadius: '12px',
                                        display: 'flex'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: '#FFFFFF',
                                            margin: '0 8px 0 0',
                                            width: '32px',
                                            height: '32px',
                                            borderRadius: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <img src={badge} alt="" />
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'flex-start',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: '#0A2938',
                                                fontSize: '14px',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {previousLoyalty?.title ? previousLoyalty?.title : `TIER 0`}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: '#546974',
                                                fontSize: '12px',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {previousLoyalty?.year ? previousLoyalty?.year : new Date().getFullYear() - 1} loyalty tier
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {/* <Box
                sx={{
                    margin: '24px 0px 0 0px',
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: containerWidth === 'true' ? 'wrap' : 'nowrap'
                }}
            >
                <Box
                    sx={{
                        flexGrow: '1',
                        margin: '5px',
                        flexBasis: '510px'
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: '#fff',
                            borderRadius: '16px',
                            padding: '12px ',
                            height: '530px',
                            overflow: 'hidden',
                            position: 'relative'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                borderBottom: ' 1px dashed #E7EAEB',
                                padding: '5px 0 16px 0'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '137px'
                                }}
                            >
                                <Person sx={{ fontSize: '20px', color: '#8C9DAB', margin: '0 3px 0 0px' }} />
                                <Typography sx={{ color: '#3B5460', fontSize: '20px' }}>NPS rating</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '137px'
                                }}
                            >
                                <StarIcon sx={{ fontSize: '20px', color: '#FF740F', margin: '0 3px 0 0px' }} />
                                <Typography sx={{ color: '#3B5460', fontSize: '20px' }}>9-Excellent</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        flexGrow: '1',
                        margin: '5px',
                        flexBasis: '600px'
                    }}
                >
                    <Box
                        sx={{
                            // height: '508px',
                            backgroundColor: '#fff',
                            borderRadius: '16px',
                            padding: '12px 20px',
                            height: '530px',
                            overflowY: 'scroll',
                            overflowX: 'hidden'
                        }}
                        className="collection-modals-y"
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                borderBottom: ' 1px dashed #E7EAEB',
                                padding: '5px 0 16px 0'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '137px'
                                }}
                            >
                                <NoteAltIcon sx={{ fontSize: '16px', color: '#193B56', margin: '0 5px 0 0px' }} />
                                <Typography sx={{ color: '#3B5460', fontSize: '16px' }}>Notes</Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '137px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => navigate(`/retailers/notes/${profile?._id}`)}
                            >
                                <Typography sx={{ fontSize: '16px', color: '#FF740F' }}>All notes</Typography>
                                <CallMadeIcon sx={{ fontSize: '16px', color: '#FF740F', margin: '0 0px 0 5px' }} />
                            </Box>
                        </Box>

                        <Box
                            style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                width: '100%',
                                margin: '24px 0 0 0'
                            }}
                        >
                            <Box
                                style={{
                                    backgroundColor: '#F6F6F6',
                                    borderRadius: '16px',
                                    margin: '0 5px',
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    height: '200px',
                                    width: '100%',
                                    padding: '10px',
                                    overflow: 'hidden'
                                }}
                            >
                                <TextArea
                                    rows={4}
                                    placeholder="Type here ..."
                                    onChange={(e) => {
                                        setComment(e.target.value);
                                    }}
                                    onKeyUp={handleKeyPress}
                                    style={{
                                        backgroundColor: '#F6F6F6',
                                        borderRadius: '16px',
                                        margin: '5px',
                                        border: 'none',
                                        height: '135px',
                                        width: '100%'
                                    }}
                                    autoSize={{
                                        minRows: 5,
                                        maxRows: 6
                                    }}
                                />
                                <Dropdown
                                    menu={{
                                        items
                                    }}
                                    trigger={['click']}
                                >
                                    <Typography
                                        sx={{
                                            border: '1px solid #FFD5B7',
                                            height: '40px',
                                            width: '132px',
                                            padding: '10px 12px',
                                            borderRadius: '8px',
                                            color: '#FF740F',
                                            fontWight: '500',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            fontSize: '16px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            margin: '10px 0'
                                        }}
                                    >
                                        Add source <KeyboardArrowDownIcon sx={{ width: '16px', color: '#FF740F' }} />
                                    </Typography>
                                </Dropdown>
                            </Box>

                            <Box
                                style={{
                                    backgroundColor: '#ECEDEF',
                                    borderRadius: '16px',
                                    margin: '5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flexDirection: 'column',
                                    height: '165px',
                                    width: '100%',
                                    padding: '15px'
                                }}
                            >
                                {profile?.notes?.length > 0 ? (
                                    <>
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                color: '#546974',
                                                overflowWrap: 'break-word'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    textAlign: 'start'
                                                }}
                                            >
                                                {profile?.notes}
                                            </Box>
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                margin: '16px 0 0 0',
                                                width: '100%'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#85949C',
                                                    fontSize: '12px'
                                                }}
                                            >
                                                Source:
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: '#85949C',
                                                        fontSize: '12px',
                                                        margin: ' 0 10px 0 0'
                                                    }}
                                                >
                                                    Added by:
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: '#85949C',
                                                        fontSize: '12px'
                                                    }}
                                                >
                                                   
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </>
                                ) : (
                                    <>
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                color: '#546974',
                                                overflowWrap: 'break-word'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    textAlign: 'center'
                                                }}
                                            >
                                                Add Note!
                                            </Box>
                                        </Typography>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box> */}

                    <Box
                        sx={{
                            // margin: '24px 0px 0 0px',
                            // display: 'flex',
                            // alignItems: 'flex-start',

                            // flexWrap: containerWidth === 'true' ? 'wrap' : 'nowrap'
                            margin: '24px 0px 0 0px',
                            alignItems: 'flex-start',
                            display: 'grid',
                            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)']
                            // gap: '10px'
                        }}
                    >
                        <Box
                            sx={{
                                flexGrow: '1',
                                margin: '5px'
                                // minWidth: '200px'
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#546974',
                                    fontSize: '20px',
                                    fontWeight: '400',
                                    margin: '0 0 10px 0'
                                }}
                            >
                                Order history
                            </Typography>
                            <Box
                                sx={{
                                    // height: '508px',
                                    // width: '591px',
                                    backgroundColor: '#fff',
                                    borderRadius: '16px',
                                    padding: '12px ',
                                    height: '530px',
                                    overflow: 'hidden',
                                    position: 'relative'
                                    // display: 'flex',
                                    // justifyContent: 'center',
                                    // alignItems: 'center'
                                    // overflowX: 'scroll',
                                    // scrollSnapType: 'x mandatory',
                                    // whiteSpace: 'nowrap'
                                }}
                                // className="collection-modals-x"
                            >
                                {orders.length > 0 ? (
                                    <table id="retailer-table" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Order Value</th>
                                                <th>Drive Type</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orders.map((order) => (
                                                <tr key={order?._id}>
                                                    <td>{dateConverter(order?.orderDate)}</td>
                                                    <td>{twoDecimalPlaces(order?.expCashCollected)}</td>
                                                    <td>{order?.orderTypId}</td>
                                                    <td>
                                                        <Box
                                                            sx={{
                                                                margin: '2px',
                                                                height: '40px',
                                                                padding: '10px 12px',
                                                                borderRadius: ' 8px',
                                                                color: ' #ff740f',
                                                                fontWeight: '500',
                                                                textAlign: 'center',
                                                                cursor: 'pointer',
                                                                fontSize: '14px',
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}
                                                            onClick={() => {
                                                                showOderDetailesModal();
                                                                setGetOrderId(order?._id);
                                                            }}
                                                        >
                                                            <Typography sx={{ fontSize: '16px', fontWeight: '400' }}>Details</Typography>
                                                            <KeyboardArrowRightIcon sx={{ fontSize: '16px', fontWeight: '900' }} />
                                                        </Box>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <EmptyState height="204px" width="80%" text="No results yet" />
                                )}

                                {orders.length > 0 ? (
                                    <Link
                                        to={`/retailers/order-history/${profile?._id}`}
                                        style={{
                                            // margin: '2px',
                                            height: '40px',
                                            padding: '10px 12px',
                                            // borderRadius: ' 8px',
                                            color: ' #ff740f',
                                            fontWeight: '500',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            fontSize: '14px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: '#fff',
                                            borderRadius: '0  0 16px 16px',
                                            width: '100%',
                                            position: 'absolute',
                                            bottom: '-10px'
                                        }}
                                    >
                                        <Typography sx={{ fontSize: '16px', fontWeight: '400' }}>See more</Typography>

                                        <KeyboardArrowRightIcon sx={{ fontSize: '16px', fontWeight: '900' }} />
                                    </Link>
                                ) : null}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                flexGrow: '1',
                                margin: '5px'
                                // flexBasis: '600px'
                            }}
                        >
                            <Box
                                sx={{
                                    flexGrow: '1',
                                    margin: '5px'
                                    // flexBasis: '600px'
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: '#546974',
                                        fontSize: '20px',
                                        fontWeight: '400',
                                        margin: '0 0 10px 0'
                                    }}
                                >
                                    Retailer history
                                </Typography>
                                <Box
                                    sx={{
                                        backgroundColor: '#fff',
                                        borderRadius: '16px',
                                        padding: '12px 20px',
                                        // height: '304px',
                                        overflowY: 'scroll',
                                        overflowX: 'hidden'
                                    }}
                                    className="order-history-modals-y"
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            borderBottom: ' 1px dashed #E7EAEB',
                                            padding: '24px 0'
                                        }}
                                    >
                                        <AccountCircleIcon sx={{ fontSize: '32px', color: '#8C9DAB', margin: '0 2px 0 0' }} />
                                        <Typography
                                            sx={{
                                                fontSize: '18px',
                                                color: '#3B5460',
                                                fontWeight: '400'
                                            }}
                                        >
                                            Conversion rating: {profile?.rating}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            borderBottom: ' 1px dashed #E7EAEB',
                                            justifyContent: 'space-between',
                                            flexWrap: containerWidth === 'true' ? 'wrap' : 'nowrap',
                                            padding: '24px 0'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                // justifyContent: 'space-between',
                                                // alignItems: 'flex-start',
                                                flexDirection: 'column'
                                                // margin: '0px auto 15px'
                                            }}
                                        >
                                            {' '}
                                            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: '1' }}>
                                                <Box
                                                    sx={{
                                                        backgroundColor: '#DBE2FF',
                                                        margin: '0 8px 0 0',
                                                        width: '32px',
                                                        height: '32px',
                                                        borderRadius: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <CalendarMonthRoundedIcon sx={{ fontSize: '16px', color: '#4B6EFF' }} />
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '18px',
                                                            fontWeight: '500',
                                                            color: '#3B5460',
                                                            margin: '5px  0 0 0 '
                                                        }}
                                                    >
                                                        {dateConverter(profile?.signupDate)}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '14px',
                                                            fontWeight: '400',
                                                            color: '#546974'
                                                        }}
                                                    >
                                                        Sign up date
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                // justifyContent: 'flex-start',
                                                // alignItems: 'flex-start',
                                                flexDirection: 'column'
                                                // margin: '0px auto 15px'
                                            }}
                                        >
                                            {' '}
                                            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: '1', width: '275px' }}>
                                                <Box
                                                    sx={{
                                                        backgroundColor: '#E6F7EE',
                                                        margin: '0 8px 0 0',
                                                        width: '32px',
                                                        height: '32px',
                                                        borderRadius: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <CalendarMonthRoundedIcon sx={{ fontSize: '16px', color: '#00AA55' }} />
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '18px',
                                                            fontWeight: '500',
                                                            color: '#3B5460',
                                                            margin: '5px  0 0 0 '
                                                        }}
                                                    >
                                                        {profile?.status} customer
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '14px',
                                                            fontWeight: '400',
                                                            color: '#546974'
                                                        }}
                                                    >
                                                        Status
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            borderBottom: ' 1px dashed #E7EAEB',
                                            justifyContent: 'space-between',
                                            flexWrap: containerWidth === 'true' ? 'wrap' : 'nowrap',
                                            padding: '24px 0'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                // justifyContent: 'space-between',
                                                // alignItems: 'flex-start',
                                                flexDirection: 'column'
                                                // margin: '0px auto 15px'
                                            }}
                                        >
                                            {' '}
                                            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: '1' }}>
                                                <Box
                                                    sx={{
                                                        backgroundColor: '#FDE7CF',
                                                        margin: '0 8px 0 0',
                                                        width: '32px',
                                                        height: '32px',
                                                        borderRadius: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <CalendarMonthRoundedIcon sx={{ fontSize: '16px', color: '#F5880E' }} />
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '18px',
                                                            fontWeight: '500',
                                                            color: '#3B5460',
                                                            margin: '5px  0 0 0 '
                                                        }}
                                                    >
                                                        N/A
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '14px',
                                                            fontWeight: '400',
                                                            color: '#546974'
                                                        }}
                                                    >
                                                        KYC completion date
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                // justifyContent: 'flex-start',
                                                // alignItems: 'flex-start',
                                                flexDirection: 'column'
                                                // margin: '0px auto 15px'
                                            }}
                                        >
                                            {' '}
                                            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: '1', width: '275px' }}>
                                                <Box
                                                    sx={{
                                                        backgroundColor: '#ECEDEF',
                                                        margin: '0 8px 0 0',
                                                        width: '32px',
                                                        height: '32px',
                                                        borderRadius: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <AccountCircleIcon sx={{ fontSize: '16px', color: '#696E7C' }} />
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '18px',
                                                            fontWeight: '500',
                                                            color: '#3B5460',
                                                            margin: '5px  0 0 0 '
                                                        }}
                                                    >
                                                        {profile?.salesPerson || 'N/A'}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '14px',
                                                            fontWeight: '400',
                                                            color: '#546974'
                                                        }}
                                                    >
                                                        Onboarding RMA
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    flexGrow: '1',
                                    margin: '5px',
                                    flexBasis: '600px'
                                }}
                            >
                                <Box
                                    sx={{
                                        // height: '508px',
                                        backgroundColor: '#fff',
                                        borderRadius: '16px',
                                        padding: '12px 20px',
                                        height: '530px',
                                        overflowY: 'scroll',
                                        overflowX: 'hidden'
                                    }}
                                    className="collection-modals-y"
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            borderBottom: ' 1px dashed #E7EAEB',
                                            padding: '5px 0 16px 0',
                                            width: '100%'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '137px'
                                            }}
                                        >
                                            <NoteAltIcon sx={{ fontSize: '16px', color: '#193B56', margin: '0 5px 0 0px' }} />
                                            <Typography sx={{ color: '#3B5460', fontSize: '16px' }}>Notes</Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => navigate(`/retailers/notes/${profile?._id}`)}
                                        >
                                            <Typography sx={{ fontSize: '16px', color: '#FF740F' }}>All notes</Typography>
                                            <CallMadeIcon sx={{ fontSize: '16px', color: '#FF740F', margin: '0 0px 0 5px' }} />
                                        </Box>
                                    </Box>

                                    <Box
                                        style={{
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            flexDirection: 'column',
                                            width: '100%',
                                            margin: '24px 0 0 0'
                                        }}
                                    >
                                        <Box
                                            style={{
                                                backgroundColor: '#F6F6F6',
                                                borderRadius: '16px',
                                                margin: '0 5px',
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                                height: '200px',
                                                width: '100%',
                                                padding: '10px'
                                                // overflow: 'hidden'
                                            }}
                                        >
                                            <TextArea
                                                rows={4}
                                                placeholder="Type here ..."
                                                onChange={(e) => {
                                                    setNote(e.target.value);
                                                }}
                                                onKeyUp={handleKeyPress}
                                                style={{
                                                    backgroundColor: '#F6F6F6',
                                                    borderRadius: '16px',
                                                    margin: '5px',
                                                    border: 'none',
                                                    height: '135px',
                                                    width: '100%'
                                                }}
                                                autoSize={{
                                                    minRows: 5,
                                                    maxRows: 6
                                                }}
                                            />
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'flex-end',
                                                    flexDirection: 'column',
                                                    position: 'relative'
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        border: '1px solid #FFD5B7',
                                                        height: '40px',
                                                        width: '132px',
                                                        padding: '10px 12px',
                                                        borderRadius: '8px',
                                                        color: '#FF740F',
                                                        fontWight: '500',
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                        fontSize: '16px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        margin: '10px 0'
                                                    }}
                                                    onClick={() => {
                                                        setOpenSource(!openSource);
                                                    }}
                                                >
                                                    Add source <KeyboardArrowDownIcon sx={{ width: '16px', color: '#FF740F' }} />
                                                </Typography>
                                                {openSource ? (
                                                    <Box
                                                        sx={{
                                                            width: '294px',
                                                            border: '1px solid #FA3246',
                                                            borderRadius: '16px',
                                                            padding: '10px 16px 16px 16px ',
                                                            textAlign: 'center',
                                                            position: 'absolute',
                                                            right: '0',
                                                            bottom: '-190px',
                                                            backgroundColor: '#fff',
                                                            zIndex: '300'
                                                        }}
                                                    >
                                                        {items.map((source, index) => (
                                                            <Box
                                                                onMouseEnter={() => handleMouseEnter(index)}
                                                                onMouseLeave={() => handleMouseLeave(index)}
                                                                key={source.key}
                                                                sx={{
                                                                    radius: '8px',
                                                                    width: '100%',
                                                                    borderBottom: '1px solid #E7EAEB',
                                                                    textAlign: 'center',
                                                                    padding: '10px',
                                                                    backgroundColor:
                                                                        source.isHovered || source.isSelected ? '#FFF1E7' : '#fff',
                                                                    transition: 'background-color 0.7s ease',
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={() => {
                                                                    setSource(source.source);
                                                                    handleSourceSelect(index);
                                                                }}
                                                            >
                                                                {source.source} {source.isSelected && <img src={check} alt="check" />}
                                                            </Box>
                                                        ))}

                                                        <Button
                                                            loading={isAddingNote}
                                                            style={{
                                                                backgroundColor: '#FF740F',
                                                                radius: '8px',
                                                                height: '40px',
                                                                width: '100%',
                                                                color: '#fff'
                                                            }}
                                                            onClick={() => {
                                                                AddNewNote();
                                                            }}
                                                        >
                                                            Save note
                                                        </Button>
                                                    </Box>
                                                ) : null}
                                            </Box>
                                        </Box>
                                        {profile?.notes?.length > 0 ? (
                                            <Box
                                                style={{
                                                    backgroundColor: '#ECEDEF',
                                                    borderRadius: '16px',
                                                    margin: '15px 5px 0',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    flexDirection: 'column',
                                                    // height: '165px',
                                                    width: '100%',
                                                    padding: '15px',
                                                    zIndex: '20'
                                                }}
                                            >
                                                <>
                                                    <Box
                                                        sx={{
                                                            fontSize: '14px',
                                                            color: '#546974',
                                                            overflowWrap: 'break-word'
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                textAlign: 'start'
                                                            }}
                                                        >
                                                            {profile?.notes}
                                                        </Box>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            margin: '16px 0 0 0',
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                color: '#85949C',
                                                                fontSize: '12px'
                                                            }}
                                                        >
                                                            {/* Source: */}
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    color: '#85949C',
                                                                    fontSize: '12px',
                                                                    margin: ' 0 10px 0 0'
                                                                }}
                                                            >
                                                                {/* Added by: */}
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    color: '#85949C',
                                                                    fontSize: '12px'
                                                                }}
                                                            >
                                                                {/* 01/Jan/2024 */}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </>
                                            </Box>
                                        ) : null}
                                        {retailerNotes.length > 0
                                            ? retailerNotes?.slice(0, 5)?.map((note) => (
                                                  <Box
                                                      style={{
                                                          backgroundColor: '#ECEDEF',
                                                          borderRadius: '16px',
                                                          margin: '10px 5px 0',
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                          justifyContent: 'space-between',
                                                          flexDirection: 'column',
                                                          // height: '165px',
                                                          width: '100%',
                                                          padding: '15px',
                                                          zIndex: '20'
                                                      }}
                                                      key={note?._id}
                                                  >
                                                      <Box
                                                          sx={{
                                                              width: '100%'
                                                          }}
                                                      >
                                                          <Box
                                                              sx={{
                                                                  fontSize: '14px',
                                                                  color: '#546974',
                                                                  overflowWrap: 'break-word'
                                                              }}
                                                          >
                                                              <Box
                                                                  sx={{
                                                                      textAlign: 'start'
                                                                  }}
                                                              >
                                                                  {note?.note}
                                                              </Box>
                                                          </Box>
                                                          <Box
                                                              sx={{
                                                                  display: 'flex',
                                                                  justifyContent: 'space-between',
                                                                  alignItems: 'center',
                                                                  margin: '16px 0 0 0',
                                                                  width: '100%'
                                                              }}
                                                          >
                                                              <Typography
                                                                  sx={{
                                                                      color: '#85949C',
                                                                      fontSize: '12px'
                                                                  }}
                                                              >
                                                                  Source: {note?.source}
                                                              </Typography>
                                                              <Box
                                                                  sx={{
                                                                      display: 'flex',
                                                                      justifyContent: 'space-between',
                                                                      alignItems: 'center'
                                                                  }}
                                                              >
                                                                  <Typography
                                                                      sx={{
                                                                          color: '#85949C',
                                                                          fontSize: '12px',
                                                                          margin: ' 0 10px 0 0'
                                                                      }}
                                                                  >
                                                                      Added by: {note?.createdBy?.firstName} {note?.createdBy?.lastName}
                                                                  </Typography>
                                                                  <Typography
                                                                      sx={{
                                                                          color: '#85949C',
                                                                          fontSize: '12px'
                                                                      }}
                                                                  >
                                                                      {new Date(note?.createdAt).toLocaleDateString()}
                                                                  </Typography>
                                                              </Box>
                                                          </Box>
                                                      </Box>
                                                  </Box>
                                              ))
                                            : null}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <RetailerProfileModal
                        isOpen={isRetailerOpen}
                        handleOk={handleRetailerOk}
                        handleCancel={handleRetailerCancel}
                        content={retailerInfoBody}
                        heading="Retailers Information"
                        height="420px"
                        isRetailerOpen={isRetailerOpen}
                    />
                    <RetailerProfileModal
                        isOpen={isCommentOpen}
                        handleOk={handleCommentOk}
                        handleCancel={handleCommentCancel}
                        content={commentBody}
                        heading="Add Comment"
                        centered={false}
                        height="150px"
                    />
                    <RetailerProfileModal
                        isOpen={isOrderDetailsOpen}
                        handleOk={handleOrderDetailsOk}
                        handleCancel={handleOrderDetailsCancel}
                        content={orderDetailsBody}
                        heading="01, Jan, 2024 / Order details"
                        centered={false}
                        orders={orders}
                        getOrderId={getOrderId}
                        // height="591px"
                    />
                </>
            ) : (
                <Box
                    style={{
                        margin: '10px auto 0',
                        position: 'relative',
                        backgroundColor: '#FFFFFF',
                        height: '496px',
                        borderRadius: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    className="empty-state-container"
                >
                    <EmptyState height="204px" text="No results yet" />
                </Box>
            )}
        </>
    );
};

export default SingleRetailerProfile;
