/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import useScriptRef from 'hooks/useScriptRef';
// import { FormHelperText } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import CircularProgress from '@mui/material/CircularProgress';

const AddFleetPartner = () => {
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [partnerName, setpartnerName] = useState('');
    const [partnerContact, setpartnerContact] = useState('');
    const [dateOnboarded, setdateOnboarded] = useState(new Date());
    const [notes, setnotes] = useState('');
    const [fleetType, setfleetType] = useState('');
    const [scheduleddate, setscheduleddate] = useState(new Date());
    const [location, setlocation] = useState('');
    const [stageId, setstageId] = useState('');
    const [customerSuccessTeamId, setcustomerSuccessTeamId] = useState('');
    const [signupDate, setsignupDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [stageInfo, setStage] = useState([]);
    const [customerSuccessTeam, setCustomerSuccessTeam] = useState([]);

    const retrieveAllCustomerSuccessTeam = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.AllActiveCustomerSuccess, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCustomerSuccessTeam(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllStage = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.AllStageUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setStage(data.data);
                        setLoading2(false);
                    } else {
                        toast.error(data.data);
                        setLoading2(false);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        retrieveAllStage();
        retrieveAllCustomerSuccessTeam();
    }, []);

    const handleSignupChange = (newValue) => {
        setsignupDate(newValue);
    };
    const handleScheduledChange = (newValue) => {
        setscheduleddate(newValue);
    };
    const handleStageChange = (event) => {
        setstageId(event.target.value);
    };
    const handleCustomerSuccessTeamChange = (event) => {
        setcustomerSuccessTeamId(event.target.value);
    };

    const handlePartnerTypeChange = (event) => {
        setfleetType(event.target.value);
    };

    const handleDateOnBoardedChange = (newValue) => {
        setdateOnboarded(newValue);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        setLoading(true);

        const data = {
            partnerName,
            partnerContact,
            stageId,
            dateOnboarded,
            location,
            fleetType,
            notes,
            customerSuccessTeamId,
            scheduleddate,
            signupDate
        };

        fetch(ApiUrl.AddFleetPartnerUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/fleetpartner');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid item xs={12} sm={6}>
                        <PageHeader title="Fleet Partners" subTitle="Home | Configuration | Channel Partners | Fleet" />
                        <MainCard title="Add Fleet">
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    autoComplete="off"
                                    name="partnerName"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    id="partnerName"
                                    label="Company Name"
                                    value={partnerName}
                                    onChange={(e) => setpartnerName(e.target.value)}
                                    autoFocus
                                />

                                <br />
                                <br />
                                <TextField
                                    autoComplete="off"
                                    name="partnerContact"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    id="partnerContact"
                                    label="Telephone Number"
                                    value={partnerContact}
                                    onChange={(e) => setpartnerContact(e.target.value)}
                                />

                                <br />
                                <br />
                                <TextField
                                    autoComplete="off"
                                    name="location"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    id="location"
                                    label="Location"
                                    value={location}
                                    onChange={(e) => setlocation(e.target.value)}
                                />

                                <br />
                                <br />
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Customer Success Team</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={customerSuccessTeamId}
                                        label="Status"
                                        onChange={handleCustomerSuccessTeamChange}
                                    >
                                        {customerSuccessTeam.map((customer) => (
                                            <MenuItem key={customer._id} value={customer._id}>
                                                {customer?.name}
                                                {customer?.firstName}
                                                {customer?.lastName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <br />
                                <br />

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Stage</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={stageId}
                                        label="Status"
                                        onChange={handleStageChange}
                                    >
                                        {stageInfo.map((stage) => (
                                            <MenuItem key={stage._id} value={stage._id}>
                                                {stage.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <br />
                                <br />
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Partner Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={fleetType}
                                        label="Status"
                                        onChange={handlePartnerTypeChange}
                                    >
                                        <MenuItem value="Fleet Individual Partner">Fleet Individual Partner</MenuItem>
                                        <MenuItem value="Fleet Company Partner">Fleet Company Partner</MenuItem>
                                    </Select>
                                </FormControl>

                                <br />
                                <br />
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack spacing={3}>
                                        <DesktopDatePicker
                                            label="Scheduled Date"
                                            inputFormat="MM/dd/yyyy"
                                            value={scheduleddate}
                                            onChange={handleScheduledChange}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Stack>
                                </LocalizationProvider>

                                <br />
                                <br />
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack spacing={3}>
                                        <DesktopDatePicker
                                            label="Date Contacted"
                                            inputFormat="MM/dd/yyyy"
                                            value={signupDate}
                                            onChange={handleSignupChange}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                                <br />
                                <br />

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack spacing={3}>
                                        <DesktopDatePicker
                                            label="Date Onboarded"
                                            inputFormat="MM/dd/yyyy"
                                            value={dateOnboarded}
                                            onChange={handleDateOnBoardedChange}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                                <br />
                                <br />

                                <TextField
                                    autoComplete="off"
                                    name="notes"
                                    variant="outlined"
                                    fullWidth
                                    id="notes"
                                    multiline
                                    rows={8}
                                    value={notes}
                                    label="Comment"
                                    onChange={(e) => setnotes(e.target.value)}
                                />

                                <br />
                                <br />
                                <Box display="flex" justifyContent="space-between">
                                    <Button component={Link} to="/fleetpartner" type="submit" variant="contained" color="error">
                                        Reset
                                    </Button>

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        disabled={!partnerName || !partnerContact || !stageId || !signupDate || !customerSuccessTeamId}
                                    >
                                        {loading ? 'Submitting ..' : 'Submit'}
                                    </Button>
                                </Box>
                            </form>
                        </MainCard>
                    </Grid>
                </>
            )}
        </>
    );
};

export default AddFleetPartner;
