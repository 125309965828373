import { Button, Collapse, Divider, Input, Select, Spin, Table, DatePicker } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ApiUrl from '../../network';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { decrypt } from '../../utils/encrypt';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const { RangePicker } = DatePicker;
    const [retailers, setRetailers] = useState([]); // eslint-disable-line no-unused-vars
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState('Priority level');
    const [loading, setLoading] = useState(false);
    const [gender, setGender] = useState('');
    const [country, setCountry] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [countries, setCountries] = useState([]);
    const [limit, setLimit] = useState('');
    const [page, setPage] = useState(1);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0
        }
    });
    const [tiers, setTiers] = useState([]);
    const [tier, setTier] = useState('');

    // eslint-disable-next-line consistent-return
    const fetchRetailers = async (filters) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/loyaltymanager/main`;

            if (filters) {
                if (filters.startDate && filters.endDate) {
                    urlParams.set('startDate', filters.startDate);
                    urlParams.set('endDate', filters.endDate);
                }
                if (filters.search) {
                    urlParams.set('retailer', filters.search);
                }
                if (filters.country) {
                    urlParams.set('country', filters.country);
                }
                if (filters.limit) {
                    urlParams.set('limit', filters.limit);
                }
                if (filters.gender) {
                    urlParams.set('gender', filters.gender);
                }
                if (filters.page) {
                    urlParams.set('page', filters.page);
                }
                if (filters.tier) {
                    urlParams.set('tier', filters.tier);
                }
            }
            if (urlParams.toString()) url += `?${urlParams.toString()}`;

            const response = await fetch(url, config);
            if (!response.ok) {
                toast.error('Failed to fetch retailers');
            }
            const data = await response.json();
            setRetailers(JSON.parse(decrypt(data.data, keyResult, ivResult)));
            setLoading(false);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: data.totalBatch,
                    current: filters?.page || 1
                }
            });
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };
    const fetchCountries = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(`${ApiUrl.BaseUrl}/country/activecountry`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error('Something went wrong');
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            toast.error(e.message);
        }
    };
    // eslint-disable-next-line consistent-return
    const fetchTiers = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(`${ApiUrl.BaseUrl}/loyaltymanager/tiers`, config);
            if (!response.ok) {
                toast.error('Failed to fetch tiers');
            }
            const data = await response.json();
            setTiers(JSON.parse(decrypt(data.data, keyResult, ivResult)));
            setLoading(false);
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchRetailers();
        fetchCountries();
        fetchTiers();
        return () => {
            // cleanup function
            setLoading(false);
        };
    }, []);

    const filteredRetailers = useMemo(() => {
        if (!search && status === 'Priority level' && !status) return retailers;
        return retailers.filter((retailer) => {
            if (search && status === 'Priority level') {
                return retailer.retailerName.toLowerCase().includes(search.toLowerCase());
            }
            if (status === 'Priority level' && !search) {
                return retailer;
            }
            if (status && !search) {
                return retailer.rating === status;
            }
            if (status && search) {
                return retailer.retailerName.toLowerCase().includes(search.toLowerCase()) && retailer.rating === status;
            }
            return retailer;
        });
    }, [retailers, search, status]);
    const countryOption = countries?.map((result) => ({
        value: result.name,
        label: result.name
    }));
    const limitOption = [
        {
            value: '100',
            label: '100'
        },
        {
            value: '200',
            label: '200'
        },
        {
            value: '300',
            label: '300'
        },
        {
            value: '400',
            label: '400'
        },
        {
            value: '500',
            label: '500'
        },
        {
            value: '600',
            label: '600'
        }
    ];
    const tierOptions = tiers.map((tier) => {
        return {
            value: tier.name,
            label: tier.name
        };
    });

    const appleyFilter = () => {
        const filterData = {
            search,
            gender,
            country,
            startDate,
            endDate,
            limit,
            tier
        };
        fetchRetailers(filterData);
    };

    const handleDateChange = (date, dateString) => {
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
    };

    const handlePaginationChange = (pagination) => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination
            }
        });
        fetchRetailers({
            limit: pagination.pageSize,
            page: pagination.current
        });
    };
    const columns = [
        {
            title: 'Retailers',
            dataIndex: 'retailerName',
            key: 'retailerName'
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender'
        },
        {
            title: 'Loyalty tier',
            dataIndex: 'loyaltyTier',
            key: 'loyaltyTier'
        },
        {
            title: 'Discount received',
            dataIndex: 'discountReceived',
            key: 'discountReceived'
        },
        {
            title: 'Orders left to next tier ',
            dataIndex: 'ordersLeftToNextTier',
            key: 'ordersLeftToNextTier'
        },
        {
            title: 'Amount left to next tier',
            dataIndex: 'amountLeftToNextTier'
        },
        {
            title: 'Next tier',
            dataIndex: 'nextTier',
            key: 'nextTier'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => (
                <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: '#FF740F',
                        cursor: 'pointer',
                        fontSize: '16px',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                    onClick={() => {
                        window.location.href = `/retailer-loyalty/${record._id}`;
                    }}
                >
                    <span>View details</span>
                </button>
            )
        }
    ];
    return (
        <div className="leadCrmContainer">
            <div className="d-flex justify-content-space-between align-items-center leadHeaders">
                <div>
                    <h2>
                        {' '}
                        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                        Retailer Loyalty management
                    </h2>
                    <p>Track the loyalty progress of our retailers</p>
                </div>
                <div className="d-flex justify-content-space-between align-items-center leadHeaders">
                    <Button
                        onClick={() => (window.location.href = '/retailer-loyalty/rewards')}
                        style={{
                            backgroundColor: '#193B56',
                            color: '#fff'
                        }}
                        className="mr-2"
                    >
                        View rewards
                    </Button>
                    <Button
                        onClick={() => (window.location.href = '/retailer-loyalty/summary')}
                        style={{
                            backgroundColor: '#FF740F',
                            color: '#fff'
                        }}
                    >
                        View summary
                    </Button>
                </div>
            </div>
            <Divider style={{ margin: '20px 0', border: '1px dash #e8e8e8' }} />
            <Collapse accordion>
                <Collapse.Panel key={1} header="Hide filters" className="mb-4">
                    <div className="batchFilters p-2 mb-2">
                        <div>
                            <p className="font-14 mb-2">Retailer</p>
                            <Input
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                className="p-1 filterInput"
                                placeholder="Search retailer"
                                size="small"
                            />
                        </div>
                        <div>
                            <p className="font-14 mb-2">Date</p>
                            <RangePicker
                                format="YYYY-MM-DD"
                                className="datePicker filterInput"
                                onChange={(date, dateString) => {
                                    handleDateChange(date, dateString);
                                }}
                                value={startDate && endDate ? [dayjs(startDate, 'YYYY-MM-DD'), dayjs(endDate, 'YYYY-MM-DD')] : null}
                            />
                        </div>
                        <div>
                            <p className="font-14 mb-2">Gender</p>
                            <Select
                                showSearch
                                allowClear
                                placeholder="Gender"
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                className="filterInput"
                                options={[
                                    {
                                        label: 'Male',
                                        value: 'Male'
                                    },
                                    {
                                        label: 'Female',
                                        value: 'Female'
                                    }
                                ]}
                                onChange={(value) => setGender(value)}
                                value={gender}
                            />
                        </div>
                        <div>
                            <p className="font-14 mb-2">Country</p>
                            <Select
                                showSearch
                                allowClear
                                placeholder="Country"
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                className="filterInput"
                                options={countryOption}
                                value={country}
                                onChange={(value) => setCountry(value)}
                            />
                        </div>
                        <div>
                            <p className="font-14 mb-2">Limit</p>
                            <Select
                                showSearch
                                allowClear
                                placeholder="Limit"
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                className="filterInput"
                                options={limitOption}
                                value={limit}
                                onChange={(value) => setLimit(value)}
                            />
                        </div>
                        <div>
                            <p className="font-14 mb-2">Tier</p>
                            <Select
                                style={{ width: 200 }}
                                placeholder="Select tier"
                                className="mr-2 filterInput"
                                allowClear
                                showSearch
                                options={tierOptions}
                                onChange={(value) => {
                                    setTier(value);
                                }}
                                optionFilterProp="children"
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                            />
                        </div>
                        <div className="d-flex align-items-center">
                            <Button
                                className="mt-3"
                                onClick={() => appleyFilter()}
                                style={{
                                    color: '#FF740F',
                                    border: '1px solid #FF740F'
                                }}
                                loading={loading}
                            >
                                Apply filter
                            </Button>
                            {(startDate && endDate) || search || gender || country || limit ? (
                                <Button
                                    onClick={() => {
                                        setStartDate('');
                                        setEndDate('');
                                        setSearch('');
                                        setGender('');
                                        setCountry('');
                                        setLimit('');
                                        setTableParams({
                                            ...tableParams,
                                            pagination: {
                                                ...tableParams.pagination,
                                                current: 1
                                            }
                                        });
                                        fetchRetailers();
                                    }}
                                    style={{
                                        color: '#FF740F',
                                        border: '1px solid #FF740F'
                                    }}
                                    className="ml-2 mt-3"
                                >
                                    Reset filter
                                </Button>
                            ) : null}
                        </div>
                    </div>
                </Collapse.Panel>
            </Collapse>
            <div
                style={{
                    backgroundColor: '#fff',
                    borderRadius: '10px'
                }}
                className="table-responsive"
            >
                <Table
                    className="table"
                    columns={columns}
                    dataSource={filteredRetailers}
                    rowKey={(record) => record._id}
                    pagination={tableParams.pagination}
                    loading={loading}
                    onChange={handlePaginationChange}
                />
            </div>
        </div>
    );
};
export default Index;
