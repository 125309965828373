import { Row, Col } from 'react-grid-system';
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';

import CardWrapper from 'assets/Wrappers/CardWrapper';

const userInfo = localStorage.getItem('userCurrency');

function CardItem({ cardTitle, thisMonth, prevMonth, perChange, isMoney, isPercent }) {
    return (
        <CardWrapper>
            <Row>
                <Col>
                    <div className="card-statistics">
                        <div className="main-card">
                            <h6 className="custom-card-title">{cardTitle}</h6>
                            <div className="stats">
                                <p className="amount">
                                    {isMoney && userInfo}
                                    {thisMonth}
                                    {isPercent && '%'}
                                </p>
                                {perChange > 0 ? (
                                    <p className="current">
                                        {perChange}%
                                        <span>
                                            <AiOutlineArrowUp className="arrow-up" />
                                        </span>
                                    </p>
                                ) : (
                                    <p className="down-curve">
                                        {perChange}%
                                        <span>
                                            <AiOutlineArrowDown className="arrow-down" />
                                        </span>
                                    </p>
                                )}
                            </div>
                            <p className="prev-stats">
                                Previous Month: {isMoney && userInfo}
                                {prevMonth}
                                {isPercent && '%'}
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </CardWrapper>
    );
}
export default CardItem;
