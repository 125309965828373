import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import deliveredIcon from '../../assets/images/icons/icons8-shop-603.png';
import latestIcon from '../../assets/images/icons/icons8-shop-60.png';
import PopUp from './PopUp';
import './map.css';
import { Select, Button, TimePicker } from 'antd';
import { useRef, useState } from 'react';

const country = localStorage.getItem('country');

const MyComponent = withScriptjs(
    withGoogleMap((props) => (
        <GoogleMap
            center={props.selectCountry === 'Ghana' ? { lat: 6.9, lng: -1.00011 } : { lat: 7.465422, lng: 4.406448 }}
            defaultCenter={props.center}
            zoom={props.zoom === 7.7 ? props.zoom : 8.7}
            options={{
                streetViewControl: false,
                mapTypeControl: false
            }}
        >
            <div className="total-orders">
                Total Orders:{' '}
                {props.show
                    ? props.totalOrders
                    : props.totalOrdersPlacedByRMA ||
                      props.totalOrdersPlacedByPod ||
                      props.totalOrdersPlacedInATimeRange ||
                      props.totalOrdesByTier}
            </div>
            {props.rmaGMV && <div className="total-gmv">Total GMV: {props.totalGMV}</div>}
            {props.podGMV && <div className="total-gmv">Total Pod GMV: {props.totalPodGMV}</div>}
            {props.timeGMV && <div className="total-gmv">Total GMV: {props.totalTimeGMV}</div>}
            {props.tierGMV && <div className="total-gmv">Total Tier GMV: {props.totalTierGMV}</div>}
            <div className="filterbyrma">
                <div className="filters">
                    {' '}
                    <Select
                        showSearch
                        style={{
                            width: 200,
                            margin: '5px'
                        }}
                        placeholder="Search to Select RMA"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={props.rma}
                        onChange={props.handleSelectChange}
                        value={props.show ? 'Search to Select RMA' : props.selectedValue}
                        allowClear
                    />
                    <Select
                        showSearch
                        style={{
                            width: 200,
                            margin: '5px'
                        }}
                        placeholder="Search to Select Pod"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={props.pod}
                        onChange={props.handlePodChange}
                        value={props.show ? 'Search to Select Pod' : props.selectedPod}
                        allowClear
                    />
                    <TimePicker.RangePicker
                        style={{
                            width: 200,
                            margin: '5px'
                        }}
                        onChange={props.onChangeTime}
                    />
                    <Select
                        showSearch
                        style={{
                            width: 200,
                            margin: '5px'
                        }}
                        placeholder="Search to Select Tier"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={props.tier}
                        onChange={props.handleTierChange}
                        value={props.show ? 'Search to Select TIER' : props.selectedTier}
                        allowClear
                    />
                    <Select
                        showSearch
                        style={{
                            width: 200,
                            margin: '5px'
                        }}
                        placeholder="Select Country"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={props.countryOption}
                        value={props.selectCountry || country}
                        onChange={(value) => props.filterCountry(value)}
                        allowClear
                    />
                    <Button
                        type="primary"
                        onClick={() => {
                            props.setShow(true);
                            props.setTierGMV(false);
                            props.setPodGMV(false);
                            props.setRMAGMV(false);
                            props.setTimeGMV(false);
                        }}
                        style={{ margin: '5px' }}
                    >
                        All Orders
                    </Button>
                </div>
            </div>

            {props.show &&
                props.pins.map((pin) => (
                    // All Orders
                    <Marker
                        key={pin?._id}
                        position={{ lat: +pin.latitude, lng: +pin.longitude }}
                        onClick={() => props.handleMarkerClick(pin?._id)}
                        icon={{
                            url: props.latestOrder?._id === pin?._id ? latestIcon : deliveredIcon,
                            scaledSize: new window.google.maps.Size(20, 20)
                        }}
                    >
                        {props.currentPlaceId === pin?._id ? (
                            <InfoWindow
                                onCloseClick={() => {
                                    props.setCurrentPlaceId(null);
                                }}
                            >
                                <PopUp key={pin?._id} {...pin} />
                            </InfoWindow>
                        ) : (
                            ''
                        )}
                    </Marker>
                ))}

            {props.orderTime.map((pin) => (
                // Orders by selected time
                <Marker
                    key={pin._id}
                    position={{ lat: +pin.latitude, lng: +pin.longitude }}
                    onClick={() => props.handleMarkerClick(pin._id)}
                    icon={{
                        url: props.latestOrder?._id === pin?._id ? latestIcon : deliveredIcon,
                        scaledSize: new window.google.maps.Size(20, 20)
                    }}
                >
                    {props.currentPlaceId === pin?._id ? (
                        <InfoWindow onCloseClick={() => props.setCurrentPlaceId(null)}>
                            <PopUp key={pin?._id} {...pin} />
                        </InfoWindow>
                    ) : null}
                </Marker>
            ))}

            {props.podOrders.map((pin) => (
                // Orders by selected pod
                <Marker
                    key={pin._id}
                    position={{ lat: +pin.latitude, lng: +pin.longitude }}
                    onClick={() => props.handleMarkerClick(pin._id)}
                    icon={{
                        url: props.latestOrder?._id === pin?._id ? latestIcon : deliveredIcon,
                        scaledSize: new window.google.maps.Size(20, 20)
                    }}
                >
                    {props.currentPlaceId === pin?._id ? (
                        <InfoWindow onCloseClick={() => props.setCurrentPlaceId(null)}>
                            <PopUp key={pin?._id} {...pin} />
                        </InfoWindow>
                    ) : null}
                </Marker>
            ))}

            {props.rmaOrders.map((pin) => (
                // Orders by selected rma
                <Marker
                    key={pin?._id}
                    position={{ lat: +pin.latitude, lng: +pin.longitude }}
                    onClick={() => props.handleMarkerClick(pin?._id)}
                    icon={{
                        url: props.latestOrder?._id === pin?._id ? latestIcon : deliveredIcon,
                        scaledSize: new window.google.maps.Size(20, 20)
                    }}
                >
                    {props.currentPlaceId === pin?._id ? (
                        <InfoWindow onCloseClick={() => props.setCurrentPlaceId(null)}>
                            <PopUp key={pin?._id} {...pin} />
                        </InfoWindow>
                    ) : null}
                </Marker>
            ))}

            {props.tierOrders.map((pin) => (
                // Orders by selected rma
                <Marker
                    key={pin?._id}
                    position={{ lat: +pin.latitude, lng: +pin.longitude }}
                    onClick={() => props.handleMarkerClick(pin?._id)}
                    icon={{
                        url: props.latestOrder?._id === pin?._id ? latestIcon : deliveredIcon,
                        scaledSize: new window.google.maps.Size(20, 20)
                    }}
                >
                    {props.currentPlaceId === pin?._id ? (
                        <InfoWindow onCloseClick={() => props.setCurrentPlaceId(null)}>
                            <PopUp key={pin?._id} {...pin} />
                        </InfoWindow>
                    ) : null}
                </Marker>
            ))}
        </GoogleMap>
    ))
);

const OrderMap = ({
    pins,
    setCurrentPlaceId,
    currentPlaceId,
    handleMarkerClick,
    latestOrder,
    totalOrders,
    mapCenter,
    rma,
    setSelectedValue,
    selectedValue,
    handleSelectChange,
    rmaOrders,
    setShow,
    show,
    onChangeTime,
    orderTime,
    totalGMV,
    totalOrdersPlacedByRMA,
    totalOrdersPlacedByPod,
    setSelectedPod,
    pod,
    handlePodChange,
    podOrders,
    totalPodGMV,
    selectedPod,
    totalTimeGMV,
    totalOrdersPlacedInATimeRange,
    selectedTime,
    selectedTier,
    handleTierChange,
    tier,
    tierOrders,
    totalOrdesByTier,
    totalTierGMV,
    setTierGMV,
    tierGMV,
    podGMV,
    setPodGMV,
    rmaGMV,
    timeGMV,
    setTimeGMV,
    setRMAGMV,
    filterCountry,
    countryOption,
    zoom,
    country,
    setZoom,
    selectCountry
}) => {
    const key = 'AIzaSyBZqHjOL-7tPYGJfRKkROaOMxlHfWkou_0';

    return (
        <>
            <MyComponent
                pins={pins}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                setCurrentPlaceId={setCurrentPlaceId}
                currentPlaceId={currentPlaceId}
                handleMarkerClick={handleMarkerClick}
                latestOrder={latestOrder}
                totalOrders={totalOrders}
                center={mapCenter}
                rma={rma}
                handleSelectChange={handleSelectChange}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                rmaOrders={rmaOrders}
                setShow={setShow}
                show={show}
                onChangeTime={onChangeTime}
                orderTime={orderTime}
                totalGMV={totalGMV}
                totalOrdersPlacedByRMA={totalOrdersPlacedByRMA}
                totalOrdersPlacedByPod={totalOrdersPlacedByPod}
                setSelectedPod={setSelectedPod}
                pod={pod}
                handlePodChange={handlePodChange}
                podOrders={podOrders}
                totalPodGMV={totalPodGMV}
                selectedPod={selectedPod}
                totalTimeGMV={totalTimeGMV}
                totalOrdersPlacedInATimeRange={totalOrdersPlacedInATimeRange}
                selectedTime={selectedTime}
                selectedTier={selectedTier}
                handleTierChange={handleTierChange}
                tier={tier}
                tierOrders={tierOrders}
                totalOrdesByTier={totalOrdesByTier}
                totalTierGMV={totalTierGMV}
                setTierGMV={setTierGMV}
                tierGMV={tierGMV}
                setPodGMV={setPodGMV}
                setTimeGMV={setTimeGMV}
                timeGMV={timeGMV}
                setRMAGMV={setRMAGMV}
                rmaGMV={rmaGMV}
                podGMV={podGMV}
                filterCountry={filterCountry}
                countryOption={countryOption}
                zoom={zoom}
                country={country}
                setZoom={setZoom}
                selectCountry={selectCountry}
            />
        </>
    );
};

export default OrderMap;
