/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
// import MainCard from 'ui-component/cards/MainCard';
// import PageHeader from '../../components/PageHeader';
// import Box from '@mui/material/Box';
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
// import { useNavigate } from 'react-router-dom';
// import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
// import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import CropRotateIcon from '@mui/icons-material/CropRotate';
// import PersonIcon from '@mui/icons-material/Person';
// import LocalShippingIcon from '@mui/icons-material/LocalShipping';
// import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Form from 'react-bootstrap/Form';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import useScriptRef from 'hooks/useScriptRef';
import InputLabel from '@mui/material/InputLabel';
import Divider from '@mui/material/Divider';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
// import Stack from '@mui/material/Stack';
// import Avatar from '@mui/material/Avatar';
// import nologo from 'assets/images/nologo.png';
// import * as blobUtil from 'blob-util';
// import { Camera } from 'react-camera-pro';

import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import SortIcon from '@mui/icons-material/Sort';
// import axios from 'axios';
// import { canvasToBlob } from 'blob-util';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import Skeleton from '@mui/material/Skeleton';
import BatchCard from 'components/OrderBatching/BatchCard';
import GenericPageHeader from 'components/GenericPageHeader';
import RefreshIcon from '@mui/icons-material/Refresh';
import LoadingButton from '@mui/lab/LoadingButton';
import { successToast } from 'utils/toast';
import MoonLoader from 'react-spinners/MoonLoader';
import Stack from '@mui/material/Stack';
import OrderStatusCard from 'components/OrderStatusCard';
import Autocomplete from '@mui/material/Autocomplete';

const pageData = [
    {
        value: 'tableview',
        label: 'Table view'
    },
    {
        value: 'cardview',
        label: 'Card view'
    }
];

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    color: {
        color: '#FF740F'
    },
    card: {
        // maxWidth: 300,
        marginBottom: '20px',
        // margin: 'auto',
        transition: '0.3s'
        // boxShadow: '0 12px 120px 10px rgb(227,242,253)',
        // '&:hover': {
        //     boxShadow: '0 12px 120px 10px rgb(227,242,253)'
        // }
    }
}));
// const Input = styled('input')({
//     display: 'none'
// });

const OrderTableStatus = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [loading, setLoading] = useState(false);
    const [orderInfo, setOrder] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [fulOrderDelivered, setfulOrderDelivered] = useState([]);
    const [fulOrderProcessing, setfulOrderPending] = useState([]);
    const [fulOrderDispatched, setfulOrderDispatched] = useState([]);
    const [receivedStatus, setreceivedStatus] = useState([]);
    const [retrieveDrivers, setretrieveDrivers] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [pageinfo, setpageinfo] = useState('tableview');
    // const [viewit, setviewit] = useState(true);
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [status, setstatus] = useState('');
    const [processingtime, setprocessingtime] = useState('');
    const [allocation, setallocation] = useState('');
    const [timedispatched, settimedispatched] = useState('');
    const [quantityordered, setquantityordered] = useState('');
    const [quantitydelivered, setquantitydelivered] = useState('');
    const [location, setlocation] = useState('');
    const [loading2, setLoading2] = useState('');
    const [ids, setids] = useState('');
    const [fulOrderId, setfulOrderId] = useState('');
    const [orderDate, setorderDate] = useState('');
    const [driverId, setdriverId] = useState('');
    // const userRole = localStorage.getItem('userRole');
    // const cardRows = [...orderData, ...fulOrderDelivered, ...fulOrderProcessing, ...fulOrderDispatched];

    // const responsive = {
    //     superLargeDesktop: {
    //         breakpoint: { max: 4000, min: 1024 },
    //         items: 5
    //     },
    //     desktop: {
    //         breakpoint: { max: 1024, min: 768 },
    //         items: 3
    //     },
    //     tablet: {
    //         breakpoint: { max: 768, min: 640 },
    //         items: 2
    //     },
    //     mobile: {
    //         breakpoint: { max: 640, min: 0 },
    //         items: 1
    //     }
    // };
    // const commonStyles = {
    //     bgcolor: 'background.paper',
    //     m: 2,
    //     border: 2
    // };
    // const commonStyles1 = {
    //     bgcolor: 'background.paper',
    //     m: 1,
    //     border: 1
    // };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 30));
        setPage(0);
    };

    const handlePageChange = (event) => {
        setpageinfo(event.target.value);
    };

    // const handleDetail = () => {
    //     setviewit(false);
    // };

    // const handleDetailback = () => {
    //     setviewit(true);
    // };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleStatus = (event) => {
        setstatus(event.target.value);
    };

    const filterDriver = (event) => {
        const newFilter = retrieveDrivers.filter((value) =>
            (`${value.firstName}${value.lastName}` || '').toLowerCase().includes((event || '').toLowerCase())
        );
        newFilter.forEach((option) => {
            setdriverId(option._id);
            console.log(option._id, 'driver');
        });
    };

    const handlePackSortClick = (row) => {
        if (scriptedRef.current) {
            // console.log(row);
            history(`/addpacksortimagedata/${row._id}`);
        }
    };

    const handleDispatchImageClick = (row) => {
        if (scriptedRef.current) {
            // console.log(row);
            history(`/adddispatchimagedata/${row._id}`);
        }
    };

    const handleDeliveryNoteClick = (row) => {
        if (scriptedRef.current) {
            // console.log(row);
            history(`/adddeliveryimagedata/${row._id}`);
        }
    };

    const handleEditClick = (row) => {
        handleClickOpen();
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.ViewOrdersUrl + row._id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setLoading2(false);
                    setstatus(result?.data?.fulStatus ?? '');
                    setprocessingtime(result?.data?.fulProcessing ?? '');
                    setallocation(result?.data?.fulAllocation ?? '');
                    settimedispatched(result?.data?.fulTimeDispatched ?? '');
                    setquantityordered(result?.data?.fulQuantityOrdered ?? '');
                    setquantitydelivered(result?.data?.fulQuantityDelivered ?? '');
                    setlocation(result?.data?.fulLocation ?? '');
                    setids(result?.data?._id ?? '');
                    setfulOrderId(result?.data?.fulOrderId ?? '');
                    setorderDate(result?.data?.orderDate ?? '');
                } else {
                    setLoading2(false);
                }
            })
            .finally(() => {
                setLoading2(false);
            });
    };

    function toTimestamp(strDate) {
        // const options = {
        //     weekday: 'long',
        //     year: 'numeric',
        //     month: 'long',
        //     day: 'numeric',
        //     hour: 'numeric',
        //     minute: 'numeric'
        // };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh');
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderInfo.length) : 0;

    const retrieveAllDrivers = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.RetrieveDriverRidersUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        // console.log('orders,', data.data);
                        setretrieveDrivers(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllOrders = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.FulOrderedYesNoUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        // console.log('orders,', data.data);
                        setOrder(data.data);
                        setOrderData(data.data);
                        successToast('Orders retrieved successfully');
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllReceivedStatus = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.FulOrderedReceivedStatusUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setreceivedStatus(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllFulOrderDelivered = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.FulOrderedDeliveredUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setfulOrderDelivered(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllFulOrderPending = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.FulOrderedProcessingUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setfulOrderPending(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllFulOrderDispatched = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.FulOrderedDispatchedUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setfulOrderDispatched(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // date filters function

    const retrieveAllOrdersDate = async (from, to) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const data = {
                from,
                to
            };
            setLoading(true);
            fetch(ApiUrl.FulOrderYesNoDateurl, {
                method: 'POST',
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrder(data.data);
                        setOrderData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllReceivedStatusDate = async (from, to) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const data = {
                from,
                to
            };
            setLoading(true);
            fetch(ApiUrl.FulOrderReceivedDateurl, {
                method: 'POST',
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setreceivedStatus(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllFulOrderDeliveredDate = async (from, to) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const data = {
                from,
                to
            };
            setLoading(true);
            fetch(ApiUrl.FulOrderDeliveredDateurl, {
                method: 'POST',
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setfulOrderDelivered(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllFulOrderProcessingDate = async (from, to) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const data = {
                from,
                to
            };
            setLoading(true);
            fetch(ApiUrl.FulOrderProcDateurl, {
                method: 'POST',
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setfulOrderPending(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllFulOrderDispatchedDate = async (from, to) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const data = {
                from,
                to
            };
            setLoading(true);
            fetch(ApiUrl.FulOrderDispatchedDateurl, {
                method: 'POST',
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setfulOrderDispatched(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // end date filters function

    const handledateChange = (event, picker) => {
        setstartDate(picker.startDate._d);
        setendDate(picker.endDate._d);

        // const userInfo = localStorage.getItem('userToken');
        const from = picker.startDate._d;
        const to = picker.endDate._d;
        const data = {
            from,
            to
        };
        localStorage.setItem('OrderState', JSON.stringify(data));
        setLoading(true);

        retrieveAllOrdersDate(from, to);
        retrieveAllReceivedStatusDate(from, to);
        retrieveAllFulOrderDeliveredDate(from, to);
        retrieveAllFulOrderProcessingDate(from, to);
        retrieveAllFulOrderDispatchedDate(from, to);
    };

    const handledateChangeNew = () => {
        // setstartDate(picker.startDate._d);
        // setendDate(picker.endDate._d);

        // const userInfo = localStorage.getItem('userToken');
        // const from = picker.startDate._d;
        // const to = picker.endDate._d;
        // const data = {
        //     from,
        //     to
        // };
        const order = localStorage.getItem('OrderState');
        const data = JSON.parse(order);
        setLoading(true);

        retrieveAllOrdersDate(data?.from, data?.to);
        retrieveAllReceivedStatusDate(data?.from, data?.to);
        retrieveAllFulOrderDeliveredDate(data?.from, data?.to);
        retrieveAllFulOrderProcessingDate(data?.from, data?.to);
        retrieveAllFulOrderDispatchedDate(data?.from, data?.to);
    };

    const onSubmitOrder = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        handleClose();

        const data = {
            ids,
            status,
            processingtime,
            allocation,
            timedispatched,
            quantityordered,
            quantitydelivered,
            location,
            fulOrderId,
            orderDate,
            driverId
        };
        fetch(ApiUrl.UpdateFulOrderDataUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading(false);

                    const order = localStorage.getItem('OrderState');
                    const datas = JSON.parse(order);
                    if (datas?.from && datas?.to) {
                        handledateChangeNew();
                        // console.log('run date here');
                    } else {
                        retrieveAllOrders();
                        retrieveAllFulOrderDelivered();
                        retrieveAllFulOrderPending();
                        retrieveAllFulOrderDispatched();
                        retrieveAllReceivedStatus();
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        const order = localStorage.getItem('OrderState');
        const datas = JSON.parse(order);
        if (datas?.from && datas?.to) {
            handledateChangeNew();
            console.log('run date here');
        } else {
            retrieveAllOrders();
            retrieveAllFulOrderDelivered();
            retrieveAllFulOrderPending();
            retrieveAllFulOrderDispatched();
            retrieveAllReceivedStatus();
            retrieveAllDrivers();
        }
    }, []);

    const handleReset = () => {
        localStorage.removeItem('OrderState');
        retrieveAllOrders();
        retrieveAllFulOrderDelivered();
        retrieveAllFulOrderPending();
        retrieveAllFulOrderDispatched();
        retrieveAllReceivedStatus();
        retrieveAllDrivers();
    };

    const Wrapper = styled.div`
        margin-top: 50px;
        .custom-date-picker {
            background-color: #193855;
            margin-bottom: 10px;
        }

        .batch-filters {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;
            margin-top: -30px;

            @media screen and (max-width: 500px) {
                flex-direction: column;
            }
        }
        .filters {
            display: flex;
            @media screen and (max-width: 500px) {
                flex-direction: column;
                justify-content: center;
                align-items: enter;
            }

            .refresh-btn {
                background-color: #ff740f;
                margin-left: 0.625rem;
                margin-bottom: 10px;
            }
        }

        .custom-select {
            background-color: #ff740f;
            color: #fff;
            font-weight: 500;
            &:hover {
                cursor: pointer;
            }
        }

        .form-text {
            margin-bottom: 30px;
        }
    `;

    function assingOrderStatusColor(status) {
        switch (status) {
            case 'Proforma Invoice':
                return '#003865';
            case 'Cancelled':
                return '#FF1E00';
            case 'Dispatched':
                return '#ff740f';
            case 'Processing':
                return '#7DCE13';
            case 'Delivered':
                return '#3CCF4E';
            case 'Received':
                return '#3CCF4E';
            default:
                return '#3D3C42';
        }
    }

    return (
        <>
            <GenericPageHeader title="Order Status" breadcrumbs="Home | Order Status" />
            <Wrapper>
                <Container>
                    <div className="batch-filters">
                        <div className="filters">
                            <div className="date-ranger-selector">
                                <DateRangePicker initialSettings={{ startDate, endDate }} onApply={handledateChange}>
                                    <LoadingButton
                                        variant="contained"
                                        disableElevation
                                        size="medium"
                                        className="custom-date-picker"
                                        loadingIndicator="Loading…"
                                        loading={loading}
                                    >
                                        Filter By Date
                                    </LoadingButton>
                                </DateRangePicker>
                            </div>
                            <div>
                                <Button
                                    variant="contained"
                                    startIcon={<RefreshIcon />}
                                    className="refresh-btn"
                                    disabled={loading}
                                    onClick={handleReset}
                                >
                                    {loading ? 'Synchronizing...' : 'Clear Filter'}
                                </Button>
                            </div>
                        </div>
                        <div>
                            <select className="form-select custom-select" onChange={handlePageChange}>
                                {pageData.map((pages) => (
                                    <option key={pages.value} value={pages.value}>
                                        {pages.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </Container>
            </Wrapper>
            <br />
            {pageinfo === 'tableview' ? (
                <>
                    <Container>
                        <Row gutterWidth={16}>
                            {loading ? (
                                <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                                    <Skeleton
                                        animation="wave"
                                        variant="rectangular"
                                        width={300}
                                        height={130}
                                        style={{ marginBottom: '10px' }}
                                    />
                                </Col>
                            ) : (
                                <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                                    <BatchCard title="Orders Received" count={receivedStatus.length ?? '0'} />
                                </Col>
                            )}
                            {loading ? (
                                <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                                    <Skeleton
                                        animation="wave"
                                        variant="rectangular"
                                        width={300}
                                        height={130}
                                        style={{ marginBottom: '10px' }}
                                    />
                                </Col>
                            ) : (
                                <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                                    <BatchCard title=" ORDERS PROCESSING" count={fulOrderProcessing.length ?? '0'} />
                                </Col>
                            )}
                            {loading ? (
                                <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                                    <Skeleton animation="wave" variant="rectangular" width={300} height={130} />
                                </Col>
                            ) : (
                                <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                                    <BatchCard title="ORDERS DISPATCHED" count={fulOrderDispatched.length ?? '0'} />
                                </Col>
                            )}
                            {loading ? (
                                <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                                    <Skeleton animation="wave" variant="rectangular" width={300} height={130} />
                                </Col>
                            ) : (
                                <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                                    <BatchCard title=" ORDERS DELIVERED" count={fulOrderDelivered.length ?? '0'} />
                                </Col>
                            )}
                        </Row>
                    </Container>

                    <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
                        <Table sx={{ minWidth: 550 }} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {loading ? (
                                        <TableCell component="th" colSpan="4" align="center">
                                            {/* {' '}
                                                    <CircularProgress size={20} thickness={4} />
                                                    {'LOADING...... '} */}
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginBottom: '50px'
                                                }}
                                            >
                                                <MoonLoader color="#ff740f" />
                                            </div>
                                        </TableCell>
                                    ) : (
                                        <>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Order No.
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Received Date/Time
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Retailer
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                RM
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Location
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Processing Time
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Time Of Dispatched
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Quantity Ordered
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Quantity Delivered
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Drivers Name
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Status
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Actions
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? receivedStatus.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : receivedStatus
                                ).map((row) => (
                                    <TableRow key={row._id}>
                                        <TableCell component="th" scope="row">
                                            {row?.fulOrderId ?? ''}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {toTimestamp(row?.orderDate) ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.newChannelPartnerId?.partnerName ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.salesTeamId?.name ?? ''} {row?.salesTeamId?.firstName ?? ''}{' '}
                                            {row?.salesTeamId?.lastName ?? ''}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row?.fulLocation ?? ''}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row?.fulProcessing ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.fulTimeDispatched ?? ''}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row?.fulQuantityOrdered ?? ''}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row?.fulQuantityDelivered}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.fulAllocation ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <span
                                                style={{
                                                    backgroundColor: `${assingOrderStatusColor(row?.fulStatus)}`,
                                                    padding: '3px 25px',
                                                    color: '#fff',
                                                    borderRadius: '15px',
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {row?.fulStatus ?? ''}
                                            </span>
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            <Stack direction="row" spacing={1}>
                                                <IconButton
                                                    aria-label="edit"
                                                    style={{ color: '#3CCF4E' }}
                                                    onClick={() => handleEditClick(row)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="edit"
                                                    style={{ color: '#ff740f' }}
                                                    onClick={() => handleDeliveryNoteClick(row)}
                                                >
                                                    <NoteAltIcon />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="edit"
                                                    style={{ color: '#AA7942' }}
                                                    onClick={() => handleDispatchImageClick(row)}
                                                >
                                                    <ContentPasteGoIcon />
                                                </IconButton>

                                                <IconButton
                                                    aria-label="edit"
                                                    style={{ color: '#193855' }}
                                                    onClick={() => handlePackSortClick(row)}
                                                >
                                                    <SortIcon />
                                                </IconButton>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[50, 150, 250, { label: 'All', value: -1 }]}
                        component="div"
                        count={receivedStatus.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            ) : (
                ''
            )}

            {pageinfo === 'cardview' ? (
                <>
                    {/* <MainCard title="">
                        <Typography variant="h3" component="h2" textAlign="center">
                            DELIVERED
                        </Typography>
                        <Carousel ssr responsive={responsive}>
                            {fulOrderDelivered.map((item, i) => (
                                <Grid key={item._id} container spacing={2} justify="flex-start">
                                    <Grid key={item._id} item xs={12} sm={6} md={4} lg={10}>
                                        <Box key={item._id} sx={{ ...commonStyles1, borderColor: '#FF740F' }}>
                                            <Card align="center">Order no.#{item?.fulOrderId ?? ''}</Card>
                                        </Box>
                                    </Grid>
                                </Grid>
                            ))}
                        </Carousel>
                    </MainCard>
                    <br />
                    <MainCard title="">
                        <Typography variant="h3" component="h2" textAlign="center">
                            PENDING
                        </Typography>
                        <Carousel ssr responsive={responsive}>
                            {orderInfo.map((item, i) => (
                                <Grid key={item._id} container spacing={2} justify="flex-start">
                                    <Grid key={item._id} item xs={12} sm={6} md={4} lg={10}>
                                        <Box key={item._id} sx={{ ...commonStyles1, borderColor: '#FF740F' }}>
                                            <Card align="center">Order no.#{item?.fulOrderId ?? ''}</Card>
                                        </Box>
                                    </Grid>
                                </Grid>
                            ))}
                        </Carousel>
                    </MainCard> */}

                    <Container>
                        <Row gutterWidth={16}>
                            {loading ? (
                                <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                                    <Skeleton
                                        animation="wave"
                                        variant="rectangular"
                                        width={300}
                                        height={130}
                                        style={{ marginBottom: '10px' }}
                                    />
                                </Col>
                            ) : (
                                <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                                    <BatchCard title="Orders Received" count={receivedStatus.length ?? '0'} />
                                    {orderData.map((row, i) => (
                                        <OrderStatusCard row={row} onEdit={handleEditClick} />
                                    ))}
                                </Col>
                            )}
                            {loading ? (
                                <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                                    <Skeleton
                                        animation="wave"
                                        variant="rectangular"
                                        width={300}
                                        height={130}
                                        style={{ marginBottom: '10px' }}
                                    />
                                </Col>
                            ) : (
                                <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                                    <BatchCard title=" ORDERS PROCESSING" count={fulOrderProcessing.length ?? '0'} />
                                    {fulOrderProcessing.map((row, i) => (
                                        <OrderStatusCard row={row} onEdit={handleEditClick} />
                                    ))}
                                </Col>
                            )}
                            {loading ? (
                                <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                                    <Skeleton animation="wave" variant="rectangular" width={300} height={130} />
                                </Col>
                            ) : (
                                <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                                    <BatchCard title="ORDERS DISPATCHED" count={fulOrderDispatched.length ?? '0'} />
                                    {fulOrderDispatched.map((row, i) => (
                                        <OrderStatusCard row={row} onEdit={handleEditClick} />
                                    ))}
                                </Col>
                            )}
                            {loading ? (
                                <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                                    <Skeleton animation="wave" variant="rectangular" width={300} height={130} />
                                </Col>
                            ) : (
                                <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                                    <BatchCard title=" ORDERS DELIVERED" count={fulOrderDelivered.length ?? '0'} />
                                    {fulOrderDelivered.map((row, i) => (
                                        <OrderStatusCard row={row} onEdit={handleEditClick} />
                                    ))}
                                </Col>
                            )}
                        </Row>
                    </Container>
                </>
            ) : (
                ''
            )}
            {loading2 ? (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '50px'
                        }}
                    >
                        <MoonLoader color="#ff740f" />
                    </div>
                </>
            ) : (
                <>
                    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title" fullWidth>
                        <DialogTitle id="responsive-dialog-title">
                            <Divider>
                                <h6>Update order status information</h6>
                            </Divider>
                        </DialogTitle>
                        <Form onSubmit={onSubmitOrder}>
                            <DialogContent>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={status}
                                            label="Status"
                                            onChange={handleStatus}
                                            className="mb-3"
                                        >
                                            <MenuItem value="Body">Select an option</MenuItem>
                                            <MenuItem value="Received">Received</MenuItem>
                                            <MenuItem value="Processing">Processing</MenuItem>
                                            <MenuItem value="Dispatched">Dispatched</MenuItem>
                                            <MenuItem value="Assigned">Assigned</MenuItem>
                                            <MenuItem value="Delivered">Delivered</MenuItem>
                                            <MenuItem value="Cancelled">Cancelled</MenuItem>
                                            <MenuItem value="In-Transit">In-Transit</MenuItem>
                                            <MenuItem value="OnHold">OnHold</MenuItem>
                                            <MenuItem value="NextDay">NextDay</MenuItem>
                                            <MenuItem value="OutOfStock">OutOfStock</MenuItem>
                                            <MenuItem value="ProformaInvoice">Proforma Invoice</MenuItem>
                                            Proforma Invoice
                                        </Select>
                                    </FormControl>
                                    <br />

                                    <TextField
                                        className="form-text mb-3"
                                        autoComplete="off"
                                        name="processingtime"
                                        variant="outlined"
                                        id="processingtime"
                                        label="Processing Time"
                                        value={processingtime}
                                        onChange={(e) => setprocessingtime(e.target.value)}
                                        fullWidth
                                    />

                                    <br />

                                    {/* <TextField
                                        className="form-text mb-3"
                                        autoComplete="off"
                                        name="allocation"
                                        variant="outlined"
                                        id="allocation"
                                        label="Drivers Name"
                                        value={allocation}
                                        onChange={(e) => setallocation(e.target.value)}
                                        fullWidth
                                    /> */}
                                    <Autocomplete
                                        id="country-select-demo"
                                        autoHighlight
                                        value={allocation}
                                        onChange={(event, newValue) => {
                                            setallocation(newValue);
                                            filterDriver(newValue);
                                        }}
                                        options={retrieveDrivers.map((option) => `${option.firstName}${option.lastName}`)}
                                        isOptionEqualToValue={(option, value) => option._id === value._id}
                                        renderInput={(params) => <TextField {...params} label="Choose a driver/rider" />}
                                    />

                                    <br />

                                    <TextField
                                        className="form-text mb-3"
                                        autoComplete="off"
                                        name="timedispatched"
                                        variant="outlined"
                                        id="timedispatched"
                                        label="Time of dispatch"
                                        value={timedispatched}
                                        onChange={(e) => settimedispatched(e.target.value)}
                                        fullWidth
                                    />

                                    <br />

                                    <TextField
                                        className="form-text mb-3"
                                        autoComplete="off"
                                        name="quantityordered"
                                        variant="outlined"
                                        id="quantityordered"
                                        label="Quantity ordered"
                                        value={quantityordered}
                                        onChange={(e) => setquantityordered(e.target.value)}
                                        fullWidth
                                    />

                                    <br />

                                    <TextField
                                        className="form-text mb-3"
                                        autoComplete="off"
                                        name="quantitydelivered"
                                        variant="outlined"
                                        id="quantitydelivered"
                                        label="Quantity delivered"
                                        value={quantitydelivered}
                                        onChange={(e) => setquantitydelivered(e.target.value)}
                                        fullWidth
                                    />

                                    <br />

                                    <TextField
                                        className="form-text mb-3"
                                        autoComplete="off"
                                        name="location"
                                        variant="outlined"
                                        id="location"
                                        label="Location"
                                        value={location}
                                        onChange={(e) => setlocation(e.target.value)}
                                        fullWidth
                                    />
                                </Form.Group>

                                {/* </DialogContentText> */}
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    size="sm"
                                    className={classes.item}
                                    autoFocus
                                    onClick={handleClose}
                                    color="error"
                                >
                                    Cancel
                                </Button>
                                <Button autoFocus variant="contained" type="submit" size="sm" className={classes.item}>
                                    Update
                                </Button>
                            </DialogActions>
                        </Form>
                    </Dialog>
                </>
            )}
        </>
    );
};

export default OrderTableStatus;
