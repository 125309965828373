import React, { useEffect, useState } from 'react';
import { Table, Paper, Space, Button, Select } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import WestIcon from '@mui/icons-material/West';
import './fleet.css';
import TrackingMap from './TrackingMap';
import Image from '../../assets/images/Frame 1000002229.png';
import Divider from '@mui/material/Divider';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import { encrypt, decrypt } from '../../utils/encrypt';
import { ToastContainer, toast } from 'react-toastify';
import ApiUrl from '../../network';

const country = localStorage.getItem('country');
const mapCenter = country === 'Ghana' ? { lat: 6.5, lng: -1.1 } : { lat: 6.465422, lng: 3.406448 };

const TrackingFleet = () => {
    const [loading, setLoading] = useState(false);

    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [fleetSchedule, setFleetSchedule] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();

    // get fleet schedule
    const singleFleetSchedule = async (data) => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/fleetschedule/viewfleetschedule`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            if (data) {
                if (data) urlParams.append('id', data);
            }

            url = `${url}?${urlParams.toString()}`;

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setLoading(false);
                        setFleetSchedule(answer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                        toast.error(answer);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        singleFleetSchedule(id);
    }, []);

    return (
        <>
            <div className="track-heading">
                <WestIcon sx={{ fontSize: '32px', marginLeft: '25px', cursor: 'pointer' }} onClick={() => navigate(-1)} />
                <span>Tracking</span>
            </div>
            <div className="tracking-container">
                <div className="tracking-map">
                    <TrackingMap position={{ lat: -34.397, lng: 150.644 }} mapCenter={mapCenter} />
                </div>
                <div className="tracking-details">
                    <div className="tracking-period">
                        <h1>Tracking Period</h1>
                        <Space wrap style={{ marginLeft: '11px', width: '100%', display: 'flex' }}>
                            <Select
                                defaultValue="Day"
                                style={{ width: 176 }}
                                size="large"
                                allowClear
                                options={[{ value: 'Day', label: 'Day' }]}
                            />
                            <Select
                                defaultValue="Month"
                                style={{ width: 176 }}
                                size="large"
                                allowClear
                                options={[{ value: 'Month', label: 'Month' }]}
                            />
                            <Select
                                defaultValue="Time"
                                style={{ width: 176 }}
                                size="large"
                                allowClear
                                options={[{ value: 'Time', label: 'Time' }]}
                            />
                        </Space>
                    </div>
                    <div className="drive-details">
                        <div style={{ height: '44.03px' }} />
                        <Divider light />
                        <div className="driver-details">
                            <div className="driver-photo">
                                <img src={Image} alt="" />
                            </div>
                            <div className="driver-info">
                                <div>
                                    <span>Drivers Name</span>
                                    <span>
                                        {fleetSchedule?.driverId?.firstName} {fleetSchedule?.driverId?.lastName}
                                    </span>
                                </div>
                                <div>
                                    <span>Vehicle Number</span>
                                    <span>{fleetSchedule?.vehicleNo}</span>
                                </div>
                                <div>
                                    <span>Vehicle</span>
                                    <span>{fleetSchedule?.vehicleId?.vehicleType}</span>
                                </div>
                                <div>
                                    <span>Route</span>
                                    {fleetSchedule?.locations?.map((location) => (
                                        <span key={location._id} className="mb-2">
                                            {location.name}
                                        </span>
                                    ))}
                                </div>
                                <div>
                                    <span>RMA</span>
                                    {fleetSchedule?.rmas?.map((rma) => (
                                        <span key={rma._id} className="mb-2">
                                            {rma.firstName} {rma.lastName}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <Divider light />
                        <div className="delivery-details">
                            <div className="distance">
                                <span>Distance</span>
                                <span className="distance-in-miles">
                                    <span>{fleetSchedule?.distanceInMiles} </span> <span className="km">miles</span>
                                </span>
                            </div>
                            <div className="time">
                                <div className="arrows">
                                    <NorthEastIcon
                                        sx={{
                                            backgroundColor: '#B3E6CC',
                                            width: '30px',
                                            height: '30px',
                                            borderRadius: '100%',
                                            padding: '7px'
                                        }}
                                    />
                                    <SouthEastIcon
                                        sx={{
                                            backgroundColor: '#B7C5FF',
                                            width: '30px',
                                            height: '30px',
                                            borderRadius: '100%',
                                            padding: '7px'
                                        }}
                                    />
                                </div>
                                <div className="depature-details">
                                    <div>
                                        <span>Departure Date/Time</span>
                                        <span>Mon, 9 Oct, 2023, 10:32 AM</span>
                                    </div>
                                    <div>
                                        <span>Arrival Date/Time</span>
                                        <span>Mon, 9 Oct, 2023, 15:32 PM</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default TrackingFleet;
