/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Link, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import useScriptRef from 'hooks/useScriptRef';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Form from 'react-bootstrap/Form';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    }
}));

const AddDistributorInventory = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [parts, setParts] = useState([]);
    const [partId, setpartId] = useState('');
    const [wholeSalePrice, setwholeSalePrice] = useState('');
    const [garagePrice, setgaragePrice] = useState('');
    const [partName, setpartName] = useState('');
    const [qty, setqty] = useState('');
    const [stockDate, setstockDate] = useState(new Date());
    const [reference, setreference] = useState('');
    const [qtyboxes, setqtyboxes] = useState('');
    // const [supplier, setsupplier] = useState('');
    // const [supplierName, setsupplierName] = useState('');
    const [loading, setLoading] = useState(false);
    // const [distributorInfo, setDistributors] = useState([]);
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [items, setItems] = useState([]);

    // const theme = useTheme();

    // const retrieveAllDistributor = async () => {
    //     try {
    //         const userInfo = localStorage.getItem('userToken');
    //         const config = {
    //             headers: {
    //                 'auth-token': userInfo,
    //                 'Content-Type': 'application/json'
    //             }
    //         };
    //         setLoading(true);
    //         fetch(ApiUrl.DistributorUrl, config)
    //             .then((data) => data.json())
    //             .then((data) => {
    //                 if (data.status === true) {
    //                     setDistributors(data.data);
    //                     setLoading(false);
    //                 } else {
    //                     toast.error(data.data);
    //                     setLoading(false);
    //                 }
    //             })
    //             .finally(() => {
    //                 setLoading(false);
    //             });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    const retrieveAllPart = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.DistributorPartUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setParts(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handleStockDateChange = (newValue) => {
        setstockDate(newValue);
    };

    const handleDeleteClick = (row, index) => {
        const newList = Object.assign([], items);
        newList.splice(index, 1);
        setItems(newList);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        retrieveAllPart();
        // retrieveAllDistributor();
    }, []);

    const onSubmitPart = (event) => {
        event.preventDefault();
        // items, setItems
        setItems([
            ...items,
            {
                partId,
                partName,
                qty,
                qtyboxes,
                wholeSalePrice,
                garagePrice
            }
        ]);

        handleClose();
    };

    // Submit Order
    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        const data = {
            stockDate,
            reference,
            items
            // supplier,
            // supplierName
        };

        fetch(ApiUrl.AddDistributorInventoryUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/distributorInventory');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // const filtersupplier = (event) => {
    //     const newFilter = distributorInfo.filter((value) => (value.partnerName || '').toLowerCase().includes((event || '').toLowerCase()));
    //     newFilter.forEach((option) => {
    //         setsupplier(option?._id);
    //     });
    // };

    const filterPart = (event) => {
        const newFilter = parts.filter((value) => (value.sku || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('newFilter', newFilter);
        newFilter.forEach((option) => {
            setpartId(option._id);
            setwholeSalePrice(option.wholeSalePrice);
            setgaragePrice(option.garagePrice);
        });
    };

    return (
        <>
            <PageHeader title="Inventory" subTitle="Home | Add Inventory" />
            <MainCard title="">
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="In Stock date"
                                        inputFormat="MM/dd/yyyy"
                                        value={stockDate}
                                        onChange={handleStockDateChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    value={supplierName}
                                    required
                                    onChange={(event, newValue) => {
                                        setsupplierName(newValue);
                                        filtersupplier(newValue);
                                    }}
                                    options={distributorInfo.map((option) => option.partnerName)}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <TextField {...params} label="Supplier" />}
                                />
                            </Box>
                        </Grid> */}
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <TextField
                                    autoComplete="off"
                                    name="reference"
                                    variant="outlined"
                                    required
                                    id="reference"
                                    label="Reference"
                                    value={reference}
                                    onChange={(e) => setreference(e.target.value)}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <br />

                    <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                        <Button variant="contained" onClick={handleClickOpen} size="sm" className={classes.item}>
                            Add Part
                        </Button>
                    </Box>
                    <br />

                    <Grid container spacing={4} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Sku No.
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Sku Name
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                QTY
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                WholeSalePrice
                                            </TableCell>
                                            {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                                Price
                                            </TableCell> */}

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {row.partId}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.partName}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    {row.qty}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    {row.wholeSalePrice}
                                                </TableCell>

                                                {/* <TableCell component="th" scope="row">
                                                    {row.garagePrice}
                                                </TableCell> */}

                                                <TableCell component="th" scope="row">
                                                    <IconButton
                                                        aria-label="delete"
                                                        color="error"
                                                        onClick={() => handleDeleteClick(row, index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <Box display="flex" justifyContent="space-between">
                        <Button component={Link} to="/distributorInventory" type="submit" variant="contained" color="error">
                            Reset
                        </Button>
                        <Button type="submit" variant="contained" color="secondary">
                            {loading ? 'Submitting ..' : 'Submit'}
                        </Button>
                    </Box>
                </form>

                <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">Add Part</DialogTitle>
                    <Form onSubmit={onSubmitPart}>
                        <DialogContent>
                            <Form.Group controlId="formFile" className="mb-3">
                                <Box width={200}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        value={partName}
                                        required
                                        onChange={(event, newValue) => {
                                            setpartName(newValue);
                                            filterPart(newValue);
                                        }}
                                        options={parts.map((option) => option.sku)}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => <TextField {...params} label="Sku Name" />}
                                    />
                                </Box>
                                <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        name="wholeSalePrice"
                                        variant="outlined"
                                        required
                                        id="wholeSalePrice"
                                        label="Wholesale Price In Pieces"
                                        value={wholeSalePrice}
                                        onChange={(e) => setwholeSalePrice(e.target.value)}
                                    />
                                </Box>
                                <br />
                                {/* <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        name="garagePrice"
                                        variant="outlined"
                                        disabled
                                        id="garagePrice"
                                        label="Garage Price In Pieces"
                                        value={garagePrice}
                                        onChange={(e) => setgaragePrice(e.target.value)}
                                    />
                                </Box>
                                <br /> */}
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        name="qty"
                                        variant="outlined"
                                        required
                                        id="qty"
                                        label="Qty In Pieces"
                                        value={qty}
                                        onChange={(e) => setqty(e.target.value)}
                                    />
                                </Box>
                                <br />
                                <Box width={180}>
                                    <TextField
                                        autoComplete="off"
                                        name="qtyboxes"
                                        variant="outlined"
                                        required
                                        id="qtyboxes"
                                        label="Qty In Boxes/Pack"
                                        value={qtyboxes}
                                        onChange={(e) => setqtyboxes(e.target.value)}
                                    />
                                </Box>
                            </Form.Group>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" size="sm" className={classes.item} autoFocus onClick={handleClose} color="error">
                                Cancel
                            </Button>
                            <Button autoFocus variant="contained" type="submit" size="sm" className={classes.item}>
                                Add
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </MainCard>
        </>
    );
};
export default AddDistributorInventory;
