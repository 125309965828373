import BatchCustomHeader from 'components/OrderBatching/BatchCustomHeader';
import BatchCardList from 'components/OrderBatching/BatchCardList';

const BatchesPage = () => {
    return (
        <>
            <BatchCustomHeader /> <BatchCardList />
        </>
    );
};
export default BatchesPage;
