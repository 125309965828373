import {
    ADD_PART_BEGIN,
    ADD_PART_FAILURE,
    ADD_PART_SUCCESS,
    DARKSTORE_BEGIN,
    DARKSTORE_SUCCESS,
    INVENTORY_BEGIN,
    INVENTORY_SUCCESS,
    INVENTORY_FAILURE,
    UPDATE_BEGIN,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    STORE_STATS_BEGIN,
    STORE_STATS_SUCCESS,
    STORE_STATS_FAILURE,
    PART_HISTORY_BEGIN,
    PART_HISTORY_SUCCESS,
    PART_HISTORY_FAILURE
} from './darkstore-actions';

const darkstoreReducer = (state, action) => {
    switch (action.type) {
        case DARKSTORE_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case DARKSTORE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                darkstores: action.payload.darkstores,
                items: action.payload.items
            };
        case ADD_PART_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case ADD_PART_SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            };
        case ADD_PART_FAILURE:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            };
        case INVENTORY_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case INVENTORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message,
                storeInventory: action.payload.storeInventory
            };
        case INVENTORY_FAILURE:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            };
        // TODO:  updating an inventory -> change naming later
        case UPDATE_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            };
        case UPDATE_FAILURE:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            };
        case 'UPDATE_INVENTORY_STOCK':
            return {
                ...state,
                editId: action.payload.editId,
                garagePrice: action.payload.garagePrice,
                wholeSalePrice: action.payload.wholeSalePrice,
                partId: action.payload.partId,
                product: action.payload.product
            };
        case 'CLEAR_UPDATE_VALUES':
            return {
                ...state,
                qty: ''
            };
        case 'HANDLE_CHANGE':
            return {
                ...state,
                [action.payload.name]: action.payload.value
            };
        case STORE_STATS_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case STORE_STATS_SUCCESS:
            return {
                ...state,
                totalProduct: action.payload.totalProduct,
                todaysells: action.payload.todaysells,
                yesterdaysells: action.payload.yesterdaysells,
                totalsells: action.payload.totalsells,
                stockissues: action.payload.stockissues,
                isLoading: false
            };
        case STORE_STATS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case PART_HISTORY_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case PART_HISTORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                partHistory: action.payload.partHistory
            };
        case PART_HISTORY_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        default:
            throw new Error(`No such action ${action.type}`);
    }
};

export default darkstoreReducer;
