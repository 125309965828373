import { Card, Typography } from 'antd';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { List, ListItem, Box } from '@mui/material';
import './links.css';
import { useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import CallMadeIcon from '@mui/icons-material/CallMade';

const QuickLinks = ({ quicklinks, text = 'How are you doing today?' }) => {
    const userFirstName = localStorage.getItem('userFirstName');
    const [open, setOpen] = useState(true);

    const toggleOpenClose = () => {
        setOpen(!open);
    };

    const userRole = localStorage.getItem('userRole');

    const links = quicklinks.filter((link) => {
        return userRole === 'Sales' && link.title !== 'Assign Tasks' ? link : userRole !== 'Sales' ? link : '';
    });

    return (
        <>
            <List
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    padding: '0 0 16px 0',
                    borderBottom: '1px dashed #E7EAEB',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    margin: '32px auto 15px'
                }}
            >
                {' '}
                <ListItem
                    sx={{ fontWeight: 500, fontSize: '1.875rem', color: '#0A2938', padding: 0 }}
                >{`Hello, ${userFirstName}  👋🏽`}</ListItem>
                <ListItem sx={{ fontSize: '0.875rem', color: '#565C6B', padding: 0 }}>{text}</ListItem>
            </List>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer'
                }}
                onClick={toggleOpenClose}
            >
                <Typography
                    style={{
                        // margin: '2rem 0',
                        fontSize: '18px',
                        fontWeight: '500',
                        color: '#0A2938'
                    }}
                >
                    Quick Links
                </Typography>

                {open ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
            </Box>
            <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                sx={{
                    borderBottom: '1px dashed #E7EAEB',
                    margin: '0 0 16px 0'
                }}
            >
                <div className="links">
                    {links?.map((item) => (
                        <Link to={item.url} key={item.id}>
                            <Card style={{ width: 320 }}>
                                <div className="info">
                                    <Typography
                                        sx={{
                                            color: '#7B808C',
                                            backgroundColor: '#F6F6F6'
                                        }}
                                    >
                                        {item.icon}
                                    </Typography>
                                    <CallMadeIcon
                                        sx={{
                                            color: '#ff740f'
                                        }}
                                    />
                                </div>

                                <Typography
                                    style={{
                                        marginTop: '10px',
                                        fontSize: '18px',
                                        color: '#3B5460',
                                        fontWeight: '400'
                                    }}
                                >
                                    {item.title}
                                </Typography>
                            </Card>
                        </Link>
                    ))}
                </div>
            </Collapse>
        </>
    );
};
export default QuickLinks;
