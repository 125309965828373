import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';

const MyComponent = withScriptjs(
    withGoogleMap((props) => (
        <GoogleMap
            defaultZoom={8}
            defaultCenter={props.center}
            options={{
                streetViewControl: false,
                mapTypeControl: false
            }}
        >
            {/* <Marker
            // key={pin._id}
            // position={{ lat: +pin.latitude, lng: +pin.longitude }}
            // onClick={() => props.handleMarkerClick(pin._id)}
            // icon={{
            //     url: props.latestOrder._id === pin._id ? latestIcon : deliveredIcon,
            //     scaledSize: new window.google.maps.Size(20, 20)
            // }}
            >
                <InfoWindow>hello</InfoWindow>
            </Marker> */}
        </GoogleMap>
    ))
);

const TrackingMap = ({ mapCenter }) => {
    const key = 'AIzaSyBZqHjOL-7tPYGJfRKkROaOMxlHfWkou_0';
    return (
        <MyComponent
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            center={mapCenter}
        />
    );
};

export default TrackingMap;
