import { useEffect } from 'react';

import PageHeader from '../../components/PageHeader';
import Card from './Card';

const CustomerSuccessDashboard = () => {
    // const [allOrders, setAllOrders] = useState([]);
    // const [allLeads, setAllLeads] = useState([]);
    // const [allProspects, setAllProspects] = useState([]);
    // const [allNewRetailers, setAllNewRetailers] = useState([]);
    // const [allTeleSalesCalls, setAllTeleSalesCalls] = useState([]);
    // const [allCustomerRatings, setAllCustomerRatings] = useState([]);
    // const [from, setFrom] = useState(null);
    // const [to, setTo] = useState(null);

    useEffect(() => {
        // if (from && to) {
        //     //
        // } else {
        //     //
        // }
    }, []);

    return (
        <>
            <PageHeader title="Customer Success | Dashboard" subTitle="Home | Customer Success | Dashboard" />

            <div className="flex-row-reverse">
                <button type="button" className="filter">
                    Filter by Date
                </button>
            </div>
            <br />
            <br />

            <div className="row">
                <div className="col-4 col-lx-4 col-l-4 col-m-6 col-s-12 padding-all-10">
                    <Card topText="Total number of orders" bottomText={50} value={500} percent={25} />
                </div>
                <div className="col-4 col-lx-4 col-l-4 col-m-6 col-s-12 padding-all-10">
                    <Card topText="Total number of leads" bottomText={50} value={500} percent={25} />
                </div>
                <div className="col-4 col-lx-4 col-l-4 col-m-6 col-s-12 padding-all-10">
                    <Card topText="Total number of prospects" bottomText={50} value={500} percent={25} />
                </div>
                <div className="col-4 col-lx-4 col-l-4 col-m-6 col-s-12 padding-all-10">
                    <Card topText="Total number of new retailers" bottomText={50} value={500} percent={25} />
                </div>
                <div className="col-4 col-lx-4 col-l-4 col-m-6 col-s-12 padding-all-10">
                    <Card topText="Total number of telesales calls" bottomText={50} value={500} percent={25} />
                </div>
                <div className="col-4 col-lx-4 col-l-4 col-m-6 col-s-12 padding-all-10">
                    <Card topText="Average customer rating & satisfaction" bottomText={50} value={500} percent={25} />
                </div>
            </div>
            <div />
        </>
    );
};

export default CustomerSuccessDashboard;
