import { Link, useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Modal, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ApiUrl from '../../../network';
import { decrypt } from '../../../utils/encrypt';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [partsDiscount, setPartsDiscount] = useState([]);
    const [logsModal, setLogsModal] = useState(false);
    const [logs, setLogs] = useState([]);
    const [partsLogs, setPartsLog] = useState([]);
    const [partModal, setPartModal] = useState(false);

    const fetchPartDiscount = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/parts/v2/viewpartsdiscount/${id}`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                setPartsDiscount(decryptedData);
                setLoading(false);
            } else {
                toast.error(result.message);
                setLoading(false);
            }
        } catch (e) {
            toast(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };
    const fetchPartLogs = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/parts/v2/viewpartsdiscountlogs/${id}`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                setLogs(decryptedData?.discountlogs);
                setPartsLog(decryptedData?.partlist);
                setLoading(false);
            } else {
                toast.error(result.message);
                setLoading(false);
            }
        } catch (e) {
            toast(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPartDiscount();
        fetchPartLogs();
    }, [id]);

    const data = partsDiscount.map((item, index) => {
        return {
            key: item._id,
            part_name: item.partIdgh,
            discount: item.discount || 0,
            price: item?.price || 0,
            qty: item.qty,
            status: item.status
        };
    });

    const logsData = logs.map((item, index) => {
        return {
            key: item._id,
            part_name: item.partIdgh,
            discount: item.discount,
            qty: item.qty,
            updatedBy: `${item?.updatedBy?.firstName + item?.updatedBy?.lastName}`,
            createdAt: new Date(item.createdAt).toLocaleDateString()
        };
    });

    const partData = partsLogs.map((item, index) => {
        return {
            key: item._id,
            part_name: item.partId,
            garagePrice: item.garagePrice,
            wholeSalePrice: item.wholeSalePrice,
            updatedBy: item?.updatedBy,
            updatedEmail: item?.updatedBy,
            createdAt: new Date(item.createdAt).toLocaleDateString()
        };
    });

    const columns = [
        {
            title: 'Part Name',
            dataIndex: 'part_name',
            key: 'part_name',
            // eslint-disable-next-line camelcase
            render: (product_name) => (
                <div className="d-flex align-items-center">
                    <img
                        src={product_name.partsImage ? product_name.partsImage : 'https://via.placeholder.com/150'}
                        alt={product_name.sku}
                        className="mr-2"
                        style={{
                            width: 50,
                            height: 50,
                            objectFit: 'cover',
                            borderRadius: '10px',
                            border: '1px solid #f0f0f0'
                        }}
                    />
                    <span>{product_name.sku}</span>
                </div>
            )
        },
        {
            title: 'Discount selling price (%)',
            dataIndex: 'discount',
            key: 'discount'
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price'
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        }
    ];
    const columnslogs = [
        {
            title: 'Part Name',
            dataIndex: 'part_name',
            key: 'part_name',
            // eslint-disable-next-line camelcase
            render: (product_name) => (
                <div className="d-flex align-items-center">
                    <img
                        src={product_name.partsImage ? product_name.partsImage : 'https://via.placeholder.com/150'}
                        alt={product_name.sku}
                        className="mr-2"
                        style={{
                            width: 50,
                            height: 50,
                            objectFit: 'cover',
                            borderRadius: '10px',
                            border: '1px solid #f0f0f0'
                        }}
                    />
                    <span>{product_name.sku}</span>
                </div>
            )
        },
        {
            title: 'Discount selling price (%)',
            dataIndex: 'discount',
            key: 'discount'
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty'
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy'
        },
        {
            title: 'created at',
            dataIndex: 'createdAt',
            key: 'createdAt'
        }
    ];
    const columnspartlogs = [
        {
            title: 'Part Name',
            dataIndex: 'part_name',
            key: 'part_name',
            // eslint-disable-next-line camelcase
            render: (product_name) => (
                <div className="d-flex align-items-center">
                    <img
                        src={product_name.partsImage ? product_name.partsImage : 'https://via.placeholder.com/150'}
                        alt={product_name.sku}
                        className="mr-2"
                        style={{
                            width: 50,
                            height: 50,
                            objectFit: 'cover',
                            borderRadius: '10px',
                            border: '1px solid #f0f0f0'
                        }}
                    />
                    <span>{product_name.sku}</span>
                </div>
            )
        },
        {
            title: 'Garage Price',
            dataIndex: 'garagePrice',
            key: 'garagePrice'
        },
        {
            title: 'Whole Sale Price',
            dataIndex: 'wholeSalePrice',
            key: 'wholeSalePrice'
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy'
        },
        {
            title: 'Updated Email',
            dataIndex: 'updatedEmail',
            key: 'updatedEmail'
        },
        {
            title: 'created at',
            dataIndex: 'createdAt',
            key: 'createdAt'
        }
    ];

    return (
        <div>
            <div className="m-header">
                <div>
                    <Link to="/parts">
                        <h1 style={{ color: '#0A2938', display: 'flex', alignItems: 'center' }}>
                            <ArrowLeftOutlined className="mr-2" /> View discount
                        </h1>
                    </Link>
                </div>
                <div>
                    <Button
                        onClick={() => setLogsModal(true)}
                        style={{
                            backgroundColor: '#FFF',
                            color: '#FF740F',
                            border: '1px solid #FF740F'
                        }}
                        className="w-fit primary-bg  mr-2"
                    >
                        Discount logs
                    </Button>
                    <Button
                        onClick={() => setPartModal(true)}
                        className="w-fit primary-bg mr-2"
                        style={{
                            backgroundColor: '#FFF',
                            color: '#FF740F',
                            border: '1px solid #FF740F'
                        }}
                    >
                        Part list logs
                    </Button>
                    <Button
                        className="w-fit"
                        style={{
                            backgroundColor: '#FFF',
                            color: '#FF740F',
                            border: '1px solid #FF740F'
                        }}
                        onClick={() => (window.location.href = `/parts/discount/edit/${id}`)}
                    >
                        Edit discount
                    </Button>
                    <Button
                        className="w-fit ml-2"
                        style={{
                            backgroundColor: '#FFF',
                            color: '#FF740F',
                            border: '1px solid #FF740F'
                        }}
                        onClick={() => (window.location.href = `/parts/discount/add/${id}`)}
                    >
                        Add discount
                    </Button>
                </div>
            </div>
            <div className="m-content mt-4">
                <div className="table-responsive">
                    <Table columns={columns} dataSource={data} loading={loading} />
                </div>
            </div>
            <Modal width={800} title="Discount logs" open={logsModal} onCancel={() => setLogsModal(false)} footer={null}>
                <div className="table-responsive">
                    <Table columns={columnslogs} dataSource={logsData} loading={loading} />
                </div>
            </Modal>
            <Modal width={800} title="Part list logs" open={partModal} onCancel={() => setPartModal(false)} footer={null}>
                <div className="table-responsive">
                    <Table columns={columnspartlogs} dataSource={partData} loading={loading} />
                </div>
            </Modal>
        </div>
    );
};

export default Index;
