import { Tag } from 'antd';

export const ViewPreSoldOrder = ({ orderList, columns, Table }) => {
    return (
        <div className="batchContainer">
            <div>
                <div className="productsContainer orderListContainer">
                    <Tag color="green">orderNo: {orderList.orderNo}</Tag>
                    <Table columns={columns} dataSource={orderList.items} />
                </div>
            </div>
        </div>
    );
};

export default ViewPreSoldOrder;
