/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
// import { notification } from 'antd';
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';
import useScriptRef from 'hooks/useScriptRef';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';

const EditDistributor = () => {
    const [partnerName, setpartnerName] = useState('');
    const [partnerContact, setpartnerContact] = useState('');
    const [partnerContact2, setpartnerContact2] = useState('');
    const [partnerContact3, setpartnerContact3] = useState('');
    const [location, setlocation] = useState('');
    const [locationLat, setlocationLat] = useState('');
    const [locationLng, setlocationLng] = useState('');
    const [partnerEmail, setpartnerEmail] = useState('');
    const [signupDate, setsignupDate] = useState(new Date());
    const [notes, setnotes] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [status, setStatus] = useState('');
    // const [distributoruser, setuserdistributordata] = useState([]);
    // const [distributorUserId, setdistributorUserId] = useState('');
    const [contactPerson, setcontactPerson] = useState('');
    // const [status, setStatus] = useState('');
    const history = useNavigate();
    const scriptedRef = useScriptRef();

    const handleSignupChange = (newValue) => {
        setsignupDate(newValue);
    };
    const handleChange = (event) => {
        setStatus(event.target.value);
    };
    const { id } = useParams();

    // const retrieveAlLdistributorUsers = async () => {
    //     try {
    //         const userInfo = localStorage.getItem('userToken');
    //         const config = {
    //             headers: {
    //                 'auth-token': userInfo,
    //                 'Content-Type': 'application/json'
    //             }
    //         };
    //         setLoading2(true);
    //         fetch(ApiUrl.AllDistributorUserUrl, config)
    //             .then((data) => data.json())
    //             .then((data) => {
    //                 if (data.status === true) {
    //                     // setuserdistributordata(data.data);
    //                 } else {
    //                     toast.error(data.data);
    //                 }
    //             })
    //             .finally(() => {
    //                 setLoading2(false);
    //             });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.ViewAllChannelPartnerURL + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    // console.log(result?.data);
                    setpartnerName(result?.data?.partnerName ?? '');
                    setpartnerContact(result?.data?.partnerContact ?? '');
                    setlocation(result?.data?.location ?? '');
                    setpartnerEmail(result?.data?.partnerEmail ?? '');
                    setsignupDate(result?.data?.signupDate ?? '');
                    setcontactPerson(result?.data?.contactPerson ?? '');
                    setnotes(result?.data?.notes ?? '');
                    setStatus(result?.data?.status ?? '');
                    setlocationLat(result?.data?.locationLat ?? '');
                    setlocationLng(result?.data?.locationLng ?? '');
                    setpartnerContact2(result?.data?.partnerContact2 ?? '');
                    setpartnerContact3(result?.data?.partnerContact3 ?? '');
                    // setdistributorUserId(result?.data?.distributorUserId ?? '');
                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                    // setLoading(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading2(false);
            });

        // retrieveAlLdistributorUsers();
    }, [id]);

    // const handleDistributorUserChange = (event) => {
    //     setdistributorUserId(event.target.value);
    // };

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');

        const data = {
            id,
            partnerName,
            partnerContact,
            partnerContact2,
            partnerContact3,
            location,
            locationLat,
            locationLng,
            partnerEmail,
            signupDate,
            contactPerson,
            notes,
            status
            // distributorUserId
        };
        setLoading(true);
        fetch(ApiUrl.UpdateChannelPartnerURL, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    // window.location.href = '/roles';
                    toast.success(result.message);
                    setLoading(false);
                    setTimeout(() => {
                        if (scriptedRef.current) {
                            history('/distributor');
                        }
                    }, 3000);
                } else {
                    toast.error(result.data);
                    // console.log(result);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid item xs={12} sm={6}>
                        <PageHeader title="Channel Partners" subTitle="Home | Configuration | Channel Partners  | Distributor" />
                        <MainCard title="Edit Distributor">
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    autoComplete="off"
                                    name="partnerName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="partnerName"
                                    value={partnerName}
                                    label="Partner Name"
                                    onChange={(e) => setpartnerName(e.target.value)}
                                    autoFocus
                                />
                                <br />
                                <br />
                                <TextField
                                    autoComplete="off"
                                    name="contactPerson"
                                    variant="outlined"
                                    fullWidth
                                    id="contactPerson"
                                    value={contactPerson}
                                    label="Contact Person"
                                    onChange={(e) => setcontactPerson(e.target.value)}
                                />
                                <br />
                                <br />

                                <TextField
                                    autoComplete="off"
                                    type="number"
                                    name="partnerContact"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="partnerContact"
                                    value={partnerContact}
                                    label="Contact"
                                    onChange={(e) => setpartnerContact(e.target.value)}
                                />
                                <br />
                                <br />
                                <TextField
                                    autoComplete="off"
                                    type="number"
                                    name="partnerContact2"
                                    variant="outlined"
                                    fullWidth
                                    id="partnerContact2"
                                    value={partnerContact2}
                                    label="Contact 2"
                                    onChange={(e) => setpartnerContact2(e.target.value)}
                                />
                                <br />
                                <br />
                                <TextField
                                    autoComplete="off"
                                    type="number"
                                    name="partnerContact3"
                                    variant="outlined"
                                    fullWidth
                                    id="partnerContact3"
                                    value={partnerContact3}
                                    label="Contact 3"
                                    onChange={(e) => setpartnerContact3(e.target.value)}
                                />
                                <br />
                                <br />
                                <TextField
                                    autoComplete="off"
                                    type="number"
                                    name="locationLat"
                                    variant="outlined"
                                    fullWidth
                                    id="locationLat"
                                    value={locationLat}
                                    label="Latitude"
                                    onChange={(e) => setlocationLat(e.target.value)}
                                />
                                <br />
                                <br />
                                <TextField
                                    autoComplete="off"
                                    type="number"
                                    name="locationLng"
                                    variant="outlined"
                                    fullWidth
                                    id="locationLng"
                                    value={locationLng}
                                    label="longitude"
                                    onChange={(e) => setlocationLng(e.target.value)}
                                />
                                <br />
                                <br />
                                <TextField
                                    autoComplete="off"
                                    name="location"
                                    variant="outlined"
                                    fullWidth
                                    id="location"
                                    value={location}
                                    label="Address"
                                    onChange={(e) => setlocation(e.target.value)}
                                />
                                <br />
                                <br />

                                <TextField
                                    autoComplete="off"
                                    name="partnerEmail"
                                    variant="outlined"
                                    fullWidth
                                    id="partnerEmail"
                                    value={partnerEmail}
                                    label="Email"
                                    onChange={(e) => setpartnerEmail(e.target.value)}
                                />
                                <br />
                                <br />
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack spacing={3}>
                                        <DesktopDatePicker
                                            label="Signup date"
                                            inputFormat="MM/dd/yyyy"
                                            value={signupDate}
                                            onChange={handleSignupChange}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Stack>
                                </LocalizationProvider>

                                <br />
                                <br />
                                <TextField
                                    autoComplete="off"
                                    name="notes"
                                    variant="outlined"
                                    multiline
                                    rows={8}
                                    fullWidth
                                    value={notes}
                                    id="notes"
                                    label="Notes"
                                    onChange={(e) => setnotes(e.target.value)}
                                />
                                {/* <br />
                                <br />
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Link Distributor to his Signed account</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={distributorUserId}
                                        required
                                        label="Sales User"
                                        onChange={handleDistributorUserChange}
                                    >
                                        {distributoruser.map((distributor) => (
                                            <MenuItem key={distributor._id} value={distributor._id}>
                                                {distributor.firstName} {distributor.lastName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl> */}
                                <br />
                                <br />
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={status}
                                        label="Status"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Active">Active</MenuItem>
                                        <MenuItem value="InActive">InActive</MenuItem>
                                    </Select>
                                </FormControl>
                                <br />
                                <br />
                                <Box display="flex" justifyContent="space-between">
                                    <Button component={Link} to="/distributor" type="submit" variant="contained" color="error">
                                        Reset
                                    </Button>

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        disabled={!partnerName || !partnerContact || !signupDate}
                                    >
                                        {loading ? 'Updating ..' : 'Update'}
                                    </Button>
                                </Box>
                            </form>
                        </MainCard>
                    </Grid>
                </>
            )}
        </>
    );
};
export default EditDistributor;
