/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../../components/PageHeader';
import Box from '@mui/material/Box';
import Controls from '../../../components/controls/Controls';
import { Search } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
// import CheckIcon from '@mui/icons-material/Check';
// import ClearIcon from '@mui/icons-material/Clear';
// import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import { Link, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { Avatar, Typography } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
// import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useTheme } from '@mui/material/styles';
import ViewIcon from '@mui/icons-material/Preview';
// import axios from 'axios';
import { toast } from 'react-toastify';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    color: {
        color: '#FF740F'
    },
    backgroundColor: {
        backgroundColor: '#FF740F'
    }
}));

const FleetVehicleRequest = () => {
    const classes = useStyles();
    const history = useNavigate();
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const [jobcardInfo, setJobCard] = useState([]);
    const [jobcardData, setJobCardData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());

    const handleEditClick = (row) => {
        if (scriptedRef.current) {
            history(`/viewfleetvehiclerequest/${row._id}`);
        }
    };

    const handleShowClick = (row) => {
        if (scriptedRef.current) {
            // console.log(row);
            history(`/showfleetvehiclerequest/${row._id}`);
        }
    };

    const handleVehicleProfileClick = (row) => {
        if (scriptedRef.current) {
            history(`/fleetvehicleprofile/${row._id}`);
        }
    };

    // const handlePdfClick = (row) => {
    //     // console.log('row', row);
    //     const userInfo = localStorage.getItem('userToken');
    //     const config = {
    //         headers: {
    //             'auth-token': userInfo,
    //             'Content-Type': 'application/json'
    //         }
    //     };
    //     // setLoading2(true);
    //     axios.post(ApiUrl.DownloadPdfJobCardsUrl, row, config).then((res) => {
    //         window.open(res.data.data, '_blank');

    //         // setLoading2(false);
    //     });
    // };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 30));
        setPage(0);
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - jobcardInfo.length) : 0;

    const retrieveAllCar = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.FleetVehicleRequestUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setJobCard(data.data);
                        setJobCardData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        // retrieve all customer complaint;
        retrieveAllCar();
    }, []);

    // const handleDeleteClick = (row) => {
    //     swal({
    //         title: 'Are you sure?',
    //         text: `Once deleted, you will not be able to recover this job card!(${row.name})`,
    //         icon: 'warning',
    //         buttons: true,
    //         dangerMode: true
    //     }).then((willDelete) => {
    //         if (willDelete) {
    //             swal('Poof! The Job Card has been deleted!', {
    //                 icon: 'success'
    //             });

    //             const data = {
    //                 id: row._id
    //             };
    //             const userInfo = localStorage.getItem('userToken');

    //             fetch(ApiUrl.DeleteFleetVehicleRequestUrl, {
    //                 method: 'DELETE',
    //                 headers: {
    //                     'auth-token': userInfo,
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify(data)
    //             })
    //                 .then((res) => res.json())
    //                 .then((result) => {
    //                     if (result.status === true) {
    //                         if (scriptedRef.current) {
    //                             retrieveAllCar();
    //                         }
    //                     } else {
    //                         toast.error(result.data);
    //                     }
    //                 });
    //         } else {
    //             swal('The Job Card is safe!');
    //         }
    //     });
    // };

    const handledateChange = (event, picker) => {
        // console.log('startDate', picker.startDate._d);
        // console.log('endDate', picker.endDate._d);
        setstartDate(picker.startDate._d);
        setendDate(picker.endDate._d);
        // FilterOrdersByDateUrl
        const userInfo = localStorage.getItem('userToken');
        const from = picker.startDate._d;
        const to = picker.endDate._d;
        setLoading(true);
        const data = {
            from,
            to
        };
        fetch(ApiUrl.FilterFleetVehicleRequestUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);

                    setLoading(false);
                    setJobCard(result.data);
                    setJobCardData(result.data);
                    // if (scriptedRef) {
                    //     setTimeout(() => {
                    //         history('/order');
                    //     }, 3000);
                    // }
                } else {
                    toast.error(result.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSearch = (event) => {
        const searchWord = event.target.value;

        const newFilter = jobcardInfo.filter((value) => value.customer.toLowerCase().includes(searchWord.toLowerCase()));

        if (searchWord === '') {
            setJobCard(jobcardData);
        } else {
            setJobCard(newFilter);
        }
    };

    return (
        <>
            <PageHeader title="Home | Fleet Management" subTitle="Home | Fleet Management | Vehicle Request" />

            <MainCard title="Vehicle Request List">
                <Box display="flex" justifyContent="flex-start">
                    <Controls.Input
                        label="Search Customer."
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearch}
                    />
                    <Box width={20} />
                    <DateRangePicker initialSettings={{ startDate, endDate }} onApply={handledateChange}>
                        <Button variant="contained" size="sm" className={classes.item}>
                            filter by Date
                        </Button>
                    </DateRangePicker>
                    <Box width={20} />
                    <Button component={Link} to="/addfleetvehiclerequest" variant="contained" size="sm" className={classes.backgroundColor}>
                        Add
                    </Button>
                </Box>
                <br />

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Request Date
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Vehicle Make
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Vehicle Model
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Registration No.
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Customer Name
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Status
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 ? jobcardInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : jobcardInfo).map(
                                (row, index) => (
                                    <TableRow key={row._id}>
                                        <TableCell component="th" scope="row">
                                            {jobcardInfo.length - index}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {toTimestamp(row?.requestDate)}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.carName ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.carmodelName ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.carRegistrationNo ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.customer ?? ''}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row.status === 'Approved' ? (
                                                <Avatar
                                                    variant="rounded"
                                                    sx={{
                                                        ...theme.typography.commonAvatar,
                                                        ...theme.typography.largeAvatar,
                                                        backgroundColor: theme.palette.success.light,
                                                        color: theme.palette.success.dark,
                                                        width: 150,
                                                        height: 20
                                                    }}
                                                >
                                                    <CheckCircleIcon fontSize="small" /> <Box width={10} />
                                                    <Typography variant="h5">{row.status}</Typography>
                                                </Avatar>
                                            ) : (
                                                ''
                                            )}
                                            {row.status === 'Done' ? (
                                                <Avatar
                                                    variant="rounded"
                                                    sx={{
                                                        ...theme.typography.commonAvatar,
                                                        ...theme.typography.largeAvatar,
                                                        backgroundColor: theme.palette.success.light,
                                                        color: theme.palette.success.dark,
                                                        width: 150,
                                                        height: 20
                                                    }}
                                                >
                                                    <CheckCircleIcon fontSize="small" />
                                                    <Box width={10} /> <Typography variant="h5">{row.status}</Typography>
                                                </Avatar>
                                            ) : (
                                                ''
                                            )}
                                            {row.status === 'Denied' ? (
                                                <Avatar
                                                    variant="rounded"
                                                    sx={{
                                                        ...theme.typography.commonAvatar,
                                                        ...theme.typography.largeAvatar,
                                                        backgroundColor: theme.palette.error.light,
                                                        color: theme.palette.error.dark,
                                                        width: 150,
                                                        height: 20
                                                    }}
                                                >
                                                    <DangerousRoundedIcon fontSize="small" /> <Box width={10} />
                                                    <Typography variant="h5">{row.status}</Typography>
                                                </Avatar>
                                            ) : (
                                                ''
                                            )}
                                            {row.status === 'Awaiting Approval' ? (
                                                <Avatar
                                                    variant="rounded"
                                                    sx={{
                                                        ...theme.typography.commonAvatar,
                                                        ...theme.typography.largeAvatar,
                                                        backgroundColor: theme.palette.warning.light,
                                                        color: theme.palette.warning.dark,
                                                        width: 150,
                                                        height: 20
                                                    }}
                                                >
                                                    <RemoveCircleIcon fontSize="small" /> <Box width={10} />{' '}
                                                    <Typography variant="h5">{row.status}</Typography>{' '}
                                                </Avatar>
                                            ) : (
                                                ''
                                            )}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(row)}>
                                                <EditIcon />
                                            </IconButton>

                                            <IconButton aria-label="delete" color="success" onClick={() => handleVehicleProfileClick(row)}>
                                                profile
                                            </IconButton>

                                            <IconButton aria-label="view" color="success" onClick={() => handleShowClick(row)}>
                                                <ViewIcon />
                                            </IconButton>

                                            {/* <IconButton aria-label="delete" color="error" onClick={() => handleDeleteClick(row)}>
                                                <DeleteIcon />
                                            </IconButton> */}
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 70, 150, { label: 'All', value: -1 }]}
                    component="div"
                    count={jobcardInfo.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default FleetVehicleRequest;
