import { LoadingOutlined } from '@ant-design/icons';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, List, ListItem, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { DatePicker, Select, Space, Switch } from 'antd';
import GrowthCircularChart from 'components/GrowthView/GrowthCircularChart';
import GrowthLineChart from 'components/GrowthView/GrowthLineChart';
import ApiUrl from '../../network';
import { useEffect, useState } from 'react';
import { decrypt } from '../../utils/encrypt';
import GrowthTableChart from 'components/GrowthView/GrowthTableChart';
import { center } from '@turf/turf';

const userFirstName = localStorage.getItem('userFirstName');
const userRole = localStorage.getItem('userRole');

const Index = () => {
    const [open, setOpen] = useState(true);
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [insightLoading, setInsightLoading] = useState(false);
    const [metricdatas, setMetricdatas] = useState([]);
    const [poddatas, setPoddatas] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [insight, setInsight] = useState('');
    const [timeFrameDatas, setTimeFrameDatas] = useState([]);
    const [chartTitle, setChartTitle] = useState('Gmv');
    const country = localStorage.getItem('country');

    const [datas, setDatas] = useState([]);
    const [check, setCheck] = useState(true);
    const [custom, setCustom] = useState(false);
    const [showGmv, setShowGmv] = useState(true);
    const [showRevenue, setShowRevenue] = useState(false);
    const [showOrder, setShowOrder] = useState(false);
    const [showCustomer, setShowCustomer] = useState(false);
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [accumulatedFilters, setAccumulatedFilters] = useState({});

    const [lineWidth, setLiineWidth] = useState('50%');
    const [progressWidth, setProgressWidth] = useState('45%');

    useEffect(() => {
        const handleResize = () => {
            // Adjust the column width based on the window width
            const newLineWidth = window.innerWidth > 1200 ? '50%' : '100%';
            const newProgressWidth = window.innerWidth > 1200 ? '45%' : '100%';
            setLiineWidth(newLineWidth);
            setProgressWidth(newProgressWidth);
        };

        // Initial setup
        handleResize();

        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleOpenClose = () => {
        setOpen(!open);
    };

    const { RangePicker } = DatePicker;

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54
            }}
            spin
        />
    );

    // get query data
    const getQueryData = async () => {
        try {
            // setLoading(true);
            const url = `${ApiUrl.BaseUrl}/mainorders/querydata?country=${accumulatedFilters?.country || country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setCountries(answer.country);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // get insight data
    const getInsight = async (id) => {
        try {
            setInsightLoading(true);
            const url = `${ApiUrl.BaseUrl}/growth/growthrate_insight?insight_id=${id}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setInsight(answer?.insight);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setInsightLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // get growth parameters
    const getGrowthParameters = async () => {
        try {
            // setLoading(true);
            const url = `${ApiUrl.BaseUrl}/growth/growthparameters?country=${accumulatedFilters?.country || country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const metricresult = decrypt(data.matricdatas, keyResult, ivResult);
                        const podresult = decrypt(data.poddatas, keyResult, ivResult);
                        const timeresult = decrypt(data.timeframedatas, keyResult, ivResult);
                        const metric = JSON.parse(metricresult);
                        const pod = JSON.parse(podresult);
                        const time = JSON.parse(timeresult);

                        setTimeFrameDatas(time);
                        setMetricdatas(metric);
                        setPoddatas(pod);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // get growth rate
    const getGrowthRateData = async () => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/growth/growthrate`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            // Append accumulated filters to URL
            Object.entries(accumulatedFilters).forEach(([key, value]) => {
                if (value) {
                    urlParams.append(key, value);
                }
            });

            url = `${url}?${urlParams.toString()}`;

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const dataResult = decrypt(data?.data, keyResult, ivResult);
                        const finaldata = dataResult ? JSON.parse(dataResult) : null;
                        // console.error(finaldata, 'data');
                        setDatas(finaldata);
                        getInsight(finaldata?._id);
                    } else {
                        console.error(data, 'data');
                        // const error = decrypt(data.data, keyResult, ivResult);
                        // const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getQueryData();
        getGrowthRateData();
        getGrowthParameters();
    }, [accumulatedFilters]);

    const countryOptions = countries?.map((item) => {
        return { label: item?.name, value: item?.name };
    });

    const metricOptions = metricdatas?.map((item) => {
        return { label: item?.name, value: item?.name };
    });

    const podOptions = poddatas?.map((item) => {
        return { label: item?.name, value: item?._id };
    });

    const timeOptions = timeFrameDatas?.map((item) => {
        return { label: item?.name, value: item?._id };
    });

    // filter country
    const filterCountry = (value) => {
        const data = {
            country: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter By Matric
    const filterMatric = (value) => {
        if (value === 'Gmv') {
            setChartTitle('Gmv');
            setShowGmv(true);
            setShowRevenue(false);
            setShowOrder(false);
            setShowCustomer(false);
        } else if (value === 'Revenue') {
            setChartTitle('Revenue');

            setShowGmv(false);
            setShowRevenue(true);
            setShowOrder(false);
            setShowCustomer(false);
        } else if (value === 'No. of Orders') {
            setChartTitle('No. of Orders');

            setShowGmv(false);
            setShowRevenue(false);
            setShowOrder(true);
            setShowCustomer(false);
        } else if (value === 'No. of Customers') {
            setChartTitle('No. of Customers');

            setShowGmv(false);
            setShowRevenue(false);
            setShowOrder(false);
            setShowCustomer(true);
        } else {
            setChartTitle('Gmv');
            setShowGmv(true);
            setShowRevenue(false);
            setShowOrder(false);
            setShowCustomer(false);
        }
        const data = {
            matric: value
        };
        // setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter By Time Frame
    const filterTime = (value) => {
        const data = {
            timeframe: value
        };
        if (value === 7) {
            setCustom(true);
        } else {
            setCustom(false);
        }
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter date
    const filterDate = (value, dateString) => {
        setSelectedDate(value);
        const data = {
            start_date: dateString[0],
            end_date: dateString[1]
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter pod
    const filterPod = (value) => {
        const data = {
            pod: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    const onCheckChange = (checked) => {
        setCheck(checked);
    };

    return (
        <Box
            sx={{
                margin: '24px 0 0 0'
            }}
        >
            {' '}
            <List
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    padding: '0 0 16px 0',
                    borderBottom: '1px dashed #E7EAEB',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    margin: '0px auto 15px'
                }}
            >
                <ListItem sx={{ fontWeight: 500, fontSize: '1.875rem', color: '#0A2938', padding: 0 }}>Growth View</ListItem>
                <ListItem sx={{ fontSize: '0.875rem', color: '#565C6B', padding: 0, margin: '10px 0 0 0' }}>
                    View growth metrics from any location
                </ListItem>
            </List>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    margin: '32px 0 0 0'
                }}
                onClick={toggleOpenClose}
            >
                <Typography
                    style={{
                        fontSize: '18px',
                        fontWeight: '500',
                        color: '#0A2938'
                    }}
                >
                    Filters
                </Typography>

                {open ? (
                    <KeyboardArrowUpIcon
                        style={{
                            margin: '0 0 0 2px',
                            color: '#0A2938'
                        }}
                    />
                ) : (
                    <ExpandMoreIcon
                        style={{
                            margin: '0 0 0 2px',
                            color: '#0A2938'
                        }}
                    />
                )}
            </Box>
            <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                sx={{
                    margin: '0 0 8px 0'
                }}
            >
                <>
                    <Space
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            width: '100%'
                        }}
                    >
                        {userRole === 'Admin' ? (
                            <Box
                                sx={{
                                    width: ' 320px',
                                    margin: '8px 0 0 0px'
                                }}
                            >
                                <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Location</Typography>

                                <Select
                                    style={{
                                        width: '100%'
                                    }}
                                    size="large"
                                    placeholder="Location"
                                    options={countryOptions}
                                    onChange={(value) => filterCountry(value)}
                                    filterOption={(inputValue, option) => {
                                        if (typeof option.label === 'string') {
                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }

                                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }}
                                    showSearch
                                    allowClear
                                    loading={loading}
                                />
                            </Box>
                        ) : null}
                        <Box
                            sx={{
                                width: ' 320px',
                                margin: '8px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Metric</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Metric"
                                onChange={(value) => filterMatric(value)}
                                options={metricOptions}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 320px',
                                margin: '8px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Pod</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Pod"
                                options={podOptions}
                                onChange={(value) => filterPod(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 320px',
                                margin: '8px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Time frame</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Time frame"
                                options={timeOptions}
                                onChange={(value) => filterTime(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>
                        {custom ? (
                            <Box
                                sx={{
                                    width: ' 320px',
                                    margin: '8px 0 0 0px'
                                }}
                            >
                                <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Date</Typography>
                                <RangePicker
                                    format="YYYY-MM-DD"
                                    style={{
                                        width: '100%',
                                        border: '1px solid #E7EAEB'
                                    }}
                                    size="large"
                                    value={selectedDate}
                                    onChange={filterDate}
                                />
                            </Box>
                        ) : null}
                    </Space>
                </>
            </Collapse>
            {loading ? (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '4000'
                    }}
                >
                    {antIcon}
                </Box>
            ) : null}
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    // alignItems: 'flex-start',
                    // justifyContent: 'space-between',
                    flexWrap: 'wrap'
                }}
            >
                <Box
                    sx={{
                        width: lineWidth,
                        height: '526px',
                        backgroundColor: '#FFFFFF',
                        borderRadius: '12px',
                        margin: '24px 20px 0 0',
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        padding: '20px',
                        flexGrow: '1'
                        // marginRight: '30px'
                        // flexBasis: '200px'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontWeight: '500',
                                textAlign: 'left'
                            }}
                        >
                            {chartTitle}
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    margin: '0 4px 0 0',
                                    color: '#0A2938'
                                }}
                            >
                                View in %
                            </Typography>
                            <Switch
                                defaultChecked
                                // style={{
                                //     backgroundColor: '#FF740F'
                                // }}
                                onChange={onCheckChange}
                            />
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            overflow: 'scroll'
                        }}
                    >
                        <GrowthLineChart
                            datas={datas}
                            check={check}
                            showCustomer={showCustomer}
                            showGmv={showGmv}
                            showOrder={showOrder}
                            showRevenue={showRevenue}
                        />
                    </Box>
                </Box>
                {/* 
                <GrowthCircularChart
                    datas={datas}
                    progressWidth={progressWidth}
                    showCustomer={showCustomer}
                    showGmv={showGmv}
                    showOrder={showOrder}
                    showRevenue={showRevenue}
                /> */}
                <GrowthTableChart
                    datas={datas}
                    progressWidth={progressWidth}
                    showCustomer={showCustomer}
                    showGmv={showGmv}
                    showOrder={showOrder}
                    showRevenue={showRevenue}
                    chartTitle={chartTitle}
                />
            </Box>
            {insightLoading ? (
                <Typography
                    sx={{
                        textAlign: 'center',
                        margin: '20px',
                        fontSize: '20px'
                    }}
                >
                    Loading ...
                </Typography>
            ) : insight.length > 0 ? (
                <Box
                    sx={{
                        backgroundColor: '#FFF',
                        borderRadius: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        // height: '122px',
                        padding: '24px 16px',
                        margin: '24px 5px 0 5px',
                        width: '100%' // Ensure each shop takes up the full width of the container
                    }}
                    // key={index}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            color: '#546974',

                            overflowWrap: 'break-word'
                        }}
                    >
                        {insight}
                    </Typography>
                </Box>
            ) : null}
        </Box>
    );
};

export default Index;
