import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

// material-ui
import { styled } from '@mui/material/styles';
import { Box, List, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import TotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';

// assets
// import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
// import CarFront from '@mui/icons-material/ArrowUpward';
// import ArrowDown from '@mui/icons-material/ArrowDownward';
// import { height } from '@mui/system';

// styles
const CardWrapper = styled(MainCard)(() => ({
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        // background: `linear-gradient(210.04deg, ${theme.palette.warning.dark} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -180
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        // background: `linear-gradient(140.9deg, ${theme.palette.warning.dark} -14.02%, rgba(144, 202, 249, 0) 70.50%)`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
}));

// ==============================|| DASHBOARD - TOTAL ORDERS ||============================== //

const AverageOrderValue = ({ isLoading, totalorders }) => {
    // const theme = useTheme();

    return (
        <>
            {isLoading ? (
                <TotalIncomeCard />
            ) : (
                <CardWrapper border={false} content={false}>
                    <Box sx={{ p: 1 }}>
                        <List sx={{ py: 0 }}>
                            <Typography subtitle2="h4">Average order value</Typography>

                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Typography variant="h2">{Number(totalorders).toFixed(2) ?? 0}</Typography>
                                </Grid>
                                {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <ListItemAvatar>
                                        {ordersdifference > 0 ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.success.light,
                                                    color: theme.palette.success.dark,
                                                    width: 70,
                                                    height: 30
                                                }}
                                            >
                                                <Typography variant="h5">+{Number(ordersdifference).toFixed()}%</Typography>{' '}
                                                <CarFront fontSize="small" />
                                            </Avatar>
                                        ) : (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.error.light,
                                                    color: theme.palette.error.dark,
                                                    width: 70,
                                                    height: 30
                                                }}
                                            >
                                                <Typography variant="h5">{Number(ordersdifference).toFixed()} %</Typography>{' '}
                                                <ArrowDown fontSize="small" />
                                            </Avatar>
                                        )}
                                    </ListItemAvatar>
                                </Grid> */}
                            </Grid>

                            {/* <Typography
                                variant="subtitle2"
                                sx={{
                                    color: theme.palette.grey[500],
                                    mt: 0.5
                                }}
                            >
                                ({title}) : {titleorders}
                            </Typography> */}
                        </List>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

AverageOrderValue.propTypes = {
    isLoading: PropTypes.bool
};

export default AverageOrderValue;
