import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import SyncIcon from '@mui/icons-material/Sync';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import SwicthCountryModal from '../../../../components/SwitchCountryModal/SwitchCountryModal';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    // Card,
    // CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Link,
    // Divider,
    // Grid,
    // InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    // OutlinedInput,
    Paper,
    Popper,
    Stack,
    // Switch,
    Typography
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
// import UpgradePlanCard from './UpgradePlanCard';
import User1 from 'assets/images/users/user-round.svg';

// assets
import { IconLogout, IconSettings, IconChevronRight } from '@tabler/icons';
import { padding } from '@mui/system';
import { RetweetOutlined } from '@ant-design/icons';
import { Button, Modal, Radio } from 'antd';
import { toast } from 'react-toastify';
import ApiUrl from '../../../../network';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const history = useNavigate();

    // const [sdm, setSdm] = useState(true);
    // const [value, setValue] = useState('');
    // const [notification, setNotification] = useState(false);
    // const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    const userRole = localStorage.getItem('userRole');
    const userFirstName = localStorage.getItem('userFirstName');
    const userLastName = localStorage.getItem('userLastName');
    const photo = localStorage.getItem('photo');
    const userRoles = JSON.parse(localStorage.getItem('userRoles'));
    const country = localStorage.getItem('country');
    const countryFilter = localStorage.getItem('countryFilter');
    const [rolePicker, setRolePicker] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);
    const [IsCountryModalOpen, setIsCountryModalOpen] = useState(false);
    const [countryValue, setCountryValue] = useState(country);

    const handleLogout = async () => {
        history('/login');
        localStorage.clear();
    };

    const toSettings = async () => {
        history('/sample-page');
    };

    const toSwitch = async () => {
        setRolePicker(true);
    };

    const setRolehandler = () => {
        if (selectedRole) {
            toast.success('Role selected successfully');
            localStorage.setItem('userRole', selectedRole.roleName);
            setTimeout(() => {
                const userInfo = localStorage.getItem('userToken');
                if (userInfo) {
                    // eslint-disable-next-line no-restricted-globals
                    location.reload();
                }
            }, 1000);
        }
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    // const handleListItemClick = (event, index, route = '') => {
    //     setSelectedIndex(index);
    //     handleClose(event);

    //     if (route && route !== '') {
    //         navigate(route);
    //     }
    // };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    // country switch modal
    const showCountryModal = () => {
        setIsCountryModalOpen(true);
    };

    const handleCountryOk = () => {
        setIsCountryModalOpen(false);
    };

    const handleCountryCancel = () => {
        setIsCountryModalOpen(false);
    };

    const retrieveProfile = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            // setLoading(true);
            await fetch(ApiUrl.UserProfile, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        // console.log(data.data);
                        // if (!localStorage.getItem('userRole')) {
                        //     localStorage.setItem('userRole', data.data.roleId?.name ?? '');
                        // }
                        // localStorage.setItem('userFirstName', data.data.firstName);
                        // localStorage.setItem('userLastName', data.data.lastName);
                        // localStorage.setItem('userEmail', data.data.email);
                        // localStorage.setItem('channelpartnerId', data?.data?.channelPartnerId ?? '');
                        // localStorage.setItem('userId', data?.data?._id ?? '');
                        // localStorage.setItem('photo', data?.data?.photo ?? '');
                        localStorage.setItem('userCurrency', data?.data?.currency ?? '');
                        // localStorage.setItem('company', data?.data?.company ?? '');
                        localStorage.setItem('country', data?.data?.country);
                        // localStorage.setItem('key', data?.data?.key ?? '');
                        // localStorage.setItem('iv', data?.data?.iv ?? '');
                        // localStorage.setItem('currentOrderType', 'Regular');
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // Update Country
    const updateCountry = async () => {
        const userInfo = localStorage.getItem('userToken');
        const userId = localStorage.getItem('userId');

        const apiUrl = `${ApiUrl.BaseUrl}/user/update`;

        const payload = {
            id: userId,
            country: countryValue,
            currency: countryValue === 'Ghana' ? 'GHS' : 'NGN'
        };

        await fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
                // Add any other headers as needed
            },
            body: JSON.stringify(payload)
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response}`);
                }
                return response.json();
            })
            .then((data) => {
                // toast.success(data.message);
                retrieveProfile();
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    // Function to update country
    const switchCountry = async () => {
        await updateCountry();

        if (country !== countryValue) {
            toast.success(`Success`);
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } else {
            toast.error(`Failed to switch country`);
        }
    };

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.primary.light,
                    backgroundColor: '#E7EAEB',
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: '#ededed',
                        background: `#fffefe !important`,
                        color: '#233E4C'
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        src={photo != null ? photo : User1}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
                label={
                    <>
                        {' '}
                        <Typography component="span" variant="h4" sx={{ fontWeight: 400, fontSize: '1rem' }}>
                            {userFirstName}
                        </Typography>{' '}
                        <KeyboardArrowDownIcon
                            sx={{
                                color: '#233E4C',
                                fontSize: '1rem'
                            }}
                        />
                    </>
                }
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />

            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
                sx={{ width: '18.75rem' }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} content={false} sx={{ boxShadow: '5px 10px 25px 0px rgba(175, 175, 175, 0.25)' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                        <Avatar
                                            src={photo != null ? photo : User1}
                                            sx={{ width: '56px', height: '56px', margin: '20px 0 10px 0' }}
                                        />
                                        <Stack sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Typography variant="h4" sx={{ fontSize: '1.125rem', fontWeight: 400 }}>
                                                    Hi,
                                                </Typography>
                                                <Typography component="span" variant="h4" sx={{ fontWeight: 400, fontSize: '1.125rem' }}>
                                                    {userFirstName} {userLastName}
                                                </Typography>
                                            </Stack>
                                            <Typography variant="subtitle2">{userRole}</Typography>
                                        </Stack>
                                        {/* <OutlinedInput
                                            sx={{ width: '100%', pr: 1, pl: 2, my: 2 }}
                                            id="input-search-profile"
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}
                                            placeholder="Search profile options"
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                                                </InputAdornment>
                                            }
                                            aria-describedby="search-helper-text"
                                            inputProps={{
                                                'aria-label': 'weight'
                                            }}
                                        /> */}
                                        {/* <Divider /> */}
                                    </Box>
                                    {/* <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}> */}
                                    <Box>
                                        {/* <UpgradePlanCard /> */}
                                        {/* <Divider /> */}
                                        {/* <Card
                                                sx={{
                                                    bgcolor: theme.palette.primary.light,
                                                    my: 2
                                                }}
                                            >
                                                <CardContent>
                                                    <Grid container spacing={3} direction="column">
                                                        <Grid item>
                                                            <Grid item container alignItems="center" justifyContent="space-between">
                                                                <Grid item>
                                                                    <Typography variant="subtitle1">Start DND Mode</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Switch
                                                                        color="primary"
                                                                        checked={sdm}
                                                                        onChange={(e) => setSdm(e.target.checked)}
                                                                        name="sdm"
                                                                        size="small"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid item container alignItems="center" justifyContent="space-between">
                                                                <Grid item>
                                                                    <Typography variant="subtitle1">Allow Notifications</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Switch
                                                                        checked={notification}
                                                                        onChange={(e) => setNotification(e.target.checked)}
                                                                        name="sdm"
                                                                        size="small"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                            <Divider /> */}
                                        <List
                                            component="nav"
                                            sx={{
                                                width: '16.75rem',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                flexDirection: 'column',
                                                margin: '0px auto 15px',
                                                // maxWidth: 350,
                                                // minWidth: 300,
                                                backgroundColor: '#ECEDEF',
                                                borderRadius: '8px',
                                                padding: 0,
                                                '&:hover': {
                                                    borderRadius: '8px'
                                                },

                                                [theme.breakpoints.down('md')]: {
                                                    minWidth: '100%'
                                                },
                                                '& .MuiListItemButton-root': {}
                                            }}
                                        >
                                            {/* <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 0}
                                                    onClick={(event) => handleListItemClick(event, 0, '/user/account-profile/profile1')}
                                                >
                                                    <ListItemIcon>
                                                        <IconSettings stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Account Settings</Typography>} />
                                                </ListItemButton> */}
                                            {/* <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 1}
                                                    onClick={(event) => handleListItemClick(event, 1, '/user/social-profile/posts')}
                                                >
                                                    <ListItemIcon>
                                                        <IconUser stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Grid container spacing={1} justifyContent="space-between">
                                                                <Grid item>
                                                                    <Typography variant="body2">Social Profile</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Chip
                                                                        label="02"
                                                                        size="small"
                                                                        sx={{
                                                                            bgcolor: theme.palette.warning.dark,
                                                                            color: theme.palette.background.default
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    />
                                                </ListItemButton> */}
                                            {(userRoles?.length > 1 ||
                                                (userRoles?.length === 1 && userRole !== userRoles[0]?.roleName)) && (
                                                <ListItemButton
                                                    sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between'
                                                    }}
                                                    onClick={toSwitch}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between'
                                                        }}
                                                    >
                                                        <ListItemIcon>
                                                            <SyncIcon
                                                                sx={{
                                                                    fontSize: '1rem',
                                                                    '&[aria-controls="menu-list-grow"], &:hover': {
                                                                        color: '#ffff'
                                                                    }
                                                                }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Typography
                                                                    variant="body2"
                                                                    sx={{
                                                                        color: '#233E4C',
                                                                        fontWeight: 400,
                                                                        '&[aria-controls="menu-list-grow"], &:hover': {
                                                                            color: '#ffff'
                                                                        }
                                                                    }}
                                                                >
                                                                    Switch Role
                                                                </Typography>
                                                            }
                                                        />
                                                    </Box>
                                                </ListItemButton>
                                            )}
                                            <ListItemButton
                                                sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between'
                                                }}
                                                onClick={toSettings}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between'
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <SettingsIcon
                                                            sx={{
                                                                fontSize: '1rem',
                                                                '&[aria-controls="menu-list-grow"], &:hover': {
                                                                    color: '#ffff'
                                                                }
                                                            }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    color: '#233E4C',
                                                                    fontWeight: 400,
                                                                    '&[aria-controls="menu-list-grow"], &:hover': {
                                                                        color: '#ffff'
                                                                    }
                                                                }}
                                                            >
                                                                Settings
                                                            </Typography>
                                                        }
                                                    />
                                                </Box>
                                                <ListItemIcon>
                                                    <IconChevronRight stroke={3} size="12px" />
                                                </ListItemIcon>
                                            </ListItemButton>
                                            <hr style={{ border: '0.1px dashed #CED4D7', width: '90%', margin: '0 auto' }} />
                                            {userRole === 'Admin' ? (
                                                <>
                                                    <ListItemButton
                                                        sx={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between'
                                                        }}
                                                        onClick={showCountryModal}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between'
                                                            }}
                                                        >
                                                            <ListItemIcon>
                                                                <SwitchAccountIcon
                                                                    sx={{
                                                                        fontSize: '1rem',
                                                                        '&[aria-controls="menu-list-grow"], &:hover': {
                                                                            color: '#ffff'
                                                                        }
                                                                    }}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        sx={{
                                                                            color: '#233E4C',
                                                                            fontWeight: 400,
                                                                            '&[aria-controls="menu-list-grow"], &:hover': {
                                                                                color: '#ffff'
                                                                            }
                                                                        }}
                                                                    >
                                                                        Switch country
                                                                    </Typography>
                                                                }
                                                            />
                                                        </Box>
                                                        <ListItemIcon>
                                                            <IconChevronRight stroke={3} size="12px" />
                                                        </ListItemIcon>
                                                    </ListItemButton>
                                                    <hr style={{ border: '0.1px dashed #CED4D7', width: '90%', margin: '0 auto' }} />
                                                </>
                                            ) : null}

                                            <ListItemButton
                                                // sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                // selected={selectedIndex === 4}
                                                onClick={handleLogout}
                                                sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <ListItemIcon>
                                                        <LogoutIcon
                                                            sx={{
                                                                fontSize: '1rem',
                                                                '&[aria-controls="menu-list-grow"], &:hover': {
                                                                    color: '#ffff'
                                                                }
                                                            }}
                                                        />
                                                    </ListItemIcon>

                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    color: '#233E4C',
                                                                    fontWeight: 400,
                                                                    '&[aria-controls="menu-list-grow"], &:hover': {
                                                                        color: '#ffff'
                                                                    }
                                                                }}
                                                            >
                                                                Logout
                                                            </Typography>
                                                        }
                                                    />
                                                </Box>
                                                <ListItemIcon>
                                                    <IconChevronRight stroke={3} size="12px" />
                                                </ListItemIcon>
                                            </ListItemButton>
                                        </List>
                                    </Box>
                                    {/* </PerfectScrollbar> */}
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
            <Modal
                open={rolePicker}
                onCancel={() => {
                    setRolePicker(false);
                }}
                footer={null}
                width={500}
            >
                <div>
                    <p style={{ textAlign: 'center', fontSize: '18px', marginTop: '20px', color: '#434A5B', fontWeight: 'bold' }}>
                        You have more than one role. <br /> Select a role to continue
                    </p>
                    <hr style={{ border: '0.5px dotted #E7EAEB', marginBottom: '10px', marginTop: '10px' }} />

                    <div style={{ marginTop: '20px', borderRadius: '10px', border: '1px solid #E7EAEB' }}>
                        <Radio.Group
                            onChange={(e) => setSelectedRole(userRoles.find((role) => role.roleName === e.target.value))}
                            value={selectedRole ? selectedRole.roleName : ''}
                            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                        >
                            {userRoles?.map(
                                (role, index) =>
                                    role.roleName !== userRole && (
                                        <div key={role.roleName}>
                                            <Radio key={role.roleName} value={role.roleName} style={{ padding: '20px', width: '100%' }}>
                                                {role.roleName}
                                            </Radio>
                                            {index !== userRoles.length - 1 && (
                                                <hr style={{ border: 'none', backgroundColor: '#E7EAEB', padding: '0.5px' }} />
                                            )}
                                        </div>
                                    )
                            )}
                        </Radio.Group>
                    </div>

                    {selectedRole && (
                        <Button
                            onClick={setRolehandler}
                            style={{
                                backgroundColor: '#FF740F',
                                color: '#fff',
                                borderRadius: '5px',
                                marginRight: '10px',
                                marginTop: '20px',
                                width: '100%',
                                height: '40px'
                            }}
                        >
                            {' '}
                            Continue
                        </Button>
                    )}
                </div>
            </Modal>
            <SwicthCountryModal
                IsCountryModalOpen={IsCountryModalOpen}
                handleCountryOk={handleCountryOk}
                handleCountryCancel={handleCountryCancel}
                setCountryValue={setCountryValue}
                countryValue={countryValue}
                switchCountry={switchCountry}
            />
        </>
    );
};

export default ProfileSection;
