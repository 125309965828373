/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import useScriptRef from 'hooks/useScriptRef';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import CircularProgress from '@mui/material/CircularProgress';

const EditCustomer = () => {
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [partnerName, setpartnerName] = useState('');
    const [partnerContact, setpartnerContact] = useState('');
    const [location, setlocation] = useState('');
    const [signupDate, setsignupDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const { id } = useParams();

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.ViewAllChannelPartnerURL + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setpartnerName(result?.data?.partnerName ?? '');
                    setpartnerContact(result?.data?.partnerContact ?? '');

                    setsignupDate(result?.data?.signupDate ?? '');

                    setlocation(result?.data?.location ?? '');

                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                    // setLoading(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading2(false);
            });
    }, [id]);

    const handleSignupChange = (newValue) => {
        setsignupDate(newValue);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        const fleetType = 'Fleet Individual Partner';
        const data = {
            id,
            partnerName,
            partnerContact,
            location,
            signupDate,
            fleetType
        };

        fetch(ApiUrl.UpdateChannelPartnerURL, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/customer');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid item xs={12} sm={6}>
                        <PageHeader title="Customer" subTitle="Home | Customers" />
                        <MainCard title="Add Customer">
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    autoComplete="off"
                                    name="partnerName"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    id="partnerName"
                                    label="Customer/Company Name"
                                    value={partnerName}
                                    onChange={(e) => setpartnerName(e.target.value)}
                                    autoFocus
                                />

                                <br />
                                <br />
                                <TextField
                                    autoComplete="off"
                                    name="partnerContact"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    id="partnerContact"
                                    label="Telephone Number"
                                    value={partnerContact}
                                    onChange={(e) => setpartnerContact(e.target.value)}
                                />

                                <br />
                                <br />
                                <TextField
                                    autoComplete="off"
                                    name="location"
                                    variant="outlined"
                                    fullWidth
                                    id="location"
                                    label="Location"
                                    value={location}
                                    onChange={(e) => setlocation(e.target.value)}
                                />
                                <br />
                                <br />

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack spacing={3}>
                                        <DesktopDatePicker
                                            label="Date Contacted"
                                            inputFormat="MM/dd/yyyy"
                                            value={signupDate}
                                            onChange={handleSignupChange}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Stack>
                                </LocalizationProvider>

                                <br />
                                <br />
                                <br />
                                <Box display="flex" justifyContent="space-between">
                                    <Button component={Link} to="/customer" type="submit" variant="contained" color="error">
                                        Reset
                                    </Button>

                                    <Button type="submit" variant="contained" color="secondary">
                                        {loading ? 'Updating ..' : 'Update'}
                                    </Button>
                                </Box>
                            </form>
                        </MainCard>
                    </Grid>
                </>
            )}
        </>
    );
};

export default EditCustomer;
