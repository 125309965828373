import { Paper } from '@mui/material';
import styled from 'styled-components';

const HeaderWrapper = styled.div`
    .custom-paper {
        font-family: 'Roboto', 'sans-serif';
        border-radius: 0;
        padding: 0.625rem 0.625rem;
        color: #444;
        margin: 20px 0;

        h4,
        h5 {
            font-size: 0.875rem;
            font-weight: 400;
        }

        h4 {
            font-weight: 800;
        }

        h5 {
            color: #8b8b8b;
        }
    }
`;

const Header = () => {
    return (
        <HeaderWrapper>
            <Paper elevation={0} className="custom-paper">
                <header>
                    <h4>Fulfillment | Order Priotization</h4>
                    <h5>Home | Fulfillment | Order Prioritization | Dashboard</h5>
                </header>
            </Paper>
        </HeaderWrapper>
    );
};
export default Header;
