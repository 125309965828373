/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../../components/PageHeader';
import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Link, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import useScriptRef from 'hooks/useScriptRef';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
// import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Form from 'react-bootstrap/Form';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const AddFleetRequestService = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    // const [fleetserviceId, setfleetserviceId] = useState('');
    const [customer, setCustomer] = useState('');
    const [customerPhone, setcustomerPhone] = useState('');
    const [carMake, setcarMake] = useState('');
    const [model, setmodel] = useState('');
    const [year, setyear] = useState('');
    const [licensesplate, setlicensesplate] = useState('');
    const [engineType, setengineType] = useState('');
    const [vins, setvins] = useState('');
    const [car, setCar] = useState([]);
    const [cars, setCars] = useState([]);
    const [carModel, setcarModel] = useState([]);
    const [services, setServices] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [serviceid, setserviceid] = useState('');
    const [serviceName, setserviceName] = useState('');
    const [partCost, setpartCost] = useState(0);
    const [labourEstimate, setlabourEstimate] = useState(0);
    const [totalEstimate, settotalEstimate] = useState(0);
    const [totalCostEstimate, settotalCostEstimate] = useState(0);
    const [carId, setcarId] = useState('');
    // const [fleetserviceids, setFleetServiceids] = useState([]);
    const [fuelType, setFuel] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [opens, setOpens] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openServiceEdit, setOpenServiceEdit] = useState(false);
    const [indexValue, setIndexValue] = useState('');
    const [indexServiceValue, setIndexServiceValue] = useState('');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const userCurrency = localStorage.getItem('userCurrency');

    // const retrieveAllFleetServiceId = async () => {
    //     try {
    //         const userInfo = localStorage.getItem('userToken');
    //         const config = {
    //             headers: {
    //                 'auth-token': userInfo,
    //                 'Content-Type': 'application/json'
    //             }
    //         };
    //         setLoading(true);
    //         fetch(ApiUrl.FleetActiveServiceIdUrl, config)
    //             .then((data) => data.json())
    //             .then((data) => {
    //                 if (data.status === true) {
    //                     setFleetServiceids(data.data);
    //                 } else {
    //                     toast.error(data.data);
    //                 }
    //             })
    //             .finally(() => {
    //                 setLoading(false);
    //             });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    const retrieveAllCar = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllActiveCar, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCar(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllCarModel = async (id) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllCarModelsDetailsUrl + id, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setcarModel(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllService = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.ActiveFleetServiceUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setServiceData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handleFuelType = (event) => {
        setFuel(event.target.value);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenitems = () => {
        setOpens(true);
    };

    const handleCloseitems = () => {
        setOpens(false);
    };
    const handleClickOpenEdit = () => {
        setOpenEdit(true);
    };
    const handleCloseEdit = () => {
        setOpenEdit(false);
    };
    const handleClickServiceOpenEdit = () => {
        setOpenServiceEdit(true);
    };

    const handleCloseServiceEdit = () => {
        setOpenServiceEdit(false);
    };

    useEffect(() => {
        // retrieveAllFleetServiceId();
        retrieveAllCar();
        retrieveAllService();
    }, []);

    const onSubmitCarEdit = (event) => {
        event.preventDefault();
        const newList = Object.assign([], cars);
        const data = { ...newList[indexValue] };
        data.carId = carId;
        data.carMake = carMake;
        data.model = model;
        data.year = year;
        data.licensesplate = licensesplate;
        data.fuelType = fuelType;
        data.engineType = engineType;
        data.vins = vins;
        newList[indexValue] = data;
        setCars(newList);
        handleCloseEdit();
    };

    const onSubmitCar = (event) => {
        event.preventDefault();
        // cars, setCars
        setCars([
            ...cars,
            {
                carId,
                carMake,
                model,
                year,
                licensesplate,
                fuelType,
                engineType,
                vins
            }
        ]);

        handleClose();
    };

    const filterCostEstimate = (event) => {
        const totalsum = Number(partCost) + Number(event);
        settotalEstimate(totalsum);
    };

    const onSubmitItems = (event) => {
        event.preventDefault();
        // items, setItems

        setServices([
            ...services,
            {
                serviceid,
                serviceName,
                partCost,
                labourEstimate,
                totalEstimate
            }
        ]);
        let sum = 0;
        settotalCostEstimate(sum);
        const newList = Object.assign([], services);
        newList.forEach((row) => {
            sum += Number(row.partCost) + Number(row.labourEstimate);
            // console.log('row data', row);
        });
        sum += Number(partCost) + Number(labourEstimate);
        settotalCostEstimate(sum);

        handleCloseitems();
    };

    const onSubmitEditServices = (event) => {
        event.preventDefault();
        const newList = Object.assign([], services);
        const data = { ...newList[indexServiceValue] };
        data.serviceid = serviceid;
        data.serviceName = serviceName;
        data.partCost = partCost;
        data.labourEstimate = labourEstimate;
        data.totalEstimate = totalEstimate;
        newList[indexServiceValue] = data;
        setServices(newList);
        handleCloseServiceEdit();
        let sum = 0;
        settotalCostEstimate(sum);
        newList.forEach((row) => {
            sum += Number(row.partCost) + Number(row.labourEstimate);
        });
        settotalCostEstimate(sum);
    };

    // Submit Order
    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        const channelPartnerId = localStorage.getItem('channelpartnerId');
        setLoading(true);
        const data = {
            channelPartnerId,
            // fleetserviceId,
            customer,
            customerPhone,
            cars,
            services,
            totalCostEstimate
        };

        fetch(ApiUrl.AddFleetRequestServiceUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/fleetrequestservice');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handleEditClick = (row, index) => {
        setcarId(row?.carId ?? '');
        setcarMake(row?.carMake ?? '');
        setengineType(row?.engineType ?? '');
        setFuel(row?.fuelType ?? '');
        setlicensesplate(row?.licensesplate ?? '');
        setmodel(row?.model ?? '');
        setvins(row?.vins ?? '');
        setyear(row?.year ?? '');
        handleClickOpenEdit();

        setIndexValue(index);
    };

    const handleDeleteClick = (row, index) => {
        // const newList = cars.filter((item) => item.carId !== row.carId);
        // setCars(newList);

        const newList = Object.assign([], cars);
        newList.splice(index, 1);
        setCars(newList);
    };
    const handleDeleteClickitems = (row, index) => {
        const newList = Object.assign([], services);
        newList.splice(index, 1);
        setServices(newList);
        let sum = 0;
        settotalCostEstimate(sum);
        newList.forEach((row) => {
            sum += Number(row.partCost) + Number(row.labourEstimate);
        });
        settotalCostEstimate(sum);
    };

    const handleEditServiceClick = (row, index) => {
        setserviceid(row?.serviceid ?? '');
        setserviceName(row?.serviceName ?? '');
        setpartCost(row?.partCost ?? '');
        setlabourEstimate(row?.labourEstimate ?? '');
        settotalEstimate(row?.totalEstimate ?? '');
        handleClickServiceOpenEdit();
        setIndexServiceValue(index);
    };

    // const filterfleetserviceId = (event) => {
    //     const newFilter = fleetserviceids.filter((value) => value.name.toLowerCase().includes(event.toLowerCase()));
    //     newFilter.forEach((option) => {
    //         setfleetserviceId(option._id);
    //     });
    // };

    const filterCar = (event) => {
        const newFilter = car.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        newFilter.forEach((option) => {
            setcarId(option._id);
            retrieveAllCarModel(option._id);
        });
    };

    const filterService = (event) => {
        const newFilter = serviceData.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));

        newFilter.forEach((option) => {
            // console.log('option', option);
            setserviceid(option._id);
            option.cars.forEach((option) => {
                // console.log('id', option);
                if (option.carId === carId) {
                    setpartCost(option.highcost);
                } else {
                    setpartCost(0);
                }
            });
        });
    };

    return (
        <>
            <PageHeader title="Request Servicing" subTitle="Home | Fleet | Request Servicing" />
            <MainCard title="">
                <Typography variant="subtitle2" component="div">
                    Customer Information
                </Typography>
                <br />
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} justify="flex-start">
                        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    value={fleetserviceId}
                                    onChange={(event, newValue) => {
                                        setfleetserviceId(newValue);
                                        filterfleetserviceId(newValue);
                                    }}
                                    options={fleetserviceids.map((option) => option.name)}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <TextField {...params} label="Fleet Service Id" />}
                                />
                            </Box>
                        </Grid> */}
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={200}>
                                <TextField
                                    autoComplete="off"
                                    name="customer"
                                    variant="outlined"
                                    required
                                    id="customer"
                                    label="Customer"
                                    value={customer}
                                    onChange={(e) => setCustomer(e.target.value)}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={200}>
                                <TextField
                                    autoComplete="off"
                                    name="customerPhone"
                                    variant="outlined"
                                    required
                                    id="customerPhone"
                                    label="Customer Phone No."
                                    value={customerPhone}
                                    onChange={(e) => setcustomerPhone(e.target.value)}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <br />
                    <Typography variant="subtitle2" component="div">
                        Vehicle Information
                    </Typography>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={10}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Car Make
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Car Model
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Car Year
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                License Plate / Tags
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Fuel Type
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Engine Type
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                VIN
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Actions
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {cars.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {row.carMake}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.model}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.year}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.licensesplate}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.fuelType}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.engineType}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    {row.vins}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <IconButton
                                                        aria-label="edit"
                                                        color="primary"
                                                        onClick={() => handleEditClick(row, index)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label="delete"
                                                        color="error"
                                                        onClick={() => handleDeleteClick(row, index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <Button variant="contained" onClick={handleClickOpen} size="sm" className={classes.item}>
                                Add vehicle
                            </Button>
                        </Grid>
                    </Grid>

                    <br />
                    <Typography variant="subtitle2" component="div">
                        Service Information
                    </Typography>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={10}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Service
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Parts Cost
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Labour Estimate
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Total Estimate
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Actions
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {services.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {row.serviceName}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.partCost}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.labourEstimate}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    {row.totalEstimate}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    <IconButton
                                                        aria-label="edit"
                                                        color="primary"
                                                        onClick={() => handleEditServiceClick(row, index)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label="delete"
                                                        color="error"
                                                        onClick={() => handleDeleteClickitems(row, index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <Button variant="contained" onClick={handleClickOpenitems} size="sm" className={classes.item}>
                                Add Service
                            </Button>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography variant="h4" component="div">
                                Total Estimate
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography variant="h4" component="div">
                                {userCurrency} {totalCostEstimate}
                            </Typography>
                        </Grid>
                    </Grid>

                    <br />
                    <Box display="flex" justifyContent="space-between">
                        <Button component={Link} to="/fleetrequestservice" type="submit" variant="contained" color="error">
                            Reset
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            disabled={!customer || !customerPhone || !cars || !services || !totalCostEstimate}
                        >
                            {loading ? 'Submitting ..' : 'Submit Request Service'}
                        </Button>
                    </Box>
                </form>

                <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">Add Vehicle</DialogTitle>
                    <Form onSubmit={onSubmitCar}>
                        <DialogContent>
                            {/* <DialogContentText> */}

                            <Form.Group controlId="formFile" className="mb-3">
                                <Box width={200}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        value={carMake}
                                        onChange={(event, newValue) => {
                                            setcarMake(newValue);
                                            filterCar(newValue);
                                        }}
                                        options={car.map((option) => option.name)}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => <TextField {...params} label="Car Make" />}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        value={model}
                                        onChange={(event, newValue) => {
                                            setmodel(newValue);
                                        }}
                                        options={carModel.map((option) => option.name)}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => <TextField {...params} label="Car Model" />}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        name="year"
                                        variant="outlined"
                                        required
                                        id="year"
                                        label="Car Year"
                                        value={year}
                                        onChange={(e) => setyear(e.target.value)}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        name="licensesplate"
                                        variant="outlined"
                                        required
                                        id="licensesplate"
                                        label="License Plate / Tags"
                                        value={licensesplate}
                                        onChange={(e) => setlicensesplate(e.target.value)}
                                    />
                                </Box>
                                <br />
                                <br />
                                <Box width={200}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Fuel Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={fuelType}
                                            label="Status"
                                            onChange={handleFuelType}
                                        >
                                            <MenuItem value="Body">Select an option</MenuItem>
                                            <MenuItem value="Petrol">Petrol</MenuItem>
                                            <MenuItem value="Diesel">Diesel</MenuItem>
                                            <MenuItem value="Gas">Gas</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        name="engineType"
                                        variant="outlined"
                                        required
                                        id="engineType"
                                        label="Engine Type"
                                        value={engineType}
                                        onChange={(e) => setengineType(e.target.value)}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        name="vins"
                                        variant="outlined"
                                        required
                                        id="vins"
                                        label="VIN"
                                        value={vins}
                                        onChange={(e) => setvins(e.target.value)}
                                    />
                                </Box>
                            </Form.Group>

                            {/* </DialogContentText> */}
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" size="sm" className={classes.item} autoFocus onClick={handleClose} color="error">
                                Cancel
                            </Button>
                            <Button
                                autoFocus
                                variant="contained"
                                type="submit"
                                size="sm"
                                className={classes.item}
                                disabled={!carMake || !model || !year || !licensesplate || !fuelType || !engineType || !vins}
                            >
                                Add
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>

                {/* Edit cars */}

                <Dialog fullScreen={fullScreen} open={openEdit} onClose={handleCloseEdit} aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">Edit Vehicle</DialogTitle>
                    <Form onSubmit={onSubmitCarEdit}>
                        <DialogContent>
                            {/* <DialogContentText> */}

                            <Form.Group controlId="formFile" className="mb-3">
                                <Box width={200}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        value={carMake}
                                        onChange={(event, newValue) => {
                                            setcarMake(newValue);
                                            filterCar(newValue);
                                        }}
                                        options={car.map((option) => option.name)}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => <TextField {...params} label="Car Make" />}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        value={model}
                                        onChange={(event, newValue) => {
                                            setmodel(newValue);
                                        }}
                                        options={carModel.map((option) => option.name)}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => <TextField {...params} label="Car Model" />}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        name="year"
                                        variant="outlined"
                                        required
                                        id="year"
                                        label="Car Year"
                                        value={year}
                                        onChange={(e) => setyear(e.target.value)}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        name="licensesplate"
                                        variant="outlined"
                                        required
                                        id="licensesplate"
                                        label="License Plate / Tags"
                                        value={licensesplate}
                                        onChange={(e) => setlicensesplate(e.target.value)}
                                    />
                                </Box>
                                <br />
                                <br />
                                <Box width={200}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Fuel Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={fuelType}
                                            label="Status"
                                            onChange={handleFuelType}
                                        >
                                            <MenuItem value="Body">Select an option</MenuItem>
                                            <MenuItem value="Petrol">Petrol</MenuItem>
                                            <MenuItem value="Diesel">Diesel</MenuItem>
                                            <MenuItem value="Gas">Gas</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        name="engineType"
                                        variant="outlined"
                                        required
                                        id="engineType"
                                        label="Engine Type"
                                        value={engineType}
                                        onChange={(e) => setengineType(e.target.value)}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        name="vins"
                                        variant="outlined"
                                        required
                                        id="vins"
                                        label="VIN"
                                        value={vins}
                                        onChange={(e) => setvins(e.target.value)}
                                    />
                                </Box>
                            </Form.Group>

                            {/* </DialogContentText> */}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                size="sm"
                                className={classes.item}
                                autoFocus
                                onClick={handleCloseEdit}
                                color="error"
                            >
                                Cancel
                            </Button>
                            <Button
                                autoFocus
                                variant="contained"
                                type="submit"
                                size="sm"
                                className={classes.item}
                                disabled={!carMake || !model || !year || !licensesplate || !fuelType || !engineType || !vins}
                            >
                                Update
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>

                <Dialog fullScreen={fullScreen} open={opens} onClose={handleCloseitems} aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">Add Service</DialogTitle>
                    <Form onSubmit={onSubmitItems}>
                        <DialogContent>
                            {/* <DialogContentText> */}

                            <Form.Group controlId="formFile" className="mb-3">
                                <Box width={200}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        value={serviceName}
                                        onChange={(event, newValue) => {
                                            setserviceName(newValue);
                                            filterService(newValue);
                                        }}
                                        options={serviceData.map((option) => option.name)}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => <TextField {...params} label="Service" />}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        name="partCost"
                                        variant="outlined"
                                        required
                                        id="partCost"
                                        label="Part Cost"
                                        value={partCost}
                                        onChange={(e) => {
                                            setpartCost(e.target.value);
                                        }}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        name="labourEstimate"
                                        variant="outlined"
                                        required
                                        id="labourEstimate"
                                        label="Labour Estimate"
                                        value={labourEstimate}
                                        onChange={(e) => {
                                            setlabourEstimate(e.target.value);
                                            filterCostEstimate(e.target.value);
                                        }}
                                    />
                                </Box>
                            </Form.Group>

                            {/* </DialogContentText> */}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                size="sm"
                                className={classes.item}
                                autoFocus
                                onClick={handleCloseitems}
                                color="error"
                            >
                                Cancel
                            </Button>
                            <Button
                                autoFocus
                                variant="contained"
                                type="submit"
                                size="sm"
                                className={classes.item}
                                disabled={!serviceName || !labourEstimate}
                            >
                                Add
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>

                {/* Edit Service */}
                <Dialog
                    fullScreen={fullScreen}
                    open={openServiceEdit}
                    onClose={handleClickServiceOpenEdit}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">Edit Service</DialogTitle>
                    <Form onSubmit={onSubmitEditServices}>
                        <DialogContent>
                            {/* <DialogContentText> */}

                            <Form.Group controlId="formFile" className="mb-3">
                                <Box width={200}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        value={serviceName}
                                        onChange={(event, newValue) => {
                                            setserviceName(newValue);
                                            filterService(newValue);
                                        }}
                                        options={serviceData.map((option) => option.name)}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => <TextField {...params} label="Service" />}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        name="partCost"
                                        variant="outlined"
                                        required
                                        id="partCost"
                                        label="Part Cost"
                                        value={partCost}
                                        onChange={(e) => {
                                            setpartCost(e.target.value);
                                        }}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        name="labourEstimate"
                                        variant="outlined"
                                        required
                                        id="labourEstimate"
                                        label="Labour Estimate"
                                        value={labourEstimate}
                                        onChange={(e) => {
                                            setlabourEstimate(e.target.value);
                                            filterCostEstimate(e.target.value);
                                        }}
                                    />
                                </Box>
                            </Form.Group>

                            {/* </DialogContentText> */}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                size="sm"
                                className={classes.item}
                                autoFocus
                                onClick={handleCloseitems}
                                color="error"
                            >
                                Cancel
                            </Button>
                            <Button
                                autoFocus
                                variant="contained"
                                type="submit"
                                size="sm"
                                className={classes.item}
                                disabled={!serviceName || !labourEstimate}
                            >
                                Update
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </MainCard>
        </>
    );
};
export default AddFleetRequestService;
