import React from 'react';
import dayjs from 'dayjs';

const DateSeparator = ({ date }) => {
    return (
        <div className="message-date-separator">
            <span>{dayjs(date).format('MMMM D, YYYY')}</span>
        </div>
    );
};

export default DateSeparator;
