import BarChartIcon from '@mui/icons-material/BarChart';
import Scale from '@mui/icons-material/Scale';
import MapIcon from '@mui/icons-material/Map';
import Trans from '@mui/icons-material/Money';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PersonIcon from '@mui/icons-material/Person';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import PollIcon from '@mui/icons-material/Poll';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import NoteAltIcon from '@mui/icons-material/NoteAlt';

const userRole = localStorage.getItem('userRole');

export const quicklinks = [
    {
        id: 'report',
        title: 'Report',
        url: '/report',
        icon: (
            <BarChartIcon
                sx={{ color: '#7B808C', backgroundColor: '#F6F6F6', borderRadius: '100%', width: '32px', height: '32px', padding: '5px' }}
            />
        )
    },
    {
        id: 'orders',
        title: 'Orders',
        url: '/order',
        icon: (
            <ReceiptLongIcon
                sx={{ color: '#7B808C', backgroundColor: '#F6F6F6', borderRadius: '100%', width: '32px', height: '32px', padding: '5px' }}
            />
        )
    },
    {
        id: 'sales drive orders',
        title: 'Sales Drive Orders',
        url: '/csssalesdriveorders',
        icon: (
            <Scale
                sx={{ color: '#7B808C', backgroundColor: '#F6F6F6', borderRadius: '100%', width: '32px', height: '32px', padding: '5px' }}
            />
        )
    },
    {
        id: 'retention orders',
        title: 'Retention Orders',
        url: '/retentionorders',
        icon: (
            <Trans
                sx={{ color: '#7B808C', backgroundColor: '#F6F6F6', borderRadius: '100%', width: '32px', height: '32px', padding: '5px' }}
            />
        )
    },
    {
        id: 'map',
        title: 'Live Order Maps',
        url: '/map',
        icon: (
            <MapIcon
                sx={{ color: '#7B808C', backgroundColor: '#F6F6F6', borderRadius: '100%', width: '32px', height: '32px', padding: '5px' }}
            />
        )
    }
];

const storedRetailerId = localStorage.getItem('selectedRetailerId');
export const adminRetilerDashboardQuicklinks = [
    {
        id: 'profile',
        title: 'Retailers profile',
        url: `/retailer/profile/${storedRetailerId}`,
        icon: (
            <PersonIcon
                sx={{ color: '#7B808C', backgroundColor: '#F6F6F6', borderRadius: '100%', width: '32px', height: '32px', padding: '5px' }}
            />
        )
    },
    {
        id: 'analytics',
        title: 'Analytics',
        url: `/retailers/analytics/${storedRetailerId}`,
        icon: (
            <PollIcon
                sx={{ color: '#7B808C', backgroundColor: '#F6F6F6', borderRadius: '100%', width: '32px', height: '32px', padding: '5px' }}
            />
        )
    },
    ...(userRole === 'Admin'
        ? [
              {
                  id: 'assigntasks',
                  title: 'Assign Tasks',
                  url: '/retailers/tasks',
                  icon: (
                      <FormatListBulletedIcon
                          sx={{
                              color: '#7B808C',
                              backgroundColor: '#F6F6F6',
                              borderRadius: '100%',
                              width: '32px',
                              height: '32px',
                              padding: '5px'
                          }}
                      />
                  )
              }
          ]
        : []),

    {
        id: 'reminders',
        title: 'Reminders',
        url: `/retailers/reminders/${storedRetailerId}`,
        icon: (
            <AccessAlarmsIcon
                sx={{ color: '#7B808C', backgroundColor: '#F6F6F6', borderRadius: '100%', width: '32px', height: '32px', padding: '5px' }}
            />
        )
    },
    {
        id: 'notes',
        title: 'All Notes',
        url: `/retailers/allnotes`,
        icon: (
            <NoteAltIcon
                sx={{ color: '#7B808C', backgroundColor: '#F6F6F6', borderRadius: '100%', width: '32px', height: '32px', padding: '5px' }}
            />
        )
    }
];
