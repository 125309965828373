import { Link } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Button, Modal, Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ApiUrl from '../../../../network';
import { decrypt } from '../../../../utils/encrypt';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';
import reportLogo from '../../../../assets/images/GarageLogo.svg';
import html2pdf from 'html2pdf.js';
import CallMadeIcon from '@mui/icons-material/CallMade';
import ReactDOM from 'react-dom';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const [loading, setLoading] = useState(false);
    const [routeData, setRouteData] = useState({});
    const [showAllRoutes, setShowAllRoutes] = useState({});
    const [showRoutes, setShowRoutes] = useState(false);

    useEffect(() => {
        // eslint-disable-next-line consistent-return
        const fetchRouteReport = async () => {
            try {
                setLoading(true);
                const userInfo = localStorage.getItem('userToken');
                if (!userInfo) {
                    toast.error('User not authenticated');
                    return false;
                }
                const config = {
                    headers: {
                        'auth-token': userInfo,
                        'Content-Type': 'application/json'
                    }
                };

                const response = await fetch(`${ApiUrl.BaseUrl}/routerreport/rmaccroutedashboard`, config);

                await response.json().then((data) => {
                    if (data.status === false) {
                        toast.error(data.message);
                        setLoading(false);
                    } else {
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setRouteData(result);
                        setLoading(false);
                    }
                });
            } catch (error) {
                toast.error(error.message);
                setLoading(false);
            }
        };
        fetchRouteReport();

        return () => {
            // cleanup function
            setLoading(false);
        };
    }, []);

    const handleDownload = () => {
        const element = document.getElementById('printContainer');
        const popoverContent = document.getElementById('popoverContent').innerHTML;
        const routeDiv = document.getElementById('routeDiv');

        // Hide the routeDiv
        routeDiv.style.display = 'none';

        const opt = {
            margin: 1,
            filename: 'report.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        const contentWithRoutes = `
        ${element.innerHTML}
        <div>
            ${popoverContent}
        </div>
    `;

        html2pdf().from(contentWithRoutes).set(opt).save();

        // Show the routeDiv after generating the PDF
        routeDiv.style.display = 'flex';
    };

    const content = (
        <div style={{ padding: '10px' }}>
            {routeData?.routes && Object.keys(routeData.routes).length > 0 ? (
                Object.keys(routeData.routes).map((routeKey) => (
                    <React.Fragment key={routeKey}>
                        {routeData.routes[routeKey].map((route, index) => (
                            <span key={route._id} className="d-flex justify-content-space-between mb-2" style={{ fontSize: '14px' }}>
                                {route.location}
                            </span>
                        ))}
                    </React.Fragment>
                ))
            ) : (
                <span>No routes found</span>
            )}
        </div>
    );

    return (
        <div className="leadCrmContainer">
            <div id="popoverContent" style={{ display: 'none' }}>
                <span style={{ color: '#0A2938', fontSize: '16px', marginLeft: '10px' }}>Routes</span>
                <span className="flex justify-content-space-between align-items-center">{content}</span>
            </div>
            <div
                className="d-flex justify-content-space-between align-items-center salesCardHolder mb-4"
                style={{ borderBottom: '1px dotted #E7EAEB' }}
            >
                <Link to="/rma-route-schedule" style={{ color: '#0A2938' }}>
                    <p style={{ fontSize: '30px' }}>
                        {' '}
                        <KeyboardBackspaceIcon style={{ marginRight: '5px' }} /> Report
                    </p>
                </Link>
                <div className="d-flex justify-content-space-between align-items-center leadHeaders">
                    <Button
                        onClick={() => handleDownload()}
                        style={{
                            backgroundColor: '#FF740F',
                            color: '#fff'
                        }}
                    >
                        Download report
                    </Button>
                </div>
            </div>
            <div>
                <div id="printContainer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            maxWidth: '380px',
                            minWidth: '380px',
                            justifyContent: 'center'
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: '#FFFFFF',
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                display: 'flex',

                                flexDirection: 'column',
                                overflow: 'hidden',
                                flexBasis: '751px'
                            }}
                        >
                            <div
                                style={{
                                    height: '100%',
                                    backgroundColor: '#FFECC3',
                                    padding: '24px 20px 25px 20px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '112px',
                                        flexDirection: 'column',
                                        margin: '0 0 16px 0'
                                    }}
                                >
                                    <img src={reportLogo} alt="" className="report-image" />
                                </div>

                                <Typography
                                    sx={{
                                        fontSize: '24px',
                                        fontWeight: '500',
                                        color: '#0A2938',
                                        textAlign: 'center'
                                    }}
                                >
                                    Report
                                </Typography>
                            </div>
                        </div>
                        <div
                            style={{
                                backgroundColor: '#FFFFFF',
                                display: 'flex',
                                justifyContent: 'space-between',
                                overflow: 'hidden',
                                flexBasis: '751px',
                                padding: '15px 10px 15px 10px'
                            }}
                        >
                            <span style={{ color: '#9DA9AF', fontSize: '16px' }}>Report date</span>
                            <span style={{ color: '#0A2938', fontSize: '16px' }}>{dayjs(routeData?.reportDate).format('DD/MM/YYYY')}</span>
                        </div>
                        <div
                            style={{
                                backgroundColor: '#FFFFFF',
                                display: 'flex',
                                justifyContent: 'space-between',
                                overflow: 'hidden',
                                flexBasis: '751px',
                                padding: '15px 10px 15px 10px'
                            }}
                        >
                            <span style={{ color: '#0A2938', fontSize: '16px' }}>RMA name:</span>
                            <span style={{ color: '#0A2938', fontSize: '16px' }}>{routeData?.userName}</span>
                        </div>
                        <div
                            id="routeDiv"
                            style={{
                                backgroundColor: '#FFFFFF',
                                display: 'flex',
                                justifyContent: 'space-between',
                                overflow: 'hidden',
                                flexBasis: '751px',
                                padding: '15px 10px 15px 10px'
                            }}
                        >
                            <span style={{ color: '#0A2938', fontSize: '16px' }}>Routes:</span>

                            <Popover placement="bottom" content={content}>
                                <span
                                    style={{
                                        color: '#F5880E',
                                        fontSize: '16px',
                                        backgroundColor: 'transparent',
                                        padding: '0',
                                        width: 'fit-content'
                                    }}
                                >
                                    Show routes
                                </span>
                            </Popover>
                        </div>
                        <div
                            style={{
                                backgroundColor: '#FFFFFF',
                                display: 'flex',
                                justifyContent: 'space-between',
                                overflow: 'hidden',
                                flexBasis: '751px',
                                padding: '15px 10px 15px 10px'
                            }}
                        >
                            <span style={{ color: '#0A2938', fontSize: '16px' }}>Expected no of retailers:</span>
                            <span style={{ color: '#0A2938', fontSize: '16px' }}>{routeData.targetretailers}</span>
                        </div>
                        <div
                            style={{
                                backgroundColor: '#FFFFFF',
                                display: 'flex',
                                justifyContent: 'space-between',
                                overflow: 'hidden',
                                flexBasis: '751px',
                                padding: '15px 10px 15px 10px'
                            }}
                        >
                            <span style={{ color: '#0A2938', fontSize: '16px' }}>Actual no of retailers: </span>
                            <span style={{ color: '#0A2938', fontSize: '16px' }}>{routeData.achieveretailers}</span>
                        </div>
                        <div
                            style={{
                                backgroundColor: '#FFFFFF',
                                display: 'flex',
                                justifyContent: 'space-between',
                                overflow: 'hidden',
                                flexBasis: '751px',
                                padding: '15px 10px 15px 10px'
                            }}
                        >
                            <span style={{ color: '#0A2938', fontSize: '16px' }}>Target revenue </span>
                            <span style={{ color: '#0A2938', fontSize: '16px' }}>
                                {routeData.currency} {routeData.targetrevenue}
                            </span>
                        </div>
                        <div
                            style={{
                                backgroundColor: '#FFFFFF',
                                display: 'flex',
                                justifyContent: 'space-between',
                                overflow: 'hidden',
                                flexBasis: '751px',
                                padding: '15px 10px 15px 10px',
                                borderBottomLeftRadius: '10px',
                                borderBottomRightRadius: '10px'
                            }}
                        >
                            <span style={{ color: '#0A2938', fontSize: '16px' }}>Achieved revenue</span>
                            <span style={{ color: '#0A2938', fontSize: '16px' }}>
                                {routeData.currency} {routeData.achievedrevenue}
                            </span>
                        </div>
                        <div
                            style={{
                                backgroundColor: '#FFFFFF',
                                display: 'flex',
                                justifyContent: 'space-between',
                                overflow: 'hidden',
                                flexBasis: '751px',
                                padding: '15px 10px 15px 10px'
                            }}
                        >
                            <span style={{ color: '#0A2938', fontSize: '16px' }}>Target GMV </span>
                            <span style={{ color: '#0A2938', fontSize: '16px' }}>
                                {routeData.currency} {routeData.targetgmv}
                            </span>
                        </div>
                        <div
                            style={{
                                backgroundColor: '#FFFFFF',
                                display: 'flex',
                                justifyContent: 'space-between',
                                overflow: 'hidden',
                                flexBasis: '751px',
                                padding: '15px 10px 15px 10px'
                            }}
                        >
                            <span style={{ color: '#0A2938', fontSize: '16px' }}>Achieved GMV</span>
                            <span style={{ color: '#0A2938', fontSize: '16px' }}>
                                {routeData.currency} {routeData.achievedgmv}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Index;
