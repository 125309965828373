/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Col, Container, Row } from 'react-grid-system';
import { InputAdornment, TextField } from '@mui/material';
import { errorToast, successToast } from 'utils/toast';
import { useEffect, useMemo, useState } from 'react';

import FormControl from '@mui/material/FormControl';
import GenericPageHeader from 'components/GenericPageHeader';
import IconButton from '@mui/material/IconButton';
import Loader from 'components/Loader';
import ProductCard from 'components/OrderRequest/ProductCard';
import Search from '@mui/icons-material/Search';
import ShopCart from 'components/OrderRequest/ShopCart';
import _ from 'lodash';
import { authFetch } from 'utils/axios-config';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media screen and (max-width: 500px) {
        justify-content: center;
    }
`;

const OrderRequest = () => {
    const [parts, setParts] = useState([]);
    const [cart, setCart] = useState([]);
    const [show, setShow] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [cartLoading, setCartLoading] = useState(false);
    // INFO: Global search for api call after all keys
    const [search, setSearch] = useState('');
    const [localSearch, setLocalSearch] = useState('');

    /** Pagination states */
    const [page, setPage] = useState(1);
    const [totalParts, setTotalParts] = useState(0);
    const [numOfPages, setNumOfPages] = useState(1);

    // INFO: Generate numbers for the buttons from numOfPages
    const pages = Array.from({ length: numOfPages }, (_, i) => {
        return i + 1;
    });

    const prevPage = () => {
        let newPage = page - 1;
        if (newPage < 1) {
            newPage = 1;
        }
        setPage(newPage);
    };

    const nextPage = () => {
        let newPage = page + 1;
        if (newPage > numOfPages) {
            newPage = 1;
        }
        setPage(newPage);
    };

    const getParts = async () => {
        let url = `/partzone/salespricepartlistpaginate?page=${page}&limit=68`;
        if (search) {
            url += `&search=${search}`;
        }
        setLoading(true);
        try {
            const { data } = await authFetch(url);
            const partsData = data.data.map((part) => {
                return {
                    ...part,
                    qty: 1,
                    partType: ''
                };
            });
            setParts(partsData);
            setTotalParts(data?.totalParts);
            setNumOfPages(data?.numOfPages);
            setLoading(false);
            successToast('Data synchronized successfully');
        } catch (error) {
            if (error.response.status === 401) return;
            console.log(error);
        }
    };

    const handleAddToCart = (item) => {
        setCartLoading(true);
        if (_.includes(cart, item)) {
            errorToast('Item already in cart');
            setCartLoading(false);
            return;
        }
        setCart([...cart, item]);
        setCartLoading(false);
        successToast('Item added to cart');
    };

    const handleChange = (item, d) => {
        const ind = cart.indexOf(item);
        const arr = cart;
        arr[ind].qty += d;

        if (arr[ind].qty === 0) arr[ind].qty = 1;
        setCart([...arr]);
    };

    const debounce = () => {
        let timeoutID;
        return (e) => {
            setLocalSearch(e.target.value);
            clearTimeout(timeoutID);
            timeoutID = setTimeout(() => {
                setSearch(e.target.value);
                // only make api call after user finish typing
            }, 1000);
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const optimizedDebounce = useMemo(() => debounce(), []);

    useEffect(() => {
        getParts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, page]);

    // console.log('Total Parts', totalParts);
    // console.log('Parts', parts);
    return (
        <>
            <GenericPageHeader title="My Shopping" breadcrumbs="Shopping | Cart" hasCart cart={cart} setShow={setShow} show={show} />
            <div className="mt-5">
                <Container>
                    {show && (
                        <div className="search-box-parts mb-5">
                            <div>
                                <div className="mb-3" style={{ textAlign: 'center' }}>
                                    <h5 style={{ fontWeight: 'bold' }}>
                                        {totalParts} part{parts.length > 1 && 's'} found
                                    </h5>
                                </div>
                                <FormControl style={{ width: '100%', margin: '0 auto', display: 'block', textAlign: 'center' }}>
                                    <TextField
                                        label="Search Part Inventory"
                                        type="search"
                                        fullWidth
                                        className="search"
                                        onChange={optimizedDebounce}
                                        value={localSearch}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton>
                                                        <Search />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    )}
                    {isLoading && <Loader />}
                    <Row>
                        {show && !isLoading
                            ? parts.map((part, index) => (
                                  <Col xl={3} lg={4} md={4} sm={6} xs={12} key={index}>
                                      <ProductCard
                                          item={part}
                                          handleAddToCart={handleAddToCart}
                                          cartLoading={cartLoading}
                                          isLoading={isLoading}
                                      />
                                  </Col>
                              ))
                            : !isLoading && (
                                  <ShopCart cart={cart} handleChange={handleChange} setCart={setCart} show={show} setShow={setShow} />
                              )}
                    </Row>
                </Container>
            </div>
            {show && !isLoading && numOfPages > 1 && (
                <Container>
                    <Wrapper>
                        <div className="mt-5 mb-5">
                            <nav aria-label="Page navigation">
                                <ul className="pagination">
                                    <li className="page-item" onClick={prevPage}>
                                        <a className="page-link" href="#">
                                            <span aria-hidden="true">&laquo;</span>
                                        </a>
                                    </li>
                                    {pages.map((pageNum, index) => (
                                        <li
                                            className={`page-item ${pageNum === page ? 'active' : ''}`}
                                            key={index}
                                            onClick={() => setPage(pageNum)}
                                        >
                                            <a className="page-link" href="#">
                                                {pageNum}
                                            </a>
                                        </li>
                                    ))}
                                    <li className="page-item" onClick={nextPage}>
                                        <a className="page-link" href="#">
                                            <span aria-hidden="true">&raquo;</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </Wrapper>
                </Container>
            )}
        </>
    );
};

export default OrderRequest;
