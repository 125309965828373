import React, { useEffect } from 'react';
import { timeAgo } from '../timer';

const UserItem = ({ user, messages, index, setSelectedUser, selectedUser, setPhoneNumber, fetchUserMessages, hideSenderList }) => {
    const lastMessage = messages.filter((msg) => msg.phone === user.phone).slice(-1)[0]?.content || user.phone;
    const hasNewMessage = messages.some((msg) => msg.phone === user.phone && !msg.isSent && msg.agent !== 'user');
    const lastChatTime = user?.last_chat?.createdAt;
    const isLastMsgRead = user?.last_chat?.read === false;

    // Re-render the component whenever `messages` change
    useEffect(() => {
        // Fetch updated messages if necessary or handle message changes
    }, [messages]);

    const isActive = selectedUser?.phone === user.phone;

    return (
        <div
            className={`sender d-flex justify-content-between ${isActive ? 'active-sender' : ''}`}
            key={user._id}
            onClick={() => {
                if (window.innerWidth < 768) {
                    hideSenderList();
                }
                setSelectedUser(user.profile);
                setPhoneNumber({ phone: user.phone, index });
                fetchUserMessages(user.phone, index).then((r) => r);
            }}
            role="button"
            tabIndex={0}
            onKeyDown={() => fetchUserMessages(user.phone, index)}
        >
            <div className="d-flex">
                <div className="sender-avatar">{user?.profile?.slice(0, 2).toUpperCase()}</div>
                <div className="sender-content">
                    <div className="senderName">{user?.profile?.slice(0, 14)}</div>
                    <div className="senderMessage">
                        {hasNewMessage || isLastMsgRead ? (
                            <strong className="new_message_indicator">New Message</strong>
                        ) : (
                            lastMessage.slice(0, 10)
                        )}
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column justify-content-end">{lastChatTime && <small>{timeAgo(lastChatTime)}</small>}</div>
        </div>
    );
};

export default UserItem;
