import { Button, Input, Select, Space, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';

import ApiUrl from '../../../network';
import { toast } from 'react-toastify';
import { decrypt, encrypt } from '../../../utils/encrypt';
import normFile from '../../../imageToBucket';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [partsFamily, setPartsFamily] = useState([]);
    const [distributorInfo, setDistributors] = useState([]);
    const [part, setPart] = useState({
        sku: '',
        brand: '',
        distributor: '',
        productFamily: '',
        wholeSalePrice: null,
        garagePrice: null,
        takGaragePrice: null,
        kumasiGaragePrice: null,
        partQtyInPieces: '',
        partsImage: '',
        partWeight: '',
        status: 'Active',
        partImageList: []
    });

    const fetchFilteredParts = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/parts/v2/partsfilterdata`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                setPartsFamily(decryptedData.productfamily);
                setLoading(false);
            } else {
                toast.error(result.message);
                setLoading(false);
            }
        } catch (e) {
            toast(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };
    const retrieveAllDistributor = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(`${ApiUrl.BaseUrl}/channelpartner/channelspartnerdistributor`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setDistributors(data.data);
                    } else {
                        toast.error(data.data);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    const fetchPartDetails = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/parts/v2/viewparts/${id}`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                setPart({
                    sku: decryptedData.sku || '',
                    brand: decryptedData.brand || '',
                    distributor: decryptedData.distributor?.partnerName || '',
                    productFamily: decryptedData.productFamily || '',
                    wholeSalePrice: decryptedData.wholeSalePrice || null,
                    garagePrice: decryptedData.garagePrice || null,
                    takGaragePrice: decryptedData.takGaragePrice || null,
                    kumasiGaragePrice: decryptedData.kumasiGaragePrice || null,
                    partQtyInPieces: decryptedData.partQtyInPieces || '',
                    partsImage: decryptedData.partsImage || '',
                    partWeight: decryptedData.partWeight || '',
                    status: decryptedData.status || 'Active',
                    partImageList: decryptedData.partImageList || []
                });
                setLoading(false);
            } else {
                toast.error(result.message);
                setLoading(false);
            }
        } catch (e) {
            toast(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPartDetails();
        fetchFilteredParts();
        retrieveAllDistributor();
    }, [id]);
    const productFamilyOptions = partsFamily.map((family) => {
        return {
            value: family.name,
            label: family.name
        };
    });
    const distributorOptions = distributorInfo.map((distributor) => {
        return {
            value: distributor._id,
            label: distributor.partnerName
        };
    });

    const handleUpload = async (options) => {
        const { file, onSuccess, onError } = options;
        try {
            setLoading(true);
            const token = localStorage.getItem('userToken');
            const rootURL = ApiUrl.BaseUrl;
            const compressedImage = await normFile(file, token, rootURL);
            if (compressedImage) {
                setPart((prevState) => {
                    const updatedPartImageList = [...(prevState.partImageList || []), { image: compressedImage }];
                    return {
                        ...prevState,
                        partsImage: updatedPartImageList[0].image,
                        partImageList: updatedPartImageList
                    };
                });
                setLoading(false);
                onSuccess('success');
                toast.success('Image uploaded successfully');
            }
        } catch (error) {
            setLoading(false);
            onError(error);
            toast.error(error.message);
        }
    };

    const handleInputChange = (field, value) => {
        setPart({
            ...part,
            [field]: value
        });
    };

    const handleDeleteImage = (index) => {
        setPart((prevState) => {
            const updatedPartImageList = prevState.partImageList.filter((_, i) => i !== index);
            return {
                ...prevState,
                partsImage: updatedPartImageList.length > 0 ? updatedPartImageList[0].image : '', // Set first image in list as partsImage or empty if list is empty
                partImageList: updatedPartImageList
            };
        });
    };

    const updatePartHandler = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const {
                sku,
                partWeight,
                brand,
                partQtyInPieces,
                distributor,
                garagePrice,
                wholeSalePrice,
                productFamily,
                takGaragePrice,
                kumasiGaragePrice,
                partsImage,
                status
            } = part;

            if (
                !sku ||
                !partWeight ||
                !brand ||
                !partQtyInPieces ||
                !distributor ||
                !garagePrice ||
                !wholeSalePrice ||
                !productFamily ||
                !partsImage ||
                !status
            ) {
                toast.error('All fields are required for part update');
                return;
            }
            const checkDistributerID = distributorInfo.find((distributor) => distributor.partnerName.trim() === part.distributor.trim());

            const datas = {
                id,
                sku: part.sku,
                brand: part.brand,
                distributor: checkDistributerID?._id || part.distributor,
                productFamily: part.productFamily,
                wholeSalePrice: parseFloat(part.wholeSalePrice),
                garagePrice: parseFloat(part.garagePrice),
                partQtyInPieces: parseFloat(part.partQtyInPieces),
                partsImage: part.partsImage,
                partWeight: parseFloat(part.partWeight),
                status: part.status,
                partImageList: part.partImageList
            };

            if (localStorage.getItem('country').toLowerCase() === 'ghana') {
                datas.takGaragePrice = parseFloat(takGaragePrice);
                datas.kumasiGaragePrice = parseFloat(kumasiGaragePrice);
            } else {
                datas.takGaragePrice = parseFloat(0);
                datas.kumasiGaragePrice = parseFloat(0);
            }

            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            const url = `${ApiUrl.BaseUrl}/parts/v2/updateparts`;
            const response = await fetch(url, {
                method: 'PUT',
                headers: config.headers,
                body: JSON.stringify({ payload })
            });
            const result = await response.json();
            if (result.status === true) {
                toast.success(result.message);
                fetchPartDetails();
                setLoading(false);
            } else {
                const error = decrypt(result.data, keyResult, ivResult);
                toast.error(error);
                setLoading(false);
            }
        } catch (e) {
            toast.error(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };

    return (
        <div>
            <div className="m-header">
                <div>
                    <Link to="/parts">
                        <h1 style={{ color: '#0A2938', display: 'flex', alignItems: 'center' }}>
                            <ArrowLeftOutlined className="mr-2" /> Edit master database
                        </h1>
                    </Link>
                </div>
                <div>
                    <Button
                        style={{
                            backgroundColor: 'transparent',
                            color: '#FF740F'
                        }}
                        onClick={() => (window.location.href = '/parts')}
                        className="w-fit border-primary-m"
                    >
                        Cancel
                    </Button>
                    <Button loading={loading} onClick={() => updatePartHandler()} className="w-fit primary-bg  text-white ml-2">
                        Save changes
                    </Button>
                </div>
            </div>
            <div className="m-content m-add-page-content mt-4 bg-white" style={{ borderRadius: '10px' }}>
                <div>
                    {part?.partImageList?.length === 0 || !part?.partImageList ? (
                        <div className="m-image-container">
                            <div className="image-placeholder">
                                <img
                                    className="m-product-img"
                                    src={part.partsImage || 'https://via.placeholder.com/150'}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = 'https://via.placeholder.com/150';
                                    }}
                                    alt=""
                                />
                            </div>
                            <div className="m-product-img-actions">
                                <Space direction="vertical" style={{ width: '100%' }} size="large">
                                    <Upload
                                        action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                        listType="picture"
                                        maxCount={3}
                                        multiple
                                        customRequest={(options) => handleUpload(options)}
                                    >
                                        <Button className="m-upload-btn">
                                            {' '}
                                            <UploadOutlined /> Upload Image
                                        </Button>
                                    </Upload>
                                </Space>
                            </div>
                        </div>
                    ) : (
                        <div className="img-main-thumb">
                            <div className="m-image-container bg-white">
                                <div className="image-placeholder slider-container">
                                    <Slider {...settings}>
                                        {part.partImageList.map((image, index) => (
                                            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                                                <img src={image.image} alt={`part-${index}`} className="m-product-img" />
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                                <div className="m-product-img-actions">
                                    <Space direction="vertical" style={{ width: '100%' }} size="large">
                                        <Upload
                                            action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                            listType="picture"
                                            maxCount={3}
                                            multiple
                                            customRequest={(options) => handleUpload(options)}
                                        >
                                            <Button className="m-upload-btn">
                                                {' '}
                                                <UploadOutlined /> Upload Image
                                            </Button>
                                        </Upload>
                                    </Space>
                                </div>
                            </div>
                            <div className="imgs-thumbnail">
                                {part.partImageList.map((image, index) => (
                                    <div key={index} style={{ position: 'relative' }}>
                                        <img src={image.image} alt={`part-${index}`} />
                                        <Button
                                            type="danger"
                                            style={{
                                                position: 'absolute',
                                                top: 5,
                                                right: 15,
                                                backgroundColor: '#ff6574',
                                                color: '#fff',
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => handleDeleteImage(index, index)}
                                        >
                                            X
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className="ml-2">
                    <div className="d-flex">
                        <div className="form-group">
                            <p>Product name</p>
                            <Input placeholder="Product name" value={part.sku} onChange={(e) => handleInputChange('sku', e.target.value)} />
                        </div>
                        <div className="form-group">
                            <p>Product family</p>
                            <Select
                                options={productFamilyOptions}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }
                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                                className="m-select"
                                placeholder="Product family"
                                value={part.productFamily || undefined}
                                onChange={(value) => handleInputChange('productFamily', value)}
                            />
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="form-group">
                            <p>Weight (kg)</p>
                            <Input
                                type="number"
                                placeholder="Weight (kg)"
                                value={part.partWeight}
                                onChange={(e) => handleInputChange('partWeight', e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <p>Qnty in pieces</p>
                            <Input
                                placeholder="Qnty in pieces"
                                value={part.partQtyInPieces}
                                onChange={(e) => handleInputChange('partQtyInPieces', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="form-group">
                            <p>Distributor</p>
                            <Select
                                placeholder="Distributor"
                                options={distributorOptions}
                                value={part.distributor || undefined}
                                onChange={(value) => handleInputChange('distributor', value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }
                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                                className="m-select"
                            />
                        </div>
                        <div className="form-group">
                            <p>Brand</p>
                            <Input placeholder="Brand" value={part.brand} onChange={(e) => handleInputChange('brand', e.target.value)} />
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="form-group">
                            <p>Wholesale price</p>
                            <Input
                                type="number"
                                placeholder="Wholesale price"
                                value={part.wholeSalePrice}
                                onChange={(e) => handleInputChange('wholeSalePrice', e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <p>Garage price</p>
                            <Input
                                type="number"
                                placeholder="Garage price"
                                value={part.garagePrice}
                                onChange={(e) => handleInputChange('garagePrice', e.target.value)}
                            />
                        </div>
                    </div>
                    {localStorage.getItem('country') === 'Ghana' && (
                        <div className="d-flex">
                            <div className="form-group">
                                <p>Kumasi garage price</p>
                                <Input
                                    type="number"
                                    placeholder="Kumasi garage price"
                                    value={part.kumasiGaragePrice}
                                    onChange={(e) => handleInputChange('kumasiGaragePrice', e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <p>Takoradi garage price</p>
                                <Input
                                    type="number"
                                    placeholder="Takoradi garage price"
                                    value={part.takGaragePrice}
                                    onChange={(e) => handleInputChange('takGaragePrice', e.target.value)}
                                />
                            </div>
                        </div>
                    )}
                    <div className="form-group">
                        <p>Status</p>
                        <Select
                            placeholder="Status"
                            value={part?.status}
                            onChange={(value) => handleInputChange('status', value)}
                            options={[
                                {
                                    value: 'Active',
                                    label: 'Active'
                                },
                                {
                                    value: 'InActive',
                                    label: 'Inactive'
                                }
                            ]}
                            filterOption={(inputValue, option) => {
                                if (typeof option.label === 'string') {
                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }
                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }}
                            showSearch
                            allowClear
                            className="m-select"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
