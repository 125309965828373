import PersonIcon from '@mui/icons-material/Person';
import { LoadingOutlined } from '@ant-design/icons';
import PhoneIcon from '@mui/icons-material/Phone';
import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
import WestIcon from '@mui/icons-material/West';
import { Box, Typography } from '@mui/material';
import { Button, DatePicker, Input, Popover, Select, Space, Spin, Table } from 'antd';
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { dateConverter, twoDecimalPlaces, cryptoUUID } from 'utils/functions';
import { decrypt } from '../../utils/encrypt';
import reportLogo from '../../assets/images/GarageLogo.svg';
import { ToastContainer, toast } from 'react-toastify';
import AddPartModal from './AddPartModal';
import DeleteIcon from '@mui/icons-material/Delete';
import AddSalesPartModal from './AddSalesPartModal';
import useScriptRef from 'hooks/useScriptRef';

const AdminOrderRequestForm = () => {
    const history = useNavigate();
    const [orderDate, setorderDate] = useState('');
    const [cash, setCash] = useState('Cash');
    const [podId, setpodId] = useState('');
    // const [parts, setParts] = useState('');
    // const [salesTeamId, setsalesTeamId] = useState('');
    const [channelId, setChannelId] = useState('620aad68cadb9a0fe049486e');
    const [newChannelId, setNewChannelId] = useState('Retail');
    const [channelPartnerId, setChannelPartnerRecord] = useState('');
    const [newChannelPartnerId, setNewChannelPartnerId] = useState('');
    const [qty, setQty] = useState('');
    const [partQtyInPieces, setpartQtyInPieces] = useState('');
    const [partid, setPartId] = useState('');
    const [skuId, setSkuId] = useState('');
    const [brand, setBrand] = useState('');
    const [wholeSalePrice, setwholeSalePrice] = useState('');
    const [garagePrice, setgaragePrice] = useState('');
    const [applicableVins, setapplicableVins] = useState('');
    const [dropOffLocation, setdropOffLocation] = useState('');
    const [dropOffLocatLng, setdropOffLocatLng] = useState('');
    const [dropOffLocation2, setdropOffLocation2] = useState('');
    const [partnerContact, setpartnerContact] = useState('');
    // const [salesname, setsalesname] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [salesTeamInfo, setSalesTeam] = useState([]);
    const [channel, setChannel] = useState([]);
    const [channelpartner, setChannelPartner] = useState([]);
    const [part, setPart] = useState([]);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [items, setItems] = useState([]);
    const [partType, setpartType] = useState([]);
    const [partsdata, setpartsdata] = useState([]);
    const [orderNotes, setorderNotes] = useState('');
    const [partname, setpartname] = useState('');
    const [partsize, setpartsize] = useState('');
    const [partqty, setpartqty] = useState('');
    const [orderStatusId, setorderStatusId] = useState('');
    const [newOrderStatusId, setnewOrderStatusId] = useState('');
    const [orderStatus, setOrderStatus] = useState([]);
    const [supplier, setSupplier] = useState('');
    const [supplier2, setSupplier2] = useState('');
    const [supplierId, setsupplierId] = useState('');
    const [shopsdata, setshopsdata] = useState([]);
    const [shopname, setshopname] = useState('');
    const [shopstatus, setshopstatus] = useState(false);
    const [retailerStatus, setretailerStatus] = useState('');
    const [sizepart, setsizepart] = useState(0);
    const [partimage, setpartimage] = useState('');
    const [salesTeamId, setsalesUserId] = useState('');
    const [state, setstate] = useState('');
    const [localId, setlocalId] = useState('');
    const [oldwholeSalePrice, setoldwholeSalePrice] = useState('');
    const [oldgaragePrice, setoldgaragePrice] = useState('');
    const [discountedQty, setdiscountedQty] = useState(0);
    const [discountedCostPrice, setdiscountedCostPrice] = useState(0);
    const [discountedGaragePrice, setdiscountedGaragePrice] = useState(0);
    const [discountedSellingPrice, setDiscountedSellingPrice] = useState(0);
    const [isPartOpen, setIsPartOpen] = useState(false);
    const [isSalesPartOpen, setIsSalesPartOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { RangePicker } = DatePicker;
    const { TextArea } = Input;
    const country = localStorage.getItem('country');
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [mainRmaCc, setMainRmaCc] = useState([]);
    const [placedfor, setPlacedFor] = useState('');

    const scriptedRef = useScriptRef();

    // part modal
    const showPartModal = () => {
        setIsPartOpen(true);
    };

    const handlePartOk = () => {
        setIsPartOpen(false);
    };

    const handlePartCancel = () => {
        setIsPartOpen(false);
    };

    const showSalesPartModal = () => {
        setIsSalesPartOpen(true);
    };

    const handleSalesPartOk = () => {
        setIsSalesPartOpen(false);
    };

    const handleSalesPartCancel = () => {
        setIsSalesPartOpen(false);
    };

    const retrieveAllPart = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.CSPartsPriceListDataUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setPart(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllChannel = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.ChannelActiveSalesPage, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setChannel(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAlLsalesTeam = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            const url = `${ApiUrl.BaseUrl}/salesdrivepod/podinfo`;
            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setSalesTeam(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
            // fetch(ApiUrl.SalesTeamActiveSalesPage, config)
            //     .then((data) => data.json())
            //     .then((data) => {
            //         if (data.status === true) {
            //             setSalesTeam(data.data);
            //         } else {
            //             toast.error(data.data);
            //         }
            //     })
            //     .finally(() => {
            //         setLoading2(false);
            //     });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllChannelPartner = async (id) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const url = `${ApiUrl.BaseUrl}/channelpartner/podretailers/${id}`;
            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setChannelPartner(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    // setLoading3(false);
                });

            // fetch(ApiUrl.AllChannelPartnersSales + id, config)
            //     .then((data) => data.json())
            //     .then((data) => {
            //         if (data.status === true) {
            //             setChannelPartner(data.data);
            //         } else {
            //             toast.error(data.data);
            //         }
            //     })
            //     .finally(() => {
            //         setLoading3(false);
            //     });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllActiveOrderStatus = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.AllSalesOrderStatus, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrderStatus(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retailerHistory = (event) => {
        // console.log('retailer id', event);
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };
        setLoading(true);
        fetch(ApiUrl.RetailerFulfilledorderHistoryData + event, config)
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    console.log('order', data.data.length);
                    if (data.data.length > 0) {
                        const rstatus = 'Old';
                        setretailerStatus(rstatus);
                        // console.log('rstatus', rstatus);
                    } else if (data.data.length === 0) {
                        const rstatus = 'New';
                        setretailerStatus(rstatus);
                        // console.log('rstatus', rstatus);
                    } else {
                        const rstatus = 'Old';
                        setretailerStatus(rstatus);
                    }
                    // setOrders(data.data);
                } else {
                    toast.error(data.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // get query data
    const getQueryData = async () => {
        try {
            setLoading(true);
            const url = `${ApiUrl.BaseUrl}/mainorders/querydata?country=${country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);

                        setMainRmaCc(answer.relationshipManagers);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const salesTeamOptions = salesTeamInfo?.map((item) => {
        return { label: item?.name, value: item?._id };
    });

    const placedforOptions = mainRmaCc?.map((item) => {
        return { label: item?.name, value: item?._id };
    });

    const retailerOptions = channelpartner?.map((item) => {
        return { label: item?.partnerName, value: item?._id };
    });

    const orderStatusOptions = orderStatus?.map((item) => {
        return { label: item?.name, value: item?._id };
    });

    const partOptions = part?.map((item) => {
        return { label: item?.sku, value: item?.sku };
    });

    useEffect(() => {
        retrieveAlLsalesTeam();
        retrieveAllChannel();
        retrieveAllActiveOrderStatus();
        retrieveAllPart();
        getQueryData();
        const ids = cryptoUUID();
        setlocalId(ids);
    }, []);

    const handleSalesChange = (value) => {
        // setsalesTeamId(value);
        setpodId(value);
        retrieveAllChannelPartner(value);
        console.log('podid', value);
        setpodId(value);
        // const newFilter = salesTeamInfo.filter((team) => team._id.includes(value));
        // newFilter.forEach((option) => {
        //     // console.log('option', option);
        //     if (option?.country === 'Nigeria') {
        //         const fullname = `${option.firstName} ${option.lastName}`;
        //         setsalesname(fullname);
        //         setsalesUserId(option?._id ?? '');
        //     } else {
        //         setsalesname(option.name);
        //         setsalesUserId(option?.salesUserId ?? '');
        //     }
        // });
    };

    const handlePlacedForChange = (value) => {
        setPlacedFor(value);
    };

    const filterRetailer = (event) => {
        const newFilter = channelpartner.filter((value) => (value._id || '').toLowerCase().includes((event || '').toLowerCase()));
        newFilter.forEach((option) => {
            // console.log('option', option);
            if (option._id === event) {
                setNewChannelPartnerId(option._id);
                retailerHistory(option._id);
                // const location = `${option.locationLat} ${option.locationLng}`;
                setdropOffLocation(option.locationLat);
                setdropOffLocatLng(option.locationLng);
                setpartnerContact(option.partnerContact);
                setdropOffLocation2(option.location);
                setsalesUserId(option?.salesTeamId ?? '');
                if (option?.shopsdata.length > 0) {
                    // console.log('shop here');
                    setshopstatus(true);
                    setshopsdata(option?.shopsdata ?? []);
                }
            }
        });
    };

    const filterPart = (event) => {
        const newFilter = part.filter((value) => (value.sku || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('newFilter', newFilter);
        console.log('newFilter', newFilter);
        newFilter.forEach((option) => {
            setSkuId(option._id);
            setBrand(option.brand);
            setwholeSalePrice(option.wholeSalePrice);
            setoldwholeSalePrice(option?.wholeSalePrice);

            if (option?.discountedSellingPrice > 0) {
                setgaragePrice(option.discountedSellingPrice);
                setoldgaragePrice(option?.garagePrice);
            } else {
                setgaragePrice(option.garagePrice);
                setoldgaragePrice(option?.partListPrice);
            }

            setapplicableVins(option?.applicableVins ?? '');
            setpartQtyInPieces(option?.partQtyInPieces ?? 0);
            setpartimage(option?.partsImage ?? '');
            setstate(option?.state ?? '');
            if (option?.stock) {
                setSupplier(option?.stock);
            } else if (option?.distributor) {
                setSupplier(option?.distributor?.partnerName);
                setsupplierId(option?.distributor?._id);
            } else {
                setSupplier('Third Party');
            }

            setdiscountedQty(option?.discountedQty ?? 0);
            setdiscountedCostPrice(option?.discountedCostPrice ?? 0);
            setdiscountedGaragePrice(option?.discountedGaragePrice ?? 0);
            setDiscountedSellingPrice(option?.discountedSellingPrice ?? 0);
        });
    };

    const handleOrderDateChange = (newValue) => {
        setorderDate(newValue);
    };

    const addQty = (e) => {
        setQty(e.target.value);
        if (
            +Number(e.target.value) >= +Number(discountedQty) &&
            partType === 'Pieces' &&
            +Number(discountedQty) > 1 &&
            discountedSellingPrice > 1
        ) {
            setgaragePrice(discountedSellingPrice);
            setwholeSalePrice(discountedCostPrice);
        } else if (discountedSellingPrice <= 0) {
            // console.log('not above');
            // console.log('oldgaragePrice', oldgaragePrice);
            // console.log('oldwholeSalePrice', oldwholeSalePrice);

            setgaragePrice(garagePrice);
            setwholeSalePrice(oldwholeSalePrice);
        } else if (discountedSellingPrice > 1) {
            setgaragePrice(discountedSellingPrice);
        } else {
            setgaragePrice(garagePrice);
            setwholeSalePrice(oldwholeSalePrice);
        }
    };

    const handlePartType = (value) => {
        // console.log('size', event.target.value);

        if (value === 'Boxes') {
            // console.log('qty size', partQtyInPieces);
            // console.log('qty', qty);
            if (partQtyInPieces === 0) {
                setpartType(value);
            } else {
                const size = 'Pieces';
                setpartType(size);
                const piece = Number(qty) * Number(partQtyInPieces);
                setQty(piece);
                if (+Number(piece) >= +Number(discountedQty) && size === 'Pieces' && +Number(discountedQty) > 1) {
                    setgaragePrice(discountedGaragePrice);
                    setwholeSalePrice(discountedCostPrice);
                }
            }
        } else if (value === 'Rolls') {
            // console.log('qty size', partQtyInPieces);
            if (partQtyInPieces === 0) {
                setpartType(value);
            } else {
                const size = 'Pieces';
                setpartType(size);
                const piece = Number(qty) * Number(partQtyInPieces);
                setQty(piece);
                if (+Number(piece) >= +Number(discountedQty) && size === 'Pieces' && +Number(discountedQty) > 1) {
                    setgaragePrice(discountedGaragePrice);
                    setwholeSalePrice(discountedCostPrice);
                }
            }
        } else if (value === 'Packs') {
            // console.log('qty size', partQtyInPieces);
            if (partQtyInPieces === 0) {
                setpartType(value);
            } else {
                const size = 'Pieces';
                setpartType(size);
                const piece = Number(qty) * Number(partQtyInPieces);
                setQty(piece);
                if (+Number(piece) >= +Number(discountedQty) && size === 'Pieces' && +Number(discountedQty) > 1) {
                    setgaragePrice(discountedGaragePrice);
                    setwholeSalePrice(discountedCostPrice);
                }
            }
        } else {
            setpartType(value);
            if (+Number(qty) >= +Number(discountedQty) && value === 'Pieces' && +Number(discountedQty) > 1) {
                setgaragePrice(discountedGaragePrice);
                setwholeSalePrice(discountedCostPrice);
            }
        }
    };

    const handleOrderStatusChange = (value) => {
        setnewOrderStatusId(value);
        const newFilter = orderStatus.filter((status) => (status._id || '').toLowerCase().includes((value || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            setorderStatusId(option.name);
        });
    };

    const filterOptionsShop = (event) => {
        const newFilter = shopsdata.filter((value) => (value.shopname || '').toLowerCase().includes((event || '').toLowerCase()));
        newFilter.forEach((option) => {
            // console.log('option', option);
            // const location = `${option.shoplat} ${option.shoplng}`;
            setdropOffLocation(option.shoplat);
            setdropOffLocatLng(option.shoplng);
            setdropOffLocation2(option.shoplocation);
        });
    };

    const handlePartTypeSize = (value) => {
        setpartsize(value);
        setSupplier2('Third Party');
    };

    const onSubmitPartData = (event) => {
        // partsdata,setpartsdata
        setpartsdata([
            ...partsdata,
            {
                partname,
                partsize,
                partqty,
                supplier2
            }
        ]);

        handleSalesPartOk();
        // console.log(partsdata);
        let sum = Number(sizepart);
        sum += Number(partqty);
        console.log('sum2', sum);
        setsizepart(sum);
    };

    const onSubmitPart = (event) => {
        // items, setItems
        setItems([
            ...items,
            {
                skuId,
                partid,
                qty,
                partType,
                brand,
                wholeSalePrice,
                garagePrice,
                applicableVins,
                supplier,
                supplierId,
                partQtyInPieces,
                partimage,
                state
            }
        ]);

        handlePartOk();
        let sum = Number(sizepart);
        sum += Number(qty);
        console.log('sum', sum);
        setsizepart(sum);
    };

    const handleDeleteClick = (row, index) => {
        const newList = Object.assign([], items);
        newList.splice(index, 1);
        setItems(newList);
        const left = Number(sizepart) - Number(row.qty);
        // console.log('left', left);
        setsizepart(left);
    };

    const handleDelete2Click = (row, index) => {
        const newList = Object.assign([], partsdata);
        newList.splice(index, 1);
        setpartsdata(newList);
        const left2 = Number(sizepart) - Number(row.partqty);
        // console.log('left2', left2);
        setsizepart(left2);
    };

    const columns = [
        {
            title: 'Part',
            dataIndex: 'part',
            key: 'part'
        },
        {
            title: 'QTY',
            dataIndex: 'qty',
            key: 'qty'
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size'
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price'
        },

        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action'
        }
    ];

    const columns2 = [
        {
            title: 'Part',
            dataIndex: 'part',
            key: 'part'
        },
        {
            title: 'QTY',
            dataIndex: 'qty',
            key: 'qty'
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size'
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price'
        },

        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action'
        }
    ];

    const itemsData = items.map((result, index) => ({
        key: result?.skuId,
        part: result?.partid,
        qty: result?.qty,
        size: result?.partType,
        price: result?.garagePrice,
        action: (
            <DeleteIcon
                onClick={() => handleDeleteClick(result, index)}
                sx={{
                    color: 'red',
                    cursor: 'pointer'
                }}
            />
        )
    }));

    const newitemsData = partsdata.map((result, index) => ({
        key: index,
        part: result?.partname,
        qty: result?.partqty,
        size: result?.partsize,
        action: (
            <DeleteIcon
                onClick={() => handleDelete2Click(result, index)}
                sx={{
                    color: 'red',
                    cursor: 'pointer'
                }}
            />
        )
    }));

    const handleSubmit = () => {
        const salesemail = localStorage.getItem('userEmail');
        // const firstname = localStorage.getItem('userFirstName');
        // const lastname = localStorage.getItem('userLastName');
        // const csUserName = firstname + lastname;
        // const src = 'Web';
        // eslint-disable-next-line camelcase
        const local_id = localId;
        const cash = 'Web';

        const data = {
            orderDate,
            salesTeamId,
            channelId,
            newChannelId,
            newChannelPartnerId,
            cash,
            items,
            dropOffLocation,
            dropOffLocatLng,
            dropOffLocation2,
            partnerContact,
            // salesname,
            orderNotes,
            orderStatusId,
            newOrderStatusId,
            partsdata,
            salesemail,
            shopname,
            retailerStatus,
            sizepart,
            // salesUserId,
            local_id,
            placedfor
        };

        const userInfo = localStorage.getItem('userToken');

        setIsSubmitting(true);
        fetch(ApiUrl.CssOrderRequestUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status) {
                    toast.success(result.message);
                    // setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/order');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <div>
            <Box
                sx={{
                    margin: '24px 0px 0 0px',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Box
                    style={{
                        backgroundColor: '#FFFFFF',
                        borderRadius: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '5px auto',
                        overflow: 'hidden',
                        flexBasis: '470px'
                    }}
                >
                    <Box
                        style={{
                            height: '100%',
                            backgroundColor: '#fff',
                            padding: '24px 20px 0px 20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                width: '112px',
                                flexDirection: 'column',
                                margin: '0 0 16px 0'
                            }}
                        >
                            <img src={reportLogo} alt="" className="report-image" />
                        </Box>

                        <Typography
                            sx={{
                                fontWeight: '500',
                                color: '#0A2938',
                                textAlign: 'center',
                                fontSize: ' 1.25rem'
                            }}
                        >
                            Order Parts Request Form
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '0.875rem',
                                fontWeight: '500',
                                color: '#0A2938',
                                textAlign: 'center'
                            }}
                        >
                            Use this form to input parts requests from channel partners
                        </Typography>
                    </Box>

                    <Box
                        style={{
                            height: '100%',
                            padding: '32px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <Box
                            sx={{
                                width: ' 100%'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Date</Typography>

                            <DatePicker
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                allowClear
                                format="MM/DD/YYYY"
                                value={orderDate}
                                onChange={handleOrderDateChange}
                            />
                        </Box>

                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Pod Name</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="
                                    Pod Name
                                    "
                                options={salesTeamOptions}
                                onChange={(value) => handleSalesChange(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Placed for</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="
                                    Placed for
                                    "
                                options={placedforOptions}
                                onChange={(value) => handlePlacedForChange(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>

                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Channel Partner Name</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Channel Partner Name"
                                options={retailerOptions}
                                onChange={(value) => filterRetailer(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}> Order Status</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Order Status"
                                options={orderStatusOptions}
                                value={newOrderStatusId}
                                onChange={handleOrderStatusChange}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>

                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Box
                                sx={{
                                    wordBreak: 'break-word',
                                    maxWidth: '336px',
                                    fontSize: '13px',
                                    color: '#0A2938',
                                    fontWeight: '900'
                                }}
                            >
                                Parts needed: select name, quantity e.g. [Grease 500g 5, Abro 12]
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '24px 0 0 0px',
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button
                                type="primary"
                                size="large"
                                style={{
                                    backgroundColor: 'transparent',
                                    borderRadius: '8px',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#FF740F',
                                    border: '1px solid #FF740F',
                                    margin: '24px 24px 0 0px',
                                    width: '168px',
                                    height: '48px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                onClick={() => {
                                    showPartModal();
                                }}
                            >
                                Add part
                            </Button>
                        </Box>
                        {items.length > 0 ? (
                            <Box
                                sx={{
                                    width: ' 100%',
                                    margin: '24px 0 0 0px'
                                }}
                            >
                                <Table
                                    // loading={loading ? { indicator: <Spin indicator={antIcon} />, spinning: true / false } : ''}
                                    columns={columns}
                                    dataSource={itemsData}
                                    size="middle"
                                    scroll={{
                                        // x: 'calc(700px + 50%)',
                                        y: 580
                                    }}
                                    // pagination={tableParams.pagination}
                                    // onChange={handlePage}
                                />
                            </Box>
                        ) : null}

                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Box
                                sx={{
                                    wordBreak: 'break-word',
                                    maxWidth: '336px',
                                    fontSize: '13px',
                                    color: '#0A2938',
                                    fontWeight: '900'
                                }}
                            >
                                Only use this Button when the parts is not in the system.
                            </Box>
                            <Box
                                sx={{
                                    wordBreak: 'break-word',
                                    maxWidth: '336px',
                                    fontSize: '13px',
                                    color: '#0A2938',
                                    fontWeight: '900',
                                    margin: '10px 0 0 0'
                                }}
                            >
                                Parts needed: include name, quantity and size.eg: [Grease 500g 12 pieces]
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '24px 0 0 0px',
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button
                                type="primary"
                                size="large"
                                style={{
                                    backgroundColor: 'transparent',
                                    borderRadius: '8px',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#FF740F',
                                    border: '1px solid #FF740F',
                                    margin: '24px 24px 0 0px',
                                    width: '168px',
                                    height: '48px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                onClick={() => {
                                    showSalesPartModal();
                                }}
                            >
                                Add sales part
                            </Button>
                        </Box>

                        {partsdata.length > 0 ? (
                            <Box
                                sx={{
                                    width: ' 100%',
                                    margin: '24px 0 0 0px'
                                }}
                            >
                                <Table
                                    // loading={loading ? { indicator: <Spin indicator={antIcon} />, spinning: true / false } : ''}
                                    columns={columns2}
                                    dataSource={newitemsData}
                                    size="middle"
                                    scroll={{
                                        // x: 'calc(700px + 50%)',
                                        y: 580
                                    }}
                                    // pagination={tableParams.pagination}
                                    // onChange={handlePage}
                                />
                            </Box>
                        ) : null}
                    </Box>

                    <Box
                        sx={{
                            padding: '0 32px 0px 32px '
                        }}
                    >
                        <TextArea
                            rows={4}
                            placeholder="Additional notes ..."
                            onChange={(e) => setorderNotes(e.target.value)}
                            value={orderNotes}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: ' 100%',
                            margin: '0px 0 24px 0px',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Button
                            type="primary"
                            size="large"
                            loading={isSubmitting}
                            style={{
                                backgroundColor: '#FF740F',
                                borderRadius: '8px',
                                fontSize: '16px',
                                fontWeight: '500',
                                color: '#fff',
                                width: '181px',
                                height: '48px',
                                margin: '24px 24px 0 0px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            onClick={() => handleSubmit()}
                            className=" hover-secondary-button-orange"
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Box>
            <AddPartModal
                handlePartOk={handlePartOk}
                isPartOpen={isPartOpen}
                handlePartCancel={handlePartCancel}
                partOptions={partOptions}
                filterPart={filterPart}
                garagePrice={garagePrice}
                qty={qty}
                addQty={addQty}
                partType={partType}
                handlePartType={handlePartType}
                partimage={partimage}
                onSubmitPart={onSubmitPart}
                setPartId={setPartId}
            />

            <AddSalesPartModal
                handleSalesPartCancel={handleSalesPartCancel}
                handleSalesPartOk={handleSalesPartOk}
                isSalesPartOpen={isSalesPartOpen}
                handlePartTypeSize={handlePartTypeSize}
                partsize={partsize}
                setpartqty={setpartqty}
                partqty={partqty}
                partname={partname}
                setpartname={setpartname}
                onSubmitPartData={onSubmitPartData}
            />
        </div>
    );
};

export default AdminOrderRequestForm;
