import { withScriptjs, withGoogleMap, GoogleMap, InfoWindow, Marker, AdvancedMarkerView } from 'react-google-maps';
import icon1 from '../../assets/images/icons/Ellipsegreen.png';
import icon2 from '../../assets/images/icons/Ellipse901.png';
import DriverPopUp from './DriverPopUp';

const MyComponent = withScriptjs(
    withGoogleMap((props) => (
        <GoogleMap
            defaultZoom={8}
            defaultCenter={props.center}
            options={{
                streetViewControl: false,
                mapTypeControl: false
            }}
        >
            {props.drivers?.map((driver) => (
                <Marker
                    key={driver._id}
                    position={{ lat: driver.locationLat, lng: driver.locationLng }}
                    onClick={() => props.handleMarkerClick(driver._id)}
                    icon={{
                        url: driver.driverAvailability ? icon1 : icon2,
                        scaledSize: new window.google.maps.Size(25, 25)
                    }}
                >
                    {props.currentDriverId === driver?._id ? (
                        <InfoWindow onCloseClick={() => props.setCurrentDriverId(null)}>
                            <>
                                <DriverPopUp key={driver._id} {...driver} />
                            </>
                        </InfoWindow>
                    ) : null}
                </Marker>
            ))}
        </GoogleMap>
    ))
);

const TrackingMap = ({ mapCenter, handleMarkerClick, setCurrentDriverId, currentDriverId, drivers }) => {
    const key = 'AIzaSyBZqHjOL-7tPYGJfRKkROaOMxlHfWkou_0';

    return (
        <MyComponent
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            center={mapCenter}
            handleMarkerClick={handleMarkerClick}
            setCurrentDriverId={setCurrentDriverId}
            currentDriverId={currentDriverId}
            drivers={drivers}
        />
    );
};

export default TrackingMap;
