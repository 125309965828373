import { Button, Paper } from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';

import GenericPageHeader from 'components/GenericPageHeader';
import IconButton from '@mui/material/IconButton';
import Loader from 'components/Loader';
import { MdDelete } from 'react-icons/md';
import { authFetch } from 'utils/axios-config';
import moment from 'moment';
import styled from 'styled-components';
import { successToast } from 'utils/toast';
import { useAppContext } from 'context/appContext';
import { useDarkStoreContext } from 'context/darkstore-inventory/darkstore-context';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { wait } from 'utils/functions';

const Wrapper = styled.div`
    margin-top: 30px;
    .custom-paper {
        font-family: 'Roboto', 'sans-serif';
        padding: 3rem;
        .table {
            max-width: 75rem;
            margin: 0 auto;
        }
        .table td {
            padding: 1rem;
        }
    }
`;

const StockHistory = () => {
    const { userCurrency } = useAppContext();
    const { getPartHistory, partHistory, isLoading } = useDarkStoreContext();

    const [searchParams] = useSearchParams();
    const darkstoreAreaId = searchParams.get('darkstore');
    const { productId: partId } = useParams();
    useEffect(() => {
        getPartHistory(partId, darkstoreAreaId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteProductHistory = async (id) => {
        try {
            await authFetch.delete('/darkstoreinventory/remove', { data: { id } });
            // await authFetch.delete('/orderbatch/remove', { data: { id } });
            successToast('History deleted successfully.');
            wait(2).then(() => {
                getPartHistory(partId, darkstoreAreaId);
            });
        } catch (error) {
            if (error.response.status !== 401) {
                console.log(error);
            }
        }
    };

    return (
        <>
            <GenericPageHeader title="Stock Adjustment History" breadcrumbs="Home | Part Adjustment History" />
            <Wrapper>
                <Paper elevation={1} className="custom-paper">
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <div className="table-responsive mt-5 mb-5">
                            <table className="table  table-hover mb-4">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">UserId</th>
                                        <th scope="col">Product</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Garage Price ({userCurrency})</th>
                                        <th scope="col">Expected Cash ({userCurrency})</th>
                                        <th scope="col">Stock Updated (Date)</th>
                                        <th scope="col">Action(s)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {partHistory.map((el, index) => {
                                        console.log('History Table', el);
                                        const {
                                            _id,
                                            userId,
                                            partId: { sku },
                                            qty,
                                            garagePrice,
                                            expectedCash,
                                            stockDate,
                                            description
                                        } = el;
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{userId}</td>
                                                <td>{sku}</td>
                                                <td>{qty}</td>
                                                <td>{description}</td>
                                                <td>{garagePrice}</td>
                                                <td>{expectedCash.toFixed(2)}</td>
                                                <td>
                                                    <strong>
                                                        <em>{moment(stockDate).fromNow()}</em>
                                                    </strong>
                                                </td>
                                                <td>
                                                    <IconButton
                                                        aria-label="delete history"
                                                        onClick={() => deleteProductHistory(_id)}
                                                        title="Delete history"
                                                    >
                                                        <MdDelete style={{ color: 'red' }} />
                                                    </IconButton>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <br />
                            <div className="go-back" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Button
                                    component={Link}
                                    to={`/inventory/darkstores/${darkstoreAreaId}`}
                                    variant="contained"
                                    style={{ backgroundColor: ' #ff740f' }}
                                >
                                    &larr; Go back
                                </Button>
                            </div>
                        </div>
                    )}
                </Paper>
            </Wrapper>
        </>
    );
};
export default StockHistory;
