import { Button, Divider, Select, Spin, Table, DatePicker } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ApiUrl from '../../../network';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { decrypt } from '../../../utils/encrypt';
import { Link } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import dayjs from 'dayjs';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const [rewards, setRewards] = useState([]); // eslint-disable-line no-unused-vars
    const [date, setDate] = useState(null);
    const [country, setCountry] = useState('select country');
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);

    // eslint-disable-next-line consistent-return
    const fetchRewards = async (filters) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/loyaltymanager/periodicrewards`;

            if (filters) {
                if (filters.year) {
                    urlParams.set('year', filters.year);
                }
                if (filters.country) {
                    urlParams.set('country', filters.country);
                }
            }
            if (urlParams.toString()) url += `?${urlParams.toString()}`;

            const response = await fetch(url, config);
            if (!response.ok) {
                toast.error('Failed to fetch retailers');
            }
            const data = await response.json();
            let apiResponse = JSON.parse(decrypt(data.data, keyResult, ivResult));
            apiResponse = apiResponse.map((item) => {
                return {
                    month: `${item.month} ${item.year}`,
                    tier: item.tier,
                    totalRetailers: item.totalRetailers,
                    totalRewards: item.totalRewards
                };
            });
            setRewards(apiResponse);
            setLoading(false);
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };

    const fetchCountries = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(`${ApiUrl.BaseUrl}/country/activecountry`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error('Something went wrong');
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        // eslint-disable-next-line consistent-return
        fetchRewards();
        fetchCountries();
        return () => {
            // cleanup function
            setLoading(false);
        };
    }, []);

    const countryOption = countries?.map((result) => ({
        value: result.name,
        label: result.name
    }));
    const handleDateChange = (dateString) => {
        const year = dayjs(dateString).format('YYYY');
        setDate(year);
    };

    const appleyFilter = () => {
        let checkCountry = '';
        if (country === 'select country') {
            checkCountry = '';
        }
        const filterData = {
            country: checkCountry || country,
            year: date
        };
        fetchRewards(filterData);
    };

    const columns = [
        {
            title: 'Year',
            dataIndex: 'month',
            key: 'month'
        },
        {
            title: 'Tier',
            dataIndex: 'tier',
            key: 'tier'
        },
        {
            title: 'Retailers',
            dataIndex: 'totalRetailers',
            key: 'totalRetailers'
        },
        {
            title: 'Total rewards',
            dataIndex: 'totalRewards',
            key: 'totalRewards'
        }
    ];
    return (
        <div className="leadCrmContainer">
            <div className="d-flex justify-content-space-between align-items-center salesCardHolder">
                <Link to="/retailer-loyalty" style={{ color: '#0A2938' }}>
                    <p style={{ fontSize: '30px' }}>
                        {' '}
                        <KeyboardBackspaceIcon style={{ marginRight: '5px' }} /> Periodic rewards
                    </p>
                </Link>
                <div className="d-flex justify-content-space-between align-items-center salesCardHolder mr-2">
                    <div>
                        <Select
                            style={{ width: 200 }}
                            className="mr-2 filterInput mt-2"
                            showSearch
                            allowClear
                            placeholder="Country"
                            filterOption={(inputValue, option) => {
                                if (typeof option.label === 'string') {
                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }

                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }}
                            options={countryOption}
                            value={country}
                            onChange={(value) => setCountry(value)}
                        />
                    </div>
                    <DatePicker
                        format="YYYY"
                        picker="year"
                        className="datePicker filterInput mt-2"
                        onChange={handleDateChange}
                        style={{ width: 200 }}
                    />
                    {date || country !== 'select country' ? (
                        <div className="d-flex align-items-center leadHeaders ">
                            <Button
                                onClick={() => appleyFilter()}
                                style={{
                                    color: '#FF740F',
                                    border: '1px solid #FF740F'
                                }}
                                loading={loading}
                            >
                                Apply filter
                            </Button>
                            <Button
                                onClick={() => {
                                    setDate('');
                                    setCountry('select country');
                                    fetchRewards();
                                }}
                                style={{
                                    color: '#FF740F',
                                    border: '1px solid #FF740F'
                                }}
                                className="ml-2"
                            >
                                Reset filter
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
            <Divider style={{ margin: '20px 0', border: '1px dash #e8e8e8' }} />
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: '#FF740F' }} spin />} />
                </div>
            ) : (
                <div className="table-responsive">
                    <Table rowKey={(record) => record.month} columns={columns} dataSource={rewards} />
                </div>
            )}
        </div>
    );
};
export default Index;
