import { createContext, useContext, useReducer } from 'react';
import { authFetch, removeUserFromLocalStorage } from 'utils/axios-config';
import { errorToast, successToast } from 'utils/toast';
import * as XLSX from 'xlsx';
import {
    PRIRIOTY_BEGIN,
    PRIRIOTY_SUCCESS,
    BATCH_STAGING_SUCCESS,
    CREATE_PRIRIOTY_BEGIN,
    CREATE_PRIRIOTY_SUCCESS,
    CREATE_PRIRIOTY_ERROR,
    SINGLE_PRIRIOTY_BEGIN,
    SINGLE_PRIRIOTY_SUCCESS,
    UPDATE_PRIORITY_BEGIN,
    UPDATE_PRIORITY_SUCCESS,
    UPDATE_PRIORITY_ERROR,
    FILTER_PRIORITY_BEGIN,
    FILTER_PRIORITY_SUCCESS,
    FILTER_PRIORITY_ERROR
} from './priority-actions';
import priorityReducer from './priority-reducer';

const initialState = {
    isLoading: false,
    batchdata: [],
    stagingarea: [],
    todaypriority: [],
    avgdistancetoday: 0,
    size: 'N/A',
    location: '',
    priority: 0,
    singlePriority: {}
};

const PriorityContext = createContext({ ...initialState });

const PriorityProvider = ({ children }) => {
    const [state, dispatch] = useReducer(priorityReducer, initialState);

    const getAllPriorityOrders = async () => {
        dispatch({ type: PRIRIOTY_BEGIN });
        try {
            const { data } = await authFetch('/orderpriority/alltodaypriority');
            const { todaypriority, avgdistancetoday, size, location, priority } = data;
            dispatch({ type: PRIRIOTY_SUCCESS, payload: { todaypriority, avgdistancetoday, size, location, priority } });
            successToast('Data synchronized successfully');
        } catch (error) {
            console.log(error);
            removeUserFromLocalStorage();
        }
    };

    const getBatchStagingData = async () => {
        try {
            const { data } = await authFetch('orderpriority/priorityformdata');
            const { batchdata, stagingarea } = data;
            dispatch({ type: BATCH_STAGING_SUCCESS, payload: { batchdata, stagingarea } });
        } catch (error) {
            console.log(error);
            removeUserFromLocalStorage();
        }
    };

    const addOrderPriority = async (currentObject) => {
        dispatch({ type: CREATE_PRIRIOTY_BEGIN });
        try {
            const response = await authFetch.post('/orderpriority/add', currentObject);
            const { message } = response.data;
            dispatch({ type: CREATE_PRIRIOTY_SUCCESS, payload: { message } });
            successToast('Priority created successfully');
            setTimeout(() => {
                getAllPriorityOrders();
            }, 3000);
        } catch (error) {
            if (error.response.status === 401) return;
            const { message } = error.response.data;
            dispatch({ type: CREATE_PRIRIOTY_ERROR, payload: { message } });
            errorToast('Error processing request');
        }
    };

    const getPriority = async (id) => {
        dispatch({ type: SINGLE_PRIRIOTY_BEGIN });
        try {
            const { data } = await authFetch.get(`orderpriority/show/${id}`);
            dispatch({ type: SINGLE_PRIRIOTY_SUCCESS, payload: { singlePriority: data.data } });
        } catch (error) {
            console.log(error);
            removeUserFromLocalStorage();
        }
    };

    const updatePriorityOrder = async (currentObj) => {
        dispatch({ type: UPDATE_PRIORITY_BEGIN });
        try {
            const response = await authFetch.put('/orderpriority/update', currentObj);
            const { message } = response.data;
            dispatch({ type: UPDATE_PRIORITY_SUCCESS, payload: { message } });
            successToast('Priority updated successfully');
            setTimeout(() => {
                getAllPriorityOrders();
            }, 3000);
        } catch (error) {
            const { message } = error.response.data;
            if (error.response.status !== 401) {
                dispatch({
                    type: UPDATE_PRIORITY_ERROR,
                    payload: { message }
                });
                errorToast(message);
            }
        }
    };

    const handleExcelDataExport = (tableRow) => {
        if (tableRow.length === 0) {
            errorToast('Excel cannot be generated for empty rows');
            return;
        }
        try {
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableRow);
            XLSX.utils.book_append_sheet(wb, ws, 'priority');
            XLSX.writeFile(wb, 'order-priority.xlsx');
            successToast('Excel generated successfully');
        } catch (error) {
            console.log(error);
        }
    };

    const handlePriorityFilter = async (filterOptions) => {
        dispatch({ type: FILTER_PRIORITY_BEGIN });
        try {
            const { data } = await authFetch.post('/orderpriority/filteryprioritydate', filterOptions);
            const { todaypriority, avgdistancetoday, size, location, priority } = data;

            dispatch({
                type: FILTER_PRIORITY_SUCCESS,
                payload: {
                    todaypriority,
                    avgdistancetoday,
                    size,
                    location,
                    priority
                }
            });
            successToast('Data filtered successfully');
        } catch (error) {
            if (error.response.status !== 401) return;
            const { message } = error.response.data;
            dispatch({ type: FILTER_PRIORITY_ERROR, payload: { message } });
            errorToast(message);
        }
    };

    return (
        <PriorityContext.Provider
            value={{
                ...state,
                getAllPriorityOrders,
                getBatchStagingData,
                addOrderPriority,
                getPriority,
                updatePriorityOrder,
                handleExcelDataExport,
                handlePriorityFilter
            }}
        >
            {children}
        </PriorityContext.Provider>
    );
};

const usePriorityContext = () => {
    return useContext(PriorityContext);
};

export { PriorityProvider, initialState, usePriorityContext };
