import React from 'react';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { isLink } from '../utils/util';

const MediaContent = ({ message }) => {
    const renderImage = (src, index) => (
        <span
            key={index}
            role="button"
            tabIndex={0}
            aria-label="View image"
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    // Trigger any action on key press if necessary
                }
            }}
            style={{ display: 'block', marginBottom: '2px' }}
        >
            <img
                src={src}
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = 'https://via.placeholder.com/150';
                }}
                alt={`img-${index}`}
                style={{
                    width: '100%',
                    height: '200px',
                    objectFit: 'cover',
                    marginTop: '2px'
                }}
            />
        </span>
    );

    const renderImages = () => <div className="mt-4 mb-4">{message.image.map(renderImage)}</div>;

    const renderVideo = () => (
        <video width="100%" height="auto" controls>
            <source src={message.image} type="video/mp4" />
            <source src={message.image} type="video/webm" />
            <source src={message.image} type="video/ogg" />
            <track kind="captions" srcLang="en" label="English captions" default />
            Your browser does not support the video tag.
        </video>
    );

    const renderDocument = (src) => (
        <div className="mt-4 mb-4">
            <iframe title={`${src}#toolbar=0`} src={`${src}#toolbar=0`} style={{ width: '100%', height: '200px' }} />
            <div className="pdf-download-content">
                <span>
                    <img
                        className="pdf-info"
                        src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
                        alt="pdf"
                        style={{
                            width: '50px',
                            height: '50px'
                        }}
                    />
                </span>
                <Button onClick={() => window.open(src, '_blank')} className="pdf-download-btn">
                    <CloudDownloadOutlined />
                </Button>
            </div>
        </div>
    );

    const renderDocuments = () => (
        <div className="mt-4 mb-4">
            {message.image.map((docUrl, index) => (
                <span key={index} style={{ display: 'block', marginBottom: '20px' }}>
                    {renderDocument(docUrl)}
                </span>
            ))}
        </div>
    );

    const renderAudio = () => (
        <audio controls className="audio-preview">
            <source src={message.image} type="audio/mpeg" />
            <source src={message.image} type="audio/ogg" />
            <source src={message.image} type="audio/wav" />
            <track kind="captions" src="" label="English captions" />
            Your browser does not support the audio element.
        </audio>
    );

    // Determine the type of content to render
    switch (message.type) {
        case 'image':
            return message.image ? (
                <img src={message.image} alt="message-content" style={{ width: '100%', height: '200px', objectFit: 'cover' }} />
            ) : null;

        case 'images':
            return renderImages();

        case 'video':
            return renderVideo();

        case 'document':
            return renderDocument(message.image);

        case 'documents':
            return renderDocuments();

        case 'audio':
            return renderAudio();

        default:
            return isLink(message.content) ? (
                <a href={message.content} target="_blank" rel="noopener noreferrer">
                    {message.content}
                </a>
            ) : (
                <p>{message.content}</p>
            );
    }
};

export default MediaContent;
