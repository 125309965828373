import React, { useState, useRef, useEffect } from 'react';
import './chat.css';
import ApiUrl from '../../network';
import logo from '../../assets/images/garage.png';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { toast } from 'react-toastify';

const ChatWidget = () => {
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [showChatIcon, setShowChatIcon] = useState(true); // State to control chat icon visibility
    const chatMessagesRef = useRef(null);

    useEffect(() => {
        // Scroll to the bottom of the chat messages when messages state changes
        if (chatMessagesRef.current) {
            chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
        }
    }, [messages]);

    const toggleChat = () => {
        setIsOpen(!isOpen);
    };

    const sendMessage = () => {
        if (inputValue.trim() !== '') {
            const payload = {
                message: inputValue
            };

            const userInfo = localStorage.getItem('userToken');

            setIsTyping(true);

            fetch(`${ApiUrl.BaseUrl}/llm/chat`, {
                method: 'POST',
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Failed to send message');
                    }
                    return response.json();
                })
                .then((data) => {
                    const newMessages = [...messages, { text: inputValue, type: 'sent' }];
                    setMessages(newMessages);
                    setInputValue('');
                    // Add the received message to the UI
                    const receivedMessage = { text: data.message, type: 'received' };
                    setMessages([...newMessages, receivedMessage]);
                })
                .catch((error) => {
                    console.error('Error:', error);
                })
                .finally(() => {
                    setIsTyping(false);
                });
        } else {
            toast.error('Please enter a message');
        }
    };

    useEffect(() => {
        // Check if the element with id 'other-element' exists
        const otherElementExists = document.getElementById('other-element');
        if (otherElementExists) {
            setShowChatIcon(false); // Hide the chat icon if the other element exists
        }
    }, []);

    return (
        <div>
            {showChatIcon && ( // Conditionally render the chat icon based on the state
                <div className="chat-icon-container">
                    <div className="chat-icon-wrapper">
                        <button type="button" className="chat-icon" onClick={toggleChat}>
                            {isOpen ? <i className="fas fa-times" /> : <i className="fas fa-comment" />}
                        </button>
                    </div>
                </div>
            )}

            <div style={{ display: isOpen ? 'block' : 'none' }} className="chat-container" id="chat-container">
                <div className="chat-header">
                    <div className="chat-img-text">
                        <img src={logo} className="chat-header-avatar" alt="" />
                        <div className="chat-header-title">
                            <p>Mira</p>
                            <p>Your Personal Assistant</p>
                        </div>
                    </div>
                </div>
                <div ref={chatMessagesRef} className="chat-messages" id="chat-messages">
                    {messages.map((message, index) => (
                        <div key={index} className={`message ${message.type}`}>
                            {message.text}
                        </div>
                    ))}
                    {isTyping && (
                        <div className="bubble">
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 20, marginRight: '2px' }} spin />} />
                            Mira is typing...
                        </div>
                    )}
                </div>
                <div className="chat-footer">
                    <input
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') sendMessage();
                        }}
                        type="text"
                        className="message-input"
                        placeholder={isTyping ? 'Mira is typing...' : 'Type a message...'}
                        value={isTyping ? '' : inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                    <button type="button" className="send-button" onClick={sendMessage}>
                        <i className="fas fa-paper-plane" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ChatWidget;
