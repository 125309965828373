import { Link, useParams, useLocation } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Button, DatePicker, Popover, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ApiUrl from '../../../../network';
import { decrypt } from '../../../../utils/encrypt';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';
import reportLogo from '../../../../assets/images/GarageLogo.svg';
import html2pdf from 'html2pdf.js';
import { LoadingOutlined } from '@ant-design/icons';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const { RangePicker } = DatePicker;
    const [loading, setLoading] = useState(false);
    const [routeData, setRouteData] = useState({});
    const { id } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const startDate = queryParams.get('startDate');
    const endDate = queryParams.get('endDate');

    useEffect(() => {
        // eslint-disable-next-line consistent-return
        const fetchReport = async () => {
            try {
                setLoading(true);

                // Retrieve the user token from local storage
                const userInfo = localStorage.getItem('userToken');
                if (!userInfo) {
                    toast.error('User not authenticated');
                    setLoading(false); // Stop loading if user is not authenticated
                    return;
                }

                // Set up request configuration
                const config = {
                    headers: {
                        'auth-token': userInfo,
                        'Content-Type': 'application/json'
                    }
                };

                // Prepare query parameters for start and end dates
                const startDateParam = startDate ? `startDate=${startDate}` : ''; // Include start date if exists
                const endDateParam = endDate ? `endDate=${endDate}` : ''; // Include end date if exists

                // Combine query parameters with '&' if both are present
                const dateParams = [startDateParam, endDateParam].filter((param) => param).join('&');

                // Construct the URL with query parameters
                const url = `${ApiUrl.BaseUrl}/routerreport/viewuserroute?userId=${id}${dateParams ? `&${dateParams}` : ''}`;

                // Fetch the data
                const response = await fetch(url, config);

                // Process the response
                const data = await response.json();
                if (data.status === false) {
                    toast.error(data.message);
                    setLoading(false);
                    return;
                }

                // Decrypt and parse the result data
                const result = JSON.parse(decrypt(data.data, keyResult, ivResult));

                // Split the reportDate into individual dates
                const dateParts = result?.reportDate.split(' ');

                // Format both dates if valid, otherwise use 'Invalid date'
                const formattedDates = dateParts.map((datePart) =>
                    dayjs(datePart).isValid() ? dayjs(datePart).format('YYYY-MM-DD') : 'Invalid date'
                );

                // Combine formatted dates into a display string
                const displayDate = formattedDates.join(' to ');

                // Modify the report data structure
                const modifyDate = {
                    reportDate: displayDate,
                    userName: result.userName,
                    routes: result.routes,
                    targetretailers: result.targetretailers,
                    achieveretailers: result.achieveretailers,
                    targetrevenue: result.targetrevenue,
                    achievedrevenue: result.achievedrevenue,
                    currency: result.currency,
                    targetgmv: result.targetgmv,
                    achievedgmv: result.achievedgmv
                };

                // Update the state with the modified data
                setRouteData(modifyDate);
                setLoading(false);
            } catch (error) {
                toast.error(error.message);
                setLoading(false);
            }
        };

        fetchReport();

        return () => {
            // cleanup function
            setLoading(false);
        };
    }, [startDate, endDate]);

    const handleDownload = () => {
        const element = document.getElementById('printContainer');
        const popoverContent = document.getElementById('popoverContent').innerHTML;
        const routeDiv = document.getElementById('routeDiv');

        // Hide the routeDiv
        routeDiv.style.display = 'none';

        const opt = {
            margin: 1,
            filename: 'report.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        const contentWithRoutes = `
        ${element.innerHTML}
        <div>
            ${popoverContent}
        </div>
    `;

        html2pdf().from(contentWithRoutes).set(opt).save();

        // Show the routeDiv after generating the PDF
        routeDiv.style.display = 'flex';
    };

    const content = (
        <div style={{ padding: '10px' }}>
            {routeData?.routes && Object.keys(routeData.routes).length > 0 ? (
                Object.keys(routeData.routes).map((routeKey) => (
                    <React.Fragment key={routeKey}>
                        {routeData.routes[routeKey].map((route, index) => (
                            <span key={route._id} className="d-flex justify-content-space-between mb-2" style={{ fontSize: '14px' }}>
                                {route.location}
                            </span>
                        ))}
                    </React.Fragment>
                ))
            ) : (
                <span>No routes found</span>
            )}
        </div>
    );

    return (
        <div className="leadCrmContainer">
            <div id="popoverContent" style={{ display: 'none' }}>
                <span style={{ color: '#0A2938', fontSize: '16px', marginLeft: '10px' }}>Routes</span>
                <span className="flex justify-content-space-between align-items-center">{content}</span>
            </div>
            <div className="d-flex justify-content-space-between align-items-center mb-4" style={{ borderBottom: '1px dotted #E7EAEB' }}>
                <Link to="/admin-route-schedule" style={{ color: '#0A2938' }}>
                    <p style={{ fontSize: '30px' }}>
                        {' '}
                        <KeyboardBackspaceIcon style={{ marginRight: '5px' }} /> Report
                    </p>
                </Link>
                <Button
                    onClick={() => handleDownload()}
                    style={{
                        backgroundColor: '#FF740F',
                        color: '#fff',
                        width: 'fit-content'
                    }}
                >
                    Download report
                </Button>
            </div>
            <div>
                <div id="printContainer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {loading ? (
                        <div>
                            <Spin
                                indicator={
                                    <LoadingOutlined
                                        style={{
                                            fontSize: 24
                                        }}
                                        spin
                                    />
                                }
                            />
                        </div>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                maxWidth: '380px',
                                minWidth: '380px',
                                justifyContent: 'center'
                            }}
                        >
                            <div
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    borderTopLeftRadius: '10px',
                                    borderTopRightRadius: '10px',
                                    display: 'flex',

                                    flexDirection: 'column',
                                    overflow: 'hidden',
                                    flexBasis: '751px'
                                }}
                            >
                                <div
                                    style={{
                                        height: '100%',
                                        backgroundColor: '#FFECC3',
                                        padding: '24px 20px 25px 20px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            width: '112px',
                                            flexDirection: 'column',
                                            margin: '0 0 16px 0'
                                        }}
                                    >
                                        <img src={reportLogo} alt="" className="report-image" />
                                    </div>

                                    <Typography
                                        sx={{
                                            fontSize: '24px',
                                            fontWeight: '500',
                                            color: '#0A2938',
                                            textAlign: 'center'
                                        }}
                                    >
                                        Report
                                    </Typography>
                                </div>
                            </div>
                            <div
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    overflow: 'hidden',
                                    flexBasis: '751px',
                                    padding: '15px 10px 15px 10px'
                                }}
                            >
                                <span style={{ color: '#9DA9AF', fontSize: '16px' }}>Report date</span>
                                <span style={{ color: '#0A2938', fontSize: '16px' }}>{routeData?.reportDate}</span>
                            </div>
                            <div
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    overflow: 'hidden',
                                    flexBasis: '751px',
                                    padding: '15px 10px 15px 10px'
                                }}
                            >
                                <span style={{ color: '#0A2938', fontSize: '16px' }}>RMA name:</span>
                                <span style={{ color: '#0A2938', fontSize: '16px' }}>{routeData?.userName}</span>
                            </div>
                            <div
                                id="routeDiv"
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    overflow: 'hidden',
                                    flexBasis: '751px',
                                    padding: '15px 10px 15px 10px'
                                }}
                            >
                                <span style={{ color: '#0A2938', fontSize: '16px' }}>Routes:</span>

                                <Popover placement="bottom" content={content}>
                                    <span
                                        style={{
                                            color: '#F5880E',
                                            fontSize: '16px',
                                            backgroundColor: 'transparent',
                                            padding: '0',
                                            width: 'fit-content'
                                        }}
                                    >
                                        Show routes
                                    </span>
                                </Popover>
                            </div>
                            <div
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    overflow: 'hidden',
                                    flexBasis: '751px',
                                    padding: '15px 10px 15px 10px'
                                }}
                            >
                                <span style={{ color: '#0A2938', fontSize: '16px' }}>Expected no of retailers:</span>
                                <span style={{ color: '#0A2938', fontSize: '16px' }}>{routeData.targetretailers}</span>
                            </div>
                            <div
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    overflow: 'hidden',
                                    flexBasis: '751px',
                                    padding: '15px 10px 15px 10px'
                                }}
                            >
                                <span style={{ color: '#0A2938', fontSize: '16px' }}>Actual no of retailers: </span>
                                <span style={{ color: '#0A2938', fontSize: '16px' }}>{routeData.achieveretailers}</span>
                            </div>
                            <div
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    overflow: 'hidden',
                                    flexBasis: '751px',
                                    padding: '15px 10px 15px 10px'
                                }}
                            >
                                <span style={{ color: '#0A2938', fontSize: '16px' }}>Target revenue </span>
                                <span style={{ color: '#0A2938', fontSize: '16px' }}>
                                    {routeData.currency} {routeData.targetrevenue}
                                </span>
                            </div>
                            <div
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    overflow: 'hidden',
                                    flexBasis: '751px',
                                    padding: '15px 10px 15px 10px',
                                    borderBottomLeftRadius: '10px',
                                    borderBottomRightRadius: '10px'
                                }}
                            >
                                <span style={{ color: '#0A2938', fontSize: '16px' }}>Achieved revenue</span>
                                <span style={{ color: '#0A2938', fontSize: '16px' }}>
                                    {routeData.currency} {routeData.achievedrevenue}
                                </span>
                            </div>
                            <div
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    overflow: 'hidden',
                                    flexBasis: '751px',
                                    padding: '15px 10px 15px 10px'
                                }}
                            >
                                <span style={{ color: '#0A2938', fontSize: '16px' }}>Target GMV </span>
                                <span style={{ color: '#0A2938', fontSize: '16px' }}>
                                    {routeData.currency} {routeData.targetgmv}
                                </span>
                            </div>
                            <div
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    overflow: 'hidden',
                                    flexBasis: '751px',
                                    padding: '15px 10px 15px 10px'
                                }}
                            >
                                <span style={{ color: '#0A2938', fontSize: '16px' }}>Achieved GMV</span>
                                <span style={{ color: '#0A2938', fontSize: '16px' }}>
                                    {routeData.currency} {routeData.achievedgmv}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default Index;
