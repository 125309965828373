/* eslint-disable import/no-unresolved */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../../components/PageHeader';
import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Link, useNavigate } from 'react-router-dom';
// import { styled } from '@mui/material/styles';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import useScriptRef from 'hooks/useScriptRef';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProviders from '@mui/lab/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import EditIcon from '@mui/icons-material/Edit';
// import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import CircularProgress from '@mui/material/CircularProgress';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import Stack from '@mui/material/Stack';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

// const Input = styled('input')({
//     display: 'none'
// });

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    },
    backgroundColor: {
        backgroundColor: '#FF740F'
    },
    color: {
        color: '#FF740F',
        fontWeight: 'bold'
    },
    cancelColor: {
        cancelColor: '#D3DADF'
    }
}));

const AddFleetManagerReminder = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [channelPartnerId, setchannelPartnerId] = useState('');
    const [title, settitle] = useState('');
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [repeat, setrepeat] = useState('');
    const [timezone, settimezone] = useState('');
    const [alerttype, setalerttype] = useState('');
    const [alerttime, setalerttime] = useState('');
    const [notes, setnotes] = useState('');
    // const [companyName, setcompanyName] = useState('');
    // const [loading3, setLoading3] = useState(false);
    // const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    useEffect(() => {
        // const company = localStorage.getItem('company');
        const channelPartnerId = localStorage.getItem('channelpartnerId');
        // setcompanyName(company);
        setchannelPartnerId(channelPartnerId);
    }, []);

    const handleRepeatChange = (event) => {
        setrepeat(event.target.value);
    };

    const handleTimeZoneChange = (event) => {
        settimezone(event.target.value);
    };

    const handleAlertTypeChange = (event) => {
        setalerttype(event.target.value);
    };

    const handleAlertTimeChange = (event) => {
        setalerttime(event.target.value);
    };

    // Submit Order
    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        const reminderType = 'FleetManager';
        setLoading2(true);
        const data = {
            title,
            channelPartnerId,
            startDate,
            endDate,
            repeat,
            timezone,
            alerttype,
            alerttime,
            notes,
            reminderType
        };

        fetch(ApiUrl.AddFleetManagerReminderUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading2(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/fleetmanagerreminder');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                }
            })
            .finally(() => {
                setLoading2(false);
            });
    };

    return (
        <>
            <PageHeader title="Home | Fleet Management | Reminders" subTitle="Home | Add Reminder" />
            {/* {loading ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : ( */}
            <>
                <MainCard title="">
                    <Typography variant="color" component="div" className={classes.color} color="warning">
                        REMINDER FOR VEHICLE SERVICING
                    </Typography>
                    <br />
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2} justify="flex-start">
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Box width={220}>
                                    <TextField
                                        autoComplete="off"
                                        name="title"
                                        fullWidth
                                        variant="outlined"
                                        id="title"
                                        label="Title of reminder"
                                        value={title}
                                        onChange={(e) => settitle(e.target.value)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Box width={220}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DateTimePicker
                                            renderInput={(props) => <TextField {...props} />}
                                            label="Start Date and Time"
                                            value={startDate}
                                            onChange={(newValue) => {
                                                console.log('startdate', newValue);
                                                setstartDate(newValue);
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Box width={220}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DateTimePicker
                                            renderInput={(props) => <TextField {...props} />}
                                            label="End Date and Time"
                                            value={endDate}
                                            onChange={(newValue) => {
                                                setendDate(newValue);
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Box width={220}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Repeat</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={repeat}
                                            label="Repeat"
                                            onChange={handleRepeatChange}
                                        >
                                            <MenuItem value="">Select an option</MenuItem>
                                            <MenuItem value="Never">Never</MenuItem>
                                            <MenuItem value="Daily">Daily</MenuItem>
                                            <MenuItem value="Weekly">Weekly</MenuItem>
                                            <MenuItem value="Monthly">Monthly</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Box width={220}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Time Zone</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={timezone}
                                            label="Time Zone"
                                            onChange={handleTimeZoneChange}
                                        >
                                            <MenuItem value="">Select an option</MenuItem>
                                            <MenuItem value="GMT">(GMT; UTC±00:00) Greenwich Mean Time </MenuItem>
                                            <MenuItem value="GMT1">(GMT + 1; UTC±00:00) Greenwich Mean Time + 1</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Box width={220}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Alert Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={alerttype}
                                            label="Alert Type"
                                            onChange={handleAlertTypeChange}
                                        >
                                            <MenuItem value="">Select an option</MenuItem>
                                            <MenuItem value="Notification">Notification </MenuItem>
                                            <MenuItem value="Email">Email</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Box width={220}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Alert Time</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={alerttime}
                                            label="Alert Time"
                                            onChange={handleAlertTimeChange}
                                        >
                                            <MenuItem value="">Select an option</MenuItem>
                                            <MenuItem value="None">None </MenuItem>
                                            <MenuItem value="OnTime">On time</MenuItem>
                                            <MenuItem value="5minsbefore">5 mins before</MenuItem>
                                            <MenuItem value="10minsbefore">10 mins before</MenuItem>
                                            <MenuItem value="30minsbefore">30 mins before</MenuItem>
                                            <MenuItem value="1hourbefore">1 hour before</MenuItem>
                                            <MenuItem value="1daybefore">1 day before</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={12}>
                                <TextField
                                    autoComplete="off"
                                    name="notes"
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    fullWidth
                                    value={notes}
                                    id="notes"
                                    label="Notes"
                                    onChange={(e) => setnotes(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <br />

                        <Box display="flex" justifyContent="space-between">
                            <Button component={Link} to="/fleetmanagerreminder" type="submit" variant="contained" color="error">
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained" className={classes.backgroundColor}>
                                {loading2 ? 'Saving ..' : 'Save'}
                            </Button>
                        </Box>
                    </form>
                </MainCard>
            </>
        </>
    );
};
export default AddFleetManagerReminder;
