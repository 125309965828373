import { LoadingOutlined } from '@ant-design/icons';
import PersonIcon from '@mui/icons-material/Person';
import ReportIcon from '@mui/icons-material/Report';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Box, Typography } from '@mui/material';
import { Input, Modal, notification } from 'antd';
import { twoDecimalPlaces } from '../../utils/functions';
import './collection.css';

const AllPaymentsModal = ({ isPaymentOpen, handlePaymentCancel, handlePaymentOk, receipts, receiptId }) => {
    const filteredReceipt = receipts.find((item) => item._id === receiptId);

    return (
        <div>
            <Modal
                key={filteredReceipt?._id}
                open={isPaymentOpen}
                onOk={handlePaymentOk}
                centered
                width={321}
                zIndex={2050}
                height={490}
                onCancel={handlePaymentCancel}
                footer={<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />}
            >
                <div className="">
                    <Typography
                        sx={{
                            fontSize: '20px',
                            color: '#546974',
                            padding: '0',
                            fontWeight: '500',
                            // marginBottom: '40px',
                            textAlign: 'center'
                        }}
                    >
                        Payment details
                    </Typography>
                    {filteredReceipt?.collectionType === 'Bulk' ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin: '7px 0 0 0',
                                alignItems: 'center'
                            }}
                        >
                            <ReportIcon
                                sx={{
                                    fontSize: '13px',
                                    color: '#4B6EFF'
                                }}
                            />
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    color: '#546974',
                                    padding: '0',

                                    textAlign: 'center'
                                }}
                            >
                                Bulk payment
                            </Typography>
                        </Box>
                    ) : null}

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',

                            height: '400px',
                            overflowY: 'scroll',
                            overflowX: 'hidden'
                            // paddingTop: '30px'
                        }}
                        className="collection-modals-y"
                    >
                        <Box
                            sx={{
                                width: ' 100%',
                                margin:
                                    filteredReceipt?.collectionType === 'Bulk'
                                        ? `${filteredReceipt?.ordersBulk?.length * 15 + 200}px 0 0 0px`
                                        : '100px 0 0 0px'
                            }}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974', margin: '0px 0 10px 0px' }}>
                                    Receipt
                                </Typography>
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    overflowX: 'scroll',
                                    scrollSnapType: 'x mandatory',
                                    whiteSpace: 'nowrap',
                                    width: '321px'
                                    // height: '100px'
                                }}
                                className="collection-modals-x"
                            >
                                {filteredReceipt?.image?.map((img) => (
                                    <Box
                                        sx={{
                                            width: ' 100%',
                                            height: '200px',
                                            backgroundColor: '#E7EAEB',
                                            borderRadius: '12px',
                                            cursor: 'pointer',
                                            overflow: 'hidden',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flex: '0 0 270px',
                                            scrollSnapAlign: 'center',
                                            marginRight: '5px'
                                        }}
                                        key={img?._id}
                                    >
                                        <img src={img?.image} className="receipt-image" alt="receipt" />

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexDirection: 'column'
                                            }}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        {filteredReceipt?.collectionType === 'Bulk' ? (
                            <Box
                                sx={{
                                    width: ' 100%',
                                    margin: '14px 0 0 0px'
                                }}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974', margin: '0px 0 5px 0px' }}>
                                        Orders
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        width: ' 100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    {filteredReceipt.ordersBulk.map((value, index) => (
                                        <Box
                                            key={value + index}
                                            sx={{
                                                width: ' 124px',
                                                margin: '0px 0 8px 0',
                                                height: '44px',
                                                borderRadius: '8px',
                                                padding: '8px',
                                                border: '1px solid #E9ECED',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#546974',
                                                    fontSize: '16px'
                                                }}
                                            >
                                                {value.orderNo}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        ) : null}

                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '14px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974', margin: '0px 0 5px 0px' }}>
                                Amount deposited
                            </Typography>
                            <Input
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                placeholder="Amount"
                                value={twoDecimalPlaces(filteredReceipt?.amount)}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '14px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974', margin: '0px 0 5px 0px' }}>
                                Payment Type
                            </Typography>
                            <Input
                                style={{
                                    width: '100%',
                                    zindex: '5900'
                                }}
                                size="large"
                                zindex="4050"
                                placeholder="Payment type"
                                value={filteredReceipt?.paymentType}
                            />
                        </Box>

                        <Box
                            sx={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                flexDirection: 'column',
                                width: '100%',
                                margin: '14px 0 0 0px'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%'
                                }}
                            >
                                <PersonIcon
                                    sx={{
                                        fontSize: '18px',
                                        color: '#4B6EFF',
                                        marginRight: '4px'
                                    }}
                                />
                                <Typography
                                    sx={{
                                        color: '#546974',
                                        marginRight: '4px'
                                    }}
                                >
                                    RMA:
                                </Typography>
                                <Typography sx={{ color: '#0A2938' }}>
                                    {filteredReceipt?.createdBy?.firstName}
                                    {filteredReceipt?.createdBy?.lastName}
                                </Typography>
                            </Box>
                            {filteredReceipt?.collectionType !== 'Bulk' ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        marginTop: '4px'
                                    }}
                                >
                                    <ShoppingCartIcon
                                        sx={{
                                            fontSize: '18px',
                                            color: '#4B6EFF',
                                            marginRight: '4px'
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            color: '#546974',
                                            marginRight: '4px'
                                        }}
                                    >
                                        Order number
                                    </Typography>
                                    <Typography sx={{ color: '#0A2938' }}>{filteredReceipt?.orderNo}</Typography>
                                </Box>
                            ) : null}
                            {filteredReceipt?.collectionType === 'Bulk' ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        marginTop: '4px'
                                    }}
                                >
                                    <ShoppingBagIcon
                                        sx={{
                                            fontSize: '18px',
                                            color: '#4B6EFF',
                                            marginRight: '4px'
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            color: '#546974',
                                            marginRight: '4px'
                                        }}
                                    >
                                        Total Sales Value
                                    </Typography>

                                    <Typography sx={{ color: '#0A2938' }}>{twoDecimalPlaces(filteredReceipt?.totalSales)}</Typography>
                                </Box>
                            ) : null}
                            {filteredReceipt?.collectionType !== 'Bulk' ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        marginTop: '4px'
                                    }}
                                >
                                    <ShoppingBagIcon
                                        sx={{
                                            fontSize: '18px',
                                            color: '#4B6EFF',
                                            marginRight: '4px'
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            color: '#546974',
                                            marginRight: '4px'
                                        }}
                                    >
                                        Sales Value
                                    </Typography>
                                    <Typography sx={{ color: '#0A2938' }}>
                                        {filteredReceipt?.country === 'Ghana'
                                            ? twoDecimalPlaces(filteredReceipt?.orderIdGh?.expCashCollected)
                                            : twoDecimalPlaces(filteredReceipt?.orderIdNg?.expCashCollected)}
                                    </Typography>
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                </div>
            </Modal>
        </div>
    );
};

export default AllPaymentsModal;
