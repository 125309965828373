import { Table, Button, Input, Select, Collapse, Modal, Upload, message, Tag } from 'antd';
import { useState, useEffect } from 'react';
import {
    ReloadOutlined,
    CheckCircleOutlined,
    InboxOutlined,
    PlusSquareOutlined,
    MinusCircleOutlined,
    CloseOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { decrypt, encrypt } from '../../utils/encrypt';
import { toast } from 'react-toastify';
import ApiUrl from '../../network';
import normFile from '../../imageToBucket';

const { Dragger } = Upload;

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');
const country = localStorage.getItem('country');
const Index = () => {
    const [filters, setFilters] = useState({
        search: '',
        routes: '',
        pods: ''
    });
    const [retailers, setRetailers] = useState([]);
    const [pods, setPods] = useState([]);
    const [retailerId, setRetailerId] = useState('');
    const [retailerDetails, setRetailerDetails] = useState({
        firstName: '',
        lastName: '',
        dataCountry: localStorage.getItem('country'),
        partnerContact: '',
        shopData: [
            {
                shopname: '',
                shoplocation: '',
                shoplat: '',
                shoplng: '',
                street: '',
                neighbourhood: '',
                city: '',
                state: '',
                zip: '',
                addressDescription: ''
            }
        ],
        stage: '1',
        shopPhoto: '',
        nationalId: '',
        notes: ''
    });

    // useEffect(() => {
    //     // Update shop locations based on existing shop data
    //     const updatedShopData = retailerDetails.shopData.map((shop) => ({
    //         ...shop,
    //         shoplocation: `${shop.city || ''} ${shop.neighbourhood || ''} ${shop.street || ''}`.trim()
    //     }));

    //     // Set the updated shop data
    //     setRetailerDetails((prevState) => ({
    //         ...prevState,
    //         shopData: updatedShopData
    //     }));
    // }, [retailerDetails.shopData]); // Trigger when shopData changes

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalCount, setTotalCount] = useState(0);
    const [locationLoading, setLocationLoading] = useState(false);
    const [routes, setRoutes] = useState([]);
    const [cities, setCities] = useState([]);

    // fetch retailers
    const fetchRetailers = async (page = currentPage, size = pageSize) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const params = new URLSearchParams({
                page,
                limit: size
            });

            Object.entries(filters).forEach(([key, value]) => {
                if (value !== '') {
                    params.append(key, value);
                }
            });

            const response = await fetch(`${ApiUrl.BaseUrl}/crm/get-channel-partners?${params.toString()}`, config);
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();

            if (data.status === true) {
                const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));

                setRetailers(decryptedData?.data);
                setTotalCount(decryptedData?.totalCount);
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            toast.error('Failed to fetch retailers.');
        } finally {
            setLoading(false);
        }
    };

    const fetchPodsData = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/salesdrivepod/salesdrivepod`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));

                        setPods(result);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    // fetch routes
    const fetchRoutes = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/crm/routes?country=${country}&page=1&limit=40000`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.message === '') {
                        const result = data.data;

                        setRoutes(result);
                        setCities(result);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    // get user lat and lng from geolocation
    // const getUserLocation = () => {
    //     setLocationLoading(true);
    //     navigator.geolocation.getCurrentPosition(
    //         (position) => {
    //             // Extract latitude and longitude from the position
    //             const latitude = position.coords.latitude.toString();
    //             const longitude = position.coords.longitude.toString();

    //             // Update all shops with the user's location
    //             setRetailerDetails((prevState) => ({
    //                 ...prevState,
    //                 shopData: prevState.shopData.map((shop, index) => ({
    //                     ...shop,
    //                     shoplat: latitude, // Update latitude for each shop
    //                     shoplng: longitude // Update longitude for each shop
    //                 }))
    //             }));

    //             toast.success('Location updated successfully');
    //             setLocationLoading(false);
    //         },
    //         (error) => {
    //             toast.error(`Failed to retrieve location: ${error.message}`);
    //             setLocationLoading(false);
    //         }
    //     );
    // };

    const getUserLocation = (shopIndex) => {
        try {
            setLocationLoading(true);
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // Extract latitude and longitude from the position
                    const { latitude, longitude } = position.coords;

                    // Update the specific shop with the user's location
                    setRetailerDetails((prevState) => ({
                        ...prevState,
                        shopData: prevState.shopData.map((shop, index) => {
                            if (index === shopIndex) {
                                // Only update the shop at shopIndex
                                return {
                                    ...shop,
                                    shoplat: latitude.toString(), // Update latitude for the current shop
                                    shoplng: longitude.toString() // Update longitude for the current shop
                                };
                            }
                            return shop;
                        })
                    }));

                    toast.success('Location updated successfully');
                    setLocationLoading(false);
                },
                (error) => {
                    toast.error(`Failed to retrieve location: ${error.message}`);
                    setLocationLoading(false);
                }
            );
        } catch (error) {
            toast.error(`Failed to retrieve location: ${error.message}`);
        } finally {
            setLocationLoading(false);
        }
    };

    // update retailer
    const updateRetailer = async () => {
        try {
            setLoadingUpdate(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            // Filter out shopData that contains 'shopdate'
            const filteredRetailerDetails = {
                ...retailerDetails,
                shopData: retailerDetails.shopData.filter((shop) => !shop.shopdate)
            };

            // Only send if there are valid shopData entries without 'shopdate'
            if (filteredRetailerDetails.shopData.length === 0) {
                toast.error('This retailer data has already been submitted');
                setLoadingUpdate(false);
                return;
            }

            const response = await fetch(`${ApiUrl.BaseUrl}/crm/update-channel-partner/${retailerId}`, {
                method: 'PATCH',
                body: JSON.stringify(filteredRetailerDetails),
                headers: config.headers
            });
            const data = await response.json();

            if (data.status === true || data.message === '') {
                const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));

                toast.success(decryptedData);

                setModalOpen(false);
                fetchRetailers();
                setRetailerDetails({
                    firstName: '',
                    lastName: '',
                    dataCountry: localStorage.getItem('country'),
                    partnerContact: '',
                    shopData: [
                        {
                            shopname: '',
                            shoplocation: '',
                            shoplat: '',
                            shoplng: '',
                            street: '',
                            neighbourhood: '',
                            city: '',
                            state: '',
                            zip: '',
                            addressDescription: ''
                        }
                    ],
                    stage: '1',
                    shopPhoto: '',
                    nationalId: '',
                    notes: ''
                });
            } else {
                toast.error(data.message);
            }
            setLoadingUpdate(false);
        } catch (error) {
            toast.error('Failed to update retailer');
            setLoadingUpdate(false);
        }
    };

    useEffect(() => {
        fetchRetailers();
        fetchPodsData();
        fetchRoutes();
    }, [filters, pageSize, currentPage]);

    const retailerData = retailers.map((retailer) => ({
        key: retailer?._id,
        firstName: retailer?.firstName,
        lastName: retailer?.lastName,
        shopName: retailer?.shopName,
        name: retailer?.partnerName,
        contact: retailer?.partnerContact,
        pod: retailer?.pod?.name,
        route: retailer?.route,
        verification_status: retailer?.verification_status,
        verifyStage: retailer?.verifyStage,
        phoneNumber: retailer?.partnerContact,
        shopData: retailer?.shopsdata
    }));

    const styles = {
        header: {
            backgroundColor: '#0A2938',
            padding: 20,
            borderRadius: 10
        },
        headerContent: {
            fontFamily: 'Poppins',
            fontSize: 30,
            fontWeight: 500,
            lineHeight: '38px',
            textAlign: 'left',
            color: '#0A2938'
        },
        contentHeaderParagraph: {
            fontFamily: 'Poppins',
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '20px',
            textAlign: 'left',
            color: '#0A2938'
        },
        content: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left',
            color: '#696E7C'
        },
        filters: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left',
            color: '#696E7C'
        },
        filterButton: {
            width: '140px',
            borderRadius: '8px',
            backgroundColor: '#FF740F',
            color: '#fff',
            border: 'none',
            marginRight: '10px'
        },
        resetButton: {
            width: 'fit-content',
            borderRadius: '8px',
            backgroundColor: '#fff',
            color: '#000',
            border: '1px solid #D9DBDE'
        },
        table: {
            borderRadius: '16px',
            backgroundColor: '#fff',
            border: '1px solid #E5E5E5'
        },
        actionButton: {
            backgroundColor: 'transparent',
            color: '#FF740F',
            border: '1px solid #FF740F',
            borderRadius: '8px',
            fontFamily: 'Poppins',
            fontSize: 14,
            lineHeight: '20px',
            fontWeight: 500,
            width: 'fit-content'
        },
        modalHeader: {
            fontFamily: 'Poppins',
            fontSize: 24,
            fontWeight: 500,
            lineHeight: '32px',
            textAlign: 'left',
            color: '#E7EAEB'
        },
        modalParagraph: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left',
            color: '#E7EAEB'
        },
        saveButton: {
            width: 'fit-content',
            borderRadius: '8px',
            backgroundColor: '#FF740F',
            color: '#fff',
            border: 'none'
        },
        modalHeaderContainer: {
            backgroundColor: '#233E4C',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: '#E7EAEB',
            padding: 20,
            borderRadius: 10
        }
    };

    const columns = [
        {
            title: 'Retailer',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Contact',
            dataIndex: 'contact',
            key: 'contact'
        },
        {
            title: 'POD',
            dataIndex: 'pod',
            key: 'pod'
        },
        {
            title: 'Route',
            dataIndex: 'route',
            key: 'route'
        },
        {
            title: 'Shops',
            dataIndex: 'shopData',
            key: 'shopData',
            render: (_, record) => (
                <>
                    {record?.shopData.length > 0 ? (
                        <ul>
                            {record?.shopData.map((shop, index) => (
                                <li style={{ listStyle: 'none' }} key={index}>
                                    {shop.shopname}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <span>{record?.shopName}</span>
                    )}
                </>
            )
        },
        {
            title: 'Verification Status',
            dataIndex: 'verification_status',
            key: 'verification_status',
            render: (status) => {
                if (status.toLowerCase() === 'pending') {
                    return <Tag color="warning">{status}</Tag>;
                }
                return <Tag color="success">{status}</Tag>;
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record) => (
                <div>
                    {record.verification_status.toLowerCase() === 'pending' && record.verifyStage !== '1' ? (
                        <>
                            <Button
                                onClick={() => {
                                    setRetailerId(record.key);

                                    // Use prevState to merge the new retailer details with the existing state
                                    setRetailerDetails((prevState) => ({
                                        ...prevState, // Spread the previous state to retain other fields
                                        firstName: record.firstName?.trim() || prevState.firstName?.trim() || '', // Fallback to prevState firstName if record firstName is empty and trim whitespace
                                        lastName: record.lastName?.trim() || prevState.lastName?.trim() || '', // Fallback to prevState lastName if record lastName is empty and trim whitespace
                                        partnerContact: record.phoneNumber?.trim() || prevState.partnerContact?.trim() || '', // Fallback to prevState partnerContact if record phoneNumber is empty and trim whitespace
                                        shopData:
                                            record.shopData?.length > 0
                                                ? record.shopData.map((shop) => ({
                                                      ...shop,
                                                      shopname: shop.shopname?.trim() // Trim whitespace from shopname
                                                  }))
                                                : [
                                                      {
                                                          shopname: record.shopName?.trim() || '' // Trim whitespace from record shopName if shopData is empty
                                                      }
                                                  ] // Add shopname if shopData is empty
                                    }));

                                    setModalOpen(true); // Open modal
                                }}
                                style={styles.actionButton}
                            >
                                Verify
                            </Button>
                        </>
                    ) : record.verifyStage === '2' ? (
                        <div style={{ display: 'flex' }}>
                            <Tag
                                color="processing"
                                bordered={false}
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            >
                                Next stage
                            </Tag>
                            <Button
                                onClick={() => {
                                    setRetailerId(record.key);
                                    setRetailerDetails((prevState) => ({
                                        ...prevState, // Spread the previous state to retain other fields
                                        firstName: record.firstName?.trim() || prevState.firstName?.trim() || '', // Fallback to prevState firstName if record firstName is empty and trim whitespace
                                        lastName: record.lastName?.trim() || prevState.lastName?.trim() || '', // Fallback to prevState lastName if record lastName is empty and trim whitespace
                                        partnerContact: record.phoneNumber?.trim() || prevState.partnerContact?.trim() || '', // Fallback to prevState partnerContact if record phoneNumber is empty and trim whitespace
                                        shopData:
                                            record.shopData?.length > 0
                                                ? record.shopData.map((shop) => ({
                                                      ...shop,
                                                      shopname: shop.shopname?.trim() // Trim whitespace from shopname
                                                  }))
                                                : [
                                                      {
                                                          shopname: record.shopName?.trim() || '' // Trim whitespace from record shopName if shopData is empty
                                                      }
                                                  ] // Add shopname if shopData is empty
                                    }));
                                    setModalOpen(true); // Open modal
                                }}
                                style={{
                                    width: 'fit-content',
                                    border: '1px solid #FF740F',
                                    backgroundColor: 'transparent',
                                    marginLeft: '5px',
                                    color: '#FF740F'
                                }}
                            >
                                Add more shop
                            </Button>
                        </div>
                    ) : (
                        <Tag color="success" bordered={false}>
                            Verified
                        </Tag>
                    )}
                </div>
            )
        }
    ];

    const applyFilters = () => {
        // Apply the filters to the retailers data
        const filteredRetailers = retailers.filter((retailer) => {
            const nameMatch = retailer.partnerName.toLowerCase().includes(filters.search.toLowerCase());
            const routeMatch = !filters.routes || retailer.route === filters.routes;
            const podMatch = !filters.pods || retailer.pod.name === filters.pods;
            return nameMatch && routeMatch && podMatch;
        });

        // Update the retailers state with the filtered data
        setRetailers(filteredRetailers);

        // Reset pagination to the first page
        setCurrentPage(1);

        // Fetch the filtered data from the API
        fetchRetailers();
    };

    const resetFilters = () => {
        setFilters({
            search: '',
            routes: '',
            pods: ''
        });
        setCurrentPage(1);
        setPageSize(25);
        fetchRetailers();
    };

    const idUploadRequest = async (file, type) => {
        try {
            const token = localStorage.getItem('userToken');
            const rootURL = ApiUrl.BaseUrl;
            const compressedImage = await normFile(file, token, rootURL);

            if (compressedImage) {
                if (type === 'photo') {
                    setRetailerDetails({ ...retailerDetails, shopPhoto: compressedImage });
                } else {
                    setRetailerDetails({ ...retailerDetails, nationalId: compressedImage });
                }
                toast.success(type === 'photo' ? 'Photo uploaded successfully' : 'National ID uploaded successfully');

                return {
                    status: 'done',
                    response: compressedImage
                };
            }
            // Return a default value if compressedImage is falsy
            return {
                status: 'error',
                response: 'Failed to compress image'
            };
        } catch (error) {
            return {
                status: 'error'
            };
        }
    };

    const props = {
        name: 'file',
        multiple: true,
        customRequest: ({ file, onSuccess, onError }) => {
            idUploadRequest(file, 'photo')
                .then((response) => {
                    if (response.status === 'done') {
                        onSuccess(response);
                    } else {
                        onError(response);
                    }
                })
                .catch((error) => {
                    onError(error);
                });
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files);
        }
    };

    const idProps = {
        name: 'file',
        multiple: false,
        customRequest: ({ file, onSuccess, onError }) => {
            idUploadRequest(file, 'id')
                .then((response) => {
                    if (response.status === 'done') {
                        onSuccess(response);
                    } else {
                        onError(response);
                    }
                })
                .catch((error) => {
                    onError(error);
                });
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files);
        }
    };

    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
        fetchRetailers(pagination.current, pagination.pageSize);
    };

    const citiOptions = cities.map((city) => ({
        label: city.name, // This should match what is displayed in the <p> tag
        value: city.name // The value for the Select option
    }));

    const addShop = () => {
        setRetailerDetails((prevState) => ({
            ...prevState,
            shopData: [
                ...prevState.shopData,
                {
                    shopname: '',
                    shoplocation: '',
                    shoplat: '',
                    shoplng: '',
                    street: '',
                    neighbourhood: '',
                    city: '',
                    state: '',
                    zip: '',
                    addressDescription: ''
                }
            ]
        }));
    };

    // Function to update the shop data
    const updateShopData = (index, key, value) => {
        setRetailerDetails((prevDetails) => {
            const updatedShopData = prevDetails.shopData.map((shop, i) => {
                if (i === index) {
                    return { ...shop, [key]: value };
                }
                return shop;
            });
            return { ...prevDetails, shopData: updatedShopData };
        });
    };

    // Function to handle neighborhood selection and related changes
    const handleNeighbourhoodChange = (value, index) => {
        if (Array.isArray(retailerDetails.shopData)) {
            const selectedRoute = routes.find((route) => route.name === value);
            if (selectedRoute) {
                const country = localStorage.getItem('country');
                let cityName = '';
                let stateName = '';

                if (country === 'Ghana') {
                    cityName = selectedRoute.cityId?.city || '';
                    stateName = selectedRoute.regionId?.name || '';
                } else if (country === 'Nigeria') {
                    cityName = selectedRoute.lgaId?.name || '';
                    stateName = selectedRoute.regionId?.name || '';
                }

                const updatedShops = [...retailerDetails.shopData];
                updatedShops[index] = {
                    ...updatedShops[index],
                    neighbourhood: value,
                    city: cityName,
                    state: stateName
                };

                setRetailerDetails({ ...retailerDetails, shopData: updatedShops });
            }
        } else {
            // console.error('shopData is not an array:', retailerDetails.shopData);
        }
    };

    // Function to update retailer details for fields not related to specific shops

    const updateRetailerDetails = (index, field, value) => {
        // Create a new array by updating the specific shop data at the given index
        const updatedShopData = retailerDetails.shopData.map((shop, i) => {
            if (i === index) {
                // Create the updated shop object with the new field value
                const updatedShop = {
                    ...shop,
                    [field]: value
                };

                // Update the shoplocation based on the other fields
                updatedShop.shoplocation = `${updatedShop.city || ''} ${updatedShop.neighbourhood || ''} ${
                    updatedShop.street || ''
                }`.trim();

                return updatedShop; // Return the updated shop object
            }
            return shop; // Return the unchanged shop object
        });

        // Update the retailerDetails state with the new shopData
        setRetailerDetails((prevDetails) => ({
            ...prevDetails,
            shopData: updatedShopData
        }));
    };

    // Function to remove shop
    const removeShop = (index) => {
        setRetailerDetails((prevDetails) => {
            const updatedShopData = [...prevDetails.shopData];
            updatedShopData.splice(index, 1); // Remove shop at the specific index

            return {
                ...prevDetails,
                shopData: updatedShopData
            };
        });
    };

    return (
        <div className="verification">
            <div className="header">
                <div className="header-content">
                    <h1 style={styles.headerContent}>Field Verification</h1>
                    <p style={styles.contentHeaderParagraph}>view and verify all retailer information</p>
                </div>
            </div>
            <div className="content">
                <div className="content-header">
                    <Collapse accordion>
                        <Collapse.Panel key={1} header="Hide filters" className="mb-4">
                            <div className="filters" style={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
                                <Input
                                    placeholder="Search by name"
                                    style={{ width: '200px' }}
                                    onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                                    value={filters.search}
                                />
                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp="label"
                                    options={routes.map((route) => ({
                                        label: route.name,
                                        value: route.name
                                    }))}
                                    filterOption={(inputValue, option) => {
                                        // This function is used to filter options in a Select component
                                        // It compares the input value with the label of each option

                                        // Convert both the input and the option label to uppercase for case-insensitive comparison
                                        const upperInput = inputValue.toUpperCase();

                                        // Check if the option label is a string
                                        if (typeof option.label === 'string') {
                                            // If it's a string, directly check if the uppercase input is included in the uppercase label
                                            return option.label.toUpperCase().indexOf(upperInput) !== -1;
                                        }

                                        // If the label is not a string (e.g., it might be a number or other type)
                                        // Convert it to a string before comparing
                                        return String(option.label).toUpperCase().indexOf(upperInput) !== -1;
                                    }}
                                    placeholder="Select route"
                                    style={{ width: '200px' }}
                                    onChange={(value) => setFilters({ ...filters, routes: value })}
                                    value={filters.routes || null}
                                />

                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp="label"
                                    options={pods.map((pod) => ({
                                        label: pod.name,
                                        value: pod._id
                                    }))}
                                    filterOption={(inputValue, option) => {
                                        if (typeof option.label === 'string') {
                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }

                                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }}
                                    placeholder="Select POD"
                                    style={{ width: '200px' }}
                                    onChange={(value) => {
                                        setFilters({ ...filters, pods: value });
                                    }}
                                    value={filters.pods || null}
                                />

                                <div className="action_button">
                                    <Button style={styles.filterButton} onClick={applyFilters}>
                                        Apply filter
                                    </Button>
                                    <Button style={styles.resetButton} onClick={resetFilters}>
                                        <ReloadOutlined />
                                    </Button>
                                </div>
                            </div>
                        </Collapse.Panel>
                    </Collapse>
                </div>
                <div className="content-body">
                    <div className="table-responsive" style={styles.table}>
                        <Table
                            rowKey={(record) => record.key}
                            columns={columns}
                            dataSource={retailerData}
                            loading={loading}
                            pagination={{
                                current: currentPage,
                                pageSize,
                                total: totalCount
                            }}
                            onChange={handleTableChange}
                            scroll={{ x: 1000 }}
                        />
                    </div>
                </div>
            </div>
            <Modal open={modalOpen} footer={null} onCancel={() => setModalOpen(false)} width={700} zIndex={999}>
                <div>
                    <div className="modal-header" style={styles.modalHeaderContainer}>
                        <div>
                            <h1 style={styles.modalHeader}>Field Verification Criteria</h1>
                            <p style={styles.modalParagraph}>Click the circle to verify and the text to edit</p>
                        </div>
                        {/* <div>
                            <Button loading={locationLoading} onClick={() => getUserLocation()}>
                                Get Location
                            </Button>
                        </div> */}
                    </div>
                    <div className="modal-body">
                        <h3
                            style={{
                                marginBottom: '16px',
                                fontSize: '20px',
                                lineHeight: '30px',
                                fontWeight: 400,
                                fontFamily: 'Poppins'
                            }}
                        >
                            Verify retailer information
                        </h3>
                        <div
                            className="modal-body-content"
                            style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}
                        >
                            <span style={{ width: '100%', marginRight: '16px' }}>
                                <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                    <CheckCircleOutlined
                                        style={{
                                            color: retailerDetails?.firstName ? '#00AA55' : '#696E7C',
                                            backgroundColor: retailerDetails?.firstName ? '#E6F7EE' : '#D9D9D9',
                                            borderRadius: '50%',
                                            padding: '4px'
                                        }}
                                    />
                                    First Name <span style={{ color: '#FF0000' }}>*</span>
                                </p>
                                <Input
                                    value={retailerDetails.firstName}
                                    onChange={(e) =>
                                        setRetailerDetails({
                                            ...retailerDetails,
                                            firstName: e.target.value
                                        })
                                    }
                                    placeholder="Retailer First Name"
                                    style={{ width: '100%' }}
                                />
                            </span>
                            <span style={{ width: '100%' }}>
                                <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                    <CheckCircleOutlined
                                        style={{
                                            color: retailerDetails?.lastName ? '#00AA55' : '#696E7C',
                                            backgroundColor: retailerDetails?.lastName ? '#E6F7EE' : '#D9D9D9',
                                            borderRadius: '50%',
                                            padding: '4px'
                                        }}
                                    />
                                    Last Name <span style={{ color: '#FF0000' }}>*</span>
                                </p>
                                <Input
                                    value={retailerDetails?.lastName}
                                    onChange={(e) =>
                                        setRetailerDetails({
                                            ...retailerDetails,
                                            lastName: e.target.value
                                        })
                                    }
                                    placeholder="Retailer Last Name"
                                    style={{ width: '100%' }}
                                />
                            </span>
                        </div>
                        <div
                            className="modal-body-content"
                            style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}
                        >
                            <span style={{ width: '100%', marginRight: '16px' }}>
                                <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                    <CheckCircleOutlined
                                        style={{
                                            color: retailerDetails?.partnerContact ? '#00AA55' : '#696E7C',
                                            backgroundColor: retailerDetails?.partnerContact ? '#E6F7EE' : '#D9D9D9',
                                            borderRadius: '50%',
                                            padding: '4px'
                                        }}
                                    />
                                    Phone Number <span style={{ color: '#FF0000' }}>*</span>
                                </p>
                                <Input
                                    value={retailerDetails.partnerContact}
                                    onChange={(e) => {
                                        const contact = e.target.value;

                                        // Allow only numbers and limit to 10 digits
                                        const numericContact = contact.replace(/\D/g, '').slice(0, 15); // Remove non-numeric characters and limit to 10 digits

                                        setRetailerDetails({
                                            ...retailerDetails,
                                            partnerContact: numericContact // Update state with the numeric value, max 10 digits
                                        });
                                    }}
                                    placeholder="Retailer Phone Number"
                                    style={{ width: '100%' }}
                                />
                            </span>
                        </div>
                        <h3
                            style={{
                                marginBottom: '16px',
                                fontSize: '20px',
                                lineHeight: '30px',
                                fontWeight: 400,
                                fontFamily: 'Poppins'
                            }}
                        >
                            Verify Location
                        </h3>

                        <div className="shop-data">
                            {retailerDetails?.shopData?.map((shop, index) => (
                                <div
                                    key={index}
                                    style={{ border: '1px solid #DDDDDD', padding: '10px', marginBottom: '20px', borderRadius: '10px' }}
                                >
                                    <div
                                        className="modal-body-content"
                                        style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}
                                    >
                                        <span style={{ width: '100%', marginRight: '16px' }}>
                                            <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                                <CheckCircleOutlined
                                                    style={{
                                                        color: shop.shopname ? '#00AA55' : '#696E7C',
                                                        backgroundColor: shop.shopname ? '#E6F7EE' : '#D9D9D9',
                                                        borderRadius: '50%',
                                                        padding: '4px'
                                                    }}
                                                />
                                                Shop name <span style={{ color: '#FF0000' }}>*</span>
                                            </p>
                                            <Input
                                                required
                                                value={shop.shopname}
                                                onChange={(e) => updateShopData(index, 'shopname', e.target.value)}
                                                placeholder="Shop name"
                                                style={{ width: '100%' }}
                                            />
                                        </span>
                                        <span style={{ width: '100%' }}>
                                            <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                                <CheckCircleOutlined
                                                    style={{
                                                        color: shop.shoplocation ? '#00AA55' : '#696E7C',
                                                        backgroundColor: shop.shoplocation ? '#E6F7EE' : '#D9D9D9',
                                                        borderRadius: '50%',
                                                        padding: '4px'
                                                    }}
                                                />
                                                Address
                                            </p>
                                            <span style={{ color: '#696E7C', fontSize: '12px', marginBottom: '12px' }}>
                                                Address will generate automatically from city, neighbourhood, and street
                                            </span>
                                            <Input
                                                disabled
                                                value={shop.shoplocation}
                                                onChange={(e) => updateShopData(index, 'shoplocation', e.target.value)}
                                                placeholder="Address"
                                                style={{ width: '100%' }}
                                            />
                                        </span>
                                    </div>

                                    {/* Latitude and Longitude Fields */}
                                    <div
                                        className="modal-body-content"
                                        style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}
                                    >
                                        <span style={{ width: '100%', marginRight: '16px' }}>
                                            <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                                <CheckCircleOutlined
                                                    style={{
                                                        color: shop.shoplat ? '#00AA55' : '#696E7C',
                                                        backgroundColor: shop.shoplat ? '#E6F7EE' : '#D9D9D9',
                                                        borderRadius: '50%',
                                                        padding: '4px'
                                                    }}
                                                />
                                                Latitude <span style={{ color: '#FF0000' }}>*</span>
                                            </p>
                                            <Input
                                                disabled
                                                value={shop.shoplat}
                                                onChange={(e) => updateShopData(index, 'shoplat', e.target.value)}
                                                placeholder="Latitude"
                                                style={{ width: '100%' }}
                                            />
                                        </span>
                                        <span style={{ width: '100%' }}>
                                            <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                                <CheckCircleOutlined
                                                    style={{
                                                        color: shop.shoplng ? '#00AA55' : '#696E7C',
                                                        backgroundColor: shop.shoplng ? '#E6F7EE' : '#D9D9D9',
                                                        borderRadius: '50%',
                                                        padding: '4px'
                                                    }}
                                                />
                                                Longitude <span style={{ color: '#FF0000' }}>*</span>
                                            </p>
                                            <Input
                                                disabled
                                                value={shop.shoplng}
                                                onChange={(e) => updateShopData(index, 'shoplng', e.target.value)}
                                                placeholder="Longitude"
                                                style={{ width: '100%' }}
                                            />
                                        </span>
                                    </div>

                                    {/* Neighborhood and City Fields */}
                                    <div
                                        className="modal-body-content"
                                        style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}
                                    >
                                        <span style={{ width: '100%', marginRight: '16px' }}>
                                            <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                                <CheckCircleOutlined
                                                    style={{
                                                        color: shop.neighbourhood ? '#00AA55' : '#696E7C',
                                                        backgroundColor: shop.neighbourhood ? '#E6F7EE' : '#D9D9D9',
                                                        borderRadius: '50%',
                                                        padding: '4px'
                                                    }}
                                                />
                                                Route / Neighborhood
                                            </p>

                                            {routes.length > 0 && (
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    value={shop.neighbourhood || null}
                                                    onChange={(value) => handleNeighbourhoodChange(value, index)}
                                                    placeholder="Route / Neighborhood"
                                                    style={{ width: '100%' }}
                                                    options={citiOptions}
                                                    filterOption={(inputValue, option) => {
                                                        const upperInput = inputValue.toUpperCase();
                                                        return option.label.toUpperCase().indexOf(upperInput) !== -1;
                                                    }}
                                                />
                                            )}
                                        </span>
                                        <span style={{ width: '100%' }}>
                                            <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                                <CheckCircleOutlined
                                                    style={{
                                                        color: shop.city ? '#00AA55' : '#696E7C',
                                                        backgroundColor: shop.city ? '#E6F7EE' : '#D9D9D9',
                                                        borderRadius: '50%',
                                                        padding: '4px'
                                                    }}
                                                />
                                                City
                                            </p>
                                            <Input
                                                value={shop.city}
                                                onChange={(e) => updateShopData(index, 'city', e.target.value)}
                                                placeholder="City"
                                                style={{ width: '100%' }}
                                                readOnly
                                            />
                                        </span>
                                    </div>

                                    {/* Street and Additional Description Fields */}
                                    <div
                                        className="modal-body-content"
                                        style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}
                                    >
                                        <span style={{ width: '100%', marginRight: '16px' }}>
                                            <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                                <CheckCircleOutlined
                                                    style={{
                                                        color: shop.street ? '#00AA55' : '#696E7C',
                                                        backgroundColor: shop.street ? '#E6F7EE' : '#D9D9D9',
                                                        borderRadius: '50%',
                                                        padding: '4px'
                                                    }}
                                                />
                                                Street
                                            </p>
                                            <Input
                                                value={shop?.street}
                                                onChange={(e) => updateRetailerDetails(index, 'street', e.target.value)}
                                                placeholder="Street"
                                                style={{ width: '100%' }}
                                            />
                                        </span>

                                        <span style={{ width: '100%' }}>
                                            <p style={{ display: 'flex', justifyContent: 'space-between', fontSize: '18px' }}>
                                                Additional description
                                            </p>
                                            <Input.TextArea
                                                value={shop?.addressDescription}
                                                onChange={(e) => updateRetailerDetails(index, 'addressDescription', e.target.value)}
                                                placeholder="Additional description"
                                                style={{ width: '100%', resize: 'none' }}
                                            />
                                        </span>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        {retailerDetails.shopData.length > 0 && (
                                            <Button
                                                onClick={() => getUserLocation(index)}
                                                style={{
                                                    backgroundColor: 'transperant',
                                                    color: '#FF7410',
                                                    border: '1px solid #FF7410',
                                                    width: 'fit-content',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginRight: '10px'
                                                }}
                                                loading={locationLoading}
                                            >
                                                Get Location
                                            </Button>
                                        )}
                                        {retailerDetails.shopData.length > 1 && (
                                            <Button
                                                onClick={() => removeShop(index)}
                                                style={{
                                                    backgroundColor: '#FF4D4F',
                                                    color: '#fff',
                                                    border: 'none',
                                                    width: 'fit-content',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <CloseOutlined />
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            ))}
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    onClick={() => {
                                        setRetailerDetails((prevDetails) => ({
                                            ...prevDetails,
                                            shopData: [
                                                ...prevDetails.shopData,
                                                {
                                                    shopname: '',
                                                    shoplocation: '',
                                                    shoplat: '',
                                                    shoplng: '',
                                                    street: '',
                                                    neighbourhood: '',
                                                    city: ''
                                                }
                                            ] // New shop object
                                        }));
                                    }}
                                    style={{
                                        width: 'fit-content',
                                        marginTop: '16px',
                                        backgroundColor: '#FF7410',
                                        color: '#fff',
                                        borderRadius: '8px',
                                        border: 'none',
                                        fontSize: '16px',

                                        fontWeight: 500,
                                        marginBottom: '20px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <PlusOutlined /> More shops
                                </Button>
                            </div>
                        </div>

                        <h3
                            style={{
                                marginBottom: '16px',
                                fontSize: '20px',
                                lineHeight: '30px',
                                fontWeight: 400,
                                fontFamily: 'Poppins'
                            }}
                        >
                            Retailer files
                        </h3>
                        <div>
                            <div style={{ width: '100%', marginBottom: '20px' }}>
                                <p style={{ display: 'flex', gap: '6px', fontSize: '16px' }}>
                                    <CheckCircleOutlined
                                        style={{
                                            color: retailerDetails?.shopPhoto ? '#00AA55' : '#000',
                                            backgroundColor: retailerDetails?.shopPhoto ? '#E6F7EE' : '#D9D9D9',
                                            borderRadius: '50%',
                                            padding: '4px'
                                        }}
                                    />
                                    Upload Photo of shop <span style={{ color: '#FF0000' }}>*</span>
                                </p>
                                <Dragger {...props}>
                                    <p className="ant-upload-drag-icon">
                                        {retailerDetails?.shopPhoto ? (
                                            <img
                                                src={retailerDetails?.shopPhoto}
                                                alt="shop"
                                                style={{
                                                    width: '100%',
                                                    height: '300px',
                                                    objectFit: 'contain',
                                                    borderRadius: '8px'
                                                }}
                                            />
                                        ) : (
                                            <InboxOutlined />
                                        )}
                                    </p>
                                    <p className="ant-upload-text">Click to upload</p>
                                    <p className="ant-upload-hint">Maximum file size is 10mb</p>
                                    <p className="ant-upload-hint">
                                        <span style={{ color: '#FF0000' }}>*</span>
                                        The photo must be clear and show the shop name
                                    </p>
                                </Dragger>
                            </div>
                            <div style={{ width: '100%', marginBottom: '20px' }}>
                                <p style={{ display: 'flex', gap: '6px', fontSize: '16px' }}>
                                    <CheckCircleOutlined
                                        style={{
                                            color: retailerDetails?.nationalId ? '#00AA55' : '#000',
                                            backgroundColor: retailerDetails?.nationalId ? '#E6F7EE' : '#D9D9D9',
                                            borderRadius: '50%',
                                            padding: '4px'
                                        }}
                                    />
                                    Upload National ID (Optional)
                                </p>
                                <Dragger {...idProps}>
                                    <p className="ant-upload-drag-icon">
                                        {retailerDetails?.nationalId ? (
                                            <img
                                                src={retailerDetails?.nationalId}
                                                alt="nationalId"
                                                style={{
                                                    width: '100%',
                                                    height: '300px',
                                                    objectFit: 'contain',
                                                    borderRadius: '8px'
                                                }}
                                            />
                                        ) : (
                                            <InboxOutlined />
                                        )}
                                    </p>
                                    <p className="ant-upload-text">Click to upload</p>
                                    <p className="ant-upload-hint">Maximum file size is 10mb</p>
                                </Dragger>
                            </div>

                            <div style={{ width: '100%', marginBottom: '20px' }}>
                                <p style={{ display: 'flex', gap: '6px', fontSize: '16px' }}>
                                    <span style={{ fontWeight: 400, color: '#4B6EFF' }}> Add Comments</span> (Optional)
                                </p>
                                <Input.TextArea
                                    value={retailerDetails.notes}
                                    onChange={(e) => setRetailerDetails({ ...retailerDetails, notes: e.target.value })}
                                    placeholder="Add Comments"
                                    style={{ width: '100%', height: '100px', resize: 'none' }}
                                />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                <Button
                                    onClick={updateRetailer}
                                    style={{
                                        width: '100%',
                                        backgroundColor: '#FF740F',
                                        color: '#fff',
                                        borderRadius: '8px',
                                        border: 'none',
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        fontWeight: 500,
                                        fontFamily: 'Poppins'
                                    }}
                                    disabled={
                                        !retailerDetails.firstName ||
                                        !retailerDetails.lastName ||
                                        retailerDetails.shopData.some(
                                            (shop) =>
                                                !shop.shopname ||
                                                !shop.shoplocation ||
                                                !shop.shoplat ||
                                                !shop.shoplng ||
                                                !shop.street ||
                                                !shop.neighbourhood ||
                                                !shop.city
                                        )
                                    }
                                    loading={loadingUpdate}
                                >
                                    Complete Verification
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default Index;
