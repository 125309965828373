import React from 'react';
import { FileImageOutlined, FilePdfOutlined, AudioOutlined, VideoCameraAddOutlined } from '@ant-design/icons';

const ChatMenuList = ({ chatMenuVisibleMobile, setAccept, setMessageType, setImageModal, setRecordingTime, startRecording }) => {
    return (
        <div className={`chat-menu-list ${chatMenuVisibleMobile ? 'visible' : ''}`}>
            <div
                className="chat-menu-item"
                onClick={() => {
                    setAccept('image/*');
                    setMessageType('image');
                    setImageModal(true);
                }}
                role="button" // Accessible button role
                tabIndex="0"
                onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        setAccept('image/*');
                        setMessageType('image');
                        setImageModal(true);
                    }
                }}
            >
                <span className="chat-menu-item-icon">
                    <FileImageOutlined />
                </span>
                <span className="chat-menu-item-text">Image</span>
            </div>
            <div
                className="chat-menu-item"
                onClick={() => {
                    setAccept('application/pdf');
                    setMessageType('document');
                    setImageModal(true);
                }}
                role="button" // Accessible button role
                tabIndex="0"
                onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        setAccept('application/pdf');
                        setMessageType('document');
                        setImageModal(true);
                    }
                }}
            >
                <span className="chat-menu-item-icon">
                    <FilePdfOutlined />
                </span>
                <span className="chat-menu-item-text">Document</span>
            </div>
            <div
                className="chat-menu-item"
                onClick={() => {
                    setRecordingTime(0);
                    startRecording();
                }}
                onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        setRecordingTime(0);
                        startRecording();
                    }
                }}
                role="button"
                tabIndex="0"
            >
                <span className="chat-menu-item-icon">
                    <AudioOutlined />
                </span>
                <span className="chat-menu-item-text">Audio</span>
            </div>
            <div
                className="chat-menu-item"
                onClick={() => {
                    setAccept('application/video');
                    setMessageType('video');
                    setImageModal(true);
                }}
                onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        setRecordingTime(0);
                        startRecording();
                    }
                }}
                role="button"
                tabIndex="0"
            >
                <span className="chat-menu-item-icon">
                    <VideoCameraAddOutlined />
                </span>
                <span className="chat-menu-item-text">Video</span>
            </div>
        </div>
    );
};

export default ChatMenuList;
