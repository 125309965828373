export const RETAILER_BEGIN = 'RETAILER_BEGIN';
export const RETAILER_SUCCESS = 'RETAILER_SUCCESS;';

export const RETAILER_INFO_BEGIN = 'RETAILER_INFO_BEGIN';
export const RETAILER_INFO_SUCCESS = 'RETAILER_INFO_SUCCESS';
export const RETAILER_INFO_ERROR = 'RETAILER_INFO_ERROR';

export const FILTER_RETAILER_INFO_BEGIN = 'FILTER_RETAILER_INFO_BEGIN';
export const FILTER_RETAILER_INFO_SUCCESS = 'FILTER_RETAILER_INFO_SUCCESS';
export const FILTER_RETAILER_INFO_ERROR = 'FILTER_RETAILER_INFO_ERROR';

export const RETAILER_CHART_BEGIN = 'RETAILER_CHART_BEGIN';
export const RETAILER_CHART_SUCCESS = 'RETAILER_CHART_SUCCESS;';
