import './mira.css';
import {
    ArrowLeftOutlined,
    CloseOutlined,
    EditOutlined,
    InboxOutlined,
    MinusOutlined,
    PlusOutlined,
    SendOutlined
} from '@ant-design/icons';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import io from 'socket.io-client';
import { Button, Input, Menu, Modal, Select, Table, Upload } from 'antd';
import ApiUrl from '../../network';

import { calculateChatExpiration } from './calculateChatExpiration';
import SenderList from './patials/SenderList';
import ChatBoxHeader from './patials/ChatBoxHeader';
import ChatBoxFooter from './patials/ChatBoxFooter';
import ChatMenuList from './patials/ChatMenuList';
import ChatBoxMessage from './patials/ChatBoxMessage'; // WebSocket endpoint
import { fetchCustomer, fetchUserMessages, fetchUsers, updateUsersLastMessageTime } from './services/userService';
// eslint-disable-next-line import/named
import { imageUploadRequest, sendMessage, transformMessage } from './services/messageService';
import { sendAudioFile, startRecording, stopRecording, uploadAudio } from './services/audioService';
import { closePopover, hideSenderList, showSenderList, menuItems, getReplyMenuItems } from './utils/util';
import MediaOptions from './patials/MediaOptions';
import useChatState from './hooks/useChatState';
import { fetchPartType, fetchProducts, placeOrderFunction } from './services/orderService';

const miraURL = ApiUrl.MiraUrl;
const country = localStorage.getItem('country') || 'Ghana';
const baseUrl = `${miraURL}/api/v1/whatsapp`; // `${miraURL}/api/v1/whatsapp`; // 'https://mira.garagemobility.com/api/v1/whatsapp'; // ; ;
const socketUrl = `${miraURL}/`;

const logo = '../../garagelogo.png';
const responseTime = '';

export const Index = () => {
    const {
        users,
        setUsers,
        open,
        setOpen,
        loading,
        setLoading,
        placeOrderLoading,
        setPlaceOrderLoading,
        receiptLoading,
        setReceiptLoading,
        selectedUser,
        setSelectedUser,
        messages,
        setMessages,
        message,
        setMessage,
        socket,
        setSocket,
        messageType,
        setMessageType,
        imageUrl,
        setImageUrl,
        fileList,
        setFileList,
        replyTo,
        setReplyTo,
        switchChat,
        phoneNumber,
        setPhoneNumber,
        chatBoxRef,
        imageModal,
        setImageModal,
        replyType,
        setReplyType,
        accept,
        setAccept,
        isSenderListVisible,
        setSenderListVisible,
        isChatBoxVisible,
        setChatBoxVisible,
        searchTerm,
        setSearchTerm,
        chatMenuVisible,
        setChatMenuVisible,
        chatMenuVisibleMobile,
        setChatMenuVisibleMobile,
        chatFilter,
        setChatFilter,
        placeOrder,
        setPlaceOrder,
        selectedProducts,
        setSelectedProducts,
        products,
        setProducts,
        product,
        setProduct,
        productType,
        setProductType,
        tab,
        setTab,
        customerName,
        setCustomerName,
        customerPhone,
        setCustomerPhone,
        customerLocation,
        setCustomerLocation,
        customerId,
        setCustomerId,
        orderNo,
        setOrderNo,
        customerLoader,
        setCustomerLoader,
        filteredUsers,
        setFilteredUsers,
        isRecording,
        setIsRecording,
        recordingTime,
        setRecordingTime,
        mediaRecorderRef,
        recordingTimerRef,
        audioModal,
        setAudioModal,
        audioPreviewUrl,
        setAudioPreviewUrl,
        setAudioChunks,
        audioFile,
        setAudioFile,
        fileInputRef,
        expiredAt,
        setExpiredAt,
        timeRemaining,
        setTimeRemaining,
        isAtBottom,
        page,
        setPage,
        setAllMessagesLoaded
    } = useChatState();

    useEffect(() => {
        const loadUsers = async () => {
            setLoading(true); // Optional: Set loading to true when fetching starts

            try {
                const [data, productList, partTypes] = await Promise.all([
                    fetchUsers(country), // Fetch users by country
                    fetchProducts(), // Fetch products
                    fetchPartType() // Fetch part types
                ]);

                setUsers(data); // Set the fetched users
                setProducts(productList);
                setProductType(partTypes?.parttypes);
            } catch (error) {
                toast.error('Failed to load users and data', error.message);
            } finally {
                setLoading(false); // Set loading to false once fetching is complete
            }
        };

        loadUsers().then((r) => r);
        fetchProducts().then((r) => r);
        fetchPartType().then((r) => r);
    }, []); // Add country as a dependency if it can change

    const setupSocket = () => {
        const newSocket = io(socketUrl, {
            transports: ['websocket'],
            path: '/socket.io'
        });

        newSocket.on('connect', () => {
            // console.log('Connected');
        });

        newSocket.on('mira_chat', (data) => {
            // Transform and update message
            const transformedMessage = transformMessage(data);
            setMessages((prevMessages) => [...prevMessages, transformedMessage]);

            // Update user's last message timestamp
            updateUsersLastMessageTime(setUsers, data);
        });

        newSocket.on('mira_status', (data) => {
            // Extract necessary details from the data object
            // eslint-disable-next-line camelcase
            const { whatsapp_id, phone, status } = data;

            // Update the message delivery status based on whatsapp_id
            setMessages((prevMessages) =>
                prevMessages.map(
                    (message) =>
                        // eslint-disable-next-line camelcase
                        message.whatsapp_id === whatsapp_id || message.phone === phone
                            ? { ...message, delivery: status } // Update only the delivery status
                            : message // Return unchanged messages
                )
            );
        });

        newSocket.on('disconnect', () => {
            // console.log('Socket.IO disconnected');
        });

        newSocket.on('connect_error', (error) => {
            // console.log('Socket.IO connect_error:', error);
        });

        setSocket(newSocket);
    };

    const handleSendMessage = () => {
        // Send the message and get the transformed message object
        const newMessage = sendMessage(
            socket,
            message,
            messageType, // Adjust the message type as needed
            selectedUser,
            replyTo,
            imageUrl
        );

        if (newMessage) {
            // Update messages state with the transformed message
            setMessages((prevMessages) => [...prevMessages, newMessage]);

            // Clear input and reset any necessary state
            setMessage('');
            setImageUrl([]);
            setFileList([]);
            setImageModal(false);
            setReplyTo({ _id: null, profile: null, content: null });

            // Clear file input (Optional)
            if (fileInputRef.current) {
                fileInputRef.current.value = ''; // Reset the file input
            }
        }
    };

    const scrollToBottom = () => {
        if (chatBoxRef.current) {
            if (window.innerWidth > 768) {
                // For larger screens, scroll to the bottom
                chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
            } else {
                // On mobile, directly show the last message without smooth scrolling
                const lastMessageElement = chatBoxRef.current.lastElementChild;
                if (lastMessageElement) {
                    lastMessageElement.scrollIntoView({ block: 'end', behavior: 'auto' });
                }
            }
        }
    };

    const handleFetchUserMessages = async (phone, index, page) => {
        try {
            // Fetch user messages from the server with the given phone number, index, and page
            const { userWithImage, sortedMessages, expiresAt } = await fetchUserMessages(phone, index, page);

            if (sortedMessages.length === 0 && page >= userWithImage?.totalPages) {
                setAllMessagesLoaded(true); // If no messages and we've reached the last page, stop fetching
                return;
            }

            // If userWithImage is found, update the selected user, expiration time, and messages state
            if (userWithImage) {
                setSelectedUser(userWithImage);
                setExpiredAt(expiresAt);

                setMessages((prevMessages) => {
                    // Filter out messages already in the state
                    const newMessages = sortedMessages.filter(
                        (newMessage) => !prevMessages.some((prevMessage) => prevMessage._id === newMessage._id)
                    );
                    // Append new messages and sort by createdAt
                    const appendMessages = [...newMessages, ...prevMessages];

                    return appendMessages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
                });
            }
        } catch (error) {
            // console.error('Error fetching messages:', error);
        } finally {
            setLoading(false); // Reset loading state after fetch
        }
    };

    useEffect(() => {
        if (phoneNumber.phone) {
            handleFetchUserMessages(phoneNumber.phone, phoneNumber.index, page);
        }
    }, [phoneNumber.phone, switchChat, page]); // Include page in dependency array

    const handleScroll = (e) => {
        const { scrollTop, scrollHeight } = e.target;

        if (scrollTop === 0) {
            const oldScrollHeight = scrollHeight; // Store the previous scroll height

            setPage((prevPage) => prevPage + 1); // Load the next page (older messages)

            // Create a MutationObserver to detect changes in the scroll height
            const observer = new MutationObserver(() => {
                const newScrollHeight = e.target.scrollHeight; // Get the new scroll height
                e.target.scrollTop = newScrollHeight - oldScrollHeight; // Adjust the scroll position

                // Disconnect the observer once the adjustment is done
                observer.disconnect();
            });

            // Start observing the target element for changes in child elements
            observer.observe(e.target, { childList: true, subtree: true });
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]); // Trigger scroll when new messages are added or chat is switched

    useEffect(() => {
        fetchUsers().then((r) => r);
    }, [chatFilter]);

    useEffect(() => {
        setupSocket();

        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, []); // Empty dependency array means this effect runs once

    const actionContent = (message) => {
        return (
            <Menu
                style={{ textAlign: 'center', width: 'fit-content' }}
                items={getReplyMenuItems(message, setReplyType, setReplyTo, setOpen)}
            />
        );
    };

    const props = {
        name: 'file',
        multiple: true,
        accept,
        customRequest: ({ file, onSuccess, onError }) => {
            // Your image upload request logic
            imageUploadRequest(file, setFileList, setImageUrl, setLoading).then(onSuccess).catch(onError);
        },
        onChange(info) {
            const { status } = info.file;
            if (status === 'done') {
                // Update file list when upload is successful
            } else if (status === 'error') {
                // Handle file upload error
                // console.log(`${info.file.name} file upload failed.`);
            }
        },
        onRemove: (file) => {
            // Remove the file from the file list when user removes it
            setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
        },
        fileList // Pass the fileList to the Upload component for control
    };

    // Handle resizing for mobile screens
    const handleResize = () => {
        if (window.innerWidth < 768) {
            if (isChatBoxVisible) {
                setSenderListVisible(false);
                document.querySelector('.MuiPaper-root')?.style.setProperty('display', 'none');
                if (isAtBottom) {
                    scrollToBottom();
                }
            } else {
                setSenderListVisible(true);
                document.querySelector('.MuiPaper-root')?.style.setProperty('display', 'flex');
            }
        } else {
            setSenderListVisible(true);
            setChatBoxVisible(true);
            document.querySelector('.MuiPaper-root')?.style.setProperty('display', 'flex');
            if (isAtBottom) {
                scrollToBottom();
            }
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check
        scrollToBottom();
        return () => window.removeEventListener('resize', handleResize);
    }, [isChatBoxVisible, isAtBottom]);

    // Filter user based on search term and chat filter
    useEffect(() => {
        const applyFilters = () => {
            const filtered = users.filter((user) => {
                const matchesSearchTerm = user.profile.toLowerCase().includes(searchTerm.toLowerCase());
                const lastChat = user.last_chat;

                const isUnread = lastChat && !lastChat.read;

                switch (chatFilter) {
                    case 'all':
                        return matchesSearchTerm;
                    case 'read':
                        return matchesSearchTerm && lastChat && lastChat.read;
                    case 'unread':
                        return matchesSearchTerm && isUnread;
                    default:
                        return false;
                }
            });

            setFilteredUsers(filtered);
        };

        applyFilters();
    }, [chatFilter, searchTerm, users]);

    const handleMenuClick = async (e) => {
        if (e.key === 'customer-cart') {
            window.location.href = '/mira/orders';
        } else if (e.key === 'place-order') {
            setPlaceOrder(true);
        } else if (e.key === 'customer-profile') {
            try {
                toast.loading('Fetching customer data...'); // Show loading toast
                const response = await fetchCustomer(phoneNumber.phone, setCustomerLoader); // Ensure the fetch call is successful

                toast.dismiss(); // Dismiss the loading toast

                // Assuming the customerId comes from the response, adjust based on your API response
                if (response && response._id) {
                    window.open(`/retailer/profile/${response._id}`, '_blank'); // Open profile in a new tab
                } else {
                    toast.dismiss(); // Dismiss the loading toast
                    toast.error('New customer, no profile found'); // Handle new customer
                }
            } catch (error) {
                toast.dismiss(); // Dismiss the loading toast
                toast.error('Failed to fetch customer data'); // Handle fetch errors
            }
        } else if (e.key === 'assign-task') {
            window.location.href = '/mira/tasks';
        }
    };

    const menu = <Menu onClick={handleMenuClick} style={{ textAlign: 'center', width: '200px' }} items={menuItems} />;

    const computeFinalQty = (qty, partQtyInPieces, partType) => {
        let finalQty = qty;
        let finalPartType = partType;

        if (partQtyInPieces && partType?.toLowerCase() !== 'pieces') {
            finalQty = qty * partQtyInPieces;
            finalPartType = 'Pieces';
        } else if (partQtyInPieces && partType?.toLowerCase() === 'pieces') {
            finalQty = qty;
            finalPartType = 'Pieces';
        } else {
            finalPartType = 'Pieces';
        }

        return { finalQty, finalPartType };
    };

    useEffect(() => {
        // Update the countdown every second
        const intervalId = setInterval(() => {
            setTimeRemaining(calculateChatExpiration(expiredAt));
        }, 2000);

        // Clear the interval when the component is unmounted or expiredAt changes
        return () => clearInterval(intervalId);
    }, [expiredAt]);

    return (
        <div className="chat-inbox-container">
            {isSenderListVisible && (
                <SenderList
                    loading={loading}
                    filteredUsers={filteredUsers}
                    chatFilter={chatFilter}
                    setChatFilter={setChatFilter}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    messages={messages}
                    setSelectedUser={setSelectedUser}
                    selectedUser={selectedUser}
                    setPhoneNumber={setPhoneNumber}
                    fetchUserMessages={handleFetchUserMessages}
                    fetchUsers={fetchUsers}
                    hideSenderList={() => hideSenderList(setSenderListVisible, setChatBoxVisible, scrollToBottom)}
                />
            )}

            {selectedUser && isChatBoxVisible && (
                <div className="chatBox">
                    <ChatBoxHeader
                        selectedUser={selectedUser}
                        showSenderList={() => showSenderList(setSenderListVisible, setChatBoxVisible)}
                        expiredAt={expiredAt}
                        timeRemaining={timeRemaining}
                        menu={menu}
                    />

                    <div className="chat-box-body">
                        <ChatBoxMessage
                            handleScroll={handleScroll}
                            messages={messages}
                            chatBoxRef={chatBoxRef}
                            phoneNumber={phoneNumber}
                            actionContent={actionContent}
                            open={open}
                            replyType={replyType}
                            replyTo={replyTo}
                            setReplyTo={setReplyTo}
                        />

                        <ChatMenuList
                            chatMenuVisibleMobile={chatMenuVisibleMobile}
                            setAccept={setAccept}
                            setMessageType={setMessageType}
                            setImageModal={setImageModal}
                            setRecordingTime={setRecordingTime}
                            startRecording={startRecording}
                        />

                        <ChatBoxFooter
                            message={message}
                            setMessage={setMessage}
                            sendMessage={handleSendMessage}
                            chatMenuVisible={chatMenuVisible}
                            setChatMenuVisible={setChatMenuVisible}
                            chatMenuVisibleMobile={chatMenuVisibleMobile}
                            setChatMenuVisibleMobile={setChatMenuVisibleMobile}
                            mediaContent={() => (
                                <MediaOptions
                                    setAccept={setAccept}
                                    setMessageType={setMessageType}
                                    setImageModal={setImageModal}
                                    closePopover={() => closePopover(setOpen)}
                                    startRecording={() =>
                                        startRecording(
                                            setAudioModal,
                                            setAudioChunks,
                                            setIsRecording,
                                            setRecordingTime,
                                            mediaRecorderRef,
                                            recordingTimerRef
                                        )
                                    }
                                />
                            )}
                        />
                    </div>
                </div>
            )}
            <Modal
                open={imageModal}
                onCancel={() => {
                    setImageModal(false);
                    setImageUrl([]);
                    setFileList([]);
                }}
                footer={null}
                width={600}
            >
                <div style={{ padding: '20px' }}>
                    <Upload.Dragger {...props} style={{ marginBottom: '10px' }}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">Support for single or bulk upload.</p>
                    </Upload.Dragger>
                    <div style={{ marginBottom: '30px', marginTop: '30px' }}>
                        {imageUrl?.map((url, index) => {
                            const fileExtension = url.split('.').pop().toLowerCase();

                            // Handle image, video, and PDF previews based on file type
                            if (['jpg', 'jpeg', 'png', 'gif', 'blob'].includes(fileExtension) || messageType === 'image') {
                                return (
                                    <img
                                        key={index}
                                        src={url}
                                        alt={`uploaded-${index}`}
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            objectFit: 'contain',
                                            marginRight: '10px'
                                        }}
                                    />
                                );
                            }
                            if (['mp4', 'webm', 'ogg', 'mov'].includes(fileExtension)) {
                                return (
                                    <video
                                        key={index}
                                        controls
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            objectFit: 'contain',
                                            marginRight: '10px'
                                        }}
                                    >
                                        <source src={url} type="video/mp4" />
                                        <source src={url} type="video/webm" />
                                        <source src={url} type="video/ogg" />
                                        <source src={url} type="video/mov" />
                                        {/* Track for captions */}
                                        <track kind="captions" srcLang="en" label="English captions" default />
                                    </video>
                                );
                            }
                            if (fileExtension === 'pdf') {
                                return (
                                    <iframe
                                        key={index}
                                        src={url}
                                        title={`uploaded-pdf-${index}`}
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            objectFit: 'contain',
                                            marginRight: '10px',
                                            border: '1px solid #ccc'
                                        }}
                                    />
                                );
                            }
                            return <p key={index}>Unsupported file type: {fileExtension}</p>;
                        })}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: 'calc(89% - 20px)',
                            padding: '10px',
                            position: 'absolute',
                            bottom: '0',
                            marginBottom: '10px',
                            marginTop: '50px',
                            paddingTop: '10px',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            border: '1px solid #f0f0f0'
                        }}
                    >
                        <input
                            style={{
                                width: 'calc(100% - 50px)',
                                padding: '5px',
                                borderRadius: '5px',
                                border: 'none',
                                outline: 'none',
                                height: '30px'
                            }}
                            type="text"
                            placeholder="Type a message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                        />
                        <button
                            type="button"
                            onClick={() => {
                                handleSendMessage();
                            }}
                            style={{
                                backgroundColor: '#F6F6F6',
                                color: '#7B808C',
                                border: 'none',
                                padding: '10px 20px',
                                borderRadius: '100px',
                                marginLeft: '10px',
                                cursor: 'pointer',
                                fontSize: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <SendOutlined />
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal open={placeOrder} onCancel={() => setPlaceOrder(false)} footer={null} width={800}>
                <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                    <div className="order-modal-header" style={{ marginBottom: '20px' }}>
                        <span>
                            {tab === 'products' && <h3>Place pro-forma</h3>}
                            {tab === 'place-order' && (
                                <div>
                                    <span
                                        role="button"
                                        tabIndex={0}
                                        onClick={() => setTab('products')}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' || e.key === ' ') {
                                                setTab('place-order');
                                            }
                                        }}
                                        style={{
                                            width: 'fit-content',
                                            backgroundColor: 'transparent',
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <ArrowLeftOutlined style={{ fontSize: '30px', marginRight: '5px' }} />
                                        <h3>Place pro-forma</h3>
                                    </span>
                                </div>
                            )}
                            {tab === 'customer-details' && (
                                <span
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => setTab('place-order')}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' || e.key === ' ') {
                                            setTab('place-order');
                                        }
                                    }}
                                    style={{
                                        width: 'fit-content',
                                        backgroundColor: 'transparent',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ArrowLeftOutlined style={{ fontSize: '30px', marginRight: '5px' }} />
                                    <h3>Customer details</h3>
                                </span>
                            )}
                            {/* {tab === 'order-receipt' && <h3>Order receipt</h3>} */}
                            {tab === 'products' && <p>Search for an item and place order</p>}
                        </span>
                        {tab === 'products' && (
                            <span>
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="Search for Item"
                                    style={{ width: '200px' }}
                                    options={products?.map((product) => ({
                                        value: product._id,
                                        label: product.sku
                                    }))}
                                    filterOption={(inputValue, option) => {
                                        // This function is used to filter options in a Select component
                                        // It compares the input value with the label of each option

                                        // Convert both the input and the option label to uppercase for case-insensitive comparison
                                        const upperInput = inputValue.toUpperCase();

                                        // Check if the option label is a string
                                        if (typeof option.label === 'string') {
                                            // If it's a string, directly check if the uppercase input is included in the uppercase label
                                            return option.label.toUpperCase().indexOf(upperInput) !== -1;
                                        }

                                        // If the label is not a string (e.g., it might be a number or other type)
                                        // Convert it to a string before comparing
                                        return String(option.label).toUpperCase().indexOf(upperInput) !== -1;
                                    }}
                                    value={product}
                                    onChange={(value) => {
                                        setProduct(value);

                                        const product = products.find((product) => product._id === value);
                                        if (product) {
                                            setSelectedProducts([...selectedProducts, product]);
                                        } else {
                                            //  console.log('Product not found for value:', value);
                                        }
                                    }}
                                />
                            </span>
                        )}
                    </div>
                    <div className="order-modal-body table-responsive">
                        {tab === 'products' ? (
                            <Table
                                rowKey={(row) => row._id}
                                dataSource={selectedProducts}
                                columns={[
                                    {
                                        title: 'Product image',
                                        dataIndex: 'partsImage',
                                        key: 'partsImage',
                                        render: (partsImage) => (
                                            <img
                                                src={partsImage}
                                                alt="product"
                                                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                            />
                                        )
                                    },
                                    {
                                        title: 'Product name',
                                        dataIndex: 'sku',
                                        key: 'sku'
                                    },
                                    {
                                        title: 'Product price',
                                        dataIndex: 'garagePrice',
                                        key: 'garagePrice'
                                    },

                                    {
                                        title: 'Action',
                                        dataIndex: 'action',
                                        key: 'action',
                                        render: (_, record) => (
                                            <Button
                                                type="primary"
                                                danger
                                                onClick={() => {
                                                    const updatedProducts = selectedProducts.filter((p) => p._id !== record._id);
                                                    setSelectedProducts(updatedProducts);
                                                }}
                                            >
                                                <CloseOutlined />
                                            </Button>
                                        )
                                    }
                                ]}
                            />
                        ) : tab === 'place-order' ? (
                            <div>
                                {selectedProducts.map((product) => {
                                    // Calculate final quantity and part type once per product
                                    const { finalQty, finalPartType } = computeFinalQty(
                                        product.qty || 0,
                                        product.partQtyInPieces,
                                        product.partType || ''
                                    ); // Compute for each product

                                    return (
                                        <div
                                            style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '20px' }}
                                            key={product._id}
                                        >
                                            <div
                                                style={{
                                                    width: '140px',
                                                    height: '140px',
                                                    objectFit: 'cover',
                                                    backgroundColor: '#CED4D7',
                                                    borderRadius: '10px',
                                                    padding: '5px'
                                                }}
                                            >
                                                <img
                                                    src={product.partsImage}
                                                    alt="product"
                                                    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px' }}
                                                />
                                            </div>
                                            <div>
                                                <p style={{ fontSize: '14px', marginBottom: '2px', paddingBottom: '0px' }}>
                                                    <strong>Product name:</strong> {product.sku}
                                                </p>
                                                <p style={{ fontSize: '14px', marginBottom: '2px', paddingBottom: '0px' }}>
                                                    <strong>Product price:</strong> {product.garagePrice}
                                                </p>

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '10px',
                                                        marginTop: '10px'
                                                    }}
                                                >
                                                    <span>
                                                        <strong>Part type:</strong>{' '}
                                                        <Select
                                                            showSearch
                                                            allowClear
                                                            placeholder="Select part type"
                                                            options={productType?.map((type) => ({
                                                                value: type.name,
                                                                label: type.name
                                                            }))}
                                                            value={product.partType} // Using finalPartType here
                                                            onChange={(value) => {
                                                                const updatedProducts = selectedProducts.map((p) =>
                                                                    p._id === product._id ? { ...p, partType: value } : p
                                                                );
                                                                setSelectedProducts(updatedProducts);
                                                            }}
                                                            filterOption={(input, option) =>
                                                                option.label.toLowerCase().startsWith(input.toLowerCase())
                                                            }
                                                            style={{ width: '100px', marginLeft: '40px' }}
                                                        />
                                                    </span>
                                                </div>

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '10px',
                                                        marginTop: '10px'
                                                    }}
                                                >
                                                    <span>
                                                        <strong>Quantity</strong>
                                                    </span>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '10px',
                                                            marginLeft: '40px'
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                cursor: 'pointer',
                                                                backgroundColor: '#193B56',
                                                                padding: '5px',
                                                                borderRadius: '5px'
                                                            }}
                                                            onClick={() => {
                                                                const updatedProducts = selectedProducts.map((p) =>
                                                                    p._id === product._id
                                                                        ? {
                                                                              ...p,
                                                                              qty: Math.max(0, (p.qty || 0) - 1)
                                                                          }
                                                                        : p
                                                                );
                                                                setSelectedProducts(updatedProducts);
                                                            }}
                                                            role="button"
                                                            tabIndex={0}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter' || e.key === ' ') {
                                                                    const updatedProducts = selectedProducts.map((p) =>
                                                                        p._id === product._id
                                                                            ? {
                                                                                  ...p,
                                                                                  qty: Math.max(0, (p.qty || 0) - 1)
                                                                              }
                                                                            : p
                                                                    );
                                                                    setSelectedProducts(updatedProducts);
                                                                }
                                                            }}
                                                        >
                                                            <MinusOutlined style={{ color: '#fff' }} />
                                                        </span>
                                                        <span>{product.qty || 0}</span> {/* Using finalQty here */}
                                                        <span
                                                            style={{
                                                                cursor: 'pointer',
                                                                backgroundColor: '#193B56',
                                                                padding: '5px',
                                                                borderRadius: '5px'
                                                            }}
                                                            onClick={() => {
                                                                const updatedProducts = selectedProducts.map((p) =>
                                                                    p._id === product._id
                                                                        ? {
                                                                              ...p,
                                                                              qty: (p.qty || 0) + 1
                                                                          }
                                                                        : p
                                                                );
                                                                setSelectedProducts(updatedProducts);
                                                            }}
                                                            role="button"
                                                            tabIndex={0}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter' || e.key === ' ') {
                                                                    const updatedProducts = selectedProducts.map((p) =>
                                                                        p._id === product._id
                                                                            ? {
                                                                                  ...p,
                                                                                  qty: (p.qty || 0) + 1
                                                                              }
                                                                            : p
                                                                    );
                                                                    setSelectedProducts(updatedProducts);
                                                                }
                                                            }}
                                                        >
                                                            <PlusOutlined style={{ color: '#fff' }} />
                                                        </span>
                                                    </div>
                                                </div>
                                                <p style={{ color: '#FF740F', marginTop: '10px' }}>
                                                    Converted Quantity: {finalQty} {finalPartType}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : tab === 'customer-details' ? (
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }} />
                                {customerLoader ? (
                                    <div style={{ textAlign: 'center', margin: '20px 0' }}>
                                        <p style={{ fontSize: '16px', marginBottom: '10px' }}>Loading customer details...</p>
                                    </div>
                                ) : (
                                    <div>
                                        <div style={{ marginBottom: '20px' }}>
                                            <p style={{ fontSize: '14px', marginBottom: '5px' }}>Retailer’s name</p>
                                            <Input
                                                placeholder="Customer name"
                                                style={{ width: '100%', padding: '10px' }}
                                                value={customerName}
                                                onChange={(e) => setCustomerName(e.target.value)}
                                            />
                                        </div>
                                        <div style={{ marginBottom: '20px' }}>
                                            <p style={{ fontSize: '14px', marginBottom: '5px' }}>Phone number</p>
                                            <Input
                                                placeholder="Phone number"
                                                style={{ width: '100%', padding: '10px' }}
                                                value={customerPhone}
                                                onChange={(e) => setCustomerPhone(e.target.value)}
                                            />
                                        </div>
                                        <div style={{ marginBottom: '20px' }}>
                                            <p style={{ fontSize: '14px', marginBottom: '5px' }}>Location</p>
                                            <Input
                                                placeholder="Location"
                                                style={{ width: '100%', padding: '10px' }}
                                                value={customerLocation}
                                                onChange={(e) => setCustomerLocation(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : tab === 'order-receipt' ? (
                            <div className="order-receipt-content">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <div className="logo">
                                        <img src={logo} alt="logo" style={{ width: '150px', height: '50px', objectFit: 'contain' }} />
                                    </div>
                                    <div className="order-receipt-header">
                                        <h1 style={{ fontSize: '18px' }}>Garage Mobility LTD</h1>
                                        <a href="mailto:info@garagemobility.com">info@garagemobility.com</a>
                                    </div>
                                </div>
                                <hr />
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginTop: '20px',
                                        marginBottom: '20px'
                                    }}
                                >
                                    <div>
                                        <h1 style={{ fontSize: '14px' }}>INVOICE TO:</h1>
                                        <p style={{ fontSize: '14px' }}>
                                            <strong>Customer Name:</strong> {customerName}
                                        </p>
                                        <p style={{ fontSize: '14px' }}>
                                            <strong>Phone Number:</strong> {customerPhone}
                                        </p>
                                        <p style={{ fontSize: '14px' }}>
                                            <strong>Location:</strong> {customerLocation}
                                        </p>
                                    </div>
                                    <div>
                                        <h1 style={{ fontSize: '14px' }}>
                                            <strong>Order No:</strong> {orderNo}
                                        </h1>
                                        <p style={{ fontSize: '14px' }}>
                                            <strong>DateTime:</strong> {new Date().toLocaleString()}
                                        </p>
                                        <p style={{ fontSize: '14px' }}>
                                            <strong>Relationship Manager:</strong> {localStorage.getItem('userFirstName')}{' '}
                                            {localStorage.getItem('userLastName')}
                                        </p>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table style={{ width: '100%' }}>
                                        <thead style={{ backgroundColor: '#1A3855', color: '#fff', width: '100%' }}>
                                            <tr style={{ fontSize: '14px', fontWeight: 'bold', width: '100%' }}>
                                                <th>ITEM DESCRIPTION</th>
                                                <th>QTY</th>
                                                <th>PART TYPE</th>
                                                <th>AMOUNT</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ fontSize: '14px' }}>
                                            {selectedProducts.map((product) => {
                                                const { finalQty, finalPartType } = computeFinalQty(
                                                    product.qty || 0,
                                                    product.partQtyInPieces,
                                                    product.partType || ''
                                                );
                                                product.qty = finalQty;
                                                product.partType = finalPartType;
                                                return (
                                                    <tr key={product._id}>
                                                        <td>{product.sku}</td>
                                                        <td>{finalQty}</td>
                                                        <td>{finalPartType}</td>
                                                        <td>{(product.garagePrice * finalQty).toFixed(2)}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <tr style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                <td colSpan={3} style={{ fontWeight: 'bold' }}>
                                                    Total Payable:
                                                </td>
                                                <td style={{ fontWeight: 'bold' }}>
                                                    {selectedProducts
                                                        .reduce((totalAmount, product) => {
                                                            const { finalQty } = computeFinalQty(
                                                                product.qty || 0,
                                                                product.partQtyInPieces,
                                                                product.partType || ''
                                                            );
                                                            return totalAmount + product.garagePrice * finalQty;
                                                        }, 0)
                                                        .toFixed(2)}
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="order-modal-footer" style={{ marginTop: '20px' }}>
                        {selectedProducts.length > 0 && (
                            <>
                                {tab === 'products' && (
                                    <Button className="order-modal-footer-btn" onClick={() => setTab('place-order')}>
                                        Continue
                                    </Button>
                                )}
                                {tab === 'place-order' && (
                                    <Button
                                        disabled={selectedProducts.some((product) => !product.qty || !product.partType)}
                                        className="order-modal-footer-btn"
                                        onClick={() => {
                                            setTab('customer-details');
                                            setCustomerLoader(true); // Set loading state to true

                                            fetchCustomer(phoneNumber.phone, setCustomerLoader)
                                                .then((result) => {
                                                    setCustomerLoader(false); // Set loading state to false after fetching

                                                    if (!result || result.length === 0) {
                                                        toast.info('Customer not in our system');
                                                        setCustomerPhone(phoneNumber?.phone);
                                                    } else {
                                                        toast.success('Customer fetched successfully');
                                                        setCustomerId(result?._id);
                                                        setCustomerName(result?.partnerName);
                                                        setCustomerPhone(result?.partnerContact);
                                                        setCustomerLocation(result?.location);
                                                    }
                                                })
                                                .catch((error) => {
                                                    setCustomerLoader(false); // Set loading state to false on error
                                                    toast.error('Error fetching customer details'); // Handle error appropriately
                                                });
                                        }}
                                    >
                                        Continue
                                    </Button>
                                )}
                                {tab === 'customer-details' && (
                                    <Button
                                        disabled={!customerName || !customerPhone || !customerLocation}
                                        loading={placeOrderLoading}
                                        className="order-modal-footer-btn"
                                        onClick={() => setTab('order-receipt')}
                                    >
                                        Continue
                                    </Button>
                                )}
                                {tab === 'order-receipt' && (
                                    <div style={{ display: 'flex', textAlign: 'center' }}>
                                        <Button
                                            className="order-modal-footer-btn"
                                            onClick={async () => {
                                                try {
                                                    setReceiptLoading(true);

                                                    const orderResponse = await placeOrderFunction(
                                                        setPlaceOrderLoading,
                                                        customerId,
                                                        customerName,
                                                        customerPhone,
                                                        customerLocation,
                                                        selectedProducts,
                                                        setOrderNo,
                                                        setTab
                                                    );

                                                    if (!orderResponse || !orderResponse.order_id) {
                                                        toast.error('Failed to place pro-forma');
                                                    }
                                                    const orderId = orderResponse.order_id;

                                                    // Import html2pdf
                                                    const html2pdf = (await import('html2pdf.js')).default;

                                                    // Get the content to be converted to PDF
                                                    const content = document.querySelector('.order-receipt-content');

                                                    // Generate PDF using html2pdf
                                                    const opt = {
                                                        margin: 1,
                                                        filename: 'order_receipt.pdf',
                                                        image: { type: 'jpeg', quality: 0.98 },
                                                        html2canvas: { scale: 2, useCORS: true },
                                                        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
                                                    };

                                                    // Generate PDF and get blob
                                                    const pdfBlob = await new Promise((resolve, reject) => {
                                                        html2pdf()
                                                            .set(opt)
                                                            .from(content)
                                                            .toPdf()
                                                            .outputPdf('blob')
                                                            .then(resolve)
                                                            .catch(reject);
                                                    });

                                                    // Create a File object from the PDF blob
                                                    const pdfFile = new File([pdfBlob], 'proforma.pdf', { type: 'application/pdf' });

                                                    // Create an object with the required properties
                                                    const fileObject = new File([pdfBlob], 'proforma.pdf', {
                                                        type: 'application/pdf',
                                                        lastModified: Date.now()
                                                    });
                                                    fileObject.uid = `rc-upload-${Date.now()}`;
                                                    fileObject.originalname = 'order_receipt.pdf';
                                                    // Upload the PDF file
                                                    const response = await imageUploadRequest(
                                                        fileObject,
                                                        setFileList,
                                                        setImageUrl,
                                                        setLoading
                                                    );

                                                    setReceiptLoading(false);
                                                    if (response && response.status === 'done') {
                                                        // Set the message and message type
                                                        const orderReceiptMessage = `${customerName} Pro-forma enquiry`;
                                                        const orderReceiptType = 'document';
                                                        const imageUrl = Array.isArray(response.url) ? response.url : [response.url];

                                                        const newMessage = {
                                                            phone: selectedUser.phone,
                                                            content: {
                                                                body: orderReceiptMessage,
                                                                type: orderReceiptType, // 'text' or 'image',
                                                                url:
                                                                    orderReceiptType === 'image' || orderReceiptType === 'document'
                                                                        ? imageUrl
                                                                        : ''
                                                            },
                                                            user_id: localStorage.getItem('userId'),
                                                            order_id: orderId,
                                                            order_type: 'proforma',
                                                            replyTo: replyTo.whatsapp_id
                                                        };

                                                        socket.emit('mira_chat', newMessage);

                                                        // Transform message content
                                                        const transformedMessage = {
                                                            phone: selectedUser.phone,
                                                            content: orderReceiptMessage,
                                                            type: orderReceiptType,
                                                            isSent: 'mira' || 'staff',
                                                            agent: 'staff',
                                                            image:
                                                                orderReceiptType === 'image' || orderReceiptType === 'document'
                                                                    ? imageUrl
                                                                    : '',
                                                            user: `${localStorage.getItem('userFirstName')} ${localStorage.getItem(
                                                                'userLastName'
                                                            )}`,
                                                            _id: Math.random().toString(36).substring(2, 15),
                                                            createdAt: new Date().toISOString()
                                                        };

                                                        setMessages((prevMessages) => [...prevMessages, transformedMessage]);

                                                        setMessage('');
                                                        setMessageType('text');
                                                        setImageUrl([]);
                                                        setPlaceOrder(false);
                                                        setTab('products');
                                                        setSelectedProducts([]);
                                                        setCustomerName('');
                                                        setCustomerPhone('');
                                                        setCustomerLocation('');
                                                        setOrderNo('');
                                                    }
                                                } catch (error) {
                                                    toast.error('Failed to send order receipt');
                                                    setReceiptLoading(false);
                                                }
                                            }}
                                            style={{ display: 'flex', alignItems: 'center' }}
                                            loading={receiptLoading}
                                        >
                                            <img
                                                src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
                                                alt="PDF icon"
                                                height={20}
                                                style={{ marginRight: '5px' }}
                                            />
                                            {receiptLoading ? 'Sending...' : 'Send to customer'}
                                        </Button>
                                        <Button
                                            onClick={() => setTab('place-order')}
                                            style={{
                                                marginLeft: '5px',
                                                color: '#FF7410',
                                                textDecoration: 'underline',

                                                fontSize: '16px'
                                            }}
                                        >
                                            <EditOutlined /> Edit pro-forma
                                        </Button>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </Modal>
            <Modal
                title="Mira audio recorder"
                open={audioModal}
                onCancel={() => {
                    setAudioModal(false);
                    stopRecording(mediaRecorderRef, setIsRecording, setAudioChunks, setRecordingTime, setAudioPreviewUrl).then((r) => r);
                    setRecordingTime(0);
                    setAudioFile('');
                }}
                footer={null}
                width={400}
            >
                <div>
                    {isRecording ? (
                        <div className="recording-indicator">
                            <div className="pulse-animation" />
                            <p>Recording... {recordingTime} seconds</p>
                            <Button
                                style={{ backgroundColor: 'transparent', borderColor: '#FF740F', color: '#FF740F', width: 'fit-content' }}
                                onClick={async () => {
                                    try {
                                        // Stop recording and get the audio blob
                                        const audioBlob = await stopRecording(
                                            mediaRecorderRef,
                                            setIsRecording,
                                            setAudioChunks,
                                            setRecordingTime,
                                            setAudioPreviewUrl
                                        );

                                        // Then upload the audio if it exists
                                        if (audioBlob) {
                                            const uploadedData = await uploadAudio(audioBlob); // Await uploadAudio

                                            if (uploadedData) {
                                                setAudioFile(uploadedData); // Set audio file if the upload succeeds
                                                toast.success('Audio uploaded successfully!');
                                            }
                                        } else {
                                            toast.warn('No audio recorded.');
                                        }
                                    } catch (error) {
                                        toast.error('An error occurred while processing your audio.');
                                    }
                                }}
                                className="mt-4"
                            >
                                Stop Recording
                            </Button>
                        </div>
                    ) : (
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            {/* Render the audio preview if it exists */}
                            {audioPreviewUrl && (
                                <audio controls>
                                    <source src={audioPreviewUrl} type="audio/webm" />
                                    <track kind="captions" srcLang="en" src={audioPreviewUrl} />
                                    Your browser does not support the audio element.
                                </audio>
                            )}

                            <Button
                                style={{ backgroundColor: 'transparent', borderColor: '#FF740F', color: '#FF740F', width: 'fit-content' }}
                                onClick={() => sendAudioFile(setAudioModal, socket, audioFile, selectedUser, replyTo, setMessages)}
                                className="mt-4"
                            >
                                Send Recording
                            </Button>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default Index;
