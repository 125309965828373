// import dashboard from './dashboard';

import fulfillment from './fulfillment';
import other from './other';

// ==============================|| MENU ITEMS dashboard ||============================== //

const fulfillmentItems = {
    items: [fulfillment, other]
};

export default fulfillmentItems;
