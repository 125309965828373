/* eslint-disable no-underscore-dangle */
/* eslint-disable no-empty */
import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import { makeStyles } from '@mui/styles';
// material-ui
import { Grid } from '@mui/material';
import ApiUrl from 'network';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
// import { gridSpacing } from 'store/constant';
import Button from '@mui/material/Button';
import TotalCollectionAlfredCard from './collectionsdata/totalcollctionalfred';
import TotalCollectionAbigailCard from './collectionsdata/totalcollectionAbigail';
import TotalCollectionDanielCard from './collectionsdata/totalcollectionDaniel';
import TotalCollectionJosephCard from './collectionsdata/totalcollectionJoseph';
import TotalCollectionKateCard from './collectionsdata/totalcollectionkate';
import TotalCollectionKwakuCard from './collectionsdata/totalcollectionkwaku';
import TotalCollectionNicholasCard from './collectionsdata/totalCollectionNicholas';
import TotalCollectionPrinceCard from './collectionsdata/totalcollectionprince';
import TotalCollectionSelinaCard from './collectionsdata/totalcollectionselina';
import TotalCollectionSophiaCard from './collectionsdata/totalcollectionSophia';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    colors: {
        color: `#ED7C1B`
    }
}));

const CollectionReport = () => {
    // const history = useNavigate();
    const classes = useStyles();
    const [status, setStatus] = useState('today');
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [isLoading, setLoading] = useState(false);
    // const userRole = localStorage.getItem('userRole');
    // const userCurrency = localStorage.getItem('userCurrency');
    const [dateRangeTitle, setdateRangeTitle] = useState('');
    const [collectedAlfred, setcollectedAlfred] = useState([]);
    const [collectedSelina, setcollectedSelina] = useState([]);
    const [collectedKate, setcollectedKate] = useState([]);
    const [collectedPrince, setcollectedPrince] = useState([]);
    const [collectedKwaku, setcollectedKwaku] = useState([]);
    const [collectedJoseph, setcollectedJoseph] = useState([]);
    const [collectedSophia, setcollectedSophia] = useState([]);
    const [collectedAbigail, setcollectedAbigail] = useState([]);
    const [collectedDaniel, setcollectedDaniel] = useState([]);
    const [collectedNicholas, setcollectedNicholas] = useState([]);
    const [collectedListowell, setcollectedListowell] = useState([]);
    const [collectedJuliana, setcollectedJuliana] = useState([]);
    const country = localStorage.getItem('country');

    const retrieveAllTodaysMatrix = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.CollectionReportFinanceTodayUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    // console.log('result', data.totaltodaysorders);
                    setcollectedAlfred(data?.collectedAlfred ?? []);
                    setcollectedSelina(data?.collectedSelina ?? []);
                    setcollectedKate(data?.collectedKate ?? []);
                    setcollectedPrince(data?.collectedPrince ?? []);
                    setcollectedKwaku(data?.collectedKwaku ?? []);
                    setcollectedJoseph(data?.collectedJoseph ?? []);
                    setcollectedSophia(data?.collectedSophia ?? []);
                    setcollectedAbigail(data?.collectedAbigail ?? []);
                    setcollectedDaniel(data?.collectedDaniel ?? []);
                    setcollectedNicholas(data?.collectedNicholas ?? []);
                    setcollectedListowell(data?.collectedListowell ?? []);
                    setcollectedJuliana(data?.collectedJuliana ?? []);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const setChange = (event, picker) => {
        console.log('startDate', picker.startDate._d);
        console.log('endDate', picker.endDate._d);
        setstartDate(picker.startDate._d);
        setendDate(picker.endDate._d);
        setStatus('dateselect');
        // AllDateRangeMasterDashboardUrl
        const userInfo = localStorage.getItem('userToken');
        const from = picker.startDate._d;
        const to = picker.endDate._d;
        setLoading(true);
        const data = {
            from,
            to
        };

        fetch(ApiUrl.CollectionReportFinanceDateRangeUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                setLoading(false);
                // console.log('data', result);
                setdateRangeTitle(result?.dateRangeTitle ?? '');
                setcollectedAlfred(result?.collecteddaterangeAlfred ?? []);
                setcollectedSelina(result?.collecteddaterangeSelina ?? []);
                setcollectedKate(result?.collecteddaterangeKate ?? []);
                setcollectedPrince(result?.collecteddaterangePrince ?? []);
                setcollectedKwaku(result?.collecteddaterangeKwaku ?? []);
                setcollectedJoseph(result?.collecteddaterangeJoseph ?? []);
                setcollectedSophia(result?.collecteddaterangeSophia ?? []);
                setcollectedAbigail(result?.collecteddaterangeAbigail ?? []);
                setcollectedDaniel(result?.collecteddaterangeDaniel ?? []);
                setcollectedNicholas(result?.collecteddaterangeNicholas ?? []);
                setcollectedListowell(data?.collecteddaterangeListowell ?? []);
                setcollectedJuliana(data?.collecteddaterangeJuliana ?? []);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (country === 'Ghana') {
            retrieveAllTodaysMatrix();
        }
    }, [country]);

    return (
        <>
            <br />
            <PageHeader title="Dashboard" subTitle="Home | Report | Collection " />
            {country === 'Ghana' ? (
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>Note: This Data is based on collected Date</Grid>

                    <Grid item>
                        <DateRangePicker initialSettings={{ startDate, endDate }} onApply={setChange}>
                            <Button variant="contained" size="sm" className={classes.item}>
                                filter by Date
                            </Button>
                        </DateRangePicker>
                    </Grid>
                </Grid>
            ) : (
                ''
            )}
            <br />
            {status === 'today' && country === 'Ghana' ? (
                <>
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionAlfredCard
                                isLoading={isLoading}
                                collectionsdata={collectedAlfred}
                                title="Alfred Adzido Total Collection For "
                                date="Today"
                                excelName="AlfredTodayTotalCollection"
                                excelName2="AlfredTodayTotalCollection.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionAbigailCard
                                isLoading={isLoading}
                                collectionsdata={collectedAbigail}
                                title="Abigail Antwi Total Collection For "
                                date="Today"
                                excelName="AbigailTodayTotalCollection"
                                excelName2="AbigailTodayTotalCollection.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionDanielCard
                                isLoading={isLoading}
                                collectionsdata={collectedDaniel}
                                title="Daniel Atta Total Collection For "
                                date="Today"
                                excelName="DanielTodayTotalCollection"
                                excelName2="DanielTodayTotalCollection.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionJosephCard
                                isLoading={isLoading}
                                collectionsdata={collectedJoseph}
                                title="Joseph Moyimeh Total Collection For "
                                date="Today"
                                excelName="JosephTodayTotalCollection"
                                excelName2="JosephTodayTotalCollection.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionKateCard
                                isLoading={isLoading}
                                collectionsdata={collectedKate}
                                title="Kate Annor Tettey Total Collection For "
                                date="Today"
                                excelName="KateTodayTotalCollection"
                                excelName2="KateTodayTotalCollection.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionKwakuCard
                                isLoading={isLoading}
                                collectionsdata={collectedKwaku}
                                title="Kwaku Atta Total Collection For "
                                date="Today"
                                excelName="KwakuTodayTotalCollection"
                                excelName2="KwakuTodayTotalCollection.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionNicholasCard
                                isLoading={isLoading}
                                collectionsdata={collectedNicholas}
                                title="Nicholas Kwakye Total Collection For "
                                date="Today"
                                excelName="NicholasTodayTotalCollection"
                                excelName2="NicholasTodayTotalCollection.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionPrinceCard
                                isLoading={isLoading}
                                collectionsdata={collectedPrince}
                                title="Prince Awuah Total Collection For "
                                date="Today"
                                excelName="PrinceTodayTotalCollection"
                                excelName2="PrinceTodayTotalCollection.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionSelinaCard
                                isLoading={isLoading}
                                collectionsdata={collectedSelina}
                                title="Selina Quaye Total Collection For "
                                date="Today"
                                excelName="SelinaTodayTotalCollection"
                                excelName2="SelinaTodayTotalCollection.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionSophiaCard
                                isLoading={isLoading}
                                collectionsdata={collectedSophia}
                                title="Sophia Eyram Atsu Total Collection For "
                                date="Today"
                                excelName="SophiaTodayTotalCollection"
                                excelName2="SophiaTodayTotalCollection.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionAlfredCard
                                isLoading={isLoading}
                                collectionsdata={collectedListowell}
                                title="Listowell Agyemang Total Collection For "
                                date="Today"
                                excelName="ListowellTodayTotalCollection"
                                excelName2="ListowellTodayTotalCollection.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionAbigailCard
                                isLoading={isLoading}
                                collectionsdata={collectedJuliana}
                                title="Juliana Quainoo Total Collection For "
                                date="Today"
                                excelName="JulianaTodayTotalCollection"
                                excelName2="JulianaTodayTotalCollection.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                </>
            ) : (
                ''
            )}

            {status === 'dateselect' && country === 'Ghana' ? (
                <>
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionAlfredCard
                                isLoading={isLoading}
                                collectionsdata={collectedAlfred}
                                title="Alfred Adzido Total Collection For "
                                date={dateRangeTitle}
                                excelName="AlfredTotalCollection"
                                excelName2="AlfredTotalCollection.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionAbigailCard
                                isLoading={isLoading}
                                collectionsdata={collectedAbigail}
                                title="Abigail Antwi Total Collection For "
                                date={dateRangeTitle}
                                excelName="AbigailTotalCollection"
                                excelName2="AbigailTotalCollection.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionDanielCard
                                isLoading={isLoading}
                                collectionsdata={collectedDaniel}
                                title="Daniel Atta Total Collection For "
                                date={dateRangeTitle}
                                excelName="DanielTotalCollection"
                                excelName2="DanielTotalCollection.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionJosephCard
                                isLoading={isLoading}
                                collectionsdata={collectedJoseph}
                                title="Joseph Moyimeh Total Collection For "
                                date={dateRangeTitle}
                                excelName="JosephTotalCollection"
                                excelName2="JosephTotalCollection.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionKateCard
                                isLoading={isLoading}
                                collectionsdata={collectedKate}
                                title="Kate Annor Tettey Total Collection For "
                                date={dateRangeTitle}
                                excelName="KateTotalCollection"
                                excelName2="KateTotalCollection.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionKwakuCard
                                isLoading={isLoading}
                                collectionsdata={collectedKwaku}
                                title="Kwaku Atta Total Collection For "
                                date={dateRangeTitle}
                                excelName="KwakuTotalCollection"
                                excelName2="KwakuTotalCollection.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionNicholasCard
                                isLoading={isLoading}
                                collectionsdata={collectedNicholas}
                                title="Nicholas Kwakye Total Collection For "
                                date={dateRangeTitle}
                                excelName="NicholasTotalCollection"
                                excelName2="NicholasTotalCollection.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionPrinceCard
                                isLoading={isLoading}
                                collectionsdata={collectedPrince}
                                title="Prince Awuah Total Collection For "
                                date={dateRangeTitle}
                                excelName="PrinceTotalCollection"
                                excelName2="PrinceTotalCollection.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionSelinaCard
                                isLoading={isLoading}
                                collectionsdata={collectedSelina}
                                title="Selina Quaye Total Collection For "
                                date={dateRangeTitle}
                                excelName="SelinaTotalCollection"
                                excelName2="SelinaTotalCollection.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionSophiaCard
                                isLoading={isLoading}
                                collectionsdata={collectedSophia}
                                title="Sophia Eyram Atsu Total Collection For "
                                date={dateRangeTitle}
                                excelName="SophiaTotalCollection"
                                excelName2="SophiaTotalCollection.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionAlfredCard
                                isLoading={isLoading}
                                collectionsdata={collectedListowell}
                                title="Listowell Agyemang Total Collection For "
                                date={dateRangeTitle}
                                excelName="ListowellTotalCollection"
                                excelName2="ListowellTotalCollection.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalCollectionAbigailCard
                                isLoading={isLoading}
                                collectionsdata={collectedJuliana}
                                title="Juliana Quainoo Total Collection For "
                                date={dateRangeTitle}
                                excelName="JulianaTotalCollection"
                                excelName2="JulianaTotalCollection.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                </>
            ) : (
                ''
            )}
        </>
    );
};

export default CollectionReport;
