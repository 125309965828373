import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';

const Cart = ({ cart, setShow }) => {
    return (
        <IconButton aria-label="shop" onClick={() => setShow(false)}>
            <Badge badgeContent={cart?.length} color="error">
                <ShoppingCartIcon />
            </Badge>
        </IconButton>
    );
};
export default Cart;
