import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button, Input, Select, DatePicker, Modal, Divider, Table, Tag } from 'antd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { decrypt, encrypt } from '../../../../utils/encrypt';
import { toast } from 'react-toastify';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import ApiUrl from '../../../../network';

export const Index = () => {
    const date = new Date();
    const today = dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [order, setOrder] = useState({});
    const { id } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const orderType = searchParams.get('orderType');
    const { RangePicker } = DatePicker;
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [driverName, setDriverName] = useState('');
    const [batchDate, setBatchDate] = useState(today);
    const [batchStatus, setBatchStatus] = useState([]);
    const [driverList, setDriverList] = useState([]);
    const [carList, setCarList] = useState([]);
    const [checkedOrders, setCheckedOrders] = useState([]);
    const [orderModalVisible, setOrderModalVisible] = useState(false);
    const [itemName, setItemName] = useState('');
    const [quantity, setQuantity] = useState('');
    const [orderItemSelect, setOrderItemSelect] = useState({});
    const [productListSelect, setProductListSelect] = useState([
        {
            id: '',
            key: '',
            partId: '',
            qty: null
        }
    ]);
    const [products, setProducts] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [orderNo, setOrderNo] = useState('');
    const [batchData, setBatchData] = useState({});
    const [productModalVisible, setProductModalVisible] = useState(false);

    const editOrder = (order) => {
        setOrderItemSelect(order);
        setItemName(order.partid);
        setQuantity(order.qty);
        setOrderModalVisible(true);
    };

    const handleDelete = (id) => {
        const newOrderDetails = order?.orderItems?.filter((item) => item._id !== id);
        setOrder({ ...order, orderItems: newOrderDetails });
    };

    const columns = [
        {
            title: 'Product name',
            dataIndex: 'partid',
            key: 'partid'
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand'
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <div className="d-flex">
                    <Button
                        type="primary"
                        onClick={() => {
                            editOrder(record);
                        }}
                        style={{
                            marginRight: '10px',
                            backgroundColor: '#fff',
                            borderColor: '#FF740F',
                            color: '#FF740F'
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            handleDelete(record._id);
                        }}
                        style={{
                            marginRight: '10px',
                            backgroundColor: '#fff',
                            borderColor: '#FF740F',
                            color: '#FF740F'
                        }}
                    >
                        Delete
                    </Button>
                </div>
            )
        }
    ];

    const fetchOrder = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/waybill/vieworderbatch/${id}?orderType=${orderType}`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setOrder(result);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        toast.error(error.message);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    const getBatchStatus = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/orderbatch/batchstatuses`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setBatchStatus(data.data);
                } else {
                    toast.error(data.message);
                }
            });
    };

    const getDrivers = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/driver/availabledrivers`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    const drivers = decrypt(JSON.stringify(data.data), keyResult, ivResult);
                    setDriverList(JSON.parse(drivers));
                } else {
                    toast.error(data.message);
                }
            });
    };

    const getCars = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/salesplanning/garagevehicleslist`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    const cars = decrypt(JSON.stringify(data.data), keyResult, ivResult);
                    setCarList(JSON.parse(cars));
                } else {
                    toast.error(data.message);
                }
            });
    };
    const fetchAllProducts = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/partzone/salespartspricelist`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = data.data;
                        setProducts(result);
                    } else {
                        toast.error(data.message);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        fetchOrder();
        getBatchStatus();
        getDrivers();
        getCars();
        fetchAllProducts();
    }, []);
    const driverOptions = driverList.map((item) => {
        return {
            label: `${item.firstName} ${item.lastName}`,
            value: `${item.firstName} ${item.lastName}`
        };
    });
    const productsOptions = products.map((item) => {
        return {
            label: item.sku,
            value: item.sku
        };
    });

    // eslint-disable-next-line consistent-return
    const updateOrderHandlerApi = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const datas = {
                id,
                // batchid: order._id,
                batchDate: order.batchDate,
                batchStatusId: order.batchStatusId,
                rmaName: order.rmaName,
                orderNo: order.orderNo,
                orderId: order.orderId,
                orderType: order.orderType,
                orderItems: order?.orderItems
            };

            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            await fetch(`${ApiUrl.BaseUrl}/waybill/updatebatchorder`, {
                method: 'PUT',
                headers: config.headers,
                body: JSON.stringify({ payload })
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        toast.success(data.message);
                        setLoading(false);
                        setModalVisible(false);
                        setSelectedOrderId(null);
                        // setTimeout(() => {
                        //     window.location.href = '/generated/waybill';
                        // }, 2000);
                    } else {
                        const error = decrypt(JSON.stringify(data.data), keyResult, ivResult);
                        toast.error(error);
                        setLoading(false);
                    }
                });
        } catch (e) {
            toast.error(e.message);
            setLoading(false);
        }
    };

    const updateOrderHandler = () => {
        const updatedOrderDetails = order?.orderItems?.map((order) => {
            if (order._id === orderItemSelect._id) {
                return { ...order, partid: itemName, qty: quantity };
            }
            return order;
        });
        setOrder({ ...order, orderItems: updatedOrderDetails });

        // Close the modal
        setOrderModalVisible(false);
    };
    const updateFieldValue = (id, field, value) => {
        setProductListSelect((prevFields) =>
            prevFields.map((prevField) => (prevField.id === id ? { ...prevField, [field]: value } : prevField))
        );
    };
    const addProduct = () => {
        const newProduct = {
            id: uuidv4(),
            key: uuidv4(),
            partid: '',
            qty: ''
        };

        setProductListSelect((prevFields) => [...prevFields, newProduct]);
    };
    const removeProduct = (id) => {
        if (id) {
            setProductListSelect((prevFields) => prevFields.filter((prevField) => prevField.id !== id));
        }
    };
    const addProductHandler = () => {
        productListSelect.forEach((item) => {
            if (item.partId && item.qty) {
                const existingItemIndex = order?.orderItems?.findIndex(
                    (existingItem) => existingItem.partid.toLowerCase() === item.partId.toLowerCase()
                );

                if (existingItemIndex !== -1) {
                    // If the partId already exists in order.items, update the quantity
                    const updatedItems = [...order.orderItems];
                    updatedItems[existingItemIndex].qty += parseInt(item.qty, 10);

                    setOrder((prevFields) => ({ ...prevFields, orderItems: updatedItems }));
                    toast.success('Quantity updated for existing product in order');
                    setProductListSelect([{ id: uuidv4(), key: uuidv4(), partId: '', qty: '' }]);
                } else {
                    // If the partId doesn't exist, add a new product
                    const newProduct = {
                        _id: uuidv4(),
                        partid: item.partId,
                        qty: parseInt(item.qty, 10)
                    };

                    setOrder((prevFields) => ({ ...prevFields, orderItems: [...prevFields.orderItems, newProduct] }));
                    toast.success('Product added to order');
                    setProductListSelect([{ id: uuidv4(), key: uuidv4(), partId: '', qty: '' }]);
                }
            }
        });
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Link to="/waybill/batch" className="createScheduleLink">
                    <p style={{ fontSize: '25px', marginLeft: '10px' }} className="d-flex">
                        <ArrowBackIcon sx={{ fontSize: '25px', color: '#000' }} /> Update order{' '}
                        <Tag color="red" className="d-flex align-items-center ml-2" style={{ width: 'fit-content', textAlign: 'center' }}>
                            Your batch has been rejected
                        </Tag>
                    </p>{' '}
                </Link>
                <div className="d-flex justify-content-between align-items-center">
                    <Button
                        style={{ backgroundColor: '#fff', color: '#FF740F', border: '1px solid #FF740F', width: 'fit-content' }}
                        className="mt-2 mb-2 mr-2 d-flex align-items-center justify-content-center btnCreateSchedule"
                        title="Apply Filters"
                        onClick={() => setModalVisible(true)}
                    >
                        view reason for rejection
                    </Button>
                    <Button
                        style={{ backgroundColor: '#FF740F', color: '#fff', width: 'fit-content' }}
                        className="mt-2 mb-2 d-flex align-items-center justify-content-center btnCreateSchedule"
                        title="Apply Filters"
                        loading={loading}
                        onClick={updateOrderHandlerApi}
                    >
                        Update order
                    </Button>
                </div>
            </div>
            <div className="batchContainer">
                <div className="p-4">
                    <div className="addSalePlanInput">
                        <div className="mr-2">
                            <p style={{ fontSize: '16px', color: '#546974' }}>Batch Date</p>
                            <Input
                                size="large"
                                style={{
                                    width: '300px'
                                }}
                                placeholder="Batch Date"
                                onChange={(e) => setBatchDate(e.target.value)}
                                value={dayjs(batchData?.batchDate).format('YYYY-MM-DD')}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="addSalePlanInput">
                        <div className="mr-2">
                            <p style={{ fontSize: '16px', color: '#546974' }}>Order Type</p>
                            <Input
                                placeholder="Order Type"
                                type="text"
                                size="large"
                                style={{
                                    width: '300px'
                                }}
                                value={order.orderType}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="addSalePlanInput">
                        <div>
                            <p style={{ fontSize: '16px', color: '#546974' }}>RMA</p>
                            <Input
                                placeholder="Batch Status"
                                type="text"
                                size="large"
                                style={{
                                    width: '300px'
                                }}
                                value={order.rmaName}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="addSalePlanInput">
                        <div>
                            <p style={{ fontSize: '16px', color: '#546974' }}>Order No</p>
                            <Input
                                placeholder="Order No"
                                type="text"
                                size="large"
                                style={{
                                    width: '300px'
                                }}
                                value={order.orderNo}
                                onChange={(e) => setOrderNo(e.target.value ?? '')}
                                disabled
                            />
                            {checkedOrders && checkedOrders.length > 0 && (
                                <div>
                                    <div className="mt-2">
                                        {checkedOrders.map((checkedOrder, index) => (
                                            <p key={index}>
                                                Order: {checkedOrder.orderNo} - {checkedOrder.rma}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="p-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <p style={{ fontSize: '16px', color: '#546974' }}>Products in this order ({order?.orderItems?.length})</p>
                        <Button
                            onClick={() => setProductModalVisible(true)}
                            type="primary"
                            className="mb-2"
                            style={{ backgroundColor: 'transparent', color: '#4B6EFF', border: '1px solid #DBE2FF' }}
                        >
                            Add Product <PlusOutlined />
                        </Button>
                    </div>
                    <div className="productsContainer orderListContainer">
                        <Table rowKey={(record) => record._id} columns={columns} dataSource={order?.orderItems} pagination={false} />
                    </div>
                </div>
            </div>
            <Modal footer={null} open={modalVisible} onCancel={() => setModalVisible(false)} width={400}>
                <div className="text-center">
                    <h5>Reason for rejection</h5>
                    <p className="mb-4 mt-2" style={{ fontSize: '14px' }}>
                        {order?.rejectedReason}
                    </p>
                </div>
            </Modal>
            <Modal
                footer={null}
                open={orderModalVisible}
                onCancel={() => {
                    setOrderModalVisible(false);
                }}
                width={400}
            >
                <div>
                    <p className="text-center mb-4 mt-2" style={{ fontSize: '14px' }}>
                        Edit item
                    </p>
                    <div>
                        <p style={{ fontSize: '12px' }}>Item name</p>
                        <Input
                            placeholder="Item name"
                            size="large"
                            value={itemName}
                            onChange={(e) => setItemName(e.target.value)}
                            style={{ width: '100%' }}
                            readOnly
                        />
                    </div>
                    <div className="mt-2">
                        <p style={{ fontSize: '12px' }}>
                            Quantity <span style={{ color: 'red' }}>*</span>
                        </p>
                        <Input
                            placeholder="Quantity"
                            size="large"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className="d-flex align-items-center mt-4">
                        <Button
                            style={{
                                color: '#FF740F',
                                borderColor: '#E7EAEB'
                            }}
                            className="mr-2"
                            onClick={() => {
                                setOrderModalVisible(false);
                            }}
                            disabled={loading}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{
                                color: '#FF740F',
                                borderColor: '#E7EAEB'
                            }}
                            onClick={updateOrderHandler}
                            loading={loading}
                            disabled={loading}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                title="Add Product"
                footer={null}
                open={productModalVisible}
                onCancel={() => {
                    setProductModalVisible(false);
                }}
                width={700}
            >
                <div>
                    {productListSelect.map((product, id) => (
                        <div className="product mb-4" key={id}>
                            <div>
                                <p>
                                    Product <span style={{ color: 'red', fontSize: '10px' }}>*</span>
                                </p>
                                <Select
                                    options={productsOptions}
                                    value={product.partId}
                                    onChange={(value) => updateFieldValue(product.id, 'partId', value)}
                                    placeholder="Select Product"
                                    allowClear
                                    showSearch
                                    filterOption={(inputValue, option) => {
                                        if (typeof option.label === 'string') {
                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }

                                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }}
                                    size="large"
                                    className="input"
                                />
                            </div>
                            <div>
                                <p>
                                    Quantity <span style={{ color: 'red', fontSize: '10px' }}>*</span>
                                </p>
                                <Input
                                    placeholder="Quantity"
                                    size="large"
                                    value={product.qty}
                                    onChange={(e) => updateFieldValue(product.id, 'qty', e.target.value)}
                                    className="input"
                                />
                            </div>
                            <div className="productActions">
                                {id >= 1 && (
                                    <Button
                                        style={{ color: '#FF740F', borderColor: '#E7EAEB' }}
                                        onClick={() => removeProduct(product.id)}
                                        className="mr-2"
                                    >
                                        <MinusOutlined />
                                    </Button>
                                )}
                                <Button onClick={() => addProduct()} className="mr-2" style={{ color: '#FF740F', borderColor: '#E7EAEB' }}>
                                    <PlusOutlined />
                                </Button>
                            </div>
                        </div>
                    ))}
                    <div className="d-flex">
                        <Button
                            type="primary"
                            onClick={() => {
                                setProductModalVisible(false);
                            }}
                            style={{
                                color: '#FF740F',
                                borderColor: '#E7EAEB',
                                marginRight: '10px',
                                width: '300px',
                                backgroundColor: 'transparent'
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            style={{
                                color: '#FF740F',
                                borderColor: '#E7EAEB',
                                marginRight: '10px',
                                width: '300px',
                                backgroundColor: 'transparent'
                            }}
                            onClick={addProductHandler}
                        >
                            Add Product
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Index;
