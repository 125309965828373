import './auth-generic.css';

import { Button, Form, Input, Select, Spin } from 'antd';
import { Col, Row } from 'react-grid-system';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { MdOutlineMarkEmailRead, MdOutlineSend, MdPhoneIphone } from 'react-icons/md';
import { errorToast, successToast } from 'utils/toast';

import { IoLocationOutline } from 'react-icons/io5';
// import { Link } from 'react-router-dom';
import Logo from '../../../assets/images/garagelogo.png';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { authFetch } from 'utils/axios-config';
import countries from 'utils/countries';
import mechnic from '../../../assets/images/retailer2.svg';
import styled from 'styled-components';
// import { useNavigate } from 'react-router';
import { wait } from 'utils/functions';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const Wrapper = styled.main`
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    background: #fcfaf7;

    .custom-form-item {
        width: 75%;
        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    .ant-form-item {
        margin-bottom: 13px;
    }

    .img-section {
        img {
            object-fit: cover;
            min-height: 100vh;
            width: 100vw;
            @media screen and (max-width: 767px) {
                display: none;
            }
        }
    }

    .h-title {
        font-family: 'Roboto', sans-serif;
        font-weight: 800;
        font-size: 3rem;
        @media screen and (max-width: 768px) {
            font-size: 1.5rem;
        }
    }

    .garage {
        color: #fe740e;
        font-weight: 800;
    }
    .logo-section {
        text-align: center;
    }

    @media screen and (min-width: 1200px) {
        .logo-section {
            max-width: 500px;
            margin: 10px auto;
            text-align: left;
        }
    }

    .submit-btn {
        background: #757575;
        color: #fff;
        font-weight: 400;
        &:hover {
            background: #fe740e;
        }
    }
`;
const useStyles = makeStyles(() => ({
    color: {
        color: '#FF740F'
    },
    backgroundColor: {
        backgroundColor: '#FF740F'
    }
}));
const ExternalSignup = () => {
    const classes = useStyles();
    // const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);

    const roles = [
        {
            id: '620e4e28352b6bc29a5e3231',
            name: 'Distributor'
        },
        {
            id: '623849adf978bc24fe107f5e',
            name: 'Fleet'
        }
    ];

    const handleClick = () => {
        window.location.replace('https://marketplace.garagemobility.com');
    };

    const handleSubmit = async (values) => {
        setIsLoading(true);
        delete values?.confirmPassword;
        try {
            await authFetch.post('/authsignups/register', values);
            successToast(`An activation link has been sent to your email`);
            wait(4).then(() => {
                handleClick();
                // navigate('/login');
            });
            setIsLoading(false);
        } catch (error) {
            console.log('Error', error);
            errorToast(error.response.data.data);
            setIsLoading(false);
        }
    };

    return (
        <Wrapper>
            <Row>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="img-section">
                        <img src={mechnic} alt="Car repair" className="img-fluid" />
                    </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Spin spinning={isLoading} style={{ textAlign: 'center', marginTop: '200px', color: '#fe740e' }} tip="Sending...">
                        <article className="form-layout px-5">
                            <div className="logo-section">
                                <img src={Logo} alt="Garage" />
                                <p>
                                    Already have an account?{' '}
                                    <Typography
                                        onClick={handleClick}
                                        variant="subtitle1"
                                        className={classes.color}
                                        sx={{ textDecoration: 'none', cursor: 'pointer' }}
                                    >
                                        Login
                                    </Typography>
                                </p>
                            </div>
                            <Form
                                autoComplete="off"
                                layout="vertical"
                                labelAlign="left"
                                labelWrap={false}
                                onFinishFailed={(error) => {
                                    console.log(error);
                                }}
                                onFinish={handleSubmit}
                            >
                                <Form.Item
                                    name="firstName"
                                    label="First Name"
                                    rules={[{ required: true, message: 'Please enter first name' }]}
                                    hasFeedback
                                >
                                    <Input
                                        prefix={<UserOutlined className="site-form-item-icon" />}
                                        className="custom-form-item"
                                        placeholder="Your first name"
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="lastName"
                                    label="Last Name"
                                    rules={[{ required: true, message: 'Please enter last name' }]}
                                    hasFeedback
                                >
                                    <Input
                                        prefix={<UserOutlined className="site-form-item-icon" />}
                                        className="custom-form-item"
                                        placeholder="Your last name"
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="accountType"
                                    label="Choose Role"
                                    rules={[{ required: true, message: 'Choose one role' }]}
                                    hasFeedback
                                >
                                    <Select placeholder="Choose account type" size="large" className="custom-form-item">
                                        {roles.map((role) => (
                                            <Select.Option value={role?.id} key={role?.id}>
                                                {role.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="country"
                                    label="Country"
                                    rules={[{ required: true, message: 'Please choose a country' }]}
                                    hasFeedback
                                >
                                    <Select
                                        placeholder="Country"
                                        showSearch
                                        className="custom-form-item"
                                        size="large"
                                        fieldNames={{ label: 'name', value: 'name' }}
                                        allowClear
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.name.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                                        }
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={[...countries]}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="location"
                                    label="Location (city)"
                                    rules={[{ required: true, message: 'Please enter location' }]}
                                    hasFeedback
                                >
                                    <Input
                                        prefix={<IoLocationOutline className="site-form-item-icon" />}
                                        className="custom-form-item"
                                        placeholder="Your city"
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="contactPhone"
                                    label="Phone Number)"
                                    rules={[{ required: true, message: 'Phone number is required' }]}
                                    hasFeedback
                                >
                                    <Input
                                        prefix={<MdPhoneIphone className="site-form-item-icon" />}
                                        className="custom-form-item"
                                        type="number"
                                        placeholder="Your phone number"
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="email"
                                    label="Email Address"
                                    rules={[
                                        { required: true, message: 'Email address cannot be empty' },
                                        { type: 'email', message: 'Invalid email address format' }
                                    ]}
                                    hasFeedback
                                >
                                    <Input
                                        prefix={<MdOutlineMarkEmailRead className="site-form-item-icon" />}
                                        className="custom-form-item"
                                        placeholder="Enter email"
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label="Enter Password"
                                    rules={[
                                        { required: true, message: 'Password is required' },
                                        { min: 8, message: 'Password must be at least 8 characters' }
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        className="custom-form-item"
                                        placeholder="Enter password"
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    dependencies={['password']}
                                    rules={[
                                        { required: true, message: 'Password is required' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                // eslint-disable-next-line prefer-promise-reject-errors
                                                return Promise.reject('Passwords do not match');
                                            }
                                        })
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        className="custom-form-item"
                                        placeholder="Repeat password"
                                        size="large"
                                    />
                                </Form.Item>
                                {/* Submit Btn */}
                                <Form.Item className="mb-3">
                                    <Button type="primary" htmlType="submit" size="large" className="submit-btn" loading={isLoading}>
                                        Register Now &nbsp; <MdOutlineSend />
                                    </Button>
                                </Form.Item>
                            </Form>
                        </article>
                    </Spin>
                </Col>
            </Row>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Wrapper>
    );
};

export default ExternalSignup;
