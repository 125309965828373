import * as XLSX from 'xlsx';

import {
    BATCH_ID_BEGIN,
    BATCH_ID_FAILURE,
    BATCH_ID_SUCCESS,
    BATCH_RECEIPT_BEGIN,
    BATCH_RECEIPT_SUCCESS,
    CC_STATS_BEGIN,
    CC_STATS_FAILURE,
    CC_STATS_SUCCESS,
    CLEAR_FILTERS,
    COLLECTIONS_BEGIN,
    COLLECTIONS_FAILURE,
    COLLECTIONS_SUCCESS,
    DATA_DEFAULTS_BEGIN,
    DATA_DEFAULTS_FAILURE,
    DATA_DEFAULTS_SUCCESS,
    DEPOSITS_BEGIN,
    DEPOSITS_FAILURE,
    DEPOSITS_SUCCESS,
    FILTER_COLLECTIONS_BEGIN,
    FILTER_COLLECTIONS_ERROR,
    FILTER_COLLECTIONS_SUCCESS,
    FILTER_DEPOSITS_BEGIN,
    FILTER_DEPOSITS_ERROR,
    FILTER_DEPOSITS_SUCCESS,
    FILTER_LEADS_BEGIN,
    FILTER_LEADS_ERROR,
    FILTER_LEADS_SUCCESS,
    FILTER_RETAILER_BEGIN,
    FILTER_RETAILER_ERROR,
    FILTER_RETAILER_SUCCESS,
    FILTER_SALES_BEGIN,
    FILTER_SALES_ERROR,
    FILTER_SALES_SUCCESS,
    FINANCE_STATS_BEGIN,
    FINANCE_STATS_FAILURE,
    FINANCE_STATS_SUCCESS,
    FULFILLMENT_ST_BEGIN,
    FULFILLMENT_ST_FAILURE,
    FULFILLMENT_ST_SUCCESS,
    GENERATE_LEADS_BEGIN,
    GENERATE_LEADS_FAILURE,
    GENERATE_LEADS_SUCCESS,
    GMV_BEGIN,
    GMV_FAILURE,
    GMV_SUCCESS,
    RESET_COLLECTIONS,
    RESET_COLLECTIONS_BEGIN,
    RESET_DEPOSITS,
    RESET_DEPOSITS_BEGIN,
    RESET_LEADS,
    RESET_LEADS_BEGIN,
    RESET_RETAILER,
    RESET_RETAILER_BEGIN,
    RESET_SALES,
    RESET_SALES_BEGIN,
    RETAILER_BEGIN,
    RETAILER_FAILURE,
    RETAILER_SUCCESS,
    SALES_BEGIN,
    SALES_FAILURE,
    SALES_SUCCESS,
    SUPPLIER_BATCH_BEGIN,
    SUPPLIER_BATCH_FAILURE,
    SUPPLIER_BATCH_SUCCESS,
    SUPPLIER_BEGIN,
    SUPPLIER_FAILURE,
    SUPPLIER_SUCCESS
} from './actions';
import { createContext, useContext, useReducer } from 'react';
import { errorToast, successToast } from '../utils/toast';

import { authFetch } from 'utils/axios-config';
import reducer from './reducer';

/** Grab localStorage and store in context for reusability */
const userToken = localStorage.getItem('userToken');
const userFirstName = localStorage.getItem('userFirstName');
const userLastName = localStorage.getItem('userLastName');
const userRole = localStorage.getItem('userRole');
const userId = localStorage.getItem('userId');
const country = localStorage.getItem('country');
const userEmail = localStorage.getItem('userEmail');
const userCurrency = localStorage.getItem('userCurrency');
const photo = localStorage.getItem('photo');
const company = localStorage.getItem('company');
const channelpartnerId = localStorage.getItem('channelpartnerId');

const initialState = {
    isLoading: false,
    userToken,
    userFirstName,
    userLastName,
    userRole,
    userId,
    country,
    userEmail,
    userCurrency,
    photo,
    company,
    channelpartnerId,
    /** GENERATE SALES LEAD DATA */
    ranks: 0,
    salesAssociate: '',
    currency: '',
    message: '',
    leadTarget: 0,
    todolist: [],
    leadGProgress: 0,
    noLeadGToday: 0,
    leadsfollowups: [],
    /** Make Sales Data */
    salesTarget: 0,
    makeSalestodolist: [],
    currentTotalSales: 0,
    previousMonthTotalSales: 0,
    currentPreviousMonthSalesRate: 0,
    currentNoOrders: 0,
    previousMonthNoOrders: 0,
    currentPreviousMonthNoOrdersRate: 0,
    currentactualratio: 0,
    lastmonthactualratio: 0,
    currentPreviousMonthActualRatioRate: 0,
    currentWhalesCaughtCount: 0,
    lastMonthWhalesCaughtCount: 0,
    currentPreviousMonthWhalesRate: 0,
    retailersduefollowups: [],
    // Collections DATA
    collectionsTodos: [],
    collectionsdue: [],
    collectionsTarget: 0,
    currentcollectionsTotal: 0,
    lastmontcollectionsTotal: 0,
    currentLastMonthCollectionsRate: 0,
    currentactualcollectionsratio: 0,
    lastmonthactualCollectionsRatio: 0,
    currentPreviousMonthActualCollectionRate: 0,
    /** DEPOSIT INITIAL STATE */
    depositTodos: [],
    collectionsdepositsdue: [],
    currentDepositsTotal: 0,
    lastmontDepositsTotal: 0,
    currentLastMonthDepositsRate: 0,
    /** Retailer intial values */
    newRetailerTarget: 0,
    retailerTodolist: [],
    newRetailerGProgress: 0,
    noRetailerGToday: 0,
    newRetailerfollowups: [],

    /** Supplier dashboard */
    totalPartsSoldToday: 0,
    totalPartsSoldYesterday: 0,
    partSoldPercentage: '',
    totalNoPartsToday: 0,
    totalNoPartsYesterday: 0,
    noPartsPercentage: '0',
    averageAmountMadeToday: '0',
    averageAmountMadeesterday: '0',
    avgAmountPercentage: '0',
    supplierTop5parts: [],
    totalsales: [],
    totalsupplierorders: [],
    orderBatches: [],
    orderedParts: [],
    currentBatchDate: new Date(),
    currentBatchNo: '',
    batchStatusId: '',
    batchSubmitLoading: false,
    /** Data Extraction Setup Values */
    orderStatuses: [],
    paymentStatuses: [],
    countries: [],
    cities: [],
    customers: [],
    relationshipManagers: [],
    // GMV values
    regularGMV: 0,
    reqularOrdersNumber: 0,
    reguarlarordersData: [],
    darkstoreGMV: 0,
    darkstoreOrdersNumber: 0,
    darkstoreordersData: [],
    salesDriveGMV: 0,
    salesDriveOrdersNumber: 0,
    salesDriveordersData: [],
    proformaGMV: 0,
    proformaOrdersNumber: 0,
    proformaordersData: [],
    noRetailers: 0,
    noRetailersData: [],
    issuesNumber: 0,
    issuesData: [],
    noNewRetailers: 0,
    noNewRetailersData: [],
    leadsNumber: 0,
    leadsData: [],
    partsoldNumber: 0,
    partsSoldData: [],
    totalOrdersNumber: 0,
    totalordersData: [],
    totalGMV: 0,
    totalGMVMonth: [],
    // Financials
    totalRetailCost: '',
    totalRetailSales: '',
    totalRetailSalesNumber: 0,
    totalRetailSalesData: [],
    totalRetailDiscount: '',
    totalRetailDiscountNumber: 0,
    totalRetailDiscountData: [],
    totalpayments: '',
    totalpaymentsData: [],
    totaloutstanding: '',
    totaloutstandingData: [],
    totalProfit: 0,
    totalProfitPercentage: 0,
    totalAverageRetailCost: 0,
    postVatTakeRate: 0,
    postVatTakeRatePercentage: 0,
    postVatTakeRateData: [],
    // setup api query parameters
    customer: null,
    salesperson: null,
    orderstatus: null,
    paymentstatus: null,
    // In api it is country
    countryId: country,
    city: null,
    startdate: '',
    enddate: '',

    /** CC state values  */
    ccOrderNumber: 0,
    ccOrderData: [],
    prospectNumber: 0,
    prospectData: [],
    onboardscompleteNumber: 0,
    onboardscompleteData: [],
    onboardsbegunNumber: 0,
    onboardsbegunData: [],
    partlistcollectedNumber: 0,
    partlistcollectedData: [],
    partlistdeliveredNumber: 0,
    partlistdeliveredData: [],
    notreadytopartnerNumber: 0,
    notreadytopartnerData: [],
    // TODO: setup retailer gender types
    retailerGender: [],

    /** Fulfillment */
    top20skus: [],
    loyaltyRetailers: 0,
    loyaltyRetailersData: []
};

const AppContext = createContext({ ...initialState });

const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const getSalesLead = async () => {
        dispatch({ type: GENERATE_LEADS_BEGIN });
        try {
            const { data } = await authFetch.get('/salesplanner/salesleads');
            const { salesAssociate, currency, leadTarget, todolist, leadGProgress, noLeadGToday, leadsfollowups, message, ranks } = data;
            dispatch({
                type: GENERATE_LEADS_SUCCESS,
                payload: { salesAssociate, currency, leadTarget, todolist, leadGProgress, noLeadGToday, leadsfollowups, message, ranks }
            });
            successToast('Data loaded successfully');
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                dispatch({ type: GENERATE_LEADS_FAILURE, payload: { message } });
                errorToast(message);
            }
        }
    };

    const getMakeSalesData = async () => {
        dispatch({ type: SALES_BEGIN });
        try {
            const { data } = await authFetch.get('/salesplanner/makesales');
            const {
                salesTarget,
                todolist,
                currentTotalSales,
                previousMonthTotalSales,
                currentPreviousMonthSalesRate,
                currentNoOrders,
                previousMonthNoOrders,
                currentPreviousMonthNoOrdersRate,
                currentactualratio,
                lastmonthactualratio,
                currentPreviousMonthActualRatioRate,
                currentWhalesCaughtCount,
                lastMonthWhalesCaughtCount,
                currentPreviousMonthWhalesRate,
                retailersduefollowups,
                message
            } = data;
            dispatch({
                type: SALES_SUCCESS,
                payload: {
                    salesTarget,
                    todolist,
                    currentTotalSales,
                    previousMonthTotalSales,
                    currentPreviousMonthSalesRate,
                    currentNoOrders,
                    previousMonthNoOrders,
                    currentPreviousMonthNoOrdersRate,
                    currentactualratio,
                    lastmonthactualratio,
                    currentPreviousMonthActualRatioRate,
                    currentWhalesCaughtCount,
                    lastMonthWhalesCaughtCount,
                    currentPreviousMonthWhalesRate,
                    retailersduefollowups,
                    message
                }
            });
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                dispatch({ type: SALES_FAILURE, payload: { message } });
                errorToast(message);
            }
        }
    };

    const getCollections = async () => {
        dispatch({ type: COLLECTIONS_BEGIN });
        try {
            const { data } = await authFetch.get('/salesplanner/collectionsales');
            const {
                todolist,
                collectionsdue,
                collectionsTarget,
                currentcollectionsTotal,
                lastmontcollectionsTotal,
                currentLastMonthCollectionsRate,
                currentactualcollectionsratio,
                lastmonthactualCollectionsRatio,
                currentPreviousMonthActualCollectionRate
            } = data;

            dispatch({
                type: COLLECTIONS_SUCCESS,
                payload: {
                    todolist,
                    collectionsdue,
                    collectionsTarget,
                    currentcollectionsTotal,
                    lastmontcollectionsTotal,
                    currentLastMonthCollectionsRate,
                    currentactualcollectionsratio,
                    lastmonthactualCollectionsRatio,
                    currentPreviousMonthActualCollectionRate
                }
            });
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                dispatch({ type: COLLECTIONS_FAILURE, payload: { message } });
                errorToast(message);
            }
        }
    };

    const getDeposits = async () => {
        dispatch({ type: DEPOSITS_BEGIN });
        try {
            const { data } = await authFetch.get('/salesplanner/depositsales');
            const { message, todolist, collectionsdepositsdue, currentDepositsTotal, lastmontDepositsTotal, currentLastMonthDepositsRate } =
                data;
            dispatch({
                type: DEPOSITS_SUCCESS,
                payload: {
                    message,
                    todolist,
                    collectionsdepositsdue,
                    currentDepositsTotal,
                    lastmontDepositsTotal,
                    currentLastMonthDepositsRate
                }
            });
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                dispatch({
                    type: DEPOSITS_FAILURE,
                    payload: {
                        message
                    }
                });
                errorToast(message);
            }
        }
    };

    const filterSales = async (filterOptions) => {
        dispatch({ type: FILTER_SALES_BEGIN });
        try {
            const { data } = await authFetch.post('/salesplanner/searchretailersfollowupdata', filterOptions);
            const { data: retailersduefollowups } = data;
            dispatch({ type: FILTER_SALES_SUCCESS, payload: { retailersduefollowups } });
            const count = retailersduefollowups.length;
            const customMsg = count ? `${count} results found` : 'No results found!';
            successToast(customMsg);
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                dispatch({ type: FILTER_SALES_ERROR, payload: { message } });
                errorToast(message);
            }
        }
    };

    const resetSales = async () => {
        dispatch({ type: RESET_SALES_BEGIN });
        try {
            const { data } = await authFetch.get('/salesplanner/makesales');
            const { retailersduefollowups } = data;
            dispatch({ type: RESET_SALES, payload: { retailersduefollowups } });
        } catch (error) {
            console.log(error.response.data);
        }
    };

    const filterLeads = async (filterOptions) => {
        dispatch({ type: FILTER_LEADS_BEGIN });
        try {
            const { data } = await authFetch.post('/salesplanner/searchbyleaddate', filterOptions);
            const { data: leadsfollowups } = data;
            dispatch({ type: FILTER_LEADS_SUCCESS, payload: { leadsfollowups } });
            const count = leadsfollowups.length;
            const customMsg = count ? `${count} results found` : 'No results found!';
            successToast(customMsg);
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                dispatch({ type: FILTER_LEADS_ERROR, payload: { message } });
                errorToast(message);
            }
            console.log(error);
        }
    };

    const resetLeads = async () => {
        dispatch({ type: RESET_LEADS_BEGIN });
        try {
            const { data } = await authFetch.get('/salesplanner/salesleads');
            const { leadsfollowups } = data;
            dispatch({ type: RESET_LEADS, payload: { leadsfollowups } });
        } catch (error) {
            console.log(error.response.data);
        }
    };

    const filterCollections = async (filterOptions) => {
        dispatch({ type: FILTER_COLLECTIONS_BEGIN });
        try {
            const { data } = await authFetch.post('/salesplanner/searchcollectionsbydate', filterOptions);
            const { data: collectionsdue } = data;
            dispatch({ type: FILTER_COLLECTIONS_SUCCESS, payload: { collectionsdue } });
            const count = collectionsdue.length;
            const customMsg = count ? `${count} results found` : 'No results found!';
            successToast(customMsg);
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                dispatch({ type: FILTER_COLLECTIONS_ERROR, payload: { message } });
                errorToast(message);
            }
            console.log(error);
        }
    };

    const resetCollections = async () => {
        dispatch({ type: RESET_COLLECTIONS_BEGIN });
        try {
            const { data } = await authFetch.get('/salesplanner/collectionsales');
            const { collectionsdue } = data;
            dispatch({ type: RESET_COLLECTIONS, payload: { collectionsdue } });
        } catch (error) {
            console.log(error.response.data);
        }
    };

    const filterDeposits = async (filterOptions) => {
        dispatch({ type: FILTER_DEPOSITS_BEGIN });
        try {
            const { data } = await authFetch.post('/salesplanner/searchcollectionsbydate', filterOptions);
            const { data: collectionsdepositsdue } = data;
            dispatch({ type: FILTER_DEPOSITS_SUCCESS, payload: { collectionsdepositsdue } });
            const count = collectionsdepositsdue.length;
            const customMsg = count ? `${count} results found` : 'No results found!';
            successToast(customMsg);
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                dispatch({ type: FILTER_DEPOSITS_ERROR, payload: { message } });
                errorToast(message);
            }
            console.log(error);
        }
    };

    const resetDeposits = async () => {
        dispatch({ type: RESET_DEPOSITS_BEGIN });
        try {
            const { data } = await authFetch.get('/salesplanner/depositsales');
            const { collectionsdepositsdue } = data;
            dispatch({ type: RESET_DEPOSITS, payload: { collectionsdepositsdue } });
        } catch (error) {
            console.log(error.response.data);
        }
    };

    /** Retailer */
    const getRetailers = async () => {
        dispatch({ type: RETAILER_BEGIN });
        try {
            const { data } = await authFetch.get('/salesplanner/newretailers');
            const { newRetailerTarget, todolist, newRetailerGProgress, noRetailerGToday, newRetailerfollowups } = data;
            dispatch({
                type: RETAILER_SUCCESS,
                payload: { newRetailerTarget, todolist, newRetailerGProgress, noRetailerGToday, newRetailerfollowups }
            });
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                dispatch({ type: RETAILER_FAILURE, payload: { message } });
                errorToast(message);
            }
        }
    };

    const filterRetailers = async (filterOptions) => {
        dispatch({ type: FILTER_RETAILER_BEGIN });
        try {
            const { data } = await authFetch.post('/salesplanner/searchbyretailerdate', filterOptions);
            const { data: newRetailerfollowups } = data;
            dispatch({ type: FILTER_RETAILER_SUCCESS, payload: { newRetailerfollowups } });
            const count = newRetailerfollowups.length;
            const customMsg = count ? `${count} results found` : 'No results found!';
            successToast(customMsg);
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                dispatch({ type: FILTER_RETAILER_ERROR, payload: { message } });
                errorToast(message);
            }
            console.log(error);
        }
    };

    const resetRetailers = async () => {
        dispatch({ type: RESET_RETAILER_BEGIN });
        try {
            const { data } = await authFetch.get('/salesplanner/newretailers');
            const { newRetailerfollowups } = data;
            dispatch({ type: RESET_RETAILER, payload: { newRetailerfollowups } });
        } catch (error) {
            console.log(error.response.data);
        }
    };

    const getSupplierInfo = async () => {
        dispatch({ type: SUPPLIER_BEGIN });
        try {
            const { data } = await authFetch.get('/suppliers/supplierdashboard');
            const {
                totalPartsSoldToday,
                totalPartsSoldYesterday,
                partSoldPercentage,
                totalNoPartsToday,
                totalNoPartsYesterday,
                noPartsPercentage,
                averageAmountMadeToday,
                averageAmountMadeesterday,
                avgAmountPercentage,
                supplierTop5parts,
                totalsales,
                totalsupplierorders
            } = data;
            dispatch({
                type: SUPPLIER_SUCCESS,
                payload: {
                    totalPartsSoldToday,
                    totalPartsSoldYesterday,
                    partSoldPercentage,
                    totalNoPartsToday,
                    totalNoPartsYesterday,
                    noPartsPercentage,
                    averageAmountMadeToday,
                    averageAmountMadeesterday,
                    avgAmountPercentage,
                    supplierTop5parts,
                    totalsales,
                    totalsupplierorders
                }
            });
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                dispatch({ type: SUPPLIER_FAILURE, payload: { message } });
                errorToast(message);
            }
        }
    };

    const getSupplierBatches = async () => {
        dispatch({ type: SUPPLIER_BATCH_BEGIN });
        try {
            const { data } = await authFetch.get('/suppliers/supplierallbatch');
            dispatch({ type: SUPPLIER_BATCH_SUCCESS, payload: { orderBatches: data.data } });
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                dispatch({ type: SUPPLIER_BATCH_FAILURE, payload: { message } });
                errorToast(message);
            }
        }
    };

    const getSupplierBatchContentById = async (id) => {
        dispatch({ type: BATCH_ID_BEGIN });
        try {
            const { data } = await authFetch.get(`/suppliers/suppliershow/${id}`);
            console.log(data);

            dispatch({
                type: BATCH_ID_SUCCESS,
                payload: {
                    orderedParts: data.data?.parts,
                    currentBatchDate: data.data.batchDate,
                    currentBatchNo: data.data.batchNo,
                    batchStatusId: data.data?.batchStatusId?._id
                }
            });
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                dispatch({ type: BATCH_ID_FAILURE, payload: { message } });
                errorToast(message);
            }
        }
    };

    const handleExcelDataExport = (tableRow, docName) => {
        try {
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(tableRow);
            XLSX.utils.book_append_sheet(wb, ws, 'batches');
            XLSX.writeFile(wb, `${docName}.xlsx`);
            successToast('Excel generated successfully');
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = (el) => {
        dispatch({ type: 'HANDLE_CHANGE', payload: { item: el } });
    };

    const generateBatchReceipt = async (payload) => {
        dispatch({ type: BATCH_RECEIPT_BEGIN });
        try {
            const response = await authFetch.post('/suppliers/updatesuplierbatch', payload);
            const { message } = response.data;
            dispatch({ type: BATCH_RECEIPT_SUCCESS, payload: { message } });
            successToast('Receipt generated successfully');
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                dispatch({ type: BATCH_RECEIPT_SUCCESS, payload: { message } });
                errorToast(message);
            }
        }
    };

    /**
     * Data Extraction Query Params Setups
     */
    const getDataQueryParamsDefaults = async (countryId = country) => {
        const url = `/dataextractanalysis/querydata?country=${countryId}`;
        dispatch({ type: DATA_DEFAULTS_BEGIN });
        try {
            const { data } = await authFetch.get(url);
            const { orderStatus, paymentStatus, country, city, customer, relationshipManagers } = data;

            dispatch({
                type: DATA_DEFAULTS_SUCCESS,
                payload: { orderStatus, paymentStatus, country, city, customer, relationshipManagers }
            });
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                dispatch({ type: DATA_DEFAULTS_FAILURE, payload: { message } });
                errorToast(message);
            }
        }
    };

    /**
     * Get GMV values
     */
    const getGMVDataMetrics = async () => {
        const { customer, salesperson, orderstatus, paymentstatus, countryId, city, startdate, enddate } = state;
        let url = `/dataextractanalysis/businessanalysis?`;

        if (startdate && enddate) {
            url += `startdate=${startdate}&enddate=${enddate}`;
        }

        if (customer) {
            url += `&customer=${customer}`;
        }
        if (salesperson) {
            url += `&salesperson=${salesperson}`;
        }
        if (countryId) {
            url += `&country=${countryId}`;
        }

        if (city) {
            url += `&city=${city}`;
        }

        if (orderstatus) {
            url += `&orderstatus=${orderstatus}`;
        }

        if (paymentstatus) {
            url += `&paymentstatus=${paymentstatus}`;
        }

        dispatch({ type: GMV_BEGIN });
        try {
            const { data } = await authFetch.get(url);
            const {
                regularGMV,
                reqularOrdersNumber,
                reguarlarordersData,
                darkstoreGMV,
                darkstoreOrdersNumber,
                darkstoreordersData,
                salesDriveGMV,
                salesDriveOrdersNumber,
                salesDriveordersData,
                proformaGMV,
                proformaOrdersNumber,
                proformaordersData,
                noRetailers,
                noRetailersData,
                issuesNumber,
                issuesData,
                noNewRetailers,
                noNewRetailersData,
                leadsNumber,
                leadsData,
                partsoldNumber,
                partsSoldData,
                totalOrdersNumber,
                totalordersData,
                totalGMV,
                retentionGMV,
                retentionOrdersNumber,
                retentionordersData,
                webSrcNumber,
                mobileSrcNumber,
                totalGMVMonth,
                darkstoreGMVMonth,
                proformaGMVMonth,
                regularGMVMonth,
                retentionGMVMonth,
                salesDriveGMVMonth,
                totalMobileMonth,
                totalWebMonth,
                totalFirstTimePurchaseMonthly,
                totalLeadsMonthly,
                preSoldGMV,
                preSoldOrdersNumber,
                preSoldordersData,
                preSoldordersDataGMVMonth,
                conversionGMV,
                conversionGMVMonth,
                conversionOrdersNumber,
                conversionordersData
            } = data;
            dispatch({
                type: GMV_SUCCESS,
                payload: {
                    regularGMV,
                    reqularOrdersNumber,
                    reguarlarordersData,
                    darkstoreGMV,
                    darkstoreOrdersNumber,
                    darkstoreordersData,
                    salesDriveGMV,
                    salesDriveOrdersNumber,
                    salesDriveordersData,
                    proformaGMV,
                    proformaOrdersNumber,
                    proformaordersData,
                    noRetailers,
                    noRetailersData,
                    issuesNumber,
                    issuesData,
                    noNewRetailers,
                    noNewRetailersData,
                    leadsNumber,
                    leadsData,
                    partsoldNumber,
                    partsSoldData,
                    totalOrdersNumber,
                    totalordersData,
                    totalGMV,
                    retentionGMV,
                    retentionOrdersNumber,
                    retentionordersData,
                    webSrcNumber,
                    mobileSrcNumber,
                    totalGMVMonth,
                    darkstoreGMVMonth,
                    proformaGMVMonth,
                    regularGMVMonth,
                    retentionGMVMonth,
                    salesDriveGMVMonth,
                    totalMobileMonth,
                    totalWebMonth,
                    totalFirstTimePurchaseMonthly,
                    totalLeadsMonthly,
                    preSoldGMV,
                    preSoldOrdersNumber,
                    preSoldordersData,
                    preSoldordersDataGMVMonth,
                    conversionGMV,
                    conversionGMVMonth,
                    conversionOrdersNumber,
                    conversionordersData
                }
            });
            successToast('Data loaded successfully');
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                dispatch({ type: GMV_FAILURE, payload: { message } });
                errorToast(message);
            }
        }
    };

    const getFinancialDataMetrics = async () => {
        const { customer, salesperson, orderstatus, paymentstatus, countryId, city, startdate, enddate } = state;
        let url = `/dataextractfinance/finance?`;

        if (startdate && enddate) {
            url += `startdate=${startdate}&enddate=${enddate}`;
        }

        if (customer) {
            url += `&customer=${customer}`;
        }
        if (salesperson) {
            url += `&salesperson=${salesperson}`;
        }
        if (countryId) {
            url += `&country=${countryId}`;
        }

        if (city) {
            url += `&city=${city}`;
        }

        if (orderstatus) {
            url += `&orderstatus=${orderstatus}`;
        }

        if (paymentstatus) {
            url += `&paymentstatus=${paymentstatus}`;
        }

        dispatch({ type: FINANCE_STATS_BEGIN });
        try {
            const { data } = await authFetch.get(url);
            const {
                totalRetailCost,
                totalRetailSales,
                totalRetailSalesNumber,
                totalRetailSalesData,
                totalRetailDiscount,
                totalRetailDiscountNumber,
                totalRetailDiscountData,
                totalpayments,
                totalpaymentsData,
                totaloutstanding,
                totaloutstandingData,
                totalProfit,
                totalProfitPercentage,
                totalAverageRetailCost,
                postVatTakeRate,
                postVatTakeRatePercentage,
                postVatTakeRateData,
                totalFinanceMonthly
            } = data;
            dispatch({
                type: FINANCE_STATS_SUCCESS,
                payload: {
                    totalRetailCost,
                    totalRetailSales,
                    totalRetailSalesNumber,
                    totalRetailSalesData,
                    totalRetailDiscount,
                    totalRetailDiscountNumber,
                    totalRetailDiscountData,
                    totalpayments,
                    totalpaymentsData,
                    totaloutstanding,
                    totaloutstandingData,
                    totalProfit,
                    totalProfitPercentage,
                    totalAverageRetailCost,
                    postVatTakeRate,
                    postVatTakeRatePercentage,
                    postVatTakeRateData,
                    totalFinanceMonthly
                }
            });
        } catch (error) {
            console.log(error);
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                dispatch({ type: FINANCE_STATS_FAILURE, payload: { message } });
                errorToast(message);
            }
        }
    };

    const getCustomerCareMetrics = async () => {
        const { customer, salesperson, orderstatus, paymentstatus, countryId, city, startdate, enddate } = state;
        let url = `/dataextractcc/customercare?`;

        if (startdate && enddate) {
            url += `startdate=${startdate}&enddate=${enddate}`;
        }

        if (customer) {
            url += `&customer=${customer}`;
        }
        if (salesperson) {
            url += `&salesperson=${salesperson}`;
        }
        if (countryId) {
            url += `&country=${countryId}`;
        }

        if (city) {
            url += `&city=${city}`;
        }

        if (orderstatus) {
            url += `&orderstatus=${orderstatus}`;
        }

        if (paymentstatus) {
            url += `&paymentstatus=${paymentstatus}`;
        }

        dispatch({ type: CC_STATS_BEGIN });
        try {
            const { data } = await authFetch.get(url);
            const {
                ccOrderNumber,
                ccOrderData,
                // firstTimeRetailersNumber,
                // firstTimeRetailersData,
                // leadsNumber,
                // leadsData,
                prospectNumber,
                prospectData,
                onboardscompleteNumber,
                onboardscompleteData,
                onboardsbegunNumber,
                onboardsbegunData,
                partlistcollectedNumber,
                partlistcollectedData,
                partlistdeliveredNumber,
                partlistdeliveredData,
                notreadytopartnerNumber,
                notreadytopartnerData,
                retailerGender,
                totalGenderFeMaleMonthly,
                totalGenderMaleMonthly,
                totalGenderPreferNotAnswerMonthly,
                totalGenderUnknownMonthly,
                totalProspectMonthly,
                totalOnboardBegunonthly,
                totalonboardscompleteMonthly
            } = data;
            dispatch({
                type: CC_STATS_SUCCESS,
                payload: {
                    ccOrderNumber,
                    ccOrderData,
                    // firstTimeRetailersNumber,
                    // firstTimeRetailersData,
                    // leadsNumber,
                    // leadsData,
                    prospectNumber,
                    prospectData,
                    onboardscompleteNumber,
                    onboardscompleteData,
                    onboardsbegunNumber,
                    onboardsbegunData,
                    partlistcollectedNumber,
                    partlistcollectedData,
                    partlistdeliveredNumber,
                    partlistdeliveredData,
                    notreadytopartnerNumber,
                    notreadytopartnerData,
                    retailerGender,
                    totalGenderFeMaleMonthly,
                    totalGenderMaleMonthly,
                    totalGenderPreferNotAnswerMonthly,
                    totalGenderUnknownMonthly,
                    totalProspectMonthly,
                    totalOnboardBegunonthly,
                    totalonboardscompleteMonthly
                }
            });
        } catch (error) {
            console.log(error);
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                dispatch({ type: CC_STATS_FAILURE, payload: { message } });
                errorToast(message);
            }
        }
    };

    const getFulfillmentMetrics = async () => {
        const { customer, salesperson, orderstatus, paymentstatus, countryId, city, startdate, enddate } = state;
        let url = `/dataextractfulfillment/fufilment?`;

        if (startdate && enddate) {
            url += `startdate=${startdate}&enddate=${enddate}`;
        }

        if (customer) {
            url += `&customer=${customer}`;
        }
        if (salesperson) {
            url += `&salesperson=${salesperson}`;
        }
        if (countryId) {
            url += `&country=${countryId}`;
        }

        if (city) {
            url += `&city=${city}`;
        }

        if (orderstatus) {
            url += `&orderstatus=${orderstatus}`;
        }

        if (paymentstatus) {
            url += `&paymentstatus=${paymentstatus}`;
        }

        dispatch({ type: FULFILLMENT_ST_BEGIN });
        try {
            const { data } = await authFetch.get(url);
            const { top20skus, loyaltyRetailers, loyaltyRetailersData } = data;
            dispatch({
                type: FULFILLMENT_ST_SUCCESS,
                payload: {
                    top20skus,
                    loyaltyRetailers,
                    loyaltyRetailersData
                }
            });
        } catch (error) {
            console.log(error);
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                dispatch({ type: FULFILLMENT_ST_FAILURE, payload: { message } });
                errorToast(message);
            }
        }
    };

    const clearDataMtricsFilters = () => {
        dispatch({ type: CLEAR_FILTERS });
    };

    return (
        <AppContext.Provider
            value={{
                ...state,
                getGMVDataMetrics,
                clearDataMtricsFilters,
                getFulfillmentMetrics,
                getFinancialDataMetrics,
                getDataQueryParamsDefaults,
                getCustomerCareMetrics,
                getSalesLead,
                getMakeSalesData,
                getCollections,
                getDeposits,
                filterSales,
                resetSales,
                filterLeads,
                resetLeads,
                filterCollections,
                resetCollections,
                filterDeposits,
                resetDeposits,
                getRetailers,
                filterRetailers,
                resetRetailers,
                getSupplierInfo,
                getSupplierBatches,
                getSupplierBatchContentById,
                handleExcelDataExport,
                handleChange,
                generateBatchReceipt,
                dispatch
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

const useAppContext = () => {
    return useContext(AppContext);
};

export { AppProvider, useAppContext, initialState };
