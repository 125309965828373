/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApiUrl from 'network';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { FormControl, InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import useScriptRef from 'hooks/useScriptRef';
import { encrypt, decrypt } from '../../utils/encrypt';

const ViewWareHouse = () => {
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [country, setCountry] = useState('');
    const [countries, setCountries] = useState([]);
    const [name, setname] = useState('');
    const [cityId, setcityId] = useState('');

    const [cityData, setCityData] = useState([]);

    const [lat, setlat] = useState('');
    const [lng, setlng] = useState('');
    const scriptedRef = useScriptRef();
    const history = useNavigate();

    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const { id } = useParams();

    const retrieveAllActiveCountry = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.ActiveCountryUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    const retrieveAllCity = async (id) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.CityFilterUrl + id, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCityData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const filterCountry = (event) => {
        const newFilter = countries.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        newFilter.forEach((option) => {
            retrieveAllCity(option?._id);
        });
    };
    const handleCityChange = (event) => {
        setcityId(event.target.value);
    };

    useEffect(() => {
        // retrieve all sales associate;
        retrieveAllActiveCountry();

        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };
        setLoading(true);
        // const obj = JSON.stringify(id);
        // const ids = encrypt(obj, keyResult, ivResult);

        // const ids = JSON.stringify({ payload: result });
        fetch(ApiUrl.ViewWareHouseUrl + id, config)
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    const result = decrypt(data.data, keyResult, ivResult);
                    const answer = JSON.parse(result);
                    setname(answer?.name);
                    setCountry(answer?.country);
                    setlat(answer?.lat);
                    setlng(answer?.lng);
                    retrieveAllCity(answer?.cityId?.country);
                    setcityId(answer?.cityId?._id ?? '');
                } else {
                    const error = decrypt(data.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id, keyResult, ivResult]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading2(true);
        const userInfo = localStorage.getItem('userToken');

        const data = {
            id,
            name,
            country,
            cityId,
            lat,
            lng
        };
        const obj = JSON.stringify(data);
        const result = encrypt(obj, keyResult, ivResult);

        fetch(ApiUrl.UpdateWareHouseUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ payload: result })
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    if (scriptedRef.current) {
                        setTimeout(() => {
                            history('/warehouse');
                        }, 3000);
                    }
                } else {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);

                    setLoading2(false);
                }
            })
            .finally(() => {
                setLoading2(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <br />
                            <PageHeader title="Configuration | Sales Pod" subTitle="Home | Configuration | Sales Pod | Add" />
                            <br />
                            <MainCard title="Add Sales Pod">
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        autoComplete="off"
                                        name="name"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="name"
                                        value={name}
                                        label="Sales Pod Name"
                                        onChange={(e) => setname(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <Autocomplete
                                        id="country-select-demo"
                                        autoHighlight
                                        value={country}
                                        onChange={(event, newValue) => {
                                            setCountry(newValue);
                                            filterCountry(newValue);
                                        }}
                                        options={countries.map((option) => option.name)}
                                        isOptionEqualToValue={(option, value) => option.code === value.code}
                                        renderInput={(params) => <TextField {...params} label="Choose a country *" />}
                                    />
                                    <br />
                                    <br />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Choose a City *</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={cityId}
                                            label="City"
                                            onChange={handleCityChange}
                                        >
                                            {cityData.map((attr) => (
                                                <MenuItem key={attr._id} value={attr._id}>
                                                    {attr?.city ?? ''}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="lat"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="lat"
                                        value={lat}
                                        label="Latitude"
                                        onChange={(e) => setlat(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="lng"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="lng"
                                        value={lng}
                                        label="Longitude"
                                        onChange={(e) => setlng(e.target.value)}
                                    />
                                    <br />
                                    <br />

                                    <Box display="flex" justifyContent="space-between">
                                        <Button component={Link} to="/warehouse" type="submit" variant="contained" color="error">
                                            Reset
                                        </Button>

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            disabled={!name || !country || !cityId || !lat || !lng || loading2}
                                        >
                                            {loading2 ? 'Submitting ..' : 'Submit'}
                                        </Button>
                                    </Box>
                                </form>
                            </MainCard>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default ViewWareHouse;
