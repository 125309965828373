import styled from 'styled-components';

const Wrapper = styled.div`
    padding: 1rem 2rem;

    .table-header {
        background: #193855;
        color: #fff;
    }
`;

const NoteContent = ({ data }) => {
    const { parts } = data;

    return (
        <Wrapper>
            <div className="table-responsive">
                <table className="table table-bordered table-hover">
                    <thead>
                        <tr className="table-header">
                            <th scope="col">#</th>
                            <th scope="col">Description</th>
                            <th scope="col">Quantity Dashboard</th>
                        </tr>
                    </thead>
                    <tbody>
                        {parts &&
                            parts.map((item, index) => {
                                const { partid, qty, partType } = item;
                                return (
                                    <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{partid}</td>
                                        <td>
                                            {qty} {partType}
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </Wrapper>
    );
};
export default NoteContent;
