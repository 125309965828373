import styled from 'styled-components';

const AccordionWrapper = styled.div`
    .custom-accordion {
        font-family: 'Roboto';
        border-radius: 0;
        border: 3px solid #ff740f;
        padding: 10px px;
        margin-bottom: 50px;
        .expand-icon {
            color: #ff740f;
        }

        .todo-badge {
            color: #fff;
            background: #fff;
        }

        .btn-todo {
            background: #ff740f;
            font-weight: 900;
            margin-bottom: 20px;
        }

        .btn-count {
            padding: 4px 8px;
            border-radius: 100%;
            background: #fff;
            color: #ff740f;
            font-weight: 900;
            font-size: 0.75rem;
        }

        .title {
            color: #ff740f;
            text-transform: uppercase;
            font-weight: 800;
            font-size: 1.4rem;
        }

        .custom-table {
            border: 1px solid #add8e6;
            border-radius: 5px;
            .table__heading {
                color: #ff740f;
                font-size: 1.2rem;
                padding: 10px 20px;
            }

            .table__content {
                padding: 10px 20px;
                margin-top: 15px;
                margin-bottom: 20px;
            }
        }

        .search-lead {
            width: 100%;
            margin-bottom: 20px;
        }

        .custom-date-picker {
            padding: 10px 30px;
            border-radius: 5px;
            margin-top: 4px;
        }

        .lead-table {
            padding: 5px 10px;
        }

        .todo-dropdown {
            background: #fff;
            z-index: 2;
            border: 1px solid #eee;
            padding: 10px 20px;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        .loader-spinner {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px;
        }

        .compare-deposits {
            background: #ff740f;
        }
    }
`;

export default AccordionWrapper;
