/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';
import Controls from '../../components/controls/Controls';
import { Search } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
// import * as XLSX from 'xlsx';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import ViewIcon from '@mui/icons-material/ViewAgenda';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
// import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import ViewIcon from '@mui/icons-material/Preview';
import Tooltip from '@mui/material/Tooltip';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Avatar } from '@mui/material';

// import { saveAs } from 'file-saver';
// import axios from 'axios';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    colors: {
        color: `#ED7C1B`
    },
    backgroundColor: {
        backgroundColor: '#2CB23B'
    }
}));

const DriverNote = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [orderInfo, setOrder] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    // const [loading2, setLoading2] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    // const [totalcollection, setTotalcollection] = useState(0);

    // const [totalapproved, setTotalapproved] = useState(0);

    const handlePriceChangesClick = (row) => {
        if (scriptedRef.current) {
            history(`/previewriverorderimage/${row._id}`);
        }
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 30));
        setPage(0);
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderInfo.length) : 0;

    const retrieveAllOrders = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllDriverDeliveryNoteImageUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        toast.success(data.message);
                        setOrder(data.data);
                        setOrderData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        retrieveAllOrders();
    }, []);

    const setChange = (event, picker) => {
        console.log('startDate', picker.startDate._d);
        console.log('endDate', picker.endDate._d);
        setstartDate(picker.startDate._d);
        setendDate(picker.endDate._d);
        const userInfo = localStorage.getItem('userToken');
        const from = picker.startDate._d;
        const to = picker.endDate._d;
        setLoading(true);
        const data = {
            from,
            to
        };
        fetch(ApiUrl.FilterDriverDeliveryNoteImageUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading(false);
                    setOrder(result.data);
                    setOrderData(result.data);
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleReset = () => {
        retrieveAllOrders();
    };

    const handleSearch = (event) => {
        const searchWord = event.target.value;

        const newFilter = orderInfo.filter((value) => (value?.capturedUser || '').toLowerCase().includes((searchWord || '').toLowerCase()));

        if (searchWord === '') {
            setOrder(orderData);
        } else {
            setOrder(newFilter);
        }
    };

    return (
        <>
            <br />
            <PageHeader title="Driver Delivery Note Images" subTitle="Home | Driver Delivery Note Image " />
            <br />

            <MainCard title="Driver Delivery Note Image List">
                <Grid container spacing={2} justify="flex-start">
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography variant="h3" component="div" className={classes.colors}>
                            {orderInfo.length}
                        </Typography>
                    </Grid>
                </Grid>
                <br />

                <Grid container spacing={2} justify="flex-start">
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography variant="subtitle2" component="div">
                            Total Images Uploaded
                        </Typography>
                    </Grid>
                </Grid>
                <br />
                <br />

                <Box display="flex" justifyContent="flex-start">
                    <Controls.Input
                        label="Search Fulfillment Person"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearch}
                    />
                    <Box width={20} />

                    <br />
                    <DateRangePicker initialSettings={{ startDate, endDate }} onApply={setChange}>
                        <Button variant="contained" size="sm" className={classes.item}>
                            filter by Date
                        </Button>
                    </DateRangePicker>
                    <Box width={20} />
                    <Button variant="contained" onClick={handleReset} size="sm" className={classes.item} color="error">
                        Reset Filter
                    </Button>
                </Box>

                <br />

                <TableContainer component={Paper}>
                    <Table aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order No
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order Date
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Captured Date
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Captured User
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Image
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 ? orderInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : orderInfo).map(
                                (row) => (
                                    <TableRow key={row._id}>
                                        <TableCell component="th" scope="row">
                                            {row?.orderNo ?? ''}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row?.orderDate ? toTimestamp(row?.orderDate) : ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.capturedDate ? toTimestamp(row?.capturedDate) : ''}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row?.capturedUser ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Avatar variant="rounded" src={row?.imgFile} sx={{ width: 80, height: 50 }} />
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            <Tooltip title="View Order Details">
                                                <IconButton aria-label="view" color="success" onClick={() => handlePriceChangesClick(row)}>
                                                    <ViewIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 150, 250, { label: 'All', value: -1 }]}
                    component="div"
                    count={orderInfo.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default DriverNote;
