import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

// import { Col, Container, Row } from 'react-grid-system';
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-empty */
import { useEffect, useState } from 'react';

import Accordion from 'components/Accordion';

import ApiUrl from '../../../network';

import BatchCardList from 'components/OrderBatching/BatchCardList';
import BatchCustomHeader from 'components/OrderBatching/BatchCustomHeader';
import Button from '@mui/material/Button';
import DataPortal from 'views/DataPortal';

// import { io } from 'socket.io-client';
// import MainCard from 'ui-component/cards/MainCard';
// project imports
// import EarningCard from './EarningCard';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// import DistributorTopFiveParts from '../../dashboardDistributor/DistributorTopFiveParts';

import FleetRequestServicingCard from './FleetRequestServicingCard';
import FleetVehiclesCard from './FleetVehiclesCard';
// import GenericPageHeader from 'components/GenericPageHeader';
// import GenericStatsCard from 'components/Card/GenericStatsCard';
// import Typography from '@mui/material/Typography';
// material-ui
import { Grid } from '@mui/material';

// import Loader from 'components/Loader';
// import LoadingButton from '@mui/lab/LoadingButton';
// import TotalIncomeLightCard from './TotalIncomeLightCard';

// import Box from '@mui/material/Box';
// import PopularCard from './PopularCard';
// import TotalOrderLineChartCard from './TotalOrderLineChartCard';
// import TotalIncomeDarkCard from './TotalIncomeDarkCard';

import PageHeader from '../../../components/PageHeader';

import Supplier from 'views/supplierdash/Supplier';
// import TopFiveParts from './TopFiveParts';

// import TotalOrdersDistributor from '../../dashboardDistributor/TotalOrdersDistributor';
// import TotalRetailResultCard from './TotalRetailResultCard';
// import TotalSalesDistributor from '../../dashboardDistributor/TotalSalesDistributor';
// import { formatCurrency } from 'utils/functions';
// import TotalGrowthBarChart from './TotalGrowthBarChart';
import { gridSpacing } from 'store/constant';
import { makeStyles } from '@mui/styles';
// import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import OrderTableStatus from 'views/orders/OrderTableStatus';

// const Wrapper = styled.div`
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;

//     @media screen and (max-width: 500px) {
//         justify-content: center;
//     }
// `;
// import Paper from '@mui/material/Paper';
// import CarFront from '@mui/icons-material/CarRental';

// const statusData = [
//     {
//         value: 'today',
//         label: 'Today'
//     }
// {
//     value: 'yesterday',
//     label: 'Yesterday'
// }
// {
//     value: 'week',
//     label: 'This Week'
// },
// {
//     value: 'month',
//     label: 'This Month'
// },
// {
//     value: 'year',
//     label: 'This Year'
// }
// ];

// ==============================|| DEFAULT DASHBOARD ||============================== //
const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    colors: {
        color: `#ED7C1B`
    }
}));
const Dashboard = () => {
    const history = useNavigate();
    // const socket = useRef();
    const classes = useStyles();
    const [status, setStatus] = useState('today');
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    // const [loading2, setLoading2] = useState(false);
    const [isLoading, setLoading] = useState(false);
    // const [car, setCar] = useState(0);
    // const [models, setModels] = useState(0);
    // const [ordervalue, setordervalue] = useState(0);
    // const [requestservicevalue, setrequestservicevalue] = useState(0);
    // const [admincar, setAdminCar] = useState(0);
    // const [adminModel, setAdminModel] = useState(0);
    // const [adminusers, setAdminUser] = useState(0);
    // const [adminmechanic, setAdminMechanic] = useState(0);
    // const [adminretails, setAdminRetails] = useState(0);
    // const [admindistributor, setAdminDistributor] = useState(0);
    // const [adminfleet, setAdminFleet] = useState(0);
    // const [adminparts, setAdminParts] = useState(0);
    // const [adminorders, setAdminOrders] = useState(0);
    const userRole = localStorage.getItem('userRole');
    // const userCurrency = localStorage.getItem('userCurrency');
    // const [totaltodaysorders, settotaltodaysorders] = useState(0);
    // const [yesterdaysorders, setyesterdaysorders] = useState(0);
    // const [todayyesterdaydifference, settodayyesterdaydifference] = useState(0);
    // const [totaltodayfufilledorders, settotaltodayfufilledorders] = useState(0);
    // const [yesterdayfulfilledorders, setyesterdayfulfilledorders] = useState(0);
    // const [todayyesterdayfulfilleddifference, settodayyesterdayfulfilleddifference] = useState(0);
    // const [expTotalTodayCash, setexpTotalTodayCash] = useState(0);
    // const [expYesterdayTotalCash, setexpYesterdayTotalCash] = useState(0);
    // const [expcashtodayyesterdaydifference, setexpcashtodayyesterdaydifference] = useState(0);
    // const [actualTotalTodayCash, setactualTotalTodayCash] = useState(0);
    // const [actualYesterdayTotalCash, setactualYesterdayTotalCash] = useState(0);
    // const [actualcashtodayyesterdaydifference, setactualcashtodayyesterdaydifference] = useState(0);
    // const [totalnewretailertoday, settotalnewretailertoday] = useState(0);
    // const [yesterdaynewretailers, setyesterdaynewretailers] = useState(0);
    // const [todayyesterdayretailerdifference, settodayyesterdayretailerdifference] = useState(0);

    // const [totaltodayproformaorders, settotaltodayproformaorders] = useState(0);
    // const [yesterdayproformaorders, setyesterdayproformaorders] = useState(0);
    // const [todayyesterdayproformadifference, settodaytodayyesterdayproformadifference] = useState(0);

    // const [totaltodaycancelledorders, settotaltodaycancelledorders] = useState(0);
    // const [yesterdaycancelledorders, setyesterdaycancelledorders] = useState(0);
    // const [todayyesterdaycancelleddifference, settodayyesterdaycancelleddifference] = useState(0);

    // const [totaltodayunfulfilledorders, settotaltodayunfulfilledorders] = useState(0);
    // const [yesterdayunfulfilledorders, setyesterdayunfulfilledorders] = useState(0);
    // const [todayyesterdayunfulfilleddifference, settodayyesterdayunfulfilleddifference] = useState(0);

    // const [todayoutstandingbalance, settodayoutstandingbalance] = useState(0);
    // const [yesterdayoutstandingbalance, setyesterdayoutstandingbalance] = useState(0);
    // const [outstandingbalancedifference, setoutstandingbalancedifference] = useState(0);

    // const [avgTotalOrderValueToday, setavgTotalOrderValueToday] = useState(0);
    // const [avgTotalOrderValueYesterday, setavgTotalOrderValueYesterday] = useState(0);
    // const [avgexpcashtodayyesterdaydifference, setavgexpcashtodayyesterdaydifference] = useState(0);

    // const [todaytotalgrossmargin, settodaytotalgrossmargin] = useState(0);
    // const [yesterdaytotalgrossmargin, setyesterdaytotalgrossmargin] = useState(0);
    // const [grossmargintodayyesterdaydifference, setgrossmargintodayyesterdaydifference] = useState(0);

    // const [todaytotalcreditcashratio, settodaytotalcreditcashratio] = useState(0);
    // const [yesterdaytotalcreditcashratio, setyesterdaytotalcreditcashratio] = useState(0);
    // const [creditcashratiotodayyesterdaydifference, setcreditcashratiotodayyesterdaydifference] = useState(0);

    // const [todaylifetimeratio, settodaylifetimeratio] = useState(0);
    // const [yesterdaylifetimeratio, setyesterdaylifetimeratio] = useState(0);
    // const [lifetimetodayyesterdaydifference, setlifetimetodayyesterdaydifference] = useState(0);

    // const [resultTodayordervaluecash, setresultTodayordervaluecash] = useState(0);
    // const [resultYesterdayOrderValueCash, setresultYesterdayOrderValueCash] = useState(0);
    // const [ordervaluetodayyesterdaydifference, setordervaluetodayyesterdaydifference] = useState(0);

    // const [dateRangeTitle, setdateRangeTitle] = useState(0);
    // const [dateRangeTotalActCash, setdateRangeTotalActCash] = useState(0);
    // const [dateRangeTotalExpCash, setdateRangeTotalExpCash] = useState(0);
    // const [dateRangeTotalSubtractActCash, setdateRangeTotalSubtractActCash] = useState(0);
    // const [dateRangeTotalSubtractExpCash, setdateRangeTotalSubtractExpCash] = useState(0);
    // const [daterangeactualccdifference, setdaterangeactualccdifference] = useState(0);
    // const [daterangeexpectccdifference, setdaterangeexpectccdifference] = useState(0);
    // const [daterangeretailerdifference, setdaterangeretailerdifference] = useState(0);
    // const [daterangesubstractnewretailers, setdaterangesubstractnewretailers] = useState(0);
    // const [totalnewretailerdaterange, settotalnewretailerdaterange] = useState(0);
    // const [todaytotalleads, settodaytotalleads] = useState(0);
    // const [yesterdaytotalleads, setyesterdaytotalleads] = useState(0);
    // const [todayyesterdaytotalleadsdifference, settodayyesterdaytotalleadsdifference] = useState(0);

    // const [totalordersdaterange, settotalordersdaterange] = useState(0);
    // const [daterangesubtractorders, setdaterangesubtractorders] = useState(0);
    // const [totalordersdaterangedifference, settotalordersdaterangedifference] = useState(0);

    // const [totalfufilledordersdaterange, settotalfufilledordersdaterange] = useState(0);
    // const [daterangesubstractfulfilledorders, setdaterangesubstractfulfilledorders] = useState(0);
    // const [daterangefulfilleddifference, setdaterangefulfilleddifference] = useState(0);

    // const [totaldaterangeproformaorders, settotaldaterangeproformaorders] = useState(0);
    // const [proformaorderssubtractdaterange, setproformaorderssubtractdaterange] = useState(0);
    // const [proformadaterangedifference, setproformadaterangedifference] = useState(0);

    // const [totalcancelledordersdateranges, settotalcancelledordersdateranges] = useState(0);
    // const [datesubtractrangecancelledorders, setdatesubtractrangecancelledorders] = useState(0);
    // const [daterangecancelleddifference, setdaterangecancelleddifference] = useState(0);

    // const [totaldaterangeunfulfilledorders, settotaldaterangeunfulfilledorders] = useState(0);
    // const [daterangesubtractunfulfilledorders, setdaterangesubtractunfulfilledorders] = useState(0);
    // const [daterangeunfulfilleddifference, setdaterangeunfulfilleddifference] = useState(0);

    // const [todaytopfiveparts, settodaytopfiveparts] = useState([]);

    // const [todayprofitmade, settodayprofitmade] = useState(0);
    // const [yesterdaysProfitmade, setyesterdaysProfitmade] = useState(0);
    // const [todaysyesterdaytotalprofitcashmade, settodaysyesterdaytotalprofitcashmade] = useState(0);

    // const [daterangeoutstandingbalance, setdaterangeoutstandingbalance] = useState(0);
    // const [datesubtractionoutstandingbalance, setdatesubtractionoutstandingbalance] = useState(0);
    // const [daterangeoutstandingbalancedifference, setdaterangeoutstandingbalancedifference] = useState(0);

    // const [avgTotalOrderValueDateRange, setavgTotalOrderValueDateRange] = useState(0);
    // const [avgTotalOrderValuedatesubtract, setavgTotalOrderValuedatesubtract] = useState(0);
    // const [avgexpcashdaterangesubtractdifference, setavgexpcashdaterangesubtractdifference] = useState(0);

    // const [resultDaterangeordervaluecash, setresultDaterangeordervaluecash] = useState(0);
    // const [resultDateSubtractOrderValueCash, setresultDateSubtractOrderValueCash] = useState(0);
    // const [ordervaluedaterangesubstractdifference, setordervaluedaterangesubstractdifference] = useState(0);

    // const [daterangeprofitmade, setdaterangeprofitmade] = useState(0);
    // const [datesubtractProfitmade, setdatesubtractProfitmade] = useState(0);
    // const [daterangesubtracttotalprofitcashmadedifference, setdaterangesubtracttotalprofitcashmadedifference] = useState(0);

    // const [daterangetotalgrossmargin, setdaterangetotalgrossmargin] = useState(0);
    // const [datesubtracttotalgrossmargin, setdatesubtracttotalgrossmargin] = useState(0);
    // const [grossmargindaterangesubtractdifference, setgrossmargindaterangesubtractdifference] = useState(0);

    // const [daterangelifetimeratio, setdaterangelifetimeratio] = useState(0);
    // const [datesubtractlifetimeratio, setdatesubtractlifetimeratio] = useState(0);
    // const [lifetimedaterangesubtractdifference, setlifetimedaterangesubtractdifference] = useState(0);

    // const [daterangetotalcreditcashratiorate, setdaterangetotalcreditcashratiorate] = useState(0);
    // const [datesubtracttotalcreditcashratio, setdatesubtracttotalcreditcashratio] = useState(0);
    // const [creditcashratiodaterangesubtractdifference, setcreditcashratiodaterangesubtractdifference] = useState(0);

    // const [daterangetotalleads, setdaterangetotalleads] = useState(0);
    // const [datesubtracttotalleads, setdatesubtracttotalleads] = useState(0);
    // const [daterangesubtracttotalleadsdifference, setdaterangesubtracttotalleadsdifference] = useState(0);

    // const [daterangetopfiveparts, setdaterangetopfiveparts] = useState([]);

    // const [uniqueOrder, setuniqueOrder] = useState(0);
    // const [yesterdayuniqueordertotal, setyesterdayuniqueordertotal] = useState(0);
    // const [todayyesterdayuniqueorderdifference, settodayyesterdayuniqueorderdifference] = useState(0);

    // const [todaytotalProfitPercent, settodaytotalProfitPercent] = useState(0);
    // const [yesterdaytotalProfitPercent, setyesterdaytotalProfitPercent] = useState(0);
    // const [todayyesterdayProfitPercentRate, settodayyesterdayProfitPercentRate] = useState(0);

    // const [daterangeuniqueOrder, setdaterangeuniqueOrder] = useState(0);
    // const [datesubtractuniqueordertotal, setdatesubtractuniqueordertotal] = useState(0);
    // const [datesubtractrangeuniqueorderdifference, setdatesubtractrangeuniqueorderdifference] = useState(0);

    // const [todaytotalProfitPercentdaterange, settodaytotalProfitPercentdaterange] = useState(0);
    // const [yesterdaytotalProfitPercentdatesubtract, setyesterdaytotalProfitPercentdatesubtract] = useState(0);
    // const [todayyesterdayProfitPercentdaterangeRate, settodayyesterdayProfitPercentdaterangeRate] = useState(0);

    // const [totalretailResult, settotalretailResult] = useState([]);
    // const [totaldaterangeretailResult, settotaldaterangeretailResult] = useState([]);

    // const [totaldistributorsales, setDistributorTotalSales] = useState('');
    // const [totaldistributororders, settotaldistributororders] = useState('');
    // const [distributortopfiveparts, setDistributorTopFiveParts] = useState([]);
    // const [totaldistributorpartssold, settotaldistributorpartssold] = useState('');

    // fleet
    const [fleetvehicleToday, setfleetvehicleToday] = useState('');
    const [yesterdayvehicle, setyesterdayvehicle] = useState('');
    const [todayyesterdaydifferencefleet, settodayyesterdaydifferencefleet] = useState('');
    const [fleetrequestservicingToday, setfleetrequestservicingToday] = useState('');
    const [yesterdayrequestservicing, setyesterdayrequestservicing] = useState('');
    const [todayyesterdaydifferenceservicing, settodayyesterdaydifferenceservicing] = useState('');

    const [dateRangeTitleFleet, setdateRangeTitleFleet] = useState('');
    const [fleetvehicleDateRange, setfleetvehicleDateRange] = useState('');
    const [daterangevehicle, setdaterangevehicle] = useState('');
    const [daterangefleetvehiceldifference, setdaterangefleetvehiceldifference] = useState('');
    const [fleetrequestservicingDateRange, setfleetrequestservicingDateRange] = useState('');
    const [daterangerequestservicing, setdaterangerequestservicing] = useState('');
    const [differenceservicingdaterange, setdifferenceservicingdaterange] = useState('');

    // const [totaltodaysordersregular, settotaltodaysordersregular] = useState(0);
    // const [yesterdaysordersregular, setyesterdaysordersregular] = useState(0);
    // const [todayyesterdaydifferenceregular, settodayyesterdaydifferenceregular] = useState(0);
    // const [totaltodaysorderssalesdrive, settotaltodaysorderssalesdrive] = useState(0);
    // const [yesterdaysordersalesdrive, setyesterdaysordersalesdrive] = useState(0);
    // const [todayyesterdaydifferencesalesdrive, settodayyesterdaydifferencesalesdrive] = useState(0);

    // const [totaltodaysordersdarkstore, settotaltodaysordersdarkstore] = useState(0);
    // const [yesterdaysorderdarkstore, setyesterdaysorderdarkstore] = useState(0);
    // const [todayyesterdaydifferencedarkstore, settodayyesterdaydifferencedarkstore] = useState(0);

    // const userFirstName = localStorage.getItem('userFirstName');
    // const userLastName = localStorage.getItem('userLastName');

    // const [socket, setSocket] = useState(null);

    const retrieveAllFleetSummary = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.FleetDashboardSummary, config)
                .then((data) => data.json())
                .then((data) => {
                    // console.log('data', data);
                    setfleetvehicleToday(data?.fleetvehicleToday ?? '');
                    setyesterdayvehicle(data?.yesterdayvehicle ?? '');
                    settodayyesterdaydifferencefleet(data?.todayyesterdaydifference ?? '');
                    setfleetrequestservicingToday(data?.fleetrequestservicingToday ?? '');
                    setyesterdayrequestservicing(data?.yesterdayrequestservicing ?? '');
                    settodayyesterdaydifferenceservicing(data?.todayyesterdaydifferenceservicing ?? '');
                    setLoading(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const setChangeFleet = (event, picker) => {
        console.log('startDate', picker.startDate._d);
        console.log('endDate', picker.endDate._d);
        setstartDate(picker.startDate._d);
        setendDate(picker.endDate._d);
        setStatus('dateselect');
        // AllDateRangeMasterDashboardUrl
        const userInfo = localStorage.getItem('userToken');
        const from = picker.startDate._d;
        const to = picker.endDate._d;
        setLoading(true);
        const data = {
            from,
            to
        };

        fetch(ApiUrl.FleetDashboardDateRangeSummary, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                setLoading(false);
                // console.log('data', result);
                setdateRangeTitleFleet(result?.dateRangeTitleFleet ?? '');
                setfleetvehicleDateRange(result?.fleetvehicleDateRange ?? '');
                setdaterangevehicle(result?.daterangevehicle ?? '');
                setdaterangefleetvehiceldifference(result?.daterangefleetvehiceldifference ?? '');
                setfleetrequestservicingDateRange(result?.fleetrequestservicingDateRange ?? '');
                setdaterangerequestservicing(result?.daterangerequestservicing ?? '');
                setdifferenceservicingdaterange(result?.differenceservicingdaterange ?? '');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // const retrieveAllAdminSummary = async () => {
    //     try {
    //         const userInfo = localStorage.getItem('userToken');
    //         const config = {
    //             headers: {
    //                 'auth-token': userInfo,
    //                 'Content-Type': 'application/json'
    //             }
    //         };
    //         setLoading(true);
    //         fetch(ApiUrl.MainDashboardSummaryUrl, config)
    //             .then((data) => data.json())
    //             .then(() => {
    //                 // console.log('result', data.car);
    //                 // setAdminCar(data.car);
    //                 // setAdminModel(data.models);
    //                 // setAdminUser(data.users);
    //                 // setAdminMechanic(data.mechanic);
    //                 // setAdminRetails(data.retails);
    //                 // setAdminDistributor(data.distributor);
    //                 // setAdminFleet(data.fleet);
    //                 // setAdminParts(data.parts);
    //                 // setAdminOrders(data.orders);
    //                 setLoading(false);
    //             })
    //             .finally(() => {
    //                 setLoading(false);
    //             });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    // const retrieveDistributorFilterSummary = async () => {
    //     try {
    //         const userInfo = localStorage.getItem('userToken');
    //         const config = {
    //             headers: {
    //                 'auth-token': userInfo,
    //                 'Content-Type': 'application/json'
    //             }
    //         };
    //         setLoading(true);
    //         fetch(ApiUrl.DistributorFilterSummaryUrl, config)
    //             .then((data) => data.json())
    //             .then((data) => {
    //                 // console.log('body data', data?.savedOrderdata);
    //                 // setDistributorTotalSales(data?.distributortotalsalesamt ?? '');
    //                 // settotaldistributororders(data?.totaldistributororders ?? '');
    //                 // setDistributorTopFiveParts(data?.distributortopfiveparts ?? []);
    //                 // settotaldistributorpartssold(data?.totaldistributorpartssold ?? '');
    //             })
    //             .finally(() => {
    //                 setLoading(false);
    //             });
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };

    // const retrieveAllTodaysMatrix = async () => {
    //     try {
    //         const userInfo = localStorage.getItem('userToken');
    //         const config = {
    //             headers: {
    //                 'auth-token': userInfo,
    //                 'Content-Type': 'application/json'
    //             }
    //         };
    //         setLoading(true);
    //         fetch(ApiUrl.AllTodaysMatrixUrl, config)
    //             .then((data) => data.json())
    //             .then((data) => {
    //                 // console.log('result', data);
    //                 settotaltodaysorders(data?.totaltodaysorders ?? '');
    //                 setyesterdaysorders(data?.yesterdaysorders ?? '');
    //                 settodayyesterdaydifference(data?.todayyesterdaydifference ?? '');
    //                 settotaltodayfufilledorders(data?.totaltodayfufilledorders ?? '');
    //                 setyesterdayfulfilledorders(data?.yesterdayfulfilledorders ?? '');
    //                 settodayyesterdayfulfilleddifference(data?.todayyesterdayfulfilleddifference ?? '');
    //                 setexpTotalTodayCash(data?.expTotalTodayCash ?? '');
    //                 setexpYesterdayTotalCash(data?.expYesterdayTotalCash ?? '');
    //                 setexpcashtodayyesterdaydifference(data?.expcashtodayyesterdaydifference ?? '');
    //                 setactualTotalTodayCash(data?.actualTotalTodayCash ?? '');
    //                 setactualYesterdayTotalCash(data?.actualYesterdayTotalCash ?? '');
    //                 setactualcashtodayyesterdaydifference(data?.actualcashtodayyesterdaydifference ?? '');
    //                 settotalnewretailertoday(data?.totalnewretailertoday ?? '');
    //                 setyesterdaynewretailers(data?.yesterdaynewretailers ?? '');
    //                 settodayyesterdayretailerdifference(data?.todayyesterdayretailerdifference ?? '');
    //                 settotaltodayproformaorders(data?.totaltodayproformaorders ?? '');
    //                 setyesterdayproformaorders(data?.yesterdayproformaorders ?? '');
    //                 settodaytodayyesterdayproformadifference(data?.todayyesterdayproformadifference ?? '');
    //                 settotaltodaycancelledorders(data?.totaltodaycancelledorders ?? '');
    //                 setyesterdaycancelledorders(data?.yesterdaycancelledorders ?? '');
    //                 settodayyesterdaycancelleddifference(data?.todayyesterdaycancelleddifference ?? '');
    //                 settotaltodayunfulfilledorders(data?.totaltodayunfulfilledorders ?? '');
    //                 setyesterdayunfulfilledorders(data?.yesterdayunfulfilledorders ?? '');
    //                 settodayyesterdayunfulfilleddifference(data?.todayyesterdayunfulfilleddifference ?? '');
    //                 settodayoutstandingbalance(data?.todayoutstandingbalance ?? '');
    //                 setyesterdayoutstandingbalance(data?.yesterdayoutstandingbalance ?? '');
    //                 setoutstandingbalancedifference(data?.outstandingbalancedifference ?? '');
    //                 setavgTotalOrderValueToday(data?.avgTotalOrderValueToday ?? '');
    //                 setavgTotalOrderValueYesterday(data?.avgTotalOrderValueYesterday ?? '');
    //                 setavgexpcashtodayyesterdaydifference(data?.avgexpcashtodayyesterdaydifference ?? '');
    //                 settodaytotalgrossmargin(data?.todaytotalgrossmargin ?? '');
    //                 setyesterdaytotalgrossmargin(data?.yesterdaytotalgrossmargin ?? '');
    //                 setgrossmargintodayyesterdaydifference(data?.grossmargintodayyesterdaydifference ?? '');
    //                 settodaytotalcreditcashratio(data?.todaytotalcreditcashratio ?? '');
    //                 setyesterdaytotalcreditcashratio(data?.yesterdaytotalcreditcashratio ?? '');
    //                 setcreditcashratiotodayyesterdaydifference(data?.creditcashratiotodayyesterdaydifference ?? '');
    //                 settodaylifetimeratio(data?.todaylifetimeratio ?? '');
    //                 setyesterdaylifetimeratio(data?.yesterdaylifetimeratio ?? '');
    //                 setlifetimetodayyesterdaydifference(data?.lifetimetodayyesterdaydifference ?? '');
    //                 setresultTodayordervaluecash(data?.resultTodayordervaluecash ?? '');
    //                 setresultYesterdayOrderValueCash(data?.resultYesterdayOrderValueCash ?? '');
    //                 setordervaluetodayyesterdaydifference(data?.ordervaluetodayyesterdaydifference ?? '');
    //                 settodaytotalleads(data?.todaytotalleads ?? '');
    //                 setyesterdaytotalleads(data?.yesterdaytotalleads ?? '');
    //                 settodayyesterdaytotalleadsdifference(data?.todayyesterdaytotalleadsdifference ?? '');
    //                 settodaytopfiveparts(data?.todaytopfiveparts ?? []);

    //                 settodayprofitmade(data?.todayprofitmade ?? '');
    //                 setyesterdaysProfitmade(data?.yesterdaysProfitmade ?? '');
    //                 settodaysyesterdaytotalprofitcashmade(data?.todaysyesterdaytotalprofitcashmade ?? '');
    //                 setuniqueOrder(data?.uniqueOrder ?? '');
    //                 setyesterdayuniqueordertotal(data?.yesterdayuniqueordertotal ?? '');
    //                 settodayyesterdayuniqueorderdifference(data?.todayyesterdayuniqueorderdifference ?? '');
    //                 settodaytotalProfitPercent(data?.todaytotalProfitPercent ?? '');
    //                 setyesterdaytotalProfitPercent(data?.yesterdaytotalProfitPercent ?? '');
    //                 settodayyesterdayProfitPercentRate(data?.todayyesterdayProfitPercentRate ?? '');
    //                 settotalretailResult(data?.totalretailResult ?? []);
    //                 settotaltodaysordersregular(data?.totaltodaysordersregular ?? '');
    //                 setyesterdaysordersregular(data?.yesterdaysordersregular ?? '');
    //                 settodayyesterdaydifferenceregular(data?.todayyesterdaydifferenceregular ?? '');
    //                 settotaltodaysorderssalesdrive(data?.totaltodaysorderssalesdrive ?? '');
    //                 setyesterdaysordersalesdrive(data?.yesterdaysordersalesdrive ?? '');
    //                 settodayyesterdaydifferencesalesdrive(data?.todayyesterdaydifferencesalesdrive ?? '');
    //                 settotaltodaysordersdarkstore(data?.totaltodaysordersdarkstore ?? '');
    //                 setyesterdaysorderdarkstore(data?.yesterdaysorderdarkstore ?? '');
    //                 settodayyesterdaydifferencedarkstore(data?.todayyesterdaydifferencedarkstore ?? '');
    //             })
    //             .finally(() => {
    //                 setLoading(false);
    //             });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    // const retrieveSalesTodayMatrix = async () => {
    //     try {
    //         const userInfo = localStorage.getItem('userToken');
    //         const config = {
    //             headers: {
    //                 'auth-token': userInfo,
    //                 'Content-Type': 'application/json'
    //             }
    //         };
    //         setLoading(true);
    //         // api here
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    // const setChangeSales = (event, picker) => {
    //     console.log('startDate', picker.startDate._d);
    //     console.log('endDate', picker.endDate._d);
    //     setstartDate(picker.startDate._d);
    //     setendDate(picker.endDate._d);
    //     setStatus('dateselect');
    //     // AllDateRangeMasterDashboardUrl
    //     const userInfo = localStorage.getItem('userToken');
    //     const from = picker.startDate._d;
    //     const to = picker.endDate._d;
    //     setLoading(true);
    //     const data = {
    //         from,
    //         to
    //     };
    // };

    // const setChange = (event, picker) => {
    //     console.log('startDate', picker.startDate._d);
    //     console.log('endDate', picker.endDate._d);
    //     setstartDate(picker.startDate._d);
    //     setendDate(picker.endDate._d);
    //     setStatus('dateselect');
    //     // AllDateRangeMasterDashboardUrl
    //     const userInfo = localStorage.getItem('userToken');
    //     const from = picker.startDate._d;
    //     const to = picker.endDate._d;
    //     setLoading(true);
    //     const data = {
    //         from,
    //         to
    //     };

    //     fetch(ApiUrl.AllDateRangeMasterDashboardUrl, {
    //         method: 'POST',
    //         headers: {
    //             'auth-token': userInfo,
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify(data)
    //     })
    //         .then((res) => res.json())
    //         .then((result) => {
    //             setLoading(false);
    //             // console.log('data', result);
    //             setdateRangeTitle(result?.dateRangeTitle ?? '');
    //             setdateRangeTotalActCash(result?.dateRangeTotalActCash ?? '');
    //             setdateRangeTotalExpCash(result?.dateRangeTotalExpCash ?? '');
    //             setdateRangeTotalSubtractActCash(result?.dateRangeTotalSubtractActCash ?? '');
    //             setdateRangeTotalSubtractExpCash(result?.dateRangeTotalSubtractExpCash ?? '');
    //             setdaterangeactualccdifference(result?.daterangeactualccdifference ?? '');
    //             setdaterangeexpectccdifference(result?.daterangeexpectccdifference ?? '');
    //             setdaterangeretailerdifference(result?.daterangeretailerdifference ?? '');
    //             setdaterangesubstractnewretailers(result?.daterangesubstractnewretailers ?? '');
    //             settotalnewretailerdaterange(result?.totalnewretailerdaterange ?? '');
    //             settotalordersdaterange(result?.totalordersdaterange ?? '');
    //             setdaterangesubtractorders(result?.daterangesubtractorders ?? '');
    //             settotalordersdaterangedifference(result?.totalordersdaterangedifference ?? '');
    //             settotalfufilledordersdaterange(result?.totalfufilledordersdaterange ?? '');
    //             setdaterangesubstractfulfilledorders(result?.daterangesubstractfulfilledorders ?? '');
    //             setdaterangefulfilleddifference(result?.daterangefulfilleddifference ?? '');
    //             settotaldaterangeproformaorders(result?.totaldaterangeproformaorders ?? '');
    //             setproformaorderssubtractdaterange(result?.proformaorderssubtractdaterange ?? '');
    //             setproformadaterangedifference(result?.proformadaterangedifference ?? '');
    //             settotalcancelledordersdateranges(result?.totalcancelledordersdateranges ?? '');
    //             setdatesubtractrangecancelledorders(result?.datesubtractrangecancelledorders ?? '');
    //             setdaterangecancelleddifference(result?.daterangecancelleddifference ?? '');
    //             settotaldaterangeunfulfilledorders(result?.totaldaterangeunfulfilledorders ?? '');
    //             setdaterangesubtractunfulfilledorders(result?.daterangesubtractunfulfilledorders ?? '');
    //             setdaterangeunfulfilleddifference(result?.daterangeunfulfilleddifference ?? '');
    //             setdaterangeoutstandingbalance(result?.daterangeoutstandingbalance ?? '');
    //             setdatesubtractionoutstandingbalance(result?.datesubtractionoutstandingbalance ?? '');
    //             setdaterangeoutstandingbalancedifference(result?.daterangeoutstandingbalancedifference ?? '');
    //             setavgTotalOrderValueDateRange(result?.avgTotalOrderValueDateRange ?? '');
    //             setavgTotalOrderValuedatesubtract(result?.avgTotalOrderValuedatesubtract ?? '');
    //             setavgexpcashdaterangesubtractdifference(result?.avgexpcashdaterangesubtractdifference ?? '');
    //             setresultDaterangeordervaluecash(result?.resultDaterangeordervaluecash ?? '');
    //             setresultDateSubtractOrderValueCash(result?.resultDateSubtractOrderValueCash ?? '');
    //             setordervaluedaterangesubstractdifference(result?.ordervaluedaterangesubstractdifference ?? '');
    //             setdaterangeprofitmade(result?.daterangeprofitmade ?? '');
    //             setdatesubtractProfitmade(result?.datesubtractProfitmade ?? '');
    //             setdaterangesubtracttotalprofitcashmadedifference(result?.daterangesubtracttotalprofitcashmadedifference ?? '');
    //             setdaterangetotalgrossmargin(result?.daterangetotalgrossmargin ?? '');
    //             setdatesubtracttotalgrossmargin(result?.datesubtracttotalgrossmargin ?? '');
    //             setgrossmargindaterangesubtractdifference(result?.grossmargindaterangesubtractdifference ?? '');
    //             setdaterangelifetimeratio(result?.daterangelifetimeratio ?? '');
    //             setdatesubtractlifetimeratio(result?.datesubtractlifetimeratio ?? '');
    //             setlifetimedaterangesubtractdifference(result?.lifetimedaterangesubtractdifference ?? '');
    //             setdaterangetotalcreditcashratiorate(result?.daterangetotalcreditcashratiorate ?? '');
    //             setdatesubtracttotalcreditcashratio(result?.datesubtracttotalcreditcashratio ?? '');
    //             setcreditcashratiodaterangesubtractdifference(result?.creditcashratiodaterangesubtractdifference ?? '');
    //             setdaterangetotalleads(result?.daterangetotalleads ?? '');
    //             setdatesubtracttotalleads(result?.datesubtracttotalleads ?? '');
    //             setdaterangesubtracttotalleadsdifference(result?.daterangesubtracttotalleadsdifference ?? '');
    //             setdaterangetopfiveparts(result?.daterangetopfiveparts ?? []);
    //             setdaterangeuniqueOrder(result?.daterangeuniqueOrder ?? '');
    //             setdatesubtractuniqueordertotal(result?.datesubtractuniqueordertotal ?? '');
    //             setdatesubtractrangeuniqueorderdifference(result?.datesubtractrangeuniqueorderdifference ?? '');
    //             settodaytotalProfitPercentdaterange(result?.todaytotalProfitPercentdaterange ?? '');
    //             setyesterdaytotalProfitPercentdatesubtract(result?.yesterdaytotalProfitPercentdatesubtract ?? '');
    //             settodayyesterdayProfitPercentdaterangeRate(result?.todayyesterdayProfitPercentdaterangeRate ?? '');
    //             settotaldaterangeretailResult(result?.totaldaterangeretailResult ?? []);

    //             settotaltodaysordersregular(result?.totaltodaysordersregular ?? '');
    //             setyesterdaysordersregular(result?.yesterdaysordersregular ?? '');
    //             settodayyesterdaydifferenceregular(result?.todayyesterdaydifferenceregular ?? '');
    //             settotaltodaysorderssalesdrive(result?.totaltodaysorderssalesdrive ?? '');
    //             setyesterdaysordersalesdrive(result?.yesterdaysordersalesdrive ?? '');
    //             settodayyesterdaydifferencesalesdrive(result?.todayyesterdaydifferencesalesdrive ?? '');
    //             settotaltodaysordersdarkstore(result?.totaltodaysordersdarkstore ?? '');
    //             setyesterdaysorderdarkstore(result?.yesterdaysorderdarkstore ?? '');
    //             settodayyesterdaydifferencedarkstore(result?.todayyesterdaydifferencedarkstore ?? '');
    //         })
    //         .finally(() => {
    //             setLoading(false);
    //         });
    // };

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        if (userInfo) {
        } else {
            history('/login');
        }
        if (userRole === 'Fleet') {
            retrieveAllFleetSummary();
        } else if (userRole === 'Admin' || userRole === 'DataEntry' || userRole === 'CustomerSuccess' || userRole === 'Finance Manager') {
            // retrieveAllTodaysMatrix();
        } else if (userRole === 'Distributor') {
            // retrieveDistributorFilterSummary();
        }
    }, []);

    // useEffect(() => {
    // const scc = setsocket(io(ApiUrl.BaseUrlInfo, { reconnect: true }));
    // setSocket(io('http://localhost:3001'));
    // console.log('socket', socket);
    // const userFirstName = localStorage.getItem('userFirstName');
    // const userLastName = localStorage.getItem('userLastName');
    // const roleid = localStorage.getItem('userRole');
    // const country = localStorage.getItem('country');
    // const userid = localStorage.getItem('userId');
    // const username = `${userFirstName}${userLastName}`;
    // socket.current = io('http://localhost:3001');
    // socket.current.emit('newUser', username, rolei`d, country, userid);
    // console.log(socket.current);
    // }, []);

    return (
        <>
            <br />

            {userRole === 'Sales' && <Accordion />}
            {userRole === 'PodLead' && <Accordion />}

            {/* {userRole === 'fulfillment' && (
                <>
                    <BatchCustomHeader /> <BatchCardList />
                </>
            )} */}
            {userRole === 'fulfillment' && (
                <>
                    <OrderTableStatus />
                </>
            )}

            {userRole === 'Fleet' ? (
                <>
                    <PageHeader title="Dashboard" subTitle="Home | Dashboard " />
                    <Grid container spacing={gridSpacing}>
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item>..</Grid>

                            <Grid item>
                                <DateRangePicker initialSettings={{ startDate, endDate }} onApply={setChangeFleet}>
                                    <Button variant="contained" size="sm" className={classes.item}>
                                        filter by Date
                                    </Button>
                                </DateRangePicker>
                            </Grid>
                        </Grid>
                        {status === 'today' ? (
                            <Grid item xs={12}>
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <FleetVehiclesCard
                                            isLoading={isLoading}
                                            totalcars={fleetvehicleToday}
                                            title="Yesterday"
                                            titlecars={yesterdayvehicle}
                                            carsdifference={todayyesterdaydifferencefleet}
                                        />
                                    </Grid>
                                    <br />

                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <FleetRequestServicingCard
                                            isLoading={isLoading}
                                            totalcars={fleetrequestservicingToday}
                                            title="Yesterday"
                                            titlecars={yesterdayrequestservicing}
                                            carsdifference={todayyesterdaydifferenceservicing}
                                        />
                                    </Grid>
                                    <br />
                                </Grid>
                            </Grid>
                        ) : (
                            ''
                        )}
                        {status === 'dateselect' ? (
                            <Grid item xs={12}>
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <FleetVehiclesCard
                                            isLoading={isLoading}
                                            totalcars={fleetvehicleDateRange}
                                            title={dateRangeTitleFleet}
                                            titlecars={daterangevehicle}
                                            carsdifference={daterangefleetvehiceldifference}
                                        />
                                    </Grid>
                                    <br />

                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <FleetRequestServicingCard
                                            isLoading={isLoading}
                                            totalcars={fleetrequestservicingDateRange}
                                            title={dateRangeTitleFleet}
                                            titlecars={daterangerequestservicing}
                                            carsdifference={differenceservicingdaterange}
                                        />
                                    </Grid>
                                    <br />
                                </Grid>
                            </Grid>
                        ) : (
                            ''
                        )}
                    </Grid>
                </>
            ) : (
                ''
            )}

            {userRole === 'Distributor' && <Supplier />}

            {/* Previous Dashboard */}
            {/* {userRole === 'Distributor' ? (
                <>
                    <PageHeader title="Dashboard" subTitle="Home | Dashboard " />
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <TotalOrdersDistributor isLoading={isLoading} totalorders={totaldistributororders} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <TotalDistributorPartsSold isLoading={isLoading} totalparts={totaldistributorpartssold} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <TotalSalesDistributor
                                        isLoading={isLoading}
                                        currency={userCurrency}
                                        totalsales={totaldistributorsales}
                                    />
                                </Grid>
                                <br />
                            </Grid>
                            <br />
                            <br />

                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12} sm={6} md={4} lg={8}>
                                    <DistributorTopFiveParts
                                        isLoading={isLoading}
                                        todaytopfiveparts={distributortopfiveparts}
                                        title="Top Five Parts Ordered of all time"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            ) : (
                ''
            )} */}

            {userRole === 'Admin' ||
            userRole === 'CustomerSuccess' ||
            userRole === 'DataEntry' ||
            userRole === 'Price Sourcing' ||
            userRole === 'CustomerSuccessLead' ||
            userRole === 'SalesLead' ||
            userRole === 'HR' ||
            userRole === 'Finance Manager' ? (
                <>
                    <DataPortal />
                </>
            ) : (
                ''
            )}
        </>
    );
};

export default Dashboard;
