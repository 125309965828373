/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';
// import Controls from '../../components/controls/Controls';
// import { Search } from '@mui/icons-material';
// import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import { Col } from 'react-grid-system';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
// import swal from 'sweetalert';
// import * as XLSX from 'xlsx';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import ViewIcon from '@mui/icons-material/ViewAgenda';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
// import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
// import { saveAs } from 'file-saver';
import axios from 'axios';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import PreSoldOrderTable from 'components/PreSoldOrderTable/PreSoldOrderTable';
import { encrypt, decrypt } from '../../utils/encrypt';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import Grid from '@mui/material/Grid';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    colors: {
        color: `#ED7C1B`
    }
}));

const PreSoldCssOrders = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [orderInfo, setOrder] = useState([]);
    const [presoldOrder, setPreSoldOrder] = useState([]);
    const [preSoldOrderStatus, setPreSoldOrderStatus] = useState([]);
    const [preSoldPaymentStatus, setPreSoldPaymentStatus] = useState([]);
    const [preSoldCustomer, setPreSoldCustomer] = useState([]);
    const [preSoldCity, setPreSoldCity] = useState([]);
    const [preSoldRma, setPreSoldRma] = useState([]);
    const [preSoldOrderNo, setPreSoldOrderNo] = useState('');
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 25,
            total: 0
        }
    });
    const [selectedDate, setSelectedDate] = useState('');
    const [countries, setCountries] = useState([]);
    const [accumulatedFilters, setAccumulatedFilters] = useState({});
    const [limit, setLimit] = useState(10);
    const [loading, setLoading] = useState(false);
    // const [loading2, setLoading2] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [orderStatus, setOrderStatus] = useState([]);
    const [searched, setSearched] = useState('');
    const [totalNumberOfOrders, setTotalNumberOfOrders] = useState('');
    const userCountry = localStorage.getItem('country');
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const country = localStorage.getItem('country');

    // const [orderStatusId, setorderStatusId] = useState('');

    const limitNumber = 100;

    const limitOptions = [
        {
            label: '100',
            value: limitNumber * 1
        },
        {
            label: '200',
            value: limitNumber * 2
        },
        {
            label: '300',
            value: limitNumber * 3
        },
        {
            label: '400',
            value: limitNumber * 4
        },
        {
            label: '500',
            value: limitNumber * 5
        },
        {
            label: '600',
            value: limitNumber * 6
        },
        {
            label: '700',
            value: limitNumber * 7
        },
        {
            label: '800',
            value: limitNumber * 8
        },
        {
            label: '900',
            value: limitNumber * 9
        },
        {
            label: '1000',
            value: limitNumber * 10
        }
    ];

    // get query data
    const getQueryData = async (data) => {
        try {
            setLoading(true);
            const url = `${ApiUrl.BaseUrl}/mainorders/querydata?country=${accumulatedFilters?.country || country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setCountries(answer.country);
                        setPreSoldOrderStatus(answer.orderStatus);
                        setPreSoldPaymentStatus(answer.paymentStatus);
                        setPreSoldCustomer(answer.customer);
                        setPreSoldCity(answer.city);
                        setPreSoldRma(answer.relationshipManagers);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const countryOptions = countries?.map((item) => {
        return { label: item?.name, value: item?.name };
    });

    const orderStatusOptions = preSoldOrderStatus?.map((item) => {
        return { label: item?.name, value: item?.name };
    });

    const paymentStatusOptions = preSoldPaymentStatus?.map((item) => {
        return { label: item?.name, value: item?._id };
    });

    const customerOptions = preSoldCustomer?.map((item) => {
        return { label: item?.partnerName, value: item?._id };
    });

    const cityOptions = preSoldCity?.map((item) => {
        return { label: item?.city, value: item?._id };
    });

    const rmaOptions = preSoldRma?.map((item) => {
        return { label: item?.name, value: item?._id };
    });

    // get all orders
    const getPreSoldOrders = async (data) => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/mainorders/presolddriveorders`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            // Append accumulated filters to URL
            Object.entries(accumulatedFilters).forEach(([key, value]) => {
                if (value) {
                    urlParams.append(key, value);
                }
            });

            url = `${url}?${urlParams.toString()}`;

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setPreSoldOrder(answer);
                        setTotalNumberOfOrders(data.totalOrders);
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                total: data.totalOrders,
                                current: accumulatedFilters?.page || 1
                            }
                        });
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        getPreSoldOrders();
        getQueryData();
    }, [accumulatedFilters]);

    // filter date
    const filterDate = (value, dateString) => {
        setSelectedDate(value);
        const data = {
            startDate: dateString[0],
            endDate: dateString[1]
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter country
    const filterCountry = (value) => {
        const data = {
            country: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter Order Status
    const filterOrderStatus = (value) => {
        const data = {
            orderstatus: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter Order Status
    const filterPaymentStatus = (value) => {
        const data = {
            paymentstatus: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter Customer
    const filterCustomer = (value) => {
        const data = {
            customer: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter City
    const filterCity = (value) => {
        const data = {
            city: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter RMA
    const filterRmaPerson = (value) => {
        const data = {
            rmcc: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter by order No
    const filterOrderNo = (value) => {
        const data = {
            orderNo: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    const handleLimit = (value) => {
        setLimit(value);
        const data = {
            limit: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    const handlePage = (page) => {
        const data = {
            page: page.current,
            limit: page.pageSize
        };
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.page
            }
        });
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    const handleEditClick = (row) => {
        if (scriptedRef.current) {
            // console.log(row);

            // if (userCountry === 'Nigeria') {
            history(`/order-request-form-pre-sold/${row._id}`);
            // } else {
            //     // console.log(row);
            //     history(`/ccupdateorder/${row._id}`);
            // }
        }
    };

    // const handleShowClick = (row) => {
    //     if (scriptedRef.current) {
    //         // console.log(row);
    //         history(`/showOrder/${row._id}`);
    //     }
    // };

    const handlePdfClick = (row) => {
        // console.log('row', row);
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };
        // setLoading2(true);
        axios.post(ApiUrl.DownloadPdfOrderPreviewsUrl, row, config).then((res) => {
            window.open(res.data.data, '_blank');

            // setLoading2(false);
        });
    };

    const handleReceiptClick = (row) => {
        // console.log('row', row);
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };
        // setLoading2(true);
        axios.post(ApiUrl.DownloadPdfOrdersUrl, row, config).then((res) => {
            window.open(res.data.data, '_blank');

            // setLoading2(false);
        });
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 30));
        setPage(0);
    };

    function toTimestamp(strDate) {
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderInfo.length) : 0;

    const retrieveAllOrders = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllPreSoldOrdersUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrder(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllActiveOrderStatus = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllActiveOrderStatus, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrderStatus(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        retrieveAllOrders();
        retrieveAllActiveOrderStatus();
    }, []);

    const handledateChange = (event, picker) => {
        setstartDate(picker.startDate._d);
        setendDate(picker.endDate._d);
        const userInfo = localStorage.getItem('userToken');
        const from = picker.startDate._d;
        const to = picker.endDate._d;
        setLoading(true);
        const data = {
            from,
            to
        };
        fetch(ApiUrl.FilterPresoldOrderDateUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);

                    setLoading(false);
                    setOrder(result.data);
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const filteredRows = orderInfo.filter((row) => (row.customerNameId || '').toLowerCase().includes(searched.toLowerCase()));
    const isPlural = filteredRows && filteredRows.length > 1 ? 's' : '';
    const searchCount = searched ? `${filteredRows.length} result${isPlural} found` : 'No Results';

    // const handleSearch = (event) => {
    //     const searchWord = event.target.value;

    //     const newFilter = orderInfo.filter((value) =>
    //         (value.channelPartnerId || '').toLowerCase().includes((searchWord || '').toLowerCase())
    //     );

    //     if (searchWord === '') {
    //         setOrder(orderData);
    //     } else {
    //         setOrder(newFilter);
    //     }
    // };

    return (
        <>
            {/* <br />
            <PageHeader title="Orders" subTitle="Home | Orders | PreSold Order " />
            <br />
            <br /> */}
            {/* <MainCard title="PreSold Order List"> */}
            {/* <h4 style={{ color: '#ff740f', fontWeight: '700' }}>
                    {searched ? searchCount : `Total PreSold Orders: ${orderInfo.length}`}
                </h4> */}
            <h4
                style={{
                    color: '#0A2938',
                    fontWeight: '700',
                    fontSize: '24px',
                    borderBottom: '1px dashed #E7EAEB',
                    padding: '32px 0 24px 0'
                }}
            >{`Total PreSold Orders: ${totalNumberOfOrders}`}</h4>
            <br />
            <PreSoldOrderTable
                presoldOrder={presoldOrder}
                loading={loading}
                handleEditClick={handleEditClick}
                rmaOptions={rmaOptions}
                cityOptions={cityOptions}
                paymentStatusOptions={paymentStatusOptions}
                countryOptions={countryOptions}
                customerOptions={customerOptions}
                orderStatusOptions={orderStatusOptions}
                selectedDate={selectedDate}
                filterCity={filterCity}
                filterCountry={filterCountry}
                filterCustomer={filterCustomer}
                filterDate={filterDate}
                filterOrderNo={filterOrderNo}
                filterOrderStatus={filterOrderStatus}
                filterPaymentStatus={filterPaymentStatus}
                filterRmaPerson={filterRmaPerson}
                handleReceiptClick={handleReceiptClick}
                handlePdfClick={handlePdfClick}
                handleLimit={handleLimit}
                limitOptions={limitOptions}
                limit={limit}
                handlePage={handlePage}
                tableParams={tableParams}
            />
            <br />

            {/* <Box display="flex" justifyContent="flex-start"> */}
            {/* <Controls.Input
                        label="Search Partner Name"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearch}
                    /> */}
            {/* <Box width={180}>
                        <FormControl fullWidth style={{ marginBottom: '10px' }}>
                            <TextField
                                id="outlined-basic"
                                label="Search Partner Name"
                                variant="outlined"
                                onChange={(e) => setSearched(e.target.value)}
                            />
                        </FormControl>
                    </Box>

                    <Box width={20} />
                    <DateRangePicker initialSettings={{ startDate, endDate }} onApply={handledateChange}>
                        <Button variant="contained" size="sm" className={classes.item}>
                            filter by Date
                        </Button>
                    </DateRangePicker>
                </Box>
                <br /> */}

            {/* <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell> */}
            {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order ID
                                        </TableCell> */}
            {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order Date
                                        </TableCell> */}
            {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Channel
                                        </TableCell> */}

            {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Customer Name
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Sales Person
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            placedBy
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Cost Value
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Sales Value
                                        </TableCell> */}

            {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Cash Collected
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Cash Balance
                                        </TableCell> */}

            {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            % sold
                                        </TableCell> */}

            {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order Status
                                        </TableCell> */}
            {/* 
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Pmt Status
                                        </TableCell> */}
            {/* 
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : filteredRows
                            ).map((row, index) => (
                                <TableRow key={row._id}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell> */}
            {/* <TableCell component="th" scope="row">
                                            {row._id}
                                        </TableCell> */}
            {/* <TableCell component="th" scope="row">
                                        {toTimestamp(row?.orderDate)}
                                    </TableCell> */}
            {/* <TableCell component="th" scope="row">
                                            {row?.newChannelId?.name ?? ''}
                                        </TableCell> */}

            {/* <TableCell component="th" scope="row">
                                        {row?.customerName?.partnerName ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.salesTeamId?.name ?? ''}
                                        {row?.salesTeamId?.firstName ?? ''}
                                        {row?.salesTeamId?.lastName ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.placeBy ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {Number(row?.costValue).toFixed(2)}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {Number(row?.salesValue).toFixed(2)}
                                    </TableCell> */}
            {/* <TableCell component="th" scope="row">
                                            {Number.isFinite(row?.actCashCollected) ? Number(row?.actCashCollected).toFixed(2) : 0}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {Number.isFinite(row?.cashOutStanding)
                                                ? Number(row?.cashOutStanding).toFixed(2)
                                                : Number(row?.expCashCollected).toFixed(2)}
                                        </TableCell> */}
            {/* percent sold channel partner */}
            {/* <TableCell component="th" scope="row">
                                            {row?.newChannelPartnerId?.percentOrderSold ?? 'N/A'}
                                        </TableCell> */}
            {/* 
                                    <TableCell component="th" scope="row">
                                        {row?.receiptStatus?.name ?? ''}
                                    </TableCell> */}
            {/*
                                        <TableCell component="th" scope="row">
                                            {row?.newPaymentStatusId?.name ?? ''}
                                        </TableCell> */}

            {/* <TableCell component="th" scope="row"> */}
            {/* {row?.newPaymentStatusId?._id === '620d76a4ee512a471bf98c3e' ? (
                                                ''
                                            ) : (
                                                <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(row)}>
                                                    update
                                                </IconButton>
                                            )} */}
            {/* <IconButton aria-label="edit" color="warning" onClick={() => handleEditClick(row)}>
                                            Update
                                        </IconButton>
                                        <br />
                                        <IconButton aria-label="delete" color="success" onClick={() => handlePdfClick(row)}>
                                            Pdf
                                        </IconButton>
                                        <br />
                                        <IconButton aria-label="delete" color="primary" onClick={() => handleReceiptClick(row)}>
                                            Receipt
                                        </IconButton> */}

            {/* <IconButton aria-label="view" color="warning" onClick={() => handleShowClick(row)}>
                                                preview
                                            </IconButton> */}

            {/* <IconButton aria-label="view" color="warning" onClick={() => handleDeleteClick(row)}>
                                                delete
                                            </IconButton> */}
            {/* </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer> */}
            {/* <TablePagination
                    rowsPerPageOptions={[50, 150, 250, { label: 'All', value: -1 }]}
                    component="div"
                    count={orderInfo.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
            {/* </MainCard> */}
        </>
    );
};

export default PreSoldCssOrders;
