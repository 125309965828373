// src/services/userService.js
import ApiUrl from '../../../network'; // `${miraURL}/api/v1/whatsapp`; // 'https://mira.garagemobility.com/api/v1/whatsapp'; // ; ;
import { fetchWithAuth } from './apiService';
import { decrypt } from '../../../utils/encrypt';
import { toast } from 'react-toastify';

const miraURL = ApiUrl.MiraUrl;
const baseUrl = `${miraURL}/api/v1/whatsapp`;
const country = localStorage.getItem('country');

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const fetchUsers = async () => {
    const data = await fetchWithAuth(`${baseUrl}/users?country=${country}&limit=2000`);
    return data?.users;
};

export const fetchCustomer = async (phoneNumber, setCustomerLoader) => {
    try {
        setCustomerLoader(true); // Set loading state to true

        const response = await fetchWithAuth(`${ApiUrl.BaseUrl}/order/v2/searchcustomers?phoneNumber=${phoneNumber}`);
        return JSON.parse(decrypt(response.data, keyResult, ivResult)); // Return the data fetched from the API
    } catch (error) {
        toast.error(error.message); // Log the error for debugging
        return null; // Return null in case of an error
    } finally {
        setCustomerLoader(false); // Set loading state to false whether success or error
    }
};

export const updateUsersLastMessageTime = (setUsers, data) => {
    setUsers((prevUsers) => {
        const updatedUsers = prevUsers.map((user) => {
            if (user.phone === data.phone) {
                return {
                    ...user,
                    lastMessageTime: data?.createdAt // Update the last message time
                };
            }
            return user;
        });

        // Sort users by last message time, most recent first
        return updatedUsers.sort((a, b) => {
            const dateA = new Date(a.lastMessageTime || 0);
            const dateB = new Date(b.lastMessageTime || 0);
            return dateB - dateA;
        });
    });
};

// eslint-disable-next-line consistent-return
export const fetchUserMessages = async (phone, index, page = 1) => {
    try {
        const data = await fetchWithAuth(`${baseUrl}/user?phone=${phone}&page=${page}&limit=25`);

        // Handle expired data
        const expiresAt = data?.expires_at;

        // Add random image URL to each user
        const userWithImage = {
            ...data,
            imageUrl: `https://api.dicebear.com/9.x/pixel-art/svg?seed=${index}&r=50&size=100`
        };

        // Transform message content
        const transformedMessages = data?.messages.map((chat) => {
            let messageContent = chat.content.body;
            if (typeof messageContent === 'object' && messageContent !== null) {
                if (messageContent.text && messageContent.text.body) {
                    messageContent = messageContent.text.body;
                } else {
                    // Fallback for undefined text or body
                    messageContent = 'No content available';
                }
            } else if (messageContent === null) {
                // Fallback for null content
                messageContent = 'No content available';
            }

            let user;
            if (chat.agent === 'staff') {
                user = `${chat.user?.firstName || ''} ${chat.user?.lastName || ''}`;
            } else if (chat.agent === 'mira') {
                user = 'mira';
            } else {
                user = data.profile;
            }

            return {
                _id: chat._id,
                phone: chat.phone,
                content: messageContent,
                image: chat?.content?.url ? chat?.content?.url : chat?.content?.links,
                type: chat.content.type,
                isSent: chat.agent === 'mira' || chat.agent === 'staff',
                agent: chat.agent,
                createdAt: chat.createdAt,
                profile: chat.agent === 'user' ? data.profile : 'mira',
                whatsapp_id: chat.whatsapp_id,
                replyTo: chat?.replyTo,
                delivery: chat?.delivery,
                user
            };
        });

        // Sort messages by created date
        const sortedMessages = transformedMessages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

        return {
            userWithImage,
            sortedMessages,
            expiresAt
        };
    } catch (error) {
        toast.error('Failed to fetch user messages'); // Show error toast
        console.error(error);
        return null;
    }
};
