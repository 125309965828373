import { Link, useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Modal, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ApiUrl from '../../../network';
import { decrypt } from '../../../utils/encrypt';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [sellers, setSellers] = useState([]);

    const fetchPartSellers = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/parts/v2/viewpartscatalogrmacc/${id}`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                setSellers(decryptedData);
                setLoading(false);
            } else {
                toast.error(result.message);
                setLoading(false);
            }
        } catch (e) {
            toast(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPartSellers();
    }, [id]);

    const data = sellers?.suppliers?.map((item, index) => {
        return {
            key: item._id,
            shop_name: item?.supplier?.fullName,
            price: item?.price,
            phone_number: item?.supplier?.contact1
        };
    });

    const columns = [
        {
            title: 'Seller shop name',
            dataIndex: 'shop_name',
            key: 'shop_name'
        },
        {
            title: 'Product price',
            dataIndex: 'price',
            key: 'price'
        },
        {
            title: 'Phone numbers',
            dataIndex: 'phone_number',
            key: 'phone_number'
        }
    ];

    return (
        <div>
            <div className="m-header">
                <div>
                    <Link to={`/parts-catalog/${id}`}>
                        <h1 style={{ color: '#0A2938', display: 'flex', alignItems: 'center' }}>
                            <ArrowLeftOutlined className="mr-2" /> Sellers and Prices
                        </h1>
                    </Link>
                </div>
            </div>
            <div className="m-content mt-4">
                <div className="d-flex align-items-center mb-4">
                    <img
                        src={sellers?.partsImage}
                        style={{
                            width: '80px',
                            height: '80px',
                            objectFit: 'cover',
                            border: '1px solid #E7EAEB',
                            borderRadius: '10px'
                        }}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/images/default-image.png';
                        }}
                        alt=""
                    />
                    <div className="ml-2">
                        <span style={{ color: '#7B808C', fontSize: '16px' }}>Product name</span>
                        <h4 className="mt-2" style={{ color: '#0A2938', fontSize: '20px' }}>
                            {sellers?.sku}
                        </h4>
                    </div>
                </div>
                <div className="table-responsive">
                    <Table columns={columns} dataSource={data} loading={loading} />
                </div>
            </div>
        </div>
    );
};

export default Index;
