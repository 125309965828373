import { DownloadOutlined, FileAddOutlined, LoadingOutlined } from '@ant-design/icons';
import { Box, Typography } from '@mui/material';
import { Button, DatePicker, Input, Modal, Popover, Select, Space, Spin, Table } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import SortIcon from '@mui/icons-material/Sort';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ConfirmationModal from './ConfirmationModal';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const userInfo = localStorage.getItem('userRole');

const dateConverter = (value) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    };

    return new Date(value).toLocaleDateString('en-US', options);
};

// 2 decimal places
const twoDecimalPlaces = (value) => {
    return parseFloat(value?.toFixed(2)).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
};

const OrderTable = ({
    loading,
    handleEditClick,
    handlePackSortClick,
    handleDeliveryNoteClick,
    handleAuditLogClick,
    handlePdfClick,
    handleManualLoyaltyDiscount,
    setGetPaymentId,
    showModal,
    handleVatClick,
    handleShowClick,
    handleDispatchImageClick,
    useStyles,
    filterOrderStatus,
    filterPaymentStatus,
    filterRmaPerson,
    filterCustomer,
    filterDate,
    selectedDate,
    mainOrder,
    countryOptions,
    filterCountry,
    orderStatusOptions,
    paymentStatusOptions,
    customerOptions,
    cityOptions,
    filterCity,
    rmaOptions,
    filterOrderNo,
    downloadExcel,
    handleLimit,
    limitOptions,
    limit,
    totalcollections,
    collections,
    updateApprovalSuccess,
    setUpdatApprovalSuccess,
    setCollections,
    setTotalcollections,
    handlePage,
    tableParams
}) => {
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [isConfirmBuzlkOpen, setIsConfirmBulkOpen] = useState(false);
    const [remainingTotal, setRemainingTotal] = useState(0);
    const [open, setOpen] = useState(true);
    const [orderModal, setOrderModal] = useState(false);
    const [orderDetails, setOrderDetails] = useState([]);

    // confirmation modal
    const showConfirmationModal = () => {
        setIsConfirmationOpen(true);
    };

    const handleConfirmationOk = () => {
        setIsConfirmationOpen(false);
    };

    const handleConfirmationCancel = () => {
        setIsConfirmationOpen(false);
    };

    // confirma bulk modal
    const showConfirmBulkModal = () => {
        setIsConfirmBulkOpen(true);
    };

    const handleConfirmaBulkOk = () => {
        setIsConfirmBulkOpen(false);
    };

    const handleConfirmBulkCancel = () => {
        setIsConfirmBulkOpen(false);
    };

    const toggleOpenClose = () => {
        setOpen(!open);
    };

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54
            }}
            spin
        />
    );

    const { RangePicker } = DatePicker;

    const navigate = useNavigate();

    const userRole = localStorage.getItem('userRole');

    const columns = [
        {
            title: 'Order No',
            dataIndex: 'orderNo',
            key: 'orderNo',
            width: '100px'
        },
        {
            title: 'Order Type',
            dataIndex: 'orderType',
            key: 'orderType',
            width: '100px'
        },
        {
            title: 'Order Date',
            dataIndex: 'orderDate',
            key: 'orderDate',
            width: '180px'
        },
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'customerName',
            width: '150px'
        },

        // {
        //     title: 'Sales Person',
        //     dataIndex: 'salesPerson',
        //     key: 'salesPerson',
        //     width: '150px'
        // },
        {
            title: 'Placed by',
            dataIndex: 'placedby',
            key: 'placedby',
            width: '150px'
        },
        {
            title: 'Cost Value',
            dataIndex: 'costValue',
            key: 'costValue',
            width: '100px'
        },
        {
            title: 'Sales Value',
            dataIndex: 'salesvalue',
            key: 'salesvalue',
            width: '100px'
        },
        {
            title: 'Original Sales Value',
            dataIndex: 'originalsalesvalue',
            key: 'originalsalesvalue',
            width: '110px'
        },
        {
            title: 'Cash Collected',
            dataIndex: 'cashCollected',
            key: 'cashCollected',
            width: '100px'
        },
        {
            title: 'Loyalty/Other Discount',
            dataIndex: 'discount',
            key: 'discount',
            width: '100px'
        },
        {
            title: 'Discount volume',
            dataIndex: 'discountVolume',
            key: 'discountVolume',
            width: '130px'
        },
        {
            title: 'Credit',
            dataIndex: 'credit',
            key: 'credit',
            width: '100px'
        },
        {
            title: 'Cash Balance',
            dataIndex: 'cashBalance',
            key: 'cashBalance',
            width: '100px'
        },
        {
            title: 'Order Status',
            dataIndex: 'orderStatus',
            key: 'orderStatus',
            width: '170px'
        },
        {
            title: 'Receipt Status',
            dataIndex: 'receiptStatus',
            key: 'receiptStatus',
            width: '170px'
        },
        {
            title: 'Pmt Status',
            dataIndex: 'pmtStatus',
            key: 'pmtStatus',
            width: '170px'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: '100px'
        }
    ];

    const theme = useTheme();
    const classes = useStyles();

    const oderDetails = mainOrder?.map((result) => ({
        key: result?._id,
        orderNo: result?.fulOrderId,
        orderType: result?.orderType === 'Bulk' ? 'Regular' : result?.orderType,
        orderDate: dateConverter(result?.orderDate),
        customerName: result?.customerName?.partnerName,
        // salesPerson: result?.salesTeamId,
        placedby: result?.placeBy,
        costValue: twoDecimalPlaces(result?.costValue),
        salesvalue: twoDecimalPlaces(result?.salesValue),
        cashCollected: twoDecimalPlaces(result?.actCashCollected),
        discount: twoDecimalPlaces(result?.discount),
        originalsalesvalue: twoDecimalPlaces(result?.originalSalesValue),
        discountVolume: twoDecimalPlaces(result?.discountVolume),
        credit: result?.retailerCredit ? twoDecimalPlaces(result?.retailerCredit) : 0,
        cashBalance: Number.isFinite(result?.cashOutStanding)
            ? twoDecimalPlaces(Number(result?.cashOutStanding))
            : twoDecimalPlaces(Number(result?.salesValue)),
        orderStatus:
            result?.orderStatus === 'Delivered' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.success.light,
                        color: theme.palette.success.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.orderStatus ?? ''}</Typography>
                </Avatar>
            ) : result?.orderStatus === 'Cancelled' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.error.light,
                        color: theme.palette.error.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <DangerousRoundedIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.orderStatus ?? ''}</Typography>
                </Avatar>
            ) : result?.orderStatus === 'Received' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <RemoveCircleIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.orderStatus ?? ''}</Typography>
                </Avatar>
            ) : result?.orderStatus === 'Dispatched' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.warning.light,
                        color: theme.palette.warning.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <RemoveCircleIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.orderStatus ?? ''}</Typography>
                </Avatar>
            ) : result?.orderStatus === 'Processing' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.warning.light,
                        color: theme.palette.warning.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.orderStatus ?? ''}</Typography>
                </Avatar>
            ) : result?.orderStatus === 'ProformaInvoice' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <RemoveCircleIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.orderStatus ?? ''}</Typography>
                </Avatar>
            ) : result?.orderStatus === 'SalesDrive' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <RemoveCircleIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.orderStatus ?? ''}</Typography>
                </Avatar>
            ) : result?.orderStatus === 'OnHold' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <RemoveCircleIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.orderStatus ?? ''}</Typography>
                </Avatar>
            ) : result?.orderStatus === 'NextDay' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <RemoveCircleIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.orderStatus ?? ''}</Typography>
                </Avatar>
            ) : result?.orderStatus === 'PreSold' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <RemoveCircleIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.orderStatus ?? ''}</Typography>
                </Avatar>
            ) : (
                ''
            ),

        receiptStatus:
            result?.receiptStatus?._id === '6228632de5b218f4be037d70' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.success.light,
                        color: theme.palette.success.dark,
                        width: 155,
                        height: 40,
                        padding: 2
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.receiptStatus?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.receiptStatus?._id === '622b9bf0f3bcbc9eb0c203ea' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.error.light,
                        color: theme.palette.error.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <DangerousRoundedIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.receiptStatus?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.receiptStatus?._id === '620d76d63a9c8148935c696b' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <RemoveCircleIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.receiptStatus?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.receiptStatus?._id === '625311abbd74072935e58592' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <RemoveCircleIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.receiptStatus?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.receiptStatus?._id === '625739679a0af3ae9771daef' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.error.light,
                        color: theme.palette.error.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <DangerousRoundedIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5">{result?.receiptStatus?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.receiptStatus?._id === '622b9bcdf3bcbc9eb0c203e2' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.warning.light,
                        color: theme.palette.warning.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.receiptStatus?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.receiptStatus?._id === '6334bab7afa1158e678a87ad' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.receiptStatus?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.receiptStatus?._id === '6342dc27f627d58acac98e0f' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.receiptStatus?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.receiptStatus?._id === '636c1abb26ac75c9880bddd6' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.receiptStatus?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.receiptStatus?._id === '636c1ab126ac75c9880bddd2' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.receiptStatus?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.receiptStatus?._id === '6466771d8328e2d254fa6ac3' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.receiptStatus?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.receiptStatus?._id === '651da6eb9d048db5123bc205' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.receiptStatus?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.receiptStatus?._id === '6527fe1dba6c46cb61943f5c' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.receiptStatus?.name ?? ''}</Typography>
                </Avatar>
            ) : (
                ''
            ),
        pmtStatus:
            result?.paymentStatus?._id === '620d76a4ee512a471bf98c3e' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.success.light,
                        color: theme.palette.success.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                    <Typography variant="h5">{result?.paymentStatus?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.paymentStatus?._id === '620d7693448d1be3b4ab1b08' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.error.light,
                        color: theme.palette.error.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <DangerousRoundedIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5"> {result?.paymentStatus?.name ?? ''}</Typography>
                </Avatar>
            ) : result?.paymentStatus?._id === '6542467c04e82ed78cd79ec6' ? (
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: theme.palette.error.light,
                        color: theme.palette.error.dark,
                        width: 155,
                        height: 40
                    }}
                >
                    <DangerousRoundedIcon fontSize="small" />
                    <Box width={5} /> <Typography variant="h5"> {result?.paymentStatus?.name ?? ''}</Typography>
                </Avatar>
            ) : (
                ''
            ),

        action: (
            <>
                <Popover
                    placement="rightTop"
                    content={
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                padding: '5px 0px',
                                minWidth: '100px'
                            }}
                        >
                            {userInfo === 'DataEntry' ? (
                                ''
                            ) : (
                                <IconButton
                                    aria-label="edit"
                                    onClick={() => {
                                        showModal();
                                        setGetPaymentId(result?._id);
                                    }}
                                    sx={{
                                        fontSize: '18px',
                                        padding: '5px',
                                        borderRadius: '0px ',
                                        fontWeight: '600'
                                    }}
                                >
                                    payment
                                </IconButton>
                            )}
                            {userInfo === 'DataEntry' ? (
                                ''
                            ) : (
                                <IconButton
                                    aria-label="edit"
                                    color="primary"
                                    sx={{
                                        fontSize: '18px',
                                        padding: '5px',
                                        borderRadius: '0px ',
                                        fontWeight: '600'
                                    }}
                                    onClick={() => handleEditClick(result)}
                                >
                                    update
                                </IconButton>
                            )}
                            <IconButton
                                aria-label="delete"
                                color="success"
                                sx={{
                                    fontSize: '18px',
                                    padding: '5px',
                                    borderRadius: '0px ',
                                    fontWeight: '600'
                                    // width: '100%'
                                }}
                                onClick={() => handlePdfClick(result)}
                            >
                                receipt
                            </IconButton>
                            {userInfo === 'DataEntry' ? (
                                ''
                            ) : (
                                <IconButton
                                    aria-label="delete"
                                    color="secondary"
                                    sx={{
                                        fontSize: '18px',
                                        padding: '5px ',
                                        borderRadius: '0px ',
                                        fontWeight: '600'
                                    }}
                                    onClick={() => handleVatClick(result)}
                                >
                                    vat
                                </IconButton>
                            )}

                            {userInfo === 'DataEntry' ? (
                                ''
                            ) : (
                                <IconButton
                                    aria-label="delete"
                                    color="secondary"
                                    sx={{
                                        fontSize: '18px',
                                        padding: '5px ',
                                        borderRadius: '0px ',
                                        fontWeight: '600'
                                    }}
                                    onClick={() => handleManualLoyaltyDiscount(result)}
                                >
                                    Apply Loyalty Discount
                                </IconButton>
                            )}

                            <IconButton
                                aria-label="view"
                                color="warning"
                                sx={{
                                    fontSize: '18px',
                                    padding: '5px ',
                                    borderRadius: '0px ',
                                    fontWeight: '600'
                                }}
                                onClick={() => handleShowClick(result)}
                            >
                                preview
                            </IconButton>
                            <IconButton
                                aria-label="view"
                                color="error"
                                sx={{
                                    fontSize: '18px',
                                    padding: '5px ',
                                    borderRadius: '0px ',
                                    fontWeight: '600'
                                }}
                                onClick={() => handleAuditLogClick(result)}
                            >
                                logs
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    setOrderDetails(result.imageUploads);
                                    setOrderModal(true);
                                }}
                                sx={{
                                    borderBottom: '1px solid #ECEDEF',
                                    height: '40px',
                                    width: '100%',
                                    padding: '10px 12px',
                                    color: '#ff740f',
                                    fontWight: '500',
                                    textAlign: 'center',
                                    borderRadius: '8px 8px 0 0',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    margin: '10px 0'
                                }}
                                className="rma-action"
                            >
                                Order Image
                            </IconButton>
                            <IconButton
                                sx={{
                                    borderBottom: '1px solid #ECEDEF',
                                    height: '40px',
                                    width: '100%',
                                    padding: '10px 12px',
                                    color: '#ff740f',
                                    fontWight: '500',
                                    textAlign: 'center',
                                    borderRadius: '8px 8px 0 0',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    margin: '10px 0'
                                }}
                                onClick={() => (window.location.href = `/payment-initiation/${result?._id}`)}
                            >
                                Payment Initiation
                            </IconButton>
                            {/* {result?.paymentStatus?._id === '620d76a4ee512a471bf98c3e' ||
                            result?.paymentStatus?._id === '6542467c04e82ed78cd79ec6' ? (
                                ''
                            ) : ( */}
                            <>
                                <Tooltip title="Delivery Note Image">
                                    <IconButton
                                        aria-label="edit"
                                        className={classes.color}
                                        color="success"
                                        onClick={() => handleDeliveryNoteClick(result)}
                                        sx={{
                                            fontSize: '18px',
                                            padding: '5px',
                                            borderRadius: '0px ',
                                            fontWeight: '600'
                                        }}
                                    >
                                        <NoteAltIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Dispatch Image">
                                    <IconButton
                                        aria-label="edit"
                                        className={classes.color}
                                        color="success"
                                        onClick={() => handleDispatchImageClick(result)}
                                        sx={{
                                            fontSize: '18px',
                                            padding: '5px ',
                                            borderRadius: '0px ',
                                            fontWeight: '600'
                                        }}
                                    >
                                        <ContentPasteGoIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Pack Image">
                                    <IconButton
                                        aria-label="edit"
                                        className={classes.color}
                                        color="success"
                                        onClick={() => handlePackSortClick(result)}
                                        sx={{
                                            fontSize: '18px',
                                            padding: '5px ',
                                            borderRadius: '0px ',
                                            fontWeight: '600'
                                        }}
                                    >
                                        <SortIcon />
                                    </IconButton>
                                </Tooltip>{' '}
                            </>
                            {/* )} */}
                        </Box>
                    }
                    trigger="click"
                >
                    <MoreHorizIcon
                        sx={{
                            display: 'flex',
                            cursor: 'pointer',
                            width: '100%'
                        }}
                    />
                </Popover>
            </>
        )
    }));

    //  // download order list
    //  const { handleExcelDataExport } = useAppContext();
    //  const downloadExcel = () => {
    //      const orderFile = oderDetails.map((obj) => {
    //          return Object.fromEntries(Object.entries(obj).filter(([key]) => key !== 'key' && key !== 'value' && key !== 'action'));
    //      });

    //      if (orderFile?.length === 0) return;

    //      handleExcelDataExport(orderFile, 'Order');
    //  };

    return (
        <div>
            <>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '32px 0 8px',
                        cursor: 'pointer'
                    }}
                    onClick={toggleOpenClose}
                >
                    {' '}
                    <Typography component="span" variant="h4" sx={{ fontWeight: 500, fontSize: '18px', color: '#434A5B' }}>
                        {open ? 'Hide filters' : 'Show filters'}
                    </Typography>
                    {open ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                </Box>
                <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    sx={{
                        margin: '0 0 16px 0'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '20px',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            width: '100%'
                        }}
                    >
                        {userRole === 'Admin' ? (
                            <Box
                                sx={{
                                    width: ' 239px',
                                    margin: '24px 0 0 0px'
                                }}
                            >
                                <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Search by Country</Typography>

                                <Select
                                    style={{
                                        width: '100%'
                                    }}
                                    size="large"
                                    placeholder="Country"
                                    options={countryOptions}
                                    onChange={(value) => filterCountry(value)}
                                    filterOption={(inputValue, option) => {
                                        if (typeof option.label === 'string') {
                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }

                                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }}
                                    showSearch
                                    allowClear
                                />
                            </Box>
                        ) : null}
                        <Box
                            sx={{
                                width: ' 239px',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Search by City</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="City"
                                options={cityOptions}
                                onChange={(value) => filterCity(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 239px',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Search by Order Status</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Order Status"
                                options={orderStatusOptions}
                                onChange={(value) => filterOrderStatus(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 239px',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Search by Payment Status</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Payment Status"
                                options={paymentStatusOptions}
                                onChange={(value) => filterPaymentStatus(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 239px',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Search by Date</Typography>
                            <RangePicker
                                format="YYYY-MM-DD"
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                value={selectedDate}
                                onChange={filterDate}
                            />
                        </Box>

                        <Box
                            sx={{
                                width: ' 239px',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Search Partner Name</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Partner Name"
                                options={customerOptions}
                                onChange={(value) => filterCustomer(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>

                        <Box
                            sx={{
                                width: ' 239px',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Search OrderNo#</Typography>

                            <Input
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                placeholder="Search OrderNo#"
                                onChange={(e) => filterOrderNo(e.target.value.toLocaleUpperCase())}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 239px',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Search By RMA/CC</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="RMA/CC"
                                options={rmaOptions}
                                onChange={(value) => filterRmaPerson(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 239px',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Limit</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Select limit"
                                options={limitOptions}
                                onChange={(value) => handleLimit(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>
                    </Box>
                </Collapse>
                <Box
                    sx={{
                        width: '100%',
                        margin: '20px 0',
                        padding: '5px 0px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexWrap: 'wrap'
                    }}
                >
                    <Button
                        type="primary"
                        size="large"
                        style={{
                            backgroundColor: 'transparent',
                            borderRadius: '8px',
                            fontSize: '14px',
                            fontWeight: '500',
                            color: '#FF740F',
                            border: '1px solid #FF740F',
                            margin: '24px 24px 0 0px',
                            width: '168px',
                            height: '48px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        onClick={() => {
                            navigate(`/order-request-form`);
                        }}
                    >
                        Add new order
                    </Button>
                    <Button
                        type="primary"
                        size="large"
                        style={{
                            backgroundColor: 'transparent',
                            borderRadius: '8px',
                            fontSize: '14px',
                            fontWeight: '500',
                            color: '#FF740F',
                            border: '1px solid #FF740F',
                            margin: '24px 24px 0 0px',
                            height: '48px',
                            width: '174px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        onClick={() => {
                            downloadExcel();
                        }}
                    >
                        Download Excel
                    </Button>
                    {userInfo === 'DataEntry' ? (
                        ''
                    ) : (
                        <Button
                            type="primary"
                            size="large"
                            style={{
                                backgroundColor: '#FF740F',
                                borderRadius: '8px',
                                fontSize: '16px',
                                fontWeight: '500',
                                color: '#fff',
                                width: '181px',
                                height: '48px',
                                margin: '24px 24px 0 0px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            onClick={() => navigate('/orders/allreceiptpayments')}
                            className=" hover-secondary-button-orange"
                        >
                            View all Payments
                        </Button>
                    )}
                    {userInfo === 'DataEntry' ? (
                        ''
                    ) : (
                        <Button
                            type="primary"
                            size="large"
                            style={{
                                backgroundColor: '#233E4C',
                                borderRadius: '8px',
                                fontSize: '16px',
                                fontWeight: '500',
                                color: '#fff',
                                height: '48px',
                                width: '219px',
                                margin: '24px 24px 0 0px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            className=" hover-secondary-button-dark"
                            onClick={() => {
                                showConfirmationModal();
                                setIsConfirmationOpen(!isConfirmationOpen);
                            }}
                        >
                            Confirm Payments {remainingTotal >= 1 || remainingTotal === 0 ? `(${remainingTotal})` : `(${totalcollections})`}
                        </Button>
                    )}
                </Box>
            </>
            <Table
                loading={loading ? { indicator: <Spin indicator={antIcon} />, spinning: true / false } : ''}
                columns={columns}
                dataSource={oderDetails}
                size="middle"
                scroll={{
                    x: 'calc(700px + 50%)',
                    y: 580
                }}
                pagination={tableParams.pagination}
                onChange={handlePage}
            />
            <ConfirmationModal
                handleConfirmationOk={handleConfirmationOk}
                handleConfirmationCancel={handleConfirmationCancel}
                isConfirmationOpen={isConfirmationOpen}
                collections={collections}
                updateApprovalSuccess={updateApprovalSuccess}
                setUpdatApprovalSuccess={setUpdatApprovalSuccess}
                totalcollections={totalcollections}
                showConfirmationModal={showConfirmationModal}
                setCollections={setCollections}
                setTotalcollections={setTotalcollections}
                setRemainingTotal={setRemainingTotal}
            />
            <Modal
                title="Order Image"
                open={orderModal}
                onCancel={() => {
                    setOrderModal(false);
                }}
                width={400}
                footer={null}
            >
                <div>
                    {orderDetails.map((item) => (
                        <div
                            key={item._id}
                            style={{
                                marginBottom: '20px',
                                padding: '10px',
                                border: '1px solid #ECEDEF',
                                borderRadius: '8px'
                            }}
                        >
                            <div className="mb-4" style={{ border: '1px solid #ECEDEF', padding: '10px', borderRadius: '8px' }}>
                                <Typography
                                    variant="h6"
                                    style={{
                                        color: '#546974',
                                        fontSize: '14px'
                                    }}
                                >
                                    <span style={{ color: '9B979E' }}> Captured By: </span>
                                    <span style={{ fontSize: '16px', color: '#FF740F' }}>{item?.capturedUser}</span>
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{
                                        borderRadius: '5px',
                                        fontSize: '14px'
                                    }}
                                >
                                    Captured Date:{' '}
                                    {new Date(item?.capturedDate).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    })}
                                </Typography>
                            </div>
                            <img
                                src={item?.imgFile}
                                alt="Order"
                                style={{
                                    width: '100%',
                                    borderRadius: '8px',
                                    height: '200px',
                                    objectFit: 'cover',
                                    border: '1px solid #ECEDEF'
                                }}
                            />
                        </div>
                    ))}
                    {orderDetails.length === 0 && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '200px',
                                border: '1px solid #ECEDEF',
                                borderRadius: '8px'
                            }}
                        >
                            <Typography
                                variant="h6"
                                style={{
                                    color: '#546974',
                                    fontSize: '14px'
                                }}
                            >
                                No Order Image found
                            </Typography>
                        </div>
                    )}
                </div>
            </Modal>
            {/* <ConfirmaBulkModal
                handleConfirmaBulkOk={handleConfirmaBulkOk}
                handleConfirmBulkCancel={handleConfirmBulkCancel}
                isConfirmBulkOpen={isConfirmBulkOpen}
                collections={collections}
                updateApprovalSuccess={updateApprovalSuccess}
                setUpdatApprovalSuccess={setUpdatApprovalSuccess}
                totalcollections={totalcollections}
                showConfirmationModal={showConfirmationModal}
            /> */}
        </div>
    );
};

export default OrderTable;
