import React from 'react';

import { Typography, MenuItem, Menu } from '@mui/material';

const DriverMenuList = ({ options, handleClose, ITEM_HEIGHT, open, anchorEl }) => {
    return (
        <div>
            {' '}
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button'
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '187px'
                    },
                    sx: {
                        mt: 1
                    }
                }}
            >
                {options?.map((option) => (
                    <MenuItem key={option._id} selected={option === 'Pyxis'} onClick={handleClose}>
                        {option.firstName} {option.lastName}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default DriverMenuList;
