import WestIcon from '@mui/icons-material/West';
import { Box, List, ListItem } from '@mui/material';
import { DatePicker } from 'antd';
import { useEffect, useState } from 'react';
import { GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import { useNavigate, useParams } from 'react-router-dom';
import deliveredIcon from '../../assets/images/icons/Vectormap.svg';
import ApiUrl from 'network';
import { decrypt } from '../../utils/encrypt';
import PopUpMapProfile from './PopUpMapProfile';

const MyComponent = withScriptjs(
    withGoogleMap((props) => (
        <GoogleMap
            defaultZoom={8}
            defaultCenter={props.center}
            options={{
                streetViewControl: false,
                mapTypeControl: false
            }}
        >
            <Marker
                position={{ lat: +props?.pins?.locationLat, lng: +props?.pins?.locationLng }}
                onClick={() => props.handleMarkerClick(props.pins?._id)}
                icon={{
                    url: deliveredIcon
                }}
            >
                {props.currentPlaceId === props?.pins?._id ? (
                    <InfoWindow
                        onCloseClick={() => {
                            props.setCurrentPlaceId(null);
                        }}
                    >
                        <PopUpMapProfile pin={props?.pins} currentsLoyalty={props?.currentsLoyalty} />
                    </InfoWindow>
                ) : (
                    ''
                )}
            </Marker>
        </GoogleMap>
    ))
);

const Map = ({ mapCenter, pins, setCurrentPlaceId, currentPlaceId, handleMarkerClick, currentsLoyalty }) => {
    const key = 'AIzaSyBZqHjOL-7tPYGJfRKkROaOMxlHfWkou_0';

    return (
        <MyComponent
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            center={mapCenter}
            setCurrentPlaceId={setCurrentPlaceId}
            handleMarkerClick={handleMarkerClick}
            currentPlaceId={currentPlaceId}
            pins={pins}
            currentsLoyalty={currentsLoyalty}
        />
    );
};

const RetailerProfileMap = () => {
    const [open, setOpen] = useState(true);
    const userRole = localStorage.getItem('userRole');
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState([]);
    const [orders, setOrders] = useState([]);
    const [mainRetailer, setMainRetailer] = useState([]);
    const [previousLoyalty, setPreviousLoyalty] = useState([]);
    const [currentsLoyalty, setCurrentsLoyalty] = useState([]);
    const [analytics, setAnalytics] = useState([]);
    const [pins, setPins] = useState([]);
    const [currentPlaceId, setCurrentPlaceId] = useState(null);

    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');

    const { RangePicker } = DatePicker;
    const toggleOpenClose = () => {
        setOpen(!open);
    };

    const country = localStorage.getItem('country');
    const mapCenter = country === 'Ghana' ? { lat: 6.5, lng: -1.1 } : { lat: 6.465422, lng: 3.406448 };

    // get query data
    const getQueryData = async () => {
        try {
            setLoading(true);
            const url = `${ApiUrl.BaseUrl}/mainorders/querydata?country=${country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);

                        setMainRetailer(answer.customer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retailerOptions = mainRetailer?.map((item) => {
        return { label: item?.partnerName, value: item?._id };
    });

    // get retailer profile
    const getRetailerProfile = async (data) => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            const url = `${ApiUrl.BaseUrl}/retailerprofiles/retailerprofile?retailerId=${data || params?.id}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const info = decrypt(data.encrypted, keyResult, ivResult);
                        const infoResult = JSON.parse(info);
                        const order = decrypt(data.encrypteds, keyResult, ivResult);
                        const orderResult = JSON.parse(order);
                        setProfile(infoResult);
                        setOrders(orderResult);
                        const analytics = decrypt(data.analyticsData, keyResult, ivResult);
                        const analyticResult = JSON.parse(analytics);
                        const currentsLoyalty = decrypt(data.currentsLoyalty, keyResult, ivResult);
                        const currentsLoyaltyResult = JSON.parse(currentsLoyalty);
                        const previousLoyalty = decrypt(data.previousLoyalty, keyResult, ivResult);
                        const previousLoyaltyResult = JSON.parse(previousLoyalty);
                        setCurrentsLoyalty(currentsLoyaltyResult);
                        // setPreviousLoyalty(previousLoyaltyResult);
                        // setAnalytics(analyticResult);
                        // console.error(previousLoyaltyResult);
                        // console.error(currentsLoyaltyResult);
                        // console.error(analyticResult);
                        // console.error(currentsLoyaltyResult);

                        const regex = /^-?\d+\.\d+$/;

                        // orders with validate coordinates
                        const validLng = regex.test(infoResult?.locationLng);
                        const validLat = regex.test(infoResult?.locationLat);

                        if (validLng && validLat) {
                            setPins(infoResult);
                            setCurrentsLoyalty(currentsLoyaltyResult);
                        }
                        // console.error(orderResult);
                    } else {
                        // const error = decrypt(data.data, keyResult, ivResult);
                        // const answer = JSON.parse(error);
                        console.error(data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getQueryData();
    }, []);

    useEffect(() => {
        getRetailerProfile();
    }, [params?.id]);

    // filter retailer
    const filterRetailer = (value) => {
        getRetailerProfile(value);
    };

    const handleMarkerClick = (id) => {
        setCurrentPlaceId(id);
    };

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '1px dashed #E7EAEB',
                    alignItems: 'flex-end',
                    margin: '0px auto 15px',
                    flexWrap: 'wrap'
                }}
            >
                {' '}
                <List
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '0 0 16px 0',
                        alignItems: 'flex-start'
                        // flexDirection: 'column'
                        // margin: '0px auto 15px'
                    }}
                >
                    <WestIcon
                        sx={{
                            fontSize: '32px',
                            cursor: 'pointer',
                            margin: '0 10px 0 0',
                            '&:hover': {
                                color: '#546974'
                            },
                            '&:focus': {
                                color: '#9DA9AF'
                            }
                        }}
                        onClick={() => navigate(-1)}
                    />{' '}
                    <ListItem sx={{ fontWeight: 500, fontSize: '1.875rem', color: '#0A2938', padding: 0 }}>
                        Retailer Map{' '}
                        <span role="img" aria-label="👋🏽">
                            👋🏽
                        </span>
                    </ListItem>
                    {/* <ListItem sx={{ fontSize: '0.875rem', color: '#565C6B', padding: 0 }}>View locations of all our retailers</ListItem> */}
                </List>
            </Box>
            {/* <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    margin: '32px 0 0 0'
                }}
                onClick={toggleOpenClose}
            >
                <Typography
                    style={{
                        fontSize: '18px',
                        fontWeight: '500',
                        color: '#0A2938'
                    }}
                >
                    {open ? `Hide filters` : `Show filter`}
                </Typography>

                {open ? (
                    <KeyboardArrowUpIcon
                        style={{
                            margin: '0 0 0 2px',
                            color: '#0A2938'
                        }}
                    />
                ) : (
                    <ExpandMoreIcon
                        style={{
                            margin: '0 0 0 2px',
                            color: '#0A2938'
                        }}
                    />
                )}
            </Box> */}
            {/* <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                sx={{
                    margin: '0 0 8px 0'
                }}
            >
                <>
                    <Space
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            width: '100%'
                        }}
                    >
                        {userRole === 'Admin' ? (
                            <Box
                                sx={{
                                    width: ' 320px',
                                    margin: '8px 0 0 0px'
                                }}
                            >
                                <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Select Country</Typography>

                                <Select
                                    style={{
                                        width: '100%'
                                    }}
                                    size="large"
                                    placeholder="Select Country"
                                    // options={countryOptions}
                                    // onChange={(value) => filterCountry(value)}
                                    filterOption={(inputValue, option) => {
                                        if (typeof option.label === 'string') {
                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }

                                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }}
                                    showSearch
                                    allowClear
                                    // loading={loading}
                                />
                            </Box>
                        ) : null}
                        <Box
                            sx={{
                                width: ' 320px',
                                margin: '8px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Select Pod</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Select Pod"
                                // onChange={(value) => filterMatric(value)}
                                // options={metricOptions}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 320px',
                                margin: '8px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Conversion</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Conversion"
                                // options={podOptions}
                                // onChange={(value) => filterPod(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 320px',
                                margin: '8px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Select Tier</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Select Tier"
                                // options={timeOptions}
                                // onChange={(value) => filterTime(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 320px',
                                margin: '8px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Date</Typography>
                            <RangePicker
                                format="YYYY-MM-DD"
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                // value={selectedDate}
                                // onChange={filterDate}
                            />
                        </Box>
                    </Space>
                </>
            </Collapse> */}
            <div id="retailer-map">
                <Map
                    position={{ lat: -34.397, lng: 150.644 }}
                    mapCenter={mapCenter}
                    setCurrentPlaceId={setCurrentPlaceId}
                    handleMarkerClick={handleMarkerClick}
                    currentPlaceId={currentPlaceId}
                    pins={pins}
                    currentsLoyalty={currentsLoyalty}
                />
            </div>
        </div>
    );
};

export default RetailerProfileMap;
