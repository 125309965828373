import Person from '@mui/icons-material/Person';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import NearMeIcon from '@mui/icons-material/NearMe';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import TokenIcon from '@mui/icons-material/Token';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { useEffect, useState } from 'react';
import { dateConverter } from 'utils/functions';
import CallMadeIcon from '@mui/icons-material/CallMade';

const PopUpMapProfile = ({ pin, currentsLoyalty, profile }) => {
    const [coordinates, setCoordinates] = useState([]); // Default coordinates
    // const navigate = useNavigate();

    useEffect(() => {
        // Function to handle geocoding
        const handleGeocode = () => {
            const geocoder = new window.google.maps.Geocoder();
            const latlng = {
                lat: parseFloat(pin?.locationLat) || parseFloat(profile?.locationLat),
                lng: parseFloat(pin?.locationLng) || parseFloat(profile?.locationLng)
            };

            geocoder
                .geocode({ location: latlng })
                .then((response) => {
                    if (response.results[0]) {
                        const city = response.results[0].address_components[1].long_name;
                        setCoordinates(city);
                    } else {
                        console.log('No results found');
                    }
                })
                .catch((e) => console.log(`Geocoder failed due to: ' + ${e}`));
        };
        handleGeocode();
    }, []);

    return (
        <div>
            <div className="popup-wrapper">
                <span className="details">
                    <Person
                        sx={{
                            fontSize: '18px',
                            color: '#3B5460',
                            backgroundColor: '#F6F6F6',
                            padding: '2px',
                            borderRadius: '100%'
                        }}
                    />
                    <p className="icon"> {pin?.retailerName || profile?.retailerName}</p>
                </span>
                {/* <span className="details">
                    <EmojiEventsIcon sx={{ fontSize: 18 }} />
                    <p className="icon">hgvuy </p>
                </span> */}

                {pin?.gender ? (
                    <span className="details">
                        <PersonPinIcon
                            sx={{
                                fontSize: '18px',
                                color: '#3B5460',
                                backgroundColor: '#F6F6F6',
                                padding: '2px',
                                borderRadius: '100%'
                            }}
                        />
                        <p className="icon">Gender : </p>
                        <p className="info"> {pin?.gender}</p>
                    </span>
                ) : null}
                {pin ? (
                    <span className="details">
                        <SupportAgentIcon
                            sx={{
                                fontSize: '18px',
                                color: '#3B5460',
                                backgroundColor: '#F6F6F6',
                                padding: '2px',
                                borderRadius: '100%'
                            }}
                        />
                        <p className="icon">RMA : </p>
                        <p className="info">{pin?.salesPerson} </p>
                    </span>
                ) : null}
                <span className="details">
                    <NearMeIcon
                        sx={{
                            fontSize: '18px',
                            color: '#3B5460',
                            backgroundColor: '#F6F6F6',
                            padding: '2px',
                            borderRadius: '100%'
                        }}
                    />
                    <p className="icon">Address : </p>
                    <p className="info"> {pin?.address || profile?.address}</p>
                </span>
                {pin?.eplType ? (
                    <span className="details">
                        <AccountBalanceIcon
                            sx={{
                                fontSize: '18px',
                                color: '#3B5460',
                                backgroundColor: '#F6F6F6',
                                padding: '2px',
                                borderRadius: '100%'
                            }}
                        />
                        <p className="icon"> EPL Type :</p>
                        <p className="info"> {pin?.eplType}</p>
                    </span>
                ) : null}
                {pin ? (
                    <span className="details">
                        <TokenIcon
                            sx={{
                                fontSize: '18px',
                                color: '#3B5460',
                                backgroundColor: '#F6F6F6',
                                padding: '2px',
                                borderRadius: '100%'
                            }}
                        />
                        <p className="icon">Rating : </p>
                        <p className="info date">{pin?.rating}</p>
                    </span>
                ) : null}
                {pin ? (
                    <span className="details">
                        <AccessTimeFilledIcon
                            sx={{
                                fontSize: '18px',
                                color: '#3B5460',
                                backgroundColor: '#F6F6F6',
                                padding: '2px',
                                borderRadius: '100%'
                            }}
                        />
                        <p className="icon">Sign up date : </p>
                        <p className="info date">{dateConverter(pin?.signupDate)}</p>
                    </span>
                ) : null}
                {pin ? (
                    <span className="details">
                        <PendingActionsIcon
                            sx={{
                                fontSize: '18px',
                                color: '#3B5460',
                                backgroundColor: '#F6F6F6',
                                padding: '2px',
                                borderRadius: '100%'
                            }}
                        />
                        <p className="icon">Status : </p>
                        <p className="info">{pin?.status}</p>
                    </span>
                ) : null}
                {profile ? (
                    <a role="button" tabIndex={0} onKeyDown={() => {}} className="details" href={`/retailers/profile/${profile?._id}`}>
                        <CallMadeIcon
                            sx={{
                                fontSize: '18px',
                                color: '#FF740F',
                                backgroundColor: '#F6F6F6',
                                padding: '3px',
                                borderRadius: '100%'
                            }}
                        />
                        <p className="icon" style={{ color: '#FF740F' }}>
                            View Profile{' '}
                        </p>
                    </a>
                ) : null}
            </div>
        </div>
    );
};

export default PopUpMapProfile;
