// eslint-disable-next-line import/prefer-default-export
export const styles = {
    header: {
        backgroundColor: '#EDF1FF',
        color: '#0A2938',
        padding: 20,
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headerContent: {
        fontFamily: 'Poppins',
        fontSize: 30,
        fontWeight: 500,
        lineHeight: '38px',
        textAlign: 'left',
        color: '#0A2938'
    },
    contentHeaderParagraph: {
        fontFamily: 'Poppins',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px',
        textAlign: 'left',
        color: '#0A2938'
    },
    content: {
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left',
        color: '#696E7C'
    },
    filters: {
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left',
        color: '#696E7C'
    },
    filterButton: {
        width: '140px',
        borderRadius: '8px',
        backgroundColor: '#FF740F',
        color: '#fff',
        border: 'none',
        marginRight: '10px'
    },
    resetButton: {
        width: 'fit-content',
        borderRadius: '8px',
        backgroundColor: '#fff',
        color: '#000',
        border: '1px solid #D9DBDE'
    },
    table: {
        borderRadius: '16px',
        backgroundColor: '#fff',
        border: '1px solid #E5E5E5'
    },
    actionButton: {
        backgroundColor: 'transparent',
        color: '#FF740F',
        border: '1px solid #FF740F',
        borderRadius: '8px',
        fontFamily: 'Poppins',
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 500,
        width: 'fit-content'
    },
    modalHeader: {
        fontFamily: 'Poppins',
        fontSize: 24,
        fontWeight: 500,
        lineHeight: '32px',
        textAlign: 'left',
        color: '#E7EAEB'
    },
    modalParagraph: {
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left',
        color: '#E7EAEB'
    },
    saveButton: {
        width: 'fit-content',
        borderRadius: '8px',
        backgroundColor: '#FF740F',
        color: '#fff',
        border: 'none'
    },
    modalHeaderContainer: {
        backgroundColor: '#233E4C',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#E7EAEB',
        padding: 20,
        borderRadius: 10
    },
    tabButtonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '10px'
    },
    todo: {
        backgroundColor: '#E7EAEB',
        color: '#B6BFC3',
        border: 'none',
        borderRadius: '4px',
        width: 'fit-content',
        flexGrow: 1,
        marginRight: '10px',
        height: '44px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '27px'
    },
    inprogress: {
        backgroundColor: '#E7EAEB',
        color: '#B6BFC3',
        border: 'none',
        borderRadius: '4px',
        width: 'fit-content',
        flexGrow: 1,
        marginRight: '10px',
        height: '44px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '27px'
    },
    complete: {
        backgroundColor: '#E7EAEB',
        color: '#B6BFC3',
        border: 'none',
        borderRadius: '4px',
        width: 'fit-content',
        flexGrow: 1,
        height: '44px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '27px'
    },
    default: {
        backgroundColor: '#E7EAEB',
        color: '#B6BFC3',
        border: 'none',
        borderRadius: '4px',
        width: 'fit-content',
        flexGrow: 1,
        marginRight: '10px',
        height: '44px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '27px'
    },
    input: {
        width: '235px',
        height: '42px',
        color: '#000',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '400',
        marginRight: '10px'
    },
    datePicker: {
        width: '235px',
        height: '42px',
        color: '#000',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '400',
        marginRight: '10px'
    },
    select: {
        width: '235px',
        color: '#000',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '400',
        marginRight: '10px',
        height: '47px',
        marginTop: '2px'
    },
    addButton: {
        borderRadius: '4px',
        backgroundColor: '#FF740F',
        color: '#fff',
        border: 'none',
        width: 'fit-content',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '400',
        padding: '0 10px',
        marginBottom: '5px'
    },
    clearButton: {
        borderRadius: '4px',
        backgroundColor: 'transparent',
        color: '#FF740F',
        border: '1px solid #FF740F',
        width: 'fit-content',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '400',
        padding: '0 10px',
        marginBottom: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    searchButton: {
        borderRadius: '4px',
        backgroundColor: '#FF740F',
        color: '#fff',
        border: 'none',
        width: 'fit-content',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '400',
        padding: '0 10px',
        marginBottom: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '10px'
    }
};
