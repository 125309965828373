// src/services/messageService.js
import { fetchWithAuth } from './apiService';
import { toast } from 'react-toastify';
import ApiUrl from '../../../network';
import normFile from '../../../imageToBucket';

const userFirstName = localStorage.getItem('userFirstName');
const userLastName = localStorage.getItem('userLastName');

export const transformMessage = (data, selectedUser) => ({
    phone: data.phone,
    content: data?.content?.body,
    image: data?.content?.link,
    type: data?.content?.type,
    isSent: data?.agent === 'mira' || data?.agent === 'staff',
    agent: data?.agent,
    user: selectedUser?.profile,
    replyTo: data?.whatsapp_reply,
    delivery: data?.delivery,
    createdAt: data?.createdAt
});

// eslint-disable-next-line consistent-return
export const sendMessage = (socket, message, messageType, selectedUser, replyTo, imageUrl) => {
    if ((socket && message.trim()) || !message) {
        const newMessage = {
            phone: selectedUser.phone,
            content: {
                body: message,
                type: messageType,
                url: messageType === 'image' || messageType === 'document' || messageType === 'video' ? imageUrl : ''
            },
            user_id: localStorage.getItem('userId'),
            replyTo: replyTo.whatsapp_id
        };
        socket.emit('mira_chat', newMessage);

        return {
            _id: Date.now(),
            phone: selectedUser.phone,
            content: message,
            type: messageType,
            isSent: true,
            agent: 'staff',
            image: messageType === 'image' || messageType === 'document' || messageType === 'video' ? imageUrl : null,
            user: `${userFirstName} ${userLastName}`,
            createdAt: new Date().toISOString()
        };
    }
};

// eslint-disable-next-line consistent-return
export const imageUploadRequest = async (file, setFileList, setImageUrl, setLoading) => {
    try {
        toast.loading('file is uploading');
        const token = localStorage.getItem('userToken');
        const rootURL = ApiUrl.BaseUrl;
        const compressedImage = await normFile(file, token, rootURL);
        if (compressedImage) {
            setFileList((prevList) => [...prevList, { uid: file.uid, url: compressedImage, name: file.name }]);
            setImageUrl((prevUrls) => [...prevUrls, compressedImage]); // Store as an array
            toast.dismiss();

            return {
                status: 'done',
                url: compressedImage
            };
        }
    } catch (error) {
        setLoading(false);
        toast.error(error.message);
    }
};
