import React from 'react';
import Momo from './ghana/Index';
import Bank from './nigeria/Index';

const userCountry = localStorage.getItem('country');
export const Index = () => {
    return <div>{userCountry === 'Ghana' ? <Momo /> : <Bank />}</div>;
};

export default Index;
