/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { Col } from 'react-grid-system';
// import Controls from '../../components/controls/Controls';
// import { Search } from '@mui/icons-material';
// import InputAdornment from '@mui/material/InputAdornment';
import { Link, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import ViewIcon from '@mui/icons-material/ViewAgenda';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
// import Grid from '@mui/material/Grid';
// import Button from '@mui/material/Button';
// import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
// import Typography from '@mui/material/Typography';
// import ViewIcon from '@mui/icons-material/Preview';
// import Tooltip from '@mui/material/Tooltip';
// import DateRangePicker from 'react-bootstrap-daterangepicker';
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-daterangepicker/daterangepicker.css';

// import { saveAs } from 'file-saver';
// import axios from 'axios';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    colors: {
        color: `#ED7C1B`
    }
}));

const Inventory = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [orderInfo, setOrder] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [startDate, setstartDate] = useState(new Date());
    // const [endDate, setendDate] = useState(new Date());
    // const [loading2, setLoading2] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searched, setSearched] = useState('');

    const handleEditClick = (row) => {
        if (scriptedRef.current) {
            // console.log(row);
            history(`/showinventory/${row.partId?._id}`);
        }
    };

    // const handlePdfClick = (row) => {
    //     // console.log('row', row);
    //     const userInfo = localStorage.getItem('userToken');
    //     const config = {
    //         headers: {
    //             'auth-token': userInfo,
    //             'Content-Type': 'application/json'
    //         }
    //     };

    //     axios.post(ApiUrl.DownloadPdfOrdersUrl, row, config).then((res) => {
    //         window.open(res.data.data, '_blank');

    //     });
    // };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderInfo.length) : 0;

    //   const handleEditClick = (row) => {
    //     if (scriptedRef.current) {
    //         // console.log(row);
    //         history(`/updateinventory/${row._id}`);
    //     }
    // };

    // const handleShowClick = (row) => {
    //     if (scriptedRef.current) {
    //         // console.log(row);
    //         history(`/showinventory/${row._id}`);
    //     }
    // };
    const retrieveAllInventory = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllInventoryUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        // console.log('body', data.data);
                        setOrder(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        retrieveAllInventory();
    }, []);

    // const handleDeleteClick = (row) => {
    //     swal({
    //         title: 'Are you sure?',
    //         text: `Once deleted, you will not be able to recover this Order !(${row._id})`,
    //         icon: 'warning',
    //         buttons: true,
    //         dangerMode: true
    //     }).then((willDelete) => {
    //         if (willDelete) {
    //             swal('Poof! The Order has been deleted!', {
    //                 icon: 'success'
    //             });

    //             const data = {
    //                 id: row._id
    //             };
    //             const userInfo = localStorage.getItem('userToken');

    //             fetch(ApiUrl.RemoveInventoryUrl, {
    //                 method: 'DELETE',
    //                 headers: {
    //                     Authorization: userInfo,
    //                     'Content-Type': 'application/json',
    //                     'auth-token': userInfo
    //                 },
    //                 body: JSON.stringify(data)
    //             })
    //                 .then((res) => res.json())
    //                 .then((result) => {
    //                     if (result.status === true) {
    //                         if (scriptedRef.current) {
    //                             // retrieveAllOrders();
    //                             // window.location.href = '/stage';
    //                             // history('/stage');
    //                         }
    //                     } else {
    //                         toast.error(result.data);
    //                     }
    //                 });
    //         } else {
    //             swal('The  Order is safe!');
    //         }
    //     });
    // };

    // const setChange = (event, picker) => {
    //     console.log('startDate', picker.startDate._d);
    //     console.log('endDate', picker.endDate._d);
    //     setstartDate(picker.startDate._d);
    //     setendDate(picker.endDate._d);
    // };
    // const handleCallback = (start, end, label) => {
    //     console.log(start, end, label);
    // };

    const filteredRows = orderInfo.filter((row) => row?.partId?.sku.toLowerCase().includes(searched.toLowerCase()));
    const isPlural = filteredRows && filteredRows.length > 1 ? 's' : '';
    const searchCount = searched ? `${filteredRows.length} result${isPlural} found` : 'No Results';

    // const handleSearch = (event) => {
    //     const searchWord = event.target.value;

    //     const newFilter = orderInfo.filter((value) => (value?.partId?.sku || '').toLowerCase().includes((searchWord || '').toLowerCase()));

    //     if (searchWord === '') {
    //         setOrder(orderData);
    //     } else {
    //         setOrder(newFilter);
    //     }
    // };

    return (
        <>
            <PageHeader title="Inventory" subTitle="Home | Inventory " />

            <MainCard title="Inventory List">
                <h4 style={{ color: '#ff740f', fontWeight: '700' }}>{searched ? searchCount : `Total Parts: ${orderInfo.length}`}</h4>
                <br />
                {/* <Grid container spacing={2} justify="flex-start">
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography variant="h3" component="div" className={classes.colors}>
                            {total} GHC
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography variant="h3" component="div" className={classes.colors}>
                            {orderInfo.length}
                        </Typography>
                    </Grid>
                </Grid>
                <br />

                <Grid container spacing={2} justify="flex-start">
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography variant="subtitle2" component="div">
                            Total Transaction Value
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography variant="subtitle2" component="div">
                            Total # Transaction
                        </Typography>
                    </Grid>
                </Grid>
                <br />
                <br /> */}

                <Box display="flex" justifyContent="flex-start">
                    {/* <Controls.Input
                        label="Search Part"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearch}
                    /> */}
                    <Box width={180}>
                        <FormControl fullWidth style={{ marginBottom: '10px' }}>
                            <TextField
                                id="outlined-basic"
                                label="Search Product"
                                variant="outlined"
                                onChange={(e) => setSearched(e.target.value)}
                            />
                        </FormControl>
                    </Box>
                    <Box width={40} />

                    <Button component={Link} to="/addinventory" variant="contained" size="sm" className={classes.item}>
                        New
                    </Button>
                    <Box width={40} />
                    <Button component={Link} to="/stockadjustment" color="success" variant="contained" size="sm" className={classes.item}>
                        Stock Adjustment
                    </Button>

                    {/* <br />
                    <DateRangePicker initialSettings={{ startDate, endDate }} onApply={setChange}>
                        <Button variant="contained" size="sm" className={classes.item}>
                            filter by Date
                        </Button>
                    </DateRangePicker> */}
                </Box>

                <br />

                <TableContainer component={Paper}>
                    <Table aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Sku No.
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Sku Name
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Qty
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            In Stock Date
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Reference
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Supplier
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Wholesale Price
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : filteredRows
                            ).map((row, index) => (
                                <TableRow key={row._id}>
                                    <TableCell component="th" scope="row">
                                        {filteredRows.length - index}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.partId?._id ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.partId?.sku ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.qty ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {toTimestamp(row?.stockDate)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.reference ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.supplier?.partnerName ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.wholeSalePrice ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(row)}>
                                            history
                                        </IconButton>
                                        {/* <IconButton aria-label="delete" color="success" onClick={() => handleDeleteClick(row)}>
                                                delete
                                            </IconButton> */}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    component="div"
                    count={orderInfo.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default Inventory;
