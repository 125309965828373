const PriorityCard = ({ title, value }) => {
    return (
        <>
            <div className="card mb-3 shadow-sm">
                <div className="card-body">
                    <h5 className="card-title">{value}</h5>
                    <h6 className="card-text">{title}</h6>
                </div>
            </div>
        </>
    );
};
export default PriorityCard;
