/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import * as XLSX from 'xlsx';
import { useParams } from 'react-router-dom';
// import { useTheme } from '@mui/material/styles';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import useScriptRef from 'hooks/useScriptRef';
import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
// import Card from '@mui/material/Card';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import styled from 'styled-components';
// import { height } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
// import { array } from 'prop-types';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    },
    backgroundColor: {
        backgroundColor: '#FF740F'
    },
    color: {
        color: '#FF740F',
        fontWeight: 'bold'
    },
    cancelColor: {
        cancelColor: '#D3DADF'
    },
    coloricon: {
        color: '#CACACA'
    },
    coloricons: {
        color: '#FFF'
    }
}));

const BulkUploadVehicle = () => {
    const classes = useStyles();
    // const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [file, setFiles] = useState({ name: 'myfile.pdf' });
    const [loading, setLoading] = useState(false);
    const [lastupload, setlastupload] = useState([]);
    // const [loading2, setLoading2] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    // const [pageinfo, setpageinfo] = useState('tableview');
    console.log(file);

    // const commonStyles = {
    //     bgcolor: 'background.paper',
    //     m: 2,
    //     border: 2
    // };

    const { id } = useParams();

    const retrieveAllLastUpload = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.BulkUploadListFleetPartnerVehicleUrl + id, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        // console.log('data', data.data);
                        setlastupload(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - lastupload.length) : 0;
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 30));
        setPage(0);
    };
    function toTimestamp(strDate) {
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // const handlePageChange = (event) => {
    //     setpageinfo(event.target.value);
    // };

    useEffect(() => {
        retrieveAllLastUpload();
        // setcompanyName(company);
        // setchannelPartnerId(channelPartnerId);
    }, []);

    const selectedFile = async (e) => {
        e.preventDefault();
        setFiles(e.target.files[0]);
        const formData = new FormData();
        // console.log('file', e.target.files[0]);
        // console.log('name', e.target.files[0].name);
        // console.log('size', e.target.files[0].size);
        formData.append('file', e.target.files[0]);

        const userInfo = localStorage.getItem('userToken');
        // const company = localStorage.getItem('company');
        // const channelPartnerId = localStorage.getItem('channelpartnerId');
        setLoading(true);
        if (
            e.target.files[0].type === 'application/vnd.ms-excel' ||
            e.target.files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
            try {
                const data = await e.target.files[0].arrayBuffer();
                const workbook = XLSX.read(data);
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                const datas = [];
                jsonData.map((row) => {
                    row.id = id;
                    datas.push(row);
                });
                // jsonData.id = id;
                // console.log('jsonData', jsonData);

                fetch(ApiUrl.UploadBulkVehicleFleetPartnerUrl, {
                    method: 'POST',
                    headers: {
                        'auth-token': userInfo,
                        'Content-Type': 'application/json'
                    },

                    body: JSON.stringify(datas)
                })
                    .then((res) => res.json())
                    .then((result) => {
                        if (result.status) {
                            if (scriptedRef.current) {
                                setTimeout(() => {
                                    toast.success(result.message);
                                    retrieveAllLastUpload();
                                    setLoading(false);
                                }, 3000);
                            }
                        } else {
                            toast.error(result.data);
                            setLoading(false);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } catch (e) {
                console.log(e);
            }
        } else {
            toast.error('File Type not supported, kindly upload excel');
        }
    };

    return (
        <>
            <PageHeader title="Home | Fleet Management" subTitle="Home | Fleet Management | Fleet Partner | Bulk Upload" />

            <MainCard title="">
                <Typography variant="color" component="div" className={classes.color} color="warning">
                    BULK UPLOAD
                </Typography>
                <br />
                <hr />
                <br />
                <Typography variant="subtitle2" component="h3">
                    Download the add vehicle template, add your data then upload it here for processing. After upload you will be able to
                    edit the uploaded data
                </Typography>
                <br />

                <a
                    href="https://aukiss-api-images.s3.amazonaws.com/3cc4cba1fgbgana_vehicles.xlsx"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                >
                    <Button>
                        <FileDownloadIcon className={classes.coloricon} />
                        Download add vehicle template
                    </Button>
                </a>
                <br />
                <br />
                {loading ? (
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                            <CircularProgress size={70} thickness={4} />
                        </div>
                    </>
                ) : (
                    <>
                        <Section>
                            <div className="file-card">
                                <div className="file-inputs">
                                    <input
                                        type="file"
                                        onChange={selectedFile}
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    />
                                    <Button>
                                        <i>
                                            <ArrowUpwardIcon className={classes.coloricons} fontSize="large" />
                                        </i>
                                    </Button>
                                </div>
                                <p className="main">Upload filled in template here</p>
                                <p className="info">click to select. supported file xlsx</p>
                            </div>
                        </Section>
                    </>
                )}

                <br />
                <br />
                <Grid item xs={12} sm={6} md={4} lg={12}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 450 }} aria-label="caption table">
                            <TableHead>
                                <TableRow>
                                    {loading ? (
                                        <TableCell component="th" colSpan="4" align="center">
                                            {' '}
                                            <CircularProgress size={20} thickness={4} />
                                            {'LOADING...... '}
                                        </TableCell>
                                    ) : (
                                        <>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                #
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Vehicle Make
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Vehicle Model
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Registration No.
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Year
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Vin
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Engine No.
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Date
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? lastupload.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : lastupload
                                ).map((row, index) => (
                                    <TableRow key={row._id}>
                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.carName ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.carmodelName ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.carRegistrationNo ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.year ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.vinNo ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.engineNo ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {toTimestamp(row?.createdAt) ?? ''}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[50, 70, 150, { label: 'All', value: -1 }]}
                        component="div"
                        count={lastupload.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
            </MainCard>
        </>
    );
};
export default BulkUploadVehicle;
const Section = styled.section`
    .file-card {
        padding: 1em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 3px dashed #cbd5e0;
        background-color: #edf2f7;
        min-width: 280px;
        min-height: 230px;

        .file-inputs {
            position: relative;
            margin-bottom: 1.5em;

            input {
                position: relative;
                text-align: right;
                opacity: 0;
                z-index: 2;
                cursor: pointer;
                height: 46px;
                max-width: 100px;
            }

            button {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 50%;
                height: 70%;
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;

                color: #fff;
                background-color: #ff740f;
                font-size: 1.1rem;
                cursor: pointer;
                border-radius: 4px;
                border: none;
                outline: none;
                // padding: 1em;
                transition: background-color 0.4s;
                box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);

                i {
                    width: 1.5em;
                    height: 1.5em;
                    padding: 0.4em;
                    color: #f55e30;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 0.8em;
                    margin-left: 0.8em;
                    margin-top: 1em;
                    font-size: 0.8em;
                }
            }

            &:hover {
                button {
                    background-color: #f15120;
                }
            }
        }
    }

    .main {
        font-weight: bold;
        margin-bottom: 0.4em;
    }

    .info {
        font-size: 0.8rem;
    }
`;
