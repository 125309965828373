/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import Button from '@mui/material/Button';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import PageHeader from '../../components/PageHeader';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import useScriptRef from 'hooks/useScriptRef';
import Avatar from '@mui/material/Avatar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import { useTheme } from '@mui/material/styles';
import * as XLSX from 'xlsx';

// import styled from 'styled-components';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const RetailerAllOrdersTracker = () => {
    // page states

    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [orderInfo, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const theme = useTheme();

    const handleShowClick = (row) => {
        if (scriptedRef.current) {
            // console.log(row);
            history(`/viewretailerdebittracker/${row._id}`);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    const handledataexport = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(orderInfo);
        XLSX.utils.book_append_sheet(wb, ws, 'RetailerOrders');
        XLSX.writeFile(wb, 'RetailerOrders.xlsx');
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderInfo.length) : 0;

    const { id } = useParams();

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };
        setLoading(true);
        fetch(ApiUrl.RetailerDebitCreditHistoryUrl + id, config)
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    console.log('order', data.data);
                    setOrders(data.data);
                } else {
                    toast.error(data.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    return (
        <>
            <Grid item xs={12} sm={12}>
                <br />
                <PageHeader title="Orders History" subTitle="Home | Retailer Credit/Debit Tracker | Order History" />
                <br />
                <MainCard title="">
                    <br />
                    <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                        <Button component={Link} to="/retailerdebittracker" type="submit" variant="contained" color="error">
                            Back
                        </Button>
                        <Button onClick={handledataexport} variant="contained" size="sm" className={classes.backgroundColor}>
                            Download Excel
                        </Button>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table aria-label="caption table">
                            <TableHead>
                                <TableRow>
                                    {loading ? (
                                        <TableCell component="th" colSpan="4" align="center">
                                            {' '}
                                            <CircularProgress size={20} thickness={4} />
                                            {'LOADING...... '}
                                        </TableCell>
                                    ) : (
                                        <>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                #
                                            </TableCell>
                                            {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order ID
                                        </TableCell> */}
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Order Date
                                            </TableCell>
                                            {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Channel
                                        </TableCell> */}

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Channel Partner
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Sales Person
                                            </TableCell>

                                            {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order Type
                                        </TableCell> */}

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Order Value
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Exp.Cash
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Cash Collected
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Discount
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Credit
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Cash Balance
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Order Status
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Receipt Status
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Pmt Status
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Actions
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0 ? orderInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : orderInfo).map(
                                    (row, index) => (
                                        <TableRow key={row._id}>
                                            <TableCell component="th" scope="row">
                                                {index + 1}
                                            </TableCell>
                                            {/* <TableCell component="th" scope="row">
                                            {row._id}
                                        </TableCell> */}
                                            <TableCell component="th" scope="row">
                                                {toTimestamp(row?.orderDate)}
                                            </TableCell>
                                            {/* <TableCell component="th" scope="row">
                                            {row?.newChannelId?.name ?? ''}
                                        </TableCell> */}

                                            <TableCell component="th" scope="row">
                                                {row?.newChannelPartnerId?.partnerName ?? ''}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row?.salesTeamId?.name ?? ''}
                                                {row?.salesTeamId?.firstName ?? ''}
                                                {row?.salesTeamId?.lastName ?? ''}
                                            </TableCell>

                                            {/* <TableCell component="th" scope="row">
                                            {row.orderTypId}
                                        </TableCell> */}

                                            <TableCell component="th" scope="row">
                                                {Number(row?.orderValue).toFixed(2)}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {Number(row?.expCashCollected).toFixed(2)}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {Number.isFinite(row?.actCashCollected) ? Number(row?.actCashCollected).toFixed(2) : 0}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {Number.isFinite(row?.discount) ? Number(row?.discount).toFixed(2) : 0}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {Number.isFinite(row?.retailerCredit) ? Number(row?.retailerCredit).toFixed(2) : 0}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {Number.isFinite(row?.cashOutStanding)
                                                    ? Number(row?.cashOutStanding).toFixed(2)
                                                    : Number(row?.expCashCollected).toFixed(2)}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {row?.fulStatus === 'Delivered' ? (
                                                    <Avatar
                                                        variant="rounded"
                                                        sx={{
                                                            ...theme.typography.commonAvatar,
                                                            ...theme.typography.largeAvatar,
                                                            backgroundColor: theme.palette.success.light,
                                                            color: theme.palette.success.dark,
                                                            width: 155,
                                                            height: 40
                                                        }}
                                                    >
                                                        <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                        <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                                    </Avatar>
                                                ) : (
                                                    ''
                                                )}
                                                {row?.fulStatus === 'Cancelled' ? (
                                                    <Avatar
                                                        variant="rounded"
                                                        sx={{
                                                            ...theme.typography.commonAvatar,
                                                            ...theme.typography.largeAvatar,
                                                            backgroundColor: theme.palette.error.light,
                                                            color: theme.palette.error.dark,
                                                            width: 155,
                                                            height: 40
                                                        }}
                                                    >
                                                        <DangerousRoundedIcon fontSize="small" />
                                                        <Box width={5} /> <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                                    </Avatar>
                                                ) : (
                                                    ''
                                                )}

                                                {row?.fulStatus === 'Received' ? (
                                                    <Avatar
                                                        variant="rounded"
                                                        sx={{
                                                            ...theme.typography.commonAvatar,
                                                            ...theme.typography.largeAvatar,
                                                            backgroundColor: theme.palette.primary.light,
                                                            color: theme.palette.primary.dark,
                                                            width: 155,
                                                            height: 40
                                                        }}
                                                    >
                                                        <RemoveCircleIcon fontSize="small" />
                                                        <Box width={5} /> <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                                    </Avatar>
                                                ) : (
                                                    ''
                                                )}

                                                {row?.fulStatus === 'Dispatched' ? (
                                                    <Avatar
                                                        variant="rounded"
                                                        sx={{
                                                            ...theme.typography.commonAvatar,
                                                            ...theme.typography.largeAvatar,
                                                            backgroundColor: theme.palette.warning.light,
                                                            color: theme.palette.warning.dark,
                                                            width: 155,
                                                            height: 40
                                                        }}
                                                    >
                                                        <RemoveCircleIcon fontSize="small" />
                                                        <Box width={5} /> <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                                    </Avatar>
                                                ) : (
                                                    ''
                                                )}

                                                {row?.fulStatus === 'Processing' ? (
                                                    <Avatar
                                                        variant="rounded"
                                                        sx={{
                                                            ...theme.typography.commonAvatar,
                                                            ...theme.typography.largeAvatar,
                                                            backgroundColor: theme.palette.warning.light,
                                                            color: theme.palette.warning.dark,
                                                            width: 155,
                                                            height: 40
                                                        }}
                                                    >
                                                        <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                        <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                                    </Avatar>
                                                ) : (
                                                    ''
                                                )}
                                                {row?.fulStatus === 'ProformaInvoice' ? (
                                                    <Avatar
                                                        variant="rounded"
                                                        sx={{
                                                            ...theme.typography.commonAvatar,
                                                            ...theme.typography.largeAvatar,
                                                            backgroundColor: theme.palette.secondary.light,
                                                            color: theme.palette.secondary.dark,
                                                            width: 155,
                                                            height: 40
                                                        }}
                                                    >
                                                        <RemoveCircleIcon fontSize="small" />
                                                        <Box width={5} /> <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                                    </Avatar>
                                                ) : (
                                                    ''
                                                )}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {row.newOrderStatusId._id === '6228632de5b218f4be037d70' ? (
                                                    <Avatar
                                                        variant="rounded"
                                                        sx={{
                                                            ...theme.typography.commonAvatar,
                                                            ...theme.typography.largeAvatar,
                                                            backgroundColor: theme.palette.success.light,
                                                            color: theme.palette.success.dark,
                                                            width: 155,
                                                            height: 40
                                                        }}
                                                    >
                                                        <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                        <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                                    </Avatar>
                                                ) : (
                                                    ''
                                                )}
                                                {row.newOrderStatusId._id === '622b9bf0f3bcbc9eb0c203ea' ? (
                                                    <Avatar
                                                        variant="rounded"
                                                        sx={{
                                                            ...theme.typography.commonAvatar,
                                                            ...theme.typography.largeAvatar,
                                                            backgroundColor: theme.palette.error.light,
                                                            color: theme.palette.error.dark,
                                                            width: 155,
                                                            height: 40
                                                        }}
                                                    >
                                                        <DangerousRoundedIcon fontSize="small" />
                                                        <Box width={5} />{' '}
                                                        <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                                    </Avatar>
                                                ) : (
                                                    ''
                                                )}

                                                {row.newOrderStatusId._id === '620d76d63a9c8148935c696b' ? (
                                                    <Avatar
                                                        variant="rounded"
                                                        sx={{
                                                            ...theme.typography.commonAvatar,
                                                            ...theme.typography.largeAvatar,
                                                            backgroundColor: theme.palette.primary.light,
                                                            color: theme.palette.primary.dark,
                                                            width: 155,
                                                            height: 40
                                                        }}
                                                    >
                                                        <RemoveCircleIcon fontSize="small" />
                                                        <Box width={5} />{' '}
                                                        <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                                    </Avatar>
                                                ) : (
                                                    ''
                                                )}

                                                {row.newOrderStatusId._id === '625311abbd74072935e58592' ? (
                                                    <Avatar
                                                        variant="rounded"
                                                        sx={{
                                                            ...theme.typography.commonAvatar,
                                                            ...theme.typography.largeAvatar,
                                                            backgroundColor: theme.palette.secondary.light,
                                                            color: theme.palette.secondary.dark,
                                                            width: 155,
                                                            height: 40
                                                        }}
                                                    >
                                                        <RemoveCircleIcon fontSize="small" />
                                                        <Box width={5} />{' '}
                                                        <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                                    </Avatar>
                                                ) : (
                                                    ''
                                                )}

                                                {row.newOrderStatusId._id === '625739679a0af3ae9771daef' ? (
                                                    <Avatar
                                                        variant="rounded"
                                                        sx={{
                                                            ...theme.typography.commonAvatar,
                                                            ...theme.typography.largeAvatar,
                                                            backgroundColor: theme.palette.error.light,
                                                            color: theme.palette.error.dark,
                                                            width: 155,
                                                            height: 40
                                                        }}
                                                    >
                                                        <DangerousRoundedIcon fontSize="small" />
                                                        <Box width={5} />{' '}
                                                        <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                                    </Avatar>
                                                ) : (
                                                    ''
                                                )}
                                                {row.newOrderStatusId._id === '622b9bcdf3bcbc9eb0c203e2' ? (
                                                    <Avatar
                                                        variant="rounded"
                                                        sx={{
                                                            ...theme.typography.commonAvatar,
                                                            ...theme.typography.largeAvatar,
                                                            backgroundColor: theme.palette.warning.light,
                                                            color: theme.palette.warning.dark,
                                                            width: 155,
                                                            height: 40
                                                        }}
                                                    >
                                                        <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                        <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                                    </Avatar>
                                                ) : (
                                                    ''
                                                )}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {row?.newPaymentStatusId?._id === '620d76a4ee512a471bf98c3e' ? (
                                                    <Avatar
                                                        variant="rounded"
                                                        sx={{
                                                            ...theme.typography.commonAvatar,
                                                            ...theme.typography.largeAvatar,
                                                            backgroundColor: theme.palette.success.light,
                                                            color: theme.palette.success.dark,
                                                            width: 85,
                                                            height: 20
                                                        }}
                                                    >
                                                        <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                        <Typography variant="h5">{row?.newPaymentStatusId?.name ?? ''}</Typography>
                                                    </Avatar>
                                                ) : (
                                                    ''
                                                )}
                                                {row?.newPaymentStatusId?._id === '620d7693448d1be3b4ab1b08' ? (
                                                    <Avatar
                                                        variant="rounded"
                                                        sx={{
                                                            ...theme.typography.commonAvatar,
                                                            ...theme.typography.largeAvatar,
                                                            backgroundColor: theme.palette.error.light,
                                                            color: theme.palette.error.dark,
                                                            width: 85,
                                                            height: 20
                                                        }}
                                                    >
                                                        <DangerousRoundedIcon fontSize="small" />
                                                        <Box width={5} />{' '}
                                                        <Typography variant="h5"> {row?.newPaymentStatusId?.name ?? ''}</Typography>
                                                    </Avatar>
                                                ) : (
                                                    ''
                                                )}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row?.newPaymentStatusId?._id === '620d76a4ee512a471bf98c3e' ? (
                                                    ''
                                                ) : (
                                                    <IconButton aria-label="edit" color="primary" onClick={() => handleShowClick(row)}>
                                                        update
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        component="div"
                        count={orderInfo.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </MainCard>
            </Grid>
        </>
    );
};

export default RetailerAllOrdersTracker;
