import BatchCardWrapper from 'assets/Wrappers/BatchCardWrapper';

const BatchCard = ({ title, count }) => {
    return (
        <BatchCardWrapper>
            <div className="card text-white mb-3 batch-card shadow-sm">
                <div className="card-body">
                    <h5 className="card-title">{count}</h5>
                    <h6 className="card-text">{title}</h6>
                </div>
            </div>
        </BatchCardWrapper>
    );
};
export default BatchCard;
