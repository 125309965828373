import Person from '@mui/icons-material/Person';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import NearMeIcon from '@mui/icons-material/NearMe';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import TokenIcon from '@mui/icons-material/Token';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import './map.css';
import { useEffect, useState } from 'react';

const PopUp = ({
    _id,
    latitude,
    longitude,
    partnerName,
    rmaperson,
    placedBy,
    city,
    orderDate,
    orderStatus,
    sales,
    pod,
    orderTypId,
    tier
}) => {
    const [coordinates, setCoordinates] = useState([]); // Default coordinates

    useEffect(() => {
        // Function to handle geocoding
        const handleGeocode = () => {
            const geocoder = new window.google.maps.Geocoder();
            const latlng = {
                lat: parseFloat(latitude),
                lng: parseFloat(longitude)
            };

            geocoder
                .geocode({ location: latlng })
                .then((response) => {
                    if (response.results[0]) {
                        const city = response.results[0].address_components[1].long_name;
                        setCoordinates(city);
                    } else {
                        console.log('No results found');
                    }
                })
                .catch((e) => console.log(`Geocoder failed due to: ' + ${e}`));
        };
        handleGeocode();
    }, []);

    return (
        <div>
            <div className="popup-wrapper">
                <span className="details">
                    <Person sx={{ fontSize: 18 }} />
                    <p className="icon"> {partnerName} </p>
                </span>
                <span className="details">
                    <EmojiEventsIcon sx={{ fontSize: 18 }} />
                    <p className="icon"> {`Loyalty ${tier}`} </p>
                </span>

                <span className="details">
                    <PersonPinIcon sx={{ fontSize: 18 }} />
                    <p className="icon">RMA : </p>
                    <p className="info"> {rmaperson}</p>
                </span>
                <span className="details">
                    <SupportAgentIcon sx={{ fontSize: 18 }} />
                    <p className="icon">Placed by : </p>
                    <p className="info"> {placedBy}</p>
                </span>
                <span className="details">
                    <NearMeIcon sx={{ fontSize: 18 }} />
                    <p className="icon">City : </p>
                    <p className="info"> {coordinates}</p>
                </span>
                <span className="details">
                    <ModeOfTravelIcon sx={{ fontSize: 18 }} />
                    <p className="icon">Pod : </p>
                    <p className="info"> {pod}</p>
                </span>
                <span className="details">
                    <AccountBalanceIcon sx={{ fontSize: 18 }} />
                    <p className="icon"> GMV :</p>
                    <p className="info">
                        {' '}
                        {parseFloat(sales?.toFixed(2)).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}
                    </p>
                </span>
                <span className="details">
                    <TokenIcon sx={{ fontSize: 18 }} />
                    <p className="icon">Order type : </p>
                    <p className="info date">{orderTypId}</p>
                </span>
                <span className="details">
                    <AccessTimeFilledIcon sx={{ fontSize: 18 }} />
                    <p className="icon">Order time : </p>
                    <p className="info date">{new Date(orderDate).toLocaleTimeString([], { hour12: true })}</p>
                </span>
                <span className="details">
                    <PendingActionsIcon sx={{ fontSize: 18 }} />
                    <p className="icon">Status : </p>
                    <p className="info">{orderStatus}</p>
                </span>
            </div>
        </div>
    );
};

export default PopUp;
