/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
// import { notification } from 'antd';
import { toast } from 'react-toastify';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';
import useScriptRef from 'hooks/useScriptRef';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';

const AddUser = () => {
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    const [accountType, setaccountType] = useState('');
    const [dob, setdob] = useState(new Date());
    const [contactPhone, setcontactPhone] = useState('');
    const [location, setlocation] = useState('');
    const [country, setCountry] = useState('');
    const [countries, setCountries] = useState([]);
    const [cityId, setcityId] = useState('');
    const [cityData, setCityData] = useState([]);
    // const [languagesSpoken, setlanguagesSpoken] = useState([]);
    // const [status, setStatus] = useState('');
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [roleInfo, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    // const languesNames = ['English', 'French', 'Hausa', 'Twi', 'Ga', 'Ewe'];

    const retrieveAllRoles = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.AllRolesUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setRoles(data.data);
                        setLoading2(false);
                    } else {
                        toast.error(data.data);
                        setLoading2(false);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    const retrieveAllCity = async (id) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.CityFilterUrl + id, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCityData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    const filterCountry = (event) => {
        const newFilter = countries.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        newFilter.forEach((option) => {
            retrieveAllCity(option?._id);
        });
    };
    const retrieveAllActiveCountry = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.ActiveCountryUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        retrieveAllRoles();
        retrieveAllActiveCountry();
    }, []);

    const handleSignupChange = (newValue) => {
        setdob(newValue);
    };
    const handleDistributorChange = (event) => {
        setaccountType(event.target.value);
    };
    const handleCityChange = (event) => {
        setcityId(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        console.log(userInfo);

        // const data = {
        //     firstName,
        //     lastName,
        //     email,
        //     password,
        //     yrExp,
        //     roleId,
        //     dob,
        //     contactPhone,
        //     location,
        //     locationLat,
        //     locationLng
        // };

        // console.log('data', JSON.stringify(data));

        fetch(ApiUrl.GarageMainSignupUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                firstName,
                lastName,
                email,
                password,
                accountType,
                dob,
                contactPhone,
                location,
                country,
                cityId
            })
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/users');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <br />
                            <PageHeader title="Users" subTitle="Home | Configuration | User Management | Users" />
                            <br />
                            <MainCard title="Add User">
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        autoComplete="off"
                                        name="firstName"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="firstName"
                                        value={firstName}
                                        label="First Name"
                                        onChange={(e) => setfirstName(e.target.value)}
                                        autoFocus
                                    />
                                    <br />
                                    <br />

                                    <TextField
                                        autoComplete="off"
                                        name="lastName"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="lastName"
                                        value={lastName}
                                        label="Last Name"
                                        onChange={(e) => setlastName(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="email"
                                        type="email"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="email"
                                        value={email}
                                        label="Email"
                                        onChange={(e) => setemail(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="password"
                                        type="password"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="password"
                                        value={password}
                                        label="Password"
                                        onChange={(e) => setpassword(e.target.value)}
                                    />

                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="contactPhone"
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        id="contactPhone"
                                        value={contactPhone}
                                        label="Contact Phone"
                                        onChange={(e) => setcontactPhone(e.target.value)}
                                    />
                                    <br />
                                    <br />

                                    <TextField
                                        autoComplete="off"
                                        name="location"
                                        variant="outlined"
                                        fullWidth
                                        id="location"
                                        value={location}
                                        label="Location"
                                        onChange={(e) => setlocation(e.target.value)}
                                    />
                                    <br />
                                    <br />

                                    <Autocomplete
                                        id="country-select-demo"
                                        autoHighlight
                                        value={country}
                                        onChange={(event, newValue) => {
                                            setCountry(newValue);
                                            filterCountry(newValue);
                                        }}
                                        options={countries.map((option) => option.name)}
                                        isOptionEqualToValue={(option, value) => option.code === value.code}
                                        renderInput={(params) => <TextField {...params} label="Choose a country" />}
                                    />

                                    <br />
                                    <br />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Choose a City</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={cityId}
                                            label="City"
                                            onChange={handleCityChange}
                                        >
                                            {cityData.map((attr) => (
                                                <MenuItem key={attr._id} value={attr._id}>
                                                    {attr?.city ?? ''}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <br />
                                    <br />

                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Stack spacing={3}>
                                            <DesktopDatePicker
                                                label="Date Of Birth"
                                                inputFormat="MM/dd/yyyy"
                                                value={dob}
                                                onChange={handleSignupChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Stack>
                                    </LocalizationProvider>

                                    <br />
                                    <br />

                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={accountType}
                                            label="Status"
                                            onChange={handleDistributorChange}
                                        >
                                            {roleInfo.map((role) => (
                                                <MenuItem key={role._id} value={role._id}>
                                                    {role.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <br />
                                    <br />

                                    <Box display="flex" justifyContent="space-between">
                                        <Button component={Link} to="/users" type="submit" variant="contained" color="error">
                                            Reset
                                        </Button>

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            disabled={!firstName || !lastName || !email || !password || !accountType || !country || !cityId}
                                        >
                                            {loading ? 'Submitting ..' : 'Submit'}
                                        </Button>
                                    </Box>
                                </form>
                            </MainCard>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default AddUser;
