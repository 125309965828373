import { Space, Modal, Button, Spin, Input, Select, notification } from 'antd';
import { Box, Typography, List, ListItem } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { LoadingOutlined } from '@ant-design/icons';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useEffect, useState } from 'react';
import { encrypt, decrypt } from '../../utils/encrypt';
import { ToastContainer, toast } from 'react-toastify';
import './collection.css';
import ApiUrl from 'network';
import ReportIcon from '@mui/icons-material/Report';
import { twoDecimalPlaces } from '../../utils/functions';

const ConfirmationModal = ({
    handleConfirmationOk,
    handleConfirmationCancel,
    isConfirmationOpen,
    collections,
    totalcollections,
    updateApprovalSuccess,
    setUpdatApprovalSuccess,
    showConfirmationModal,
    setTotalcollections,
    setRemainingTotal
}) => {
    const [confirmLoading, setConfirmloading] = useState(false);

    const antIcon = (
        <LoadingOutlined
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%'
            }}
            spin
        />
    );

    const [api, contextHolder] = notification.useNotification();

    const openNotification = (placement, status) => {
        api.open({
            description: status ? 'Receipt Confirmed!' : 'Receipt Rejected!',
            placement,
            style: {
                width: 267,
                height: 70,
                backgroundColor: '#0A2938',
                borderRadius: '12px',
                textAlign: 'center',
                color: '#fff',
                fontWeight: 500,
                fontSize: '18px'
            },
            closeIcon: false
        });
    };

    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');

    const filteredCollection = collections.filter((item) => item.status !== 'Rejected');

    const sortedCollection = filteredCollection.slice().sort((a, b) => a.status.localeCompare(b.status));

    // console.error(sortedCollection);

    useEffect(() => {
        setRemainingTotal(sortedCollection?.length);
    }, [sortedCollection?.length]);

    // confirm Approval
    const confirmApproval = async (collection, status) => {
        const userInfo = localStorage.getItem('userToken');
        setConfirmloading(true);

        let data = {};
        let url = '';

        if (collection?.collectionType === 'Bulk' || collection?.ordersBulk?.length > 0) {
            data = {
                id: collection?._id,
                approveAmt: collection?.amount,
                approveStatus: status, // reject = false and confirm = true
                ordersBulk: collection?.ordersBulk
            };
            url = `${ApiUrl.BaseUrl}/collection/bulkcollectionaproval`;
        } else if (collection?.collectionType === 'Single' || collection?.ordersBulk?.length === 0) {
            data = {
                id: collection?._id,
                approveAmt: collection?.amount,
                approveStatus: status, // reject = false and confirm = true
                orderId: collection?.country === 'Ghana' ? collection?.orderIdGh?._id : collection?.orderIdNg?._id,
                orderNo: collection?.orderNo
            };

            url = `${ApiUrl.BaseUrl}/collection/approvecollection`;
        }

        // if (collection?.collectionType === 'Single' || collection?.ordersBulk?.length === 0) {
        // data = {
        //     id: collection?._id,
        //     approveAmt: collection?.amount,
        //     approveStatus: status, // reject = false and confirm = true
        //     orderId: collection?.country === 'Ghana' ? collection?.orderIdGh?._id : collection?.orderIdNg?._id,
        //     orderNo: collection?.orderNo
        // };

        // url = `${ApiUrl.BaseUrl}/collection/approvecollection`;
        // }

        const obj = JSON.stringify(data);
        const result = encrypt(obj, keyResult, ivResult);
        await fetch(url, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ payload: result })
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    openNotification('bottom', status);
                    handleConfirmationOk();
                    setUpdatApprovalSuccess(!updateApprovalSuccess);
                    showConfirmationModal();
                } else if (result.status === false) {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                    handleConfirmationCancel();
                } else {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                    handleConfirmationCancel();
                }
            })
            .finally(() => {
                setConfirmloading(false);
            });
    };

    return (
        <div>
            {contextHolder}

            {isConfirmationOpen ? (
                <Box
                    sx={{
                        position: 'fixed',
                        top: '2%',
                        zIndex: 4440,
                        width: '147px',
                        left: '50%',
                        transform: 'translateX(-50%)'
                    }}
                >
                    {totalcollections === 0 ? (
                        <Box
                            sx={{
                                backgroundColor: '#0A2938',
                                height: '49px',
                                width: '147px',
                                borderRadius: '8px',
                                padding: '10px 24px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#fff',
                                marginBottom: '12px'
                            }}
                        >
                            No Receipts!
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                backgroundColor: '#fff',
                                height: '49px',
                                width: '147px',
                                borderRadius: '8px',
                                padding: '10px 24px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#0A2938',
                                marginBottom: '12px'
                            }}
                        >
                            {sortedCollection?.length} remaining
                        </Box>
                    )}
                    <Box
                        sx={{
                            overflow: 'hidden',
                            display: 'flex'
                        }}
                    >
                        {sortedCollection.map((item, index) => (
                            <FiberManualRecordIcon
                                sx={{
                                    color: index === 0 ? '#fff' : '#E7EAEB'
                                }}
                                key={item?._id}
                            />
                        ))}
                    </Box>
                </Box>
            ) : null}
            {sortedCollection.map((collection, index) => (
                <Modal
                    key={collection?._id}
                    open={isConfirmationOpen}
                    onOk={handleConfirmationOk}
                    centered
                    width={321}
                    zIndex={2050}
                    height={490}
                    onCancel={handleConfirmationCancel}
                    style={{
                        // top: '5%',
                        top: index !== 0 ? '6%' : '5%',
                        right: index !== 0 ? '50px' : 'none'
                    }}
                    mask={index === 0 ? true : null}
                    footer={
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: '8px',
                                    border: '1px solid #FF740F',
                                    height: '48px',
                                    fontSize: '18px',
                                    fontWeight: '700',
                                    color: '#FF740F ',
                                    width: '154px',
                                    textAlign: 'center'
                                }}
                                onClick={() => {
                                    confirmApproval(collection, false);
                                    toast.success('Processing...');
                                }}
                            >
                                Reject
                            </Button>
                            <Button
                                style={{
                                    backgroundColor: '#FF740F',
                                    borderRadius: '8px',
                                    height: '48px',
                                    fontSize: '18px',
                                    fontWeight: '700',
                                    color: '#FFFFFF',
                                    width: '154px',
                                    textAlign: 'center'
                                }}
                                onClick={() => {
                                    confirmApproval(collection, true);
                                    toast.success('Processing...');
                                }}
                            >
                                Confirm
                            </Button>
                        </Box>
                    }
                >
                    <div className="">
                        <Typography
                            sx={{
                                fontSize: '20px',
                                color: '#546974',
                                padding: '0',
                                fontWeight: '500',
                                // marginBottom: '40px',
                                textAlign: 'center'
                            }}
                        >
                            Confirm payment
                        </Typography>
                        {collection?.collectionType === 'Bulk' ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    margin: '7px 0 0 0',
                                    alignItems: 'center'
                                }}
                            >
                                <ReportIcon
                                    sx={{
                                        fontSize: '13px',
                                        color: '#4B6EFF'
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        color: '#546974',
                                        padding: '0',

                                        textAlign: 'center'
                                    }}
                                >
                                    Bulk payment
                                </Typography>
                            </Box>
                        ) : null}

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center',

                                height: '400px',
                                overflowY: 'scroll',
                                overflowX: 'hidden'
                                // paddingTop: '30px'
                            }}
                            className="collection-modals-y"
                        >
                            <Box
                                sx={{
                                    width: ' 100%',
                                    margin:
                                        collection?.collectionType === 'Bulk'
                                            ? `${collection?.ordersBulk?.length * 15 + 200}px 0 0 0px`
                                            : '100px 0 0 0px'
                                }}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974', margin: '0px 0 10px 0px' }}>
                                        Receipt
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        display: 'flex',
                                        overflowX: 'scroll',
                                        scrollSnapType: 'x mandatory',
                                        whiteSpace: 'nowrap',
                                        width: '321px'
                                        // height: '100px'
                                    }}
                                    className="collection-modals-x"
                                >
                                    {collection?.image?.map((img) => (
                                        <Box
                                            sx={{
                                                width: ' 100%',
                                                height: '200px',
                                                backgroundColor: '#E7EAEB',
                                                borderRadius: '12px',
                                                cursor: 'pointer',
                                                overflow: 'hidden',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flex: '0 0 270px',
                                                scrollSnapAlign: 'center',
                                                marginRight: '5px'
                                            }}
                                            key={img?._id}
                                        >
                                            <img src={img?.image} className="receipt-image" alt="receipt" />

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexDirection: 'column'
                                                }}
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                            {collection?.collectionType === 'Bulk' ? (
                                <Box
                                    sx={{
                                        width: ' 100%',
                                        margin: '14px 0 0 0px'
                                    }}
                                >
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974', margin: '0px 0 5px 0px' }}>
                                            Orders
                                        </Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            width: ' 100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            flexWrap: 'wrap'
                                        }}
                                    >
                                        {collection.ordersBulk.map((value, index) => (
                                            <Box
                                                key={value + index}
                                                sx={{
                                                    width: ' 124px',
                                                    margin: '0px 0 8px 0',
                                                    height: '44px',
                                                    borderRadius: '8px',
                                                    padding: '8px',
                                                    border: '1px solid #E9ECED',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: '#546974',
                                                        fontSize: '16px'
                                                    }}
                                                >
                                                    {value.orderNo}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            ) : null}

                            <Box
                                sx={{
                                    width: ' 100%',
                                    margin: '14px 0 0 0px'
                                }}
                            >
                                <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974', margin: '0px 0 5px 0px' }}>
                                    Amount Deposited
                                </Typography>
                                <Input
                                    style={{
                                        width: '100%',
                                        border: '1px solid #E7EAEB'
                                    }}
                                    size="large"
                                    placeholder="Amount"
                                    value={twoDecimalPlaces(collection?.amount)}
                                />
                            </Box>
                            <Box
                                sx={{
                                    width: ' 100%',
                                    margin: '14px 0 0 0px'
                                }}
                            >
                                <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974', margin: '0px 0 5px 0px' }}>
                                    Payment Type
                                </Typography>
                                <Input
                                    style={{
                                        width: '100%',
                                        zindex: '5900'
                                    }}
                                    size="large"
                                    zindex="4050"
                                    placeholder="Payment type"
                                    value={collection?.paymentType}
                                />
                            </Box>

                            <Box
                                sx={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    flexDirection: 'column',
                                    width: '100%',
                                    margin: '14px 0 0 0px'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%'
                                    }}
                                >
                                    <PersonIcon
                                        sx={{
                                            fontSize: '18px',
                                            color: '#4B6EFF',
                                            marginRight: '4px'
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            color: '#546974',
                                            marginRight: '4px'
                                        }}
                                    >
                                        RMA:
                                    </Typography>
                                    <Typography sx={{ color: '#0A2938' }}>
                                        {collection?.createdBy?.firstName}
                                        {collection?.createdBy?.lastName}
                                    </Typography>
                                </Box>
                                {collection?.collectionType !== 'Bulk' ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginTop: '4px'
                                        }}
                                    >
                                        <ShoppingCartIcon
                                            sx={{
                                                fontSize: '18px',
                                                color: '#4B6EFF',
                                                marginRight: '4px'
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                color: '#546974',
                                                marginRight: '4px'
                                            }}
                                        >
                                            Order number
                                        </Typography>
                                        <Typography sx={{ color: '#0A2938' }}>{collection?.orderNo}</Typography>
                                    </Box>
                                ) : null}
                                {collection?.collectionType === 'Bulk' ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginTop: '4px'
                                        }}
                                    >
                                        <ShoppingBagIcon
                                            sx={{
                                                fontSize: '18px',
                                                color: '#4B6EFF',
                                                marginRight: '4px'
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                color: '#546974',
                                                marginRight: '4px'
                                            }}
                                        >
                                            Total Sales Value
                                        </Typography>

                                        <Typography sx={{ color: '#0A2938' }}>{twoDecimalPlaces(collection?.totalSales)}</Typography>
                                    </Box>
                                ) : null}
                                {collection?.collectionType !== 'Bulk' ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginTop: '4px'
                                        }}
                                    >
                                        <ShoppingBagIcon
                                            sx={{
                                                fontSize: '18px',
                                                color: '#4B6EFF',
                                                marginRight: '4px'
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                color: '#546974',
                                                marginRight: '4px'
                                            }}
                                        >
                                            Sales Value
                                        </Typography>
                                        <Typography sx={{ color: '#0A2938' }}>
                                            {collection?.country === 'Ghana'
                                                ? twoDecimalPlaces(collection?.orderIdGh?.expCashCollected)
                                                : twoDecimalPlaces(collection?.orderIdNg?.expCashCollected)}
                                        </Typography>
                                    </Box>
                                ) : null}
                            </Box>
                        </Box>
                    </div>
                </Modal>
            ))}
        </div>
    );
};

export default ConfirmationModal;
