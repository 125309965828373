import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WestIcon from '@mui/icons-material/West';
import { Box, List, ListItem } from '@mui/material';
import { Button, Input, Typography, notification, antIcon, Empty } from 'antd';
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import check from '../../assets/images/icons/checkbox.svg';
import { decrypt, encrypt } from '../../utils/encrypt';
import { LoadingOutlined } from '@ant-design/icons';
import { EmptyState } from '../../components/EmptyState/EmptyState';
import RetailerProfileModal from './retailerProfileModal';

const Notes = () => {
    const userRole = localStorage.getItem('userRole');
    const navigate = useNavigate();
    const params = useParams();
    const [containerWidth, setContainerWidth] = useState('false');
    const [insightLoading, setInsightLoading] = useState(false);
    const [insight, setInsight] = useState('');
    const [insightOpen, setInsightOpen] = useState(false);
    const [source, setSource] = useState('');
    const [note, setNote] = useState('');
    const [isAddingNote, setIsAddingNote] = useState(false);
    const [loadingRetailerNotes, setLoadingRetailerNotes] = useState(false);
    const [retailerNotes, setRetailerNotes] = useState([]);
    const [noteSuccess, setNoteSuccess] = useState(false);
    const [openSource, setOpenSource] = useState(false);
    const { TextArea } = Input;
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [api, contextHolder] = notification.useNotification();
    const country = localStorage.getItem('country');

    const [items, setItems] = useState([
        { key: 1, source: 'Phone Call', isHovered: false, isSelected: false },
        { key: 2, source: 'Messaging', isHovered: false, isSelected: false },
        { key: 3, source: 'Physical Conversation', isHovered: false, isSelected: false }
    ]);

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54
            }}
            spin
        />
    );

    const openNotification = (placement) => {
        api.open({
            description: 'Note has been added.',
            placement,
            style: {
                width: 267,
                height: 70,
                backgroundColor: '#0A2938',
                borderRadius: '12px',
                textAlign: 'center',
                color: '#fff',
                fontWeight: 500,
                fontSize: '18px'
            },
            closeIcon: false
        });
    };

    // insight modal
    const showInsightModal = () => {
        setInsightOpen(true);
    };

    const handleInsightOk = () => {
        setInsightOpen(false);
    };

    const handleInsightCancel = () => {
        setInsightOpen(false);
    };

    useEffect(() => {
        const handleResize = () => {
            // Adjust the column width based on the window width
            const newWidth = window.innerWidth < 1200 ? 'true' : 'false';
            setContainerWidth(newWidth);
        };

        // Initial setup
        handleResize();

        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSourceSelect = (index) => {
        setItems((prevItems) => {
            return prevItems.map((item, i) => {
                if (i === index) {
                    return { ...item, isSelected: true };
                }
                return { ...item, isSelected: false };
            });
        });
    };

    const handleMouseEnter = (index) => {
        setItems((prevItems) => prevItems.map((item, i) => (i === index ? { ...item, isHovered: true } : item)));
    };

    const handleMouseLeave = (index) => {
        setItems((prevItems) => prevItems.map((item, i) => (i === index ? { ...item, isHovered: false } : item)));
    };

    const getRetailerNotes = async (id) => {
        try {
            setLoadingRetailerNotes(true);
            const url = `${ApiUrl.BaseUrl}/crm/fetch-partner-notes?retailer_id=${params?.id}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setRetailerNotes(answer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoadingRetailerNotes(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // get insight data
    const getInsight = async (id) => {
        try {
            setInsightLoading(true);
            const userInfo = localStorage.getItem('userToken');

            const apiUrl = `${ApiUrl.BaseUrl}/llm/customer-notes-insight?retailer_id=${id}`;

            await fetch(apiUrl, {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    setInsight(data.insight);
                })
                .catch((error) => {
                    console.error('Error:', error);
                })
                .finally(() => {
                    setInsightLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getRetailerNotes(params.id);
    }, [noteSuccess, params.id]);

    const AddNewNote = async (event) => {
        const userInfo = localStorage.getItem('userToken');
        setIsAddingNote(true);

        const data = {
            source,
            note,
            country,
            retailer_id: params.id
        };

        const obj = JSON.stringify(data);
        const result = encrypt(obj, keyResult, ivResult);

        await fetch(`${ApiUrl.BaseUrl}/crm/store-note`, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ payload: result })
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    openNotification('bottom');
                    setNoteSuccess(!noteSuccess);
                } else if (result.status === false) {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                } else {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                }
            })
            .finally(() => {
                setIsAddingNote(false);
                setNote('');
                setOpenSource(false);
            });
    };

    const NotesCard = ({ source, createdBy, note, createdAt }) => {
        return (
            <Box
                sx={{
                    flexGrow: '1',
                    margin: '5px',
                    flexBasis: '510px'
                }}
            >
                {contextHolder}
                <Box
                    style={{
                        backgroundColor: '#ECEDEF',
                        borderRadius: '16px',
                        margin: '5px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        width: '100%',
                        padding: '15px',
                        overflow: 'hidden'
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'start',
                            color: '#546974',
                            fontSize: '14px',
                            height: '100px',
                            overflowY: 'scroll',
                            overflowX: 'hidden'
                        }}
                        className="order-history-modals-y"
                    >
                        {note}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            margin: '16px 0 0 0',
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                color: '#85949C',
                                fontSize: '12px',
                                margin: '5px 0 0 0'
                            }}
                        >
                            Source: {source}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                margin: '5px 0 0 0'
                            }}
                        >
                            <Box
                                sx={{
                                    color: '#85949C',
                                    fontSize: '12px',
                                    margin: ' 5px 10px 0 0'
                                }}
                            >
                                Added by: {createdBy?.firstName} {createdBy?.lastName}
                            </Box>
                            <Box
                                sx={{
                                    color: '#85949C',
                                    fontSize: '12px',
                                    margin: '5px 0 0 0'
                                }}
                            >
                                {new Date(createdAt).toLocaleDateString()}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    };

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '1px dashed #E7EAEB',
                    alignItems: 'center',
                    margin: '0px auto 15px',
                    flexWrap: 'wrap'
                }}
            >
                {' '}
                <List
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start'
                    }}
                >
                    <WestIcon
                        sx={{
                            fontSize: '32px',
                            cursor: 'pointer',
                            margin: '0 10px 0 0',
                            '&:hover': {
                                color: '#546974'
                            },
                            '&:focus': {
                                color: '#9DA9AF'
                            }
                        }}
                        onClick={() => navigate(-1)}
                    />{' '}
                    <ListItem sx={{ fontWeight: 500, fontSize: '1.875rem', color: '#0A2938', padding: 0 }}>Notes</ListItem>
                </List>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 0 16px 0'
                    }}
                >
                    <Button
                        type="primary"
                        size="large"
                        loading={insightLoading}
                        style={{
                            backgroundColor: '#F5880E',
                            borderRadius: '12px',
                            fontSize: '16px',
                            fontWeight: '400',
                            color: '#fff',
                            width: '161px',
                            margin: '24px 5px 0 5px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '38px',
                            flexGrow: '1 '
                            // flexBasis: '200px'
                        }}
                        className="hover-secondary-button-orange"
                        onClick={() => {
                            showInsightModal();
                            getInsight(params?.id);
                        }}
                    >
                        View Summary
                    </Button>
                </Box>
            </Box>

            <Box
                sx={{
                    margin: '24px 0px 0 0px',
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: containerWidth === 'true' ? 'wrap' : 'nowrap'
                }}
            >
                {isAddingNote === false ? (
                    <Box
                        style={{
                            backgroundColor: '#fff',
                            borderRadius: '16px',
                            flexGrow: '1 ',
                            border: '1px solid #FF740F',
                            margin: '5px',
                            display: 'flex',
                            flexBasis: '591px',
                            padding: '18px 20px',
                            flexDirection: 'column'
                        }}
                    >
                        <TextArea
                            rows={4}
                            placeholder="Add note here"
                            onChange={(e) => {
                                setNote(e.target.value);
                            }}
                            // onKeyUp={handleKeyPress}
                            style={{
                                backgroundColor: '#fff',
                                borderRadius: '12px',
                                margin: '5px',
                                border: 'none',
                                height: '100px',
                                width: '100%'
                            }}
                            autoSize={{
                                minRows: 5,
                                maxRows: 6
                            }}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-end',
                                flexDirection: 'column',
                                position: 'relative',
                                zIndex: '190'
                            }}
                        >
                            <Button
                                size="large"
                                style={{
                                    border: '1px solid #FFD5B7',
                                    height: '40px',
                                    width: '132px',
                                    padding: '10px 12px',
                                    borderRadius: '8px',
                                    color: '#FF740F',
                                    fontWight: '500',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    fontSize: '16px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    margin: '10px 0 5px 0'
                                }}
                                onClick={() => {
                                    setOpenSource(!openSource);
                                }}
                            >
                                Add source <KeyboardArrowDownIcon sx={{ width: '16px', color: '#FF740F' }} />
                            </Button>
                            {openSource ? (
                                <Box
                                    sx={{
                                        width: '294px',
                                        border: '1px solid #FA3246',
                                        borderRadius: '16px',
                                        padding: '10px 16px 16px 16px ',
                                        textAlign: 'center',
                                        position: 'absolute',
                                        right: '0',
                                        bottom: '-200px',
                                        backgroundColor: '#fff'
                                    }}
                                >
                                    {items.map((source, index) => (
                                        <Box
                                            onMouseEnter={() => handleMouseEnter(index)}
                                            onMouseLeave={() => handleMouseLeave(index)}
                                            key={source.key}
                                            sx={{
                                                radius: '8px',
                                                width: '100%',
                                                borderBottom: '1px solid #E7EAEB',
                                                textAlign: 'center',
                                                padding: '10px',
                                                backgroundColor: source.isHovered || source.isSelected ? '#FFF1E7' : '#fff',
                                                transition: 'background-color 0.7s ease',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => {
                                                setSource(source.source);
                                                handleSourceSelect(index);
                                            }}
                                        >
                                            {source.source} {source.isSelected && <img src={check} alt="check" />}
                                        </Box>
                                    ))}

                                    <Button
                                        loading={isAddingNote}
                                        style={{
                                            backgroundColor: '#FF740F',
                                            radius: '8px',
                                            height: '40px',
                                            width: '100%',
                                            color: '#fff'
                                        }}
                                        onClick={() => {
                                            AddNewNote();
                                        }}
                                        className="hover-secondary-button-orange"
                                    >
                                        Save note
                                    </Button>
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                ) : null}

                <Box
                    style={{
                        flexGrow: '1 ',

                        flexBasis: '591px',
                        padding: '18px 20px'
                    }}
                />
            </Box>
            {loadingRetailerNotes ? (
                <Box
                    sx={{
                        display: 'grid',
                        placeItems: 'center'
                    }}
                >
                    {antIcon}
                </Box>
            ) : retailerNotes.length > 0 ? (
                <Box
                    sx={{
                        margin: '24px 0px 0 0px',
                        alignItems: 'center',
                        display: 'grid',
                        gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                        gap: '10px'
                    }}
                >
                    {retailerNotes.map((note) => (
                        <NotesCard key={note?._id} {...note} />
                    ))}
                </Box>
            ) : (
                <Box
                    style={{
                        margin: '100px auto 0',
                        position: 'relative',
                        backgroundColor: '#FFFFFF',
                        height: '496px',
                        borderRadius: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <EmptyState height="204px" padding="5px" text="No results yet" />
                </Box>
            )}
            <RetailerProfileModal
                isOpen={insightOpen}
                handleOk={handleInsightOk}
                handleCancel={handleInsightCancel}
                content={insight}
                heading="Summary"
                centered={false}
                subheading={retailerNotes.length > 0 ? `This is a summary of all the notes from ${retailerNotes[0]?.retailer_name}` : ''}
                insightLoading={insightLoading}
                // height="591px"
            />
        </div>
    );
};

export default Notes;
