import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Select, Spin, DatePicker, Collapse, Modal, TimePicker, Tag, Input, Divider } from 'antd';
import { Box } from '@mui/material';
import { encrypt, decrypt } from '../../utils/encrypt';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ApiUrl from '../../network';
import { BiPencil } from 'react-icons/all';
import { EmptyState } from '../../components/EmptyState/EmptyState';
import dayjs from 'dayjs';

const dateConverter = (value) => {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        weekday: 'long'
        // hour: '2-digit',
        // minute: '2-digit',
        // second: '2-digit'
    };

    return new Date(value).toLocaleDateString('en-US', options);
};

const Fleet = () => {
    const [salesDriveSchedule, setSalesDriveSchedule] = useState([]);
    const [fleetSchedule, setFleetSchedule] = useState([]);
    const [availableDrivers, setAvailableDrivers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [allVehilces, setAllVehilces] = useState([]);
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [selectedDate, setSelectedDate] = useState(null);
    const [countries, setCountries] = useState([]);
    const [pods, setPods] = useState([]);
    const { RangePicker } = DatePicker;
    const [filter, setFilter] = useState(1);
    const [assignModal, setAssignModal] = useState(false);
    const [vehicle, setVehicle] = useState('');
    const [driver, setDriver] = useState('');
    const [assingLoading, setAssignLoading] = useState(false);
    const [assignedSchudleId, setAssignedSchudleId] = useState('');
    const [rmas, setRmas] = useState([]);
    const [driveTypeList, setDriveTypeList] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [actionType, setActionType] = useState('arrival');
    const [timeModal, setTimeModal] = useState(false);
    const [dispatchDateTime, setDispatchDateTime] = useState('');
    const [arrivalDateTime, setArrivalDateTime] = useState('');
    const [pod, setPod] = useState('');
    const [country, setCountry] = useState('');
    const [rma, setRma] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [scheduleId, setScheduleId] = useState([]);
    const [scheduleData, setScheduleData] = useState({});
    const [editScheduleModal, setEditScheduleModal] = useState(false);
    const [routesModal, setRoutesModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [columnRoutes, setColumnRoutes] = useState([]);
    const [rmaPick, setRmaPick] = useState('');
    const [thirdPartyDriver, setThirdPartyDriver] = useState('');

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54
            }}
            spin
        />
    );

    const toggleFilter = (index) => {
        setFilter(index);
    };

    const fetchCountries = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(`${ApiUrl.BaseUrl}/country/activecountry`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error('Something went wrong');
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const fetchPodsData = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/salesdrivepod/salesdrivepod`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setPods(decryptedData);
                        const allSalespersons = decryptedData.reduce((acc, pod) => {
                            acc.push(...pod.salesperson);
                            return acc;
                        }, []);
                        setRmas(allSalespersons);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };
    const getDriveTypeHandler = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/salesplanning/drivetypeslist`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    const driveTypeList = decrypt(data.data, keyResult, ivResult);
                    setDriveTypeList(JSON.parse(driveTypeList));
                } else {
                    toast.error(data.message);
                }
            });
    };

    const podsOptions = pods?.map((pod) => {
        return {
            label: pod.name,
            value: pod._id
        };
    });

    const driveTypeOptions = driveTypeList?.map((driveType) => {
        return {
            label: driveType.name,
            value: driveType._id
        };
    });

    // get All vehicles
    const getAllVehicles = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/salesplanning/garagevehicleslist`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setAllVehilces(answer);
                        setLoading(false);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                        toast.error(answer);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        getAllVehicles();
    }, []);

    const vehicleOptions = allVehilces?.map((item) => {
        return { label: item?.vehicleNumber, value: item?._id };
    });

    // get all sales drive schedule
    const allSalesDriveSchedule = async (data) => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/salesplanning/planinglist`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            if (data) {
                if (data.startDate) urlParams.append('startDate', data.startDate);
                if (data.endDate) urlParams.append('endDate', data.endDate);
                if (data.pod) urlParams.append('pod', data.pod);
                if (data.driveType) urlParams.append('driveType', data.driveType);
                if (data.country) urlParams.append('country', data.country);
                if (data.vehicleNo) urlParams.append('vehicleNo', data.vehicleNo);
                if (data.driver) urlParams.append('driver', data.driver);
                if (data.rma) urlParams.append('rma', data.rma);
            }

            url = `${url}?${urlParams.toString()}`;

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setSalesDriveSchedule(answer);
                        setLoading(false);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        allSalesDriveSchedule();
        fetchPodsData();
        getDriveTypeHandler();
    }, []);

    const salesDriveOptions = salesDriveSchedule?.map((result) => ({
        key: result?._id,
        date: dateConverter(result?.driveDate),
        pod: result?.pod?.name,
        driveType: result?.driveType?.name,
        trip_type: result?.tripType?.name,
        location: result?.locName,
        rma: `${result?.rmaId?.firstName} ${result?.rmaId?.lastName}`,
        status: <div>{result?.planStatus === 'Assigned' ? <Tag color="green">Assigned</Tag> : <Tag color="red">Not Assigned</Tag>}</div>,
        action: result?.planStatus
    }));

    const filterSalesDate = (value, dateString) => {
        setSelectedDate(value);
        const data = {
            startDate: dateString[0],
            endDate: dateString[1]
        };
        allSalesDriveSchedule(data);
    };

    const filterPod = (value) => {
        const data = {
            pod: value
        };
        allSalesDriveSchedule(data);
    };

    const filterRma = (value) => {
        const data = {
            rma: value
        };
        allSalesDriveSchedule(data);
    };

    const filterDriveType = (value) => {
        const data = {
            driveType: value
        };
        allSalesDriveSchedule(data);
    };

    // get Available Drivers
    const getAvailableDrivers = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/driver/alldrivers`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);

                        const available = answer.filter((item) => {
                            return item.driverAvailability === true;
                        });

                        // lets add 3pl drivers to the available drivers
                        available.push({
                            _id: '3pl',
                            firstName: '3PL',
                            lastName: 'Driver'
                        });

                        setAvailableDrivers(available);

                        setLoading(false);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                        toast.error(answer);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        getAvailableDrivers();
    }, []);

    // get all fleet schedules
    const allFleetSchedules = async (data) => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/salesplanning/assigneddriverlist`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            if (data) {
                if (data.startDate) urlParams.append('startDate', data.startDate);
                if (data.endDate) urlParams.append('endDate', data.endDate);
                if (data.country) urlParams.append('country', data.country);
                if (data.vehicleNo) urlParams.append('vehicleNo', data.vehicleNo);
                if (data.driver) urlParams.append('driver', data.driver);
                if (data.rma) urlParams.append('rma', data.rma);
                if (data.pod) urlParams.append('pod', data.pod);
            }

            url = `${url}?${urlParams.toString()}`;

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setFleetSchedule(answer);
                        setLoading(false);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                        toast.error(answer);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        allFleetSchedules();
        fetchCountries();
    }, []);

    const fleetScheduleOptions = fleetSchedule?.map((result) => ({
        key: result?._id,
        // eslint-disable-next-line no-bitwise
        date: `${dateConverter(result?.driveDate)} | ${result?.week}`,
        vehicleNo: result?.vehicleId?.vehicleNumber,
        driverName: result?.driverName,
        driver: (
            <div>
                <p>{result?.driverName}</p>
                <p style={{ color: '#9DA9AF', fontSize: '12px' }}>Vehicle: {result?.vehicleId?.vehicleNumber}</p>
            </div>
        ),
        location: (
            <div>
                {result.locations.slice(0, 2).map((r, index) => (
                    <span key={index} className="d-flex justify-content-space-between mb-2" style={{ fontSize: '14px' }}>
                        {r.route}
                    </span>
                ))}
                {result.locations.length > 2 && (
                    <Button
                        type="primary"
                        style={{
                            backgroundColor: 'transparent',
                            color: '#FF740F',
                            border: 'none',
                            padding: '0',
                            width: 'fit-content',
                            outline: 'none',
                            boxShadow: 'none'
                        }}
                        onClick={() => {
                            setRoutesModal(true);
                            setColumnRoutes(result.locations);
                            setRmaPick(`${result?.rmaId?.firstName.trim()} ${result?.rmaId?.lastName.trim()}`);
                        }}
                    >
                        All routes
                    </Button>
                )}
            </div>
        ),
        rma: `${result?.rmaId?.firstName} ${result?.rmaId?.lastName}`,
        arrivaltime: (
            <div className="d-flex align-items-center">
                {result?.arrivaltime ? <span>{result?.arrivaltime.split(' ')[1] || result?.arrivaltime}</span> : <span>Not available</span>}
                {/* <Button */}
                {/*    className="ml-2" */}
                {/*    style={{ color: '#FF740F', padding: '0px', width: 'fit-content', backgroundColor: 'transparent' }} */}
                {/*    onClick={() => { */}
                {/*        setActionType('arrival'); */}
                {/*        setTimeModal(true); */}
                {/*        setAssignedSchudleId(result._id); */}
                {/*    }} */}
                {/* > */}
                {/*    <span> */}
                {/*        Edit <BiPencil sx={{ width: '16px' }} /> */}
                {/*    </span> */}
                {/* </Button> */}
            </div>
        ),
        wait: result?.waittime,
        dispatch: (
            <div className="d-flex align-items-center">
                {result?.dispatchtime ? (
                    <span>{result?.dispatchtime.split(' ')[1] || result?.dispatchtime}</span>
                ) : (
                    <span>Not available</span>
                )}
                {/* <Button */}
                {/*    className="ml-2" */}
                {/*    style={{ color: '#FF740F', padding: '0px', width: 'fit-content', backgroundColor: 'transparent' }} */}
                {/*    onClick={() => { */}
                {/*        setActionType('dispatch'); */}
                {/*        setTimeModal(true); */}
                {/*        setAssignedSchudleId(result._id); */}
                {/*    }} */}
                {/* > */}
                {/*    <span className="ml-2"> */}
                {/*        Edit <BiPencil sx={{ width: '16px' }} /> */}
                {/*    </span> */}
                {/* </Button> */}
            </div>
        ),
        pod: result?.podId?.name,
        waybill: (
            <div className="edit-trackfleet-button">
                {result?.waybill === null ? (
                    <Button
                        onClick={() => (window.location.href = `/waybill/print/${result?.waybill}`)}
                        style={{ boxShadow: 'none' }}
                        className="assign-fleet-button"
                    >
                        View waybill <KeyboardArrowRightIcon sx={{ width: '16px' }} />
                    </Button>
                ) : (
                    <span>Not available</span>
                )}
            </div>
        )
    }));

    const countryOptions = countries?.map((result) => ({
        value: result._id,
        label: result.name
    }));
    const rmasOptions = rmas?.map((rma) => {
        return {
            label: `${rma.firstName.trim()} - ${rma.lastName.trim()}`,
            value: rma.userId
        };
    });

    const countryOption = countryOptions.map((item, index) => {
        return { value: item.label, label: item._id };
    });

    const AssignFleet = async (event) => {
        event.preventDefault();
        setAssignLoading(true);
        const userInfo = localStorage.getItem('userToken');

        const driveScheduleStructure = scheduleId.map((schedule) => {
            return {
                driveScheduleId: schedule
            };
        });

        const data = {
            driverId: driver === '3PL Driver' ? thirdPartyDriver : driver,
            vehicleId: vehicle,
            driveSchedule: driveScheduleStructure
        };
        const obj = JSON.stringify(data);
        const result = encrypt(obj, keyResult, ivResult);
        await fetch(`${ApiUrl.BaseUrl}/salesplanning/assigndriver`, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ payload: result })
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setAssignModal(false);
                    setDriver('');
                    setVehicle('');
                    allSalesDriveSchedule();
                    allFleetSchedules();
                    setScheduleId([]);
                } else if (result.status === false) {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                } else {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                }
            })
            .finally(() => {
                setAssignLoading(false);
            });
    };

    const updateFleetHandler = async () => {
        setLoading(true);
        const userInfo = localStorage.getItem('userToken');
        const url = `${ApiUrl.BaseUrl}/salesplanning/updateassignedfleets`;

        const driverID = availableDrivers.find(
            // eslint-disable-next-line camelcase
            (driver_found) => `${driver_found.firstName.trim()} ${driver_found.lastName.trim()}` === driver
        )?._id;

        // eslint-disable-next-line camelcase
        const vehicleID = allVehilces.find((vehicle_found) => vehicle_found.vehicleNumber === vehicle)?._id;

        const data = {
            assignedSchudleId,
            arrivalDateTime: dayjs(arrivalDateTime).format('YYYY-MM-DD HH:mm:ss'),
            dispatchDateTime: dayjs(dispatchDateTime).format('YYYY-MM-DD HH:mm:ss'),
            driverId: driver === '3PL Driver' ? thirdPartyDriver : driver,
            vehicleId: vehicleID || vehicle
        };

        const obj = JSON.stringify(data);
        const result = encrypt(obj, keyResult, ivResult);
        await fetch(url, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ payload: result })
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setLoading(false);
                    toast.success(result.message);
                    setTimeModal(false);
                    setArrivalDateTime('');
                    setDispatchDateTime('');
                    setFilter(2);
                    setEditScheduleModal(false);
                    allFleetSchedules();
                } else if (result.status === false) {
                    setLoading(false);
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                } else {
                    setLoading(false);
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                }
            })
            .finally(() => {
                setLoading(false);
                setAssignLoading(false);
                setEditScheduleModal(false);
            });
    };

    const deleteScheduleHandler = async () => {
        setLoading(true);
        const userInfo = localStorage.getItem('userToken');
        const url = `${ApiUrl.BaseUrl}/salesplanning/removeassignedfleet?id=${assignedSchudleId}`;

        await fetch(url, {
            method: 'DELETE',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setLoading(false);
                    toast.success(result.message);
                    setDeleteModal(false);
                    setFilter(2);
                    allFleetSchedules();
                } else if (result.status === false) {
                    setLoading(false);
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                } else {
                    setLoading(false);
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                }
            })
            .finally(() => {
                setLoading(false);
                setAssignLoading(false);
                setEditScheduleModal(false);
            });
    };

    const handleDateChange = (date, dateString) => {
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
        setSelectedDate(date);
    };

    const applyFilterHandler = () => {
        const data = {
            startDate,
            endDate,
            country,
            rma,
            pod,
            driver
        };
        allFleetSchedules(data);
    };

    const columns = [
        {
            title: 'Drive date',
            dataIndex: 'date',
            key: 'date'
        },
        {
            title: 'RMA',
            dataIndex: 'rma',
            key: 'rma'
        },
        {
            title: 'Drive Type',
            dataIndex: 'driveType',
            key: 'driveType'
        },
        {
            title: 'POD',
            dataIndex: 'pod',
            key: 'pod'
        },
        {
            title: 'Routes',
            dataIndex: 'location',
            key: 'location'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        }
    ];

    const columns2 = [
        {
            title: 'Drive date',
            dataIndex: 'date',
            key: 'date'
        },
        {
            title: 'POD',
            dataIndex: 'pod',
            key: 'pod'
        },
        {
            title: 'RMA',
            dataIndex: 'rma',
            key: 'rma'
        },
        {
            title: 'Driver',
            dataIndex: 'driver',
            key: 'driver'
        },
        {
            title: 'Arrival time',
            dataIndex: 'arrivaltime',
            key: 'arrivaltime'
        },
        {
            title: 'Dispatch time',
            dataIndex: 'dispatch',
            key: 'dispatch'
        },
        {
            title: 'Wait',
            dataIndex: 'wait',
            key: 'wait'
        },
        {
            title: 'Routes',
            dataIndex: 'location',
            key: 'location'
        },
        {
            title: 'Waybill',
            dataIndex: 'waybill',
            key: 'waybill'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button
                        style={{
                            color: '#FF740F',
                            width: 'fit-content',
                            backgroundColor: 'transparent',
                            border: '1px solid #FF740F'
                        }}
                        onClick={() => {
                            setAssignedSchudleId(record.key);
                            setDriver(record.driverName);
                            setVehicle(record.vehicleNo);
                            setArrivalDateTime(record.arrivaltimeplacholder);
                            setDispatchDateTime(record.dispatchtimeplacholder);
                            setEditScheduleModal(true);
                        }}
                    >
                        <span>
                            Edit <KeyboardArrowRightIcon sx={{ width: '16px' }} />
                        </span>
                    </Button>
                    <Button
                        style={{
                            color: '#FF740F',
                            width: 'fit-content',
                            backgroundColor: 'transparent',
                            border: '1px solid #FF740F'
                        }}
                        onClick={() => {
                            setAssignedSchudleId(record.key);
                            setDeleteModal(true);
                        }}
                    >
                        <span>Delete</span>
                    </Button>
                </Space>
            )
        }
    ];

    useEffect(() => {
        // Flatten and set the initial columnRoutes based on fetched data
        setColumnRoutes(fleetSchedule.flatMap((route) => route.locations));
    }, [fleetSchedule]);

    const handleSearch = (e) => {
        const value = e.target.value;
        if (value) {
            const filteredData = columnRoutes.filter((route) => route?.route?.toLowerCase().includes(value.toLowerCase()));
            setColumnRoutes(filteredData);
        } else {
            const filteredRoutes = fleetSchedule.flatMap((route) => {
                const rmaName = `${route?.rmaId?.firstName.trim()} ${route?.rmaId?.lastName.trim()}`;
                if (rmaName === rmaPick) {
                    return route.locations || []; // Ensure route.routes is returned as an array
                }
                return [];
            });
            setColumnRoutes(filteredRoutes);
        }
    };

    return (
        <div>
            <div className="d-flex justify-content-space-between leadHeaders" style={{ borderBottom: '1px dashed #E7EAEB' }}>
                <div>
                    <p style={{ fontWeight: 500, fontSize: '1.875rem', color: '#0A2938', padding: 0 }}>Drive management</p>
                    <p style={{ fontSize: '0.875rem', color: '#565C6B', padding: 0 }}>Manage and organize drive and fleet schedules</p>
                </div>
                {filter === 1 && scheduleId.length > 0 ? (
                    <Button
                        style={{
                            boxShadow: 'none',
                            backgroundColor: '#FF740F',
                            color: '#FFFFFF',
                            width: 'fit-content'
                        }}
                        onClick={() => {
                            setAssignModal(true);
                        }}
                    >
                        Assign Fleet <KeyboardArrowRightIcon sx={{ width: '16px' }} />
                    </Button>
                ) : null}
            </div>
            <div>
                <Button
                    type="primary"
                    style={{
                        backgroundColor: filter === 1 ? '#ECEDEE' : '#FFFFFF',
                        border: '1px solid #A1A5AD',
                        borderRadius: '200px',
                        boxShadow: 'none',
                        color: filter === 1 ? '#0A2938' : '#0A2938',
                        textAlign: 'center',
                        fontWeight: '400',
                        fontSize: '16px',
                        height: '44px'
                    }}
                    onClick={() => toggleFilter(1)}
                >
                    Assign fleet
                </Button>
                <Button
                    type="primary"
                    style={{
                        backgroundColor: filter === 2 ? '#ECEDEE' : '#F6F6F6',
                        borderRadius: '200px',
                        border: '1px solid #A1A5AD',
                        boxShadow: 'none',
                        height: '44px',
                        color: filter === 2 ? '#0A2938' : '#0A2938',
                        textAlign: 'center',
                        margin: '0.625rem',
                        fontWeight: '400',
                        fontSize: '16px'
                    }}
                    onClick={() => toggleFilter(2)}
                >
                    Fleet schedule
                </Button>
            </div>
            <div
                style={{
                    backgroundColor: '#fff',
                    borderRadius: '10px'
                }}
            >
                {filter === 1 && (
                    <div>
                        <div style={{ padding: '10px' }}>
                            <Collapse accordion>
                                <Collapse.Panel key={1} header="Hide filters" className="mb-4">
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'justify-content-space-between',
                                            alignItems: 'center',
                                            flexWrap: 'wrap'
                                        }}
                                    >
                                        <Select
                                            style={{
                                                width: 300,
                                                margin: '0.625rem'
                                            }}
                                            size="large"
                                            placeholder="Search RMA"
                                            options={rmasOptions}
                                            onChange={(value) => filterRma(value)}
                                            filterOption={(inputValue, option) => {
                                                if (typeof option.label === 'string') {
                                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                }

                                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }}
                                            showSearch
                                            allowClear
                                        />
                                        <RangePicker
                                            format="YYYY-MM-DD"
                                            size="large"
                                            style={{
                                                width: '300px',
                                                margin: '0.625rem'
                                            }}
                                            value={selectedDate}
                                            onChange={filterSalesDate}
                                        />
                                        <Select
                                            style={{
                                                width: 300,
                                                margin: '0.625rem'
                                            }}
                                            size="large"
                                            placeholder="Filter By Pod"
                                            options={podsOptions}
                                            onChange={(value) => filterPod(value)}
                                            filterOption={(inputValue, option) => {
                                                if (typeof option.label === 'string') {
                                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                }

                                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }}
                                            showSearch
                                            allowClear
                                        />
                                        <Select
                                            style={{
                                                width: 300,
                                                margin: '0.625rem'
                                            }}
                                            size="large"
                                            placeholder="Drive type"
                                            options={driveTypeOptions}
                                            onChange={(value) => filterDriveType(value)}
                                            filterOption={(inputValue, option) => {
                                                if (typeof option.label === 'string') {
                                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                }

                                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }}
                                            showSearch
                                            allowClear
                                        />
                                    </div>
                                </Collapse.Panel>
                            </Collapse>
                        </div>
                        <div className="table-responsive">
                            <Table
                                className="table"
                                loading={loading ? { indicator: <Spin indicator={antIcon} />, spinning: true / false } : ''}
                                columns={columns}
                                dataSource={salesDriveOptions}
                                rowSelection={{
                                    type: 'checkbox',
                                    getCheckboxProps: (record) => ({
                                        disabled: record.action === 'Assigned' // Disable checkbox if planStatus is 'Assigned'
                                    }),
                                    onChange: (selectedRowKeys, selectedRows) => {
                                        setScheduleId(selectedRowKeys);
                                    }
                                }}
                                locale={{
                                    emptyText: <EmptyState text="No data found" width="300px" padding="15px" />
                                }}
                            />
                        </div>
                    </div>
                )}
                {filter === 2 && (
                    <div>
                        <div style={{ padding: '10px' }}>
                            <Collapse accordion>
                                <Collapse.Panel key={1} header="Hide filters" className="mb-4">
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'justify-content-space-between',
                                            alignItems: 'center',
                                            flexWrap: 'wrap'
                                        }}
                                    >
                                        <Select
                                            style={{
                                                width: 300,
                                                margin: '0.625rem'
                                            }}
                                            size="large"
                                            placeholder="Search Country"
                                            defaultValue="Search Country"
                                            options={countryOption}
                                            value={country || undefined}
                                            onChange={(value) => setCountry(value)}
                                            filterOption={(inputValue, option) => {
                                                if (typeof option.label === 'string') {
                                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                }

                                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }}
                                            showSearch
                                            allowClear
                                        />
                                        <Select
                                            style={{
                                                width: 300,
                                                margin: '0.625rem'
                                            }}
                                            size="large"
                                            placeholder="Search RMA"
                                            defaultValue="Search RMA"
                                            options={rmasOptions}
                                            value={rma || undefined}
                                            onChange={(value) => setRma(value)}
                                            filterOption={(inputValue, option) => {
                                                if (typeof option.label === 'string') {
                                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                }

                                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }}
                                            showSearch
                                            allowClear
                                        />
                                        <RangePicker
                                            format="YYYY-MM-DD"
                                            size="large"
                                            style={{
                                                width: '300px',
                                                margin: '0.625rem'
                                            }}
                                            value={selectedDate}
                                            onChange={(date, dateString) => {
                                                handleDateChange(date, dateString);
                                            }}
                                        />
                                        <Select
                                            style={{
                                                width: 300,
                                                margin: '0.625rem'
                                            }}
                                            size="large"
                                            placeholder="Filter By Pod"
                                            defaultValue="Filter By Pod"
                                            options={podsOptions}
                                            value={pod || undefined}
                                            onChange={(value) => setPod(value)}
                                            filterOption={(inputValue, option) => {
                                                if (typeof option.label === 'string') {
                                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                }

                                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }}
                                            showSearch
                                            allowClear
                                        />
                                        <Select
                                            style={{
                                                width: 300,
                                                margin: '0.625rem'
                                            }}
                                            size="large"
                                            placeholder="Driver"
                                            defaultValue="Driver"
                                            options={availableDrivers?.map((driver) => {
                                                return {
                                                    label: `${driver.firstName} ${driver.lastName}`,
                                                    value: driver._id
                                                };
                                            })}
                                            value={driver || undefined}
                                            onChange={(value) => setDriver(value)}
                                            filterOption={(inputValue, option) => {
                                                if (typeof option.label === 'string') {
                                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                }

                                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }}
                                            showSearch
                                            allowClear
                                        />
                                        <div className="d-flex align-items-center">
                                            {selectedDate || pod || driver || rma || country ? (
                                                <Button
                                                    onClick={() => applyFilterHandler()}
                                                    style={{
                                                        color: '#FF740F',
                                                        border: '1px solid #FF740F'
                                                    }}
                                                    loading={loading}
                                                >
                                                    Apply filter
                                                </Button>
                                            ) : null}

                                            {selectedDate || pod || driver || rma || country ? (
                                                <Button
                                                    onClick={() => {
                                                        setFilter(2);
                                                        setCountry('');
                                                        setPod('');
                                                        setRma('');
                                                        setDriver('');
                                                        setSelectedDate(null);
                                                        allFleetSchedules();
                                                    }}
                                                    style={{
                                                        color: '#FF740F',
                                                        border: '1px solid #FF740F'
                                                    }}
                                                    className="ml-2"
                                                >
                                                    Reset filter
                                                </Button>
                                            ) : null}
                                        </div>
                                    </div>
                                </Collapse.Panel>
                            </Collapse>
                        </div>
                        <div className="table-responsive">
                            <Table
                                className="table"
                                loading={loading ? { indicator: <Spin indicator={antIcon} />, spinning: true / false } : ''}
                                columns={columns2}
                                dataSource={fleetScheduleOptions}
                                locale={{
                                    emptyText: <EmptyState text="No data found" width="300px" padding="15px" />
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
            <Modal width={700} title="Assign Driver" open={assignModal} onCancel={() => setAssignModal(false)} footer={null}>
                <div>
                    <div className="d-flex justify-content-space-between align-items-center flex-wrap mt-4 mb-4">
                        <div>
                            <p style={{ fontSize: '12px', paddingBottom: '3px', marginBottom: '0' }}>Driver Name</p>
                            <Select
                                style={{
                                    width: 300
                                }}
                                className="mt-1"
                                size="medium"
                                placeholder="Select Driver"
                                showSearch
                                allowClear
                                options={availableDrivers?.map((driver) => {
                                    return {
                                        label: `${driver.firstName} ${driver.lastName}`,
                                        value: `${driver.firstName.trim()} ${driver.lastName.trim()}`
                                    };
                                })}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                value={driver || null}
                                onChange={(value) => setDriver(value)}
                            />
                        </div>
                        <div>
                            <p style={{ fontSize: '12px', paddingBottom: '3px', marginBottom: '0' }}>Select Vehicle</p>
                            <Select
                                style={{
                                    width: 300
                                }}
                                className="mt-1"
                                size="medium"
                                placeholder="Select Vehicle"
                                options={vehicleOptions}
                                value={vehicle || null}
                                onChange={(value) => setVehicle(value)}
                                showSearch
                                allowClear
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        {driver === '3PL Driver' ? (
                            <div>
                                <p style={{ fontSize: '12px', paddingBottom: '3px', marginBottom: '0' }}>3PL Driver Name</p>
                                <Input
                                    size="medium"
                                    style={{
                                        width: 300
                                    }}
                                    className="mt-1"
                                    placeholder="3PL Drive Name"
                                    value={thirdPartyDriver}
                                    onChange={(e) => setThirdPartyDriver(e.target.value)}
                                />
                            </div>
                        ) : null}
                    </div>
                    <div className="d-flex justify-content-space-between align-items-center flex-wrap mt-4">
                        <Button
                            type="primary"
                            style={{
                                backgroundColor: '#FFFFFF',
                                borderRadius: '10px',
                                border: '1px solid #FF740F',
                                boxShadow: 'none',
                                height: '44px',
                                color: '#FF740F',
                                textAlign: 'center',
                                fontWeight: '400',
                                fontSize: '16px',
                                width: 300
                            }}
                            className="flex-grow-1"
                            onClick={() => setAssignModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            style={{
                                backgroundColor: driver && vehicle && driver === '3PL Driver' && thirdPartyDriver ? '#FF740F' : '#A1A5AD',
                                borderRadius: '10px',
                                boxShadow: 'none',
                                height: '44px',
                                color: '#FFFFFF',
                                textAlign: 'center',
                                fontWeight: '400',
                                fontSize: '16px',
                                width: 300
                            }}
                            disabled={!driver || !vehicle || (driver === '3PL Driver' && !thirdPartyDriver) || assingLoading}
                            className="flex-grow-1 ml-2"
                            onClick={AssignFleet}
                        >
                            {assingLoading ? 'Assigning...' : 'Assign Driver'}
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal title="Edit Schedule" open={editScheduleModal} onCancel={() => setEditScheduleModal(false)} footer={null}>
                <div className="mt-4">
                    <div className="d-flex justify-content-space-between">
                        <div className="flex-grow-1">
                            <div>
                                <Select
                                    className="w-100"
                                    size="medium"
                                    placeholder="Select Driver"
                                    showSearch
                                    allowClear
                                    options={availableDrivers?.map((driver) => {
                                        return {
                                            label: `${driver.firstName} ${driver.lastName}`,
                                            value: `${driver.firstName} ${driver.lastName}`
                                        };
                                    })}
                                    filterOption={(inputValue, option) => {
                                        if (typeof option.label === 'string') {
                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }

                                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }}
                                    value={driver || null}
                                    onChange={(value) => setDriver(value)}
                                />
                            </div>
                            <div className="mt-3">
                                {driver === '3PL Driver' ? (
                                    <div>
                                        <Input
                                            size="medium"
                                            style={{
                                                width: 300
                                            }}
                                            className="mt-1"
                                            placeholder="3PL Drive Name"
                                            value={thirdPartyDriver}
                                            onChange={(e) => setThirdPartyDriver(e.target.value)}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="flex-grow-1 ml-2">
                            <Select
                                className="w-100"
                                size="medium"
                                placeholder="Select Vehicle"
                                options={vehicleOptions}
                                value={vehicle || null}
                                onChange={(value) => setVehicle(value)}
                                showSearch
                                allowClear
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-space-between align-items-center mt-4">
                        <div className="flex-grow-1">
                            <TimePicker
                                size="medium"
                                placeholder="Arrival time"
                                className="w-100"
                                value={arrivalDateTime}
                                onChange={(value) => setArrivalDateTime(value)}
                                label="Arrival time"
                                showSearch
                                allowClear
                            />
                        </div>
                        <div className="ml-2 flex-grow-1">
                            <TimePicker
                                size="medium"
                                placeholder="Dispatch time"
                                className="w-100"
                                value={dispatchDateTime}
                                onChange={(value) => setDispatchDateTime(value)}
                                label="Dispatch time"
                                showSearch
                                allowClear
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-space-between align-items-center mt-4">
                        <Button
                            type="primary"
                            style={{
                                backgroundColor: '#FFFFFF',
                                borderRadius: '10px',
                                border: '1px solid #FF740F',
                                boxShadow: 'none',
                                height: '44px',
                                color: '#FF740F',
                                marginRight: '20px',
                                textAlign: 'center',
                                fontWeight: '400',
                                fontSize: '16px'
                            }}
                            className="flex-grow-1"
                            onClick={() => setEditScheduleModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            style={{
                                backgroundColor:
                                    actionType === 'arrival'
                                        ? arrivalDateTime
                                            ? '#FF740F'
                                            : '#A1A5AD'
                                        : dispatchDateTime
                                        ? '#FF740F'
                                        : '#A1A5AD',
                                borderRadius: '10px',
                                boxShadow: 'none',
                                height: '44px',
                                color: '#FFFFFF',
                                textAlign: 'center',
                                fontWeight: '400',
                                fontSize: '16px'
                            }}
                            className="flex-grow-1"
                            onClick={updateFleetHandler}
                            loading={loading}
                        >
                            update
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal title={`${rmaPick} Routes`} open={routesModal} onCancel={() => setRoutesModal(false)} footer={null}>
                <div className="mt-4">
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Input
                            onChange={handleSearch}
                            addonBefore={<SearchOutlined />}
                            placeholder="Search route"
                            style={{ width: '300px' }}
                        />
                        <span className="text-center">
                            Total routes <b>{columnRoutes.length}</b>
                        </span>
                    </div>
                    {columnRoutes?.length > 0 ? (
                        <div className="mt-4">
                            {columnRoutes.map(
                                (route, index) =>
                                    route &&
                                    route.route && (
                                        <div key={route._id || index}>
                                            <li key={index} className="mt-4">
                                                <span className="ml-2">{route.route}</span>
                                            </li>
                                            {route.route.length > 0 && <Divider style={{ margin: '10px 0' }} />}
                                        </div>
                                    )
                            )}
                        </div>
                    ) : (
                        <div className="mt-4">
                            <p>No routes</p>
                        </div>
                    )}
                </div>
            </Modal>
            <Modal title="Delete Schedule" open={deleteModal} onCancel={() => setDeleteModal(false)} footer={null}>
                <div>
                    <div
                        className="mb-4 mt-4 text-center"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            height: '100px'
                        }}
                    >
                        <p>Are you sure you want to delete this schedule?</p>
                    </div>
                    <div className="d-flex justify-content-between mt-4">
                        <Button
                            style={{
                                backgroundColor: 'transparent',
                                color: '#FF740F',
                                border: '1px solid #FF740F',
                                width: '48%'
                            }}
                            onClick={() => {
                                setDeleteModal(false);
                            }}
                        >
                            No
                        </Button>
                        <Button
                            style={{
                                backgroundColor: '#FF740F',
                                color: '#fff',
                                border: '1px solid #FF740F',
                                width: '48%'
                            }}
                            onClick={() => {
                                deleteScheduleHandler();
                            }}
                            loading={loading}
                        >
                            Yes
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Fleet;
