import axios from 'axios';
import Compressor from 'compressorjs';

const normFile = async (file, userInfo, rootURL) => {
    let fileToUpload = file;

    if (file.type.startsWith('image/')) {
        fileToUpload = await new Promise((resolve) => {
            // eslint-disable-next-line no-new
            new Compressor(file, {
                quality: 0.8,
                maxWidth: 400,
                maxHeight: 400,
                success: (result) => {
                    resolve(result);
                }
            });
        });
    }

    const formData = new FormData();
    formData.append('file', fileToUpload);

    try {
        const response = await axios.post(`${rootURL}/channelpartner/uploadpartsimage`, formData, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'multipart/form-data'
            }
        });

        if (response.data.status === true) {
            return response.data.data || 'https://example.com/default-placeholder.png';
        }
        throw new Error('Upload failed');
    } catch (error) {
        throw new Error('Upload failed');
    }
};

export default normFile;
