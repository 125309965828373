/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from 'assets/images/garage.png';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import AuthCardWrapper from '../pages/authentication/AuthCardWrapper';
import AuthWrapper1 from '../pages/authentication/AuthWrapper1';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ApiUrl from 'network';
import useScriptRef from 'hooks/useScriptRef';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Form from 'react-bootstrap/Form';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import nologo from 'assets/images/nologo.png';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    backgroundColor: {
        backgroundColor: '#2CB23B'
    }
}));

const ViewCssOrder = () => {
    const classes = useStyles();
    const history = useNavigate();
    const [orderDate, setorderDate] = useState(new Date());
    const [cash, setCash] = useState('');
    // const [parts, setParts] = useState('');
    const [salesTeamId, setsalesTeamId] = useState('');
    const [partQtyInPieces, setpartQtyInPieces] = useState('');
    const [channelId, setChannelId] = useState('');
    const [newChannelId, setNewChannelId] = useState('');
    const [channelPartnerId, setChannelPartnerRecord] = useState('');
    const [newChannelPartnerId, setNewChannelPartnerId] = useState('');
    const [qty, setQty] = useState('');
    const [partid, setPartId] = useState('');
    const [skuId, setSkuId] = useState('');
    const [brand, setBrand] = useState('');
    const [wholeSalePrice, setwholeSalePrice] = useState('');
    const [garagePrice, setgaragePrice] = useState('');
    const [applicableVins, setapplicableVins] = useState('');
    const [dropOffLocation, setdropOffLocation] = useState('');
    const [dropOffLocation2, setdropOffLocation2] = useState('');
    const [partnerContact, setpartnerContact] = useState('');
    const [salesname, setsalesname] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [salesTeamInfo, setSalesTeam] = useState([]);
    const [channel, setChannel] = useState([]);
    const [channelpartner, setChannelPartner] = useState([]);
    const [part, setPart] = useState([]);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [items, setItems] = useState([]);
    const [partType, setpartType] = useState([]);
    const [partsdata, setpartsdata] = useState([]);
    const [orderNotes, setorderNotes] = useState('');
    const [partname, setpartname] = useState('');
    const [partsize, setpartsize] = useState('');
    const [partqty, setpartqty] = useState('');
    const [orderStatusId, setorderStatusId] = useState('');
    const [newOrderStatusId, setnewOrderStatusId] = useState('');
    const [orderStatus, setOrderStatus] = useState([]);
    const [supplier, setSupplier] = useState('');
    const [supplier2, setSupplier2] = useState('');
    const [shopsdata, setshopsdata] = useState([]);
    const [shopname, setshopname] = useState('');
    const [shopstatus, setshopstatus] = useState(false);
    const [retailerStatus, setretailerStatus] = useState('');
    const [fulOrderId, setfulOrderId] = useState('');
    const [sizepart, setsizepart] = useState(0);
    const [partimage, setpartimage] = useState('');
    const [oldwholeSalePrice, setoldwholeSalePrice] = useState('');
    const [oldgaragePrice, setoldgaragePrice] = useState('');
    const [discountedQty, setdiscountedQty] = useState(0);
    const [discountedCostPrice, setdiscountedCostPrice] = useState(0);
    const [discountedGaragePrice, setdiscountedGaragePrice] = useState(0);
    const { id } = useParams();

    const retrieveAllPart = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.PartPriceListDataUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setPart(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllChannel = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.ChannelActiveSalesPage, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setChannel(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAlLsalesTeam = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.SalesTeamActiveSalesPage, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setSalesTeam(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllChannelPartner = async (id) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            // setLoading2(true);
            fetch(ApiUrl.AllChannelPartnersSales + id, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setChannelPartner(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    // setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllActiveOrderStatus = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.AllSalesOrderStatus, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrderStatus(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const scriptedRef = useScriptRef();

    const handleOrderDateChange = (newValue) => {
        console.log('order date', newValue);
        // console.log('date', new Date());
        const date = new Date();
        setorderDate(date);
    };

    const addQty = (e) => {
        setQty(e.target.value);
        if (+Number(e.target.value) >= +Number(discountedQty) && partType === 'Pieces' && +Number(discountedQty) > 1) {
            setgaragePrice(discountedGaragePrice);
            setwholeSalePrice(discountedCostPrice);
        } else {
            // console.log('not above');
            // console.log('oldgaragePrice', oldgaragePrice);
            // console.log('oldwholeSalePrice', oldwholeSalePrice);

            setgaragePrice(garagePrice);
            setwholeSalePrice(oldwholeSalePrice);
        }
    };

    const handlePartType = (event) => {
        // setpartType(event.target.value);
        if (event.target.value === 'Boxes') {
            // console.log('qty size', partQtyInPieces);
            // console.log('qty', qty);
            if (partQtyInPieces === 0) {
                setpartType(event.target.value);
            } else {
                const size = 'Pieces';
                setpartType(size);
                const piece = Number(qty) * Number(partQtyInPieces);
                setQty(piece);
                if (+Number(piece) >= +Number(discountedQty) && size === 'Pieces' && +Number(discountedQty) > 1) {
                    setgaragePrice(discountedGaragePrice);
                    setwholeSalePrice(discountedCostPrice);
                }
            }
        } else if (event.target.value === 'Rolls') {
            // console.log('qty size', partQtyInPieces);
            if (partQtyInPieces === 0) {
                setpartType(event.target.value);
            } else {
                const size = 'Pieces';
                setpartType(size);
                const piece = Number(qty) * Number(partQtyInPieces);
                setQty(piece);
                if (+Number(piece) >= +Number(discountedQty) && size === 'Pieces' && +Number(discountedQty) > 1) {
                    setgaragePrice(discountedGaragePrice);
                    setwholeSalePrice(discountedCostPrice);
                }
            }
        } else if (event.target.valud === 'Packs') {
            // console.log('qty size', partQtyInPieces);
            if (partQtyInPieces === 0) {
                setpartType(event.target.value);
            } else {
                const size = 'Pieces';
                setpartType(size);
                const piece = Number(qty) * Number(partQtyInPieces);
                setQty(piece);
                if (+Number(piece) >= +Number(discountedQty) && size === 'Pieces' && +Number(discountedQty) > 1) {
                    setgaragePrice(discountedGaragePrice);
                    setwholeSalePrice(discountedCostPrice);
                }
            }
        } else {
            setpartType(event.target.value);
            if (+Number(qty) >= +Number(discountedQty) && event.target.value === 'Pieces' && +Number(discountedQty) > 1) {
                setgaragePrice(discountedGaragePrice);
                setwholeSalePrice(discountedCostPrice);
            }
        }
    };

    const handlePartTypeSize = (event) => {
        setpartsize(event.target.value);
        setSupplier2('Third Party');
    };

    const handleCashChange = (event) => {
        setCash(event.target.value);
    };
    const handleSalesChange = (event) => {
        setsalesTeamId(event.target.value);
        retrieveAllChannelPartner(event.target.value);
        const newFilter = salesTeamInfo.filter((value) => value._id.includes(event.target.value));
        newFilter.forEach((option) => {
            if (option?.country === 'Nigeria') {
                const fullname = `${option.firstName} ${option.lastName}`;
                setsalesname(fullname);
            } else {
                setsalesname(option.name);
            }
        });
    };

    const handleChannelChange = (event) => {
        // console.log('channelid', event.target.value);
        setChannelId(event.target.value);

        const newFilter = channel.filter((value) => value._id.includes(event.target.value));
        newFilter.forEach((option) => {
            setNewChannelId(option._id);
        });
    };
    const filterOptions = (event) => {
        const newFilter = channelpartner.filter((value) => (value.partnerName || '').toLowerCase().includes((event || '').toLowerCase()));

        newFilter.forEach((option) => {
            if (option.partnerName === event) {
                setNewChannelPartnerId(option._id);
                const location = `${option.locationLat} ${option.locationLng}`;
                setdropOffLocation(location);
                setpartnerContact(option.partnerContact);
                setdropOffLocation2(option.location);
                if (option?.shopsdata.length > 0) {
                    // console.log('shop here');
                    setshopstatus(true);
                    setshopsdata(option?.shopsdata ?? []);
                }
            }
        });
    };

    const filterOptionsShop = (event) => {
        const newFilter = shopsdata.filter((value) => (value.shopname || '').toLowerCase().includes((event || '').toLowerCase()));
        newFilter.forEach((option) => {
            // console.log('option', option);

            const location = `${option.shoplat} ${option.shoplng}`;
            setdropOffLocation(location);

            setdropOffLocation2(option.shoplocation);
        });
    };

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.ViewOrdersUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    // console.log('id', result?.data);
                    setorderDate(result?.data?.orderDate ?? '');
                    setChannelId(result?.data?.newChannelId?.name ?? '');
                    setNewChannelId(result?.data?.newChannelId?._id ?? '');

                    setChannelPartnerRecord(result?.data?.newChannelPartnerId?.partnerName ?? '');
                    setNewChannelPartnerId(result?.data?.newChannelPartnerId?._id ?? '');
                    retrieveAllChannelPartner(result?.data?.salesTeamId?._id ?? '');
                    setpartnerContact(result?.data?.newChannelPartnerId?.partnerContact ?? '');
                    setdropOffLocation(result?.data?.dropOffLocation ?? '');
                    setdropOffLocation2(result?.data?.dropOffLocation2 ?? '');
                    setItems(result?.data?.items ?? '');
                    setsalesTeamId(result?.data?.salesTeamId?._id ?? '');
                    const fullname = `${result?.data?.salesTeamId?.firstName}${result?.data?.salesTeamId?.firstName}`;
                    setsalesname(result?.data?.salesTeamId?.name ?? fullname);
                    setCash(result?.data?.cash ?? '');
                    // setParts(result?.data?.parts ?? '');
                    setpartsdata(result?.data?.partsdata ?? []);
                    setorderNotes(result?.data?.orderNotes ?? '');
                    setorderStatusId(result?.data?.newOrderStatusId?.name ?? '');
                    setnewOrderStatusId(result?.data?.newOrderStatusId?._id ?? '');
                    setshopname(result?.data?.shopname ?? '');
                    setretailerStatus(result?.data?.retailerStatus ?? '');
                    setfulOrderId(result?.data?.fulOrderId ?? '');
                    setsizepart(result?.data?.sizepart ?? 0);

                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                    // setLoading(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading2(false);
            });
        retrieveAlLsalesTeam();
        retrieveAllChannel();
        retrieveAllActiveOrderStatus();

        retrieveAllPart();
    }, [id]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const handleOrderStatusChange = (event) => {
        setnewOrderStatusId(event.target.value);
        const date = new Date();
        setorderDate(date);
        const newFilter = orderStatus.filter((value) => (value._id || '').toLowerCase().includes((event.target.value || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            setorderStatusId(option.name);
        });
    };

    const onSubmitPartData = (event) => {
        event.preventDefault();
        // partsdata,setpartsdata
        setpartsdata([
            ...partsdata,
            {
                partname,
                partsize,
                partqty,
                supplier2
            }
        ]);

        handleClose2();
        // console.log(partsdata);
        let sum = Number(sizepart);
        sum += Number(partqty);
        // console.log('sum2', sum);
        setsizepart(sum);
    };

    const onSubmitPart = (event) => {
        event.preventDefault();
        // items, setItems
        setItems([
            ...items,
            {
                skuId,
                partid,
                qty,
                partType,
                brand,
                wholeSalePrice,
                garagePrice,
                applicableVins,
                supplier,
                partQtyInPieces,
                partimage
            }
        ]);

        handleClose();
        let sum = Number(sizepart);
        sum += Number(qty);
        console.log('sum', sum);
        setsizepart(sum);
    };

    const filterPart = (event) => {
        const newFilter = part.filter((value) => (value.sku || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('newFilter', newFilter);
        newFilter.forEach((option) => {
            setSkuId(option._id);
            setBrand(option.brand);
            setwholeSalePrice(option.wholeSalePrice);
            setoldwholeSalePrice(option?.wholeSalePrice);
            setgaragePrice(option.garagePrice);
            setoldgaragePrice(option?.partListPrice);
            setapplicableVins(option.applicableVins);
            setpartQtyInPieces(option?.partQtyInPieces ?? 0);
            setpartimage(option?.partsImage ?? '');
            if (option?.stock) {
                setSupplier(option?.stock);
            } else if (option?.distributor) {
                setSupplier(option?.distributor?.partnerName);
            } else {
                setSupplier('Third Party');
            }

            setdiscountedQty(option?.discountedQty ?? 0);
            setdiscountedCostPrice(option?.discountedCostPrice ?? 0);
            setdiscountedGaragePrice(option?.discountedGaragePrice ?? 0);
        });
    };
    const handleDeleteClick = (row, index) => {
        // const newList = items.filter((item) => item.skuId !== row.skuId);

        // setItems(newList);

        const newList = Object.assign([], items);
        newList.splice(index, 1);
        setItems(newList);
        const left = Number(sizepart) - Number(row.qty);
        console.log('left', left);
        setsizepart(left);
    };

    const handleDelete2Click = (row, index) => {
        const newList = Object.assign([], partsdata);
        newList.splice(index, 1);
        setpartsdata(newList);
        const left2 = Number(sizepart) - Number(row.partqty);
        console.log('left2', left2);
        setsizepart(left2);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const salesemail = localStorage.getItem('userEmail');
        const data = {
            id,
            orderDate,
            salesTeamId,
            channelId,
            newChannelId,
            channelPartnerId,
            newChannelPartnerId,
            cash,
            items,
            dropOffLocation,
            dropOffLocation2,
            partnerContact,
            salesname,
            orderNotes,
            orderStatusId,
            newOrderStatusId,
            partsdata,
            salesemail,
            shopname,
            retailerStatus,
            fulOrderId,
            sizepart
        };
        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        fetch(ApiUrl.UpdateSalesOrdersUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status) {
                    toast.success(result.message);
                    // setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            // window.location.reload();
                            history(-1);
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                // setLoading(false);
            });
    };
    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <AuthWrapper1>
                        <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                            <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                                    <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                                        <AuthCardWrapper>
                                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                                <Grid item sx={{ mb: 3 }}>
                                                    <Link to="#">
                                                        <img src={logo} alt="logo" height={50} />
                                                    </Link>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <br />
                                                    <Typography variant="h3" component="h2" textAlign="center">
                                                        Order Parts Request Form
                                                    </Typography>
                                                    <br />

                                                    <Typography variant="h5" component="h2" textAlign="center">
                                                        Use this form to input parts requests from channel partners
                                                    </Typography>
                                                    <br />

                                                    <br />
                                                    <form onSubmit={handleSubmit}>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <Stack spacing={3}>
                                                                <DesktopDatePicker
                                                                    label="Date"
                                                                    inputFormat="MM/dd/yyyy"
                                                                    value={orderDate}
                                                                    onChange={handleOrderDateChange}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                />
                                                            </Stack>
                                                        </LocalizationProvider>

                                                        <br />
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">Sales / Ops Focal Name</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={salesTeamId}
                                                                label="Sales Team"
                                                                onChange={handleSalesChange}
                                                            >
                                                                {salesTeamInfo.map((sales) => (
                                                                    <MenuItem key={sales._id} value={sales._id}>
                                                                        {sales.name} {sales?.firstName}
                                                                        {sales?.lastName}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>

                                                        <br />
                                                        <br />

                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">Channel Name</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={channelId}
                                                                label="Channel Name"
                                                                onChange={handleChannelChange}
                                                            >
                                                                {channel.map((channels) => (
                                                                    <MenuItem key={channels.name} value={channels.name}>
                                                                        {channels.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>

                                                        <br />
                                                        <br />
                                                        <Autocomplete
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            value={channelPartnerId}
                                                            onChange={(event, newValue) => {
                                                                setChannelPartnerRecord(newValue);
                                                                filterOptions(newValue);
                                                            }}
                                                            options={channelpartner.map((option) => option.partnerName)}
                                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                                            renderInput={(params) => <TextField {...params} label="Channel Partner Name" />}
                                                        />
                                                        <br />
                                                        <br />
                                                        {shopstatus ? (
                                                            <>
                                                                <Autocomplete
                                                                    disablePortal
                                                                    id="combo-box-demo"
                                                                    value={shopname}
                                                                    required
                                                                    onChange={(event, newValue) => {
                                                                        setshopname(newValue);
                                                                        filterOptionsShop(newValue);
                                                                    }}
                                                                    options={shopsdata.map((option) => option.shopname)}
                                                                    isOptionEqualToValue={(option, value) =>
                                                                        option.shopname === value.shopname
                                                                    }
                                                                    renderInput={(params) => <TextField {...params} label="Shop Name" />}
                                                                />
                                                            </>
                                                        ) : (
                                                            ''
                                                        )}
                                                        <br />
                                                        <br />
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">Order Status</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={newOrderStatusId}
                                                                required
                                                                label="Status"
                                                                onChange={handleOrderStatusChange}
                                                            >
                                                                <MenuItem value="Body">Select an option</MenuItem>
                                                                <MenuItem value="620d76d63a9c8148935c696b">Order Generated</MenuItem>
                                                                <MenuItem value="625311abbd74072935e58592">
                                                                    Proforma Invoice Request
                                                                </MenuItem>
                                                                <MenuItem value="6334bab7afa1158e678a87ad">Sales Drive</MenuItem>
                                                                {/* <MenuItem value="6342dc27f627d58acac98e0f">Dark Store</MenuItem> */}
                                                                <MenuItem value="6466771d8328e2d254fa6ac3">Retention Drive</MenuItem>
                                                                <MenuItem value="651da6eb9d048db5123bc205">PreSold</MenuItem>
                                                                <MenuItem value="6527fe1dba6c46cb61943f5c">Conversion Drive</MenuItem>
                                                            </Select>
                                                        </FormControl>

                                                        <br />
                                                        <br />
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">Cash or Credit</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={cash}
                                                                disabled
                                                                label="Status"
                                                                onChange={handleCashChange}
                                                            >
                                                                <MenuItem value="Body">Select an option</MenuItem>
                                                                <MenuItem value="Cash">Cash</MenuItem>
                                                                <MenuItem value="Credit">Credit</MenuItem>
                                                                <MenuItem value="Cheque">Cheque</MenuItem>
                                                                <MenuItem value="CashCredit">Cash and Credit</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <Typography variant="h5" component="h2">
                                                            Parts needed: select name, quantity e.g. [Grease 500g 5, Abro 12]
                                                        </Typography>
                                                        <br />

                                                        <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                                            <Button
                                                                variant="contained"
                                                                onClick={handleClickOpen}
                                                                size="sm"
                                                                className={classes.item}
                                                            >
                                                                Add Part
                                                            </Button>
                                                        </Box>
                                                        <br />

                                                        <Grid container spacing={2} justify="flex-start">
                                                            <Grid item xs={12} sm={6} md={4} lg={12}>
                                                                <TableContainer component={Paper}>
                                                                    <Table aria-label="caption table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    Part
                                                                                </TableCell>

                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    QTY
                                                                                </TableCell>
                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    Size
                                                                                </TableCell>
                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    Price
                                                                                </TableCell>

                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    Action
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {items.map((row, index) => (
                                                                                <TableRow key={index}>
                                                                                    <TableCell component="th" scope="row">
                                                                                        {row.partid}
                                                                                    </TableCell>

                                                                                    <TableCell component="th" scope="row">
                                                                                        {row.qty}
                                                                                    </TableCell>

                                                                                    <TableCell component="th" scope="row">
                                                                                        {row.partType}
                                                                                    </TableCell>

                                                                                    <TableCell component="th" scope="row">
                                                                                        {row.garagePrice}
                                                                                    </TableCell>

                                                                                    <TableCell component="th" scope="row">
                                                                                        <IconButton
                                                                                            aria-label="delete"
                                                                                            color="error"
                                                                                            onClick={() => handleDeleteClick(row, index)}
                                                                                        >
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Grid>
                                                        </Grid>
                                                        <br />
                                                        <br />
                                                        <Typography variant="h5" component="h2">
                                                            Only use this Button when the parts is not in the system.
                                                            <br /> <br />
                                                            Parts needed: include name, quantity and size.eg: [Grease 500g 12 pieces]
                                                        </Typography>

                                                        <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                                            <Button
                                                                variant="contained"
                                                                onClick={handleClickOpen2}
                                                                size="sm"
                                                                className={classes.backgroundColor}
                                                            >
                                                                Add Sales Part
                                                            </Button>
                                                        </Box>
                                                        <br />

                                                        <Grid container spacing={4} justify="flex-start">
                                                            <Grid item xs={12} sm={6} md={4} lg={12}>
                                                                <TableContainer component={Paper}>
                                                                    <Table aria-label="caption table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    Part
                                                                                </TableCell>

                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    QTY
                                                                                </TableCell>
                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    Size
                                                                                </TableCell>

                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    Action
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {partsdata.map((row, index) => (
                                                                                <TableRow key={index}>
                                                                                    <TableCell component="th" scope="row">
                                                                                        {row.partname}
                                                                                    </TableCell>
                                                                                    <TableCell component="th" scope="row">
                                                                                        {row.partqty}
                                                                                    </TableCell>

                                                                                    <TableCell component="th" scope="row">
                                                                                        {row.partsize}
                                                                                    </TableCell>

                                                                                    <TableCell component="th" scope="row">
                                                                                        <IconButton
                                                                                            aria-label="delete"
                                                                                            color="error"
                                                                                            onClick={() => handleDelete2Click(row, index)}
                                                                                        >
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Grid>
                                                        </Grid>

                                                        <br />
                                                        <br />

                                                        <TextField
                                                            autoComplete="off"
                                                            name="orderNotes"
                                                            variant="outlined"
                                                            multiline
                                                            rows={3}
                                                            fullWidth
                                                            value={orderNotes}
                                                            id="orderNotes"
                                                            label="Order Notes......."
                                                            onChange={(e) => setorderNotes(e.target.value)}
                                                        />

                                                        <br />
                                                        <br />
                                                        <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                                            <Button
                                                                type="submit"
                                                                variant="contained"
                                                                color="secondary"
                                                                disabled={!!loading}
                                                            >
                                                                {loading ? 'Updating ..' : 'Update'}
                                                            </Button>
                                                            <Button onClick={() => history(-1)} variant="contained" color="error">
                                                                Reset
                                                            </Button>
                                                        </Box>
                                                    </form>
                                                </Grid>
                                            </Grid>
                                        </AuthCardWrapper>
                                    </Grid>
                                </Grid>

                                <Dialog
                                    fullScreen={fullScreen}
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="responsive-dialog-title"
                                    fullWidth
                                >
                                    <div style={{ padding: '1rem 2rem' }}>
                                        <DialogTitle id="responsive-dialog-title" style={{ fontSize: '1rem' }}>
                                            Add Part
                                        </DialogTitle>
                                        <Form onSubmit={onSubmitPart}>
                                            <DialogContent>
                                                <Form.Group controlId="formFile" className="mb-3">
                                                    <Autocomplete
                                                        disablePortal
                                                        fullWidth
                                                        id="combo-box-demo"
                                                        value={partid}
                                                        onChange={(event, newValue) => {
                                                            setPartId(newValue);
                                                            filterPart(newValue);
                                                        }}
                                                        options={part.map((option) => option.sku)}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        renderInput={(params) => <TextField {...params} label="Sku" />}
                                                    />
                                                    <br /> <br />
                                                    <TextField
                                                        autoComplete="off"
                                                        disabled
                                                        type="number"
                                                        name="garagePrice"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        id="garagePrice"
                                                        label="Garage Price"
                                                        value={garagePrice}
                                                        onChange={(e) => setgaragePrice(e.target.value)}
                                                    />
                                                    <br /> <br />
                                                    <TextField
                                                        autoComplete="off"
                                                        name="qty"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        id="qty"
                                                        label="Qty"
                                                        value={qty}
                                                        onChange={(e) => {
                                                            addQty(e);
                                                        }}
                                                    />
                                                    <br /> <br />
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Part Size</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            fullWidth
                                                            value={partType}
                                                            label="Status"
                                                            onChange={handlePartType}
                                                        >
                                                            <MenuItem value="Body">Select an option</MenuItem>
                                                            <MenuItem value="Boxes">Boxe(s)</MenuItem>
                                                            <MenuItem value="Pieces">Pieces</MenuItem>
                                                            <MenuItem value="Gallons">Gallons</MenuItem>
                                                            <MenuItem value="Drums">Drums</MenuItem>
                                                            <MenuItem value="Rolls">Roll(s)</MenuItem>
                                                            <MenuItem value="Bucket">Bucket</MenuItem>
                                                            <MenuItem value="Packs">Pack(s)</MenuItem>
                                                            <MenuItem value="Litre">Litre(s)</MenuItem>
                                                            <MenuItem value="Pair">Pair(s)</MenuItem>
                                                            <MenuItem value="Carton">Carton(s)</MenuItem>
                                                            <MenuItem value="Bag">Bag(s)</MenuItem>
                                                            <MenuItem value="Set">Set(s)</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <br /> <br />
                                                    <Avatar
                                                        variant="rounded"
                                                        fullWidth
                                                        src={partimage ?? nologo}
                                                        style={{ border: 0, objectFit: 'cover' }}
                                                        sx={{ width: '100%', height: '400px' }}
                                                    />
                                                </Form.Group>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    variant="contained"
                                                    size="sm"
                                                    className={classes.item}
                                                    autoFocus
                                                    onClick={handleClose}
                                                    color="error"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button autoFocus variant="contained" type="submit" size="sm" className={classes.item}>
                                                    Add
                                                </Button>
                                            </DialogActions>
                                        </Form>
                                    </div>
                                </Dialog>

                                <Dialog
                                    fullScreen={fullScreen}
                                    open={open2}
                                    onClose={handleClose2}
                                    aria-labelledby="responsive-dialog-title"
                                    fullWidth
                                >
                                    <div style={{ padding: '1rem 2rem' }}>
                                        <DialogTitle id="responsive-dialog-title" style={{ fontSize: '1rem' }}>
                                            Enter Parts Details
                                        </DialogTitle>
                                        <Form onSubmit={onSubmitPartData}>
                                            <DialogContent>
                                                <Form.Group controlId="formFile" className="mb-3">
                                                    <TextField
                                                        autoComplete="off"
                                                        name="partname"
                                                        variant="outlined"
                                                        required
                                                        id="partname"
                                                        label="Part Name"
                                                        value={partname}
                                                        onChange={(e) => setpartname(e.target.value)}
                                                        fullWidth
                                                    />
                                                    <br /> <br />
                                                    <TextField
                                                        autoComplete="off"
                                                        name="partqty"
                                                        variant="outlined"
                                                        required
                                                        id="partqty"
                                                        label="Qty"
                                                        value={partqty}
                                                        onChange={(e) => setpartqty(e.target.value)}
                                                        fullWidth
                                                    />
                                                    <br /> <br />
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Part Size</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={partsize}
                                                            required
                                                            label="Status"
                                                            onChange={handlePartTypeSize}
                                                            fullWidth
                                                        >
                                                            <MenuItem value="Body">Select an option</MenuItem>
                                                            <MenuItem value="Boxes">Boxe(s)</MenuItem>
                                                            <MenuItem value="Pieces">Pieces</MenuItem>
                                                            <MenuItem value="Gallons">Gallons</MenuItem>
                                                            <MenuItem value="Drums">Drums</MenuItem>
                                                            <MenuItem value="Rolls">Roll(s)</MenuItem>
                                                            <MenuItem value="Bucket">Bucket</MenuItem>
                                                            <MenuItem value="Packs">Pack(s)</MenuItem>
                                                            <MenuItem value="Litre">Litre(s)</MenuItem>
                                                            <MenuItem value="Pair">Pair(s)</MenuItem>
                                                            <MenuItem value="Carton">Carton(s)</MenuItem>
                                                            <MenuItem value="Bag">Bag(s)</MenuItem>
                                                            <MenuItem value="Set">Set(s)</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Form.Group>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    variant="contained"
                                                    size="sm"
                                                    className={classes.item}
                                                    autoFocus
                                                    onClick={handleClose2}
                                                    color="error"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button autoFocus variant="contained" type="submit" size="sm" className={classes.item}>
                                                    Add
                                                </Button>
                                            </DialogActions>
                                        </Form>
                                    </div>
                                </Dialog>
                            </Grid>
                        </Grid>
                    </AuthWrapper1>
                </>
            )}
        </>
    );
};

export default ViewCssOrder;
