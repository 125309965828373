import { createContext, useReducer, useContext } from 'react';
import darkstoreReducer from './darkstore-reducer';
import {
    DARKSTORE_BEGIN,
    DARKSTORE_SUCCESS,
    ADD_PART_BEGIN,
    ADD_PART_SUCCESS,
    ADD_PART_FAILURE,
    INVENTORY_BEGIN,
    INVENTORY_SUCCESS,
    INVENTORY_FAILURE,
    UPDATE_BEGIN,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    STORE_STATS_BEGIN,
    STORE_STATS_SUCCESS,
    STORE_STATS_FAILURE,
    PART_HISTORY_BEGIN,
    PART_HISTORY_SUCCESS,
    PART_HISTORY_FAILURE
} from './darkstore-actions';
import { authFetch, removeUserFromLocalStorage } from 'utils/axios-config';
import { errorToast, successToast } from 'utils/toast';

const initialState = {
    isLoading: false,
    darkstores: [],
    items: [],
    storeInventory: [],
    editId: '',
    garagePrice: '',
    wholeSalePrice: '',
    partId: '',
    qty: '',
    product: '',
    totalProduct: 0,
    todaysells: 0,
    yesterdaysells: 0,
    totalsells: 0,
    stockissues: 0,
    partHistory: []
};

const DarkStoreContext = createContext({ ...initialState });

const DarkStoreProvider = ({ children }) => {
    const [state, dispatch] = useReducer(darkstoreReducer, initialState);

    const getAllDarkStores = async () => {
        dispatch({ type: DARKSTORE_BEGIN });
        try {
            const {
                data: { darkstorearea, part }
            } = await authFetch('/darkstoreinventory/alldarkstoreinfo');
            dispatch({ type: DARKSTORE_SUCCESS, payload: { darkstores: darkstorearea, items: part } });
            successToast('Data synchronized successfully');
        } catch (error) {
            if (error.response.status !== 401) {
                console.log(error);
                removeUserFromLocalStorage();
            }
        }
    };

    const getInventoryById = async (id) => {
        dispatch({ type: INVENTORY_BEGIN });
        try {
            const { data } = await authFetch.get(`/darkstoreinventory/darkstoreinventory/${id}`);
            dispatch({ type: INVENTORY_SUCCESS, payload: { storeInventory: data.data, message: data.message } });
        } catch (error) {
            const { message } = error.response.data;
            if (error.response.status !== 401) {
                console.log(error);
                dispatch({ type: INVENTORY_FAILURE, payload: { message } });
                errorToast(message);
            }
        }
    };

    const getDarkStoreStats = async (id) => {
        dispatch({ type: STORE_STATS_BEGIN });
        try {
            const { data } = await authFetch.get(`darkstoreinventory/darkstorestat/${id}`);
            const { totalProduct, todaysells, yesterdaysells, totalsells, stockissues } = data;
            dispatch({ type: STORE_STATS_SUCCESS, payload: { totalProduct, todaysells, yesterdaysells, totalsells, stockissues } });
        } catch (error) {
            const { message } = error.response.data;
            if (error.response.status !== 401) {
                console.log(error);
                dispatch({ type: STORE_STATS_FAILURE, payload: { message } });
                errorToast(message);
            }
        }
    };

    const handleNewInventory = async (currentObject) => {
        dispatch({ type: ADD_PART_BEGIN });
        try {
            const response = await authFetch.post('/darkstoreinventory/add', currentObject);
            const { message } = response.data;
            dispatch({ type: ADD_PART_SUCCESS, payload: { message } });
            getInventoryById(currentObject?.darkstoreAreaId);
            getDarkStoreStats(currentObject?.darkstoreAreaId);
            successToast('Part added successfully');
        } catch (error) {
            const { message } = error.response.data;
            if (error.response.status !== 401) {
                dispatch({
                    type: ADD_PART_FAILURE,
                    payload: { message }
                });
                errorToast(message);
            }
        }
    };

    const handleStockUpdate = async (payload) => {
        dispatch({ type: UPDATE_BEGIN });
        try {
            const response = await authFetch.put('/darkstoreinventory/update', payload);
            const { message } = response.data;
            dispatch({ type: UPDATE_SUCCESS, payload: { message } });
            getInventoryById(payload?.darkstoreAreaId);
            successToast('Stock updated successfully');
        } catch (error) {
            const { message } = error.response.data;
            if (error.response.status !== 401) {
                dispatch({
                    type: UPDATE_FAILURE,
                    payload: { message }
                });
                errorToast(message);
            }
        }
    };

    const getPartHistory = async (partId, darkstoreAreaId) => {
        dispatch({ type: PART_HISTORY_BEGIN });
        try {
            const { data } = await authFetch.get(
                `/darkstoreinventory/darkstoreinventoryhistory?partId=${partId}&darkstoreAreaId=${darkstoreAreaId}`
            );
            dispatch({ type: PART_HISTORY_SUCCESS, payload: { partHistory: data.data, message: data.message } });
            successToast('Data Synchronized successfully');
        } catch (error) {
            const { message } = error.response.data;
            if (error.response.status !== 401) {
                console.log(error);
                dispatch({ type: PART_HISTORY_FAILURE, payload: { message } });
                errorToast(message);
            }
        }
    };

    const handleChange = (name, value) => {
        dispatch({ type: 'HANDLE_CHANGE', payload: { name, value } });
    };

    return (
        <DarkStoreContext.Provider
            value={{
                ...state,
                getAllDarkStores,
                handleNewInventory,
                getInventoryById,
                handleChange,
                handleStockUpdate,
                getDarkStoreStats,
                dispatch,
                getPartHistory
            }}
        >
            {children}
        </DarkStoreContext.Provider>
    );
};

const useDarkStoreContext = () => {
    return useContext(DarkStoreContext);
};

export { useDarkStoreContext, DarkStoreProvider, initialState };
