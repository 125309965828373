import styled from 'styled-components';

const LeadTableWrapper = styled.div`
    .lead__main-table {
        .icon-preview,
        .icon-phone {
            color: #ff740f;
        }

        .icon-whatsapp {
            color: #25d366;
        }
    }
`;

export default LeadTableWrapper;
