/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import logo from 'assets/images/garage.png';
import { useParams, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import MainCard from 'ui-component/cards/MainCard';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ApiUrl from 'network';
import useScriptRef from 'hooks/useScriptRef';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import EditIcon from '@mui/icons-material/Edit';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Form from 'react-bootstrap/Form';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
// import axios from 'axios';
// import Avatar from '@mui/material/Avatar';
// import nologo from 'assets/images/nologo.png';

// const Input = styled('input')({
//     display: 'none'
// });

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    }
}));

const AddOrderTag = () => {
    const classes = useStyles();
    const history = useNavigate();
    const [fulOrderId, setfulOrderId] = useState('');
    const [name, setName] = useState('');
    const [tagid, settagid] = useState('');
    const [attribute, setattribute] = useState('');
    const [attributeName, setattributeName] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [tags, settags] = useState([]);
    const [attributeData, setAttributeData] = useState([]);
    const [tagData, setTagData] = useState([]);
    const [open, setOpen] = useState(false);
    const [indexValue, setIndexValue] = useState('');
    const [open2, setOpen2] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { id } = useParams();

    const scriptedRef = useScriptRef();
    const retrieveAllAttribute = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AttributeUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setAttributeData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllTags = async (id) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.TagFilterUrl + id, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setTagData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    const handleAttributeChange = (event) => {
        setattribute(event.target.value);
        retrieveAllTags(event.target.value);
        const newFilter = attributeData.filter((value) =>
            (value._id || '').toLowerCase().includes((event.target.value || '').toLowerCase())
        );
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            setattributeName(option?.name);
        });
    };

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.ViewOrdersUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setfulOrderId(result?.data?.fulOrderId ?? '');
                    settags(result?.data?.tags ?? []);

                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                    // setLoading(false);
                }
            })
            .finally(() => {
                setLoading2(false);
            });

        retrieveAllAttribute();
    }, [id]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const onSubmitCollection = (event) => {
        event.preventDefault();
        // items, setItems
        settags([
            ...tags,
            {
                tagid,
                attribute,
                attributeName,
                name
            }
        ]);

        handleClose();
    };

    const onSubmitEditCollection = (event) => {
        event.preventDefault();
        const newList = Object.assign([], tags);
        const data = { ...newList[indexValue] };
        data.name = name;
        data.attribute = attribute;
        data.attributeName = attributeName;
        data.tagid = tagid;
        newList[indexValue] = data;
        settags(newList);
        handleClose2();
    };

    const handleDeleteClick = (row, index) => {
        const newList = Object.assign([], tags);
        newList.splice(index, 1);
        settags(newList);
    };

    const handleEditClick = (row, index) => {
        // console.log('row', row);
        setName(row.name ?? '');
        setattribute(row?.attribute ?? '');
        setattributeName(row?.attributeName ?? '');
        settagid(row.tagid ?? '');
        handleClickOpen2();
        setIndexValue(index);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            id,
            tags
        };
        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        fetch(ApiUrl.AddOrderTagsUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            // history('/salespartner');
                            history(-1);
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const filterTagId = (event) => {
        const newFilter = tagData.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            settagid(option._id);
        });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    {/* <AuthWrapper1> */}
                    <MainCard title="">
                        <Grid container justify="flex-start">
                            <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                            <Grid item xs={12}>
                                {/* <Grid container> */}
                                <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                                    {/* <AuthCardWrapper> */}
                                    <Grid container spacing={2}>
                                        {/* <Grid item sx={{ mb: 3 }} justifyContent="center">
                                            <Link to="#">
                                                <img src={logo} alt="logo" height={50} />
                                            </Link>
                                        </Grid> */}

                                        <Grid item xs={12}>
                                            <Typography variant="h3" component="h2" textAlign="center">
                                                Add Tag
                                            </Typography>
                                            <br />

                                            <Typography variant="h5" component="h2" textAlign="center">
                                                Use this form to input new tags for the order
                                            </Typography>

                                            <Typography variant="h3" component="h2" textAlign="center">
                                                {fulOrderId}{' '}
                                            </Typography>

                                            <br />
                                            <form onSubmit={handleSubmit}>
                                                <br />

                                                <Box display="flex" flexDirection="row-reverse">
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleClickOpen}
                                                        size="sm"
                                                        className={classes.item}
                                                    >
                                                        Add
                                                    </Button>
                                                </Box>
                                                <br />

                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <TableContainer component={Paper}>
                                                            <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                                            Attribute
                                                                        </TableCell>
                                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                                            Tag Name
                                                                        </TableCell>

                                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                                            Action
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {tags.map((row, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell component="th" scope="row">
                                                                                {row?.attributeName ?? ''}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {row?.name ?? ''}
                                                                            </TableCell>

                                                                            <TableCell component="th" scope="row">
                                                                                <IconButton
                                                                                    aria-label="edit"
                                                                                    color="primary"
                                                                                    onClick={() => handleEditClick(row, index)}
                                                                                >
                                                                                    <EditIcon />
                                                                                </IconButton>

                                                                                <IconButton
                                                                                    aria-label="delete"
                                                                                    color="error"
                                                                                    onClick={() => handleDeleteClick(row, index)}
                                                                                >
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                </Grid>

                                                <br />
                                                <br />
                                                <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                                    <Button type="submit" variant="contained" color="secondary">
                                                        {loading ? 'Saving...' : 'Save'}
                                                    </Button>

                                                    <Button onClick={() => history(-1)} variant="contained" color="error">
                                                        Back
                                                    </Button>
                                                </Box>
                                            </form>
                                        </Grid>
                                    </Grid>
                                    {/* </AuthCardWrapper> */}
                                </Grid>
                                {/* </Grid> */}

                                <Dialog
                                    fullScreen={fullScreen}
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="responsive-dialog-title"
                                    fullWidth
                                >
                                    <div style={{ padding: '1rem 2rem' }}>
                                        <DialogTitle id="responsive-dialog-title" style={{ fontSize: '1rem' }}>
                                            Add Tag
                                        </DialogTitle>
                                        <Form onSubmit={onSubmitCollection}>
                                            <DialogContent>
                                                <Form.Group controlId="formFile" className="mb-3">
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Choose a Attribute</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={attribute}
                                                            label="Attribute"
                                                            onChange={handleAttributeChange}
                                                        >
                                                            {attributeData.map((attr) => (
                                                                <MenuItem key={attr._id} value={attr._id}>
                                                                    {attr?.name ?? ''}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <br />
                                                    <br />
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        value={name}
                                                        onChange={(event, newValue) => {
                                                            setName(newValue);
                                                            filterTagId(newValue);
                                                        }}
                                                        options={tagData.map((option) => option.name)}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        renderInput={(params) => <TextField {...params} label="Select Tag Name" />}
                                                    />

                                                    <br />
                                                </Form.Group>
                                            </DialogContent>
                                            <DialogActions
                                                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    size="large"
                                                    className={classes.item}
                                                    autoFocus
                                                    onClick={handleClose}
                                                    color="error"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button autoFocus variant="contained" type="submit" size="large" className={classes.item}>
                                                    Add
                                                </Button>
                                            </DialogActions>
                                        </Form>
                                    </div>
                                </Dialog>

                                {/* Edit Shop */}
                                <Dialog
                                    fullScreen={fullScreen}
                                    open={open2}
                                    onClose={handleClose2}
                                    aria-labelledby="responsive-dialog-title"
                                    fullWidth
                                >
                                    <div style={{ padding: '1rem 2rem' }}>
                                        <DialogTitle id="responsive-dialog-title" style={{ fontSize: '1rem' }}>
                                            Edit Tag
                                        </DialogTitle>
                                        <Form onSubmit={onSubmitEditCollection}>
                                            <DialogContent>
                                                <Form.Group controlId="formFile" className="mb-3">
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Choose a Attribute</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={attribute}
                                                            label="Attribute"
                                                            onChange={handleAttributeChange}
                                                        >
                                                            {attributeData.map((attr) => (
                                                                <MenuItem key={attr._id} value={attr._id}>
                                                                    {attr?.name ?? ''}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <br />
                                                    <br />
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        value={name}
                                                        onChange={(event, newValue) => {
                                                            setName(newValue);
                                                            filterTagId(newValue);
                                                        }}
                                                        options={tagData.map((option) => option.name)}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        renderInput={(params) => <TextField {...params} label="Select Tag Name" />}
                                                    />

                                                    <br />
                                                </Form.Group>
                                            </DialogContent>
                                            <DialogActions
                                                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    size="large"
                                                    className={classes.item}
                                                    autoFocus
                                                    onClick={handleClose2}
                                                    color="error"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button autoFocus variant="contained" type="submit" size="large" className={classes.item}>
                                                    Update
                                                </Button>
                                            </DialogActions>
                                        </Form>
                                    </div>
                                </Dialog>
                            </Grid>
                        </Grid>
                    </MainCard>
                    {/* </AuthWrapper1> */}
                </>
            )}
        </>
    );
};

export default AddOrderTag;
