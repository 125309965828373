import React, { useEffect, useState } from 'react';
import { Table, Divider, Paper, Space, Button, Select, Modal, Checkbox, Alert, Input, DatePicker } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import ApiUrl from 'network';
import { ToastContainer, toast } from 'react-toastify';
import { encrypt, decrypt } from '../../utils/encrypt';
import PersonIcon from '@mui/icons-material/Person';
import { twoDecimalPlaces } from 'utils/functions';

const ClearedDetailsModal = ({ getId, handleClearedDetailsOk, isClearedDetailsOpen, handleClearedDetailsCancel }) => {
    const [clearedData, setClearedData] = useState('');
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');

    const getClearedList = async (id) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(`${ApiUrl.BaseUrl}/collection/viewclearanceSheet/${id}`, config)
                .then((data) => data.json())
                .then((data) => {
                    const result = decrypt(data.data, keyResult, ivResult);
                    const answer = JSON.parse(result);
                    setClearedData(answer);
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getClearedList(getId);
    }, [getId]);

    return (
        <div>
            <Modal
                open={isClearedDetailsOpen}
                onOk={handleClearedDetailsOk}
                centered
                width={345}
                onCancel={handleClearedDetailsCancel}
                footer={
                    <Button
                        style={{
                            width: '100%',
                            backgroundColor: '#fff',
                            borderRadius: '12px',
                            height: '48px',
                            fontSize: '18px',
                            fontWeight: '500',
                            color: '#FF740F',
                            border: '1px solid #FF740F',
                            textAlign: 'center'
                        }}
                        onClick={() => {
                            handleClearedDetailsCancel();
                        }}
                    >
                        Cancel
                    </Button>
                }
            >
                <div className="">
                    <Typography
                        sx={{
                            fontSize: '20px',
                            color: '#546974',
                            padding: '0',
                            fontWeight: '500',
                            // marginBottom: '40px',
                            textAlign: 'center'
                        }}
                    >
                        Details
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexDirection: 'column',
                            backgroundColor: '#fff',
                            borderRadius: '12px',
                            height: `${clearedData[0]?.orders?.length > 0 ? '350px' : 'none'}`,
                            overflow: 'scroll'
                        }}
                        className="collection-modals-y"
                    >
                        <Box
                            sx={{
                                width: ' 277px',
                                margin: `14px 0 0 0`
                                // margin: `${clearedData[0]?.orders.length}px 0 0 0`
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974', margin: '0 0 5px 0' }}>
                                {' '}
                                Orders
                            </Typography>
                            {clearedData[0]?.orders?.length > 0 ? (
                                <Box
                                    sx={{
                                        width: ' 100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        flexWrap: 'wrap',
                                        height: `${
                                            clearedData[0]?.orders.length < 5
                                                ? clearedData[0]?.orders.length * 5
                                                : clearedData[0]?.orders.length * 2
                                        }rem`,
                                        overflow: 'scroll'
                                    }}
                                    className="collection-modals-y"
                                >
                                    {clearedData[0]?.orders?.map((order) => (
                                        <Box
                                            key={order?.orderNo}
                                            sx={{
                                                width: ' 124px',
                                                margin: '0px 0 4px 0',
                                                height: '44px',
                                                borderRadius: '8px',
                                                padding: '8px',
                                                border: '1px solid #E9ECED',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#546974',
                                                    fontSize: '16px'
                                                }}
                                            >
                                                {order?.orderNo}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            ) : (
                                <Typography
                                    sx={{
                                        width: ' 100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        fontWeight: 400,
                                        fontSize: '18px'
                                    }}
                                >
                                    No Orders
                                </Typography>
                            )}
                        </Box>
                        <Box
                            sx={{
                                width: ' 277px',
                                margin: '24px 0 0 0'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974', margin: '0 0 5px 0' }}>
                                Total Sales Value
                            </Typography>
                            <Input
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                placeholder="Order Value"
                                value={!Number(clearedData[0]?.total) ? 0 : twoDecimalPlaces(clearedData[0]?.total)}
                            />
                        </Box>
                        {/* <Box
                            sx={{
                                width: ' 277px',
                                margin: '24px 0 0 0'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974', margin: '0 0 5px 0' }}>
                                Amount Deposited
                            </Typography>
                            <Input
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                placeholder="Amount Paid"
                                value={collectionImage?.amount}
                                type="number"
                                disabled
                            />
                        </Box> */}
                        <Box
                            sx={{
                                width: ' 277px',
                                margin: '24px 0 0px 0'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%'
                                }}
                            >
                                <PersonIcon
                                    sx={{
                                        fontSize: '18px',
                                        color: '#4B6EFF',
                                        marginRight: '4px'
                                    }}
                                />
                                <Typography
                                    sx={{
                                        color: '#546974',
                                        marginRight: '4px'
                                    }}
                                >
                                    RMA:
                                </Typography>
                                <Typography sx={{ color: '#0A2938' }}>{clearedData[0]?.name}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </div>
            </Modal>
        </div>
    );
};

export default ClearedDetailsModal;
