// import dashboard from './dashboard';
import distributor from './distributor';
import other from './other';

// ==============================|| MENU ITEMS dashboard ||============================== //

const distributorItems = {
    items: [distributor, other]
};

export default distributorItems;
