import styled from 'styled-components';

const BatchHeaderWrapper = styled.div`
    margin-top: 10px;

    .filter-page {
        display: flex;
    }
    .custom-paper {
        font-family: 'Roboto', 'sans-serif';
        border-radius: 0;
        padding: 0.625rem 0.625rem;
        color: #444;
        margin-bottom: 50px;

        h4,
        h5 {
            font-size: 0.875rem;
            font-weight: 400;
        }

        h4 {
            font-weight: 800;
        }

        h5 {
            color: #8b8b8b;
        }

        .header-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media screen and (max-width: 500px) {
                flex-direction: column;
            }
        }
        .custom-select {
            background-color: #ff740f;
            color: #fff;
            font-weight: 500;

            //change caret later takes too long to load
            /* background-image: url('https://www.freeiconspng.com/thumbs/white-arrow-png/white-down-arrow-png-2.png'); */
            &:hover {
                cursor: pointer;
            }
        }
    }
    .batch-filters {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        margin-top: -30px;

        @media screen and (max-width: 500px) {
            flex-direction: column;
        }
    }

    .filters {
        display: flex;
        @media screen and (max-width: 500px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .refresh-btn {
            background-color: #ff740f;
            margin-left: 0.625rem;
        }
    }

    .filter-supplier {
        margin-left: 1rem;
        background-color: #193855;
        color: #fff;
        font-weight: 500;

        &:hover {
            cursor: pointer;
        }
    }

    .custom-date-picker {
        background-color: #193855;
    }

    .create-batch {
        background-color: #ff740f;
        text-transform: capitalize;
        @media screen and (max-width: 500px) {
            margin-top: 20px;
        }
    }

    .date-ranger-selector {
        @media screen and (max-width: 500px) {
            margin-bottom: 20px;
            margin-left: 1.5rem;
        }
    }
`;

export default BatchHeaderWrapper;
