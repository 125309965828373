/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
// import { makeStyles } from '@mui/styles';
// import MainCard from 'ui-component/cards/MainCard';
import Box from '@mui/material/Box';
// import { Link, useNavigate } from 'react-router-dom';

import Paper from '@mui/material/Paper';
// import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
// import TablePagination from '@mui/material/TablePagination';
// import swal from 'sweetalert';
// import useScriptRef from 'hooks/useScriptRef';
// import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
// import { useTheme } from '@mui/material/styles';
// import axios from 'axios';
import { toast } from 'react-toastify';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
    Scheduler,
    Appointments,
    AppointmentTooltip,
    MonthView,
    Toolbar,
    TodayButton,
    DateNavigator
} from '@devexpress/dx-react-scheduler-material-ui';
import PageHeader from '../../../components/PageHeader';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// const useStyles = makeStyles(() => ({
//     tableHeaderCell: {
//         fontWeight: 'bold'
//     },
//     item: {
//         padding: 5
//     },
//     color: {
//         color: '#FF740F'
//     },
//     backgroundColor: {
//         backgroundColor: '#FF740F'
//     }
// }));

const GarageCalendarRequestServicing = () => {
    // const classes = useStyles();
    // const history = useNavigate();
    // const theme = useTheme();
    // const scriptedRef = useScriptRef();
    const [jobcardInfo, setJobCard] = useState([]);
    const [jobcardData, setJobCardData] = useState([]);
    const [loading, setLoading] = useState(false);
    console.log(jobcardData);
    console.log(loading);

    const commonStyles = {
        bgcolor: 'background.paper',
        m: 2,
        border: 1
    };

    function toTimestamp(strDate) {
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh');
    }

    function formatAMPM(strDate) {
        const today = strDate ? new Date(strDate) : new Date();
        let hours = today.getHours();
        let minutes = today.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours %= 12;
        hours = hours || 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        const strTime = `${hours}:${minutes} ${ampm}`;
        return strTime;
    }

    const retrieveAllReminder = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllGarageManagerReminderUrls, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        // console.log('data', data.data);
                        // const dataValue = [];

                        // data.data.map((rows) => {
                        //     dataValue.push({
                        //         title: rows.title,
                        //         startDate: rows.startDate,
                        //         endDate: rows.endDate
                        //     });
                        // });

                        setJobCard(data.data);
                        setJobCardData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        retrieveAllReminder();
    }, []);

    const currentDate = new Date();

    return (
        <>
            <div id="calendar">
                <PageHeader title="Calendar" subTitle="Home | Calendar" />
                <Grid container spacing={2} justify="flex-start">
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box sx={{ ...commonStyles, borderColor: '#FF740F' }}>
                            {jobcardInfo.map((rows) => (
                                <Grid key={rows._id} item>
                                    <Card align="center">
                                        <Typography variant="subtitle2" component="h2" textAlign="left">
                                            {toTimestamp(rows?.startDate)}--- {toTimestamp(rows?.endDate)} | {formatAMPM(rows?.startDate)}{' '}
                                            --- {formatAMPM(rows?.endDate)}
                                        </Typography>

                                        <Typography variant="subtitle2" component="h2" textAlign="left">
                                            {rows.title ?? ''}
                                        </Typography>
                                    </Card>
                                </Grid>
                            ))}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={8}>
                        <Paper>
                            <Scheduler data={jobcardInfo}>
                                <ViewState defaultCurrentDate={currentDate} />
                                <MonthView />
                                <Toolbar />
                                <DateNavigator />
                                <TodayButton />
                                <Appointments />
                                <AppointmentTooltip showCloseButton />
                            </Scheduler>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default GarageCalendarRequestServicing;
