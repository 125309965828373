import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Button, Select, Progress, Input, Popover, Checkbox, Modal, Spin, Divider, Table } from 'antd';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { decrypt, encrypt } from '../../../utils/encrypt';
import { toast } from 'react-toastify';
import 'react-circular-progressbar/dist/styles.css';
import { Link, useParams, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    CloseCircleOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    LoadingOutlined,
    MinusOutlined,
    PlusOutlined,
    PrinterOutlined,
    SearchOutlined
} from '@ant-design/icons';
import SearchIcon from '@mui/icons-material/Search';
import { v4 as uuidv4 } from 'uuid';
import ApiUrl from '../../../network';

const actionHandler = (record) => {
    window.location.href = `/salesplan/edit/${record.key}`;
};

export const Index = () => {
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [order, setOrder] = useState({});
    const { id } = useParams();
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    const [search, setSearch] = useState('');
    const [filteredCompressedOrder, setFilteredCompressedOrder] = useState([]);
    const [generateWaybill, setGenerateWaybill] = useState(false);
    const [searchInputShow, setSearchInputShow] = useState(false);
    const [productModalVisible, setProductModalVisible] = useState(false);
    const [itemName, setItemName] = useState('');
    const [quantity, setQuantity] = useState('');
    const [orderItemSelect, setOrderItemSelect] = useState({});
    const [productListSelect, setProductListSelect] = useState([
        {
            id: '',
            key: '',
            partId: '',
            qty: null
        }
    ]);
    const [products, setProducts] = useState([]);
    const [orderModalVisible, setOrderModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [driverName, setDriverName] = useState('');
    const [driverList, setDriverList] = useState([]);

    const fetchOrder = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/waybill/viewwaybill/${id}`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setOrder(result);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        toast.error(error.message);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };
    const fetchAllProducts = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/partzone/salespartspricelist`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = data.data;
                        setProducts(result);
                    } else {
                        toast.error(data.message);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };
    const getDrivers = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/driver/availabledrivers`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    const drivers = decrypt(JSON.stringify(data.data), keyResult, ivResult);
                    setDriverList(JSON.parse(drivers));
                } else {
                    toast.error(data.message);
                }
            });
    };
    const addProductHandler = () => {
        productListSelect.forEach((item) => {
            if (item.partId && item.qty) {
                const existingItemIndex = order?.ordersDetails?.findIndex(
                    (existingItem) => existingItem.partid.toLowerCase() === item.partId.toLowerCase()
                );

                if (existingItemIndex !== -1) {
                    const updatedItems = [...(order?.ordersDetails || [])];
                    updatedItems[existingItemIndex].qty += parseInt(item.qty, 10);

                    setOrder((prevFields) => ({ ...prevFields, ordersDetails: updatedItems }));
                    toast.success('Quantity updated for an existing product in the order');
                    setProductListSelect([{ id: uuidv4(), key: uuidv4(), partId: '', qty: '' }]);
                } else {
                    // If the partId doesn't exist, add a new product
                    const newProduct = {
                        _id: uuidv4(),
                        partid: item.partId,
                        qty: parseInt(item.qty, 10)
                    };

                    setOrder((prevFields) => ({ ...prevFields, ordersDetails: [...(prevFields?.ordersDetails || []), newProduct] }));
                    toast.success('Product added to the order');
                    setProductListSelect([{ id: uuidv4(), key: uuidv4(), partId: '', qty: '' }]);
                }
            }
        });
    };

    const updateOrderHandler = () => {
        const updatedOrderDetails = order?.ordersDetails?.map((order) => {
            if (order._id === orderItemSelect?._id) {
                return { ...order, partid: itemName, qty: quantity };
            }
            return order;
        });
        setOrder((prevFields) => ({ ...prevFields, ordersDetails: updatedOrderDetails }));

        // Close the modal
        setOrderModalVisible(false);
    };

    const updateFieldValue = (id, field, value) => {
        setProductListSelect((prevFields) =>
            prevFields?.map((prevField) => (prevField.id === id ? { ...prevField, [field]: value } : prevField))
        );
    };
    const addProduct = () => {
        const newProduct = {
            id: uuidv4(),
            key: uuidv4(),
            partid: '',
            qty: ''
        };

        setProductListSelect((prevFields) => [...prevFields, newProduct]);
    };
    const removeProduct = (id) => {
        if (id) {
            setProductListSelect((prevFields) => prevFields.filter((prevField) => prevField.id !== id));
        }
    };

    useEffect(() => {
        if (id) {
            fetchOrder();
            fetchAllProducts();
            getDrivers();
        } else {
            window.location.href = '/waybill';
            toast.error('No order found');
        }
    }, []);
    useEffect(() => {
        if (Array?.isArray(order?.ordersDetails)) {
            const filteredOrders = order?.ordersDetails?.filter((orderItem) => orderItem?.partid?.includes(search));
            setFilteredCompressedOrder(filteredOrders);
        }
    }, [order?.ordersDetails, search]);

    const editOrder = (order) => {
        setOrderItemSelect(order);
        setItemName(order.partid);
        setQuantity(order.qty);
        setOrderModalVisible(true);
    };

    const handleDelete = (id) => {
        const newOrderDetails = order?.ordersDetails?.filter((item) => item._id !== id);
        setOrder((prevFields) => ({ ...prevFields, ordersDetails: newOrderDetails }));
    };

    const columns = [
        {
            title: 'Product name',
            dataIndex: 'partid',
            key: 'partid'
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <div className="d-flex">
                    <Button
                        type="primary"
                        onClick={() => {
                            editOrder(record);
                        }}
                        style={{
                            marginRight: '10px',
                            backgroundColor: '#fff',
                            borderColor: '#FF740F',
                            color: '#FF740F'
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            handleDelete(record._id);
                        }}
                        style={{
                            marginRight: '10px',
                            backgroundColor: '#fff',
                            borderColor: '#FF740F',
                            color: '#FF740F'
                        }}
                    >
                        Delete
                    </Button>
                </div>
            )
        }
    ];

    const productsOptions = products.map((item) => {
        return {
            label: item.sku,
            value: item.sku
        };
    });
    const driverOptions = driverList.map((item) => {
        return {
            label: `${item.firstName} ${item.lastName}`,
            value: item._id
        };
    });

    const handleGenerateWaybill = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const datas = {
                id,
                batchid: order?.batchId,
                batchDate: order?.batchDate,
                batchStatusId: 'batched',
                rmaName: order?.rmaName,
                orderNo: order?.orderNo,
                orderId: order?.orderId,
                orderType: order?.orderType,
                ordersDetails: order?.ordersDetails,
                driverName: order?.driverName
            };

            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            await fetch(`${ApiUrl.BaseUrl}/waybill/updatewaybill`, {
                method: 'PUT',
                headers: config.headers,
                body: JSON.stringify({ payload })
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        toast.success(data.message);
                        setLoading(false);
                        setOrderModalVisible(false);
                        setTimeout(() => {
                            window.location.href = '/generated/waybill';
                        }, 2000);
                    } else {
                        const error = decrypt(JSON.stringify(data.data), keyResult, ivResult);
                        toast.error(error);
                        setLoading(false);
                    }
                });
        } catch (e) {
            toast.error(e.message);
            setLoading(false);
        }
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Link to="/generated/waybill" className="createScheduleLink">
                    <p style={{ fontSize: '25px', marginLeft: '10px' }}>
                        <ArrowBackIcon sx={{ fontSize: '25px', color: '#000' }} />
                        Edit Waybill details
                    </p>
                </Link>
                <div>
                    <Button
                        type="primary"
                        style={{
                            backgroundColor: '#FF740F',
                            color: '#fff',
                            borderRadius: '10px',
                            marginRight: '10px',
                            marginBottom: '10px',
                            marginLeft: 'auto',
                            width: '200px'
                        }}
                        onClick={handleGenerateWaybill}
                    >
                        Update Waybill
                    </Button>
                </div>
            </div>
            <div className="batchContainer">
                <div className="p-4">
                    <div className="mb-4">
                        <div className="mr-2">
                            <Select
                                size="large"
                                style={{
                                    width: '300px'
                                }}
                                showSearch
                                placeholder="Driver Name"
                                onChange={(e) => setDriverName(e)}
                                value={`Driver Name: ${order?.driverName}`}
                                options={driverOptions}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                            />
                        </div>
                    </div>
                    <Divider />
                    <div className="mb-4">
                        <Input value={`RMA Name: ${order?.rmaName}`} disabled placeholder="RMA Name" className="p-2 inputViewKpi" />
                    </div>
                    <div className="mb-4">
                        <Input
                            value={`PlaceBy: ${order?.userId?.firstName} ${order?.userId?.lastName}`}
                            disabled
                            placeholder="Name"
                            className="p-2 inputViewKpi"
                        />
                    </div>
                    <div className="mb-4">
                        <Input value={`Country: ${order?.country}`} disabled placeholder="Country" className="p-2 inputViewKpi" />
                    </div>
                    <div className="mb-4">
                        <Input
                            value={`waybill date: ${new Date(order?.waybillDate).toLocaleDateString('en-US', options)}`}
                            disabled
                            placeholder="Waybill Date"
                            className="p-2 inputViewKpi"
                        />
                    </div>
                    <div className="mb-4">
                        <Input value={`order Type: ${order?.orderType}`} disabled placeholder="Batch Date" className="p-2 inputViewKpi" />
                    </div>
                </div>
                <div className="p-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <p style={{ fontSize: '16px', color: '#546974' }}>Products in this order ({order?.ordersDetails?.length})</p>
                        <Button
                            onClick={() => setProductModalVisible(true)}
                            type="primary"
                            className="mb-2"
                            style={{ backgroundColor: 'transparent', color: '#4B6EFF', border: '1px solid #DBE2FF' }}
                        >
                            Add Product <PlusOutlined />
                        </Button>
                    </div>
                    <div className="productsContainer orderListContainer">
                        <Table rowKey={(record) => record._id} columns={columns} dataSource={order?.ordersDetails} pagination={false} />
                    </div>
                </div>
            </div>
            <Modal
                title="Add Product"
                footer={null}
                open={productModalVisible}
                onCancel={() => {
                    setProductModalVisible(false);
                }}
                width={700}
            >
                <div>
                    {productListSelect.map((product, id) => (
                        <div className="product mb-4" key={id}>
                            <div>
                                <p>
                                    Product <span style={{ color: 'red', fontSize: '10px' }}>*</span>
                                </p>
                                <Select
                                    options={productsOptions}
                                    value={product?.partId}
                                    onChange={(value) => updateFieldValue(product.id, 'partId', value)}
                                    placeholder="Select Product"
                                    allowClear
                                    showSearch
                                    filterOption={(inputValue, option) => {
                                        if (typeof option.label === 'string') {
                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }

                                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }}
                                    size="large"
                                    className="input"
                                />
                            </div>
                            <div>
                                <p>
                                    Quantity <span style={{ color: 'red', fontSize: '10px' }}>*</span>
                                </p>
                                <Input
                                    placeholder="Quantity"
                                    size="large"
                                    value={product?.qty}
                                    onChange={(e) => updateFieldValue(product.id, 'qty', e.target.value)}
                                    className="input"
                                />
                            </div>
                            <div className="productActions">
                                {id >= 1 && (
                                    <Button
                                        style={{ color: '#FF740F', borderColor: '#E7EAEB' }}
                                        onClick={() => removeProduct(product.id)}
                                        className="mr-2"
                                    >
                                        <MinusOutlined />
                                    </Button>
                                )}
                                <Button onClick={() => addProduct()} className="mr-2" style={{ color: '#FF740F', borderColor: '#E7EAEB' }}>
                                    <PlusOutlined />
                                </Button>
                            </div>
                        </div>
                    ))}
                    <div className="d-flex">
                        <Button
                            type="primary"
                            onClick={() => {
                                setProductModalVisible(false);
                            }}
                            style={{
                                color: '#FF740F',
                                borderColor: '#E7EAEB',
                                marginRight: '10px',
                                width: '300px',
                                backgroundColor: 'transparent'
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            style={{
                                color: '#FF740F',
                                borderColor: '#E7EAEB',
                                marginRight: '10px',
                                width: '300px',
                                backgroundColor: 'transparent'
                            }}
                            onClick={addProductHandler}
                        >
                            Add Product
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                footer={null}
                open={orderModalVisible}
                onCancel={() => {
                    setOrderModalVisible(false);
                }}
                width={400}
            >
                <div>
                    <p className="text-center mb-4 mt-2" style={{ fontSize: '14px' }}>
                        Edit item
                    </p>
                    <div>
                        <p style={{ fontSize: '12px' }}>Item name</p>
                        <Input
                            placeholder="Item name"
                            size="large"
                            value={itemName}
                            onChange={(e) => setItemName(e.target.value)}
                            style={{ width: '100%' }}
                            readOnly
                        />
                    </div>
                    <div className="mt-2">
                        <p style={{ fontSize: '12px' }}>
                            Quantity <span style={{ color: 'red' }}>*</span>
                        </p>
                        <Input
                            placeholder="Quantity"
                            size="large"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className="d-flex align-items-center mt-4">
                        <Button
                            style={{
                                color: '#FF740F',
                                borderColor: '#E7EAEB'
                            }}
                            className="mr-2"
                            onClick={() => {
                                setOrderModalVisible(false);
                            }}
                            disabled={loading}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{
                                color: '#FF740F',
                                borderColor: '#E7EAEB'
                            }}
                            onClick={updateOrderHandler}
                            loading={loading}
                            disabled={loading}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default Index;
