import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { twoDecimalPlaces } from 'utils/functions';

const GrowthCircularChart = ({ datas, progressWidth, progressChartTitle, showCustomer, showGmv, showOrder, showRevenue }) => {
    const [average, setAverage] = useState(0);

    // Average of percentage
    // const findAverage = (array) => {
    //     const sum = array?.reduce(
    //         (accumulator, currentValue) => accumulator + currentValue?.gmvGrowthPercentage || currentValue?.revenueGrowthPercentage,
    //         0
    //     );

    //     const average = sum / datas.length;

    //     setAverage(Number(average));
    // };

    // useEffect(() => {
    //     findAverage(datas);
    // }, [datas]);

    // Average of value
    // const sum = datas?.reduce(
    //     (accumulator, currentValue) => accumulator + currentValue?.gmvGrowthValue || currentValue?.revenueGrowthValue,
    //     0
    // );

    // const getAveragesum = sum / datas.length;

    return (
        // <Box
        //     sx={{
        //         // width: progressWidth,
        //         // height: '244px',
        //         // backgroundColor: average < 50 ? '#FFEBED' : '#E6F7EE',
        //         // borderRadius: '12px',
        //         // margin: '24px 0 0 0',
        //         display: 'flex',
        //         // alignItems: 'center',
        //         // flexDirection: 'column',
        //         justifyContent: 'space-between',
        //         flexWrap: 'wrap',
        //         flexGrow: '1',
        //         // padding: '20px'
        //         // width: '100%'
        //         flexBasis: '600px'
        //     }}
        // >
        <>
            {showGmv ? (
                <Box
                    sx={{
                        width: progressWidth,
                        height: '244px',
                        backgroundColor: datas.gmv_growth < 50 ? '#FFEBED' : '#E6F7EE',
                        borderRadius: '12px',
                        margin: '24px 0 0 0',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: '20px'
                    }}
                >
                    <>
                        <Typography
                            sx={{
                                fontSize: '20px',
                                fontWeight: '400',
                                color: '#546974'
                            }}
                        >
                            Average GMV growth
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                                margin: '12px 0'
                            }}
                        >
                            <Box
                                sx={{
                                    width: '124px',
                                    fontWeight: '600'
                                }}
                            >
                                <CircularProgressbar
                                    value={twoDecimalPlaces(datas.gmv_growth)}
                                    text={
                                        !Number(datas.avg_gmv) ? (
                                            <Box
                                                sx={{
                                                    fontSize: '17px'
                                                }}
                                            >
                                                loading...
                                            </Box>
                                        ) : (
                                            `${twoDecimalPlaces(datas.gmv_growth)}%`
                                        )
                                    }
                                    styles={buildStyles({
                                        strokeLinecap: 'butt',
                                        pathColor: datas.gmv_growth < 50 ? '#FA3246' : '#00AA55',
                                        trailColor: datas.gmv_growth < 50 ? '#FED6DA' : '#CCEEDD',
                                        textColor: datas.gmv_growth < 50 ? '#FA3246' : '#00AA55',
                                        textSize: '20x'
                                    })}
                                    strokeWidth={10}
                                />
                            </Box>
                        </Box>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: '400',
                                color: '#546974'
                            }}
                        >
                            Amount in numbers: {!Number(datas.avg_gmv) ? null : twoDecimalPlaces(datas.avg_gmv)}
                        </Typography>
                    </>
                </Box>
            ) : null}

            {showRevenue ? (
                <Box
                    sx={{
                        width: progressWidth,
                        height: '244px',
                        backgroundColor: datas.revenue_growth < 50 ? '#FFEBED' : '#E6F7EE',
                        borderRadius: '12px',
                        margin: '24px 0 0 0',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: '20px'
                    }}
                >
                    <>
                        <Typography
                            sx={{
                                fontSize: '20px',
                                fontWeight: '400',
                                color: '#546974'
                            }}
                        >
                            Average revenue growth
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                                margin: '12px 0'
                            }}
                        >
                            <Box
                                sx={{
                                    width: '124px',
                                    fontWeight: '600'
                                }}
                            >
                                <CircularProgressbar
                                    value={twoDecimalPlaces(datas.revenue_growth)}
                                    text={
                                        !Number(datas.revenue_growth) ? (
                                            <Box
                                                sx={{
                                                    fontSize: '17px'
                                                }}
                                            >
                                                loading...
                                            </Box>
                                        ) : (
                                            `${twoDecimalPlaces(datas.revenue_growth)}%`
                                        )
                                    }
                                    styles={buildStyles({
                                        strokeLinecap: 'butt',
                                        pathColor: datas.revenue_growth < 50 ? '#FA3246' : '#00AA55',
                                        trailColor: datas.revenue_growth < 50 ? '#FED6DA' : '#CCEEDD',
                                        textColor: datas.revenue_growth < 50 ? '#FA3246' : '#00AA55',
                                        textSize: '20x'
                                    })}
                                    strokeWidth={10}
                                />
                            </Box>
                        </Box>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: '400',
                                color: '#546974'
                            }}
                        >
                            Amount in numbers: {!Number(datas.avg_revenue) ? null : ''}
                        </Typography>
                    </>
                </Box>
            ) : null}

            {showOrder ? (
                <Box
                    sx={{
                        width: progressWidth,
                        height: '244px',
                        backgroundColor: datas.number_of_orders_growth < 50 ? '#FFEBED' : '#E6F7EE',
                        borderRadius: '12px',
                        margin: '24px 0 0 0',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: '20px'
                    }}
                >
                    <>
                        <Typography
                            sx={{
                                fontSize: '20px',
                                fontWeight: '400',
                                color: '#546974'
                            }}
                        >
                            Average order growth
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                                margin: '12px 0'
                            }}
                        >
                            <Box
                                sx={{
                                    width: '124px',
                                    fontWeight: '600'
                                }}
                            >
                                <CircularProgressbar
                                    value={twoDecimalPlaces(datas.number_of_orders_growth)}
                                    text={
                                        !Number(datas.number_of_orders_growth) ? (
                                            <Box
                                                sx={{
                                                    fontSize: '17px'
                                                }}
                                            >
                                                loading...
                                            </Box>
                                        ) : (
                                            `${twoDecimalPlaces(datas.number_of_orders_growth)}%`
                                        )
                                    }
                                    styles={buildStyles({
                                        strokeLinecap: 'butt',
                                        pathColor: datas.number_of_orders_growth < 50 ? '#FA3246' : '#00AA55',
                                        trailColor: datas.number_of_orders_growth < 50 ? '#FED6DA' : '#CCEEDD',
                                        textColor: datas.number_of_orders_growth < 50 ? '#FA3246' : '#00AA55',
                                        textSize: '20x'
                                    })}
                                    strokeWidth={10}
                                />
                            </Box>
                        </Box>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: '400',
                                color: '#546974'
                            }}
                        >
                            Amount in numbers: {!Number(datas.avg_orders) ? null : ''}
                        </Typography>
                    </>
                </Box>
            ) : null}
        </>
        // </Box>
    );
};

export default GrowthCircularChart;
