import { usePriorityContext } from 'context/order-priority/priority-context';
import { Row, Col, Container } from 'react-grid-system';
import PriorityCard from './PriorityCard';
import _ from 'lodash';

const PriorityCardList = () => {
    const { avgdistancetoday, size, location, priority } = usePriorityContext();
    const truncateLongLocation = location && location.length > 10 ? _.truncate(location, { length: 10 }) : location;
    return (
        <div>
            <Container>
                <Row>
                    <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                        <PriorityCard value={avgdistancetoday.toFixed(2)} title="AVG. distance covered daily" />
                    </Col>
                    <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                        <PriorityCard value={size || 'N/A'} title="AVG. size ordered daily" />
                    </Col>
                    <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                        <PriorityCard
                            value={truncateLongLocation && truncateLongLocation.length > 0 ? truncateLongLocation : 'N/A'}
                            title="Daily popular location"
                        />
                    </Col>
                    <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                        <PriorityCard value={priority || 'N/A'} title="Daily popular priority" />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default PriorityCardList;
