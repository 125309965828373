import LoadingButton from '@mui/lab/LoadingButton';
import { Paper } from '@mui/material';
import placeholder from '../../assets/images/placeholder.png';
import styled from 'styled-components';

const currency = localStorage.getItem('userCurrency');

// import Box from '@mui/material/Box';
// import Skeleton from '@mui/material/Skeleton';

const Wrapper = styled.div`
    font-family: 'Roboto';
    background: #fff;
    padding: 1rem;
    margin-bottom: 15px;
    img {
        width: 100%;
        height: 250px;
        border-radius: 5px;
    }

    .product-title {
        font-weight: 800;
        padding: 0.625rem 0;
    }

    .product-price {
        color: #737373;
        margin-top: -15px;
    }

    .cart {
        background: #ff740f;
    }
`;

const ProductCard = ({ item, handleAddToCart, cartLoading }) => {
    return (
        <Paper elevation={2}>
            <Wrapper className="prouct-card">
                <img src={item?.partsImage || placeholder} alt="book" loading="lazy" width="100%" height="250px" />
                <p className="product-title">
                    <strong>{item?.sku}</strong>
                </p>
                <p className="product-price">
                    Price - {currency} {item?.garagePrice}
                </p>
                <LoadingButton
                    variant="contained"
                    disabled={cartLoading}
                    size="small"
                    className="cart"
                    onClick={() => handleAddToCart(item)}
                    loading={cartLoading}
                >
                    Add to cart
                </LoadingButton>
            </Wrapper>
        </Paper>
    );
};
export default ProductCard;
