import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
// import { notification } from 'antd';
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import useScriptRef from 'hooks/useScriptRef';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';

const EditCountry = () => {
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [countryData, setCountryData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const history = useNavigate();
    const scriptedRef = useScriptRef();

    const handleCountryChange = (event) => {
        setCountry(event.target.value);
    };
    const { id } = useParams();

    const retrieveAllCountry = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.CountryUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountryData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        retrieveAllCountry();
        setLoading2(true);
        fetch(ApiUrl.ViewCityUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setCity(result?.data?.city ?? '');
                    setCountry(result?.data?.country?._id ?? '');
                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                }
            })
            .finally(() => {
                setLoading2(false);
            });
    }, [id]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');

        const data = {
            id,
            city,
            country
        };
        setLoading(true);
        fetch(ApiUrl.UpdateCityUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading(false);
                    setTimeout(() => {
                        if (scriptedRef.current) {
                            history('/city');
                        }
                    }, 3000);
                } else {
                    toast.error(result.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <br />
                            <PageHeader title="City" subTitle="Home | Setup Management  | City" />
                            <br />
                            <MainCard title="Edit City">
                                <form onSubmit={handleSubmit}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Choose a Country</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={country}
                                            label="Attribute"
                                            onChange={handleCountryChange}
                                        >
                                            {countryData.map((attr) => (
                                                <MenuItem key={attr._id} value={attr._id}>
                                                    {attr?.name ?? ''}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="city"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="city"
                                        value={city}
                                        label="City"
                                        onChange={(e) => setCity(e.target.value)}
                                        autoFocus
                                    />

                                    <br />
                                    <br />
                                    <Box display="flex" justifyContent="space-between">
                                        <Button component={Link} to="/city" type="submit" variant="contained" color="error">
                                            Reset
                                        </Button>

                                        <Button type="submit" variant="contained" color="secondary" disabled={!city || !country || loading}>
                                            {loading ? 'Updating ..' : 'Update'}
                                        </Button>
                                    </Box>
                                </form>
                            </MainCard>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};
export default EditCountry;
