import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Search from '@mui/icons-material/Search';
import { Row } from 'react-grid-system';
import { InputAdornment, TextField } from '@mui/material';
// import { MdEdit } from 'react-icons/md';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import LeadTableWrapper from 'assets/Wrappers/LeadTableWrapper';
import { makeStyles } from '@mui/styles';
import TablePaginationActions from 'components/TablePaginationActions';
import { useDarkStoreContext } from 'context/darkstore-inventory/darkstore-context';
import styled from 'styled-components';

const Wrapper = styled.div`
    padding: 1rem 2rem;

    @media screen and (min-width: 768px) {
        .search {
            width: 25rem;
        }
    }
`;

const DarkStoreTable = ({ th1, th2, th3, th4, th5, th6, th7, th8 }) => {
    const { darkstores } = useDarkStoreContext();
    /** Table data rows */

    const useStyles = makeStyles(() => ({
        tableHeaderCell: {
            fontWeight: 'bolder'
        }
    }));
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - darkstores.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [store, setStore] = useState('');
    const filteredStores = darkstores.filter((el) => el?.name.toLowerCase().includes(store.toLowerCase()));

    return (
        <LeadTableWrapper>
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className="lead__main-table" elevation={0}>
                <Row>
                    <Wrapper>
                        <TextField
                            label="Search Store"
                            type="search"
                            className="search"
                            onChange={(e) => setStore(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton>
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Wrapper>
                </Row>
                <>
                    <TableContainer sx={{ maxHeight: 400 }}>
                        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                        {th1}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                        {th2}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                        {th3}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                        {th4}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                        {th5}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                        {th6}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                        {th7}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                        {th8}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? filteredStores.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : filteredStores
                                ).map((row, index = 1) => {
                                    const { _id, name, cityId, lat, lng, salesperson, threshold, status } = row;
                                    const statusColor = status === 'Active' ? '#7DCE13' : '#FF1E00';
                                    return (
                                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="left" scope="row">
                                                {index + 1}
                                            </TableCell>
                                            <TableCell align="left" scope="row">
                                                {name}
                                            </TableCell>
                                            <TableCell align="left" scope="row">
                                                {cityId?.city}
                                            </TableCell>
                                            <TableCell align="left" scope="row">
                                                {threshold}
                                            </TableCell>
                                            <TableCell align="left" scope="row">
                                                {lng},{lat}
                                            </TableCell>
                                            <TableCell align="left" scope="row">
                                                <span
                                                    style={{
                                                        background: statusColor,
                                                        padding: '3px 25px',
                                                        color: '#fff',
                                                        borderRadius: '15px',
                                                        fontSize: '12px',
                                                        fontWeight: '500',
                                                        whiteSpace: 'nowrap'
                                                    }}
                                                >
                                                    {status}
                                                </span>
                                            </TableCell>
                                            <TableCell align="left" scope="row">
                                                {salesperson}
                                            </TableCell>
                                            <TableCell align="left" scope="row">
                                                <Stack direction="row" spacing={1}>
                                                    <IconButton
                                                        aria-label="preview"
                                                        href={`/inventory/darkstores/${_id}`}
                                                        style={{ color: '#76BA99' }}
                                                        title="Preview"
                                                    >
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={darkstores.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        component="div"
                        SelectProps={{
                            inputProps: {
                                'aria-label': 'rows per page'
                            },
                            native: true
                        }}
                        className="custom-paginator"
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </>
            </Paper>
        </LeadTableWrapper>
    );
};

export default DarkStoreTable;
