import { useLoyaltyContext } from 'context/loyalty/loyalty-context';
import styled from 'styled-components';
// import moment from 'moment';

const Wrapper = styled.div`
    padding: 1rem 2rem;
    font-family: 'Roboto';
    .customer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media screen and (max-width: 500px) {
        .customer {
            flex-direction: column;
            justify-content: center;
        }
        .note-id {
            p {
                text-align: left;
            }
        }
    }

    .info {
        border-left: 5px solid #ffbe36;
        padding-left: 0.625rem;
    }

    .note-id {
        p {
            text-align: right;
        }
    }

    h6 {
        font-weight: 800;
    }

    .billno {
        font-size: 1.3rem;
        font-weight: 800;
        color: #1f88e5;
    }
`;

const ReceiptHeaderInfo = () => {
    const { customerName, customerContact, customerLat, customerLng, date, salesPerson } = useLoyaltyContext();
    return (
        <Wrapper>
            <div className="customer">
                <div className="info">
                    <h6>INVOICE TO: </h6>
                    <p>Custormer Name: {customerName}</p>
                    <p>
                        Customer Location: {customerLat}, {customerLng}
                    </p>
                    <p>Customer Phone #: {customerContact}</p>
                    {/* <p>Parts Shop Number #: 0033AE2022</p> */}
                </div>
                <div className="note-id">
                    {/* <p className="billno">Bill No: 003838RICH2022</p> */}
                    <p>
                        Date: <strong>{date}</strong>
                    </p>
                    <p>Sales Person: {salesPerson}</p>
                </div>
            </div>
        </Wrapper>
    );
};
export default ReceiptHeaderInfo;
