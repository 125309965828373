import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
import { toast } from 'react-toastify';

import useScriptRef from 'hooks/useScriptRef';

const AddFleetServiceFee = () => {
    const [name, setName] = useState('');
    const [cost, setCost] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useNavigate();
    const scriptedRef = useScriptRef();

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const userInfo = localStorage.getItem('userToken');

        const data = {
            name,
            cost
        };

        fetch(ApiUrl.AddFleetServiceFeeUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                Accept: 'application/form-data',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    if (scriptedRef.current) {
                        setTimeout(() => {
                            history('/fleetservicefee');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                    console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Grid item xs={12} sm={6}>
                <PageHeader title="Fleet Service Fee" subTitle="Home | Fleet Service Fee | Setup Management  | Fleet Service Fee" />
                <MainCard title="Add Fleet Service Fee">
                    <form onSubmit={handleSubmit}>
                        <TextField
                            autoComplete="off"
                            name="name"
                            variant="outlined"
                            required
                            fullWidth
                            id="name"
                            value={name}
                            label="Name"
                            onChange={(e) => setName(e.target.value)}
                            autoFocus
                        />
                        <br />
                        <br />

                        <TextField
                            autoComplete="off"
                            type="number"
                            name="cost"
                            variant="outlined"
                            required
                            fullWidth
                            id="cost"
                            value={cost}
                            label="Cost"
                            onChange={(e) => setCost(e.target.value)}
                        />
                        <br />
                        <br />

                        <Box display="flex" justifyContent="space-between">
                            <Button component={Link} to="/fleetservicefee" type="submit" variant="contained" color="error">
                                Reset
                            </Button>

                            <Button type="submit" variant="contained" color="secondary" disabled={!name || !cost || loading}>
                                {loading ? 'Submitting ..' : 'Submit'}
                            </Button>
                        </Box>
                    </form>
                </MainCard>
            </Grid>
        </>
    );
};

export default AddFleetServiceFee;
