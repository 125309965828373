import { Divider } from '@mui/material';
import styled from 'styled-components';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useLoyaltyContext } from 'context/loyalty/loyalty-context';
import { useAppContext } from 'context/appContext';

const Wrapper = styled.div`
    margin: 1rem 2rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-family: 'Roboto';
    h6 {
        padding: 0.625rem 2rem;
        color: #ff740f;
    }
    .content {
        padding: 1rem 2rem;
    }

    .shopname {
        text-transform: uppercase;
        color: #222;
        font-weight: 700;
    }

    .shop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
        p {
            text-transform: uppercase;
            color: #222;
            font-weight: 700;
        }
    }

    .progress-bar {
        background-color: #193855 !important;
    }

    .pg-bar {
        height: 28px;
        border-radius: 50px;
    }

    .pg-text {
        font-weight: bold;
        font-size: 1.2rem;
    }

    @media screen and (max-width: 500px) {
        .pg-text {
            font-size: 1rem;
        }
    }

    .goto-tier {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .tier-type {
        text-transform: uppercase;
        color: #222;
        font-weight: 700;
    }
`;

const LoyaltyProgram = () => {
    const { userCurrency } = useAppContext();
    const { currentTier, currentReward, amountToSpendMore, ordersLefts, percentage, customerName, nextTier } = useLoyaltyContext();
    return (
        <Wrapper>
            <h6>Loyalty Program Progress</h6>
            <Divider />
            <div className="content">
                <p className="shopname">{customerName}</p>
                <div className="shop">
                    <p>Your current reward for Discount</p>
                    <p className="discount">
                        {userCurrency} {currentReward} <br /> {currentTier}
                    </p>
                </div>
                <div className="packages">
                    <div className="goto-tier">
                        <p className="tier-type">
                            {userCurrency}
                            {amountToSpendMore} TO {nextTier}
                        </p>
                    </div>
                    <ProgressBar now={percentage} className="pg-bar" />
                    <p className="pg-text">
                        Amount to spend {userCurrency}
                        {amountToSpendMore}
                    </p>
                </div>
                <br />
                <br />
                <div className="packages">
                    <div className="goto-tier">
                        {/* <p className="tier-type">
                            GH<>&#8373;</>999.82 TO TIER 2
                        </p> */}
                    </div>
                    <ProgressBar now={percentage} className="pg-bar" />
                    <p className="pg-text">Place {ordersLefts} or more orders</p>
                </div>
            </div>
        </Wrapper>
    );
};
export default LoyaltyProgram;
