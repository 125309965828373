import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
// import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Result } from 'antd';

// assets
import { IconChevronRight } from '@tabler/icons';
import checkNetworkStatus from '../../checkNetworkStatus';
import ChatWidget from '../../components/ChatWidget/ChatWidget';
import requestPermission from '../../requestNotification';
// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const navigate = useNavigate();

    // eslint-disable-next-line consistent-return

    useEffect(() => {
        const token = localStorage.getItem('userToken');
        // Check if token is expired
        if (!token) {
            navigate('/login');
        }
    }, [navigate]);

    useEffect(() => {
        requestPermission();
    }, []);

    // Function to handle response errors
    const handleResponseError = async (response) => {
        if (!response.ok) {
            const clonedResponse = response.clone();
            const responseData = await clonedResponse.json();
            if (response.status === 403 && responseData.message === 'access-denied') {
                // Access denied, log the user out
                localStorage.clear();
                navigate('/login');
            }
        }
        return response;
    };

    // Global interceptor for fetch
    const originalFetch = window.fetch;

    window.fetch = async function (...args) {
        const response = await originalFetch(...args);
        await handleResponseError(response);
        return response;
    };

    const networkStatus = checkNetworkStatus();

    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                {/* header */}
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {/* Same as */}
                <ToastContainer />
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    color="inherit"
                    elevation={0}
                    sx={{
                        bgcolor: theme.palette.background.default,
                        transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
                        borderBottom: '1px solid #E7EAEB',
                        padding: '0px'
                    }}
                >
                    <Toolbar
                        sx={{
                            padding: '0px'
                        }}
                    >
                        <Header className="header-main" handleLeftDrawerToggle={handleLeftDrawerToggle} />
                    </Toolbar>
                </AppBar>

                {/* drawer */}
                <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

                {/* main content */}
                <Main theme={theme} open={leftDrawerOpened}>
                    {/* breadcrumb */}
                    <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                    {localStorage.getItem('userToken') ? <Outlet /> : null}
                </Main>
                {/* <Customization /> */}
            </Box>
            {/* {localStorage.getItem('userToken') ? <ChatWidget /> : null} */}
            <Modal
                open={networkStatus === 'offline' || networkStatus === 'weak'}
                closable={false}
                footer={null}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                {networkStatus === 'offline' ? (
                    <Result status="error" title="Internet connection is offline" />
                ) : networkStatus === 'weak' ? (
                    <Result status="warning" title="Internet connection not stable" />
                ) : null}
            </Modal>
        </div>
    );
};

export default MainLayout;
