import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button as MuiButton, Divider } from '@mui/material';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDarkStoreContext } from 'context/darkstore-inventory/darkstore-context';
import { useParams } from 'react-router';

const ModalWrapper = styled.div`
    .modal-actions {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 30px;
    }

    .title {
        color: #ff740f;
        font-weight: 700;
        text-align: center;
        padding: 1rem 0;
    }

    .cancel-btn {
        background-color: #193855;
        margin-left: -3rem;
    }

    .save-btn {
        background-color: #ff740f;
        margin-right: -2rem;
    }

    .content {
        padding: 1rem 2rem;
        margin-bottom: 50px;
    }

    .h-title {
        font-weight: 600;
        color: #444;
        text-transform: uppercase;
    }
`;

const AddPartModal = ({ handleClose, show }) => {
    const { id } = useParams();
    const { items, isLoading, handleNewInventory } = useDarkStoreContext();
    const [qty, setQty] = useState(0);
    const [partName, setPartName] = useState('');
    const [partId, setPartId] = useState('');
    const [garagePrice, setPrice] = useState(0);
    const [wholeSalePrice, setWholeSalePrice] = useState(0);

    const handleSelectPartId = (e, value) => {
        setPartName(value);
        // eslint-disable-next-line array-callback-return
        items.map((item) => {
            if (item?.sku === value) {
                setPartId(item?._id);
                setPrice(item?.garagePrice);
                setWholeSalePrice(item?.wholeSalePrice);
            }
        });
    };

    const currentObject = {
        darkstoreAreaId: id,
        partId,
        qty: String(qty),
        wholeSalePrice: String(wholeSalePrice),
        garagePrice: String(garagePrice)
    };

    const createNewPart = () => {
        handleNewInventory(currentObject);
        setPartName('');
        setQty(0);
        setPrice(0);
        setWholeSalePrice(0);
        setTimeout(() => {
            handleClose();
        }, 2000);
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <ModalWrapper>
                    {/* <Modal.Title>
                        <h5 className="title">Add New Part</h5>
                        <hr />
                    </Modal.Title> */}
                    <Modal.Body>
                        <div className="content">
                            <Divider>
                                <h5 className="h-title">Add New Inventory</h5>
                            </Divider>
                            <br />
                            <FormControl fullWidth>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    value={partName}
                                    onChange={handleSelectPartId}
                                    options={items.map((option) => option?.sku)}
                                    isOptionEqualToValue={(option, value) => option?.sku === value?.sku}
                                    renderInput={(params) => <TextField {...params} label="Search or Select Auto Parts" />}
                                />
                            </FormControl>
                            <br /> <br />
                            <FormControl fullWidth>
                                <TextField
                                    id="outlined-basic"
                                    label="Quantity"
                                    variant="outlined"
                                    type="number"
                                    value={qty}
                                    onChange={(e) => setQty(e.target.value)}
                                />
                            </FormControl>
                            <br /> <br />
                            <FormControl fullWidth>
                                <TextField
                                    id="outlined-basic"
                                    label="Price"
                                    variant="outlined"
                                    type="number"
                                    value={garagePrice}
                                    onChange={(e) => setPrice(e.target.value)}
                                />
                            </FormControl>
                            <br /> <br />
                            <FormControl fullWidth>
                                <TextField
                                    id="outlined-basic"
                                    label="WholeSale Price"
                                    variant="outlined"
                                    type="number"
                                    value={wholeSalePrice}
                                    onChange={(e) => setWholeSalePrice(e.target.value)}
                                />
                            </FormControl>
                        </div>
                    </Modal.Body>
                    <div className="modal-actions">
                        <MuiButton variant="contained" onClick={handleClose} className="cancel-btn">
                            Cancel
                        </MuiButton>
                        <LoadingButton onClick={createNewPart} loading={isLoading} variant="contained" className="save-btn">
                            Save
                        </LoadingButton>
                    </div>
                </ModalWrapper>
            </Modal>
        </>
    );
};
export default AddPartModal;
