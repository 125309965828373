/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, TextField } from '@mui/material';
import { Col, Row } from 'react-grid-system';
import React, { useState } from 'react';
import { errorToast, successToast } from 'utils/toast';

import { AiFillFilePdf } from 'react-icons/ai';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import LeadTableWrapper from 'assets/Wrappers/LeadTableWrapper';
// import { MdQrCode } from 'react-icons/md';
import MenuItem from '@mui/material/MenuItem';
import MoonLoader from 'react-spinners/MoonLoader';
import Paper from '@mui/material/Paper';
import { QRCode } from 'antd';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TablePaginationActions from 'components/TablePaginationActions';
import TableRow from '@mui/material/TableRow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { authFetch } from 'utils/axios-config';
import { detectCurrencyType } from 'utils/functions';
import { makeStyles } from '@mui/styles';
import { useDeliveryContext } from 'context/delivery-notes/delivery-context';

const DeliveryNoteTable = ({ th1, th2, th3, th4, th5, th6, th7, th8 }) => {
    const { handlePdfPreview } = useDeliveryContext();
    const [isLoading, setLoading] = useState(false);

    /** Table data rows */
    const [rows, setRows] = useState([]);
    /** Order stasuses */
    const [orderStasusList, setOrderStatusesList] = React.useState([]);
    const [partnerName, setPartnerName] = React.useState('');
    const [orderStatusId, setOrderStatusId] = React.useState('All');

    const useStyles = makeStyles(() => ({
        tableHeaderCell: {
            fontWeight: 'bolder'
        }
    }));
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());

    const handleEvent = (e, picker) => {
        setStartDate(picker.startDate._d.toISOString());
        setEndDate(picker.endDate._d.toISOString());
    };

    const filteredRows = rows.filter((row) => (row.channelPartnerId || '').toLowerCase().includes(partnerName.toLowerCase()));
    const isPlural = filteredRows && filteredRows.length > 1 ? 's' : '';
    const searchCount = partnerName ? `${filteredRows.length} result${isPlural} found` : 'No Results';

    const filterOptions = {
        orderStatusId
    };

    /** Get All deliveries */
    const getAllDeliveries = async () => {
        setLoading(true);
        try {
            const { data } = await authFetch('/deliveryautomation/todayorderlist');
            const { orderlist, orderstatuslist } = data;
            setRows(orderlist);
            setOrderStatusesList(orderstatuslist);
            setLoading(false);
            successToast('Data synchronized successfully');
        } catch (error) {
            const { message } = error.response.data;
            if (error.response.status !== 401) {
                errorToast(message);
            }
        }
    };

    /** Filter orders by date */
    const filterOrderByDate = async (filterOptions) => {
        setLoading(true);
        try {
            const response = await authFetch.post('/deliveryautomation/filtertodayorderlistbydate', filterOptions);
            const { orderlist } = response.data;
            setRows(orderlist);
            setLoading(false);
            successToast('Data filtered successfully');
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                errorToast(message);
            }
            console.log(error);
        }
    };

    /** Filter by status */
    const handleOrderStatusFilter = async (filterOptions) => {
        setLoading(true);
        try {
            const { data } = await authFetch.post('/deliveryautomation/filterorderlistbyorderstatus', filterOptions);
            const { orderlist } = data;
            setLoading(false);
            setRows(orderlist);
        } catch (error) {
            console.log(error);
        }
    };

    React.useEffect(() => {
        getAllDeliveries();
    }, []);

    React.useEffect(() => {
        handleOrderStatusFilter(filterOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderStatusId]);

    const handleOrderFilterByDate = async () => {
        filterOrderByDate({ from: startDate, to: endDate });
    };

    const handleFilterReset = () => {
        window.location = '/orders/deliveries';
    };

    const downloadQRCode = (orderNo) => {
        const canvas = document.getElementById('myqrcode')?.querySelector('canvas');
        if (canvas) {
            const url = canvas.toDataURL();
            const a = document.createElement('a');
            a.download = `#${orderNo}QRCode.png`;
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };

    return (
        <LeadTableWrapper>
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className="lead__main-table" elevation={0}>
                <div style={{ padding: '15px 30px' }}>
                    <h4 style={{ color: '#ff740f', fontWeight: '700' }}>{partnerName ? searchCount : `Total Orders: ${rows.length}`}</h4>
                    <div style={{ padding: '10px' }}>
                        <Row>
                            <Col xs={12} xl={3} lg={3} md={6} sm={6}>
                                <FormControl fullWidth style={{ marginBottom: '10px' }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Search partner name"
                                        variant="outlined"
                                        onChange={(e) => setPartnerName(e.target.value)}
                                    />
                                </FormControl>
                            </Col>
                            <Col xs={12} xl={3} lg={3} md={6} sm={6}>
                                <FormControl fullWidth style={{ marginBottom: '10px' }}>
                                    <InputLabel id="demo-simple-select2-label">Filter order status</InputLabel>
                                    <Select
                                        defaultValue=""
                                        labelId="demo-simple-select2-label"
                                        id="demo-simple-select2"
                                        value={orderStatusId}
                                        label="Orders"
                                        onChange={(e) => setOrderStatusId(e.target.value)}
                                    >
                                        <MenuItem value="All">All</MenuItem>
                                        {orderStasusList.map((status) => {
                                            return (
                                                <MenuItem value={status._id} key={status._id}>
                                                    {status.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} xl={6} lg={6} md={6} sm={6}>
                                <Stack direction="row" spacing={1}>
                                    <DateRangePicker
                                        initialSettings={{ startDate, endDate }}
                                        onEvent={handleEvent}
                                        onApply={handleOrderFilterByDate}
                                        onCancel={handleFilterReset}
                                    >
                                        <Button
                                            variant="contained"
                                            disableElevation
                                            size="large"
                                            className="custom-date-picker"
                                            style={{ whiteSpace: 'nowrap' }}
                                        >
                                            Filter By Date
                                        </Button>
                                    </DateRangePicker>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        onClick={handleFilterReset}
                                        style={{ background: '#ff740f', whiteSpace: 'nowrap' }}
                                    >
                                        Reset Filter
                                    </Button>
                                </Stack>
                            </Col>
                        </Row>
                    </div>
                </div>
                {isLoading ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '50px'
                        }}
                    >
                        <MoonLoader color="#ff740f" />
                    </div>
                ) : (
                    <>
                        <TableContainer sx={{ maxHeight: 400 }}>
                            <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th1}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th2}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th3}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th4}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th5}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th6}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th7}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th8}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : filteredRows
                                    ).map((row, index = 1) => {
                                        const {
                                            _id,
                                            orderDate,
                                            channelPartnerId,
                                            orderValue,
                                            orderStatusId,
                                            fulOrderId,
                                            salesname,
                                            expCashCollected
                                        } = row;
                                        console.log(row);

                                        const qrCodeContent = `Order No: #${fulOrderId}\n Order Date: ${new Date(
                                            orderDate
                                        ).toDateString()}\n Sales Person: ${salesname}\nCustomer: ${channelPartnerId}\nSale Value: ${detectCurrencyType(
                                            orderValue
                                        )}\n `;

                                        function assingOrderStatusColor() {
                                            switch (orderStatusId) {
                                                case 'Order fulfilled':
                                                    return '#42855B';
                                                case 'Proforma Invoice Request':
                                                    return '#003865';
                                                case 'Order Cancelled':
                                                    return '#FF1E00';
                                                case 'Order Generated':
                                                    return '#ff740f';
                                                case 'Order Processing':
                                                    return '#100720';
                                                case 'Order Picked Up':
                                                    return '#7DCE13';
                                                case 'Unfulfilled Order':
                                                    return '#CA4E79';
                                                case 'Order Receipt Generated':
                                                    return '#3CCF4E';
                                                default:
                                                    return '#3D3C42';
                                            }
                                        }
                                        return (
                                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell align="left" scope="row">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    {new Date(orderDate).toDateString()}
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    {channelPartnerId}
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    {salesname}
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    {orderValue.toFixed(2)}
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    {expCashCollected.toFixed(2)}
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    <span
                                                        style={{
                                                            backgroundColor: `${assingOrderStatusColor()}`,
                                                            padding: '3px 25px',
                                                            color: '#fff',
                                                            borderRadius: '15px',
                                                            fontSize: '12px',
                                                            fontWeight: '500',
                                                            whiteSpace: 'nowrap'
                                                        }}
                                                    >
                                                        {orderStatusId}
                                                    </span>
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    <Stack direction="row" spacing={1}>
                                                        <IconButton
                                                            aria-label="call lead phone"
                                                            onClick={() => handlePdfPreview(row)}
                                                            title="Generate Pdf"
                                                        >
                                                            <a href="#!" style={{ color: '#FF1E00' }}>
                                                                <AiFillFilePdf />
                                                            </a>
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="preview"
                                                            href={`/orders/deliveries/${_id}`}
                                                            style={{ color: '#76BA99' }}
                                                            title="Preview"
                                                        >
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                        <div
                                                            id="myqrcode"
                                                            onClick={() => downloadQRCode(fulOrderId)}
                                                            role="button"
                                                            title="Click to download QR Code"
                                                        >
                                                            <QRCode size={130} value={qrCodeContent} />
                                                        </div>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            component="div"
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page'
                                },
                                native: true
                            }}
                            className="custom-paginator"
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </>
                )}
            </Paper>
        </LeadTableWrapper>
    );
};

export default DeliveryNoteTable;
