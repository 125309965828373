const data = [
    {
        contact: '0244556677',
        license: 'GH 123-90 1',
        carMake: 'Toyota',
        carYear: '2014',
        carModel: 'Camry',
        fuelType: 'Petrol',
        emissions: 166.4,
        milleage: 160000
    },
    {
        contact: '0244556677',
        license: 'GH 123-90 2',
        carMake: 'Nissan',
        carYear: '2016',
        carModel: 'Sentra',
        fuelType: 'Petrol',
        emissions: 166.4,
        milleage: 150000
    },
    {
        contact: '0244556677',
        license: 'GH 123-90 2',
        carMake: 'Ford',
        carYear: '2014',
        carModel: 'Sentra',
        fuelType: 'Petrol',
        emissions: 166.4,
        milleage: 150000
    }
];

export default data;
