import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import LeadTableWrapper from 'assets/Wrappers/LeadTableWrapper';
import { makeStyles } from '@mui/styles';
import TablePaginationActions from 'components/TablePaginationActions';
import { MdModeEdit } from 'react-icons/md';
import Modal from 'react-bootstrap/Modal';
import { Button as MuiButton } from '@mui/material';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';
import PreviewPriorityModal from './PreviewModal';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { usePriorityContext } from 'context/order-priority/priority-context';
import { authFetch } from 'utils/axios-config';
import MoonLoader from 'react-spinners/MoonLoader';
import { SiMicrosoftexcel } from 'react-icons/si';

dayjs.extend(localizedFormat);

const PriorityTable = ({ th1, th2, th3, th4, th5, th6, th7, th8, th9, th10, th11, rows }) => {
    const { singlePriority, getPriority, isLoading, stagingarea, updatePriorityOrder, handleExcelDataExport } = usePriorityContext();

    const useStyles = makeStyles(() => ({
        tableHeaderCell: {
            fontWeight: 'bolder'
        }
    }));
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [priorityDate, setpriorityDate] = React.useState(new Date());
    const [stagingAreaId, setStagingAreaId] = React.useState('');
    const [batchId, setBatchId] = React.useState('');
    const [orderId, setOrderId] = React.useState('');
    const [retailerlat, setRetailerlat] = React.useState('');
    const [retailerlng, setRetailerlng] = React.useState('');
    const [id, setId] = React.useState('');

    const priorityObject = {
        id,
        priorityDate,
        stagingAreaId,
        batchId,
        orderId,
        retailerlat,
        retailerlng
    };

    /** Handling Modal Edit states & funcs */
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handlePriorityEdit = async (id) => {
        handleShow();
        try {
            const { data } = await authFetch.get(`orderpriority/show/${id}`);
            const { _id, batchId, stagingAreaId, priorityDate, orderId, retailerlat, retailerlng } = data.data;
            setpriorityDate(priorityDate);
            setStagingAreaId(stagingAreaId);
            setBatchId(batchId);
            setOrderId(orderId);
            setRetailerlat(retailerlat);
            setRetailerlng(retailerlng);
            setId(_id);
        } catch (error) {
            console.log(error);
        }
    };

    const handlePriorityUpdate = () => {
        updatePriorityOrder(priorityObject);
        setTimeout(() => {
            handleClose();
        }, 3000);
    };

    /** Preview Modal Component */
    const [preview, setPreview] = React.useState(false);
    const handlePriorityClose = () => setPreview(false);
    const handlePriorityShow = (id) => {
        setPreview(true);
        getPriority(id);
    };

    return (
        <LeadTableWrapper>
            {isLoading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '80px'
                    }}
                >
                    <MoonLoader color="#ff740f" />
                </div>
            ) : (
                <Paper sx={{ width: '100%', overflow: 'hidden' }} className="lead__main-table">
                    <div
                        className="print-table"
                        style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '1rem 3rem' }}
                    >
                        <MuiButton
                            onClick={() => handleExcelDataExport(rows)}
                            variant="contained"
                            className="print-table"
                            style={{ background: '#193855' }}
                        >
                            Download &nbsp;
                            <SiMicrosoftexcel />
                        </MuiButton>
                    </div>
                    <TableContainer sx={{ maxHeight: 400 }}>
                        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                        {th1}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                        {th2}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                        {th3}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                        {th4}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                        {th5}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                        {th6}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                        {th7}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                        {th8}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                        {th9}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                        {th10}
                                    </TableCell>
                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                        {th11}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map(
                                    (row, index = 1) => {
                                        const {
                                            _id,
                                            priorityDate,
                                            batchNo,
                                            orderNo,
                                            size,
                                            orderType,
                                            distance,
                                            stagingArea,
                                            location,
                                            priority
                                        } = row;
                                        return (
                                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell align="left" scope="row">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    {dayjs(priorityDate).format('l')}
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    {batchNo}
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    {orderNo}
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    {size}
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    {orderType === 'New' ? (
                                                        <span className="badge bg-success">{orderType}</span>
                                                    ) : (
                                                        <span className="badge bg-danger">{orderType}</span>
                                                    )}
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    {distance}
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    {location}
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    {stagingArea}
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    {priority}
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    <Stack direction="row" spacing={0}>
                                                        <IconButton
                                                            aria-label="preview"
                                                            className="icon-preview"
                                                            title="Preview"
                                                            onClick={() => handlePriorityShow(_id)}
                                                        >
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="call lead phone"
                                                            onClick={() => handlePriorityEdit(_id)}
                                                            title="Edit batch"
                                                        >
                                                            <a href="#!" className="icon-phone">
                                                                <MdModeEdit />
                                                            </a>
                                                        </IconButton>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                )}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        component="div"
                        SelectProps={{
                            inputProps: {
                                'aria-label': 'rows per page'
                            },
                            native: true
                        }}
                        className="custom-paginator"
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </Paper>
            )}
            {/* Edit Modal */}
            <Modal show={show} onHide={handleClose} style={{ marginTop: '90px' }}>
                <ModalWrapper>
                    <Modal.Title>
                        <h5 className="title">Update Priority</h5>
                        <hr />
                    </Modal.Title>
                    <Modal.Body>
                        <div className="content">
                            {/* Pick date */}
                            <div className="form-align">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Date"
                                        inputFormat="MM/dd/yyyy"
                                        value={priorityDate}
                                        onChange={(value) => setpriorityDate(value)}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="form-align">
                                <FormControl fullWidth>
                                    <TextField
                                        id="outlined-basic"
                                        label="Latitude"
                                        variant="outlined"
                                        value={retailerlat}
                                        onChange={(e) => setRetailerlat(e.target.value)}
                                    />
                                </FormControl>
                            </div>
                            <div className="form-align">
                                <FormControl fullWidth>
                                    <TextField
                                        id="outlined-basic"
                                        label="Longitude"
                                        variant="outlined"
                                        value={retailerlng}
                                        onChange={(e) => setRetailerlng(e.target.value)}
                                    />
                                </FormControl>
                            </div>
                            <div className="form-align">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select2-label">Staging Area</InputLabel>
                                    <Select
                                        labelId="demo-simple-select2-label"
                                        id="demo-simple-select2"
                                        value={stagingAreaId}
                                        label="Staging area"
                                        onChange={(e) => setStagingAreaId(e.target.value)}
                                    >
                                        {stagingarea.map((item, index) => (
                                            <MenuItem key={index} value={item._id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </Modal.Body>
                    <div className="modal-actions">
                        <MuiButton variant="contained" onClick={handleClose} className="cancel-btn">
                            Cancel
                        </MuiButton>
                        <LoadingButton onClick={handlePriorityUpdate} disabled={isLoading} variant="contained" className="save-btn">
                            {isLoading ? 'Sending...' : 'Update Changes'}
                        </LoadingButton>
                    </div>
                </ModalWrapper>
            </Modal>
            <PreviewPriorityModal handleClose={handlePriorityClose} show={preview} singlePriority={singlePriority} isLoading={isLoading} />
        </LeadTableWrapper>
    );
};

export default PriorityTable;

const ModalWrapper = styled.div`
    .modal-actions {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 50px;
    }

    .title {
        color: #ff740f;
        font-weight: 800;
        font-size: 1.5rem;
        text-align: center;
        padding: 1rem 0;
    }

    .cancel-btn {
        background-color: #193855;
        margin-left: -3rem;
    }

    .save-btn {
        background-color: #ff740f;
        margin-right: -2rem;
    }

    .content {
        padding: 1rem 2rem;
        margin-bottom: 50px;
    }

    .form-align {
        margin-bottom: 20px;
    }
`;
