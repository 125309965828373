import { Divider, IconButton } from '@mui/material';
import styled from 'styled-components';
import { MdEdit } from 'react-icons/md';

const Wrapper = styled.div`
    background: #fff;
    padding: 0.625rem;
    margin-bottom: 15px;

    h6 {
        font-weight: 800;
    }

    .edit-icon {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .edit {
        color: #ff740f;
    }

    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .actual-text {
        font-style: italic;
        font-weight: 600;
        border-radius: 10px;
        padding: 5px 10px;
        font-size: 14px;
    }
`;

function assingOrderStatusColor(status) {
    switch (status) {
        case 'Proforma Invoice':
            return '#003865';
        case 'Cancelled':
            return '#FF1E00';
        case 'Dispatched':
            return '#ff740f';
        case 'Processing':
            return '#7DCE13';
        case 'Delivered':
            return '#3CCF4E';
        case 'Received':
            return '#3CCF4E';
        default:
            return '#3D3C42';
    }
}

function toTimestamp(strDate) {
    const today = strDate ? new Date(strDate) : new Date();
    return today.toLocaleDateString('en-gh');
}

const OrderStatusCard = ({ row, onEdit }) => {
    return (
        <Wrapper className="shadow-sm">
            <Divider>
                <h6>Order #{row?.fulOrderId}</h6>
            </Divider>
            {row?.fulStatus !== 'Delivered' && (
                <div className="edit-icon">
                    <IconButton aria-label="edit" onClick={() => onEdit(row)}>
                        <MdEdit className="edit" />
                    </IconButton>
                </div>
            )}
            <div className="body">
                <div className="content">
                    <p>Status</p>
                    <p className="actual-text" style={{ background: assingOrderStatusColor(row?.fulStatus) }}>
                        {row?.fulStatus}
                    </p>
                </div>
                <div className="content">
                    <p>Received date/time</p>
                    <p className="actual-text"> {toTimestamp(row?.orderDate) ?? 'N/A'}</p>
                </div>
                <div className="content">
                    <p>Retailer</p>
                    <p className="actual-text"> {row?.newChannelPartnerId?.partnerName ?? ''}</p>
                </div>
                <div className="content">
                    <p>RM</p>
                    <p className="actual-text">
                        {' '}
                        {row?.salesTeamId?.name ?? ''} {row?.salesTeamId?.firstName ?? ''} {row?.salesTeamId?.lastName ?? ''}
                    </p>
                </div>

                <div className="content">
                    <p>Processing time</p>
                    <p className="actual-text"> {row?.fulProcessing ?? 'N/A'}</p>
                </div>
                <div className="content">
                    <p>Drivers Name</p>
                    <p className="actual-text"> {row?.fulAllocation ?? 'N/A'}</p>
                </div>
                <div className="content">
                    <p>Dispatch time</p>
                    <p className="actual-text">{row?.fulTimeDispatched ?? 'N/A'}</p>
                </div>
                <div className="content">
                    <p>Quantity ordered</p>
                    <p className="actual-text">{row?.fulQuantityOrdered ?? 'N/A'}</p>
                </div>
                <div className="content">
                    <p>Quantity delivered</p>
                    <p className="actual-text">{row?.fulQuantityDelivered ?? 'N/A'}</p>
                </div>
                <div className="content">
                    <p>Location</p>
                    <p className="actual-text"> {row?.fulLocation ?? 'N/A'}</p>
                </div>
            </div>
        </Wrapper>
    );
};
export default OrderStatusCard;
