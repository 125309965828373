import styled from 'styled-components';

const Wrapper = styled.div`
    margin-top: 30px;
    padding: 1rem 2rem;
    color: #222;
    font-family: 'Roboto';
    .sign {
        border-left: 10px solid #1f88e5;
        padding: 0 0.625rem;
    }
    p {
        line-height: 1.5;
        font-weight: 600;
    }
`;

const Signature = () => {
    return (
        <Wrapper>
            {/* <div className="sign">
                <p>Cash .................................</p>
                <p>Cash Tendered ..........................</p>
                <p>Balance .................................</p>
                <p>Signature ...............................</p>
            </div> */}
        </Wrapper>
    );
};
export default Signature;
