/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import useScriptRef from 'hooks/useScriptRef';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Form from 'react-bootstrap/Form';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const ViewOrderParts = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [policyId, setpolicyId] = useState('');
    const [newpolicyId, setnewpolicyId] = useState('');
    const [customer, setCustomer] = useState('');
    const [customerPhone, setcustomerPhone] = useState('');
    const [carMake, setcarMake] = useState('');
    const [model, setmodel] = useState('');
    const [year, setyear] = useState('');
    const [licensesplate, setlicensesplate] = useState('');
    const [engineType, setengineType] = useState('');
    const [vins, setvins] = useState('');
    const [car, setCar] = useState([]);
    const [cars, setCars] = useState([]);
    const [carModel, setcarModel] = useState([]);
    const [items, setItems] = useState([]);
    const [paymentStatusId, setpaymentStatusRecord] = useState('');
    const [newPaymentStatusId, setNewPaymentStatusId] = useState('');
    const [newOrderStatusId, setNewOrderStatusId] = useState('');
    const [orderStatusId, setorderStatusRecord] = useState('');
    const [orderStatus, setOrderStatus] = useState([]);
    const [location, setLocation] = useState('');
    const [availableTime, setavailableTime] = useState(new Date('2022-03-03T21:11:54'));
    const [totalCost, settotalCost] = useState(0);
    const [serviceFee, setserviceFee] = useState('');
    const [skuId, setSkuId] = useState('');
    const [partid, setpartid] = useState('');
    const [qty, setQty] = useState('');
    const [garagePrice, setgaragePrice] = useState('');
    const [carId, setcarId] = useState('');
    const [policy, setPolicy] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState([]);
    const [fuelType, setFuel] = useState([]);
    const [part, setPart] = useState([]);

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [opens, setOpens] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { id } = useParams();

    const retrieveAllActiveOrderStatus = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllActiveOrderStatus, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrderStatus(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllActivePaymentStatus = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllActivePaymentStatus, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setPaymentStatus(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllPolicyId = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllActivePolicyId, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setPolicy(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllCar = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllActiveCar, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCar(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllCarModel = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllCarModelActive, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setcarModel(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    const retrieveAllPart = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.ActivePartUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setPart(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllServiceFee = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllServiceFeeActive, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setserviceFee(data?.data?.cost ?? 0);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handleAvailableTime = (event) => {
        setavailableTime(event);
    };

    const handleFuelType = (event) => {
        setFuel(event.target.value);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenitems = () => {
        setOpens(true);
    };

    const handleCloseitems = () => {
        setOpens(false);
    };

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.EditInsurerOrderPart + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setpolicyId(result?.data?.newpolicyId?.name ?? '');
                    setnewpolicyId(result?.data?.newpolicyId?._id ?? '');
                    setCustomer(result?.data?.customer ?? '');
                    setcustomerPhone(result?.data?.customerPhone ?? '');
                    setCars(result?.data?.cars ?? '');
                    setLocation(result?.data?.location ?? '');
                    setavailableTime(result?.data?.availableTime ?? '');
                    setpaymentStatusRecord(result?.data?.newPaymentStatusId?.name ?? '');
                    setNewPaymentStatusId(result?.data?.newPaymentStatusId?._id ?? '');
                    setorderStatusRecord(result?.data?.newOrderStatusId?.name ?? '');
                    setNewOrderStatusId(result?.data?.newOrderStatusId?._id ?? '');
                    setItems(result?.data?.items ?? '');
                    settotalCost(result?.data?.totalCost ?? '');
                    setserviceFee(result?.data?.serviceFee ?? '');

                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                }
            })
            .finally(() => {
                setLoading2(false);
            });

        retrieveAllPolicyId();
        retrieveAllCar();
        retrieveAllCarModel();
        retrieveAllPart();
        retrieveAllServiceFee();
        retrieveAllActivePaymentStatus();
        retrieveAllActiveOrderStatus();
    }, [id]);

    const onSubmitCar = (event) => {
        event.preventDefault();
        // cars, setCars
        setCars([
            ...cars,
            {
                carId,
                carMake,
                model,
                year,
                licensesplate,
                fuelType,
                engineType,
                vins
            }
        ]);

        handleClose();
    };

    const onSubmitItems = (event) => {
        event.preventDefault();
        // items, setItems
        setItems([
            ...items,
            {
                skuId,
                partid,
                qty,
                garagePrice
            }
        ]);
        let sum = totalCost;
        const amt = garagePrice * qty;
        sum += amt;
        settotalCost(sum);

        handleCloseitems();
    };

    // Submit Order
    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        const data = {
            id,
            policyId,
            newpolicyId,
            customer,
            customerPhone,
            cars,
            paymentStatusId,
            newPaymentStatusId,
            orderStatusId,
            newOrderStatusId,
            location,
            availableTime,
            items,
            totalCost,
            serviceFee
        };

        fetch(ApiUrl.UpdateInsurerOrderPart, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/orderparts');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const filterPart = (event) => {
        const newFilter = part.filter((value) => value.sku.toLowerCase().includes(event.toLowerCase()));

        newFilter.forEach((option) => {
            setSkuId(option._id);
            setpartid(option.sku);
            setgaragePrice(option.garagePrice);
        });
    };

    const filterOrderStatus = (event) => {
        const newFilter = orderStatus.filter((value) => value.name.toLowerCase().includes(event.toLowerCase()));

        newFilter.forEach((option) => {
            setNewOrderStatusId(option._id);
        });
    };

    const filterPaymentStatus = (event) => {
        const newFilter = paymentStatus.filter((value) => value.name.toLowerCase().includes(event.toLowerCase()));

        newFilter.forEach((option) => {
            setNewPaymentStatusId(option._id);
        });
    };

    const handleDeleteClick = (row) => {
        const newList = cars.filter((item) => item.carId !== row.carId);
        setCars(newList);
    };
    const handleDeleteClickitems = (row) => {
        const newList = items.filter((item) => item.skuId !== row.skuId);

        setItems(newList);
    };

    const filterpolicyId = (event) => {
        const newFilter = policy.filter((value) => value.name.toLowerCase().includes(event.toLowerCase()));
        newFilter.forEach((option) => {
            setnewpolicyId(option._id);
        });
    };

    const filterCar = (event) => {
        const newFilter = car.filter((value) => value.name.toLowerCase().includes(event.toLowerCase()));
        newFilter.forEach((option) => {
            console.log('id', option._id);
            setcarId(option._id);
        });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <PageHeader title="Order Part" subTitle="Home | View Order Part" />
                    <MainCard title="">
                        <Typography variant="subtitle2" component="div">
                            Customer Information
                        </Typography>
                        <br />
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={policyId}
                                            onChange={(event, newValue) => {
                                                setpolicyId(newValue);
                                                filterpolicyId(newValue);
                                            }}
                                            options={policy.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Policy Id" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="customer"
                                            variant="outlined"
                                            required
                                            id="customer"
                                            label="Customer"
                                            value={customer}
                                            onChange={(e) => setCustomer(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="customerPhone"
                                            variant="outlined"
                                            required
                                            id="customerPhone"
                                            label="Customer Phone Number"
                                            value={customerPhone}
                                            onChange={(e) => setcustomerPhone(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />
                            <Typography variant="subtitle2" component="div">
                                Vehicle Information
                            </Typography>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={10}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Car Make
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Car Model
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Car Year
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        License Plate / Tags
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Fuel Type
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Engine Type
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        VIN
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {cars.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {row.carMake}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.model}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.year}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.licensesplate}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.fuelType}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.engineType}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row.vins}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                onClick={() => handleDeleteClick(row)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                    <Button variant="contained" onClick={handleClickOpen} size="sm" className={classes.item}>
                                        Add vehicle
                                    </Button>
                                </Grid>
                            </Grid>
                            <br />
                            <Typography variant="subtitle2" component="div">
                                Drop Location, Time and Payment Status
                            </Typography>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="location"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="location"
                                            value={location}
                                            label="Location"
                                            onChange={(e) => setLocation(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={220}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DateTimePicker
                                                label="Available Times"
                                                value={availableTime}
                                                onChange={handleAvailableTime}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={orderStatusId}
                                            onChange={(event, newValue) => {
                                                setorderStatusRecord(newValue);
                                                filterOrderStatus(newValue);
                                            }}
                                            options={orderStatus.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Order Status" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={paymentStatusId}
                                            onChange={(event, newValue) => {
                                                setpaymentStatusRecord(newValue);
                                                filterPaymentStatus(newValue);
                                            }}
                                            options={paymentStatus.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Payment Status" />}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />
                            <Typography variant="subtitle2" component="div">
                                Parts
                            </Typography>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={10}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Part Name
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Qty
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Part Cost
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {items.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {row.partid}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.qty}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.garagePrice}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                onClick={() => handleDeleteClickitems(row)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                    <Button variant="contained" onClick={handleClickOpenitems} size="sm" className={classes.item}>
                                        Add Part
                                    </Button>
                                </Grid>
                            </Grid>
                            <br />
                            <br />
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Typography variant="h4" component="div">
                                        Total Parts Cost
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Typography variant="h4" component="div">
                                        {totalCost} GHC
                                    </Typography>
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Typography variant="h4" component="div">
                                        Service Cost
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Typography variant="h4" component="div">
                                        {serviceFee} GHC
                                    </Typography>
                                </Grid>
                            </Grid>
                            <br />
                            <br />
                            <Box display="flex" justifyContent="space-between">
                                <Button component={Link} to="/orderparts" type="submit" variant="contained" color="error">
                                    Reset
                                </Button>
                                <Button type="submit" variant="contained" color="secondary">
                                    {loading ? 'Updating...' : 'Update Order Part'}
                                </Button>
                            </Box>
                        </form>

                        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                            <DialogTitle id="responsive-dialog-title">Add Vehicle</DialogTitle>
                            <Form onSubmit={onSubmitCar}>
                                <DialogContent>
                                    {/* <DialogContentText> */}

                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Box width={200}>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                value={carMake}
                                                onChange={(event, newValue) => {
                                                    setcarMake(newValue);
                                                    filterCar(newValue);
                                                }}
                                                options={car.map((option) => option.name)}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                renderInput={(params) => <TextField {...params} label="Car Make" />}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                value={model}
                                                onChange={(event, newValue) => {
                                                    setmodel(newValue);
                                                }}
                                                options={carModel.map((option) => option.name)}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                renderInput={(params) => <TextField {...params} label="Car Model" />}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="year"
                                                variant="outlined"
                                                required
                                                id="year"
                                                label="Car Year"
                                                value={year}
                                                onChange={(e) => setyear(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="licensesplate"
                                                variant="outlined"
                                                required
                                                id="licensesplate"
                                                label="License Plate / Tags"
                                                value={licensesplate}
                                                onChange={(e) => setlicensesplate(e.target.value)}
                                            />
                                        </Box>
                                        <br />
                                        <br />
                                        <Box width={200}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Fuel Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={fuelType}
                                                    label="Status"
                                                    onChange={handleFuelType}
                                                >
                                                    <MenuItem value="Body">Select an option</MenuItem>
                                                    <MenuItem value="Petrol">Petrol</MenuItem>
                                                    <MenuItem value="Diesel">Diesel</MenuItem>
                                                    <MenuItem value="Gas">Gas</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="engineType"
                                                variant="outlined"
                                                required
                                                id="engineType"
                                                label="Engine Type"
                                                value={engineType}
                                                onChange={(e) => setengineType(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="vins"
                                                variant="outlined"
                                                required
                                                id="vins"
                                                label="VIN"
                                                value={vins}
                                                onChange={(e) => setvins(e.target.value)}
                                            />
                                        </Box>
                                    </Form.Group>

                                    {/* </DialogContentText> */}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="sm"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleClose}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        type="submit"
                                        size="sm"
                                        className={classes.item}
                                        disabled={!carMake || !model || !year || !licensesplate || !fuelType || !engineType || !vins}
                                    >
                                        Add
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>

                        <Dialog fullScreen={fullScreen} open={opens} onClose={handleCloseitems} aria-labelledby="responsive-dialog-title">
                            <DialogTitle id="responsive-dialog-title">Add Part</DialogTitle>
                            <Form onSubmit={onSubmitItems}>
                                <DialogContent>
                                    {/* <DialogContentText> */}

                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Box width={200}>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                value={partid}
                                                onChange={(event, newValue) => {
                                                    setpartid(newValue);
                                                    filterPart(newValue);
                                                }}
                                                options={part.map((option) => option.sku)}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                renderInput={(params) => <TextField {...params} label="Sku" />}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="qty"
                                                variant="outlined"
                                                required
                                                id="qty"
                                                label="Qty"
                                                value={qty}
                                                onChange={(e) => setQty(e.target.value)}
                                            />
                                        </Box>
                                    </Form.Group>

                                    {/* </DialogContentText> */}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="sm"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleCloseitems}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        type="submit"
                                        size="sm"
                                        className={classes.item}
                                        disabled={!partid || !qty}
                                    >
                                        Add
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>
                    </MainCard>
                </>
            )}
        </>
    );
};
export default ViewOrderParts;
