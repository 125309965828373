/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import logo from 'assets/images/garage.png';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import MainCard from 'ui-component/cards/MainCard';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ApiUrl from 'network';
import useScriptRef from 'hooks/useScriptRef';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import EditIcon from '@mui/icons-material/Edit';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Form from 'react-bootstrap/Form';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
// import axios from 'axios';
// import Avatar from '@mui/material/Avatar';
// import nologo from 'assets/images/nologo.png';

// const Input = styled('input')({
//     display: 'none'
// });

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    }
}));

const AddShop = () => {
    const classes = useStyles();
    const history = useNavigate();
    const [partnerName, setpartnerName] = useState('');
    const [shopname, setshopname] = useState('');
    const [shoplocation, setshoplocation] = useState('');
    const [shoplat, setshoplat] = useState('');
    const [shoplng, setshoplng] = useState('');
    // const [loading3, setLoading3] = useState(false);
    const [loading4, setLoading4] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [shopsdata, setshopsdata] = useState([]);
    const [open, setOpen] = useState(false);
    const [indexValue, setIndexValue] = useState('');
    const [error, setError] = useState('');
    const [correct, setCorrect] = useState('');
    const [open2, setOpen2] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { id } = useParams();

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    const handleGeoLocation = () => {
        setLoading4(true);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (res) => {
                    setshoplat(res.coords.latitude);
                    setshoplng(res.coords.longitude);
                    // fetchLocationName(res.coords.latitude, res.coords.longitude);
                    // console.log(res.coords.latitude, res.coords.longitude);
                    setCorrect('success');
                    setLoading4(false);
                },
                (err) => {
                    if (err.code === 2) {
                        setError('Error : Please Check internet connnection');
                        setLoading4(false);
                        setCorrect('failed');
                        // console.log('Error : Please Check internet connnection');
                    } else {
                        // console.log('Error : Please Enable Browser location Accessn');
                        setError('Error : Please Enable Browser location Accessn');
                        setLoading4(false);
                        setCorrect('failed');
                    }
                }
            );
        } else {
            //  console.log('Geolocation is not supported by this browser');
            setError('Geolocation is not supported by this browser');
            setLoading4(false);
            setCorrect('failed');
        }
    };

    const scriptedRef = useScriptRef();
    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.ViewAllChannelPartnerURL + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setpartnerName(result?.data?.partnerName ?? '');
                    setshopsdata(result?.data?.shopsdata ?? []);

                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                    // setLoading(false);
                }
            })
            .finally(() => {
                setLoading2(false);
            });
    }, [id]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const onSubmitCollection = (event) => {
        event.preventDefault();
        // items, setItems
        setshopsdata([
            ...shopsdata,
            {
                id,
                shopname,
                shoplocation,
                shoplat,
                shoplng
            }
        ]);

        handleClose();
    };

    const onSubmitEditCollection = (event) => {
        event.preventDefault();
        const newList = Object.assign([], shopsdata);
        const data = { ...newList[indexValue] };
        data.shopname = shopname;
        data.shoplocation = shoplocation;
        data.shoplat = shoplat;
        data.shoplng = shoplng;
        newList[indexValue] = data;
        setshopsdata(newList);
        handleClose2();
    };

    const handleDeleteClick = (row, index) => {
        const newList = Object.assign([], shopsdata);
        newList.splice(index, 1);
        setshopsdata(newList);
    };

    const handleEditClick = (row, index) => {
        // console.log('row', row);
        setshopname(row.shopname ?? '');
        setshoplocation(row.shoplocation ?? '');
        setshoplat(row?.shoplat ?? '');
        setshoplng(row?.shoplng ?? '');
        handleClickOpen2();
        setIndexValue(index);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            id,
            shopsdata
        };
        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        fetch(ApiUrl.AddShopChannelPartnerUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/salespartner');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    {/* <AuthWrapper1> */}
                    <MainCard title="">
                        <Grid container justify="flex-start">
                            <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                            <Grid item xs={12}>
                                {/* <Grid container> */}
                                <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                                    {/* <AuthCardWrapper> */}
                                    <Grid container spacing={2}>
                                        {/* <Grid item sx={{ mb: 3 }} justifyContent="center">
                                            <Link to="#">
                                                <img src={logo} alt="logo" height={50} />
                                            </Link>
                                        </Grid> */}

                                        <Grid item xs={12}>
                                            <Typography variant="h3" component="h2" textAlign="center">
                                                Add Shop Form
                                            </Typography>
                                            <br />

                                            <Typography variant="h5" component="h2" textAlign="center">
                                                Use this form to input new shop from
                                            </Typography>

                                            <Typography variant="h3" component="h2" textAlign="center">
                                                {partnerName}{' '}
                                            </Typography>

                                            <br />
                                            <form onSubmit={handleSubmit}>
                                                <br />

                                                <Box display="flex" flexDirection="row-reverse">
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleClickOpen}
                                                        size="sm"
                                                        className={classes.item}
                                                    >
                                                        Add
                                                    </Button>
                                                </Box>
                                                <br />

                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <TableContainer component={Paper}>
                                                            <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                                            Date
                                                                        </TableCell>
                                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                                            Shop Name
                                                                        </TableCell>

                                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                                            Location
                                                                        </TableCell>
                                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                                            Latitude
                                                                        </TableCell>
                                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                                            Longitude
                                                                        </TableCell>

                                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                                            Action
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {shopsdata.map((row, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell component="th" scope="row">
                                                                                {toTimestamp(row?.shopdate)}
                                                                            </TableCell>

                                                                            <TableCell component="th" scope="row">
                                                                                {row?.shopname ?? ''}
                                                                            </TableCell>

                                                                            <TableCell component="th" scope="row">
                                                                                {row?.shoplocation ?? ''}
                                                                            </TableCell>

                                                                            <TableCell component="th" scope="row">
                                                                                {row?.shoplat ?? ''}
                                                                            </TableCell>

                                                                            <TableCell component="th" scope="row">
                                                                                {row?.shoplng ?? ''}
                                                                            </TableCell>

                                                                            <TableCell component="th" scope="row">
                                                                                <IconButton
                                                                                    aria-label="edit"
                                                                                    color="primary"
                                                                                    onClick={() => handleEditClick(row, index)}
                                                                                >
                                                                                    <EditIcon />
                                                                                </IconButton>

                                                                                <IconButton
                                                                                    aria-label="delete"
                                                                                    color="error"
                                                                                    onClick={() => handleDeleteClick(row, index)}
                                                                                >
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                </Grid>

                                                <br />
                                                <br />
                                                <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                                    <Button type="submit" variant="contained" color="secondary">
                                                        {loading ? 'Saving...' : 'Save'}
                                                    </Button>
                                                    <Button
                                                        component={Link}
                                                        to="/salespartner"
                                                        type="submit"
                                                        variant="contained"
                                                        color="error"
                                                    >
                                                        Reset
                                                    </Button>
                                                </Box>
                                            </form>
                                        </Grid>
                                    </Grid>
                                    {/* </AuthCardWrapper> */}
                                </Grid>
                                {/* </Grid> */}

                                <Dialog
                                    fullScreen={fullScreen}
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="responsive-dialog-title"
                                    fullWidth
                                >
                                    <div style={{ padding: '1rem 2rem' }}>
                                        <DialogTitle id="responsive-dialog-title" style={{ fontSize: '1rem' }}>
                                            Add Shop
                                        </DialogTitle>
                                        <Form onSubmit={onSubmitCollection}>
                                            <DialogContent>
                                                <Form.Group controlId="formFile" className="mb-3">
                                                    <TextField
                                                        fullWidth
                                                        autoComplete="off"
                                                        name="shopname"
                                                        variant="outlined"
                                                        required
                                                        value={shopname}
                                                        id="shopname"
                                                        label="Shop Name"
                                                        onChange={(e) => {
                                                            setshopname(e.target.value);
                                                        }}
                                                    />
                                                    <br /> <br />
                                                    <TextField
                                                        autoComplete="off"
                                                        fullWidth
                                                        name="shoplocation"
                                                        required
                                                        variant="outlined"
                                                        value={shoplocation}
                                                        id="shoplocation"
                                                        label="Shop Location"
                                                        onChange={(e) => {
                                                            setshoplocation(e.target.value);
                                                        }}
                                                    />
                                                    <br /> <br />
                                                    {correct === 'success' ? (
                                                        <>
                                                            <Typography variant="h3" component="h2" textAlign="center" color="#008000">
                                                                Success
                                                            </Typography>
                                                        </>
                                                    ) : (
                                                        <Typography variant="h3" component="h2" textAlign="center" color="#FF0000">
                                                            {error}
                                                        </Typography>
                                                    )}
                                                    <br />
                                                    <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                                        <Button variant="contained" color="info" onClick={handleGeoLocation}>
                                                            {loading4 ? 'fetching....' : 'Get Location Coordinates'}
                                                        </Button>
                                                    </Box>
                                                    <br />
                                                    <TextField
                                                        autoComplete="off"
                                                        name="shoplat"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        id="shoplat"
                                                        value={shoplat}
                                                        label="Latitude"
                                                        onChange={(e) => setshoplat(e.target.value)}
                                                    />
                                                    <br />
                                                    <br />
                                                    <TextField
                                                        autoComplete="off"
                                                        name="shoplng"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        id="shoplng"
                                                        value={shoplng}
                                                        label="longitude"
                                                        onChange={(e) => setshoplng(e.target.value)}
                                                    />
                                                    <br />
                                                </Form.Group>
                                            </DialogContent>
                                            <DialogActions
                                                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    size="large"
                                                    className={classes.item}
                                                    autoFocus
                                                    onClick={handleClose}
                                                    color="error"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button autoFocus variant="contained" type="submit" size="large" className={classes.item}>
                                                    Add
                                                </Button>
                                            </DialogActions>
                                        </Form>
                                    </div>
                                </Dialog>

                                {/* Edit Shop */}
                                <Dialog
                                    fullScreen={fullScreen}
                                    open={open2}
                                    onClose={handleClose2}
                                    aria-labelledby="responsive-dialog-title"
                                    fullWidth
                                >
                                    <div style={{ padding: '1rem 2rem' }}>
                                        <DialogTitle id="responsive-dialog-title" style={{ fontSize: '1rem' }}>
                                            Edit Shop
                                        </DialogTitle>
                                        <Form onSubmit={onSubmitEditCollection}>
                                            <DialogContent>
                                                <Form.Group controlId="formFile" className="mb-3">
                                                    <TextField
                                                        autoComplete="off"
                                                        fullWidth
                                                        name="shopname"
                                                        variant="outlined"
                                                        required
                                                        value={shopname}
                                                        id="shopname"
                                                        label="Shop Name"
                                                        onChange={(e) => {
                                                            setshopname(e.target.value);
                                                        }}
                                                    />
                                                    <br /> <br />
                                                    <TextField
                                                        autoComplete="off"
                                                        name="shoplocation"
                                                        fullWidth
                                                        required
                                                        variant="outlined"
                                                        value={shoplocation}
                                                        id="shoplocation"
                                                        label="Shop Location"
                                                        onChange={(e) => {
                                                            setshoplocation(e.target.value);
                                                        }}
                                                    />
                                                    <br /> <br />
                                                    {correct === 'success' ? (
                                                        <>
                                                            <Typography variant="h3" component="h2" textAlign="center" color="#008000">
                                                                Success
                                                            </Typography>
                                                        </>
                                                    ) : (
                                                        <Typography variant="h3" component="h2" textAlign="center" color="#FF0000">
                                                            {error}
                                                        </Typography>
                                                    )}
                                                    <br />
                                                    <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                                        <Button variant="contained" color="info" onClick={handleGeoLocation}>
                                                            {loading4 ? 'fetching....' : 'Get Location Coordinates'}
                                                        </Button>
                                                    </Box>
                                                    <br />
                                                    <TextField
                                                        autoComplete="off"
                                                        name="shoplat"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        id="shoplat"
                                                        value={shoplat}
                                                        label="Latitude"
                                                        onChange={(e) => setshoplat(e.target.value)}
                                                    />
                                                    <br />
                                                    <br />
                                                    <TextField
                                                        autoComplete="off"
                                                        name="shoplng"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        id="shoplng"
                                                        value={shoplng}
                                                        label="longitude"
                                                        onChange={(e) => setshoplng(e.target.value)}
                                                    />
                                                    <br />
                                                </Form.Group>
                                            </DialogContent>
                                            <DialogActions
                                                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    size="large"
                                                    className={classes.item}
                                                    autoFocus
                                                    onClick={handleClose2}
                                                    color="error"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button autoFocus variant="contained" type="submit" size="large" className={classes.item}>
                                                    Update
                                                </Button>
                                            </DialogActions>
                                        </Form>
                                    </div>
                                </Dialog>
                            </Grid>
                        </Grid>
                    </MainCard>
                    {/* </AuthWrapper1> */}
                </>
            )}
        </>
    );
};

export default AddShop;
