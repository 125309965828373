import { Link, useParams } from 'react-router-dom';
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ApiUrl from '../../../../network';
import { decrypt, encrypt } from '../../../../utils/encrypt';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [partId, setPartId] = useState('');
    const [partsDiscount, setPartsDiscount] = useState([
        {
            id: '1',
            discountid: '',
            partid: '',
            sku: '',
            qty: '',
            discount: '',
            price: ''
        }
    ]);

    const fetchPartDiscount = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/parts/v2/viewpartsdiscount/${id}`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                const updatedPartsDiscount = decryptedData.map((item) => ({
                    id: item._id,
                    discountid: item._id,
                    partid: item.partIdgh._id,
                    sku: item.sku,
                    qty: item.qty,
                    discount: item.discount,
                    price: item.price
                }));
                setPartsDiscount(updatedPartsDiscount);
                setPartId(decryptedData[0]?.partIdgh._id || '');
                setLoading(false);
            } else {
                toast.error(result.message);
                setLoading(false);
            }
        } catch (e) {
            toast(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPartDiscount();
    }, [id]);

    const handleInputChange = (index, field, value) => {
        const values = [...partsDiscount];
        values[index][field] = value;
        setPartsDiscount(values);
    };

    const addDiscountHandler = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const datas = {
                discounts: partsDiscount
                    .filter((part) => part.discountid === '')
                    .map((part) => ({
                        partid: partId || id,
                        sku: part.sku,
                        qty: part.qty,
                        discount: part.discount,
                        price: part.price
                    }))
            };
            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            const url = `${ApiUrl.BaseUrl}/parts/v2/adddiscount`;
            const response = await fetch(url, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify({ payload })
            });
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                toast.success(result.message);
                fetchPartDiscount();
                setLoading(false);
            } else {
                toast.error(result.message);
                setLoading(false);
            }
        } catch (e) {
            toast.error(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const addNewDiscount = () => {
        setPartsDiscount([
            ...partsDiscount,
            {
                id: `${partsDiscount.length + 1}`,
                partid: partId,
                sku: '',
                qty: '',
                discount: '',
                discountid: '',
                price: ''
            }
        ]);
    };

    return (
        <div>
            <div className="m-header">
                <div>
                    <Link to={`/parts/discount/${id}`}>
                        <h1 style={{ color: '#0A2938', display: 'flex', alignItems: 'center' }}>
                            <ArrowLeftOutlined className="mr-2" /> Add Discount
                        </h1>
                    </Link>
                </div>
                <div>
                    <Button
                        style={{
                            backgroundColor: 'transparent',
                            color: '#FF740F'
                        }}
                        onClick={() => (window.location.href = `/parts/discount/${id}`)}
                        className="w-fit border-primary-m"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={partsDiscount.length === 0}
                        onClick={addDiscountHandler}
                        loading={loading}
                        className="w-fit primary-bg  text-white ml-2"
                    >
                        Save changes
                    </Button>
                </div>
            </div>
            <div className="m-content m-discount mt-4">
                {partsDiscount.map((item, index) => (
                    <div key={item.id} className="mb-4 discount-content">
                        <div className="d-flex">
                            <div className="form-group">
                                <p>SKU</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={item.sku}
                                    onChange={(e) => handleInputChange(index, 'sku', e.target.value)}
                                    disabled={item.discountid !== ''}
                                />
                            </div>
                            <div className="form-group">
                                <p>Quantity</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={item.qty}
                                    onChange={(e) => handleInputChange(index, 'qty', e.target.value)}
                                    disabled={item.discountid !== ''}
                                />
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="form-group">
                                <p>Discount %</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={item.discount}
                                    onChange={(e) => handleInputChange(index, 'discount', e.target.value)}
                                    disabled={item.price !== ''}
                                />
                            </div>
                            <div className="form-group">
                                <p>Price</p>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={item.price}
                                    onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                                    disabled={item.discount !== ''}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center mt-4">
                            {partsDiscount.length > 1 && (
                                <Button
                                    className="w-fit d-flex align-items-center justify-content-center"
                                    style={{
                                        backgroundColor: 'transparent',
                                        color: '#FF740F'
                                    }}
                                    onClick={() => {
                                        const values = [...partsDiscount];
                                        values.splice(index, 1);
                                        setPartsDiscount(values);
                                    }}
                                >
                                    Remove discount
                                </Button>
                            )}
                            {index === partsDiscount.length - 1 && (
                                <Button
                                    className="w-fit d-flex align-items-center justify-content-center"
                                    style={{
                                        backgroundColor: 'transparent',
                                        color: '#FF740F'
                                    }}
                                    onClick={addNewDiscount}
                                >
                                    Add new discount <PlusOutlined />
                                </Button>
                            )}
                        </div>
                    </div>
                ))}
                {partsDiscount.length === 0 && (
                    <div className="d-flex justify-content-center align-items-center">
                        <Button
                            className="w-fit d-flex align-items-center justify-content-center"
                            style={{
                                backgroundColor: 'transparent',
                                color: '#FF740F'
                            }}
                            onClick={addNewDiscount}
                        >
                            Add new discount <PlusOutlined />
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Index;
