/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';
import Controls from '../../components/controls/Controls';
import { Search } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
// import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
// import axios from 'axios';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import ViewIcon from '@mui/icons-material/ViewAgenda';
// import IconButton from '@mui/material/IconButton';
// import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
// import swal from 'sweetalert';
// import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
// import ViewIcon from '@mui/icons-material/Preview';
// import Tooltip from '@mui/material/Tooltip';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import * as XLSX from 'xlsx';

// import { saveAs } from 'file-saver';
// import axios from 'axios';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    colors: {
        color: `#ff740f`
    },
    backgroundColor: {
        backgroundColor: '#2CB23B'
    }
}));

const AllSalesPersonOrders = () => {
    const classes = useStyles();
    // const history = useNavigate();
    // const scriptedRef = useScriptRef();
    const [orderInfo, setOrder] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    // const [loading2, setLoading2] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [total, setTotal] = useState(0);
    const [cashTaken, setcashTaken] = useState(0);
    const [cashleft, setcashLeft] = useState(0);
    const [costtotal, setcosttotal] = useState(0);
    const [discounttotal, setdiscounttotal] = useState(0);
    const [retailerCredit, setretailerCredit] = useState(0);
    const userCurrency = localStorage.getItem('userCurrency');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 30));
        setPage(0);
    };

    // function toTimestamp(strDate) {
    //     const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    //     const today = strDate ? new Date(strDate) : new Date();
    //     return today.toLocaleDateString('en-gh', options);
    // }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderInfo.length) : 0;

    // const retrieveAllOrdersTotal = async () => {
    //     try {
    //         const userInfo = localStorage.getItem('userToken');
    //         const config = {
    //             headers: {
    //                 'auth-token': userInfo,
    //                 'Content-Type': 'application/json'
    //             }
    //         };
    //         setLoading(true);
    //         fetch(ApiUrl.AllFulfilledSalesOrdersUrl, config)
    //             .then((data) => data.json())
    //             .then((data) => {
    //                 if (data.status === true) {
    //                     let sum = 0;
    //                     data.data.forEach((element) => {
    //                         sum += Number(element.expCashCollected);
    //                     });
    //                     setTotal(Number(sum).toFixed(2));
    //                 } else {
    //                     toast.error(data.data);
    //                 }
    //             })
    //             .finally(() => {
    //                 setLoading(false);
    //             });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    const retrieveAllOrders = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllSalesPersonOrdersUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        // console.log(data.data);
                        setOrder(data.data);
                        setOrderData(data.data);

                        let sum = 0;
                        let sum2 = 0;
                        let sum3 = 0;
                        let sum4 = 0;
                        let sum5 = 0;
                        data.data.forEach((element) => {
                            const collected = Number.isFinite(element?.cashcollected) ? element?.cashcollected : 0;
                            const costs = Number.isFinite(element?.costValue) ? element?.costValue : 0;
                            const dicounts = Number.isFinite(element?.discount) ? element?.discount : 0;
                            const credits = Number.isFinite(element?.retailerCredit) ? element?.retailerCredit : 0;
                            sum += Number(element.salesValue);
                            sum2 += collected;
                            sum3 += costs;
                            sum4 += dicounts;
                            sum5 += credits;
                        });
                        setTotal(Number(sum).toFixed(2));
                        setcashTaken(Number(sum2).toFixed(2));
                        setcosttotal(Number(sum3).toFixed(2));
                        setdiscounttotal(Number(sum4).toFixed(2));
                        setretailerCredit(Number(sum5).toFixed(2));
                        const left = sum - sum2 - sum4 - sum5;
                        setcashLeft(Number(left).toFixed(2));
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        retrieveAllOrders();
        // retrieveAllOrdersTotal();
    }, []);

    const setChange = (event, picker) => {
        // console.log('startDate', picker.startDate._d);
        // console.log('endDate', picker.endDate._d);
        setstartDate(picker.startDate._d);
        setendDate(picker.endDate._d);
        const userInfo = localStorage.getItem('userToken');
        const from = picker.startDate._d;
        const to = picker.endDate._d;
        setLoading(true);
        const data = {
            from,
            to
        };
        fetch(ApiUrl.AllSalesPersonFilterOrdersUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);

                    setLoading(false);
                    setOrder(result.data);
                    setOrderData(result.data);

                    let sum = 0;
                    let sum2 = 0;
                    let sum3 = 0;
                    let sum4 = 0;
                    let sum5 = 0;
                    result.data.forEach((element) => {
                        const collected = Number.isFinite(element?.cashcollected) ? element?.cashcollected : 0;
                        const costs = Number.isFinite(element?.costValue) ? element?.costValue : 0;
                        const dicounts = Number.isFinite(element?.discount) ? element?.discount : 0;
                        const credits = Number.isFinite(element?.retailerCredit) ? element?.retailerCredit : 0;
                        sum += Number(element.salesValue);
                        sum2 += collected;
                        sum3 += costs;
                        sum4 += dicounts;
                        sum5 += credits;
                    });
                    setTotal(Number(sum).toFixed(2));
                    setcashTaken(Number(sum2).toFixed(2));
                    setcosttotal(Number(sum3).toFixed(2));
                    setdiscounttotal(Number(sum4).toFixed(2));
                    setretailerCredit(Number(sum5).toFixed(2));
                    const left = sum - sum2 - sum4 - sum5;
                    setcashLeft(Number(left).toFixed(2));
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    // const handleCallback = (start, end, label) => {
    //     console.log(start, end, label);
    // };

    const handledataexport = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(orderInfo);
        XLSX.utils.book_append_sheet(wb, ws, 'SalesPersonSales');
        XLSX.writeFile(wb, 'SalesPersonSales.xlsx');
    };

    const handleSearch = (event) => {
        const searchWord = event.target.value;

        const newFilter = orderInfo.filter((value) => (value.salesPerson || '').toLowerCase().includes((searchWord || '').toLowerCase()));

        if (searchWord === '') {
            setOrder(orderData);
        } else {
            setOrder(newFilter);
        }
    };

    return (
        <>
            <br />
            <PageHeader title="Sales Person Sales" subTitle="Home | Sales Person Receipt Generated Sales " />
            <br />

            <MainCard title="Sales Person Receipt Generated Sales (Today)">
                <Grid container spacing={2} justify="flex-start">
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Typography variant="h3" component="div" className={classes.colors}>
                            {costtotal} {userCurrency}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Typography variant="h3" component="div" className={classes.colors}>
                            {total} {userCurrency}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Typography variant="h3" component="div" className={classes.colors}>
                            {cashTaken} {userCurrency}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Typography variant="h3" component="div" className={classes.colors}>
                            {discounttotal} {userCurrency}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Typography variant="h3" component="div" className={classes.colors}>
                            {retailerCredit} {userCurrency}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Typography variant="h3" component="div" className={classes.colors}>
                            {cashleft} {userCurrency}
                        </Typography>
                    </Grid>
                </Grid>
                <br />

                <Grid container spacing={2} justify="flex-start">
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Typography variant="subtitle2" component="div">
                            Total cost value
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Typography variant="subtitle2" component="div">
                            Total sales value
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Typography variant="subtitle2" component="div">
                            Total cash collected
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Typography variant="subtitle2" component="div">
                            Discount
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Typography variant="subtitle2" component="div">
                            Credit
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Typography variant="subtitle2" component="div">
                            Total cash outstanding
                        </Typography>
                    </Grid>
                </Grid>
                <br />
                <br />

                <Box display="flex" justifyContent="flex-start">
                    <Controls.Input
                        label="Search Sales Person"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearch}
                    />
                    <Box width={10} />

                    <br />
                    <DateRangePicker initialSettings={{ startDate, endDate }} onApply={setChange}>
                        <Button variant="contained" size="sm" className={classes.item}>
                            filter by Date
                        </Button>
                    </DateRangePicker>

                    <Box width={10} />
                    <Button onClick={handledataexport} variant="contained" size="sm" className={classes.backgroundColor}>
                        Download Excel
                    </Button>
                </Box>

                <br />

                <TableContainer component={Paper}>
                    <Table aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Sales Associate
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Cost Value
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Sales Value
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Trnx Type
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Cash Collected
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Discount
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Credit
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Cash Outstanding
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Quantity
                                        </TableCell>

                                        {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell> */}
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 ? orderInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : orderInfo).map(
                                (row, index) => (
                                    <TableRow key={row._id}>
                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.salesPerson ?? ''}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {Number.isFinite(row?.costValue) ? Number(row?.costValue).toFixed(2) : 0}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {Number.isFinite(row?.salesValue) ? Number(row?.salesValue).toFixed(2) : 0}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.paymentType ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {Number.isFinite(row?.cashcollected) ? Number(row?.cashcollected).toFixed(2) : 0}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {Number.isFinite(row?.discount) ? Number(row?.discount).toFixed(2) : 0}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {Number.isFinite(row?.retailerCredit) ? Number(row?.retailerCredit).toFixed(2) : 0}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {Number.isFinite(row?.cashOutStanding)
                                                ? Number(row?.cashOutStanding).toFixed(2)
                                                : Number(row?.salesValue).toFixed(2)}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {Number.isFinite(row?.quantity) ? Number(row?.quantity).toFixed() : 0}
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 150, 250, { label: 'All', value: -1 }]}
                    component="div"
                    count={orderInfo.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default AllSalesPersonOrders;
