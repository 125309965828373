export const PRIRIOTY_BEGIN = 'PRIRIOTY_BEGIN';
export const PRIRIOTY_SUCCESS = 'PRIRIOTY_SUCCESS;';

export const BATCH_STAGING_SUCCESS = 'BATCH_STAGING_SUCCESS';

export const CREATE_PRIRIOTY_BEGIN = 'CREATE_PRIRIOTY_BEGIN';
export const CREATE_PRIRIOTY_SUCCESS = 'CREATE_PRIRIOTY_SUCCESS';
export const CREATE_PRIRIOTY_ERROR = 'CREATE_PRIRIOTY_ERROR';

export const SINGLE_PRIRIOTY_BEGIN = 'SINGLE_PRIRIOTY_BEGIN';
export const SINGLE_PRIRIOTY_SUCCESS = 'SINGLE_PRIRIOTY_SUCCESS';

export const UPDATE_PRIORITY_BEGIN = 'UPDATE_PRIORITY_BEGIN';
export const UPDATE_PRIORITY_SUCCESS = 'UPDATE_PRIORITY_SUCCESS';
export const UPDATE_PRIORITY_ERROR = 'UPDATE_PRIORITY_ERROR';

export const FILTER_PRIORITY_BEGIN = 'FILTER_PRIORITY_BEGIN';
export const FILTER_PRIORITY_SUCCESS = 'FILTER_PRIORITY_SUCCESS';
export const FILTER_PRIORITY_ERROR = 'FILTER_PRIORITY_ERROR';
