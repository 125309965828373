/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import logo from 'assets/images/garage.png';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import MainCard from 'ui-component/cards/MainCard';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import Stack from '@mui/material/Stack';
// import TextField from '@mui/material/TextField';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ApiUrl from 'network';
import useScriptRef from 'hooks/useScriptRef';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import EditIcon from '@mui/icons-material/Edit';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import Form from 'react-bootstrap/Form';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import nologo from 'assets/images/nologo.png';
import Compressor from 'compressorjs';
// import * as blobUtil from 'blob-util';

const Input = styled('input')({
    display: 'none'
});

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    }
}));

const AddDispatchImage2 = () => {
    const classes = useStyles();
    const history = useNavigate();
    const [imgFile, setImgFile] = useState(null);
    const [imageId, setimageId] = useState('');
    const [capturedUser, setcapturedUser] = useState('');
    const [capturedDate, setcapturedDate] = useState(new Date());

    const [loading3, setLoading3] = useState(false);
    const [fulOrderId, setfulOrderId] = useState('');

    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [dispatchImageData, setdispatchImageData] = useState([]);

    const [open, setOpen] = useState(false);
    const [indexValue, setIndexValue] = useState('');
    const [open2, setOpen2] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { id } = useParams();

    const scriptedRef = useScriptRef();

    const selectedFile = (e) => {
        const image = e.target.files[0];
        setLoading3(true);
        const userInfo = localStorage.getItem('userToken');

        // eslint-disable-next-line no-new
        new Compressor(image, {
            quality: 0.8,
            success: (compressedResult) => {
                console.log('compressedResult', compressedResult);
                const formData = new FormData();
                formData.append('file', compressedResult);
                axios
                    .post(ApiUrl.UploadIDCardPartsUrl, formData, {
                        headers: {
                            'auth-token': userInfo,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((result) => {
                        if (result.data.status === true) {
                            setLoading3(false);
                            setImgFile(result?.data?.data ?? '');
                            toast.success('success');
                        } else {
                            toast.error('failed');
                            setLoading3(false);
                        }
                    })
                    .catch((err) => {
                        toast.error('failed');
                        setLoading3(false);
                        console.log(err);
                    })
                    .finally(() => {
                        setLoading3(false);
                    });
            }
        });
    };

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.ViewOrdersUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setfulOrderId(result?.data?.fulOrderId ?? '');
                    setdispatchImageData(result?.data?.dispatchImageData ?? []);
                    setimageId(result?.data?._id);
                    const userFirstName = localStorage.getItem('userFirstName');
                    const userLastName = localStorage.getItem('userLastName');
                    const fullname = `${userFirstName}${userLastName}`;
                    setcapturedUser(fullname);
                    const date = new Date();
                    setcapturedDate(date);

                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                    // setLoading(false);
                }
            })
            .finally(() => {
                setLoading2(false);
            });
    }, [id]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    const onSubmitCollection = (event) => {
        event.preventDefault();
        // items, setItems
        setdispatchImageData([
            ...dispatchImageData,
            {
                imageId,
                capturedUser,
                capturedDate,
                imgFile
            }
        ]);

        handleClose();
    };

    const onSubmitEditCollection = (event) => {
        event.preventDefault();
        const newList = Object.assign([], dispatchImageData);
        const data = { ...newList[indexValue] };
        data.imageId = imageId;
        data.capturedUser = capturedUser;
        data.capturedDate = capturedDate;
        data.imgFile = imgFile;
        newList[indexValue] = data;
        setdispatchImageData(newList);
        handleClose2();
    };

    const handleDeleteClick = (row, index) => {
        const newList = Object.assign([], dispatchImageData);
        newList.splice(index, 1);
        setdispatchImageData(newList);
    };

    const handleEditClick = (row, index) => {
        // console.log('row', row);
        setimageId(row.imageId ?? '');
        setcapturedUser(row.capturedUser ?? '');
        setcapturedDate(row?.capturedDate ?? '');
        setImgFile(row?.imgFile ?? '');
        handleClickOpen2();
        setIndexValue(index);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // const useremail = localStorage.getItem('userEmail');

        const data = {
            imageId,
            dispatchImageData
        };
        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        fetch(ApiUrl.UpdateDispatchDataUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            // window.location.reload();
                            history('/ordertablestatus');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    {/* <AuthWrapper1> */}
                    <MainCard title="">
                        <Grid container justify="flex-start">
                            <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                            <Grid item xs={12}>
                                {/* <Grid container> */}
                                <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                                    {/* <AuthCardWrapper> */}
                                    <Grid container spacing={2}>
                                        {/* <Grid item sx={{ mb: 3 }} justifyContent="center">
                                            <Link to="#">
                                                <img src={logo} alt="logo" height={50} />
                                            </Link>
                                        </Grid> */}

                                        <Grid item xs={12}>
                                            <Typography variant="h3" component="h2" textAlign="center">
                                                Add Dispatch Image
                                            </Typography>
                                            <br />

                                            <Typography variant="h5" component="h2" textAlign="center">
                                                Use this form to upload dispatch image
                                            </Typography>

                                            <Typography variant="h3" component="h2" textAlign="center">
                                                for this order
                                            </Typography>

                                            <Typography variant="h3" component="h1" textAlign="center">
                                                {fulOrderId}{' '}
                                            </Typography>

                                            <br />
                                            <form onSubmit={handleSubmit}>
                                                <br />

                                                <Box display="flex" flexDirection="row-reverse">
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleClickOpen}
                                                        size="sm"
                                                        className={classes.item}
                                                    >
                                                        Add
                                                    </Button>
                                                </Box>
                                                <br />

                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <TableContainer component={Paper}>
                                                            <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                                            Date
                                                                        </TableCell>
                                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                                            User
                                                                        </TableCell>

                                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                                            Image
                                                                        </TableCell>

                                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                                            Action
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {dispatchImageData.map((row, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell component="th" scope="row">
                                                                                {toTimestamp(row?.capturedDate)}
                                                                            </TableCell>

                                                                            <TableCell component="th" scope="row">
                                                                                {row?.capturedUser ?? ''}
                                                                            </TableCell>

                                                                            <TableCell component="th" scope="row">
                                                                                <Avatar
                                                                                    variant="rounded"
                                                                                    src={row?.imgFile}
                                                                                    sx={{ width: 100, height: 100 }}
                                                                                />
                                                                            </TableCell>

                                                                            <TableCell component="th" scope="row">
                                                                                <IconButton
                                                                                    aria-label="edit"
                                                                                    color="primary"
                                                                                    onClick={() => handleEditClick(row, index)}
                                                                                >
                                                                                    <EditIcon />
                                                                                </IconButton>

                                                                                <IconButton
                                                                                    aria-label="delete"
                                                                                    color="error"
                                                                                    onClick={() => handleDeleteClick(row, index)}
                                                                                >
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                </Grid>

                                                <br />
                                                <br />
                                                <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                                    <Button type="submit" variant="contained" color="secondary">
                                                        {loading ? 'Saving...' : 'Save'}
                                                    </Button>
                                                    <Button
                                                        component={Link}
                                                        to="/ordertablestatus"
                                                        type="submit"
                                                        variant="contained"
                                                        color="error"
                                                    >
                                                        Reset
                                                    </Button>
                                                </Box>
                                            </form>
                                        </Grid>
                                    </Grid>
                                    {/* </AuthCardWrapper> */}
                                </Grid>
                                {/* </Grid> */}

                                <Dialog
                                    fullScreen={fullScreen}
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="responsive-dialog-title"
                                    fullWidth
                                >
                                    <div style={{ padding: '1rem 2rem' }}>
                                        <DialogTitle id="responsive-dialog-title" style={{ fontSize: '1rem' }}>
                                            Add Dispatch Image
                                        </DialogTitle>
                                        <Form onSubmit={onSubmitCollection}>
                                            <DialogContent>
                                                <Form.Group controlId="formFile" className="mb-3">
                                                    <InputLabel id="demo-simple-select-label">Attach Dispatch Image</InputLabel>
                                                    <label htmlFor="contained-button-file">
                                                        <Input
                                                            accept="image/*"
                                                            id="contained-button-file"
                                                            type="file"
                                                            onChange={selectedFile}
                                                        />
                                                        <Button variant="contained" component="span" disabled={!!loading3}>
                                                            {loading3 ? 'Uploading ...' : 'Upload'}
                                                        </Button>
                                                    </label>

                                                    <Avatar
                                                        variant="rounded"
                                                        src={imgFile ?? nologo}
                                                        style={{ border: 0, objectFit: 'cover' }}
                                                        sx={{ width: '100%', height: '400px' }}
                                                    />
                                                </Form.Group>
                                            </DialogContent>
                                            <DialogActions
                                                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    size="sm"
                                                    className={classes.item}
                                                    autoFocus
                                                    onClick={handleClose}
                                                    color="error"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button autoFocus variant="contained" type="submit" size="sm" className={classes.item}>
                                                    Add
                                                </Button>
                                            </DialogActions>
                                        </Form>
                                    </div>
                                </Dialog>

                                {/* Edit Collections */}
                                <Dialog
                                    fullScreen={fullScreen}
                                    open={open2}
                                    onClose={handleClose2}
                                    aria-labelledby="responsive-dialog-title"
                                    fullWidth
                                >
                                    <div style={{ padding: '1rem 2rem' }}>
                                        <DialogTitle id="responsive-dialog-title" style={{ fontSize: '1rem' }}>
                                            Edit Dispatch Image
                                        </DialogTitle>
                                        <Form onSubmit={onSubmitEditCollection}>
                                            <DialogContent>
                                                <Form.Group controlId="formFile" className="mb-3">
                                                    <InputLabel id="demo-simple-select-label">Attach Dispatch Image</InputLabel>
                                                    <label htmlFor="contained-button-file">
                                                        <Input
                                                            accept="image/*"
                                                            id="contained-button-file"
                                                            type="file"
                                                            onChange={selectedFile}
                                                        />
                                                        <Button variant="contained" component="span" disabled={!!loading3}>
                                                            {loading3 ? 'Uploading ...' : 'Upload'}
                                                        </Button>
                                                    </label>
                                                    <br />
                                                    <br />

                                                    <Avatar
                                                        variant="rounded"
                                                        src={imgFile ?? nologo}
                                                        style={{ border: 0, objectFit: 'cover' }}
                                                        sx={{ width: '100%', height: '400px' }}
                                                    />
                                                </Form.Group>
                                            </DialogContent>
                                            <DialogActions
                                                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    size="sm"
                                                    className={classes.item}
                                                    autoFocus
                                                    onClick={handleClose2}
                                                    color="error"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button autoFocus variant="contained" type="submit" size="sm" className={classes.item}>
                                                    Update
                                                </Button>
                                            </DialogActions>
                                        </Form>
                                    </div>
                                </Dialog>
                            </Grid>
                        </Grid>
                    </MainCard>
                    {/* </AuthWrapper1> */}
                </>
            )}
        </>
    );
};

export default AddDispatchImage2;
