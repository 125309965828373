import PropTypes from 'prop-types';
// import IconButton from '@mui/material/IconButton';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Divider, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Select } from 'antd';

// project imports
import LogoSection from '../LogoSection/index';
// import ChannelPartnerLogo from '../LogoSection/channelPartnerLogo';
// import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from '@tabler/icons';
import { height } from '@mui/system';
import { useEffect, useState } from 'react';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const channelPartnerId = localStorage.getItem('channelpartnerId');
    const photo = localStorage.getItem('photo');
    const userRole = localStorage.getItem('userRole');
    const country = localStorage.getItem('country');
    const [flag, setFlag] = useState('');
    const [countryName, setCountryName] = useState('');

    const retrieveCountry = async () => {
        try {
            fetch(`https://restcountries.com/v3.1/name/${country}`)
                .then((data) => data.json())
                .then((data) => {
                    setFlag(data[0]?.flags.png);
                    setCountryName(data[0]?.fifa);
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        retrieveCountry();
    }, []);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 235,
                    display: 'flex',

                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.primary.tangerine,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: '#fca544',
                                color: '#ECEDEF'
                            },
                            borderRadius: '16px 0px 0px 16px'
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <KeyboardArrowLeftIcon
                            sx={{
                                color: '#ECEDEF'
                            }}
                        />
                    </Avatar>
                </ButtonBase>
            </Box>
            <Divider sx={{ width: '1px', height: '88px !important' }} orientation="vertical" />

            {/* header search */}
            <Box sx={{ flexGrow: 1 }} />
            {/* Put cart icon here */}
            {channelPartnerId && userRole === 'Fleet' ? <Avatar variant="rounded" src={photo} sx={{ width: 86, height: 66 }} /> : ''}
            {channelPartnerId && userRole === 'Distributor' ? <Avatar variant="rounded" src={photo} sx={{ width: 86, height: 66 }} /> : ''}
            {channelPartnerId && userRole === 'Mechanic' ? <Avatar variant="rounded" src={photo} sx={{ width: 140, height: 66 }} /> : ''}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* notification & profile */}
            {userRole === 'Admin' && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#FFFFFF',
                        color: '#233E4C',
                        border: '1px solid #E7EAEB',
                        height: '3rem',
                        padding: '0px 15px',
                        borderRadius: '12px',
                        '&:hover': {
                            background: '#f9f9f9',
                            color: '#233E4C'
                        }
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '18px',
                            overflow: 'hidden',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        {/* {flag} */}
                        <img src={flag} alt="flag" style={{ width: '18px', height: 'auto' }} />
                    </Typography>
                    <Typography sx={{ margin: '0 0  0 4px' }}>{countryName}</Typography>
                </Box>
            )}
            <NotificationSection />
            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
