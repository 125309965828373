// import dashboard from './dashboard';
import juniormechanic from './JuniorMechanic';
import MechanicSettings from './mechanicSetting';

// ==============================|| MENU ITEMS dashboard ||============================== //

const juniormechanicItems = {
    items: [juniormechanic, MechanicSettings]
};

export default juniormechanicItems;
