import { useState, useEffect } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Header from './Header';
import PriorityCardList from './PriorityCardList';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button as MuiButton } from '@mui/material';
import 'bootstrap-daterangepicker/daterangepicker.css';
import AddPriorityModal from './AddPriorityModal';
import PriorityTable from './PriorityTable';
import { usePriorityContext } from 'context/order-priority/priority-context';
import OrderPriorityWrapper from 'assets/Wrappers/OrderPririotyWrapper';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const OrderPriority = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleEvent = (e, picker) => {
        setStartDate(picker.startDate._d.toISOString());
        setEndDate(picker.endDate._d.toISOString());
    };

    const { getAllPriorityOrders, todaypriority, handlePriorityFilter, isLoading } = usePriorityContext();

    useEffect(() => {
        getAllPriorityOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filterOptions = { startDate, endDate };

    const filterPriorityData = async () => {
        handlePriorityFilter(filterOptions);
    };

    const handlePriorityReset = () => {
        window.location = '/order-priority';
    };

    return (
        <OrderPriorityWrapper>
            <Header />
            <div className="filter">
                <div>
                    <Stack direction="row" spacing={1}>
                        <DateRangePicker
                            initialSettings={{ startDate, endDate }}
                            onEvent={handleEvent}
                            onApply={filterPriorityData}
                            onCancel={handlePriorityReset}
                        >
                            <LoadingButton
                                variant="contained"
                                disableElevation
                                size="medium"
                                className="custom-date-picker"
                                loadingIndicator="Loading…"
                                loading={isLoading}
                            >
                                Filter By Date
                            </LoadingButton>
                        </DateRangePicker>
                        <Button
                            variant="contained"
                            startIcon={<RefreshIcon />}
                            className="refresh-btn"
                            disabled={isLoading}
                            onClick={handlePriorityReset}
                        >
                            {isLoading ? 'Synchronizing...' : 'Refresh'}
                        </Button>
                    </Stack>
                </div>

                <div>
                    <MuiButton variant="contained" onClick={handleShow} className="add-priority">
                        Add priority
                    </MuiButton>
                </div>
            </div>
            <PriorityCardList />
            <PriorityTable
                th1="#"
                th2="Date"
                th3="Batch No"
                th4="Order No."
                th5="Size"
                th6="Order Type"
                th7="Distance(KM)/Time(Hr)"
                th8="Location"
                th9="Staging Area"
                th10="Priority"
                th11="Actions"
                rows={todaypriority}
            />
            <AddPriorityModal handleClose={handleClose} show={show} />
        </OrderPriorityWrapper>
    );
};
export default OrderPriority;
