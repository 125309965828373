import { useEffect } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';

const MyComponent = withScriptjs(
    withGoogleMap((props) => (
        <GoogleMap defaultZoom={10} defaultCenter={props.center}>
            {props.locations.map(({ color, lat, lng, name }, index) => (
                <Marker key={index} position={{ lat, lng }}>
                    {color ? (
                        <InfoWindow>
                            <div>{name}</div>
                        </InfoWindow>
                    ) : (
                        <div />
                    )}
                </Marker>
            ))}
        </GoogleMap>
    ))
);

function MechanicMap({ mapCenter, locations }) {
    // const [center, setCenter] = useState({});
    // const zoom = 11;
    // console.log(setCenter());
    const key = 'AIzaSyBZqHjOL-7tPYGJfRKkROaOMxlHfWkou_0';

    useEffect(() => {
        // console.log(locations);
        // setCenter(mapCenter);
    }, [locations]);

    return (
        <MyComponent
            center={mapCenter}
            locations={locations}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div className="partner-map" />}
            mapElement={<div style={{ height: `100%` }} />}
        />
    );
}

export default MechanicMap;
