/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import { notification } from 'antd';
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import useScriptRef from 'hooks/useScriptRef';

const AddPartZones = () => {
    const [loading, setLoading] = useState(false);
    const [salesAssociateData, setsalesAssociateData] = useState([]);
    const [partListData, setpartListData] = useState([]);
    const [salesperson, setsalesperson] = useState('');
    const [salesPersonId, setsalesPersonId] = useState('');
    const [partListName, setpartListName] = useState('');
    const [zone, setzone] = useState('');

    // const [status, setStatus] = useState('');
    const history = useNavigate();
    const scriptedRef = useScriptRef();

    const retrieveAllSalesAssociate = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllSalesPersonTargetUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setsalesAssociateData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllPartListNames = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllPartListNamesUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setpartListData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        // retrieve all sales associate;
        retrieveAllSalesAssociate();
        retrieveAllPartListNames();
    }, []);

    const handleSalesChange = (event) => {
        setsalesPersonId(event.target.value);
        const newFilter = salesAssociateData.filter((value) =>
            (value._id || '').toLowerCase().includes((event.target.value || '').toLowerCase())
        );
        newFilter.forEach((option) => {
            const fullname = `${option?.firstName}${option?.lastName}`;
            setsalesperson(fullname);
        });
    };

    const handlePartListChange = (event) => {
        setpartListName(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const userInfo = localStorage.getItem('userToken');

        const data = {
            salesPersonId,
            salesperson,
            zone,
            partListName
        };

        fetch(ApiUrl.AddPartZonesUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                Accept: 'application/form-data',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    if (scriptedRef.current) {
                        setTimeout(() => {
                            history('/partzones');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                    console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <br />
                    <PageHeader title="Configuration | Part Zones" subTitle="Home | Configuration | Part Zones| Add Part Zone" />
                    <br />
                    <MainCard title="Add Part Zone">
                        <form onSubmit={handleSubmit}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Relation Associate</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={salesPersonId}
                                    required
                                    label="Sales Associate"
                                    onChange={handleSalesChange}
                                >
                                    {salesAssociateData.map((sales) => (
                                        <MenuItem key={sales._id} value={sales._id}>
                                            {sales.firstName} {sales.lastName} ({sales.country})
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <br />
                            <br />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Part List Names</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={partListName}
                                    required
                                    label="Part List Name"
                                    onChange={handlePartListChange}
                                >
                                    {partListData.map((sales) => (
                                        <MenuItem key={sales._id} value={sales._id}>
                                            {sales.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <br />
                            <br />
                            <TextField
                                autoComplete="off"
                                name="zone"
                                variant="outlined"
                                required
                                fullWidth
                                id="zone"
                                value={zone}
                                label="Zone"
                                onChange={(e) => setzone(e.target.value)}
                            />
                            <br />
                            <br />

                            <Box display="flex" justifyContent="space-between">
                                <Button component={Link} to="/partzones" type="submit" variant="contained" color="error">
                                    Reset
                                </Button>

                                <Button type="submit" variant="contained" color="secondary" disabled={!!loading}>
                                    {loading ? 'Submitting ..' : 'Submit'}
                                </Button>
                            </Box>
                        </form>
                    </MainCard>
                </Grid>
            </Grid>
        </>
    );
};

export default AddPartZones;
