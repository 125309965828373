import React, { useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader';
import MainCard from 'ui-component/cards/MainCard';
import Box from '@mui/material/Box';
import Controls from '../../components/controls/Controls';
import { Search } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
// import TablePagination from '@mui/material/TablePagination';
// import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
// import ApiUrl from 'network';

// const { BaseUrl } = ApiUrl;

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

function LotDetails() {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const handleSearch = (e) => {
        console.log(e.target.value);
    };

    useEffect(() => {}, []);
    console.log(setIsLoading());

    const tHead = ['Channel', 'Order Value', 'Entry Point', 'Destination', 'Part', 'QTY', 'Actions'];
    const tTHead = tHead.map((item, index) => (
        <React.Fragment key={index}>
            <TableCell align="left" className={classes.tableHeaderCell}>
                {item}
            </TableCell>
        </React.Fragment>
    ));

    return (
        <>
            <PageHeader title="Lot Details" subTitle="Lot ID" />

            <MainCard title="Lot Details List">
                <Box display="flex" justifyContent="space-between">
                    <Controls.Input
                        label="Search Lot Id"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                    <Box width={10} />
                    <Button component={Link} to="/addlotdetails" variant="contained" size="sm" className={classes.item}>
                        New
                    </Button>
                </Box>
                <br />

                {isLoading ? (
                    <div className="flex-column justify-content-center align-items-center">
                        <CircularProgress />
                    </div>
                ) : (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 450 }} aria-label="caption table">
                            <TableHead>
                                <TableRow>{tTHead}</TableRow>
                            </TableHead>
                            <TableBody />
                        </Table>
                    </TableContainer>
                )}
            </MainCard>
        </>
    );
}

export default LotDetails;
