/* eslint-disable no-underscore-dangle */
import ApiUrl from '../../network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
// import MainCard from 'ui-component/cards/MainCard';
// import PageHeader from '../../components/PageHeader';
// import Box from '@mui/material/Box';
// import Controls from '../../components/controls/Controls';
// import { Search } from '@mui/icons-material';
// import InputAdornment from '@mui/material/InputAdornment';
// import Button from '@mui/material/Button';
// import swal from 'sweetalert';
import * as XLSX from 'xlsx';
// import Form from 'react-bootstrap/Form';
// import Typography from '@mui/material/Typography';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import ViewIcon from '@mui/icons-material/ViewAgenda';
// import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import CircularProgress from '@mui/material/CircularProgress';
// import TablePagination from '@mui/material/TablePagination';
// import Tooltip from '@mui/material/Tooltip';
// import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
// import { saveAs } from 'file-saver';
import axios from 'axios';
// import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import Grid from '@mui/material/Grid';
// import Avatar from '@mui/material/Avatar';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
// import { Col } from 'react-grid-system';
// import TextField from '@mui/material/TextField';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
// import NoteAltIcon from '@mui/icons-material/NoteAlt';
// import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
// import SortIcon from '@mui/icons-material/Sort';
// import * as blobUtil from 'blob-util';
// import { Camera } from 'react-camera-pro';
// import { useTheme } from '@mui/material/styles';
import { cryptoUUID } from '../../utils/functions';
import PaymentModal from 'components/PaymentModal/PaymentModal';
// import Stack from '@mui/material/Stack';
// // import nologo from 'assets/images/nologo.png';
// import Popover from '@mui/material/Popover';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import Menu from '@mui/material/Menu';
import OrderTable from 'components/OrderTable/OrderTable';
import { useAppContext } from '../../context/appContext';
import { encrypt, decrypt } from '../../utils/encrypt';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    backgroundColor: {
        backgroundColor: '#2CB23B'
    },
    backgroundColor2: {
        backgroundColor2: '#CE1212'
    },
    color: {
        color: '#FF740F'
    }
}));
// const Input = styled('input')({
//     display: 'none'
// });

const Order = () => {
    // const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [orderInfo, setOrder] = useState([]);
    const [mainOrder, setMainOrder] = useState([]);
    const [mainOrderStatus, setMainOrderStatus] = useState([]);
    const [mainPaymentStatus, setMainPaymentStatus] = useState([]);
    const [mainCustomer, setMainCustomer] = useState([]);
    const [mainCity, setMainCity] = useState([]);
    const [mainRma, setMainRma] = useState([]);
    const [mainOrderNo, setMainOrderNo] = useState('');
    const [limit, setLimit] = useState(10);
    const [loading, setLoading] = useState(false);
    // const theme = useTheme();
    // const [loading2, setLoading2] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(150);
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [orderStatus, setOrderStatus] = useState([]);
    const [orderStatusId, setorderStatusId] = useState('');
    const [salespersonInfo, setSalesTeam] = useState([]);
    const [filtersalesperson, setfiltersalesperson] = useState('');
    const [searched, setSearched] = useState('');
    const [orderNos, setSearchedOrderNo] = useState('');
    const [isPaymentOpen, setIsPaymentOpen] = useState(false);
    const [updatePaymentSuccess, setUpdatePaymentSuccess] = useState(false);
    const [updateApprovalSuccess, setUpdatApprovalSuccess] = useState(false);
    const [getPaymentId, setGetPaymentId] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [countries, setCountries] = useState([]);
    const [accumulatedFilters, setAccumulatedFilters] = useState({});
    const [totalNumberOfOrders, setTotalNumberOfOrders] = useState('');
    const [numberOfPages, setNumberOfOrders] = useState(1);
    const [collections, setCollections] = useState([]);
    const [confirmedList, setConfirmedList] = useState([]);
    const [totalcollections, setTotalcollections] = useState(0);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 25,
            total: 0
        }
    });
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    // const [numberOfCameras, setNumberOfCameras] = useState(0);
    // const camera = useRef(null);
    // console.log(orderData);
    const country = localStorage.getItem('country');

    const limitNumber = 100;

    // payment modal
    const showPaymentModal = () => {
        setIsPaymentOpen(true);
    };

    const handlePaymentOk = () => {
        setIsPaymentOpen(false);
    };

    const handlePaymentCancel = () => {
        setIsPaymentOpen(false);
    };

    const handleMoMoPayClick = (row) => {
        if (scriptedRef.current) {
            const ids = cryptoUUID();

            // console.log(row);
            history(`/payorder/${row._id}/${ids}`);
        }
    };

    const handleEditClick = (row) => {
        if (scriptedRef.current) {
            history(`/viewOrder/${row._id}/${row?.userId}`);
        }
    };

    const handleShowClick = (row) => {
        if (scriptedRef.current) {
            // console.log(row);
            history(`/showOrder/${row._id}`);
        }
    };

    const handleAuditLogClick = (row) => {
        if (scriptedRef.current) {
            // console.log(row);
            history(`/auditlogs/${row._id}`);
        }
    };

    const handlePdfClick = (row) => {
        // console.log('row', row);
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };
        // setLoading2(true);
        axios.post(ApiUrl.DownloadPdfOrdersUrl, row, config).then((res) => {
            window.open(res.data.data, '_blank');

            // setLoading2(false);
        });
    };

    const handleManualLoyaltyDiscount = (row) => {
        // console.log('row', row);
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };
        // setLoading2(true);
        // axios.post(ApiUrl.DownloadManuelLoyaltyDiscountOrdersUrl, row, config).then((res) => {
        //     console.log('res', res);
        //     if (res.status === true) {
        //         toast.success(res.message);
        //     } else {
        //         toast.error(res.message);
        //     }
        //     // window.open(res.data.data, '_blank');
        //     // setLoading2(false);
        // });

        fetch(ApiUrl.DownloadManuelLoyaltyDiscountOrdersUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                Accept: 'application/form-data',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(row)
        })
            .then((res) => res.json())
            .then((result) => {
                console.log('result', result);
                if (result.status === true) {
                    toast.success(result.message);
                } else {
                    toast.error(result.message);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleVatClick = (row) => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };
        axios.post(ApiUrl.VatReceiptUrl, row, config).then((res) => {
            window.open(res.data.data, '_blank');

            // setLoading2(false);
        });
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 30));
        setPage(0);
    };

    const handlePackSortClick = (row) => {
        if (scriptedRef.current) {
            // console.log(row);
            history(`/addpacksortimage/${row._id}`);
        }
    };

    const handleDispatchImageClick = (row) => {
        if (scriptedRef.current) {
            // console.log(row);
            history(`/adddispatchimage/${row._id}`);
        }
    };

    const handleDeliveryNoteClick = (row) => {
        if (scriptedRef.current) {
            // console.log(row);
            history(`/adddeliveryimage/${row._id}`);
        }
    };

    const handleAddTagClick = (row) => {
        if (scriptedRef.current) {
            history(`/addordertag/${row._id}`);
        }
    };

    function toTimestamp(strDate) {
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    const limitOptions = [
        {
            label: '100',
            value: limitNumber * 1
        },
        {
            label: '200',
            value: limitNumber * 2
        },
        {
            label: '300',
            value: limitNumber * 3
        },
        {
            label: '400',
            value: limitNumber * 4
        },
        {
            label: '500',
            value: limitNumber * 5
        },
        {
            label: '600',
            value: limitNumber * 6
        },
        {
            label: '700',
            value: limitNumber * 7
        },
        {
            label: '800',
            value: limitNumber * 8
        },
        {
            label: '900',
            value: limitNumber * 9
        },
        {
            label: '1000',
            value: limitNumber * 10
        }
    ];

    // get query data
    const getQueryData = async () => {
        try {
            setLoading(true);
            const url = `${ApiUrl.BaseUrl}/mainorders/querydata?country=${accumulatedFilters?.country || country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setCountries(answer.country);
                        setMainOrderStatus(answer.orderStatus);
                        setMainPaymentStatus(answer.paymentStatus);
                        setMainCustomer(answer.customer);
                        setMainCity(answer.city);
                        setMainRma(answer.relationshipManagers);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const countryOptions = countries?.map((item) => {
        return { label: item?.name, value: item?.name };
    });

    const orderStatusOptions = mainOrderStatus?.map((item) => {
        return { label: item?.name, value: item?.name };
    });

    const paymentStatusOptions = mainPaymentStatus?.map((item) => {
        return { label: item?.name, value: item?._id };
    });

    const customerOptions = mainCustomer?.map((item) => {
        return { label: item?.partnerName, value: item?._id };
    });

    const cityOptions = mainCity?.map((item) => {
        return { label: item?.city, value: item?._id };
    });

    const rmaOptions = mainRma?.map((item) => {
        return { label: item?.name, value: item?._id };
    });

    // get all orders
    const getMainOrders = async () => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/mainorders/mainorders`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            // Append accumulated filters to URL
            Object.entries(accumulatedFilters).forEach(([key, value]) => {
                if (value) {
                    urlParams.append(key, value);
                }
            });

            url = `${url}?${urlParams.toString()}`;

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setNumberOfOrders(data.numOfPages);
                        setMainOrder(answer);
                        setTotalNumberOfOrders(data.totalOrders);
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                total: data.totalOrders,
                                current: accumulatedFilters?.page || 1
                            }
                        });
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // get all collections
    const getCollections = async () => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/collection/financecollection`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            // Append accumulated filters to URL
            Object.entries(accumulatedFilters).forEach(([key, value]) => {
                if (value) {
                    urlParams.append(key, value);
                }
            });

            url = `${url}?${urlParams.toString()}`;

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setCollections(answer);
                        setTotalcollections(data.totalcollection);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // get all confirmed list
    const getConfirmedList = async () => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/collection/approvecollectionlist`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            // Append accumulated filters to URL
            Object.entries(accumulatedFilters).forEach(([key, value]) => {
                if (value) {
                    urlParams.append(key, value);
                }
            });

            url = `${url}?${urlParams.toString()}`;

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setConfirmedList(answer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getConfirmedList();
        getCollections();
        getMainOrders();
        getQueryData();
    }, [accumulatedFilters, updatePaymentSuccess, updateApprovalSuccess]);

    // filter date
    const filterDate = (value, dateString) => {
        setSelectedDate(value);
        const data = {
            startDate: dateString[0],
            endDate: dateString[1]
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter country
    const filterCountry = (value) => {
        const data = {
            country: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter Order Status
    const filterOrderStatus = (value) => {
        const data = {
            orderstatus: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter Order Status
    const filterPaymentStatus = (value) => {
        const data = {
            paymentstatus: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter Customer
    const filterCustomer = (value) => {
        const data = {
            customer: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter City
    const filterCity = (value) => {
        const data = {
            city: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter RMA
    const filterRmaPerson = (value) => {
        const data = {
            rmcc: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter by order No
    const filterOrderNo = (value) => {
        const data = {
            orderNo: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    const handleLimit = (value) => {
        setLimit(value);
        const data = {
            limit: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    const handlePage = (page) => {
        const data = {
            page: page.current,
            limit: page.pageSize
        };
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.page
            }
        });
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderInfo.length) : 0;

    const retrieveAllOrders = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllOrdersAdminUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        // console.log('data', data.data);
                        setOrder(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllActiveOrderStatus = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllActiveOrderStatus, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrderStatus(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handledateChange = (event, picker) => {
        setstartDate(picker.startDate._d);
        setendDate(picker.endDate._d);

        console.log(picker);

        const userInfo = localStorage.getItem('userToken');
        const from = picker.startDate._d;
        const to = picker.endDate._d;
        setLoading(true);
        const data = {
            from,
            to,
            orderStatusId,
            filtersalesperson
        };
        localStorage.setItem('GeneralOrder', JSON.stringify(data));
        fetch(ApiUrl.FilterOrderDateAdminUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);

                    setLoading(false);
                    setOrder(result.data);
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleOrderStatus = (event) => {
        setorderStatusId(event.target.value);

        const userInfo = localStorage.getItem('userToken');
        const orderStatusId = event.target.value;

        setLoading(true);
        const data = {
            startDate,
            endDate,
            orderStatusId,
            filtersalesperson
        };
        localStorage.setItem('GeneralOrder', JSON.stringify(data));
        fetch(ApiUrl.FilterOrderByOrderStatusUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);

                    setLoading(false);
                    setOrder(result.data);
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handledateChangeNew = () => {
        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        const order = localStorage.getItem('GeneralOrder');
        const data = JSON.parse(order);
        fetch(ApiUrl.FilterOrderDateAdminUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);

                    setLoading(false);
                    setOrder(result.data);
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleFilterSalesPersonChange = (e) => {
        setfiltersalesperson(e.target.value);
        const userInfo = localStorage.getItem('userToken');
        const filtersalesperson = e.target.value;

        setLoading(true);
        const data = {
            startDate,
            endDate,
            orderStatusId,
            filtersalesperson
        };
        localStorage.setItem('GeneralOrder', JSON.stringify(data));
        fetch(ApiUrl.FilterOrderByRMUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);

                    setLoading(false);
                    setOrder(result.data);
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const retrieveAlLsalesTeam = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.SalesTeamActiveSalesPage, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setSalesTeam(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const order = localStorage.getItem('GeneralOrder');
        const datas = JSON.parse(order);

        if (datas?.from && datas?.to) {
            handledateChangeNew();
            retrieveAllActiveOrderStatus();
            retrieveAlLsalesTeam();
            // console.log('run date here');
        } else {
            // console.log('run else here');
            retrieveAllOrders();
            retrieveAllActiveOrderStatus();
            retrieveAlLsalesTeam();
        }
    }, [updatePaymentSuccess]);

    const handledataexport = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(orderInfo);
        XLSX.utils.book_append_sheet(wb, ws, 'Orders');
        XLSX.writeFile(wb, 'Orders.xlsx');
    };

    const handledataClear = () => {
        localStorage.removeItem('GeneralOrder');
        retrieveAllOrders();
        retrieveAllActiveOrderStatus();
        retrieveAlLsalesTeam();
        setfiltersalesperson('');
        setorderStatusId('');
    };

    const filteredRows = orderInfo.filter((row) => {
        return (
            (row?.customerNameId || '').toLowerCase().includes(searched.toLowerCase()) &&
            (row?.fulOrderId || '').toLowerCase().includes(orderNos.toLowerCase())
        );
    });

    // orderInfo.filter((row) => (row?.customerNameId || '').toLowerCase().includes((searched || '').toLowerCase()));
    const isPlural = filteredRows && filteredRows.length > 1 ? 's' : '';
    const searchCount = searched
        ? `${filteredRows.length} result${isPlural} found`
        : // eslint-disable-next-line no-constant-condition
        'No Results' || orderNos
        ? `${filteredRows.length} result${isPlural} found`
        : 'No Results';

    const dateConverter = (value) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };

        return new Date(value).toLocaleDateString('en-US', options);
    };

    // 2 decimal places
    const twoDecimalPlaces = (value) => {
        return parseFloat(value?.toFixed(2)).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    };

    // download order list
    const { handleExcelDataExport } = useAppContext();
    const downloadExcel = () => {
        const oderDetails = mainOrder?.map((result) => ({
            orderNo: result?.fulOrderId,
            orderType: result?.orderType === 'Bulk' ? 'Regular' : result?.orderType,
            orderDate: dateConverter(result?.orderDate),
            customerName: result?.customerName?.partnerName,
            // salesPerson: result?.salesTeamId,
            placedby: result?.placeBy,
            costValue: twoDecimalPlaces(result?.costValue),
            salesvalue: twoDecimalPlaces(result?.salesValue),
            cashCollected: twoDecimalPlaces(result?.actCashCollected),
            discount: twoDecimalPlaces(result?.discount),
            credit: result?.retailerCredit ? twoDecimalPlaces(result?.retailerCredit) : 0,
            cashBalance: result?.cashOutStanding ? twoDecimalPlaces(result?.cashOutStanding) : twoDecimalPlaces(result?.salesValue),
            orderStatus:
                result?.orderStatus === 'Delivered'
                    ? result?.orderStatus ?? ''
                    : result?.orderStatus === 'Cancelled'
                    ? result?.orderStatus ?? ''
                    : result?.orderStatus === 'Received'
                    ? result?.orderStatus ?? ''
                    : result?.orderStatus === 'Dispatched'
                    ? result?.orderStatus ?? ''
                    : result?.orderStatus === 'Processing'
                    ? result?.orderStatus ?? ''
                    : result?.orderStatus === 'ProformaInvoice'
                    ? result?.orderStatus ?? ''
                    : result?.orderStatus === 'SalesDrive'
                    ? result?.orderStatus ?? ''
                    : result?.orderStatus === 'OnHold'
                    ? result?.orderStatus ?? ''
                    : result?.orderStatus === 'NextDay'
                    ? result?.orderStatus ?? ''
                    : result?.orderStatus === 'PreSold'
                    ? result?.orderStatus ?? ''
                    : '',

            receiptStatus:
                result?.receiptStatus?._id === '6228632de5b218f4be037d70'
                    ? result?.receiptStatus?.name ?? ''
                    : result?.receiptStatus?._id === '622b9bf0f3bcbc9eb0c203ea'
                    ? result?.receiptStatus?.name ?? ''
                    : result?.receiptStatus?._id === '620d76d63a9c8148935c696b'
                    ? result?.receiptStatus?.name ?? ''
                    : result?.receiptStatus?._id === '625311abbd74072935e58592'
                    ? result?.receiptStatus?.name ?? ''
                    : result?.receiptStatus?._id === '625739679a0af3ae9771daef'
                    ? result?.receiptStatus?.name ?? ''
                    : result?.receiptStatus?._id === '622b9bcdf3bcbc9eb0c203e2'
                    ? result?.receiptStatus?.name ?? ''
                    : result?.receiptStatus?._id === '6334bab7afa1158e678a87ad'
                    ? result?.receiptStatus?.name ?? ''
                    : result?.receiptStatus?._id === '6342dc27f627d58acac98e0f'
                    ? result?.receiptStatus?.name ?? ''
                    : result?.receiptStatus?._id === '636c1abb26ac75c9880bddd6'
                    ? result?.receiptStatus?.name ?? ''
                    : result?.receiptStatus?._id === '636c1ab126ac75c9880bddd2'
                    ? result?.receiptStatus?.name ?? ''
                    : result?.receiptStatus?._id === '6466771d8328e2d254fa6ac3'
                    ? result?.receiptStatus?.name ?? ''
                    : result?.receiptStatus?._id === '651da6eb9d048db5123bc205'
                    ? result?.receiptStatus?.name ?? ''
                    : result?.receiptStatus?._id === '6527fe1dba6c46cb61943f5c'
                    ? result?.receiptStatus?.name ?? ''
                    : '',
            pmtStatus:
                result?.paymentStatus?._id === '620d76a4ee512a471bf98c3e'
                    ? result?.paymentStatus?.name ?? ''
                    : result?.paymentStatus?._id === '620d7693448d1be3b4ab1b08'
                    ? result?.paymentStatus?.name ?? ''
                    : result?.paymentStatus?._id === '6542467c04e82ed78cd79ec6'
                    ? result?.paymentStatus?.name ?? ''
                    : ''
        }));

        const orderFile = oderDetails.map((obj) => {
            return Object.fromEntries(Object.entries(obj).filter(([key]) => key !== 'key' && key !== 'value'));
        });

        if (orderFile?.length === 0) return;

        handleExcelDataExport(orderFile, 'Order');
    };

    // searched ? `${filteredRows.length} result${isPlural} found` : 'No Results';

    // orderNos

    // const handleDeleteClick = (row) => {
    //     swal({
    //         title: 'Are you sure?',
    //         text: `Once deleted, you will not be able to recover this Order !(${row._id})`,
    //         icon: 'warning',
    //         buttons: true,
    //         dangerMode: true
    //     }).then((willDelete) => {
    //         if (willDelete) {
    //             swal('Poof! The Order has been deleted!', {
    //                 icon: 'success'
    //             });

    //             const data = {
    //                 id: row._id
    //             };
    //             const userInfo = localStorage.getItem('userToken');

    //             fetch(ApiUrl.DeleteSalesOrderRequest, {
    //                 method: 'DELETE',
    //                 headers: {
    //                     'auth-token': userInfo,
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify(data)
    //             })
    //                 .then((res) => res.json())
    //                 .then((result) => {
    //                     if (result.status === true) {
    //                         if (scriptedRef.current) {
    //                             retrieveAllOrders();
    //                             // window.location.href = '/stage';
    //                             // history('/stage');
    //                         }
    //                     } else {
    //                         toast.error(result.data);
    //                     }
    //                 });
    //         } else {
    //             swal('The  Order is safe!');
    //         }
    //     });
    // };

    return (
        <>
            {/* <br />
            <PageHeader title="Orders" subTitle="Home | Orders | Order " />
            <br /> */}

            {/* <MainCard title="Order List"> */}
            {/* <h4 style={{ color: '#ff740f', fontWeight: '700' }}>
                    {searched
                        ? searchCount
                        : `Total Orders : ${orderInfo.length}` || orderNos
                        ? searchCount
                        : `Total Orders : ${orderInfo.length}`}
                </h4> */}
            <h4
                style={{
                    color: '#0A2938',
                    fontWeight: '700',
                    fontSize: '24px',
                    borderBottom: '1px dashed #E7EAEB',
                    padding: '32px 0 24px 0'
                }}
            >{`Total orders: ${totalNumberOfOrders}`}</h4>
            <br />
            {/* 
                <Box display="flex" justifyContent="flex-start">
                    <Box width={180}>
                        <FormControl fullWidth>
                            <TextField
                                id="outlined-basic"
                                label="Search Partner Name"
                                variant="outlined"
                                onChange={(e) => setSearched(e.target.value)}
                            />
                        </FormControl>
                    </Box>
                    <Box width={20} />

                    <Box width={180}>
                        <FormControl fullWidth>
                            <TextField
                                id="outlined-basic"
                                label="Search OrderNo#"
                                variant="outlined"
                                onChange={(e) => setSearchedOrderNo(e.target.value)}
                            />
                        </FormControl>
                    </Box>
                </Box>
                <br /> */}
            {/* 
                <Box display="flex" justifyContent="flex-start">
                    <br />

                    <Box width={180}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Filter By Order Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="orderStatusId"
                                value={orderStatusId}
                                label="Order Status"
                                onChange={handleOrderStatus}
                            >
                                <MenuItem value="All">All</MenuItem>
                                {orderStatus.map((order) => (
                                    <MenuItem key={order._id} value={order._id}>
                                        {order.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box width={20} />
                    <Box width={180}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Filter By RM</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={filtersalesperson}
                                label="Status"
                                onChange={handleFilterSalesPersonChange}
                            >
                                <MenuItem value="All">All</MenuItem>
                                {salespersonInfo.map((sales) => (
                                    <MenuItem key={sales._id} value={sales._id}>
                                        {sales?.name}
                                        {sales?.firstName}
                                        {sales?.lastName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box> */}
            {/* <Box width={20} />
                <br />
                <Box display="flex" justifyContent="flex-start"> */}
            {/* <DateRangePicker initialSettings={{ startDate, endDate }} onApply={handledateChange}>
                        <Button variant="contained" size="sm" className={classes.item}>
                            filter by Date
                        </Button>
                    </DateRangePicker>

                    <Box width={20} />
                    <Button component={Link} to="/addOrder" variant="contained" size="sm" className={classes.item}>
                        New
                    </Button>

                    <Box width={10} /> */}
            {/* <Button onClick={handledataexport} variant="contained" size="sm" className={classes.backgroundColor}>
                        Download Excel
                    </Button> */}
            {/* <Button onClick={downloadExcel} variant="contained" size="sm" className={classes.backgroundColor}>
                        Download Excel
                    </Button>
                    <Box width={10} />
                    <Button onClick={handledataClear} variant="contained" size="sm" color="error">
                        Clear Filter
                    </Button> */}
            {/* </Box>
                <br /> */}

            <OrderTable
                mainOrder={mainOrder}
                loading={loading}
                handleEditClick={handleEditClick}
                salespersonInfo={salespersonInfo}
                filtersalesperson={filtersalesperson}
                handleFilterSalesPersonChange={handleFilterSalesPersonChange}
                orderStatus={orderStatus}
                orderStatusId={orderStatusId}
                setSearchedOrderNo={setSearchedOrderNo}
                setSearched={setSearched}
                filteredRows={filteredRows}
                handlePackSortClick={handlePackSortClick}
                handleDeliveryNoteClick={handleDeliveryNoteClick}
                handleAuditLogClick={handleAuditLogClick}
                showPaymentModal={showPaymentModal}
                handlePdfClick={handlePdfClick}
                handleManualLoyaltyDiscount={handleManualLoyaltyDiscount}
                setGetPaymentId={setGetPaymentId}
                showModal={showPaymentModal}
                handledateChange={handledateChange}
                handleVatClick={handleVatClick}
                handleShowClick={handleShowClick}
                handleDispatchImageClick={handleDispatchImageClick}
                useStyles={useStyles}
                filterOrderStatus={filterOrderStatus}
                filterCustomer={filterCustomer}
                selectedDate={selectedDate}
                filterDate={filterDate}
                countryOptions={countryOptions}
                filterCountry={filterCountry}
                orderStatusOptions={orderStatusOptions}
                paymentStatusOptions={paymentStatusOptions}
                filterPaymentStatus={filterPaymentStatus}
                customerOptions={customerOptions}
                cityOptions={cityOptions}
                filterCity={filterCity}
                rmaOptions={rmaOptions}
                filterRmaPerson={filterRmaPerson}
                setMainOrderNo={setMainOrderNo}
                filterOrderNo={filterOrderNo}
                downloadExcel={downloadExcel}
                updatePaymentSuccess={updatePaymentSuccess}
                handleLimit={handleLimit}
                limitOptions={limitOptions}
                limit={limit}
                totalcollections={totalcollections}
                collections={collections}
                updateApprovalSuccess={updateApprovalSuccess}
                setUpdatApprovalSuccess={setUpdatApprovalSuccess}
                setCollections={setCollections}
                setTotalcollections={setTotalcollections}
                handlePage={handlePage}
                tableParams={tableParams}
            />
            <br />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 450 }} aria-label="caption table">
                    {/* <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {userCountry === 'Ghana' ? '#' : 'Order No.#'}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order Type
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order Date
                                        </TableCell> */}
                    {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Channel
                                        </TableCell> */}

                    {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Customer Name
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Sales Person
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Placed By
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Cost Value
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Sales Value
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Cash Collected
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Discount
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Credit
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Cash Balance
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order Status
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Receipt Status
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Pmt Status
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead> */}
                    {/* <TableBody>
                            {(rowsPerPage > 0
                                ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : filteredRows
                            ).map((row, index) => (
                                <TableRow key={row._id}>
                                    <TableCell component="th" scope="row">
                                        {userCountry === 'Ghana' ? index + 1 : row?.fulOrderId}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.orderType === 'Bulk' ? 'Regular' : row?.orderType}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {toTimestamp(row?.orderDate)}
                                    </TableCell> */}
                    {/* <TableCell component="th" scope="row">
                                            {row?.newChannelId?.name ?? ''}
                                        </TableCell> */}

                    {/* <TableCell component="th" scope="row">
                                        {row?.customerName?.partnerName ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.salesTeamId ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.placeBy ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {Number(row?.costValue).toFixed(2)}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {Number(row?.salesValue).toFixed(2)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {Number.isFinite(row?.actCashCollected) ? Number(row?.actCashCollected).toFixed(2) : 0}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {Number.isFinite(row?.discount) ? Number(row?.discount).toFixed(2) : 0}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {Number.isFinite(row?.retailerCredit) ? Number(row?.retailerCredit).toFixed(2) : 0}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {Number.isFinite(row?.cashOutStanding)
                                            ? Number(row?.cashOutStanding).toFixed(2)
                                            : Number(row?.salesValue).toFixed(2)}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.orderStatus === 'Delivered' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.success.light,
                                                    color: theme.palette.success.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row?.orderStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row?.orderStatus === 'Cancelled' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.error.light,
                                                    color: theme.palette.error.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <DangerousRoundedIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.orderStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row?.orderStatus === 'Received' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.primary.light,
                                                    color: theme.palette.primary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.orderStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row?.orderStatus === 'Dispatched' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.warning.light,
                                                    color: theme.palette.warning.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.orderStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row?.orderStatus === 'Processing' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.warning.light,
                                                    color: theme.palette.warning.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row?.orderStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row?.orderStatus === 'ProformaInvoice' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.orderStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row?.orderStatus === 'SalesDrive' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.orderStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row?.orderStatus === 'OnHold' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.orderStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row?.orderStatus === 'NextDay' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.orderStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row?.orderStatus === 'PreSold' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.orderStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row.receiptStatus._id === '6228632de5b218f4be037d70' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.success.light,
                                                    color: theme.palette.success.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row.receiptStatus?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row.receiptStatus._id === '622b9bf0f3bcbc9eb0c203ea' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.error.light,
                                                    color: theme.palette.error.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <DangerousRoundedIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row.receiptStatus?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row.receiptStatus._id === '620d76d63a9c8148935c696b' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.primary.light,
                                                    color: theme.palette.primary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row.receiptStatus?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row.receiptStatus._id === '625311abbd74072935e58592' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row.receiptStatus?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row.receiptStatus._id === '625739679a0af3ae9771daef' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.error.light,
                                                    color: theme.palette.error.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <DangerousRoundedIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row.receiptStatus?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row.receiptStatus._id === '622b9bcdf3bcbc9eb0c203e2' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.warning.light,
                                                    color: theme.palette.warning.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row.receiptStatus?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row.receiptStatus._id === '6334bab7afa1158e678a87ad' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row.receiptStatus?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row.receiptStatus._id === '6342dc27f627d58acac98e0f' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row.receiptStatus?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row.receiptStatus._id === '636c1abb26ac75c9880bddd6' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row.receiptStatus?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row.receiptStatus._id === '636c1ab126ac75c9880bddd2' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row.receiptStatus?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row.receiptStatus._id === '6466771d8328e2d254fa6ac3' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row.receiptStatus?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row.receiptStatus._id === '651da6eb9d048db5123bc205' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row.receiptStatus?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row.receiptStatus._id === '6527fe1dba6c46cb61943f5c' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row.receiptStatus?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.paymentStatus?._id === '620d76a4ee512a471bf98c3e' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.success.light,
                                                    color: theme.palette.success.dark,
                                                    width: 85,
                                                    height: 20
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row?.paymentStatus?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row?.paymentStatus?._id === '620d7693448d1be3b4ab1b08' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.error.light,
                                                    color: theme.palette.error.dark,
                                                    width: 85,
                                                    height: 20
                                                }}
                                            >
                                                <DangerousRoundedIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5"> {row?.paymentStatus?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row?.paymentStatus?._id === '6542467c04e82ed78cd79ec6' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.error.light,
                                                    color: theme.palette.error.dark,
                                                    width: 85,
                                                    height: 20
                                                }}
                                            >
                                                <DangerousRoundedIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5"> {row?.paymentStatus?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        <IconButton
                                            aria-label="edit"
                                            color="primary"
                                            onClick={() => {
                                                // handleEditClick(row)
                                                showPaymentModal();
                                                setGetPaymentId(row?._id);
                                                console.log(row);
                                            }}
                                        >
                                            payment
                                        </IconButton>

                                        {row?.paymentStatus?._id === '620d76a4ee512a471bf98c3e' ? (
                                            ''
                                        ) : (
                                            <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(row)}>
                                                update
                                            </IconButton>
                                        )}
                                        <IconButton aria-label="delete" color="success" onClick={() => handlePdfClick(row)}>
                                            receipt
                                        </IconButton>

                                        <IconButton aria-label="delete" color="secondary" onClick={() => handleVatClick(row)}>
                                            vat
                                        </IconButton>
                                        {/* <IconButton aria-label="delete" color="secondary" onClick={() => handleAddTagClick(row)}>
                                            add tag
                                        </IconButton> */}

                    {/* <IconButton aria-label="view" color="warning" onClick={() => handleShowClick(row)}>
                                            preview
                                        </IconButton>

                                        <IconButton aria-label="view" color="error" onClick={() => handleAuditLogClick(row)}>
                                            logs
                                        </IconButton>
                                        <Tooltip title="Delivery Note Image">
                                            <IconButton
                                                aria-label="edit"
                                                className={classes.color}
                                                color="success"
                                                onClick={() => handleDeliveryNoteClick(row)}
                                            >
                                                <NoteAltIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Dispatch Image">
                                            <IconButton
                                                aria-label="edit"
                                                className={classes.color}
                                                color="success"
                                                onClick={() => handleDispatchImageClick(row)}
                                            >
                                                <ContentPasteGoIcon />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Pack Image">
                                            <IconButton
                                                aria-label="edit"
                                                className={classes.color}
                                                color="success"
                                                onClick={() => handlePackSortClick(row)}
                                            >
                                                <SortIcon />
                                            </IconButton>
                                        </Tooltip>  */}
                    {/* {userCountry === 'Ghana' ? (
                                            <>
                                                <IconButton aria-label="delete" color="error" onClick={() => handleMoMoPayClick(row)}>
                                                    MoMoPay
                                                </IconButton>
                                            </>
                                        ) : (
                                            ''
                                        )} */}

                    {/* <IconButton aria-label="view" color="warning" onClick={() => handleDeleteClick(row)}>
                                            delete
                                        </IconButton> */}
                    {/* </TableCell>
                                </TableRow>
                            ))} */}
                    {/* {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody> */}
                </Table>
            </TableContainer>
            {/* <TablePagination
                    rowsPerPageOptions={[150, 300, 450, { label: 'All', value: -1 }]}
                    component="div"
                    count={orderInfo.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
            <PaymentModal
                handlePaymentCancel={handlePaymentCancel}
                handlePaymentOk={handlePaymentOk}
                isPaymentOpen={isPaymentOpen}
                getPaymentId={getPaymentId}
                setUpdatePaymentSuccess={setUpdatePaymentSuccess}
                updatePaymentSuccess={updatePaymentSuccess}
                accumulatedFilters={accumulatedFilters}
                collections={collections}
            />
            {/* </MainCard> */}
        </>
    );
};

export default Order;
