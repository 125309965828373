import { useCallback, useEffect, useMemo, useState } from 'react';
import OrderMap from '../../components/orderMap/OrderMap';
import { ToastContainer, toast } from 'react-toastify';
import ApiUrl from '../../network';

const Map = () => {
    const [pins, setPins] = useState([]);
    const [currentPlaceId, setCurrentPlaceId] = useState(null);
    const [totalOrders, setTotalOrders] = useState(0);
    const [latestOrder, setLatestOrder] = useState([]);
    const [rma, setRma] = useState([]);
    const [pod, setPod] = useState([]);
    const [tier, setTier] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const [selectedPod, setSelectedPod] = useState([]);
    const [selectedTier, setSelectedTier] = useState([]);
    const [rmaOrders, setRmaOrders] = useState([]);
    const [podOrders, setPodOrders] = useState([]);
    const [tierOrders, setTierOrders] = useState([]);
    const [show, setShow] = useState(true);
    const [selectedTime, setSelectedTime] = useState([]);
    const [totalGMV, setTotalGMV] = useState(0);
    const [totalPodGMV, setTotalPodGMV] = useState(0);
    const [totalTimeGMV, setTotalTimeGMV] = useState(0);
    const [totalTierGMV, setTotalTierGMV] = useState(0);
    const [tierGMV, setTierGMV] = useState(false);
    const [podGMV, setPodGMV] = useState(false);
    const [rmaGMV, setRMAGMV] = useState(false);
    const [timeGMV, setTimeGMV] = useState(false);
    const [countries, setCountries] = useState([]);
    const country = localStorage.getItem('country');
    const [selectCountry, setSelectCountry] = useState(country);

    const mapCenter = country === 'Ghana' ? { lat: 7.5, lng: -1.00011 } : { lat: 7.465422, lng: 3.406448 };
    const [zoom, setZoom] = useState(7.7);

    const fetchCountries = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(`${ApiUrl.BaseUrl}/country/activecountry`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error('Something went wrong');
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchCountries();
    }, []);

    const countryOptions = countries?.map((result) => ({
        value: result._id,
        label: result.name
    }));

    const countryOption = countryOptions.map((item, index) => {
        return { value: item.label, label: item._id };
    });

    const getOrders = async (data) => {
        const userInfo = localStorage.getItem('userToken');

        try {
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/order/routeorders`;

            if (data) {
                if (data.country) urlParams.append('country', data.country);
            }

            url = `${url}?${urlParams.toString()}`;

            const result = await (
                await fetch(url, {
                    headers: {
                        'auth-token': userInfo,
                        'Content-Type': 'application/json'
                    }
                })
            ).json();

            // const { message, data } = result;
            const message = result.message;
            const orders = result.data;

            if (message === 'success') {
                const regex = /^-?\d+\.\d+$/;

                // orders with validate coordinates
                const validCoordinates = orders.filter((coordinate) => {
                    return regex.test(coordinate.latitude) && regex.test(coordinate.longitude);
                });

                setPins(validCoordinates);

                // total orders
                setTotalOrders((prev) => (prev = orders.length));

                // Latest Order
                const newAddedOrder = orders[orders.length - 1];
                setLatestOrder((prev) => (prev = newAddedOrder));

                // fields for rma search
                const orderByRma = orders.map((result) => ({
                    value: result._id,
                    label: result.placedBy
                }));

                // fields for pod search
                const orderByPod = orders.map((result) => ({
                    value: result._id,
                    label: result.pod
                }));

                // fields for tier search
                const orderByTier = orders.map((result) => ({
                    value: result._id,
                    label: `Loyalty ${result.tier}`
                }));

                // filter duplicate labels
                const filteredData = orderByRma.filter((item, index, self) => index === self.findIndex((t) => t.label === item.label));
                setRma(filteredData);

                // filter duplicate pods
                const filteredPod = orderByPod.filter((item, index, self) => index === self.findIndex((t) => t.label === item.label));
                setPod(filteredPod);

                // filter duplicate tiers
                const filteredTier = orderByTier.filter((item, index, self) => index === self.findIndex((t) => t.label === item.label));
                setTier(filteredTier);
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getOrders();

        // const interval = setInterval(() => {
        //     getOrders();
        // }, 60000);
        // return () => clearInterval(interval);
    }, []);

    const filterCountry = (value) => {
        setSelectCountry(value);
        const data = {
            country: value
        };

        if (data.country === 'Ghana') {
            setZoom(7.7);
        } else if (data.country === 'Nigeria') {
            setZoom(8.7);
        }

        getOrders(data);
        setShow(true);
        setTierGMV(false);
        setPodGMV(false);
        setRMAGMV(false);
        setTimeGMV(false);
        setSelectedPod([]);
        setSelectedValue([]);
        setSelectedTier([]);
        setSelectedTime([]);
    };

    // Sum of GMV
    const sumOfGMV = (value) => {
        const sum = value.reduce((accumulator, currentValue) => {
            const total = accumulator + currentValue.sales;

            return total;
        }, 0);
        return sum;
    };

    const handleMarkerClick = (id) => {
        setCurrentPlaceId(id);
    };

    // select time range
    const onChangeTime = (time, timeString) => {
        setSelectedTime((prev) => (prev = timeString));
        setShow(false);
        setSelectedPod([]);
        setSelectedValue([]);
        setSelectedTier([]);
        setTierGMV(false);
        setPodGMV(false);
        setRMAGMV(false);
        setTimeGMV(true);
    };

    //  order time
    const orderTime = pins.filter((item) => {
        const dateString = item.orderDate;
        const formattedTime = new Date(dateString).toLocaleTimeString();
        return formattedTime >= selectedTime[0] && formattedTime <= selectedTime[1] ? item : null;
    });

    // total gmv within a selected time range
    useEffect(() => {
        setTotalTimeGMV(
            sumOfGMV(orderTime).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        );
    }, [totalTimeGMV, selectedTime]);

    // number of orders by in a time range
    const totalOrdersPlacedInATimeRange = useMemo(() => {
        return orderTime.length;
    }, [selectedTime]);

    // select rma
    const handleSelectChange = (value) => {
        setSelectedValue((prev) => (prev = value));
        setShow(false);
        setSelectedPod([]);
        setSelectedTime([]);
        setSelectedTier([]);
        setRMAGMV(true);
        setTimeGMV(false);
        setTierGMV(false);
        setPodGMV(false);
    };

    // rma data from selected value
    const rmaName = rma.filter((rma) => {
        return rma.value === selectedValue;
    });

    // rma name from field selected value
    const selectedRmaPerson = rmaName.map((item) => item.label);

    //  order data placed by selected rma
    const ordersPlaced = pins.filter((item) => {
        return item.placedBy === selectedRmaPerson.toString() ? item : null;
    });

    // re render component when selected rma changes
    useEffect(() => {
        setRmaOrders(ordersPlaced);
    }, [selectedValue, show]);

    // number of orders by an rma
    const totalOrdersPlacedByRMA = useMemo(() => {
        return ordersPlaced.length;
    }, [selectedValue]);

    // total gmv by an rma
    useEffect(() => {
        setTotalGMV(
            sumOfGMV(ordersPlaced).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        );
    }, [totalGMV, selectedValue]);

    // select pod
    const handlePodChange = (value) => {
        setSelectedPod((prev) => (prev = value));
        setShow(false);
        setSelectedValue([]);
        setSelectedTime([]);
        setSelectedTier([]);
        setTierGMV(false);
        setPodGMV(true);
        setTimeGMV(false);
        setRMAGMV(false);
    };

    // pod Name
    const podName = pod.filter((pod) => {
        return pod.value === selectedPod;
    });

    // pod name from field selected value
    const selectPod = podName.map((item) => item.label);

    //  pod data placed by selected rma
    const podSelection = pins.filter((item) => {
        return item.pod === selectPod.toString() ? item : null;
    });

    // number of orders by a pod
    const totalOrdersPlacedByPod = useMemo(() => {
        return podSelection.length;
    }, [selectedPod]);

    // re render component when selected pod changes
    useEffect(() => {
        setPodOrders(podSelection);
    }, [selectedPod, show]);

    // total gmv by a pod
    useEffect(() => {
        setTotalPodGMV(
            sumOfGMV(podSelection).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        );
    }, [totalPodGMV, selectedPod]);

    // select tier
    const handleTierChange = (value) => {
        setSelectedTier((prev) => (prev = value));
        setShow(false);
        setSelectedValue([]);
        setSelectedTime([]);
        setSelectedPod([]);
        setTierGMV(true);
        setPodGMV(false);
        setTimeGMV(false);
        setRMAGMV(false);
    };

    // Tier Name
    const tierName = tier.filter((tier) => {
        return tier.value === selectedTier;
    });

    // tier name from field selected value
    const selectTier = tierName.map((item) => item.label);

    //  tier data placed by selected rma
    const tierSelection = pins.filter((item) => {
        return `Loyalty ${item.tier}` === selectTier.toString() ? item : null;
    });

    // number of orders by a tier
    const totalOrdesByTier = useMemo(() => {
        return tierSelection.length;
    }, [selectedTier]);

    // re render component when selected tier changes
    useEffect(() => {
        setTierOrders(tierSelection);
    }, [selectedTier, show]);

    // total gmv by a tier
    useEffect(() => {
        setTotalTierGMV(
            sumOfGMV(tierSelection).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        );
    }, [totalTierGMV, selectedTier]);

    return (
        <OrderMap
            position={{ lat: -34.397, lng: 150.644 }}
            pins={pins}
            setCurrentPlaceId={setCurrentPlaceId}
            handleMarkerClick={handleMarkerClick}
            currentPlaceId={currentPlaceId}
            latestOrder={latestOrder}
            totalOrders={totalOrders}
            mapCenter={mapCenter}
            rma={rma}
            handleSelectChange={handleSelectChange}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            rmaOrders={rmaOrders}
            setShow={setShow}
            show={show}
            onChangeTime={onChangeTime}
            orderTime={orderTime}
            totalGMV={totalGMV}
            totalOrdersPlacedByPod={totalOrdersPlacedByPod}
            totalOrdersPlacedByRMA={totalOrdersPlacedByRMA}
            setSelectedPod={setSelectedPod}
            pod={pod}
            handlePodChange={handlePodChange}
            podOrders={podOrders}
            totalPodGMV={totalPodGMV}
            selectedPod={selectedPod}
            totalTimeGMV={totalTimeGMV}
            totalOrdersPlacedInATimeRange={totalOrdersPlacedInATimeRange}
            selectedTime={selectedTime}
            selectedTier={selectedTier}
            handleTierChange={handleTierChange}
            tier={tier}
            tierOrders={tierOrders}
            totalOrdesByTier={totalOrdesByTier}
            totalTierGMV={totalTierGMV}
            setTierGMV={setTierGMV}
            tierGMV={tierGMV}
            setPodGMV={setPodGMV}
            podGMV={podGMV}
            setTimeGMV={setTimeGMV}
            timeGMV={timeGMV}
            setRMAGMV={setRMAGMV}
            rmaGMV={rmaGMV}
            filterCountry={filterCountry}
            countryOption={countryOption}
            zoom={zoom}
            country={country}
            setZoom={setZoom}
            selectCountry={selectCountry}
        />
    );
};

export default Map;
