// import dashboard from './dashboard';
import insurer from './insurer';
import other from './other';

// ==============================|| MENU ITEMS dashboard ||============================== //

const insurerItems = {
    items: [insurer, other]
};

export default insurerItems;
