/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useTheme } from '@mui/material/styles';
import { Col } from 'react-grid-system';
import TextField from '@mui/material/TextField';
import { cryptoUUID } from '../../utils/functions';
import RmaOrders from '../../components/RmaOrdersTable/RmaOrdersTable';
import { List, ListItem } from '@mui/material';
import RmaReceiptModal from '../../components/RmaOrdersTable/RmaReceiptModal';
import RmaReceiptUpdateModal from '../../components/RmaOrdersTable/RmaReceiptUpdateModal';
import { encrypt, decrypt } from '../../utils/encrypt';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    }
}));

const CssPlacedOrder = () => {
    const classes = useStyles();
    const history = useNavigate();
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const [orderInfo, setOrder] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [count, setCount] = useState(50);
    const [searched, setSearched] = useState('');
    const [localSearch, setlocalSearch] = useState('');
    const [accumulatedFilters, setAccumulatedFilters] = useState({});
    const [cssOrders, setCssOrders] = useState([]);
    const [totalOrders, setTotalOrders] = useState(0);
    const [isReceiptOpen, setIsReceiptOpen] = useState(false);
    const [isReceiptUpdateOpen, setIsReceiptUpdateOpen] = useState(false);
    const [getOrderId, setGetOrderId] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [collections, setCollections] = useState([]);
    const [confirmedList, setConfirmedList] = useState([]);
    const [updateReceiptSuccess, setUpdatReceiptSuccess] = useState(false);
    const [updateReceiptBulkSuccess, setUpdatReceiptBulkSuccess] = useState(false);
    const [mainCustomer, setMainCustomer] = useState([]);
    const [limit, setLimit] = useState(10);
    const [getOrderNo, setGetOrderNo] = useState('');
    const userCountry = localStorage.getItem('country');
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 25,
            total: 0
        }
    });

    // receipt modal
    const showReceiptModal = () => {
        setIsReceiptOpen(true);
    };

    const handleReceiptOk = () => {
        setIsReceiptOpen(false);
    };

    const handleReceiptCancel = () => {
        setIsReceiptOpen(false);
    };

    // receipt Update modal
    const showReceiptUpdateModal = () => {
        setIsReceiptUpdateOpen(true);
    };

    const handleReceiptUpdateOk = () => {
        setIsReceiptUpdateOpen(false);
    };

    const handleReceiptUpdateCancel = () => {
        setIsReceiptUpdateOpen(false);
    };

    // get all orders
    const getCssOrders = async () => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.MyMainOrdersUrl}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            // Append accumulated filters to URL
            Object.entries(accumulatedFilters).forEach(([key, value]) => {
                if (value) {
                    urlParams.append(key, value);
                }
            });

            url = `${url}?${urlParams.toString()}`;

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCssOrders(data.data);

                        setTotalOrders(data.totalOrders);
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                total: data.totalOrders,
                                current: accumulatedFilters?.page || 1
                            }
                        });
                    } else {
                        const answer = JSON.parse(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // get all collections
    const getCollections = async () => {
        try {
            setLoading(true);
            const url = `${ApiUrl.BaseUrl}/collection/financecollection`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setCollections(answer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const getConfirmedList = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(`${ApiUrl.BaseUrl}/collection/approvecollectionlist`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setConfirmedList(answer);
                    } else {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        toast.error(answer);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getCssOrders();
        getCollections();
        getConfirmedList();
    }, [accumulatedFilters, updateReceiptSuccess]);

    const limitNumber = 100;

    const limitOptions = [
        {
            label: '100',
            value: limitNumber * 1
        },
        {
            label: '200',
            value: limitNumber * 2
        },
        {
            label: '300',
            value: limitNumber * 3
        },
        {
            label: '400',
            value: limitNumber * 4
        },
        {
            label: '500',
            value: limitNumber * 5
        },
        {
            label: '600',
            value: limitNumber * 6
        },
        {
            label: '700',
            value: limitNumber * 7
        },
        {
            label: '800',
            value: limitNumber * 8
        },
        {
            label: '900',
            value: limitNumber * 9
        },
        {
            label: '1000',
            value: limitNumber * 10
        }
    ];

    // filter by partner
    const filterPartner = (value) => {
        const data = {
            partner: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter date
    const filterDate = (value, dateString) => {
        setSelectedDate(value);
        const data = {
            from: value[0]?.$d,
            to: value[1]?.$d
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    const handleLimit = (value) => {
        setLimit(value);
        const data = {
            limit: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    const handlePage = (page) => {
        const data = {
            page: page.current,
            limit: page.pageSize
        };
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.page
            }
        });
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    const handleEditClick = (row) => {
        const date = new Date();
        // data from database
        // console.log(`db: ${row?.orderDate}`);
        // console.log(`current date: ${date}`);

        const FIFETEEB_MIN = 15 * 60 * 1000;
        // console.log(date - new Date(row?.orderDate));
        // console.log(FIFETEEB_MIN);

        if (date - new Date(row?.orderDate) > FIFETEEB_MIN && row?.newOrderStatusId?._id !== '651da6eb9d048db5123bc205') {
            swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Update Delayed by more than 15 minutes. Kindly contact your Pod Portfolio',
                footer: '<a href="">Why do I have this issue?</a>'
            });
            // console.log('Delayed by more than 15 mins');
        } else if (scriptedRef.current) {
            // console.log(row);
            history(`/order-request-form/${row._id}`);
            // history(`/viewmysalesorders/${row._id}`);
        }
    };

    const handleShowClick = (row) => {
        if (scriptedRef.current) {
            // console.log(row); preview
            history(`/previewmysalesorders/${row._id}`);
        }
    };

    const handleIssueClick = (row) => {
        if (scriptedRef.current) {
            // console.log(row); preview
            history(`/addissue/${row._id}`);
        }
    };

    const handlePdfClick = (row) => {
        // console.log('row', row);
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };
        // setLoading2(true);
        axios.post(ApiUrl.DownloadPdfOrdersUrl, row, config).then((res) => {
            window.open(res.data.data, '_blank');

            // setLoading2(false);
        });
    };

    return (
        <>
            <List
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    padding: '0 0 16px 0',
                    borderBottom: '1px dashed #E7EAEB',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    margin: '0px auto 15px'
                }}
            >
                <ListItem sx={{ fontWeight: 600, fontSize: '24px', color: '#FF740F', padding: 0 }}>
                    {`Total Orders: ${totalOrders}`}
                </ListItem>
            </List>

            <RmaOrders
                rmaOrders={cssOrders}
                loading={loading}
                useStyles={useStyles}
                handleIssueClick={handleIssueClick}
                handlePdfClick={handlePdfClick}
                handleEditClick={handleEditClick}
                handleShowClick={handleShowClick}
                showModal={showReceiptModal}
                showReceiptUpdateModal={showReceiptUpdateModal}
                setGetOrderId={setGetOrderId}
                setGetOrderNo={setGetOrderNo}
                handleLimit={handleLimit}
                limitOptions={limitOptions}
                limit={limit}
                filterDate={filterDate}
                selectedDate={selectedDate}
                filterPartner={filterPartner}
                collections={collections}
                confirmedList={confirmedList}
                setUpdatReceiptBulkSuccess={setUpdatReceiptBulkSuccess}
                updateReceiptBulkSuccess={updateReceiptBulkSuccess}
                handlePage={handlePage}
                tableParams={tableParams}
            />
            <br />
            <RmaReceiptModal
                handleReceiptCancel={handleReceiptCancel}
                handleReceiptOk={handleReceiptOk}
                isReceiptOpen={isReceiptOpen}
                getOrderId={getOrderId}
                getOrderNo={getOrderNo}
                setUpdatReceiptSuccess={setUpdatReceiptSuccess}
                updateReceiptSuccess={updateReceiptSuccess}
            />
            <RmaReceiptUpdateModal
                handleReceiptUpdateCancel={handleReceiptUpdateCancel}
                handleReceiptUpdateOk={handleReceiptUpdateOk}
                isReceiptUpdateOpen={isReceiptUpdateOpen}
                collections={collections}
                getOrderId={getOrderId}
                getOrderNo={getOrderNo}
                setUpdatReceiptSuccess={setUpdatReceiptSuccess}
                updateReceiptSuccess={updateReceiptSuccess}
                confirmedList={confirmedList}
            />
        </>
    );
};

export default CssPlacedOrder;
