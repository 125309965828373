import { Space, Modal, Button, Spin } from 'antd';
import { Box, Typography, List, ListItem } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { LoadingOutlined } from '@ant-design/icons';

const PaymentAlert = ({ handleSkuModalOk, handleSkuModalCancel, isSkuOpen, sku, skuIdLoaing }) => {
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54
            }}
            spin
        />
    );
    return (
        <div>
            <Modal
                open={isSkuOpen}
                onOk={handleSkuModalOk}
                onCancel={handleSkuModalCancel}
                width={504}
                zIndex={2050}
                centered
                okButtonProps={{
                    disabled: true,
                    style: { display: 'none' }
                }}
                cancelButtonProps={{
                    disabled: true,
                    style: { display: 'none' }
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        // height: '300px',
                        backgroundColor: '#FFFFFF',
                        borderRadius: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <List sx={{ paddingTop: '0px', height: '300px', overflowY: 'auto' }}>
                        {sku?.items?.map((item, index) => (
                            <>
                                {skuIdLoaing ? (
                                    <Spin
                                        size="small"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
                                ) : (
                                    <ListItem
                                        key={item._id}
                                        sx={{
                                            fontSize: '1rem',
                                            color: '#233E4C',
                                            fontWeight: 400,
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            justifyContent: 'space-between',
                                            width: '450px',
                                            borderBottom: index !== item?.length - 1 ? '1px dashed #E7EAEB' : 'none'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                justifyContent: 'space-between',
                                                flexDirection: 'column',
                                                height: '100%'
                                            }}
                                        >
                                            <Typography
                                                sx={{ fontSize: '18px', fontWeight: '600', marginBottom: '10px' }}
                                            >{` Sku: ${item?.partid}`}</Typography>
                                            <Typography
                                                sx={{ fontSize: '18px', fontWeight: '600', marginBottom: '10px' }}
                                            >{`Quantity: ${item?.qty} ${item.partType}`}</Typography>
                                            <Typography
                                                sx={{ fontSize: '18px', fontWeight: '600', marginBottom: '10px' }}
                                            >{`Price: ${item?.garagePrice}`}</Typography>
                                            <Typography
                                                sx={{ fontSize: '18px', fontWeight: '600', marginBottom: '10px' }}
                                            >{`Brand: ${item?.brand}`}</Typography>
                                        </Box>

                                        <div className="item-photo" style={{ width: '100px' }}>
                                            <img src={item?.partimage} alt="" />{' '}
                                        </div>
                                    </ListItem>
                                )}
                            </>
                        ))}
                    </List>
                </Box>
            </Modal>
        </div>
    );
};

export default PaymentAlert;
