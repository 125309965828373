import React, { useState, useEffect, useCallback } from 'react';
import { Space, DatePicker, Button } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Link, useNavigate, useParams, useHistory } from 'react-router-dom';
import WestIcon from '@mui/icons-material/West';
import './logistics.css';
import Image from '../../assets/images/brakefluid.png';
import { encrypt, decrypt } from '../../utils/encrypt';
import { LoadingOutlined } from '@ant-design/icons';
import ApiUrl from 'network';
import { ToastContainer, toast } from 'react-toastify';
import OrderSumbition from './OrderSumbition';
import useOrder from './useOrder';

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 54
        }}
        spin
    />
);

const OrderDetails = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isDriverOpen, setIsDriverOpen] = useState(false);
    const [showAssignOrderButton, setShowAssignOrderButton] = useState(false);
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const navigate = useNavigate();
    const { id } = useParams();
    const { assigned } = useOrder();

    // 2 decimal places
    const twoDecimalPlaces = (value) => {
        return parseFloat(value?.toFixed(2)).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    };

    // convert date
    const dateConverter = (value) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long'
            // hour: '2-digit',
            // minute: '2-digit',
            // second: '2-digit'
        };

        return new Date(value).toLocaleDateString('en-US', options);
    };

    // driver select modals
    const showDriverModal = () => {
        setIsDriverOpen(true);
    };

    const handleDriverOk = () => {
        setIsDriverOpen(false);
    };
    const handleDriverCancel = () => {
        setIsDriverOpen(false);
    };

    const singleOrder = async (data) => {
        try {
            setLoading(true);
            const url = `${ApiUrl.BaseUrl}/driver/allregularorders/`;
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setOrders(answer);
                        setLoading(false);
                        const order = answer?.find((item) => {
                            return item?._id === id ? item : '';
                        });

                        setOrders(order);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                        toast.error(answer);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        singleOrder();
    }, []);

    useEffect(() => {
        const p = assigned?.filter((item) => {
            return item?._id === id ? item : '';
        });

        p?.map((item) => {
            return item?._id === id ? setShowAssignOrderButton(true) : setShowAssignOrderButton(false);
        });
    }, [id]);

    return (
        <>
            <div className="logistic-container">
                <List
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '0 0 16px 0',
                        borderBottom: '1px dashed #E7EAEB',
                        alignItems: 'flex-start',
                        margin: '0px auto 15px'
                    }}
                >
                    <WestIcon sx={{ fontSize: '32px', cursor: 'pointer' }} onClick={() => navigate(-1)} />

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            marginLeft: '24px'
                        }}
                    >
                        <ListItem
                            sx={{
                                fontSize: '1.875rem',
                                color: '#0A2938',
                                padding: 0,
                                fontWeight: 900,
                                marginBottom: '7px'
                            }}
                        >
                            Order Details
                        </ListItem>
                        <ListItem sx={{ fontSize: '0.875rem', color: '#565C6B', padding: 0 }}>
                            Showing all the products on this order
                        </ListItem>
                    </Box>
                </List>
                {loading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '200px'
                        }}
                    >
                        {antIcon}
                    </Box>
                ) : (
                    <div className="order-assined-info">
                        {!showAssignOrderButton ? (
                            <Button
                                style={{
                                    backgroundColor: '#FF740F',
                                    borderRadius: '12px',
                                    height: '56px',
                                    fontSize: '18px',
                                    fontWeight: '700',
                                    color: '#FFFFFF',
                                    width: '250px',
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    margin: '16px 0'
                                }}
                                onClick={showDriverModal}
                            >
                                Assign Order
                            </Button>
                        ) : (
                            <Button
                                style={{
                                    backgroundColor: '#FF740F',
                                    borderRadius: '12px',
                                    height: '56px',
                                    fontSize: '18px',
                                    fontWeight: '700',
                                    color: '#FFFFFF',
                                    width: '250px',
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    margin: '16px 0'
                                }}
                                onClick={showDriverModal}
                            >
                                Re-Assign Order
                            </Button>
                        )}
                        {orders?.items?.map((item) => (
                            <div className="order-assined-info-container" key={item?._id}>
                                <div className="product-image-container">
                                    <img src={item.partimage} alt="" className="product-image" />
                                </div>

                                <div className="order-assined-details">
                                    <List sx={{ margin: '16px', paddingTop: '0px' }}>
                                        <ListItem sx={{ fontSize: '0.85rem', color: '#233E4C', fontWeight: 400 }}>
                                            Product Name: {item.partid}
                                        </ListItem>

                                        <ListItem sx={{ fontSize: '0.85rem', color: '#233E4C', fontWeight: 400 }}>
                                            Order number: {orders.orderNo}
                                        </ListItem>
                                        <ListItem sx={{ fontSize: '0.85rem', color: '#233E4C', fontWeight: 400 }}>
                                            Price: {item.garagePrice}
                                        </ListItem>
                                        <ListItem sx={{ fontSize: '0.85rem', color: '#233E4C', fontWeight: 400 }}>
                                            Quantity: {item.qty}
                                        </ListItem>

                                        <ListItem sx={{ fontSize: '0.85rem', color: '#233E4C', fontWeight: 400 }}>
                                            Drop-off location: {orders?.location}
                                        </ListItem>
                                    </List>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <OrderSumbition
                isDriverOpen={isDriverOpen}
                handleDriverOk={handleDriverOk}
                handleDriverCancel={handleDriverCancel}
                orders={orders}
                showAssignOrderButton={showAssignOrderButton}
            />
        </>
    );
};

export default OrderDetails;
