// assets
import { IconKey, IconTruckDelivery } from '@tabler/icons';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Person from '@mui/icons-material/Person';
import CarCrashIcon from '@mui/icons-material/CarRental';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import Scale from '@mui/icons-material/Scale';
import Shop from '@mui/icons-material/Shop2';
import Trans from '@mui/icons-material/Money';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import BugReportIcon from '@mui/icons-material/BugReport';

// constant
const icons = {
    IconKey,
    IconTruckDelivery,
    DashboardIcon,
    Scale,
    Shop,
    Person,
    DisplaySettingsIcon,
    Trans,
    CarCrashIcon,
    InsertChartIcon,
    BugReportIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //
const userRole = localStorage.getItem('userRole');
const partsupload = {
    id: 'partsupload',
    title: 'Parts Upload',
    type: userRole,
    children: [
        {
            id: 'home',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: icons.DashboardIcon,
            breadcrumbs: false
        },
        {
            id: 'partsuploaddata',
            title: 'Parts Image Upload',
            type: 'item',
            url: '/partsuploaddata',
            icon: icons.InsertChartIcon,
            breadcrumbs: false
        }
    ]
};

export default partsupload;
