import { Link, useParams } from 'react-router-dom';
import { ArrowLeftOutlined, LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import ApiUrl from '../../../network';
import { toast } from 'react-toastify';
import { decrypt } from '../../../utils/encrypt';

const process = '../../moving_clock.png';
const success = '../../lets-icons_check-fill.png';
const failed = '../../ic_baseline-cancel.png';
const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const [response, setResponse] = useState(false);
    const [responseState, setResponseState] = useState(''); // Possible states: 'processing', 'success',
    const [networkData, setnetworkData] = useState([]);
    const [phone, setPhone] = useState('');
    const [amount, setAmount] = useState('');
    const [network, setNetwork] = useState('');
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState('');
    const [fullOrderId, setFullOrderId] = useState('');
    const [timeLeft, setTimeLeft] = useState(20);
    const { id } = useParams();

    const retrieveAllNetworks = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(ApiUrl.PaymentNetworkUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setnetworkData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                });
        } catch (e) {
            console.log(e);
        }
    };

    const fetchOrderDetails = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            await fetch(`${ApiUrl.BaseUrl}/order/v2/vieworders/${id}`, {
                method: 'GET',
                headers: config.headers
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        // 667ee3694dd577d1bb439ecb
                        const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setCustomer(decryptedData?.newChannelPartnerId?.partnerName);
                        setFullOrderId(decryptedData?.fulOrderId);
                    } else {
                        toast.error(data.message);
                    }
                });
        } catch (error) {
            toast.error(error.message);
        }
    };

    const generateReference = () => {
        const random = Math.floor(Math.random() * 1000000000);
        return random.toString();
    };

    useEffect(() => {
        if (timeLeft === 0) {
            // window.location.href = '/'; // Redirect when timer hits 0
            return;
        }

        const timer = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000); // Update every second

        // eslint-disable-next-line consistent-return
        return () => clearInterval(timer); // Cleanup interval on component unmount
    }, [timeLeft]);

    // Format time left as MM:SS
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secondsLeft = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secondsLeft).padStart(2, '0')}`;
    };

    useEffect(() => {
        retrieveAllNetworks().then((r) => r);
        fetchOrderDetails().then((r) => r);
    }, []);

    const handleSubmit = () => {
        setTimeLeft(20);
        setResponse(true);
        setResponseState('processing');
        const data = {
            orderId: id,
            phoneNumber: phone,
            network,
            amount: parseFloat(amount),
            reference: generateReference(),
            paymentType: 'MoMo',
            narration: `Payment for order ${fullOrderId} by ${customer}`
        };

        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        fetch(ApiUrl.OrderPayMoMoUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.data);
                    setResponseState('processing');
                    setLoading(false);
                } else {
                    toast.error(result.data);
                    setLoading(false);
                    setResponseState('failed');
                }
            });
    };

    const checkPaymentStatus = () => {
        setTimeLeft(20);
        const data = {
            reference: generateReference(),
            orderId: id
        };

        const userInfo = localStorage.getItem('userToken');
        fetch(ApiUrl.CheckingOrderPayStatusUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.data?.status);
                    setResponseState('success');
                } else {
                    toast.error(result?.data?.status);
                    setResponseState('failed');
                }
            });
    };

    return (
        <div>
            <div className="m-header">
                <div>
                    <Link to="/payment-transactions">
                        <h1 style={{ color: '#0A2938', display: 'flex', alignItems: 'center' }}>
                            <ArrowLeftOutlined className="mr-2" /> MoMo payment
                        </h1>
                    </Link>
                </div>
            </div>
            <div className="m-body">
                <div className="momo">
                    <div className="mb-4">
                        <p style={{ fontSize: '16px', color: '#546974', marginBottom: '4px' }}>Network</p>
                        <Select
                            placeholder="Select network"
                            size="large"
                            showSearch
                            style={{ width: '100%' }}
                            options={networkData.map((data) => ({
                                value: data,
                                label: data
                            }))}
                            onChange={(e) => setNetwork(e)}
                        />
                    </div>
                    <div className="mb-4">
                        <p style={{ fontSize: '16px', color: '#546974', marginBottom: '4px' }}>Phone number</p>
                        <Input
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            type="number"
                            placeholder="Enter phone number"
                            size="large"
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div>
                        <p style={{ fontSize: '16px', color: '#546974', marginBottom: '4px' }}>Amount</p>
                        <Input
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            type="number"
                            placeholder="Enter amount"
                            size="large"
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div>
                        <Button
                            type="primary"
                            size="large"
                            style={{
                                width: '100%',
                                marginTop: '20px',
                                backgroundColor: '#FF740F'
                            }}
                            onClick={handleSubmit}
                        >
                            Process payment
                        </Button>
                    </div>
                </div>
            </div>
            <Modal
                open={response}
                onCancel={() => {
                    setResponse(false);
                    setResponseState('');
                    setAmount('');
                    setPhone('');
                    setNetwork('');
                }}
                footer={null}
            >
                {responseState === 'processing' && (
                    <div className="text-center">
                        <img
                            style={{ width: '100px', height: '100px', marginBottom: '20px', objectFit: 'contain' }}
                            src={process}
                            alt="processing"
                        />
                        <p style={{ color: '#0A2938', fontSize: '22px', fontWeight: 'bold' }}>
                            Kindly wait as we process <br /> payment
                        </p>
                        <p
                            style={{
                                fontSize: '16px',
                                color: '#9DA9AF',
                                lineHeight: '24px'
                            }}
                        >
                            Payment is currently processing, if payment <br /> process is not complete under the estimated time,kindly try
                            again later.
                        </p>
                        <small
                            style={{
                                color: '#00AA55',
                                fontSize: '14px',
                                display: 'block',
                                marginTop: '20px',
                                marginBottom: '20px'
                            }}
                        >
                            Estimated time: <span style={{ color: '#4B6EFF', marginLeft: '8px' }}>{formatTime(timeLeft)}</span>
                        </small>
                        {timeLeft <= 0 && (
                            <div style={{ marginTop: '20px' }}>
                                <Button
                                    onClick={checkPaymentStatus}
                                    style={{
                                        color: '#FF740F',
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        borderRadius: '5px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Try Again
                                </Button>
                                <Button
                                    onClick={() => (window.location.href = '/payment-transactions')}
                                    style={{
                                        color: '#FF740F',
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        borderRadius: '5px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        )}
                    </div>
                )}
                {responseState === 'success' && (
                    <div className="text-center">
                        <img
                            style={{ width: '100px', height: '100px', marginBottom: '20px', objectFit: 'contain' }}
                            src={success}
                            alt="processing"
                        />
                        <p style={{ color: '#0A2938', fontSize: '22px', fontWeight: 'bold' }}>Payment Successful!</p>
                        <p
                            style={{
                                fontSize: '16px',
                                color: '#9DA9AF',
                                lineHeight: '24px'
                            }}
                        >
                            Your Payment has been successfully <br /> completed, kindly return back to home screen
                        </p>
                        <Button
                            onClick={() => {
                                setAmount('');
                                setPhone('');
                                setNetwork('');
                                setResponse(false);
                            }}
                            type="primary"
                            size="large"
                            style={{
                                width: '50%',
                                marginTop: '20px',
                                backgroundColor: '#FF740F'
                            }}
                        >
                            Return to home
                            <Spin
                                className="ml-2"
                                indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />}
                                size="small"
                            />
                        </Button>
                    </div>
                )}

                {responseState === 'failed' && (
                    <div className="text-center">
                        <img
                            style={{ width: '100px', height: '100px', marginBottom: '20px', objectFit: 'contain' }}
                            src={failed}
                            alt="processing"
                        />
                        <p style={{ color: '#0A2938', fontSize: '22px', fontWeight: 'bold' }}>Payment Declined!</p>
                        <p
                            style={{
                                fontSize: '16px',
                                color: '#9DA9AF',
                                lineHeight: '24px'
                            }}
                        >
                            Your Payment has been declined, <br /> kindly take required action
                        </p>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexDirection: 'column',
                                width: '100%',
                                marginTop: '20px'
                            }}
                        >
                            <Button
                                onClick={() => setResponse(false)}
                                type="primary"
                                size="large"
                                style={{
                                    width: 'fit-content',
                                    marginTop: '20px',
                                    backgroundColor: '#FF740F'
                                }}
                            >
                                Return back to home screen
                            </Button>
                            <Button
                                onClick={handleSubmit}
                                type="primary"
                                size="large"
                                style={{
                                    width: 'fit-content',
                                    marginTop: '20px',
                                    backgroundColor: '#fff',
                                    color: '#000',
                                    border: '1px solid #000'
                                }}
                            >
                                <ReloadOutlined className="mr-2" /> Retry payment
                            </Button>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default Index;
