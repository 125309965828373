import { CodeSandboxOutlined, LoadingOutlined, RiseOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import ApiUrl from '../../network';
import { decrypt } from '../../utils/encrypt';
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { Skeleton, Spin } from 'antd';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Dashboard = () => {
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
            window.location.href = '/pricing-parts';
        }
    };
    const [loading, setLoading] = useState(false);
    const [bannerData, setBannerData] = useState([]);

    const fetchBannerData = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/pricing/location/pricingbanner`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                console.log(decryptedData, 'decryptedData');
                setBannerData(decryptedData);
                setLoading(false);
            } else {
                toast.error(result.message);
                setLoading(false);
            }
        } catch (e) {
            toast(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBannerData();
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };

    return (
        <div>
            <div className="m-header">
                <div>
                    <h1>Pricing Feature</h1>
                    <p>Keep track of product prices and ongoing discounts</p>
                </div>
            </div>
            <div className="m-content mt-4">
                <div className="banner">
                    {loading ? (
                        <div
                            className="text-center"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '200px',
                                padding: '20px'
                            }}
                        >
                            <Skeleton active />
                        </div>
                    ) : (
                        <Slider {...settings}>
                            {bannerData.map((image, index) => (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                                    <img src={image.image} alt={`part-${index}`} className="bannerImage" />
                                </div>
                            ))}
                        </Slider>
                    )}
                </div>
                <div>
                    <div className="product-banner-cards">
                        <div
                            className="banner-content d-flex justify-content-between"
                            onClick={() => (window.location.href = '/pricing-parts')}
                            onKeyDown={handleKeyDown}
                            role="button"
                            tabIndex={0}
                            aria-label="Navigate to product prices page"
                        >
                            <div className="d-flex" style={{ cursor: 'pointer' }}>
                                <div>
                                    <span
                                        style={{
                                            backgroundColor: '#fff',
                                            color: '#4B6EFF',
                                            fontSize: '36px',
                                            borderRadius: '50px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '50px',
                                            height: '50px'
                                        }}
                                    >
                                        <CodeSandboxOutlined />
                                    </span>
                                </div>
                                <span className="ml-2 flex-grow-1">
                                    <h1 style={{ fontSize: '30px' }}>Product prices</h1>
                                    <p>View product prices basedon their location</p>
                                </span>
                            </div>
                            <span style={{ fontSize: '36px', marginLeft: 'auto' }}>
                                <RiseOutlined />
                            </span>
                        </div>
                        <div className="banner-content d-flex justify-content-between">
                            <div className="d-flex">
                                <div>
                                    <span
                                        style={{
                                            backgroundColor: '#fff',
                                            color: '#4B6EFF',
                                            fontSize: '36px',
                                            borderRadius: '50px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '50px',
                                            height: '50px'
                                        }}
                                    >
                                        <CodeSandboxOutlined />
                                    </span>
                                </div>
                                <span className="ml-2 flex-grow-1">
                                    <h1 style={{ fontSize: '30px' }}>Product bundle</h1>
                                    <p>View product bundle with great prices</p>
                                </span>
                            </div>
                            <span style={{ fontSize: '36px', marginLeft: 'auto' }}>
                                <RiseOutlined />
                            </span>
                        </div>
                    </div>
                    <div className="product-banner-cards">
                        <div className="banner-content d-flex justify-content-between">
                            <div className="d-flex">
                                <div>
                                    <span
                                        style={{
                                            backgroundColor: '#fff',
                                            color: '#4B6EFF',
                                            fontSize: '36px',
                                            borderRadius: '50px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '50px',
                                            height: '50px'
                                        }}
                                    >
                                        <CodeSandboxOutlined />
                                    </span>
                                </div>
                                <span className="ml-2 flex-grow-1">
                                    <h1 style={{ fontSize: '30px' }}>Discount prices</h1>
                                    <p>View discount prices and great deals</p>
                                </span>
                            </div>
                            <span style={{ fontSize: '36px', marginLeft: 'auto' }}>
                                <RiseOutlined />
                            </span>
                        </div>
                        <div className="banner-content d-flex justify-content-between">
                            <div className="d-flex">
                                <div>
                                    <span
                                        style={{
                                            backgroundColor: '#fff',
                                            color: '#4B6EFF',
                                            fontSize: '36px',
                                            borderRadius: '50px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '50px',
                                            height: '50px'
                                        }}
                                    >
                                        <CodeSandboxOutlined />
                                    </span>
                                </div>
                                <span className="ml-2 flex-grow-1">
                                    <h1 style={{ fontSize: '30px' }}>Campaign prices</h1>
                                    <p>View all campaign prices for all our products</p>
                                </span>
                            </div>
                            <span style={{ fontSize: '36px', marginLeft: 'auto' }}>
                                <RiseOutlined />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
