import LeadCardWrapper from 'assets/Wrappers/LeadCardWrapper';

const userInfo = localStorage.getItem('userCurrency');

const SimpleCard = ({ count, title, isPercent, isMoney }) => {
    return (
        <LeadCardWrapper>
            <div className="lead-card">
                <h4 className="card-title">{title}</h4>
                <h5 className="lead-count">
                    {isMoney && userInfo}
                    {count}
                    {isPercent}
                </h5>
            </div>
        </LeadCardWrapper>
    );
};
export default SimpleCard;
