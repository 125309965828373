import { getToken } from 'firebase/messaging';
import { messaging } from './firebase';
import axios from 'axios';
import ApiUrl from './network';

import { decrypt, encrypt } from './utils/encrypt';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');
const vapidKey = 'BM0bai4D3fhjHYiOUTVwtPdvKQ6LsOwWn_1sjfnXErKGGlobNm6U5Zvx3zOPVe3Y_H3gPdq_2a8jCAbVwG0Zzxc';

const requestPermission = async () => {
    try {
        const permission = await Notification.requestPermission();

        if (permission === 'granted') {
            const token = await getToken(messaging, {
                vapidKey
            });

            if (token) {
                try {
                    const data = {
                        user_id: localStorage.getItem('userId'),
                        token,
                        platform: 'WEB'
                    };
                    const payload = encrypt(JSON.stringify(data), keyResult, ivResult);
                    const response = await axios.post(`${ApiUrl.BaseUrl}/notifications/register`, JSON.stringify({ payload }), {
                        headers: {
                            'auth-token': localStorage.getItem('userToken'),
                            'Content-Type': 'application/json'
                        }
                    });

                    const decryptedResponse = response.data.message;
                } catch (error) {
                    console.error('Error sending notification request:', error);
                }
            }
        } else if (permission === 'denied') {
            console.warn('Push notification permission denied');
        }
    } catch (error) {
        console.error('Error requesting notification permission:', error);
    }
};

export default requestPermission;
