import { Divider, Paper } from '@mui/material';
import GenericPageHeader from 'components/GenericPageHeader';
import { useEffect, useState } from 'react';
import NoteAction from './NoteAction';
import NoteContent from './NoteContent';
import NoteHeader from './NoteHeader';
import NoteHeaderInfo from './NoteHeaderInfo';
import { useParams } from 'react-router-dom';
import { authFetch } from 'utils/axios-config';
import { errorToast, successToast } from 'utils/toast';

const NoteConfirmation = () => {
    const [data, setData] = useState({});
    const { id } = useParams();

    const [isLoading, setLoading] = useState(false);

    async function getSingleNoteById(id) {
        setLoading(true);
        try {
            const { data } = await authFetch.get(`/deliveryautomation/viewdeliverynote/${id}`);
            setData(data.data);
            setLoading(false);
            successToast('Data loaded successfully');
        } catch (error) {
            if (error.response.status !== 401) {
                const { message } = error.response.data;
                errorToast(message);
            }
        }
    }

    useEffect(() => {
        getSingleNoteById(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <GenericPageHeader title="Delivery Note Confirmation" breadcrumbs="Home | Delivery Note" />
            <Paper elevation={1} sx={{ width: '100%', overflow: 'hidden' }}>
                <NoteHeader />
                <Divider />
                <NoteHeaderInfo data={data} isLoading={isLoading} />
                <NoteContent data={data} isLoading={isLoading} />
                <NoteAction data={data} id={id} />
            </Paper>
        </div>
    );
};
export default NoteConfirmation;
