import DarkStoreTable from 'components/DarkStoreInventory/DarkStoreTable';
import GenericPageHeader from 'components/GenericPageHeader';
import Loader from 'components/Loader';
import { useDarkStoreContext } from 'context/darkstore-inventory/darkstore-context';
import { useEffect } from 'react';

const DarkStore = () => {
    const { getAllDarkStores, isLoading } = useDarkStoreContext();
    useEffect(() => {
        getAllDarkStores();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <GenericPageHeader breadcrumbs="Home | Dark Stores" title="All Dark Stores" />
            {isLoading ? (
                <Loader />
            ) : (
                <DarkStoreTable
                    th1="#"
                    th2="Name"
                    th3="Location"
                    th4="Threshold"
                    th5="GPS Co-ordinates"
                    th6="Status"
                    th7="Sales Rep"
                    th8="Actions"
                />
            )}
        </>
    );
};
export default DarkStore;
