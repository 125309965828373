/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
// import Controls from '../../components/controls/Controls';
// import { Search } from '@mui/icons-material';
// import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@mui/icons-material/Preview';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
// import swal from 'sweetalert';
// encrypt
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import { decrypt } from '../../utils/encrypt';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const SalesPod = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [salesPodInfo, setSalesPod] = useState([]);
    // const [stageData, setStageData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searched, setSearched] = useState('');
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');

    const handleEditClick = (row) => {
        if (scriptedRef.current) {
            history(`/editsalespod/${row._id}`);
        }
    };

    const handleViewClick = (row) => {
        if (scriptedRef.current) {
            history(`/viewsalespod/${row._id}`);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - salesPodInfo.length) : 0;

    const retrieveAllSalesPod = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.PodUrlsMain, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setSalesPod(answer);
                        // console.log('result', answer);

                        // setStage(result);
                        // setStageData(data.data);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                        toast.error(answer);

                        setLoading(false);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        // retrieve all sales pod;
        retrieveAllSalesPod();
    }, []);

    // const handleDeleteClick = (row) => {
    //     swal({
    //         title: 'Are you sure?',
    //         text: `Once deleted, you will not be able to recover this Sales Pod(${row.name})`,
    //         icon: 'warning',
    //         buttons: true,
    //         dangerMode: true
    //     }).then((willDelete) => {
    //         if (willDelete) {
    //             swal('Poof! The Sales Pod has been deleted!', {
    //                 icon: 'success'
    //             });

    //             const data = {
    //                 id: row._id
    //             };
    //             const userInfo = localStorage.getItem('userToken');

    //             const obj = JSON.stringify(data);
    //             const result = encrypt(obj, keyResult, ivResult);

    //             fetch(ApiUrl.RemoveSalesPodUrl, {
    //                 method: 'DELETE',
    //                 headers: {
    //                     'auth-token': userInfo,
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify({ payload: result })
    //             })
    //                 .then((res) => res.json())
    //                 .then((result) => {
    //                     if (result.status === true) {
    //                         if (scriptedRef.current) {
    //                             retrieveAllSalesPod();
    //                         }
    //                     } else {
    //                         toast.error(result.data);
    //                     }
    //                 });
    //         } else {
    //             swal('The Sales Pod is safe!');
    //         }
    //     });
    // };

    // const handleSearch = (event) => {
    //     const searchWord = event.target.value;

    //     const newFilter = stageInfo.filter((value) => value.name.toLowerCase().includes(searchWord.toLowerCase()));

    //     if (searchWord === '') {
    //         setStage(stageData);
    //     } else {
    //         setStage(newFilter);
    //     }
    // };
    const filteredRows = salesPodInfo.filter((row) => (row?.name || '').toLowerCase().includes(searched.toLowerCase()));
    const isPlural = filteredRows && filteredRows.length > 1 ? 's' : '';
    const searchCount = searched ? `${filteredRows.length} result${isPlural} found` : 'No Results';

    return (
        <>
            <br />
            <PageHeader title="Sales Pod" subTitle="Home | Configuration | Setups | Sales Pod" />
            <br />
            <MainCard title="Sales Pod List">
                <h4 style={{ color: '#ff740f', fontWeight: '700' }}>
                    {searched ? searchCount : `Total Sales Pod: ${filteredRows.length}`}
                </h4>
                <br />
                <Box display="flex" justifyContent="space-between">
                    <Grid>
                        <FormControl fullWidth style={{ marginBottom: '10px' }}>
                            <TextField
                                id="outlined-basic"
                                label="Search Name"
                                variant="outlined"
                                onChange={(e) => setSearched(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Box width={10} />

                    <Button component={Link} to="/addsalespod" variant="contained" size="sm" className={classes.item}>
                        Add
                    </Button>
                </Box>
                <br />

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Pod Name
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Country
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            City
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Status
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Date
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : filteredRows
                            ).map((row) => (
                                <TableRow key={row._id}>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.country}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.cityId?.city ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.status}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {toTimestamp(row.createdAt)}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        <IconButton aria-label="view" color="success" onClick={() => handleViewClick(row)}>
                                            <ViewIcon />
                                        </IconButton>
                                        <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(row)}>
                                            <EditIcon />
                                        </IconButton>
                                        {/* <IconButton aria-label="delete" color="error" onClick={() => handleDeleteClick(row)}>
                                            <DeleteIcon />
                                        </IconButton> */}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    component="div"
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default SalesPod;
