// assets
import { IconKey, IconTruckDelivery } from '@tabler/icons';
import DashboardIcon from '@mui/icons-material/Dashboard';
// import Person from '@mui/icons-material/Person';
import CarCrashIcon from '@mui/icons-material/CarRental';
// import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import Scale from '@mui/icons-material/Scale';
// import Shop from '@mui/icons-material/Shop2';
import Trans from '@mui/icons-material/Money';
import CollectionsIcon from '@mui/icons-material/Collections';
import BugReportIcon from '@mui/icons-material/BugReport';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import CategoryIcon from '@mui/icons-material/Category';
// import { SiSimpleanalytics } from 'react-icons/si';
// constant
const icons = {
    IconKey,
    IconTruckDelivery,
    DashboardIcon,
    Scale,
    Trans,
    CarCrashIcon,
    CollectionsIcon,
    BugReportIcon,
    FactCheckIcon,
    ViewModuleIcon,
    EmojiTransportationIcon,
    CategoryIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //
const userRole = localStorage.getItem('userRole');
const logistics = {
    id: 'logistics',
    title: 'Logistics',
    type: userRole,
    children: [
        {
            id: 'home',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: icons.DashboardIcon,
            breadcrumbs: false
        },
        {
            id: 'attendance',
            title: 'Attendance',
            type: 'collapse',
            icon: icons.FactCheckIcon,
            children: [
                // {
                //     id: 'imageUpload',
                //     title: 'Image Upload',
                //     type: 'item',
                //     url: '/image-upload',
                //     breadcrumbs: false
                // },

                {
                    id: 'checkIn',
                    title: 'check In',
                    type: 'item',
                    url: '/check-in',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'orderswaybill',
            title: 'Waybill',
            type: 'collapse',
            icon: icons.ViewModuleIcon,
            children: [
                {
                    id: 'orders',
                    title: 'Batch order',
                    type: 'item',
                    url: '/waybill/orders',
                    icon: icons.ViewModuleIcon,
                    breadcrumbs: false
                },
                {
                    id: 'batch',
                    title: 'Generate waybill',
                    url: '/waybill/batch',
                    type: 'item',
                    icon: icons.EmojiTransportationIcon,
                    breadcrumbs: false
                },
                {
                    id: 'waybill',
                    title: 'View waybill',
                    type: 'item',
                    url: '/generated/waybill',
                    icon: icons.EmojiTransportationIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'orders',
            title: 'Orders',
            type: 'item',
            url: '/order',
            icon: icons.Scale,
            breadcrumbs: false
        },
        {
            id: 'proformacssorders',
            title: 'Proforma Orders',
            type: 'item',
            url: '/proformacssorders',
            icon: icons.Trans,
            breadcrumbs: false
        },
        {
            id: 'presoldorders',
            title: 'PreSold Orders',
            type: 'item',
            url: '/presoldorders',
            icon: icons.Trans,
            breadcrumbs: false
        },
        {
            id: 'csssalesdriveorders',
            title: 'Sales Drive Orders',
            type: 'item',
            icon: icons.Scale,
            url: '/csssalesdriveorders',
            breadcrumbs: false
        },
        {
            id: 'retentionorders',
            title: 'Retention Drive Orders',
            type: 'item',
            icon: icons.Scale,
            url: '/retentionorders',
            breadcrumbs: false
        },
        {
            id: 'conversionorders',
            title: 'Conversion Drive Orders',
            type: 'item',
            icon: icons.Scale,
            url: '/conversionorders',
            breadcrumbs: false
        },
        {
            id: 'parts',
            title: 'Parts',
            type: 'collapse',
            icon: icons.CarCrashIcon,

            children: [
                {
                    id: 'masterdb',
                    title: 'Master Database',
                    type: 'item',
                    url: '/parts'
                }
            ]
        },
        {
            id: 'fleet',
            title: 'Manage fleet',
            type: 'collapse',
            icon: icons.WifiFindIcon,
            children: [
                {
                    id: 'fleet',
                    title: 'Fleet Schedule',
                    type: 'item',
                    url: '/fleet'
                },
                {
                    id: 'addvehicles',
                    title: 'Add Vehicles',
                    type: 'item',
                    url: '/fleet/add-vehicles'
                },
                {
                    id: 'allvehicles',
                    title: 'Vehicles',
                    type: 'item',
                    url: '/fleet/all-vehicles'
                }
            ]
        }
    ]
};

export default logistics;
