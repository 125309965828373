import styled from 'styled-components';
import Logo from '../../assets/images/garage_logo.png';

const Wrapper = styled.div`
    font-family: 'Roboto';
    .page-header {
        padding: 1rem 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media screen and (max-width: 500px) {
        .page-header {
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }
    }

    h5 {
        font-weight: 700;
        color: #444;
    }
    .purchase {
        font-weight: 600;
    }
    p {
        line-height: 1.2;
        a {
            text-decoration: none;
            color: #1f88e5;
            font-weight: 700;
        }
    }
`;

const ReceiptHeader = () => {
    return (
        <Wrapper>
            <div className="page-header">
                <div className="logo">
                    <img src={Logo} alt="Garage identity" width={200} />
                </div>
                <div className="identity">
                    <h5>Garage Mobility Limited</h5>
                    <p>
                        <a href="https://garagemobility.com/">www.garagemobility.com</a>
                    </p>
                    <p className="purchase">Purchase Receipt</p>
                </div>
            </div>
        </Wrapper>
    );
};
export default ReceiptHeader;
