import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useScriptRef from 'hooks/useScriptRef';
import ApiUrl from 'network';
import { toast } from 'react-toastify';

const AddRole = () => {
    const [name, setName] = useState('');
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    // const [status, setStatus] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        console.log(userInfo);

        const data = {
            name
        };

        fetch(ApiUrl.CreateRoleUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);

                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/roles');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                }
                // console.log(result);
            });
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <br />
                    <PageHeader title="Roles" subTitle="Home | Configuration | User Management | Roles" />
                    <br />
                    <MainCard title="Add Role">
                        <form onSubmit={handleSubmit}>
                            <TextField
                                autoComplete="off"
                                name="name"
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                label="Role Name"
                                onChange={(e) => setName(e.target.value)}
                                autoFocus
                            />
                            <br />
                            <br />
                            <Box display="flex" justifyContent="space-between">
                                <Link to="/roles">
                                    <Button type="submit" variant="contained" color="error">
                                        Reset
                                    </Button>
                                </Link>

                                <Button type="submit" variant="contained" color="secondary">
                                    Submit
                                </Button>
                            </Box>
                        </form>
                    </MainCard>
                </Grid>
            </Grid>
        </>
    );
};

export default AddRole;
