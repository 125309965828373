export const DARKSTORE_BEGIN = 'DARKSTORE_BEGIN';
export const DARKSTORE_SUCCESS = 'DARKSTORE_SUCCESS';

export const ADD_PART_BEGIN = 'ADD_PART_BEGIN';
export const ADD_PART_SUCCESS = 'ADD_PART_SUCCESS';
export const ADD_PART_FAILURE = 'ADD_PART_FAILURE';

export const INVENTORY_BEGIN = 'INVENTORY_BEGIN';
export const INVENTORY_SUCCESS = 'INVENTORY_SUCCESS';
export const INVENTORY_FAILURE = 'INVENTORY_FAILURE';

export const UPDATE_BEGIN = 'UPDATE_BEGIN';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'UPDATE_FAILURE';

export const STORE_STATS_BEGIN = 'STORE_STATS_BEGIN';
export const STORE_STATS_SUCCESS = 'STORE_STATS_SUCCESS';
export const STORE_STATS_FAILURE = 'STORE_STATS_FAILURE';

export const PART_HISTORY_BEGIN = 'PART_HISTORY_BEGIN';
export const PART_HISTORY_SUCCESS = 'PART_HISTORY_SUCCESS';
export const PART_HISTORY_FAILURE = 'PART_HISTORY_FAILURE';
