import { Link, useLocation } from 'react-router-dom';
import { ArrowLeftOutlined, CopyOutlined, LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Input, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { decrypt, encrypt } from '../../../utils/encrypt';

const baseUrl = 'https://apistaging.garagemobility.com/api';
const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

const process = '../../moving_clock.png';
const success = '../../lets-icons_check-fill.png';
const failed = '../../ic_baseline-cancel.png';
const money = '../../money-send.png';

export const MoneyTransfer = () => {
    const [responseState, setResponseState] = useState(''); // Possible states:  'processing', 'success', 'failed'
    const [timeLeft, setTimeLeft] = useState(10);

    const location = useLocation();
    const paymentData = location.state?.option;

    useEffect(() => {}, [paymentData]);

    useEffect(() => {
        if (timeLeft === 0) {
            // window.location.href = '/'; // Redirect when timer hits 0
            return;
        }

        const timer = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000); // Update every second

        // eslint-disable-next-line consistent-return
        return () => clearInterval(timer); // Cleanup interval on component unmount
    }, [timeLeft]);

    // Format time left as MM:SS
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secondsLeft = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secondsLeft).padStart(2, '0')}`;
    };

    const spinnerIcon = (
        <LoadingOutlined
            style={{
                fontSize: 64,
                color: '#FF740F'
            }}
            spin
        />
    );

    const confirmPaymentHandler = async () => {
        // reset timeLeft
        setTimeLeft(10);
        setResponseState('processing');
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        const datas = {
            reference: paymentData?.reference
        };
        const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);

        try {
            await fetch(`${baseUrl}/order/v2/payments/paga/status`, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify({ payload })
            })
                .then((data) => data.json())
                .then((data) => {
                    const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));
                    if (decryptedData.status.toLowerCase() === 'failed') {
                        toast.error(decryptedData.message);
                        setResponseState('failed');
                    } else if (decryptedData.status.toLowerCase() === 'pending') {
                        setResponseState('processing');
                        toast.warning(decryptedData.message);
                    } else {
                        setResponseState('success');
                        toast.success(decryptedData.message);
                    }
                });
        } catch (error) {
            const errorMessage = error.message;
            toast.error(errorMessage);
            setResponseState('failed');
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then((r) => r);
        toast.success('Copied to clipboard');
    };

    return (
        <div>
            <div className="m-header">
                <div>
                    <Link to="/payment-transactions">
                        <h1 style={{ color: '#0A2938', display: 'flex', alignItems: 'center' }}>
                            <ArrowLeftOutlined className="mr-2" /> {paymentData?.name}
                        </h1>
                    </Link>
                </div>
            </div>
            <div className="m-bank-body">
                <div className="bank-actions text-center">
                    <img
                        style={{ width: '60px', height: '60px', marginBottom: '20px', objectFit: 'contain' }}
                        src={money}
                        alt="Money Transfer"
                    />
                    <h3 style={{ color: '#193B56' }}>Payment link created</h3>
                    <p>
                        Your payment link has been successfully <br /> created for NGN {paymentData.amount} transfer{' '}
                    </p>
                    <div className="mb-4 mt-4">
                        <Input
                            readOnly
                            style={{
                                backgroundColor: '#EDF1FF',
                                color: '#000',
                                fontWeight: 'bold',
                                border: 'none',
                                outline: 'none'
                            }}
                            value={paymentData?.properties?.WebPaymentLink}
                            addonAfter={
                                <Button
                                    style={{
                                        backgroundColor: 'transparent',
                                        width: 'fit-content',
                                        border: 'none',
                                        outline: 'none'
                                    }}
                                    onClick={() => copyToClipboard(paymentData?.properties?.WebPaymentLink)}
                                >
                                    Copy Link <CopyOutlined />
                                </Button>
                            }
                            size="large"
                        />
                    </div>
                    <p>Your transfer countdown starts once transfer is processing, kindly wait for Payment to be approved or declined.</p>
                    <div className="mb-4 d-flex justify-content-center align-items-center text-center mt-4">
                        <Button
                            type="primary"
                            size="large"
                            style={{
                                width: 'fit-content',
                                backgroundColor: '#FF740F'
                            }}
                            onClick={confirmPaymentHandler}
                        >
                            Confirm payment
                        </Button>
                    </div>
                </div>
                <div className="bank-response">
                    {responseState === 'processing' && (
                        <div className="text-center">
                            <img
                                style={{ width: '100px', height: '100px', marginBottom: '20px', objectFit: 'contain' }}
                                src={process}
                                alt="processing"
                            />
                            <p style={{ color: '#0A2938', fontSize: '22px', fontWeight: 'bold' }}>
                                Kindly wait as we process <br /> payment
                            </p>
                            <p
                                style={{
                                    fontSize: '16px',
                                    color: '#9DA9AF',
                                    lineHeight: '24px',
                                    textAlign: 'center'
                                }}
                            >
                                Payment is currently processing, if payment <br /> process is not complete under the estimated <br /> time,
                                kindly try again later.
                                <span style={{ color: '#4B6EFF', marginLeft: '8px' }}>{formatTime(timeLeft)}</span>
                            </p>
                            {timeLeft <= 0 && (
                                <div style={{ marginTop: '20px' }}>
                                    <Button
                                        onClick={confirmPaymentHandler}
                                        style={{
                                            color: '#FF740F',
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            borderRadius: '5px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Try Again
                                    </Button>
                                    <Button
                                        onClick={() => (window.location.href = '/payment-transactions')}
                                        style={{
                                            color: '#FF740F',
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            borderRadius: '5px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                    {responseState === 'success' && (
                        <div className="text-center">
                            <img
                                style={{ width: '100px', height: '100px', marginBottom: '20px', objectFit: 'contain' }}
                                src={success}
                                alt="Payment Successful"
                            />
                            <p style={{ color: '#0A2938', fontSize: '22px', fontWeight: 'bold' }}>Payment Successful!</p>
                            <p
                                style={{
                                    fontSize: '16px',
                                    color: '#9DA9AF',
                                    lineHeight: '24px'
                                }}
                            >
                                Your Payment has been successfully <br /> completed, kindly return back to home screen
                            </p>
                            <Button
                                onClick={() => (window.location.href = '/payment-transactions')}
                                type="primary"
                                size="large"
                                style={{
                                    width: '50%',
                                    marginTop: '20px',
                                    backgroundColor: '#FF740F'
                                }}
                            >
                                Return to home
                                <Spin
                                    className="ml-2"
                                    indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />}
                                    size="small"
                                />
                            </Button>
                        </div>
                    )}
                    {responseState === 'failed' && (
                        <div className="text-center">
                            <img
                                style={{ width: '100px', height: '100px', marginBottom: '20px', objectFit: 'contain' }}
                                src={failed}
                                alt="Payment Declined"
                            />
                            <p style={{ color: '#0A2938', fontSize: '22px', fontWeight: 'bold' }}>Payment Declined!</p>
                            <p
                                style={{
                                    fontSize: '16px',
                                    color: '#9DA9AF',
                                    lineHeight: '24px'
                                }}
                            >
                                Your Payment has been declined, <br /> kindly take required action
                            </p>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    width: '100%',
                                    marginTop: '20px'
                                }}
                            >
                                <Button
                                    onClick={() => (window.location.href = '/payment-transactions')}
                                    type="primary"
                                    size="large"
                                    style={{
                                        width: '50%',
                                        marginTop: '20px',
                                        backgroundColor: '#FF740F'
                                    }}
                                >
                                    Return back to home screen
                                </Button>
                                <Button
                                    onClick={confirmPaymentHandler}
                                    type="primary"
                                    size="large"
                                    style={{
                                        width: '50%',
                                        marginTop: '20px',
                                        backgroundColor: '#fff',
                                        color: '#000',
                                        border: '1px solid #000'
                                    }}
                                >
                                    <ReloadOutlined className="mr-2" /> Retry payment
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MoneyTransfer;
