import React, { useEffect, useRef, useState } from 'react';
import { Table, Divider, Paper, Space, Button, Select, Modal, Checkbox, Alert, Input } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import ApiUrl from 'network';
import { ToastContainer, toast } from 'react-toastify';
// import PaymentAlert from './PaymentAlert';
import { encrypt, decrypt } from '../../utils/encrypt';
import './rmaTable.css';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const RmaReceiptConfirmModal = ({
    handleReceiptConfirmCancel,
    handleReceiptConfirmOk,
    receiptConfirmOpen,
    paymentType,
    amountDeposited,
    handlePaymentType,
    setAmountDeposited,
    addCollection,
    showReceiptConfirmModal,
    handleReceiptCancel
}) => {
    return (
        <div>
            <Modal
                open={receiptConfirmOpen}
                onOk={handleReceiptConfirmOk}
                centered
                width={385}
                // zIndex={4400}
                onCancel={handleReceiptConfirmCancel}
                footer={
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                            style={{
                                backgroundColor: '#FFFFFF',
                                borderRadius: '8px',
                                border: '1px solid #FF740F',
                                height: '48px',
                                fontSize: '18px',
                                fontWeight: '700',
                                color: '#FF740F ',
                                width: '154px',
                                textAlign: 'center'
                            }}
                            onClick={() => {
                                handleReceiptConfirmCancel();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{
                                backgroundColor: '#FF740F',
                                borderRadius: '8px',
                                height: '48px',
                                fontSize: '18px',
                                fontWeight: '700',
                                color: '#FFFFFF',
                                width: '154px',
                                textAlign: 'center'
                            }}
                            onClick={() => {
                                addCollection();
                                handleReceiptConfirmOk();
                                toast.success('Processing...');
                            }}
                        >
                            Continue
                        </Button>
                    </Box>
                }
            >
                <div className="">
                    <Typography
                        sx={{
                            fontSize: '20px',
                            color: '#546974',
                            padding: '0',
                            fontWeight: '500',
                            textAlign: 'center'
                        }}
                    >
                        Confirm details
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            // backgroundColor: '#f6f6f6',
                            // borderRadius: '12px',
                            // border: '1px solid #e7eaeb',
                            height: '200px',
                            overflow: 'scroll'
                            // paddingBottom: '20px'
                        }}
                    >
                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974', margin: '0px 0 10px 0px' }}>
                                Amount Deposited
                            </Typography>
                            <Input
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                placeholder="Amount"
                                value={amountDeposited}
                                onChange={(e) => setAmountDeposited(e.target.value)}
                                type="number"
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '24px 0 0 0'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974', margin: '0px 0 10px 0px' }}>
                                Payment Type
                            </Typography>
                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                allowClear
                                placeholder="Order Status"
                                optionFilterProp="children"
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                options={[
                                    {
                                        value: 'Cash',
                                        label: 'Cash'
                                    },
                                    {
                                        value: 'Cheque',
                                        label: 'Cheque'
                                    },
                                    {
                                        value: 'Bank account',
                                        label: 'Bank account'
                                    },
                                    {
                                        value: 'Momo',
                                        label: 'Momo'
                                    }
                                ]}
                                value={paymentType}
                                onChange={(value) => {
                                    handlePaymentType(value);
                                }}
                            />
                        </Box>
                    </Box>
                </div>
            </Modal>
        </div>
    );
};

export default RmaReceiptConfirmModal;
