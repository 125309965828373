/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from 'assets/images/garage.png';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import AuthCardWrapper from '../pages/authentication/AuthCardWrapper';
import AuthWrapper1 from '../pages/authentication/AuthWrapper1';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ApiUrl from 'network';
import useScriptRef from 'hooks/useScriptRef';
import CircularProgress from '@mui/material/CircularProgress';

// const useStyles = makeStyles(() => ({
//     tableHeaderCell: {
//         fontWeight: 'bold'
//     },
//     item: {
//         padding: 0
//     }
// }));

const PreviewSalesPartners = () => {
    // const classes = useStyles();
    const history = useNavigate();
    // const [channelId, setChannelId] = useState('');
    // const [newChannelId, setNewChannelId] = useState('');
    const [partnerName, setpartnerName] = useState('');
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [shopName, setshopName] = useState('');
    const [partnerContact, setpartnerContact] = useState('');
    const [locationLat, setlocationLat] = useState('');
    const [locationLng, setlocationLng] = useState('');
    const [dateOnboarded, setdateOnboarded] = useState(new Date());
    const [stageId, setstageId] = useState('');
    // const [salesTeamId, setsalesTeamId] = useState('');
    const [signupDate, setsignupDate] = useState(new Date());
    // const [channel, setChannel] = useState([]);
    const [loading2, setLoading2] = useState(false);
    // const [loading, setLoading] = useState(false);
    const [stageInfo, setStage] = useState([]);
    // const [salesTeamInfo, setSalesTeam] = useState([]);
    // const [error, setError] = useState('');
    // const [correct, setCorrect] = useState('');
    const [rating, setrating] = useState('');
    // const [loading4, setLoading4] = useState(false);
    const [gender, setgender] = useState('');
    const [salesnotes, setsalesnotes] = useState('');

    // const retrieveAllChannel = async () => {
    //     try {
    //         const config = {
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         };
    //         setLoading2(true);
    //         fetch(ApiUrl.ChannelActiveSalesPage, config)
    //             .then((data) => data.json())
    //             .then((data) => {
    //                 if (data.status === true) {
    //                     setChannel(data.data);
    //                 } else {
    //                     toast.error(data.data);
    //                 }
    //             })
    //             .finally(() => {
    //                 setLoading2(false);
    //             });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    // const retrieveAlLsalesTeam = async () => {
    //     try {
    //         const config = {
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         };
    //         setLoading2(true);
    //         fetch(ApiUrl.SalesTeamActiveSalesPage, config)
    //             .then((data) => data.json())
    //             .then((data) => {
    //                 if (data.status === true) {
    //                     setSalesTeam(data.data);
    //                 } else {
    //                     toast.error(data.data);
    //                 }
    //             })
    //             .finally(() => {
    //                 setLoading2(false);
    //             });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    const retrieveAllStage = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.AllSalesStageUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setStage(data.data);
                        setLoading2(false);
                    } else {
                        toast.error(data.data);
                        setLoading2(false);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    const scriptedRef = useScriptRef();

    const { id } = useParams();

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.ViewAllChannelPartnerURL + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setpartnerName(result?.data?.partnerName ?? '');
                    setfirstName(result?.data?.firstName ?? '');
                    setlastName(result?.data?.lastName ?? '');
                    setshopName(result?.data?.shopName ?? '');
                    setpartnerContact(result?.data?.partnerContact ?? '');
                    setstageId(result?.data?.stageId ?? '');
                    setsignupDate(result?.data?.signupDate ?? '');
                    // setsalesTeamId(result?.data?.salesTeamId ?? '');
                    setlocationLat(result?.data?.locationLat ?? '');
                    setlocationLng(result?.data?.locationLng ?? '');
                    setdateOnboarded(result?.data?.dateOnboarded ?? '');
                    setrating(result?.data?.rating ?? '');
                    setgender(result?.data?.gender ?? '');
                    setsalesnotes(result?.data?.salesnotes ?? '');
                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                }
            })
            .finally(() => {
                setLoading2(false);
            });
        // retrieveAllChannel();
        retrieveAllStage();
        // retrieveAlLsalesTeam();
    }, [id]);

    const handleSignupChange = (newValue) => {
        setsignupDate(newValue);
    };

    const handleStageChange = (event) => {
        setstageId(event.target.value);
    };
    const handleGenderChange = (event) => {
        setgender(event.target.value);
    };

    // const handleSalesChange = (event) => {
    //     setsalesTeamId(event.target.value);
    // };

    const handleDateOnBoardedChange = (newValue) => {
        setdateOnboarded(newValue);
    };

    const handleRatingChange = (event) => {
        setrating(event.target.value);
    };

    // const handleGeoLocation = () => {
    //     setLoading4(true);
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(
    //             (res) => {
    //                 setlocationLat(res.coords.latitude);
    //                 setlocationLng(res.coords.longitude);
    //                 // console.log(res.coords.latitude, res.coords.longitude);
    //                 setCorrect('success');
    //                 setLoading4(false);
    //             },
    //             (err) => {
    //                 if (err.code === 2) {
    //                     setError('Error : Please Check internet connnection');
    //                     setLoading4(false);
    //                     setCorrect('failed');
    //                     // console.log('Error : Please Check internet connnection');
    //                 } else {
    //                     // console.log('Error : Please Enable Browser location Accessn');
    //                     setError('Error : Please Enable Browser location Accessn');
    //                     setLoading4(false);
    //                     setCorrect('failed');
    //                 }
    //             }
    //         );
    //     } else {
    //         //  console.log('Geolocation is not supported by this browser');
    //         setError('Geolocation is not supported by this browser');
    //         setLoading4(false);
    //         setCorrect('failed');
    //     }
    // };

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');

        const data = {
            id,
            firstName,
            lastName,
            shopName,
            partnerContact,
            locationLat,
            locationLng,
            stageId,
            dateOnboarded,
            rating,
            signupDate,
            salesnotes
        };
        // setLoading(true);
        fetch(ApiUrl.UpdateChannelPartnerURL, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status) {
                    toast.success(result.message);
                    // setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/salespartner');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    // setLoading(false);
                }
            })
            .finally(() => {
                // setLoading(false);
            });
    };
    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <AuthWrapper1>
                        <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                            <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                                    <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                                        <AuthCardWrapper>
                                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                                <Grid item sx={{ mb: 3 }}>
                                                    <Link to="#">
                                                        <img src={logo} alt="logo" height={50} />
                                                    </Link>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Typography variant="h3" component="h2" textAlign="center">
                                                        View Retailer Information
                                                    </Typography>
                                                    <br />

                                                    <br />
                                                    <form onSubmit={handleSubmit}>
                                                        <TextField
                                                            autoComplete="off"
                                                            name="partnerName"
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled
                                                            required
                                                            id="partnerName"
                                                            label="Partner Name"
                                                            value={partnerName}
                                                            onChange={(e) => setpartnerName(e.target.value)}
                                                            autoFocus
                                                        />
                                                        <br />
                                                        <br />
                                                        <TextField
                                                            autoComplete="off"
                                                            name="firstName"
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled
                                                            id="firstName"
                                                            label="First Name"
                                                            value={firstName}
                                                            onChange={(e) => setfirstName(e.target.value)}
                                                            autoFocus
                                                        />

                                                        <br />
                                                        <br />

                                                        <TextField
                                                            autoComplete="off"
                                                            name="lastName"
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled
                                                            id="lastName"
                                                            label="Last Name"
                                                            value={lastName}
                                                            onChange={(e) => setlastName(e.target.value)}
                                                        />

                                                        <br />
                                                        <br />

                                                        <TextField
                                                            autoComplete="off"
                                                            name="shopName"
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled
                                                            id="shopName"
                                                            label="Shop Name"
                                                            value={shopName}
                                                            onChange={(e) => setshopName(e.target.value)}
                                                        />

                                                        <br />
                                                        <br />

                                                        <TextField
                                                            autoComplete="off"
                                                            type="number"
                                                            name="partnerContact"
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled
                                                            required
                                                            id="partnerContact"
                                                            label="Phone"
                                                            value={partnerContact}
                                                            onChange={(e) => setpartnerContact(e.target.value)}
                                                        />
                                                        <br />
                                                        <br />
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={gender}
                                                                required
                                                                disabled
                                                                label="Status"
                                                                onChange={handleGenderChange}
                                                            >
                                                                <MenuItem value="Body">Select an option</MenuItem>
                                                                <MenuItem value="Male">Male</MenuItem>
                                                                <MenuItem value="Female">Female</MenuItem>
                                                                <MenuItem value="Unknown">Unknown</MenuItem>
                                                                <MenuItem value="PreferNotToAnswer">Prefer not to answer</MenuItem>
                                                            </Select>
                                                        </FormControl>

                                                        <br />
                                                        <br />

                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">Rating</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={rating}
                                                                disabled
                                                                required
                                                                label="Status"
                                                                onChange={handleRatingChange}
                                                            >
                                                                <MenuItem value="Body">Select an option</MenuItem>
                                                                <MenuItem value="High">High</MenuItem>
                                                                <MenuItem value="Medium">Medium</MenuItem>
                                                                <MenuItem value="Low">Low</MenuItem>
                                                            </Select>
                                                        </FormControl>

                                                        <br />
                                                        <br />
                                                        {/* {correct === 'success' ? (
                                                            <>
                                                                <Typography variant="h3" component="h2" textAlign="center" color="#008000">
                                                                    Success
                                                                </Typography>
                                                            </>
                                                        ) : (
                                                            <Typography variant="h3" component="h2" textAlign="center" color="#FF0000">
                                                                {error}
                                                            </Typography>
                                                        )} */}
                                                        {/* <br />
                                                        <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                                            <Button variant="contained" color="info" onClick={handleGeoLocation}>
                                                                {loading4 ? 'fetching....' : 'Get Location Coordinates'}
                                                            </Button>
                                                        </Box>
                                                        <br /> */}

                                                        <TextField
                                                            autoComplete="off"
                                                            name="locationLat"
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled
                                                            id="locationLat"
                                                            value={locationLat}
                                                            label="Latitude"
                                                            onChange={(e) => setlocationLat(e.target.value)}
                                                        />
                                                        <br />
                                                        <br />

                                                        <TextField
                                                            autoComplete="off"
                                                            name="locationLng"
                                                            variant="outlined"
                                                            disabled
                                                            fullWidth
                                                            id="locationLng"
                                                            value={locationLng}
                                                            label="longitude"
                                                            onChange={(e) => setlocationLng(e.target.value)}
                                                        />

                                                        <br />
                                                        <br />

                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">Stage</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={stageId}
                                                                disabled
                                                                required
                                                                label="Status"
                                                                onChange={handleStageChange}
                                                            >
                                                                {stageInfo.map((stage) => (
                                                                    <MenuItem key={stage._id} value={stage._id}>
                                                                        {stage.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>

                                                        <br />
                                                        <br />

                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <Stack spacing={3}>
                                                                <DesktopDatePicker
                                                                    label="Date Contacted"
                                                                    disabled
                                                                    inputFormat="MM/dd/yyyy"
                                                                    value={signupDate}
                                                                    onChange={handleSignupChange}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                />
                                                            </Stack>
                                                        </LocalizationProvider>
                                                        <br />
                                                        <br />

                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <Stack spacing={3}>
                                                                <DesktopDatePicker
                                                                    label="Date Onboarded"
                                                                    disabled
                                                                    inputFormat="MM/dd/yyyy"
                                                                    value={dateOnboarded}
                                                                    onChange={handleDateOnBoardedChange}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                />
                                                            </Stack>
                                                        </LocalizationProvider>
                                                        <br />
                                                        <br />

                                                        <TextField
                                                            autoComplete="off"
                                                            name="salesnotes"
                                                            variant="outlined"
                                                            multiline
                                                            disabled
                                                            rows={4}
                                                            value={salesnotes}
                                                            fullWidth
                                                            id="salesnotes"
                                                            label="Sales Comment"
                                                            onChange={(e) => setsalesnotes(e.target.value)}
                                                        />

                                                        <br />
                                                        <br />

                                                        <Box display="flex" justifyContent="space-between">
                                                            <Button
                                                                component={Link}
                                                                to="/salespartner"
                                                                type="submit"
                                                                variant="contained"
                                                                color="error"
                                                            >
                                                                Reset
                                                            </Button>
                                                        </Box>
                                                    </form>
                                                </Grid>
                                            </Grid>
                                        </AuthCardWrapper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AuthWrapper1>
                </>
            )}
        </>
    );
};

export default PreviewSalesPartners;
