import { Button, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import ApiUrl from '../../network';
import { toast } from 'react-toastify';
import { decrypt } from '../../utils/encrypt';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const [loading, setLoading] = useState(false);
    const [partsData, setPartsData] = useState([]);
    const [part, setParts] = useState({
        skuId: '',
        qty: '',
        partsImage: '',
        lat: '',
        lng: ''
    });
    const [discountData, setDiscountData] = useState({});

    const fetchParts = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/order/v2/allparts`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                setPartsData(decryptedData);
            } else {
                toast.error(result.message);
            }
        } catch (e) {
            toast(e.message);
        }
    };

    const getDiscountPrice = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/parts/v2/partspbasedonqty?skuId=${part.skuId}&qty=${part.qty}&lat=${part.lat}&lng=${part.lng}`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                setDiscountData(decryptedData);
                setLoading(false);
            } else {
                setLoading(false);
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                toast.error(decryptedData);
            }
        } catch (e) {
            toast(e.message);
            setLoading(false);
        }
    };

    const getUserLatLng = async () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setParts((prevState) => ({
                        ...prevState,
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }));
                },
                (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        toast.error('Location permission denied. Please enable location services in your browser settings.');
                    } else {
                        toast.error('Error fetching location. Please try again.');
                    }
                }
            );
        } else {
            toast.error('Geolocation is not supported by this browser.');
        }
    };

    const askUserLocation = () => {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
            if (result.state === 'granted') {
                getUserLatLng().then((r) => r);
            } else if (result.state === 'prompt') {
                getUserLatLng().then((r) => r);
            } else if (result.state === 'denied') {
                toast.error('Location permission denied. Please enable location services in your browser settings.');
            }
        });
    };

    useEffect(() => {
        getUserLatLng().then((r) => r);
        fetchParts().then((r) => r);
    }, []);
    const partsOptions = partsData.map((part) => {
        return {
            value: part._id,
            label: part.sku
        };
    });

    const handleInputChange = (field, value) => {
        setParts((prevState) => {
            return {
                ...prevState,
                [field]: value
            };
        });
    };

    return (
        <div>
            <div className="m-header">
                <div>
                    <h1 style={{ color: '#0A2938', display: 'flex', alignItems: 'center' }}>
                        <ArrowLeftOutlined className="mr-2" /> View product discount
                    </h1>
                </div>
                <span>
                    {!part.lat && !part.lng && (
                        <Button
                            onClick={() => askUserLocation()}
                            style={{ backgroundColor: '#F34822', color: '#fff', padding: '4px', borderRadius: '4px' }}
                        >
                            Enable location
                        </Button>
                    )}
                </span>
            </div>
            <div className="m-content m-add-page-content mt-4 bg-white" style={{ borderRadius: '10px' }}>
                <div>
                    <div className="m-image-container">
                        <div className="image-placeholder">
                            <img
                                className="m-product-img"
                                src={part?.partsImage || 'https://via.placeholder.com/150'}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = 'https://via.placeholder.com/150';
                                }}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div className="ml-2">
                    <div className="d-flex">
                        <div className="form-group">
                            <p>Product name</p>
                            <Select
                                options={partsOptions}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }
                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                                className="m-select"
                                placeholder="Product name"
                                value={part.skuId || undefined}
                                onChange={(value) => {
                                    handleInputChange('skuId', value);
                                    const selectedPart = partsData.find((part) => part._id === value);
                                    setParts((prevState) => {
                                        return {
                                            ...prevState,
                                            partsImage: selectedPart ? selectedPart.partsImage : ''
                                        };
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <p>Quantity</p>
                            <Input placeholder="Quantity" value={part.qty} onChange={(e) => handleInputChange('qty', e.target.value)} />
                        </div>
                    </div>
                    {discountData.discountSellingPrice && (
                        <div>
                            <div className="d-flex">
                                <div className="form-group">
                                    <p>Original garge price</p>
                                    <Input placeholder="Original garge price" value={discountData.originalGargePrice} disabled />
                                </div>
                                <div className="form-group">
                                    <p>Discount selling price</p>
                                    <Input placeholder="Discount price" value={discountData.discountSellingPrice} disabled />
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="form-group">
                                    <p>City</p>
                                    <Input placeholder="City" value={discountData.city} disabled />
                                </div>
                                <div className="form-group">
                                    <p>Currency</p>
                                    <Input placeholder="Currency" value={discountData.currency} disabled />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="d-flex">
                        {(part.skuId?.length !== 0 || part.qty?.length !== 0) && (
                            <Button
                                style={{
                                    backgroundColor: 'transparent',
                                    color: '#FF740F',
                                    border: '1px solid #FF740F'
                                }}
                                onClick={() => {
                                    setParts({
                                        skuId: '',
                                        qty: '',
                                        partsImage: ''
                                    });
                                    setDiscountData({});
                                }}
                                className="mt-4 mr-2 flex-grow-1"
                            >
                                Clear
                            </Button>
                        )}
                        <Button
                            loading={loading}
                            disabled={!part.skuId || !part.qty || loading || !part.lat || !part.lng}
                            style={{
                                backgroundColor: '#FF740F',
                                color: '#fff'
                            }}
                            onClick={() => getDiscountPrice()}
                            className="border-primary-m mt-4 flex-grow-1"
                        >
                            Get discount price
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
