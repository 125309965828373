import styled from 'styled-components';

const CardWrapper = styled.section`
    @media screen and (max-width: 500px) {
        .card-statistics {
            background-color: red;
            width: 100%;
            margin: 0 auto;
        }
    }
    .card-statistics {
        font-family: 'Roboto', 'sans-serif';
        max-width: 18rem;
        padding: 0.625rem 1.25rem;
        border: 3px solid #ff740f;
        background: #fff;
        border-radius: 0.3125rem;
        margin-bottom: 1.25rem;

        .main-card {
            .stats {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .card-title {
                    color: #989898;
                    font-size: 1.2rem;
                    opacity: 0.5;
                    font-weight: 700;
                }
                .amount {
                    font-weight: 900;
                    font-size: 1.4rem;
                }

                .current {
                    font-size: 1rem;
                    color: #097200;
                    text-align: center;
                    background: #e0ffe5;
                    font-weight: 500;
                    border-radius: 0.315rem;
                    padding: 0.625rem;
                }

                .down-curve {
                    font-size: 1rem;
                    color: #000;
                    text-align: center;
                    background: #ef9a9a;
                    font-weight: 500;
                    border-radius: 0.315rem;
                    padding: 0.625rem;
                }

                .arrow-down {
                    font-weight: 600;
                    text-align: center;
                    color: red;
                }

                .arrow-up {
                    font-weight: 600;
                    text-align: center;
                    color: #097200;
                }

                .previous-stats {
                    color: #989898;
                    font-weight: 600;
                    opacity: 0.5;
                }
            }
        }
    }
`;

export default CardWrapper;
