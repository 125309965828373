import React, { useState, useEffect } from 'react';
import { Table, Divider, Paper, Space, Select, Spin, DatePicker, Modal, Input, Dropdown, Popover, Button } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { encrypt, decrypt } from '../../utils/encrypt';
import { LoadingOutlined, DownloadOutlined, FileAddOutlined } from '@ant-design/icons';
import { twoDecimalPlaces } from 'utils/functions';

const GrowthTableChart = ({ datas, showGmv, showRevenue, showOrder, progressWidth, chartTitle }) => {
    const columns = [
        {
            title: 'Time Frame',
            dataIndex: 'time',
            key: 'time'
            // width: '100px'
        },
        {
            title: `${chartTitle}`,
            dataIndex: 'metric',
            key: 'metric'
            // width: '100px'
        },
        {
            title: 'Growth',
            dataIndex: 'growth',
            key: 'growth'
            // width: '180px'
        }
    ];

    const gmvTableData = datas?.trends?.chart_data?.map((result, index) => ({
        key: index,
        time: result?.name,
        metric: twoDecimalPlaces(result?.gmv),
        growth: `${result?.gmv_growth}%`
    }));

    const revenueTableData = datas?.trends?.chart_data?.map((result, index) => ({
        key: index,
        time: result?.name,
        metric: twoDecimalPlaces(result?.revenue),
        growth: `${result?.revenue_growth}%`
    }));

    const ordersTableData = datas?.trends?.chart_data?.map((result, index) => ({
        key: index,
        time: result?.name,
        metric: result?.orders,
        growth: `${result?.orders_growth}%`
    }));

    return (
        // <Box
        //     sx={{
        //         // width: progressWidth,
        //         // height: '244px',
        //         // backgroundColor: average < 50 ? '#FFEBED' : '#E6F7EE',
        //         // borderRadius: '12px',
        //         // margin: '24px 0 0 0',
        //         display: 'flex',
        //         // alignItems: 'center',
        //         // flexDirection: 'column',
        //         justifyContent: 'space-between',
        //         flexWrap: 'wrap',
        //         flexGrow: '1',
        //         // padding: '20px'
        //         // width: '100%'
        //         flexBasis: '600px'
        //     }}
        // >
        <>
            {showGmv ? (
                <Box
                    sx={{
                        width: progressWidth,
                        // height: '244px'
                        // backgroundColor: datas.gmv_growth < 50 ? '#FFEBED' : '#E6F7EE',
                        // borderRadius: '12px',
                        // margin: '24px 0 0 0',
                        // display: 'flex',
                        // alignItems: 'center',
                        // flexDirection: 'column',
                        // justifyContent: 'center',
                        padding: '20px'
                    }}
                >
                    {' '}
                    <Table
                        // loading={loading ? { indicator: <Spin indicator={antIcon} />, spinning: true / false } : ''}
                        columns={columns}
                        dataSource={gmvTableData}
                        size="middle"
                        pagination={false}
                        // pagination={{
                        //     pageSize: limit,
                        //     hideOnSinglePage: true
                        // }}
                        // onChange={onChange}
                        // scroll={{
                        //     x: 'calc(700px + 50%)',
                        //     y: 580
                        // }}
                    />
                </Box>
            ) : null}

            {showRevenue ? (
                <Box
                    sx={{
                        width: progressWidth,
                        // height: '244px'
                        // backgroundColor: datas.gmv_growth < 50 ? '#FFEBED' : '#E6F7EE',
                        // borderRadius: '12px',
                        // margin: '24px 0 0 0',
                        // display: 'flex',
                        // alignItems: 'center',
                        // flexDirection: 'column',
                        // justifyContent: 'center',
                        padding: '20px'
                    }}
                >
                    {' '}
                    <Table
                        // loading={loading ? { indicator: <Spin indicator={antIcon} />, spinning: true / false } : ''}
                        columns={columns}
                        dataSource={revenueTableData}
                        size="middle"
                        pagination={false}
                        // pagination={{
                        //     pageSize: limit,
                        //     hideOnSinglePage: true
                        // }}
                        // onChange={onChange}
                        // scroll={{
                        //     x: 'calc(700px + 50%)',
                        //     y: 580
                        // }}
                    />
                </Box>
            ) : null}

            {showOrder ? (
                <Box
                    sx={{
                        width: progressWidth,
                        // height: '244px'
                        // backgroundColor: datas.gmv_growth < 50 ? '#FFEBED' : '#E6F7EE',
                        // borderRadius: '12px',
                        // margin: '24px 0 0 0',
                        // display: 'flex',
                        // alignItems: 'center',
                        // flexDirection: 'column',
                        // justifyContent: 'center',
                        padding: '20px'
                    }}
                >
                    {' '}
                    <Table
                        // loading={loading ? { indicator: <Spin indicator={antIcon} />, spinning: true / false } : ''}
                        columns={columns}
                        dataSource={ordersTableData}
                        size="middle"
                        pagination={false}
                        // pagination={{
                        //     pageSize: limit,
                        //     hideOnSinglePage: true
                        // }}
                        // onChange={onChange}
                        // scroll={{
                        //     x: 'calc(700px + 50%)',
                        //     y: 580
                        // }}
                    />
                </Box>
            ) : null}
        </>
        // </Box>
    );
};

export default GrowthTableChart;
