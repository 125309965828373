import React from 'react';
import { Box, Typography } from '@mui/material';
import { Button, DatePicker, Input, Popover, Select, Space, Spin, Table, Modal } from 'antd';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';

const AddSalesPartModal = ({
    isSalesPartOpen,
    handleSalesPartCancel,
    handleSalesPartOk,

    partname,
    setpartname,
    setpartqty,
    handlePartTypeSize,
    partsize,
    partqty,
    onSubmitPartData
}) => {
    return (
        <div>
            <Modal
                open={isSalesPartOpen}
                onOk={handleSalesPartOk}
                onCancel={handleSalesPartCancel}
                footer={
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                            style={{
                                backgroundColor: '#FFFFFF',
                                borderRadius: '8px',
                                border: '1px solid #FF740F',
                                height: '48px',
                                fontSize: '18px',
                                fontWeight: '700',
                                color: '#FF740F ',
                                width: '154px',
                                textAlign: 'center'
                            }}
                            onClick={() => {
                                handleSalesPartCancel();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{
                                backgroundColor: '#FF740F',
                                borderRadius: '8px',
                                height: '48px',
                                fontSize: '18px',
                                fontWeight: '700',
                                color: '#FFFFFF',
                                width: '154px',
                                textAlign: 'center'
                            }}
                            onClick={() => {
                                onSubmitPartData();
                            }}
                        >
                            Add
                        </Button>
                    </Box>
                }
            >
                <Typography
                    sx={{
                        fontWeight: '500',
                        color: '#0A2938',
                        textAlign: 'center',
                        fontSize: ' 1.25rem'
                    }}
                >
                    Add part
                </Typography>
                <Box
                    style={{
                        backgroundColor: '#FFFFFF',
                        borderRadius: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '5px auto',
                        overflow: 'hidden'
                    }}
                >
                    <Box
                        style={{
                            height: '100%',
                            padding: '0 32px 32px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Part Name</Typography>

                            <Input
                                style={{
                                    width: '100%'
                                }}
                                required
                                size="large"
                                value={partname}
                                placeholder="
                                    Part Name
                                    "
                                onChange={(e) => setpartname(e.target.value)}
                            />
                        </Box>

                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Qty</Typography>

                            <Input
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Qty"
                                value={partqty}
                                required
                                onChange={(e) => {
                                    setpartqty(e.target.value);
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Part size</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                required
                                size="large"
                                placeholder="Part size"
                                options={[
                                    {
                                        value: 'Boxes',
                                        label: 'Box(es)'
                                    },
                                    {
                                        value: 'Pieces',
                                        label: 'Pieces'
                                    },
                                    {
                                        value: 'Gallons',
                                        label: 'Gallons'
                                    },
                                    {
                                        value: 'Drums',
                                        label: 'Drums'
                                    },
                                    {
                                        value: 'Rolls',
                                        label: 'Roll(s)'
                                    },
                                    {
                                        value: 'Bucket',
                                        label: 'Bucket'
                                    },
                                    {
                                        value: 'Packs',
                                        label: 'Packs(s)'
                                    },
                                    {
                                        value: 'Litre',
                                        label: 'Litre(s)'
                                    },
                                    {
                                        value: 'Pair',
                                        label: 'Pair(s)'
                                    },
                                    {
                                        value: 'Carton',
                                        label: 'Carton(s)'
                                    }
                                ]}
                                onChange={handlePartTypeSize}
                                value={partsize}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default AddSalesPartModal;
