import { useAppContext } from 'context/appContext';
import styled from 'styled-components';
import placeholder from 'assets/images/placeholder.png';

const Wrapper = styled.div`
    margin-top: 30px;

    .product-img {
        width: 80px;
        height: 80px;
    }
`;
const InventoryTable = ({ inventory }) => {
    const { userCurrency } = useAppContext();
    return (
        <Wrapper>
            <div className="table-responsive">
                <table className="table table-hoverable">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Product</th>
                            <th>Part</th>
                            <th>Price ({userCurrency})</th>
                            <th>Stock</th>
                        </tr>
                    </thead>
                    <tbody>
                        {inventory.map((item, index) => {
                            const { partName, price, stock, partimage, partsize } = item;
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <img src={partimage || placeholder} alt={partName} className="product-img" />
                                    </td>
                                    <td>{partName}</td>
                                    <td>{price}</td>
                                    <td>{`${stock} ${partsize}`}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </Wrapper>
    );
};
export default InventoryTable;
