import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import styled from 'styled-components';

const Wrapper = styled.div`
    background-color: red;
    h3 {
        text-align: center;
        font-weight: 900;
    }
`;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #eee',
    boxShadow: 24,
    p: 4,
    color: '#333'
};

const SalesModal = ({ handleOpen, handleClose }) => {
    return (
        <Modal open={handleOpen} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Wrapper>
                    <header id="modal-modal-title">
                        <h3>Daily goal tip</h3>
                    </header>
                    <div className="content" id="modal-modal-description">
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quo asperiores porro nisi ab obcaecati iste beatae
                        laboriosam quos, molestiae, quaerat voluptatem eaque, omnis quidem eos quas consectetur at nostrum soluta!
                    </div>
                </Wrapper>
            </Box>
        </Modal>
    );
};
export default SalesModal;
