import { Button, Collapse, Input, Spin, DatePicker, Table, Modal, Popover, Select, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ApiUrl from '../../../../network';
import { decrypt, encrypt } from '../../../../utils/encrypt';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { EmptyState } from '../../../../components/EmptyState/EmptyState';
import CallMadeIcon from '@mui/icons-material/CallMade';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const { RangePicker } = DatePicker;
    const [loading, setLoading] = useState(false);
    const [routesModal, setRoutesModal] = useState(false);
    const [routes, setRoutes] = useState([]);
    const [pods, setPods] = useState([]);
    const [rmas, setRmas] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [rma, setRma] = useState(null);
    const [pod, setPod] = useState(null);
    const [rmaRetailers, setRmaRetailers] = useState([]);
    const [rmaRetailersModal, setRmaRetailersModal] = useState(false);
    const [columnRoutes, setColumnRoutes] = useState([]);
    const [rmaPick, setRmaPick] = useState('');

    // eslint-disable-next-line consistent-return
    const fetchRoutePlaning = async (data) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/routerreport/adminccroutedashboard`;

            if (data) {
                if (data.startDate) urlParams.append('startDate', data.startDate);
                if (data.endDate) urlParams.append('endDate', data.endDate);
                if (data.rma) urlParams.append('rma', data.rma);
                if (data.pod) urlParams.append('pod', data.pod);
            }

            url = `${url}?${urlParams.toString()}`;

            const response = await fetch(url, config);

            await response.json().then((data) => {
                if (data.status === false) {
                    toast.error(data.message);
                    setLoading(false);
                } else {
                    const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                    setRoutes(result);
                    setLoading(false);
                }
            });
        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    };
    // eslint-disable-next-line consistent-return
    const postRoutePlaning = async (routes) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const datas = {
                routes
            };
            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            const response = await fetch(`${ApiUrl.BaseUrl}/routerreport/routeslocation`, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify({ payload })
            });

            await response.json().then((data) => {
                if (data.status === false) {
                    toast.error(data.message);
                    setLoading(false);
                } else {
                    const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                    setRmaRetailers(result);
                    setLoading(false);
                    setRmaRetailersModal(true);
                }
            });
        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchRoutePlaning();

        return () => {
            // cleanup function
            setLoading(false);
        };
    }, []);

    useEffect(() => {
        // Flatten and set the initial columnRoutes based on fetched data
        setColumnRoutes(routes.flatMap((route) => route.routes));
    }, [routes]);

    // eslint-disable-next-line consistent-return

    const fetchPodsData = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/salesdrivepod/salesdrivepod`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setPods(decryptedData);
                        const allSalespersons = decryptedData.reduce((acc, pod) => {
                            acc.push(...pod.salesperson);
                            return acc;
                        }, []);
                        setRmas(allSalespersons);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        fetchPodsData();
        return () => {
            // cleanup function
            setLoading(false);
        };
    }, []);

    const content = (routes) => (
        <div style={{ padding: '10px' }}>
            {routes && routes.length > 0 ? (
                routes.map((route, index) => (
                    <span key={route.key} className="d-flex justify-content-space-between mb-2" style={{ fontSize: '14px' }}>
                        {route.location}
                    </span>
                ))
            ) : (
                <span>No routes found</span>
            )}
        </div>
    );

    const routesDataSource = routes.map((route) => {
        // Split the date string by space to handle multiple dates
        const dateParts = route.date.split(' ');

        // Format both dates if valid, otherwise use fallback
        const formattedDates = dateParts.map((datePart) => {
            return dayjs(datePart).isValid() ? dayjs(datePart).format('YYYY-MM-DD') : 'Invalid date';
        });

        // Create a string to display both dates
        const displayDate = formattedDates.join(' to ');

        return {
            key: route._id,
            date: displayDate,
            rma: `${route?.rma?.firstName} ${route?.rma?.lastName}`,
            pod: route?.pod?.name,
            targetgmv: `${route?.currency?.name} ${route.targetgmv}`,
            achievedgmv: `${route?.currency?.name} ${route.achievedgmv}`,
            targetrevenue: `${route?.currency?.name} ${route.targetrevenue}`,
            achievedrevenue: `${route?.currency?.name} ${route.achievedrevenue}`,
            targetretailers: route.targetretailers,
            achieveretailers: route.achieveretailers,
            routes: (
                <div>
                    {route.routes.slice(0, 2).map((r, index) => (
                        <span key={index} className="d-flex justify-content-space-between mb-2" style={{ fontSize: '14px' }}>
                            {r.location}
                        </span>
                    ))}
                    {route.routes.length > 2 && (
                        <Button
                            type="primary"
                            style={{
                                backgroundColor: 'transparent',
                                color: '#FF740F',
                                border: 'none',
                                padding: '0',
                                width: 'fit-content',
                                outline: 'none',
                                boxShadow: 'none'
                            }}
                            onClick={() => {
                                setRoutesModal(true);
                                setColumnRoutes(route.routes);
                                setRmaPick(`${route?.rma?.firstName.trim()} ${route?.rma?.lastName.trim()}`);
                            }}
                        >
                            All routes
                        </Button>
                    )}
                </div>
            ),
            rmaRetailers: (
                <Button
                    type="primary"
                    style={{
                        backgroundColor: 'transparent',
                        color: '#FF740F',
                        border: 'none',
                        padding: '0',
                        width: 'fit-content',
                        outline: 'none',
                        boxShadow: 'none'
                    }}
                    onClick={() => {
                        setRmaPick(`${route?.rma?.firstName.trim()} ${route?.rma?.lastName.trim()}`);
                        postRoutePlaning(route.routes);
                    }}
                >
                    Retailers
                </Button>
            ),
            report: (
                <Button
                    size="small"
                    onClick={() => {
                        let url = `/admin-route-schedule-report/${route?.rma?._id}`;
                        if (startDate) {
                            url += `?startDate=${startDate}`;
                        }
                        if (endDate) {
                            url += `&endDate=${endDate}`;
                        }
                        window.location.href = url;
                    }}
                    style={{
                        backgroundColor: '#FF740F',
                        color: '#fff',
                        width: 'fit-content'
                    }}
                >
                    View report
                </Button>
            )
        };
    });
    const rmasOptions = rmas?.map((rma) => {
        return {
            label: `${rma.firstName.trim()} - ${rma.lastName.trim()}`,
            value: rma.userId
        };
    });
    const podsOptions = pods.map((pod) => {
        return {
            label: pod?.name.trim(),
            value: pod._id
        };
    });

    const columns = [
        {
            id: 'date',
            title: 'Date',
            dataIndex: 'date'
        },
        {
            id: 'rma',
            title: 'RMA',
            dataIndex: 'rma'
        },
        {
            id: 'pod',
            title: 'POD',
            dataIndex: 'pod'
        },
        {
            id: 'targetgmv',
            title: 'Target GMV',
            dataIndex: 'targetgmv'
        },
        {
            id: 'achievedgmv',
            title: 'Actual GMV',
            dataIndex: 'achievedgmv'
        },
        {
            id: 'targetrevenue',
            title: 'Target revenue',
            dataIndex: 'targetrevenue'
        },
        {
            id: 'achievedrevenue',
            title: 'Actual revenue',
            dataIndex: 'achievedrevenue'
        },
        {
            id: 'targetretailers',
            title: 'Target retailers',
            dataIndex: 'targetretailers'
        },
        {
            id: 'achieveretailers',
            title: 'Actual retailers',
            dataIndex: 'achieveretailers'
        },
        {
            id: 'routes',
            title: 'Routes',
            dataIndex: 'routes'
        },
        {
            id: 'action',
            title: 'Rma Retailers',
            dataIndex: 'rmaRetailers'
        },
        {
            id: 'report',
            title: 'Report',
            dataIndex: 'report'
        }
    ];

    const handleDateChange = (date, dateString) => {
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
    };

    const appleyFilter = () => {
        const filterData = {
            rma,
            pod,
            startDate,
            endDate
        };
        fetchRoutePlaning(filterData);
    };

    const handleSearch = (e) => {
        const value = e.target.value;
        if (value) {
            const filteredData = columnRoutes.filter((route) => route?.location?.toLowerCase().includes(value.toLowerCase()));
            setColumnRoutes(filteredData);
        } else {
            const filteredRoutes = routes.flatMap((route) => {
                const rmaName = `${route?.rma?.firstName.trim()} ${route?.rma?.lastName.trim()}`;
                if (rmaName === rmaPick) {
                    return route.routes || []; // Ensure route.routes is returned as an array
                }
                return [];
            });
            setColumnRoutes(filteredRoutes);
        }
    };
    return (
        <div className="leadCrmContainer">
            <div style={{ color: '#0A2938', borderBottom: '1px dotted #E7EAEB' }} className="tags-header mb-4">
                <p style={{ fontSize: '30px', display: 'flex', alignItems: 'center' }}> Admin dashboard </p>
                <p className="tag" style={{ fontSize: '30px' }}>
                    <span
                        className="ml-2"
                        style={{
                            backgroundColor: '#FDE7CF',
                            color: '#0A2938',
                            borderRadius: '20px',
                            padding: '10px',
                            fontSize: '14px',
                            textAlign: 'center'
                        }}
                    >
                        Total drives: {routes.length}
                    </span>
                    <span
                        className="ml-2"
                        style={{
                            backgroundColor: '#FDE7CF',
                            color: '#0A2938',
                            borderRadius: '20px',
                            padding: '10px',
                            fontSize: '14px',
                            textAlign: 'center'
                        }}
                    >
                        Total Customers: {routes[0]?.totalCustomers || 0}
                    </span>
                    <span
                        className="ml-2"
                        style={{
                            backgroundColor: '#FDE7CF',
                            color: '#0A2938',
                            borderRadius: '20px',
                            padding: '10px',
                            fontSize: '14px',
                            textAlign: 'center'
                        }}
                    >
                        Total Purchased Customers For Month: {routes[0]?.totalPurchasedCustomersForMonth || 0}
                    </span>
                    <span
                        className="ml-2"
                        style={{
                            backgroundColor: '#FDE7CF',
                            color: '#0A2938',
                            borderRadius: '20px',
                            padding: '10px',
                            fontSize: '14px',
                            textAlign: 'center'
                        }}
                    >
                        Total Not Purchased Customers For Month: {routes[0]?.totalNotPurchasedCustomersForMonth || 0}
                    </span>
                </p>{' '}
            </div>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 44
                                }}
                                spin
                            />
                        }
                    />
                </div>
            ) : (
                <div>
                    <Collapse accordion>
                        <Collapse.Panel key={1} header="Hide filters" className="mb-4">
                            <div className="batchFilters">
                                <div className="mr-2">
                                    <Select
                                        style={{
                                            width: 300
                                        }}
                                        placeholder="Search RMA"
                                        options={rmasOptions}
                                        onChange={(value) => setRma(value)}
                                        value={rma || null}
                                        filterOption={(inputValue, option) => {
                                            if (typeof option.label === 'string') {
                                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }

                                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }}
                                        showSearch
                                        allowClear
                                    />
                                </div>
                                <div className="mr-2">
                                    <Select
                                        style={{
                                            width: 300
                                        }}
                                        placeholder="Search Pod"
                                        options={podsOptions}
                                        onChange={(value) => setPod(value)}
                                        value={pod || null}
                                        filterOption={(inputValue, option) => {
                                            if (typeof option.label === 'string') {
                                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }

                                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }}
                                        showSearch
                                        allowClear
                                    />
                                </div>
                                <div className="mt-2">
                                    <RangePicker
                                        onChange={(date, dateString) => {
                                            handleDateChange(date, dateString);
                                        }}
                                        value={startDate && endDate ? [dayjs(startDate, 'YYYY-MM-DD'), dayjs(endDate, 'YYYY-MM-DD')] : null}
                                        format="YYYY-MM-DD"
                                        className="datePicker filterInput"
                                    />
                                </div>
                                <div className="d-flex align-items-center">
                                    <Button
                                        style={{
                                            marginLeft: '10px',
                                            color: '#FF740F',
                                            border: '1px solid #FF740F'
                                        }}
                                        loading={loading}
                                        onClick={() => appleyFilter()}
                                    >
                                        Apply filter
                                    </Button>
                                    {(startDate && endDate) || rma || pod ? (
                                        <Button
                                            onClick={() => {
                                                setStartDate('');
                                                setEndDate('');
                                                setRma('');
                                                setPod('');
                                                fetchRoutePlaning();
                                            }}
                                            style={{
                                                color: '#FF740F',
                                                border: '1px solid #FF740F'
                                            }}
                                            className="ml-2"
                                        >
                                            Reset filter
                                        </Button>
                                    ) : null}
                                </div>
                            </div>
                        </Collapse.Panel>
                    </Collapse>
                    <div className="table-responsive">
                        <Table
                            columns={columns}
                            dataSource={routesDataSource}
                            locale={{
                                emptyText: <EmptyState text="No data found" width="300px" padding="15px" />
                            }}
                        />
                    </div>
                </div>
            )}
            <Modal title={`${rmaPick} Routes`} open={routesModal} onCancel={() => setRoutesModal(false)} footer={null}>
                <div className="mt-4">
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Input
                            onChange={handleSearch}
                            addonBefore={<SearchOutlined />}
                            placeholder="Search route"
                            style={{ width: '300px' }}
                        />
                        <span className="text-center">
                            Total routes <b>{columnRoutes.length}</b>
                        </span>
                    </div>
                    {columnRoutes?.length > 0 ? (
                        <div className="mt-4">
                            {columnRoutes.map(
                                (route, index) =>
                                    route &&
                                    route.location && (
                                        <div key={route._id || index}>
                                            <li key={index} className="mt-4">
                                                <span className="ml-2">{route.location}</span>
                                            </li>
                                            {route.location.length > 0 && <Divider style={{ margin: '10px 0' }} />}
                                        </div>
                                    )
                            )}
                        </div>
                    ) : (
                        <div className="mt-4">
                            <p>No routes</p>
                        </div>
                    )}
                </div>
            </Modal>

            <Modal
                width={800}
                title={`${rmaPick} Retailers`}
                open={rmaRetailersModal}
                onCancel={() => setRmaRetailersModal(false)}
                footer={null}
            >
                <div className="scroll-routes">
                    {rmaRetailers?.routes && Object.keys(rmaRetailers.routes).length > 0 ? (
                        Object.keys(rmaRetailers.routes).map((routeKey) => (
                            <React.Fragment key={routeKey}>
                                <h4 className="mb-4 mt-4" style={{ fontSize: '14px' }}>
                                    {routeKey}
                                </h4>
                                {rmaRetailers.routes[routeKey].map((route) => (
                                    <li key={route._id} className="mt-3 mb-2 d-flex justify-content-space-between ">
                                        <div>
                                            <span
                                                style={{
                                                    backgroundColor: '#C7C9CE',
                                                    padding: '7px',
                                                    borderRadius: '50%',
                                                    color: '#fff',
                                                    fontSize: '10px',
                                                    fontWeight: 'bold',
                                                    marginBottom: '10px'
                                                }}
                                            >
                                                <CallMadeIcon
                                                    style={{
                                                        fontSize: '12px',
                                                        color: '#fff'
                                                    }}
                                                />
                                            </span>
                                            <span
                                                style={{
                                                    marginLeft: '10px',
                                                    color: '#3B5460',
                                                    fontSize: '14px',
                                                    fontWeight: '400'
                                                }}
                                            >
                                                {route.location}
                                            </span>
                                        </div>
                                        <Button
                                            onClick={() => window.open(`/retailer/profile/${route._id}`, '_blank')}
                                            style={{
                                                color: `${route?.purchasedInfo.toLowerCase() === 'purchased' ? '#00AC00' : '#FA3246'}`,
                                                border: 'none',
                                                outline: 'none',
                                                boxShadow: 'none',
                                                padding: '0px',
                                                width: 'fit-content'
                                            }}
                                        >
                                            {route.partnerName}
                                        </Button>
                                    </li>
                                ))}
                            </React.Fragment>
                        ))
                    ) : (
                        <EmptyState text="No data found" width="300px" padding="15px" />
                    )}
                </div>
            </Modal>
        </div>
    );
};
export default Index;
