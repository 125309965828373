/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../../components/PageHeader';
import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
// import * as XLSX from 'xlsx';
import { Link, useParams } from 'react-router-dom';
// import { useTheme } from '@mui/material/styles';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import useScriptRef from 'hooks/useScriptRef';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
// import styled from 'styled-components';
// import { height } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import nologo from 'assets/images/nologo.png';
import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    },
    backgroundColor: {
        backgroundColor: '#FF740F'
    },
    color: {
        color: '#FF740F',
        fontWeight: 'bold'
    },
    color1: {
        color: '#000'
    },
    cancelColor: {
        cancelColor: '#D3DADF'
    },
    coloricon: {
        color: '#CACACA'
    },
    coloricons: {
        color: '#FFF'
    }
}));

const FleetVehicleProfile = () => {
    const classes = useStyles();
    // const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [carName, setcarName] = useState('');
    const [carmodelName, setcarmodelName] = useState('');
    const [requestDate, setrequestDate] = useState(new Date());
    const [carRegistrationNo, setcarRegistrationNo] = useState('');
    const [year, setyear] = useState('');
    const [companyName, setcompanyName] = useState('');
    const [mileage, setmileage] = useState('');
    const [registrationDate, setregistrationDate] = useState('');
    const [engineNo, setengineNo] = useState('');
    const [vinNo, setvinNo] = useState('');
    const [profile, setprofile] = useState('');
    const [customerContact, setcustomerContact] = useState('');
    const [loading, setLoading] = useState(false);
    // const [lastupload, setlastupload] = useState([]);
    const [serviceRemainders, setserviceRemainders] = useState([]);
    const [carpictures, setcarpictures] = useState([]);
    const [servicingHistory, setservicingHistory] = useState([]);
    const [previousPartsChanged, setpreviousPartsChanged] = useState([]);
    const [workDoneHistory, setworkDoneHistory] = useState([]);
    const [attachments, setattachments] = useState([]);
    const [jobcard, setjobcard] = useState([]);
    const [loading2, setLoading2] = useState(false);
    // const [page, setPage] = useState(0);
    // const [rowsPerPage, setRowsPerPage] = useState(20);
    // const [pageinfo, setpageinfo] = useState('tableview');

    const { id } = useParams();
    console.log(year);
    console.log(registrationDate);
    console.log(loading2);
    console.log(setjobcard([]));

    const commonStyles = {
        bgcolor: 'background.paper',
        m: 2,
        border: 1
    };

    const commonStyles2 = {
        bgcolor: 'background.paper',
        m: 2
        // border: 1
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - lastupload.length) : 0;
    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 30));
    //     setPage(0);
    // };
    function toTimestamp(strDate) {
        // const options = {
        //     weekday: 'long',
        //     year: 'numeric',
        //     month: 'long',
        //     day: 'numeric',
        //     hour: 'numeric',
        //     minute: 'numeric'
        // };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh');
    }

    function formatAMPM(strDate) {
        const today = strDate ? new Date(strDate) : new Date();
        let hours = today.getHours();
        let minutes = today.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours %= 12;
        hours = hours || 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        const strTime = `${hours}:${minutes} ${ampm}`;
        return strTime;
    }

    // const handlePageChange = (event) => {
    //     setpageinfo(event.target.value);
    // };

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        fetch(ApiUrl.ViewFleetVehicleRequestUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    // console.log(result?.data);

                    setcarName(result?.data?.carName ?? '');
                    setcarmodelName(result?.data?.carmodelName ?? '');
                    setrequestDate(result?.data?.requestDate ?? '');
                    setcarRegistrationNo(result?.data?.carRegistrationNo ?? '');
                    setyear(result?.data?.year ?? '');
                    setmileage(result?.data?.mileage ?? '');
                    setcompanyName(result?.data?.companyName ?? result?.data?.channelPartnerId?.partnerName);
                    setregistrationDate(result?.data?.registrationDate ?? '');
                    setengineNo(result?.data?.engineNo ?? '');
                    setvinNo(result?.data?.vinNo ?? '');
                    setprofile(result?.data?._id ?? '');
                    setcustomerContact(result?.data?.channelPartnerId?.partnerContact ?? '');
                    setservicingHistory(result?.data?.servicingHistory ?? []);
                    setpreviousPartsChanged(result?.data?.previousPartsChanged ?? []);
                    setworkDoneHistory(result?.data?.workDoneHistory ?? '');
                    setattachments(result?.data?.attachments ?? []);
                    setcarpictures(result?.data?.carpictures ?? []);

                    setLoading(false);
                } else {
                    toast.error(result.data);
                    setLoading(false);
                    // setLoading(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading2(false);
            });

        fetch(ApiUrl.AllServiceReminderVehicleProfileUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    // console.log('service reminders', result?.data);
                    setserviceRemainders(result?.data ?? []);

                    setLoading(false);
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading2(false);
            });
    }, [id]);

    const handleView = (row) => {
        if (scriptedRef.current) {
            // history(`/viewOrder/${row._id}`);
            console.log(row);
        }
    };

    return (
        <>
            <PageHeader title="Home | Fleet Management" subTitle="Home | Fleet Management | Vehicle Profile" />

            <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                <Button component={Link} to="/fleetvehiclerequest" type="submit" variant="contained" color="error">
                    Back
                </Button>
            </Box>
            <br />

            <MainCard title="">
                {loading ? (
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                            <CircularProgress size={70} thickness={4} />
                        </div>
                    </>
                ) : (
                    <>
                        <Grid container spacing={2} justify="flex-start">
                            <Grid item xs={12} sm={6} md={4} lg={6}>
                                <Typography variant="color" component="div" className={classes.color} color="warning">
                                    {carName} {carmodelName}
                                </Typography>
                                <br />
                                <Typography variant="color" component="div" className={classes.color1} color="warning">
                                    ID: {profile}
                                </Typography>
                                <br />
                                <Typography variant="color" component="div" className={classes.color1} color="warning">
                                    Registration No: {carRegistrationNo}
                                </Typography>
                                <br />
                                <Typography variant="color" component="div" className={classes.color1} color="warning">
                                    Date brought in: {toTimestamp(requestDate)}
                                </Typography>
                                <br />
                                <Typography variant="color" component="div" className={classes.color1} color="warning">
                                    Mileage: {mileage}
                                </Typography>
                                <br />
                                <Typography variant="color" component="div" className={classes.color1} color="warning">
                                    VIN: {vinNo}
                                </Typography>
                                <br />
                                <Typography variant="color" component="div" className={classes.color1} color="warning">
                                    Engine No: {engineNo}
                                </Typography>
                                <br />
                                <Typography variant="color" component="div" className={classes.color1} color="warning">
                                    Customer Name: {companyName}
                                </Typography>
                                <br />
                                <Typography variant="color" component="div" className={classes.color1} color="warning">
                                    Customer Number: {customerContact}
                                </Typography>

                                {/* <hr /> */}
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={6}>
                                <Typography variant="color" component="div" className={classes.color} color="warning">
                                    SERVICE REMINDERS
                                </Typography>
                                <br />
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <Box sx={{ ...commonStyles, borderColor: '#FF740F' }}>
                                        {serviceRemainders.map((rows) => (
                                            <Grid key={rows._id} item>
                                                <Card align="center">
                                                    <Typography variant="subtitle2" component="h2" textAlign="left">
                                                        {toTimestamp(rows?.startDate)}--- {toTimestamp(rows?.endDate)} |{' '}
                                                        {formatAMPM(rows?.startDate)} --- {formatAMPM(rows?.endDate)}
                                                    </Typography>

                                                    <Typography variant="subtitle2" component="h2" textAlign="left">
                                                        {rows.title ?? ''}
                                                    </Typography>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Box>
                                </Grid>

                                {/* <hr /> */}
                            </Grid>
                            <br />
                            <Grid item xs={12} sm={6} md={4} lg={6}>
                                <Box sx={{ ...commonStyles2 }}>
                                    {carpictures.map((rows) => (
                                        <Grid key={rows._id} item>
                                            <Card align="center">
                                                <Avatar variant="rounded" src={rows?.doc ?? nologo} sx={{ width: 100, height: 70 }} />
                                            </Card>
                                        </Grid>
                                    ))}
                                </Box>
                            </Grid>
                        </Grid>
                        <br />
                        <br />
                        <br />
                        <br />
                        <Typography variant="color" component="div" className={classes.color} color="warning">
                            JOB CARDS
                        </Typography>
                        <br />

                        <Grid container spacing={2} justify="flex-start">
                            <Grid item xs={12} sm={6} md={4} lg={12}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    #
                                                </TableCell>
                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    Date
                                                </TableCell>

                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    Actions
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {jobcard.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell component="th" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {toTimestamp(row?.dateServiced) ?? ''}
                                                    </TableCell>

                                                    <TableCell component="th" scope="row">
                                                        <IconButton
                                                            aria-label="edit"
                                                            color="primary"
                                                            onClick={() => handleView(row, index)}
                                                        >
                                                            view
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                        <br />
                        <Typography variant="color" component="div" className={classes.color} color="warning">
                            SERVICING HISTORY
                        </Typography>
                        <br />
                        <Grid container spacing={2} justify="flex-start">
                            <Grid item xs={12} sm={6} md={4} lg={12}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    #
                                                </TableCell>
                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    Date Serviced
                                                </TableCell>
                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    Name of service carried out
                                                </TableCell>
                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    Description
                                                </TableCell>
                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    Automobile company
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {servicingHistory.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell component="th" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {toTimestamp(row?.dateServiced) ?? ''}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row?.serviceName ?? ''}
                                                    </TableCell>

                                                    <TableCell component="th" scope="row">
                                                        {row?.serviceDescription ?? ''}
                                                    </TableCell>

                                                    <TableCell component="th" scope="row">
                                                        {row?.automobileCompany ?? ''}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                        <br />
                        <Typography variant="color" component="div" className={classes.color} color="warning">
                            PARTS CHANGED
                        </Typography>
                        <br />
                        <Grid container spacing={2} justify="flex-start">
                            <Grid item xs={12} sm={6} md={4} lg={12}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    #
                                                </TableCell>
                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    Date part was changed
                                                </TableCell>
                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    Part name
                                                </TableCell>

                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    Model/Make/Manufacturer
                                                </TableCell>

                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    Automobile company
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {previousPartsChanged.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell component="th" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {toTimestamp(row?.datePartChanged) ?? ''}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row?.partName ?? ''}
                                                    </TableCell>

                                                    <TableCell component="th" scope="row">
                                                        {row?.partModel ?? ''}
                                                    </TableCell>

                                                    <TableCell component="th" scope="row">
                                                        {row?.partAutomobileCompany ?? ''}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                        <br />
                        <Typography variant="color" component="div" className={classes.color} color="warning">
                            ALL WORKED DONE
                        </Typography>
                        <br />
                        <Grid container spacing={2} justify="flex-start">
                            <Grid item xs={12} sm={6} md={4} lg={12}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    #
                                                </TableCell>
                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    Date work was done
                                                </TableCell>
                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    Name of work done
                                                </TableCell>
                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    Description
                                                </TableCell>

                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    Automobile company
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {workDoneHistory.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell component="th" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {toTimestamp(row?.dateWorkDone) ?? ''}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row?.nameOfWorkDone ?? ''}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row?.workDescription ?? ''}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row?.workAutomobileCompany ?? ''}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>

                        <br />
                        <Typography variant="color" component="div" className={classes.color} color="warning">
                            ATTACHMENTS
                        </Typography>
                        <br />
                        <Grid container spacing={2} justify="flex-start">
                            <Grid item xs={12} sm={6} md={4} lg={12}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    #
                                                </TableCell>
                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    Document
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {attachments.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell component="th" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <Avatar variant="rounded" src={row.doc ?? nologo} sx={{ width: 100, height: 70 }} />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                        <br />
                    </>
                )}

                <br />
                <br />
            </MainCard>
        </>
    );
};
export default FleetVehicleProfile;
