import React, { useEffect, useState } from 'react';
import { decrypt, encrypt } from '../../../utils/encrypt';
import { toast } from 'react-toastify';
import { Button, Input, Modal, Skeleton, Table } from 'antd';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import SaveIcon from '@mui/icons-material/Save';
import { Typography } from '@mui/material';
import ApiUrl from '../../../network';

export const Index = () => {
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [driveType, setDriveType] = useState('');
    const [id, setId] = useState('');
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [driveTypeList, setDriveTypeList] = useState([]);
    const [actionType, setActionType] = useState('Add Drive Type');

    const getDriveTypeHandler = async () => {
        setLoading(true);
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/salesplanning/drivetypeslist`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setLoading(false);
                    const driveTypeList = decrypt(data.data, keyResult, ivResult);
                    setDriveTypeList(JSON.parse(driveTypeList));
                } else {
                    setLoading(false);
                    toast.error(data.message);
                }
            });
    };
    const addDriveTypeHandler = async () => {
        setLoading(true);
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        const datas = {
            name: driveType
        };
        const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);

        await fetch(`${ApiUrl.BaseUrl}/salesplanning/adddrivetypes`, {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify({ payload })
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setLoading(false);
                    toast.success(data.message);
                    setIsModalVisible(false);
                    getDriveTypeHandler();
                } else {
                    setLoading(false);
                    toast.error(data.message);
                }
            });
    };
    const editDriveTypeHandler = async () => {
        setLoading(true);
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        const datas = {
            name: driveType,
            id,
            status: true
        };
        const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);

        await fetch(`${ApiUrl.BaseUrl}/salesplanning/updatedrivetypes`, {
            method: 'PUT',
            headers: config.headers,
            body: JSON.stringify({ payload })
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setLoading(false);
                    toast.success(data.message);
                    setIsModalVisible(false);
                    getDriveTypeHandler();
                } else {
                    setLoading(false);
                    toast.error(data.message);
                }
            });
    };

    useEffect(() => {
        getDriveTypeHandler();
    }, []);

    const driveTypeTableData = driveTypeList.map((item) => {
        return {
            key: item._id,
            driveType: item.name,
            createdBy: `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}`
        };
    });
    const columns = [
        {
            title: 'Drive Type',
            dataIndex: 'driveType',
            key: 'driveType'
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            key: 'createdBy'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Button
                        onClick={() => {
                            setDriveType(record.driveType);
                            setActionType('Edit Drive Type');
                            setId(record.key);
                            setIsModalVisible(true);
                        }}
                        style={{ backgroundColor: '#FFF', color: '#FF740F', border: '1px solid #FF740F', width: '40px', height: '40px' }}
                        className="mt-2 mb-2 d-flex align-items-center justify-content-center"
                    >
                        <EditLocationAltIcon sx={{ fontSize: '16px', color: '#FF740F' }} />
                    </Button>
                </div>
            )
        }
    ];
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Link to="/salesplanning" className="createScheduleLink">
                    <p style={{ fontSize: '25px', marginLeft: '10px' }}>
                        <ArrowBackIcon sx={{ fontSize: '25px', color: '#000' }} /> Drive Type
                    </p>
                </Link>
                <Button
                    onClick={() => setIsModalVisible(true)}
                    style={{ backgroundColor: '#FF740F', color: '#fff' }}
                    className="btnCreateSchedule"
                    title="Apply Filters"
                >
                    <SaveIcon sx={{ fontSize: '16px', color: '#fff' }} />
                    <Typography sx={{ margin: '0 14px ', color: '#fff' }}>Add Drive Type</Typography>
                </Button>
            </div>
            <div className="d-flex">
                {loading ? (
                    <div>
                        <Skeleton style={{ padding: '10px 0' }} active />
                    </div>
                ) : (
                    <Table columns={columns} scroll={{ x: 'calc(700px + 50%)', y: 480 }} dataSource={driveTypeTableData} />
                )}
            </div>
            <Modal width={400} title={actionType} open={isModalVisible} onCancel={() => setIsModalVisible(false)} footer={null}>
                <div>
                    <Input placeholder="Drive Type" value={driveType} onChange={(e) => setDriveType(e.target.value)} />
                </div>
                <div className="mt-2">
                    <Button
                        style={{ backgroundColor: '#FF740F', color: '#fff', width: '100%', marginTop: '10px' }}
                        className="btnCreateSchedule"
                        title="Apply Filters"
                        loading={loading}
                        onClick={actionType === 'Add Drive Type' ? addDriveTypeHandler : editDriveTypeHandler}
                    >
                        {actionType}
                    </Button>
                </div>
            </Modal>
        </div>
    );
};
export default Index;
