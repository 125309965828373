export const calculateChatExpiration = (expiredAt) => {
    const expirationTime = new Date(expiredAt);

    // Check if the expirationTime is valid
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(expirationTime.getTime())) {
        return 'Invalid expiration date';
    }

    // Get the current date and time
    const currentTime = new Date();

    // Calculate the remaining time until the chat expires
    const timeRemaining = expirationTime - currentTime;

    // Check if the chat has already expired
    if (timeRemaining <= 0) {
        return 'Chat has expired';
    }

    // Convert the remaining time into hours, minutes, and seconds
    const hoursRemaining = Math.floor(timeRemaining / (1000 * 60 * 60));
    const minutesRemaining = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
    const secondsRemaining = Math.floor((timeRemaining % (1000 * 60)) / 1000);

    return `Expires in ${hoursRemaining} hrs ${minutesRemaining} mins ${secondsRemaining} secs.`;
};

export default calculateChatExpiration;
