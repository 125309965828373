import React from 'react';
import ChatBox from './ChatBox'; // If this is another component

const ChatBoxMessageComponent = ({
    messages,
    chatBoxRef,
    phoneNumber,
    actionContent,
    open,
    replyType,
    replyTo,
    setReplyTo,
    handleScroll
}) => {
    return (
        <>
            <ChatBox
                messages={messages}
                chatBoxRef={chatBoxRef}
                phoneNumber={phoneNumber}
                actionContent={actionContent}
                open={open}
                handleScroll={handleScroll}
            />
            {replyType === 'text' && replyTo.content && (
                <div className="replyToMessage">
                    <p style={{ fontSize: '12px' }}>
                        <strong className="profile">{replyTo.profile}</strong>
                        <br /> {replyTo.content}
                    </p>
                    <button className="close-btn" type="button" onClick={() => setReplyTo({ _id: null, profile: null, content: null })}>
                        x
                    </button>
                </div>
            )}
        </>
    );
};

export default ChatBoxMessageComponent;
