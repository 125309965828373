import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PhoneIcon from '@mui/icons-material/Phone';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import LeadTableWrapper from 'assets/Wrappers/LeadTableWrapper';
import { makeStyles } from '@mui/styles';
import TablePaginationActions from 'components/TablePaginationActions';
import { RiWhatsappFill } from 'react-icons/ri';
import { authFetch } from 'utils/axios-config';
import LeadPreviewModal from './LeadsPreviewModal';

const LeadTableData = ({ th1, th2, th3, th4, th5, rows }) => {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [leadRecord, setLeadRecord] = React.useState({});
    const useStyles = makeStyles(() => ({
        tableHeaderCell: {
            fontWeight: 'bolder'
        }
    }));
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const handleLeadRecord = async (id) => {
        handleOpen();
        setLoading(true);
        try {
            const { data } = await authFetch.get(`/channelpartner/show/${id}`);
            setLeadRecord(data.data);
            setLoading(false);
        } catch (error) {
            if (error.response.status !== 401) {
                console.log(error);
            }
        }
    };

    return (
        <LeadTableWrapper>
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className="lead__main-table">
                <TableContainer sx={{ maxHeight: 400 }}>
                    <LeadPreviewModal handleOpen={open} handleClose={handleClose} record={leadRecord} loading={isLoading} />
                    <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className={classes.tableHeaderCell}>
                                    {th1}
                                </TableCell>
                                <TableCell align="left" className={classes.tableHeaderCell}>
                                    {th2}
                                </TableCell>
                                <TableCell align="left" className={classes.tableHeaderCell}>
                                    {th3}
                                </TableCell>
                                <TableCell align="left" className={classes.tableHeaderCell}>
                                    {th4}
                                </TableCell>
                                <TableCell align="left" className={classes.tableHeaderCell}>
                                    {th5}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map(
                                (row, index = 1) => {
                                    const { _id, partnerName, partnerContact, followupsDate, pendingfollowups } = row;
                                    return (
                                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="left" scope="row">
                                                {index + 1}
                                            </TableCell>
                                            <TableCell align="left" scope="row">
                                                {partnerName}
                                            </TableCell>
                                            <TableCell align="left" scope="row">
                                                {new Date(followupsDate).toDateString()}
                                            </TableCell>
                                            <TableCell align="left" scope="row">
                                                {pendingfollowups}
                                            </TableCell>
                                            <TableCell align="left" scope="row">
                                                <Stack direction="row" spacing={1}>
                                                    <IconButton
                                                        aria-label="preview"
                                                        className="icon-preview"
                                                        onClick={() => handleLeadRecord(_id)}
                                                    >
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                    <IconButton aria-label="call lead phone">
                                                        <a
                                                            href={`tel:+233${partnerContact}`}
                                                            className="icon-phone"
                                                            title={`Make a phone call to ${partnerName}`}
                                                        >
                                                            <PhoneIcon />
                                                        </a>
                                                    </IconButton>
                                                    <IconButton aria-label="whatsapp leads phone">
                                                        <a
                                                            href={`https://wa.me/233${row.partnerContact}?text=Hello ${row.partnerName}!, How are you doing today?`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="icon-whatsapp"
                                                            title={`Send a WhatsApp message to ${row.partnerName}`}
                                                        >
                                                            <RiWhatsappFill />
                                                        </a>
                                                    </IconButton>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            )}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                    colSpan={3}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    component="div"
                    SelectProps={{
                        inputProps: {
                            'aria-label': 'rows per page'
                        },
                        native: true
                    }}
                    className="custom-paginator"
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </Paper>
        </LeadTableWrapper>
    );
};

export default LeadTableData;
