import { Paper, Box, Button, Typography } from '@mui/material';
import { Container, Row, Col } from 'react-grid-system';
import HeaderWrapper from 'assets/Wrappers/HeaderWrapper';

const CustomPageHeader = ({ title, subtitle, currentUser, rank, clearnceStatus }) => {
    return (
        <HeaderWrapper>
            <Paper elevation={0} className="custom-paper">
                <Container>
                    <Row>
                        <Col xs={12} sm={6} md={6} lg={8}>
                            <header>
                                <h3>{title}</h3>
                                <h4>{subtitle}</h4>
                            </header>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={4}>
                            <div className="sales-person">
                                <h3 className="associate-title">R.M Associate: {currentUser}</h3>
                                <h4 className="rank">Rank: {rank}</h4>
                            </div>
                        </Col>
                    </Row>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '18px',
                                fontWeight: '600',
                                margin: ' 0  10px 0 0'
                            }}
                        >
                            Clearance status:{' '}
                        </Typography>

                        <Button
                            style={{
                                border: ' none',
                                backgroundColor: `${clearnceStatus === 'NOT CLEARED' ? '#f74639' : ' #2ad151'}`,
                                color: '#FFF',
                                fontWeight: '500',
                                textAlign: 'center',
                                cursor: 'pointer',
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            size="small"
                        >
                            {clearnceStatus}
                        </Button>
                    </Box>
                </Container>
            </Paper>
        </HeaderWrapper>
    );
};
export default CustomPageHeader;
