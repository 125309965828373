import './logistics.css';
import { Typography, Box, Avatar, List, ListItem } from '@mui/material';
import User1 from '../../assets/images/users/user-round.svg';
import { useEffect, useState } from 'react';

const DriverPopUp = ({ firstName, lastName, contactPhone, locationLat, locationLng }) => {
    const [coordinates, setCoordinates] = useState([]); // Default coordinates
    useEffect(() => {
        // Function to handle geocoding
        const handleGeocode = () => {
            const geocoder = new window.google.maps.Geocoder();
            const latlng = {
                lat: parseFloat(locationLat),
                lng: parseFloat(locationLng)
            };

            geocoder
                .geocode({ location: latlng })
                .then((response) => {
                    if (response?.results[0]) {
                        const city = response?.results[0]?.address_components[1]?.long_name;
                        setCoordinates(city);
                    } else {
                        console.log('No results found');
                    }
                })
                .catch((e) => console.log(`Geocoder failed due to: ' + ${e}`));
        };
        handleGeocode();
    }, []);

    return (
        <Box
            sx={{
                overflow: 'hidden',
                width: '285px',
                transition: '1s',
                backgroundColor: '#FFFFFF',
                marginTop: '10px',
                padding: '16px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    margin: '0 0 12px 0'
                }}
            >
                <Avatar
                    src={User1}
                    sx={{
                        cursor: 'pointer',
                        width: '44px',
                        height: '44px'
                    }}
                />
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: '500'
                    }}
                >
                    {`${firstName} ${lastName}`}
                </Typography>
            </Box>

            <List sx={{ width: '100%', backgroundColor: '#ECEDEF', borderRadius: '12px' }}>
                <ListItem
                    sx={{
                        fontSize: '14px',
                        color: '#0A2938',
                        fontWeight: '400'
                    }}
                >
                    Contact : {contactPhone}
                </ListItem>
                <ListItem
                    sx={{
                        fontSize: '14px',
                        color: '#0A2938',
                        fontWeight: '400'
                    }}
                >
                    Location : {coordinates}
                </ListItem>
                <ListItem
                    sx={{
                        fontSize: '14px',
                        color: '#0A2938',
                        fontWeight: '400'
                    }}
                >
                    Vehicle No : GW-7657
                </ListItem>
            </List>
        </Box>
    );
};

export default DriverPopUp;
