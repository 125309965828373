import { Button, Input, Select } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { decrypt, encrypt } from '../../../utils/encrypt';
import ApiUrl from '../../../network';
import { toast } from 'react-toastify';

export const Add = () => {
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [customer, setCustomer] = useState('');
    const [amtDue, setAmtDue] = useState('');
    const [description, setDescription] = useState('');
    const [rma, setRma] = useState('');
    const [rmaCc, setRmaCc] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchRmaCc = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            await fetch(`${ApiUrl.BaseUrl}/epl/eplcustomersrmacc`, {
                method: 'GET',
                headers: config.headers
            })
                .then((data) => data.json())
                .then((rmaccData) => {
                    if (rmaccData.message === 'Success') {
                        const decryptedRmaccData = JSON.parse(decrypt(rmaccData.data, keyResult, ivResult));
                        setRmaCc(decryptedRmaccData.rmcc);
                        setCustomerData(decryptedRmaccData.customer);
                    } else {
                        toast.error(rmaccData.data);
                    }
                });
        } catch (error) {
            toast.error('Error occurred while fetching RMA');
        }
    };

    useEffect(() => {
        fetchRmaCc();
    }, []);

    const rmaOptions = rmaCc.map((rmacc) => {
        return {
            value: rmacc._id,
            label: `${rmacc.firstName} ${rmacc.lastName}`
        };
    });

    const customerOptions = customerData.map((customer) => {
        return {
            value: customer._id,
            label: customer.partnerName
        };
    });

    const createCreditHandler = async () => {
        try {
            if (!customer || !amtDue || !description || !rma) {
                toast.error('All fields are required');
                return;
            }
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const datas = {
                customer,
                amtDue,
                description,
                rmcc: rma
            };

            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            await fetch(`${ApiUrl.BaseUrl}/epl/addeplcredittracker`, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify({ payload })
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setLoading(false);
                        toast.success(data.message);
                        setCustomer('');
                        setAmtDue('');
                        setDescription('');
                        setRma('');
                    } else {
                        setLoading(false);
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
            toast.error('Error occurred while adding credit');
        }
    };

    return (
        <div className="easy-pay-container">
            <div className="header">
                <Link className="d-flex align-items-center" to="/epl/easy-pay-later" style={{ color: '#0A2938' }}>
                    <ArrowLeftOutlined className="font-20" />
                    <h1 className="font-30 ml-2 mt-2">Add credit </h1>
                </Link>
                <Button
                    loading={loading}
                    onClick={() => {
                        createCreditHandler();
                    }}
                    style={{ backgroundColor: '#FF740F', color: '#fff', width: 'fit-content' }}
                >
                    Add credit
                </Button>
            </div>
            <hr className="divider" />
            <div className="content">
                <div className="bg-white add-inputs-container">
                    <div className="form-group">
                        <span>Customer</span>
                        <Select
                            value={customer}
                            onChange={(e) => setCustomer(e)}
                            size="middle"
                            className="mt-2"
                            showSearch
                            allowClear
                            options={customerOptions}
                            defaultValue="Select option"
                            style={{ width: '100%' }}
                            filterOption={(inputValue, option) => {
                                if (typeof option.label === 'string') {
                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }

                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <span>Principal amount</span>
                        <Input
                            value={amtDue}
                            onChange={(e) => setAmtDue(e.target.value)}
                            type="text"
                            className="form-control mt-2"
                            id="amount"
                        />
                    </div>
                    <div className="form-group">
                        <span>Description</span>
                        <Input
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            type="text"
                            className="form-control mt-2"
                            id="description"
                        />
                    </div>
                    <div className="form-group">
                        <span>RMA/CC Direct contact</span>
                        <Select
                            value={rma}
                            onChange={(e) => setRma(e)}
                            size="middle"
                            className="mt-2"
                            showSearch
                            allowClear
                            options={rmaOptions}
                            defaultValue="Select option"
                            style={{ width: '100%' }}
                            filterOption={(inputValue, option) => {
                                if (typeof option.label === 'string') {
                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }

                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Add;
