// import dashboard from './dashboard';
import dataentry from './dataentry';
import other from './other';
// import reporting from './reporting';

// ==============================|| MENU ITEMS dashboard ||============================== //

const dataEntryItems = {
    items: [dataentry, other]
};

export default dataEntryItems;
