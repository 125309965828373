import React from 'react';

const ReplyMessage = ({ repliedMessage, scrollToMessage }) => {
    return (
        <div
            role="button"
            tabIndex={0}
            aria-label={`Scroll to message from ${repliedMessage.user}`}
            className="replied-message"
            style={{ padding: '5px', borderLeft: '3px solid #ccc', marginBottom: '10px', color: '#666', fontSize: '12px' }}
            onClick={() => scrollToMessage(repliedMessage._id)}
            onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    scrollToMessage(repliedMessage._id);
                }
            }}
        >
            <small>Replying to {repliedMessage.user}:</small>
            <p>{repliedMessage.content}</p>
        </div>
    );
};

export default ReplyMessage;
