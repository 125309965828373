import React, { useEffect, useState } from 'react';
import { Radio, Space, Button, Modal } from 'antd';
import { Typography } from '@mui/material';
import ApiUrl from 'network';
import { encrypt, decrypt } from '../../utils/encrypt';

const CountrySwitchModal = ({
    IsCountryModalOpen,
    showCountryModal,
    handleCountryOk,
    handleCountryCancel,
    setCountryValue,
    countryValue,
    switchCountry
}) => {
    const [countries, setCountries] = useState([]);
    const [flag, setFlag] = useState('');
    const [countryName, setCountryName] = useState('');
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const country = localStorage.getItem('country');

    const retrieveCountry = async () => {
        try {
            fetch(`https://restcountries.com/v3.1/name/${country}`)
                .then((data) => data.json())
                .then((data) => {
                    setFlag(data[0]?.flag);
                    setCountryName(data[0]?.fifa);
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    console.log();

    useEffect(() => {
        retrieveCountry();
    }, []);

    const onChange = (e) => {
        setCountryValue(e.target.value);
    };

    // get query data
    const getQueryData = async () => {
        try {
            const url = `${ApiUrl.BaseUrl}/mainorders/querydata?country=${country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setCountries(answer?.country);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {});
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getQueryData();
    }, []);

    return (
        <div>
            <Modal
                open={IsCountryModalOpen}
                onOk={handleCountryOk}
                centered
                onCancel={handleCountryCancel}
                footer={
                    <div className="driver-footer">
                        <Button
                            style={{
                                backgroundColor: '#FFFFFF',
                                borderRadius: '12px',
                                height: '46px',
                                fontSize: '18px',
                                fontWeight: '700',
                                color: '#FF740F',
                                border: '1px solid #FF740F',
                                width: '200px',
                                textAlign: 'center'
                            }}
                            onClick={() => {
                                handleCountryCancel();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{
                                backgroundColor: '#FF740F',
                                borderRadius: '12px',
                                height: '46px',
                                fontSize: '18px',
                                fontWeight: '700',
                                color: '#FFFFFF',
                                width: '200px',
                                textAlign: 'center'
                            }}
                            onClick={() => {
                                switchCountry();
                                handleCountryOk();
                            }}
                        >
                            Switch
                        </Button>
                    </div>
                }
            >
                <Typography
                    sx={{
                        fontSize: '18px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: '800'
                    }}
                >
                    Switch Country
                </Typography>
                <Radio.Group onChange={onChange} value={countryValue}>
                    <Space direction="vertical">
                        {countries?.map((country) => (
                            <Radio value={country?.name} key={country?._id} style={{ fontSize: '16px' }}>
                                {country?.name}
                                {/* {flag} */}
                            </Radio>
                        ))}
                    </Space>
                </Radio.Group>
            </Modal>
        </div>
    );
};

export default CountrySwitchModal;
