/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

// material-ui
import { styled } from '@mui/material/styles';
import { Box, List, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import TotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

// styles
const CardWrapper = styled(MainCard)(() => ({
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        // background: `linear-gradient(210.04deg, ${theme.palette.warning.dark} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -180
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        // background: `linear-gradient(140.9deg, ${theme.palette.warning.dark} -14.02%, rgba(144, 202, 249, 0) 70.50%)`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
}));

// ==============================|| DASHBOARD - TOTAL ORDERS ||============================== //

const TopFiveParts = ({ isLoading, todaytopfiveparts, title, date }) => {
    // const theme = useTheme();

    return (
        <>
            {isLoading ? (
                <TotalIncomeCard />
            ) : (
                <CardWrapper border={false} content={false}>
                    <Box sx={{ p: 1 }}>
                        <List sx={{ py: 0 }}>
                            <Typography subtitle2="h4">
                                {title} {date}
                            </Typography>

                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    {isLoading ? (
                                                        <TableCell component="th" colSpan="4" align="center">
                                                            {' '}
                                                            <CircularProgress />{' '}
                                                        </TableCell>
                                                    ) : (
                                                        <>
                                                            <TableCell align="left">#</TableCell>
                                                            <TableCell align="left">Part Name</TableCell>
                                                            <TableCell align="left">Number of Orders (Quantity)</TableCell>

                                                            <TableCell align="left">Order Value</TableCell>
                                                        </>
                                                    )}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {todaytopfiveparts.map((row, index) => (
                                                    <TableRow key={row._id}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.title ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {Number(row?.totalSold).toFixed() ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {Number(row?.totalSalesAmount).toFixed(2) ?? ''}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </List>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

TopFiveParts.propTypes = {
    isLoading: PropTypes.bool
};

export default TopFiveParts;
