import create from 'zustand';

const useOrder = create((set) => ({
    orders: null,
    getSelectedOrders: (arr) => set({ orders: arr }),
    assigned: null,
    getAssigned: (arr) => set({ assigned: arr })
}));

export default useOrder;
