// import { MessageSharp } from '@mui/icons-material';

export const CREATE_BATCH_BEGIN = 'CREATE_BATCH_BEGIN';
export const CREATE_BATCH_SUCCESS = 'CREATE_BATCH_SUCCESS';
export const CREATE_BATCH_FAILURE = 'CREATE_BATCH_FAILURE';
export const BATCH_STATUS_SUCCESS = 'BATCH_STATUS_SUCCESS';
export const BATCH_ORDERS_SUCCESS = 'BATCH_ORDERS_SUCCESS';

export const GET_BATCH_BEGIN = 'GET_BATCH_BEGIN';
export const GET_BATCH_SUCCESS = 'GET_BATCH_SUCCESS';
export const GET_BATCH_FAILURE = 'GET_BATCH_FAILURE';

export const DELETE_BATCH_BEGIN = 'DELETE_BATCH_BEGIN';

export const FILTER_BATCH_BEGIN = 'FILTER_BATCH_BEGIN';
export const FILTER_BATCH_SUCCESS = 'FILTER_BATCH_SUCCESS';
export const FILTER_BATCH_ERROR = 'FILTER_BATCH_ERROR';
export const RESET_BATCH_BEGIN = 'RESET_BATCH_BEGIN';
export const RESET_BATCH = 'RESET_BATCH';

export const SINGLE_BATCH_BEGIN = 'SINGLE_BATCH_BEGIN';
export const SINGLE_BATCH_SUCCESS = 'SINGLE_BATCH_SUCCESS';
export const SINGLE_BATCH_FAILURE = 'SINGLE_BATCH_FAILURE';

export const UPDATE_BATCH_BEGIN = 'UPDATE_BATCH_BEGIN';
export const UPDATE_BATCH_SUCCESS = 'UPDATE_BATCH_SUCCESS';
export const UPDATE_BATCH_FAILURE = 'UPDATE_BATCH_FAILURE';

export const SET_DASHBOARD_VIEW = 'SET_DASHBOARD_VIEW';
