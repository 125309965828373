/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState, useMemo } from 'react';
import * as XLSX from 'xlsx';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
// import Controls from '../../components/controls/Controls';
// import { Search } from '@mui/icons-material';
// import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import MoonLoader from 'react-spinners/MoonLoader';
import TextField from '@mui/material/TextField';
// import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import swal from 'sweetalert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import Grid from '@mui/material/Grid';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ViewIcon from '@mui/icons-material/Preview';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    }
}));

const Retailer = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [retailerInfo, setRetailer] = useState([]);
    // const [retailerData, setRetailerData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [count, setCount] = useState(50);
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [file, setFile] = useState('');
    const [filterstage, setfilterstage] = useState('');
    const [filterRating, setfilterRating] = useState('');
    const [filtersalesperson, setfiltersalesperson] = useState('');
    const [stageInfo, setStage] = useState([]);
    const [salespersonInfo, setSalesTeam] = useState([]);
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState('');
    const [searched, setSearched] = useState('');
    const [searchedPhone, setSearchedPhone] = useState('');
    const [stageId, setStageId] = useState('');
    const [rating, setRating] = useState('');
    const [localSearch, setlocalSearch] = useState('');
    const [localSearch2, setlocalSearch2] = useState('');
    const [localSearch3, setlocalSearch3] = useState('');
    const [locationSearch, setLocationSearch] = useState('');
    const [allRetailers, setAllRetailers] = useState([]);
    const [filterRetailer, setFilterRetailer] = useState('');
    const [filteredRetailerOptions, setFilteredRetailerOptions] = useState([]);
    const [selectedRetailerOption, setSelectedRetailerOption] = useState(null);
    const [openOption, setOpenOption] = useState(true);

    const roles = localStorage.getItem('userRole');

    useEffect(() => {
        const getRetailers = async () => {
            try {
                const userInfo = localStorage.getItem('userToken');
                const config = {
                    headers: {
                        'auth-token': userInfo,
                        'Content-Type': 'application/json'
                    }
                };
                fetch(`${ApiUrl.BaseUrl}/retailersummary/retailerdata`, config)
                    .then((data) => data.json())
                    .then((data) => {
                        setAllRetailers(data?.data);
                    })
                    .finally(() => {
                        // setLoading(false);
                    });
            } catch (e) {
                console.log(e);
            }
        };
        getRetailers();
    }, []);

    const retailerOptions = allRetailers.map((result) => ({
        value: result._id,
        label: result.partnerName
    }));

    const retailerOption = retailerOptions.map((item, index) => {
        return { label: item.label, value: item.value };
    });

    const handleEditClick = (row) => {
        if (scriptedRef.current) {
            history(`/editRetailer/${row._id}`);
        }
    };
    const handleLogsClick = (row) => {
        if (scriptedRef.current) {
            history(`/retailerauditlog/${row._id}`);
        }
    };

    const handleOrderhistoryClick = (row) => {
        if (scriptedRef.current) {
            history(`/retailerOrderHistory/${row._id}`);
        }
    };

    const handleChangePage = (event, newPage) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);

            let url = `${ApiUrl.AllMainRetailerUrl}`;
            if (stageId && filtersalesperson) {
                url += `?stageId=${stageId}&filtersalesperson=${filtersalesperson}&page=${newPage}`;
            } else if (filtersalesperson) {
                url += `?filtersalesperson=${filtersalesperson}&page=${newPage}`;
            } else {
                url += `?page=${newPage}`;
            }

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setRetailer(data.data);
                        setPage(data?.currentPage);
                        setCount(data?.totalPartners);
                        setRowsPerPage(data?.perPage);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        // setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);

        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            let url = `${ApiUrl.AllMainRetailerUrl}`;
            if (stageId && filtersalesperson) {
                url += `?stageId=${stageId}&filtersalesperson=${filtersalesperson}&limit=${event.target.value}`;
            } else if (filtersalesperson) {
                url += `?filtersalesperson=${filtersalesperson}&limit=${event.target.value}`;
            } else {
                url += `?limit=${event.target.value}`;
            }

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setRetailer(data.data);
                        setPage(data?.currentPage);
                        setCount(data?.totalPartners);
                        setRowsPerPage(data?.perPage);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const selectedFile = (e) => {
        setFile(e.target.files[0]);
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    const retrieveAlLsalesTeam = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.SalesTeamActiveSalesPage, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setSalesTeam(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllStage = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllStageUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setStage(data.data);
                        setLoading(false);
                    } else {
                        toast.error(data.data);
                        setLoading(false);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - retailerInfo.length) : 0;

    const retrieveAllRetail = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            setLoading(true);
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            let url = `${ApiUrl.AllMainRetailerUrl}`;
            if (searched) {
                url += `?partner=${searched}`;
            } else if (searchedPhone) {
                url += `?partnerPhone=${searchedPhone}`;
            } else if (stageId) {
                url += `?stageId=${stageId}`;
            } else if (filtersalesperson) {
                url += `?filtersalesperson=${filtersalesperson}`;
            } else if (locationSearch) {
                url += `?location=${locationSearch}`;
            }

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setRetailer(data.data);
                        setPage(data?.currentPage);
                        setCount(data?.totalPartners);
                        setRowsPerPage(data?.perPage);

                        setLoading(false);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handleFilterStageChange = (e) => {
        setfilterstage(e.target.value);
        const userInfo = localStorage.getItem('userToken');
        const stageId = e.target.value;
        setStageId(e.target.value);

        setLoading(true);
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        let url = `${ApiUrl.AllMainRetailerUrl}`;
        if (stageId && filtersalesperson) {
            url += `?stageId=${stageId}&filtersalesperson=${filtersalesperson}`;
        } else if (stageId) {
            url += `?stageId=${stageId}`;
        } else if (filtersalesperson) {
            url += `?filtersalesperson=${filtersalesperson}`;
        } else if (locationSearch) {
            url += `?location=${locationSearch}`;
        }

        fetch(url, config)
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    toast.success(data.message);
                    // console.log('body', result.data);
                    setRetailer(data.data ?? []);
                    setPage(data?.currentPage);
                    setCount(data?.totalPartners);
                    setRowsPerPage(data?.perPage);

                    setLoading(false);
                } else {
                    toast.error(data.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleFilterRatingChange = (e) => {
        setfilterRating(e.target.value);
        const userInfo = localStorage.getItem('userToken');
        const rating = e.target.value;
        setRating(e.target.value);

        setLoading(true);
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        let url = `${ApiUrl.AllMainRetailerUrl}`;
        if (rating && filtersalesperson) {
            url += `?rating=${rating}&filtersalesperson=${filtersalesperson}`;
        } else if (rating) {
            url += `?rating=${rating}`;
        } else if (filtersalesperson) {
            url += `?filtersalesperson=${filtersalesperson}`;
        } else if (locationSearch) {
            url += `?location=${locationSearch}`;
        }

        fetch(url, config)
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    toast.success(data.message);
                    // console.log('body', result.data);
                    setRetailer(data.data ?? []);
                    setPage(data?.currentPage);
                    setCount(data?.totalPartners);
                    setRowsPerPage(data?.perPage);

                    setLoading(false);
                } else {
                    toast.error(data.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleFilterRetailerChange = (event) => {
        setOpenOption(true);
        setFilterRetailer(event.target.value);
        const filteredRetailerOptions = allRetailers.filter((option) => {
            return option.partnerName.toLowerCase().includes(filterRetailer.toLowerCase());
        });
        setFilteredRetailerOptions(filteredRetailerOptions);
    };

    const handleOptionSelect = async (option) => {
        setSelectedRetailerOption(option);
        setFilterRetailer(option.partnerName);
        setOpenOption(false);

        try {
            const userInfo = localStorage.getItem('userToken');
            setLoading(true);
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            let url = `${ApiUrl.AllMainRetailerUrl}`;
            if (option.partnerName) {
                url += `?partner=${option.partnerName}`;
            } else if (searchedPhone) {
                url += `?partnerPhone=${searchedPhone}`;
            } else if (stageId) {
                url += `?stageId=${stageId}`;
            } else if (filtersalesperson) {
                url += `?filtersalesperson=${filtersalesperson}`;
            }

            await fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        toast.success(data.message);
                        setRetailer(data.data);
                        setPage(data?.currentPage);
                        setCount(data?.totalPartners);
                        setRowsPerPage(data?.perPage);

                        setLoading(false);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handleEnterKeyPress = (event) => {
        if (event.key === 'Enter' && filteredRetailerOptions.length > 0) {
            // Select the first option in the filtered list
            handleOptionSelect(filteredRetailerOptions[0]);
        }
    };

    const handleFilterSalesPersonChange = (e) => {
        setfiltersalesperson(e.target.value);
        const userInfo = localStorage.getItem('userToken');
        const filtersalesperson = e.target.value;
        // const stageId = filterstage;
        setLoading(true);

        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        let url = `${ApiUrl.AllMainRetailerUrl}`;
        if (filtersalesperson && stageId) {
            url += `?filtersalesperson=${filtersalesperson}&stageId=${stageId}`;
        } else if (filtersalesperson) {
            url += `?filtersalesperson=${filtersalesperson}`;
        } else if (stageId) {
            url += `?stageId=${stageId}`;
        }

        fetch(url, config)
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    toast.success(data.message);
                    // console.log('body', result.data);
                    setRetailer(data.data ?? []);
                    setPage(data?.currentPage);
                    setCount(data?.totalPartners);
                    setRowsPerPage(data?.perPage);

                    setLoading(false);
                } else {
                    toast.error(data.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handledateChange = (event, picker) => {
        // console.log('endDate', picker.endDate._d);
        setstartDate(picker.startDate._d);
        setendDate(picker.endDate._d);
        const startDate = picker.startDate._d;
        const endDate = picker.endDate._d;
        const userInfo = localStorage.getItem('userToken');
        // const stageId = filterstage;
        setLoading(true);

        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        let url = `${ApiUrl.AllMainRetailerUrl}`;
        if (startDate && endDate) {
            url += `?startDate=${startDate}&endDate=${endDate}`;
        }

        fetch(url, config)
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    toast.success(data.message);
                    // console.log('body', result.data);
                    setRetailer(data.data ?? []);
                    setPage(data?.currentPage);
                    setCount(data?.totalPartners);
                    setRowsPerPage(data?.perPage);

                    setLoading(false);
                } else {
                    toast.error(data.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const debounce = () => {
        let timeoutID;
        return (e) => {
            setlocalSearch(e.target.value);
            clearTimeout(timeoutID);
            timeoutID = setTimeout(() => {
                setSearched(e.target.value);
            }, 500);
        };
    };
    const searchDebounce = () => {
        let timeoutID;
        return (e) => {
            setlocalSearch3(e.target.value);
            clearTimeout(timeoutID);
            timeoutID = setTimeout(() => {
                setLocationSearch(e.target.value);
            }, 500);
        };
    };

    const handleSearch = useMemo(() => debounce(), []);
    const handlelocationSearch = useMemo(() => searchDebounce(), []);

    const debounce2 = () => {
        let timeoutID;
        return (e) => {
            setlocalSearch2(e.target.value);
            clearTimeout(timeoutID);
            timeoutID = setTimeout(() => {
                setSearchedPhone(e.target.value);
            }, 500);
        };
    };

    const handleSearchPhone = useMemo(() => debounce2(), []);

    useEffect(() => {
        retrieveAllRetail();
        retrieveAllStage();
        retrieveAlLsalesTeam();
        // eslint-disable-next-line
    }, [searched, searchedPhone, locationSearch]);

    const handleReset = () => {
        retrieveAllRetail();
    };

    const handledataexport = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(retailerInfo);
        XLSX.utils.book_append_sheet(wb, ws, 'Retailers');
        XLSX.writeFile(wb, 'Retailers.xlsx');
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        // console.log('e', e);
        const formData = new FormData();
        formData.append('file', file);
        // console.log('file', file);
        // console.log('file type', file.type);
        const userInfo = localStorage.getItem('userToken');
        if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            try {
                const data = await file.arrayBuffer();
                const workbook = XLSX.read(data);
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                // console.log('workbook', JSON.stringify(jsonData));
                fetch(ApiUrl.UploadRetailsUrl, {
                    method: 'POST',
                    headers: {
                        'auth-token': userInfo,
                        'Content-Type': 'application/json'
                    },

                    body: JSON.stringify(jsonData)
                })
                    .then((res) => res.json())
                    .then((result) => {
                        if (result.status) {
                            toast.success(result.message);
                            setLoading(false);
                            if (scriptedRef) {
                                handleClose();
                                setTimeout(() => {
                                    retrieveAllRetail();
                                }, 3000);
                            }
                        } else {
                            toast.error(result.data);
                            setLoading(false);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } catch (e) {
                console.log(e);
            }
        } else {
            toast.error('File Type not supported, kindly upload excel');
        }
    };

    const handleDeleteClick = (row) => {
        swal({
            title: 'Are you sure?',
            text: `Once deleted, you will not be able to recover this Retail!(${row.partnerName})`,
            icon: 'warning',
            buttons: true,
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                swal('Poof! The Retail has been deleted!', {
                    icon: 'success'
                });

                const data = {
                    id: row._id
                };
                const userInfo = localStorage.getItem('userToken');

                fetch(ApiUrl.DeleteChannelPartnerUrl, {
                    method: 'DELETE',
                    headers: {
                        'auth-token': userInfo,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                })
                    .then((res) => res.json())
                    .then((result) => {
                        if (result.status === true) {
                            if (scriptedRef.current) {
                                retrieveAllRetail();
                                // window.location.href = '/stage';
                                // history('/stage');
                            }
                        } else {
                            toast.error(result.data);
                        }
                    });
            } else {
                swal('The Retail Partner is safe!');
            }
        });
    };

    // const filteredRows = retailerInfo.filter((row) => {
    //     return (
    //         (row?.partnerName || '').toLowerCase().includes(searched.toLowerCase()) &&
    //         (row?.partnerContact || '').toLowerCase().includes(searchedPhone.toLowerCase())
    //     );
    // });
    // const isPlural = filteredRows && filteredRows.length > 1 ? 's' : '';
    // const searchCount = searched
    //     ? `${filteredRows.length} result${isPlural} found`
    //     : // eslint-disable-next-line no-constant-condition
    //     'No Results' || searchedPhone
    //     ? `${filteredRows.length} result${isPlural} found`
    //     : 'No Results';

    return (
        <>
            <br />
            <PageHeader title="Channel partners" subTitle="Home | Configuration | Channel Partners | Retail" />
            <br />
            <MainCard title="Retail Partners">
                <h4 style={{ color: '#ff740f', fontWeight: '700' }}>{`Total Retailers: ${count}`}</h4>
                <br />
                <br />
                <Box display="flex" justifyContent="flex-start">
                    <Grid>
                        <FormControl fullWidth style={{ marginBottom: '10px' }}>
                            <TextField
                                id="outlined-basic"
                                label="Search Retail Partner"
                                variant="outlined"
                                value={localSearch}
                                onChange={handleSearch}
                            />
                        </FormControl>
                    </Grid>
                    {/* <Grid>
                        <Box width={190} height={20}>
                            <FormControl fullWidth>
                                <TextField
                                    id="outlined-basic"
                                    label="Search Retail Partner"
                                    variant="outlined"
                                    value={filterRetailer}
                                    onChange={handleFilterRetailerChange}
                                    onKeyPress={handleEnterKeyPress}
                                />
                                {openOption &&
                                    filteredRetailerOptions.map((retailer) => (
                                        <MenuItem
                                            key={retailer._id}
                                            value={retailer._id}
                                            onClick={() => handleOptionSelect(retailer)}
                                            sx={{
                                                zIndex: '2340',
                                                backgroundColor: '#fff',
                                                '&:hover': {
                                                    background: '#e4e4e4'
                                                }
                                            }}
                                        >
                                            {retailer.partnerName}
                                        </MenuItem>
                                    ))}
                            </FormControl>
                        </Box>
                    </Grid> */}
                    <Box width={10} />
                    <Grid>
                        <FormControl fullWidth style={{ marginBottom: '10px' }}>
                            <TextField
                                id="outlined-basic"
                                label="Search PhoneNumber"
                                variant="outlined"
                                value={localSearch2}
                                onChange={handleSearchPhone}
                            />
                        </FormControl>
                    </Grid>
                </Box>{' '}
                <br />
                <Box display="flex" justifyContent="flex-start">
                    <Box width={190} height={20}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Filter By Stage</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={filterstage}
                                label="Status"
                                onChange={handleFilterStageChange}
                            >
                                {/* <MenuItem value="All">All</MenuItem> */}
                                {stageInfo.map((stage) => (
                                    <MenuItem key={stage._id} value={stage._id}>
                                        {stage.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box width={10} />
                    <Box width={190} height={20}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Filter By RM</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={filtersalesperson}
                                label="Status"
                                onChange={handleFilterSalesPersonChange}
                            >
                                {/* <MenuItem value="All">All</MenuItem> */}
                                {salespersonInfo.map((sales) => (
                                    <MenuItem key={sales._id} value={sales._id}>
                                        {sales?.name}
                                        {sales?.firstName}
                                        {sales?.lastName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>{' '}
                <br /> <br /> <br />
                <Box display="flex" justifyContent="flex-start">
                    <Box width={190} height={20}>
                        <Grid>
                            <FormControl fullWidth style={{ marginBottom: '10px' }}>
                                <TextField
                                    id="outlined-basic"
                                    label="Search By Location"
                                    variant="outlined"
                                    value={localSearch3}
                                    onChange={handlelocationSearch}
                                />
                            </FormControl>
                        </Grid>
                    </Box>
                    <Box width={10} />
                    <Box width={190} height={20}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Filter By Rating</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={filterRating}
                                label="Status"
                                onChange={handleFilterRatingChange}
                            >
                                <MenuItem value="All">All</MenuItem>
                                <MenuItem value="High">High</MenuItem>
                                <MenuItem value="Medium">Medium</MenuItem>
                                <MenuItem value="Low">Low</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>{' '}
                <br /> <br /> <br />
                <Box width={20} />
                <Box display="flex" justifyContent="flex-start">
                    <DateRangePicker initialSettings={{ startDate, endDate }} onApply={handledateChange}>
                        <Button variant="contained" size="sm" className={classes.item} color="info">
                            filter by Date
                        </Button>
                    </DateRangePicker>
                    <Box width={10} />
                    <Button variant="contained" onClick={handleReset} size="sm" className={classes.item} color="error">
                        Reset Filter
                    </Button>
                </Box>
                <br />
                <Box display="flex" justifyContent="flex-start">
                    <Button variant="contained" onClick={handleClickOpen} size="sm" className={classes.item} color="secondary">
                        Upload
                    </Button>
                    <Box width={10} />

                    <Button component={Link} to="/addRetailer" variant="contained" size="sm" className={classes.item}>
                        Add
                    </Button>
                    <Box width={10} />
                    {roles === 'CustomerSuccessLead' ? (
                        <Button onClick={handledataexport} variant="contained" size="sm" className={classes.item} color="info">
                            Download Excel
                        </Button>
                    ) : (
                        ''
                    )}
                </Box>
                <br />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <>
                                        <TableCell component="th" colSpan="4" align="center">
                                            {' '}
                                            <CircularProgress size={60} thickness={4} />
                                            {'LOADING...... '}
                                        </TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Channel
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Partner Name
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Contact
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Location
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            GPS Coordinates
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Pod
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Sales Person
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Stage
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Sign up Date
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Rating
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Status
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {retailerInfo.map((row, index) => (
                                <TableRow key={row._id}>
                                    <TableCell component="th" scope="row">
                                        {retailerInfo.length - index}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.channelName ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.partnerName ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.partnerContact ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.location ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.locationLat ?? ''} , {row?.locationLng ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.pod ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.salesTeamId ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.stageId ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {toTimestamp(row?.signupDate)}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.rating ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.status ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        <Tooltip title="Order History">
                                            <IconButton aria-label="view" color="success" onClick={() => handleOrderhistoryClick(row)}>
                                                <ViewIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(row)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton aria-label="edit" color="error" onClick={() => handleLogsClick(row)}>
                                            Log
                                        </IconButton>
                                        <IconButton aria-label="delete" color="error" onClick={() => handleDeleteClick(row)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[100, 200, 300, count]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={!count || count <= 0 ? 0 : +page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">Upload Retail Partners</DialogTitle>
                    <Form onSubmit={onSubmit}>
                        <DialogContent>
                            {/* <DialogContentText> */}

                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Control
                                    type="file"
                                    onChange={selectedFile}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                />
                                <br /> <br />
                                <Form.Label>Expected columns</Form.Label>
                                <br /> <br />
                                (Note: salesfocal,stage,customersuccess should be an ID from the Sales Team, Stage and Customer Success Team
                                Pages)
                                <br />
                                <br />
                                <Form.Label>
                                    partnername, datecontacted, phone, salesfocal, stage, dateonboarded,
                                    <br />
                                    partnertype,status,latitude,longitude,customersuccess,partlistcollected,
                                    <br />
                                    datefirstpartreceived,kyccompleted,notes
                                </Form.Label>
                            </Form.Group>

                            {/* </DialogContentText> */}
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" size="sm" className={classes.item} autoFocus onClick={handleClose} color="error">
                                Cancel
                            </Button>
                            <Button autoFocus variant="contained" type="submit" size="sm" className={classes.item}>
                                Upload
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </MainCard>
        </>
    );
};

export default Retailer;
