import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Button, Select, Progress, Input, Popover, Checkbox, Modal, Spin, Divider, Table } from 'antd';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { decrypt, encrypt } from '../../../../utils/encrypt';
import { toast } from 'react-toastify';
import 'react-circular-progressbar/dist/styles.css';
import { Link, useParams, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    CloseCircleOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    LoadingOutlined,
    PlusOutlined,
    PrinterOutlined,
    SearchOutlined
} from '@ant-design/icons';
import SearchIcon from '@mui/icons-material/Search';
import ApiUrl from '../../../../network';

const actionHandler = (record) => {
    window.location.href = `/salesplan/edit/${record.key}`;
};

export const Index = () => {
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [order, setOrder] = useState({});
    const { id } = useParams();
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    const [search, setSearch] = useState('');
    const [filteredCompressedOrder, setFilteredCompressedOrder] = useState([]);
    const [generateWaybill, setGenerateWaybill] = useState(false);
    const [searchInputShow, setSearchInputShow] = useState(false);

    const fetchOrder = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/waybill/viewwaybill/${id}`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setOrder(result);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        toast.error(error.message);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        if (id) {
            fetchOrder();
        } else {
            window.location.href = '/waybill';
            toast.error('No order found');
        }
    }, []);
    useEffect(() => {
        if (Array.isArray(order.ordersDetails)) {
            const filteredOrders = order.ordersDetails.filter((orderItem) => orderItem.partid.includes(search));
            setFilteredCompressedOrder(filteredOrders);
        }
    }, [order.ordersDetails, search]);

    const columns = [
        {
            title: 'Product name',
            dataIndex: 'partid',
            key: 'partid'
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty'
        }
    ];

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Link to="/generated/waybill" className="createScheduleLink">
                    <p style={{ fontSize: '25px', marginLeft: '10px' }}>
                        <ArrowBackIcon sx={{ fontSize: '25px', color: '#000' }} /> Waybill details
                    </p>
                </Link>
                <div>
                    <Button
                        type="primary"
                        style={{
                            backgroundColor: '#FF740F',
                            color: '#fff',
                            borderRadius: '10px',
                            marginRight: '10px',
                            marginBottom: '10px',
                            marginLeft: 'auto',
                            width: '200px'
                        }}
                        onClick={() => (window.location.href = `/waybill/print/${id}`)}
                    >
                        Print Waybill
                    </Button>
                </div>
            </div>
            <div className="bg-white p-4">
                <div className="batchFlex">
                    <div>
                        <div className="mb-4">
                            <Input
                                value={`waybill date: ${new Date(order?.waybillDate).toLocaleDateString('en-US', options)}`}
                                disabled
                                placeholder="Waybill Date"
                                className="p-2 inputViewKpi"
                            />
                        </div>
                        <div className="mb-4">
                            <Input
                                value={`batch date: ${new Date(order?.batchDate).toLocaleDateString('en-US', options)}`}
                                disabled
                                placeholder="Batch Date"
                                className="p-2 inputViewKpi"
                            />
                        </div>
                        <div className="mb-4">
                            <Input
                                value={`Name: ${order?.userId?.firstName} ${order?.userId?.lastName}`}
                                disabled
                                placeholder="Name"
                                className="p-2 inputViewKpi"
                            />
                        </div>
                        <div className="mb-4">
                            <Input value={`Country: ${order?.country}`} disabled placeholder="Country" className="p-2 inputViewKpi" />
                        </div>
                        <div className="mb-4">
                            <Input
                                value={`Status: ${order?.batchStatusId?.name}`}
                                disabled
                                placeholder="Status"
                                className="p-2 inputViewKpi"
                            />
                        </div>
                    </div>
                    <div className="productsContainer orderListContainer ml-2">
                        <Table rowKey={(record) => record._id} columns={columns} dataSource={order.ordersDetails} pagination={false} />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Index;
