import { Button, Divider, TextField } from '@mui/material';
import { Col, Row } from 'react-grid-system';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionWrapper from 'assets/Wrappers/AccordionWrapper';
import CardItem from 'components/Card/CardItem';
import CollectionsTableData from './CollectionTableData';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingButton from '@mui/lab/LoadingButton';
import MoonLoader from 'react-spinners/MoonLoader';
import React from 'react';
import SimpleCard from 'components/Card/SimpleCard';
import TodoList from 'components/TodoList';
import { useAppContext } from 'context/appContext';

const CollectionAccordion = () => {
    const [input, setInput] = React.useState('');
    const [isOpen, setIsOpen] = React.useState(false);
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());

    const {
        collectionsTodos,
        collectionsdue,
        collectionsTarget,
        currentcollectionsTotal,
        lastmontcollectionsTotal,
        currentLastMonthCollectionsRate,
        currentactualcollectionsratio,
        lastmonthactualCollectionsRatio,
        currentPreviousMonthActualCollectionRate,
        getCollections,
        filterCollections,
        resetCollections,
        isLoading
    } = useAppContext();

    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleEvent = (e, picker) => {
        setStartDate(picker.startDate._d.toISOString());
        setEndDate(picker.endDate._d.toISOString());
    };
    const filterOptions = { startDate, endDate };

    const handleCollectionsFilter = async () => {
        filterCollections(filterOptions);
    };

    const handCollectionsReset = () => {
        resetCollections();
    };

    React.useEffect(() => {
        getCollections();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filteredCollections = collectionsdue.filter((item) => {
        return item.channelPartnerId.toLowerCase().includes(input.toLowerCase());
    });

    const collectionsCount = filteredCollections.length;
    const isPlural = collectionsCount > 1 ? 'results' : 'result';
    return (
        <AccordionWrapper>
            <Accordion className="custom-accordion">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expand-icon" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <h3 className="title">Collections</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <Row>
                        <Col xl={7} lg={7} md={12} sm={12} xs={12}>
                            <div className="custom-table">
                                <h4 className="table__heading">Retailers: Collections due</h4>
                                <Divider />
                                <div className="table__content">
                                    <Row>
                                        <Col xl={7} lg={7} md={12} sm={12} xs={12}>
                                            <TextField
                                                id="outlined-basic"
                                                label="Search lead name"
                                                variant="outlined"
                                                className="search-lead"
                                                onChange={(e) => setInput(e.target.value)}
                                            />
                                        </Col>

                                        <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                                            <DateRangePicker
                                                initialSettings={{ startDate, endDate }}
                                                onEvent={handleEvent}
                                                onApply={handleCollectionsFilter}
                                                onCancel={handCollectionsReset}
                                            >
                                                <LoadingButton
                                                    variant="contained"
                                                    disableElevation
                                                    size="medium"
                                                    className="custom-date-picker"
                                                    loadingIndicator="Loading…"
                                                    loading={isLoading}
                                                >
                                                    Filter By Date
                                                </LoadingButton>
                                            </DateRangePicker>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="lead-table">
                                    {input && (
                                        <h4>
                                            {collectionsCount > 0
                                                ? `Displaying ${filteredCollections.length} ${isPlural}`
                                                : 'No Results found'}
                                        </h4>
                                    )}
                                    {isLoading ? (
                                        <div className="loader-spinner">
                                            <MoonLoader color="#ff740f" />
                                        </div>
                                    ) : (
                                        <CollectionsTableData
                                            rows={filteredCollections}
                                            th1="#"
                                            th2="Retailer Name"
                                            th3="Collection due date"
                                            th4="Outstanding balance"
                                            th5="Actions"
                                        />
                                    )}
                                </div>
                            </div>
                        </Col>

                        <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Button className="btn-todo" variant="contained" size="medium" onClick={handleDropdownToggle}>
                                        Todo list &nbsp; &nbsp;
                                        <span className="btn-count">{collectionsTodos.length}</span>
                                    </Button>
                                    {isOpen && <TodoList todos={collectionsTodos} />}
                                </Col>

                                <Col xl={9} lg={9} md={9} sm={9} xs={9}>
                                    <SimpleCard count={collectionsTarget} title="Collection target" isMoney="yes" />
                                </Col>

                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <CardItem
                                        cardTitle="Total Collections"
                                        thisMonth={currentcollectionsTotal}
                                        isMoney="yes"
                                        prevMonth={lastmontcollectionsTotal}
                                        perChange={currentLastMonthCollectionsRate}
                                    />
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <CardItem
                                        cardTitle="Ratio of actual collections to target collections"
                                        isPercent="%"
                                        thisMonth={currentactualcollectionsratio}
                                        prevMonth={lastmonthactualCollectionsRatio}
                                        perChange={currentPreviousMonthActualCollectionRate}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </AccordionDetails>
            </Accordion>
        </AccordionWrapper>
    );
};

export default CollectionAccordion;
