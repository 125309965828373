import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Link, useNavigate, useParams, useHistory } from 'react-router-dom';
import WestIcon from '@mui/icons-material/West';
import { Button, Select, Input } from 'antd';
import { encrypt, decrypt } from '../../utils/encrypt';
import { ToastContainer, toast } from 'react-toastify';
import FleetAlert from './FleetAlert';
import ApiUrl from '../../network';

const AssignFleet = () => {
    const [loading, setLoading] = useState(false);
    const [assingLoading, setAssignLoading] = useState(false);
    const [allDrivers, setAllDrivers] = useState([]);
    const [vehicleNumber, setVehicleNumber] = useState('');
    const [vehicle, setVehicle] = useState('');
    const [driver, setDriver] = useState('');
    const [allVehilces, setAllVehilces] = useState([]);
    const [fleetSchedule, setFleetSchedule] = useState(null);
    const [distance, setDistance] = useState('');
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [isSuccessOpen, setIsModalSuccessOpen] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    // success alert modals
    const showSuccessModal = () => {
        setIsModalSuccessOpen(true);
    };

    const handleSuccessOk = () => {
        setIsModalSuccessOpen(false);
    };

    const handleSuccessCancel = () => {
        setIsModalSuccessOpen(false);
    };

    // get fleet schedule
    const singleFleetSchedule = async (data) => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/fleetschedule/viewfleetschedule`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            if (data) {
                if (data) urlParams.append('id', data);
            }

            url = `${url}?${urlParams.toString()}`;

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setLoading(false);
                        setFleetSchedule(answer);
                        setDriver(answer?.driverId._id);
                        setVehicle(answer?.vehicleId._id);
                        setVehicleNumber(answer?.vehicleNo);
                        setDistance(answer?.distanceInMiles);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                        toast.error(answer);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        singleFleetSchedule(id);
    }, []);

    // get All Drivers
    const getAllDrivers = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/driver/alldrivers`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setAllDrivers(answer);
                        setLoading(false);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                        toast.error(answer);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        getAllDrivers();
    }, []);

    const driverOptions = allDrivers?.map((item, index) => {
        return { label: `${item?.firstName} ${item?.lastName}`, value: item?._id };
    });

    const handleDriverChange = (value) => {
        setDriver(value);
    };

    // get All vehicles
    const getAllVehicles = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/salesplanning/garagevehicleslist`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setAllVehilces(answer);
                        setLoading(false);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                        toast.error(answer);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        getAllVehicles();
    }, []);

    const vehicleOptions = allVehilces?.map((item, index) => {
        return { label: item?.vehicleType, value: item?._id };
    });

    const handleVehicleChange = (value) => {
        setVehicle(value);
        const getNumber = allVehilces?.find((item) => {
            return value === item._id;
        });
        setVehicleNumber(getNumber.vehicleNumber);
    };

    const locationOptions = fleetSchedule?.locations?.map((item, index) => {
        return { label: item?.name, value: item?._id };
    });

    const rmaOptions = fleetSchedule?.rmas?.map((item) => {
        return { label: `${item?.firstName} ${item?.lastName}`, value: item?._id };
    });

    // const handleVehicleChange = (value) => {
    //     setVehicle(value);
    // };

    // edit fleet
    const EditFleet = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        setAssignLoading(true);

        const data = {
            id,
            driverId: driver,
            vehicleId: vehicle,
            vehicleNo: vehicleNumber,
            rmas: fleetSchedule?.rmas,
            locations: fleetSchedule?.locations,
            route: [
                {
                    routeId: '64f5abdea126ba92954aed0sdfa',
                    routeName: 'Shop C'
                },
                {
                    routeId: '64f5abdea126ba92954aed233',
                    routeName: 'Shop D'
                }
            ],
            distanceInMiles: distance
        };
        showSuccessModal();

        const obj = JSON.stringify(data);
        const result = encrypt(obj, keyResult, ivResult);
        fetch(`${ApiUrl.BaseUrl}/fleetschedule/updatefleetschedule`, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ payload: result })
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                } else if (result.status === false) {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                    handleSuccessCancel();
                } else {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                    handleSuccessCancel();
                }
            })
            .finally(() => {
                setAssignLoading(false);
            });
    };

    return (
        <div>
            {' '}
            <List
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    padding: '0 0 16px 0',
                    borderBottom: '1px dashed #E7EAEB',
                    alignItems: 'flex-start',
                    margin: '0px auto 15px'
                }}
            >
                <WestIcon sx={{ fontSize: '32px', cursor: 'pointer' }} onClick={() => navigate(-1)} />

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginLeft: '24px'
                    }}
                >
                    <ListItem
                        sx={{
                            fontSize: '1.875rem',
                            color: '#0A2938',
                            padding: 0,
                            fontWeight: 900,
                            marginBottom: '7px'
                        }}
                    >
                        Edit fleet schedule
                    </ListItem>
                    {/* <ListItem sx={{ fontSize: '0.875rem', color: '#565C6B', padding: 0 }}>Orders available to be assigned</ListItem> */}
                </Box>
            </List>
            <div className="fleet-form-wrapper">
                <section className="fleet-select-input">
                    <Box
                        sx={{
                            width: ' 277px',
                            margin: '24px 0 0 24px'
                        }}
                    >
                        <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974' }}>Driver</Typography>
                        <Select
                            style={{
                                width: '100%'
                            }}
                            size="large"
                            allowClear
                            placeholder="Driver"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            value={driver}
                            onChange={handleDriverChange}
                            options={driverOptions}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: ' 277px',
                            margin: '24px 0 0 24px'
                        }}
                    >
                        <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974' }}>Vehicle</Typography>
                        <Select
                            style={{
                                width: '100%'
                            }}
                            size="large"
                            allowClear
                            placeholder="vehicle"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={vehicleOptions}
                            value={vehicle}
                            onChange={handleVehicleChange}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: ' 277px',
                            margin: '24px 0 0 24px'
                        }}
                    >
                        <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974' }}>Vehicle Number</Typography>
                        <Input
                            style={{
                                width: '100%',
                                border: '1px solid #E7EAEB'
                            }}
                            size="large"
                            placeholder="Vehicle number"
                            value={vehicleNumber}
                            disabled
                        />
                    </Box>

                    <Box
                        sx={{
                            width: ' 277px',
                            margin: '24px 0 0 24px'
                        }}
                    >
                        <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974' }}>RMA(s)</Typography>
                        <Select
                            style={{
                                width: '100%'
                            }}
                            maxTagCount={1}
                            loading={loading}
                            mode="multiple"
                            size="large"
                            allowClear
                            placeholder="RMA(s)"
                            value={rmaOptions}
                            disabled
                        />
                    </Box>
                    <Box
                        sx={{
                            width: ' 277px',
                            margin: '24px 0 0 24px'
                        }}
                    >
                        <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974' }}>Route</Typography>
                        <Select
                            style={{
                                width: '100%'
                            }}
                            size="large"
                            maxTagCount={1}
                            mode="multiple"
                            allowClear
                            placeholder="route"
                            value={locationOptions}
                            loading={loading}
                            disabled
                        />
                    </Box>
                    {/* <Box
                        sx={{
                            width: ' 277px',
                            margin: '24px 0 0 24px'
                        }}
                    >
                        <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974' }}>Route</Typography>
                        <Select
                            style={{
                                width: '100%'
                            }}
                            size="large"
                            allowClear
                            placeholder="Route"
                            loading={loading}
                            disabled
                        />
                    </Box> */}
                    {/* <Box
                        sx={{
                            width: ' 277px',
                            margin: '24px 0 0 24px'
                        }}
                    >
                        <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974' }}>Distance in miles</Typography>
                        <Input
                            style={{
                                width: '100%',
                                border: '1px solid #E7EAEB'
                            }}
                            maxtagcount={1}
                            mode="multiple"
                            size="large"
                            placeholder="Distance in miles"
                            value={distance}
                            disabled
                        />
                    </Box> */}
                </section>
                <section className="fleet-form-buttons">
                    <Button
                        style={{
                            backgroundColor: '#FFFFFF',

                            borderRadius: '8px',
                            height: '40px',
                            fontSize: '16px',
                            fontWeight: '500',
                            color: '#0A2938',
                            width: '169px',
                            textAlign: 'center',
                            margin: '0.625rem',
                            border: '1px solid #CED4D7'
                        }}
                        onClick={() => {
                            setDriver('');
                            setVehicle('');
                            setVehicleNumber('');
                        }}
                    >
                        Discard changes{' '}
                    </Button>
                    <Button
                        style={{
                            backgroundColor: '#FF740F',

                            borderRadius: '8px',
                            height: '40px',
                            fontSize: '16px',
                            fontWeight: '500',
                            color: '#FFFFFF',
                            width: '169px',
                            textAlign: 'center',
                            margin: '0.625rem'
                        }}
                        onClick={EditFleet}
                    >
                        Update Changes
                    </Button>
                </section>
            </div>
            <FleetAlert
                showSuccessModal={showSuccessModal}
                handleSuccessOk={handleSuccessOk}
                handleSuccessCancel={handleSuccessCancel}
                isSuccessOpen={isSuccessOpen}
                assingLoading={assingLoading}
                setAssignLoading={setAssignLoading}
            />
        </div>
    );
};

export default AssignFleet;
