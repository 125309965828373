/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../../components/PageHeader';
import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import useScriptRef from 'hooks/useScriptRef';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import EditIcon from '@mui/icons-material/Edit';
// import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Form from 'react-bootstrap/Form';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const ViewMyFleetOrderParts = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    // const [fleetserviceId, setfleetserviceId] = useState('');
    // const [newfleetserviceId, setnewfleetserviceId] = useState('');
    const [customer, setCustomer] = useState('');
    const [customerPhone, setcustomerPhone] = useState('');
    const [carMake, setcarMake] = useState('');
    const [model, setmodel] = useState('');
    const [year, setyear] = useState('');
    const [licensesplate, setlicensesplate] = useState('');
    const [engineType, setengineType] = useState('');
    const [vins, setvins] = useState('');
    const [car, setCar] = useState([]);
    const [cars, setCars] = useState([]);
    const [carModel, setcarModel] = useState([]);
    const [items, setItems] = useState([]);
    const [paymentStatusId, setpaymentStatusRecord] = useState('');
    const [newPaymentStatusId, setNewPaymentStatusId] = useState('');
    const [newOrderStatusId, setNewOrderStatusId] = useState('');
    const [orderStatusId, setorderStatusRecord] = useState('');
    const [orderStatus, setOrderStatus] = useState([]);
    const [location, setLocation] = useState('');
    const [availableTime, setavailableTime] = useState(new Date('2022-03-03T21:11:54'));
    const [totalCost, settotalCost] = useState(0);
    const [serviceFee, setserviceFee] = useState('');
    const [skuId, setSkuId] = useState('');
    const [partid, setpartid] = useState('');
    const [qty, setQty] = useState('');
    const [garagePrice, setgaragePrice] = useState('');
    const [carId, setcarId] = useState('');
    const [channelPartnerId, setchannelPartnerId] = useState('');
    const [newchannelPartnerId, setnewchannelPartnerId] = useState('');
    // const [fleetservice, setfleetservice] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState([]);
    const [fuelType, setFuel] = useState([]);
    const [part, setPart] = useState([]);
    // const [fleetpartner, setFleetPartner] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [opens, setOpens] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openPartEdit, setOpenPartEdit] = useState(false);
    const [indexValue, setIndexValue] = useState('');
    const [indexPartValue, setIndexPartValue] = useState('');
    const [loading2, setLoading2] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const userCurrency = localStorage.getItem('userCurrency');
    const { id } = useParams();

    // const retrieveAllActiveFleetPartner = async () => {
    //     try {
    //         const userInfo = localStorage.getItem('userToken');
    //         const config = {
    //             headers: {
    //                 'auth-token': userInfo,
    //                 'Content-Type': 'application/json'
    //             }
    //         };
    //         setLoading(true);
    //         fetch(ApiUrl.FleetActivePartnerUrl, config)
    //             .then((data) => data.json())
    //             .then((data) => {
    //                 if (data.status === true) {
    //                     setFleetPartner(data.data);
    //                 } else {
    //                     toast.error(data.data);
    //                 }
    //             })
    //             .finally(() => {
    //                 setLoading(false);
    //             });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    const retrieveAllActiveOrderStatus = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllActiveOrderStatus, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrderStatus(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllActivePaymentStatus = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllActivePaymentStatus, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setPaymentStatus(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // const retrieveAllFleetServiceId = async () => {
    //     try {
    //         const userInfo = localStorage.getItem('userToken');
    //         const config = {
    //             headers: {
    //                 'auth-token': userInfo,
    //                 'Content-Type': 'application/json'
    //             }
    //         };
    //         setLoading(true);
    //         fetch(ApiUrl.FleetActiveServiceIdUrl, config)
    //             .then((data) => data.json())
    //             .then((data) => {
    //                 if (data.status === true) {
    //                     setfleetservice(data.data);
    //                 } else {
    //                     toast.error(data.data);
    //                 }
    //             })
    //             .finally(() => {
    //                 setLoading(false);
    //             });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    const retrieveAllCar = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllActiveCar, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCar(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllCarModel = async (id) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllCarModelsDetailsUrl + id, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setcarModel(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    const retrieveAllPart = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.ActivePartUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setPart(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllFleetServiceFee = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.FleetActiveServiceFeeUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setserviceFee(data?.data?.cost ?? 0);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handleAvailableTime = (event) => {
        setavailableTime(event);
    };

    const handleFuelType = (event) => {
        setFuel(event.target.value);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenitems = () => {
        setOpens(true);
    };

    const handleCloseitems = () => {
        setOpens(false);
    };

    const handleClickOpenEdit = () => {
        setOpenEdit(true);
    };
    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleClickPartOpenEdit = () => {
        setOpenPartEdit(true);
    };

    const handleClosePartEdit = () => {
        setOpenPartEdit(false);
    };

    // useEffect(() => {
    //     retrieveAllFleetServiceId();
    //     retrieveAllCar();
    //     retrieveAllPart();
    //     retrieveAllFleetServiceFee();
    //     retrieveAllActivePaymentStatus();
    //     retrieveAllActiveOrderStatus();
    //     retrieveAllActiveFleetPartner();
    // }, []);

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.ViewFleetOrderPartsUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    // console.log('result', result);
                    // setfleetserviceId(result?.data?.newfleetserviceId?.name ?? '');
                    // setnewfleetserviceId(result?.data?.newfleetserviceId?._id ?? '');
                    setCustomer(result?.data?.customer ?? '');
                    setcustomerPhone(result?.data?.customerPhone ?? '');
                    setCars(result?.data?.cars ?? '');
                    setLocation(result?.data?.location ?? '');
                    setavailableTime(result?.data?.availableTime ?? '');
                    setpaymentStatusRecord(result?.data?.newPaymentStatusId?.name ?? '');
                    setNewPaymentStatusId(result?.data?.newPaymentStatusId?._id ?? '');
                    setorderStatusRecord(result?.data?.newOrderStatusId?.name ?? '');
                    setNewOrderStatusId(result?.data?.newOrderStatusId?._id ?? '');
                    setItems(result?.data?.items ?? '');
                    settotalCost(result?.data?.totalCost ?? '');
                    setserviceFee(result?.data?.serviceFee ?? '');
                    setchannelPartnerId(result?.data?.channelPartnerId?._id ?? '');
                    setnewchannelPartnerId(result?.data?.channelPartnerId?.partnerName ?? '');

                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                }
            })
            .finally(() => {
                setLoading2(false);
            });
        // retrieveAllFleetServiceId();
        retrieveAllCar();
        retrieveAllPart();
        retrieveAllFleetServiceFee();
        retrieveAllActivePaymentStatus();
        retrieveAllActiveOrderStatus();
        // retrieveAllActiveFleetPartner();
    }, [id]);

    const onSubmitCarEdit = (event) => {
        event.preventDefault();
        const newList = Object.assign([], cars);
        const data = { ...newList[indexValue] };
        data.carId = carId;
        data.carMake = carMake;
        data.model = model;
        data.year = year;
        data.licensesplate = licensesplate;
        data.fuelType = fuelType;
        data.engineType = engineType;
        data.vins = vins;
        newList[indexValue] = data;
        setCars(newList);
        handleCloseEdit();
    };

    const onSubmitCar = (event) => {
        event.preventDefault();
        // cars, setCars
        setCars([
            ...cars,
            {
                carId,
                carMake,
                model,
                year,
                licensesplate,
                fuelType,
                engineType,
                vins
            }
        ]);

        handleClose();
    };

    const onSubmitPartItems = (event) => {
        event.preventDefault();
        const newList = Object.assign([], items);
        const data = { ...newList[indexPartValue] };
        data.skuId = skuId;
        data.partid = partid;
        data.qty = qty;
        data.garagePrice = garagePrice;
        newList[indexPartValue] = data;
        setItems(newList);
        handleClosePartEdit();
        let sum = 0;
        settotalCost(sum);
        newList.forEach((row) => {
            sum += Number(row.garagePrice) * Number(row.qty);
        });
        settotalCost(sum);
    };

    const onSubmitItems = (event) => {
        event.preventDefault();
        // items, setItems
        setItems([
            ...items,
            {
                skuId,
                partid,
                qty,
                garagePrice
            }
        ]);
        let sum = 0;
        settotalCost(sum);
        const newList = Object.assign([], items);
        newList.forEach((row) => {
            sum += Number(row.garagePrice) * Number(row.qty);
            // console.log('row data', row);
        });
        sum += Number(garagePrice) * Number(qty);
        settotalCost(sum);

        // let sum = totalCost;

        // const amt = garagePrice * qty;
        // sum += amt;
        // settotalCost(sum);

        handleCloseitems();
    };

    // Submit Order
    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        const data = {
            id,
            // fleetserviceId,
            // newfleetserviceId,
            channelPartnerId,
            newchannelPartnerId,
            customer,
            customerPhone,
            cars,
            paymentStatusId,
            newPaymentStatusId,
            orderStatusId,
            newOrderStatusId,
            location,
            availableTime,
            items,
            totalCost,
            serviceFee
        };

        fetch(ApiUrl.UpdateFleetOrderPartsUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/channelpartnerfleetorderparts');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const filterPart = (event) => {
        const newFilter = part.filter((value) => (value.sku || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('newFilter', newFilter);
        newFilter.forEach((option) => {
            setSkuId(option._id);
            setpartid(option.sku);
            setgaragePrice(option.garagePrice);
        });
    };

    // const filterfleetPartnerId = (event) => {
    //     const newFilter = fleetpartner.filter((value) => (value.partnerName || '').toLowerCase().includes((event || '').toLowerCase()));
    //     newFilter.forEach((option) => {
    //         setchannelPartnerId(option._id);
    //     });
    // };

    const filterOrderStatus = (event) => {
        const newFilter = orderStatus.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            setNewOrderStatusId(option._id);
        });
    };

    const filterPaymentStatus = (event) => {
        const newFilter = paymentStatus.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            setNewPaymentStatusId(option._id);
        });
    };

    const handleEditClick = (row, index) => {
        // console.log('row', row);
        setcarId(row?.carId ?? '');
        setcarMake(row?.carMake ?? '');
        setengineType(row?.engineType ?? '');
        setFuel(row?.fuelType ?? '');
        setlicensesplate(row?.licensesplate ?? '');
        setmodel(row?.model ?? '');
        setvins(row?.vins ?? '');
        setyear(row?.year ?? '');
        handleClickOpenEdit();

        setIndexValue(index);
    };

    const handleEditPartClick = (row, index) => {
        // console.log('row', row);
        setgaragePrice(row?.garagePrice ?? '');
        setpartid(row?.partid ?? '');
        setQty(row?.qty ?? '');
        setSkuId(row?.skuId ?? '');
        handleClickPartOpenEdit();

        setIndexPartValue(index);
    };

    const handleDeleteClick = (row, index) => {
        // const newList = cars.filter((item) => item.carId !== row.carId);
        // setCars(newList);

        const newList = Object.assign([], cars);
        newList.splice(index, 1);
        setCars(newList);
    };
    const handleDeleteClickitems = (row, index) => {
        // const newList = items.filter((item) => item.skuId !== row.skuId);

        // setItems(newList);
        const newList = Object.assign([], items);
        newList.splice(index, 1);
        setItems(newList);
        let sum = 0;
        settotalCost(sum);
        newList.forEach((row) => {
            sum += Number(row.garagePrice) * Number(row.qty);
            // console.log('row data', row);
        });
        settotalCost(sum);
    };

    // const filterfleetserviceId = (event) => {
    //     const newFilter = fleetservice.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
    //     newFilter.forEach((option) => {
    //         setnewfleetserviceId(option._id);
    //     });
    // };

    const filterCar = (event) => {
        const newFilter = car.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        newFilter.forEach((option) => {
            // console.log('id', option._id);
            setcarId(option._id);
            retrieveAllCarModel(option._id);
        });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <PageHeader title="Order Part" subTitle="Home | Fleet | Update Order Part" />
                    <MainCard title="">
                        <Typography variant="subtitle2" component="div">
                            Customer Information
                        </Typography>
                        <br />
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2} justify="flex-start">
                                {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={fleetserviceId}
                                            onChange={(event, newValue) => {
                                                setfleetserviceId(newValue);
                                                filterfleetserviceId(newValue);
                                            }}
                                            options={fleetservice.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Fleet Service Id" />}
                                        />
                                    </Box>
                                </Grid> */}
                                {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={newchannelPartnerId}
                                            onChange={(event, newValue) => {
                                                setnewchannelPartnerId(newValue);
                                                filterfleetPartnerId(newValue);
                                            }}
                                            options={fleetpartner.map((option) => option.partnerName)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Fleet Partner" />}
                                        />
                                    </Box>
                                </Grid> */}
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="customer"
                                            variant="outlined"
                                            id="customer"
                                            label="Customer"
                                            value={customer}
                                            onChange={(e) => setCustomer(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="customerPhone"
                                            variant="outlined"
                                            id="customerPhone"
                                            label="Customer Phone No."
                                            value={customerPhone}
                                            onChange={(e) => setcustomerPhone(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />
                            <Typography variant="subtitle2" component="div">
                                Vehicle Information
                            </Typography>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={10}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Car Make
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Car Model
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Car Year
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        License Plate / Tags
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Fuel Type
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Engine Type
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        VIN
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {cars.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {row.carMake}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.model}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.year}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.licensesplate}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.fuelType}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.engineType}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row.vins}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                                aria-label="edit"
                                                                color="primary"
                                                                onClick={() => handleEditClick(row, index)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                onClick={() => handleDeleteClick(row, index)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                    <Button variant="contained" onClick={handleClickOpen} size="sm" className={classes.item}>
                                        Add vehicle
                                    </Button>
                                </Grid>
                            </Grid>
                            <br />
                            <Typography variant="subtitle2" component="div">
                                Drop Location, Time and Payment Status
                            </Typography>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="location"
                                            variant="outlined"
                                            fullWidth
                                            id="location"
                                            value={location}
                                            label="Location"
                                            onChange={(e) => setLocation(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={220}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DateTimePicker
                                                label="Available Times"
                                                value={availableTime}
                                                onChange={handleAvailableTime}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={orderStatusId}
                                            onChange={(event, newValue) => {
                                                setorderStatusRecord(newValue);
                                                filterOrderStatus(newValue);
                                            }}
                                            options={orderStatus.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Order Status" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={paymentStatusId}
                                            onChange={(event, newValue) => {
                                                setpaymentStatusRecord(newValue);
                                                filterPaymentStatus(newValue);
                                            }}
                                            options={paymentStatus.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Payment Status" />}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />
                            <Typography variant="subtitle2" component="div">
                                Parts
                            </Typography>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={10}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Part Name
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Qty
                                                    </TableCell>
                                                    {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Type
                                                    </TableCell> */}
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Part Cost
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {items.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {row.partid}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.qty}
                                                        </TableCell>
                                                        {/* <TableCell component="th" scope="row">
                                                            {row.partType}
                                                        </TableCell> */}

                                                        <TableCell component="th" scope="row">
                                                            {row.garagePrice}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                                aria-label="edit"
                                                                color="primary"
                                                                onClick={() => handleEditPartClick(row, index)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                onClick={() => handleDeleteClickitems(row, index)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                    <Button variant="contained" onClick={handleClickOpenitems} size="sm" className={classes.item}>
                                        Add Part
                                    </Button>
                                </Grid>
                            </Grid>
                            <br />
                            <br />
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Typography variant="h4" component="div">
                                        Total Parts Cost
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Typography variant="h4" component="div">
                                        {userCurrency} {totalCost}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Typography variant="h4" component="div">
                                        Service Cost
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Typography variant="h4" component="div">
                                        {userCurrency} {serviceFee}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <br />
                            <br />
                            <Box display="flex" justifyContent="space-between">
                                <Button
                                    component={Link}
                                    to="/channelpartnerfleetorderparts"
                                    type="submit"
                                    variant="contained"
                                    color="error"
                                >
                                    Reset
                                </Button>
                                <Button type="submit" variant="contained" color="secondary">
                                    {loading ? 'Updating ..' : 'Update Order Part'}
                                </Button>
                            </Box>
                        </form>

                        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                            <DialogTitle id="responsive-dialog-title">Add Vehicle</DialogTitle>
                            <Form onSubmit={onSubmitCar}>
                                <DialogContent>
                                    {/* <DialogContentText> */}

                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Box width={200}>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                value={carMake}
                                                onChange={(event, newValue) => {
                                                    setcarMake(newValue);
                                                    filterCar(newValue);
                                                }}
                                                options={car.map((option) => option.name)}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                renderInput={(params) => <TextField {...params} label="Car Make" />}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                value={model}
                                                onChange={(event, newValue) => {
                                                    setmodel(newValue);
                                                }}
                                                options={carModel.map((option) => option.name)}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                renderInput={(params) => <TextField {...params} label="Car Model" />}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="year"
                                                variant="outlined"
                                                required
                                                id="year"
                                                label="Car Year"
                                                value={year}
                                                onChange={(e) => setyear(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="licensesplate"
                                                variant="outlined"
                                                required
                                                id="licensesplate"
                                                label="License Plate / Tags"
                                                value={licensesplate}
                                                onChange={(e) => setlicensesplate(e.target.value)}
                                            />
                                        </Box>
                                        <br />
                                        <br />
                                        <Box width={200}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Fuel Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={fuelType}
                                                    label="Status"
                                                    onChange={handleFuelType}
                                                >
                                                    <MenuItem value="Body">Select an option</MenuItem>
                                                    <MenuItem value="Petrol">Petrol</MenuItem>
                                                    <MenuItem value="Diesel">Diesel</MenuItem>
                                                    <MenuItem value="Gas">Gas</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="engineType"
                                                variant="outlined"
                                                required
                                                id="engineType"
                                                label="Engine Type"
                                                value={engineType}
                                                onChange={(e) => setengineType(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="vins"
                                                variant="outlined"
                                                required
                                                id="vins"
                                                label="VIN"
                                                value={vins}
                                                onChange={(e) => setvins(e.target.value)}
                                            />
                                        </Box>
                                    </Form.Group>

                                    {/* </DialogContentText> */}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="sm"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleClose}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        type="submit"
                                        size="sm"
                                        className={classes.item}
                                        disabled={!carMake || !model || !year || !licensesplate || !fuelType || !engineType || !vins}
                                    >
                                        Add
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>

                        {/* Edit cars */}

                        <Dialog fullScreen={fullScreen} open={openEdit} onClose={handleCloseEdit} aria-labelledby="responsive-dialog-title">
                            <DialogTitle id="responsive-dialog-title">Edit Vehicle</DialogTitle>
                            <Form onSubmit={onSubmitCarEdit}>
                                <DialogContent>
                                    {/* <DialogContentText> */}

                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Box width={200}>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                value={carMake}
                                                onChange={(event, newValue) => {
                                                    setcarMake(newValue);
                                                    filterCar(newValue);
                                                }}
                                                options={car.map((option) => option.name)}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                renderInput={(params) => <TextField {...params} label="Car Make" />}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                value={model}
                                                onChange={(event, newValue) => {
                                                    setmodel(newValue);
                                                }}
                                                options={carModel.map((option) => option.name)}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                renderInput={(params) => <TextField {...params} label="Car Model" />}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="year"
                                                variant="outlined"
                                                required
                                                id="year"
                                                label="Car Year"
                                                value={year}
                                                onChange={(e) => setyear(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="licensesplate"
                                                variant="outlined"
                                                required
                                                id="licensesplate"
                                                label="License Plate / Tags"
                                                value={licensesplate}
                                                onChange={(e) => setlicensesplate(e.target.value)}
                                            />
                                        </Box>
                                        <br />
                                        <br />
                                        <Box width={200}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Fuel Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={fuelType}
                                                    label="Status"
                                                    onChange={handleFuelType}
                                                >
                                                    <MenuItem value="Body">Select an option</MenuItem>
                                                    <MenuItem value="Petrol">Petrol</MenuItem>
                                                    <MenuItem value="Diesel">Diesel</MenuItem>
                                                    <MenuItem value="Gas">Gas</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="engineType"
                                                variant="outlined"
                                                required
                                                id="engineType"
                                                label="Engine Type"
                                                value={engineType}
                                                onChange={(e) => setengineType(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="vins"
                                                variant="outlined"
                                                required
                                                id="vins"
                                                label="VIN"
                                                value={vins}
                                                onChange={(e) => setvins(e.target.value)}
                                            />
                                        </Box>
                                    </Form.Group>

                                    {/* </DialogContentText> */}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="sm"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleCloseEdit}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        type="submit"
                                        size="sm"
                                        className={classes.item}
                                        disabled={!carMake || !model || !year || !licensesplate || !fuelType || !engineType || !vins}
                                    >
                                        Update
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>

                        <Dialog fullScreen={fullScreen} open={opens} onClose={handleCloseitems} aria-labelledby="responsive-dialog-title">
                            <DialogTitle id="responsive-dialog-title">Add Part</DialogTitle>
                            <Form onSubmit={onSubmitItems}>
                                <DialogContent>
                                    {/* <DialogContentText> */}

                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Box width={200}>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                value={partid}
                                                onChange={(event, newValue) => {
                                                    setpartid(newValue);
                                                    filterPart(newValue);
                                                }}
                                                options={part.map((option) => option.sku)}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                renderInput={(params) => <TextField {...params} label="Sku" />}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="qty"
                                                variant="outlined"
                                                required
                                                id="qty"
                                                label="Qty"
                                                value={qty}
                                                onChange={(e) => setQty(e.target.value)}
                                            />
                                        </Box>
                                    </Form.Group>

                                    {/* </DialogContentText> */}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="sm"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleCloseitems}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        type="submit"
                                        size="sm"
                                        className={classes.item}
                                        disabled={!partid || !qty}
                                    >
                                        Add
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>

                        <Dialog
                            fullScreen={fullScreen}
                            open={openPartEdit}
                            onClose={handleClickPartOpenEdit}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">Edit Part</DialogTitle>
                            <Form onSubmit={onSubmitPartItems}>
                                <DialogContent>
                                    {/* <DialogContentText> */}

                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Box width={200}>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                value={partid}
                                                onChange={(event, newValue) => {
                                                    setpartid(newValue);
                                                    filterPart(newValue);
                                                }}
                                                options={part.map((option) => option.sku)}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                renderInput={(params) => <TextField {...params} label="Sku" />}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="qty"
                                                variant="outlined"
                                                required
                                                id="qty"
                                                label="Qty"
                                                value={qty}
                                                onChange={(e) => setQty(e.target.value)}
                                            />
                                        </Box>
                                    </Form.Group>

                                    {/* </DialogContentText> */}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="sm"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleCloseitems}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        type="submit"
                                        size="sm"
                                        className={classes.item}
                                        disabled={!partid || !qty}
                                    >
                                        Update
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>
                    </MainCard>
                </>
            )}
        </>
    );
};
export default ViewMyFleetOrderParts;
