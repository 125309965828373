/* eslint-disable no-underscore-dangle */
/* eslint-disable no-empty */
import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import { makeStyles } from '@mui/styles';
// material-ui
import { Grid } from '@mui/material';
import ApiUrl from 'network';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
// import { gridSpacing } from 'store/constant';
import Button from '@mui/material/Button';
import TotalSalesDepositsCard from './collectionsdata/totalsalesdeposits';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    colors: {
        color: `#ED7C1B`
    }
}));

const SalesDepositReport = () => {
    // const history = useNavigate();
    const classes = useStyles();
    const [status, setStatus] = useState('today');
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [isLoading, setLoading] = useState(false);
    // const userRole = localStorage.getItem('userRole');
    // const userCurrency = localStorage.getItem('userCurrency');
    const [dateRangeTitle, setdateRangeTitle] = useState('');
    const [deposited, setdeposited] = useState([]);
    const [depositeddaterange, setdepositeddaterange] = useState([]);

    const retrieveAllTodaysMatrix = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.DepositSalesReportTodayUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    // console.log('result', data.totaltodaysorders);
                    setdeposited(data?.deposited ?? []);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const setChange = (event, picker) => {
        console.log('startDate', picker.startDate._d);
        console.log('endDate', picker.endDate._d);
        setstartDate(picker.startDate._d);
        setendDate(picker.endDate._d);
        setStatus('dateselect');
        // AllDateRangeMasterDashboardUrl
        const userInfo = localStorage.getItem('userToken');
        const from = picker.startDate._d;
        const to = picker.endDate._d;
        setLoading(true);
        const data = {
            from,
            to
        };

        fetch(ApiUrl.DepositSalesReportDateRangeUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                setLoading(false);
                // console.log('data', result);
                setdateRangeTitle(result?.dateRangeTitle ?? '');
                setdepositeddaterange(result?.depositeddaterange ?? []);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        // const userInfo = localStorage.getItem('userToken');
        retrieveAllTodaysMatrix();
    }, []);

    return (
        <>
            <br />
            <PageHeader title="Dashboard" subTitle="Home | Report | Deposits" />
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>Note: This Data is based on deposited Date</Grid>

                <Grid item>
                    <DateRangePicker initialSettings={{ startDate, endDate }} onApply={setChange}>
                        <Button variant="contained" size="sm" className={classes.item}>
                            filter by Date
                        </Button>
                    </DateRangePicker>
                </Grid>
            </Grid>
            <br />
            {status === 'today' ? (
                <>
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={12}>
                            <TotalSalesDepositsCard
                                isLoading={isLoading}
                                collectionsdata={deposited}
                                title="Total Deposits For"
                                date="Today"
                                excelName="TotalDeposit"
                                excelName2="TotalDeposit.xlsx"
                            />
                        </Grid>
                    </Grid>
                </>
            ) : (
                ''
            )}

            {status === 'dateselect' ? (
                <>
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={12}>
                            <TotalSalesDepositsCard
                                isLoading={isLoading}
                                collectionsdata={depositeddaterange}
                                title="Total Deposits For "
                                date={dateRangeTitle}
                                excelName="TotalDeposited"
                                excelName2="TotalDeposited.xlsx"
                            />
                        </Grid>
                    </Grid>

                    <br />
                </>
            ) : (
                ''
            )}
        </>
    );
};

export default SalesDepositReport;
