import React, { useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import styled from 'styled-components';
import Autocomplete from '@mui/material/Autocomplete';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useRetailerContext } from 'context/retailer-shop/retailer-context';

const Wrapper = styled.div`
    .main-filter {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        .custom-date-picker {
            background: #193855;
        }
    }

    @media screen and (max-width: 500px) {
        .main-filter {
            flex-direction: column;
            justify-content: center;
        }
    }

    margin-bottom: 30px;

    .refresh-btn {
        background-color: #ff740f;
        margin-left: 0.625rem;
    }
`;

const PageFilter = () => {
    const {
        getAllRetailers,
        retailers,
        dispatch,
        currentRetailerId,
        currentRetailerName,
        handleRetailerFilter,
        isLoading,
        dateRangeTitle
    } = useRetailerContext();

    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const [retailerId, setRetailerId] = React.useState('');
    const [retailer, setRetailer] = React.useState('Anthony Abanga');

    const [isFitlerApplied, setIsFitlerApplied] = React.useState(false);
    console.log(retailerId);

    const handleEvent = (e, picker) => {
        setStartDate(picker.startDate._d.toISOString());
        setEndDate(picker.endDate._d.toISOString());
    };

    const filterOptions = { id: currentRetailerId, startDate, endDate };

    const filterRetailerData = async () => {
        handleRetailerFilter(filterOptions);
        setIsFitlerApplied(true);
    };

    useEffect(() => {
        getAllRetailers();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCurrentRetailerId = (e, value) => {
        setRetailer(value);
        // eslint-disable-next-line array-callback-return
        retailers.map((retailer) => {
            if (retailer?.partnerName === value) {
                setRetailerId(retailer?._id);
                dispatch({
                    type: 'SET_CURRENT_RETAILER',
                    payload: { currentRetailerId: retailer?._id, currentRetailerName: retailer?.partnerName }
                });
            }
        });
    };

    return (
        <Wrapper>
            <div className="main-filter">
                <div className="date-filter">
                    <DateRangePicker
                        initialSettings={{ startDate, endDate }}
                        onEvent={handleEvent}
                        onApply={filterRetailerData}
                        onCancel={() => {
                            setIsFitlerApplied(false);
                            window.location.href = '/retailers/shops';
                        }}
                    >
                        <LoadingButton
                            variant="contained"
                            disableElevation
                            size="medium"
                            className="custom-date-picker"
                            loadingIndicator="Loading…"
                            loading={isLoading}
                        >
                            Filter By Date
                        </LoadingButton>
                    </DateRangePicker>
                    &nbsp;&nbsp;
                    <Button
                        variant="contained"
                        startIcon={<RefreshIcon />}
                        className="refresh-btn"
                        disabled={isLoading}
                        onClick={() => {
                            setIsFitlerApplied(false);
                            window.location.href = '/retailers/shops';
                        }}
                    >
                        {isLoading ? 'Synchronizing...' : 'Refresh'}
                    </Button>
                </div>
                <div className="retailer-filter">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={retailer}
                        style={{ width: 300 }}
                        onChange={handleCurrentRetailerId}
                        options={retailers.map((option) => option?.partnerName)}
                        isOptionEqualToValue={(option, value) => option?._id === value?._id}
                        renderInput={(params) => <TextField {...params} label="Retailers" variant="outlined" />}
                    />
                </div>
            </div>
            <div style={{ padding: '1rem' }}>
                <h1
                    style={{
                        textAlign: 'center',
                        fontSize: '2.2rem'
                    }}
                >
                    <em>
                        <strong> {`${currentRetailerName}'s`}</strong>
                    </em>{' '}
                    shop
                </h1>
                <p style={{ textAlign: 'center', fontSize: '1.2rem' }}>
                    <strong>{isFitlerApplied ? <em>Displaying sales data for: {dateRangeTitle}</em> : ''}</strong>
                </p>
            </div>
        </Wrapper>
    );
};
export default PageFilter;
