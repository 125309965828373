// import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import insurer from './insurer';
// import fleet from './fleet';
// import reporting from './reporting';
// import nigeria from './nigeria';
// import distributor from './distributor';fleet

// ==============================|| MENU ITEMS dashboard ||============================== //

const menuItems = {
    items: [utilities, insurer, other]
};

export default menuItems;
