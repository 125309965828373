import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, Divider, List, ListItem } from '@mui/material';
import { Table, Paper, Space, Select, Spin, DatePicker, Modal, Input, Dropdown, Progress, Button } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import WestIcon from '@mui/icons-material/West';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { BarChart, Bar, XAxis, Cell } from 'recharts';
import { LoadingOutlined, DownloadOutlined, FileAddOutlined } from '@ant-design/icons';
import { decrypt } from '../../utils/encrypt';
import ApiUrl from '../../network';
import { dateConverter, twoDecimalPlaces } from 'utils/functions';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { EmptyState } from 'components/EmptyState/EmptyState';

const RetailerAnalytics = () => {
    const navigate = useNavigate();
    const { RangePicker } = DatePicker;

    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState([]);
    const [orders, setOrders] = useState([]);
    const [mainRetailer, setMainRetailer] = useState([]);
    const [analytics, setAnalytics] = useState([]);
    const [loyaltyProgress, setLoyaltyProgressDataResult] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [sales, setSales] = useState(0);
    const [selectedDate, setSelectedDate] = useState('');
    const [containerWidth, setContainerWidth] = useState('false');
    const [accumulatedFilters, setAccumulatedFilters] = useState({});
    const [profileId, setProfileId] = useState('');
    const [filteredProfileId, setFilteredProfileId] = useState('');

    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const country = localStorage.getItem('country');

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54
            }}
            spin
        />
    );

    const handleClick = useCallback(
        (entry, index) => {
            setSales(entry?.payload?.totalSales);
            setMonth(entry?.payload?.month);
            setYear(entry?.payload?.year);
            setActiveIndex(index);
        },
        [setActiveIndex]
    );

    useEffect(() => {
        const handleResize = () => {
            // Adjust the column width based on the window width
            const newWidth = window.innerWidth < 1200 ? 'true' : 'false';
            setContainerWidth(newWidth);
        };

        // Initial setup
        handleResize();

        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // get query data
    const getQueryData = async () => {
        try {
            setLoading(true);
            const url = `${ApiUrl.BaseUrl}/mainorders/querydata?country=${country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);

                        setMainRetailer(answer.customer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retailerOptions = mainRetailer?.map((item) => {
        return { label: item?.partnerName, value: item?._id };
    });

    // get retailer profile
    const getRetailerProfile = async (data) => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            const url = `${ApiUrl.BaseUrl}/retailerprofiles/retailerprofile`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const retailerId = data || params?.id;

            // Construct the final URL by appending the parameters
            let finalUrl = `${url}?retailerId=${retailerId}`;

            // Append other parameters if available
            if (accumulatedFilters) {
                Object.entries(accumulatedFilters).forEach(([key, value]) => {
                    finalUrl += `&${key}=${value}`;
                });
            }

            fetch(finalUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const info = decrypt(data.encrypted, keyResult, ivResult);
                        const infoResult = JSON.parse(info);
                        const order = decrypt(data.encrypteds, keyResult, ivResult);
                        const orderResult = JSON.parse(order);
                        const analytics = decrypt(data.analyticsData, keyResult, ivResult);
                        const analyticResult = JSON.parse(analytics);
                        const currentsLoyalty = decrypt(data.currentsLoyalty, keyResult, ivResult);
                        const currentsLoyaltyResult = JSON.parse(currentsLoyalty);
                        const previousLoyalty = decrypt(data.previousLoyalty, keyResult, ivResult);
                        const previousLoyaltyResult = JSON.parse(previousLoyalty);
                        const loyaltyProgressData = decrypt(data.loyaltyProgressData, keyResult, ivResult);
                        const loyaltyProgressDataResult = JSON.parse(loyaltyProgressData);
                        setProfile(infoResult);
                        setOrders(orderResult);
                        setAnalytics(analyticResult);
                        setLoyaltyProgressDataResult(loyaltyProgressDataResult);
                        const initialSales = analyticResult?.monthlySales?.[0];
                        //  console.log('data', loyaltyProgressDataResult);
                        // console.error(previousLoyaltyResult);
                        // console.error(currentsLoyaltyResult);
                        // console.error(analyticResult);
                        // console.error(initialSales);
                        if (initialSales) {
                            setSales(initialSales.totalSales);
                            setMonth(initialSales.month);
                            setYear(initialSales.year);
                            setActiveIndex(0);
                        }
                        setProfileId(infoResult._id);
                        // console.error(loyaltyProgressDataResult);
                    } else {
                        // const error = decrypt(data.data, keyResult, ivResult);
                        // const answer = JSON.parse(error);
                        console.error(data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getQueryData();
    }, []);

    useEffect(() => {
        const storedRetailerId = localStorage.getItem('selectedRetailerId');
        if (filteredProfileId) {
            getRetailerProfile(filteredProfileId); // Fetch retailer profile from filtered value
        } else if (storedRetailerId) {
            getRetailerProfile(storedRetailerId); // Fetch retailer profile using the id from localStorage
        } else {
            // If no value is found in localStorage, set default value
            const defaultRetailerId = params?.id; // Use the params id option as default
            getRetailerProfile(defaultRetailerId); // Fetch retailer profile using the  params id
        }
    }, [params?.id, accumulatedFilters]);

    // filter retailer
    const filterRetailer = (value) => {
        setFilteredProfileId(value);
        getRetailerProfile(value);
    };

    // filter date
    const filterDate = (value, dateString) => {
        setSelectedDate(value);
        const data = {
            startDate: dateString[0],
            endDate: dateString[1]
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    return (
        <div>
            {loading ? (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '4000'
                    }}
                >
                    {antIcon}
                </Box>
            ) : null}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '1px dashed #E7EAEB',
                    alignItems: 'flex-end',
                    margin: '0px auto 15px',
                    flexWrap: 'wrap'
                }}
            >
                {' '}
                <List
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '0 0 16px 0',
                        alignItems: 'flex-start'
                        // margin: '0px auto 15px'
                    }}
                >
                    <WestIcon
                        sx={{
                            fontSize: '32px',
                            cursor: 'pointer',
                            '&:hover': {
                                color: '#546974'
                            },
                            '&:focus': {
                                color: '#9DA9AF'
                            }
                        }}
                        onClick={() => navigate(-1)}
                    />{' '}
                    <ListItem sx={{ fontWeight: 500, fontSize: '1.875rem', color: '#0A2938', padding: 0, marginLeft: '24px' }}>
                        Analytics{' '}
                    </ListItem>
                </List>
                <Box
                    sx={{
                        // margin: '20px 0',
                        display: 'flex',
                        justifyContent: 'space-between',

                        alignItems: 'center',
                        flexWrap: 'wrap',
                        padding: '0 0 16px 0'
                    }}
                >
                    <Box
                        sx={{
                            width: ' 277px',
                            margin: '24px 5px 0 5px',
                            flexGrow: '1 '
                            // flexBasis: '200px'
                        }}
                    >
                        <Select
                            style={{
                                width: '100%'
                            }}
                            size="large"
                            placeholder="Search retailer"
                            options={retailerOptions}
                            onChange={(value) => filterRetailer(value)}
                            value={profileId || 'Search retailer'}
                            filterOption={(inputValue, option) => {
                                if (typeof option.label === 'string') {
                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }

                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }}
                            showSearch
                        />
                    </Box>
                    <Box
                        sx={{
                            width: ' 277px',
                            margin: '24px 5px 0 5px',
                            flexGrow: '1 '
                            // flexBasis: '200px'
                        }}
                    >
                        <RangePicker
                            format="YYYY-MM-DD"
                            style={{
                                width: '100%',
                                border: '1px solid #E7EAEB'
                            }}
                            size="large"
                            value={selectedDate}
                            onChange={filterDate}
                        />
                    </Box>

                    <Button
                        type="primary"
                        size="large"
                        style={{
                            backgroundColor: '#FF740F',
                            borderRadius: '12px',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#fff',
                            width: '161px',
                            margin: '24px 5px 0 5px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '38px',
                            flexGrow: '1 '
                        }}
                        className="hover-secondary-button-orange"
                        onClick={() => {
                            return accumulatedFilters.startDate
                                ? navigate(
                                      `/retailers/report/${profile?._id}?startDate=${encodeURIComponent(
                                          accumulatedFilters.startDate
                                      )}&endDate=${encodeURIComponent(accumulatedFilters.endDate)}`
                                  )
                                : navigate(`/retailers/report/${profile?._id}`);
                        }}
                    >
                        Generate report
                    </Button>
                </Box>
            </Box>
            <Box
                sx={{
                    margin: '24px 0px 0 0px',
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: containerWidth === 'true' ? 'wrap' : 'nowrap'
                }}
            >
                <Box
                    style={{
                        backgroundColor: '#FFF1E7',
                        borderRadius: '16px',
                        flexGrow: '1 ',
                        border: '1px solid #FFF1E7',
                        margin: '5px',
                        display: 'flex',
                        height: '112px',
                        padding: '18px 20px',
                        flexDirection: 'column'
                        // flexBasis: '200px'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '20px',
                                color: '#0A2938',
                                fontWeight: '600'
                            }}
                        >
                            {!Number(analytics?.totalOrders) ? 0 : analytics?.totalOrders}
                        </Typography>
                        <Box
                            sx={{
                                margin: '2px',
                                height: '40px',
                                padding: '10px 12px',
                                borderRadius: ' 8px',
                                color: ' #ff740f',
                                fontWeight: '500',
                                textAlign: 'center',
                                cursor: 'pointer',
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                            onClick={() => {
                                navigate(`/retailers/order-history/${profile?._id}`);
                            }}
                        >
                            <Typography sx={{ fontSize: '16px', fontWeight: '400', margin: '0 6px 0 0' }}>View total orders </Typography>

                            <KeyboardDoubleArrowRightIcon sx={{ fontSize: '20px', fontWeight: '900' }} />
                        </Box>
                    </Box>
                </Box>
                <Box
                    style={{
                        backgroundColor: '#E8EBEE',
                        borderRadius: '16px',
                        flexGrow: '1 ',
                        border: '1px solid #E8EBEE',
                        margin: '5px',
                        display: 'flex',
                        height: '112px',
                        padding: '18px 20px',
                        flexDirection: 'column'
                        // flexBasis: '200px'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '20px',
                                color: '#0A2938',
                                fontWeight: '600'
                            }}
                        >
                            {!Number(analytics?.totalOrders) ? 0.0 : twoDecimalPlaces(analytics?.totalSales)}
                        </Typography>

                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: '400',
                                color: ' #3B5460',
                                margin: '2px',
                                height: '40px',
                                padding: '10px 12px'
                            }}
                        >
                            Total sales
                        </Typography>
                    </Box>
                </Box>
                <Box
                    style={{
                        backgroundColor: '#CCEEDD',
                        borderRadius: '16px',
                        flexGrow: '1 ',
                        border: '1px solid #CCEEDD',
                        margin: '5px',
                        display: 'flex',
                        height: '112px',
                        padding: '18px 20px',
                        flexDirection: 'column'
                        // flexBasis: '200px'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '20px',
                                color: '#0A2938',
                                fontWeight: '600'
                            }}
                        >
                            {!Number(analytics?.totalOrders) ? 0.0 : twoDecimalPlaces(analytics?.averageValue)}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: '400',
                                color: ' #3B5460',
                                margin: '2px',
                                height: '40px',
                                padding: '10px 12px'
                            }}
                        >
                            Average order value
                        </Typography>
                    </Box>
                </Box>
                {/* <Box
                    style={{
                        backgroundColor: '#DBE2FF',
                        borderRadius: '16px',
                        flexGrow: '1 ',
                        border: '1px solid #DBE2FF',
                        margin: '5px',
                        display: 'flex',
                        height: '112px',
                        padding: '18px 20px',
                        flexDirection: 'column'
                        // flexBasis: '200px'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '20px',
                                color: '#0A2938',
                                fontWeight: '600'
                            }}
                        >
                            {!Number(analytics?.totalOrders) ? 0.0 : twoDecimalPlaces(analytics?.totalRevenue)}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: '400',
                                color: ' #3B5460',
                                margin: '2px',
                                height: '40px',
                                padding: '10px 12px'
                            }}
                        >
                            Total revenue
                        </Typography>
                    </Box>
                </Box> */}
            </Box>
            <Box
                sx={{
                    margin: '24px 0px 0 0px',
                    // display: 'flex',
                    // alignItems: 'center',
                    // flexWrap: 'wrap',
                    display: 'grid',
                    gridTemplateColumns: ['1fr', 'repeat(2, 1fr)']
                }}
            >
                <Box
                    sx={{
                        // flexGrow: '1',
                        margin: '5px'
                    }}
                >
                    <Typography
                        sx={{
                            color: '#546974',
                            fontSize: '20px',
                            fontWeight: '400',
                            margin: '0 0 10px 0'
                        }}
                    >
                        Top 5 part ordered
                    </Typography>
                    <Box
                        sx={{
                            height: '490px',
                            // width: '591px',
                            backgroundColor: '#fff',
                            borderRadius: '16px',
                            padding: '12px 0',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            position: 'relative'
                        }}
                    >
                        {analytics?.top5Parts?.length > 0 ? (
                            <table id="retailer-table" style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th />
                                        <th>Part Name</th>
                                        {/* <th>Total Quantity</th> */}
                                        <th>Order Value (GHS)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {analytics?.top5Parts?.map((item) => (
                                        <tr key={item?._id}>
                                            <td>
                                                {' '}
                                                <Box
                                                    style={{
                                                        backgroundColor: '#F6F6F6',
                                                        borderRadius: '4px',
                                                        margin: '5px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        height: '60px',
                                                        width: '80px'
                                                    }}
                                                >
                                                    <img src={item?.image} alt="" className="item-image" />
                                                </Box>
                                            </td>
                                            <td>{item?.sku}</td>
                                            {/* <td>{parseFloat(item?.totalQty?.toFixed(2)).toLocaleString()}</td> */}
                                            <td>{twoDecimalPlaces(item?.totalGMV)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <Box
                                style={{
                                    height: '456px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                                className="empty-state-container"
                            >
                                <EmptyState height="204px" padding="5px" text="No results yet" />
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box
                    sx={{
                        // flexGrow: '1',
                        margin: '5px'
                        // flexBasis: '510px'
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '20px',
                            fontWeight: '400',
                            margin: '0 0 10px 0',
                            color: 'transparent'
                        }}
                    >
                        Top 5 part ordered
                    </Typography>

                    <Box
                        sx={{
                            // height: '350px',
                            // width: '591px',
                            backgroundColor: '#fff',
                            borderRadius: '16px',
                            padding: '50px 12px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            position: 'relative'
                        }}
                    >
                        {analytics?.monthlySales?.length > 0 ? (
                            <Box
                                sx={{
                                    borderRadius: '16px',
                                    padding: '12px ',
                                    display: 'flex',
                                    margin: '70px 0 0 0',
                                    flexDirection: 'column',
                                    flexGrow: '1'
                                }}
                            >
                                <Typography
                                    sx={{
                                        border: '0.1px solid #FFF1E7',
                                        height: '40px',
                                        width: '133px',
                                        padding: '10px 12px',
                                        borderRadius: '8px',
                                        color: '#FF740F',
                                        fontWight: '500',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        margin: '10px 0'
                                    }}
                                >
                                    {month} {year}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '36px',
                                        color: '#3B5460'
                                    }}
                                >
                                    {twoDecimalPlaces(sales)}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        color: '#85949C'
                                    }}
                                >
                                    Total month sales
                                </Typography>
                            </Box>
                        ) : (
                            <Box
                                style={{
                                    margin: '10px auto 0',
                                    position: 'relative',
                                    left: '50%',
                                    transform: 'translate(-50%)'
                                }}
                                className="empty-state-container"
                            >
                                <EmptyState height="204px" padding="5px" text="No results yet" />
                            </Box>
                        )}

                        <Box
                            sx={{
                                flexGrow: '1'
                            }}
                        >
                            <Box
                                sx={{
                                    height: '100%'
                                }}
                            >
                                <BarChart
                                    width={300}
                                    height={250}
                                    data={analytics?.monthlySales}
                                    margin={{
                                        top: 10,
                                        right: 0,
                                        left: 0,
                                        bottom: -10
                                    }}
                                >
                                    <XAxis dataKey="month" />
                                    <Bar dataKey="totalSales" fill="#FFBE37" onClick={handleClick}>
                                        <>
                                            {analytics?.monthlySales?.map((entry, index) => (
                                                <Cell
                                                    cursor="pointer"
                                                    fill={index === activeIndex ? `#FFBE37` : `#FFF2D7`}
                                                    key={`cell-${index}`}
                                                />
                                            ))}
                                        </>
                                    </Bar>
                                </BarChart>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            height: '118px',
                            // width: '591px',
                            backgroundColor: '#fff',
                            borderRadius: '16px',
                            padding: '12px ',
                            margin: '20px 0 0 0'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                // justifyContent: 'space-between',
                                // alignItems: 'flex-start',
                                flexDirection: 'column',
                                // margin: '0px auto 15px',
                                height: '100%'
                            }}
                        >
                            {' '}
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <Box
                                    sx={{
                                        backgroundColor: '#DBE2FF',
                                        margin: '0 8px 0 0',
                                        width: '32px',
                                        height: '32px',
                                        borderRadius: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <CalendarMonthRoundedIcon sx={{ fontSize: '16px', color: '#4B6EFF' }} />
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: '18px',
                                            fontWeight: '500',
                                            color: '#3B5460'
                                        }}
                                    >
                                        {dateConverter(profile?.signupDate)}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: '#546974'
                                        }}
                                    >
                                        Sign up date
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    margin: '24px 0px 0 0px',
                    // display: 'flex',
                    alignItems: 'center',
                    // flexWrap: 'wrap',
                    display: 'grid',
                    gridTemplateColumns: ['1fr', 'repeat(2, 1fr)']
                }}
            >
                <Box
                    sx={{
                        flexGrow: '1',
                        margin: '5px',
                        // height: '166px',
                        backgroundColor: '#FFFFFF',
                        borderRadius: '16px'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            margin: '20px 24px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '20px',
                                color: '#546974'
                            }}
                        >
                            Loyalty program progress
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '20px',
                                color: '#546974'
                            }}
                        >
                            {loyaltyProgress?.currentLoyalty}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            margin: '20px 24px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '16px',
                                color: '#546974',
                                display: 'flex'
                            }}
                        >
                            {loyaltyProgress?.currentTier}
                        </Typography>

                        <Progress percent={loyaltyProgress?.percentage} />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            margin: '20px 24px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '16px',
                                color: '#3B5460'
                            }}
                        >
                            {loyaltyProgress?.noOfMoreOrders} Left
                        </Typography>

                        <Typography
                            sx={{
                                fontSize: '16px',
                                color: '#3B5460'
                            }}
                        >
                            {twoDecimalPlaces(loyaltyProgress?.amountToSpendMore)} away from {loyaltyProgress?.nextTier}
                        </Typography>
                    </Box>
                    {/* <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            margin: '20px 24px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '16px',
                                color: '#546974'
                            }}
                        >
                            Fulfilled Total Sales{' '}
                            {!Number(loyaltyProgress?.totalSales) ? null : twoDecimalPlaces(loyaltyProgress?.totalSales)}
                        </Typography>
                    </Box> */}
                </Box>
                <Box
                    sx={{
                        flexGrow: '1',
                        margin: '5px'
                        // flexBasis: '400px'
                    }}
                >
                    <Box
                        sx={{
                            height: '176px',
                            width: '100%',
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                            // width: '591px',
                            backgroundColor: '#fff',
                            borderRadius: '16px',
                            padding: '12px ',
                            position: 'relative'
                        }}
                        className="collection-modals-y"
                    >
                        {analytics?.mostRecentOrder?.items?.length > 0 ? (
                            <table id="retailer-table" style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        {/* <th /> */}
                                        <th>Most recent order</th>
                                        <th>Total Quantity</th>
                                        <th>Order Value (GHS)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {analytics?.mostRecentOrder?.items?.map((item) => (
                                        <tr key={item?._id}>
                                            <td>{item?.partid}</td>
                                            <td>{item?.qty}</td>
                                            <td>{twoDecimalPlaces(item?.qty * item.garagePrice)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <EmptyState height="170px" padding="5px" text="No results yet" />
                        )}
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

export default RetailerAnalytics;
