import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Row, Col } from 'react-grid-system';
import { Button, Divider, TextField } from '@mui/material';
import AccordionWrapper from 'assets/Wrappers/AccordionWrapper';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import CardItem from 'components/Card/CardItem';
import SalesTableData from './SalesTableData';
import { useAppContext } from 'context/appContext';
import TodoList from 'components/TodoList';
import SalesModal from './SalesModal';
import LoadingButton from '@mui/lab/LoadingButton';
import MoonLoader from 'react-spinners/MoonLoader';
import SimpleCard from 'components/Card/SimpleCard';

const SalesAccordion = () => {
    const [input, setInput] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);

    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());

    const {
        salesTarget,
        makeSalestodolist,
        currentTotalSales,
        previousMonthTotalSales,
        currentPreviousMonthSalesRate,
        currentNoOrders,
        previousMonthNoOrders,
        currentPreviousMonthNoOrdersRate,
        currentactualratio,
        lastmonthactualratio,
        currentPreviousMonthActualRatioRate,
        currentWhalesCaughtCount,
        lastMonthWhalesCaughtCount,
        currentPreviousMonthWhalesRate,
        getMakeSalesData,
        retailersduefollowups,
        filterSales,
        isLoading,
        resetSales
    } = useAppContext();

    const handleEvent = (e, picker) => {
        setStartDate(picker.startDate._d.toISOString());
        setEndDate(picker.endDate._d.toISOString());
    };

    const handleOpen = () => setOpen(true);
    console.log(handleOpen);
    const handleClose = () => setOpen(false);

    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };

    const filterOptions = { startDate, endDate };
    const handleSalesFilter = async () => {
        filterSales(filterOptions);
    };

    const handleFilterReset = () => {
        resetSales();
    };

    React.useEffect(() => {
        getMakeSalesData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filteredSales = retailersduefollowups.filter((item) => {
        return item.partnerName.toLowerCase().includes(input.toLowerCase());
    });

    const salesCount = filteredSales.length;
    const isPlural = salesCount > 1 ? 'results' : 'result';
    return (
        <AccordionWrapper>
            <Accordion className="custom-accordion">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expand-icon" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <h3 className="title">Make Sales</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <Row>
                        <Col xl={7} lg={7} md={12} sm={12} xs={12}>
                            {/* Click modal to open */}
                            {/* <Button onClick={handleOpen} variant="contained" size="medium" className="btn-todo">
                                View daily goal tip
                            </Button> */}
                            {/* Modal open */}
                            <SalesModal handleOpen={open} handleClose={handleClose} />
                            <div className="custom-table">
                                <h4 className="table__heading">Retailers: Due for follow up</h4>
                                <Divider />
                                <div className="table__content">
                                    <Row>
                                        <Col xl={7} lg={7} md={12} sm={12} xs={12}>
                                            <TextField
                                                id="outlined-basic"
                                                label="Search lead name"
                                                variant="outlined"
                                                className="search-lead"
                                                onChange={(e) => setInput(e.target.value)}
                                            />
                                        </Col>
                                        <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                                            <DateRangePicker
                                                initialSettings={{ startDate, endDate }}
                                                onEvent={handleEvent}
                                                onApply={handleSalesFilter}
                                                onCancel={handleFilterReset}
                                            >
                                                <LoadingButton
                                                    variant="contained"
                                                    disableElevation
                                                    size="medium"
                                                    className="custom-date-picker"
                                                    loadingIndicator="Loading…"
                                                    loading={isLoading}
                                                >
                                                    Filter By Date
                                                </LoadingButton>
                                            </DateRangePicker>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="lead-table">
                                    {input && (
                                        <h4>{salesCount > 0 ? `Displaying ${filteredSales.length} ${isPlural}` : 'No Results found'}</h4>
                                    )}
                                    {isLoading ? (
                                        <div className="loader-spinner">
                                            <MoonLoader color="#ff740f" />
                                        </div>
                                    ) : (
                                        <SalesTableData
                                            rows={filteredSales}
                                            th1="#"
                                            th2="Retailer Name"
                                            th3="Follow up due date"
                                            th4="Pending follow ups"
                                            th5="Actions"
                                        />
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                            <Row>
                                {/* Todo btn */}
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Button className="btn-todo" variant="contained" size="medium" onClick={handleDropdownToggle}>
                                        Todo list &nbsp; &nbsp;
                                        <span className="btn-count">{makeSalestodolist.length}</span>
                                    </Button>
                                    {isOpen && <TodoList todos={makeSalestodolist} />}
                                </Col>
                                <Col xl={9} lg={9} md={9} sm={9} xs={9}>
                                    <SimpleCard count={salesTarget} title="Sales target" isMoney="yes" />
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <CardItem
                                        cardTitle="Total Sales"
                                        thisMonth={currentTotalSales}
                                        isMoney="yes"
                                        prevMonth={previousMonthTotalSales}
                                        perChange={currentPreviousMonthSalesRate}
                                    />
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <CardItem
                                        cardTitle="Total number of orders"
                                        thisMonth={currentNoOrders}
                                        prevMonth={previousMonthNoOrders}
                                        perChange={currentPreviousMonthNoOrdersRate}
                                    />
                                </Col>

                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <CardItem
                                        cardTitle="Whales caught"
                                        thisMonth={currentWhalesCaughtCount}
                                        prevMonth={lastMonthWhalesCaughtCount}
                                        perChange={currentPreviousMonthWhalesRate}
                                    />
                                </Col>

                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <CardItem
                                        cardTitle="Ratio of actual sales / target sales"
                                        thisMonth={`${currentactualratio}%`}
                                        prevMonth={`${lastmonthactualratio}%`}
                                        perChange={currentPreviousMonthActualRatioRate}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </AccordionDetails>
            </Accordion>
        </AccordionWrapper>
    );
};

export default SalesAccordion;
