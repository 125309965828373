/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import PageHeader from '../../components/PageHeader';
import ApiUrl from 'network';
import Box from '@mui/material/Box';
import Controls from '../../components/controls/Controls';
import { Search } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import MainCard from 'ui-component/cards/MainCard';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import TextField from '@mui/material/TextField';

// import MechanicMap from '../mechanicPartners/MechanicMap';
import RetailMap from './RetailMap';
// import Profile from './Profile';

import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Radio from '@mui/material/Radio';
// import { makeStyles } from '@mui/styles';

const { BaseUrl } = ApiUrl;

// const useStyles = makeStyles(() => ({
//     tableHeaderCell: {
//         fontWeight: 'bold'
//     },
//     item: {
//         padding: 0
//     }
// }));

const RetailerPartners = () => {
    // const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [limit, setLimit] = useState(5);
    // const [mapCenter, setMapCenter] = useState({ lat: 7.9465, lng: 1.0232 });
    // const [mapCenter, setMapCenter] = useState({ lat: 5.5557169, lng: -0.196306 });
    const [retailPartners, setRetailPartners] = useState([]);
    const [locations, setLocations] = useState([]);
    const [list, setList] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const [profile, setProfile] = useState(null);
    const country = localStorage.getItem('country');
    const mapCenter = country === 'Ghana' ? { lat: 5.5557169, lng: -0.196306 } : { lat: 6.465422, lng: 3.406448 };
    const margin = 5;

    const setItem = (item) => {
        setProfile(item);
        const arr = locations.map((loc) => {
            // eslint-disable-next-line no-underscore-dangle
            if (loc.id === item._id) {
                loc.color = 'orange-text oscillate';
            } else {
                delete loc.color;
            }
            return loc;
        });
        setLocations(arr);

        // const lat = Number(item.locationLat);
        // const lng = Number(item.locationLng);
        // setMapCenter({ lat, lng });
    };

    const goBack = () => {
        if (limit > margin) {
            const val = limit - margin;
            setLimit(val);
        } else {
            setLimit(margin);
        }
    };

    const goForward = () => {
        if (retailPartners.length > limit) {
            const val = limit + margin;
            setLimit(val);
        } else {
            setLimit(limit);
        }
    };

    const runPartners = (array) => {
        const arr = [];
        let len = 0;
        if (array.length === limit) {
            len = limit;
        } else if (array.length > limit) {
            len = limit;
        } else {
            len = array.length;
        }
        array.forEach((item, index) => {
            if (index < len) {
                arr.push(item);
            }
        });
        setList(arr);
        setRetailPartners(array);
    };

    const filterList = (text) => {
        if (text !== '') {
            const arr = [];
            list.forEach((item) => {
                const name = item.partnerName.toLowerCase();
                if (name.indexOf(text) > -1) {
                    arr.push(item);
                }
            });
            setList(arr);
        } else {
            runPartners(retailPartners);
        }
    };

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };
        const url = `${BaseUrl}/channelpartner/channelspartnerretailactive`;
        async function getActiveRetail() {
            try {
                const result = await (await fetch(url, config)).json();
                if (result.data) {
                    runPartners(result.data);
                    const locs = [];
                    result.data.forEach(({ _id, locationLat, locationLng, partnerName }) => {
                        const lat = isNaN(locationLat) ? 0 : Number(locationLat);
                        const lng = isNaN(locationLng) ? 0 : Number(locationLng);
                        locs.push({ id: _id, lat, lng, name: partnerName });
                    });
                    // console.log(locs);
                    setLocations(locs);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (e) {
                runPartners([]);
                setLocations([]);
                setIsLoading(false);
            }
        }
        getActiveRetail();
    }, []);

    const handleChange = (event, item) => {
        const val = Number(event.target.value);
        setSelectedValue(val);
        setItem(item);
    };

    const tHeadNames = ['', '#', 'Retailer Name', 'WhatsApp', 'Location'];
    // const tHeadNames = ['', 'Retailer Name', 'WhatsApp', 'Cluster', 'Services', 'Rating', 'Location', 'About', 'Note'];
    const tTHead = tHeadNames.map((item, index) => (
        <React.Fragment key={index}>
            <th className="font-15">{item}</th>
            {/* <TableCell className={classes.tableHeaderCell} align="left">
                {item}
            </TableCell> */}
        </React.Fragment>
    ));

    const retailerList = list.map((item, index) => {
        const num = index + 1;
        return (
            <React.Fragment key={index}>
                <tr className={selectedValue === index ? 'dodger font-13' : 'font-13'}>
                    <td>
                        <Radio
                            style={{ padding: 0 }}
                            checked={selectedValue === index}
                            onChange={(e) => handleChange(e, item)}
                            value={index}
                        />
                    </td>
                    <td>{num}</td>
                    <td>{item.partnerName}</td>
                    <td>{item.partnerContact}</td>
                    {/* <td>{item.cluster}</td> */}
                    {/* <td>{item.services}</td>
                    <td>{item.rating}</td> */}
                    <td>
                        {item.locationLat},{item.locationLng}
                    </td>
                    {/* <td className="overflow-hidden">
                        <div style={{ maxWidth: '50px' }} className="trailing">
                            {item.about}
                        </div>
                    </td> */}
                    {/* <td className="overflow-hidden">
                        <div style={{ maxWidth: '50px' }} className="trailing">
                            {item.note}
                        </div>
                    </td> */}
                    {/* <td>
                        <button onClick={() => setItem(item)} type="button" className="orange font-14">
                            Select
                        </button>
                    </td> */}
                </tr>
            </React.Fragment>
        );
    });

    return (
        <>
            <PageHeader title="Retail Partners" subTitle="Home | Retail Partners" />

            <div className="row">
                <div className="col-7 col-lx-12 col-l-12 col-m-12 col-s-12 padding-all-5">
                    <MainCard title={`Retailer Partner (${retailPartners.length}) List`}>
                        <Box display="flex" justifyContent="space-between">
                            <Controls.Input
                                label="Search Retailer Partner"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={(e) => filterList(e.target.value)}
                            />
                            <Box width={10} />
                        </Box>
                        <br />

                        {isLoading ? (
                            <div className="width-100 flex-column justify-content-center align-items-center">
                                <CircularProgress />
                            </div>
                        ) : (
                            <TableContainer component={Paper}>
                                <Table className="garage-table" sx={{ minWidth: 450 }} aria-label="caption table">
                                    <TableHead>
                                        <TableRow>{tTHead}</TableRow>
                                    </TableHead>
                                    <TableBody>{retailerList}</TableBody>
                                </Table>
                            </TableContainer>
                        )}
                        <br />

                        <div className="flex-row-reverse">
                            <div className="width-20 width-lx-25 width-l-30 width-m-40 width-s-60">
                                <div className="row align-items-center">
                                    <div className="col-6 padding-all-5">
                                        <Select
                                            label="Page"
                                            labelId="select-label"
                                            id="select-page"
                                            value={limit}
                                            onChange={(e) => setLimit(e.target.value)}
                                            className="gar-select"
                                        >
                                            <MenuItem value={0}>Page</MenuItem>
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={25}>25</MenuItem>
                                            <MenuItem value={retailPartners.length}>All</MenuItem>
                                        </Select>
                                    </div>
                                    <div className="col-3 padding-all-5">
                                        <button onClick={() => goBack()} type="button" className="none">
                                            <ChevronLeftIcon className="font-20" />
                                        </button>
                                    </div>
                                    <div className="col-3 padding-all-5">
                                        <button onClick={() => goForward()} type="button" className="none">
                                            <ChevronRightIcon className="font-20" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MainCard>
                </div>

                <div className="col-5 col-lx-12 col-l-12 col-m-12 col-s-12 padding-all-5 round">
                    <div className="row">
                        <div className="col-12 col-lx-12 col-l-12 col-m-12 col-s-12">
                            <MainCard title="City Map">
                                <RetailMap mapCenter={mapCenter} locations={locations} country={country} />
                            </MainCard>
                        </div>

                        <div className="col-12 col-lx-12 padding-all-5" />

                        {/* <div className="col-12 col-lx-12 col-l-12 col-m-12 col-s-12">
                            <Profile profile={profile} />
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default RetailerPartners;
