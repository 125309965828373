import styled from 'styled-components';
import { Divider } from '@mui/material';
import { useAppContext } from 'context/appContext';
import { formatCurrency } from 'utils/functions';

const Wrapper = styled.div`
    font-family: 'Roboto';
    border-radius: 50px;
    background: #fff;
    color: #222;
    padding: 1rem 2rem;
    border-radius: 10px;
    font-family: 'Roboto';
    margin-bottom: 30px;

    thead tr:first-child {
        border-bottom: 2px solid #e0e0e0;
    }
    h5 {
        text-transform: uppercase;
        padding: 0.625rem 0;
        font-size: 1.1rem;
        color: #ff740f;
    }

    tr td {
        padding: 1rem;
    }
`;

const Top5Brands = () => {
    const { totalsupplierorders } = useAppContext();
    return (
        <Wrapper className="shadow-sm">
            <Divider>
                <h5>Top 5 Brands Being Sold this week</h5>
            </Divider>
            <div className="table-responsive">
                <table className="table table-hover top-parts">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Part Name</th>
                            <th scope="col">No. of Orders</th>
                            <th scope="col">Wholesale Price</th>
                            <th scope="col">Order Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {totalsupplierorders.map((el, i) => {
                            const { partName, orderValue, quantity, wholeSalePrice } = el;
                            return (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{partName}</td>
                                    <td>{quantity}</td>
                                    <td>{wholeSalePrice}</td>
                                    <td>{formatCurrency(orderValue)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </Wrapper>
    );
};

export default Top5Brands;
