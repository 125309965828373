// import dashboard from './dashboard';
import fleetdriver from './fleetdriver';
import settings from './mychannelpartnerfleetsettings';

// ==============================|| MENU ITEMS dashboard ||============================== //

const myfleetdriverItems = {
    items: [fleetdriver, settings]
};

export default myfleetdriverItems;
