import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: 'AIzaSyDwEijSXmnLgaczZc0qwG-WL3i3mpRdqTc',
    authDomain: 'garagenotification-6f8c0.firebaseapp.com',
    projectId: 'garagenotification-6f8c0',
    storageBucket: 'garagenotification-6f8c0.appspot.com',
    messagingSenderId: '73554559227',
    appId: '1:73554559227:web:143fdebb3d3ffde833b05e',
    measurementId: 'G-7PTJVSZDT6'
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
