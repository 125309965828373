import React, { useState, useEffect } from 'react';
import { Table, Divider, Paper, Space, Button, Select, Spin, DatePicker, Modal } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { encrypt, decrypt } from '../../utils/encrypt';
import { LoadingOutlined } from '@ant-design/icons';
import ApiUrl from '../../network';
import { ToastContainer, toast } from 'react-toastify';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import './logistics.css';
import useOrder from './useOrder';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import Image from '../../assets/images/Frame 1000002229.png';

const userFirstName = localStorage.getItem('userFirstName');

const dateConverter = (value) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    };

    return new Date(value).toLocaleDateString('en-US', options);
};

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 54
        }}
        spin
    />
);

const columns = [
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        width: '22%'
    },
    {
        title: 'Order No',
        dataIndex: 'orderNo',
        key: 'orderNo'
    },

    {
        title: 'Retailer',
        dataIndex: 'customer',
        key: 'customer'
    },
    {
        title: 'Placed by',
        dataIndex: 'placedby',
        key: 'placedby'
    },
    {
        title: 'RMA',
        dataIndex: 'rma',
        key: 'rma'
    },
    {
        title: 'Sales Value',
        dataIndex: 'salesvalue',
        key: 'salesvalue'
    },
    {
        title: 'Image',
        dataIndex: 'image',
        key: 'image'
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status'
    },
    {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        fixed: 'right',
        width: '17%'
    }
];

const columns2 = [
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        width: '250px'
    },
    {
        title: 'Trip Started',
        dataIndex: 'startTripDate',
        key: 'startTripDate',
        width: '250px'
    },
    {
        title: 'Trip Ended',
        dataIndex: 'endTripDate',
        key: 'endTripDate',
        width: '250px'
    },
    {
        title: 'Order No',
        dataIndex: 'orderNo',
        key: 'orderNo',
        width: '90px'
    },

    {
        title: 'Retailer',
        dataIndex: 'customer',
        key: 'customer',
        width: '120px'
    },
    {
        title: 'Placed by',
        dataIndex: 'placedby',
        key: 'placedby',
        width: '120px'
    },
    {
        title: 'RMA',
        dataIndex: 'rma',
        key: 'rma',
        width: '120px'
    },

    {
        title: 'Sales Value',
        dataIndex: 'salesvalue',
        key: 'salesvalue',
        width: '120px'
    },
    {
        title: 'Driver',
        dataIndex: 'driver',
        key: 'driver',
        width: '120px'
    },
    {
        title: 'Image',
        dataIndex: 'image',
        key: 'image',
        width: '100px'
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '100px'
    },
    {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        fixed: 'right',
        width: '220px'
    }
];

const handleAvailableDrivers = (value) => {
    console.log(`selected ${value}`);
};

const Logistics = () => {
    const [selectionType, setSelectionType] = useState('checkbox');
    const [allOrders, setAllOrders] = useState([]);
    const [unAssignedOrders, setUnAssignedOrders] = useState([]);
    const [assignedOrders, setAssignedOrders] = useState([]);
    const [availableDrivers, setAvailableDrivers] = useState([]);
    const [showAssignOrderButton, setShowAssignOrderButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedUnAssignedDate, setSelectedUnAssignedDate] = useState(null);
    const [filter, setFilter] = useState(1);
    const navigate = useNavigate();
    const { RangePicker } = DatePicker;
    const [isProductOpen, setIsModalProductOpen] = useState(false);
    const [selectDriver, setSelectDriver] = useState('');
    const [driverName, setDriverName] = useState('');
    const [getOrderId, setGetOrderId] = useState('');
    const [updateOrderButton, setUpdateOrderButton] = useState(false);
    const getSelectedOrders = useOrder((state) => state.getSelectedOrders);
    const getAssigned = useOrder((state) => state.getAssigned);

    const toggleFilter = (index) => {
        setFilter(index);
    };

    const showProductModal = () => {
        setIsModalProductOpen(true);
    };

    const handleProductOk = () => {
        setIsModalProductOpen(false);
    };

    const handleProductCancel = () => {
        setIsModalProductOpen(false);
    };

    // rowSelection object indicates the need for row selection
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            getSelectedOrders(selectedRowKeys);
            if (selectedRows.length === 0) {
                setShowAssignOrderButton(false);
            } else {
                setShowAssignOrderButton(true);
            }
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name
        })
    };

    // 2 decimal places
    const twoDecimalPlaces = (value) => {
        return parseFloat(value?.toFixed(2)).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    };

    const unAssignedOptions = unAssignedOrders?.map((result) => ({
        key: result?._id,
        date: dateConverter(result?.orderDate),
        orderNo: result?.orderNo,
        customer: result?.customerName,
        placedby: result?.placeBy,
        rma: result?.rma,
        salesvalue: twoDecimalPlaces(result?.salesValue),
        status: result?.orderStatus,
        image: result?.driverOrderImage ? (
            <div className="item-photo">
                <div className="item-photo">
                    <img src={result?.driverOrderImage} alt="" />{' '}
                </div>
            </div>
        ) : (
            <Typography className="action-text">No Image</Typography>
        ),
        action: (
            <Typography
                onClick={() => navigate(`/order-details/${result?._id}`)}
                sx={{
                    cursor: 'pointer'
                }}
                className="action-text"
            >
                View Details <KeyboardArrowRightIcon sx={{ width: '16px' }} />
            </Typography>
        )
    }));

    const assignedOptions = assignedOrders?.map((result) => ({
        key: result?._id,
        date: result?.orderDate ? dateConverter(result?.orderDate) : '',
        startTripDate: result?.startTripDate ? dateConverter(result?.startTripDate) : '',
        endTripDate: result?.endTripDate ? dateConverter(result?.endTripDate) : '',
        orderNo: result?.orderNo,
        customer: result?.customerName,
        placedby: result?.placeBy,
        rma: result?.rma,
        salesvalue: twoDecimalPlaces(result?.salesValue),
        status: result?.orderStatus,
        driver: result?.driverName,
        image: result?.driverOrderImage ? (
            <div className="item-photo">
                <div className="item-photo">
                    <img src={result?.driverOrderImage} alt="" />{' '}
                </div>
            </div>
        ) : (
            <Typography className="item-photo-text">No Image</Typography>
        ),
        action: (
            <div className="edit-logistics-button">
                <Typography
                    onClick={() => {
                        showProductModal();
                        setGetOrderId(result?._id);
                    }}
                    sx={{
                        cursor: 'pointer',
                        margin: '2px'
                    }}
                    className="action-text"
                >
                    Edit <KeyboardArrowRightIcon sx={{ width: '16px' }} />
                </Typography>
                <Typography
                    onClick={() => {
                        navigate(`/order-details/${result?._id}`);
                    }}
                    sx={{
                        cursor: 'pointer',
                        margin: '2px'
                    }}
                    className="action-text"
                >
                    View Details <KeyboardArrowRightIcon sx={{ width: '16px' }} />
                </Typography>
            </div>
        )
    }));

    // get Available Drivers
    const getAvailableDrivers = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/driver/alldrivers`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);

                        const available = answer.filter((item) => {
                            return item.driverAvailability === true;
                        });

                        setAvailableDrivers(available);

                        setLoading(false);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                        toast.error(answer);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        getAvailableDrivers();
    }, []);

    // fields for driver search
    const driverFeilds = availableDrivers.map((result) => ({
        value: result._id,
        label: `${result.firstName} ${result.lastName}`
    }));

    const driverOption = driverFeilds.map((item, index) => {
        return { label: item.label, value: item.value };
    });

    // get all UnAssigned Orders
    const allUnAssignedOrders = async (data) => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/driver/allunassignedorders`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            if (data) {
                if (data.startDate) urlParams.append('startDate', data.startDate);
                if (data.endDate) urlParams.append('endDate', data.endDate);
            }
            url = `${url}?${urlParams.toString()}`;

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setUnAssignedOrders(answer);
                        setLoading(false);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        allUnAssignedOrders();
    }, []);

    // get all Assigned Orders
    const allAssignedOrders = async (data) => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/driver/allassignedorders`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            if (data) {
                if (data.startDate) urlParams.append('startDate', data.startDate);
                if (data.endDate) urlParams.append('endDate', data.endDate);
            }
            url = `${url}?${urlParams.toString()}`;

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setAssignedOrders(answer);
                        setLoading(false);
                        getAssigned(answer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        allAssignedOrders();
    }, []);

    const filterAssignedDate = (value, dateString) => {
        setSelectedDate(value);
        const data = {
            startDate: dateString[0],
            endDate: dateString[1]
        };
        allAssignedOrders(data);
    };

    const filterUnAssignedDate = (value, dateString) => {
        setSelectedUnAssignedDate(value);
        const data = {
            startDate: dateString[0],
            endDate: dateString[1]
        };
        allUnAssignedOrders(data);
    };

    // Assign an order to a driver
    const UpdateOrder = (event) => {
        // event.preventDefault();
        const userInfo = localStorage.getItem('userToken');

        const data = {
            driverId: selectDriver,
            orderId: getOrderId
        };

        // console.log(data);

        // const info = {
        //     driverId: selectDriver,
        //     orders: `Order: ${getOrderId}`
        // };

        // setOrderName(info.orders);

        const obj = JSON.stringify(data);
        const result = encrypt(obj, keyResult, ivResult);
        fetch(`${ApiUrl.BaseUrl}/driver/changedriverorder`, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ payload: result })
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    // showSuccessModal();
                } else if (result.status === false) {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                    // setSuccessAlert(true);
                } else {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                }
            })
            .finally(() => {
                // setLoading(false);
            });
    };

    // select driver
    const handleSelectDriver = (value, name) => {
        setSelectDriver(value);
        setDriverName(name);
        setUpdateOrderButton(true);
    };

    return (
        <>
            <div className="logistic-container">
                <List
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '0 0 16px 0',
                        borderBottom: '1px dashed #E7EAEB',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        margin: '0px auto 15px'
                    }}
                >
                    <ListItem
                        sx={{ fontWeight: 500, fontSize: '1.875rem', color: '#0A2938', padding: 0 }}
                    >{`Hello, ${userFirstName}  👋🏽`}</ListItem>
                    <ListItem sx={{ fontSize: '0.875rem', color: '#565C6B', padding: 0 }}>How are you doing today?</ListItem>
                </List>
                <Box>
                    <Button
                        type="primary"
                        style={{
                            backgroundColor: filter === 1 ? '#FF740F' : '#E7EAEB',
                            borderRadius: '200px',
                            height: '44px',
                            color: filter === 1 ? '#FFFFFF' : '#0A2938',
                            textAlign: 'center',
                            margin: '0.625rem',
                            fontWeight: '400',
                            fontSize: '16px',
                            padding: '10px 24px'
                        }}
                        onClick={() => toggleFilter(1)}
                    >
                        Unassigned Orders
                    </Button>
                    <Button
                        type="primary"
                        style={{
                            backgroundColor: filter === 2 ? '#FF740F' : '#E7EAEB',
                            borderRadius: '200px',
                            height: '44px',
                            color: filter === 2 ? '#FFFFFF' : '#0A2938',
                            textAlign: 'center',
                            margin: '0.625rem',
                            fontWeight: '400',
                            fontSize: '16px',
                            padding: '10px 24px'
                        }}
                        onClick={() => toggleFilter(2)}
                    >
                        Assigned Orders
                    </Button>
                </Box>

                <div className={filter === 1 ? 'active-assigned-content table-container' : 'disable-assigned-content'}>
                    <Table
                        rowSelection={{
                            type: selectionType,
                            ...rowSelection
                        }}
                        loading={loading ? { indicator: <Spin indicator={antIcon} />, spinning: true / false } : ''}
                        columns={columns}
                        dataSource={unAssignedOptions}
                        size="middle"
                        scroll={{
                            x: 'calc(700px + 50%)',
                            y: 580
                        }}
                        title={() => (
                            <>
                                <Space
                                    style={{
                                        // marginBottom: 16,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <Box sx={{ margin: '0.625rem' }}>
                                        <RangePicker
                                            format="YYYY-MM-DD"
                                            size="large"
                                            style={{
                                                width: '300px',
                                                margin: '0.625rem'
                                            }}
                                            value={selectedUnAssignedDate}
                                            onChange={filterUnAssignedDate}
                                        />
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            flexWrap: 'wrap'
                                        }}
                                    >
                                        <Link
                                            style={{
                                                backgroundColor: '#FFFFFF',
                                                padding: '10px',
                                                borderRadius: '12px',
                                                fontSize: '18px',
                                                fontWeight: '500',
                                                color: '#0A2938',
                                                width: '187px',
                                                textAlign: 'center',
                                                margin: '0.625rem',
                                                border: '1px solid #0A2938'
                                            }}
                                            to="/track-all"
                                        >
                                            Track Drivers
                                        </Link>

                                        {showAssignOrderButton ? (
                                            <Link
                                                style={{
                                                    backgroundColor: '#193B56',
                                                    padding: '10px',
                                                    borderRadius: '12px',
                                                    fontSize: '18px',
                                                    fontWeight: '700',
                                                    color: '#FFFFFF',
                                                    width: '187px',
                                                    textAlign: 'center',
                                                    margin: '0.625rem'
                                                }}
                                                to="/assign-order"
                                            >
                                                Assign Order
                                            </Link>
                                        ) : (
                                            <Link
                                                style={{
                                                    backgroundColor: '#E7EAEB',
                                                    padding: '10px',
                                                    borderRadius: '12px',
                                                    fontSize: '18px',
                                                    fontWeight: '700',
                                                    color: '#999',
                                                    width: '187px',
                                                    textAlign: 'center',
                                                    margin: '0.625rem'
                                                }}
                                                to="/assign-order"
                                                disabled
                                            >
                                                Assign Order
                                            </Link>
                                        )}
                                    </Box>
                                </Space>
                            </>
                        )}
                    />
                </div>
                <div className={filter === 2 ? 'active-assigned-content table-container' : 'disable-assigned-content'}>
                    <Table
                        rowSelection={{
                            type: selectionType,
                            ...rowSelection
                        }}
                        loading={loading ? { indicator: <Spin indicator={antIcon} />, spinning: true / false } : ''}
                        columns={columns2}
                        dataSource={assignedOptions}
                        size="middle"
                        scroll={{
                            x: 'calc(700px + 50%)',
                            y: 580
                        }}
                        title={() => (
                            <>
                                <Space
                                    style={{
                                        // marginBottom: 16,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <Box sx={{ margin: '0.625rem' }}>
                                        <RangePicker
                                            format="YYYY-MM-DD"
                                            size="large"
                                            style={{
                                                width: '300px',
                                                margin: '0.625rem'
                                            }}
                                            value={selectedDate}
                                            onChange={filterAssignedDate}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            flexWrap: 'wrap'
                                        }}
                                    >
                                        <Link
                                            style={{
                                                backgroundColor: '#FFFFFF',
                                                padding: '10px',
                                                borderRadius: '12px',
                                                fontSize: '18px',
                                                fontWeight: '500',
                                                color: '#0A2938',
                                                width: '187px',
                                                textAlign: 'center',
                                                margin: '0.625rem',
                                                border: '1px solid #0A2938'
                                            }}
                                            to="/track-all"
                                        >
                                            Track Drivers
                                        </Link>
                                    </Box>
                                </Space>
                            </>
                        )}
                    />
                </div>
                <Modal
                    open={isProductOpen}
                    onOk={handleProductOk}
                    onCancel={handleProductCancel}
                    width={371}
                    zIndex={2050}
                    footer={
                        <div className="product-footer">
                            {updateOrderButton ? (
                                <Button
                                    key="back"
                                    onClick={() => {
                                        UpdateOrder();
                                    }}
                                    style={{
                                        backgroundColor: '#FF740F',
                                        borderRadius: '12px',
                                        height: '56px',
                                        fontSize: '18px',
                                        fontWeight: '700',
                                        color: '#FFFFFF',
                                        width: '100%',
                                        textAlign: 'center'
                                    }}
                                >
                                    Update Order
                                </Button>
                            ) : (
                                <Button
                                    disabled
                                    key="link"
                                    style={{
                                        backgroundColor: '#FFC79F',
                                        borderRadius: '12px',
                                        height: '56px',
                                        fontSize: '18px',
                                        fontWeight: '700',
                                        color: '#FFFFFF',
                                        width: '100%',
                                        textAlign: 'center'
                                    }}
                                >
                                    Update Order
                                </Button>
                            )}
                        </div>
                    }
                >
                    <div className="drivers-available">
                        <h1>Drivers Available</h1>

                        {availableDrivers.length > 0 ? (
                            <section className="driver-list">
                                {availableDrivers?.map((driver) => (
                                    <div
                                        className="drivers"
                                        key={driver._id}
                                        onClick={() => {
                                            handleSelectDriver(driver._id, driver.firstName);
                                            // toggleCheckbox();
                                        }}
                                        role="button"
                                        tabIndex={0}
                                        onKeyDown={() => {}}
                                    >
                                        <span className="driver">
                                            {driver.firstName} {driver.lastName}
                                        </span>
                                    </div>
                                ))}
                            </section>
                        ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <HourglassEmptyIcon sx={{ fontSize: '35px' }} />
                                <Typography sx={{ fontSize: '35px' }}>No Drivers Available</Typography>
                            </Box>
                        )}
                    </div>
                </Modal>
            </div>
        </>
    );
};
export default Logistics;
