import { useState } from 'react';

// import PageHeader from '../../components/PageHeader';
import MainCard from 'ui-component/cards/MainCard';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import { makeStyles } from '@mui/styles';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import CircularProgress from '@mui/material/CircularProgress';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import ApiUrl from 'network';

// const { BaseUrl } = ApiUrl;

function AddLotDetails() {
    // Main information
    const [lotName, setLotName] = useState('');
    const [channel, setChannel] = useState('');
    const [supplier, setSupplier] = useState('');
    const [orderValue, setOrderValue] = useState(0);
    const [qty, setQty] = useState(0);
    const [buyer, setBuyer] = useState('');
    const [stockDate, setStockDate] = useState(new Date().toLocaleDateString());
    const [brand, setBrand] = useState('');
    const [avgUnitCost, setAvgUnitCost] = useState(0);
    const [invHoldingDays, setInvHoldingDays] = useState(0);

    // Transit information
    const [entry, setEntry] = useState('');
    const [clearingAgent, setClearingAgent] = useState('');
    const [clearingAgentContact, setClearingAgentContact] = useState('');
    const [clearingCost, setClearingCost] = useState(0);
    const [dockDate, setDockDate] = useState(new Date().toLocaleDateString());
    const [dateCleared, setDateCleared] = useState(new Date().toLocaleDateString());
    const [pickupTime, setPickupTime] = useState('');
    const [dropOffTime, setDropOffTime] = useState('');
    const [destination, setDestination] = useState('');
    const [carrier, setCarrier] = useState('');
    const [carrierContact, setCarrierContact] = useState('');
    const [contentVerifiedAtPort, setContentVerifiedAtPort] = useState('');
    const [packageReceiver, setPackageReceiver] = useState('');
    const [packageReceiverContact, setPackageReceiverContact] = useState('');
    const [contentVerifiedAtDestination, setContentVerifiedAtDestination] = useState('');
    const [lotUnloadedAtDestination, setLotUnloadedAtDestination] = useState('');

    // Lot Items
    // const [sku, setSku] = useState('');
    // const [part, setPart] = useState('');
    const [lotQty, setLotQty] = useState(0);
    // const [netQty, setNetQty] = useState(0);
    // const [lotBrand, setLotBrand] = useState('');
    const [purchasePrice, setPurchasePrice] = useState(0);
    const [garageRetailPrice, setGarageRetailPrice] = useState(0);
    const [marketPrice, setMarketPrice] = useState(0);
    const [cashCollected, setCashCollected] = useState(0);
    const [cashOutstanding, setCashOutstanding] = useState(0);
    const [percentCashOutstanding, setPercentCashOutstanding] = useState('');
    // const [qtySold, setQtySold] = useState(0);
    // const [percentMargin, setPercentMargin] = useState('');
    // const [applicableVins, setApplicableVins] = useState('');
    // const [allLotItems, setAllLotItems] = useState([]);

    const handleCashCollected = (value) => {
        setCashCollected(value);
        const val = garageRetailPrice - cashCollected;
        const percent = (val / garageRetailPrice) * 100;
        setTimeout(() => {
            setCashOutstanding(val);
            setPercentCashOutstanding(percent);
        });
    };

    const [openLot, setOpenLot] = useState(false);
    const handleOpenLot = () => setOpenLot(!openLot);

    // Note
    // const [note, setNote] = useState('');
    console.log(setGarageRetailPrice());

    return (
        <>
            <MainCard title="Add Lot Details">
                <div className="garage-grey-text">Main Information</div>
                <br />
                <div className="row">
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField onChange={(e) => setLotName(e.target.value)} value={lotName} label="Lot Name" variant="outlined" />
                        </FormControl>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField onChange={(e) => setChannel(e.target.value)} value={channel} label="Channel" variant="outlined" />
                        </FormControl>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField onChange={(e) => setSupplier(e.target.value)} value={supplier} label="Supplier" variant="outlined" />
                        </FormControl>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                type="number"
                                onChange={(e) => setOrderValue(e.target.value)}
                                value={orderValue}
                                label="Order Value"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField type="number" onChange={(e) => setQty(e.target.value)} value={qty} label="QTY" variant="outlined" />
                        </FormControl>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField onChange={(e) => setBuyer(e.target.value)} value={buyer} label="Buyer" variant="outlined" />
                        </FormControl>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Date Issued"
                                    value={stockDate}
                                    onChange={(newValue) => {
                                        setStockDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField onChange={(e) => setBrand(e.target.value)} value={brand} label="Brand" variant="outlined" />
                        </FormControl>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                type="number"
                                onChange={(e) => setAvgUnitCost(e.target.value)}
                                value={avgUnitCost}
                                label="Avg Unit Cost"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                type="number"
                                onChange={(e) => setInvHoldingDays(e.target.value)}
                                value={invHoldingDays}
                                label="Inv Holding Days"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>
                </div>
                <br />

                <div className="garage-grey-text">Transit Information</div>
                <br />
                <div className="row">
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField onChange={(e) => setEntry(e.target.value)} value={entry} label="Entry Port" variant="outlined" />
                        </FormControl>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                onChange={(e) => setClearingAgent(e.target.value)}
                                value={clearingAgent}
                                label="Clearing Agent"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                onChange={(e) => setClearingAgentContact(e.target.value)}
                                value={clearingAgentContact}
                                label="Clearing Agent Contact"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                type="number"
                                onChange={(e) => setClearingCost(e.target.value)}
                                value={clearingCost}
                                label="Clearing Costs(GHC)"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Dock Date"
                                    value={dockDate}
                                    onChange={(newValue) => {
                                        setDockDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Date Cleared"
                                    value={dateCleared}
                                    onChange={(newValue) => {
                                        setDateCleared(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <TimePicker
                                    label="Pickup from port"
                                    value={pickupTime}
                                    onChange={(newValue) => setPickupTime(newValue)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <TimePicker
                                    label="Drop off at destination"
                                    value={dropOffTime}
                                    onChange={(newValue) => setDropOffTime(newValue)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                onChange={(e) => setDestination(e.target.value)}
                                value={destination}
                                label="Destination"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                onChange={(e) => setCarrier(e.target.value)}
                                value={carrier}
                                label="Package Carrier"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                onChange={(e) => setCarrierContact(e.target.value)}
                                value={carrierContact}
                                label="Package Carrier contact info"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="content-verified">Content verified at port</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="content-verified-id"
                                    value={contentVerifiedAtPort}
                                    label="Content verified at port"
                                    onChange={(e) => setContentVerifiedAtPort(e.target.value)}
                                >
                                    <MenuItem value="">Select an option</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                    <MenuItem value="N">No</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                onChange={(e) => setPackageReceiver(e.target.value)}
                                value={packageReceiver}
                                label="Package Receiver"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                onChange={(e) => setPackageReceiverContact(e.target.value)}
                                value={packageReceiverContact}
                                label="Package Receiver contact info"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="content-verified-destination">Content verified at destination</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="content-verified-destination-id"
                                    value={contentVerifiedAtDestination}
                                    label="Content verified at destination"
                                    onChange={(e) => setContentVerifiedAtDestination(e.target.value)}
                                >
                                    <MenuItem value="">Select an option</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                    <MenuItem value="N">No</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="content-verified">Lot unloaded at destination</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="content-verified-id"
                                    value={lotUnloadedAtDestination}
                                    label="Lot unloaded at destination"
                                    onChange={(e) => setLotUnloadedAtDestination(e.target.value)}
                                >
                                    <MenuItem value="">Select an option</MenuItem>
                                    <MenuItem value="Y">Yes</MenuItem>
                                    <MenuItem value="N">No</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                </div>
                <br />
                <div className="garage-grey-text">Lot Items</div>
                <div className="flex-row-reverse">
                    <button type="button" onClick={() => handleOpenLot()} className="compare-sm-btn garage-blue-bg white-text">
                        Add item
                    </button>
                </div>
            </MainCard>

            <Modal open={openLot} onClose={handleOpenLot} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <div className="width-25 width-lx-35 width-l-45 width-m-70 width-s-85 height-100 flex-column justify-content-center align-items-center margin-auto">
                    <div className="width-100 height-5" />
                    <div className="width-90 height-90 padding-all-10 white-bg overflow-auto" style={{ borderRadius: '10px' }}>
                        <div className="font-20">Add Lot Items</div>
                        <br />
                        <form>
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        id="lot-qty"
                                        label="QTY"
                                        value={lotQty}
                                        onChange={(e) => setLotQty(e.target.value)}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        type="number"
                                        id="purchase-price"
                                        label="Purchase Price"
                                        value={purchasePrice}
                                        onChange={(e) => setPurchasePrice(e.target.value)}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        type="number"
                                        id="market-retail-price"
                                        label="Market Retail Price"
                                        value={marketPrice}
                                        onChange={(e) => setMarketPrice(e.target.value)}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        type="number"
                                        id="cash-collected"
                                        label="Actual Cash Collected"
                                        value={cashCollected}
                                        onChange={(e) => handleCashCollected(e.target.value)}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        disabled
                                        type="number"
                                        id="cash-outstanding"
                                        label="Cash Outstanding"
                                        value={cashOutstanding}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        disabled
                                        id="cash-collected"
                                        label="% Cash Outstanding"
                                        value={percentCashOutstanding}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                        </form>
                    </div>
                    <div className="width-100 height-5" />
                </div>
            </Modal>
        </>
    );
}

export default AddLotDetails;
