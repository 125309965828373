import React, { useState, useEffect } from 'react';
import { Table, Divider, Paper, Space, Button, Select, Spin, DatePicker } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { encrypt, decrypt } from '../../utils/encrypt';
import { LoadingOutlined } from '@ant-design/icons';
import WestIcon from '@mui/icons-material/West';
import { ToastContainer, toast } from 'react-toastify';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import './fleet.css';
import ApiUrl from '../../network';

const userFirstName = localStorage.getItem('userFirstName');

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 54
        }}
        spin
    />
);

const columns = [
    {
        title: '',
        dataIndex: 'index',
        key: 'id',
        rowScope: 'row',
        render: (id, record, index) => {
            return index;
        },
        width: '3%'
    },
    {
        title: 'Vehicle Type',
        dataIndex: 'vehicleType',
        key: 'vehicleType'
    },
    {
        title: 'vehicle Number',
        dataIndex: 'vehicleNumber',
        key: 'vehicleNumber'
    },

    {
        title: 'Fuel Tank Capacity',
        dataIndex: 'fuelTankCapacity',
        key: 'fuelTankCapacity'
    },
    {
        title: 'Engine Capacity',
        dataIndex: 'engineCapacity',
        key: 'engineCapacity'
    },
    {
        title: 'Fuel Tank Cost',
        dataIndex: 'fuelTankCost',
        key: 'fuelTankCost'
    },
    {
        title: 'Location',
        dataIndex: 'location',
        key: 'location'
    }
];

const AllGarageVehicles = () => {
    const [loading, setLoading] = useState(false);
    const [allVehilces, setAllVehilces] = useState([]);
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');

    const navigate = useNavigate();

    // get All vehicles
    const getAllVehicles = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/salesplanning/garagevehicleslist`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setAllVehilces(answer);
                        setLoading(false);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                        toast.error(answer);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        getAllVehicles();
    }, []);

    const vehicles = allVehilces?.map((result) => ({
        key: result?._id,
        vehicleType: result?.vehicleType,
        vehicleNumber: result?.vehicleNumber,
        fuelTankCapacity: result?.fuelTankCapacity,
        engineCapacity: result?.engineCapacity,
        fuelTankCost: result?.fuelTankCost,
        location: result?.location
    }));

    return (
        <div>
            <List
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    padding: '0 0 16px 0',
                    borderBottom: '1px dashed #E7EAEB',
                    alignItems: 'flex-start',
                    margin: '0px auto 15px'
                }}
            >
                <WestIcon sx={{ fontSize: '32px', cursor: 'pointer' }} onClick={() => navigate(-1)} />

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginLeft: '24px'
                    }}
                >
                    <ListItem
                        sx={{
                            fontSize: '1.875rem',
                            color: '#0A2938',
                            padding: 0,
                            fontWeight: 900,
                            marginBottom: '7px'
                        }}
                    >
                        All Garage Vehicles
                    </ListItem>
                </Box>
            </List>
            <Table
                loading={loading ? { indicator: <Spin indicator={antIcon} />, spinning: true / false } : ''}
                columns={columns}
                dataSource={vehicles}
                size="middle"
                scroll={{
                    x: 'calc(700px + 50%)',
                    y: 580
                }}
                bordered="true"
            />
        </div>
    );
};

export default AllGarageVehicles;
