import React, { useEffect, useState } from 'react';
import ApiUrl from 'network';
import { DatePicker, Select, Space, Checkbox, Table, Spin, Input, Button, antIcon } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import Search from 'assets/images/search-normal.svg';
import ProductImage from 'assets/images/shell_oil-removebg-preview1.png';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate, useParams } from 'react-router';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { EmptyState } from 'components/EmptyState/EmptyState';
import { LoadingOutlined } from '@ant-design/icons';

const SingleProduct = () => {
    const navigate = useNavigate();
    const [part, setPart] = useState([]);
    const [currency, setCurrency] = useState([]);
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [cartItems, setCartItems] = useState([]);
    const [quantity, setQuantity] = useState(1);

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54
            }}
            spin
        />
    );

    const getSinglePart = async (id) => {
        try {
            setLoading(true);
            await fetch(`${ApiUrl.BaseUrl}/whatsapp/parts?phone=233247790040`)
                .then((data) => data.json())
                .then((data) => {
                    const singleItem = data?.parts.find((item) => item?._id === id);
                    setPart(singleItem);
                    setCurrency(data.currency);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getSinglePart(params?.id);
    }, [params?.id]);

    const handleIncreaseQuantity = () => {
        setQuantity(quantity + 1);
    };

    const handleDecreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    useEffect(() => {
        // Retrieve cart items from local storage when component mounts
        const storedCartItems = localStorage.getItem('cartItems');
        if (storedCartItems) {
            setCartItems(JSON.parse(storedCartItems));
        }
    }, []);

    useEffect(() => {
        // Update local storage when cart items change
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);

    const addToCart = (item, quantity) => {
        // Create a new item object with the provided quantity
        const newItem = {
            ...item,
            quantity
        };

        // Update the cart items with the new item
        setCartItems([...cartItems, newItem]);
    };

    const orderNow = (item, quantity) => {
        // Create a new item object with the provided quantity
        const newItem = {
            ...item,
            quantity
        };

        // Update the cart items with the new item
        setCartItems([...cartItems, newItem]);
    };

    return (
        <>
            {loading ? (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '4000',
                        color: '#0a2938'
                    }}
                >
                    {antIcon}
                </Box>
            ) : part ? (
                <div className="single-product-page">
                    <div className="single-product-container-image">
                        {part?.partsImage ? (
                            <img src={part?.partsImage} alt="product" />
                        ) : (
                            <BrokenImageIcon
                                sx={{
                                    color: '#999'
                                }}
                            />
                        )}
                    </div>
                    <div className="details">
                        <p>{part?.sku}</p>
                        <span>
                            {currency} {part?.garagePrice}
                        </span>
                    </div>
                    <div className="quantity">
                        <p>Quantity</p>
                        <div className="quantity-control">
                            <Box
                                sx={{
                                    width: '48px',
                                    height: '48px',
                                    borderRadius: '8px',
                                    fontSize: '14px',
                                    backgroundColor: '#A1A5AD',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                                onClick={handleDecreaseQuantity}
                            >
                                <RemoveIcon
                                    sx={{
                                        fontSize: '24px',
                                        color: '#fff'
                                    }}
                                />
                            </Box>

                            <span className="quantity-number"> {quantity}</span>
                            <Box
                                sx={{
                                    width: '48px',
                                    height: '48px',
                                    borderRadius: '8px',
                                    fontSize: '14px',
                                    backgroundColor: '#193B56',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                                onClick={handleIncreaseQuantity}
                            >
                                <AddIcon
                                    sx={{
                                        fontSize: '24px',
                                        color: '#fff'
                                    }}
                                />
                            </Box>
                        </div>
                    </div>
                    <Box
                        sx={{
                            width: '100%',
                            margin: ' 0 0 32px 0',
                            padding: '0px 16px',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: '10px',
                                width: '100%'
                            }}
                        >
                            <Button
                                type="primary"
                                size="large"
                                style={{
                                    backgroundColor: '#FF740F',
                                    borderRadius: '8px',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#fff',
                                    width: '190px',
                                    height: '48px',
                                    margin: '24px 0px 0px 0px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexGrow: '1'
                                }}
                                className=" hover-secondary-button-orange"
                                onClick={() => {
                                    orderNow(part, quantity);
                                    setTimeout(() => {
                                        navigate('/whatsapporder/product/cart');
                                    }, 100);
                                }}
                            >
                                Order now
                            </Button>
                            <Button
                                type="primary"
                                size="large"
                                style={{
                                    backgroundColor: '#233E4C',
                                    borderRadius: '8px',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#fff',
                                    height: '48px',
                                    width: '190px',
                                    margin: '24px 0px 0px 0px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexGrow: '1'
                                }}
                                className=" hover-secondary-button-dark"
                                onClick={() => {
                                    addToCart(part, quantity);
                                    setTimeout(() => {
                                        navigate('/whatsapporder/homepage');
                                    }, 300);
                                }}
                            >
                                Add to cart
                            </Button>
                        </Box>
                        <Button
                            type="primary"
                            size="large"
                            style={{
                                backgroundColor: 'transparent',
                                borderRadius: '8px',
                                fontSize: '14px',
                                fontWeight: '500',
                                color: '#FF740F',
                                border: '1px solid #FF740F',
                                margin: '16px 0px 32px 0px',
                                width: '100%',
                                height: '48px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            onClick={() => navigate('/whatsapporder/homepage')}
                        >
                            Cancel
                        </Button>
                    </Box>
                </div>
            ) : (
                <Box
                    style={{
                        margin: '100px auto 0',
                        position: 'relative',
                        backgroundColor: '#FFFFFF',
                        height: '496px',
                        borderRadius: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <EmptyState height="204px" padding="5px" text="No results yet" />
                </Box>
            )}
        </>
    );
};

export default SingleProduct;
