/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
// import { notification } from 'antd';
import { toast } from 'react-toastify';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import Stack from '@mui/material/Stack';
import useScriptRef from 'hooks/useScriptRef';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import CircularProgress from '@mui/material/CircularProgress';
// import axios from 'axios';
// import Avatar from '@mui/material/Avatar';
// import nologo from 'assets/images/nologo.png';
// import Compressor from 'compressorjs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Form from 'react-bootstrap/Form';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

// const Input = styled('input')({
//     display: 'none'
// });
const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const PartSourced = () => {
    const classes = useStyles();
    const [items, setItems] = useState([]);
    const [sku, setSku] = useState('');
    const [productFamily, setproductFamily] = useState('');
    // const [brand, setbrand] = useState('');
    const [wholeSalePrice, setwholeSalePrice] = useState('');
    // const [garagePrice, setgarageRetailPrice] = useState('');
    // const [retailPrice, setretailPrice] = useState('');
    // const [distributor, setdistributor] = useState('');
    const [partQtyInPieces, setpartQtyInPieces] = useState('');
    // const [status, setStatus] = useState('');
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    // const [distributorInfo, setDistributors] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [loading2, setLoading2] = useState(false);
    // const [loading3, setLoading3] = useState(false);
    // const [partsImage, setpartsImage] = useState('');
    // const [partListAPrice, setpartListAPrice] = useState('');
    // const [partListBPrice, setpartListBPrice] = useState('');
    // const [partListCPrice, setpartListCPrice] = useState('');
    // const [partListCSPrice, setpartListCSPrice] = useState('');
    // const [partWeight, setpartWeight] = useState('');
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // const retrieveAllDistributor = async () => {
    //     try {
    //         const userInfo = localStorage.getItem('userToken');
    //         const config = {
    //             headers: {
    //                 'auth-token': userInfo,
    //                 'Content-Type': 'application/json'
    //             }
    //         };
    //         setLoading2(true);
    //         fetch(ApiUrl.DistributorUrl, config)
    //             .then((data) => data.json())
    //             .then((data) => {
    //                 if (data.status === true) {
    //                     setDistributors(data.data);
    //                     setLoading2(false);
    //                 } else {
    //                     toast.error(data.data);
    //                     setLoading2(false);
    //                 }
    //             })
    //             .finally(() => {
    //                 setLoading2(false);
    //             });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };
    // const selectedFile = (e) => {
    //     // const formData = new FormData();
    //     // formData.append('file', e.target.files[0]);
    //     const image = e.target.files[0];
    //     setLoading3(true);
    //     const userInfo = localStorage.getItem('userToken');
    //     // eslint-disable-next-line no-new
    //     new Compressor(image, {
    //         quality: 0.8,
    //         height: '400px',
    //         width: '400px',
    //         resize: 'contain',
    //         success: (compressedResult) => {
    //             console.log('compressedResult', compressedResult);
    //             const formData = new FormData();
    //             formData.append('file', compressedResult);
    //             axios
    //                 .post(ApiUrl.UploadIDCardPartsUrl, formData, {
    //                     headers: {
    //                         'auth-token': userInfo,
    //                         'Content-Type': 'multipart/form-data'
    //                     }
    //                 })
    //                 .then((result) => {
    //                     if (result.data.status === true) {
    //                         setLoading3(false);
    //                         setpartsImage(result?.data?.data ?? '');
    //                         toast.success('success');
    //                     } else {
    //                         toast.error('failed');
    //                         setLoading3(false);
    //                     }
    //                 })
    //                 .catch((err) => {
    //                     toast.error('failed');
    //                     setLoading3(false);
    //                     console.log(err);
    //                 })
    //                 .finally(() => {
    //                     setLoading3(false);
    //                 });
    //         }
    //     });

    //     // axios
    //     //     .post(ApiUrl.UploadIDCardPartsUrl, formData, {
    //     //         headers: {
    //     //             'auth-token': userInfo,
    //     //             'Content-Type': 'multipart/form-data'
    //     //         }
    //     //     })
    //     //     .then((result) => {
    //     //         if (result.data.status === true) {
    //     //             setLoading3(false);
    //     //             setpartsImage(result?.data?.data ?? '');
    //     //         } else {
    //     //             toast.error(result.data);
    //     //             setLoading3(false);
    //     //         }
    //     //     })
    //     //     .catch((err) => {
    //     //         toast.error(err);
    //     //         setLoading3(false);
    //     //     });
    // };

    useEffect(() => {
        // retrieveAllDistributor();
    }, []);

    const handlePFamilyChange = (event) => {
        setproductFamily(event.target.value);
    };
    // const handleDistributorChange = (event) => {
    //     setdistributor(event.target.value);
    // };

    const onSubmitPart = (event) => {
        event.preventDefault();
        // items, setItems
        setItems([
            ...items,
            {
                sku,
                productFamily,
                wholeSalePrice,
                partQtyInPieces
            }
        ]);

        handleClose();
    };

    const handleDeleteClick = (row, index) => {
        const newList = Object.assign([], items);
        newList.splice(index, 1);
        setItems(newList);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');

        const data = {
            items
        };

        fetch(ApiUrl.AddPartSourcedUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/fulpartlist');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <br />
            <PageHeader title="Parts" subTitle="Home | Add Part" />
            <br />
            <MainCard title="Add Parts">
                <form onSubmit={handleSubmit}>
                    <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                        <Button variant="contained" onClick={handleClickOpen} size="sm" className={classes.item}>
                            Add part
                        </Button>
                    </Box>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                #
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Part
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Product Family
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Qty In Pieces
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Wholesale Price
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Actions
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.sku}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.productFamily}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    {row.partQtyInPieces}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    {row.wholeSalePrice}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    <IconButton
                                                        aria-label="delete"
                                                        color="error"
                                                        onClick={() => handleDeleteClick(row, index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                        <Button type="submit" variant="contained" color="secondary">
                            {loading ? 'Saving...' : 'Save'}
                        </Button>
                        <Button component={Link} to="/parts" type="submit" variant="contained" color="error">
                            Reset
                        </Button>
                    </Box>
                </form>

                <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title" fullWidth>
                    <div style={{ padding: '1rem 2rem' }}>
                        <DialogTitle id="responsive-dialog-title" style={{ fontSize: '1rem' }}>
                            Add Part
                        </DialogTitle>
                        <Form onSubmit={onSubmitPart}>
                            <DialogContent>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <TextField
                                        autoComplete="off"
                                        name="sku"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="sku"
                                        value={sku}
                                        label="Product Name"
                                        onChange={(e) => setSku(e.target.value)}
                                        autoFocus
                                    />

                                    <br />
                                    <br />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Product Family</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={productFamily}
                                            label="Status"
                                            onChange={handlePFamilyChange}
                                        >
                                            <MenuItem value="Filters">Filters</MenuItem>
                                            <MenuItem value="Body">Body</MenuItem>
                                            <MenuItem value="Mechanical">Mechanical</MenuItem>
                                            <MenuItem value="consumables">consumables</MenuItem>
                                            <MenuItem value="Electrical">Electrical</MenuItem>
                                            <MenuItem value="Battery">Battery</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="partQtyInPieces"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="partQtyInPieces"
                                        value={partQtyInPieces}
                                        label="Quantity For Pieces In Box or Roll or Packs"
                                        onChange={(e) => setpartQtyInPieces(e.target.value)}
                                    />

                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="wholeSalePrice"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="wholeSalePrice"
                                        value={wholeSalePrice}
                                        label="Wholesale Price"
                                        onChange={(e) => setwholeSalePrice(e.target.value)}
                                    />
                                </Form.Group>
                            </DialogContent>
                            <DialogActions style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Button
                                    variant="contained"
                                    size="sm"
                                    className={classes.item}
                                    autoFocus
                                    onClick={handleClose}
                                    color="error"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    autoFocus
                                    variant="contained"
                                    type="submit"
                                    size="sm"
                                    className={classes.item}
                                    disabled={!sku || !wholeSalePrice || !partQtyInPieces}
                                >
                                    {loading ? 'Adding ..' : 'Add'}
                                </Button>
                            </DialogActions>
                        </Form>
                    </div>
                </Dialog>
            </MainCard>
        </>
    );
};

export default PartSourced;
