import DataCard from './DataCard';
import { Skeleton } from 'antd';
import Wrapper from '../../assets/Wrappers/StatsContainer';
import { useAppContext } from 'context/appContext';

const DataContainer = ({ stats }) => {
    const { isLoading } = useAppContext();
    return (
        <Wrapper>
            {stats?.map((item, index) => {
                if (isLoading) return <Skeleton active avatar={{ shape: 'square' }} key={index} />;
                return <DataCard key={index} {...item} />;
            })}
        </Wrapper>
    );
};

export default DataContainer;
