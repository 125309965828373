import React, { useEffect, useState } from 'react';
import Products from './Products';
import './whatsappplaceorder.css';
import { useLocation } from 'react-router';
import Navigation from './Navigation';

const Home = () => {
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const phoneNumber = searchParams.get('phone');

        if (phoneNumber) {
            localStorage.setItem('retailerNo', JSON.stringify(phoneNumber));
        }
    }, [location]);

    return (
        <div id="homepage">
            <h1 className="welcome-msg">
                Welcome back{' '}
                <span role="img" aria-label="👋🏽">
                    👋🏽
                </span>
                ,
            </h1>
            <h2 className="homepage-title-msg">Place an order</h2>

            <Products />

            <Navigation />
        </div>
    );
};

export default Home;
