// components/LoadingSpinner.jsx
import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const LoadingSpinner = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 48, color: '#FF740F' }} spin />} />
        </div>
    );
};

export default LoadingSpinner;
