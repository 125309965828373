import GenericPageHeader from 'components/GenericPageHeader';
import ReceiptHeader from './ReceiptHeader';
import { Divider, Paper } from '@mui/material';
import ReceiptHeaderInfo from './ReceiptHeaderInfo';
import ReceiptTable from './ReceiptTable';
import Signature from './Signature';
import LoyaltyProgram from './LoyaltyProgram';
import FootNote from './FootNote';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import { useEffect } from 'react';
import { useLoyaltyContext } from 'context/loyalty/loyalty-context';
import Loader from 'components/Loader';

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-bottom: 50px;
`;

const LoyaltyReceipt = () => {
    const { isLoading, getAllRetailers, retailers, handleRetailerChange, currentRetailerName, currentRetailerId, getRetailerLoyalty } =
        useLoyaltyContext();

    const handleRetailerId = (e, value) => {
        // eslint-disable-next-line array-callback-return
        retailers.map((retailer) => {
            if (retailer?.partnerName === value) {
                const id = retailer?._id;
                handleRetailerChange(value, id);
            }
        });
    };

    const currentRetailerObj = {
        id: currentRetailerId
    };

    useEffect(() => {
        getAllRetailers();
        getRetailerLoyalty(currentRetailerObj);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRetailerId]);

    return (
        <div>
            <GenericPageHeader breadcrumbs="Home | Receipt" title="Receipt" />
            <Wrapper>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={currentRetailerName}
                    style={{ width: 300 }}
                    name="loyalty"
                    onChange={handleRetailerId}
                    options={retailers.map((option) => option?.partnerName)}
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    renderInput={(params) => <TextField {...params} label="Retailers" variant="outlined" />}
                />
            </Wrapper>
            <Paper elevation={1} sx={{ width: '100%', overflow: 'hidden' }} style={{ borderRadius: '0px' }}>
                <ReceiptHeader />
                <Divider />
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <ReceiptHeaderInfo />
                        <ReceiptTable />
                        <Signature />
                        <LoyaltyProgram />
                    </>
                )}
                <FootNote />
            </Paper>
        </div>
    );
};
export default LoyaltyReceipt;
