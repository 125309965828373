import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { Button, Input, Select, DatePicker, Modal, Divider } from 'antd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import '../salesplan.css';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SaveIcon from '@mui/icons-material/Save';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { decrypt, encrypt } from '../../../utils/encrypt';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import ApiUrl from '../../../network';

export const Index = () => {
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [date, setDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const { RangePicker } = DatePicker;
    const [pods, setPods] = useState([]);
    const [rmas, setRmas] = useState([]);
    const [salesPersons, setSalesPersons] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [pod, setPod] = useState('');
    const [rma, setRma] = useState('');
    const [planLocation, setPlanLocation] = useState([]);
    const [target, setTarget] = useState(null);
    const [revenueTarget, setRevenueTarget] = useState(null);
    const [driveDate, setDriveDate] = useState('');
    const [driveType, setDriveType] = useState('');
    const [weekNumber, setWeekNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [driveTypeList, setDriveTypeList] = useState([]);
    const [driveLocationList, setDriveLocationList] = useState([]);
    const [locationMax, setLocationMax] = useState(0);
    const [selectedRouteKeys, setSelectedRouteKeys] = useState([]);
    const [driveId, setDriveId] = useState('');
    const { id } = useParams();
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    const fetchSingleSalesPlan = async (selectedRouteKeys) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const data = {
                routes: selectedRouteKeys.map((key) => ({ routeKey: key }))
            };
            await fetch(`${ApiUrl.BaseUrl}/salesplanning/viewbulksalesplanning`, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify(data)
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        const driveDateValue = result.driveDate ? new Date(result.driveDate) : null;
                        setDriveId(result._id);
                        setStartDate(new Date(result?.startDate).toLocaleDateString('en-US', options));
                        setEndDate(new Date(result?.endDate).toLocaleDateString('en-US', options));
                        setDate([result?.startDate, result?.endDate]);
                        setPod(result?.pod?.name);
                        setRma(`${result?.rmaId?.firstName} ${result?.rmaId?.lastName}`);
                        setPlanLocation(result?.locations.map((location) => location.locName));
                        setLocationMax(result?.locations.length);
                        setTarget(result?.target);
                        setRevenueTarget(result?.revenuetarget);
                        setDriveDate(driveDateValue);
                        setDriveType(result?.driveType?.name);
                        setWeekNumber(result?.week);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        toast.error(error.message);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };
    const fetchPodsData = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/salesdrivepod/salesdrivepod`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setPods(JSON.parse(decrypt(data.data, keyResult, ivResult)));

                        // Extract and set all salespersons
                        const allSalespersons = [];
                        result.forEach((pod) => {
                            pod.salesperson.forEach((salesperson) => {
                                allSalespersons.push(salesperson);
                            });
                        });
                        setSalesPersons(allSalespersons);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        toast.error(error.message);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };
    const getDriveTypeHandler = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/salesplanning/drivetypeslist`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    const driveTypeList = decrypt(data.data, keyResult, ivResult);
                    setDriveTypeList(JSON.parse(driveTypeList));
                } else {
                    toast.error(data.message);
                }
            });
    };
    const getDriveLocationHandler = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/salesplanning/drivelocationslist`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setLoading(false);
                    const driveLocationList = decrypt(data.data, keyResult, ivResult);
                    setDriveLocationList(JSON.parse(driveLocationList));
                } else {
                    setLoading(false);
                    toast.error(data.message);
                }
            });
    };

    const getRouteKeys = async () => {
        // get route keys from local storage ( selectedRouteKeys )
        const selectedRouteKeysString = localStorage.getItem('selectedRouteKeys');

        if (selectedRouteKeysString) {
            try {
                // Parse the string to an array
                const selectedRouteKeys = JSON.parse(selectedRouteKeysString);

                // Ensure it's an array before passing to fetchSingleSalesPlan
                if (Array.isArray(selectedRouteKeys)) {
                    fetchSingleSalesPlan(selectedRouteKeys);
                    setSelectedRouteKeys(selectedRouteKeys);
                } else {
                    toast.error('routes not selected. Please select a route to edit.');
                }
            } catch (error) {
                toast.error('Error fetching route keys');
            }
        }
    };

    useEffect(() => {
        getRouteKeys();
        fetchPodsData();
        getDriveTypeHandler();
        getDriveLocationHandler();
    }, []);

    const podOptions = pods?.map((pod) => {
        return {
            label: pod.name,
            value: pod.name
        };
    });
    const rmsOptions = rmas?.map((rma) => {
        return {
            label: `${rma?.firstName} ${rma?.lastName}`,
            value: rma?.userId
        };
    });
    const driveTypeOptions = driveTypeList?.map((driveType) => {
        return {
            label: driveType.name,
            value: driveType.name
        };
    });
    const locationOptions = driveLocationList?.map((location) => {
        return {
            label: location.location,
            value: location.location
        };
    });

    const updateSalesPlanHandler = async () => {
        setLoading(true);
        let formatStartDate = '';
        let formatEndDate = '';
        if (date) {
            formatStartDate = dayjs(date[0]).format('YYYY-MM-DD');
            formatEndDate = dayjs(date[1]).format('YYYY-MM-DD');
        } else {
            toast.error('Please select start and end date');
            setLoading(false);
            return;
        }

        const formattedLocations = planLocation.map((planLocName) => {
            let location = driveLocationList.find((locationFound) => locationFound.location === planLocName);

            if (!location) {
                // If location is not found, create a default entry
                location = {
                    location: planLocName,
                    latitude: 0,
                    longitude: 0
                };
            }

            return {
                locName: location.location,
                latitude: location.latitude,
                longitude: location.longitude
            };
        });

        const podID = pods.find((podFound) => podFound.name === pod)?._id;
        const driveTypeID = driveTypeList.find((driveTypeFound) => driveTypeFound.name === driveType)?._id;
        const rmaID = rmas.find((rmaFound) => `${rmaFound?.firstName} ${rmaFound?.lastName}` === rma)?.userId;
        const formatedDate = dayjs(driveDate).format('YYYY-MM-DD');

        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        const datas = {
            driveid: selectedRouteKeys.map((key) => ({ _id: key })),
            pod: podID,
            rmaId: rmaID,
            locations: formattedLocations,
            target,
            revenuetarget: revenueTarget,
            driveDate: formatedDate,
            driveType: driveTypeID,
            week: weekNumber
        };

        const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);

        await fetch(`${ApiUrl.BaseUrl}/salesplanning/updatebulksalesplanning`, {
            method: 'PUT',
            headers: config.headers,
            body: JSON.stringify({ payload })
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setLoading(false);
                    toast.success(data.message);
                    fetchSingleSalesPlan(selectedRouteKeys);
                } else {
                    setLoading(false);
                    const error = decrypt(data.data, keyResult, ivResult);
                    toast.error(error);
                }
            });
    };

    useEffect(() => {
        if (pod) {
            const selectedPod = pods.find((podFound) => podFound.name === pod);
            if (selectedPod) {
                setRmas(selectedPod?.salesperson);
            }
        }
    }, [pods, pod]);
    const podSelectHandler = (value) => {
        setPod(value);
        const selectedPod = pods.find((pod) => pod.name === value);
        if (selectedPod) {
            setRmas(selectedPod?.salesperson);
        }
    };

    const cancelHanlder = () => {
        window.location.href = '/salesplanning';
    };

    const handleLocationChange = (value) => {
        if (value.length > locationMax) {
            toast.error(`You can only select up to ${locationMax} locations.`);
        } else {
            setPlanLocation(value);
        }
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Link to="/salesplanning" className="createScheduleLink">
                    <p style={{ fontSize: '25px', marginLeft: '10px' }}>
                        <ArrowBackIcon sx={{ fontSize: '25px', color: '#000' }} /> Edit schedule
                    </p>
                </Link>
                <Button
                    onClick={updateSalesPlanHandler}
                    style={{ backgroundColor: '#FF740F', color: '#fff' }}
                    className="btnCreateSchedule"
                    title="Apply Filters"
                    loading={loading}
                >
                    <SaveIcon sx={{ fontSize: '16px', color: '#fff' }} />
                    <Typography sx={{ margin: '0 14px ', color: '#fff' }}>Update Schedule</Typography>
                </Button>
            </div>
            <div className="addPlanContainer">
                <div className="inputHolder">
                    <div className="addSalePlanInput">
                        <div className="mr-2">
                            <p style={{ fontSize: '18px', fontWeight: 'bold', color: '#3B5460' }}>Weekly sales drive</p>
                        </div>
                    </div>
                    <div>
                        <div className="addSalePlanInput">
                            <div className="mr-2">
                                <p style={{ fontSize: '16px', color: '#546974' }}>POD</p>
                                <Select
                                    disabled
                                    placeholder="POD"
                                    showSearch
                                    allowClear
                                    size="large"
                                    style={{ width: '300px' }}
                                    value={pod}
                                    onChange={(value) => podSelectHandler(value)}
                                    filterOption={(input, option) =>
                                        (option?.name.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                                    }
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={podOptions}
                                />
                            </div>
                            <div>
                                <p style={{ fontSize: '16px', color: '#546974' }}>RMA(s)</p>
                                <Select
                                    placeholder="RMA(s)"
                                    mode="single"
                                    allowClear
                                    showSearch
                                    size="large"
                                    style={{ width: '300px' }}
                                    onChange={(value) => setRma(value)}
                                    filterOption={(inputValue, option) => {
                                        const label = option.label.toString().toUpperCase();
                                        return label.includes(inputValue.toUpperCase());
                                    }}
                                    value={rma || undefined}
                                    options={rmsOptions}
                                />
                            </div>
                        </div>
                        <div className="addSalePlanInput">
                            <div className="mr-2">
                                <p style={{ fontSize: '16px', color: '#546974' }}>locations</p>
                                <Select
                                    placeholder="Add Location"
                                    mode="tags"
                                    allowClear
                                    size="large"
                                    showSearch
                                    style={{ width: '300px' }}
                                    value={planLocation}
                                    onChange={handleLocationChange}
                                    filterOption={(inputValue, option) => {
                                        const label = option.label.toString().toUpperCase();
                                        return label.includes(inputValue.toUpperCase());
                                    }}
                                    options={locationOptions}
                                />
                            </div>
                            <div>
                                <p style={{ fontSize: '16px', color: '#546974' }}>Drive Type</p>
                                <Select
                                    placeholder="Drive Type"
                                    type="text"
                                    size="large"
                                    style={{
                                        width: '300px'
                                    }}
                                    value={driveType}
                                    onChange={(value) => setDriveType(value)}
                                    filterOption={(inputValue, option) => {
                                        const label = option.label.toString().toUpperCase();
                                        return label.includes(inputValue.toUpperCase());
                                    }}
                                    options={driveTypeOptions}
                                />
                            </div>
                        </div>
                        <div className="addSalePlanInput">
                            <div className="mr-2">
                                <p style={{ fontSize: '16px', color: '#546974' }}>Drive date</p>
                                <DatePicker
                                    format="DD/MM/YYYY"
                                    size="large"
                                    style={{
                                        width: '300px'
                                    }}
                                    value={driveDate ? dayjs(driveDate) : null}
                                    onChange={(date) => setDriveDate(date ? date.format('YYYY/MM/DD') : null)}
                                />
                            </div>
                            <div>
                                <p style={{ fontSize: '16px', color: '#546974' }}>Week</p>
                                <Input
                                    placeholder="Week"
                                    size="large"
                                    style={{ width: '300px' }}
                                    value={weekNumber}
                                    onChange={(e) => setWeekNumber(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="addSalePlanInput">
                            <div className="mr-2">
                                <p style={{ fontSize: '16px', color: '#546974' }}>Gmv Target</p>
                                <Input
                                    type="number"
                                    placeholder="Target"
                                    size="large"
                                    style={{ width: '300px' }}
                                    value={target}
                                    onChange={(e) => setTarget(e.target.value)}
                                />
                            </div>
                            <div>
                                <p style={{ fontSize: '16px', color: '#546974' }}>Revenue Target</p>
                                <Input
                                    type="number"
                                    placeholder="Target"
                                    size="large"
                                    style={{ width: '300px' }}
                                    value={revenueTarget}
                                    onChange={(e) => setRevenueTarget(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="addSalePlanInput margin-top-20">
                            <Button
                                onClick={() => cancelHanlder()}
                                style={{ border: '1px solid #CED4D7', backgroundColor: '#fff', color: '#000' }}
                                className="mt-2 mb-2 d-flex align-items-center justify-content-cente btnCreateSchedule"
                                title="Apply Filters"
                            >
                                <Typography sx={{ margin: '0 14px ', color: '#000' }}>Cancel</Typography>
                            </Button>
                        </div>
                    </div>
                </div>
                <Modal width={300} title="Add a date range" footer={null} open={isModalVisible} onCancel={() => setIsModalVisible(false)}>
                    <div>
                        <RangePicker
                            format="DD/MM/YYYY"
                            size="large"
                            value={date ? [dayjs(startDate), dayjs(endDate)] : null}
                            onChange={(date) => {
                                setDate(date);
                                if (date && date.length === 2) {
                                    setStartDate(date[0].format('YYYY/MM/DD'));
                                    setEndDate(date[1].format('YYYY/MM/DD'));
                                } else {
                                    setStartDate(null);
                                    setEndDate(null);
                                }
                            }}
                        />
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default Index;
