import styled from 'styled-components';

const HeaderWrapper = styled.div`
    .custom-paper {
        font-family: 'Roboto', 'sans-serif';
        border-radius: 0;
        padding: 10px 10px;
        color: #444;
        margin-bottom: 50px;
        h3 {
            font-size: 1.1rem;
            font-weight: 700;
        }

        @media screen and (min-width: 1200px) {
            .sales-person {
                text-align: right;
            }
        }

        .associate-title {
            padding: 5px 0;
            color: #ff740f;
            font-size: 1rem;
        }

        .rank {
            color: #ff740f;
            margin-top: -5px;
            font-weight: 700;
        }

        h4 {
            font-size: 1rem;
            color: #777;
        }
    }
`;

export default HeaderWrapper;
