export const showSenderList = (setSenderListVisible, setChatBoxVisible) => {
    setSenderListVisible(true);
    setChatBoxVisible(false);
    document.querySelector('.MuiPaper-root')?.style.setProperty('display', 'none');
};

export const hideSenderList = (setSenderListVisible, setChatBoxVisible, scrollToBottom) => {
    setSenderListVisible(false);
    setChatBoxVisible(true);
    document.querySelector('.MuiPaper-root')?.style.setProperty('display', 'flex');
    scrollToBottom();
};

export const handleChatPopClick = (id, open, setOpen) => {
    if (open === id) {
        setOpen(null);
        document.getElementById('chat-box-message').classList.remove('no-scroll');
    } else {
        setOpen(id);
        document.getElementById('chat-box-message').classList.add('no-scroll');
    }
};

export const closePopover = (setOpen) => {
    setOpen(false);
    document.getElementById('chat-box-message').classList.remove('no-scroll');
};

export const replyToMessage = (message, setReplyTo, setOpen) => {
    setReplyTo({
        _id: message._id,
        profile: message.profile,
        content: message.content,
        whatsapp_id: message.whatsapp_id
    }); // Set the message to reply to
    closePopover(setOpen); // Close the popover
};

export const menuItems = [
    {
        key: 'assign-task',
        label: 'Create task',
        style: { display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }
    },
    {
        key: 'customer-cart',
        label: 'View pro-forma',
        style: { display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }
    },
    {
        key: 'place-order',
        label: 'Create pro-forma',
        style: { display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }
    },
    {
        key: 'customer-profile',
        label: 'View customer profile',
        style: { display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px', color: '#4B6EFF' }
    }
];

export const getReplyMenuItems = (message, setReplyType, setReplyTo, setOpen) => [
    {
        key: 'reply',
        label: 'Reply',
        style: { padding: '10px' },
        onClick: () => {
            setReplyType('text');
            replyToMessage(message, setReplyTo, setOpen);
        }
    },
    {
        key: 'delete',
        label: 'Delete',
        style: { padding: '10px' },
        onClick: () => {
            // Handle delete action here
        }
    },
    {
        key: 'edit',
        label: 'Edit',
        style: { padding: '10px' },
        onClick: () => {
            // Handle edit action here
        }
    }
];

export const isLink = (content) => {
    try {
        const url = new URL(content);
        return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (e) {
        return false;
    }
};
