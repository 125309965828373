import styled from 'styled-components';

const Wrapper = styled.div`
    padding: 1.2rem;
    background: #fff;
    font-family: 'Roboto', sans-serif;
    border-radius: 5px;
    cursor: pointer;
    border-bottom: 5px solid ${(props) => props.color};
    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .count {
        display: block;
        font-weight: 700;
        font-size: 1.5rem;
        color: ${(props) => props.color};
    }
    .title {
        margin: 0;
        text-transform: capitalize;
        text-align: left;
        font-size: 1.1rem;
        margin-top: 0.5rem;
    }
    .icon {
        width: 70px;
        height: 60px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            font-size: 2.5rem;
            color: ${(props) => props.color};
        }
    }
`;

export default Wrapper;
