import logo from '../../../assets/images/garage.png';
import { Button, DatePicker, Modal, Select, Table, Input } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import ApiUrl from '../../../network';
import { decrypt, encrypt } from '../../../utils/encrypt';
import { cryptoUUID } from '../../../utils/functions';
import { useParams } from 'react-router-dom';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const { id } = useParams();
    const [partModal, setPartModal] = useState(false);
    const [partsBuyModal, setPartsBuyModal] = useState(false);
    const [parts, setParts] = useState([]);
    const [partsTypes, setPartsTypes] = useState([]);
    const [pods, setPods] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [orderStatus, setOrderStatus] = useState([]);
    const [items, setItems] = useState([]);
    const [partsdata, setPartsData] = useState([]);
    const [orderDate, setOrderDate] = useState('');
    const [presaleDate, setPresaleDate] = useState('');
    const [newChannelPartnerId, setNewChannelPartnerId] = useState('');
    const [newOrderStatusId, setNewOrderStatusId] = useState('');
    const [orderNotes, setOrderNotes] = useState('');
    const [localId, setLocalId] = useState('');
    const [podId, setPodId] = useState('');
    const [placedfor, setPlacedfor] = useState('');
    const [rmacc, setRmacc] = useState([]);
    const [src, setSrc] = useState('Web');
    const [loading, setLoading] = useState(false);
    const [skuId, setSkuId] = useState('');
    const [garagePrice, setGaragePrice] = useState('');
    const [wholesalePrice, setWholesalePrice] = useState('');
    const [qty, setQty] = useState('');
    const [partQtyInPieces, setPartQtyInPieces] = useState('');
    const [partType, setPartType] = useState('');
    const [partsImage, setPartsImage] = useState('');
    const [partname, setPartName] = useState('');
    const [partsize, setPartSize] = useState('');
    const [partqty, setPartQty] = useState('');
    const [supplier2, setSupplier2] = useState('');

    const fetchParts = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/order/v2/allparts`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                setParts(decryptedData);
            } else {
                toast.error(result.message);
            }
        } catch (e) {
            toast(e.message);
        }
    };
    const fetchRma = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/order/v2/rmccdata`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                setRmacc(decryptedData);
            } else {
                toast.error(result.message);
            }
        } catch (e) {
            toast(e.message);
        }
    };
    const fetchPartTypes = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/order/v2/orderstatuspartypes`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                setPartsTypes(decryptedData.parttypes);
                setOrderStatus(decryptedData.orderstatus);
            } else {
                toast.error(result.message);
            }
        } catch (e) {
            toast(e.message);
        }
    };
    const fetchPods = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/order/v2/allpods`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                setPods(decryptedData);
            } else {
                toast.error(result.message);
            }
        } catch (e) {
            toast(e.message);
        }
    };
    const fetchCustomers = async (id) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/order/v2/allcustomers/${id}`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                setCustomers(decryptedData);
            } else {
                toast.error(result.message);
            }
        } catch (e) {
            toast(e.message);
        }
    };

    const fetchOrder = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/order/v2/vieworders/${id}`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                setOrderDate(dayjs(decryptedData.orderDate));
                setPodId(decryptedData.pod);
                setNewChannelPartnerId(decryptedData?.newChannelPartnerId?.partnerName);
                setNewOrderStatusId(decryptedData?.newOrderStatusId?.name);
                setOrderNotes(decryptedData.orderNotes);
                setSrc(decryptedData.src);
                setPlacedfor(decryptedData.placedfor);
                setItems(decryptedData.items);
                setPartsData(decryptedData.partsdata);
                fetchCustomers(decryptedData.pod);
                setLoading(false);
            } else {
                toast.error(result.message);
            }
        } catch (e) {
            toast(e.message);
        }
    };

    useEffect(() => {
        fetchOrder();
        fetchPods();
        fetchParts();
        fetchPartTypes();
        fetchRma();
        const ids = cryptoUUID();
        setLocalId(ids);
    }, [id]);

    const partOptions = parts.map((part) => {
        return {
            label: part.sku,
            value: part._id
        };
    });
    const podOptions = pods.map((pod) => {
        return {
            label: pod.name,
            value: pod._id
        };
    });
    const customerOptions = customers.map((customer) => {
        return {
            label: customer.partnerName,
            value: customer._id
        };
    });
    const partsTypesOptions = partsTypes.map((partType) => {
        return {
            label: partType.name,
            value: partType.name
        };
    });
    const orderStatusOptions = orderStatus.map((orderStatus) => {
        return {
            label: orderStatus.name,
            value: orderStatus._id
        };
    });
    const rmaccOptions = rmacc.map((rmacc) => {
        return {
            label: `${rmacc.firstName} ${rmacc.lastName}`,
            value: rmacc._id
        };
    });

    const filteredOrderStatusOptions = orderStatusOptions.filter((option) => option.value !== '651da6eb9d048db5123bc205');

    const itemsData = items.map((item) => {
        return {
            key: item.id,
            skuId: item.skuId,
            qty: item.qty,
            partType: item.partType
        };
    });
    const partsData = partsdata.map((part) => {
        return {
            key: Math.random(),
            partname: part.partname,
            partsize: part.partsize,
            partqty: part.partqty,
            supplier2: part.supplier2
        };
    });

    const itemsColumn = [
        {
            title: 'Part',
            dataIndex: 'skuId',
            key: 'skuId',
            render: (text) => {
                const part = parts.find((part) => part._id === text);
                return part ? part.sku : '';
            }
        },
        {
            title: 'QTY',
            dataIndex: 'qty',
            key: 'qty'
        },
        {
            title: 'Size',
            dataIndex: 'partType',
            key: 'partType'
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) =>
                record.skuId !== '' && (
                    <Button
                        className="d-flex justify-content-center align-items-center"
                        style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            color: '#FF740F',
                            width: 'fit-content'
                        }}
                        onClick={() => {
                            if (items.length > 1) {
                                const newItems = items.filter((item) => item.skuId !== record.skuId);
                                setItems(newItems);
                            } else {
                                setItems([
                                    {
                                        id: 1,
                                        skuId: '',
                                        garagePrice: '',
                                        wholesalePrice: '',
                                        qty: '',
                                        partQtyInPieces: '',
                                        partType: '',
                                        partsImage: ''
                                    }
                                ]);
                            }
                        }}
                    >
                        <MinusCircleOutlined /> Remove
                    </Button>
                )
        }
    ];

    const partsColumn = [
        {
            title: 'Part Name',
            dataIndex: 'partname',
            key: 'partname'
        },
        {
            title: 'Part Size',
            dataIndex: 'partsize',
            key: 'partsize'
        },
        {
            title: 'Part Qty',
            dataIndex: 'partqty',
            key: 'partqty'
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) =>
                record.partsize !== '' && (
                    <Button
                        className="d-flex justify-content-center align-items-center"
                        style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            color: '#FF740F',
                            width: 'fit-content'
                        }}
                        onClick={() => {
                            if (partsdata.length > 1) {
                                const newPartsData = partsdata.filter((part) => part.partname !== record.partname);
                                setPartsData(newPartsData);
                            } else {
                                setPartsData([
                                    {
                                        partname: '',
                                        partsize: '',
                                        partqty: '',
                                        supplier2: ''
                                    }
                                ]);
                            }
                        }}
                    >
                        <MinusCircleOutlined /> Remove
                    </Button>
                )
        }
    ];

    const computeFinalQty = (qty, partQtyInPieces, partType) => {
        let finalQty = qty;
        let finalPartType = partType;

        if (partQtyInPieces && partType.toLowerCase() !== 'pieces') {
            finalQty = qty * partQtyInPieces;
            finalPartType = 'Pieces';
        } else if (partQtyInPieces && partType.toLowerCase() === 'pieces') {
            finalQty = qty;
            finalPartType = 'Pieces';
        } else {
            finalPartType = 'Pieces';
        }

        return { finalQty, finalPartType };
    };

    const { finalQty, finalPartType } = computeFinalQty(qty, partQtyInPieces, partType);

    const addItem = () => {
        const newPart = {
            id: items.length + 1,
            skuId,
            qty: finalQty,
            partType: finalPartType
        };

        const newItems = [...items, newPart];

        setItems(newItems);
        // Clear the input fields after adding the part
        setSkuId('');
        setGaragePrice('');
        setWholesalePrice('');
        setQty('');
        setPartQtyInPieces('');
        setPartType('');
        setPartsImage('');
        setPartModal(false);
    };
    const addPart = () => {
        const newPart = {
            partname,
            partsize,
            partqty,
            supplier2: ''
        };
        const newPartsData = [...partsdata, newPart];
        setPartsData(newPartsData);
        // Clear the input fields after adding the part
        setPartName('');
        setPartSize('');
        setPartQty('');
        setSupplier2('');
        setPartsBuyModal(false);
    };

    const handleUpdateSubmit = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const pod = pods.find((pod) => pod._id === podId);
            const newChannelPartner = customers.find((customer) => customer.partnerName === newChannelPartnerId);
            const orderStatusFind = orderStatus.find((orderStatus) => orderStatus.name === newOrderStatusId);

            const datas = {
                id,
                orderDate,
                podId: pod?._id || podId,
                newChannelPartnerId: newChannelPartner?._id || newChannelPartnerId,
                newOrderStatusId: orderStatusFind?._id || newOrderStatusId,
                orderNotes,
                src,
                local_id: localId,
                items: itemsData,
                partsdata: partsData
            };
            if (newOrderStatusId === '651da6eb9d048db5123bc205') {
                datas.placedfor = placedfor;
            } else {
                datas.placedfor = '';
            }

            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);

            const url = `${ApiUrl.BaseUrl}/order/v2/updateordersrmacc`;
            const response = await fetch(url, {
                method: 'PUT',
                headers: config.headers,
                body: JSON.stringify({ payload })
            });
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                toast.success(result.message);
                setLoading(true);
                fetchOrder();
            } else {
                const error = JSON.parse(decrypt(result.data, keyResult, ivResult));
                toast.error(error);
                setLoading(true);
            }
        } catch (e) {
            toast(e.message);
        }
    };

    return (
        <div className="order-request-content">
            <div>
                <div className="order-header">
                    <img src={logo} alt="logo" className="mb-4" height={50} />
                    <h5>Order Parts Request Form</h5>
                    <p>Use this form to input parts requests from channel partners</p>
                </div>
                <div>
                    <div className="order-form">
                        <div className="form-group">
                            <p>Order date</p>
                            <DatePicker
                                disabled
                                value={orderDate}
                                onChange={(date) => setOrderDate(date)}
                                className="form-control"
                                placeholder="Select date"
                                format="DD/MM/YYYY"
                                defaultValue={dayjs()}
                            />
                        </div>
                        <div className="form-group">
                            <p>Pods</p>
                            <Select
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }
                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                                options={podOptions}
                                style={{ width: '100%' }}
                                placeholder="Select pod"
                                value={podId || undefined}
                                onChange={(value) => {
                                    setPodId(value);
                                    // eslint-disable-next-line no-unused-expressions
                                    value && fetchCustomers(value);
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <p>Customer</p>
                            <Select
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }
                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                                options={customerOptions}
                                style={{ width: '100%' }}
                                placeholder="Select customer"
                                value={newChannelPartnerId || undefined}
                                onChange={(value) => {
                                    setNewChannelPartnerId(value);
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <p>Order status</p>
                            <Select
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }
                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                                options={filteredOrderStatusOptions}
                                value={newOrderStatusId || undefined}
                                onChange={(value) => {
                                    setNewOrderStatusId(value);
                                }}
                                style={{ width: '100%' }}
                                placeholder="Select order status"
                            />
                        </div>
                        {newOrderStatusId === '651da6eb9d048db5123bc205' && (
                            <div className="form-group">
                                <p>Placed for</p>
                                <Select
                                    filterOption={(inputValue, option) => {
                                        if (typeof option.label === 'string') {
                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }
                                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }}
                                    showSearch
                                    allowClear
                                    options={rmaccOptions}
                                    value={placedfor || undefined}
                                    onChange={(value) => {
                                        setPlacedfor(value);
                                    }}
                                    style={{ width: '100%' }}
                                    placeholder="Placed for"
                                />
                            </div>
                        )}
                        <div className="form-group">
                            <p>Additional Notes</p>
                            <textarea
                                className="form-control"
                                rows="2"
                                style={{
                                    resize: 'none',
                                    height: '100px'
                                }}
                                placeholder="Additional Notes......."
                                value={orderNotes || undefined}
                                onChange={(e) => setOrderNotes(e.target.value)}
                            />
                        </div>
                        <div className="form-group d-flex flex-row-reverse">
                            <Button
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    color: '#FF740F',
                                    width: 'fit-content'
                                }}
                                onClick={() => setPartModal(true)}
                            >
                                {items.length > 1 ? 'View Parts' : 'Add Parts'}
                            </Button>
                        </div>
                        <div className="table-responsive">
                            <Table
                                style={{
                                    width: '100%'
                                }}
                                dataSource={itemsData}
                                columns={itemsColumn}
                                pagination={false}
                                scroll={{ x: 1000 }}
                            />
                        </div>
                        <div>
                            <p
                                style={{
                                    fontSize: '12px',
                                    color: '546974',
                                    textAlign: 'center',
                                    marginBottom: '10px',
                                    marginTop: '20px'
                                }}
                            >
                                Only use this Button when the parts is not in the system.
                            </p>
                            <p
                                style={{
                                    fontSize: '12px',
                                    color: '546974',
                                    textAlign: 'center',
                                    marginBottom: '10px'
                                }}
                            >
                                Parts needed: include name, quantity and size.eg: [Grease 500g 12 pieces]
                            </p>
                        </div>
                        <div className="form-group d-flex flex-row-reverse">
                            <Button
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    color: '#FF740F',
                                    width: 'fit-content'
                                }}
                                onClick={() => setPartsBuyModal(true)}
                            >
                                Add Sales Part
                            </Button>
                        </div>
                        <div className="table-responsive">
                            <Table
                                style={{
                                    width: '100%'
                                }}
                                dataSource={partsData}
                                columns={partsColumn}
                                pagination={false}
                                scroll={{ x: 1000 }}
                            />
                        </div>
                        <div className="form-group d-flex justify-content-space-between mt-4">
                            <Button
                                type="primary"
                                style={{
                                    backgroundColor: 'transparent',
                                    borderColor: '#FF740F',
                                    color: '#FF740F'
                                }}
                                onClick={() => {
                                    setOrderDate(dayjs());
                                    setPresaleDate(dayjs());
                                    setNewChannelPartnerId('');
                                    setNewOrderStatusId('');
                                    setOrderNotes('');
                                    setLocalId('');
                                    setPodId('');
                                    setPlacedfor('');
                                    setSrc('Web');
                                    setItems([
                                        {
                                            id: 1,
                                            skuId: '',
                                            garagePrice: '',
                                            wholesalePrice: '',
                                            qty: '',
                                            partQtyInPieces: '',
                                            partType: '',
                                            partsImage: ''
                                        }
                                    ]);
                                    setPartsData([
                                        {
                                            partname: '',
                                            partsize: '',
                                            partqty: '',
                                            supplier2: ''
                                        }
                                    ]);
                                }}
                            >
                                Reset
                            </Button>
                            <Button
                                type="primary"
                                style={{
                                    backgroundColor: '#FF740F',
                                    borderColor: '#FF740F'
                                }}
                                onClick={handleUpdateSubmit}
                                loading={loading}
                                disabled={loading}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                title="Add Parts"
                open={partModal}
                onOk={() => setPartModal(false)}
                onCancel={() => setPartModal(false)}
                width={600}
                footer={null}
            >
                <div className="order-form mb-4 parts-data-field">
                    <div className="form-group">
                        <p>Part Name</p>
                        <Select
                            options={partOptions}
                            style={{ width: '100%' }}
                            filterOption={(inputValue, option) => {
                                if (typeof option.label === 'string') {
                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }
                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }}
                            showSearch
                            allowClear
                            placeholder="Part name"
                            value={skuId || undefined}
                            onChange={(value) => {
                                setSkuId(value);
                                const selectedPart = parts.find((part) => part._id === value);
                                if (selectedPart) {
                                    setGaragePrice(selectedPart.garagePrice);
                                    setWholesalePrice(selectedPart.wholeSalePrice);
                                    setPartsImage(selectedPart.partsImage);
                                    setPartQtyInPieces(selectedPart.partQtyInPieces);
                                }
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <p>Quantity</p>
                        <input
                            type="number"
                            min="0"
                            className="form-control"
                            placeholder="Quantity"
                            value={qty || ''}
                            onChange={(e) => {
                                setQty(e.target.value);
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <p>Part Type</p>
                        <Select
                            options={partsTypesOptions}
                            style={{ width: '100%' }}
                            filterOption={(inputValue, option) => {
                                if (typeof option.label === 'string') {
                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }
                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }}
                            showSearch
                            allowClear
                            placeholder="Part size"
                            value={partType || undefined}
                            onChange={(value) => {
                                setPartType(value);
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <p>Garage Price</p>
                        <input
                            disabled
                            type="number"
                            className="form-control"
                            placeholder="Garage Price"
                            value={garagePrice || ''}
                            onChange={(e) => {
                                setGaragePrice(e.target.value);
                            }}
                        />
                    </div>
                    {skuId && (
                        <div className="form-group">
                            <img
                                width={100}
                                height={100}
                                src={partsImage}
                                onError={(e) => {
                                    e.target.src = `${logo}`;
                                }}
                                alt="part"
                            />
                        </div>
                    )}
                    <div className="form-group mt-4">
                        <p style={{ color: '#FF740F' }}>
                            Converted Quantity {finalQty} {finalPartType}
                        </p>
                    </div>
                    <div className="d-flex flex-row-reverse align-items-center">
                        <Button
                            className="d-flex justify-content-center align-items-center"
                            style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                                color: '#FF740F',
                                width: 'fit-content'
                            }}
                            onClick={addItem}
                        >
                            Add Part <PlusOutlined />
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                title="Enter Parts Details"
                open={partsBuyModal}
                onOk={() => setPartsBuyModal(false)}
                onCancel={() => setPartsBuyModal(false)}
                width={600}
                footer={null}
            >
                <div className="order-form mb-4 parts-data-field">
                    <div className="form-group">
                        <p>Part Name</p>
                        <Input
                            style={{ width: '100%' }}
                            placeholder="Part name"
                            value={partname || undefined}
                            onChange={(e) => {
                                setPartName(e.target.value);
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <p>Quantity</p>
                        <input
                            type="number"
                            min="0"
                            className="form-control"
                            placeholder="Quantity"
                            value={partqty || ''}
                            onChange={(e) => {
                                setPartQty(e.target.value);
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <p>Part Size</p>
                        <Select
                            options={partsTypesOptions}
                            style={{ width: '100%' }}
                            filterOption={(inputValue, option) => {
                                if (typeof option.label === 'string') {
                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }
                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }}
                            showSearch
                            allowClear
                            placeholder="Part size"
                            value={partsize || undefined}
                            onChange={(value) => {
                                setPartSize(value);
                            }}
                        />
                    </div>
                    <div className="d-flex flex-row-reverse align-items-center">
                        <Button
                            className="d-flex justify-content-center align-items-center"
                            style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                                color: '#FF740F',
                                width: 'fit-content'
                            }}
                            onClick={addPart}
                        >
                            Add Part <PlusOutlined />
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Index;
