import { useEffect } from 'react';

import { Chart as ChartJS, registerables } from 'chart.js';

import { Bar } from 'react-chartjs-2';

ChartJS.register(...registerables);

const BarChart = ({ data }) => {
    useEffect(() => {}, [data]);
    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true
                    }
                }
            ]
        }
    };

    return (
        <>
            <div className="width-100">{data ? <Bar data={data} options={options} /> : ''}</div>
        </>
    );
};

export default BarChart;
