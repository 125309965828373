/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../../components/PageHeader';
import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import { useTheme } from '@mui/material/styles';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import useScriptRef from 'hooks/useScriptRef';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import EditIcon from '@mui/icons-material/Edit';
// import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    },
    backgroundColor: {
        backgroundColor: '#FF740F'
    },
    color: {
        color: '#FF740F',
        fontWeight: 'bold'
    },
    cancelColor: {
        cancelColor: '#D3DADF'
    }
}));

const AllViewRequestServicingFleet = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [carRegistrationNo, setcarRegistrationNo] = useState('');
    const [firstname, setfirstname] = useState('');
    const [lastname, setlastname] = useState('');
    const [email, setemail] = useState('');
    const [phoneNumber, setphoneNumber] = useState('');
    const [companyName, setcompanyName] = useState('');
    // const [car, setcar] = useState('');
    const [carName, setcarName] = useState('');
    // const [carmodel, setcarmodel] = useState('');
    const [carmodelName, setcarmodelName] = useState('');
    const [dateLastService, setdateLastService] = useState(new Date());
    const [preferredAppointmentDate, setpreferredAppointmentDate] = useState(new Date());
    const [purposeLastService, setpurposeLastService] = useState('');
    const [currentPurposeService, setcurrentPurposeService] = useState('');
    const [additionalDetails, setadditionalDetails] = useState('');
    const [vinNo, setvinNo] = useState('');
    const [status, setstatus] = useState('');
    const [channelPartnerId, setchannelPartnerId] = useState('');
    // const [loading3, setLoading3] = useState(false);
    const [carData, setCarData] = useState([]);
    // const [carmodelData, setCarModelData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    // const theme = useTheme();
    const { id } = useParams();
    console.log(carData);
    console.log(loading2);

    const retrieveAllCar = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllFleetCarss, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCarData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // const retrieveAllCarModel = async (id) => {
    //     try {
    //         const userInfo = localStorage.getItem('userToken');
    //         const config = {
    //             headers: {
    //                 'auth-token': userInfo,
    //                 'Content-Type': 'application/json'
    //             }
    //         };

    //         fetch(ApiUrl.AllCarModelsDetailsUrlFleetModelss + id, config)
    //             .then((data) => data.json())
    //             .then((data) => {
    //                 if (data.status === true) {
    //                     setCarModelData(data.data);
    //                 } else {
    //                     toast.error(data.data);
    //                 }
    //             })
    //             .finally(() => {
    //                 setLoading(false);
    //             });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    useEffect(() => {
        retrieveAllCar();

        // const company = localStorage.getItem('company');
        // const channelPartnerId = localStorage.getItem('channelpartnerId');
        // setcompanyName(company);
        // setchannelPartnerId(channelPartnerId);
        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        fetch(ApiUrl.ShowUrlsRequestServicingFleets + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    // console.log('result', result.data);
                    setchannelPartnerId(result?.data?.channelPartnerId ?? '');
                    setcarRegistrationNo(result?.data?.carRegistrationNo ?? '');
                    setfirstname(result?.data?.firstname ?? '');
                    setlastname(result?.data?.lastname ?? '');
                    setcompanyName(result?.data?.companyName ?? '');
                    // setcar(result?.data?.car ?? '');
                    setcarName(result?.data?.carName ?? '');
                    // setcarmodel(result?.data?.carmodel ?? '');
                    setcarmodelName(result?.data?.carmodelName ?? '');
                    setemail(result?.data?.email ?? '');
                    setphoneNumber(result?.data?.phoneNumber ?? '');
                    setdateLastService(result?.data?.dateLastService ?? '');
                    setvinNo(result?.data?.vinNo ?? '');
                    setstatus(result?.data?.status ?? '');
                    setpreferredAppointmentDate(result?.data?.preferredAppointmentDate ?? '');
                    setpurposeLastService(result?.data?.purposeLastService ?? '');
                    setcurrentPurposeService(result?.data?.currentPurposeService ?? '');
                    setadditionalDetails(result?.data?.additionalDetails ?? '');

                    setLoading(false);
                } else {
                    toast.error(result.data);
                    setLoading(false);
                    // setLoading(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading2(false);
            });
    }, [id]);

    const handletDateOfServicingChange = (newValue) => {
        setdateLastService(newValue);
    };

    const handlePreferredDateChange = (newValue) => {
        setpreferredAppointmentDate(newValue);
    };

    // const handleCarMakeChange = (event) => {
    //     setcar(event.target.value);
    //     retrieveAllCarModel(event.target.value);

    //     const newFilter = carData.filter((value) => (value._id || '').toLowerCase().includes((event.target.value || '').toLowerCase()));
    //     newFilter.forEach((option) => {
    //         setcarName(option.name);
    //     });
    // };

    // Submit Order
    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        const status = 'Awaiting Approval';
        setLoading2(true);
        const data = {
            id,
            channelPartnerId,
            carRegistrationNo,
            companyName,
            firstname,
            lastname,
            email,
            phoneNumber,
            dateLastService,
            preferredAppointmentDate,
            purposeLastService,
            currentPurposeService,
            additionalDetails,
            // car,
            carName,
            // carmodel,
            carmodelName,
            vinNo,
            status
        };

        fetch(ApiUrl.UpdateUrlsRequestServicingFleets, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading2(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/allrequestservicingfleet');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                }
            })
            .finally(() => {
                setLoading2(false);
            });
    };

    // const filterCarModel = (event) => {
    //     const newFilter = carmodelData.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
    //     newFilter.forEach((option) => {
    //         setcarmodel(option._id);
    //     });
    // };

    const handleApprovalStatusChange = (event) => {
        setstatus(event.target.value);
    };

    // const handleStatus = (event) => {
    //     setstatus(event.target.value);
    // };

    // function toTimestamp(strDate) {
    //     const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    //     const today = strDate ? new Date(strDate) : new Date();
    //     return today.toLocaleDateString('en-gh', options);
    // }

    return (
        <>
            <PageHeader title="Request for Servicing" subTitle="Home | View Request for Servicing" />

            {loading ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <MainCard title="">
                        <Typography variant="color" component="div" className={classes.color} color="warning">
                            REQUEST FOR SERVICING
                        </Typography>
                        <br />
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="firstname"
                                            variant="outlined"
                                            disabled
                                            id="firstname"
                                            label="First Name"
                                            value={firstname}
                                            onChange={(e) => setfirstname(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="lastname"
                                            variant="outlined"
                                            disabled
                                            id="lastname"
                                            label="Last Name"
                                            value={lastname}
                                            onChange={(e) => setlastname(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="email"
                                            type="email"
                                            variant="outlined"
                                            disabled
                                            id="email"
                                            label="Email"
                                            value={email}
                                            onChange={(e) => setemail(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="phoneNumber"
                                            variant="outlined"
                                            id="phoneNumber"
                                            disabled
                                            label="Phone Number"
                                            value={phoneNumber}
                                            onChange={(e) => setphoneNumber(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="carName"
                                            variant="outlined"
                                            id="carName"
                                            disabled
                                            label="Vehicle make"
                                            value={carName}
                                            onChange={(e) => {
                                                setcarName(e.target.value);
                                            }}
                                        />
                                        {/* <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Vehicle Make *</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={car}
                                                required
                                                disabled
                                                label="Vehicle Make *"
                                                onChange={handleCarMakeChange}
                                            >
                                                {carData.map((cars) => (
                                                    <MenuItem key={cars._id} value={cars._id}>
                                                        {cars.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl> */}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="carmodelName"
                                            variant="outlined"
                                            id="carmodelName"
                                            disabled
                                            label="Model Name"
                                            value={carmodelName}
                                            onChange={(e) => {
                                                setcarmodelName(e.target.value);
                                            }}
                                        />
                                        {/* <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            disabled
                                            value={carmodelName}
                                            onChange={(event, newValue) => {
                                                filterCarModel(newValue);
                                                setcarmodelName(newValue);
                                            }}
                                            options={carmodelData.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Vehicle Model *" />}
                                        /> */}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="carRegistrationNo"
                                            variant="outlined"
                                            id="carRegistrationNo"
                                            disabled
                                            label="Registration No."
                                            value={carRegistrationNo}
                                            onChange={(e) => setcarRegistrationNo(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="vinNo"
                                            variant="outlined"
                                            disabled
                                            id="vinNo"
                                            label="VIN"
                                            value={vinNo}
                                            onChange={(e) => setvinNo(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                label="Date Of Last Servicing"
                                                inputFormat="MM/dd/yyyy"
                                                disabled
                                                value={dateLastService}
                                                onChange={handletDateOfServicingChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="purposeLastService"
                                            variant="outlined"
                                            id="purposeLastService"
                                            disabled
                                            label="Purpose for last servicing"
                                            value={purposeLastService}
                                            onChange={(e) => setpurposeLastService(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                label="Preferred Appointment Date"
                                                inputFormat="MM/dd/yyyy"
                                                disabled
                                                value={preferredAppointmentDate}
                                                onChange={handlePreferredDateChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="currentPurposeService"
                                            variant="outlined"
                                            disabled
                                            id="currentPurposeService"
                                            label="Current Complaint/Purpose of servicing"
                                            value={currentPurposeService}
                                            onChange={(e) => setcurrentPurposeService(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Approval Status</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={status}
                                                required
                                                disabled
                                                label="Status"
                                                onChange={handleApprovalStatusChange}
                                            >
                                                <MenuItem value="">Select an option</MenuItem>
                                                <MenuItem value="Approved">Approved</MenuItem>
                                                <MenuItem value="Denied">Denied</MenuItem>
                                                <MenuItem value="Awaiting Approval">Awaiting Approval</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={11.5}>
                                    <TextField
                                        autoComplete="off"
                                        name="additionalDetails"
                                        disabled
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        fullWidth
                                        id="additionalDetails"
                                        label="Additional Details"
                                        value={additionalDetails}
                                        onChange={(e) => setadditionalDetails(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <br />

                            <Box display="flex" justifyContent="space-between">
                                <Button component={Link} to="/allrequestservicingfleet" type="submit" variant="contained" color="error">
                                    Cancel
                                </Button>
                                {/* <Button type="submit" variant="contained" className={classes.backgroundColor} disabled={!car || !carmodel}>
                                    {loading2 ? 'Updating ..' : 'Update'}
                                </Button> */}
                            </Box>
                        </form>
                    </MainCard>
                </>
            )}
        </>
    );
};
export default AllViewRequestServicingFleet;
