import { Button, Stack } from '@mui/material';
import { Col, Row } from 'react-grid-system';
import { Delete, ModeEdit } from '@mui/icons-material';
import { Form, InputNumber, Modal, Select } from 'antd';
import { detectCurrencyType, wait } from 'utils/functions';
import { errorToast, successToast } from 'utils/toast';
import { useEffect, useState } from 'react';

import IconButton from '@mui/material/IconButton';
import { authFetch } from 'utils/axios-config';
import { useAppContext } from 'context/appContext';
import { useBatchContext } from 'context/order-batching/batchContext';
import { useParams, useNavigate } from 'react-router';

const DetailsTable = ({ ordersDetails }) => {
    const history = useNavigate();
    const { getAllBatchOrders, orderType } = useBatchContext();
    const { id } = useParams();
    const { userCurrency } = useAppContext();
    const [suppliers, setSuppliers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [batchStatuses, setBatchStatuses] = useState([]);
    const [batchDrivers, setBatchDrivers] = useState([]);
    const [orders, setOrders] = useState(ordersDetails);
    const [order, setOrderDetails] = useState({});
    const [supplierId, setSupplierId] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [sellingPrice, setSellingPrice] = useState(null);
    const [returnedQty, setReturnedQty] = useState(0);
    const [costPrice, setCostPrice] = useState(0);
    const [size, setSize] = useState('Pieces');
    const [batchStatusId, setBatchStatusId] = useState(null);
    const [batchDriverId, setBatchDriverId] = useState(null);
    const [removedOrderDetails, setRemovedOrderDetails] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const getSuppliers = async () => {
        try {
            const { data } = await authFetch.get('/orderbatch/batchsuppliers');
            setSuppliers(data.data);
        } catch (error) {
            if (error.response.status !== 401) {
                console.log(error);
            }
        }
    };

    const getAllBatchStatuses = async () => {
        try {
            const { data } = await authFetch.get('/orderbatch/batchstatuses');
            setBatchStatuses(data.data);
        } catch (error) {
            if (error.response.status !== 401) {
                console.log(error);
            }
        }
    };

    const getAllBatchDrivers = async () => {
        try {
            const { data } = await authFetch.get('/orderbatch/batchdrivers');
            const updatedDrivers = data.data.map((driver) => {
                return {
                    ...driver,
                    name: `${driver?.firstName} ${driver?.lastName}`
                };
            });
            setBatchDrivers(updatedDrivers);
        } catch (error) {
            if (error.response.status !== 401) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        getSuppliers();
        getAllBatchStatuses();
        getAllBatchDrivers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOrderEdit = (orderId) => {
        // when user clicks on edit icon!!! previous details saved to state
        showModal();
        const order = orders.find((order) => order._id === orderId);
        setOrderDetails(order);
        setSupplierId(order?.supplierId);
        setQuantity(order?.qty);
        setCostPrice(order?.costPrice);
        setSellingPrice(order?.salesPrice);
        setSize(order?.partType);
    };

    const handleOk = () => {
        const updatedOrders = orders.map((curOrder) => {
            if (curOrder?._id === order?._id) {
                const extractSupplierName = suppliers.find((supplier) => supplier._id === supplierId);
                return {
                    ...curOrder,
                    supplierId,
                    supplier: extractSupplierName?.partnerName,
                    qty: quantity,
                    partType: size,
                    costPrice,
                    salesPrice: sellingPrice,
                    totalcost: quantity * costPrice,
                    totalsales: quantity * sellingPrice,
                    returnQty: returnedQty
                };
            }
            return curOrder;
        });
        setOrders([...updatedOrders]);
        successToast(`Order updated successfully`);
        setIsModalOpen(false);
    };

    const handleOrderDelete = (orderToRemove) => {
        const newOrders = orders.filter((order) => order._id !== orderToRemove._id);
        setOrders([...newOrders]);
        setRemovedOrderDetails([...removedOrderDetails, orderToRemove]);
        successToast(`Order removed  successfully`);
    };

    const sizes = [
        { id: 1, name: 'Boxes' },
        { id: 2, name: 'Pieces' },
        { id: 3, name: 'Gallons' },
        { id: 4, name: 'Drums' },
        { id: 5, name: 'Rolls' },
        { id: 6, name: 'Bucket' },
        { id: 7, name: 'Packs' },
        { id: 8, name: 'Litre' },
        { id: 9, name: 'Pair' },
        { id: 10, name: 'Carton' }
    ];

    const payload = {
        batchid: id,
        driverId: batchDriverId,
        batchStatusId,
        ordersDetails: orders,
        removedOrderDetails,
        orderType
    };

    const updateDispatchBatch = async () => {
        setLoading(true);
        if (!batchStatusId) errorToast('Please select batch status');

        if (!batchDriverId) errorToast('Please select driver');
        try {
            await authFetch.put('/orderbatch/dispatchupdate', payload);
            successToast('Batch updated successfully');
            setLoading(false);
            wait(3).then(() => {
                getAllBatchOrders();
            });
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <>
            <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form layout="horizontal">
                        <Form.Item label="Select Batch Status" required>
                            <Select
                                placeholder="Batch Status"
                                showSearch
                                style={{
                                    width: '100%',
                                    margin: '0.625rem 0'
                                }}
                                size="large"
                                fieldNames={{ label: 'name', value: '_id' }}
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.name.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                    (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={[...batchStatuses]}
                                value={batchStatusId}
                                onChange={(value) => setBatchStatusId(value)}
                            />
                        </Form.Item>
                    </Form>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form layout="horizontal">
                        <Form.Item label="Select Batch Driver" required>
                            <Select
                                placeholder="Batch Driver"
                                showSearch
                                style={{
                                    width: '100%',
                                    margin: '0.625rem 0'
                                }}
                                size="large"
                                fieldNames={{ label: `name`, value: '_id' }}
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.name.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                    (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={[...batchDrivers]}
                                value={batchDriverId}
                                onChange={(value) => setBatchDriverId(value)}
                            />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <div className="table-responsive">
                <table className="table  table-hover" style={{ fontSize: '0.875rem' }}>
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">OrderNo.</th>
                            <th scope="col">Sales Agent</th>
                            <th scope="col">Item</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Cost Price</th>
                            <th scope="col">Selling Price</th>
                            <th scope="col">Total CostPrice</th>
                            <th scope="col">Total SalePrice</th>
                            <th scope="col">ReturnedQty</th>
                            <th scope="col">Size</th>
                            <th scope="col">Supplier</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders &&
                            orders?.map((order, index) => {
                                const {
                                    _id,
                                    orderNo,
                                    partid,
                                    qty,
                                    costPrice,
                                    supplier,
                                    partType,
                                    salesperson,
                                    returnQty,
                                    salesPrice,
                                    totalcost,
                                    totalsales
                                } = order;
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{orderNo}</td>
                                        <td>{salesperson}</td>
                                        <td>{partid}</td>
                                        <td>{qty}</td>
                                        <td>{detectCurrencyType(costPrice)}</td>
                                        <td>{detectCurrencyType(salesPrice)}</td>
                                        <td>{detectCurrencyType(totalcost)}</td>
                                        <td>{detectCurrencyType(totalsales)}</td>
                                        <td>{returnQty}</td>
                                        <td>{partType}</td>
                                        <td>{supplier}</td>
                                        <td>
                                            <Stack direction="row" spacing={0}>
                                                <IconButton
                                                    aria-label="delete order form batch"
                                                    onClick={() => handleOrderDelete(order)}
                                                    title="Remove order from batch"
                                                >
                                                    <Delete style={{ color: '#DD4A48' }} />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="edit order"
                                                    onClick={() => handleOrderEdit(_id)}
                                                    title="Edit Order "
                                                >
                                                    <ModeEdit style={{ color: '#4E9525' }} />
                                                </IconButton>
                                            </Stack>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
            <br />
            <div className="go-back" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button variant="contained" onClick={() => history(-1)} style={{ backgroundColor: ' #ff740f' }}>
                    &larr; Go back
                </Button>
                <Button variant="contained" style={{ backgroundColor: ' #4E9525' }} onClick={updateDispatchBatch} disabled={loading}>
                    {loading ? 'Submitting...' : 'UPDATE BATCH NOW'}
                </Button>
            </div>

            <Modal title="Update Order Information" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} className="mt-3">
                <Form layout="vertical">
                    <Form.Item label="Choose Supplier">
                        <Select
                            placeholder="Select Supplier"
                            showSearch
                            style={{
                                width: '100%',
                                margin: '0.625rem 0'
                            }}
                            size="large"
                            fieldNames={{ label: 'partnerName', value: '_id' }}
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.partnerName.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                                (optionA?.partnerName ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={[...suppliers]}
                            value={supplierId}
                            onChange={(value) => setSupplierId(value)}
                        />
                    </Form.Item>
                    <Form.Item label="Quantity">
                        <InputNumber
                            min={1}
                            size="large"
                            value={quantity}
                            onChange={(value) => setQuantity(value)}
                            style={{
                                width: '100%',
                                margin: '0.625rem 0'
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Select Part Size">
                        <Select
                            placeholder="Part Size"
                            showSearch
                            style={{
                                width: '100%',
                                margin: '0.625rem 0'
                            }}
                            size="large"
                            fieldNames={{ label: 'name', value: 'name' }}
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.name.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={[...sizes]}
                            value={size}
                            onChange={(value) => setSize(value)}
                        />
                    </Form.Item>
                    <Form.Item label={`Cost Price (${userCurrency})`}>
                        <InputNumber
                            min={1}
                            size="large"
                            value={costPrice}
                            onChange={(value) => setCostPrice(value)}
                            style={{
                                width: '100%',
                                margin: '0.625rem 0'
                            }}
                        />
                    </Form.Item>
                    <Form.Item label={`Selling Price (${userCurrency})`}>
                        <InputNumber
                            min={1}
                            size="large"
                            value={sellingPrice}
                            onChange={(value) => setSellingPrice(value)}
                            style={{
                                width: '100%',
                                margin: '0.625rem 0'
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Returned Qty">
                        <InputNumber
                            min={1}
                            size="large"
                            value={returnedQty}
                            onChange={(value) => setReturnedQty(value)}
                            style={{
                                width: '100%',
                                margin: '0.625rem 0'
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default DetailsTable;
