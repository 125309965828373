import React, { useEffect, useState, useRef } from 'react';
import { Select, Input, Button, Space, Result } from 'antd';
import { encrypt, decrypt } from '../../utils/encrypt';
import { ToastContainer, toast } from 'react-toastify';
import './attendance.css';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Webcam from 'react-webcam';
import Divider from '@mui/material/Divider';
import User1 from '../../assets/images/users/user-round.svg';
import { Avatar } from '@mui/material';
import ApiUrl from 'network';

const CheckIn = () => {
    const [area, setArea] = useState([]);
    const [show, setShow] = useState(true);
    const [showCheckIn, setShowCheckIn] = useState(false);
    const [where, setWhere] = useState('');
    const [ipAddress, setIpAddress] = useState('');
    const [workingFromWhere, setWorkingFromWhere] = useState(false);
    const [lat, setLat] = useState('');
    const [lng, setLng] = useState('');
    const [selectedValue, setSelectedValue] = useState([]);
    const [textAreaValue, setTextAreaValue] = useState('');
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const userFirstName = localStorage.getItem('userFirstName');
    const userLastName = localStorage.getItem('userLastName');
    const { TextArea } = Input;
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [openCamera, setOpenCamera] = useState(null);
    const [checkInStatus, setCheckInStatus] = useState(null);
    const [checkedIn, setCheckedIn] = useState(false);
    const [checkInForm, setCheckInForm] = useState(null);
    const webcamRef = useRef(null);
    const photo = localStorage.getItem('photo');

    // You can test for the presence of geolocation thusly:
    const getUserGeoLocation = () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                },
                (error) => {
                    console.log(error.message);
                }
            );
        } else {
            console.error('Your browser does not support geolocation');
        }
    };

    useEffect(() => {
        getUserGeoLocation();
    }, []);

    // const handleFileChange = (event) => {
    //     const file = event.target.files[0];
    //     setSelectedFile(file);
    //     console.log(file);
    // };

    // const handleUploadButtonClick = () => {
    //     // Trigger the file input click when the button is clicked
    //     fileInputRef.current.click();
    //     setOpenCamera(false);
    // };

    // const capture = () => {
    //     const imageSrc = webcamRef.current.getScreenshot();
    //     setImageSrc(imageSrc);
    // };

    // const handleOpenCamera = () => {
    //     setOpenCamera(!openCamera);
    // };

    // const [present, setPresent] = useState(false);

    // get users location and measure distance between area
    function geoFindMe(pos) {
        const crd = pos.coords;
        setLat(crd.latitude);
        setLng(crd.longitude);
    }

    function err(err) {
        console.error(`ERROR(${err.code}): ${err.message}`);
    }

    const option = {
        enableHighAccuracy: false,
        maximumAge: 0
    };

    navigator.geolocation.watchPosition(geoFindMe, err, option);

    // get Ip address
    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then((response) => response.json())
            .then((data) => {
                const ipAddress = data.ip;
                setIpAddress(ipAddress);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    // get check in status
    const checkinStatus = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(`${ApiUrl.BaseUrl}/checkin/usercheckinout`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setCheckInStatus(answer?.checkInstatus);
                        setCheckInForm(true);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                        console.log(answer);
                        setCheckInForm(false);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        checkinStatus();
    }, [checkInStatus, checkedIn]);

    // get area locations
    useEffect(() => {
        const checkin = async () => {
            try {
                const userInfo = localStorage.getItem('userToken');
                const config = {
                    headers: {
                        'auth-token': userInfo,
                        'Content-Type': 'application/json'
                    }
                };
                fetch(`${ApiUrl.BaseUrl}/checkin/checkinlocation`, config)
                    .then((data) => data.json())
                    .then((data) => {
                        if (data.status === true) {
                            const result = decrypt(data.data, keyResult, ivResult);
                            const answer = JSON.parse(result);
                            setArea(answer);
                        } else {
                            const error = decrypt(data.data, keyResult, ivResult);
                            const answer = JSON.parse(error);
                        }
                    })
                    .finally(() => {
                        // setLoading(false);
                    });
            } catch (e) {
                console.log(e);
            }
        };
        checkin();
    }, []);

    const areaOptions = area.map((result) => ({
        value: result._id,
        label: result.name
    }));

    const areaName = areaOptions.filter((area) => {
        return area.value === selectedValue;
    });

    const selectArea = areaName.map((item) => item.label);

    const areaSelection = area.filter((item) => {
        return item.name === selectArea.toString() ? item : null;
    });

    // send check in details
    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        try {
            const data = {
                location: where,
                ip: ipAddress,
                latitude: +lat,
                longitude: +lng,
                reason: textAreaValue
            };
            const obj = JSON.stringify(data);
            const result = encrypt(obj, keyResult, ivResult);

            fetch(`${ApiUrl.BaseUrl}/checkin/checkins`, {
                method: 'POST',
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ payload: result })
            })
                .then((res) => res.json())
                .then((result) => {
                    if (result.status === true) {
                        setCheckedIn(true);
                        setWorkingFromWhere(false);
                        toast.success(result.message);
                        toast.success(`Congratulations, you've checked in at ${areaSelection[0]?.name}`);
                    } else if (result.status === false) {
                        const error = decrypt(result.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                        toast.error(answer);
                        setCheckedIn(false);
                    } else {
                        const error = decrypt(result.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                        toast.error(answer);
                        setCheckedIn(false);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (error) {
            console.log(error);
        }
    };

    const onChangeTextArea = (e) => {
        setTextAreaValue((prev) => (prev = e.target.value));
    };

    // select location
    const handleSelectChange = (value) => {
        setSelectedValue(value);
        setShow(false);
        setWhere(value);
        setShowCheckIn(true);

        if (value === '652445e06cfc03111b2af5fc' || value === '652446096cfc03111b2af684') {
            setWorkingFromWhere(true);
        } else {
            setWorkingFromWhere(false);
        }
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="check-in-wrapper">
                <div className="check-in-container">
                    {checkInStatus === true || checkedIn === true ? (
                        <Result status="success" title={`Hello, ${userFirstName}. You have Successfully Checked In!`} />
                    ) : (
                        <>
                            {checkInForm && (
                                <section>
                                    <h1>Hi {userFirstName} 👋🏽, Where are you working from today?</h1>

                                    <h5>Name</h5>
                                    <Input
                                        placeholder="Name"
                                        value={` ${userFirstName} ${userLastName}`}
                                        disabled
                                        style={{
                                            width: '100%',
                                            margin: '0 0 20px 0px'
                                        }}
                                        size="large"
                                    />

                                    <h5>Select Location</h5>
                                    <Select
                                        placeholder="Select Location"
                                        showSearch
                                        style={{
                                            width: '100%',
                                            margin: '0 0 20px 0px'
                                        }}
                                        size="large"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={areaOptions}
                                        allowClear
                                        onChange={handleSelectChange}
                                        value={selectedValue}
                                    />
                                    {workingFromWhere ? (
                                        <div className="text-area-div">
                                            <h5>Hi, why are you working from home? </h5>
                                            <TextArea
                                                rows={4}
                                                placeholder="Please, briefly state a reason"
                                                style={{
                                                    width: '100%',
                                                    margin: '0 0 20px 0px'
                                                }}
                                                value={textAreaValue}
                                                onChange={onChangeTextArea}
                                                required
                                            />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {showCheckIn ? (
                                        <>
                                            {checkInStatus === true || checkedIn === true ? (
                                                <Button disabled type="primary" size="large">
                                                    Checked In
                                                </Button>
                                            ) : (
                                                <Button onClick={handleSubmit} type="primary" size="large">
                                                    Check In
                                                </Button>
                                            )}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </section>
                            )}
                        </>
                    )}
                    <div className="image-container">
                        {/* <p>Upload Image</p>
                    <CloudUploadIcon />
                    <input type="file" accept="image/*" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
                    <Space wrap>
                        <Button size="large" type="primary" onClick={handleUploadButtonClick}>
                            Browse files
                        </Button>
                        <Divider variant="middle" />
                        <Button size="large" type="primary" onClick={handleOpenCamera}>
                            Open Camera
                        </Button>
                    </Space> */}
                        {/* {selectedFile && <img src={URL.createObjectURL(selectedFile)} alt="Selected" />} */}
                        {/* <img src={photo} alt="Selected" style={{ width: '100%', height: '100%' }} /> */}
                        <Avatar src={photo != null ? photo : User1} sx={{ width: '100%', height: '100%' }} />
                    </div>
                </div>
                {/* <div>
                    {openCamera && (
                        <>
                            <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
                            <Button size="large" type="primary" onClick={capture}>
                                Capture Photo
                            </Button>
                            {imageSrc && <img src={imageSrc} alt="Captured" />}
                        </>
                    )}
                </div> */}
            </div>
        </>
    );
};

export default CheckIn;
