import styled from 'styled-components';

const Wrapper = styled.div`
    max-width: 400px;
    padding: 0.325rem 0.625rem;
    border: 1px solid #8b8b8b;
    border-radius: 10px;
    background: #eee;
    color: #222;
    margin-bottom: 20px;

    .label {
        font-size: 0.625rem;
        color: #444;
    }
`;

const SurroundText = ({ content, label }) => {
    return (
        <Wrapper>
            <small className="label">{label}</small>
            <p>{content}</p>
        </Wrapper>
    );
};
export default SurroundText;
