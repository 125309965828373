import { Button, Input, Select } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { decrypt, encrypt } from '../../../utils/encrypt';
import ApiUrl from '../../../network';
import { toast } from 'react-toastify';

export const Edit = () => {
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [customer, setCustomer] = useState('');
    const [amtPaid, setAmtPaid] = useState('');
    const [amtDue, setAmtDue] = useState('');
    const [description, setDescription] = useState('');
    const [rma, setRma] = useState('');
    const [rmaCc, setRmaCc] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [credittrackerid, setCreditTrackerId] = useState('');
    const [defaultstatus, setDefaultStatus] = useState('');
    const [creditTrackerData, setCreditTrackerData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    const fetchRmaCc = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            await fetch(`${ApiUrl.BaseUrl}/epl/eplcustomersrmacc`, {
                method: 'GET',
                headers: config.headers
            })
                .then((data) => data.json())
                .then((rmaccData) => {
                    if (rmaccData.message === 'Success') {
                        const decryptedRmaccData = JSON.parse(decrypt(rmaccData.data, keyResult, ivResult));
                        setRmaCc(decryptedRmaccData.rmcc);
                        setCustomerData(decryptedRmaccData.customer);
                    } else {
                        toast.error(rmaccData.data);
                    }
                });
        } catch (error) {
            toast.error('Error occurred while fetching RMA');
        }
    };

    const fetchPaymentDetails = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/epl/viewcreditpayment/${id}`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setCustomer(decryptedData.customergh?.partnerName);
                        setAmtPaid(decryptedData.amtPaid);
                        setDescription(decryptedData.description);
                        setRma(`${decryptedData.rmacc?.firstName} ${decryptedData.rmacc?.lastName}`);
                        setCreditTrackerId(decryptedData?.creditTrackerId?.creditno);
                        setDefaultStatus(decryptedData.defaultstatus);
                        setAmtDue(decryptedData.amtDue);
                    } else {
                        toast.error(data.message);
                    }
                });
        } catch (error) {
            toast.error(error.message);
        }
    };

    const fetchAllData = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const creditUrl = `${ApiUrl.BaseUrl}/epl/credittrackerdata`;
            const creditResponse = await fetch(creditUrl, config);
            const creditData = await creditResponse.json();
            if (creditData.message === 'success') {
                const decryptedCreditData = JSON.parse(decrypt(creditData.data, keyResult, ivResult));
                setCreditTrackerData(decryptedCreditData);
            } else {
                toast.error(creditData.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        fetchPaymentDetails();
        fetchRmaCc();
        fetchAllData();
    }, []);

    const rmaOptions = rmaCc.map((rmacc) => {
        return {
            value: rmacc._id,
            label: `${rmacc.firstName} ${rmacc.lastName}`
        };
    });

    const customerOptions = customerData.map((customer) => {
        return {
            value: customer._id,
            label: customer.partnerName
        };
    });

    const creditOptions = creditTrackerData.map((credit) => {
        return {
            value: credit._id,
            label: credit.creditno
        };
    });

    const updatePaymentHandler = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            // checks to get ids
            const checkCustomerID = customerData.find((item) => item.partnerName === customer);
            const checkRmaID = rmaCc.find((item) => `${item.firstName} ${item.lastName}` === rma);
            const credittrackerID = creditTrackerData.find((item) => item.creditno === credittrackerid);

            const datas = {
                id,
                customer: checkCustomerID?._id || customer,
                amtPaid,
                description,
                rmcc: checkRmaID?._id || rma,
                credittrackerid: credittrackerID?._id || credittrackerid,
                defaultstatus
            };

            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            await fetch(`${ApiUrl.BaseUrl}/epl/updateeplcreditpayment`, {
                method: 'PUT',
                headers: config.headers,
                body: JSON.stringify({ payload })
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setLoading(false);
                        toast.success(data.message);
                        fetchPaymentDetails();
                    } else {
                        const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setLoading(false);
                        toast.error(decryptedData);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
            toast.error('Error occurred while adding credit');
        }
    };

    return (
        <div className="easy-pay-container">
            <div className="header">
                <Link className="d-flex align-items-center" to="/epl/easy-pay-later" style={{ color: '#0A2938' }}>
                    <ArrowLeftOutlined className="font-20" />
                    <h1 className="font-30 ml-2 mt-2">Edit details</h1>
                </Link>
                <Button
                    loading={loading}
                    onClick={() => {
                        updatePaymentHandler();
                    }}
                    style={{ backgroundColor: '#FF740F', color: '#fff', width: 'fit-content' }}
                >
                    Save changes
                </Button>
            </div>
            <hr className="divider" />
            <div className="content">
                <div className="bg-white add-inputs-container">
                    <div className="form-group">
                        <span>Customer</span>
                        <Select
                            disabled
                            value={customer}
                            onChange={(e) => setCustomer(e)}
                            size="middle"
                            className="mt-2"
                            showSearch
                            allowClear
                            options={customerOptions}
                            defaultValue="Select option"
                            style={{ width: '100%' }}
                            filterOption={(inputValue, option) => {
                                if (typeof option.label === 'string') {
                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }

                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <span>
                            Amount paid ( Amount Due: <span className="text-danger">{amtDue}</span> )
                        </span>
                        <Input
                            value={amtPaid}
                            onChange={(e) => setAmtPaid(e.target.value)}
                            type="text"
                            className="form-control mt-2"
                            id="amount"
                        />
                    </div>
                    <div className="form-group">
                        <span>Description</span>
                        <Input
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            type="text"
                            className="form-control mt-2"
                            id="description"
                        />
                    </div>
                    <div className="form-group">
                        <span>RMA/CC Direct contact</span>
                        <Select
                            value={rma}
                            onChange={(e) => setRma(e)}
                            size="middle"
                            className="mt-2"
                            showSearch
                            allowClear
                            options={rmaOptions}
                            defaultValue="Select option"
                            style={{ width: '100%' }}
                            filterOption={(inputValue, option) => {
                                if (typeof option.label === 'string') {
                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }

                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <span>
                            Credit <span className="text-danger">*</span>
                        </span>
                        <Select
                            disabled
                            value={credittrackerid}
                            onChange={(e) => setCreditTrackerId(e)}
                            size="middle"
                            className="mt-2"
                            showSearch
                            allowClear
                            options={creditOptions}
                            defaultValue="Select option"
                            style={{ width: '100%' }}
                            filterOption={(inputValue, option) => {
                                if (typeof option.label === 'string') {
                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }

                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <span>Default Status</span>
                        <Select
                            disabled
                            value={defaultstatus}
                            onChange={(e) => setDefaultStatus(e)}
                            size="middle"
                            className="mt-2"
                            showSearch
                            allowClear
                            options={[
                                {
                                    value: 'Defaulted',
                                    label: 'Defaulted'
                                },
                                {
                                    value: 'Not Defaulted',
                                    label: 'Not Defaulted'
                                }
                            ]}
                            defaultValue="Select option"
                            style={{ width: '100%' }}
                            filterOption={(inputValue, option) => {
                                if (typeof option.label === 'string') {
                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }

                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Edit;
