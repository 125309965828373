import React from 'react';
import { LeftOutlined, FieldTimeOutlined, DownOutlined } from '@ant-design/icons';
import { Tag, Button, Dropdown } from 'antd';

const ChatBoxHeader = ({ selectedUser, showSenderList, expiredAt, timeRemaining, menu }) => {
    return (
        <div className="chat-box-header">
            <div className="chat-box-header-content">
                <span className="back">
                    <LeftOutlined onClick={showSenderList} />
                </span>
                <div className="sender-avatar">{selectedUser?.profile?.slice(0, 2).toUpperCase()}</div>
                <div className="ml-5">
                    <p className="user-name">
                        <strong>{selectedUser?.profile?.slice(0, 15) || 'Loading...'}</strong>
                    </p>
                    <div className="d-flex flex-column">
                        {/* <small className="senderMessage">{selectedUser?.phone}</small> */}
                        {expiredAt && (
                            <Tag style={{ color: '#FA3246', backgroundColor: '#FFEBED', border: 'none' }}>
                                <FieldTimeOutlined style={{ marginRight: '2px', marginBottom: '2px' }} />
                                {timeRemaining}
                            </Tag>
                        )}
                    </div>
                </div>
            </div>
            <div className="customer-option-container">
                <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                    <Button
                        style={{
                            backgroundColor: '#0A2938',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '8px',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        Actions <DownOutlined style={{ marginLeft: '5px', fontSize: '14px', marginTop: '3px' }} />
                    </Button>
                </Dropdown>
            </div>
        </div>
    );
};

export default ChatBoxHeader;
