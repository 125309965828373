import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Link, useNavigate, useParams, useHistory } from 'react-router-dom';
import WestIcon from '@mui/icons-material/West';
import { Button, Select, Input } from 'antd';
import { encrypt, decrypt } from '../../utils/encrypt';
import { ToastContainer, toast } from 'react-toastify';
import ApiUrl from '../../network';

const AddVehicles = () => {
    const [location, setLocation] = useState('');
    const [vehicleNumber, setVehicleNumber] = useState('');
    const [vehicleType, setVehicleType] = useState('');
    const [fuelTankCapacity, setFuelTankCapacity] = useState('');
    const [engineCapacity, setEngineCapacity] = useState('');
    const [fuelTankCost, setFuelTankCost] = useState([]);
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');

    const navigate = useNavigate();

    // Assign an order to a driver
    const AddVehicle = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');

        const data = {
            vehicleNumber,
            vehicleType,
            fuelTankCapacity,
            engineCapacity,
            fuelTankCost,
            location
        };
        const obj = JSON.stringify(data);
        const result = encrypt(obj, keyResult, ivResult);
        fetch(`${ApiUrl.BaseUrl}/salesplanning/addvehicles`, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ payload: result })
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                } else if (result.status === false) {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                } else {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                }
            })
            .finally(() => {
                // setLoading(false);
                setVehicleNumber('');
                setVehicleType('');
                setEngineCapacity('');
                setFuelTankCapacity('');
                setFuelTankCost('');
                setLocation('');
            });
    };

    return (
        <div>
            {' '}
            <List
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    padding: '0 0 16px 0',
                    borderBottom: '1px dashed #E7EAEB',
                    alignItems: 'flex-start',
                    margin: '0px auto 15px'
                }}
            >
                <WestIcon sx={{ fontSize: '32px', cursor: 'pointer' }} onClick={() => navigate(-1)} />

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginLeft: '24px'
                    }}
                >
                    <ListItem
                        sx={{
                            fontSize: '1.875rem',
                            color: '#0A2938',
                            padding: 0,
                            fontWeight: 900,
                            marginBottom: '7px'
                        }}
                    >
                        Add Vehicle
                    </ListItem>
                </Box>
            </List>
            <div className="fleet-form-wrapper">
                <section className="fleet-select-input">
                    <Box
                        sx={{
                            width: ' 277px',
                            margin: '24px 0 0 24px'
                        }}
                    >
                        <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974', margin: '0 0 4px 0' }}>
                            Vehicle type
                        </Typography>
                        <Input
                            style={{
                                width: '100%'
                            }}
                            size="large"
                            allowClear
                            placeholder="vehicle type"
                            value={vehicleType}
                            onChange={(e) => {
                                setVehicleType(e.target.value);
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: ' 277px',
                            margin: '24px 0 0 24px'
                        }}
                    >
                        <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974', margin: '0 0 4px 0' }}>
                            Vehicle number
                        </Typography>
                        <Input
                            style={{
                                width: '100%',
                                border: '1px solid #E7EAEB'
                            }}
                            size="large"
                            placeholder="Vehicle number"
                            allowClear
                            value={vehicleNumber}
                            onChange={(e) => {
                                setVehicleNumber(e.target.value);
                            }}
                        />
                    </Box>

                    <Box
                        sx={{
                            width: ' 277px',
                            margin: '24px 0 0 24px'
                        }}
                    >
                        <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974', margin: '0 0 4px 0' }}>
                            Fuel tank capacity (L)
                        </Typography>
                        <Input
                            size="large"
                            allowClear
                            style={{
                                width: '100%'
                            }}
                            placeholder="Fuel tank capacity"
                            type="number"
                            value={fuelTankCapacity}
                            onChange={(e) => {
                                setFuelTankCapacity(e.target.value.trim());
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: ' 277px',
                            margin: '24px 0 0 24px'
                        }}
                    >
                        <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974', margin: '0 0 4px 0' }}>
                            Engine capacity (L)
                        </Typography>
                        <Input
                            style={{
                                width: '100%'
                            }}
                            size="large"
                            allowClear
                            placeholder="Engine capacity"
                            type="number"
                            value={engineCapacity}
                            onChange={(e) => {
                                setEngineCapacity(e.target.value.trim());
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: ' 277px',
                            margin: '24px 0 0 24px'
                        }}
                    >
                        <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974', margin: '0 0 4px 0' }}>
                            Fuel tank cost per/L
                        </Typography>
                        <Input
                            style={{
                                width: '100%'
                            }}
                            size="large"
                            placeholder="Fuel tank cost"
                            allowClear
                            type="number"
                            value={fuelTankCost}
                            onChange={(e) => {
                                setFuelTankCost(e.target.value.trim());
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: ' 277px',
                            margin: '24px 0 0 24px'
                        }}
                    >
                        <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974', margin: '0 0 4px 0' }}>Location</Typography>
                        <Input
                            style={{
                                width: '100%'
                            }}
                            size="large"
                            allowClear
                            placeholder="location"
                            value={location}
                            onChange={(e) => {
                                setLocation(e.target.value);
                            }}
                        />
                    </Box>
                </section>
                <section className="fleet-form-buttons">
                    <Button
                        style={{
                            backgroundColor: '#FFFFFF',
                            borderRadius: '8px',
                            height: '40px',
                            fontSize: '16px',
                            fontWeight: '500',
                            color: '#0A2938',
                            width: '120px',
                            textAlign: 'center',
                            margin: '0.625rem',
                            border: '1px solid #CED4D7'
                        }}
                        onClick={() => {
                            setVehicleNumber('');
                            setVehicleType('');
                            setEngineCapacity('');
                            setFuelTankCapacity('');
                            setFuelTankCost('');
                            setLocation('');
                        }}
                    >
                        Cancel{' '}
                    </Button>
                    <Button
                        style={{
                            backgroundColor: '#FF740F',
                            borderRadius: '8px',
                            height: '40px',
                            fontSize: '16px',
                            fontWeight: '500',
                            color: '#FFFFFF',
                            width: '120px',
                            textAlign: 'center',
                            margin: '0.625rem'
                        }}
                        onClick={AddVehicle}
                    >
                        Assign
                    </Button>
                </section>
            </div>
        </div>
    );
};

export default AddVehicles;
