// import dashboard from './dashboard';

import driver from './driver';
import other from './other';

// ==============================|| MENU ITEMS dashboard ||============================== //

const driverIndexItems = {
    items: [driver, other]
};

export default driverIndexItems;
