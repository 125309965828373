/* eslint-disable no-underscore-dangle */
/* eslint-disable no-empty */
import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import { makeStyles } from '@mui/styles';
// material-ui
import { Grid } from '@mui/material';
import ApiUrl from 'network';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
// import { gridSpacing } from 'store/constant';
import Button from '@mui/material/Button';
import TotalOrders from './totalorderscard';
import TotalFulfilledOrdersCard from './totalfulfilledorderscard';
import TotalGeneratedOrdersCard from './totalgeneratedorderscard';
import TotalCancelledOrdersCard from './totalcancelledorderscard';
import TotalUnfulfilledOrdersCard from './totalunfulfilledorders';
import TotalProformaOrdersCard from './totalproformaorderscard';
import TotalRegularOrderCard from './totalregularorders';
import TotalSalesOrderCard from './totalsalesorders';
import TotalDarkStoreOrdersCard from './totaldarkstoresorders';
import TotalOnHoldOrderCard from './totalonholdorderscard';
import TotalNextDayOrderCard from './totalnextdayorderscard';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    colors: {
        color: `#ED7C1B`
    }
}));

const OrderReport = () => {
    // const history = useNavigate();
    const classes = useStyles();
    const [status, setStatus] = useState('today');
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [isLoading, setLoading] = useState(false);
    // const userRole = localStorage.getItem('userRole');
    // const userCurrency = localStorage.getItem('userCurrency');
    const [totalorders, settotalorders] = useState([]);
    const [totalordersrange, settotalordersrange] = useState([]);
    const [dateRangeTitle, setdateRangeTitle] = useState('');
    const [todayfulfilledorders, settodayfulfilledorders] = useState([]);
    const [daterangetotalfulfilledorders, setdaterangetotalfulfilledorders] = useState([]);
    const [todaygeneratedorders, settodaygeneratedorders] = useState([]);
    const [daterangetotalgeneratedorders, setdaterangetotalgeneratedorders] = useState([]);
    const [todaycancelledorders, settodaycancelledorders] = useState([]);
    const [daterangetotalcancelledorders, setdaterangetotalcancelledorders] = useState([]);
    const [todayunfulfilledorders, settodayunfulfilledorders] = useState([]);
    const [daterangetotalunfulfilledorders, setdaterangetotalunfulfilledorders] = useState([]);
    const [todayproformaorders, settodayproformaorders] = useState([]);
    const [daterangetotalproformaorders, setdaterangetotalproformaorders] = useState([]);

    const [todayregularorders, settodayregularorders] = useState([]);
    const [todaysalesorders, settodaysalesorders] = useState([]);
    const [todaydarkstoreorders, settodaydarkstoreorders] = useState([]);

    const [daterangeregularorders, setdaterangeregularorders] = useState([]);
    const [daterangesalesorders, setdaterangesalesorders] = useState([]);
    const [daterangedarkstoreorders, setdaterangedarkstoreorders] = useState([]);

    const [todayNextDayorders, settodayNextDayorders] = useState([]);
    const [todayOnHoldorders, settodayOnHoldorders] = useState([]);

    const [daterangeOnHoldorders, setdaterangeOnHoldorders] = useState([]);
    const [daterangeNextDayorders, setdaterangeNextDayorders] = useState([]);

    const retrieveAllTodaysMatrix = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.ReportTodaysUrls, config)
                .then((data) => data.json())
                .then((data) => {
                    // console.log('result', data.todayfulfilledorders);
                    settotalorders(data?.todayorders ?? []);
                    settodayfulfilledorders(data?.todayfulfilledorders ?? []);
                    settodaygeneratedorders(data?.todaygeneratedorders ?? []);
                    settodaycancelledorders(data?.todaycancelledorders ?? []);
                    settodayunfulfilledorders(data?.todayunfulfilledorders ?? []);
                    settodayproformaorders(data?.todayproformaorders ?? []);
                    settodayregularorders(data?.todayregularorders ?? []);
                    settodaysalesorders(data?.todaysalesorders ?? []);
                    settodaydarkstoreorders(data?.todaydarkstoreorders ?? []);
                    settodayNextDayorders(data?.todayNextDayorders ?? []);
                    settodayOnHoldorders(data?.todayOnHoldorders ?? []);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const setChange = (event, picker) => {
        console.log('startDate', picker.startDate._d);
        console.log('endDate', picker.endDate._d);
        setstartDate(picker.startDate._d);
        setendDate(picker.endDate._d);
        setStatus('dateselect');
        // AllDateRangeMasterDashboardUrl
        const userInfo = localStorage.getItem('userToken');
        const from = picker.startDate._d;
        const to = picker.endDate._d;
        setLoading(true);
        const data = {
            from,
            to
        };

        fetch(ApiUrl.ReportingDateRangeUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                setLoading(false);
                // console.log('data', result);
                settotalordersrange(result?.daterangetotalorders ?? []);
                setdateRangeTitle(result?.dateRangeTitle ?? '');
                setdaterangetotalfulfilledorders(result?.daterangetotalfulfilledorders ?? []);
                setdaterangetotalgeneratedorders(result?.daterangetotalgeneratedorders ?? []);
                setdaterangetotalcancelledorders(result?.daterangetotalcancelledorders ?? []);
                setdaterangetotalunfulfilledorders(result?.daterangetotalunfulfilledorders ?? []);
                setdaterangetotalproformaorders(result?.daterangetotalproformaorders ?? []);
                setdaterangeregularorders(result?.daterangeregularorders ?? []);
                setdaterangesalesorders(result?.daterangesalesorders ?? []);
                setdaterangedarkstoreorders(result?.daterangedarkstoreorders ?? []);
                setdaterangeOnHoldorders(result?.daterangeOnHoldorders ?? []);
                setdaterangeNextDayorders(result?.daterangeNextDayorders ?? []);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        // const userInfo = localStorage.getItem('userToken');
        retrieveAllTodaysMatrix();
    }, []);

    return (
        <>
            <br />
            <PageHeader title="Dashboard" subTitle="Home | Report " />
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>Note: This Data is based on Order Date</Grid>

                <Grid item>
                    <DateRangePicker initialSettings={{ startDate, endDate }} onApply={setChange}>
                        <Button variant="contained" size="sm" className={classes.item}>
                            filter by Date
                        </Button>
                    </DateRangePicker>
                </Grid>
            </Grid>
            <br />
            {status === 'today' ? (
                <>
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalOrders isLoading={isLoading} todaytotalorders={totalorders} title="Total Orders For " date="Today" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalFulfilledOrdersCard
                                isLoading={isLoading}
                                todaytotalfulfilledorders={todayfulfilledorders}
                                title="Total Orders Delivered For "
                                date="Today"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalGeneratedOrdersCard
                                isLoading={isLoading}
                                todaytotalgeneratedorders={todaygeneratedorders}
                                title="Total Receipt yet to be Fulfilled For "
                                date="Today"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalRegularOrderCard
                                isLoading={isLoading}
                                totalregularorders={todayregularorders}
                                title="Total Regular Orders For "
                                date="Today"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalSalesOrderCard
                                isLoading={isLoading}
                                totalsalesorders={todaysalesorders}
                                title="Total Sales Drive Orders For "
                                date="Today"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalDarkStoreOrdersCard
                                isLoading={isLoading}
                                totaldarkstoresorders={todaydarkstoreorders}
                                title="Total DarkStore Orders For "
                                date="Today"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalCancelledOrdersCard
                                isLoading={isLoading}
                                todaytotalcancelledorders={todaycancelledorders}
                                title="Total Cancelled Orders For "
                                date="Today"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalUnfulfilledOrdersCard
                                isLoading={isLoading}
                                todaytotalunfulfilledorders={todayunfulfilledorders}
                                title="Total Unfulfilled Orders For "
                                date="Today"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalProformaOrdersCard
                                isLoading={isLoading}
                                todaytotalproformaorders={todayproformaorders}
                                title="Total Proforma Orders For "
                                date="Today"
                            />
                        </Grid>
                    </Grid>

                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalOnHoldOrderCard
                                isLoading={isLoading}
                                totalonholdorders={todayOnHoldorders}
                                title="Total OnHold Orders For "
                                date="Today"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalNextDayOrderCard
                                isLoading={isLoading}
                                totalnextdayorders={todayNextDayorders}
                                title="Total NextDay Orders For "
                                date="Today"
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalProformaOrdersCard
                                isLoading={isLoading}
                                todaytotalproformaorders={todayproformaorders}
                                title="Total Proforma Orders For "
                                date="Today"
                            />
                        </Grid> */}
                    </Grid>
                </>
            ) : (
                ''
            )}

            {status === 'dateselect' ? (
                <>
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalOrders
                                isLoading={isLoading}
                                todaytotalorders={totalordersrange}
                                title="Total Orders For "
                                date={dateRangeTitle}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalFulfilledOrdersCard
                                isLoading={isLoading}
                                todaytotalfulfilledorders={daterangetotalfulfilledorders}
                                title="Total Fulfilled Orders For "
                                date={dateRangeTitle}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalGeneratedOrdersCard
                                isLoading={isLoading}
                                todaytotalgeneratedorders={daterangetotalgeneratedorders}
                                title="Total Orders yet to be Fulfilled For "
                                date={dateRangeTitle}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalRegularOrderCard
                                isLoading={isLoading}
                                totalregularorders={daterangeregularorders}
                                title="Total Regular Orders For "
                                date="Today"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalSalesOrderCard
                                isLoading={isLoading}
                                totalsalesorders={daterangesalesorders}
                                title="Total Sales Drive Orders For "
                                date="Today"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalDarkStoreOrdersCard
                                isLoading={isLoading}
                                totaldarkstoresorders={daterangedarkstoreorders}
                                title="Total DarkStore Orders For "
                                date="Today"
                            />
                        </Grid>
                    </Grid>

                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalCancelledOrdersCard
                                isLoading={isLoading}
                                todaytotalcancelledorders={daterangetotalcancelledorders}
                                title="Total Cancelled Orders For "
                                date={dateRangeTitle}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalUnfulfilledOrdersCard
                                isLoading={isLoading}
                                todaytotalunfulfilledorders={daterangetotalunfulfilledorders}
                                title="Total Unfulfilled Orders For "
                                date={dateRangeTitle}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalProformaOrdersCard
                                isLoading={isLoading}
                                todaytotalproformaorders={daterangetotalproformaorders}
                                title="Total Proforma Orders For "
                                date={dateRangeTitle}
                            />
                        </Grid>
                    </Grid>

                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalOnHoldOrderCard
                                isLoading={isLoading}
                                totalonholdorders={daterangeOnHoldorders}
                                title="Total OnHold Orders For "
                                date={dateRangeTitle}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalNextDayOrderCard
                                isLoading={isLoading}
                                totalnextdayorders={daterangeNextDayorders}
                                title="Total NextDay Orders For "
                                date={dateRangeTitle}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={4} lg={4}>
                            <TotalProformaOrdersCard
                                isLoading={isLoading}
                                todaytotalproformaorders={todayproformaorders}
                                title="Total Proforma Orders For "
                                date="Today"
                            />
                        </Grid> */}
                    </Grid>
                </>
            ) : (
                ''
            )}
        </>
    );
};

export default OrderReport;
