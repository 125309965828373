import React, { useEffect, useState } from 'react';
import { Table, Divider, Paper, Space, Button, Select, Modal, Checkbox, Alert, Input, DatePicker } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import ApiUrl from '../../network';
import { ToastContainer, toast } from 'react-toastify';
import PaymentAlert from './PaymentAlert';
import { encrypt, decrypt } from '../../utils/encrypt';
import SingleOrder from 'components/DeliveryNotes/SingleOrder';

const PaymentModal = ({
    setIsPaymentOpen,
    isPaymentOpen,
    handlePaymentCancel,
    handlePaymentOk,
    getPaymentId,
    setUpdatePaymentSuccess,
    updatePaymentSuccess,
    accumulatedFilters,
    collections
}) => {
    const [mainOrder, setMainOrder] = useState([]);
    const [mainOrderStatus, setMainOrderStatus] = useState([]);
    const [mainPaymentStatus, setMainPaymentStatus] = useState([]);
    const [mainCustomer, setMainCustomer] = useState([]);
    const [mainCity, setMainCity] = useState([]);
    const [mainRma, setMainRma] = useState([]);
    const [expCashCollected, setExpectedCash] = useState('');
    const [cashOutStanding, setCashOutStanding] = useState(0);
    const [actCashCollected, setActCashCollected] = useState('');
    const [perCashOutStanding, setPerCashOutStanding] = useState(0);
    const [paymentStatusId, setpaymentStatusRecord] = useState('');
    const [discount, setdiscount] = useState('');
    const [discountAppliedBy, setdiscountAppliedBy] = useState('');
    const [retailerCredit, setretailerCredit] = useState('');
    const [retailerDebit, setretailerDebit] = useState('');
    const [orderValue, setorderValue] = useState('');
    const [orderStatus, setOrderStatus] = useState([]);
    const [orderStatusId, setorderStatusRecord] = useState('');
    const [isSuccessOpen, setIsModalSuccessOpen] = useState(false);
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [fulStatus, setfulStatus] = useState('');
    const [countries, setCountries] = useState([]);
    const [receiptImage, setReceiptImage] = useState('');
    const [collectionImage, setCollectionImage] = useState('');
    const country = localStorage.getItem('country');

    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');

    // success alert modals
    const showSuccessModal = () => {
        setIsModalSuccessOpen(true);
    };

    const handleSuccessOk = () => {
        setIsModalSuccessOpen(false);
    };

    const handleSuccessCancel = () => {
        setIsModalSuccessOpen(false);
    };

    // get query data
    const getQueryData = async () => {
        try {
            // setLoading(true);
            const url = `${ApiUrl.BaseUrl}/mainorders/querydata?country=${accumulatedFilters?.country || country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setCountries(answer.country);
                        setMainOrderStatus(answer.orderStatus);
                        setMainPaymentStatus(answer.paymentStatus);
                        setMainCustomer(answer.customer);
                        setMainCity(answer.city);
                        setMainRma(answer.relationshipManagers);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // const orderStatusOptions = mainOrderStatus?.map((item) => {
    //     return { label: item?.name, value: item?.name };
    // });

    const paymentStatusOptions = mainPaymentStatus?.map((item) => {
        return { label: item?.name, value: item?._id };
    });

    const calcCashOutStanding = () => {
        // calculate cash outstanding
        const cashOut = Number(expCashCollected) - Number(actCashCollected) - Number(discount);
        const value = Number.isFinite(cashOut) ? cashOut : 0;
        const cashOutresult = Number(value).toFixed(2);

        setCashOutStanding(cashOutresult);

        // calculate % cash outstanding
        if (!Number.isNaN(value) && !Number.isNaN(expCashCollected) && expCashCollected !== 0) {
            const percent = (value / expCashCollected) * 100;
            // Do something with the calculated percentage
            setPerCashOutStanding(percent.toFixed(2));
        } else {
            // Handle the case where the values are not valid
            console.error('Invalid input values for percentage calculation.');
        }

        // setPerCashOutStanding((prev) => (prev = percent.toFixed(2)));
    };

    // get all orders
    const getMainOrders = async () => {
        try {
            // setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/mainorders/mainorders`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            // Append accumulated filters to URL
            Object.entries(accumulatedFilters).forEach(([key, value]) => {
                if (value) {
                    urlParams.append(key, value);
                }
            });

            url = `${url}?${urlParams.toString()}`;

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        const singelOrder = answer.find((order) => {
                            return order._id === getPaymentId;
                        });
                        setMainOrder(singelOrder);
                        setorderValue(Number(singelOrder?.costValue).toFixed(2) ?? 0);
                        setorderStatusRecord(singelOrder?.receiptStatus?._id ?? '');
                        setExpectedCash(Number(singelOrder?.salesValue).toFixed(2) ?? '');
                        setActCashCollected(singelOrder?.actCashCollected ?? 0);
                        setpaymentStatusRecord(singelOrder?.paymentStatus?._id ?? '');
                        setCashOutStanding(singelOrder?.cashOutStanding ?? 0);
                        setPerCashOutStanding(singelOrder?.perCashOutStanding ?? 0);
                        setdiscount(singelOrder?.discount ?? 0);
                        setretailerDebit(singelOrder?.retailerDebit ?? 0);
                        setretailerCredit(singelOrder?.retailerCredit ?? 0);
                        setdiscountAppliedBy(singelOrder?.discountAppliedBy ?? 'System applied discount');
                        setfulStatus(singelOrder?.orderStatus ?? '');
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // const retrieveAllActivePaymentStatus = async () => {
    //     try {
    //         const userInfo = localStorage.getItem('userToken');
    //         const config = {
    //             headers: {
    //                 'auth-token': userInfo,
    //                 'Content-Type': 'application/json'
    //             }
    //         };
    //         // setLoading(true);
    //         fetch(ApiUrl.AllActivePaymentStatus, config)
    //             .then((data) => data.json())
    //             .then((data) => {
    //                 if (data.status === true) {
    //                     setPaymentStatus(data.data);
    //                 } else {
    //                     toast.error(data.data);
    //                 }
    //             })
    //             .finally(() => {
    //                 // setLoading(false);
    //             });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    const getCollection = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(`${ApiUrl.BaseUrl}/collection/financecollection`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        const collection = answer?.find((collection) => {
                            return collection?.country === 'Ghana'
                                ? collection?.orderIdGh?._id === mainOrder?._id
                                : collection?.orderIdNg?._id === mainOrder?._id;
                        });
                        setReceiptImage(collection);
                    } else {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        toast.error(answer);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const getConfirmedList = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(`${ApiUrl.BaseUrl}/collection/approvecollectionlist`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);

                        const collection = answer?.find((collection) => {
                            return collection?.country === 'Ghana'
                                ? collection?.orderIdGh?._id === mainOrder?._id
                                : collection?.orderIdNg?._id === mainOrder?._id;
                        });
                        setCollectionImage(collection);
                    } else {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        toast.error(answer);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllActiveOrderStatus = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            // setLoading(true);
            fetch(ApiUrl.AllActiveOrderStatus, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrderStatus(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getConfirmedList();
        getMainOrders();
        getQueryData();
        retrieveAllActiveOrderStatus();
        calcCashOutStanding();
        getCollection();
    }, [getPaymentId, mainOrder?._id]);

    // useEffect(() => {
    //     const getOrderInfo = async () => {
    //         const userInfo = localStorage.getItem('userToken');

    //         const config = {
    //             headers: {
    //                 'auth-token': userInfo,
    //                 'Content-Type': 'application/json'
    //             }
    //         };

    //         await fetch(ApiUrl.ViewOrdersUrl + getPaymentId, config)
    //             .then((res) => res.json())
    //             .then((result) => {
    //                 if (result.status === true) {
    //                     console.error(result?.data?.orderValue, 'result');
    //                     console.error(result?.data, 'result');
    //                     // setorderValue(Number(result?.data?.orderValue).toFixed(2) ?? 0);
    //                     // setorderStatusRecord(result?.data?.newOrderStatusId?._id ?? '');
    //                     // setExpectedCash(Number(result?.data?.expCashCollected).toFixed(2) ?? '');
    //                     // setActCashCollected(result?.data?.actCashCollected ?? 0);
    //                     setPerMargin(result?.data?.perMargin ?? '');
    //                     // setpaymentStatusRecord(result?.data?.newPaymentStatusId?._id ?? '');
    //                     // setNewPaymentStatusId(result?.data?.newPaymentStatusId?._id ?? '');
    //                     // setCashOutStanding(result?.data?.cashOutStanding ?? 0);
    //                     // setPerCashOutStanding(result?.data?.perCashOutStanding ?? 0);
    //                     // setdiscount(result?.data?.discount ?? 0);
    //                     setdriverOrderStatus(result?.data?.driverOrderStatus ?? '');
    //                     setretailerCredit(result?.data?.retailerCredit ?? 0);
    //                     setretailerCreditHolder(result?.data?.retailerCreditHolder ?? 0);
    //                     setretailerDebit(result?.data?.retailerDebit ?? 0);
    //                     // setdiscountAppliedBy(result?.data?.discountAppliedBy ?? '');
    //                     setfulStatus(result?.data?.fulStatus ?? '');
    //                 } else {
    //                     toast.error(result.data);
    //                 }
    //             })
    //             .finally(() => {
    //                 // setLoading2(false);
    //             });
    //     };

    //     getOrderInfo();
    //     // retrieveAllActivePaymentStatus();
    //     // retrieveAllActiveOrderStatus();
    //     calcCashOutStanding();
    // }, [getPaymentId]);

    // const paymentStatusOptions = paymentStatus?.map((item) => {
    //     return { label: item?.name, value: item?._id };
    // });

    const orderStatusOptions = orderStatus?.map((item) => {
        return { label: item?.name, value: item?._id };
    });

    const handleFulStatus = (value) => {
        setfulStatus(value);
    };

    const handlePaymentStatusChange = (value) => {
        setpaymentStatusRecord(value);
    };

    const handleReceiptStatusChange = (value) => {
        setorderStatusRecord(value);
    };

    // Update Payment
    const updatePayment = async (event) => {
        const userInfo = localStorage.getItem('userToken');
        setPaymentLoading(true);

        const data = {
            id: getPaymentId,
            orderValue,
            expCashCollected,
            actCashCollected,
            orderStatus: fulStatus,
            receiptStatus: 'Order Receipt Generated',
            receiptStatusId: orderStatusId,
            paymentStatus: paymentStatusId,
            newPaymentStatusId: paymentStatusId,
            cashOutStanding,
            perCashOutStanding,
            discount,
            discountAppliedBy
        };

        showSuccessModal();

        const obj = JSON.stringify(data);
        const result = encrypt(obj, keyResult, ivResult);
        await fetch(`${ApiUrl.BaseUrl}/order/orderapproval`, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ payload: result })
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    handlePaymentOk();
                    setUpdatePaymentSuccess(!updatePaymentSuccess);
                } else if (result.status === false) {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                    handleSuccessCancel();
                } else {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                    handleSuccessCancel();
                }
            })
            .finally(() => {
                setPaymentLoading(false);
            });
    };

    return (
        <div>
            <Modal
                open={isPaymentOpen}
                onOk={handlePaymentOk}
                centered
                width={700}
                onCancel={handlePaymentCancel}
                footer={
                    <div className="driver-footer">
                        <Button
                            style={{
                                backgroundColor: '#FFFFFF',
                                borderRadius: '12px',
                                height: '56px',
                                fontSize: '18px',
                                fontWeight: '700',
                                color: '#FF740F',
                                border: '1px solid #FF740F',
                                width: '300px',
                                textAlign: 'center'
                            }}
                            onClick={() => {
                                handlePaymentCancel();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{
                                backgroundColor: '#FF740F',
                                borderRadius: '12px',
                                height: '56px',
                                fontSize: '18px',
                                fontWeight: '700',
                                color: '#FFFFFF',
                                width: '300px',
                                textAlign: 'center'
                            }}
                            onClick={() => {
                                updatePayment();
                            }}
                        >
                            Update
                        </Button>
                    </div>
                }
            >
                <div className="drivers-available">
                    <h1>Payment Information</h1>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            backgroundColor: '#f6f6f6',
                            borderRadius: '12px',
                            border: '1px solid #e7eaeb',
                            height: '360px',
                            overflow: 'scroll',
                            paddingBottom: '20px'
                        }}
                    >
                        <Box
                            sx={{
                                width: ' 277px',
                                margin: '24px 24px 0 24px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974' }}>Cost Value</Typography>
                            <Input
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                placeholder="Order Value"
                                value={orderValue}
                                disabled
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 277px',
                                margin: '24px 24px 0 24px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974' }}>Sales Value</Typography>
                            <Input
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                placeholder="Sales Value"
                                value={expCashCollected}
                                disabled
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 277px',
                                margin: '24px 24px 0 24px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974' }}>Cash Collected+Discount</Typography>
                            <Input
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                placeholder="Cash Collected+Discount"
                                value={actCashCollected}
                                onChange={(e) => {
                                    setActCashCollected(e.target.value);
                                    if (e.target.value > 0) {
                                        setpaymentStatusRecord('620d76a4ee512a471bf98c3e');
                                    } else {
                                        setpaymentStatusRecord('620d7693448d1be3b4ab1b08');
                                    }
                                }}
                                type="number"
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 277px',
                                margin: '24px 24px 0 24px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974' }}>Cash Outstanding</Typography>
                            <Input
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                placeholder="Cash Outstanding"
                                value={cashOutStanding}
                                disabled
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 277px',
                                margin: '24px 24px 0 24px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974' }}>% Cash Outstanding</Typography>
                            <Input
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                placeholder="% Cash Outstanding"
                                value={perCashOutStanding}
                                disabled
                            />
                        </Box>

                        <Box
                            sx={{
                                width: ' 277px',
                                margin: '24px 24px 0 24px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974' }}>Payment Status</Typography>
                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                allowClear
                                placeholder="Payment Status"
                                optionFilterProp="children"
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                options={paymentStatusOptions}
                                value={paymentStatusId}
                                onChange={handlePaymentStatusChange}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 277px',
                                margin: '24px 24px 0 24px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974' }}>Order Status</Typography>
                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                allowClear
                                placeholder="Order Status"
                                optionFilterProp="children"
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                options={[
                                    {
                                        value: 'Received',
                                        label: 'Received'
                                    },
                                    {
                                        value: 'Processing',
                                        label: 'Processing'
                                    },
                                    {
                                        value: 'Dispatched',
                                        label: 'Dispatched'
                                    },
                                    {
                                        value: 'Delivered',
                                        label: 'Delivered'
                                    },
                                    {
                                        value: 'Cancelled',
                                        label: 'Cancelled'
                                    },
                                    {
                                        value: 'OnHold',
                                        label: 'OnHold'
                                    },
                                    {
                                        value: 'NextDay',
                                        label: 'NextDay'
                                    },
                                    {
                                        value: 'OutOfStock',
                                        label: 'Out Of Stock'
                                    },
                                    {
                                        value: 'ProformaInvoice',
                                        label: 'Proforma Invoice'
                                    }
                                ]}
                                value={fulStatus}
                                onChange={handleFulStatus}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 277px',
                                margin: '24px 24px 0 24px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974' }}>Receipt Order Status</Typography>
                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                allowClear
                                placeholder="Receipt Order Status"
                                optionFilterProp="children"
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                options={orderStatusOptions}
                                value={orderStatusId}
                                onChange={handleReceiptStatusChange}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 277px',
                                margin: '24px 24px 0 24px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974' }}>Discount Amount</Typography>
                            <Input
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                placeholder="Discount Amount"
                                value={discount}
                                onChange={(e) => setdiscount(e.target.value)}
                                type="number"
                            />
                        </Box>

                        <Box
                            sx={{
                                width: ' 277px',
                                margin: '24px 24px 0 24px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974' }}>Discount Entered by</Typography>
                            <Input
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                placeholder="Discount Entered by"
                                value={discountAppliedBy}
                                disabled
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 277px',
                                margin: '24px 24px 0 24px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974' }}>Credit Amount Value</Typography>
                            <Input
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                placeholder="Credit Amount Value"
                                value={retailerCredit}
                                disabled
                            />
                        </Box>

                        <Box
                            sx={{
                                width: ' 277px',
                                margin: '24px 24px 0 24px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974' }}>Cash Outstanding Amount </Typography>
                            <Input
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                placeholder="Cash Outstanding Amount"
                                value={retailerDebit}
                                disabled
                            />
                        </Box>
                        {receiptImage?.image?.length > 0 ? (
                            receiptImage?.image?.map((image) => (
                                <Box
                                    sx={{
                                        width: ' 277px',
                                        margin: '24px 24px 0 24px',
                                        display: 'flex'
                                        // justifyContent: 'flex-start',
                                        // alignItems: 'flex-start'
                                    }}
                                    key={image?._id}
                                >
                                    <Box
                                        sx={{
                                            width: ' 321px',
                                            height: '200px',
                                            backgroundColor: '#E7EAEB',
                                            borderRadius: '12px',
                                            cursor: 'pointer',
                                            overflow: 'hidden',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <img src={image?.image} alt="" />
                                    </Box>
                                </Box>
                            ))
                        ) : collectionImage?.image?.length > 0 ? (
                            collectionImage?.image?.map((image) => (
                                <Box
                                    sx={{
                                        width: ' 277px',
                                        margin: '24px 24px 0 24px',
                                        display: 'flex'
                                        // justifyContent: 'flex-start',
                                        // alignItems: 'flex-start'
                                    }}
                                    key={image?._id}
                                >
                                    <Box
                                        sx={{
                                            width: ' 321px',
                                            height: '200px',
                                            backgroundColor: '#E7EAEB',
                                            borderRadius: '12px',
                                            cursor: 'pointer',
                                            overflow: 'hidden',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <img src={image?.image} alt="" />
                                    </Box>
                                </Box>
                            ))
                        ) : (
                            <Box
                                sx={{
                                    width: ' 277px',
                                    margin: '24px 24px 0 24px',
                                    display: 'flex'
                                    // justifyContent: 'flex-start',
                                    // alignItems: 'flex-start'
                                }}
                            >
                                <Box
                                    sx={{
                                        width: ' 321px',
                                        height: '200px',
                                        backgroundColor: '#E7EAEB',
                                        borderRadius: '12px',
                                        cursor: 'pointer',
                                        overflow: 'hidden',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    No Receipt
                                </Box>
                            </Box>
                        )}
                    </Box>
                </div>
            </Modal>
            <PaymentAlert
                showSuccessModal={showSuccessModal}
                handleSuccessOk={handleSuccessOk}
                handleSuccessCancel={handleSuccessCancel}
                isSuccessOpen={isSuccessOpen}
                paymentLoading={paymentLoading}
                setPaymentLoading={setPaymentLoading}
            />
        </div>
    );
};

export default PaymentModal;
