import { Button, Input, Select, Space, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';

import ApiUrl from '../../../network';
import { toast } from 'react-toastify';
import { decrypt, encrypt } from '../../../utils/encrypt';
import normFile from '../../../imageToBucket';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [partsFamily, setPartsFamily] = useState([]);
    const [distributorInfo, setDistributors] = useState([]);
    const [part, setPart] = useState({
        sku: '',
        brand: '',
        distributor: '',
        productFamily: '',
        cityprice: '',
        currency: '',
        partQtyInPieces: '',
        partsImage: '',
        partWeight: '',
        partImageList: [],
        description: '',
        city: '',
        suppliers: [],
        manufacturer: '',
        ownerdistributor: '',
        ownerdistributorContact: '',
        longDescription: '',
        dimensions: ''
    });

    const fetchFilteredParts = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/parts/v2/partsfilterdata`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                setPartsFamily(decryptedData.productfamily);
                setLoading(false);
            } else {
                toast.error(result.message);
                setLoading(false);
            }
        } catch (e) {
            toast(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };
    const retrieveAllDistributor = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(`${ApiUrl.BaseUrl}/channelpartner/channelspartnerdistributor`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setDistributors(data.data);
                    } else {
                        toast.error(data.data);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    const fetchPartDetails = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/parts/v2/viewpartscatalogrmacc/${id}`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                console.log(decryptedData, 'decryptedData');
                setPart({
                    sku: decryptedData.sku || '',
                    brand: decryptedData.brand || '',
                    productFamily: decryptedData.productFamily || '',
                    cityprice: decryptedData.cityprice || '',
                    currency: decryptedData.currency || '',
                    partQtyInPieces: decryptedData.partQtyInPieces || '',
                    partsImage: decryptedData.partsImage || '',
                    partWeight: decryptedData.partWeight || '',
                    partImageList: decryptedData.partImageList || [],
                    description: decryptedData.description || '',
                    city: decryptedData.city || '',
                    suppliers: decryptedData.suppliers || [],
                    manufacturer: decryptedData.manufacturer || '',
                    ownerdistributor: decryptedData.ownerdistributor || '',
                    ownerdistributorContact: decryptedData.ownerdistributorContact || '',
                    longDescription: decryptedData.longDescription || '',
                    dimensions: decryptedData.dimensions || ''
                });
                setLoading(false);
            } else {
                toast.error(result.message);
                setLoading(false);
            }
        } catch (e) {
            toast(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPartDetails();
        fetchFilteredParts();
        retrieveAllDistributor();
    }, [id]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };

    return (
        <div>
            <div className="m-header">
                <div>
                    <Link to="/parts-catalog">
                        <h1 style={{ color: '#0A2938', display: 'flex', alignItems: 'center' }}>
                            <ArrowLeftOutlined className="mr-2" /> View details
                        </h1>
                    </Link>
                </div>
            </div>
            <div className="m-content  mt-4 bg-white" style={{ borderRadius: '10px' }}>
                <div className="m-part-cat-content">
                    <div className="img-main-thumb">
                        <div className="m-image-container bg-white">
                            <div className="image-placeholder slider-container">
                                {part.partImageList.length === 0 && (
                                    <img
                                        src={part.partsImage}
                                        alt="part"
                                        style={{ display: 'flex', alignItems: 'center', marginBottom: 8, borderRadius: '10px' }}
                                        className="m-product-img"
                                    />
                                )}
                                <Slider {...settings}>
                                    {part?.partImageList?.map((image, index) => (
                                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                                            <img src={image.image} alt={`part-${index}`} className="m-product-img" />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                        <div className="imgs-thumbnail">
                            {part.partImageList.map((image, index) => (
                                <div key={index} style={{ position: 'relative' }}>
                                    <img src={image.image} alt={`part-${index}`} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="ml-2 p-2">
                        <h3 style={{ fontSize: '24px', color: '#0A2938' }}>Description</h3>
                        <p style={{ color: '#233E4C', lineHeight: '30px' }}>{part.description || 'N/A'}</p>

                        {part.longDescription && (
                            <div>
                                <p className="mt-4" style={{ fontSize: '16px', color: '#0A2938', fontWeight: 'bold' }}>
                                    Detail Description
                                </p>

                                <p style={{ color: '#233E4C', lineHeight: '30px' }}>{part.longDescription || 'N/A'}</p>
                            </div>
                        )}

                        {(localStorage.getItem('userRole').toLowerCase() === 'admin' ||
                            localStorage.getItem('userRole').toLowerCase() === 'finance manager') && (
                            <div className="d-flex justify-content-center align-items-center">
                                <Button
                                    className="mt-4"
                                    onClick={() => (window.location.href = `/parts-catalog/seller-prices/${id}`)}
                                    style={{
                                        backgroundColor: '#FF740F',
                                        color: 'white',
                                        width: 'fit-content'
                                    }}
                                    loading={loading}
                                >
                                    View sellers and prices
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="part-cat-form">
                    <h2 className="mb-4">Part Information</h2>
                    <div>
                        <div className="part-content-flex">
                            <p>Product name</p>
                            <p>{part.sku}</p>
                        </div>
                        <div className="part-content-flex">
                            <p>Brand</p>
                            <p>{part.brand}</p>
                        </div>
                        <div className="part-content-flex">
                            <p>City</p>
                            <p>{part.city}</p>
                        </div>
                        <div className="part-content-flex">
                            <p>Supplier</p>
                            <p>
                                {part.suppliers.map((supplier, index) => (
                                    <span key={index}>
                                        {supplier.supplier.fullName} {index < part.suppliers.length - 1 ? ', ' : ''}
                                    </span>
                                ))}
                            </p>
                        </div>
                        <div className="part-content-flex">
                            <p>Product family</p>
                            <p>{part.productFamily}</p>
                        </div>
                        <div className="part-content-flex">
                            <p>Price</p>
                            <p>
                                {part.cityprice} {part.currency}
                            </p>
                        </div>
                        <div className="part-content-flex">
                            <p>Quantity</p>
                            <p>{part.partQtyInPieces}</p>
                        </div>
                        <div className="part-content-flex">
                            <p>Weight</p>
                            <p>{part.partWeight}</p>
                        </div>
                        <div className="part-content-flex">
                            <p>Dimensions</p>
                            <p>{part.dimensions || 'N/A'}</p>
                        </div>
                        <div className="part-content-flex">
                            <p>Manufacturer</p>
                            <p>{part.manufacturer || 'N/A'}</p>
                        </div>
                        <div className="part-content-flex">
                            <p>Qwner distributor</p>
                            <p>{part?.ownerdistributor || 'N/A'}</p>
                        </div>
                        <div className="part-content-flex">
                            <p>Owner distributor contact</p>
                            <p>{part?.ownerdistributorContact || 'N/A'}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
