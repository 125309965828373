/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from 'assets/images/garage.png';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import AuthCardWrapper from '../pages/authentication/AuthCardWrapper';
import AuthWrapper1 from '../pages/authentication/AuthWrapper1';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ApiUrl from 'network';
import CircularProgress from '@mui/material/CircularProgress';

const PreviewMoMoCollection = () => {
    const history = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    // const [loading3, setLoading3] = useState(false);
    const [phoneNumber, setphoneNumber] = useState('');
    const [network, setnetwork] = useState('');
    const [amount, setamount] = useState('');
    const [orderId, setorderId] = useState('');
    const [paymentType, setpaymentType] = useState('');
    const [narration, setnarration] = useState('');
    const [userId, setuserId] = useState('');
    const [momoStatus, setmomoStatus] = useState('');
    const [momoReference, setmomoReference] = useState('');
    const [networkData, setnetworkData] = useState([]);
    const { id } = useParams();

    const retrieveAllNetworks = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.PaymentNetworkUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setnetworkData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.PreviewBulkPaymentUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setphoneNumber(result?.data?.momoNumber ?? '');
                    setamount(result?.data?.momoAmt ?? '');
                    setorderId(result?.data?.orderId ?? '');
                    setpaymentType('MoMo');
                    setnarration(result?.data?.momoDescription ?? '');
                    setuserId(result?.data?.userId ?? '');
                    setmomoStatus(result?.data?.momoStatus ?? '');
                    setmomoReference(result?.data?.momoReference ?? '');
                    setnetwork(result?.data?.momoNetwork ?? '');
                    // setLoading3(true);

                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                }
            })
            .finally(() => {
                setLoading2(false);
            });
        retrieveAllNetworks();
    }, [id]);

    const handleNetworkChange = (event) => {
        setnetwork(event.target.value);
    };

    // const checkPaymentStatus = (e) => {
    //     const reference = id;
    //     const data = {
    //         reference
    //     };

    //     const userInfo = localStorage.getItem('userToken');
    //     fetch(ApiUrl.CheckingBulkPayStatusUrl, {
    //         method: 'POST',
    //         headers: {
    //             'auth-token': userInfo,
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify(data)
    //     })
    //         .then((res) => res.json())
    //         .then((result) => {
    //             if (result.status === true) {
    //                 toast.success(result.data?.status);
    //                 setTimeout(() => {
    //                     history(-1);
    //                 }, 3000);
    //             } else {
    //                 toast.error(result?.data?.status);
    //             }
    //         })
    //         .finally(() => {});
    // };

    const handleSubmit = (event) => {
        event.preventDefault();
        const reference = id;
        const data = {
            orderId,
            phoneNumber,
            network,
            amount,
            reference,
            paymentType,
            narration
        };

        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        fetch(ApiUrl.BulkPayMoMoUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.data);

                    setTimeout(() => {
                        // setLoading3(false);
                    }, 3000);
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {});
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <AuthWrapper1>
                        <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                            <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                                    <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                                        <AuthCardWrapper>
                                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                                <Grid item sx={{ mb: 3 }}>
                                                    <Link to="#">
                                                        <img src={logo} alt="logo" height={50} />
                                                    </Link>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    {/* <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                                        <Button
                                                            onClick={() => checkPaymentStatus()}
                                                            variant="contained"
                                                            color="info"
                                                            disabled={!!loading3}
                                                        >
                                                            check payment status
                                                        </Button>
                                                    </Box> */}
                                                    <br />
                                                    <br />
                                                    <Typography variant="h3" component="h2" textAlign="center">
                                                        MoMo Payment
                                                    </Typography>
                                                    <br />

                                                    <br />
                                                    <form onSubmit={handleSubmit}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">Network Name *</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={network}
                                                                required
                                                                disabled
                                                                label="Select Network"
                                                                onChange={handleNetworkChange}
                                                            >
                                                                {networkData.map((channels) => (
                                                                    <MenuItem key={channels} value={channels}>
                                                                        {channels}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        <br />
                                                        <br />

                                                        <TextField
                                                            autoComplete="off"
                                                            type="number"
                                                            name="phoneNumber"
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled
                                                            required
                                                            id="phoneNumber"
                                                            label="Phone Number"
                                                            value={phoneNumber}
                                                            onChange={(e) => setphoneNumber(e.target.value)}
                                                            autoFocus
                                                        />

                                                        <br />
                                                        <br />

                                                        <TextField
                                                            autoComplete="off"
                                                            type="number"
                                                            name="amount"
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled
                                                            required
                                                            id="amount"
                                                            label="Amount"
                                                            value={amount}
                                                            onChange={(e) => setamount(e.target.value)}
                                                        />
                                                        <br />
                                                        <br />
                                                        <TextField
                                                            autoComplete="off"
                                                            name="momoStatus"
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled
                                                            required
                                                            id="momoStatus"
                                                            label="MoMo Status"
                                                            value={momoStatus}
                                                            onChange={(e) => setmomoStatus(e.target.value)}
                                                        />
                                                        <br />
                                                        <br />
                                                        <TextField
                                                            autoComplete="off"
                                                            name="narration"
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled
                                                            required
                                                            id="narration"
                                                            label="Narration"
                                                            value={narration}
                                                            onChange={(e) => setnarration(e.target.value)}
                                                        />

                                                        <br />
                                                        <br />

                                                        <TextField
                                                            autoComplete="off"
                                                            name="momoReference"
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled
                                                            required
                                                            id="momoReference"
                                                            label="MoMo Reference"
                                                            value={momoReference}
                                                            onChange={(e) => setmomoReference(e.target.value)}
                                                        />
                                                        <br />
                                                        <br />

                                                        <TextField
                                                            autoComplete="off"
                                                            name="userId"
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled
                                                            required
                                                            id="userId"
                                                            label="User"
                                                            value={userId}
                                                            onChange={(e) => setuserId(e.target.value)}
                                                        />
                                                        <br />
                                                        <br />
                                                        <TextField
                                                            autoComplete="off"
                                                            name="orderId"
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled
                                                            required
                                                            id="orderId"
                                                            label="Order ID"
                                                            value={orderId}
                                                            onChange={(e) => setorderId(e.target.value)}
                                                        />
                                                        <br />
                                                        <br />

                                                        <Box display="flex" justifyContent="space-between">
                                                            <Button onClick={() => history(-1)} variant="contained" color="error">
                                                                Back
                                                            </Button>

                                                            <Button type="submit" variant="contained" color="secondary" disabled>
                                                                {loading ? 'Submitting ..' : 'Submit'}
                                                            </Button>
                                                        </Box>
                                                    </form>
                                                </Grid>
                                            </Grid>
                                        </AuthCardWrapper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AuthWrapper1>
                </>
            )}
        </>
    );
};

export default PreviewMoMoCollection;
