import { Table, Checkbox, Popover, List, Button, Input, Select } from 'antd';
import React, { useState } from 'react';
import PageHeader from 'components/PageHeader';
import './permission.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const { Search } = Input;

const onSearch = (value) => console.log(value);

const handleChange = (value) => {
    console.log(`selected ${value}`);
};
const dataSource = [
    {
        key: '1',
        name: 'Mike',
        role: 32,
        address: 'you@company.com'
    },
    {
        key: '2',
        name: 'John',
        role: 42,
        address: 'me@company.com'
    },
    {
        key: '3',
        name: 'Mike',
        role: 32,
        address: 'you@company.com'
    },
    {
        key: '4',
        name: 'John',
        role: 42,
        address: 'me@company.com'
    },
    {
        key: '5',
        name: 'Mike',
        role: 32,
        address: 'you@company.com'
    },
    {
        key: '6',
        name: 'John',
        role: 42,
        address: 'me@company.com'
    },
    {
        key: '7',
        name: 'Mike',
        role: 32,
        address: 'you@company.com'
    },
    {
        key: '8',
        name: 'John',
        role: 42,
        address: 'me@company.com'
    },
    {
        key: '9',
        name: 'Mike',
        role: 32,
        address: 'you@company.com'
    },
    {
        key: '10',
        name: 'John',
        role: 42,
        address: 'me@company.com'
    }
];

const data = ['Admin', 'Customer Care', 'Sales', 'Fullfilment', 'Finance'];

const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
};

const content = (
    <List
        size="small"
        footer={<Button>Edit Roles</Button>}
        dataSource={data}
        renderItem={(item) => (
            <List.Item>
                <Checkbox onChange={onChange}>{item}</Checkbox>
            </List.Item>
        )}
    />
);

const columns = [
    {
        title: 'Account',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        render: () => (
            <div>
                <Popover placement="bottomLeft" content={content} trigger="click">
                    <a>
                        Admin <KeyboardArrowDownIcon />
                    </a>
                </Popover>
            </div>
        )
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address'
    }
];
const Permission = () => {
    return (
        <div>
            <br />
            <PageHeader title="Permission" subTitle="Home | Permission " />
            <br />
            <br />
            <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                options={[
                    {
                        value: '1',
                        label: 'Mike'
                    },
                    {
                        value: '2',
                        label: 'John'
                    }
                ]}
            />{' '}
            <Select
                defaultValue="Admin"
                style={{
                    width: 120
                }}
                allowClear
                onChange={handleChange}
                options={[
                    {
                        value: 'Admin',
                        label: 'Admin'
                    },
                    {
                        value: 'Customer Care',
                        label: 'Customer Care'
                    },
                    {
                        value: 'RMA',
                        label: 'RMA'
                    }
                ]}
            />
            <br />
            <br />
            <Table dataSource={dataSource} columns={columns} />
        </div>
    );
};

export default Permission;
