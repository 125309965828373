import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';
import Controls from '../../components/controls/Controls';
import { Search } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
// import TablePagination from '@mui/material/TablePagination';
import ApiUrl from 'network';

const { BaseUrl } = ApiUrl;

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const InspectionReport = () => {
    const classes = useStyles();
    const history = useNavigate();
    const [loading, setLoading] = useState(true);
    const [allInspectionReport, setAllInspectionReport] = useState([]);
    const [limit, setLimit] = useState(5);
    const [list, setList] = useState([]);

    const margin = 5;

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        async function getInspectionReport() {
            try {
                const result = await (await fetch(`${BaseUrl}/autolab/autolab`, config)).json();
                const { status, message, data } = result;
                if (status && message === 'success') {
                    setLoading(false);
                    setAllInspectionReport(data);
                    const arr = [];
                    let len = 0;
                    if (data.length === limit) {
                        len = limit;
                    } else if (data.length > limit) {
                        len = limit;
                    } else {
                        len = data.length;
                    }
                    data.forEach((item, index) => {
                        if (index < len) {
                            arr.push(item);
                        }
                    });
                    setList(arr);
                    console.log(result);
                } else {
                    setLoading(false);
                    console.log(result);
                }
            } catch (e) {
                console.log(e);
            }
        }
        getInspectionReport();
    }, []);

    const handleSearch = (text) => {
        if (text !== '') {
            const arr = [];
            list.forEach((item) => {
                const name = item.customerName.toLowerCase();
                if (name.indexOf(text) > -1) {
                    arr.push(item);
                }
            });
            setList(arr);
        } else {
            const arr = [];
            let len = 0;
            if (allInspectionReport.length === limit) {
                len = limit;
            } else if (allInspectionReport.length > limit) {
                len = limit;
            } else {
                len = allInspectionReport.length;
            }
            allInspectionReport.forEach((item, index) => {
                if (index < len) {
                    arr.push(item);
                }
            });
            setList(arr);
        }
    };

    const goBack = () => {
        if (limit > margin) {
            const val = limit - margin;
            setLimit(val);
        } else {
            setLimit(margin);
        }
    };

    const goForward = () => {
        if (allInspectionReport.length > limit) {
            const val = limit + margin;
            setLimit(val);
        } else {
            setLimit(limit);
        }
    };

    const tHead = ['Customer Name', 'Advisor Name', 'Date', 'Notes', ''];
    const tTHead = tHead.map((item, index) => (
        <React.Fragment key={index}>
            <th className="font-15">{item}</th>
        </React.Fragment>
    ));

    const handleEditClick = (id) => {
        history(`/viewinspectionreport/${id}`);
    };

    const tBody = list.map(({ _id, customerName, advisorName, date, notes }, index) => (
        <React.Fragment key={index}>
            <tr className="font-13">
                <td>{customerName}</td>
                <td>{advisorName}</td>
                <td>{new Date(date).toDateString()}</td>
                <td>{notes}</td>
                <td>
                    <IconButton style={{ padding: 0 }} aria-label="edit" color="primary" onClick={() => handleEditClick(_id)}>
                        view
                    </IconButton>
                </td>
            </tr>
        </React.Fragment>
    ));

    return (
        <>
            <PageHeader title="Inspection Report" subTitle="Home | Inspection Report" />

            <MainCard title="Inspection Report">
                <Box display="flex" justifyContent="space-between">
                    <Controls.Input
                        label="Search Report"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                    <Box width={10} />
                    <Button component={Link} to="/addinspectionreport" variant="contained" size="sm" className={classes.item}>
                        New
                    </Button>
                </Box>
                <br />

                {loading ? (
                    <div className="flex-column justify-content-center align-items-center">
                        <CircularProgress />
                    </div>
                ) : (
                    // <TableContainer component={Paper}>
                    //     <Table className="table inventory">
                    //         <TableHead>
                    //             <TableRow>{tTHead}</TableRow>
                    //         </TableHead>
                    //         <TableBody>{tBody}</TableBody>
                    //     </Table>
                    // </TableContainer>
                    <table className="table inventory">
                        <thead>
                            <tr>{tTHead}</tr>
                        </thead>
                        <tbody>{tBody}</tbody>
                    </table>
                )}

                <br />
                <div className="flex-row-reverse">
                    <div className="width-20 width-lx-25 width-l-30 width-m-40 width-s-60">
                        <div className="row align-items-center">
                            <div className="col-6 padding-all-5">
                                <FormControl fullWidth>
                                    <Select
                                        label="Page"
                                        labelId="select-label"
                                        id="select-page"
                                        value={limit}
                                        onChange={(e) => setLimit(e.target.value)}
                                        className="gar-select"
                                    >
                                        <MenuItem value={0}>Page</MenuItem>
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={25}>25</MenuItem>
                                        <MenuItem value={allInspectionReport.length}>All</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-3 padding-all-5">
                                <button onClick={() => goBack()} type="button" className="none">
                                    <ChevronLeftIcon className="font-20" />
                                </button>
                            </div>
                            <div className="col-3 padding-all-5">
                                <button onClick={() => goForward()} type="button" className="none">
                                    <ChevronRightIcon className="font-20" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </MainCard>
        </>
    );
};

export default InspectionReport;
