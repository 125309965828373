import React, { useState, useEffect } from 'react';
import { Table, Divider, Paper, Space, Select, Spin, DatePicker, Modal, Input, Dropdown, Popover, Button } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { encrypt, decrypt } from '../../utils/encrypt';
import { LoadingOutlined, DownloadOutlined, FileAddOutlined } from '@ant-design/icons';
import ApiUrl from 'network';
import { ToastContainer, toast } from 'react-toastify';
import Avatar from '@mui/material/Avatar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/material/styles';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ClearedDetailsModal from './ClearedDetailsModal';
import { twoDecimalPlaces } from 'utils/functions';

// import ConfirmationModal from './ConfirmationModal';

const dateConverter = (value) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    };

    return new Date(value).toLocaleDateString('en-US', options);
};

function subtractNumbers(num1, num2) {
    if (typeof num1 !== 'number' || typeof num2 !== 'number') {
        throw new TypeError('Inputs must be numbers');
    }

    const difference = num1 - num2;

    return difference;
}

const ClearanceTable = ({
    confirmedList,
    countryOptions,
    handleLimit,
    limitOptions,
    limit,
    filterCountry,
    loading,
    filterRmaPerson,
    rmaOptions,
    selectedDate,
    filterDate,
    orderStatus,
    filterClearanceStatus,
    orderStatusOptions
}) => {
    const [isClearedDetailsOpen, setIsClearedDetailsOpen] = useState(false);
    const [getId, setGetId] = useState('');

    // clearance modal
    const showClearedDetailsModal = () => {
        setIsClearedDetailsOpen(true);
    };

    const handleClearedDetailsOk = () => {
        setIsClearedDetailsOpen(false);
    };

    const handleClearedDetailsCancel = () => {
        setIsClearedDetailsOpen(false);
    };

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54
            }}
            spin
        />
    );

    const { RangePicker } = DatePicker;

    const navigate = useNavigate();

    const userRole = localStorage.getItem('userRole');

    const columns = [
        // {
        //     title: 'Date',
        //     dataIndex: 'date',
        //     key: 'date',
        //     width: '100px'
        // },

        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '150px'
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            width: '80px'
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            width: '100px'
        },

        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            width: '100px'
        },
        {
            title: 'Clearance status',
            dataIndex: 'clearancestatus',
            key: 'clearancestatus',
            width: '100px'
        },

        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: '100px'
        }
    ];

    const oderDetails = confirmedList?.map((result) => ({
        key: result?._id,
        date: dateConverter(result?.approvedDate),
        name: result?.name,
        balance: twoDecimalPlaces(result?.balance),
        total: twoDecimalPlaces(result?.total),
        role: result?.role,
        clearancestatus: (
            <Typography
                sx={{
                    border: ' none',
                    backgroundColor: `${result?.balStatus === 'NOT CLEARED' ? '#f74639' : ' #2ad151'}`,
                    height: '40px',
                    width: '129px',
                    padding: '10px 12px',
                    borderRadius: '20px',
                    color: '#FFF',
                    fontWeight: '500',
                    textAlign: 'center',
                    cursor: 'pointer',
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '10px 0'
                }}
            >
                {result?.balStatus}
            </Typography>
        ),

        action: (
            <>
                <Typography
                    onClick={() => {
                        setGetId(result?._id);
                        showClearedDetailsModal();
                    }}
                    sx={{
                        border: ' 1px solid #FF740F',
                        height: '40px',
                        width: '100%',
                        padding: '10px 12px',
                        borderRadius: '8px',
                        color: '#FF740F',
                        fontWeight: '500',
                        textAlign: 'center',
                        cursor: 'pointer',
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '10px 0'
                    }}
                >
                    View details <KeyboardArrowRightIcon sx={{ width: '16px' }} />
                </Typography>
            </>
        )
    }));

    return (
        <div>
            {' '}
            <Table
                loading={loading ? { indicator: <Spin indicator={antIcon} />, spinning: true / false } : ''}
                columns={columns}
                dataSource={oderDetails}
                size="middle"
                pagination={{
                    pageSize: limit,
                    hideOnSinglePage: true
                }}
                scroll={{
                    x: 'calc(700px + 50%)',
                    y: 580
                }}
                title={() => (
                    <>
                        <Space
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                width: '100%'
                            }}
                        >
                            <Box
                                sx={{
                                    width: ' 277px',
                                    margin: '24px 0 0 0px'
                                }}
                            >
                                <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>RMA & CC</Typography>

                                <Select
                                    style={{
                                        width: '100%'
                                    }}
                                    size="large"
                                    placeholder="RMA & CC"
                                    options={rmaOptions}
                                    onChange={(value) => {
                                        filterRmaPerson(value);
                                        filterClearanceStatus([]);
                                    }}
                                    filterOption={(inputValue, option) => {
                                        if (typeof option.label === 'string') {
                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }

                                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }}
                                    showSearch
                                    allowClear
                                />
                            </Box>
                            {/* <Box
                                sx={{
                                    width: ' 277px',
                                    margin: '24px 0 0 0px'
                                }}
                            >
                                <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Date</Typography>
                                <RangePicker
                                    format="YYYY-MM-DD"
                                    style={{
                                        width: '100%',
                                        border: '1px solid #E7EAEB'
                                    }}
                                    size="large"
                                    value={selectedDate}
                                    onChange={filterDate}
                                />
                            </Box> */}
                            {/* <Box
                                sx={{
                                    width: ' 277px',
                                    margin: '24px 0 0 0px'
                                }}
                            >
                                <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Limit</Typography>

                                <Select
                                    style={{
                                        width: '100%'
                                    }}
                                    size="large"
                                    placeholder="Select limit"
                                    options={limitOptions}
                                    onChange={(value) => handleLimit(value)}
                                    filterOption={(inputValue, option) => {
                                        if (typeof option.label === 'string') {
                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }

                                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }}
                                    showSearch
                                    allowClear
                                />
                            </Box> */}
                            <Box
                                sx={{
                                    width: ' 277px',
                                    margin: '24px 0 0 0px'
                                }}
                            >
                                <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>
                                    Search by Clearance Status
                                </Typography>

                                <Select
                                    style={{
                                        width: '100%'
                                    }}
                                    size="large"
                                    placeholder="Clearance Status"
                                    options={[
                                        {
                                            value: 'CLEARED',
                                            label: 'CLEARED'
                                        },
                                        {
                                            value: 'NOT CLEARED',
                                            label: 'NOT CLEARED'
                                        }
                                    ]}
                                    onChange={(value) => {
                                        filterClearanceStatus(value);
                                        filterRmaPerson([]);
                                    }}
                                    filterOption={(inputValue, option) => {
                                        if (typeof option.label === 'string') {
                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }

                                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }}
                                    showSearch
                                    allowClear
                                />
                            </Box>

                            {userRole === 'Admin' ? (
                                <Box
                                    sx={{
                                        width: ' 277px',
                                        margin: '24px 0 0 0px'
                                    }}
                                >
                                    <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Search by Country</Typography>

                                    <Select
                                        style={{
                                            width: '100%'
                                        }}
                                        size="large"
                                        placeholder="Country"
                                        options={countryOptions}
                                        onChange={(value) => filterCountry(value)}
                                        filterOption={(inputValue, option) => {
                                            if (typeof option.label === 'string') {
                                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }

                                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }}
                                        showSearch
                                        allowClear
                                    />
                                </Box>
                            ) : null}
                        </Space>
                    </>
                )}
            />
            <ClearedDetailsModal
                isClearedDetailsOpen={isClearedDetailsOpen}
                showClearedDetailsModal={showClearedDetailsModal}
                handleClearedDetailsOk={handleClearedDetailsOk}
                handleClearedDetailsCancel={handleClearedDetailsCancel}
                getId={getId}
            />
        </div>
    );
};

export default ClearanceTable;
