/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../../components/PageHeader';
import Box from '@mui/material/Box';
import Controls from '../../../components/controls/Controls';
import { Search } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
// import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
// import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
// import useScriptRef from 'hooks/useScriptRef';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
// import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    color: {
        color: '#FF740F'
    },
    backgroundColor: {
        backgroundColor: '#FF740F'
    }
}));

const FleetVehicleRequest = () => {
    const classes = useStyles();
    // const history = useNavigate();
    // const theme = useTheme();
    // const scriptedRef = useScriptRef();
    const [jobcardInfo, setJobCard] = useState([]);
    const [jobcardData, setJobCardData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    // const [startDate, setstartDate] = useState(new Date());
    // const [endDate, setendDate] = useState(new Date());

    // const handleEditClick = (row) => {
    //     if (scriptedRef.current) {
    //         history(`/partsordersfleetupdate/${row._id}`);
    //     }
    // };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 30));
        setPage(0);
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - jobcardInfo.length) : 0;

    const retrieveAllOrdersPart = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.PartsOrdersFleetAllUrls, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setJobCard(data.data);
                        setJobCardData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        // retrieve all customer complaint;
        retrieveAllOrdersPart();
    }, []);
    const handleSearch = (event) => {
        const searchWord = event.target.value;

        const newFilter = jobcardInfo.filter((value) => value._id.toLowerCase().includes(searchWord.toLowerCase()));

        if (searchWord === '') {
            setJobCard(jobcardData);
        } else {
            setJobCard(newFilter);
        }
    };

    return (
        <>
            <PageHeader title="Fleet Management | Part Orders" subTitle="Home | Part Orders" />

            <MainCard title="Part Orders List">
                <Box display="flex" justifyContent="flex-start">
                    <Controls.Input
                        label="Search Order ID."
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearch}
                    />
                    {/* <Box width={20} /> */}

                    {/* <Button component={Link} to="/addpartorderedfleet" variant="contained" size="sm" className={classes.backgroundColor}>
                        Order new part
                    </Button> */}
                </Box>
                <br />

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order Date
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order ID
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Company
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order Status
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Payment Status
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 ? jobcardInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : jobcardInfo).map(
                                (row, index) => (
                                    <TableRow key={row._id}>
                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {toTimestamp(row?.orderDate)}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?._id ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.companyName ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.orderStatusId ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.paymentStatusId ?? ''}
                                        </TableCell>

                                        {/* <TableCell component="th" scope="row">
                                            <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(row)}>
                                                update
                                            </IconButton>
                                        </TableCell> */}
                                    </TableRow>
                                )
                            )}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 70, 150, { label: 'All', value: -1 }]}
                    component="div"
                    count={jobcardInfo.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default FleetVehicleRequest;
