import React, { useEffect, useState } from 'react';

import PageHeader from '../../components/PageHeader';
import MainCard from 'ui-component/cards/MainCard';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
// import Box from '@mui/material/Box';
// import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
// import TablePagination from '@mui/material/TablePagination';
import Card from '../customersuccessdashboard/Card';

const DistributorDashboard = () => {
    const [loading, setLoading] = useState(true);
    // const [allDistributors, setAllDistributors] = useState([]);
    const [lastWeekOrders, setLastWeekOrders] = useState(0);
    const [lastWeekSales, setLastWeekSales] = useState(0);
    const [totalOrders, setTotalOrders] = useState(0);
    const [totalSales, setTotalSales] = useState(0);
    // const [topParts, setTopParts] = useState([]);
    const [list, setList] = useState([]);
    // const [to, setTo] = useState(null);
    // const [from, setFrom] = useState(null);
    // const [limit, setLimit] = useState(5);
    // const margin = 5;

    // const runList = (array) => {
    //     if (array.length > 0) {
    //         const arr = [];
    //         let len = 0;
    //         if (array.length === limit) {
    //             len = limit;
    //         } else if (array.length > limit) {
    //             len = limit;
    //         } else {
    //             len = array.length;
    //         }
    //         array.forEach((item, index) => {
    //             if (index < len) {
    //                 arr.push(item);
    //             }
    //         });

    //         setList(arr);
    //     }
    // };

    useEffect(() => {
        async function getAllDistributors() {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const url = 'http://localhost:3000/api/alldistributors';
            // const url = 'https://api.garagemobility.com/api/alldistributors';

            try {
                const result = await (await fetch(url, config)).json();
                const { message, data } = result;
                // console.log(data);

                if (message === 'success') {
                    // runList(data);
                    // setAllDistributors(data);
                    setLastWeekOrders(data.lastWeekOrders);
                    setLastWeekSales(data.lastWeekSales);
                    setTotalOrders(data.allOrders);
                    setTotalSales(data.allSales);
                    setList(data.topParts);
                    // setTopParts(data.topParts);
                }
                setLoading(false);
            } catch (e) {
                setLoading(false);
                console.log(e);
            }
        }

        // async function getAllDistributorsRange(from, to) {
        //    // const userInfo = localStorage.getItem('userToken');
        //     // const config = {
        //     //     headers: {
        //     //         'auth-token': userInfo,
        //     //         'Content-Type': 'application/json'
        //     //     }
        //     // };
        //     // const url = `http://localhost:3000/api/alldistributors?from=${from}&to=${to}`;
        //     // const url = `https://api.garagemobility.com/api/alldistributors?from=${from}&to={to}`;

        //     try {
        //         // const result = await (await fetch(url, config)).json();
        //         // const { message, data } = result;
        //         // console.log(data);

        //         // if (message === 'success') {
        //         //     runList(data);
        //         //     // setAllDistributors(data);
        //         // }
        //         setLoading(false);
        //     } catch (e) {
        //         setLoading(false);
        //         console.log(e);
        //     }
        // }

        // if (to && from) {
        //     getAllDistributorsRange(from, to);
        // } else {
        //     getAllDistributors();
        // }
        getAllDistributors();
    }, []);

    const tHead = ['#', 'Part Name', 'Number of Orders', 'Order Value'].map((item, index) => (
        <React.Fragment key={index}>
            <th className="font-15">{item}</th>
        </React.Fragment>
    ));

    const tBody =
        list.length === 0 ? (
            <tr>
                <td colSpan="4" className="center-text">
                    Empty List
                </td>
            </tr>
        ) : (
            list.map((item, index) => (
                <React.Fragment key={index}>
                    <tr className="font-13 font-roboto">
                        <td>{index + 1}</td>
                        <td>{item.partid}</td>
                        <td>{item.qty}</td>
                        <td>{(item.qty * item.wholeSalePrice).toFixed(2)}</td>
                    </tr>
                </React.Fragment>
            ))
        );

    return (
        <>
            <PageHeader title="Distributor | Dashboard" subTitle="Home | Distributor | Dashboard" />

            <div className="flex-row-reverse">
                <button type="button" className="filter">
                    Filter By Date
                </button>
            </div>
            <br />
            <br />

            <div className="row justify-content-space-between">
                <div className="col-4">
                    <Card topText="Total number of orders" bottomText={lastWeekOrders} value={totalOrders} percent={25} />
                </div>
                <div className="col-4">
                    <Card topText="Total sales" bottomText={lastWeekSales} value={totalSales} percent={10} />
                </div>
            </div>
            <br />
            <br />

            <MainCard title="Top 5 parts ordered of all times">
                <TableContainer component={Paper}>
                    <Table className="normal-table font-roboto" sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead className="black-text">
                            <TableRow>{tHead}</TableRow>
                        </TableHead>
                        {loading ? (
                            <TableBody>
                                <tr>
                                    <td colSpan="4">
                                        <div className="flex-column justify-content-center align-items-center">
                                            <CircularProgress />
                                        </div>
                                    </td>
                                </tr>
                            </TableBody>
                        ) : (
                            <TableBody>{tBody}</TableBody>
                        )}
                    </Table>
                </TableContainer>
            </MainCard>
            <div />
        </>
    );
};

export default DistributorDashboard;
