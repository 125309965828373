import { createContext, useContext, useReducer } from 'react';
import { authFetch } from 'utils/axios-config';
import { errorToast, successToast } from 'utils/toast';
import { CREATE_NOTE_SUCCESS, CREATE_NOTE_BEGIN } from './delivery-actions';

import priorityReducer from './delivery-reducer';

const initialState = {
    isLoading: false,
    notes: []
};

const DeliveryContext = createContext({ ...initialState });

const DeliveryProvider = ({ children }) => {
    const [state, dispatch] = useReducer(priorityReducer, initialState);

    const handlePdfPreview = async (tableRow) => {
        try {
            const response = await authFetch.post('/deliveryautomation/createorderpdfnew', tableRow);
            const { data } = response.data;
            successToast('Pdf generated. Redirecting...');
            setTimeout(() => {
                window.open(data, '_blank');
            }, 2000);
        } catch (error) {
            const { message } = error.response.data;
            if (error.response.status !== 401) {
                errorToast(message);
            }
        }
    };

    const createDeliveryNote = async (noteObject) => {
        dispatch({ type: CREATE_NOTE_BEGIN });
        try {
            await authFetch.post('/deliveryautomation/generatedeliverynote', noteObject);
            dispatch({ type: CREATE_NOTE_SUCCESS });
            successToast('Note generated successfully');
        } catch (error) {
            const { message } = error.response.data;
            if (error.response.status !== 401) {
                errorToast(message);
            }
        }
    };

    const handleNotePdfDownload = async (tableRow) => {
        try {
            const response = await authFetch.post('/deliveryautomation/deliverynotepdf', tableRow);
            const { data } = response.data;
            successToast('Pdf generated. Redirecting...');
            setTimeout(() => {
                window.open(data, '_blank');
            }, 2000);
        } catch (error) {
            const { message } = error.response.data;
            if (error.response.status !== 401) {
                errorToast(message);
            }
        }
    };

    return (
        <DeliveryContext.Provider
            value={{
                ...state,
                handlePdfPreview,
                createDeliveryNote,
                handleNotePdfDownload
            }}
        >
            {children}
        </DeliveryContext.Provider>
    );
};

const useDeliveryContext = () => {
    return useContext(DeliveryContext);
};

export { DeliveryProvider, initialState, useDeliveryContext };
