import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
// import { notification } from 'antd';
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';
import useScriptRef from 'hooks/useScriptRef';

const AddDistributor = () => {
    const [partnerName, setpartnerName] = useState('');
    const [partnerContact, setpartnerContact] = useState('');
    const [partnerContact2, setpartnerContact2] = useState('');
    const [partnerContact3, setpartnerContact3] = useState('');
    const [location, setlocation] = useState('');
    const [locationLat, setlocationLat] = useState('');
    const [locationLng, setlocationLng] = useState('');
    const [partnerEmail, setpartnerEmail] = useState('');
    const [signupDate, setsignupDate] = useState(new Date());
    const [contactPerson, setcontactPerson] = useState('');
    const [notes, setnotes] = useState('');
    const [loading, setLoading] = useState(false);
    // const [status, setStatus] = useState('');
    const history = useNavigate();
    const scriptedRef = useScriptRef();

    const handleSignupChange = (newValue) => {
        setsignupDate(newValue);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const userInfo = localStorage.getItem('userToken');
        console.log(userInfo);

        const data = {
            partnerName,
            partnerContact,
            partnerContact2,
            partnerContact3,
            location,
            locationLat,
            locationLng,
            partnerEmail,
            signupDate,
            contactPerson,
            notes
        };

        fetch(ApiUrl.AddDistributorUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                Accept: 'application/form-data',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    if (scriptedRef.current) {
                        setTimeout(() => {
                            history('/distributor');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                    console.log(result);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <>
            <Grid item xs={12} sm={6}>
                {/* <Button onClick={toastClick()} variant="contained" color="error">
                    onClick
                </Button> */}

                <PageHeader title="Channel Partners" subTitle="Home | Configuration | Channel Partners  | Distributor" />
                <MainCard title="Add Distributor">
                    <form onSubmit={handleSubmit}>
                        <TextField
                            autoComplete="off"
                            name="partnerName"
                            variant="outlined"
                            required
                            fullWidth
                            id="partnerName"
                            value={partnerName}
                            label="Partner Name"
                            onChange={(e) => setpartnerName(e.target.value)}
                            autoFocus
                        />
                        <br />
                        <br />
                        <TextField
                            autoComplete="off"
                            name="contactPerson"
                            variant="outlined"
                            fullWidth
                            id="contactPerson"
                            value={contactPerson}
                            label="Contact Person"
                            onChange={(e) => setcontactPerson(e.target.value)}
                        />
                        <br />
                        <br />
                        <TextField
                            autoComplete="off"
                            type="number"
                            name="partnerContact"
                            variant="outlined"
                            required
                            fullWidth
                            id="partnerContact"
                            value={partnerContact}
                            label="Contact"
                            onChange={(e) => setpartnerContact(e.target.value)}
                        />
                        <br />
                        <br />
                        <TextField
                            autoComplete="off"
                            type="number"
                            name="partnerContact2"
                            variant="outlined"
                            fullWidth
                            id="partnerContact2"
                            value={partnerContact2}
                            label="Contact 2"
                            onChange={(e) => setpartnerContact2(e.target.value)}
                        />
                        <br />
                        <br />
                        <TextField
                            autoComplete="off"
                            type="number"
                            name="partnerContact3"
                            variant="outlined"
                            fullWidth
                            id="partnerContact3"
                            value={partnerContact3}
                            label="Contact 3"
                            onChange={(e) => setpartnerContact3(e.target.value)}
                        />
                        <br />
                        <br />
                        <TextField
                            autoComplete="off"
                            type="number"
                            name="locationLat"
                            variant="outlined"
                            fullWidth
                            id="locationLat"
                            value={locationLat}
                            label="Latitude"
                            onChange={(e) => setlocationLat(e.target.value)}
                        />
                        <br />
                        <br />
                        <TextField
                            autoComplete="off"
                            type="number"
                            name="locationLng"
                            variant="outlined"
                            fullWidth
                            id="locationLng"
                            value={locationLng}
                            label="longitude"
                            onChange={(e) => setlocationLng(e.target.value)}
                        />
                        <br />
                        <br />
                        <TextField
                            autoComplete="off"
                            name="location"
                            variant="outlined"
                            fullWidth
                            id="location"
                            value={location}
                            label="Address"
                            onChange={(e) => setlocation(e.target.value)}
                        />
                        <br />
                        <br />

                        <TextField
                            autoComplete="off"
                            name="partnerEmail"
                            variant="outlined"
                            fullWidth
                            id="partnerEmail"
                            value={partnerEmail}
                            label="Email"
                            onChange={(e) => setpartnerEmail(e.target.value)}
                        />
                        <br />
                        <br />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack spacing={3}>
                                <DesktopDatePicker
                                    label="Signup date"
                                    inputFormat="MM/dd/yyyy"
                                    value={signupDate}
                                    onChange={handleSignupChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Stack>
                        </LocalizationProvider>

                        <br />
                        <br />
                        <TextField
                            autoComplete="off"
                            name="notes"
                            variant="outlined"
                            multiline
                            rows={8}
                            fullWidth
                            id="notes"
                            label="Notes"
                            onChange={(e) => setnotes(e.target.value)}
                        />
                        <br />
                        <br />
                        <Box display="flex" justifyContent="space-between">
                            <Button component={Link} to="/distributor" type="submit" variant="contained" color="error">
                                Reset
                            </Button>

                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                disabled={!partnerName || !partnerContact || !signupDate || loading}
                            >
                                {loading ? 'Submitting ..' : 'Submit'}
                            </Button>
                        </Box>
                    </form>
                </MainCard>
            </Grid>
        </>
    );
};

export default AddDistributor;
