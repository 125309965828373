import { Divider } from '@mui/material';
import styled from 'styled-components';
import { MdOutlineDateRange } from 'react-icons/md';
// import { IoPlayForwardSharp } from 'react-icons/io5';
import { BsTruck } from 'react-icons/bs';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { useRetailerContext } from 'context/retailer-shop/retailer-context';
import { useAppContext } from 'context/appContext';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

const Wrapper = styled.div`
    background: #fff;
    color: #222;
    padding: 1rem 2rem;
    border-radius: 10px;
    font-family: 'Roboto';

    h5 {
        font-size: 1.2rem;
        color: #ff740f;
        margin-bottom: 20px;
    }
    .item-list {
        margin-top: 20px;
        p {
            line-height: 1.5;
        }
    }
`;

const RecentOrder = () => {
    const { userCurrency } = useAppContext();
    const { mostrecentorder } = useRetailerContext();
    return (
        <Wrapper>
            <h5>Most recent order</h5>
            <Divider />
            <div className="item-list">
                <p>
                    <FaRegMoneyBillAlt style={{ color: '#ff740f', fontSize: '1.2rem' }} /> Total Value: {userCurrency}
                    {mostrecentorder?.totalValue}
                </p>
                <p>
                    <MdOutlineDateRange style={{ color: '#ff740f', fontSize: '1.2rem' }} /> Date: {dayjs(mostrecentorder?.date).format('l')}
                </p>
                <p>
                    <BsTruck style={{ color: '#ff740f', fontSize: '1.2rem' }} /> Time Delivered: {mostrecentorder?.timeDelivered || 'N/A'}
                </p>
                {/* <p>
                    <IoPlayForwardSharp style={{ color: '#ff740f', fontSize: '1.2rem' }} /> Expected Next Order Date: 15th August
                </p> */}
            </div>
        </Wrapper>
    );
};
export default RecentOrder;
