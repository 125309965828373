// assets
import { IconKey, IconSnowflake, IconTruckDelivery } from '@tabler/icons';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import CarCrashIcon from '@mui/icons-material/CarRental';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import InventoryIcon from '@mui/icons-material/Inventory';
import Scale from '@mui/icons-material/Scale';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import vinSearch from '@mui/icons-material/Search';
import ReportIcon from '@mui/icons-material/Report';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import BugReportIcon from '@mui/icons-material/BugReport';
import FactCheckIcon from '@mui/icons-material/FactCheck';
// constant
const icons = {
    IconKey,
    IconTruckDelivery,
    IconSnowflake,
    CarRepairIcon,
    CarCrashIcon,
    Scale,
    DisplaySettingsIcon,
    DashboardIcon,
    InventoryIcon,
    InsertChartIcon,
    vinSearch,
    ReportIcon,
    PeopleAltIcon,
    BugReportIcon,
    FactCheckIcon
};

// ==============================|| EXTRA Distributor MENU ITEMS ||============================== //
const userRole = localStorage.getItem('userRole');

const SeniorMechanic = {
    id: 'seniormechanic',
    title: 'Senior Mechanic',
    type: userRole,
    children: [
        {
            id: 'home',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: icons.DashboardIcon,
            breadcrumbs: false
        },
        {
            id: 'attendance',
            title: 'Attendance',
            type: 'collapse',
            icon: icons.FactCheckIcon,
            children: [
                // {
                //     id: 'imageUpload',
                //     title: 'Image Upload',
                //     type: 'item',
                //     url: '/image-upload',
                //     breadcrumbs: false
                // },

                {
                    id: 'checkIn',
                    title: 'check In',
                    type: 'item',
                    url: '/check-in',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'inspectionReport',
            title: 'Inspection Report',
            type: 'item',
            url: '/inspectionreport',
            icon: icons.ReportIcon,
            breadcrumbs: false
        },
        {
            id: 'jobcard',
            title: 'Job Card',
            type: 'item',
            url: '/jobcard',
            icon: icons.CarRepairIcon,
            breadcrumbs: false
        },
        {
            id: 'garagevinsearch',
            title: 'Vin Search',
            type: 'item',
            url: '/garagevinsearch',
            icon: icons.vinSearch,
            breadcrumbs: false
        },
        {
            id: 'setup',
            title: 'Vehicles',
            type: 'collapse',
            icon: icons.CarRepairIcon,
            children: [
                {
                    id: 'car',
                    title: 'Cars',
                    type: 'item',
                    url: '/car'
                },
                {
                    id: 'carmodel',
                    title: 'Model',
                    type: 'item',
                    url: '/carmodel'
                }
            ]
        },
        {
            id: 'setup',
            title: 'Issues',
            type: 'collapse',
            icon: icons.BugReportIcon,
            children: [
                {
                    id: 'customercomplaint',
                    title: 'Customer Complaint',
                    type: 'item',
                    url: '/customercomplaint'
                },
                {
                    id: 'issuesdiagnosed',
                    title: 'Issues Diagnosed',
                    type: 'item',
                    url: '/issuesdiagnosed'
                }
            ]
        },

        {
            id: 'customers',
            title: 'Customers',
            type: 'item',
            url: '/customer',
            icon: icons.PeopleAltIcon,
            breadcrumbs: false
        },

        {
            id: 'mechusers',
            title: 'Users',
            type: 'item',
            url: '/autoaccount',
            icon: icons.PeopleAltIcon,
            breadcrumbs: false
        }
    ]
};

export default SeniorMechanic;
