import React from 'react';

const DeliveryStatusIcon = ({ message }) => {
    if (message?.agent === 'staff' || message?.agent === 'mira') {
        if (message?.delivery === 'sent') {
            return (
                <span className="status-icon" aria-label="Message sent">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.74882 11.9712C6.3601 12.3635 5.72694 12.3664 5.33462 11.9777L0.740968 7.42617C0.348648 7.03745 0.345731 6.40429 0.734452 6.01197L0.816568 5.92909C1.20529 5.53677 1.83845 5.53386 2.23077 5.92258L5.32083 8.98429C5.71315 9.37301 6.3463 9.37009 6.73503 8.97777L14.9196 0.717469C15.3083 0.32515 15.9414 0.322233 16.3338 0.710954L16.4166 0.79307C16.8089 1.18179 16.8119 1.81495 16.4231 2.20727L6.74882 11.9712Z"
                            fill="#D9DBDE"
                        />
                    </svg>
                </span>
            );
        }
        if (message?.delivery === 'delivered') {
            return (
                <span className="status-icon" aria-label="Message delivered">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.74882 11.9712C6.3601 12.3635 5.72694 12.3664 5.33462 11.9777L0.740968 7.42617C0.348648 7.03745 0.345731 6.40429 0.734452 6.01197L0.816568 5.92909C1.20529 5.53677 1.83845 5.53386 2.23077 5.92258L5.32083 8.98429C5.71315 9.37301 6.3463 9.37009 6.73503 8.97777L14.9196 0.717469C15.3083 0.32515 15.9414 0.322233 16.3338 0.710954L16.4166 0.79307C16.8089 1.18179 16.8119 1.81495 16.4231 2.20727L6.74882 11.9712Z"
                            fill="#D9DBDE"
                        />
                        <path
                            d="M6.74882 17.2622C6.3601 17.6545 5.72694 17.6574 5.33462 17.2687L0.740968 12.7172C0.348648 12.3285 0.345731 11.6953 0.734452 11.303L0.816568 11.2201C1.20529 10.8278 1.83845 10.8249 2.23077 11.2136L5.32083 14.2753C5.71315 14.664 6.3463 14.6611 6.73503 14.2688L14.9196 6.00848C15.3083 5.61617 15.9414 5.61325 16.3338 6.00197L16.4166 6.08409C16.8089 6.47281 16.8119 7.10596 16.4231 7.49828L6.74882 17.2622Z"
                            fill="#D9DBDE"
                        />
                    </svg>
                </span>
            );
        }
        if (message?.delivery === 'read') {
            return (
                <span className="status-icon" aria-label="Message read">
                    <svg width="20" height="10" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.625 7.625L5.375 11.375M10.625 5.375L14.375 1.625M7.625 7.625L11.375 11.375L20.375 1.625"
                            stroke="#00AA55"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </span>
            );
        }
    }
    return null;
};

export default DeliveryStatusIcon;
