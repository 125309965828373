import { Button as MuiButton, Paper } from '@mui/material';
import { useEffect, useState } from 'react';

import BatchHeaderWrapper from 'assets/Wrappers/BatchHeaderWrapper';
import Button from '@mui/material/Button';
import { Container } from 'react-grid-system';
import CreateBatchModal from './CreateBatchModal';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import LoadingButton from '@mui/lab/LoadingButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useBatchContext } from 'context/order-batching/batchContext';

const BatchCustomHeader = () => {
    const {
        getAllBatchStatuses,
        batchStatuses,
        getTodayOrders,
        todayOrders,
        isLoading,
        filterBatch,
        handleDashboardView,
        orderType,
        handleOrderChange
    } = useBatchContext();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const roleid = localStorage.getItem('userRole');

    const handleEvent = (e, picker) => {
        setStartDate(picker.startDate._d.toISOString());
        setEndDate(picker.endDate._d.toISOString());
    };
    const filterOptions = { startDate, endDate, orderType };

    const handleBatchFilter = async () => {
        filterBatch(filterOptions);
    };

    const handBatchReset = () => {
        window.location = '/';
    };

    useEffect(() => {
        getTodayOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderType]);

    useEffect(() => {
        getAllBatchStatuses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event) => {
        const { value } = event.target;
        handleOrderChange(value);
        localStorage.setItem('currentOrderType', value);
    };

    return (
        <BatchHeaderWrapper>
            <Paper elevation={0} className="custom-paper">
                <Container>
                    <div className="header-wrapper">
                        <div>
                            <h4>Fulfillment | Order Batching</h4>
                            <h5>Home | Fullfillment | Order Batching | Dashboard</h5>
                        </div>
                        <div className="filter-page">
                            <div>
                                <select className="form-select custom-select" onChange={(e) => handleDashboardView(e.target.value)}>
                                    <option value="table" defaultValue="table">
                                        Table view
                                    </option>
                                    <option value="card">Card view</option>
                                </select>
                            </div>
                            &nbsp;
                            {/* Second filter */}
                            <div>
                                <select className="form-select custom-select" onChange={handleChange} style={{ background: '#0B2447' }}>
                                    <option value="Regular">Regular Orders</option>
                                    <option value="SalesDrive">Sales Drive Orders</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </Container>
            </Paper>
            <Container>
                <div className="batch-filters">
                    <div className="filters">
                        <div className="date-ranger-selector">
                            <DateRangePicker
                                initialSettings={{ startDate, endDate }}
                                onEvent={handleEvent}
                                onApply={handleBatchFilter}
                                onCancel={handBatchReset}
                            >
                                <LoadingButton
                                    variant="contained"
                                    disableElevation
                                    size="medium"
                                    className="custom-date-picker"
                                    loadingIndicator="Loading…"
                                    loading={isLoading}
                                >
                                    Filter By Date
                                </LoadingButton>
                            </DateRangePicker>
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                startIcon={<RefreshIcon />}
                                className="refresh-btn"
                                disabled={isLoading}
                                onClick={handBatchReset}
                            >
                                {isLoading ? 'Synchronizing...' : 'Refresh'}
                            </Button>
                        </div>
                    </div>
                    <div>
                        {roleid === 'CustomerSuccess' ? (
                            ''
                        ) : (
                            <MuiButton variant="contained" onClick={handleShow} className="create-batch">
                                Create new batch
                            </MuiButton>
                        )}
                    </div>
                    <CreateBatchModal handleClose={handleClose} show={show} statuses={batchStatuses} orders={todayOrders} />
                </div>
            </Container>
        </BatchHeaderWrapper>
    );
};
export default BatchCustomHeader;
