/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../../components/PageHeader';
import Box from '@mui/material/Box';
import Controls from '../../../components/controls/Controls';
import { Search } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const FleetServiceId = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [fleetserviceidInfo, setFleetServiceId] = useState([]);
    const [fleetserviceIdData, setfleetServiceIdData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleEditClick = (row) => {
        if (scriptedRef.current) {
            history(`/viewfleetserviceidsinfo/${row._id}`);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    // good

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - fleetserviceidInfo.length) : 0;

    const retrieveAllFleetServiceId = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.FleetServiceIdUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setFleetServiceId(data.data);
                        setfleetServiceIdData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        // retrieve all fleet service id;
        retrieveAllFleetServiceId();
    }, []);

    const handleDeleteClick = (row) => {
        swal({
            title: 'Are you sure?',
            text: `Once deleted, you will not be able to recover this Fleet Service Id!(${row.name})`,
            icon: 'warning',
            buttons: true,
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                swal('Poof! The Fleet Service Id has been deleted!', {
                    icon: 'success'
                });

                const data = {
                    id: row._id
                };
                const userInfo = localStorage.getItem('userToken');

                fetch(ApiUrl.RemoveFleetServiceIdUrl, {
                    method: 'DELETE',
                    headers: {
                        'auth-token': userInfo,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                })
                    .then((res) => res.json())
                    .then((result) => {
                        if (result.status === true) {
                            if (scriptedRef.current) {
                                retrieveAllFleetServiceId();
                            }
                        } else {
                            toast.error(result.data);
                        }
                    });
            } else {
                swal('The Fleet Service Id is safe!');
            }
        });
    };

    const handleSearch = (event) => {
        const searchWord = event.target.value;

        const newFilter = fleetserviceidInfo.filter((value) => value.name.toLowerCase().includes(searchWord.toLowerCase()));

        if (searchWord === '') {
            setFleetServiceId(fleetserviceIdData);
        } else {
            setFleetServiceId(newFilter);
        }
    };

    return (
        <>
            <PageHeader title="Fleet Service Id" subTitle="Home | Fleet Service Id | Setup Management | Fleet Service Id" />

            <MainCard title="Fleet Service Id List">
                <Box display="flex" justifyContent="space-between">
                    <Controls.Input
                        label="Search Fleet Service Id"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearch}
                    />
                    <Box width={10} />

                    <Button component={Link} to="/addfleetserviceid" variant="contained" size="sm" className={classes.item}>
                        Add
                    </Button>
                </Box>
                <br />

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Name
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Status
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Date
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? fleetserviceidInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : fleetserviceidInfo
                            ).map((row, index) => (
                                <TableRow key={row._id}>
                                    <TableCell component="th" scope="row">
                                        {fleetserviceidInfo.length - index}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.status}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {toTimestamp(row.createdAt)}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(row)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton aria-label="delete" color="error" onClick={() => handleDeleteClick(row)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    component="div"
                    count={fleetserviceidInfo.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default FleetServiceId;
