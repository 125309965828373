import React from 'react';
import { Divider, FormControl, TextField } from '@mui/material';
import styled from 'styled-components';
import InventoryTable from './InventoryTable';
import { useRetailerContext } from 'context/retailer-shop/retailer-context';

const Wrapper = styled.div`
    background: #fff;
    color: #222;
    padding: 1rem 2rem;
    border-radius: 10px;
    font-family: 'Roboto';
    margin-bottom: 30px;

    .inventory {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 30px;
        gap: 15px;
    }
    @media screen and (max-width: 500px) {
        .inventory {
            flex-direction: column;
        }
    }
    .search {
        max-width: 25rem;
    }

    .custom-date-picker {
        background: #193855;
    }
    h5 {
        font-size: 1.2rem;
        color: #ff740f;
        margin-bottom: 20px;
    }
`;

const Inventory = () => {
    const { inventory } = useRetailerContext();
    const [search, setSearch] = React.useState('');

    const filteredInventory = inventory.filter((item) => item?.partName.toLowerCase().includes(search.toLowerCase()));
    return (
        <Wrapper className="shadow-sm ">
            <h5>Inventory</h5>
            <Divider />
            <div className="inventory">
                <FormControl fullWidth style={{ marginBottom: '10px' }}>
                    <TextField
                        id="outlined-basic"
                        label="Search part"
                        variant="outlined"
                        value={search}
                        type="search"
                        className="search"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </FormControl>
            </div>
            <InventoryTable inventory={filteredInventory} />
        </Wrapper>
    );
};
export default Inventory;
