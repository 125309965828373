import { AppProvider } from './appContext';
import { PriorityProvider } from './order-priority/priority-context';
import { BatchProvider } from './order-batching/batchContext';
import { DeliveryProvider } from './delivery-notes/delivery-context';
import { RetailerProvider } from './retailer-shop/retailer-context';
import { LoyaltyProvider } from './loyalty/loyalty-context';
import { DarkStoreProvider } from './darkstore-inventory/darkstore-context';

const AppContextProvider = ({ children }) => {
    return (
        <AppProvider>
            <DarkStoreProvider>
                <LoyaltyProvider>
                    <RetailerProvider>
                        <BatchProvider>
                            <PriorityProvider>
                                <DeliveryProvider>{children}</DeliveryProvider>
                            </PriorityProvider>
                        </BatchProvider>
                    </RetailerProvider>
                </LoyaltyProvider>
            </DarkStoreProvider>
        </AppProvider>
    );
};

export default AppContextProvider;
