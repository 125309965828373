import { Button } from '@mui/material';
import { useAppContext } from 'context/appContext';
import { useState, useRef } from 'react';
import styled from 'styled-components';
import { Editor } from '@tinymce/tinymce-react';
import { authFetch } from 'utils/axios-config';
import { errorToast, successToast } from 'utils/toast';

const Wrapper = styled.div`
    padding: 1rem 2rem;
    .note {
        max-width: 400px;
    }
    .actions {
        max-width: 30rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.5rem;
    }
`;

const NoteAction = ({ data, id }) => {
    const editorRef = useRef(null);

    const { userRole } = useAppContext();
    const [noteLoading, setNoteLoading] = useState(false);
    const [acceptVariable, setAcceptVariable] = useState('Confirmed');
    const [declineVariable, setDeclinetVariable] = useState('Declined');
    const { notes } = data;
    const [salesNotes, setNotes] = useState(notes);
    // console.log(setAcceptVariable('Confirmed'));
    // console.log(setDeclinetVariable('Declined'));

    async function handleNoteConfirmation(variable) {
        const object = {
            id,
            status: variable,
            orderId: data?.orderId?._id,
            notes: salesNotes
        };
        setNoteLoading(true);
        try {
            await authFetch.put('/deliveryautomation/acceptdeclinenote', object);
            setNoteLoading(false);
            const emojiSelector = variable === 'Confirmed' ? ' 👏' : '  👎';
            successToast(`Order ${variable}${emojiSelector} successfully`);
            setTimeout(() => {
                window.location.href = '/sales/deliveries';
            }, 3000);
        } catch (error) {
            const { message } = error.response.data;
            if (error.response.status !== 401) {
                errorToast(message);
            }
        }
    }
    return (
        <Wrapper>
            <p>Attach Notes</p>
            <div>
                <Editor
                    onInit={(editor) => {
                        editorRef.current = editor;
                    }}
                    initialValue={notes}
                    value={salesNotes}
                    onEditorChange={(newValue) => setNotes(newValue)}
                    init={{
                        height: 400,
                        menubar: true,
                        plugins: [
                            'advlist',
                            'autolink',
                            'lists',
                            'link',
                            'image',
                            'charmap',
                            'preview',
                            'anchor',
                            'searchreplace',
                            'visualblocks',
                            'code',
                            'fullscreen',
                            'insertdatetime',
                            'media',
                            'table',
                            'code',
                            'help',
                            'wordcount'
                        ],
                        toolbar:
                            'undo redo | blocks | ' +
                            'bold italic forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help'
                    }}
                />
            </div>
            {userRole === 'Sales' && (
                <>
                    {/* Put tinymce here */}
                    <div className="actions">
                        <Button
                            variant="contained"
                            size="small"
                            style={{ background: '#ff740f' }}
                            onClick={() => handleNoteConfirmation(declineVariable)}
                            disabled={noteLoading}
                        >
                            {noteLoading ? 'Sending...' : 'Decline'}
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            style={{ background: '#193855' }}
                            onClick={() => handleNoteConfirmation(acceptVariable)}
                            disabled={noteLoading}
                        >
                            {noteLoading ? 'Sending...' : 'Confirm'}
                        </Button>
                    </div>
                </>
            )}
        </Wrapper>
    );
};
export default NoteAction;
