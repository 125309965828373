/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
import Autocomplete from '@mui/material/Autocomplete';
// import { notification } from 'antd';
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { FormControl, InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import useScriptRef from 'hooks/useScriptRef';

const AddDarkStoreArea = () => {
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState('');
    const [countries, setCountries] = useState([]);
    const [name, setname] = useState('');
    const [lat, setlat] = useState('');
    const [lng, setlng] = useState('');
    const [cityId, setcityId] = useState('');
    const [cityData, setCityData] = useState([]);
    const [threshold, setthreshold] = useState('');
    const [salesperson, setsalesperson] = useState('');
    const [salesTeamInfo, setSalesTeam] = useState([]);
    const [salesTeamId, setsalesTeamId] = useState('');
    const history = useNavigate();
    const scriptedRef = useScriptRef();

    const retrieveAlLsalesTeam = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.SalesTeamActiveSalesPage, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setSalesTeam(data.data);
                        // console.log('Sales team', data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllActiveCountry = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.ActiveCountryUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    const retrieveAllCity = async (id) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.CityFilterUrl + id, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCityData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const filterCountry = (event) => {
        const newFilter = countries.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        newFilter.forEach((option) => {
            retrieveAllCity(option?._id);
        });
    };
    const handleCityChange = (event) => {
        setcityId(event.target.value);
    };
    const handleSalesChange = (event) => {
        setsalesTeamId(event.target.value);
        const newFilter = salesTeamInfo.filter((value) => value._id.includes(event.target.value));
        newFilter.forEach((option) => {
            if (option?.country === 'Nigeria') {
                const fullname = `${option.firstName} ${option.lastName}`;
                setsalesperson(fullname);
            } else if (option?.country === 'Cameroon') {
                const fullname = `${option.firstName} ${option.lastName}`;
                setsalesperson(fullname);
            } else {
                setsalesperson(option.name);
            }
        });
    };

    useEffect(() => {
        // retrieve all sales associate;
        retrieveAllActiveCountry();
        retrieveAlLsalesTeam();
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const userInfo = localStorage.getItem('userToken');

        const data = {
            name,
            country,
            lat,
            lng,
            cityId,
            salesperson,
            threshold
        };

        fetch(ApiUrl.AddDarkStoreAreaUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    if (scriptedRef.current) {
                        setTimeout(() => {
                            history('/darkstorearea');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                    console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <br />
                    <PageHeader title="Configuration | Dark Store Area" subTitle="Home | Configuration | Dark Store Area | Add" />
                    <br />
                    <MainCard title="Add Dark Store Area">
                        <form onSubmit={handleSubmit}>
                            <TextField
                                autoComplete="off"
                                name="name"
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                value={name}
                                label="Dark Store Name"
                                onChange={(e) => setname(e.target.value)}
                            />
                            <br />
                            <br />
                            <Autocomplete
                                id="country-select-demo"
                                autoHighlight
                                value={country}
                                onChange={(event, newValue) => {
                                    setCountry(newValue);
                                    filterCountry(newValue);
                                }}
                                options={countries.map((option) => option.name)}
                                isOptionEqualToValue={(option, value) => option.code === value.code}
                                renderInput={(params) => <TextField {...params} label="Choose a country *" />}
                            />
                            <br />
                            <br />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Choose a City *</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={cityId}
                                    label="City"
                                    onChange={handleCityChange}
                                >
                                    {cityData.map((attr) => (
                                        <MenuItem key={attr._id} value={attr._id}>
                                            {attr?.city ?? ''}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <br />
                            <br />

                            <TextField
                                autoComplete="off"
                                name="lat"
                                variant="outlined"
                                required
                                fullWidth
                                id="lat"
                                value={lat}
                                label="Latitude"
                                onChange={(e) => setlat(e.target.value)}
                            />
                            <br />
                            <br />
                            <TextField
                                autoComplete="off"
                                name="lng"
                                variant="outlined"
                                required
                                fullWidth
                                id="lng"
                                value={lng}
                                label="longitude"
                                onChange={(e) => setlng(e.target.value)}
                            />
                            <br />
                            <br />
                            <TextField
                                autoComplete="off"
                                name="threshold"
                                variant="outlined"
                                required
                                fullWidth
                                id="threshold"
                                value={threshold}
                                label="threshold"
                                onChange={(e) => setthreshold(e.target.value)}
                            />
                            <br />
                            <br />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">RM</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={salesTeamId}
                                    required
                                    label="RM"
                                    onChange={handleSalesChange}
                                >
                                    {salesTeamInfo.map((sales) => (
                                        <MenuItem key={sales._id} value={sales._id}>
                                            {sales?.name} {sales?.firstName}
                                            {sales?.lastName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <br />
                            <br />

                            <Box display="flex" justifyContent="space-between">
                                <Button component={Link} to="/darkstorearea" type="submit" variant="contained" color="error">
                                    Reset
                                </Button>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    disabled={!name || !country || !cityId || !lat || !lng || !threshold || !salesperson || loading}
                                >
                                    {loading ? 'Submitting ..' : 'Submit'}
                                </Button>
                            </Box>
                        </form>
                    </MainCard>
                </Grid>
            </Grid>
        </>
    );
};

export default AddDarkStoreArea;
