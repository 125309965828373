import { Button, Collapse, Input, Modal, Select, Spin, Table, Tag, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { ArrowLeftOutlined, DollarOutlined, LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { decrypt, encrypt } from '../../utils/encrypt';
import { toast } from 'react-toastify';
import ApiUrl from '../../network';

const baseUrl = 'https://apistaging.garagemobility.com/api';
const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

const process = '../../moving_clock.png';
const success = '../../lets-icons_check-fill.png';
const failed = '../../ic_baseline-cancel.png';
const dollarBlue = '../../dollarblue.png';
const dollarGreen = '../../dollargreen.png';
const dollarRed = '../../dollarred.png';
const userRole = localStorage.getItem('userRole');

export const Index = () => {
    const [loading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState([]);
    const [totalPaymentsMade, setTotalPaymentsMade] = useState(0);
    const [totalPaymentsMadeToday, setTotalPaymentsMadeToday] = useState(0);
    const [pendingTransactions, setPendingTransactions] = useState(0);
    const [failedTransactions, setFailedTransactions] = useState(0);
    const [successTransactions, setSuccessTransactions] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [responseState, setResponseState] = useState('');
    const [response, setResponseModal] = useState(false);
    const [timeLeft, setTimeLeft] = useState(10);
    const [referencePick, setReferencePick] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filters, setFilters] = useState({
        startDate: '',
        endDate: '',
        rma: '',
        status: '',
        momonumber: '',
        network: ''
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [rmas, setRmas] = useState([]);

    // Function to reset the timer
    const resetTimer = () => {
        const initialTime = Math.random() < 0.5 ? 10 : 15;
        setTimeLeft(initialTime);
    };

    // Countdown logic
    useEffect(() => {
        if (timeLeft === 0) {
            // window.location.href = '/'; // Redirect when timer hits 0
            return;
        }

        const timer = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000); // Update every second

        // eslint-disable-next-line consistent-return
        return () => clearInterval(timer); // Cleanup interval on component unmount
    }, [timeLeft]);

    // Format time left as MM:SS
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secondsLeft = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secondsLeft).padStart(2, '0')}`;
    };

    // Load filters from localStorage on component mount
    useEffect(() => {
        const savedFilters = localStorage.getItem('payments_filters');
        if (savedFilters) {
            const parsedFilters = JSON.parse(savedFilters);
            setFilters(parsedFilters);
            setStartDate(parsedFilters?.startDate);
            setEndDate(parsedFilters?.endDate);
        }
    }, []);

    const makeRequest = useCallback(async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            let url = ``;

            const urlParams = new URLSearchParams({
                ...filters,
                limit: perPage,
                page: currentPage
            });

            if (userRole === 'Finance Manager') {
                url = `${ApiUrl.BaseUrl}/mainorders/financemomopayments?${urlParams.toString()}`;
            } else {
                url = `${ApiUrl.BaseUrl}/mainorders/rmaccmomopayments?${urlParams.toString()}`;
            }

            const response = await fetch(url, config);
            const data = await response.json();
            const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));
            // setTotalRecords(decryptedData?.totalRecords);
            // setTotalPaymentsMade(decryptedData?.totalPaymentsMade);
            // setTotalPaymentsMadeToday(decryptedData?.totalPaymentsMadeToday);
            setFailedTransactions(data?.totalFailedPayment);
            setSuccessTransactions(data?.totalSuccessfulPayment);
            setPendingTransactions(data?.totalPendingPayment);
            setTransactions(decryptedData);
            setLoading(false);
        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }, [filters, perPage, currentPage]);
    const fetchPodsData = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/salesdrivepod/salesdrivepod`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        // Extract all salespersons from the pods using flatMap
                        const allSalespersons = result.flatMap((pod) => pod.salesperson);

                        setRmas(allSalespersons);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        toast.error(error);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        makeRequest().then((r) => r);
        fetchPodsData().then((r) => r);
    }, [makeRequest]);

    const transactionSource = transactions.map((transaction) => {
        return {
            key: transaction._id,
            retailer: transaction?.orderId?.channelPartnerId,
            rma: transaction?.userId,
            phone: transaction?.momoNumber,
            amount: transaction.momoAmt,
            reference: transaction.momoReference,
            status: transaction.momoStatus,
            momoNetwork: transaction.momoNetwork,
            // orderNumber: transaction?.orderDetails?.fulOrderId,
            // orderDate: transaction?.orderDetails?.orderDate,
            date: transaction.createdAt
        };
    });

    const confirmPaymentHandler = async (reference) => {
        setResponseState('processing');
        setResponseModal(true);
        resetTimer();
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        const datas = {
            reference
        };
        const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);

        try {
            await fetch(`${baseUrl}/order/v2/payments/paga/status`, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify({ payload })
            })
                .then((data) => data.json())
                .then((data) => {
                    const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));
                    if (decryptedData.status.toLowerCase() === 'failed') {
                        toast.error(decryptedData.message);
                        setResponseModal(true);
                        setResponseState('failed');
                    } else if (decryptedData.status.toLowerCase() === 'pending') {
                        toast.warning(decryptedData.message);
                        // setResponseModal(true);
                        setResponseState('processing');
                    } else {
                        setResponseState('success');
                        toast.success(decryptedData.message);
                    }
                });
        } catch (error) {
            const errorMessage = error.message;
            toast.error(errorMessage);
            setResponseState('failed');
        }
    };

    const columns = [
        {
            title: 'RMA',
            dataIndex: 'rma',
            key: 'rma'
        },
        {
            title: 'Retailer',
            dataIndex: 'retailer',
            key: 'retailer'
        },
        {
            title: 'Network',
            dataIndex: 'momoNetwork',
            key: 'momoNetwork'
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text) => <span>{`${text}`}</span>
        },
        // {
        //     title: 'Order number',
        //     dataIndex: 'orderNumber',
        //     key: 'orderNumber',
        //     render: (text) => <span>{text}</span>
        // },
        // {
        //     title: 'Order date',
        //     dataIndex: 'orderDate',
        //     key: 'orderDate',
        //     render: (text) => <span>{dayjs(text).format('DD/MM/YYYY')}</span>
        // },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => {
                const color = text.toLowerCase() === 'success' ? 'green' : text.toLowerCase() === 'failed' ? 'red' : 'blue';
                return <Tag color={color}>{text}</Tag>;
            }
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text) => <span>{dayjs(text).format('DD/MM/YYYY')}</span>
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <div>
                    {record.status.toLowerCase() === 'pending approval' ? (
                        <Button
                            style={{
                                backgroundColor: 'transparent',
                                color: '#FF740F',
                                border: '1px solid #FF740F',
                                borderRadius: '4px',
                                padding: '8px 16px',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                            type="primary"
                            onClick={() => {
                                setReferencePick(record.reference);
                                confirmPaymentHandler(record?.reference);
                            }}
                        >
                            <ArrowLeftOutlined /> Check payment
                        </Button>
                    ) : null}
                </div>
            )
        }
    ];

    if (userRole !== 'Finance Manager') {
        columns.splice(0, 1);
    }

    const resetFilters = () => {
        setFilters({
            startDate: '',
            endDate: '',
            search: '',
            status: ''
        });
        setStartDate('');
        setEndDate('');
        localStorage.removeItem('payments_filters');
        makeRequest().then((r) => r);
    };

    const applyFilters = () => {
        localStorage.setItem('payments_filters', JSON.stringify(filters));
        makeRequest().then((r) => r);
    };

    return (
        <div>
            <div className="m-header">
                <h1 style={{ color: '#0A2938', display: 'flex', alignItems: 'center' }}>Momo Payment Confirmation</h1>
            </div>
            <div>
                <div className="payment-card">
                    <div className="total-payment">
                        <span>
                            {' '}
                            <img src={dollarGreen} alt="dollar" className="dollar-img" />
                        </span>
                        <span>
                            <p style={{ color: '#FFF' }}>{successTransactions}</p>
                            <small>
                                Number of successful <br /> payment
                            </small>
                        </span>
                    </div>
                    <div>
                        <span>
                            {' '}
                            <img src={dollarRed} alt="dollar" className="dollar-img" />
                        </span>
                        <span>
                            <p style={{ color: '#FFF' }}>{failedTransactions}</p>
                            <small>
                                Number of Failed <br />
                                payment
                            </small>
                        </span>
                    </div>
                    <div className="pending-payment">
                        <span>
                            {' '}
                            <img src={dollarBlue} alt="dollar" className="dollar-img" />
                        </span>
                        <span>
                            <p style={{ color: '#FFF' }}>{pendingTransactions}</p>
                            <small>
                                Number of Pending <br /> payment
                            </small>
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <Collapse accordion>
                    <Collapse.Panel key={1} header="Hide filters" className="mb-4">
                        <div className="batchFilters">
                            {userRole === 'Finance Manager' ? (
                                <div className="mr-2">
                                    <Select
                                        className="filterInput"
                                        style={{
                                            width: 300
                                        }}
                                        placeholder="Search RMA"
                                        showSearch
                                        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        options={rmas.map((rma) => ({
                                            label: `${rma.firstName.trim()} ${rma.lastName.trim()}`,
                                            value: rma.userId
                                        }))}
                                        value={filters.rma || undefined}
                                        onChange={(value) => {
                                            setFilters({
                                                ...filters,
                                                rma: value
                                            });
                                            localStorage.setItem(
                                                'payments_filters',
                                                JSON.stringify({
                                                    ...filters,
                                                    rma: value
                                                })
                                            );
                                            makeRequest();
                                        }}
                                    />
                                </div>
                            ) : null}
                            <div className="mr-2">
                                <DatePicker.RangePicker
                                    className="filterInput"
                                    style={{
                                        width: 300
                                    }}
                                    placeholder={['Start date', 'End date']}
                                    value={startDate && endDate ? [dayjs(startDate, 'YYYY-MM-DD'), dayjs(endDate, 'YYYY-MM-DD')] : null}
                                    onChange={(date, dateString) => {
                                        setFilters({
                                            ...filters,
                                            startDate: dateString[0],
                                            endDate: dateString[1]
                                        });
                                        setStartDate(dateString[0]);
                                        setEndDate(dateString[1]);
                                        localStorage.setItem(
                                            'payments_filters',
                                            JSON.stringify({
                                                ...filters,
                                                startDate: dateString[0],
                                                endDate: dateString[1]
                                            })
                                        );
                                        makeRequest();
                                    }}
                                />
                            </div>
                            <div className="mr-2">
                                <Select
                                    className="filterInput"
                                    style={{
                                        width: 300
                                    }}
                                    placeholder="Select status"
                                    value={filters.status || undefined}
                                    onChange={(value) => {
                                        setFilters({
                                            ...filters,
                                            status: value
                                        });
                                        localStorage.setItem(
                                            'payments_filters',
                                            JSON.stringify({
                                                ...filters,
                                                status: value
                                            })
                                        );
                                        makeRequest();
                                    }}
                                    options={[
                                        { label: 'Success', value: 'Success' },
                                        { label: 'Failed', value: 'Failed' },
                                        { label: 'Pending', value: 'Pending Approval' }
                                    ]}
                                />
                            </div>
                            <div className="mr-2">
                                <Select
                                    className="filterInput"
                                    style={{
                                        width: 300
                                    }}
                                    placeholder="Select network"
                                    value={filters.network || undefined}
                                    onChange={(value) => {
                                        setFilters({
                                            ...filters,
                                            network: value
                                        });
                                        localStorage.setItem(
                                            'payments_filters',
                                            JSON.stringify({
                                                ...filters,
                                                network: value
                                            })
                                        );
                                        makeRequest();
                                    }}
                                    options={[
                                        { label: 'MTN', value: 'MTN' },
                                        { label: 'ATG', value: 'ATG' },
                                        { label: 'VODAFONE', value: 'VODAFONE' }
                                    ]}
                                />
                            </div>
                            <div className="mr-2">
                                <Input
                                    className="filterInput"
                                    style={{
                                        width: 300
                                    }}
                                    placeholder="Mobile number"
                                    value={filters.momonumber || undefined}
                                    onChange={(e) => {
                                        setFilters({
                                            ...filters,
                                            momonumber: e.target.value
                                        });
                                        localStorage.setItem(
                                            'payments_filters',
                                            JSON.stringify({
                                                ...filters,
                                                momonumber: e.target.value
                                            })
                                        );
                                        makeRequest();
                                    }}
                                />
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                {filters.startDate || filters.rma || filters.status || filters.network || filters.momonumber ? (
                                    <Button
                                        style={{
                                            color: '#FF740F',
                                            border: '1px solid #FF740F'
                                        }}
                                        onClick={applyFilters}
                                    >
                                        Apply filter
                                    </Button>
                                ) : null}
                                {filters.startDate || filters.rma || filters.status || filters.network || filters.momonumber ? (
                                    <Button
                                        style={{
                                            color: '#FF740F',
                                            border: '1px solid #FF740F',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                        className="ml-2"
                                        onClick={resetFilters}
                                    >
                                        <ReloadOutlined />
                                    </Button>
                                ) : null}
                            </div>
                        </div>
                    </Collapse.Panel>
                </Collapse>
            </div>
            <div className="table-responsive">
                <Table rowKey={(record) => record.key} columns={columns} dataSource={transactionSource} loading={loading} />
            </div>
            <Modal open={response} onCancel={() => setResponseModal(null)} footer={null}>
                {responseState === 'processing' && (
                    <div className="text-center">
                        <img
                            style={{ width: '100px', height: '100px', marginBottom: '20px', objectFit: 'contain' }}
                            src={process}
                            alt="processing"
                        />
                        <p style={{ color: '#0A2938', fontSize: '22px', fontWeight: 'bold' }}>
                            Kindly wait as we process <br /> payment
                        </p>
                        <p
                            style={{
                                fontSize: '16px',
                                color: '#9DA9AF',
                                lineHeight: '24px'
                            }}
                        >
                            Payment is currently processing, if payment <br /> process is not complete under the estimated time,kindly try
                            again later.
                        </p>
                        <small
                            style={{
                                color: '#00AA55',
                                fontSize: '14px',
                                display: 'block',
                                marginTop: '20px',
                                marginBottom: '20px'
                            }}
                        >
                            Estimated time: <span style={{ color: '#4B6EFF', marginLeft: '8px' }}>{formatTime(timeLeft)}</span>
                        </small>
                        {timeLeft <= 0 && (
                            <div style={{ marginTop: '20px' }}>
                                <Button
                                    onClick={() => confirmPaymentHandler(referencePick)}
                                    style={{
                                        color: '#FF740F',
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        borderRadius: '5px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Try Again
                                </Button>
                                <Button
                                    onClick={() => setResponseModal(false)}
                                    style={{
                                        color: '#FF740F',
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        borderRadius: '5px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        )}
                    </div>
                )}
                {responseState === 'success' && (
                    <div className="text-center">
                        <img
                            style={{ width: '100px', height: '100px', marginBottom: '20px', objectFit: 'contain' }}
                            src={success}
                            alt="processing"
                        />
                        <p style={{ color: '#0A2938', fontSize: '22px', fontWeight: 'bold' }}>Payment Successful!</p>
                        <p
                            style={{
                                fontSize: '16px',
                                color: '#9DA9AF',
                                lineHeight: '24px'
                            }}
                        >
                            Your Payment has been successfully <br /> completed, kindly return back to home screen
                        </p>
                        <Button
                            type="primary"
                            size="large"
                            style={{
                                width: 'fit-content',
                                marginTop: '20px',
                                backgroundColor: '#FF740F'
                            }}
                            onClick={() => setResponseModal(false)}
                        >
                            Return to home
                            <Spin
                                className="ml-2"
                                indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />}
                                size="small"
                            />
                        </Button>
                    </div>
                )}

                {responseState === 'failed' && (
                    <div className="text-center">
                        <img
                            style={{ width: '100px', height: '100px', marginBottom: '20px', objectFit: 'contain' }}
                            src={failed}
                            alt="processing"
                        />
                        <p style={{ color: '#0A2938', fontSize: '22px', fontWeight: 'bold' }}>Payment Declined!</p>
                        <p
                            style={{
                                fontSize: '16px',
                                color: '#9DA9AF',
                                lineHeight: '24px'
                            }}
                        >
                            Your Payment has been declined, <br /> kindly take required action
                        </p>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexDirection: 'column',
                                width: '100%',
                                marginTop: '20px'
                            }}
                        >
                            <Button
                                type="primary"
                                size="large"
                                style={{
                                    width: 'fit-content',
                                    marginTop: '20px',
                                    backgroundColor: '#FF740F'
                                }}
                                onClick={() => setResponseModal(false)}
                            >
                                Return back to home screen
                            </Button>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default Index;
