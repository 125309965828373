// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NotificationsIcon from '@mui/icons-material/Notifications';

// assets
import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons';
import User1 from 'assets/images/users/user-round.svg';
import ConfirmationModal from 'components/OrderTable/ConfirmationModal';
import { useState } from 'react';
import RmaReceiptUpdateModal from 'components/RmaOrdersTable/RmaReceiptUpdateModal';
import dayjs from 'dayjs';
import { decrypt, encrypt } from '../../../../utils/encrypt';
import ApiUrl from '../../../../network';
import { toast } from 'react-toastify';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = (notifications) => {
    const [notificationList, setNotificationList] = useState(notifications.notifications);
    const theme = useTheme();

    const chipSX = {
        height: 36,
        padding: '0 6px',
        borderRadius: ' 200px'
        // cursor: 'pointer'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.orange.light,
        marginRight: '5px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.warning.light
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
        height: 28
    };

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [isReceiptUpdateOpen, setIsReceiptUpdateOpen] = useState(false);

    // confirmation modal
    const showConfirmationModal = () => {
        setIsConfirmationOpen(true);
    };

    const handleConfirmationOk = () => {
        setIsConfirmationOpen(false);
    };

    const handleConfirmationCancel = () => {
        setIsConfirmationOpen(false);
    };

    // receipt Update modal
    const showReceiptUpdateModal = () => {
        setIsReceiptUpdateOpen(true);
    };

    const handleReceiptUpdateOk = () => {
        setIsReceiptUpdateOpen(false);
    };

    const handleReceiptUpdateCancel = () => {
        setIsReceiptUpdateOpen(false);
    };

    const readNotifications = async (id) => {
        try {
            // Retrieve the user token from localStorage
            const userInfo = localStorage.getItem('userToken');

            // Set up the request headers
            const config = {
                method: 'POST', // Specify the HTTP method
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const datas = {
                message_ids: id
            };
            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            // Make the fetch request to the API
            const response = await fetch(`${ApiUrl.BaseUrl}/notifications/mark-as-read`, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify({ payload })
            });

            // Parse the response JSON
            const data = await response.json();

            if (data.status === true) {
                toast.success(data.message);

                // filter notification with it id and change read to true
                setNotificationList((prevNotifications) =>
                    prevNotifications.map((notification) => (notification._id === id ? { ...notification, read: true } : notification))
                );
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };

    return (
        <List
            sx={{
                width: '100%',
                // maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    // maxWidth: 300,
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {Array.isArray(notificationList) &&
                notificationList?.map((notification) => (
                    <div key={notification._id}>
                        <ListItemWrapper
                            style={{ backgroundColor: notification.read === true ? '#fff' : '#DBE2FE' }}
                            onClick={() => readNotifications(notification._id)}
                        >
                            <ListItem alignItems="center">
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            color: theme.palette.success.dark,
                                            backgroundColor: '#DBE2FF',
                                            border: 'none',
                                            borderColor: theme.palette.success.main
                                        }}
                                    >
                                        {' '}
                                        <NotificationsIcon
                                            sx={{
                                                color: '#4B6EFF'
                                            }}
                                        />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    <Typography variant="subtitle1" dangerouslySetInnerHTML={{ __html: notification.title }} />
                                </ListItemText>
                                <ListItemText
                                    sx={{ color: '#7B808C' }}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        marginLeft: 'auto'
                                    }}
                                >
                                    <Typography variant="caption" display="block" gutterBottom sx={{ color: '#7B808C' }}>
                                        {dayjs(notification.createdAt).format('DD MMM YY')}
                                    </Typography>
                                </ListItemText>
                            </ListItem>

                            <Grid container direction="column" className="list-container">
                                <Grid item xs={12} sx={{ pb: 2 }}>
                                    <Typography variant="subtitle2" dangerouslySetInnerHTML={{ __html: notification.message }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item>
                                            <Chip
                                                label={notification?.country}
                                                sx={{
                                                    backgroundColor: 'transparent',
                                                    borderRadius: '8px',
                                                    fontSize: '14px',
                                                    fontWeight: '400',
                                                    color: '#4B6EFF',
                                                    width: 'fit-content',
                                                    border: 'none',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginRight: '10px',
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        background: '#4B6EFF',
                                                        color: '#fff'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ListItemWrapper>
                        <Divider />
                    </div>
                ))}
        </List>
    );
};

export default NotificationList;
