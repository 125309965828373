/** GENERATE LEADS ACTIONS */
export const GENERATE_LEADS_BEGIN = 'GENERATE_LEADS_BEGIN';
export const GENERATE_LEADS_SUCCESS = 'GENERATE_LEADS_SUCCESS';
export const GENERATE_LEADS_FAILURE = 'GENERATE_LEADS_FAILURE';
export const FILTER_LEADS_BEGIN = 'FILTER_LEADS_BEGIN';
export const FILTER_LEADS_SUCCESS = 'FILTER_LEADS_SUCCESS';
export const FILTER_LEADS_ERROR = 'FILTER_LEADS_ERROR';
export const RESET_LEADS_BEGIN = 'RESET_LEADS_BEGIN';
export const RESET_LEADS = 'RESET_LEADS';

/** MAKE SALES */
export const SALES_BEGIN = 'SALES_BEGIN';
export const SALES_SUCCESS = 'SALES_SUCCESS';
export const SALES_FAILURE = 'SALES_FAILURE';
export const FILTER_SALES_BEGIN = 'FILTER_SALES_BEGIN';
export const FILTER_SALES_SUCCESS = 'FILTER_SALES_SUCCESS';
export const FILTER_SALES_ERROR = 'FILTER_SALES_ERROR';
export const RESET_SALES_BEGIN = 'RESET_SALES_BEGIN';
export const RESET_SALES = 'RESET_SALES';

/** COLLECTIONS */
export const COLLECTIONS_BEGIN = 'COLLECTIONS_BEGIN';
export const COLLECTIONS_SUCCESS = 'COLLECTIONS_SUCCESS';
export const COLLECTIONS_FAILURE = 'COLLECTIONS_FAILURE';

export const FILTER_COLLECTIONS_BEGIN = 'FILTER_COLLECTIONS_BEGIN';
export const FILTER_COLLECTIONS_SUCCESS = 'FILTER_COLLECTIONS_SUCCESS';
export const FILTER_COLLECTIONS_ERROR = 'FILTER_COLLECTIONS_ERROR';
export const RESET_COLLECTIONS_BEGIN = 'RESET_COLLECTIONS_BEGIN';
export const RESET_COLLECTIONS = 'RESET_COLLECTIONS';

/** DEPOSITS */
export const DEPOSITS_BEGIN = 'DEPOSITS_BEGIN';
export const DEPOSITS_SUCCESS = 'DEPOSITS_SUCCESS';
export const DEPOSITS_FAILURE = 'DEPOSITS_FAILURE';

export const FILTER_DEPOSITS_BEGIN = 'FILTER_DEPOSITS_BEGIN';
export const FILTER_DEPOSITS_SUCCESS = 'FILTER_DEPOSITS_SUCCESS';
export const FILTER_DEPOSITS_ERROR = 'FILTER_DEPOSITS_ERROR';
export const RESET_DEPOSITS_BEGIN = 'RESET_DEPOSITS_BEGIN';
export const RESET_DEPOSITS = 'RESET_DEPOSITS';

/** Retailer  */
export const RETAILER_BEGIN = 'RETAILER_BEGIN';
export const RETAILER_SUCCESS = 'RETAILER_SUCCESS';
export const RETAILER_FAILURE = 'RETAILER_FAILURE';
export const FILTER_RETAILER_BEGIN = 'FILTER_RETAILER_BEGIN';
export const FILTER_RETAILER_SUCCESS = 'FILTER_RETAILER_SUCCESS';
export const FILTER_RETAILER_ERROR = 'FILTER_RETAILER_ERROR';
export const RESET_RETAILER_BEGIN = 'RESET_RETAILER_BEGIN';
export const RESET_RETAILER = 'RESET_RETAILER';

/** Supplier Actions */
export const SUPPLIER_BEGIN = 'SUPPLIER_BEGIN';
export const SUPPLIER_SUCCESS = 'SUPPLIER_SUCCESS';
export const SUPPLIER_FAILURE = 'SUPPLIER_FAILURE';

export const SUPPLIER_BATCH_BEGIN = 'SUPPLIER_BATCH_BEGIN';
export const SUPPLIER_BATCH_SUCCESS = 'SUPPLIER_BATCH_SUCCESS';
export const SUPPLIER_BATCH_FAILURE = 'SUPPLIER_BATCH_FAILURE';

export const BATCH_ID_BEGIN = 'BATCH_ID_BEGIN';
export const BATCH_ID_SUCCESS = 'BATCH_ID_SUCCESS';
export const BATCH_ID_FAILURE = 'BATCH_ID_FAILURE';

export const BATCH_RECEIPT_BEGIN = 'BATCH_RECEIPT_BEGIN';
export const BATCH_RECEIPT_SUCCESS = 'BATCH_RECEIPT_SUCCESS';
export const BATCH_RECEIPT_FAILURE = 'BATCH_RECEIPT_FAILURE';

/** DATA EXTRACTION DEFAULT ACTIONS */
export const DATA_DEFAULTS_BEGIN = 'DATA_DEFAULTS_BEGIN';
export const DATA_DEFAULTS_SUCCESS = 'DATA_DEFAULTS_SUCCESS';
export const DATA_DEFAULTS_FAILURE = 'DATA_DEFAULTS_FAILURE';

/** GMV DATA */
export const GMV_BEGIN = 'GMV_BEGIN';
export const GMV_SUCCESS = 'GMV_SUCCESS';
export const GMV_FAILURE = 'GMV_FAILURE';

/** Finance */
export const FINANCE_STATS_BEGIN = 'FINANCE_STATS_BEGIN';
export const FINANCE_STATS_SUCCESS = 'FINANCE_STATS_SUCCESS';
export const FINANCE_STATS_FAILURE = 'FINANCE_STATS_FAILURE';

/**
 * CC Actions
 */
export const CC_STATS_BEGIN = 'CC_STATS_BEGIN';
export const CC_STATS_SUCCESS = 'CC_STATS_SUCCESS';
export const CC_STATS_FAILURE = 'CC_STATS_FAILURE';

/**
 * GET Fulfillment Metrics
 */

export const FULFILLMENT_ST_BEGIN = 'FULFILLMENT_ST_BEGIN';
export const FULFILLMENT_ST_SUCCESS = 'FULFILLMENT_ST_SUCCESS';
export const FULFILLMENT_ST_FAILURE = 'FULFILLMENT_ST_FAILURE';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

/**
 * Warehouse Actions
 */

export const WAREHOUSE_BEGIN = 'WAREHOUSE_BEGIN';
export const WAREHOUSE_SUCCESS = 'WAREHOUSE_SUCCESS';
export const WAREHOUSE_FAILURE = 'WAREHOUSE_FAILURE';

export const WAREHOUSE_SETUPS = 'WAREHOUSE_SETUPS';

// adding of stocks
export const WAREHOUSE_STOCK_BEGIN = 'WAREHOUSE_STOCK_BEGIN';
export const WAREHOUSE_STOCK_SUCCESS = 'WAREHOUSE_STOCK_SUCCESS';
export const WAREHOUSE_STOCK_FAILURE = 'WAREHOUSE_STOCK_FAILURE';
