import { Button, Input, Select } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { decrypt, encrypt } from '../../../../utils/encrypt';
import ApiUrl from '../../../../network';
import { toast } from 'react-toastify';

export const Add = () => {
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [customer, setCustomer] = useState('');
    const [orderValue, setOrderValue] = useState('');
    const [cashathand, setCashAtHand] = useState('');
    const [drivetype, setDriveType] = useState('');
    const [description, setDescription] = useState('');
    const [customerID, setCustomerID] = useState('');
    const [orderId, setOrderId] = useState('');
    const [placedfor, setPlacedFor] = useState('');
    const [qualifiedAmt, setQualifiedAmt] = useState('');
    const [loyaltytitle, SetLoyaltytitle] = useState('');
    const [customerData, setCustomerData] = useState([]);
    const [rmacc, setRmacc] = useState([]);
    const [driveTypeList, setDriveTypeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const { TextArea } = Input;
    const { id } = useParams();

    const fetchRmaCc = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            await fetch(`${ApiUrl.BaseUrl}/epl/eplcustomersrmacc`, {
                method: 'GET',
                headers: config.headers
            })
                .then((data) => data.json())
                .then((rmaccData) => {
                    if (rmaccData.message === 'Success') {
                        const decryptedRmaccData = JSON.parse(decrypt(rmaccData.data, keyResult, ivResult));
                        setCustomerData(decryptedRmaccData.customer);
                        setRmacc(decryptedRmaccData.rmcc);
                    } else {
                        toast.error(rmaccData.data);
                    }
                });
        } catch (error) {
            toast.error('Error occurred while fetching RMA');
        }
    };

    const getDriveTypeHandler = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/epl/epldrivetyeps`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    const driveTypeList = decrypt(JSON.stringify(data.data), keyResult, ivResult);
                    setDriveTypeList(JSON.parse(driveTypeList).driveType);
                } else {
                    toast.error(data.message);
                }
            });
    };

    const fetchOrderDetails = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            await fetch(`${ApiUrl.BaseUrl}/epl/orderinfo/${id}`, {
                method: 'GET',
                headers: config.headers
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setOrderId(decryptedData._id);
                        setOrderValue(decryptedData.expCashCollected);
                        setCustomer(decryptedData?.newChannelPartnerId?.partnerName);
                        setDriveType(decryptedData?.drivetype);
                        setQualifiedAmt(decryptedData?.qualifiedAmt);
                        SetLoyaltytitle(decryptedData?.loyaltytitle);
                    } else {
                        toast.error(data.message);
                    }
                });
        } catch (error) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        fetchRmaCc();
        getDriveTypeHandler();
        if (id) {
            fetchOrderDetails();
        }
    }, []);

    const customerOptions = customerData.map((customer) => {
        return {
            value: customer._id,
            label: customer.partnerName
        };
    });

    const placedForOptions = rmacc.map((rmcc) => {
        return {
            value: rmcc._id,
            label: `${rmcc.firstName} ${rmcc.lastName} `
        };
    });

    const driveTypeOptions = driveTypeList.map((driveType) => {
        return {
            value: driveType.name,
            label: driveType.name
        };
    });

    // Ensure numbers for comparison
    const parsedOrderValue = parseFloat(orderValue) || 0;
    const parsedCashAtHand = parseFloat(cashathand) || 0;
    const parsedQualifiedAmt = parseFloat(qualifiedAmt) || 0;

    const isExceedingCreditLimit = parsedOrderValue > parsedQualifiedAmt + parsedCashAtHand;

    const addRequestHandler = async () => {
        try {
            if (!customer || !orderValue || !cashathand || !drivetype || !customerID) {
                toast.error('All fields are required');
                return;
            }
            if (parsedOrderValue === parsedCashAtHand || parsedCashAtHand > parsedOrderValue) {
                toast.error('Cash at hand cannot be equal to or greater than the order value');
                return;
            }
            if (isExceedingCreditLimit) {
                toast.error('You have exceeded the credit limit');
                return;
            }

            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const customerId = customerData.find((customerData) => customerData.partnerName === customer);

            const datas = {
                customer: customerId._id,
                orderValue: parsedOrderValue,
                cashathand: parsedCashAtHand,
                description,
                drivetype,
                customercard: customerID,
                orderId,
                placedfor
            };

            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            await fetch(`${ApiUrl.BaseUrl}/epl/addeplrequest`, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify({ payload })
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setLoading(false);
                        toast.success(data.message);
                        setCustomer('');
                        setOrderValue('');
                        setCashAtHand('');
                        setDescription('');
                        setDriveType('');
                        setCustomerID('');
                        setPlacedFor('');
                    } else {
                        setLoading(false);
                        toast.error(data.message);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    };

    return (
        <div className="easy-pay-container">
            <div className="header">
                <Link className="d-flex align-items-center" to="/epl/cc-rma-request" style={{ color: '#0A2938' }}>
                    <ArrowLeftOutlined className="font-20" />
                    <h1 className="font-30 ml-2 mt-2">Add Epl Request </h1>
                </Link>
                <div className="d-flex align-items-center justify-content-center mt-4">
                    <Button
                        onClick={() => {
                            window.history.back();
                        }}
                        style={{
                            backgroundColor: '#fff',
                            color: '#FF740F',
                            border: '1px solid #FF740F'
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="ml-2"
                        loading={loading}
                        onClick={() => {
                            addRequestHandler();
                        }}
                        style={{
                            backgroundColor: `${
                                customer && orderValue && cashathand && drivetype && customerID && placedfor ? '#FF740F' : '#B4B7BD'
                            }`,
                            color: '#fff'
                        }}
                    >
                        Submit request
                    </Button>
                </div>
            </div>
            <div className="content bg-white">
                <div className="epl-request-content">
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="form-group">
                            <span>Retailer name</span>
                            <Select
                                disabled
                                value={customer}
                                onChange={(e) => setCustomer(e)}
                                size="middle"
                                className="mt-2"
                                showSearch
                                allowClear
                                options={customerOptions}
                                defaultValue="Select option"
                                style={{ width: '100%' }}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                            />
                        </div>
                        <div className="form-group ml-2">
                            <span>Drive type</span>
                            <Select
                                disabled
                                value={drivetype}
                                onChange={(e) => setDriveType(e)}
                                size="middle"
                                className="mt-2"
                                showSearch
                                allowClear
                                options={driveTypeOptions}
                                defaultValue="Select option"
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="form-group ml-2">
                            <span>Loyalty</span>
                            <Input value={loyaltytitle} className="form-control mt-2" id="amount" disabled />
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="form-group">
                            <span style={{ color: orderValue - cashathand < 0 ? '#FA3246' : '' }}>Order value</span>
                            <Input
                                disabled
                                value={orderValue}
                                onChange={(e) => setOrderValue(e.target.value)}
                                type="number"
                                className="form-control mt-2"
                                id="amount"
                            />
                        </div>
                        <div className="form-group ml-2">
                            <span
                                style={{
                                    color: orderValue - cashathand < 0 ? '#FA3246' : ''
                                }}
                            >
                                Cash at hand
                            </span>
                            <Input
                                value={cashathand}
                                onChange={(e) => setCashAtHand(e.target.value)}
                                type="number"
                                className="form-control mt-2"
                                id="amount"
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="form-group">
                            <span
                                style={{
                                    color: orderValue - cashathand < 0 ? '#FA3246' : ''
                                }}
                            >
                                EPL balance
                            </span>
                            <Input
                                value={(orderValue - cashathand).toFixed(2)}
                                type="number"
                                className="form-control mt-2"
                                id="amount"
                                disabled
                            />
                            <small
                                style={{
                                    fontSize: '10px',
                                    color: '#FA3246',
                                    marginTop: '5px'
                                }}
                            >
                                {orderValue - cashathand < 0 && 'Balance is negative because cash at hand exceeds the order value'}
                            </small>
                        </div>
                        <div className="form-group ml-2">
                            <span
                                style={{
                                    color: parsedOrderValue > parsedQualifiedAmt ? '#FA3246' : ''
                                }}
                            >
                                Eligible amount
                            </span>
                            <Input value={qualifiedAmt} disabled type="number" className="form-control mt-2" id="amount" />
                            <small
                                style={{
                                    fontSize: '10px',
                                    color: '#FA3246',
                                    marginTop: '5px'
                                }}
                            >
                                {isExceedingCreditLimit && 'Order value greater than eligible amount + cash at hand'}
                            </small>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="form-group">
                            <span>Retailer ID</span>
                            <Select
                                value={customerID}
                                onChange={(e) => setCustomerID(e)}
                                size="middle"
                                className="mt-2"
                                showSearch
                                allowClear
                                options={[
                                    { value: 'Yes', label: 'Yes' },
                                    { value: 'No', label: 'No' }
                                ]}
                                defaultValue="Select option"
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="form-group ml-2">
                            <span>Placed For</span>
                            <Select
                                value={placedfor}
                                onChange={(e) => setPlacedFor(e)}
                                size="middle"
                                className="mt-2"
                                showSearch
                                allowClear
                                options={placedForOptions}
                                defaultValue="Select option"
                                style={{ width: '100%' }}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <span>Description</span>
                        <TextArea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="form-control mt-2"
                            id="description"
                            rows={4}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Add;
