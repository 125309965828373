import GenericPageHeader from 'components/GenericPageHeader';
import DeliveryNoteTable from './DeliveryNoteTable';

const DeliveryNote = () => {
    return (
        <>
            <GenericPageHeader title="Orders" breadcrumbs="Home | Orders | Listings" />
            <DeliveryNoteTable
                th1="#"
                th2="Order Date"
                th3="Customer Name"
                th4="Sales Person"
                th5="Cost Value"
                th6="Sales Value"
                th7="Order Status"
                th8="Actions"
            />
        </>
    );
};
export default DeliveryNote;
