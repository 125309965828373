// import dashboard from './dashboard';
import financemanager from './FinanceManager';
import other from './other';
// import reporting from './reporting';

// ==============================|| MENU ITEMS dashboard ||============================== //

const financeManagerItems = {
    items: [financemanager, other]
};

export default financeManagerItems;
