/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import { makeStyles } from '@mui/styles';
import { toast } from 'react-toastify';
// material-ui
import { Grid } from '@mui/material';
import ApiUrl from 'network';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Button from '@mui/material/Button';
import { gridSpacing } from 'store/constant';
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
import TotalOrdersCard from './TotalOrdersCard';
import AverageOrderValue from './AverageOrderValue';
import TopFiveParts from './TopFiveParts';
import axios from 'axios';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    colors: {
        color: `#ED7C1B`
    }
}));
const DashboardRetailerIndex = () => {
    // const history = useNavigate();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [retailerInfo, setRetailer] = useState([]);
    const [retailerId, setRetailerId] = useState('');
    const [totalretailerorders, settotalretailerorders] = useState('');
    const [totalaverageorderValue, settotalaverageorderValue] = useState('');
    const [todaytopfiveparts, settodaytopfiveparts] = useState([]);
    const [receiptall, setreceiptall] = useState({});

    const retrieveAllRetail = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllRetailerUrlDashboard, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setRetailer(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllRetailSummaryDashboard = async (id) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            setLoading(true);
            const data = { id };
            fetch(ApiUrl.RetailerDashboardSummaryUrl, {
                method: 'POST',
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then((data) => data.json())
                .then((data) => {
                    console.log(data);
                    settotalretailerorders(data?.totalretailerorders ?? '0');
                    settotalaverageorderValue(data?.totalaverageorderValue ?? '0');
                    settodaytopfiveparts(data?.todaytopfiveparts ?? []);
                    // setRetailer(data.data);
                    data.retailerpdf.forEach((element) => {
                        console.log('element', element);
                        setreceiptall(element);
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handleallpdf = () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };
        // setLoading2(true);
        axios.post(ApiUrl.RetrievallRetailerReceiptUrl, receiptall, config).then((res) => {
            window.open(res.data.data, '_blank');

            // setLoading2(false);
        });
    };

    const filterRetailer = (e) => {
        setRetailerId(e.target.value);
        // const newFilter = retailerInfo.filter((value) => (value.partnerName || '').toLowerCase().includes((newvalue || '').toLowerCase()));

        // newFilter.forEach((option) => {
        //     setid(option._id);
        // });
        retrieveAllRetailSummaryDashboard(e.target.value);
    };

    useEffect(() => {
        retrieveAllRetail();
    }, []);

    return (
        <>
            <br />
            <PageHeader title="Retailer Dashboard" subTitle="Home | Retailer Dashboard " />
            <br />
            <Grid container spacing={gridSpacing}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>..</Grid>
                    <br />

                    <Grid item>
                        <Box width={150} height={20}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Filter By Retailer</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={retailerId}
                                    label="Status"
                                    onChange={filterRetailer}
                                >
                                    {retailerInfo.map((retail) => (
                                        <MenuItem key={retail._id} value={retail._id}>
                                            {retail.partnerName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        {/* <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            value={retailerId}
                            onChange={(event, newValue) => {
                                setRetailerId(newValue);
                                filterRetailer(newValue);
                            }}
                            options={retailerInfo.map((option) => option.partnerName)}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => <TextField {...params} label="Retailer" />}
                        /> */}
                    </Grid>
                </Grid>
                <br />
                <br />
                <br />
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <TotalOrdersCard isLoading={loading} totalorders={totalretailerorders} />
                        </Grid>
                        <br />

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <AverageOrderValue isLoading={loading} totalorders={totalaverageorderValue} />
                        </Grid>
                        <br />
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Button onClick={handleallpdf} variant="contained" size="sm" className={classes.item} color="info">
                                Download receipt as at may (pdf)
                            </Button>
                        </Grid>
                    </Grid>

                    <br />
                    <br />

                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12} sm={6} md={4} lg={8}>
                            <TopFiveParts
                                isLoading={loading}
                                todaytopfiveparts={todaytopfiveparts}
                                title="Top Five Parts Ordered of all time"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default DashboardRetailerIndex;
