import { Button, Input, Select, Space, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import ApiUrl from '../../../network';
import { toast } from 'react-toastify';
import { decrypt, encrypt } from '../../../utils/encrypt';
import normFile from '../../../imageToBucket';
import Slider from 'react-slick';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const [loading, setLoading] = useState(false);
    const [partsFamily, setPartsFamily] = useState([]);
    const [distributorInfo, setDistributors] = useState([]);
    const [parts, setParts] = useState([
        {
            sku: '',
            brand: '',
            distributor: '',
            productFamily: '',
            wholeSalePrice: null,
            garagePrice: null,
            takGaragePrice: null,
            kumasiGaragePrice: null,
            partQtyInPieces: '',
            partsImage: '',
            partWeight: '',
            partImageList: [],
            errors: {}
        }
    ]);

    const fetchFilteredParts = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/parts/v2/partsfilterdata`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                setPartsFamily(decryptedData.productfamily);
                setLoading(false);
            } else {
                toast.error(result.message);
                setLoading(false);
            }
        } catch (e) {
            toast(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };
    const retrieveAllDistributor = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(`${ApiUrl.BaseUrl}/channelpartner/channelspartnerdistributor`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setDistributors(data.data);
                    } else {
                        toast.error(data.data);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        fetchFilteredParts();
        retrieveAllDistributor();
    }, []);
    const productFamilyOptions = partsFamily.map((family) => {
        return {
            value: family.name,
            label: family.name
        };
    });
    const distributorOptions = distributorInfo.map((distributor) => {
        return {
            value: distributor._id,
            label: distributor.partnerName
        };
    });

    const handleInputChange = (index, field, value) => {
        const newParts = [...parts];
        newParts[index][field] = value;
        newParts[index].errors[field] = '';
        setParts(newParts);
    };

    const addPartHandler = async () => {
        try {
            setLoading(true);

            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }

            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const country = localStorage.getItem('country').toLowerCase();

            // Validate parts and collect errors
            const newParts = parts.map((part) => {
                const {
                    sku,
                    brand,
                    distributor,
                    productFamily,
                    wholeSalePrice,
                    garagePrice,
                    takGaragePrice,
                    kumasiGaragePrice,
                    partQtyInPieces,
                    partsImage,
                    partWeight
                } = part;

                const errors = {};
                if (!sku) errors.sku = 'SKU is required';
                if (!partWeight) errors.partWeight = 'Weight is required';
                if (!brand) errors.brand = 'Brand is required';
                if (!partQtyInPieces) errors.partQtyInPieces = 'Quantity in pieces is required';
                if (!distributor) errors.distributor = 'Distributor is required';
                if (!garagePrice) errors.garagePrice = 'Garage price is required';
                if (!wholeSalePrice) errors.wholeSalePrice = 'Wholesale price is required';
                if (!productFamily) errors.productFamily = 'Product family is required';
                if (!partsImage) errors.partsImage = 'Part Image is required';

                if (country === 'ghana') {
                    if (!takGaragePrice) errors.takGaragePrice = 'Tak Garage Price is required';
                    if (!kumasiGaragePrice) errors.kumasiGaragePrice = 'kumasi Garage Price is required';
                }

                return { ...part, errors };
            });

            // Check if there are any errors
            const hasErrors = newParts.some((part) => Object.keys(part.errors).length > 0);
            setParts(newParts);

            if (hasErrors) {
                setLoading(false);
                toast.error('Please fix the errors in the form');
                return;
            }

            // Prepare data for API call
            const datas = {
                parts: newParts.map(({ errors, ...part }) => {
                    const {
                        sku,
                        brand,
                        distributor,
                        productFamily,
                        wholeSalePrice,
                        garagePrice,
                        takGaragePrice,
                        kumasiGaragePrice,
                        partQtyInPieces,
                        partsImage,
                        partImageList,
                        partWeight
                    } = part;

                    const partData = {
                        sku,
                        brand,
                        distributor,
                        productFamily,
                        wholeSalePrice: parseFloat(wholeSalePrice),
                        garagePrice: parseFloat(garagePrice),
                        partQtyInPieces: parseFloat(partQtyInPieces),
                        partsImage,
                        partImageList,
                        partWeight: parseFloat(partWeight)
                    };

                    if (country === 'ghana') {
                        partData.takGaragePrice = parseFloat(takGaragePrice);
                        partData.kumasiGaragePrice = parseFloat(kumasiGaragePrice);
                    } else {
                        partData.takGaragePrice = parseFloat(0);
                        partData.kumasiGaragePrice = parseFloat(0);
                    }

                    return partData;
                })
            };

            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            const url = `${ApiUrl.BaseUrl}/parts/v2/addparts`;

            const response = await fetch(url, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify({ payload })
            });

            const result = await response.json();

            if (result.message.toLowerCase() === 'success') {
                toast.success(result.message);
                setParts([
                    {
                        sku: '',
                        brand: '',
                        distributor: '',
                        productFamily: '',
                        wholeSalePrice: '',
                        garagePrice: '',
                        takGaragePrice: '',
                        kumasiGaragePrice: '',
                        partQtyInPieces: '',
                        partsImage: '',
                        partWeight: '',
                        partImageList: [],
                        errors: {}
                    }
                ]);
            } else {
                const error = decrypt(result.data, keyResult, ivResult);
                toast.error(error);
            }
        } catch (e) {
            toast.error(e.message);
        } finally {
            setLoading(false);
        }
    };

    const handleUpload = async (options, index) => {
        const { file, onSuccess, onError } = options;
        try {
            setLoading(true);
            const token = localStorage.getItem('userToken');
            const rootURL = ApiUrl.BaseUrl;
            const compressedImage = await normFile(file, token, rootURL);

            if (compressedImage) {
                setParts((prevParts) => {
                    const updatedParts = [...prevParts];
                    const updatedPartImageList = [...(updatedParts[index].partImageList || []), { image: compressedImage }];
                    updatedParts[index] = {
                        ...updatedParts[index],
                        partImageList: updatedPartImageList,
                        partsImage: updatedPartImageList[0].image
                    };
                    return updatedParts;
                });
                setLoading(false);
                onSuccess('success');
                toast.success('Image uploaded successfully');
            }
        } catch (error) {
            setLoading(false);
            onError(error);
            toast.error(error.message);
        }
    };

    const handleDeleteImage = (partIndex, imageIndex) => {
        setParts((prevParts) => {
            const updatedParts = [...prevParts];
            const updatedPartImageList = [...updatedParts[partIndex].partImageList];
            updatedPartImageList.splice(imageIndex, 1);
            updatedParts[partIndex] = {
                ...updatedParts[partIndex],
                partImageList: updatedPartImageList,
                partsImage: updatedPartImageList[0]?.image || '' // Set partsImage to the first image or empty string if no images left
            };
            return updatedParts;
        });
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };

    return (
        <div>
            <div className="m-header">
                <div>
                    <Link to="/parts">
                        <h1 style={{ color: '#0A2938', display: 'flex', alignItems: 'center' }}>
                            <ArrowLeftOutlined className="mr-2" /> Add Part
                        </h1>
                    </Link>
                </div>
                <div>
                    <Button
                        style={{
                            backgroundColor: 'transparent',
                            color: '#FF740F'
                        }}
                        onClick={() => (window.location.href = '/parts')}
                        className="w-fit border-primary-m"
                    >
                        Cancel
                    </Button>
                    <Button loading={loading} onClick={addPartHandler} className="w-fit primary-bg  text-white ml-2">
                        Save changes
                    </Button>
                </div>
            </div>
            {parts.map((part, index) => (
                <div key={index} className="m-content m-add-page-content mt-4 bg-white" style={{ borderRadius: '10px' }}>
                    <div>
                        {part.errors.partsImage && <span className="error-text mb-4 text-center">{part.errors.partsImage}</span>}
                        {part?.partImageList?.length === 0 || !part?.partImageList ? (
                            <div className="m-image-container">
                                <div className="image-placeholder">
                                    <img
                                        className="m-product-img"
                                        src={part.partsImage || 'https://via.placeholder.com/150'}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = 'https://via.placeholder.com/150';
                                        }}
                                        alt=""
                                    />
                                </div>
                                <div className="m-product-img-actions">
                                    <span>
                                        <Space direction="vertical" style={{ width: '100%' }} size="large">
                                            <Upload
                                                action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                                listType="picture"
                                                maxCount={3}
                                                multiple
                                                customRequest={(options) => handleUpload(options, index)}
                                            >
                                                <Button className="m-upload-btn">
                                                    {' '}
                                                    <UploadOutlined /> Upload Image
                                                </Button>
                                            </Upload>
                                        </Space>
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <div className="img-main-thumb">
                                <div className="m-image-container bg-white">
                                    <div className="image-placeholder slider-container">
                                        <Slider {...settings}>
                                            {part.partImageList.map((image, imageIndex) => (
                                                <div
                                                    key={imageIndex}
                                                    style={{ display: 'flex', alignItems: 'center', marginBottom: 8, position: 'relative' }}
                                                >
                                                    <img src={image.image} alt={`part-${imageIndex}`} className="m-product-img" />
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                    <div className="m-product-img-actions">
                                        <span>
                                            <Space direction="vertical" style={{ width: '100%' }} size="large">
                                                <Upload
                                                    action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                                    listType="picture"
                                                    maxCount={3}
                                                    multiple
                                                    customRequest={(options) => handleUpload(options, index)}
                                                >
                                                    <Button className="m-upload-btn">
                                                        {' '}
                                                        <UploadOutlined /> Upload Image
                                                    </Button>
                                                </Upload>
                                            </Space>
                                        </span>
                                    </div>
                                </div>
                                <div className="imgs-thumbnail">
                                    {part.partImageList.map((image, imageIndex) => (
                                        <div key={imageIndex} style={{ position: 'relative' }}>
                                            <img src={image.image} alt={`part-${imageIndex}`} />
                                            <Button
                                                type="danger"
                                                style={{
                                                    position: 'absolute',
                                                    top: 5,
                                                    right: 15,
                                                    backgroundColor: '#ff6574',
                                                    color: '#fff',
                                                    borderRadius: '10px'
                                                }}
                                                onClick={() => handleDeleteImage(index, imageIndex)}
                                            >
                                                X
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="ml-2">
                        {index > 0 && (
                            <div className="d-flex flex-row-reverse">
                                <Button
                                    className="w-fit mb-4"
                                    style={{
                                        backgroundColor: '#ff6574',
                                        color: '#FFFFFF',
                                        fontWeight: 'bold',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                    onClick={() => {
                                        const newParts = [...parts];
                                        newParts.splice(index, 1);
                                        setParts(newParts);
                                    }}
                                >
                                    Delete <DeleteOutlined />
                                </Button>
                            </div>
                        )}
                        <div className="d-flex">
                            <div className="form-group">
                                <p>Product name</p>
                                <Input
                                    placeholder="Product name"
                                    value={part.sku}
                                    onChange={(e) => handleInputChange(index, 'sku', e.target.value)}
                                />
                                {part.errors.sku && <span className="error-text">{part.errors.sku}</span>}
                            </div>
                            <div className="form-group">
                                <p>Product family</p>
                                <Select
                                    options={productFamilyOptions}
                                    filterOption={(inputValue, option) => {
                                        if (typeof option.label === 'string') {
                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }
                                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }}
                                    showSearch
                                    allowClear
                                    className="m-select"
                                    placeholder="Product family"
                                    value={part.productFamily || undefined}
                                    onChange={(value) => handleInputChange(index, 'productFamily', value)}
                                />
                                {part.errors.productFamily && <span className="error-text">{part.errors.productFamily}</span>}
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="form-group">
                                <p>Weight (kg)</p>
                                <Input
                                    type="number"
                                    placeholder="Weight (kg)"
                                    value={part.partWeight}
                                    onChange={(e) => handleInputChange(index, 'partWeight', e.target.value)}
                                />
                                {part.errors.partWeight && <span className="error-text">{part.errors.partWeight}</span>}
                            </div>
                            <div className="form-group">
                                <p>Qnty in pieces</p>
                                <Input
                                    placeholder="Qnty in pieces"
                                    value={part.partQtyInPieces}
                                    onChange={(e) => handleInputChange(index, 'partQtyInPieces', e.target.value)}
                                />
                                {part.errors.partQtyInPieces && <span className="error-text">{part.errors.partQtyInPieces}</span>}
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="form-group">
                                <p>Distributor</p>
                                <Select
                                    placeholder="Distributor"
                                    options={distributorOptions}
                                    value={part.distributor || undefined}
                                    onChange={(value) => handleInputChange(index, 'distributor', value)}
                                    filterOption={(inputValue, option) => {
                                        if (typeof option.label === 'string') {
                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }
                                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }}
                                    showSearch
                                    allowClear
                                    className="m-select"
                                />
                                {part.errors.distributor && <span className="error-text">{part.errors.distributor}</span>}
                            </div>
                            <div className="form-group">
                                <p>Brand</p>
                                <Input
                                    placeholder="Brand"
                                    value={part.brand}
                                    onChange={(e) => handleInputChange(index, 'brand', e.target.value)}
                                />
                                {part.errors.brand && <span className="error-text">{part.errors.brand}</span>}
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="form-group">
                                <p>Wholesale price</p>
                                <Input
                                    type="number"
                                    placeholder="Wholesale price"
                                    value={part.wholeSalePrice}
                                    onChange={(e) => handleInputChange(index, 'wholeSalePrice', e.target.value)}
                                />
                                {part.errors.wholeSalePrice && <span className="error-text">{part.errors.wholeSalePrice}</span>}
                            </div>
                            <div className="form-group">
                                <p>Garage price</p>
                                <Input
                                    type="number"
                                    placeholder="Garage price"
                                    value={part.garagePrice}
                                    onChange={(e) => handleInputChange(index, 'garagePrice', e.target.value)}
                                />
                                {part.errors.garagePrice && <span className="error-text">{part.errors.garagePrice}</span>}
                            </div>
                        </div>
                        {localStorage.getItem('country') === 'Ghana' && (
                            <div className="d-flex">
                                <div className="form-group">
                                    <p>Kumasi garage price</p>
                                    <Input
                                        type="number"
                                        placeholder="Kumasi garage price"
                                        value={part.kumasiGaragePrice}
                                        onChange={(e) => handleInputChange(index, 'kumasiGaragePrice', e.target.value)}
                                    />
                                    {part.errors.kumasiGaragePrice && <span className="error-text">{part.errors.kumasiGaragePrice}</span>}
                                </div>
                                <div className="form-group">
                                    <p>Takoradi garage price</p>
                                    <Input
                                        type="number"
                                        placeholder="Takoradi garage price"
                                        value={part.takGaragePrice}
                                        onChange={(e) => handleInputChange(index, 'takGaragePrice', e.target.value)}
                                    />
                                    {part.errors.takGaragePrice && <span className="error-text">{part.errors.takGaragePrice}</span>}
                                </div>
                            </div>
                        )}
                        <Button
                            style={{
                                backgroundColor: 'transparent',
                                color: '#FF740F'
                            }}
                            onClick={() =>
                                setParts([
                                    ...parts,
                                    {
                                        sku: '',
                                        brand: '',
                                        distributor: '',
                                        productFamily: '',
                                        wholeSalePrice: '',
                                        garagePrice: '',
                                        kumasiGaragePrice: '',
                                        takGaragePrice: '',
                                        partQtyInPieces: '',
                                        partsImage: '',
                                        partWeight: '',
                                        partImageList: [],
                                        errors: {}
                                    }
                                ])
                            }
                            className="border-primary-m mt-4"
                        >
                            Add more
                        </Button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Index;
