import { useEffect } from 'react';

import PageHeader from '../../components/PageHeader';
import MainCard from 'ui-component/cards/MainCard';
import Box from '@mui/material/Box';
import Controls from '../../components/controls/Controls';
import { Search } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import CircularProgress from '@mui/material/CircularProgress';
// import TablePagination from '@mui/material/TablePagination';
// import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
// import ApiUrl from 'network';

// const { BaseUrl } = ApiUrl;

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const NewOrder = () => {
    const classes = useStyles();
    // const [isLoading, setIsLoading] = useState(true);
    // const [allNewOrders, setAllNewOrders] = useState([]);

    // const handleSearch = (e) => {
    //     console.log(e.target.value);
    // };

    useEffect(() => {}, []);
    console.log('here');

    const filterList = (text) => {
        console.log(text);
    };

    return (
        <>
            <PageHeader title="New Order" subTitle="Home | New Order" />
            <MainCard title="New Order List">
                <Box display="flex" justifyContent="space-between">
                    <Controls.Input
                        label="Search Mechanic Partner"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={(e) => filterList(e.target.value)}
                    />
                    <Box width={10} />
                    <Button component={Link} to="/addneworder" variant="contained" size="sm" className={classes.item}>
                        New
                    </Button>
                </Box>

                <div />
            </MainCard>
        </>
    );
};

export default NewOrder;
