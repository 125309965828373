import { Button, Input, Modal, Select } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { decrypt, encrypt } from '../../../../utils/encrypt';
import ApiUrl from '../../../../network';
import { toast } from 'react-toastify';

export const Add = () => {
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [customer, setCustomer] = useState('');
    const [creditNo, setCreditNo] = useState('');
    const [amtDue, setAmtDue] = useState('');
    const [amtPaid, setAmtPaid] = useState('');
    const [customerData, setCustomerData] = useState([]);
    const [rmacc, setRmacc] = useState([]);
    const [driveTypeList, setDriveTypeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [confirmPayment, setConfirmPayment] = useState(false);
    const { id } = useParams();

    const fetchRmaCc = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            await fetch(`${ApiUrl.BaseUrl}/epl/eplcustomersrmacc`, {
                method: 'GET',
                headers: config.headers
            })
                .then((data) => data.json())
                .then((rmaccData) => {
                    if (rmaccData.message === 'Success') {
                        const decryptedRmaccData = JSON.parse(decrypt(rmaccData.data, keyResult, ivResult));
                        setCustomerData(decryptedRmaccData.customer);
                        setRmacc(decryptedRmaccData.rmcc);
                    } else {
                        toast.error(rmaccData.data);
                    }
                });
        } catch (error) {
            toast.error('Error occurred while fetching RMA');
        }
    };

    const getDriveTypeHandler = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/epl/epldrivetyeps`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    const driveTypeList = decrypt(JSON.stringify(data.data), keyResult, ivResult);
                    setDriveTypeList(JSON.parse(driveTypeList).driveType);
                } else {
                    toast.error(data.message);
                }
            });
    };

    const fetchOrderDetails = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            await fetch(`${ApiUrl.BaseUrl}/epl/creditnodetails/${id}`, {
                method: 'GET',
                headers: config.headers
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setCustomer(decryptedData?.customer?.partnerName);
                        setCreditNo(decryptedData?.creditno);
                        setAmtDue(decryptedData?.amtDue);
                    } else {
                        toast.error(data.message);
                    }
                });
        } catch (error) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        fetchRmaCc();
        getDriveTypeHandler();
        if (id) {
            fetchOrderDetails();
        }
    }, []);

    const customerOptions = customerData.map((customer) => {
        return {
            value: customer._id,
            label: customer.partnerName
        };
    });

    const addPaymentRequestHandler = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const customerId = customerData.find((customerData) => customerData.partnerName === customer);

            if (amtPaid > amtDue) {
                setLoading(false);
                toast.error('Amount paid cannot be greater than amount due');
                return;
            }

            const datas = {
                customer: customerId._id,
                amtPaid: parseFloat(amtPaid),
                credittrackerid: id,
                amtDue: parseFloat(amtDue)
            };

            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            await fetch(`${ApiUrl.BaseUrl}/epl/addeplcollection`, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify({ payload })
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setLoading(false);
                        toast.success(data.message);
                        setConfirmPayment(false);
                    } else {
                        setLoading(false);
                        toast.error(data.message);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    };

    return (
        <div className="easy-pay-container">
            <div className="header">
                <Link className="d-flex align-items-center" to="/epl/cc-rma-credit-status" style={{ color: '#0A2938' }}>
                    <ArrowLeftOutlined className="font-20" />
                    <h1 className="font-30 ml-2 mt-2">Add Payment </h1>
                </Link>
                <div className="d-flex align-items-center justify-content-center mt-4">
                    <Button
                        onClick={() => {
                            window.history.back();
                        }}
                        style={{
                            backgroundColor: '#fff',
                            color: '#FF740F',
                            border: '1px solid #FF740F'
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="ml-2"
                        loading={loading}
                        onClick={() => setConfirmPayment(true)}
                        style={{
                            backgroundColor: `${amtPaid ? '#FF740F' : '#B4B7BD'}`,
                            color: '#fff'
                        }}
                    >
                        Submit request
                    </Button>
                </div>
            </div>
            <div className="content bg-white">
                <div className="epl-request-content">
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="form-group">
                            <span>Customer name</span>
                            <Select
                                value={customer || undefined}
                                onChange={(e) => setCustomer(e)}
                                size="middle"
                                className="mt-2"
                                showSearch
                                allowClear
                                options={customerOptions}
                                defaultValue="Search here"
                                placeholder="Search here"
                                style={{ width: '100%' }}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                disabled
                            />
                        </div>
                        <div className="form-group ml-2">
                            <span>Credit number</span>
                            <Input
                                value={creditNo}
                                onChange={(e) => setCreditNo(e.target.value)}
                                disabled
                                className="form-control mt-2"
                                id="amount"
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="form-group">
                            <span>Amount due</span>
                            <Input
                                value={amtDue}
                                onChange={(e) => setAmtDue(e.target.value)}
                                type="number"
                                className="form-control mt-2"
                                id="amount"
                                disabled
                            />
                        </div>
                        <div className="form-group ml-2">
                            <span>Amount</span>
                            <Input
                                value={amtPaid}
                                onChange={(e) => setAmtPaid(e.target.value)}
                                type="number"
                                className="form-control mt-2"
                                id="amount"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Modal title="Confirm Payment" open={confirmPayment} onCancel={() => setConfirmPayment(false)} footer={null}>
                <div>
                    <span className="text-center p-4 mb-4 mt-2">
                        <p className="font-20">
                            Are you sure you want to <br /> add payment?
                        </p>
                    </span>
                    <div className="d-flex align-items-center justify-content-space-between">
                        <Button
                            style={{
                                backgroundColor: 'transparent',
                                color: '#FF740F',
                                border: '1px solid #FF740F'
                            }}
                            className="flex-grow-1"
                            type="button"
                            onClick={() => setConfirmPayment(false)}
                        >
                            No
                        </Button>
                        <Button
                            loading={loading}
                            className="flex-grow-1 ml-2"
                            type="button"
                            style={{
                                backgroundColor: 'transparent',
                                color: '#FF740F',
                                border: '1px solid #FF740F'
                            }}
                            onClick={() => {
                                addPaymentRequestHandler();
                            }}
                        >
                            Yes
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Add;
