/* eslint-disable no-underscore-dangle */
/* eslint-disable no-empty */
import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import { makeStyles } from '@mui/styles';
// material-ui
import { Grid } from '@mui/material';
import ApiUrl from 'network';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
// import { gridSpacing } from 'store/constant';
import Button from '@mui/material/Button';
import TotalDepositsAbigailCard from './depositsdata/totaldepositsAbigail';
import TotalDepositsAlfredCard from './depositsdata/totaldepositsalfred';
import TotalDepositsDanielCard from './depositsdata/totaldepositsDaniel';
import TotalDepositsJosephCard from './depositsdata/totaldepositsJoseph';
import TotalDepositsKateCard from './depositsdata/totaldepositskate';
import TotalDepositsKwakuCard from './depositsdata/totaldepositskwaku';
import TotalDepositsNicholasCard from './depositsdata/totaldepositsNicholas';
import TotalDepositsPrinceCard from './depositsdata/totaldepositsprince';
import TotalDepositsSelinaCard from './depositsdata/totaldepositsselina';
import TotalDepositsSophiaCard from './depositsdata/totaldepositsSophia';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    colors: {
        color: `#ED7C1B`
    }
}));

const DepositReport = () => {
    // const history = useNavigate();
    const classes = useStyles();
    const [status, setStatus] = useState('today');
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [isLoading, setLoading] = useState(false);
    // const userRole = localStorage.getItem('userRole');
    // const userCurrency = localStorage.getItem('userCurrency');
    const [depositedAbigail, setdepositedAbigail] = useState([]);
    const [depositedAlfred, setdepositedAlfred] = useState([]);
    const [depositedSelina, setdepositedSelina] = useState([]);
    const [depositedKate, setdepositedKate] = useState([]);
    const [depositedPrince, setdepositedPrince] = useState([]);
    const [depositedKwaku, setdepositedKwaku] = useState([]);
    const [depositedJoseph, setdepositedJoseph] = useState([]);
    const [depositedSophia, setdepositedSophia] = useState([]);
    const [depositedDaniel, setdepositedDaniel] = useState([]);
    const [depositedNicholas, setdepositedNicholas] = useState([]);
    const [depositedListowell, setdepositedListowell] = useState([]);
    const [depositedJuliana, setdepositedJuliana] = useState([]);
    const [dateRangeTitle, setdateRangeTitle] = useState('');
    const country = localStorage.getItem('country');

    const retrieveAllTodaysMatrix = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.DepositReportTodayFinanceUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    // console.log('result', data.totaltodaysorders);
                    setdepositedAlfred(data?.depositedAlfred ?? []);
                    setdepositedSelina(data?.depositedSelina ?? []);
                    setdepositedKate(data?.depositedKate ?? []);
                    setdepositedPrince(data?.depositedPrince ?? []);
                    setdepositedKwaku(data?.depositedKwaku ?? []);
                    setdepositedJoseph(data?.depositedJoseph ?? []);
                    setdepositedSophia(data?.depositedSophia ?? []);
                    setdepositedAbigail(data?.depositedAbigail ?? []);
                    setdepositedDaniel(data?.depositedDaniel ?? []);
                    setdepositedNicholas(data?.depositedNicholas ?? []);
                    setdepositedListowell(data?.depositedListowell ?? []);
                    setdepositedJuliana(data?.depositedJuliana ?? []);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const setChange = (event, picker) => {
        console.log('startDate', picker.startDate._d);
        console.log('endDate', picker.endDate._d);
        setstartDate(picker.startDate._d);
        setendDate(picker.endDate._d);
        setStatus('dateselect');
        // AllDateRangeMasterDashboardUrl
        const userInfo = localStorage.getItem('userToken');
        const from = picker.startDate._d;
        const to = picker.endDate._d;
        setLoading(true);
        const data = {
            from,
            to
        };

        fetch(ApiUrl.DepositReportDateRangeFinanceUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                setLoading(false);
                // console.log('data', result);
                setdateRangeTitle(result?.dateRangeTitle ?? '');
                setdepositedAlfred(result?.depositeddaterangeAlfred ?? []);
                setdepositedSelina(result?.depositeddaterangeSelina ?? []);
                setdepositedKate(result?.depositeddaterangeKate ?? []);
                setdepositedPrince(result?.depositeddaterangePrince ?? []);
                setdepositedKwaku(result?.depositeddaterangeKwaku ?? []);
                setdepositedJoseph(result?.depositeddaterangeJoseph ?? []);
                setdepositedSophia(result?.depositeddaterangeSophia ?? []);
                setdepositedAbigail(result?.depositeddaterangeAbigail ?? []);
                setdepositedDaniel(result?.depositeddaterangeDaniel ?? []);
                setdepositedNicholas(result?.depositeddaterangeNicholas ?? []);
                setdepositedListowell(data?.depositeddaterangeListowell ?? []);
                setdepositedJuliana(data?.depositeddaterangeJuliana ?? []);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (country === 'Ghana') {
            retrieveAllTodaysMatrix();
        }
    }, [country]);

    return (
        <>
            <br />
            <PageHeader title="Dashboard" subTitle="Home | Report | Approved Deposit " />
            {country === 'Ghana' ? (
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>Note: This Data is based on deposited Date</Grid>

                    <Grid item>
                        <DateRangePicker initialSettings={{ startDate, endDate }} onApply={setChange}>
                            <Button variant="contained" size="sm" className={classes.item}>
                                filter by Date
                            </Button>
                        </DateRangePicker>
                    </Grid>
                </Grid>
            ) : (
                ''
            )}
            <br />
            {status === 'today' && country === 'Ghana' ? (
                <>
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsAlfredCard
                                isLoading={isLoading}
                                depositsdata={depositedAlfred}
                                title="Alfred Adzido Total Deposit For "
                                date="Today"
                                excelName="AlfredTodayTotalDeposit"
                                excelName2="AlfredTodayTotalDeposit.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsAbigailCard
                                isLoading={isLoading}
                                depositsdata={depositedAbigail}
                                title="Abigail Antwi Total Deposit For "
                                date="Today"
                                excelName="AbigailTodayTotalDeposit"
                                excelName2="AbigailTodayTotalDeposit.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsDanielCard
                                isLoading={isLoading}
                                depositsdata={depositedDaniel}
                                title="Daniel Atta Total Deposits For "
                                date="Today"
                                excelName="DanielTodayTotalDeposits"
                                excelName2="DanielTodayTotalDeposits.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsJosephCard
                                isLoading={isLoading}
                                depositsdata={depositedJoseph}
                                title="Joseph Moyimeh Total Deposits For "
                                date="Today"
                                excelName="JosephTodayTotalDeposits"
                                excelName2="JosephTodayTotalDeposits.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsKateCard
                                isLoading={isLoading}
                                depositsdata={depositedKate}
                                title="Kate Annor Tettey Total Deposits For "
                                date="Today"
                                excelName="KateTodayTotalDeposits"
                                excelName2="KateTodayTotalDeposits.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsKwakuCard
                                isLoading={isLoading}
                                depositsdata={depositedKwaku}
                                title="Kwaku Atta Total Deposits For "
                                date="Today"
                                excelName="KwakuTodayTotalDeposits"
                                excelName2="KwakuTodayTotalDeposits.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsNicholasCard
                                isLoading={isLoading}
                                depositsdata={depositedNicholas}
                                title="Nicholas Kwakye Total Deposits For "
                                date="Today"
                                excelName="NicholasTodayTotalDeposits"
                                excelName2="NicholasTodayTotalDeposits.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsPrinceCard
                                isLoading={isLoading}
                                depositsdata={depositedPrince}
                                title="Prince Awuah Total Deposits For "
                                date="Today"
                                excelName="PrinceTodayTotalDeposits"
                                excelName2="PrinceTodayTotalDeposits.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsSelinaCard
                                isLoading={isLoading}
                                depositsdata={depositedSelina}
                                title="Selina Quaye Total Deposits For "
                                date="Today"
                                excelName="SelinaTodayTotalDeposits"
                                excelName2="SelinaTodayTotalDeposits.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsSophiaCard
                                isLoading={isLoading}
                                depositsdata={depositedSophia}
                                title="Sophia Eyram Atsu Total Deposits For "
                                date="Today"
                                excelName="SophiaTodayTotalDeposits"
                                excelName2="SophiaTodayTotalDeposits.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsSelinaCard
                                isLoading={isLoading}
                                depositsdata={depositedListowell}
                                title="Listowell Agyemang Total Deposits For "
                                date="Today"
                                excelName="ListowellTodayTotalDeposits"
                                excelName2="ListowellTodayTotalDeposits.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsSophiaCard
                                isLoading={isLoading}
                                depositsdata={depositedJuliana}
                                title="Juliana Quainoo Total Deposits For "
                                date="Today"
                                excelName="JulianaTodayTotalDeposits"
                                excelName2="JulianaTodayTotalDeposits.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                </>
            ) : (
                ''
            )}

            {status === 'dateselect' && country === 'Ghana' ? (
                <>
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsAlfredCard
                                isLoading={isLoading}
                                depositsdata={depositedAlfred}
                                title="Alfred Adzido Total Deposit For "
                                date={dateRangeTitle}
                                excelName="AlfredTodayTotalDeposit"
                                excelName2="AlfredTodayTotalDeposit.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsAbigailCard
                                isLoading={isLoading}
                                depositsdata={depositedAbigail}
                                title="Abigail Antwi Total Deposit For "
                                date={dateRangeTitle}
                                excelName="AbigailTodayTotalDeposit"
                                excelName2="AbigailTodayTotalDeposit.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsDanielCard
                                isLoading={isLoading}
                                depositsdata={depositedDaniel}
                                title="Daniel Atta Total Deposits For "
                                date={dateRangeTitle}
                                excelName="DanielTodayTotalDeposits"
                                excelName2="DanielTodayTotalDeposits.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsJosephCard
                                isLoading={isLoading}
                                depositsdata={depositedJoseph}
                                title="Joseph Moyimeh Total Deposits For "
                                date={dateRangeTitle}
                                excelName="JosephTodayTotalDeposits"
                                excelName2="JosephTodayTotalDeposits.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsKateCard
                                isLoading={isLoading}
                                depositsdata={depositedKate}
                                title="Kate Annor Tettey Total Deposits For "
                                date={dateRangeTitle}
                                excelName="KateTodayTotalDeposits"
                                excelName2="KateTodayTotalDeposits.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsKwakuCard
                                isLoading={isLoading}
                                depositsdata={depositedKwaku}
                                title="Kwaku Atta Total Deposits For "
                                date={dateRangeTitle}
                                excelName="KwakuTodayTotalDeposits"
                                excelName2="KwakuTodayTotalDeposits.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsNicholasCard
                                isLoading={isLoading}
                                depositsdata={depositedNicholas}
                                title="Nicholas Kwakye Total Deposits For "
                                date={dateRangeTitle}
                                excelName="NicholasTodayTotalDeposits"
                                excelName2="NicholasTodayTotalDeposits.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsPrinceCard
                                isLoading={isLoading}
                                depositsdata={depositedPrince}
                                title="Prince Awuah Total Deposits For "
                                date={dateRangeTitle}
                                excelName="PrinceTodayTotalDeposits"
                                excelName2="PrinceTodayTotalDeposits.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsSelinaCard
                                isLoading={isLoading}
                                depositsdata={depositedSelina}
                                title="Selina Quaye Total Deposits For "
                                date={dateRangeTitle}
                                excelName="SelinaTodayTotalDeposits"
                                excelName2="SelinaTodayTotalDeposits.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsSophiaCard
                                isLoading={isLoading}
                                depositsdata={depositedSophia}
                                title="Sophia Eyram Atsu Total Deposits For "
                                date={dateRangeTitle}
                                excelName="SophiaTodayTotalDeposits"
                                excelName2="SophiaTodayTotalDeposits.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsSelinaCard
                                isLoading={isLoading}
                                depositsdata={depositedListowell}
                                title="Listowell Agyemang Total Deposits For "
                                date={dateRangeTitle}
                                excelName="ListowellTodayTotalDeposits"
                                excelName2="ListowellTodayTotalDeposits.xlsx"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                            <TotalDepositsSophiaCard
                                isLoading={isLoading}
                                depositsdata={depositedJuliana}
                                title="Juliana Quainoo Total Deposits For "
                                date={dateRangeTitle}
                                excelName="JulianaTodayTotalDeposits"
                                excelName2="JulianaTodayTotalDeposits.xlsx"
                            />
                        </Grid>
                    </Grid>
                    <br />
                </>
            ) : (
                ''
            )}
        </>
    );
};

export default DepositReport;
