/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../../components/PageHeader';
import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme, styled } from '@mui/material/styles';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import useScriptRef from 'hooks/useScriptRef';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import EditIcon from '@mui/icons-material/Edit';
// import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import Form from 'react-bootstrap/Form';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import nologo from 'assets/images/nologo.png';
import Stack from '@mui/material/Stack';

const Input = styled('input')({
    display: 'none'
});

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    },
    backgroundColor: {
        backgroundColor: '#FF740F'
    },
    cancelColor: {
        cancelColor: '#D3DADF'
    }
}));

const AddFleetVehicleRequest = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [requestDate, setrequestDate] = useState(new Date());
    const [carRegistrationNo, setcarRegistrationNo] = useState('');
    const [year, setyear] = useState('');
    const [mileage, setmileage] = useState('');
    const [companyName, setcompanyName] = useState('');
    // const [car, setcar] = useState('');
    const [carName, setcarName] = useState('');
    // const [carmodel, setcarmodel] = useState('');
    const [carmodelName, setcarmodelName] = useState('');
    const [customer, setcustomer] = useState('');
    const [customerContact, setcustomerContact] = useState('');
    const [engineNo, setengineNo] = useState('');
    const [vinNo, setvinNo] = useState('');
    // const [status, setstatus] = useState('');
    const [channelPartnerId, setchannelPartnerId] = useState('');
    const [loading3, setLoading3] = useState(false);

    // const [carData, setCarData] = useState([]);
    // const [carmodelData, setCarModelData] = useState([]);
    const [servicingHistory, setservicingHistory] = useState([]);
    const [previousPartsChanged, setpreviousPartsChanged] = useState([]);
    const [workDoneHistory, setworkDoneHistory] = useState([]);
    const [attachments, setattachments] = useState([]);
    const [carpictures, setcarpictures] = useState([]);
    const [dateServiced, setdateServiced] = useState(new Date());
    const [serviceName, setserviceName] = useState('');
    const [serviceDescription, setserviceDescription] = useState([]);
    const [automobileCompany, setautomobileCompany] = useState('');
    const [datePartChanged, setdatePartChanged] = useState(new Date());
    const [registrationDate, setregistrationDate] = useState('');
    const [partName, setpartName] = useState('');
    const [partModel, setpartModel] = useState('');
    const [partAutomobileCompany, setpartAutomobileCompany] = useState('');
    const [dateWorkDone, setdateWorkDone] = useState(new Date());
    const [nameOfWorkDone, setnameOfWorkDone] = useState('');
    const [workDescription, setworkDescription] = useState('');
    const [workAutomobileCompany, setworkAutomobileCompany] = useState('');
    const [doc, setdoc] = useState('');
    const [image, setimage] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading4, setLoading4] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [indexValueservicingHistory, setIndexValueservicingHistory] = useState('');
    const [indexValuepreviousPartsChanged, setIndexValuepreviousPartsChanged] = useState('');
    const [indexValueworkDoneHistory, setIndexValueworkDoneHistory] = useState('');
    const [indexValueattachments, setIndexValueattachments] = useState('');
    const [indexValuecarpictures, setIndexValuecarpictures] = useState('');
    const [openaddservicingHistory, setOpenaddservicingHistory] = useState(false);
    const [openeditservicingHistory, setOpeneditservicingHistory] = useState(false);

    const [openaddpreviousPartsChanged, setOpenaddpreviousPartsChanged] = useState(false);
    const [openeditpreviousPartsChanged, setOpeneditpreviousPartsChanged] = useState(false);

    const [openaddworkDoneHistory, setOpenaddworkDoneHistory] = useState(false);
    const [openeditworkDoneHistory, setOpeneditworkDoneHistory] = useState(false);

    const [openaddattachments, setOpenaddattachments] = useState(false);
    const [openeditattachments, setOpeneditattachments] = useState(false);

    const [openaddcarpictures, setOpenaddcarpictures] = useState(false);
    const [openeditcarpictures, setOpeneditcarpictures] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    console.log(indexValueattachments);
    console.log(indexValuecarpictures);
    console.log(setLoading(false));

    // const retrieveAllCar = async () => {
    //     try {
    //         const userInfo = localStorage.getItem('userToken');
    //         const config = {
    //             headers: {
    //                 'auth-token': userInfo,
    //                 'Content-Type': 'application/json'
    //             }
    //         };
    //         setLoading(true);
    //         fetch(ApiUrl.AllFleetCar, config)
    //             .then((data) => data.json())
    //             .then((data) => {
    //                 if (data.status === true) {
    //                     setCarData(data.data);
    //                 } else {
    //                     toast.error(data.data);
    //                 }
    //             })
    //             .finally(() => {
    //                 setLoading(false);
    //             });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    // const retrieveAllCarModel = async (id) => {
    //     try {
    //         const userInfo = localStorage.getItem('userToken');
    //         const config = {
    //             headers: {
    //                 'auth-token': userInfo,
    //                 'Content-Type': 'application/json'
    //             }
    //         };

    //         fetch(ApiUrl.AllCarModelsDetailsUrlFleetModel + id, config)
    //             .then((data) => data.json())
    //             .then((data) => {
    //                 if (data.status === true) {
    //                     setCarModelData(data.data);
    //                 } else {
    //                     toast.error(data.data);
    //                 }
    //             })
    //             .finally(() => {
    //                 setLoading(false);
    //             });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    useEffect(() => {
        // retrieveAllCar();

        const company = localStorage.getItem('company');
        const channelPartnerId = localStorage.getItem('channelpartnerId');
        setcompanyName(company);
        setchannelPartnerId(channelPartnerId);
    }, []);

    const handleRequestDateChange = (newValue) => {
        setrequestDate(newValue);
    };

    const handleDateServicedChange = (newValue) => {
        setdateServiced(newValue);
    };

    const handleDatePartChange = (newValue) => {
        setdatePartChanged(newValue);
    };

    const handleDateWorkDoneChange = (newValue) => {
        setdateWorkDone(newValue);
    };

    const handleClickOpenAddservicingHistory = () => {
        setOpenaddservicingHistory(true);
    };

    const handleCloseAddservicingHistory = () => {
        setOpenaddservicingHistory(false);
    };

    const handleClickOpenEditservicingHistory = () => {
        setOpeneditservicingHistory(true);
    };

    const handleCloseEditservicingHistory = () => {
        setOpeneditservicingHistory(false);
    };

    const handleClickOpenAddpreviousPartsChanged = () => {
        setOpenaddpreviousPartsChanged(true);
    };

    const handleCloseAddpreviousPartsChanged = () => {
        setOpenaddpreviousPartsChanged(false);
    };

    const handleClickOpenEditpreviousPartsChanged = () => {
        setOpeneditpreviousPartsChanged(true);
    };

    const handleCloseEditpreviousPartsChanged = () => {
        setOpeneditpreviousPartsChanged(false);
    };

    const handleClickOpenAddworkDoneHistory = () => {
        setOpenaddworkDoneHistory(true);
    };

    const handleCloseAddworkDoneHistory = () => {
        setOpenaddworkDoneHistory(false);
    };

    const handleClickOpenEditworkDoneHistory = () => {
        setOpeneditworkDoneHistory(true);
    };

    const handleCloseEditworkDoneHistory = () => {
        setOpeneditworkDoneHistory(false);
    };

    const handleClickOpenAddattachments = () => {
        setOpenaddattachments(true);
    };

    const handleCloseAddattachments = () => {
        setOpenaddattachments(false);
    };

    const handleClickOpenEditattachments = () => {
        setOpeneditattachments(true);
    };

    const handleCloseEditattachments = () => {
        setOpeneditattachments(false);
    };

    // car pictures
    const handleClickOpenAddcarpictures = () => {
        setOpenaddcarpictures(true);
    };

    const handleCloseAddcarpictures = () => {
        setOpenaddcarpictures(false);
    };

    const handleClickOpenEditcarpictures = () => {
        setOpeneditcarpictures(true);
    };

    const handleCloseEditcarpictures = () => {
        setOpeneditcarpictures(false);
    };

    const selectedFile = (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        setLoading3(true);
        const userInfo = localStorage.getItem('userToken');
        axios
            .post(ApiUrl.UploadCollectionImageUrl, formData, {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => {
                if (result.data.status === true) {
                    setLoading3(false);
                    setdoc(result?.data?.data ?? '');
                } else {
                    toast.error(result.data);
                    setLoading3(false);
                }
            })
            .catch((err) => {
                toast.error(err);
                setLoading3(false);
            });
    };

    const selectedCarImage = (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        setLoading4(true);
        const userInfo = localStorage.getItem('userToken');
        axios
            .post(ApiUrl.UploadCollectionImageUrl, formData, {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => {
                if (result.data.status === true) {
                    setLoading4(false);
                    setimage(result?.data?.data ?? '');
                } else {
                    toast.error(result.data);
                    setLoading4(false);
                }
            })
            .catch((err) => {
                toast.error(err);
                setLoading4(false);
            });
    };

    // const handleCarMakeChange = (event) => {
    //     setcar(event.target.value);
    //     retrieveAllCarModel(event.target.value);

    //     const newFilter = carData.filter((value) => (value._id || '').toLowerCase().includes((event.target.value || '').toLowerCase()));
    //     newFilter.forEach((option) => {
    //         setcarName(option.name);
    //     });
    // };

    const onSubmitservicingHistoryEdit = (event) => {
        event.preventDefault();
        const newList = Object.assign([], servicingHistory);
        const data = { ...newList[indexValueservicingHistory] };
        data.dateServiced = dateServiced;
        data.serviceName = serviceName;
        data.serviceDescription = serviceDescription;
        data.automobileCompany = automobileCompany;
        newList[indexValueservicingHistory] = data;
        setservicingHistory(newList);
        handleCloseEditservicingHistory();
    };

    const onSubmitpreviousPartsChangedEdit = (event) => {
        event.preventDefault();
        const newList = Object.assign([], previousPartsChanged);
        const data = { ...newList[indexValuepreviousPartsChanged] };
        data.datePartChanged = datePartChanged;
        data.partName = partName;
        data.partModel = partModel;
        data.partAutomobileCompany = partAutomobileCompany;
        newList[indexValuepreviousPartsChanged] = data;
        setpreviousPartsChanged(newList);
        handleCloseEditpreviousPartsChanged();
    };

    const onSubmitEditworkDoneHistory = (event) => {
        event.preventDefault();
        const newList = Object.assign([], workDoneHistory);
        const data = { ...newList[indexValueworkDoneHistory] };
        data.dateWorkDone = dateWorkDone;
        data.nameOfWorkDone = nameOfWorkDone;
        data.workDescription = workDescription;
        data.workAutomobileCompany = workAutomobileCompany;
        newList[indexValueworkDoneHistory] = data;
        setworkDescription(newList);
        handleCloseEditworkDoneHistory();
    };

    // const onSubmitEditattachments = (event) => {
    //     event.preventDefault();
    //     const newList = Object.assign([], attachments);
    //     const data = { ...newList[indexValueattachments] };
    //     data.doc = doc;
    //     newList[indexValueattachments] = data;
    //     setattachments(newList);
    //     handleCloseEditattachments();
    // };

    const onSubmitservicingHistory = (event) => {
        event.preventDefault();
        setservicingHistory([
            ...servicingHistory,
            {
                dateServiced,
                serviceName,
                serviceDescription,
                automobileCompany
            }
        ]);

        handleCloseAddservicingHistory();
    };

    const onSubmitpreviousPartsChanged = (event) => {
        event.preventDefault();
        // previousPartsChanged, setpreviousPartsChanged
        setpreviousPartsChanged([
            ...previousPartsChanged,
            {
                datePartChanged,
                partName,
                partModel,
                partAutomobileCompany
            }
        ]);

        handleCloseAddpreviousPartsChanged();
    };

    const onSubmitworkDoneHistory = (event) => {
        event.preventDefault();
        // customer complaint
        setworkDoneHistory([
            ...workDoneHistory,
            {
                dateWorkDone,
                nameOfWorkDone,
                workDescription,
                workAutomobileCompany
            }
        ]);

        handleCloseAddworkDoneHistory();
    };

    const onSubmitattachments = (event) => {
        event.preventDefault();
        // attachments, setattachments
        setattachments([
            ...attachments,
            {
                doc
            }
        ]);

        handleCloseAddattachments();
    };

    // car images
    const onSubmitimages = (event) => {
        event.preventDefault();
        // images, setimages
        setcarpictures([
            ...carpictures,
            {
                image
            }
        ]);

        handleCloseAddcarpictures();
    };

    // Submit Order
    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        const status = 'Awaiting Approval';
        setLoading2(true);
        const data = {
            channelPartnerId,
            requestDate,
            carRegistrationNo,
            registrationDate,
            year,
            mileage,
            companyName,
            // car,
            carName,
            // carmodel,
            carmodelName,
            customer,
            customerContact,
            engineNo,
            vinNo,
            status,
            servicingHistory,
            previousPartsChanged,
            workDoneHistory,
            attachments,
            carpictures
        };

        fetch(ApiUrl.AddFleetVehicleRequestUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading2(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/fleetvehiclerequest');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                }
            })
            .finally(() => {
                setLoading2(false);
            });
    };

    // const filterCarModel = (event) => {
    //     const newFilter = carmodelData.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
    //     newFilter.forEach((option) => {
    //         setcarmodel(option._id);
    //         setcarRegistrationNo(option.vehicleRegNo);
    //         setyear(option.year);
    //         setvinNo(option.vin);
    //     });
    // };

    // const handleStatus = (event) => {
    //     setstatus(event.target.value);
    // };

    const handleDeleteClickservicingHistory = (row, index) => {
        const newList = Object.assign([], servicingHistory);
        newList.splice(index, 1);
        setservicingHistory(newList);
    };

    const handleDeleteClickpreviousPartsChanged = (row, index) => {
        const newList = Object.assign([], previousPartsChanged);
        newList.splice(index, 1);
        setpreviousPartsChanged(newList);
    };

    const handleDeleteClickworkDoneHistory = (row, index) => {
        const newList = Object.assign([], workDoneHistory);
        newList.splice(index, 1);
        setworkDoneHistory(newList);
    };

    const handleDeleteClickattachments = (row, index) => {
        const newList = Object.assign([], attachments);
        newList.splice(index, 1);
        setattachments(newList);
    };

    const handleDeleteClickcarpictures = (row, index) => {
        const newList = Object.assign([], carpictures);
        newList.splice(index, 1);
        setcarpictures(newList);
    };

    const handleEditClickservicingHistory = (row, index) => {
        setdateServiced(row.dateServiced ?? '');
        setserviceName(row.serviceName ?? '');
        setserviceDescription(row.serviceDescription ?? '');
        setautomobileCompany(row.automobileCompany ?? '');
        handleClickOpenEditservicingHistory();

        setIndexValueservicingHistory(index);
    };

    const handleEditClickpreviousPartsChanged = (row, index) => {
        setdatePartChanged(row?.datePartChanged ?? '');
        setpartName(row?.partName ?? '');
        setpartModel(row?.partModel ?? '');
        setpartAutomobileCompany(row?.partAutomobileCompany ?? '');
        handleClickOpenEditpreviousPartsChanged();

        setIndexValuepreviousPartsChanged(index);
    };

    const handleEditClickworkDoneHistory = (row, index) => {
        setdateWorkDone(row?.dateWorkDone ?? '');
        setnameOfWorkDone(row?.nameOfWorkDone ?? '');
        setworkDescription(row?.workDescription ?? '');
        setworkAutomobileCompany(row?.workAutomobileCompany ?? '');
        handleClickOpenEditworkDoneHistory();

        setIndexValueworkDoneHistory(index);
    };
    // const handleRegistrationtDateChange = (newValue) => {
    //     setregistrationDate(newValue);
    // };

    const handleEditClickattachments = (row, index) => {
        setdoc(row?.doc ?? '');
        handleClickOpenEditattachments();
        setIndexValueattachments(index);
    };

    const handleEditClickcarpictures = (row, index) => {
        setimage(row?.image ?? '');
        handleClickOpenEditcarpictures();
        setIndexValuecarpictures(index);
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    return (
        <>
            <PageHeader title="Home | Fleet Management" subTitle="Home | Add vehicle to fleet" />

            {loading ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <MainCard title="">
                        <Typography variant="subtitle2" component="div">
                            Vehicle Request
                        </Typography>
                        <br />
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                label="Date"
                                                inputFormat="MM/dd/yyyy"
                                                value={requestDate}
                                                onChange={handleRequestDateChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="carRegistrationNo"
                                            variant="outlined"
                                            id="carRegistrationNo"
                                            label="Car Registration No."
                                            value={carRegistrationNo}
                                            onChange={(e) => setcarRegistrationNo(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="registrationDate"
                                            variant="outlined"
                                            id="registrationDate"
                                            label="Registration Date"
                                            value={registrationDate}
                                            onChange={(e) => setregistrationDate(e.target.value)}
                                        />
                                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                label="Registration Date"
                                                inputFormat="MM/dd/yyyy"
                                                value={registrationDate}
                                                onChange={handleRegistrationtDateChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider> */}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="year"
                                            variant="outlined"
                                            id="year"
                                            label="Year"
                                            value={year}
                                            onChange={(e) => setyear(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="mileage"
                                            variant="outlined"
                                            id="mileage"
                                            label="Mileage"
                                            value={mileage}
                                            onChange={(e) => setmileage(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="carName"
                                            variant="outlined"
                                            id="carName"
                                            label="Vehicle make"
                                            value={carName}
                                            onChange={(e) => {
                                                setcarName(e.target.value);
                                            }}
                                        />
                                        {/* <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Vehicle Make *</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={car}
                                                required
                                                label="Vehicle Make *"
                                                onChange={handleCarMakeChange}
                                            >
                                                {carData.map((cars) => (
                                                    <MenuItem key={cars._id} value={cars._id}>
                                                        {cars.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl> */}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="carmodelName"
                                            variant="outlined"
                                            id="carmodelName"
                                            label="Model Name"
                                            value={carmodelName}
                                            onChange={(e) => {
                                                setcarmodelName(e.target.value);
                                            }}
                                        />
                                        {/* <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={carmodelName}
                                            onChange={(event, newValue) => {
                                                filterCarModel(newValue);
                                                setcarmodelName(newValue);
                                            }}
                                            options={carmodelData.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Vehicle Model *" />}
                                        /> */}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="customer"
                                            variant="outlined"
                                            id="customer"
                                            label="Customer"
                                            value={customer}
                                            onChange={(e) => {
                                                setcustomer(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="customerContact"
                                            variant="outlined"
                                            id="customerContact"
                                            label="Customer Contact No."
                                            value={customerContact}
                                            onChange={(e) => {
                                                setcustomerContact(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="engineNo"
                                            variant="outlined"
                                            id="engineNo"
                                            label="Engine No."
                                            value={engineNo}
                                            onChange={(e) => {
                                                setengineNo(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="vinNo"
                                            variant="outlined"
                                            id="vinNo"
                                            label="VIN No."
                                            value={vinNo}
                                            onChange={(e) => {
                                                setvinNo(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />
                            <br />

                            <Typography variant="subtitle2" component="div">
                                Vehicle Pictures
                            </Typography>
                            <br />

                            <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                <Button
                                    variant="contained"
                                    onClick={handleClickOpenAddcarpictures}
                                    size="sm"
                                    className={classes.backgroundColor}
                                >
                                    Attach pictures
                                </Button>
                            </Box>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Pictures
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {carpictures.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Avatar
                                                                variant="rounded"
                                                                src={row.image ?? nologo}
                                                                sx={{ width: 100, height: 70 }}
                                                            />
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                                aria-label="edit"
                                                                color="primary"
                                                                onClick={() => handleEditClickcarpictures(row, index)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                onClick={() => handleDeleteClickcarpictures(row, index)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <br />

                            <Typography variant="subtitle2" component="div">
                                Servicing History
                            </Typography>
                            <br />
                            <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                <Button
                                    variant="contained"
                                    onClick={handleClickOpenAddservicingHistory}
                                    size="sm"
                                    className={classes.backgroundColor}
                                >
                                    Add
                                </Button>
                            </Box>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Date Serviced
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Name of service carried out
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Description
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Automobile company
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {servicingHistory.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {toTimestamp(row?.dateServiced) ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.serviceName ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row?.serviceDescription ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row?.automobileCompany ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                                aria-label="edit"
                                                                color="primary"
                                                                onClick={() => handleEditClickservicingHistory(row, index)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                onClick={() => handleDeleteClickservicingHistory(row, index)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <br />
                            <Typography variant="subtitle2" component="div">
                                Previous Parts Changed
                            </Typography>
                            <br />
                            <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                <Button
                                    variant="contained"
                                    onClick={handleClickOpenAddpreviousPartsChanged}
                                    size="sm"
                                    className={classes.backgroundColor}
                                >
                                    Add
                                </Button>
                            </Box>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Date part was changed
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Part name
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Model/Make/Manufacturer
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Automobile company
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {previousPartsChanged.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {toTimestamp(row?.datePartChanged) ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.partName ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row?.partModel ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row?.partAutomobileCompany ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                                aria-label="edit"
                                                                color="primary"
                                                                onClick={() => handleEditClickpreviousPartsChanged(row, index)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                onClick={() => handleDeleteClickpreviousPartsChanged(row, index)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <br />

                            <Typography variant="subtitle2" component="div">
                                Work Done History
                            </Typography>
                            <br />
                            <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                <Button
                                    variant="contained"
                                    onClick={handleClickOpenAddworkDoneHistory}
                                    size="sm"
                                    className={classes.backgroundColor}
                                >
                                    Add
                                </Button>
                            </Box>
                            <br />

                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Date work was done
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Name of work done
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Description
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Automobile company
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {workDoneHistory.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {toTimestamp(row?.dateWorkDone) ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.nameOfWorkDone ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.workDescription ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.workAutomobileCompany ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                                aria-label="edit"
                                                                color="primary"
                                                                onClick={() => handleEditClickworkDoneHistory(row, index)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                onClick={() => handleDeleteClickworkDoneHistory(row, index)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>

                            <br />

                            <Typography variant="subtitle2" component="div">
                                Attach Document
                            </Typography>
                            <br />

                            <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                <Button
                                    variant="contained"
                                    onClick={handleClickOpenAddattachments}
                                    size="sm"
                                    className={classes.backgroundColor}
                                >
                                    Attach Document
                                </Button>
                            </Box>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Document
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {attachments.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Avatar
                                                                variant="rounded"
                                                                src={row.doc ?? nologo}
                                                                sx={{ width: 100, height: 70 }}
                                                            />
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                                aria-label="edit"
                                                                color="primary"
                                                                onClick={() => handleEditClickattachments(row, index)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                onClick={() => handleDeleteClickattachments(row, index)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <br />

                            <br />
                            <br />
                            <Box display="flex" justifyContent="space-between">
                                <Button component={Link} to="/fleetvehiclerequest" type="submit" variant="contained" color="error">
                                    Cancel
                                </Button>
                                <Button type="submit" variant="contained" className={classes.backgroundColor}>
                                    {loading2 ? 'Saving ..' : 'Save'}
                                </Button>
                            </Box>
                        </form>

                        {/* edit servicingHistory */}

                        <Dialog
                            fullScreen={fullScreen}
                            open={openeditservicingHistory}
                            onClose={handleCloseEditservicingHistory}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">Edit Servicing History</DialogTitle>
                            <Form onSubmit={onSubmitservicingHistoryEdit}>
                                <DialogContent>
                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Box width={200}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DesktopDatePicker
                                                    label="Date Serviced"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={dateServiced}
                                                    onChange={handleDateServicedChange}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="serviceName"
                                                variant="outlined"
                                                id="serviceName"
                                                label="Name of Service Carried out"
                                                value={serviceName}
                                                onChange={(e) => setserviceName(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="serviceDescription"
                                                variant="outlined"
                                                required
                                                id="serviceDescription"
                                                label="Description"
                                                value={serviceDescription}
                                                onChange={(e) => setserviceDescription(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="automobileCompany"
                                                variant="outlined"
                                                id="automobileCompany"
                                                label="Automobile company"
                                                value={automobileCompany}
                                                onChange={(e) => setautomobileCompany(e.target.value)}
                                            />
                                        </Box>
                                    </Form.Group>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="sm"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleCloseEditservicingHistory}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button autoFocus variant="contained" type="submit" size="sm" className={classes.item}>
                                        Update
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>
                        {/* edit servicingHistory */}

                        {/* add servicingHistory */}

                        <Dialog
                            fullScreen={fullScreen}
                            open={openaddservicingHistory}
                            onClose={handleCloseAddservicingHistory}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">Add Servicing History</DialogTitle>
                            <Form onSubmit={onSubmitservicingHistory}>
                                <DialogContent>
                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Box width={200}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DesktopDatePicker
                                                    label="Date Serviced"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={dateServiced}
                                                    onChange={handleDateServicedChange}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="serviceName"
                                                variant="outlined"
                                                id="serviceName"
                                                label="Name of Service Carried out"
                                                value={serviceName}
                                                onChange={(e) => setserviceName(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="serviceDescription"
                                                variant="outlined"
                                                required
                                                id="serviceDescription"
                                                label="Description"
                                                value={serviceDescription}
                                                onChange={(e) => setserviceDescription(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="automobileCompany"
                                                variant="outlined"
                                                id="automobileCompany"
                                                label="Automobile company"
                                                value={automobileCompany}
                                                onChange={(e) => setautomobileCompany(e.target.value)}
                                            />
                                        </Box>
                                    </Form.Group>

                                    {/* </DialogContentText> */}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="sm"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleCloseAddservicingHistory}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        type="submit"
                                        size="sm"
                                        className={classes.item}
                                        disabled={!dateServiced || !serviceName}
                                    >
                                        Add
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>
                        {/* edit previousPartsChanged */}

                        <Dialog
                            fullScreen={fullScreen}
                            open={openeditpreviousPartsChanged}
                            onClose={handleCloseEditpreviousPartsChanged}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">Edit Previous Parts Changed</DialogTitle>
                            <Form onSubmit={onSubmitpreviousPartsChangedEdit}>
                                <DialogContent>
                                    {/* <DialogContentText> */}

                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Box width={200}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DesktopDatePicker
                                                    label="Date part was changed"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={datePartChanged}
                                                    onChange={handleDatePartChange}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="partName"
                                                variant="outlined"
                                                id="partName"
                                                label="Part name"
                                                value={partName}
                                                onChange={(e) => setpartName(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="partModel"
                                                variant="outlined"
                                                id="partModel"
                                                label="Model/Make/Manufacturer"
                                                value={partModel}
                                                onChange={(e) => setpartModel(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="automobileCompany"
                                                variant="outlined"
                                                id="automobileCompany"
                                                label="Automobile company"
                                                value={partAutomobileCompany}
                                                onChange={(e) => setpartAutomobileCompany(e.target.value)}
                                            />
                                        </Box>
                                    </Form.Group>

                                    {/* </DialogContentText> */}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="sm"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleCloseEditpreviousPartsChanged}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        type="submit"
                                        size="sm"
                                        className={classes.item}
                                        disabled={!datePartChanged || !partName}
                                    >
                                        Update
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>
                        {/* edit previous part */}

                        {/* add previous part */}

                        <Dialog
                            fullScreen={fullScreen}
                            open={openaddpreviousPartsChanged}
                            onClose={handleCloseAddpreviousPartsChanged}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">Add Previous Parts Changed</DialogTitle>
                            <Form onSubmit={onSubmitpreviousPartsChanged}>
                                <DialogContent>
                                    {/* <DialogContentText> */}

                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Box width={200}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DesktopDatePicker
                                                    label="Date part was changed"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={datePartChanged}
                                                    onChange={handleDatePartChange}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="partName"
                                                variant="outlined"
                                                id="partName"
                                                label="Part name"
                                                value={partName}
                                                onChange={(e) => setpartName(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="partModel"
                                                variant="outlined"
                                                id="partModel"
                                                label="Model/Make/Manufacturer"
                                                value={partModel}
                                                onChange={(e) => setpartModel(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="automobileCompany"
                                                variant="outlined"
                                                id="automobileCompany"
                                                label="Automobile company"
                                                value={partAutomobileCompany}
                                                onChange={(e) => setpartAutomobileCompany(e.target.value)}
                                            />
                                        </Box>
                                    </Form.Group>

                                    {/* </DialogContentText> */}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="sm"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleCloseAddpreviousPartsChanged}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        type="submit"
                                        size="sm"
                                        className={classes.item}
                                        disabled={!datePartChanged || !partName}
                                    >
                                        Add
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>
                        {/* add previous parts changed */}

                        {/* edit workDoneHistory */}

                        <Dialog
                            fullScreen={fullScreen}
                            open={openeditworkDoneHistory}
                            onClose={handleCloseEditworkDoneHistory}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">Edit Previous Work done</DialogTitle>
                            <Form onSubmit={onSubmitEditworkDoneHistory}>
                                <DialogContent>
                                    {/* <DialogContentText> */}

                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Box width={200}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DesktopDatePicker
                                                    label="Date work was done"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={dateWorkDone}
                                                    onChange={handleDateWorkDoneChange}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="nameOfWorkDone"
                                                variant="outlined"
                                                id="nameOfWorkDone"
                                                label="Name of work done"
                                                value={nameOfWorkDone}
                                                onChange={(e) => setnameOfWorkDone(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="workDescription"
                                                variant="outlined"
                                                id="workDescription"
                                                label="Description"
                                                value={workDescription}
                                                onChange={(e) => setworkDescription(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="workAutomobileCompany"
                                                variant="outlined"
                                                id="workAutomobileCompany"
                                                label="Automobile company"
                                                value={workAutomobileCompany}
                                                onChange={(e) => setworkAutomobileCompany(e.target.value)}
                                            />
                                        </Box>
                                    </Form.Group>

                                    {/* </DialogContentText> */}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="sm"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleCloseEditworkDoneHistory}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        type="submit"
                                        size="sm"
                                        className={classes.item}
                                        disabled={!dateWorkDone || !nameOfWorkDone}
                                    >
                                        Update
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>
                        {/* edit work done */}

                        {/* add work done */}
                        <Dialog
                            fullScreen={fullScreen}
                            open={openaddworkDoneHistory}
                            onClose={handleCloseAddworkDoneHistory}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">Add Previous Work Done</DialogTitle>
                            <Form onSubmit={onSubmitworkDoneHistory}>
                                <DialogContent>
                                    {/* <DialogContentText> */}

                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Box width={200}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DesktopDatePicker
                                                    label="Date work was done"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={dateWorkDone}
                                                    onChange={handleDateWorkDoneChange}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="nameOfWorkDone"
                                                variant="outlined"
                                                id="nameOfWorkDone"
                                                label="Name of work done"
                                                value={nameOfWorkDone}
                                                onChange={(e) => setnameOfWorkDone(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="workDescription"
                                                variant="outlined"
                                                id="workDescription"
                                                label="Description"
                                                value={workDescription}
                                                onChange={(e) => setworkDescription(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="workAutomobileCompany"
                                                variant="outlined"
                                                id="workAutomobileCompany"
                                                label="Automobile company"
                                                value={workAutomobileCompany}
                                                onChange={(e) => setworkAutomobileCompany(e.target.value)}
                                            />
                                        </Box>
                                    </Form.Group>

                                    {/* </DialogContentText> */}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="sm"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleCloseAddworkDoneHistory}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        type="submit"
                                        size="sm"
                                        className={classes.item}
                                        disabled={!dateWorkDone || !nameOfWorkDone}
                                    >
                                        Add
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>
                        {/* add work done */}

                        {/* edit attachment */}

                        <Dialog
                            fullScreen={fullScreen}
                            open={openeditattachments}
                            onClose={handleCloseEditattachments}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">Edit Attach Document</DialogTitle>
                            <Form onSubmit={onSubmitattachments}>
                                <DialogContent>
                                    {/* <DialogContentText> */}

                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Box width={200}>
                                            <InputLabel id="demo-simple-select-label">Attach Document</InputLabel>
                                            <Stack direction="row" alignItems="center" spacing={4}>
                                                <Avatar variant="rounded" src={doc ?? nologo} sx={{ width: 100, height: 70 }} />

                                                <label htmlFor="contained-button-file">
                                                    <Input
                                                        accept="image/*"
                                                        id="contained-button-file"
                                                        multiple
                                                        type="file"
                                                        onChange={selectedFile}
                                                    />
                                                    <Button variant="contained" component="span" disabled={loading3}>
                                                        {loading3 ? 'Uploading ...' : 'Upload'}
                                                    </Button>
                                                </label>
                                            </Stack>
                                        </Box>
                                    </Form.Group>

                                    {/* </DialogContentText> */}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="sm"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleCloseEditattachments}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button autoFocus variant="contained" type="submit" size="sm" className={classes.item} disabled={!doc}>
                                        Update
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>

                        {/* edit attachment */}

                        <Dialog
                            fullScreen={fullScreen}
                            open={openaddattachments}
                            onClose={handleCloseAddattachments}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">Add Attach Document</DialogTitle>
                            <Form onSubmit={onSubmitattachments}>
                                <DialogContent>
                                    {/* <DialogContentText> */}

                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Box width={200}>
                                            <InputLabel id="demo-simple-select-label">Attach Document</InputLabel>
                                            <Stack direction="row" alignItems="center" spacing={4}>
                                                <Avatar variant="rounded" src={doc ?? nologo} sx={{ width: 100, height: 70 }} />

                                                <label htmlFor="contained-button-file">
                                                    <Input
                                                        accept="image/*"
                                                        id="contained-button-file"
                                                        multiple
                                                        type="file"
                                                        onChange={selectedFile}
                                                    />
                                                    <Button variant="contained" component="span" disabled={loading3}>
                                                        {loading3 ? 'Uploading ...' : 'Upload'}
                                                    </Button>
                                                </label>
                                            </Stack>
                                        </Box>
                                    </Form.Group>

                                    {/* </DialogContentText> */}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="sm"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleCloseAddattachments}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button autoFocus variant="contained" type="submit" size="sm" className={classes.item} disabled={!doc}>
                                        Add
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>

                        {/* edit car pictures */}

                        <Dialog
                            fullScreen={fullScreen}
                            open={openeditcarpictures}
                            onClose={handleCloseEditcarpictures}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">Edit Car Picture</DialogTitle>
                            <Form onSubmit={onSubmitimages}>
                                <DialogContent>
                                    {/* <DialogContentText> */}

                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Box width={200}>
                                            <InputLabel id="demo-simple-select-label">Attach Car Picture</InputLabel>
                                            <Stack direction="row" alignItems="center" spacing={4}>
                                                <Avatar variant="rounded" src={image ?? nologo} sx={{ width: 100, height: 70 }} />

                                                <label htmlFor="contained-button-file">
                                                    <Input
                                                        accept="image/*"
                                                        id="contained-button-file"
                                                        multiple
                                                        type="file"
                                                        onChange={selectedCarImage}
                                                    />
                                                    <Button variant="contained" component="span" disabled={loading4}>
                                                        {loading4 ? 'Uploading ...' : 'Upload'}
                                                    </Button>
                                                </label>
                                            </Stack>
                                        </Box>
                                    </Form.Group>

                                    {/* </DialogContentText> */}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="sm"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleCloseEditcarpictures}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        type="submit"
                                        size="sm"
                                        className={classes.item}
                                        disabled={!image}
                                    >
                                        Update
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>

                        {/* add car picture */}

                        <Dialog
                            fullScreen={fullScreen}
                            open={openaddcarpictures}
                            onClose={handleCloseAddcarpictures}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">Add Car Picture</DialogTitle>
                            <Form onSubmit={onSubmitimages}>
                                <DialogContent>
                                    {/* <DialogContentText> */}

                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Box width={200}>
                                            <InputLabel id="demo-simple-select-label">Attach Picture</InputLabel>
                                            <Stack direction="row" alignItems="center" spacing={4}>
                                                <Avatar variant="rounded" src={image ?? nologo} sx={{ width: 100, height: 70 }} />

                                                <label htmlFor="contained-button-file">
                                                    <Input
                                                        accept="image/*"
                                                        id="contained-button-file"
                                                        multiple
                                                        type="file"
                                                        onChange={selectedCarImage}
                                                    />
                                                    <Button variant="contained" component="span" disabled={loading4}>
                                                        {loading4 ? 'Uploading ...' : 'Upload'}
                                                    </Button>
                                                </label>
                                            </Stack>
                                        </Box>
                                    </Form.Group>

                                    {/* </DialogContentText> */}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="sm"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleCloseAddcarpictures}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        type="submit"
                                        size="sm"
                                        className={classes.item}
                                        disabled={!image}
                                    >
                                        Add
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>
                    </MainCard>
                </>
            )}
        </>
    );
};
export default AddFleetVehicleRequest;
