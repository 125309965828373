import React, { useState } from 'react';
import { Table, Paper, Space, Button, Select } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import WestIcon from '@mui/icons-material/West';
import './logistics.css';
import TrackingMap from './TrackingMap';
import Image from '../../assets/images/Frame 1000002229.png';
import Divider from '@mui/material/Divider';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthEastIcon from '@mui/icons-material/SouthEast';

const userFirstName = localStorage.getItem('userFirstName');
const country = localStorage.getItem('country');
const mapCenter = country === 'Ghana' ? { lat: 6.5, lng: -1.1 } : { lat: 6.465422, lng: 3.406448 };

const Tracking = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="logistic-container">
                <List
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '0 0 16px 0',
                        borderBottom: '1px dashed #E7EAEB',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        margin: '0px auto 15px'
                    }}
                >
                    <ListItem
                        sx={{ fontWeight: 500, fontSize: '1.875rem', color: '#0A2938', padding: 0 }}
                    >{`Hello, ${userFirstName}  👋🏽`}</ListItem>
                    <ListItem sx={{ fontSize: '0.875rem', color: '#565C6B', padding: 0 }}>How are you doing today?</ListItem>
                </List>
            </div>
            <div className="track-heading">
                <WestIcon sx={{ fontSize: '32px', marginLeft: '25px', cursor: 'pointer' }} onClick={() => navigate(-1)} />
                <span>Tracking</span>
            </div>
            <div className="tracking-container">
                <div className="tracking-map">
                    <TrackingMap position={{ lat: -34.397, lng: 150.644 }} mapCenter={mapCenter} />
                </div>
                <div className="tracking-details">
                    <div className="tracking-period">
                        <h1>Tracking Period</h1>
                        <Space wrap style={{ marginLeft: '11px', width: '100%', display: 'flex' }}>
                            <Select
                                defaultValue="Day"
                                style={{ width: 176 }}
                                size="large"
                                allowClear
                                options={[{ value: 'Day', label: 'Day' }]}
                            />
                            <Select
                                defaultValue="Month"
                                style={{ width: 176 }}
                                size="large"
                                allowClear
                                options={[{ value: 'Month', label: 'Month' }]}
                            />
                            <Select
                                defaultValue="Time"
                                style={{ width: 176 }}
                                size="large"
                                allowClear
                                options={[{ value: 'Time', label: 'Time' }]}
                            />
                        </Space>
                    </div>
                    <div className="drive-details">
                        <div style={{ height: '44.03px' }} />
                        <Divider light />
                        <div className="driver-details">
                            <div className="driver-photo">
                                <img src={Image} alt="" />
                            </div>
                            <div className="driver-info">
                                <div>
                                    <span>Drivers Name</span>
                                    <span>John Michael</span>
                                </div>
                                <div>
                                    <span>Drivers Contact</span>
                                    <span>090888766343</span>
                                </div>
                                <div>
                                    <span>Drivers Plate Number</span>
                                    <span>MH7642bW98</span>
                                </div>
                                <div>
                                    <span>Route</span>
                                    <span>Oshodi</span>
                                </div>
                            </div>
                            <span className="status">In progress</span>
                        </div>
                        <Divider light />
                        <div className="delivery-details">
                            <div className="distance">
                                <span>Distance</span>
                                <span>
                                    65 <span className="km">km</span>
                                </span>
                            </div>
                            <div className="time">
                                <div className="arrows">
                                    <NorthEastIcon
                                        sx={{
                                            backgroundColor: '#B3E6CC',
                                            width: '30px',
                                            height: '30px',
                                            borderRadius: '100%',
                                            padding: '7px'
                                        }}
                                    />
                                    <SouthEastIcon
                                        sx={{
                                            backgroundColor: '#B7C5FF',
                                            width: '30px',
                                            height: '30px',
                                            borderRadius: '100%',
                                            padding: '7px'
                                        }}
                                    />
                                </div>
                                <div className="depature-details">
                                    <div>
                                        <span>Departure Date/Time</span>
                                        <span>Mon, 9 Oct, 2023, 10:32 AM</span>
                                    </div>
                                    <div>
                                        <span>Arrival Date/Time</span>
                                        <span>Mon, 9 Oct, 2023, 15:32 PM</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Tracking;
