import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Row, Col } from 'react-grid-system';
import SimpleCard from 'components/Card/SimpleCard';
import AccordionWrapper from 'assets/Wrappers/AccordionWrapper';
import TodoList from 'components/TodoList';
import { useAppContext } from 'context/appContext';
import { Button, Divider, TextField } from '@mui/material';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import LoadingButton from '@mui/lab/LoadingButton';
import LeadTableData from './LeadTableData';
import MoonLoader from 'react-spinners/MoonLoader';

const LeadAccordion = () => {
    const { getSalesLead, leadTarget, todolist, leadGProgress, noLeadGToday, leadsfollowups, isLoading, resetLeads, filterLeads } =
        useAppContext();
    const [isOpen, setIsOpen] = React.useState(false);
    const [input, setInput] = React.useState('');
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());

    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleEvent = (e, picker) => {
        setStartDate(picker.startDate._d.toISOString());
        setEndDate(picker.endDate._d.toISOString());
    };
    const filterOptions = { startDate, endDate };

    const handleLeadsFilter = async () => {
        filterLeads(filterOptions);
    };

    const handLeadsReset = () => {
        resetLeads();
    };

    React.useEffect(() => {
        getSalesLead();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filteredLeads = leadsfollowups.filter((item) => {
        return item.partnerName.toLowerCase().includes(input.toLowerCase());
    });

    const leadsCount = filteredLeads.length;
    const isPlural = leadsCount > 1 ? 'results' : 'result';

    return (
        <AccordionWrapper>
            <Accordion className="custom-accordion">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expand-icon" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <h3 className="title">Generate Leads</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <Row>
                        <Col xl={7} lg={7} md={12} sm={12} xs={12}>
                            <div className="custom-table">
                                <h4 className="table__heading">Leads: Due for follow up</h4>
                                <Divider />
                                <div className="table__content">
                                    <Row>
                                        <Col xl={7} lg={7} md={12} sm={12} xs={12}>
                                            <TextField
                                                id="outlined-basic"
                                                label="Search lead name"
                                                variant="outlined"
                                                className="search-lead"
                                                onChange={(e) => setInput(e.target.value)}
                                            />
                                        </Col>

                                        <Col xl={5} lg={5} md={12} sm={12} xs={12}>
                                            <DateRangePicker
                                                initialSettings={{ startDate, endDate }}
                                                onEvent={handleEvent}
                                                onApply={handleLeadsFilter}
                                                onCancel={handLeadsReset}
                                            >
                                                <LoadingButton
                                                    variant="contained"
                                                    disableElevation
                                                    size="medium"
                                                    className="custom-date-picker"
                                                    loadingIndicator="Loading…"
                                                    loading={isLoading}
                                                >
                                                    Filter By Date
                                                </LoadingButton>
                                            </DateRangePicker>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="lead-table">
                                    {input && (
                                        <h4>{leadsCount > 0 ? `Displaying ${filteredLeads.length} ${isPlural}` : 'No Results found'}</h4>
                                    )}
                                    {isLoading ? (
                                        <div className="loader-spinner">
                                            <MoonLoader color="#ff740f" />
                                        </div>
                                    ) : (
                                        <LeadTableData
                                            rows={filteredLeads}
                                            th1="#"
                                            th2="Lead Name"
                                            th3="Follow up due date"
                                            th4="Pending follow ups"
                                            th5="Actions"
                                        />
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col xl={3} lg={5} md={12} sm={12} xs={12}>
                            <SimpleCard count={leadTarget} title="Lead generation target" />
                            <SimpleCard count={leadGProgress} title="Lead generation progress" isPercent="%" />
                            <SimpleCard count={`${noLeadGToday} +`} title="Number of leads to generate today" />
                        </Col>
                        <Col xl={2} lg={2} md={12} sm={12} xs={12}>
                            <Button className="btn-todo" variant="contained" size="medium" onClick={handleDropdownToggle}>
                                Todo list &nbsp; &nbsp;
                                <span className="btn-count">{todolist.length}</span>
                            </Button>
                            {isOpen && <TodoList todos={todolist} />}
                        </Col>
                    </Row>
                </AccordionDetails>
            </Accordion>
        </AccordionWrapper>
    );
};

export default LeadAccordion;
