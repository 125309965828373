import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import RiseLoader from 'react-spinners/RiseLoader';
import styled from 'styled-components';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { FaTimesCircle } from 'react-icons/fa';

dayjs.extend(relativeTime);

const Wrapper = styled.div`
    font-family: 'Roboto', 'sans-serif';
    h6 {
        text-align: center;
        font-weight: 500;
        margin-bottom: 0.625rem;
        color: #ff740f;
    }

    .spinner-location {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.25rem;
    }

    .icon-state {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.9375rem;

        .check {
            color: green;
            font-size: 2.5rem;
        }

        .error {
            color: red;
            font-size: 2.5rem;
        }
    }

    .custom-message {
        font-weight: 700;
        text-align: center;
        font-size: 0.875rem;
    }
`;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '3px solid #ff740f',
    boxShadow: 24,
    p: 4,
    color: '#333',
    maxWidth: '100%'
};

const ComparisonPreviewModal = ({ handleOpen, handleClose, loading, message, errorStatus, errorList }) => {
    const customMessage = errorStatus ? (
        <>
            <h6>Compare deposits and collections</h6>
            <div className="icon-state">
                <FaTimesCircle className="error" />
            </div>
            <div className="errorObject">
                <p>{message}</p>
                {errorList.map((person, index) => (
                    <p key={index} className="custom-message">
                        {`${index + 1}. ${person.channelPartnerId}`}
                    </p>
                ))}
            </div>
        </>
    ) : (
        <>
            <h6>Compare deposits and collections</h6>
            <div className="icon-state">
                <IoCheckmarkCircle className="check" />
            </div>
            <p className="custom-message">{message}</p>
        </>
    );
    return (
        <Modal open={handleOpen} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Wrapper>
                    {loading ? (
                        <div className="spinner-location">
                            <RiseLoader color="#ff740f" />
                        </div>
                    ) : (
                        <div className="content" id="modal-modal-description">
                            {customMessage}
                        </div>
                    )}
                </Wrapper>
            </Box>
        </Modal>
    );
};
export default ComparisonPreviewModal;
