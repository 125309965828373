// assets
import { IconKey, IconSnowflake, IconTruckDelivery } from '@tabler/icons';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import CarCrashIcon from '@mui/icons-material/CarRental';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import InventoryIcon from '@mui/icons-material/Inventory';
import Scale from '@mui/icons-material/Scale';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { AiFillGold } from 'react-icons/ai';

// constant
const icons = {
    IconKey,
    IconTruckDelivery,
    IconSnowflake,
    CarRepairIcon,
    CarCrashIcon,
    Scale,
    DisplaySettingsIcon,
    DashboardIcon,
    InventoryIcon,
    InsertChartIcon
};

// ==============================|| EXTRA Distributor MENU ITEMS ||============================== //
const userRole = localStorage.getItem('userRole');

const distributor = {
    id: 'distributor',
    title: 'Distributor',
    type: userRole,
    children: [
        {
            id: 'home',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: icons.DashboardIcon,
            breadcrumbs: false
        },
        {
            id: 'supplierbatch',
            title: 'Order Batches',
            type: 'item',
            url: '/suppliers/batch',
            icon: AiFillGold,
            breadcrumbs: false
        },
        // {
        //     id: 'retailerDashboard',
        //     title: 'Retailer Dashboard',
        //     type: 'item',
        //     url: '/dashboardRetailerIndex',
        //     icon: icons.InsertChartIcon,
        //     breadcrumbs: false
        // },
        {
            id: 'inventory',
            title: 'Inventory',
            type: 'item',
            url: '/distributorInventory',
            icon: icons.InventoryIcon,
            breadcrumbs: false
        },
        {
            id: 'soldparts',
            title: 'Sold Parts',
            type: 'item',
            url: '/soldPartsDistributor',
            icon: icons.Scale,
            breadcrumbs: false
        },
        {
            id: 'orders',
            title: 'Orders',
            type: 'item',
            url: '/distributorinventoryorders',
            icon: icons.Scale,
            breadcrumbs: false
        },
        {
            id: 'retailer',
            title: 'Retail Partners',
            type: 'item',
            url: '/retailpartners',
            icon: icons.CarCrashIcon,
            breadcrumbs: false
        }
    ]
};

export default distributor;
