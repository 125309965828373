import { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import ApiUrl from '../../../network'; // Adjust the import path based on your project structure

// Function to send the audio message
export const sendAudioFile = (setAudioModal, socket, audioFile, selectedUser, replyTo, setMessages) => {
    setAudioModal(false);
    const newMessage = {
        phone: selectedUser.phone,
        content: {
            body: '',
            type: 'audio',
            url: audioFile
        },
        user_id: localStorage.getItem('userId'),
        replyTo: replyTo.whatsapp_id
    };

    socket.emit('mira_chat', newMessage);

    const transformedMessage = {
        _id: Date.now(),
        phone: selectedUser.phone,
        content: '',
        image: audioFile,
        type: 'audio',
        isSent: true,
        agent: 'staff',
        user: `${localStorage.getItem('userFirstName')} ${localStorage.getItem('userLastName')}`,
        createdAt: new Date().toISOString()
    };

    setMessages((prevMessages) => [...prevMessages, transformedMessage]);
};

// Function to start recording
export const startRecording = (setAudioModal, setAudioChunks, setIsRecording, setRecordingTime, mediaRecorderRef, recordingTimerRef) => {
    setAudioModal(true);
    setAudioChunks([]);
    setIsRecording(true);

    navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorderRef.current = mediaRecorder;

            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    setAudioChunks((prevChunks) => [...prevChunks, event.data]);
                }
            };

            mediaRecorder.start();
            recordingTimerRef.current = setInterval(() => {
                setRecordingTime((prev) => prev + 1);
            }, 1000);
        })
        .catch((error) => {
            toast.error('Failed to access the microphone.');
            console.error('Microphone access error:', error);
        });
};

// Function to upload the recorded audio
export const uploadAudio = async (audioBlob) => {
    if (!audioBlob) return;

    const formData = new FormData();
    formData.append('file', audioBlob, 'audio_recording.webm');

    try {
        const token = localStorage.getItem('userToken');
        const rootURL = ApiUrl.BaseUrl;

        const response = await fetch(`${rootURL}/channelpartner/uploadpartsimage`, {
            method: 'POST',
            headers: {
                'auth-token': token
            },
            body: formData
        });

        const result = await response.json();

        if (result.status === true) {
            // eslint-disable-next-line consistent-return
            return result.data; // Return the uploaded data
        }
        toast.error('Failed to upload audio.');
        // eslint-disable-next-line consistent-return
        return null; // Return null if the upload fails
    } catch (error) {
        toast.error(error.message);
        // eslint-disable-next-line consistent-return
        return null; // Return null in case of an error
    }
};

// Function to stop recording
export const stopRecording = (mediaRecorderRef, setIsRecording, setAudioChunks, setRecordingTime, setAudioPreviewUrl) => {
    return new Promise((resolve) => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);

            const tracks = mediaRecorderRef.current.stream.getTracks();
            tracks.forEach((track) => track.stop());

            mediaRecorderRef.current.onstop = async () => {
                setAudioChunks((prevChunks) => {
                    if (prevChunks.length > 0) {
                        const audioBlob = new Blob(prevChunks, { type: 'audio/webm' });

                        // Generate a URL for local preview
                        const previewUrl = URL.createObjectURL(audioBlob);
                        setAudioPreviewUrl(previewUrl); // Set the preview URL

                        setRecordingTime(0);
                        resolve(audioBlob); // Resolve with the audioBlob
                    } else {
                        resolve(null); // Resolve with null if no chunks
                    }
                    return prevChunks;
                });
            };
        } else {
            resolve(null); // Resolve with null if no mediaRecorder
        }
    });
};
