import Index from '../../components/RetailerShop/index';

const RetailerShop = () => {
    return (
        <>
            <Index />
        </>
    );
};
export default RetailerShop;
