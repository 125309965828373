import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Button, Table, Input, Skeleton, DatePicker, Select, Progress, Popover, Modal } from 'antd';
import SearchIcon from '@mui/icons-material/Search';
import TimelineIcon from '@mui/icons-material/Timeline';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { decrypt } from '../../utils/encrypt';
import { isArray } from 'lodash';
import { toast } from 'react-toastify';
import 'react-circular-progressbar/dist/styles.css';
import { DeleteOutlined, EditOutlined, EyeInvisibleOutlined, MoreOutlined } from '@ant-design/icons';
import ApiUrl from '../../network';

export const Index = () => {
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const userRole = localStorage.getItem('userRole');
    const country = localStorage.getItem('country');
    const [pods, setPods] = useState([]);
    const [kpi, setKPI] = useState([]);
    const [podKpi, setPodKpi] = useState([]);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState([]);
    const [salesPlan, setSalesPlan] = useState([]);
    const { RangePicker } = DatePicker;
    const [podKpiView, setPodKpiView] = useState(true);
    const [individualKpiView, setIndividualKpiView] = useState(false);
    const [moreDataModal, setMoreDataModal] = useState(false);
    const [tableData, setTableData] = useState({});
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    const [open, setOpen] = useState(false);

    const fetchPodsData = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/salesdrivepod/salesdrivepod`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setPods(result);
                        const allSalespersons = [];
                        result.forEach((pod) => {
                            pod.salesperson.forEach((salesperson) => {
                                allSalespersons.push(salesperson);
                            });
                        });
                        setSalesPlan(allSalespersons);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };
    const fetchIndividualKPiData = async (data) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const urlParams = new URLSearchParams(window.location.search);
            let url = '';

            if (userRole === 'Sales') {
                url = `${ApiUrl.BaseUrl}/kpiplan/userkpiplan`;
            } else {
                url = `${ApiUrl.BaseUrl}/kpiplan/kpiplanninglist`;
            }

            if (data) {
                if (data.startDate) urlParams.append('startDate', data.startDate);
                if (data.endDate) urlParams.append('endDate', data.endDate);
                if (data.pod) urlParams.append('pod', data.pod);
                if (data.userId) urlParams.append('userId', data.userId);
            }
            url = `${url}?${urlParams.toString()}`;

            await fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setLoading(false);
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setKPI(result);
                    } else {
                        setLoading(false);
                        const error = decrypt(data.data, keyResult, ivResult);
                        toast.error(error.message);
                    }
                });
        } catch (e) {
            setLoading(false);
            toast.error(e.message);
        }
    };
    const fetchPodKPiData = async (data) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const urlParams = new URLSearchParams(window.location.search);
            let url = '';

            if (userRole === 'Sales') {
                url = `${ApiUrl.BaseUrl}/kpiplan/poduserkpiplan`;
            } else {
                url = `${ApiUrl.BaseUrl}/kpiplan/podkpi`;
            }

            if (data) {
                if (data.startDate) urlParams.append('startDate', data.startDate);
                if (data.endDate) urlParams.append('endDate', data.endDate);
                if (data.pod) urlParams.append('pod', data.pod);
                if (data.country) urlParams.append('country', data.country);
            }
            url = `${url}?${urlParams.toString()}`;

            await fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setLoading(false);
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setPodKpi(result);
                    } else {
                        setLoading(false);
                        const error = decrypt(data.data, keyResult, ivResult);
                        toast.error(error.message);
                    }
                });
        } catch (e) {
            setLoading(false);
            toast.error(e.message);
        }
    };
    const fetchCountries = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(`${ApiUrl.BaseUrl}/country/activecountry`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error('Something went wrong');
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    const KpiData = kpi?.map((item) => {
        return {
            key: item?._id,
            name: `${item?.userId?.firstName} ${item?.userId?.lastName}`,
            date: `${new Date(item?.startDate).toLocaleDateString('en-US', options)} - ${new Date(item?.endDate).toLocaleDateString(
                'en-US',
                options
            )}`,
            gmvTarget: item?.gmvtarget,
            gmvActual: item?.gmvactual,
            gmvPercent: item?.gmvpercent,
            conversionTarget: item?.conversiontarget,
            conversionActual: item?.conversionactual,
            conversionPercent: item?.conversionpercent,
            retentionTarget: item?.retentiontarget,
            retentionActual: item?.retentionactual,
            retentionPercent: item?.retentionpercent,
            revenuetarget: item?.revenuetarget,
            revenueactual: item?.revenueValue,
            revenuepercent: item?.revenuePercentage,
            pod: item?.pod?.name,
            country: item?.country,
            currency: item?.currency?.name,
            role: item?.roleId?.name,
            createdBy: `${item?.createdBy?.firstName} - ${item?.createdBy?.lastName}`
        };
    });
    const podsOptions = pods?.map((pod) => {
        return {
            label: pod.name,
            value: pod._id
        };
    });
    const salesPersonOptions = salesPlan?.map((item) => {
        return {
            label: `${item?.firstName} - ${item?.lastName}`,
            value: item?.userId
        };
    });
    const countryOption = countries?.map((result) => ({
        value: result.name,
        label: result.name
    }));
    const filterOptions = [
        {
            label: 'All',
            value: 'all'
        },
        {
            label: 'Today',
            value: 'today'
        },
        {
            label: 'This Week',
            value: 'thisWeek'
        },
        {
            label: 'This Month',
            value: 'thisMonth'
        },
        {
            label: 'This Year',
            value: 'thisYear'
        }
    ];
    useEffect(() => {
        fetchPodsData();
        fetchPodKPiData();
        fetchIndividualKPiData();
        fetchCountries();
    }, []);

    const editHandler = (record, key) => {
        if (key === 'edit') {
            window.location.href = `/kpi/edit-kpi/${record.key}`;
        } else if (key === 'view') {
            window.location.href = `/kpi/view-kpi/${record.key}`;
        } else {
            setMoreDataModal(true);
        }
    };
    const deleteHandler = (record) => {
        const data = {
            id: record.key
        };

        const userInfo = localStorage.getItem('userToken');
        const config = {
            method: 'DELETE',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        fetch(`${ApiUrl.BaseUrl}/kpiplan/removeKpiPlanning?id=${data.id}`, config)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    toast.success('Sales Plan Deleted Successfully');
                    fetchIndividualKPiData();
                } else {
                    const error = decrypt(data.data, keyResult, ivResult);
                    toast.error(error.message);
                }
            })
            .catch((error) => {
                toast.error(error.message);
            });
    };

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date'
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Pod',
            dataIndex: 'pod',
            key: 'pod'
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency'
        },
        {
            title: 'GMV (%)',
            dataIndex: 'gmvPercent',
            key: 'gmvPercent'
        },
        {
            title: 'GMV Target',
            dataIndex: 'gmvTarget',
            key: 'gmv'
        },
        {
            title: 'GMV Actual',
            dataIndex: 'gmvActual',
            key: 'gmvActual'
        }
        // {
        //     title: 'Conversion (%)',
        //     dataIndex: 'conversionPercent',
        //     key: 'conversionPercent'
        // },
        // {
        //     title: 'Conversion Target',
        //     dataIndex: 'conversionTarget',
        //     key: 'conversionTarget'
        // },
        // {
        //     title: 'Conversion Actual',
        //     dataIndex: 'conversionActual',
        //     key: 'conversionActual'
        // },
        // {
        //     title: 'Retention Target',
        //     dataIndex: 'retentionTarget',
        //     key: 'retentionTarget'
        // },
        // {
        //     title: 'Revenue Target',
        //     dataIndex: 'revenuetarget',
        //     key: 'revenuetarget'
        // },
        // {
        //     title: 'Revenue Actual',
        //     dataIndex: 'revenueactual',
        //     key: 'revenueactual'
        // },
        // {
        //     title: 'Revenue (%)',
        //     dataIndex: 'revenuepercent',
        //     key: 'revenuepercent'
        // },
        // {
        //     title: 'By',
        //     dataIndex: 'createdBy',
        //     key: 'createdBy'
        // }
    ];

    if (userRole !== 'Sales') {
        columns.push({
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => (
                <div className="d-flex">
                    <Button
                        className="mr-2"
                        icon={<MoreOutlined />}
                        onClick={() => {
                            setTableData(record);
                            editHandler(record, 'more');
                        }}
                    />
                    <Button
                        className="mr-2"
                        icon={<EyeInvisibleOutlined />}
                        onClick={() => {
                            editHandler(record, 'view');
                        }}
                    />
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => {
                            editHandler(record, 'edit');
                        }}
                    />
                    <Button
                        className="ml-2 mr-2"
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            deleteHandler(record);
                        }}
                    />
                </div>
            )
        });
    }

    const dateHanlder = (date, dateString) => {
        setDate(date);
        const data = {
            startDate: dateString[0],
            endDate: dateString[1]
        };
        fetchIndividualKPiData(data);
    };
    const filterPodData = (podId) => {
        // Create the data object
        const data = {
            pod: podId
        };

        // Call fetchIndividualKPiData with the data object
        fetchIndividualKPiData(data);
    };
    const filterSalesPersonData = (userId) => {
        // Create the data object
        const data = {
            userId
        };

        // Call fetchIndividualKPiData with the data object
        fetchIndividualKPiData(data);
    };

    const filterPodKPIHandler = (value) => {
        const data = {
            pod: value
        };
        fetchPodKPiData(data);
    };
    const dateFilterHanlder = (value, dateString) => {
        setDate(value);
        const data = {
            startDate: dateString[0],
            endDate: dateString[1]
        };
        fetchPodKPiData(data);
    };

    return (
        <div>
            <div className="podContainer p-2 mb-4 bg-white">
                <p style={{ fontSize: '20px' }}>
                    Key Performance Indicators <TimelineIcon className="ml-2" />
                </p>
                <div className="d-flex align-items-center justify-content-center">
                    <Button
                        onClick={() => {
                            setPodKpiView(true);
                            setIndividualKpiView(false);
                        }}
                        className={`mt-2 mb-2 mr-2 d-flex align-items-center justify-content-center ${
                            podKpiView ? 'pod-kpi-active' : 'pod-kpi-inactive'
                        }`}
                        title="Apply Filters"
                    >
                        <Typography sx={{ margin: '0 14px ' }}>POD KPI</Typography>
                    </Button>
                    <Button
                        onClick={() => {
                            setPodKpiView(false);
                            setIndividualKpiView(true);
                        }}
                        className={`mt-2 mb-2 d-flex align-items-center justify-content-center ml-2 ${
                            individualKpiView ? 'pod-kpi-active' : 'pod-kpi-inactive'
                        }`}
                        title="Apply Filters"
                    >
                        <Typography sx={{ margin: '0 14px ' }}>Individual KPI</Typography>
                    </Button>
                </div>
            </div>
            {individualKpiView && (
                <div>
                    <div className="podContainer bg-white p-2 mb-2">
                        <RangePicker
                            size="large"
                            format="YYYY-MM-DD"
                            className="datePicker inputViewKpi"
                            style={{ width: '300px' }}
                            value={date}
                            onChange={dateHanlder}
                        />
                        {userRole === 'Admin' && (
                            <div className="podContainer flex-grow-1">
                                <div className="flex-grow-1">
                                    <Select
                                        showSearch
                                        allowClear
                                        size="large"
                                        placeholder="Filter By Pod"
                                        options={podsOptions}
                                        onChange={(e) => {
                                            filterPodData(e);
                                        }}
                                        filterOption={(inputValue, option) => {
                                            if (typeof option.label === 'string') {
                                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }

                                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }}
                                        className="ml-2 p-2 inputViewKpi"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className="flex-grow-1">
                                    <Select
                                        showSearch
                                        allowClear
                                        size="large"
                                        placeholder="Filter By Person"
                                        options={salesPersonOptions}
                                        onChange={(e) => {
                                            filterSalesPersonData(e);
                                        }}
                                        filterOption={(inputValue, option) => {
                                            if (typeof option.label === 'string') {
                                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }

                                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }}
                                        className="ml-2 p-2 inputViewKpi"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="salesPlanTable">
                        {loading ? (
                            <div>
                                <Skeleton style={{ padding: '10px 0' }} active />
                            </div>
                        ) : (
                            <Table columns={columns} scroll={{ x: 'calc(700px + 50%)', y: 480 }} dataSource={KpiData} />
                        )}
                    </div>
                </div>
            )}
            {podKpiView && (
                <div>
                    <div>
                        <div className="podContainer p-2 mb-4">
                            <RangePicker
                                size="large"
                                format="YYYY-MM-DD"
                                className="datePicker inputViewKpi mr-2"
                                value={date}
                                onChange={dateFilterHanlder}
                                style={{ width: '300px' }}
                            />
                            {userRole === 'Admin' && (
                                <div className="podContainer flex-grow-1">
                                    <div className="flex-grow-1">
                                        <Select
                                            size="large"
                                            options={podsOptions}
                                            placeholder="Select POD"
                                            onChange={(value) => filterPodKPIHandler(value)}
                                            className="inputViewKpi mr-2"
                                            filterOption={(inputValue, option) => {
                                                if (typeof option.label === 'string') {
                                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                }

                                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }}
                                            showSearch
                                            allowClear
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                    <div className="flex-grow-1">
                                        <Select
                                            showSearch
                                            allowClear
                                            size="large"
                                            placeholder="Filter By Country"
                                            onChange={(value) => fetchPodKPiData({ country: value })}
                                            filterOption={(inputValue, option) => {
                                                if (typeof option.label === 'string') {
                                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                }

                                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }}
                                            options={countryOption}
                                            className="ml-2 p-2 inputViewKpi flex-grow-1"
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {loading ? (
                        <Skeleton style={{ padding: '10px 0' }} active />
                    ) : (
                        <div className="podContainer">
                            <div className="bg-white p-4 w-25" style={{ borderRadius: '10px', textAlign: 'center' }}>
                                <div className="mb-4">
                                    <Progress type="circle" percent={podKpi.gmvPercent} width={200} />
                                </div>
                                <p
                                    className="mt-2 mb-4 d-flex align-items-center justify-content-center"
                                    style={{ fontSize: '14px', textAlign: 'center' }}
                                >
                                    <span style={{ backgroundColor: '#4DC488', color: '#fff', borderRadius: '50px', marginRight: '5px' }}>
                                        <RestartAltIcon style={{ color: '#fff' }} />
                                    </span>
                                    {podKpi?.pod?.name} GMV
                                </p>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <p style={{ fontSize: '12px' }}>Achieved</p>
                                        <p style={{ fontSize: '14px' }}>
                                            {podKpi?.currency?.name} {podKpi?.gmvActual}
                                        </p>
                                    </div>
                                    <span className="ml-2 mr-2">|</span>
                                    <div>
                                        <p style={{ fontSize: '12px' }}>Target</p>
                                        <p style={{ fontSize: '14px' }}>
                                            {podKpi?.currency?.name} {podKpi?.gmvTarget}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white p-4 w-25 m-lg-2" style={{ borderRadius: '10px', textAlign: 'center' }}>
                                <div className="mb-4">
                                    <Progress type="circle" percent={podKpi.conversionPercent} width={200} status="GMV reached 75%" />
                                </div>
                                <p
                                    className="mt-2 mb-4 d-flex align-items-center justify-content-center"
                                    style={{ fontSize: '14px', textAlign: 'center' }}
                                >
                                    <span style={{ backgroundColor: '#4DC488', color: '#fff', borderRadius: '50px', marginRight: '5px' }}>
                                        <RestartAltIcon style={{ color: '#fff' }} />
                                    </span>
                                    {podKpi?.pod?.name} Conversion
                                </p>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <p style={{ fontSize: '12px' }}>Achieved</p>
                                        <p style={{ fontSize: '14px' }}>{podKpi?.conversionActual}</p>
                                    </div>
                                    <span className="ml-2 mr-2">|</span>
                                    <div>
                                        <p style={{ fontSize: '12px' }}>Target</p>
                                        <p style={{ fontSize: '14px' }}>{podKpi?.conversionTarget}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white p-4 w-25 m-lg-2" style={{ borderRadius: '10px', textAlign: 'center' }}>
                                <div className="mb-4">
                                    <Progress type="circle" percent={podKpi.retentionPercent} width={200} status="GMV reached 75%" />
                                </div>
                                <p
                                    className="mt-2 mb-4 d-flex align-items-center justify-content-center"
                                    style={{ fontSize: '14px', textAlign: 'center' }}
                                >
                                    <span style={{ backgroundColor: '#4DC488', color: '#fff', borderRadius: '50px', marginRight: '5px' }}>
                                        <RestartAltIcon style={{ color: '#fff' }} />
                                    </span>
                                    {podKpi?.pod?.name} Retention
                                </p>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <p style={{ fontSize: '12px' }}>Achieved</p>
                                        <p style={{ fontSize: '14px' }}>{podKpi?.retentionActual}</p>
                                    </div>
                                    <span className="ml-2 mr-2">|</span>
                                    <div>
                                        <p style={{ fontSize: '12px' }}>Target</p>
                                        <p style={{ fontSize: '14px' }}>{podKpi?.retentionTarget}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white p-4 w-25 m-lg-2" style={{ borderRadius: '10px', textAlign: 'center' }}>
                                <div className="mb-4">
                                    <Progress type="circle" percent={podKpi.revenuePercentage} width={200} status="Revenue reached 75%" />
                                </div>
                                <p
                                    className="mt-2 mb-4 d-flex align-items-center justify-content-center"
                                    style={{ fontSize: '14px', textAlign: 'center' }}
                                >
                                    <span style={{ backgroundColor: '#4DC488', color: '#fff', borderRadius: '50px', marginRight: '5px' }}>
                                        <RestartAltIcon style={{ color: '#fff' }} />
                                    </span>
                                    {podKpi?.pod?.name} Revenue
                                </p>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <p style={{ fontSize: '12px' }}>Achieved</p>
                                        <p style={{ fontSize: '14px' }}>{podKpi?.revenueValue}</p>
                                    </div>
                                    <span className="ml-2 mr-2">|</span>
                                    <div>
                                        <p style={{ fontSize: '12px' }}>Target</p>
                                        <p style={{ fontSize: '14px' }}>{podKpi?.revenuetarget}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            <Modal
                title={`${tableData.name} - ${tableData.pod} Pod`}
                open={moreDataModal}
                onCancel={() => {
                    setMoreDataModal(false);
                }}
                footer={null}
            >
                <div>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '10px',
                            alignItems: 'center'
                        }}
                    >
                        <div>
                            <p>
                                <strong>Date:</strong> {tableData.date}
                            </p>
                            <p>
                                <strong>Country:</strong> {tableData.country}
                            </p>
                            <p>
                                <strong>Name:</strong> {tableData.name}
                            </p>
                            <p>
                                <strong>Pod:</strong> {tableData.pod}
                            </p>
                            <p>
                                <strong>Currency:</strong> {tableData.currency}
                            </p>
                            <p>
                                <strong>Gmv Percent:</strong> {tableData.gmvPercent}
                            </p>
                            <p>
                                <strong>GMV Target:</strong> {tableData.gmvTarget}
                            </p>
                            <p>
                                <strong>GMV Actual:</strong> {tableData.gmvActual}
                            </p>
                            <p>
                                <strong>GMV Percent:</strong> {tableData.gmvPercent}
                            </p>
                        </div>
                        <div>
                            <p>
                                <strong>Conversion Target:</strong> {tableData.conversionTarget}
                            </p>
                            <p>
                                <strong>Conversion Actual:</strong> {tableData.conversionActual}
                            </p>
                            <p>
                                <strong>Conversion Percent:</strong> {tableData.conversionPercent}
                            </p>
                            <p>
                                <strong>Retention Target:</strong> {tableData.retentionTarget}
                            </p>
                            <p>
                                <strong>Revenue Target:</strong> {tableData.revenuetarget}
                            </p>
                            <p>
                                <strong>Revenue Actual:</strong> {tableData.revenueactual}
                            </p>
                            <p>
                                <strong>Revenue Percent:</strong> {tableData.revenuepercent}
                            </p>
                            <p>
                                <strong>By:</strong> {tableData.createdBy}
                            </p>
                        </div>
                    </Box>
                </div>
            </Modal>
        </div>
    );
};
export default Index;
