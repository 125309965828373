import { SiMicrosoftexcel } from 'react-icons/si';
import Wrapper from '../../assets/Wrappers/StatWrapper';
import { useAppContext } from 'context/appContext';
import './dataCard.css';
import { IconDownload } from '@tabler/icons';
import CountChart from 'components/charts/BarChart';
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import SubMiniCard from '../statsCards/SubMiniCard';
import SubMaxCard from '../statsCards/SubMaxCard';
import { useEffect, useState } from 'react';

function DataCard({ count, title, icon, color, bcg, data, gender, percentage, progressColor, trailColor, month }) {
    const lastIndex = month?.length - 1;
    const [activeIndex, setActiveIndex] = useState(lastIndex);
    const [showTotal, setShowTotal] = useState(false);
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        setActiveIndex(lastIndex);
    }, [lastIndex]);

    const { handleExcelDataExport } = useAppContext();
    const downloadExcel = () => {
        if (data?.length === 0) return;
        handleExcelDataExport(data, title);
    };

    return (
        <>
            {title === 'Total Issues' ? (
                <SubMiniCard count={count} data={data} title={title} month={month} />
            ) : title === 'Partners not Ready to partner' ? (
                <SubMiniCard count={count} data={data} title={title} month={month} />
            ) : title === 'Total Parts Delivered' ? (
                <SubMiniCard count={count} data={data} title={title} month={month} />
            ) : title === 'Total Parts Collected' ? (
                <SubMiniCard count={count} data={data} title={title} month={month} />
            ) : title === 'Total Retail Discount No.' ? (
                <SubMiniCard count={count} data={data} title={title} month={month} />
            ) : title === 'Total Orders from Web.' ? (
                <SubMaxCard count={count} month={month} data={data} title={title} />
            ) : title === 'Marketplace Take Rate' ? (
                <SubMaxCard count={count} month={month} data={data} title={title} />
            ) : title === 'Total Orders from Mobile.' ? (
                <SubMaxCard count={count} month={month} data={data} title={title} />
            ) : title === 'Customer Care Order No.' ? (
                <SubMiniCard count={count} title={title} data={data} month={month} />
            ) : title === 'Unique Retailer Purchases' ? (
                <SubMiniCard count={count} title={title} data={data} month={month} />
            ) : title === 'Total Parts Sold' ? (
                <SubMiniCard count={count} title={title} data={data} month={month} />
            ) : (
                <div className={title === 'Total Issues' ? 'count-container small-cards' : 'count-container'}>
                    <div className="count-heading">
                        <span className="total">{title}</span>
                        {data?.length || count !== 0 ? (
                            <a
                                role="button"
                                tabIndex={-1}
                                onClick={downloadExcel}
                                onKeyDown={downloadExcel}
                                title={data?.length > 0 ? 'Click to download excel' : ''}
                            >
                                <span>Download</span>
                                <IconDownload size="1rem" />
                            </a>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="today">
                        <span>Today</span>
                        <span>{!gender ? count : `${percentage}% (${gender} retailers)`}</span>
                    </div>

                    <div className="graph">
                        {!gender && showTotal ? (
                            <div className="graph-text">
                                <span>{title}</span>
                                <span>{amount}</span>
                            </div>
                        ) : (
                            ''
                        )}

                        <div className="count-graph">
                            {gender ? (
                                <>
                                    <CountChart
                                        color={color}
                                        month={month}
                                        trailColor={trailColor}
                                        title={title}
                                        activeIndex={activeIndex}
                                        setActiveIndex={setActiveIndex}
                                        setShowTotal={setShowTotal}
                                        setAmount={setAmount}
                                    />
                                    <div className="circular-progress-bar">
                                        <CircularProgressbar
                                            value={percentage}
                                            text={`${percentage}%`}
                                            styles={buildStyles({
                                                strokeLinecap: 'butt',
                                                pathColor: progressColor,
                                                trailColor,
                                                textColor: '#3B5460',
                                                textSize: '18px'
                                            })}
                                            strokeWidth={10}
                                        />
                                    </div>
                                </>
                            ) : (
                                <CountChart
                                    color={color}
                                    month={month}
                                    trailColor={trailColor}
                                    title={title}
                                    activeIndex={activeIndex}
                                    setActiveIndex={setActiveIndex}
                                    setShowTotal={setShowTotal}
                                    setAmount={setAmount}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default DataCard;
