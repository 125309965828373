/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import useScriptRef from 'hooks/useScriptRef';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import EditIcon from '@mui/icons-material/Edit';
// import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import nologo from 'assets/images/nologo.png';
// import IconButton from '@mui/material/IconButton';
// import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Form from 'react-bootstrap/Form';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const DispatchPreview = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [orderDate, setorderDate] = useState(new Date());
    const [deliveryDate, setDeliveryDate] = useState(new Date());
    const [payDueDate, setPayDueDate] = useState(new Date());
    const [orderTypId, setOrderTypeRecord] = useState('');
    const [paymentStatusId, setpaymentStatusRecord] = useState('');
    const [orderStatusId, setorderStatusRecord] = useState('');
    const [channelId, setChannelRecord] = useState('');
    const [channelPartnerId, setChannelPartnerRecord] = useState('');
    const [dropOffLocation, setdropOffLocation] = useState('');
    const [expCashCollected, setExpectedCash] = useState('');
    const [actCashCollected, setActualCash] = useState('');
    const [cashOutStanding, setCashOutstanding] = useState('');
    const [perCashOutStanding, setperCashOut] = useState('');
    const [perMargin, setMargin] = useState('');
    const [daysPastDue, setdaysPastDue] = useState('');
    const [partnerContact, setPartnerContact] = useState('');
    const [partnerPercentSold, setpartnerPercentSold] = useState('0');
    const [creditrating, setCreditRating] = useState('');
    const [orderType, setOrderType] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState([]);
    const [orderStatus, setOrderStatus] = useState([]);
    const [channel, setChannel] = useState([]);
    const [channelpartner, setChannelPartner] = useState([]);
    const [carrier, setCarrier] = useState([]);
    const [part, setPart] = useState([]);
    const [pickdroptype, setPickDropType] = useState([]);
    const [carrierId, setCarrierId] = useState('');
    const [orderValue, setorderValue] = useState('');
    const [pickUpLocation, setPickupLocation] = useState('');
    const [carrierContact, setCarrierContact] = useState('');
    const [carrierNPS, setCarrierNPS] = useState('');
    const [carrierRating, setCarrierRating] = useState('');
    const [orderPickUpTime, setOrderPickupTime] = useState('');
    const [qty, setQty] = useState('');
    const [partid, setPartId] = useState('');
    const [skuId, setSkuId] = useState('');
    const [brand, setBrand] = useState('');
    const [wholeSalePrice, setwholeSalePrice] = useState('');
    const [garagePrice, setgaragePrice] = useState('');
    const [applicableVins, setapplicableVins] = useState('');
    const [pickUpTypeId, setPickupType] = useState('');
    const [dropOffTypeId, setDropoffType] = useState('');
    const [orderNotes, setOrderNotes] = useState('');
    // const [channelIds, setchannelId] = useState('');
    const [newChannelId, setNewChannelId] = useState('');
    const [newChannelPartnerId, setNewChannelPartnerId] = useState('');
    const [newOrdertypeId, setNewOrderTypeId] = useState('');
    const [newOrderStatusId, setNewOrderStatusId] = useState('');
    const [newPaymentStatusId, setNewPaymentStatusId] = useState('');
    const [newCarrierId, setnewCarrierId] = useState('');
    const [newPickupTypeId, setNewPickUpTypeId] = useState('');
    const [newDropoffTypeId, setNewDropoffTypeId] = useState('');
    const [cash, setCash] = useState('');
    const [salesTeamId, setsalesTeamId] = useState('');
    // const [partType, setpartType] = useState([]);
    // const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [collectiondatas, setCollectiondatas] = useState([]);
    const [open, setOpen] = useState(false);
    // const [open2, setOpen2] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [items, setItems] = useState([]);
    const [salesTeamInfo, setSalesTeam] = useState([]);
    // const [indexValue, setIndexValue] = useState('');
    // const [parts, setparts] = useState('');
    const [fulOrderId, setfulOrderId] = useState('');
    const [partsdata, setpartsdata] = useState([]);
    const [deliveryNoteData, setdeliveryNoteData] = useState([]);
    const [dispatchImageData, setdispatchImageData] = useState([]);
    const [packsortImageData, setpacksortImageData] = useState([]);
    const [driverImageNoteData, setdriverImageNoteData] = useState([]);
    const { id } = useParams();

    const handleCashChange = (event) => {
        setCash(event.target.value);
    };
    const retrieveAlLsalesTeam = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.SalesTeamActiveSalesPage, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setSalesTeam(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllPickDropType = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.AllActivePickDropType, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setPickDropType(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllPart = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.ActivePartUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setPart(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllCarrier = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.ActiveCarrierUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCarrier(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllChannelPartner = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.AllChannelPartnersOrders, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setChannelPartner(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllActiveChannel = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.AllActiveChannel, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setChannel(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllActiveOrderStatus = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.AllActiveOrderStatus, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrderStatus(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllActivePaymentStatus = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.AllActivePaymentStatus, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setPaymentStatus(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllOrderType = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.AllActiveOrderTypes, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrderType(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.ViewOrdersUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    console.log('result', result.data);
                    setorderDate(result?.data?.orderDate ?? '');
                    setChannelRecord(result?.data?.newChannelId?.name ?? '');
                    setNewChannelId(result?.data?.newChannelId?._id ?? '');
                    setChannelPartnerRecord(result?.data?.newChannelPartnerId?.partnerName ?? '');
                    setNewChannelPartnerId(result?.data?.newChannelPartnerId?._id ?? '');
                    setorderValue(Number(result?.data?.orderValue).toFixed(2) ?? '');
                    setOrderTypeRecord(result?.data?.newOrdertypeId?.name ?? '');
                    setNewOrderTypeId(result?.data?.newOrdertypeId?._id ?? '');
                    setdropOffLocation(result?.data?.dropOffLocation ?? '');
                    setDeliveryDate(result?.data?.deliveryDate ?? '');
                    setorderStatusRecord(result?.data?.newOrderStatusId?.name ?? '');
                    setNewOrderStatusId(result?.data?.newOrderStatusId?._id ?? '');
                    setExpectedCash(Number(result?.data?.expCashCollected).toFixed(2) ?? '');
                    setActualCash(result?.data?.actCashCollected ?? '');
                    setCashOutstanding(result?.data?.cashOutStanding ?? '');
                    setperCashOut(result?.data?.perCashOutStanding ?? '');
                    setMargin(result?.data?.perMargin ?? '');
                    setPayDueDate(result?.data?.payDueDate ?? '');
                    setdaysPastDue(result?.data?.daysPastDue ?? '');
                    setpaymentStatusRecord(result?.data?.newPaymentStatusId?.name ?? '');
                    setNewPaymentStatusId(result?.data?.newPaymentStatusId?._id ?? '');
                    // setchannelId(result?.data?.newChannelPartnerId?.partnerName ?? '');
                    setPartnerContact(result?.data?.newChannelPartnerId?.partnerContact ?? '');
                    setpartnerPercentSold(result?.data?.newChannelPartnerId?.percentOrderSold ?? '');
                    setCreditRating(result?.data?.newChannelPartnerId?.creditRating ?? '');
                    setCarrierId(result?.data?.newCarrierId?.name ?? '');
                    setnewCarrierId(result?.data?.newCarrierId?._id ?? '');
                    setCarrierRating(result?.data?.newCarrierId?.carrierRating ?? '');
                    setCarrierContact(result?.data?.newCarrierId?.carrierContact ?? '');
                    setCarrierNPS(result?.data?.newCarrierId?.carrierNPS ?? '');
                    setOrderPickupTime(result?.data?.orderPickUpTime ?? '');
                    setPickupType(result?.data?.newPickupTypeId?.name ?? '');
                    setNewPickUpTypeId(result?.data?.newPickupTypeId?._id ?? '');
                    setPickupLocation(result?.data?.pickUpLocation ?? '');
                    setDropoffType(result?.data?.newDropoffTypeId?.name ?? '');
                    setNewDropoffTypeId(result?.data?.newDropoffTypeId?._id ?? '');
                    setItems(result?.data?.items ?? '');
                    setOrderNotes(result?.data?.orderNotes ?? '');
                    setsalesTeamId(result?.data?.salesTeamId?._id ?? '');
                    setCash(result?.data?.cash ?? '');
                    // setparts(result?.data?.parts ?? '');
                    setpartsdata(result?.data?.partsdata ?? []);
                    setCollectiondatas(result?.data?.collectiondatas ?? []);
                    setdeliveryNoteData(result?.data?.deliveryNoteData ?? []);
                    setdispatchImageData(result?.data?.dispatchImageData ?? []);
                    setpacksortImageData(result?.data?.packsortImageData ?? []);
                    setdriverImageNoteData(result?.data?.driverImageNoteData ?? []);
                    setfulOrderId(result?.data?.fulOrderId ?? '');

                    // setName(result.data.name);
                    // setStatus(result.data.status);
                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                    // setLoading(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading2(false);
            });
        retrieveAllActiveChannel();
        retrieveAllOrderType();
        retrieveAllActivePaymentStatus();
        retrieveAllActiveOrderStatus();
        retrieveAllChannelPartner();
        retrieveAllCarrier();
        retrieveAllPart();
        retrieveAllPickDropType();
        retrieveAlLsalesTeam();
    }, [id]);

    const handleOrderDateChange = (newValue) => {
        setorderDate(newValue);
    };
    const handleDeliveryDateChange = (newValue) => {
        setDeliveryDate(newValue);
    };

    const handlePayDueDateChange = (newValue) => {
        setPayDueDate(newValue);
        const DifferenceInTime = new Date(newValue).getTime() - new Date(orderDate).getTime();
        // To calculate the no. of days between two dates
        const DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);
        const days = Math.round(DifferenceInDays);

        setdaysPastDue(days);
    };
    // const handleClickOpen = () => {
    //     setOpen(true);
    // };
    // const handlePartType = (event) => {
    //     setpartType(event.target.value);
    // };

    const handleClose = () => {
        setOpen(false);
    };
    // const handleClickOpen2 = () => {
    //     setOpen2(true);
    // };

    // const handleClose2 = () => {
    //     setOpen2(false);
    // };
    const handleSalesChange = (event) => {
        setsalesTeamId(event.target.value);
    };

    // const onSubmitPartEdit = (event) => {
    //     event.preventDefault();
    //     const newList = Object.assign([], items);
    //     const data = { ...newList[indexValue] };
    //     data.garagePrice = garagePrice;
    //     data.qty = qty;
    //     data.partType = partType;
    //     data.partid = partid;
    //     data.skuId = skuId;
    //     data.applicableVins = applicableVins;
    //     data.brand = brand;
    //     data.wholeSalePrice = wholeSalePrice;
    //     newList[indexValue] = data;
    //     setItems(newList);
    //     handleClose2();
    //     let sum = 0;
    //     setorderValue(sum);
    //     newList.forEach((row) => {
    //         sum += Number(row.garagePrice) * Number(row.qty);
    //         // console.log('row data', row);
    //     });

    //     setorderValue(sum);
    //     const marginRate = (Number(expCashCollected) - Number(sum)) / Number(sum);
    //     const value = isFinite(marginRate) ? marginRate : 0;
    //     const margin = Number(value).toFixed(2);

    //     setMargin(margin);
    // };

    const onSubmitPart = (event) => {
        event.preventDefault();
        // items, setItems
        setItems([
            ...items,
            {
                skuId,
                partid,
                qty,
                brand,
                // partType,
                wholeSalePrice,
                garagePrice,
                applicableVins
            }
        ]);

        handleClose();
        // console.log('row qty', qty);
        // console.log('row garagePrice', garagePrice);
        let sum = 0;
        setorderValue(sum);
        const newList = Object.assign([], items);
        newList.forEach((row) => {
            sum += Number(row.garagePrice) * Number(row.qty);
            // console.log('row data', row);
        });

        sum += Number(garagePrice) * Number(qty);
        setorderValue(sum);
        const marginRate = (Number(expCashCollected) - Number(sum)) / Number(sum);
        const value = isFinite(marginRate) ? marginRate : 0;
        const margin = Number(value).toFixed(2);

        setMargin(margin);
    };

    // Submit Order
    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        const data = {
            id,
            orderDate,
            channelId,
            newChannelId,
            channelPartnerId,
            newChannelPartnerId,
            orderValue,
            orderTypId,
            newOrdertypeId,
            dropOffLocation,
            deliveryDate,
            orderStatusId,
            newOrderStatusId,
            expCashCollected,
            actCashCollected,
            cashOutStanding,
            perCashOutStanding,
            perMargin,
            payDueDate,
            daysPastDue,
            paymentStatusId,
            newPaymentStatusId,
            carrierId,
            newCarrierId,
            orderPickUpTime,
            pickUpTypeId,
            newPickupTypeId,
            pickUpLocation,
            dropOffTypeId,
            newDropoffTypeId,
            orderNotes,
            items,
            salesTeamId,
            cash
        };

        fetch(ApiUrl.UpdateOrdersUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading2(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/order');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading2(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading2(false);
            });
    };
    const filterCash = (event) => {
        const cashOut = expCashCollected - event;

        setCashOutstanding(cashOut);
        const percent = (cashOut / expCashCollected) * 100;

        setperCashOut(percent.toFixed(2));
    };
    const filterMargin = (event) => {
        const marginRate = (Number(event) - Number(orderValue)) / Number(orderValue);
        const value = isFinite(marginRate) ? marginRate : 0;
        const margin = Number(value).toFixed(2);

        setMargin(margin);

        const cashOut = Number(event) - Number(actCashCollected);

        setCashOutstanding(cashOut);
        const percent = (cashOut / Number(event)) * 100;

        setperCashOut(percent.toFixed(2));
        setActualCash(0);
    };
    const filterOptions = (event) => {
        const newFilter = channelpartner.filter((value) => (value.partnerName || '').toLowerCase().includes((event || '').toLowerCase()));

        newFilter.forEach((option) => {
            setPartnerContact(option.partnerContact);
            setpartnerPercentSold(option.percentOrderSold);
            setCreditRating(option.creditRating);
            setNewChannelPartnerId(option._id);
        });
    };

    const filterCarrier = (event) => {
        const newFilter = carrier.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));

        newFilter.forEach((option) => {
            setCarrierContact(option.carrierContact);
            setCarrierNPS(option.carrierNPS);
            setCarrierRating(option.carrierRating);
            setnewCarrierId(option._id);
        });
    };

    const filterPart = (event) => {
        const newFilter = part.filter((value) => (value.sku || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('newFilter', newFilter);
        newFilter.forEach((option) => {
            setSkuId(option._id ?? '');
            setBrand(option.brand ?? '');
            setwholeSalePrice(option.wholeSalePrice ?? '');
            setgaragePrice(option.garagePrice ?? '');
            setapplicableVins(option.applicableVins ?? '');
        });
    };

    const filterchannelId = (event) => {
        const newFilter = channel.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            setNewChannelId(option._id);
        });
    };
    const filterOrderType = (event) => {
        const newFilter = orderType.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            setNewOrderTypeId(option._id);
        });
    };

    const filterOrderStatus = (event) => {
        const newFilter = orderStatus.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            setNewOrderStatusId(option._id);
        });
    };

    const filterPaymentStatus = (event) => {
        const newFilter = paymentStatus.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            setNewPaymentStatusId(option._id);
        });
    };

    const filterPickType = (event) => {
        const newFilter = pickdroptype.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            setNewPickUpTypeId(option._id);
        });
    };

    const filterDropoffType = (event) => {
        const newFilter = pickdroptype.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            setNewDropoffTypeId(option._id);
        });
    };

    // const handleDeleteClick = (row, index) => {
    //     // const newList = items.filter((item) => item.skuId !== row.skuId);
    //     // setItems(newList);
    //     const newList = Object.assign([], items);
    //     newList.splice(index, 1);
    //     setItems(newList);
    //     let sum = 0;
    //     setorderValue(sum);

    //     newList.forEach((row) => {
    //         sum += Number(row.garagePrice) * Number(row.qty);
    //         // console.log('row data', row);
    //     });

    //     setorderValue(sum);
    //     const marginRate = (Number(expCashCollected) - Number(sum)) / Number(sum);
    //     const value = isFinite(marginRate) ? marginRate : 0;
    //     const margin = Number(value).toFixed(2);
    //     setMargin(margin);
    // };

    // const handleEditClick = (row, index) => {
    //     // console.log('row', row);
    //     setPartId(row.partid ?? '');
    //     setQty(row.qty ?? '');
    //     setgaragePrice(row.garagePrice ?? '');
    //     setSkuId(row.skuId ?? '');
    //     setapplicableVins(row.applicableVins ?? '');
    //     setBrand(row.brand ?? '');
    //     setwholeSalePrice(row.wholeSalePrice ?? '');
    //     handleClickOpen2();
    //     // const newList = Object.assign([], items);
    //     // const idx = newList.findIndex((data) => data._id === row._id);
    //     console.log('indexf', index);
    //     setIndexValue(index);
    // };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <br />
                    <PageHeader title="Order" subTitle="Home | Show Order" />
                    <br />
                    <MainCard title="">
                        <Typography variant="subtitle2" component="div">
                            Main Information
                        </Typography>
                        <br />
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="fulOrderId"
                                            variant="outlined"
                                            required
                                            id="fulOrderId"
                                            label="Order No."
                                            disabled
                                            value={fulOrderId}
                                            onChange={(e) => setfulOrderId(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            {/* <Stack spacing={1}> */}
                                            <DesktopDatePicker
                                                label="Order date"
                                                disabled
                                                inputFormat="MM/dd/yyyy"
                                                value={orderDate}
                                                onChange={handleOrderDateChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            {/* </Stack> */}
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            disabled
                                            id="combo-box-demo"
                                            value={channelId}
                                            onChange={(event, newValue) => {
                                                setChannelRecord(newValue);
                                                filterchannelId(newValue);
                                            }}
                                            options={channel.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Channel" />}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            disabled
                                            id="combo-box-demo"
                                            value={channelPartnerId}
                                            onChange={(event, newValue) => {
                                                setChannelPartnerRecord(newValue);
                                                filterOptions(newValue);
                                            }}
                                            options={channelpartner.map((option) => option.partnerName)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Channel Partner" />}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            name="name"
                                            variant="outlined"
                                            required
                                            id="name"
                                            label="Order Value GHC"
                                            disabled
                                            value={orderValue}
                                            onChange={(e) => setorderValue(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            disabled
                                            id="combo-box-demo"
                                            value={orderTypId}
                                            onChange={(event, newValue) => {
                                                setOrderTypeRecord(newValue);
                                                filterOrderType(newValue);
                                            }}
                                            options={orderType.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Order Type" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            name="dropOffLocation"
                                            variant="outlined"
                                            required
                                            id="dropOffLocation"
                                            label="Drop off location"
                                            value={dropOffLocation}
                                            onChange={(e) => setdropOffLocation(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            {/* <Stack spacing={1}> */}
                                            <DesktopDatePicker
                                                label="Fulfilled Date"
                                                disabled
                                                inputFormat="MM/dd/yyyy"
                                                value={deliveryDate}
                                                onChange={handleDeliveryDateChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            {/* </Stack> */}
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            disabled
                                            id="combo-box-demo"
                                            value={orderStatusId}
                                            onChange={(event, newValue) => {
                                                setorderStatusRecord(newValue);
                                                filterOrderStatus(newValue);
                                            }}
                                            options={orderStatus.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Order Status" />}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />
                            <Typography variant="subtitle2" component="div">
                                Payment Information
                            </Typography>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            type="number"
                                            name="expCashCollected"
                                            variant="outlined"
                                            required
                                            id="expCashCollected"
                                            label="Expected Cash Collected"
                                            value={expCashCollected}
                                            onChange={(e) => {
                                                setExpectedCash(e.target.value);
                                                filterMargin(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            type="number"
                                            name="actCashCollected"
                                            variant="outlined"
                                            required
                                            id="actCashCollected"
                                            label="Actual Cash Collected"
                                            value={actCashCollected}
                                            onChange={(e) => {
                                                setActualCash(e.target.value);
                                                filterCash(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            type="number"
                                            name="cashOutStanding"
                                            variant="outlined"
                                            id="cashOutStanding"
                                            label="Cash Outstanding"
                                            value={cashOutStanding}
                                            onChange={(e) => setCashOutstanding(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            type="number"
                                            name="perCashOutStanding"
                                            variant="outlined"
                                            id="perCashOutStanding"
                                            label="% Cash Outstanding"
                                            value={perCashOutStanding}
                                            onChange={(e) => setperCashOut(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            type="number"
                                            name="perMargin"
                                            variant="outlined"
                                            id="perMargin"
                                            label="% Margin"
                                            value={perMargin}
                                            onChange={(e) => setMargin(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            {/* <Stack spacing={1}> */}
                                            <DesktopDatePicker
                                                label="Pmt Due date"
                                                disabled
                                                inputFormat="MM/dd/yyyy"
                                                value={payDueDate}
                                                onChange={handlePayDueDateChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            {/* </Stack> */}
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            type="number"
                                            name="daysPastDue"
                                            variant="outlined"
                                            required
                                            id="daysPastDue"
                                            label="Days Past Due"
                                            value={daysPastDue}
                                            onChange={(e) => setdaysPastDue(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            disabled
                                            id="combo-box-demo"
                                            value={paymentStatusId}
                                            onChange={(event, newValue) => {
                                                setpaymentStatusRecord(newValue);
                                                filterPaymentStatus(newValue);
                                            }}
                                            options={paymentStatus.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Payment Status" />}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />
                            <Typography variant="subtitle2" component="div">
                                Channel Partner Information
                            </Typography>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            disabled
                                            id="combo-box-demo"
                                            value={channelPartnerId}
                                            onChange={() => {
                                                // setchannelId(newValue);
                                            }}
                                            options={channelpartner.map((option) => option.partnerName)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Channel Partner" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            name="partnerContact"
                                            variant="outlined"
                                            id="partnerContact"
                                            label="Partner Contact Info"
                                            value={partnerContact}
                                            onChange={(e) => setPartnerContact(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            name="partnerPercentSold"
                                            variant="outlined"
                                            required
                                            id="partnerPercentSold"
                                            label="% Sold"
                                            value={partnerPercentSold}
                                            onChange={(e) => setpartnerPercentSold(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            name="creditrating"
                                            variant="outlined"
                                            required
                                            id="creditrating"
                                            label="Credit rating"
                                            value={creditrating}
                                            onChange={(e) => setCreditRating(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                            <br />
                            <Typography variant="subtitle2" component="div">
                                Carrier Information
                            </Typography>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            disabled
                                            value={carrierId}
                                            onChange={(event, newValue) => {
                                                setCarrierId(newValue);
                                                filterCarrier(newValue);
                                            }}
                                            options={carrier.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Carrier" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            name="carrierRating"
                                            variant="outlined"
                                            // id="carrierRating"
                                            label="Carrier Rating"
                                            value={carrierRating}
                                            onChange={(e) => setCarrierRating(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            name="carrierContact"
                                            variant="outlined"
                                            id="carrierContact"
                                            label="Carrier Contact Info"
                                            value={carrierContact}
                                            onChange={(e) => setCarrierContact(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            name="carrierNPS"
                                            variant="outlined"
                                            id="carrierNPS"
                                            label="Carrier NPS"
                                            value={carrierNPS}
                                            onChange={(e) => setCarrierNPS(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            name="orderPickUpTime"
                                            variant="outlined"
                                            required
                                            id="orderPickUpTime"
                                            label="Order Pickup Time"
                                            value={orderPickUpTime}
                                            onChange={(e) => setOrderPickupTime(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            disabled
                                            id="combo-box-demo"
                                            value={pickUpTypeId}
                                            onChange={(event, newValue) => {
                                                setPickupType(newValue);
                                                filterPickType(newValue);
                                            }}
                                            options={pickdroptype.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Pickup Type" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            name="pickUpLocation"
                                            variant="outlined"
                                            required
                                            id="pickUpLocation"
                                            label="Pickup Location"
                                            value={pickUpLocation}
                                            onChange={(e) => setPickupLocation(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            disabled
                                            id="combo-box-demo"
                                            value={dropOffTypeId}
                                            onChange={(event, newValue) => {
                                                setDropoffType(newValue);
                                                filterDropoffType(newValue);
                                            }}
                                            options={pickdroptype.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Drop off Type" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            name="dropOffLocation"
                                            variant="outlined"
                                            id="dropOffLocation"
                                            label="Drop off Location"
                                            value={dropOffLocation}
                                            onChange={(e) => setdropOffLocation(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Sales / Ops Focal Name</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                disabled
                                                id="demo-simple-select"
                                                value={salesTeamId}
                                                label="Sales Team"
                                                onChange={handleSalesChange}
                                            >
                                                {salesTeamInfo.map((sales) => (
                                                    <MenuItem key={sales._id} value={sales._id}>
                                                        {sales.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Cash or Credit</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                disabled
                                                id="demo-simple-select"
                                                value={cash}
                                                label="Status"
                                                onChange={handleCashChange}
                                            >
                                                <MenuItem value="Body">Select an option</MenuItem>
                                                <MenuItem value="Cash">Cash</MenuItem>
                                                <MenuItem value="Credit">Credit</MenuItem>
                                                <MenuItem value="Credit">Cheque</MenuItem>
                                                <MenuItem value="CashCredit">Cash and Credit</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                            </Grid>

                            <br />
                            <Typography variant="subtitle2" component="div">
                                Order Items
                            </Typography>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        SKU
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Part
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        QTY
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Size
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Brand
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Purchase Price
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Garage Retail Price
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Image
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Supplier
                                                    </TableCell>
                                                    {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Actions
                                                    </TableCell> */}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {items.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.skuId}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.partid}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.qty}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.partType}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.brand}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.wholeSalePrice}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.garagePrice}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <Avatar
                                                                variant="rounded"
                                                                src={row?.partimage ?? nologo}
                                                                style={{ border: 0, objectFit: 'cover' }}
                                                                sx={{ width: 100, height: 100 }}
                                                            />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.supplier ?? ''}
                                                        </TableCell>
                                                        {/* <TableCell component="th" scope="row">
                                                            <IconButton
                                                                aria-label="edit"
                                                                color="primary"
                                                                onClick={() => handleEditClick(row, index)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                onClick={() => handleDeleteClick(row, index)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell> */}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                    {/* <Button variant="contained" onClick={handleClickOpen} size="sm" className={classes.item}>
                                        Add item
                                    </Button> */}
                                </Grid>
                            </Grid>

                            <br />
                            <br />

                            <Typography variant="h5" component="h2">
                                Parts not in the system.
                            </Typography>

                            <br />

                            <Grid container spacing={4} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={8}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Part
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        QTY
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Size
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Supplier
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {partsdata.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.partname}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.partqty}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.partsize}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.supplier2 ?? ''}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <br />
                            <Typography variant="h5" component="h2">
                                Collections
                            </Typography>
                            <br />

                            <Grid container spacing={4} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Collected Date
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Desposited Date
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Payment Type
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Collected Amount
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Desposited Amount
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Image
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Notes
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Approved Status
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {collectiondatas.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.collectedDate ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.depositeDate ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.paymentType ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row?.collectedAmt ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {Number.isFinite(row?.approveAmt)
                                                                ? Number(row?.approveAmt).toFixed(2)
                                                                : Number(row?.depositeAmt).toFixed(2)}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Avatar
                                                                variant="rounded"
                                                                src={row?.depositeImage}
                                                                sx={{ width: 80, height: 50 }}
                                                            />
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row?.referenceNote ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row?.approveStatus ?? ''}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <br />
                            <Typography variant="h5" component="h2">
                                Delivery Note
                            </Typography>
                            <br />

                            <Grid container spacing={4} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Date
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        User
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Image
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {deliveryNoteData.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.capturedDate ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.capturedUser ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <Avatar variant="rounded" src={row?.imgFile} sx={{ width: 100, height: 100 }} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>

                            <br />
                            <Typography variant="h5" component="h2">
                                Dispatch
                            </Typography>
                            <br />

                            <Grid container spacing={4} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Date
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        User
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Image
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {dispatchImageData.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.capturedDate ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.capturedUser ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <Avatar variant="rounded" src={row?.imgFile} sx={{ width: 100, height: 100 }} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>

                            <br />
                            <Typography variant="h5" component="h2">
                                Pack and Sort
                            </Typography>
                            <br />

                            <Grid container spacing={4} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Date
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        User
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Image
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {packsortImageData.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.capturedDate ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.capturedUser ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <Avatar variant="rounded" src={row?.imgFile} sx={{ width: 100, height: 100 }} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <br />
                            <Typography variant="h5" component="h2">
                                Driver Delivery Note
                            </Typography>
                            <br />

                            <Grid container spacing={4} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Date
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        User
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Image
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {driverImageNoteData.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.capturedDate ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.capturedUser ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <Avatar variant="rounded" src={row?.imgFile} sx={{ width: 100, height: 100 }} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>

                            <br />
                            <br />
                            <Typography variant="subtitle2" component="div">
                                Order Notes
                            </Typography>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TextField
                                        autoComplete="off"
                                        disabled
                                        name="orderNotes"
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        fullWidth
                                        value={orderNotes}
                                        id="orderNotes"
                                        label="Order Notes"
                                        onChange={(e) => setOrderNotes(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <br />
                            <br />
                            <Box display="flex" justifyContent="space-between">
                                <Button component={Link} to="/dispatchimages" type="submit" variant="contained" color="error">
                                    Back
                                </Button>
                                {/* <Button type="submit" variant="contained" color="secondary">
                                    {loading ? 'Updating....' : 'Update Order'}
                                </Button> */}
                            </Box>
                        </form>

                        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                            <DialogTitle id="responsive-dialog-title">Add Part</DialogTitle>
                            <Form onSubmit={onSubmitPart}>
                                <DialogContent>
                                    {/* <DialogContentText> */}

                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Box width={200}>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                value={partid}
                                                onChange={(event, newValue) => {
                                                    setPartId(newValue);
                                                    filterPart(newValue);
                                                }}
                                                options={part.map((option) => option.sku)}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                renderInput={(params) => <TextField {...params} label="Sku" />}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="wholeSalePrice"
                                                variant="outlined"
                                                required
                                                id="wholeSalePrice"
                                                label="WholeSale Price"
                                                value={wholeSalePrice}
                                                onChange={(e) => setwholeSalePrice(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="garagePrice"
                                                variant="outlined"
                                                required
                                                id="garagePrice"
                                                label="Garage Price"
                                                value={garagePrice}
                                                onChange={(e) => setgaragePrice(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="qty"
                                                variant="outlined"
                                                required
                                                id="qty"
                                                label="Qty"
                                                value={qty}
                                                onChange={(e) => {
                                                    setQty(e.target.value);
                                                }}
                                            />
                                        </Box>
                                    </Form.Group>

                                    {/* </DialogContentText> */}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="sm"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleClose}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        type="submit"
                                        size="sm"
                                        className={classes.item}
                                        disabled={!partid || !garagePrice || !qty}
                                    >
                                        Add
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>
                    </MainCard>
                </>
            )}
        </>
    );
};
export default DispatchPreview;
