import {
    BATCH_ID_BEGIN,
    BATCH_ID_FAILURE,
    BATCH_ID_SUCCESS,
    BATCH_RECEIPT_BEGIN,
    BATCH_RECEIPT_FAILURE,
    BATCH_RECEIPT_SUCCESS,
    CC_STATS_BEGIN,
    CC_STATS_FAILURE,
    CC_STATS_SUCCESS,
    CLEAR_FILTERS,
    COLLECTIONS_BEGIN,
    COLLECTIONS_FAILURE,
    COLLECTIONS_SUCCESS,
    DATA_DEFAULTS_BEGIN,
    DATA_DEFAULTS_FAILURE,
    DATA_DEFAULTS_SUCCESS,
    DEPOSITS_BEGIN,
    DEPOSITS_FAILURE,
    DEPOSITS_SUCCESS,
    FILTER_COLLECTIONS_BEGIN,
    FILTER_COLLECTIONS_ERROR,
    FILTER_COLLECTIONS_SUCCESS,
    FILTER_DEPOSITS_BEGIN,
    FILTER_DEPOSITS_ERROR,
    FILTER_DEPOSITS_SUCCESS,
    FILTER_LEADS_BEGIN,
    FILTER_LEADS_ERROR,
    FILTER_LEADS_SUCCESS,
    FILTER_RETAILER_BEGIN,
    FILTER_RETAILER_ERROR,
    FILTER_RETAILER_SUCCESS,
    FILTER_SALES_BEGIN,
    FILTER_SALES_ERROR,
    FILTER_SALES_SUCCESS,
    FINANCE_STATS_BEGIN,
    FINANCE_STATS_FAILURE,
    FINANCE_STATS_SUCCESS,
    FULFILLMENT_ST_BEGIN,
    FULFILLMENT_ST_FAILURE,
    FULFILLMENT_ST_SUCCESS,
    GENERATE_LEADS_BEGIN,
    GENERATE_LEADS_FAILURE,
    GENERATE_LEADS_SUCCESS,
    GMV_BEGIN,
    GMV_FAILURE,
    GMV_SUCCESS,
    RESET_COLLECTIONS,
    RESET_COLLECTIONS_BEGIN,
    RESET_DEPOSITS,
    RESET_DEPOSITS_BEGIN,
    RESET_LEADS,
    RESET_LEADS_BEGIN,
    RESET_RETAILER,
    RESET_RETAILER_BEGIN,
    RESET_SALES,
    RESET_SALES_BEGIN,
    RETAILER_BEGIN,
    RETAILER_FAILURE,
    RETAILER_SUCCESS,
    SALES_BEGIN,
    SALES_FAILURE,
    SALES_SUCCESS,
    SUPPLIER_BATCH_BEGIN,
    SUPPLIER_BATCH_FAILURE,
    SUPPLIER_BATCH_SUCCESS,
    SUPPLIER_BEGIN,
    SUPPLIER_FAILURE,
    SUPPLIER_SUCCESS
} from './actions';

const reducer = (state, action) => {
    switch (action.type) {
        case GENERATE_LEADS_BEGIN:
            return { ...state, isLoading: true };
        case GENERATE_LEADS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                ranks: action.payload.ranks,
                salesAssociate: action.payload.salesAssociate,
                currency: action.payload.currency,
                leadTarget: action.payload.leadTarget,
                todolist: action.payload.todolist,
                leadGProgress: action.payload.leadGProgress,
                noLeadGToday: action.payload.noLeadGToday,
                leadsfollowups: action.payload.leadsfollowups,
                message: action.payload.message
            };
        case GENERATE_LEADS_FAILURE:
            return { ...state, isLoading: false, message: action.payload.message };

        case SALES_BEGIN:
            return {
                ...state,
                isloading: true
            };

        case SALES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message,
                salesTarget: action.payload.salesTarget,
                makeSalestodolist: action.payload.todolist,
                currentTotalSales: action.payload.currentTotalSales,
                previousMonthTotalSales: action.payload.previousMonthTotalSales,
                currentPreviousMonthSalesRate: action.payload.currentPreviousMonthSalesRate,
                currentNoOrders: action.payload.currentNoOrders,
                previousMonthNoOrders: action.payload.previousMonthNoOrders,
                currentPreviousMonthNoOrdersRate: action.payload.currentPreviousMonthNoOrdersRate,
                currentactualratio: action.payload.currentactualratio,
                lastmonthactualratio: action.payload.lastmonthactualratio,
                currentPreviousMonthActualRatioRate: action.payload.currentPreviousMonthActualRatioRate,
                currentWhalesCaughtCount: action.payload.currentWhalesCaughtCount,
                lastMonthWhalesCaughtCount: action.payload.lastMonthWhalesCaughtCount,
                currentPreviousMonthWhalesRate: action.payload.currentPreviousMonthWhalesRate,
                retailersduefollowups: action.payload.retailersduefollowups
            };

        case SALES_FAILURE:
            return {
                ...state,
                isLoading: false
            };

        case COLLECTIONS_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case COLLECTIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                collectionsTodos: action.payload.todolist,
                collectionsdue: action.payload.collectionsdue,
                collectionsTarget: action.payload.collectionsTarget,
                currentcollectionsTotal: action.payload.currentcollectionsTotal,
                lastmontcollectionsTotal: action.payload.lastmontcollectionsTotal,
                currentLastMonthCollectionsRate: action.payload.currentLastMonthCollectionsRate,
                currentactualcollectionsratio: action.payload.currentactualcollectionsratio,
                lastmonthactualCollectionsRatio: action.payload.lastmonthactualCollectionsRatio,
                currentPreviousMonthActualCollectionRate: action.payload.currentPreviousMonthActualCollectionRate
            };

        case COLLECTIONS_FAILURE:
            return {
                ...state,
                isLoading: false
            };

        case DEPOSITS_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case DEPOSITS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                depositTodos: action.payload.todolist,
                collectionsdepositsdue: action.payload.collectionsdepositsdue,
                currentDepositsTotal: action.payload.currentDepositsTotal,
                lastmontDepositsTotal: action.payload.lastmontDepositsTotal,
                currentLastMonthDepositsRate: action.payload.currentLastMonthDepositsRate
            };
        case DEPOSITS_FAILURE:
            return {
                ...state,
                isLoading: false
            };

        case FILTER_SALES_BEGIN:
            return {
                ...state,
                isLoading: true
            };

        case FILTER_SALES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                retailersduefollowups: action.payload.retailersduefollowups
            };
        }

        case FILTER_SALES_ERROR: {
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            };
        }
        case RESET_SALES_BEGIN: {
            return {
                ...state,
                isLoading: true
            };
        }
        case RESET_SALES: {
            return {
                ...state,
                isLoading: false,
                retailersduefollowups: action.payload.retailersduefollowups
            };
        }
        case FILTER_LEADS_BEGIN:
            return {
                ...state,
                isLoading: true
            };

        case FILTER_LEADS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                leadsfollowups: action.payload.leadsfollowups
            };
        }

        case FILTER_LEADS_ERROR: {
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            };
        }
        case RESET_LEADS_BEGIN: {
            return {
                ...state,
                isLoading: true
            };
        }
        case RESET_LEADS: {
            return {
                ...state,
                isLoading: false,
                leadsfollowups: action.payload.leadsfollowups
            };
        }

        case FILTER_COLLECTIONS_BEGIN:
            return {
                ...state,
                isLoading: true
            };

        case FILTER_COLLECTIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                collectionsdue: action.payload.collectionsdue
            };
        }
        case FILTER_COLLECTIONS_ERROR: {
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            };
        }
        case RESET_COLLECTIONS_BEGIN: {
            return {
                ...state,
                isLoading: true
            };
        }
        case RESET_COLLECTIONS: {
            return {
                ...state,
                isLoading: false,
                collectionsdue: action.payload.collectionsdue
            };
        }

        case FILTER_DEPOSITS_BEGIN:
            return {
                ...state,
                isLoading: true
            };

        case FILTER_DEPOSITS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                collectionsdepositsdue: action.payload.collectionsdepositsdue
            };
        }

        case FILTER_DEPOSITS_ERROR: {
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            };
        }
        case RESET_DEPOSITS_BEGIN: {
            return {
                ...state,
                isLoading: true
            };
        }
        case RESET_DEPOSITS: {
            return {
                ...state,
                isLoading: false,
                collectionsdepositsdue: action.payload.collectionsdepositsdue
            };
        }

        case RETAILER_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case RETAILER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                newRetailerTarget: action.payload.newRetailerTarget,
                retailerTodolist: action.payload.todolist,
                newRetailerGProgress: action.payload.newRetailerGProgress,
                noRetailerGToday: action.payload.noRetailerGToday,
                newRetailerfollowups: action.payload.newRetailerfollowups
            };
        case RETAILER_FAILURE:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            };

        case FILTER_RETAILER_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case FILTER_RETAILER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                newRetailerfollowups: action.payload.newRetailerfollowups
            };
        }

        case FILTER_RETAILER_ERROR: {
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            };
        }

        case RESET_RETAILER_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case RESET_RETAILER:
            return {
                ...state,
                isLoading: false,
                newRetailerfollowups: action.payload.newRetailerfollowups
            };
        case SUPPLIER_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case SUPPLIER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                totalPartsSoldToday: action.payload.totalPartsSoldToday,
                totalPartsSoldYesterday: action.payload.totalPartsSoldYesterday,
                partSoldPercentage: action.payload.partSoldPercentage,
                totalNoPartsToday: action.payload.totalPartsSoldToday,
                totalNoPartsYesterday: action.payload.totalNoPartsYesterday,
                noPartsPercentage: action.payload.noPartsPercentage,
                averageAmountMadeToday: action.payload.averageAmountMadeToday,
                averageAmountMadeesterday: action.payload.averageAmountMadeesterday,
                avgAmountPercentage: action.payload.avgAmountPercentage,
                supplierTop5parts: action.payload.supplierTop5parts,
                totalsales: action.payload.totalsales,
                totalsupplierorders: action.payload.totalsupplierorders
            };
        case SUPPLIER_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case SUPPLIER_BATCH_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case SUPPLIER_BATCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                orderBatches: action.payload.orderBatches
            };
        case SUPPLIER_BATCH_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case BATCH_ID_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case BATCH_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                orderedParts: action.payload.orderedParts,
                currentBatchDate: action.payload.currentBatchDate,
                currentBatchNo: action.payload.currentBatchNo,
                batchStatusId: action.payload.batchStatusId
            };
        case BATCH_ID_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case 'HANDLE_CHANGE':
            const { item } = action.payload;
            const updatedParts = state.orderedParts.map((cx) => {
                if (cx.skuId === item?.skuId) {
                    return {
                        ...cx,
                        isAvailable: !cx.isAvailable
                    };
                }
                return {
                    ...cx
                };
            });
            return {
                ...state,
                orderedParts: updatedParts,
                isLoading: false
            };
        case BATCH_RECEIPT_BEGIN:
            return {
                ...state,
                batchSubmitLoading: true
            };
        case BATCH_RECEIPT_SUCCESS:
            return {
                ...state,
                batchSubmitLoading: false,
                message: action.payload.message
            };
        case BATCH_RECEIPT_FAILURE:
            return {
                ...state,
                batchSubmitLoading: false
            };
        case DATA_DEFAULTS_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case DATA_DEFAULTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                orderStatuses: action.payload.orderStatus,
                paymentStatuses: action.payload.paymentStatus,
                countries: action.payload.country,
                cities: action.payload.city,
                customers: action.payload.customer,
                relationshipManagers: action.payload.relationshipManagers
            };
        case DATA_DEFAULTS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case GMV_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case GMV_SUCCESS:
            return {
                ...state,
                isLoading: false,
                regularGMV: action.payload.regularGMV,
                reqularOrdersNumber: action.payload.reqularOrdersNumber,
                reguarlarordersData: action.payload.reguarlarordersData,
                darkstoreGMV: action.payload.darkstoreGMV,
                darkstoreOrdersNumber: action.payload.darkstoreOrdersNumber,
                darkstoreordersData: action.payload.darkstoreordersData,
                salesDriveGMV: action.payload.salesDriveGMV,
                salesDriveOrdersNumber: action.payload.salesDriveOrdersNumber,
                salesDriveordersData: action.payload.salesDriveordersData,
                proformaGMV: action.payload.proformaGMV,
                proformaOrdersNumber: action.payload.proformaOrdersNumber,
                proformaordersData: action.payload.proformaordersData,
                noRetailers: action.payload.noRetailers,
                noRetailersData: action.payload.noRetailersData,
                issuesNumber: action.payload.issuesNumber,
                issuesData: action.payload.issuesData,
                noNewRetailers: action.payload.noNewRetailers,
                noNewRetailersData: action.payload.noNewRetailersData,
                leadsNumber: action.payload.leadsNumber,
                leadsData: action.payload.leadsData,
                partsoldNumber: action.payload.partsoldNumber,
                partsSoldData: action.payload.partsSoldData,
                totalOrdersNumber: action.payload.totalOrdersNumber,
                totalordersData: action.payload.totalordersData,
                totalGMV: action.payload.totalGMV,
                retentionGMV: action.payload.retentionGMV,
                retentionOrdersNumber: action.payload.retentionOrdersNumber,
                retentionordersData: action.payload.retentionordersData,
                webSrcNumber: action.payload.webSrcNumber,
                mobileSrcNumber: action.payload.mobileSrcNumber,
                totalGMVMonth: action.payload.totalGMVMonth,
                darkstoreGMVMonth: action.payload.darkstoreGMVMonth,
                proformaGMVMonth: action.payload.proformaGMVMonth,
                regularGMVMonth: action.payload.regularGMVMonth,
                retentionGMVMonth: action.payload.retentionGMVMonth,
                salesDriveGMVMonth: action.payload.salesDriveGMVMonth,
                totalMobileMonth: action.payload.totalMobileMonth,
                totalWebMonth: action.payload.totalWebMonth,
                totalFirstTimePurchaseMonthly: action.payload.totalFirstTimePurchaseMonthly,
                totalLeadsMonthly: action.payload.totalLeadsMonthly,
                preSoldGMV: action.payload.preSoldGMV,
                preSoldOrdersNumber: action.payload.preSoldOrdersNumber,
                preSoldordersData: action.payload.preSoldordersData,
                preSoldordersDataGMVMonth: action.payload.preSoldordersDataGMVMonth,
                conversionGMV: action.payload.conversionGMV,
                conversionGMVMonth: action.payload.conversionGMVMonth,
                conversionOrdersNumber: action.payload.conversionOrdersNumber,
                conversionordersData: action.payload.conversionordersData
            };
        case GMV_FAILURE:
            return {
                isLoading: false,
                ...state
            };
        case FINANCE_STATS_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case FINANCE_STATS_SUCCESS:
            return {
                ...state,
                isLoading: true,
                totalRetailCost: action.payload.totalRetailCost,
                totalRetailSales: action.payload.totalRetailSales,
                totalRetailSalesNumber: action.payload.totalRetailSalesNumber,
                totalRetailSalesData: action.payload.totalRetailSalesData,
                totalRetailDiscount: action.payload.totalRetailDiscount,
                totalRetailDiscountNumber: action.payload.totalRetailDiscountNumber,
                totalRetailDiscountData: action.payload.totalRetailDiscountData,
                totalpayments: action.payload.totalpayments,
                totalpaymentsData: action.payload.totalpaymentsData,
                totaloutstanding: action.payload.totaloutstanding,
                totaloutstandingData: action.payload.totaloutstandingData,
                totalProfit: action.payload.totalProfit,
                totalProfitPercentage: action.payload.totalProfitPercentage,
                totalAverageRetailCost: action.payload.totalAverageRetailCost,
                postVatTakeRate: action.payload.postVatTakeRate,
                postVatTakeRatePercentage: action.payload.postVatTakeRatePercentage,
                postVatTakeRateData: action.payload.postVatTakeRateData,
                totalFinanceMonthly: action.payload.totalFinanceMonthly
            };
        case FINANCE_STATS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case CC_STATS_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case CC_STATS_SUCCESS:
            const {
                ccOrderNumber,
                ccOrderData,
                prospectNumber,
                prospectData,
                onboardscompleteNumber,
                onboardscompleteData,
                onboardsbegunNumber,
                onboardsbegunData,
                partlistcollectedNumber,
                partlistcollectedData,
                partlistdeliveredNumber,
                partlistdeliveredData,
                notreadytopartnerNumber,
                notreadytopartnerData,
                retailerGender,
                totalGenderFeMaleMonthly,
                totalGenderMaleMonthly,
                totalGenderPreferNotAnswerMonthly,
                totalGenderUnknownMonthly,
                totalProspectMonthly,
                totalOnboardBegunonthly,
                totalonboardscompleteMonthly
            } = action.payload;
            return {
                ...state,
                isLoading: false,
                ccOrderNumber,
                ccOrderData,
                prospectNumber,
                prospectData,
                onboardscompleteNumber,
                onboardscompleteData,
                onboardsbegunNumber,
                onboardsbegunData,
                partlistcollectedNumber,
                partlistcollectedData,
                partlistdeliveredNumber,
                partlistdeliveredData,
                notreadytopartnerNumber,
                notreadytopartnerData,
                retailerGender,
                totalGenderFeMaleMonthly,
                totalGenderMaleMonthly,
                totalGenderPreferNotAnswerMonthly,
                totalGenderUnknownMonthly,
                totalProspectMonthly,
                totalOnboardBegunonthly,
                totalonboardscompleteMonthly
            };
        case CC_STATS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case FULFILLMENT_ST_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case FULFILLMENT_ST_SUCCESS:
            const { top20skus, loyaltyRetailers, loyaltyRetailersData } = action.payload;
            return {
                ...state,
                isLoading: false,
                top20skus,
                loyaltyRetailers,
                loyaltyRetailersData
            };
        case FULFILLMENT_ST_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case 'SET_SALES_PERSON':
            return {
                ...state,
                salesperson: action.payload.salesperson
            };
        case 'SET_CUSTOMER':
            return {
                ...state,
                customer: action.payload.customer
            };
        case 'SET_COUNTRY':
            return {
                ...state,
                countryId: action.payload.country
            };
        case 'SET_CITY':
            return {
                ...state,
                city: action.payload.city
            };
        case 'SET_PAYMENT_STATUS':
            return {
                ...state,
                paymentstatus: action.payload.paymentstatus
            };
        case 'SET_ORDER_STATUS':
            return {
                ...state,
                orderstatus: action.payload.orderstatus
            };
        case 'SET_DATE_RANGE':
            return {
                ...state,
                startdate: action.payload.startdate,
                enddate: action.payload.enddate
            };
        case CLEAR_FILTERS:
            const country = localStorage.getItem('country');
            const initialState = {
                customer: null,
                salesperson: null,
                orderstatus: null,
                paymentstatus: null,
                countryId: country,
                city: null,
                startdate: '',
                enddate: ''
            };
            return { ...state, ...initialState };
        default:
            throw new Error(`No such action ${action.type}`);
    }
};

export default reducer;
