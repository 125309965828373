import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { MdEdit } from 'react-icons/md';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import LeadTableWrapper from 'assets/Wrappers/LeadTableWrapper';
import { makeStyles } from '@mui/styles';
import TablePaginationActions from 'components/TablePaginationActions';
import { useAppContext } from 'context/appContext';
import moment from 'moment/moment';
import Loader from 'components/Loader';
import { Link } from 'react-router-dom';

const BatchTable = ({ th1, th2, th3, th4, th5 }) => {
    const { getSupplierBatches, orderBatches, isLoading } = useAppContext();
    useEffect(() => {
        getSupplierBatches();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /** Table data rows */
    const rows = orderBatches;

    const useStyles = makeStyles(() => ({
        tableHeaderCell: {
            fontWeight: 'bolder'
        }
    }));
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <LeadTableWrapper>
            {isLoading ? (
                <Loader />
            ) : (
                <Paper sx={{ width: '100%', overflow: 'hidden' }} className="lead__main-table" elevation={0}>
                    <>
                        <TableContainer sx={{ maxHeight: 400 }}>
                            <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th1}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th2}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th3}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th4}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th5}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map(
                                        (row, index = 1) => {
                                            const {
                                                _id,
                                                batchDate,
                                                batchNo,
                                                batchStatusId: { name }
                                            } = row;

                                            return (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align="left" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell align="left" scope="row">
                                                        {moment(batchDate).format('LLLL')}
                                                    </TableCell>
                                                    <TableCell align="left" scope="row">
                                                        {batchNo}
                                                    </TableCell>
                                                    <TableCell align="left" scope="row">
                                                        <span
                                                            style={{
                                                                background: '#ff740f',
                                                                padding: '5px 25px',
                                                                color: '#fff',
                                                                borderRadius: '15px',
                                                                fontSize: '14px',
                                                                fontWeight: '500',
                                                                whiteSpace: 'nowrap'
                                                            }}
                                                        >
                                                            {name}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell align="left" scope="row">
                                                        <Stack direction="row" spacing={1}>
                                                            <IconButton
                                                                component={Link}
                                                                aria-label="preview"
                                                                to={`/suppliers/batch/${_id}`}
                                                                style={{ color: '#76BA99' }}
                                                                title="Preview"
                                                            >
                                                                <VisibilityIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                component={Link}
                                                                aria-label="preview"
                                                                to={`/suppliers/batch/submit/${_id}`}
                                                                style={{ color: '#ff740f' }}
                                                                title="Preview"
                                                            >
                                                                <MdEdit />
                                                            </IconButton>
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }
                                    )}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            component="div"
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page'
                                },
                                native: true
                            }}
                            className="custom-paginator"
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </>
                </Paper>
            )}
        </LeadTableWrapper>
    );
};

export default BatchTable;
