import styled from 'styled-components';

const OrderPriorityWrapper = styled.div`
    .card {
        background: #ff740f;
        text-align: center;
        color: #fff;
    }

    .refresh-btn {
        background: #ff740f;
    }

    .card-title {
        font-size: 2.5rem;
        font-weight: 900;
    }

    .card-text {
        text-transform: uppercase;
        font-weight: 800;
        font-size: 0.875rem;
    }

    .custom-date-picker {
        background: #193855;
    }

    .filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 15px 0;
    }

    @media screen and (max-width: 500px) {
        .filter {
            flex-direction: column;
        }
        .add-priority {
            margin-top: 10px;
        }
    }

    .add-priority {
        background-color: #ff740f;
        align-self: center;
        justify-self: center;
    }
`;

export default OrderPriorityWrapper;
