/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import ApiUrl from 'network';

const { BaseUrl } = ApiUrl;

const AddComparePrice = () => {
    const { id } = useParams();
    const history = useNavigate();
    const [policies, setPolicies] = useState([]);
    const [policyId, setPolicyId] = useState('');
    const [customer, setCustomer] = useState('');
    const [customerNumber, setCustomerNumber] = useState('');
    const [claimsArr, setClaimsArr] = useState([]);
    const [claim, setClaim] = useState('');
    const [claimDate, setClaimDate] = useState(null);
    const [claimType, setClaimType] = useState('');
    const [amountQuoted, setAmountQuoted] = useState('');
    const [serviceProvider, setServiceProvider] = useState('');
    const [dueDate, setDueDate] = useState(null);
    const [cityCountry, setCityCountry] = useState('');
    const [claimError, setClaimError] = useState('');
    const [allClaims, setAllClaims] = useState([]);

    const [carMakeArr, setCarMakeArr] = useState([]);
    const [carMake, setCarMake] = useState('');
    const [carModel, setCarModel] = useState('');
    const [carYear, setCarYear] = useState('');
    const [licence, setLicence] = useState('');
    const [fuel, setFuel] = useState('');
    const [engine, setEngine] = useState('');
    const [vin, setVin] = useState('');
    const [vehicleError, setVehicleError] = useState('');
    const [allVehicle, setAllVehicles] = useState([]);

    const [partsArr, setPartsArr] = useState([]);
    const [sku, setSku] = useState('');
    const [qty, setQty] = useState('');
    const [quotedPrice, setQuotedPrice] = useState('');
    const [partsError, setPartsError] = useState('');
    const [allParts, setAllParts] = useState([]);

    const [openClaim, setOpenClaim] = useState(false);
    const handleOpenClaim = () => setOpenClaim(!openClaim);
    const [openVehicle, setOpenVehicle] = useState(false);
    const handleOpenVehicle = () => setOpenVehicle(!openVehicle);
    const [openParts, setOpenParts] = useState(false);
    const handleOpenParts = () => setOpenParts(!openParts);
    // const [formError, setFormError] = useState('');
    // const [formSuccess, setFormSuccess] = useState('');
    const [submitLoading, setSubmitLoading] = useState(false);
    const [position, setPosition] = useState(0);

    const [openUpdateClaim, setOpenUpdateClaim] = useState(false);
    const handleOpenUpdateClaim = () => setOpenUpdateClaim(!openUpdateClaim);
    const [openUpdateVehicle, setOpenUpdateVehicle] = useState(false);
    const handleOpenUpdateVehicle = () => setOpenUpdateVehicle(!openUpdateVehicle);
    const [openUpdateParts, setOpenUpdateParts] = useState(false);
    const handleOpenUpdateParts = () => setOpenUpdateParts(!openUpdateParts);

    const addClaim = () => {
        if (claim && claimDate && claimType && amountQuoted && serviceProvider && dueDate && cityCountry) {
            const findClaim = claimsArr.find((item) => item._id === claim);
            if (Number(amountQuoted)) {
                const claimItem = {
                    claimId: findClaim._id,
                    claimName: findClaim.name,
                    dateIssued: new Date(claimDate).toLocaleDateString(),
                    claimType,
                    amountQuoted: Number(amountQuoted),
                    serviceProvider,
                    dueDate: new Date(dueDate).toLocaleDateString(),
                    countryCity: cityCountry
                };
                setAllClaims([claimItem, ...allClaims]);
                setOpenClaim(!openClaim);
                setClaim('');
                setClaimDate(null);
                setClaimType('');
                setAmountQuoted('');
                setServiceProvider('');
                setDueDate(null);
                setCityCountry('');
            } else {
                setClaimError('Amount must be a number');
                setTimeout(() => setClaimError(''), 4000);
            }
        } else {
            setClaimError('All the field are required');
            setTimeout(() => setClaimError(''), 4000);
        }
    };

    const removeClaim = (index) => {
        const copy = allClaims.slice();
        copy.splice(index, 1);
        setAllClaims(copy);
    };

    const editClaim = (index) => {
        const item = allClaims[index];
        setClaim(item.claimId);
        setClaimDate(new Date(item.dateIssued).toLocaleDateString());
        setClaimType(item.claimType);
        setAmountQuoted(item.amountQuoted);
        setServiceProvider(item.serviceProvider);
        setDueDate(new Date(item.dueDate).toLocaleDateString());
        setCityCountry(item.countryCity);
        setPosition(Number(index));
        handleOpenUpdateClaim();
    };

    const updateClaim = () => {
        if (claim && claimDate && claimType && amountQuoted && serviceProvider && dueDate && cityCountry) {
            const findClaim = claimsArr.find((item) => item._id === claim);
            if (Number(amountQuoted)) {
                const claimItem = {
                    claimId: findClaim._id,
                    claimName: findClaim.name,
                    dateIssued: new Date(claimDate).toLocaleDateString(),
                    claimType,
                    amountQuoted: Number(amountQuoted),
                    serviceProvider,
                    dueDate: new Date(dueDate).toLocaleDateString(),
                    countryCity: cityCountry
                };
                const arr = allClaims.map((item, index) => {
                    if (index === position) {
                        item = claimItem;
                    }
                    return item;
                });
                setAllClaims(arr);
                setOpenUpdateClaim(!openUpdateClaim);
                setClaim('');
                setClaimDate(null);
                setClaimType('');
                setAmountQuoted('');
                setServiceProvider('');
                setDueDate(null);
                setCityCountry('');
                setPosition(0);
            } else {
                setClaimError('Amount must be a number');
                setTimeout(() => setClaimError(''), 4000);
            }
        } else {
            setClaimError('All the field are required');
            setTimeout(() => setClaimError(''), 4000);
        }
        // console.log('update here', position);
    };

    const addVehicle = () => {
        if (carMake && carModel && carYear && licence && fuel && engine && vin) {
            const findVehicle = carMakeArr.find((item) => item._id === carMake);

            if (Number(carYear)) {
                const vehicleItem = {
                    carId: findVehicle._id,
                    carMake: findVehicle.name,
                    model: carModel,
                    year: Number(carYear),
                    licensesplate: licence,
                    fuelType: fuel,
                    engineType: engine,
                    vins: vin
                };
                setAllVehicles([vehicleItem, ...allVehicle]);
                setCarMake('');
                setCarModel('');
                setCarYear('');
                setLicence('');
                setFuel('');
                setEngine('');
                setVin('');
                setOpenVehicle(!openVehicle);
            } else {
                setVehicleError('Year must be a number');
                setTimeout(() => setVehicleError(''), 4000);
            }
        } else {
            setVehicleError('All the field are required');
            setTimeout(() => setVehicleError(''), 4000);
        }
    };

    const removeVehicle = (index) => {
        const copy = allVehicle.slice();
        copy.splice(index, 1);
        setAllVehicles(copy);
    };

    const editVehicle = (index) => {
        const item = allVehicle[index];
        setCarMake(item.carId);
        setCarModel(item.model);
        setCarYear(item.year);
        setLicence(item.licensesplate);
        setFuel(item.fuelType);
        setEngine(item.engineType);
        setVin(item.vins);
        setPosition(Number(index));
        setOpenUpdateVehicle(!openUpdateVehicle);
    };

    const updateVehicle = () => {
        if (carMake && carModel && carYear && licence && fuel && engine && vin) {
            const findVehicle = carMakeArr.find((item) => item._id === carMake);
            console.log(findVehicle);

            if (Number(carYear)) {
                const vehicleItem = {
                    carId: findVehicle._id,
                    carMake: findVehicle.name,
                    model: carModel,
                    year: Number(carYear),
                    licensesplate: licence,
                    fuelType: fuel,
                    engineType: engine,
                    vins: vin
                };
                const arr = allVehicle.map((item, index) => {
                    if (index === position) {
                        item = vehicleItem;
                    }
                    return item;
                });
                setAllVehicles(arr);
                setCarMake('');
                setCarModel('');
                setCarYear('');
                setLicence('');
                setFuel('');
                setEngine('');
                setVin('');
                setOpenUpdateVehicle(!openUpdateVehicle);
                setPosition(0);
            } else {
                setVehicleError('Year must be a number');
                setTimeout(() => setVehicleError(''), 4000);
            }
        } else {
            setVehicleError('All the field are required');
            setTimeout(() => setVehicleError(''), 4000);
        }
    };

    const addParts = () => {
        if (sku && qty && quotedPrice) {
            const findSku = partsArr.find((item) => item._id === sku);
            if (Number(qty) && Number(quotedPrice)) {
                const savings = Math.floor((findSku.garagePrice - Number(quotedPrice)) / 100);
                const skuItem = {
                    skuId: findSku._id,
                    partid: findSku.sku,
                    qty: Number(qty),
                    brand: findSku.brand,
                    quotedPrice: Number(quotedPrice),
                    garagePrice: Number(findSku.garagePrice),
                    savings
                };
                setAllParts([skuItem, ...allParts]);
                setOpenParts(!openParts);
                setSku('');
                setQty('');
                setQuotedPrice('');
            } else {
                setPartsError('Quantity and your quoted price must be a number');
                setTimeout(() => setPartsError(''), 4000);
            }
        } else {
            setPartsError('All the field are required');
            setTimeout(() => setPartsError(''), 4000);
        }
    };

    const removeParts = (index) => {
        const copy = allParts.slice();
        copy.splice(index, 1);
        setAllParts(copy);
    };

    const editParts = (index) => {
        const item = allParts[index];
        setSku(item.skuId);
        setQty(item.qty);
        setQuotedPrice(item.quotedPrice);
        handleOpenUpdateParts();
        setPosition(Number(index));
    };

    const updateParts = () => {
        if (sku && qty && quotedPrice) {
            const findSku = partsArr.find((item) => item._id === sku);
            if (Number(qty) && Number(quotedPrice)) {
                const savings = Math.floor((findSku.garagePrice - Number(quotedPrice)) / 100);
                const skuItem = {
                    skuId: findSku._id,
                    partid: findSku.sku,
                    qty: Number(qty),
                    brand: findSku.brand,
                    quotedPrice: Number(quotedPrice),
                    garagePrice: Number(findSku.garagePrice),
                    savings
                };
                const arr = allParts.map((item, index) => {
                    if (index === position) {
                        item = skuItem;
                    }
                    return item;
                });
                setAllParts(arr);
                setOpenUpdateParts(!openUpdateParts);
                setSku('');
                setQty('');
                setQuotedPrice('');
                setPosition(0);
            } else {
                setPartsError('Quantity and your quoted price must be a number');
                setTimeout(() => setPartsError(''), 4000);
            }
        } else {
            setPartsError('All the field are required');
            setTimeout(() => setPartsError(''), 4000);
        }
    };

    async function getComparePrice(id, config) {
        try {
            const result = await (await fetch(`${BaseUrl}/compareprice/show/${id}`, config)).json();
            const json = result.data;
            // console.log(json);
            setAllVehicles(json.cars);
            setAllClaims(json.claims);
            setAllParts(json.items);
            setPolicyId(json.policyId);
            setCustomer(json.customer);
            setCustomerNumber(json.customerPhone);
        } catch (e) {
            console.log(e);
        }
    }

    async function submitForm() {
        setSubmitLoading(true);
        if (policyId && customer && customerNumber && allClaims.length > 0 && allVehicle.length > 0 && allParts.length > 0) {
            const claims = allClaims;
            const cars = allVehicle;
            const items = allParts;
            let savingsTotal = 0;
            let total = 0;
            // eslint-disable-next-line no-return-assign
            allParts.forEach(({ garagePrice, quotedPrice, qty }) => (savingsTotal += (Number(garagePrice) - Number(quotedPrice)) * qty));
            // eslint-disable-next-line no-return-assign
            allParts.forEach(({ qty, quotedPrice }) => (total += qty * quotedPrice));
            const totalOrderValue = total;
            const totalNewOrderValue = savingsTotal;
            const body = {
                id,
                policyId,
                customer,
                customerPhone: customerNumber,
                claims,
                cars,
                items,
                totalOrderValue,
                totalNewOrderValue
            };

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                },
                method: 'PUT',
                body: JSON.stringify(body)
            };
            try {
                // console.log(body);
                const result = await (await fetch(`${BaseUrl}/compareprice/update`, config)).json();
                const { status, message } = result;
                console.log(result);
                if (status && message === 'success') {
                    setSubmitLoading(false);
                    toast.success('Updated successfully');
                    // setFormSuccess('Updated successfully');
                    // setTimeout(() => setFormSuccess(''), 1000);
                    setTimeout(() => history('/comparePrice'), 6000);
                } else {
                    setSubmitLoading(false);
                    toast.error('Update failed');
                    // setFormError('Failed operation');
                    // setTimeout(() => setFormError(''), 4000);
                }
            } catch (e) {
                setSubmitLoading(false);
                toast.error('Network error');
                // setFormError('Server side error');
                // setTimeout(() => setFormError(''), 4000);
            }
        } else {
            setSubmitLoading(false);
            toast.error('Please fill the form before submitting');
            // setFormError('Please fill the form before submitting');
            // setTimeout(() => setFormError(''), 4000);
        }
    }

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        getComparePrice(id, config);

        async function getPolicy() {
            try {
                const result = await (await fetch(`${BaseUrl}/policyid/policyidactive`, config)).json();
                setPolicies(result.data);
            } catch (e) {
                setPolicies([]);
            }
        }
        getPolicy();

        async function getClaim() {
            try {
                const result = await (await fetch(`${BaseUrl}/claimid/claimid`, config)).json();
                setClaimsArr(result.data);
            } catch (e) {
                setClaimsArr([]);
            }
        }
        getClaim();

        async function getCarMake() {
            try {
                const result = await (await fetch(`${BaseUrl}/car/caractive`, config)).json();
                setCarMakeArr(result.data);
            } catch (e) {
                setCarMakeArr([]);
            }
        }
        getCarMake();

        async function getParts() {
            try {
                const result = await (await fetch(`${BaseUrl}/part/partsactive`, config)).json();
                setPartsArr(result.data);
            } catch (e) {
                setPartsArr([]);
            }
        }
        getParts();
    }, [id]);

    const claimTHeadString = ['Claim ID', 'Date Issued', 'Type', 'Amount Quoted', 'Service Provider', 'Due Date', 'Country/City', ''];
    const claimTHead = claimTHeadString.map((item, index) => (
        <React.Fragment key={index}>
            <th>{item}</th>
        </React.Fragment>
    ));

    const claimBody = allClaims.map((item, index) => (
        <React.Fragment key={index}>
            <tr>
                <td>{item.claimName}</td>
                <td>{new Date(item.dateIssued).toDateString()}</td>
                <td>{item.claimType}</td>
                <td>{item.amountQuoted}</td>
                <td>{item.serviceProvider}</td>
                <td>{new Date(item.dueDate).toDateString()}</td>
                <td>{item.countryCity}</td>
                <td>
                    {/* <button type="button" onClick={() => removeClaim(index)} style={{ padding: 'none', backgroundColor: 'transparent' }}>
                        <i className="fi fi-rr-trash red-text font-20 cursor-pointer" />
                    </button> */}
                    <button type="button" onClick={() => editClaim(index)} style={{ padding: 'none', backgroundColor: 'transparent' }}>
                        <span className="blue-text">
                            <EditIcon />
                        </span>
                    </button>

                    <button type="button" onClick={() => removeClaim(index)} style={{ padding: 'none', backgroundColor: 'transparent' }}>
                        <span className="red-text">
                            <DeleteIcon />
                        </span>
                    </button>
                </td>
            </tr>
        </React.Fragment>
    ));

    const vehicleTHeadString = ['Car Make', 'Car Model', 'Year', 'Licence Plate / Tags', 'Fuel Type', 'Engine Type', 'VIN', ''];
    const vehicleTHead = vehicleTHeadString.map((item, index) => (
        <React.Fragment key={index}>
            <th>{item}</th>
        </React.Fragment>
    ));

    const vehicleBody = allVehicle.map((item, index) => (
        <React.Fragment key={index}>
            <tr>
                <td>{item.carMake}</td>
                <td>{item.model}</td>
                <td>{item.year}</td>
                <td>{item.licensesplate}</td>
                <td>{item.fuelType}</td>
                <td>{item.engineType}</td>
                <td>{item.vins}</td>
                <td>
                    <button type="button" onClick={() => editVehicle(index)} style={{ padding: 'none', backgroundColor: 'transparent' }}>
                        {/* <i className="fi fi-rr-trash red-text font-20 cursor-pointer" /> */}
                        <span className="blue-text">
                            <EditIcon />
                        </span>
                    </button>

                    <button type="button" onClick={() => removeVehicle(index)} style={{ padding: 'none', backgroundColor: 'transparent' }}>
                        {/* <i className="fi fi-rr-trash red-text font-20 cursor-pointer" /> */}
                        <span className="red-text">
                            <DeleteIcon />
                        </span>
                    </button>
                </td>
            </tr>
        </React.Fragment>
    ));

    const partsTHeadString = ['SKU', 'Part', 'QTY', 'Brand', 'Quoted Price', '%Savings', ''];
    const partsTHead = partsTHeadString.map((item, index) => (
        <React.Fragment key={index}>
            <th>{item}</th>
        </React.Fragment>
    ));

    const partsBody = allParts.map((item, index) => (
        <React.Fragment key={index}>
            <tr>
                <td>{item.skuId}</td>
                <td>{item.partid}</td>
                <td>{item.qty}</td>
                <td>{item.brand}</td>
                <td>{item.quotedPrice} GHC</td>
                <td>
                    <span className={item.savings <= 0 ? 'red-text' : 'green-text'}>{item.savings === 0 ? `NA` : `${item.savings}%`}</span>
                </td>
                <td>
                    <button type="button" onClick={() => editParts(index)} style={{ padding: 'none', backgroundColor: 'transparent' }}>
                        {/* <i className="fi fi-rr-trash red-text font-20 cursor-pointer" /> */}
                        <span className="blue-text">
                            <EditIcon />
                        </span>
                    </button>

                    <button type="button" onClick={() => removeParts(index)} style={{ padding: 'none', backgroundColor: 'transparent' }}>
                        {/* <i className="fi fi-rr-trash red-text font-20 cursor-pointer" /> */}
                        <span className="red-text">
                            <DeleteIcon />
                        </span>
                    </button>
                </td>
            </tr>
        </React.Fragment>
    ));

    return (
        <>
            <PageHeader title="Update Compare Prices" subTitle="Home | Insurer | Compare Prices" />
            <div className="padding-all-20 add-compare-price-holder white-bg">
                <div className="garage-grey-text font-14">Customer Information</div>
                <br />
                <div className="row">
                    <div className="col-3 col-l-4 col-m-6 col-s-12 padding-all-10">
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="policy">Policy Id</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="policy-id"
                                    value={policyId}
                                    label="Policy Id"
                                    onChange={(e) => setPolicyId(e.target.value)}
                                >
                                    <MenuItem value="">Select an option</MenuItem>
                                    {policies.map(({ _id, name }, index) => (
                                        <MenuItem key={index} value={_id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <div className="col-3 col-l-4 col-m-6 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                value={customer}
                                onChange={(e) => setCustomer(e.target.value)}
                                id="outlined-basic"
                                label="Customer"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>
                    <div className="col-3 col-l-4 col-m-6 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                value={customerNumber}
                                onChange={(e) => setCustomerNumber(e.target.value)}
                                id="outlined-basic"
                                label="Customer phone number"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>
                </div>
                <br />

                <div className="garage-grey-text font-14">Claim Information</div>
                <div className="flex-row-reverse" style={{ marginBottom: '3px' }}>
                    <button type="button" onClick={() => handleOpenClaim()} className="compare-sm-btn garage-blue-bg white-text">
                        Add claim
                    </button>
                </div>
                <div className="overflow-auto">
                    <table className="table">
                        <thead>
                            <tr>{claimTHead}</tr>
                        </thead>
                        <tbody>{claimBody}</tbody>
                    </table>
                </div>
                <br />
                <br />

                <div className="garage-grey-text font-14">Vehicle Information</div>
                <div className="flex-row-reverse" style={{ marginBottom: '3px' }}>
                    <button type="button" onClick={() => handleOpenVehicle()} className="compare-sm-btn garage-blue-bg white-text">
                        Add vehicle
                    </button>
                </div>
                <div className="overflow-auto">
                    <table className="table">
                        <thead>
                            <tr>{vehicleTHead}</tr>
                        </thead>
                        <tbody>{vehicleBody}</tbody>
                    </table>
                </div>
                <br />
                <br />

                <div className="garage-grey-text font-14">Parts Information</div>
                <div className="flex-row-reverse" style={{ marginBottom: '3px' }}>
                    <button type="button" onClick={() => handleOpenParts()} className="compare-sm-btn garage-blue-bg white-text">
                        Add Parts
                    </button>
                </div>
                <div className="overflow-auto">
                    <table className="table">
                        <thead>
                            <tr>{partsTHead}</tr>
                        </thead>
                        <tbody>{partsBody}</tbody>
                    </table>
                </div>
                <br />
                <br />

                {/* <div>{formError ? <Alert severity="error">{formError}</Alert> : ''}</div>
                <div>{formSuccess ? <Alert severity="success">{formSuccess}</Alert> : ''}</div>
                <br /> */}

                <div className="flex-column justify-content-center align-items-center">{submitLoading ? <CircularProgress /> : ''}</div>

                <div className="row justify-content-space-between">
                    <div>
                        <button type="button" onClick={() => history('/comparePrice')} className="normal-btn red-bg white-text">
                            Reset
                        </button>
                    </div>
                    <div>
                        <button type="button" onClick={submitForm} className="garage-submit-btn">
                            {submitLoading ? 'Updating...' : 'Update Compare Price'}
                        </button>
                    </div>
                </div>
            </div>

            {/* Add new claim */}
            <Modal
                open={openClaim}
                onClose={handleOpenClaim}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="width-25 width-lx-35 width-l-45 width-m-70 width-s-85 height-100 flex-column justify-content-center align-items-center margin-auto">
                    <div className="width-100 height-5" />
                    <div className="width-90 height-90 padding-all-10 white-bg overflow-auto" style={{ borderRadius: '10px' }}>
                        <div className="black-text bold-text">Add Claim</div>
                        <br />
                        <form>
                            <div>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="claim">Claim Id</InputLabel>
                                        <Select
                                            labelId="claim-simple-select-label"
                                            id="claim-id"
                                            value={claim}
                                            label="claim Id"
                                            onChange={(e) => setClaim(e.target.value)}
                                        >
                                            <MenuItem value="">Select an option</MenuItem>
                                            {claimsArr.map(({ _id, name }, index) => (
                                                <MenuItem key={index} value={_id}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <br />
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Date Issued"
                                        value={claimDate}
                                        onChange={(newValue) => {
                                            setClaimDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        value={claimType}
                                        onChange={(e) => setClaimType(e.target.value)}
                                        id="outlined-basic"
                                        label="Type"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        type="number"
                                        value={amountQuoted}
                                        onChange={(e) => setAmountQuoted(e.target.value)}
                                        id="outlined-basic"
                                        label="Amount Quoted"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        value={serviceProvider}
                                        onChange={(e) => setServiceProvider(e.target.value)}
                                        id="outlined-basic"
                                        label="Service Provider"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Due Date"
                                        value={dueDate}
                                        onChange={(newValue) => {
                                            setDueDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        value={cityCountry}
                                        onChange={(e) => setCityCountry(e.target.value)}
                                        id="outlined-basic"
                                        label="City/Country"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>{claimError}</div>
                            <div className="padding-all-5" />
                            <div className="row justify-content-space-between">
                                <div>
                                    <button type="button" className="compare-submit-btn red-bg white-text" onClick={handleOpenClaim}>
                                        Cancel
                                    </button>
                                </div>
                                <div>
                                    <button type="button" className="compare-submit-btn garage-blue-bg white-text" onClick={addClaim}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div className="padding-all-20" />
                    </div>
                    <div className="width-100 height-5" />
                </div>
            </Modal>

            {/* Update claim modal */}
            <Modal
                open={openUpdateClaim}
                onClose={handleOpenUpdateClaim}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="width-25 width-lx-35 width-l-45 width-m-70 width-s-85 height-100 flex-column justify-content-center align-items-center margin-auto">
                    <div className="width-100 height-5" />
                    <div className="width-90 height-90 padding-all-10 white-bg overflow-auto" style={{ borderRadius: '10px' }}>
                        <div className="black-text bold-text">Update Claim</div>
                        <br />
                        <form>
                            <div>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="claim">Claim Id</InputLabel>
                                        <Select
                                            labelId="claim-simple-select-label"
                                            id="claim-id"
                                            value={claim}
                                            label="claim Id"
                                            onChange={(e) => setClaim(e.target.value)}
                                        >
                                            <MenuItem value="">Select an option</MenuItem>
                                            {claimsArr.map(({ _id, name }, index) => (
                                                <MenuItem key={index} value={_id}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <br />
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Date Issued"
                                        value={claimDate}
                                        onChange={(newValue) => {
                                            setClaimDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        value={claimType}
                                        onChange={(e) => setClaimType(e.target.value)}
                                        id="outlined-basic"
                                        label="Type"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        type="number"
                                        value={amountQuoted}
                                        onChange={(e) => setAmountQuoted(e.target.value)}
                                        id="outlined-basic"
                                        label="Amount Quoted"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        value={serviceProvider}
                                        onChange={(e) => setServiceProvider(e.target.value)}
                                        id="outlined-basic"
                                        label="Service Provider"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Due Date"
                                        value={dueDate}
                                        onChange={(newValue) => {
                                            setDueDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        value={cityCountry}
                                        onChange={(e) => setCityCountry(e.target.value)}
                                        id="outlined-basic"
                                        label="City/Country"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>{claimError}</div>
                            <div className="padding-all-5" />
                            <div className="row justify-content-space-between">
                                <div>
                                    <button type="button" className="compare-submit-btn red-bg white-text" onClick={handleOpenUpdateClaim}>
                                        Cancel
                                    </button>
                                </div>
                                <div>
                                    <button type="button" className="compare-submit-btn garage-blue-bg white-text" onClick={updateClaim}>
                                        Update
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div className="padding-all-20" />
                    </div>
                    <div className="width-100 height-5" />
                </div>
            </Modal>

            {/* Add new vehicle */}
            <Modal
                open={openVehicle}
                onClose={handleOpenVehicle}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="width-25 width-lx-35 width-l-45 width-m-70 width-s-85 height-100 flex-column justify-content-center align-items-center margin-auto">
                    <div className="width-100 height-5" />
                    <div className="width-90 height-90 padding-all-10 white-bg overflow-auto" style={{ borderRadius: '10px' }}>
                        <div className="black-text bold-text">Add Vehicle</div>
                        <br />
                        <form>
                            <div>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="claim">Car Make</InputLabel>
                                        <Select
                                            labelId="car-make-simple-select-label"
                                            id="car-make-id"
                                            value={carMake}
                                            label="Car Make"
                                            onChange={(e) => setCarMake(e.target.value)}
                                        >
                                            <MenuItem value="">Select an option</MenuItem>
                                            {carMakeArr.map(({ _id, name }, index) => (
                                                <MenuItem key={index} value={_id}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        value={carModel}
                                        onChange={(e) => setCarModel(e.target.value)}
                                        id="outlined-basic"
                                        label="Car Model"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        type="number"
                                        value={carYear}
                                        onChange={(e) => setCarYear(e.target.value)}
                                        id="outlined-basic"
                                        label="Car Year"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        value={licence}
                                        onChange={(e) => setLicence(e.target.value)}
                                        id="outlined-basic"
                                        label="License Plate / Tags"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="fuel">Fuel Type</InputLabel>
                                        <Select
                                            labelId="fuel-simple-select-label"
                                            id="fuel-id"
                                            value={fuel}
                                            label="Fuel Type"
                                            onChange={(e) => setFuel(e.target.value)}
                                        >
                                            <MenuItem value="">Select an option</MenuItem>
                                            <MenuItem value="Petrol">Petrol</MenuItem>
                                            <MenuItem value="Diesel">Diesel</MenuItem>
                                            <MenuItem value="Gas">Gas</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        value={engine}
                                        onChange={(e) => setEngine(e.target.value)}
                                        id="outlined-basic"
                                        label="Engine Type"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        value={vin}
                                        onChange={(e) => setVin(e.target.value)}
                                        id="outlined-basic"
                                        label="VIN"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>{vehicleError}</div>
                            <div className="padding-all-5" />
                            <div className="row justify-content-space-between">
                                <div>
                                    <button type="button" className="compare-submit-btn red-bg white-text" onClick={handleOpenVehicle}>
                                        Cancel
                                    </button>
                                </div>
                                <div>
                                    <button type="button" className="compare-submit-btn garage-blue-bg white-text" onClick={addVehicle}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div className="padding-all-20" />
                    </div>
                    <div className="width-100 height-5" />
                </div>
            </Modal>

            {/* Update vehicle */}
            <Modal
                open={openUpdateVehicle}
                onClose={handleOpenUpdateVehicle}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="width-25 width-lx-35 width-l-45 width-m-70 width-s-85 height-100 flex-column justify-content-center align-items-center margin-auto">
                    <div className="width-100 height-5" />
                    <div className="width-90 height-90 padding-all-10 white-bg overflow-auto" style={{ borderRadius: '10px' }}>
                        <div className="black-text bold-text">Update Vehicle</div>
                        <br />
                        <form>
                            <div>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="claim">Car Make</InputLabel>
                                        <Select
                                            labelId="car-make-simple-select-label"
                                            id="car-make-id"
                                            value={carMake}
                                            label="Car Make"
                                            onChange={(e) => setCarMake(e.target.value)}
                                        >
                                            <MenuItem value="">Select an option</MenuItem>
                                            {carMakeArr.map(({ _id, name }, index) => (
                                                <MenuItem key={index} value={_id}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        value={carModel}
                                        onChange={(e) => setCarModel(e.target.value)}
                                        id="outlined-basic"
                                        label="Car Model"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        type="number"
                                        value={carYear}
                                        onChange={(e) => setCarYear(e.target.value)}
                                        id="outlined-basic"
                                        label="Car Year"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        value={licence}
                                        onChange={(e) => setLicence(e.target.value)}
                                        id="outlined-basic"
                                        label="License Plate / Tags"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="fuel">Fuel Type</InputLabel>
                                        <Select
                                            labelId="fuel-simple-select-label"
                                            id="fuel-id"
                                            value={fuel}
                                            label="Fuel Type"
                                            onChange={(e) => setFuel(e.target.value)}
                                        >
                                            <MenuItem value="">Select an option</MenuItem>
                                            <MenuItem value="Petrol">Petrol</MenuItem>
                                            <MenuItem value="Diesel">Diesel</MenuItem>
                                            <MenuItem value="Gas">Gas</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        value={engine}
                                        onChange={(e) => setEngine(e.target.value)}
                                        id="outlined-basic"
                                        label="Engine Type"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        value={vin}
                                        onChange={(e) => setVin(e.target.value)}
                                        id="outlined-basic"
                                        label="VIN"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>{vehicleError}</div>
                            <div className="padding-all-5" />
                            <div className="row justify-content-space-between">
                                <div>
                                    <button
                                        type="button"
                                        className="compare-submit-btn red-bg white-text"
                                        onClick={handleOpenUpdateVehicle}
                                    >
                                        Cancel
                                    </button>
                                </div>
                                <div>
                                    <button type="button" className="compare-submit-btn garage-blue-bg white-text" onClick={updateVehicle}>
                                        Update
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div className="padding-all-20" />
                    </div>
                    <div className="width-100 height-5" />
                </div>
            </Modal>

            {/* Add new Parts */}
            <Modal
                open={openParts}
                onClose={handleOpenParts}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="width-25 width-lx-35 width-l-45 width-m-70 width-s-85 height-100 flex-column justify-content-center align-items-center margin-auto">
                    <div className="width-100 height-5" />
                    <div
                        className="width-90 padding-all-10 white-bg overflow-auto"
                        style={{ borderRadius: '10px', maxHeight: '90vh', minHeight: '40vh' }}
                    >
                        <div className="black-text bold-text">Add Parts</div>
                        <br />
                        <form>
                            <div>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="claim">SKU</InputLabel>
                                        <Select
                                            labelId="sku-simple-select-label"
                                            id="sku-id"
                                            value={sku}
                                            label="SKU"
                                            onChange={(e) => setSku(e.target.value)}
                                        >
                                            <MenuItem value="" />
                                            {partsArr.map(({ _id, sku }, index) => (
                                                <MenuItem key={index} value={_id}>
                                                    {sku}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        type="number"
                                        value={qty}
                                        onChange={(e) => setQty(e.target.value)}
                                        id="outlined-basic"
                                        label="QTY"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        type="number"
                                        value={quotedPrice}
                                        onChange={(e) => setQuotedPrice(e.target.value)}
                                        id="outlined-basic"
                                        label="Quoted Price"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>{partsError}</div>
                            <div className="padding-all-5" />
                            <div className="row justify-content-space-between">
                                <div>
                                    <button type="button" className="compare-submit-btn red-bg white-text" onClick={handleOpenParts}>
                                        Cancel
                                    </button>
                                </div>
                                <div>
                                    <button type="button" className="compare-submit-btn garage-blue-bg white-text" onClick={addParts}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div className="padding-all-20" />
                    </div>
                    <div className="width-100 height-5" />
                </div>
            </Modal>

            {/* Update Parts */}
            <Modal
                open={openUpdateParts}
                onClose={handleOpenUpdateParts}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="width-25 width-lx-35 width-l-45 width-m-70 width-s-85 height-100 flex-column justify-content-center align-items-center margin-auto">
                    <div className="width-100 height-5" />
                    <div
                        className="width-90 padding-all-10 white-bg overflow-auto"
                        style={{ borderRadius: '10px', maxHeight: '90vh', minHeight: '40vh' }}
                    >
                        <div className="black-text bold-text">Update Parts</div>
                        <br />
                        <form>
                            <div>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="claim">SKU</InputLabel>
                                        <Select
                                            labelId="sku-simple-select-label"
                                            id="sku-id"
                                            value={sku}
                                            label="SKU"
                                            onChange={(e) => setSku(e.target.value)}
                                        >
                                            <MenuItem value="" />
                                            {partsArr.map(({ _id, sku }, index) => (
                                                <MenuItem key={index} value={_id}>
                                                    {sku}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        type="number"
                                        value={qty}
                                        onChange={(e) => setQty(e.target.value)}
                                        id="outlined-basic"
                                        label="QTY"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        type="number"
                                        value={quotedPrice}
                                        onChange={(e) => setQuotedPrice(e.target.value)}
                                        id="outlined-basic"
                                        label="Quoted Price"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>{partsError}</div>
                            <div className="padding-all-5" />
                            <div className="row justify-content-space-between">
                                <div>
                                    <button type="button" className="compare-submit-btn red-bg white-text" onClick={handleOpenUpdateParts}>
                                        Cancel
                                    </button>
                                </div>
                                <div>
                                    <button type="button" className="compare-submit-btn garage-blue-bg white-text" onClick={updateParts}>
                                        Update
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div className="padding-all-20" />
                    </div>
                    <div className="width-100 height-5" />
                </div>
            </Modal>
        </>
    );
};

export default AddComparePrice;
