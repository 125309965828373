import { Table, Checkbox, Popover, List, Button, Input, Select, DatePicker, Space } from 'antd';
import { Box, Paper, Typography, Divider, ListItem } from '@mui/material';
import React, { useState, useEffect } from 'react';
import PageHeader from 'components/PageHeader';
import { encrypt, decrypt } from '../../utils/encrypt';
import './viewCheckIn.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useAppContext } from '../../context/appContext';
import { ToastContainer, toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { DownloadOutlined } from '@ant-design/icons';
import ApiUrl from '../../network';

const columns = [
    {
        title: 'Fisrt Name',
        dataIndex: 'firstName',
        key: 'firstName'
    },
    {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName'
    },
    {
        title: 'Location',
        dataIndex: 'location',
        key: 'location'
    },
    {
        title: 'Checked In ',
        dataIndex: 'checkin',
        key: 'checkin',
        width: '23%'
    },
    {
        title: 'Country',
        dataIndex: 'country',
        key: 'country'
    },
    {
        title: 'Latitude',
        dataIndex: 'lat',
        key: 'lat'
    },
    {
        title: 'Longitude',
        dataIndex: 'lng',
        key: 'lng'
    },

    {
        title: 'IP Address',
        dataIndex: 'ipAddress',
        key: 'ipAddress'
    },

    {
        title: 'Reason',
        dataIndex: 'reason',
        key: 'reason'
    }
];

const ViewCheckIn = () => {
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [check, setCheck] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [locationList, setLocationList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [user, setUser] = useState(null);
    const [data, setData] = useState([]); // State to store the original data
    const [filteredData, setFilteredData] = useState([]);
    const [limit, setLimit] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [filterButton, setFilterButton] = useState(false);
    const [countries, setCountries] = useState([]);
    const [accumulatedFilters, setAccumulatedFilters] = useState({});

    const limitNumber = 100;
    const { RangePicker } = DatePicker;

    const fetchCountries = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(`${ApiUrl.BaseUrl}/country/activecountry`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error('Something went wrong');
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        weekday: 'long'
    };

    const checkOptions = check?.map((result) => ({
        value: result?._id,
        key: result?._id,
        firstName: result?.userId?.firstName,
        lastName: result?.userId?.lastName,
        location: result?.stageId?.name,
        country: result?.stageId?.country,
        lat: result?.stageId?.lat,
        lng: result?.stageId?.lng,
        ipAddress: result?.ipAddress,
        checkin: new Date(result?.createdAt).toLocaleDateString('en-US', options),
        reason: result?.reason
    }));

    // get All checkins
    const checkin = async (data) => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/checkin/allcheckins`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            // if (data) {
            //     if (data.startDate) urlParams.append('startDate', data.startDate);
            //     if (data.endDate) urlParams.append('endDate', data.endDate);
            //     if (data.country) urlParams.append('country', data.country);
            //     if (data.stage) urlParams.append('stage', data.stage);
            //     if (data.page) urlParams.append('page', data.page);
            //     if (data.limit) urlParams.append('limit', data.limit);
            //     if (data.user) urlParams.append('user', data.user);
            // }

            // Append accumulated filters to URL
            Object.entries(accumulatedFilters).forEach(([key, value]) => {
                if (value) {
                    urlParams.append(key, value);
                }
            });

            url = `${url}?${urlParams.toString()}`;

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setCheck(answer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                        toast.error(answer);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchCountries();
        checkin();
    }, [accumulatedFilters]);

    // get area locations
    useEffect(() => {
        const locations = async () => {
            try {
                const userInfo = localStorage.getItem('userToken');
                const config = {
                    headers: {
                        'auth-token': userInfo,
                        'Content-Type': 'application/json'
                    }
                };
                fetch(`${ApiUrl.BaseUrl}/checkin/checkinlocation`, config)
                    .then((data) => data.json())
                    .then((data) => {
                        if (data.status === true) {
                            const result = decrypt(data.data, keyResult, ivResult);
                            const answer = JSON.parse(result);
                            setLocationList(answer);
                        } else {
                            const error = decrypt(data.data, keyResult, ivResult);
                            const answer = JSON.parse(error);
                        }
                    })
                    .finally(() => {
                        // setLoading(false);
                    });
            } catch (e) {
                console.log(e);
            }
        };
        locations();
    }, []);

    const locationOptions = locationList.map((result) => ({
        value: result.name,
        label: result.name
    }));

    // get all checkin users
    useEffect(() => {
        const allCheckinUser = async () => {
            try {
                const userInfo = localStorage.getItem('userToken');
                const config = {
                    headers: {
                        'auth-token': userInfo,
                        'Content-Type': 'application/json'
                    }
                };
                await fetch(`${ApiUrl.BaseUrl}/checkin/allusers`, config)
                    .then((data) => data.json())
                    .then((data) => {
                        if (data.status === true) {
                            const result = decrypt(data.data, keyResult, ivResult);
                            const answer = JSON.parse(result);
                            setUsersList(answer);
                        } else {
                            const error = decrypt(data.data, keyResult, ivResult);
                            const answer = JSON.parse(error);
                        }
                    })
                    .finally(() => {
                        // setLoading(false);
                    });
            } catch (e) {
                console.log(e);
            }
        };
        allCheckinUser();
    }, []);

    const checkinUserOptions = usersList?.map((item) => {
        return { label: `${item?.firstName} ${item?.lastName}`, value: item?._id };
    });

    const countryOptions = countries?.map((result) => ({
        value: result._id,
        label: result.name
    }));

    const countryOption = countryOptions.map((item, index) => {
        return { value: item.label, label: item.label };
    });

    const limitOptions = [
        {
            label: '100',
            value: limitNumber * 1
        },
        {
            label: '200',
            value: limitNumber * 2
        },
        {
            label: '300',
            value: limitNumber * 3
        },
        {
            label: '400',
            value: limitNumber * 4
        },
        {
            label: '500',
            value: limitNumber * 5
        },
        {
            label: '600',
            value: limitNumber * 6
        },
        {
            label: '700',
            value: limitNumber * 7
        },
        {
            label: '800',
            value: limitNumber * 8
        },
        {
            label: '900',
            value: limitNumber * 9
        },
        {
            label: '1000',
            value: limitNumber * 10
        }
    ];

    const handleLimit = (value) => {
        setLimit(value);
    };

    const clearFilter = () => {
        setSelectedCountry(null);
        setSelectedLocation(null);
        setSelectedDate(null);
        setUser(null);
        setLimit(null);
        checkin();
        setFilterButton(false);
        setAccumulatedFilters([]);
    };

    const applyFilterHanlder = () => {
        setFilterButton(true);
        const locationId = locationList?.find((item) => item.name === selectedLocation);
        const data = {
            country: selectedCountry,
            stage: locationId?._id,
            startDate: selectedDate ? selectedDate[0].format('YYYY-MM-DD') : null,
            endDate: selectedDate ? selectedDate[1].format('YYYY-MM-DD') : null,
            limit,
            user
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setFilterButton(true);
            const locationId = locationList?.find((item) => item.name === selectedLocation);
            const data = {
                country: selectedCountry,
                stage: locationId?._id,
                startDate: selectedDate ? selectedDate[0].format('YYYY-MM-DD') : null,
                endDate: selectedDate ? selectedDate[1].format('YYYY-MM-DD') : null,
                limit,
                user
            };
            setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
        }
    };

    useEffect(() => {
        document.addEventListener('keyup', handleKeyPress);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('keyup', handleKeyPress);
        };
    }, [selectedCountry, selectedLocation, selectedDate, limit, user]);

    // download list
    const { handleExcelDataExport } = useAppContext();
    const downloadExcel = () => {
        const checkinFile = checkOptions.map((obj) => {
            return Object.fromEntries(Object.entries(obj).filter(([key]) => key !== 'key' && key !== 'value'));
        });

        if (checkinFile?.length === 0) return;

        handleExcelDataExport(checkinFile, 'Checkinlist');
    };

    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <br />
            <PageHeader title="Check In" subTitle="Home | Check in " />
            <br />
            <br />

            <div className="filter-container">
                <Select
                    placeholder="Country"
                    showSearch
                    allowClear
                    size="large"
                    style={{
                        width: '300px',
                        margin: '0.625rem'
                    }}
                    onChange={(value) => setSelectedCountry(value)}
                    filterOption={(inputValue, option) => {
                        if (typeof option.label === 'string') {
                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        }

                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                    }}
                    value={selectedCountry}
                    options={countryOption}
                    onKeyUp={handleKeyPress}
                />
                <Select
                    placeholder="Name"
                    showSearch
                    allowClear
                    size="large"
                    style={{
                        width: '300px',
                        margin: '0.625rem'
                    }}
                    options={checkinUserOptions}
                    filterOption={(inputValue, option) => {
                        if (typeof option.label === 'string') {
                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        }

                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                    }}
                    value={user}
                    onChange={(value) => setUser(value)}
                    onKeyUp={handleKeyPress}
                />
                <Select
                    placeholder="Location"
                    showSearch
                    allowClear
                    size="large"
                    style={{
                        width: '300px',
                        margin: '0.625rem'
                    }}
                    options={locationOptions}
                    filterOption={(inputValue, option) => {
                        if (typeof option.label === 'string') {
                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        }

                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                    }}
                    value={selectedLocation}
                    onChange={(value) => setSelectedLocation(value)}
                    onKeyUp={handleKeyPress}
                />
                <Select
                    placeholder="Limit"
                    showSearch
                    allowClear
                    size="large"
                    style={{
                        width: '300px',
                        margin: '0.625rem'
                    }}
                    value={limit}
                    onChange={handleLimit}
                    options={limitOptions}
                    onKeyUp={handleKeyPress}
                />
                <RangePicker
                    format="DD/MM/YYYY"
                    size="large"
                    style={{
                        width: '300px',
                        margin: '0.625rem'
                    }}
                    value={selectedDate}
                    onChange={(value) => setSelectedDate(value)}
                    onKeyUp={handleKeyPress}
                />
                <Button
                    className="mt-2 mb-2 d-flex align-items-center"
                    onClick={applyFilterHanlder}
                    title="Apply Filters"
                    style={{
                        backgroundColor: '#F5880E',
                        borderRadius: '3.875rem',
                        height: '40px',
                        padding: '0',
                        width: '9rem',

                        margin: '0.625rem'
                    }}
                >
                    <Typography sx={{ margin: '0 14px ', color: '#fff' }}>&nbsp;Apply Filters</Typography>
                    <QueryStatsIcon sx={{ fontSize: '1rem', color: '#ffff' }} />
                </Button>
                {filterButton && (
                    <Button
                        className="mt-2 mb-2 d-flex align-items-center"
                        onClick={clearFilter}
                        title="Apply Filters"
                        style={{
                            backgroundColor: '#0A2938',
                            borderRadius: '3.875rem',
                            height: '40px',
                            padding: '0',
                            width: '9rem',
                            margin: '0.625rem'
                        }}
                    >
                        <Typography sx={{ margin: '0 14px ', color: '#fff' }}>&nbsp;Reset Filters</Typography>
                        <HighlightOffIcon sx={{ fontSize: '1rem', color: '#ffff' }} />
                    </Button>
                )}
            </div>
            <br />
            <br />
            <div>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Table
                        title={() => (
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                                <Button type="primary" icon={<DownloadOutlined />} size="medium" onClick={downloadExcel}>
                                    Download
                                </Button>
                            </Box>
                        )}
                        dataSource={checkOptions}
                        columns={columns}
                        scroll={{
                            x: 'calc(700px + 50%)',
                            y: 480
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default ViewCheckIn;
