import { Link, useParams } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Button, Checkbox, Divider, Modal, Popover, Select, Spin } from 'antd';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ApiUrl from '../../../network';
import { decrypt, encrypt } from '../../../utils/encrypt';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const [notes, setNotes] = useState([]);
    const [note, setNote] = useState('');
    const [sourceOptions, setSourceOptions] = useState([]);
    const [source, setSource] = useState('');
    const [country, setCountry] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [summaryModal, setSummaryModal] = useState(false);
    const [summaryNote, setSummaryNotes] = useState({});
    const { id } = useParams();
    // eslint-disable-next-line consistent-return
    const fetchAllNotes = async (id) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(`${ApiUrl.BaseUrl}/crm/fetch-partner-notes?retailer_id=${id}`, config);
            await response.json().then((data) => {
                if (data.status === false) {
                    toast.error(data.message);
                    setLoading(false);
                } else {
                    setNotes(JSON.parse(decrypt(data.data, keyResult, ivResult)));
                    setLoading(false);
                }
            });
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };
    // eslint-disable-next-line consistent-return
    const fetchSummaryNotes = async (id) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(`${ApiUrl.BaseUrl}/llm/customer-notes-insight?retailer_id=${id}`, config);
            await response.json().then((data) => {
                if (data.status === false) {
                    toast.error(data.message);
                    setLoading(false);
                } else {
                    setSummaryNotes(data);
                    setLoading(false);
                }
            });
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };
    useEffect(() => {
        // eslint-disable-next-line consistent-return
        const getSource = async () => {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(`${ApiUrl.BaseUrl}/crm/get-contact-sources`, config);
            if (!response.ok) {
                toast.error('Failed to fetch source');
            }
            const data = await response.json();
            setSourceOptions(JSON.parse(decrypt(data.data, keyResult, ivResult)));
        };

        if (id) {
            fetchAllNotes(id);
            getSource();
            fetchSummaryNotes(id);
        }
    }, [id]);

    // eslint-disable-next-line consistent-return
    const handleAddNote = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const datas = {
                retailer_id: id,
                note,
                source,
                country: localStorage.getItem('country')
            };
            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);

            const response = await fetch(`${ApiUrl.BaseUrl}/crm/store-note`, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify({ payload })
            });
            await response.json().then((data) => {
                if (data.status === false) {
                    toast.error(data.message);
                } else {
                    toast.success('Note added successfully');
                    setNote('');
                    setSource('');
                    setCountry('');
                    fetchAllNotes(id);
                }
            });
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
            } else {
                toast.error('An error occurred');
            }
        }
    };

    return (
        <div className="leadCrmContainer">
            <div className="d-flex justify-content-space-between align-items-center salesCardHolder">
                <Link to={`/salescrm/${id}`} style={{ color: '#0A2938' }}>
                    <p style={{ fontSize: '30px' }}>
                        {' '}
                        <KeyboardBackspaceIcon style={{ marginRight: '5px' }} /> Notes
                    </p>
                </Link>
                {summaryNote?.insight ? (
                    <Button
                        style={{
                            backgroundColor: '#FF740F',
                            color: '#fff',
                            marginLeft: '10px',
                            width: '150px',
                            textAlign: 'center'
                        }}
                        onClick={() => setSummaryModal(true)}
                    >
                        View summary
                    </Button>
                ) : null}
            </div>
            <Divider style={{ margin: '20px 0', border: '1px dash #e8e8e8' }} />
            <div>
                <div
                    style={{
                        backgroundColor: '#FFF',
                        border: '1px solid #FFBA87',
                        padding: '10px',
                        borderRadius: '10px',
                        margin: '10px 0',
                        position: 'relative',
                        marginBottom: '20px',
                        height: '200px'
                    }}
                >
                    <textarea
                        placeholder="Add note here"
                        style={{
                            width: '100%',
                            height: '100%',
                            padding: '10px',
                            borderRadius: '10px',
                            backgroundColor: '#FFF',
                            color: '#3B5460',
                            position: 'relative',
                            marginBottom: '10px',
                            border: 'none',
                            resize: 'none',
                            outline: 'none'
                        }}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                    />
                    <Popover
                        placement="bottomLeft"
                        content={
                            <div style={{ padding: '20px' }}>
                                {sourceOptions.map((option, index) => (
                                    <div key={option._id}>
                                        <Checkbox
                                            onChange={(e) => setSource(e.target.checked ? option.name : '')}
                                            checked={source === option.name}
                                            style={{ marginRight: '10px' }}
                                        >
                                            {' '}
                                            {option.name}{' '}
                                        </Checkbox>
                                        <Divider style={{ margin: '20px 0', border: '1px dash #e8e8e8' }} />
                                    </div>
                                ))}

                                {source ? (
                                    <Button
                                        style={{
                                            backgroundColor: '#FF740F',
                                            color: '#fff',
                                            padding: '20px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '100%'
                                        }}
                                        onClick={handleAddNote}
                                    >
                                        Save note
                                    </Button>
                                ) : null}
                            </div>
                        }
                    >
                        <Button
                            style={{
                                backgroundColor: '#fff',
                                color: '#FF740F',
                                border: '1px solid #FF740F',
                                padding: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                bottom: '0',
                                right: '20px',
                                position: 'absolute',
                                width: '120px',
                                marginBottom: '10px'
                            }}
                        >
                            Add source <DownOutlined className="ml-2" />
                        </Button>
                    </Popover>
                </div>
            </div>
            {isLoading ? (
                <div className="text-center mt-4">
                    Notes loading..
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 24
                                }}
                                spin
                            />
                        }
                    />
                </div>
            ) : (
                <div className="d-flex flex-wrap">
                    {notes.map((note, index) => (
                        <div
                            key={note._id}
                            className="mb-4 flex-grow-1 mr-2"
                            style={{
                                backgroundColor: '#FFF',
                                padding: '20px',
                                borderRadius: '10px',
                                margin: '10px',
                                width: 'calc(50% - 20px)', // Set width for two columns with margin
                                boxSizing: 'border-box',
                                position: 'relative'
                            }}
                        >
                            <p
                                style={{
                                    fontSize: '1rem',
                                    marginBottom: '10px',
                                    color: '#3B5460',
                                    position: 'relative'
                                }}
                            >
                                {note.note}
                            </p>
                            <div
                                style={{
                                    color: '#85949C',
                                    fontSize: '10px',
                                    bottom: '10px',
                                    left: '20px',
                                    right: '20px',
                                    position: 'absolute',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginTop: '20px'
                                }}
                            >
                                <span>Source: {note.source}</span>
                                <div>
                                    <span style={{ marginLeft: '4px', marginRight: '4px' }}>
                                        Added by: {note.createdBy?.firstName} {note.createdBy?.lastName}
                                    </span>
                                    <span>{new Date(note.createdAt).toLocaleDateString()}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <Modal
                title="Summary"
                submitText="This is a summary of all the notes from “Retailer’s name”"
                open={summaryModal}
                onCancel={() => setSummaryModal(false)}
                footer={null}
            >
                <div className="p-4">
                    <p>{summaryNote?.insight}</p>
                </div>
            </Modal>
        </div>
    );
};

export default Index;
