import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
// import { notification } from 'antd';
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import useScriptRef from 'hooks/useScriptRef';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';

const ViewFleetEmail = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [status, setStatus] = useState('');
    const history = useNavigate();
    const scriptedRef = useScriptRef();

    const handleChange = (event) => {
        setStatus(event.target.value);
    };
    const { id } = useParams();

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.ViewFleetEmailOrder + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setEmail(result?.data?.email ?? '');
                    setStatus(result?.data?.status ?? '');
                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                }
            })
            .finally(() => {
                setLoading2(false);
            });
    }, [id]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');

        // const channelPartnerId = localStorage.getItem('channelpartnerId');
        const data = {
            id,
            email,
            status
        };
        setLoading(true);
        fetch(ApiUrl.UpdateFleetEmailOrder, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading(false);
                    setTimeout(() => {
                        if (scriptedRef.current) {
                            history('/fleetemailorder');
                        }
                    }, 3000);
                } else {
                    toast.error(result.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid item xs={12} sm={6}>
                        <PageHeader title="Emails" subTitle="Home | Email" />
                        <MainCard title="Edit Email">
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    autoComplete="off"
                                    name="email"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    value={email}
                                    label="Email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    autoFocus
                                />
                                <br />
                                <br />

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={status}
                                        label="Status"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Active">Active</MenuItem>
                                        <MenuItem value="InActive">InActive</MenuItem>
                                    </Select>
                                </FormControl>
                                <br />
                                <br />
                                <Box display="flex" justifyContent="space-between">
                                    <Button component={Link} to="/fleetemailorder" type="submit" variant="contained" color="error">
                                        Reset
                                    </Button>

                                    <Button type="submit" variant="contained" color="secondary" disabled={!email}>
                                        {loading ? 'Updating ..' : 'Update'}
                                    </Button>
                                </Box>
                            </form>
                        </MainCard>
                    </Grid>
                </>
            )}
        </>
    );
};
export default ViewFleetEmail;
