import { Button, Divider, Input, Select, Spin, Table, Tag } from 'antd';
import { LoadingOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import ApiUrl from '../../network';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { decrypt } from '../../utils/encrypt';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const [retailers, setRetailers] = useState([]); // eslint-disable-line no-unused-vars
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState('Priority level');
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        // eslint-disable-next-line consistent-return
        const fetchRetailers = async () => {
            try {
                setLoading(true);
                const userInfo = localStorage.getItem('userToken');
                if (!userInfo) {
                    toast.error('User not authenticated');
                    return false;
                }
                const config = {
                    headers: {
                        'auth-token': userInfo,
                        'Content-Type': 'application/json'
                    }
                };

                const response = await fetch(`${ApiUrl.BaseUrl}/crm/get-channel-partners`, config);
                if (!response.ok) {
                    toast.error('Failed to fetch retailers');
                }
                const data = await response.json();
                const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setRetailers(result);
                setLoading(false);
            } catch (error) {
                if (error instanceof TypeError) {
                    toast.error('Network error occurred');
                    setLoading(false);
                } else {
                    toast.error('An error occurred');
                    setLoading(false);
                }
            }
        };
        fetchRetailers();

        return () => {
            // cleanup function
            setLoading(false);
        };
    }, []);

    const filteredRetailers = useMemo(() => {
        if (!search && status === 'Priority level' && !status) return retailers;
        return retailers.filter((retailer) => {
            if (search && status === 'Priority level') {
                return retailer.partnerName.toLowerCase().includes(search.toLowerCase());
            }
            if (status === 'Priority level' && !search) {
                return retailer;
            }
            if (status && !search) {
                return retailer.rating === status;
            }
            if (status && search) {
                return retailer.partnerName.toLowerCase().includes(search.toLowerCase()) && retailer.rating === status;
            }
            return retailer;
        });
    }, [retailers, search, status]);

    const columns = [
        {
            title: 'Lead’s name',
            dataIndex: 'partnerName',
            key: 'partnerName'
        },
        {
            title: 'Priority level',
            dataIndex: 'rating',
            key: 'rating',
            render: (text) => {
                if (text === 'High') {
                    return <Tag color="green">{text}</Tag>;
                }
                if (text === 'Medium') {
                    return <Tag color="orange">{text}</Tag>;
                }
                if (text === 'Low') {
                    return <Tag color="red">{text}</Tag>;
                }
                return <Tag color="gray">No priority</Tag>;
            }
        },
        {
            title: 'Generated by',
            dataIndex: 'createdBy',
            key: 'createdBy',
            render: (_, record) => (
                <span>{record?.createdBy?.firstName ? `${record?.createdBy?.firstName} ${record?.createdBy?.lastName}` : 'N/A'}</span>
            )
        },
        {
            title: 'Date generated',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text) => <span>{new Date(text).toDateString()}</span>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => (
                <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: '#FF740F',
                        cursor: 'pointer',
                        fontSize: '16px',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                    onClick={() => {
                        window.location.href = `/salescrm/${record._id}`;
                    }}
                >
                    <span>View profile</span> <RightOutlined style={{ fontSize: '12px', marginLeft: '5px' }} />
                </button>
            )
        }
    ];
    return (
        <div className="leadCrmContainer">
            <div className="d-flex justify-content-space-between align-items-center leadHeaders">
                <div>
                    <h2>
                        {' '}
                        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                        Lead’s journey
                    </h2>
                    <p>Track the life cycle of our leads</p>
                </div>
                <div className="d-flex align-items-center justify-content-space-between leadHeaders">
                    <Input
                        addonBefore={<SearchOutlined />}
                        placeholder="Search leads"
                        className="mr-2 leadHeadersInput"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                    />
                    <Select
                        placeholder="Priority level"
                        style={{ width: 200 }}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(inputValue, option) => {
                            if (typeof option.label === 'string') {
                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }

                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        }}
                        options={[
                            { label: 'High', value: 'High' },
                            { label: 'Medium', value: 'Medium' },
                            { label: 'Low', value: 'Low' }
                        ]}
                        onChange={(value) => setStatus(value)}
                        value={status}
                        className="leadHeadersInput"
                    />
                    <Button
                        style={{
                            backgroundColor: '#FF740F',
                            color: '#fff',
                            marginLeft: '10px',
                            width: '150px',
                            textAlign: 'center'
                        }}
                        onClick={() => (window.location.href = '/salescrm/analysis')}
                    >
                        View analytics
                    </Button>
                </div>
            </div>
            <Divider style={{ margin: '20px 0', border: '1px dash #e8e8e8' }} />
            <div
                style={{
                    backgroundColor: '#fff',
                    borderRadius: '10px'
                }}
                className="table-responsive"
            >
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                        <Spin
                            indicator={
                                <LoadingOutlined
                                    style={{
                                        fontSize: 34
                                    }}
                                    spin
                                />
                            }
                        />
                    </div>
                ) : (
                    <Table
                        className="table"
                        columns={columns}
                        dataSource={filteredRetailers}
                        rowKey={(record) => record._id}
                        pagination={{ position: ['bottomRight'] }}
                    />
                )}
            </div>
        </div>
    );
};
export default Index;
