/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';
// import Controls from '../../components/controls/Controls';
// import { Search } from '@mui/icons-material';
// import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import { Col } from 'react-grid-system';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import Chip from '@mui/material/Chip';
// import { Link } from 'react-router-dom';
// import swal from 'sweetalert';
// import * as XLSX from 'xlsx';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import ViewIcon from '@mui/icons-material/ViewAgenda';
import IconButton from '@mui/material/IconButton';
// import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
// import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
// import { saveAs } from 'file-saver';
// import axios from 'axios';
// import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Form from 'react-bootstrap/Form';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
// import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    colors: {
        color: `#ED7C1B`
    }
}));

const Groups = () => {
    const classes = useStyles();
    // const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [orderInfo, setOrder] = useState([]);
    // const [orderData, setOrderData] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [loading2, setLoading2] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [name, setname] = useState('');
    const [id, setId] = useState('');
    // const [type, settype] = useState('');
    // const [startDate, setstartDate] = useState(new Date());
    // const [endDate, setendDate] = useState(new Date());
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [personData, setpersonData] = useState([]);
    const [retailerInfo, setRetailer] = useState([]);
    const [searched, setSearched] = useState('');
    const [indexValue, setIndexValue] = useState('');
    // const [balance, setBlance] = useState('');
    // const country = localStorage.getItem('country');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 30));
        setPage(0);
    };

    function toTimestamp(strDate) {
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderInfo.length) : 0;

    const retrieveAllOrders = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.MessageGroupsUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        // console.log('data', data);
                        setOrder(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const handleChange = (event) => {
        // console.log('event', event);
        setpersonData(event);
        // console.log('personPhone', personPhone);

        // const {
        //     target: { value }
        // } = event;
        // setPersonPhone(
        //     // On autofill we get a stringified value.
        //     typeof value === 'string' ? value.split(',') : value
        // );
    };

    const retrieveAllRetail = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            setLoading(true);
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(ApiUrl.RetailerMessageUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        // console.log('data', data.data);
                        setRetailer(data.data);

                        setLoading(false);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        retrieveAllOrders();
        retrieveAllRetail();
    }, []);

    const handleEditClick = (row, index) => {
        console.log(row);
        setpersonData(row?.personData ?? []);
        setname(row?.name ?? '');
        setId(row?._id ?? '');
        handleClickOpen2();
        setIndexValue(index);
    };

    const onSubmitMessage = (event) => {
        event.preventDefault();
        const data = {
            name,
            personData
        };
        const userInfo = localStorage.getItem('userToken');

        setLoading(true);
        fetch(ApiUrl.MessageAddGroupsUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                handleClose();
                if (result.status) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            retrieveAllOrders();
                            // history('/allmessages');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                // setLoading(false);
            });
    };

    const onSubmitMessageUpdate = (event) => {
        event.preventDefault();
        const data = {
            id,
            name,
            personData
        };
        const userInfo = localStorage.getItem('userToken');

        setLoading(true);
        fetch(ApiUrl.MessageUpdateGroupUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                handleClose2();
                if (result.status) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            retrieveAllOrders();
                            // history('/allmessages');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                // setLoading(false);
            });
    };

    const filteredRows = orderInfo.filter((row) => (row.name || '').toLowerCase().includes(searched.toLowerCase()));
    const isPlural = filteredRows && filteredRows.length > 1 ? 's' : '';
    const searchCount = searched ? `${filteredRows.length} result${isPlural} found` : 'No Results';

    // const handleSearch = (event) => {
    //     const searchWord = event.target.value;

    //     const newFilter = orderInfo.filter((value) => (value.to || '').toLowerCase().includes((searchWord || '').toLowerCase()));

    //     if (searchWord === '') {
    //         setOrder(orderData);
    //     } else {
    //         setOrder(newFilter);
    //     }
    // };

    return (
        <>
            <br />
            <PageHeader title="Groups" subTitle="Home | All Groups " />
            <br />
            <br />
            <MainCard title="All Groups List">
                <h4 style={{ color: '#ff740f', fontWeight: '700' }}>{searched ? searchCount : `Total Groups: ${orderInfo.length}`}</h4>

                <br />
                <Box display="flex" justifyContent="flex-start">
                    {/* <Controls.Input
                        label="Search To"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearch}
                    /> */}
                    <Col xs={12} xl={3} lg={3} md={6} sm={6}>
                        <FormControl fullWidth style={{ marginBottom: '10px' }}>
                            <TextField
                                id="outlined-basic"
                                label="Search Group Name"
                                variant="outlined"
                                onChange={(e) => setSearched(e.target.value)}
                            />
                        </FormControl>
                    </Col>
                    <Box width={20} />
                    <Button variant="contained" onClick={handleClickOpen} size="sm" className={classes.item} color="secondary">
                        Add Group
                    </Button>
                </Box>
                <br />

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Date
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Name
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Created By
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Action
                                        </TableCell>

                                        {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell> */}
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : filteredRows
                            ).map((row, index) => (
                                <TableRow key={row._id}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {toTimestamp(row?.createdAt)}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.name}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.createdBy?.firstName}
                                        {row?.createdBy?.lastName}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <IconButton aria-label="edit" color="secondary" onClick={() => handleEditClick(row, index)}>
                                            Update
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 150, 250, { label: 'All', value: -1 }]}
                    component="div"
                    count={orderInfo.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>

            <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title" fullWidth>
                <div style={{ padding: '1rem 2rem' }}>
                    <DialogTitle id="responsive-dialog-title" style={{ fontSize: '1rem' }}>
                        Add Group
                    </DialogTitle>
                    <Form onSubmit={onSubmitMessage}>
                        <DialogContent>
                            <Form.Group controlId="formFile" className="mb-3">
                                <TextField
                                    autoComplete="off"
                                    name="name"
                                    variant="outlined"
                                    fullWidth
                                    value={name}
                                    id="name"
                                    label="Group Name"
                                    onChange={(e) => setname(e.target.value)}
                                />
                                <br /> <br />
                                <>
                                    <Autocomplete
                                        multiple
                                        fullWidth
                                        id="checkboxes-tags-demo"
                                        value={personData}
                                        required
                                        options={retailerInfo}
                                        onChange={(event, newValue) => {
                                            handleChange(newValue);
                                        }}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.partnerName}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option?.partnerName}---{option?.partnerContact}
                                            </li>
                                        )}
                                        style={{ width: 480 }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Retailer Phone Number(s)"
                                                placeholder="Phone Number(s)"
                                                rows={3}
                                            />
                                        )}
                                    />
                                </>
                                <br />
                                <br />
                            </Form.Group>
                        </DialogContent>
                        <DialogActions style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Button variant="contained" size="large" className={classes.item} autoFocus onClick={handleClose} color="error">
                                Cancel
                            </Button>
                            <Button autoFocus variant="contained" type="submit" size="large" className={classes.item} disabled={!!loading}>
                                {loading ? 'Adding ..' : 'Add'}
                            </Button>
                        </DialogActions>
                    </Form>
                </div>
            </Dialog>

            {/* Edit  */}
            <Dialog fullScreen={fullScreen} open={open2} onClose={handleClose2} aria-labelledby="responsive-dialog-title" fullWidth>
                <div style={{ padding: '1rem 2rem' }}>
                    <DialogTitle id="responsive-dialog-title" style={{ fontSize: '1rem' }}>
                        Edit Group
                    </DialogTitle>
                    <Form onSubmit={onSubmitMessageUpdate}>
                        <DialogContent>
                            <Form.Group controlId="formFile" className="mb-3">
                                <TextField
                                    autoComplete="off"
                                    name="name"
                                    variant="outlined"
                                    fullWidth
                                    value={name}
                                    id="name"
                                    label="Group Name"
                                    onChange={(e) => setname(e.target.value)}
                                />
                                <br /> <br />
                                <>
                                    <Autocomplete
                                        multiple
                                        fullWidth
                                        id="checkboxes-tags-demo"
                                        value={personData}
                                        required
                                        options={retailerInfo}
                                        onChange={(event, newValue) => {
                                            handleChange(newValue);
                                        }}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.partnerName}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option?.partnerName}---{option?.partnerContact}
                                            </li>
                                        )}
                                        style={{ width: 480 }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Retailer Phone Number(s)"
                                                placeholder="Phone Number(s)"
                                                rows={3}
                                            />
                                        )}
                                    />
                                </>
                                <br />
                                <br />
                            </Form.Group>
                        </DialogContent>
                        <DialogActions style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Button
                                variant="contained"
                                size="large"
                                className={classes.item}
                                autoFocus
                                onClick={handleClose2}
                                color="error"
                            >
                                Cancel
                            </Button>
                            <Button autoFocus variant="contained" type="submit" size="large" className={classes.item} disabled={!!loading}>
                                {loading ? 'Updating ..' : 'Update'}
                            </Button>
                        </DialogActions>
                    </Form>
                </div>
            </Dialog>
        </>
    );
};

export default Groups;
