import {
    BATCH_ORDERS_SUCCESS,
    BATCH_STATUS_SUCCESS,
    CREATE_BATCH_BEGIN,
    CREATE_BATCH_FAILURE,
    CREATE_BATCH_SUCCESS,
    DELETE_BATCH_BEGIN,
    FILTER_BATCH_BEGIN,
    FILTER_BATCH_ERROR,
    FILTER_BATCH_SUCCESS,
    GET_BATCH_BEGIN,
    GET_BATCH_FAILURE,
    GET_BATCH_SUCCESS,
    RESET_BATCH_BEGIN,
    SET_DASHBOARD_VIEW,
    SINGLE_BATCH_BEGIN,
    SINGLE_BATCH_FAILURE,
    SINGLE_BATCH_SUCCESS,
    UPDATE_BATCH_BEGIN,
    UPDATE_BATCH_FAILURE,
    UPDATE_BATCH_SUCCESS
} from './batch-actions';

const batchReducer = (state, action) => {
    switch (action.type) {
        case BATCH_STATUS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                batchStatuses: action.payload.batchStatuses
            };
        case BATCH_ORDERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                todayOrders: action.payload.todayOrders
            };
        case CREATE_BATCH_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case CREATE_BATCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            };
        case CREATE_BATCH_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case GET_BATCH_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case GET_BATCH_SUCCESS:
            return {
                ...state,
                batchdatatoday: action.payload.batchdatatoday,
                createdbatchtoday: action.payload.createdbatchtoday,
                fullysorcedbatchtoday: action.payload.fullysorcedbatchtoday,
                partiallysourcedbatchtoday: action.payload.partiallysourcedbatchtoday,
                intransitbatchtoday: action.payload.intransitbatchtoday,
                deliveredbatchtoday: action.payload.deliveredbatchtoday,
                verifiedbatchtoday: action.payload.verifiedbatchtoday,
                isLoading: false
            };
        case GET_BATCH_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case DELETE_BATCH_BEGIN:
            return {
                ...state,
                isLoading: true
            };

        case FILTER_BATCH_BEGIN:
            return {
                ...state,
                isLoading: true
            };

        case FILTER_BATCH_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                batchdatatoday: action.payload.batchfilterdatedata,
                createdbatchtoday: action.payload.createdbatchdate,
                fullysorcedbatchtoday: action.payload.fullysorcedbatchdate,
                partiallysourcedbatchtoday: action.payload.partiallysourcedbatchdate,
                intransitbatchtoday: action.payload.intransitbatchdate,
                deliveredbatchtoday: action.payload.deliveredbatchdate,
                verifiedbatchtoday: action.payload.verifiedbatchdate,
                todayOrders: action.payload.order
            };
        }

        case FILTER_BATCH_ERROR: {
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            };
        }
        case RESET_BATCH_BEGIN: {
            return {
                ...state,
                isLoading: true
            };
        }

        case SINGLE_BATCH_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case SINGLE_BATCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                singleBatchContent: action.payload.content
            };
        case SINGLE_BATCH_FAILURE:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            };
        case UPDATE_BATCH_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case UPDATE_BATCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            };
        case UPDATE_BATCH_FAILURE:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            };
        case SET_DASHBOARD_VIEW:
            return {
                ...state,
                view: action.payload.view,
                isLoading: false
            };
        case 'ORDER_CHANGE':
            return {
                ...state,
                orderType: action.payload.orderType
            };

        default:
            throw new Error(`No such action ${action.type}`);
    }
};

export default batchReducer;
