import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

export default function Chart({ month, title }) {
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <span className="label">{`${label} `}</span>
                    <span className="label">{` ${payload[0].value}`}</span>
                </div>
            );
        }

        return null;
    };
    return (
        <AreaChart
            width={280}
            height={100}
            data={month}
            margin={{
                top: 10,
                right: 0,
                left: 0,
                bottom: -10
            }}
            style={{ bottom: '-80px' }}
        >
            <XAxis dataKey="month" />
            <Tooltip content={<CustomTooltip />} />

            {title === 'Total Orders from Web.' ? (
                <Area type="monotone" dataKey="totalWebOrderNo" stroke="#8884d8" fill="#8884d8" />
            ) : title === 'Total Orders from Mobile.' ? (
                <Area type="monotone" dataKey="totalMobileOrderNo" stroke="#8884d8" fill="#8884d8" />
            ) : title === 'Marketplace Take Rate' ? (
                <Area type="monotone" dataKey="totalNetRevenuePercentage" stroke="#8884d8" fill="#8884d8" />
            ) : (
                ''
            )}
        </AreaChart>
    );
}
