import { Button, DatePicker, Select, Spin } from 'antd';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import ApiUrl from '../../../network';
import { decrypt, encrypt } from '../../../utils/encrypt';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const { RangePicker } = DatePicker;
    const [config, setConfig] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rmacc, setRmacc] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [driveTypeList, setDriveTypeList] = useState([]);
    const [status, setStatus] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(25);
    const [totalPages, setTotalPages] = useState(1);

    // eslint-disable-next-line consistent-return
    const fetchRequestData = async (data, page = 1) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const urlParams = new URLSearchParams(window.location.search);
            if (data) {
                if (data.startDate) urlParams.append('startDate', startDate);
                if (data.endDate) urlParams.append('endDate', endDate);
                if (data.status) urlParams.append('status', status);
            }

            urlParams.append('page', page);
            urlParams.append('limit', itemsPerPage);

            const url = `${ApiUrl.BaseUrl}/epl/eplrequestrmacc?${urlParams.toString()}`;

            const response = await fetch(url, config);
            if (!response.ok) {
                toast.error('Failed to fetch retailers');
            }
            const apiData = await response.json();

            const result = JSON.parse(decrypt(apiData.data, keyResult, ivResult));
            result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

            result.sort((a, b) => {
                if (a.status.toLowerCase() === 'pending') {
                    return -1;
                }
                return 1;
            });
            setConfig(result);
            setTotalPages(Math.ceil(apiData.totalepl / itemsPerPage));
            setLoading(false);
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };

    const fetchAllData = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const rmaccUrl = `${ApiUrl.BaseUrl}/epl/eplcustomersrmacc`;
            const rmaccResponse = await fetch(rmaccUrl, config);
            const rmaccData = await rmaccResponse.json();
            if (rmaccData.message === 'Success') {
                const decryptedRmaccData = JSON.parse(decrypt(rmaccData.data, keyResult, ivResult));
            } else {
                toast.error(rmaccData.message);
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    // eslint-disable-next-line consistent-return
    const getDriveTypeHandler = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/epl/epldrivetyeps`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    const driveTypeList = decrypt(JSON.stringify(data.data), keyResult, ivResult);
                    setDriveTypeList(JSON.parse(driveTypeList).driveType);
                } else {
                    toast.error(data.message);
                }
            });
    };

    useEffect(() => {
        fetchRequestData();
        getDriveTypeHandler();
        fetchAllData();
    }, [itemsPerPage]);

    const driveTypeOptions = driveTypeList.map((driveType) => {
        return {
            value: driveType.name,
            label: driveType.name
        };
    });

    const handleDateChange = (date, dateString) => {
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
    };

    const applyFilter = () => {
        const data = {
            startDate,
            endDate,
            rmacc,
            status
        };
        fetchRequestData(data);
    };

    // Get the items for the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = config.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    // const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchRequestData({}, page);
    };

    return (
        <div className="config-container epl-request-finance-home">
            <div className="header">
                <Link className="d-flex" to="/epl/easy-pay-later" style={{ color: '#0A2938' }}>
                    <div className="ml-2">
                        <h1 className="font-30">EPL request</h1>
                        <p style={{ color: '#565C6B', fontWeight: 'normal' }}>View and manage all EPL request </p>
                    </div>
                </Link>
                <div className="header-actions">
                    <Select
                        style={{
                            margin: '0.625rem'
                        }}
                        placeholder="Request status"
                        options={[
                            { value: 'Pending', label: 'Pending' },
                            { value: 'Approved', label: 'Approved' },
                            { value: 'Rejected', label: 'Rejected' }
                        ]}
                        onChange={(value) => setStatus(value)}
                        value={status || null}
                        filterOption={(inputValue, option) => {
                            if (typeof option.label === 'string') {
                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }

                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        }}
                        showSearch
                        allowClear
                    />
                    <RangePicker
                        onChange={(date, dateString) => {
                            handleDateChange(date, dateString);
                        }}
                        value={startDate && endDate ? [dayjs(startDate, 'YYYY-MM-DD'), dayjs(endDate, 'YYYY-MM-DD')] : null}
                        format="YYYY-MM-DD"
                        className="datePicker filterInput"
                        style={{ width: 'fit-content' }}
                    />
                    <Button
                        className="mt-3"
                        style={{
                            color: '#FF740F',
                            border: '1px solid #FF740F',
                            width: 'fit-content'
                        }}
                        loading={loading}
                        onClick={() => applyFilter()}
                    >
                        Apply filter
                    </Button>
                    {(startDate && endDate) || rmacc || status ? (
                        <Button
                            onClick={() => {
                                setStartDate('');
                                setEndDate('');
                                setRmacc('');
                                setStatus('');
                                fetchRequestData();
                            }}
                            style={{
                                color: '#FF740F',
                                border: '1px solid #FF740F',
                                width: 'fit-content'
                            }}
                            className="ml-2 mt-3"
                        >
                            Reset filter
                        </Button>
                    ) : null}
                </div>
            </div>
            <hr className="divider" />
            {loading ? (
                <div className="spinner-container">
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 34 }} spin />} />
                </div>
            ) : (
                <div>
                    {config.length > 0 ? (
                        <div className="content">
                            {config.map((item) => (
                                <div className="card border-0 p-4" style={{ borderRadius: '20px' }} key={item._id}>
                                    <div>
                                        <div className="d-flex flex-row-reverse">
                                            {item?.status?.toLowerCase() === 'pending' ? (
                                                <Button
                                                    className="mb-4"
                                                    onClick={() => {
                                                        window.location.href = `/epl/cc-rma-request-edit/${item._id}`;
                                                    }}
                                                    style={{
                                                        backgroundColor: '#FFF1E7',
                                                        color: '#FF740F',
                                                        border: 'none',
                                                        width: 'fit-content',
                                                        borderRadius: '1px'
                                                    }}
                                                >
                                                    Edit <EditOutlined />
                                                </Button>
                                            ) : null}
                                        </div>
                                        <div>
                                            <span className="d-flex justify-content-space-between align-items-center">
                                                <p style={{ color: '#546974', fontSize: '15px' }}>Date</p>
                                                <p style={{ color: '#0A2938' }}>
                                                    {new Date(item.date).toLocaleDateString('us-US', {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        hour12: true,
                                                        timeZone: 'Africa/Lagos'
                                                    })}
                                                </p>
                                            </span>
                                            <span className="d-flex justify-content-space-between align-items-center">
                                                <p style={{ color: '#546974', fontSize: '15px' }}>Retailer</p>
                                                <p style={{ color: '#0A2938' }}>{item?.customergh?.partnerName}</p>
                                            </span>
                                            <span className="d-flex justify-content-space-between align-items-center">
                                                <p style={{ color: '#546974', fontSize: '15px' }}>RMA/CC</p>
                                                <p style={{ color: '#0A2938' }}>
                                                    {item?.rmacc?.firstName} {item?.rmacc?.lastName}
                                                </p>
                                            </span>
                                            {item?.placedfor ? (
                                                <span className="d-flex justify-content-space-between align-items-center">
                                                    <p style={{ color: '#546974', fontSize: '15px' }}>Placed For</p>
                                                    <p style={{ color: '#0A2938' }}>
                                                        {item?.placedfor?.firstName} {item?.placedfor?.lastName}
                                                    </p>
                                                </span>
                                            ) : null}
                                            <span className="d-flex justify-content-space-between align-items-center">
                                                <p style={{ color: '#546974', fontSize: '15px' }}>Order value</p>
                                                <p style={{ color: '#0A2938' }}>
                                                    {item?.currency?.name} {item.orderValue}
                                                </p>
                                            </span>
                                            <span className="d-flex justify-content-space-between align-items-center">
                                                <p style={{ color: '#546974', fontSize: '15px' }}>Cash at hand</p>
                                                <p style={{ color: '#0A2938' }}>
                                                    {item?.currency?.name} {item.cashathand}
                                                </p>
                                            </span>
                                            <span className="d-flex justify-content-space-between align-items-center">
                                                <p style={{ color: '#546974', fontSize: '15px' }}>Drive type</p>
                                                <p style={{ color: '#0A2938' }}>{item?.drivetype}</p>
                                            </span>
                                            <span className="d-flex justify-content-space-between align-items-center">
                                                <p style={{ color: '#546974', fontSize: '15px' }}>Description</p>
                                                <p style={{ color: '#0A2938' }}>{item.description}</p>
                                            </span>
                                        </div>
                                        <div>
                                            <div
                                                className="mb-3"
                                                style={{
                                                    backgroundColor:
                                                        item.status.toLowerCase() === 'approved'
                                                            ? '#E6F7EE'
                                                            : item.status.toLowerCase() === 'pending'
                                                            ? '#EDF1FF'
                                                            : item.status.toLowerCase() === 'rejected'
                                                            ? '#FCE8E6'
                                                            : '',
                                                    color:
                                                        item.status.toLowerCase() === 'approved'
                                                            ? '#00A86B'
                                                            : item.status.toLowerCase() === 'pending'
                                                            ? '#4B6EFF'
                                                            : item.status.toLowerCase() === 'rejected'
                                                            ? '#FA3246'
                                                            : '', // Add more conditions if needed
                                                    border: 'none',
                                                    padding: '10px',
                                                    borderRadius: '5px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {item.status.toLowerCase() === 'approved'
                                                    ? 'Approved'
                                                    : item.status.toLowerCase() === 'pending'
                                                    ? 'Pending'
                                                    : item.status.toLowerCase() === 'rejected'
                                                    ? 'Rejected'
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div
                            className="card border-0 p-4 d-flex justify-content-center align-items-center not-found"
                            style={{
                                borderRadius: '20px',
                                width: '50%',
                                marginTop: '10vh',
                                margin: '0 auto',
                                height: '40vh'
                            }}
                        >
                            <p style={{ fontSize: '16', fontWeight: 'bold' }}>No EPL request available</p>
                        </div>
                    )}
                    <div className="custom-pagination">
                        {[...Array(totalPages).keys()].map((number) => (
                            <button
                                type="button"
                                key={number}
                                onClick={() => {
                                    handlePageChange(number + 1);
                                }}
                                className={`custom-pagination-btn page-link ${currentPage === number + 1 ? 'active' : ''}`}
                            >
                                {number + 1}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Index;
