/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import { notification } from 'antd';
import { toast } from 'react-toastify';
import Autocomplete from '@mui/material/Autocomplete';
import useScriptRef from 'hooks/useScriptRef';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

const skills = ['General Maintenance', 'Spraying', 'Body works', 'Car wash', 'Alignment', 'Electricals'];
const languages = ['English', 'Twi', 'Ga'];

function getStyles(name, languagesSpoken, theme) {
    return {
        fontWeight: languagesSpoken.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
    };
}

function getStyles2(name, keyskills, theme) {
    return {
        fontWeight: keyskills.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
    };
}

const AddAutoAccount = () => {
    const theme = useTheme();
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    const [roleId, setroleId] = useState('');
    const [country, setCountry] = useState('');
    const [currency, setcurrency] = useState('');
    const [countries, setCountries] = useState([]);
    const [dob, setdob] = useState(new Date());
    const [location, setlocation] = useState('');
    const [typeofMechanic, settypeofMechanic] = useState('');
    const [languagesSpoken, setlanguagesSpoken] = useState([]);
    const [keyskills, setkeyskills] = useState([]);
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const handleRoleChange = (event) => {
        setroleId(event.target.value);
    };

    const handleMechanicTypeChange = (event) => {
        settypeofMechanic(event.target.value);
    };

    const handleLanguageChange = (event) => {
        const {
            target: { value }
        } = event;
        setlanguagesSpoken(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const handleSkillsChange = (event) => {
        const {
            target: { value }
        } = event;
        setkeyskills(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const retrieveAllActiveCountry = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.ActiveCountryUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const filterCountry = (event) => {
        const newFilter = countries.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        newFilter.forEach((option) => {
            setcurrency(option.currency);
        });
    };

    const handleDateBirthChange = (newValue) => {
        setdob(newValue);
    };

    useEffect(() => {
        retrieveAllActiveCountry();
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        const channelPartnerId = localStorage.getItem('channelpartnerId');
        const company = localStorage.getItem('company');
        const userInfo = localStorage.getItem('userToken');
        fetch(ApiUrl.AutoMechanicCreateAccountUrl, {
            method: 'POST',
            headers: { 'auth-token': userInfo, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                firstName,
                lastName,
                email,
                password,
                country,
                roleId,
                channelPartnerId,
                currency,
                location,
                dob,
                company,
                typeofMechanic,
                keyskills,
                languagesSpoken
            })
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/autoaccount');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid item xs={12} sm={6}>
                        <PageHeader title="Users" subTitle="Home | Users" />
                        <MainCard title="Add User">
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    autoComplete="off"
                                    name="firstName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="firstName"
                                    value={firstName}
                                    label="First Name"
                                    onChange={(e) => setfirstName(e.target.value)}
                                    autoFocus
                                />
                                <br />
                                <br />

                                <TextField
                                    autoComplete="off"
                                    name="lastName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="lastName"
                                    value={lastName}
                                    label="Last Name"
                                    onChange={(e) => setlastName(e.target.value)}
                                />
                                <br />
                                <br />
                                <TextField
                                    autoComplete="off"
                                    name="email"
                                    type="email"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    value={email}
                                    label="Email"
                                    onChange={(e) => setemail(e.target.value)}
                                />
                                <br />
                                <br />
                                <TextField
                                    autoComplete="off"
                                    name="password"
                                    type="password"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="password"
                                    value={password}
                                    label="Password"
                                    onChange={(e) => setpassword(e.target.value)}
                                />

                                <br />
                                <br />
                                <TextField
                                    autoComplete="off"
                                    name="location"
                                    variant="outlined"
                                    fullWidth
                                    id="location"
                                    value={location}
                                    label="Location"
                                    onChange={(e) => setlocation(e.target.value)}
                                />

                                <br />
                                <br />

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Mechanic Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={typeofMechanic}
                                        label="mechanic"
                                        onChange={handleMechanicTypeChange}
                                    >
                                        <MenuItem value="">Select an option</MenuItem>
                                        <MenuItem value="Auto Mechanic">Auto Mechanic</MenuItem>
                                        <MenuItem value="Heavy Duty Mechanic">Heavy Duty Mechanic</MenuItem>
                                        <MenuItem value="Diesel Mechanic">Diesel Mechanic</MenuItem>
                                    </Select>
                                </FormControl>

                                <br />
                                <br />

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Date of Birth"
                                        inputFormat="MM/dd/yyyy"
                                        value={dob}
                                        onChange={handleDateBirthChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                                <br />
                                <br />

                                <Autocomplete
                                    id="country-select-demo"
                                    autoHighlight
                                    value={country}
                                    onChange={(event, newValue) => {
                                        setCountry(newValue);
                                        filterCountry(newValue);
                                    }}
                                    options={countries.map((option) => option.name)}
                                    isOptionEqualToValue={(option, value) => option.code === value.code}
                                    renderInput={(params) => <TextField {...params} label="Choose a country" />}
                                />

                                <br />
                                <br />
                                <FormControl sx={{ m: 1, width: 300 }}>
                                    <InputLabel id="demo-multiple-chip-label">Languages Spoken</InputLabel>
                                    <Select
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={languagesSpoken}
                                        onChange={handleLanguageChange}
                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {languages.map((name) => (
                                            <MenuItem key={name} value={name} style={getStyles(name, languagesSpoken, theme)}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <br />
                                <br />
                                <FormControl sx={{ m: 1, width: 300 }}>
                                    <InputLabel id="demo-multiple-chip-label">Key Skills</InputLabel>
                                    <Select
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={keyskills}
                                        onChange={handleSkillsChange}
                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {skills.map((name) => (
                                            <MenuItem key={name} value={name} style={getStyles2(name, keyskills, theme)}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <br />
                                <br />

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Account Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={roleId}
                                        label="roles"
                                        onChange={handleRoleChange}
                                    >
                                        <MenuItem value="">Select an option</MenuItem>
                                        <MenuItem value="6295f604e005eacf12adea22">Senior Mechanic</MenuItem>
                                        <MenuItem value="6295f618e005eacf12adea26">Junior Mechanic</MenuItem>
                                    </Select>
                                </FormControl>

                                <br />
                                <br />

                                <Box display="flex" justifyContent="space-between">
                                    <Button component={Link} to="/autoaccount" type="submit" variant="contained" color="error">
                                        Reset
                                    </Button>

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        disabled={!firstName || !lastName || !email || !password || !roleId}
                                    >
                                        {loading ? 'Submitting ..' : 'Submit'}
                                    </Button>
                                </Box>
                            </form>
                        </MainCard>
                    </Grid>
                </>
            )}
        </>
    );
};

export default AddAutoAccount;
