// assets
import { IconKey, IconSnowflake, IconTruckDelivery } from '@tabler/icons';
import DashboardIcon from '@mui/icons-material/Dashboard';
// import Person from '@mui/icons-material/Person';
// import CarCrashIcon from '@mui/icons-material/CarRental';
// import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import Scale from '@mui/icons-material/Scale';
// import Shop from '@mui/icons-material/Shop2';
import Trans from '@mui/icons-material/Money';
import BugReportIcon from '@mui/icons-material/BugReport';
import { BsGraphUp, BsFillCartPlusFill, BsShop } from 'react-icons/bs';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import CategoryIcon from '@mui/icons-material/Category';
import { FaBookReader } from 'react-icons/fa';
import { MdLoyalty, MdStore } from 'react-icons/md';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';

// constant
const icons = {
    IconKey,
    IconTruckDelivery,
    IconSnowflake,
    DashboardIcon,
    Scale,
    Trans,
    BugReportIcon,
    FactCheckIcon,
    ViewModuleIcon,
    EmojiTransportationIcon,
    CategoryIcon,
    DataThresholdingIcon
};

// ==============================|| EXTRA FLEET MENU ITEMS ||============================== //
const userRole = localStorage.getItem('userRole');

const fulfillment = {
    id: 'fulfillment',
    title: 'fulfillment',
    type: userRole,
    children: [
        {
            id: 'home',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: icons.DashboardIcon,
            breadcrumbs: false
        },
        {
            id: 'attendance',
            title: 'Attendance',
            type: 'collapse',
            icon: icons.FactCheckIcon,
            children: [
                // {
                //     id: 'imageUpload',
                //     title: 'Image Upload',
                //     type: 'item',
                //     url: '/image-upload',
                //     breadcrumbs: false
                // },

                {
                    id: 'checkIn',
                    title: 'check In',
                    type: 'item',
                    url: '/check-in',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'orderswaybill',
            title: 'Waybill',
            type: 'collapse',
            icon: icons.ViewModuleIcon,
            children: [
                {
                    id: 'orders',
                    title: 'Batch order',
                    type: 'item',
                    url: '/waybill/orders',
                    icon: icons.ViewModuleIcon,
                    breadcrumbs: false
                },
                {
                    id: 'batch',
                    title: 'Generate waybill',
                    url: '/waybill/batch',
                    type: 'item',
                    icon: icons.EmojiTransportationIcon,
                    breadcrumbs: false
                },
                {
                    id: 'waybill',
                    title: 'View waybill',
                    type: 'item',
                    url: '/generated/waybill',
                    icon: icons.EmojiTransportationIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'orders',
            title: 'Orders',
            type: 'collapse',
            icon: icons.Scale,
            children: [
                {
                    id: 'cssOrders',
                    title: 'Orders',
                    type: 'item',
                    url: '/cssOrders',
                    icon: icons.Trans,
                    breadcrumbs: false
                },
                {
                    id: 'proformacssorders',
                    title: 'Proforma Orders',
                    type: 'item',
                    url: '/proformacssorders',
                    icon: icons.Trans,
                    breadcrumbs: false
                },
                // {
                //     id: 'darkstores',
                //     title: 'Dark Stores Orders',
                //     type: 'item',
                //     url: '/darkstores',
                //     icon: icons.Trans,
                //     breadcrumbs: false
                // },

                {
                    id: 'salesdrive',
                    title: 'Sales Drive Orders',
                    type: 'item',
                    url: '/csssalesdriveorders',
                    icon: icons.Trans,
                    breadcrumbs: false
                },

                {
                    id: 'retentionorders',
                    title: 'Retention Drive Orders',
                    type: 'item',
                    icon: icons.Scale,
                    url: '/retentionorders',
                    breadcrumbs: false
                },
                {
                    id: 'conversionorders',
                    title: 'Conversion Drive Orders',
                    type: 'item',
                    icon: icons.Scale,
                    url: '/conversionorders',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'presold-orders',
            title: 'pre-sold batch',
            type: 'collapse',
            icon: icons.DataThresholdingIcon,
            children: [
                {
                    id: 'orders',
                    title: 'PreSold Orders',
                    type: 'item',
                    url: '/pre-sold-orders',
                    icon: icons.DataThresholdingIcon,
                    breadcrumbs: false
                },
                {
                    id: 'pre-sold-orders-batch',
                    title: 'PreSold Batches',
                    type: 'item',
                    url: '/pre-sold-orders/batch',
                    icon: icons.DataThresholdingIcon,
                    breadcrumbs: false
                }
            ]
        },
        // {
        //     id: 'presoldsales',
        //     title: 'PreSold Orders',
        //     type: 'collapse',
        //     icon: icons.FactCheckIcon,
        //     children: [
        //         {
        //             id: 'presoldmysalesorder',
        //             title: 'My PreSold Order',
        //             type: 'item',
        //             url: '/presoldmysalesorder',
        //             breadcrumbs: false
        //         },

        //         {
        //             id: 'presoldsales',
        //             title: 'Add PreSold Orders',
        //             type: 'item',
        //             url: '/presoldsales',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        {
            id: 'logistics',
            title: 'Logistics',
            type: 'item',
            url: '/logistics',
            icon: icons.Trans,
            breadcrumbs: false
        },
        // {
        //     id: 'cssOrders',
        //     title: 'Orders',
        //     type: 'item',
        //     url: '/cssOrders',
        //     icon: icons.Trans,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'proformacssorders',
        //     title: 'Proforma Orders',
        //     type: 'item',
        //     url: '/proformacssorders',
        //     icon: icons.Trans,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'darkstores',
        //     title: 'Dark Stores Orders',
        //     type: 'item',
        //     url: '/darkstores',
        //     icon: icons.Trans,
        //     breadcrumbs: false
        // },

        // {
        //     id: 'salesdrive',
        //     title: 'Sales Drive Orders',
        //     type: 'item',
        //     url: '/csssalesdriveorders',
        //     icon: icons.Trans,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'retentionorders',
        //     title: 'Retention Drive Orders',
        //     type: 'item',
        //     icon: icons.Scale,
        //     url: '/retentionorders',
        //     breadcrumbs: false
        // },
        {
            id: 'parts',
            title: 'Parts',
            type: 'collapse',
            icon: icons.Trans,

            children: [
                {
                    id: 'masterdb',
                    title: 'Master Database',
                    type: 'item',
                    url: '/parts'
                }
                // {
                //     id: 'fulpartlist',
                //     title: 'UnApproved Parts',
                //     type: 'item',

                //     url: '/fulpartlist'
                // }
            ]
        },
        // {
        //     id: 'addpartsourced',
        //     title: 'Add Part Sourcing',
        //     type: 'item',
        //     icon: icons.Scale,
        //     url: '/addpartsourced'
        // },

        {
            id: 'fulfillment',
            title: 'Order Status',
            type: 'item',
            icon: icons.Scale,
            url: '/ordertablestatus'
        },
        // {
        //     id: 'orderpriority',
        //     title: 'Order Priority',
        //     type: 'item',
        //     icon: BsGraphUp,
        //     url: '/order-priority'
        // },
        {
            id: 'deliverynotes',
            title: 'Delivery Orders',
            type: 'item',
            icon: BsFillCartPlusFill,
            url: '/orders/deliveries'
        },
        {
            id: 'alldeliveries',
            title: 'All Delivery Notes',
            type: 'item',
            url: '/orders/deliveries/notes',
            icon: FaBookReader
        },
        // {
        //     id: 'retailershops',
        //     title: 'Retailer Shop',
        //     type: 'item',
        //     url: '/retailers/shops',
        //     icon: BsShop
        // },
        {
            id: 'loyalty',
            title: 'Retailer Loyalty',
            type: 'item',
            url: '/retailers/loyalties/point',
            icon: MdLoyalty
        },

        // {
        //     id: 'darkstoreinventorytracking',
        //     title: 'Dark Store Inventory',
        //     type: 'item',
        //     url: '/inventory/darkstores',
        //     icon: MdStore
        // },

        {
            id: 'cssorderissuesdata',
            title: 'Order Issues',
            type: 'collapse',
            icon: icons.BugReportIcon,
            children: [
                {
                    id: 'cssorderissues',
                    title: 'Order Issues',
                    type: 'item',
                    url: '/cssorderissues'
                },
                {
                    id: 'cssresolvedIssue',
                    title: 'Resolved Order Issues',
                    type: 'item',
                    url: '/cssresolvedIssue'
                }
            ]
        }
    ]
};

export default fulfillment;
