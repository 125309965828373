import { Box, Typography } from '@mui/material';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { twoDecimalPlaces } from 'utils/functions';

const CustomTooltip = ({ active, payload, label, check, showGmv, showOrder, showRevenue }) => {
    if (active && payload && payload.length) {
        return (
            <Box
                sx={{
                    height: '28px',
                    padding: '5px',
                    bordeRadius: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    backgroundColor: '#FFFFFF',
                    boxShadow: ' 0px 4px 25px 0px #4B6EFF1A',
                    textAlign: 'center'
                }}
            >
                <Typography
                    sx={{
                        color: '#4B6EFF',
                        width: '100%',
                        padding: '3px'
                    }}
                >
                    {check
                        ? showGmv
                            ? payload?.map((item) => {
                                  return item?.payload.gmv_growth ? item?.payload?.gmv_growth : 0;
                              })
                            : showRevenue
                            ? payload?.map((item) => {
                                  return item?.payload?.revenue_growth ? item?.payload?.revenue_growth : 0;
                              })
                            : showOrder
                            ? payload?.map((item) => {
                                  return item?.payload?.orders_growth ? item?.payload?.orders_growth : 0;
                              })
                            : null
                        : showGmv
                        ? payload?.map((item) => {
                              return item?.payload.gmv ? twoDecimalPlaces(item?.payload?.gmv) : 0;
                          })
                        : showRevenue
                        ? payload?.map((item) => {
                              return item?.payload?.revenue ? twoDecimalPlaces(item?.payload?.revenue) : 0;
                          })
                        : showOrder
                        ? payload?.map((item) => {
                              return item?.payload?.orders ? item?.payload?.orders : 0;
                          })
                        : null}
                    {check ? `%` : ''}
                </Typography>
            </Box>
        );
    }

    return null;
};

const GrowthLineChart = ({ datas, check, showCustomer, showGmv, showOrder, showRevenue }) => {
    // const CustomizedLabel = (props) => {
    //     return (
    //         <>
    //             <style>
    //                 {`
    //             .small {
    //                 font: 16px ;
    //                 }
    //             `}
    //             </style>

    //             <text x={props?.viewBox?.x} y={props?.viewBox?.y} fill="#4B6EFF" dy={-15} dx={-28} className="small">
    //                 {twoDecimalPlaces(props.value)}

    //                 {/* // ${check ? `%` : ''} */}
    //             </text>
    //         </>
    //     );
    // };
    // console.error(datas?.trends?.chart_data);
    return (
        <>
            <LineChart
                width={640}
                height={400}
                data={datas?.trends?.chart_data}
                margin={{
                    top: 27,
                    right: 55,
                    left: 27,
                    bottom: 0
                }}
                style={{ margin: 'auto' }}
            >
                <CartesianGrid strokeDasharray="1 1" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip
                    content={<CustomTooltip check={check} showGmv={showGmv} showOrder={showOrder} showRevenue={showRevenue} />}
                    offset={1}
                />

                {check ? (
                    <>
                        {showGmv ? (
                            <Line dataKey="gmv_growth" stroke="#4B6EFF" strokeWidth={1} dot={{ r: 8 }} name="gmv_growth" />
                        ) : showRevenue ? (
                            <Line dataKey="revenue_growth" stroke="#4B6EFF" strokeWidth={1} dot={{ r: 8 }} name="revenue_growth" />
                        ) : showOrder ? (
                            <Line dataKey="orders_growth" stroke="#4B6EFF" strokeWidth={1} dot={{ r: 8 }} name="orders_growth" />
                        ) : null}
                    </>
                ) : showGmv ? (
                    <Line dataKey="gmv" stroke="#4B6EFF" strokeWidth={1} dot={{ r: 8 }} name="gmv" />
                ) : showRevenue ? (
                    <Line dataKey="revenue" stroke="#4B6EFF" strokeWidth={1} dot={{ r: 8 }} name="revenue" />
                ) : showOrder ? (
                    <Line dataKey="orders" stroke="#4B6EFF" strokeWidth={1} dot={{ r: 8 }} name="orders" />
                ) : null}
            </LineChart>
        </>
    );
};

export default GrowthLineChart;
