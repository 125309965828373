import React, { useState, useEffect } from 'react';
import { Table, Divider, Paper, Space, Button, Select, Modal, Checkbox, Alert } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Link, useNavigate, useParams, useHistory, useLocation } from 'react-router-dom';
import WestIcon from '@mui/icons-material/West';
import './logistics.css';
import Image from '../../assets/images/brakefluid.png';
import ApiUrl from 'network';
import { encrypt, decrypt } from '../../utils/encrypt';
import { ToastContainer, toast } from 'react-toastify';
import useOrder from './useOrder';
import OrderSumbition from './OrderSumbition';
import { LoadingOutlined } from '@ant-design/icons';

const AssignOrder = () => {
    const [isDriverOpen, setIsDriverOpen] = useState(false);
    const [allOrders, setAllOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const navigate = useNavigate();
    const { orders } = useOrder();

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54
            }}
            spin
        />
    );

    // driver select modals
    const showDriverModal = () => {
        setIsDriverOpen(true);
    };

    const handleDriverOk = () => {
        setIsDriverOpen(false);
    };

    const handleDriverCancel = () => {
        setIsDriverOpen(false);
    };

    // get all Un-Assigned Orders
    const allUnAssignedOrders = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(`${ApiUrl.BaseUrl}/driver/allunassignedorders`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setLoading(false);
                        const order = answer?.filter((item) => orders?.includes(item?._id));
                        const flattenedArray = order.flatMap((item) => {
                            return item.items;
                        });
                        setAllOrders(flattenedArray);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                        toast.error(answer);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        allUnAssignedOrders();
    }, []);

    return (
        <>
            <div className="logistic-container">
                <List
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '0 0 16px 0',
                        borderBottom: '1px dashed #E7EAEB',
                        alignItems: 'flex-start',
                        margin: '0px auto 15px'
                    }}
                >
                    <WestIcon sx={{ fontSize: '32px', cursor: 'pointer' }} onClick={() => navigate(-1)} />

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            marginLeft: '24px'
                        }}
                    >
                        <ListItem
                            sx={{
                                fontSize: '1.875rem',
                                color: '#0A2938',
                                padding: 0,
                                fontWeight: 900,
                                marginBottom: '7px'
                            }}
                        >
                            Order Details
                        </ListItem>
                        <ListItem sx={{ fontSize: '0.875rem', color: '#565C6B', padding: 0 }}>
                            Showing all the products on this order
                        </ListItem>
                    </Box>
                </List>

                {loading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '200px'
                        }}
                    >
                        {antIcon}
                    </Box>
                ) : (
                    <div className="assign-container">
                        <div className="order-assined-info">
                            <Button
                                onClick={showDriverModal}
                                style={{
                                    backgroundColor: '#FF740F',
                                    borderRadius: '12px',
                                    height: '56px',
                                    fontSize: '18px',
                                    fontWeight: '700',
                                    color: '#FFFFFF',
                                    width: '250px',
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    margin: '16px 0'
                                }}
                                to="/assign-order"
                            >
                                Assign Order
                            </Button>

                            {allOrders?.map((orders) => (
                                <div className="order-assined-info-container" key={orders._id}>
                                    <div className="product-image-container">
                                        <img src={orders?.partimage} alt="" className="product-image" />
                                    </div>

                                    <div className="order-assined-details">
                                        <List sx={{ margin: '16px', paddingTop: '0px' }}>
                                            <ListItem sx={{ fontSize: '0.8rem', color: '#233E4C', fontWeight: 400 }}>
                                                Product Name: {orders?.partid}
                                            </ListItem>

                                            <ListItem sx={{ fontSize: '0.8rem', color: '#233E4C', fontWeight: 400 }}>
                                                Order number: {orders?.orderNo}
                                            </ListItem>
                                            <ListItem sx={{ fontSize: '0.8rem', color: '#233E4C', fontWeight: 400 }}>
                                                Price: {orders?.garagePrice}
                                            </ListItem>
                                            <ListItem sx={{ fontSize: '0.8rem', color: '#233E4C', fontWeight: 400 }}>
                                                Quantity: {orders?.qty}
                                            </ListItem>

                                            <ListItem sx={{ fontSize: '0.8rem', color: '#233E4C', fontWeight: 400 }}>
                                                Drop-off location: {orders?.location}
                                            </ListItem>
                                        </List>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {/* {allOrders.length < 0 ? (
                    <Button
                        outline
                        onClick={() => navigate(-1)}
                        style={{
                            backgroundColor: '#193B56',
                            padding: '10px',
                            borderRadius: '12px',
                            height: '200px',
                            fontSize: '18px',
                            fontWeight: '700',
                            color: '#FFFFFF',
                            textAlign: 'center',
                            margin: '0.625rem'
                        }}
                    >
                        Select An Order To Assign
                    </Button>
                ) : (
                    ''
                )} */}
            </div>

            <OrderSumbition
                isDriverOpen={isDriverOpen}
                handleDriverOk={handleDriverOk}
                handleDriverCancel={handleDriverCancel}
                orders={orders}
            />
        </>
    );
};

export default AssignOrder;
