// import dashboard from './dashboard';

import darkstore from './darkstore';
import other from './other';

// ==============================|| MENU ITEMS dashboard ||============================== //

const darkstoreIndexItems = {
    items: [darkstore, other]
};

export default darkstoreIndexItems;
