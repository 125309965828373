// assets
import { IconKey, IconTruckDelivery } from '@tabler/icons';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Person from '@mui/icons-material/Person';
import CarCrashIcon from '@mui/icons-material/CarRental';
import InventoryIcon from '@mui/icons-material/Inventory';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import Scale from '@mui/icons-material/Scale';
import vinSearch from '@mui/icons-material/Search';
// import Shop from '@mui/icons-material/Shop2';
import Trans from '@mui/icons-material/Money';
import BugReportIcon from '@mui/icons-material/BugReport';
import { SiSimpleanalytics } from 'react-icons/si';
import FactCheckIcon from '@mui/icons-material/FactCheck';

// constant
const icons = {
    IconKey,
    IconTruckDelivery,
    InventoryIcon,
    DashboardIcon,
    Scale,
    Person,
    DisplaySettingsIcon,
    CarCrashIcon,
    Trans,
    vinSearch,
    BugReportIcon,
    FactCheckIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //
const userRole = localStorage.getItem('userRole');
const partSourcing = {
    id: 'partSourcing',
    title: 'Part Sourcing',
    type: userRole,
    children: [
        {
            id: 'home',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: icons.DashboardIcon,
            breadcrumbs: false
        },
        {
            id: 'attendance',
            title: 'Attendance',
            type: 'collapse',
            icon: icons.FactCheckIcon,
            children: [
                // {
                //     id: 'imageUpload',
                //     title: 'Image Upload',
                //     type: 'item',
                //     url: '/image-upload',
                //     breadcrumbs: false
                // },

                {
                    id: 'checkIn',
                    title: 'check In',
                    type: 'item',
                    url: '/check-in',
                    breadcrumbs: false
                }
            ]
        },
        // {
        //     id: 'anaylytics',
        //     title: 'Data Extraction',
        //     type: 'item',
        //     url: '/analytics/data',
        //     icon: SiSimpleanalytics,
        //     breadcrumbs: false
        // },
        {
            id: 'inventory',
            title: 'Inventory',
            type: 'item',
            url: '/inventory',
            icon: icons.InventoryIcon,
            breadcrumbs: false
        },
        {
            id: 'cssOrders',
            title: 'Orders',
            type: 'item',
            url: '/cssOrders',
            icon: icons.Trans,
            breadcrumbs: false
        },
        {
            id: 'proformacssorders',
            title: 'Proforma Orders',
            type: 'item',
            url: '/proformacssorders',
            icon: icons.Trans,
            breadcrumbs: false
        },
        // {
        //     id: 'darkstores',
        //     title: 'Dark Stores Orders',
        //     type: 'item',
        //     url: '/darkstores',
        //     icon: icons.Trans,
        //     breadcrumbs: false
        // },
        {
            id: 'orders',
            title: 'Order Status',
            type: 'item',
            url: '/ordertablestatus',
            icon: icons.Scale,
            breadcrumbs: false
        },
        {
            id: 'batchespage',
            title: 'Batch Orders',
            type: 'item',
            url: '/batchespage',
            icon: icons.Trans,
            breadcrumbs: false
        },

        {
            id: 'cssorderissues',
            title: 'Order Issues',
            type: 'item',
            url: '/cssorderissues',
            icon: icons.BugReportIcon,
            breadcrumbs: false
        },
        {
            id: 'garagevinsearch',
            title: 'Vin Search',
            type: 'item',
            url: '/garagevinsearch',
            icon: icons.vinSearch,
            breadcrumbs: false
        },
        {
            id: 'channelpartners',
            title: 'Channel Partners',
            type: 'collapse',
            icon: icons.Person,

            children: [
                {
                    id: 'retailers',
                    title: 'Retail',
                    type: 'item',
                    url: '/retail'
                },
                {
                    id: 'distributor',
                    title: 'Distributor',
                    type: 'item',
                    url: '/distributor'
                },
                {
                    id: 'mechanic',
                    title: 'Mechanic',
                    type: 'item',
                    url: '/mechanic'
                },
                {
                    id: 'fleetpartner',
                    title: 'Fleet',
                    type: 'item',
                    url: '/fleetpartner'
                },
                {
                    id: 'channel',
                    title: 'Channel',
                    type: 'item',
                    url: '/channel'
                }
            ]
        },
        {
            id: 'jobcard',
            title: 'JobCard',
            type: 'collapse',
            icon: icons.BugReportIcon,
            children: [
                {
                    id: 'jobcard',
                    title: 'Job Card',
                    type: 'item',
                    url: '/jobcard'
                },
                {
                    id: 'customercomplaint',
                    title: 'Customer Complaint',
                    type: 'item',
                    url: '/customercomplaint'
                },
                {
                    id: 'issuesdiagnosed',
                    title: 'Issues Diagnosed',
                    type: 'item',
                    url: '/issuesdiagnosed'
                },
                {
                    id: 'car',
                    title: 'Cars',
                    type: 'item',
                    url: '/car'
                },
                {
                    id: 'carmodel',
                    title: 'Model',
                    type: 'item',
                    url: '/carmodel'
                }
            ]
        },
        {
            id: 'statuses',
            title: 'Setups',
            type: 'collapse',
            icon: icons.DisplaySettingsIcon,

            children: [
                {
                    id: 'orderStatus',
                    title: 'Order Statuses',
                    type: 'item',
                    url: '/orderStatus'
                },
                {
                    id: 'paymentStatus',
                    title: 'Payment Status',
                    type: 'item',
                    url: '/paymentStatus'
                },
                {
                    id: 'stage',
                    title: 'Stage',
                    type: 'item',
                    url: '/stage'
                }
            ]
        },

        {
            id: 'partzones',
            title: 'Part Zones',
            type: 'collapse',
            icon: icons.CarCrashIcon,

            children: [
                {
                    id: 'partlistname',
                    title: 'Part List Name',
                    type: 'item',
                    url: '/partlistnames'
                },
                {
                    id: 'partzones',
                    title: 'Parts Zones',
                    type: 'item',
                    url: '/partzones'
                }
            ]
        },

        {
            id: 'parts',
            title: 'Parts',
            type: 'collapse',
            icon: icons.CarCrashIcon,

            children: [
                {
                    id: 'masterdb',
                    title: 'Master Database',
                    type: 'item',
                    url: '/parts'
                }
            ]
        }
    ]
};

export default partSourcing;
