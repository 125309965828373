/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import Button from '@mui/material/Button';
import { Link, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import * as XLSX from 'xlsx';

import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

// import styled from 'styled-components';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const RetailerAuditLog = () => {
    // page states

    const classes = useStyles();
    const [auditlogInfo, setAuditLog] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - auditlogInfo.length) : 0;

    const { id } = useParams();

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };
        setLoading(true);
        fetch(ApiUrl.RetailerAuditLogUrl + id, config)
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    // console.log('data', data.data);
                    setAuditLog(data.data);
                } else {
                    toast.error(data.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    const handledataexport = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(auditlogInfo);
        XLSX.utils.book_append_sheet(wb, ws, 'AuditLog');
        XLSX.writeFile(wb, 'AuditLog.xlsx');
    };

    return (
        <>
            <Grid item xs={12} sm={6}>
                <MainCard title="Audit Log">
                    <br />
                    <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                        <Button component={Link} to="/retail" type="submit" variant="contained" color="error">
                            Back
                        </Button>
                        <Button onClick={handledataexport} variant="contained" size="sm" className={classes.item} color="success">
                            Download Excel
                        </Button>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 450 }} aria-label="caption table">
                            <TableHead>
                                <TableRow>
                                    {loading ? (
                                        <TableCell component="th" colSpan="4" align="center">
                                            {' '}
                                            <CircularProgress size={20} thickness={4} />
                                            {'LOADING...... '}
                                        </TableCell>
                                    ) : (
                                        <>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Date/Time
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Changed By/Email
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Category
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Retailer
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Retailer phoneNumber
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Stage
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Relationship Manager
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Customer Success
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? auditlogInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : auditlogInfo
                                ).map((row) => (
                                    <TableRow key={row._id}>
                                        <TableCell component="th" scope="row">
                                            {toTimestamp(row?.createdAt)}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row?.updatedBy ?? ''}

                                            <br />
                                            {row?.updatedEmail ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.category}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.partnerName}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.partnerContact}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.stage}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row?.relationshipManager}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row?.customerSuccess}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        component="div"
                        count={auditlogInfo.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </MainCard>
            </Grid>
        </>
    );
};

export default RetailerAuditLog;
