import { initialState } from './retailer-context';
import {
    RETAILER_BEGIN,
    RETAILER_SUCCESS,
    RETAILER_INFO_BEGIN,
    RETAILER_INFO_SUCCESS,
    RETAILER_INFO_ERROR,
    FILTER_RETAILER_INFO_BEGIN,
    FILTER_RETAILER_INFO_SUCCESS,
    FILTER_RETAILER_INFO_ERROR,
    RETAILER_CHART_BEGIN,
    RETAILER_CHART_SUCCESS
} from './retailer-actions';

const retailerReducer = (state = initialState, action) => {
    switch (action.type) {
        case RETAILER_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case RETAILER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                retailers: action.payload.retailers
            };
        case 'SET_CURRENT_RETAILER':
            return {
                ...state,
                isLoading: false,
                currentRetailerId: action.payload.currentRetailerId,
                currentRetailerName: action.payload.currentRetailerName
            };
        case RETAILER_INFO_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case RETAILER_INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                cwtotalnumberoforders: action.payload.cwtotalnumberoforders,
                pwtotalnumberoforders: action.payload.pwtotalnumberoforders,
                ratetotalnumberoforders: action.payload.ratetotalnumberoforders,
                dateRangeTitle: action.payload.dateRangeTitle,
                cwtotalsales: action.payload.cwtotalsales,
                pwtotalsales: action.payload.pwtotalsales,
                ratesalespercentage: action.payload.ratesalespercentage,
                cwaverageordervalue: action.payload.cwaverageordervalue,
                pwaverageordervalue: action.payload.pwaverageordervalue,
                rateorderaveragepercentage: action.payload.rateorderaveragepercentage,
                topfiveparts: action.payload.topfiveparts,
                inventory: action.payload.inventory,
                todaybestseller: action.payload.todaybestseller,
                mostrecentorder: action.payload.mostrecentorder
            };
        case RETAILER_INFO_ERROR:
            return {
                ...state,
                isLoading: false
            };
        // FILTERING CASES
        case FILTER_RETAILER_INFO_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case FILTER_RETAILER_INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                cwtotalnumberoforders: action.payload.cwtotalnumberoforders,
                pwtotalnumberoforders: action.payload.pwtotalnumberoforders,
                ratetotalnumberoforders: action.payload.ratetotalnumberoforders,
                dateRangeTitle: action.payload.dateRangeTitle,
                cwtotalsales: action.payload.cwtotalsales,
                pwtotalsales: action.payload.pwtotalsales,
                ratesalespercentage: action.payload.ratesalespercentage,
                cwaverageordervalue: action.payload.cwaverageordervalue,
                pwaverageordervalue: action.payload.pwaverageordervalue,
                rateorderaveragepercentage: action.payload.rateorderaveragepercentage,
                topfiveparts: action.payload.topfiveparts,
                inventory: action.payload.inventory,
                todaybestseller: action.payload.todaybestseller,
                mostrecentorder: action.payload.mostrecentorder
            };
        case FILTER_RETAILER_INFO_ERROR:
            return {
                ...state,
                isLoading: false
            };
        case RETAILER_CHART_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case RETAILER_CHART_SUCCESS:
            return {
                ...state,
                isLoading: false,
                chartData: action.payload.sales
            };
        default:
            throw new Error(`No such action ${action.type}`);
    }
};

export default retailerReducer;
