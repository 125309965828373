/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

// material-ui
import { styled } from '@mui/material/styles';
import { Box, List, Typography } from '@mui/material';
import * as XLSX from 'xlsx';
import { makeStyles } from '@mui/styles';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import TotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

// styles
const CardWrapper = styled(MainCard)(() => ({
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        // background: `linear-gradient(210.04deg, ${theme.palette.warning.dark} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -180
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        // background: `linear-gradient(140.9deg, ${theme.palette.warning.dark} -14.02%, rgba(144, 202, 249, 0) 70.50%)`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
}));

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 3
    },
    colors: {
        color: `#ED7C1B`
    },
    colors2: {
        color: '#000000',
        fontWeight: 'bold'
    },
    colors3: {
        color: '#000000',
        fontWeight: 'bold'
    },
    backgroundColor: {
        backgroundColor: '#2CB23B'
    }
}));

// ==============================|| DASHBOARD - TOTAL ORDERS ||============================== //

const TotalCollectionPrinceCard = ({ isLoading, collectionsdata, title, date, excelName, excelName2 }) => {
    // const theme = useTheme();
    const classes = useStyles();

    function toTimestamp(strDate) {
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    const handledataexport = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(collectionsdata);
        XLSX.utils.book_append_sheet(wb, ws, `${excelName}`);
        XLSX.writeFile(wb, `${excelName2}`);
    };

    function totalcount() {
        return collectionsdata.map(({ collectedAmt }) => collectedAmt).reduce((sum, i) => sum + i, 0);
    }
    const sumtotalcount = totalcount();

    return (
        <>
            {isLoading ? (
                <TotalIncomeCard />
            ) : (
                <CardWrapper border={false} content={false}>
                    <Box sx={{ p: 1 }}>
                        <List sx={{ py: 0 }}>
                            <Typography variant="h3" component="div" textAlign="center" className={classes.colors}>
                                {title}
                                <br />
                                {date}
                            </Typography>
                            <br />
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>..</Grid>
                                <Grid item>
                                    <Button onClick={handledataexport} variant="contained" size="sm" className={classes.backgroundColor}>
                                        Excel
                                    </Button>
                                </Grid>
                            </Grid>

                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    {isLoading ? (
                                                        <TableCell component="th" colSpan="4" align="center">
                                                            {' '}
                                                            <CircularProgress />{' '}
                                                        </TableCell>
                                                    ) : (
                                                        <>
                                                            <TableCell align="left">#</TableCell>
                                                            <TableCell align="left">Date</TableCell>
                                                            <TableCell align="left">SalesAssociate</TableCell>
                                                            <TableCell align="left">Retailer</TableCell>
                                                            <TableCell align="left">Amount</TableCell>
                                                        </>
                                                    )}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {collectionsdata.map((row, index) => (
                                                    <TableRow key={row._id}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {toTimestamp(row?.collectedDate) ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row.salesname ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.channelPartnerId ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {Number(row.collectedAmt).toFixed(2) ?? ''}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow>
                                                    <TableCell rowSpan={1} />
                                                    <TableCell component="th" scope="row" className={classes.colors2} colSpan={3}>
                                                        SUM
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" className={classes.colors3}>
                                                        {Number(sumtotalcount).toFixed(2)}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </List>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

TotalCollectionPrinceCard.propTypes = {
    isLoading: PropTypes.bool
};

export default TotalCollectionPrinceCard;
