// components/ChatFilter.jsx
import React from 'react';
import { Button, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const ChatFilter = ({ chatFilter, setChatFilter, searchTerm, setSearchTerm }) => {
    const filterItems = [
        {
            label: 'All',
            value: 'all'
        },
        {
            label: 'Read',
            value: 'read'
        },
        {
            label: 'Unread',
            value: 'unread'
        },
        // {
        //     label: 'Priority',
        //     value: 'priority'
        // },
        {
            label: 'Expiring',
            value: 'expiring'
        }
    ];

    return (
        <div className="search-container">
            <div>
                <Input
                    type="text"
                    placeholder="Search customer name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input flex-grow-1"
                    prefix={<SearchOutlined />}
                />
                <div className="filter-item">
                    {filterItems.map((item, index) => (
                        <Button
                            key={index}
                            onClick={() => setChatFilter(item.value)}
                            className={`filter-button ${chatFilter === item.value ? 'active-filter' : ''}`}
                        >
                            {item.label}
                        </Button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ChatFilter;
