// import dashboard from './dashboard';

import sales from './sales';
import other from './other';
// import salesreport from './salesreport';

// ==============================|| MENU ITEMS dashboard ||============================== //

const salesItems = {
    items: [sales, other]
};

export default salesItems;
