import { AiFillFilePdf } from 'react-icons/ai';
import BatchHeaderWrapper from 'assets/Wrappers/BatchHeaderWrapper';
import CompressedTable from './CompressedTable';
import { Container } from 'react-grid-system';
import DetailsTable from './DetailTable';
import IconButton from '@mui/material/IconButton';
import MoonLoader from 'react-spinners/MoonLoader';
import { Paper } from '@mui/material';
import React from 'react';
import { SiMicrosoftexcel } from 'react-icons/si';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import styled from 'styled-components';
import { useBatchContext } from 'context/order-batching/batchContext';
import { useParams } from 'react-router-dom';

dayjs.extend(localizedFormat);

const SingleBatchWrapper = styled.div`
    margin-top: 30px;
    .custom-paper {
        font-family: 'Roboto', 'sans-serif';
        padding: 3rem;
        .table {
            max-width: 75rem;
            margin: 0 auto;
        }
        .table td {
            padding: 1rem;
        }

        .batch-description {
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 1rem 2rem;

            h4 {
                color: #ff740f;
                font-weight: 800;
            }
        }
        .icon-actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 2rem;

            .icon-phone {
                color: #ff740f;
            }
        }

        @media screen and (max-width: 500px) {
            .batch-description {
                flex-direction: column;
                h4 {
                    font-size: 1rem;
                }
            }
        }
    }
`;

const OrderBatchView = () => {
    const { getBatchDataById, singleBatchContent, isLoading, handlePdfPreview, handlePdfPreviewDetailed, handleExcelDataExport } =
        useBatchContext();
    const { id } = useParams();

    const [view, setView] = React.useState('details');

    const varyExcelExport = view === 'details' ? singleBatchContent?.ordersDetails : singleBatchContent?.orderCompressed;

    React.useEffect(() => {
        getBatchDataById(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <BatchHeaderWrapper>
                <Paper elevation={0} className="custom-paper">
                    <Container>
                        <div className="header-wrapper">
                            <div>
                                <h4>Fulfillment | Order Batching</h4>
                                <h5>Home | Fullfillment | Order Batching | Dashboard</h5>
                            </div>
                            <div>
                                <select className="form-select custom-select" onChange={(e) => setView(e.target.value)} value={view}>
                                    <option value="compressed">Compressed view</option>
                                    <option value="details">Detailed view</option>
                                </select>
                            </div>
                        </div>
                    </Container>
                </Paper>
            </BatchHeaderWrapper>
            <SingleBatchWrapper>
                <Paper elevation={0} className="custom-paper">
                    <div className="icon-actions">
                        <Stack direction="row" spacing={0}>
                            {view === 'compressed' ? (
                                <>
                                    <IconButton
                                        aria-label="call lead phone"
                                        onClick={() => handlePdfPreview(singleBatchContent)}
                                        title="Generate Pdf"
                                    >
                                        <a href="#!" className="icon-phone">
                                            <AiFillFilePdf />
                                        </a>
                                    </IconButton>
                                </>
                            ) : (
                                ''
                            )}

                            {view === 'details' ? (
                                <>
                                    <IconButton
                                        aria-label="call lead phone"
                                        onClick={() => handlePdfPreviewDetailed(singleBatchContent)}
                                        title="Generate Pdf"
                                    >
                                        <a href="#!" className="icon-phone">
                                            <AiFillFilePdf />
                                        </a>
                                    </IconButton>
                                </>
                            ) : (
                                ''
                            )}
                            <IconButton
                                aria-label="call lead phone"
                                onClick={() => handleExcelDataExport(varyExcelExport)}
                                title="Generate Excel"
                            >
                                <a href="#!" className="icon-phone">
                                    <SiMicrosoftexcel />
                                </a>
                            </IconButton>
                        </Stack>
                    </div>
                    <div className="batch-description">
                        <div className="batch-no">
                            <h4>Batch No: {singleBatchContent?.batchNo}</h4>
                        </div>
                        <div className="batch-date">
                            <h4> {dayjs(singleBatchContent?.batchDate).format('l')}</h4>
                        </div>
                    </div>
                    {isLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <MoonLoader color="#ff740f" />
                        </div>
                    ) : (
                        <>
                            {view === 'compressed' && <CompressedTable orderCompressed={singleBatchContent?.orderCompressed} />}
                            {view === 'details' && <DetailsTable ordersDetails={singleBatchContent?.ordersDetails} />}
                        </>
                    )}
                    <br /> <br />
                </Paper>
            </SingleBatchWrapper>
        </>
    );
};
export default OrderBatchView;
