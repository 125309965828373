import React from 'react';
import MomoTransaction from './MomoTransactions';
import BankTransaction from './Transactions';

const userCountry = localStorage.getItem('country');
export const Index = () => {
    return <div>{userCountry === 'Ghana' ? <MomoTransaction /> : <BankTransaction />}</div>;
};

export default Index;
