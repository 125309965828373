import { useEffect, useState } from 'react';
import { Row, Col } from 'react-grid-system';
import { Paper, Divider, Button, MenuItem } from '@mui/material';
import styled from 'styled-components';
import GenericPageHeader from 'components/GenericPageHeader';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
// import { useDeliveryContext } from 'context/delivery-notes/delivery-context';
import { useParams } from 'react-router-dom';
// import { BsPatchCheck } from 'react-icons/bs';
import { MdModeEdit } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { authFetch } from 'utils/axios-config';
import { errorToast, successToast } from 'utils/toast';
import LoadingButton from '@mui/lab/LoadingButton';
import Modal from 'react-bootstrap/Modal';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { ObjectID } from 'bson';
import SurroundText from './SurroundText';
import Autocomplete from '@mui/material/Autocomplete';

const OrderWrapper = styled.div`
    .order-page {
        margin-top: 50px;

        h4 {
            padding: 20px 50px;
            color: #ff740f;
            font-weight: 600;
        }
        h5 {
            color: #ff740f;
            font-weight: 800;
            padding: 1rem 2rem;
        }

        .content {
            padding: 20px 50px;
        }

        .add-part {
            background: #ff740f;
            white-space: nowrap;
        }

        .generate-note {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
        }

        @media screen and (max-width: 500px) {
            .generate-note {
                flex-direction: column;
            }
        }

        .parts-table {
            padding: 20px 50px;
            .table {
                padding: 2rem 4rem;

                td {
                    padding: 1rem;
                }
            }
        }
    }
`;
const ModalWrapper = styled.div`
    .modal-actions {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 30px;
    }

    .title {
        color: #ff740f;
        font-weight: 700;
        text-align: center;
        padding: 1rem 0;
    }

    .cancel-btn {
        background-color: #193855;
        margin-left: -3rem;
    }

    .save-btn {
        background-color: #ff740f;
        margin-right: -2rem;
    }

    .content {
        padding: 1rem 2rem;
        margin-bottom: 50px;
    }
`;

const UpdateNote = () => {
    /** Add part modal states */
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { id } = useParams();

    const [isLoading, setLoading] = useState(false);
    const [singleNote, setSingleNote] = useState({});
    const { retailerId, noteNo, userId, orderId } = singleNote;

    const [systemParts, setSystemParts] = useState([]);
    const [qty, setQty] = useState(0);
    const [partid, setPartId] = useState('');
    const [partType, setPartType] = useState('');
    const [garagePrice, setPrice] = useState(0);
    const [wholeSalePrice, setWholeSalePrice] = useState(0);

    const uniqueId = new ObjectID();
    const newPart = {
        partid,
        qty,
        partType,
        garagePrice,
        skuId: uniqueId.toHexString(),
        wholeSalePrice,
        status: true
    };

    const [parts, setParts] = useState([]);

    const addNewPart = () => {
        setParts([{ ...newPart }, ...parts]);
        setQty(0);
        setPartId('');
        setPartType('');
        setPrice(0);
        handleClose();
        setTimeout(() => {
            successToast('New part added successfully');
        }, 1000);
    };

    const getSingleNote = async (id) => {
        try {
            const { data } = await authFetch.get(`/deliveryautomation/viewdeliverynote/${id}`);
            console.log(data.data);
            setSingleNote(data.data);
            setParts(data.data?.parts);
        } catch (error) {
            const { message } = error.response.data;
            if (error.response.status !== 401) {
                errorToast(message);
            }
        }
    };

    /** Get System parts */
    const getSystemParts = async () => {
        try {
            const { data } = await authFetch('/deliveryautomation/allpartdata');
            setSystemParts(data.data);
        } catch (error) {
            const { message } = error.response.data;
            if (error.response.status !== 401) {
                errorToast(message);
            }
        }
    };

    useEffect(() => {
        getSingleNote(id);
        getSystemParts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [removedParts, setremovedParts] = useState([]);

    const handlePartRemoval = (id) => {
        const filteredItem = parts.filter((item) => item.skuId === id);
        removedParts.push([...filteredItem][0]);
        const updatedParts = parts.filter((item) => item.skuId !== id);
        setParts(updatedParts);
        successToast('Item removed from notes successfully');
    };

    const noteObject = {
        id,
        orderId: orderId?._id,
        retailerId: retailerId?._id,
        salesPersonId: userId?._id,
        deliverynoteDate: Date.now(),
        parts,
        partsRemoved: removedParts
    };

    const handleNoteUpdate = async () => {
        setLoading(true);
        try {
            await authFetch.put('/deliveryautomation/updatedeliverynoteadmin', noteObject);
            setLoading(false);
            successToast('Note updated successfully');
            setTimeout(() => {
                window.location.href = '/orders/deliveries/notes';
            }, 3000);
        } catch (error) {
            const { message } = error.response.data;
            if (error.response.status !== 401) {
                errorToast(message);
                setLoading(false);
            }
        }
    };

    const handleNewPart = () => {
        handleShow();
    };
    /** Edit part modal states */
    const [prevQty, setPrevQty] = useState(0);
    const [prevPartid, setPrevPartId] = useState('');
    const [prevPartType, setPrevPartType] = useState('');
    const [prevGaragePrice, setPrevPrice] = useState(0);
    const [editStatus, setEditStatus] = useState(false);
    const [editId, setEditId] = useState('');
    const [prevWholeSalePrice, setPrevWholeSalePrice] = useState(0);

    const [showEdit, setShowEdit] = useState(false);
    const handleEditClose = () => setShowEdit(false);
    const handleEditShow = () => setShowEdit(true);

    const handlePartEdit = (item) => {
        handleEditShow();
        setPrevPartId(item?.partid);
        setPrevPartType(item?.partType);
        setPrevPrice(item?.garagePrice);
        setPrevQty(item?.qty);
        setEditId(item?.skuId);
        setEditStatus(item?.status);
        setPrevWholeSalePrice(item?.wholeSalePrice);
    };

    const EditPart = () => {
        const newParts = parts.map((part) => {
            if (part?.skuId === editId) {
                return {
                    ...part,
                    partid: prevPartid,
                    qty: prevQty,
                    partType: prevPartType,
                    garagePrice: prevGaragePrice,
                    status: editStatus,
                    wholeSalePrice: prevWholeSalePrice
                };
            }
            return { ...part };
        });
        setParts(newParts);
        handleEditClose();
        successToast('Part updated successfully');
    };

    return (
        <>
            <GenericPageHeader title="Create Delivery Note" breadcrumbs="Home | Orders | Create Delivery Note" />
            <OrderWrapper>
                <Paper elevation={0} className="order-page" sx={{ width: '100%', overflow: 'hidden' }}>
                    <h4>Update Delivery Note</h4>
                    <Divider />
                    <div className="content">
                        <Row>
                            <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                <SurroundText content={retailerId?.partnerName} label="Customer Name" />
                                <SurroundText content={retailerId?.partnerContact} label="Customer Contact" />
                                <SurroundText content={retailerId?.location} label="Address" />
                                <div>
                                    <Button
                                        variant="contained"
                                        className="add-part"
                                        size="large"
                                        onClick={handleNewPart}
                                        style={{ marginBottom: '10px' }}
                                    >
                                        Add new part
                                    </Button>
                                </div>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                                <SurroundText content={noteNo} label="Note No" />
                                <SurroundText content={orderId?.salesname} label="Sales Person" />
                            </Col>
                        </Row>
                    </div>
                    <div className="parts-table">
                        <Paper elevation={2}>
                            <h5>Selects parts from order</h5>
                            <div className="table-responsive">
                                <table className="table  table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Decription</th>
                                            <th scope="col">Quantity Dashboard</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {parts.map((item, index) => {
                                            const { skuId, partid, qty, status, partType } = item;
                                            const tableBg = status === false && '#ff740f';
                                            return (
                                                <tr key={index} style={{ backgroundColor: tableBg }}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{partid}</td>
                                                    <td>
                                                        {qty} {partType}
                                                    </td>
                                                    <td>
                                                        <Stack direction="row" spacing={0}>
                                                            <IconButton
                                                                aria-label="Remove part from note"
                                                                title="Remove part from note"
                                                                onClick={() => handlePartRemoval(skuId)}
                                                            >
                                                                <a href="#!" style={{ color: '#E64848' }}>
                                                                    <RiDeleteBin6Line />
                                                                </a>
                                                            </IconButton>
                                                            <IconButton
                                                                aria-label="Edit Part"
                                                                title="Edit Part"
                                                                onClick={() => handlePartEdit(item)}
                                                            >
                                                                <a href="#!" style={{ color: '#3FA796' }}>
                                                                    <MdModeEdit />
                                                                </a>
                                                            </IconButton>
                                                        </Stack>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </Paper>
                        <div className="generate-note">
                            <Button
                                variant="contained"
                                href="/orders/deliveries/notes"
                                style={{ backgroundColor: '#193855', marginBottom: '15px' }}
                            >
                                &larr; Go back
                            </Button>
                            <LoadingButton
                                onClick={handleNoteUpdate}
                                size="large"
                                loading={isLoading}
                                variant="contained"
                                className="add-part"
                                disabled={isLoading}
                            >
                                Update delivery note
                            </LoadingButton>
                        </div>
                    </div>
                </Paper>
            </OrderWrapper>
            <Modal show={show} onHide={handleClose} centered>
                <ModalWrapper>
                    <Modal.Title>
                        <h5 className="title">Add a new part</h5>
                        <hr />
                    </Modal.Title>
                    <Modal.Body>
                        <div className="content">
                            <FormControl fullWidth>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    value={partid}
                                    onChange={(event, newValue) => {
                                        setPartId(newValue);
                                        const price = systemParts.find((item) => item?.sku === newValue);
                                        setPrice(price?.garagePrice);
                                        setWholeSalePrice(price?.wholeSalePrice);
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        setPartId(newInputValue);
                                        const price = systemParts.find((item) => item?.sku === newInputValue);
                                        setPrice(price?.garagePrice);
                                        setWholeSalePrice(price?.wholeSalePrice);
                                    }}
                                    freeSolo
                                    options={systemParts.map((option) => option?.sku)}
                                    isOptionEqualToValue={(option, value) => option?.sku === value?.sku}
                                    renderInput={(params) => <TextField {...params} label="Select or enter part not in system" />}
                                />
                            </FormControl>
                            <br /> <br />
                            <FormControl fullWidth>
                                <TextField
                                    id="outlined-basic"
                                    label="Quantity"
                                    variant="outlined"
                                    type="number"
                                    value={qty}
                                    onChange={(e) => setQty(e.target.value)}
                                />
                            </FormControl>
                            <br /> <br />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select2-label">Select size</InputLabel>
                                <Select
                                    labelId="demo-simple-select2-label"
                                    id="demo-simple-select2"
                                    value={partType}
                                    label="Select size"
                                    onChange={(e) => {
                                        setPartType(e.target.value);
                                    }}
                                >
                                    <MenuItem value="Body">Select an option</MenuItem>
                                    <MenuItem value="Boxes">Boxe(s)</MenuItem>
                                    <MenuItem value="Pieces">Pieces</MenuItem>
                                    <MenuItem value="Gallons">Gallons</MenuItem>
                                    <MenuItem value="Drums">Drums</MenuItem>
                                    <MenuItem value="Rolls">Roll(s)</MenuItem>
                                    <MenuItem value="Bucket">Bucket</MenuItem>
                                    <MenuItem value="Packs">Pack(s)</MenuItem>
                                    <MenuItem value="Litre">Litre(s)</MenuItem>
                                    <MenuItem value="Pair">Pair(s)</MenuItem>
                                    <MenuItem value="Carton">Carton(s)</MenuItem>
                                </Select>
                            </FormControl>
                            <br /> <br />
                            <FormControl fullWidth>
                                <TextField
                                    id="outlined-basic"
                                    label="Price"
                                    variant="outlined"
                                    type="number"
                                    value={garagePrice}
                                    onChange={(e) => setPrice(e.target.value)}
                                />
                            </FormControl>
                        </div>
                    </Modal.Body>
                    <div className="modal-actions">
                        <Button variant="contained" onClick={handleClose} className="cancel-btn">
                            Cancel
                        </Button>
                        <LoadingButton loading={isLoading} variant="contained" className="save-btn" onClick={addNewPart}>
                            Save
                        </LoadingButton>
                    </div>
                </ModalWrapper>
            </Modal>
            {/* Edit Modal */}
            <Modal show={showEdit} onHide={handleEditClose} centered>
                <ModalWrapper>
                    <Modal.Title>
                        <h5 className="title">Edit part</h5>
                        <hr />
                    </Modal.Title>
                    <Modal.Body>
                        <div className="content">
                            <FormControl fullWidth>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    value={prevPartid}
                                    onChange={(event, newValue) => {
                                        setPrevPartId(newValue);
                                        const price = systemParts.find((item) => item?.sku === newValue);
                                        setPrevPrice(price?.garagePrice);
                                        setPrevWholeSalePrice(price?.wholeSalePrice);
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        setPrevPartId(newInputValue);
                                        const price = systemParts.find((item) => item?.sku === newInputValue);
                                        setPrevPrice(price?.garagePrice);
                                        setPrevWholeSalePrice(price?.wholeSalePrice);
                                    }}
                                    options={systemParts.map((option) => option?.sku)}
                                    freeSolo
                                    isOptionEqualToValue={(option, value) => option?.sku === value?.sku}
                                    renderInput={(params) => <TextField {...params} label="Select or enter part not in system" />}
                                />
                            </FormControl>
                            <br /> <br />
                            <FormControl fullWidth>
                                <TextField
                                    id="outlined-basic"
                                    label="Quantity"
                                    variant="outlined"
                                    type="number"
                                    value={prevQty}
                                    onChange={(e) => setPrevQty(e.target.value)}
                                />
                            </FormControl>
                            <br /> <br />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select2-label">Select size</InputLabel>
                                <Select
                                    labelId="demo-simple-select2-label"
                                    id="demo-simple-select2"
                                    value={prevPartType}
                                    label="Select size"
                                    onChange={(e) => setPrevPartType(e.target.value)}
                                >
                                    <MenuItem value="Body">Select an option</MenuItem>
                                    <MenuItem value="Boxes">Boxe(s)</MenuItem>
                                    <MenuItem value="Pieces">Pieces</MenuItem>
                                    <MenuItem value="Gallons">Gallons</MenuItem>
                                    <MenuItem value="Drums">Drums</MenuItem>
                                    <MenuItem value="Rolls">Roll(s)</MenuItem>
                                    <MenuItem value="Bucket">Bucket</MenuItem>
                                    <MenuItem value="Packs">Pack(s)</MenuItem>
                                    <MenuItem value="Litre">Litre(s)</MenuItem>
                                    <MenuItem value="Pair">Pair(s)</MenuItem>
                                    <MenuItem value="Carton">Carton(s)</MenuItem>
                                </Select>
                            </FormControl>
                            <br /> <br />
                            <FormControl fullWidth>
                                <TextField
                                    id="outlined-basic"
                                    label="Price"
                                    variant="outlined"
                                    type="number"
                                    value={prevGaragePrice}
                                    onChange={(e) => setPrevPrice(e.target.value)}
                                />
                            </FormControl>
                        </div>
                    </Modal.Body>
                    <div className="modal-actions">
                        <Button variant="contained" onClick={handleEditClose} className="cancel-btn">
                            Cancel
                        </Button>
                        <LoadingButton loading={isLoading} variant="contained" className="save-btn" onClick={EditPart}>
                            Update
                        </LoadingButton>
                    </div>
                </ModalWrapper>
            </Modal>
        </>
    );
};

export default UpdateNote;
