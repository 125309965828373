import React, { useEffect, useState } from 'react';
import { Button, Select, Table, DatePicker, Spin, Skeleton, Tag, Modal, Divider } from 'antd';
import { decrypt, encrypt } from '../../utils/encrypt';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
// eslint-disable-next-line import/no-named-as-default
import ViewPreSoldOrder from '../../components/presold/ViewPreSoldOrder';
import * as XLSX from 'xlsx';
import ApiUrl from 'network';

export const Index = () => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    const initialState = {
        startDate: '',
        endDate: '',
        country: ''
    };
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [filter, setFilter] = useState(initialState);
    const { RangePicker } = DatePicker;
    const [countries, setCountries] = useState([]);
    const [orderModal, setOrderModal] = useState(false);
    const [selectedData, setSelectData] = useState([]);

    const fetchOrders = async (data) => {
        setLoading(true);
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/waybill/presoldorders`;

            if (data) {
                if (data.startDate && data.endDate) {
                    urlParams.set('startDate', data.startDate);
                    urlParams.set('endDate', data.endDate);
                }
                if (data.country) {
                    urlParams.set('country', data.country);
                }
            } else {
                const savedParamsString = localStorage.getItem('saveParams');
                let savedParams;

                try {
                    savedParams = savedParamsString ? JSON.parse(savedParamsString) : {};
                } catch (error) {
                    savedParams = {};
                }

                if (savedParams.startDate && savedParams.endDate) {
                    urlParams.set('startDate', savedParams.startDate);
                    urlParams.set('endDate', savedParams.endDate);
                }
                if (savedParams.country) {
                    urlParams.set('country', savedParams.country);
                }
            }
            if (urlParams.toString()) url += `?${urlParams.toString()}`;

            const response = await fetch(url, {
                method: 'GET',
                headers: config.headers
            });

            const responseData = await response.json();

            if (responseData.status === true) {
                const orders = decrypt(JSON.stringify(responseData.data), keyResult, ivResult);
                setOrders(JSON.parse(orders));
                setLoading(false);
            } else {
                toast.error(responseData.message);
                setLoading(false);
            }
        } catch (e) {
            toast.error(e.message);
            setLoading(false);
        }
    };
    const fetchCountries = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(`${ApiUrl.BaseUrl}/country/activecountry`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error('Something went wrong');
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        fetchOrders();
        fetchCountries();
    }, []);

    const orderList = orders?.map((order) => {
        return {
            key: order._id,
            orderNo: order.orderNo,
            rma: order.rma,
            costValue: order.costValue,
            salesValue: order.salesValue,
            retailer: order.retailer,
            orderStatus: order.orderStatus,
            items: order.items,
            placeBy: order.placeBy,
            country: order.country,
            orderDate: new Date(order.orderDate).toLocaleDateString('en-US', options)
        };
    });
    const countryOption = countries?.map((result) => ({
        value: result.name,
        label: result.name
    }));

    const checkOrderStatus = (record) => {
        if (record.orderStatus === 'PreSold') {
            return <Tag color="orange">{record.orderStatus}</Tag>;
        }
        if (record.orderStatus === 'Batched') {
            return <Tag color="green">{record.orderStatus}</Tag>;
        }
        return <Tag color="gray">{record.orderStatus}</Tag>;
    };

    const columns = [
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country'
        },
        {
            title: 'Date',
            dataIndex: 'orderDate',
            key: 'orderDate'
        },
        {
            title: 'Order No',
            dataIndex: 'orderNo',
            key: 'orderNo'
        },
        {
            title: 'RMA',
            dataIndex: 'rma',
            key: 'rma'
        },
        {
            title: 'Cost Value',
            dataIndex: 'costValue',
            key: 'costValue'
        },
        {
            title: 'Sales Value',
            dataIndex: 'salesValue',
            key: 'salesValue'
        },
        {
            title: 'Retailer',
            dataIndex: 'retailer',
            key: 'retailer'
        },
        {
            title: 'Placed By',
            dataIndex: 'placeBy',
            key: 'placeBy'
        },
        {
            title: 'Status',
            dataIndex: 'orderStatus',
            key: 'orderStatus',
            render: (_, record) => checkOrderStatus(record)
        }
    ];

    const onSelectChange = (newSelectedRowKeys, record) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectData(record);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
    };
    const hasSelected = selectedRowKeys.length > 0;

    const saveFilterToLocalStorage = (filterData) => {
        const savedParamsString = localStorage.getItem('saveParams');
        let savedParams;

        try {
            savedParams = savedParamsString ? JSON.parse(savedParamsString) : {};
        } catch (error) {
            savedParams = {};
        }

        const updatedParams = { ...savedParams, ...filterData };
        localStorage.setItem('saveParams', JSON.stringify(updatedParams));
    };

    const handleDateChange = (date, dateString) => {
        setFilter((prevFilter) => ({ ...prevFilter, startDate: dateString[0], endDate: dateString[1] }));
        saveFilterToLocalStorage({ startDate: dateString[0], endDate: dateString[1] });
    };

    const handleCountryChange = (value) => {
        setFilter((prevFilter) => ({ ...prevFilter, country: value }));
        saveFilterToLocalStorage({ country: value });
    };

    const BatchPresoldHandlder = async () => {
        setIsLoading(true);
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        const datas = {
            orderInfo: selectedRowKeys
        };
        const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);

        await fetch(`${ApiUrl.BaseUrl}/waybill/presoldbatchorder`, {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify({ payload })
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    toast.success('Batch Presold Order Created Successfully');
                    fetchOrders();
                    setIsLoading(false);
                    setSelectedRowKeys([]);
                } else {
                    const error = decrypt(JSON.stringify(data.data), keyResult, ivResult);
                    toast.error(error);
                    setIsLoading(false);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const exportOrdersHandler = () => {
        const orderCompressed = orders?.map((order) => {
            return {
                OrderNo: order.orderNo,
                RMA: order.rma,
                CostValue: order.costValue,
                SalesValue: order.salesValue,
                Retailer: order.retailer,
                OrderStatus: order.orderStatus,
                Items: order.items,
                PlaceBy: order.placeBy,
                Country: order.country,
                OrderDate: new Date(order.orderDate).toLocaleDateString('en-US', options)
            };
        });
        if (orderCompressed && orderCompressed.length > 0) {
            // Create a new workbook
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(orderCompressed);
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, 'PreSoldOrders.xlsx');
        } else {
            toast.error('No Data to Export');
        }
    };

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center batchFilters">
                <div>
                    <h1 style={{ fontSize: '30px' }}>PreSold Orders</h1>
                    <p>
                        <strong>PreSold Orders</strong> - List of all PreSold Orders
                    </p>
                </div>
                <div className="pt-4 pb-4">
                    <div className="d-flex align-items-center justify-content-space-between flex-row-reverse batchFilters">
                        <Button
                            className="ml-2"
                            type="primary"
                            onClick={exportOrdersHandler}
                            style={{
                                backgroundColor: '#233E4C',
                                color: '#fff'
                            }}
                        >
                            Export Pre-Sold
                        </Button>
                        <Button
                            className="ml-2"
                            type="primary"
                            onClick={BatchPresoldHandlder}
                            disabled={!hasSelected}
                            loading={isLoading}
                            style={{
                                backgroundColor: !hasSelected ? '#ECECEC' : '#FF740F',
                                border: `1px solid ${!hasSelected ? '#ECECEC' : '#FF740F'}`,
                                color: !hasSelected ? '#000' : '#fff'
                            }}
                        >
                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                            Batch Pre-Sold
                        </Button>
                        <Button
                            type="primary"
                            disabled={!hasSelected}
                            style={{
                                backgroundColor: !hasSelected ? '#ECECEC' : '#fff',
                                border: `1px solid ${!hasSelected ? '#ECECEC' : '#FF740F'}`,
                                color: !hasSelected ? '#000' : '#FF740F'
                            }}
                            onClick={() => setOrderModal(true)}
                        >
                            Pre-Sold Items
                        </Button>
                    </div>
                </div>
            </div>
            <div className="batchFilters bg-white p-2 mb-4">
                <RangePicker
                    format="YYYY-MM-DD"
                    className="datePicker filterInput"
                    onChange={handleDateChange}
                    value={
                        filter.startDate && filter.endDate
                            ? [dayjs(filter.startDate, 'YYYY-MM-DD'), dayjs(filter.endDate, 'YYYY-MM-DD')]
                            : localStorage.getItem('saveParams') &&
                              JSON.parse(localStorage.getItem('saveParams')).startDate &&
                              JSON.parse(localStorage.getItem('saveParams')).endDate
                            ? [
                                  dayjs(JSON.parse(localStorage.getItem('saveParams')).startDate, 'YYYY-MM-DD'),
                                  dayjs(JSON.parse(localStorage.getItem('saveParams')).endDate, 'YYYY-MM-DD')
                              ]
                            : null
                    }
                />

                <div>
                    <Select
                        showSearch
                        allowClear
                        placeholder="Filter By Country"
                        filterOption={(inputValue, option) => {
                            if (typeof option.label === 'string') {
                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }

                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        }}
                        className=" p-2 filterInput"
                        options={countryOption}
                        onChange={handleCountryChange}
                        value={
                            filter.country
                                ? filter.country
                                : localStorage.getItem('saveParams')
                                ? JSON.parse(localStorage.getItem('saveParams')).country
                                : null
                        }
                    />
                </div>
                <div className="d-flex">
                    <Button
                        className="mr-2"
                        style={{
                            color: '#fff',
                            border: '1px solid #FF740F',
                            backgroundColor: '#FF740F',
                            width: '150px'
                        }}
                        onClick={() => fetchOrders(filter)}
                    >
                        Search
                    </Button>
                    {localStorage.getItem('saveParams') && (
                        <Button
                            onClick={() => {
                                localStorage.removeItem('saveParams');
                                setFilter(initialState);
                                fetchOrders();
                            }}
                            className="filterInput"
                            style={{
                                color: '#FF740F',
                                border: '1px solid #FF740F',
                                width: '150px'
                            }}
                        >
                            Clear Filters
                        </Button>
                    )}
                </div>
            </div>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                    <Skeleton />
                </div>
            ) : (
                <div className="table-responsive">
                    <Table rowSelection={rowSelection} columns={columns} dataSource={orderList} />
                </div>
            )}
            <Modal title="Pre-Sold Items" open={orderModal} onCancel={() => setOrderModal(false)} footer={null} width={800}>
                {selectedData.map((data, index) => (
                    <div>
                        <ViewPreSoldOrder
                            orderList={data}
                            key={index + Math.random()}
                            Table={Table}
                            columns={[
                                {
                                    title: 'Part Name',
                                    dataIndex: 'partid',
                                    key: 'partid'
                                },
                                {
                                    title: 'Quantity',
                                    dataIndex: 'qty',
                                    key: 'qty'
                                },
                                {
                                    title: 'Part Qty In Pieces',
                                    dataIndex: 'partQtyInPieces',
                                    key: 'partQtyInPieces'
                                },
                                {
                                    title: 'Garage Price',
                                    dataIndex: 'garagePrice',
                                    key: 'garagePrice'
                                },
                                {
                                    title: 'whole Sale Price',
                                    dataIndex: 'wholeSalePrice',
                                    key: 'wholeSalePrice'
                                },
                                {
                                    title: 'State',
                                    dataIndex: 'state',
                                    key: 'state'
                                },
                                {
                                    title: 'Supplier',
                                    dataIndex: 'supplier',
                                    key: 'supplier'
                                },
                                {
                                    title: 'Image',
                                    dataIndex: 'partimage',
                                    key: 'partimage',
                                    render: (_, record) => (
                                        <img
                                            style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '10px' }}
                                            src={record.partimage}
                                            alt={record.partimage}
                                        />
                                    )
                                }
                            ]}
                        />
                        {index < selectedData.length - 1 && <Divider key={index} />}
                    </div>
                ))}
            </Modal>
        </div>
    );
};
export default Index;
