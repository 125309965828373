import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Button, Select, Progress, Input } from 'antd';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { decrypt } from '../../../utils/encrypt';
import { toast } from 'react-toastify';
import 'react-circular-progressbar/dist/styles.css';
import { useParams } from 'react-router-dom';
import ApiUrl from '../../../network';

const actionHandler = (record) => {
    window.location.href = `/salesplan/edit/${record.key}`;
};

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Role',
        dataIndex: 'role',
        key: 'role'
    },
    {
        title: 'GMV',
        dataIndex: 'gmv',
        key: 'gmv'
    },
    {
        title: 'Conversion',
        dataIndex: 'conversion',
        key: 'conversion'
    },
    {
        title: 'Retention',
        dataIndex: 'retention',
        key: 'retention'
    },
    {
        title: 'Created By',
        dataIndex: 'createdBy',
        key: 'createdBy'
    },
    {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (_, record) => (
            <div>
                <Button
                    onClick={() => actionHandler(record)}
                    style={{ backgroundColor: '#FFF', border: '1px solid #FF740F' }}
                    className="mt-2 mb-2 d-flex align-items-center justify-content-center"
                    title="Edit schedule"
                >
                    <Typography sx={{ margin: '0 14px ', color: '#FF740F' }}>More Details</Typography>
                </Button>
            </div>
        )
    }
];
export const Index = () => {
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [kpiData, setKpiData] = useState({});
    const { id } = useParams();
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    const fetchSingleKpi = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/kpiplan/viewkpidetails?id=${id}`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setKpiData(result);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        toast.error(error.message);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    const filterOptions = [
        {
            label: 'All',
            value: 'all'
        },
        {
            label: 'Today',
            value: 'today'
        },
        {
            label: 'This Week',
            value: 'thisWeek'
        },
        {
            label: 'This Month',
            value: 'thisMonth'
        },
        {
            label: 'This Year',
            value: 'thisYear'
        }
    ];

    useEffect(() => {
        if (id) {
            fetchSingleKpi();
        } else {
            window.location.href = '/kpi';
            toast.error('Please select sales plan to edit');
        }
    }, []);
    const filterPodData = (podId) => {
        // Create the data object
        const data = {
            pod: podId
        };

        // Call fetchSalesPlanData with the data object
        fetchSingleKpi(data);
    };

    return (
        <div>
            <div className="d-flex align-items-center justify-content-between p-2 mb-4 bg-white">
                <p style={{ fontSize: '20px', marginTop: '5px' }}>View KPI details</p>
            </div>
            <div>
                <div className="kpiDetails">
                    <div className="bg-white" style={{ borderRadius: '10px', textAlign: 'center' }}>
                        <div className="dropdownFitler p-2 mt-2">
                            <div>
                                <Select
                                    options={filterOptions}
                                    placeholder="Select Filter"
                                    onChange={(value) => filterPodData(value)}
                                    style={{ width: '200px' }}
                                />
                            </div>
                        </div>
                        <div className="podContainer">
                            <div>
                                <div className="mb-4">
                                    <Input
                                        value={`Name: ${kpiData?.userId?.firstName} ${kpiData?.userId?.lastName}`}
                                        disabled
                                        placeholder="Name"
                                        className="p-2 inputViewKpi"
                                    />
                                </div>
                                <div className="mb-4">
                                    <Input value={`POD: ${kpiData?.pod?.name}`} disabled placeholder="POD" className="p-2 inputViewKpi" />
                                </div>
                                <div className="mb-4">
                                    <Input
                                        value={`Role: ${kpiData?.roleId?.name}`}
                                        disabled
                                        placeholder="Role: RMA"
                                        className="p-2 inputViewKpi"
                                    />
                                </div>
                                <div className="mb-4">
                                    <Input
                                        value={`GMV: ${kpiData?.gmvtarget}`}
                                        disabled
                                        placeholder="GMV: 1000"
                                        className="p-2 inputViewKpi"
                                    />
                                </div>
                                <div className="mb-4">
                                    <Input
                                        value={`Conversion: ${kpiData?.conversiontarget}`}
                                        disabled
                                        placeholder="Conversion: 1000"
                                        className="p-2 inputViewKpi"
                                    />
                                </div>
                                <div className="mb-4">
                                    <Input
                                        value={`Retention: ${kpiData?.retentiontarget}`}
                                        disabled
                                        placeholder="Retention: 1000"
                                        className="p-2 inputViewKpi"
                                    />
                                </div>
                            </div>
                            <div className="mr-4">
                                <Progress type="circle" percent={kpiData?.overralperformace} width={300} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Index;
