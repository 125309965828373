/* eslint-disable no-underscore-dangle */
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from 'assets/images/ent.png';
import { Link } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import AuthWrapper1 from '../pages/authentication/AuthWrapper1';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
// import ApiUrl from 'network';
// import useScriptRef from 'hooks/useScriptRef';
// import Autocomplete from '@mui/material/Autocomplete';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// // import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import MobileTimePicker from '@mui/lab/MobileTimePicker';
// import Form from 'react-bootstrap/Form';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import IconButton from '@mui/material/IconButton';
// import DeleteIcon from '@mui/icons-material/Delete';
// import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    },
    colors: {
        color: `#195CD7`
    },
    colors2: {
        color: `#0E6DE4`
    }
}));

const EnterpriseMotorAccident = () => {
    const classes = useStyles();
    const [renewalDate, setRenewalDate] = useState(new Date());
    const [policyNo, setPolicyNo] = useState('');
    const [nameofInsured, setNameOfInsured] = useState('');
    const [address, setAddress] = useState('');
    const [occupation, setOccupation] = useState('');
    const [telephone, setTelephone] = useState('');
    const [registrationNo, setRegistrationNo] = useState('');
    const [model, setModel] = useState('');
    const [make, setMake] = useState('');
    const [year, setYear] = useState('');
    const [financeCompany, setFinanceCompany] = useState('');
    const [motorTrade, setMotorTrade] = useState('');
    const [purpose, setPurpose] = useState('');
    const [consent, setConsent] = useState('');
    const [pFullName, setpFullName] = useState('');
    const [pAddress, setPAddress] = useState('');
    const [pAge, setpAge] = useState(0);
    const [pOccupation, setPOccupation] = useState('');
    const [pTelephone, setpTelephone] = useState('');
    const [pDrivingLicensense, setpDrivingLicense] = useState('');
    const [pDateofIssue, setpDateofIssue] = useState(new Date());
    const [pgroupOfpeople, setpgroupofpeople] = useState('');
    const [pconvicted, setpConvicted] = useState('');
    const [pgiveDetails, setpgiveDetails] = useState('');
    const [pcarAccOwner, setpCarAccOwner] = useState('');
    const [pemployeeDriver, setpemployeeDriver] = useState('');
    const [ppersonDriving, setppersonDriving] = useState('');
    const [pInsurer, setpInsurer] = useState('');
    const [cDate, setcDate] = useState(new Date());
    const [cTime, setcTime] = useState(new Date('2018-01-01T00:00:00.000Z'));
    const [cexactLocation, setcexactLocation] = useState('');
    const [cspeedVehicle, setcspeedVehicle] = useState('');
    const [clighting, setclighting] = useState('');
    const [cnumberofpersons, setcNumberofpersons] = useState('');
    const [cwhenaccreport, setcwhenaccreport] = useState('');
    const [chowacchappened, setchowaccHappened] = useState('');
    const [cacccausedbydriver, setcaccusedbydriver] = useState('');
    const [cdamagetodriver, setcdamagetodriver] = useState('');
    const [cnamerepair, setcnamerepair] = useState('');
    const [tpartiesnamesandaddress, settpartiesnamesandaddress] = useState('');
    const [textentofinjurer, settextentofinjurer] = useState('');
    const [tinjuredpersonsnamesaddressother, settinjuredpersonsnamesaddressother] = useState('');
    const [tinjuredextentofinjurer, settinjuredextentofinjurer] = useState('');
    const [tinjuredpedestrianspersonsnamesaddressother, settinjuredpedestrianspersonsnamesaddressother] = useState('');
    const [tinjuredpedestriansextentofinjurer, settinjurpedestriansedextentofinjurer] = useState('');
    const [vehicleRegNo, setVehicleRegNo] = useState('');
    const [vehicleMake, setVehicleMake] = useState('');
    const [vehicleModel, setVehicleModel] = useState('');
    const [nameDriver, setNameDriver] = useState('');
    const [ownerVehicle, setOwnerVehicle] = useState('');
    const [nameOfInsurer, setNameInsurer] = useState('');
    const [damageVehicle, setDamageVehicle] = useState('');
    const [claimMade, setClaimMade] = useState('');
    const [stateParticulars, setStateParticulars] = useState('');
    const [personInvolved, setPersonInvolved] = useState('');
    const [witness, setWitness] = useState('');
    const [addressWitness, setAddressWitness] = useState('');
    const [reportPolice, setReportPolice] = useState('');
    const [policeStation, setPoliceStation] = useState('');
    const [policeConstable, setPoliceConstable] = useState('');
    const [policyIndemnify, setpolicyIndemnify] = useState('');
    const [policyDateIssue, setPolicyDateIssue] = useState(new Date());
    const [signaturePolicy, setSignaturePolicy] = useState('');

    const [loading, setLoading] = useState(false);
    // const [loading2, setLoading2] = useState(false);
    // const [open, setOpen] = useState(false);
    // const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    // const scriptedRef = useScriptRef();

    const handleOrderDateChange = (newValue) => {
        setRenewalDate(newValue);
    };
    const handlepDateofIssueChange = (newValue) => {
        setpDateofIssue(newValue);
    };
    const handleCDateChange = (newValue) => {
        setcDate(newValue);
    };
    const handlePolicyDateChange = (newValue) => {
        setPolicyDateIssue(newValue);
    };

    // useEffect(() => {
    // }, []);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        // const data = {
        //     renewalDate,
        //     policyNo
        // };

        // fetch(ApiUrl.SalesOrderRequest, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(data)
        // })
        //     .then((res) => res.json())
        //     .then((result) => {
        //         if (result.status) {
        //             toast.success(result.message);
        //             setLoading(false);
        //             if (scriptedRef) {
        //                 setTimeout(() => {
        //                     window.location.reload();
        //                     // history('/sales');
        //                 }, 3000);
        //             }
        //         } else {
        //             toast.error(result.data);
        //             setLoading(false);
        //         }
        //     })
        //     .finally(() => {
        //         setLoading(false);
        //     });
    };
    return (
        <>
            <AuthWrapper1>
                <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                                <MainCard title="">
                                    <Grid container>
                                        <Box display="flex" justifyContent="space-between">
                                            <Grid item sx={{ mb: 3 }}>
                                                <Link to="#">
                                                    <img src={logo} alt="logo" height={70} />
                                                </Link>
                                            </Grid>
                                        </Box>
                                        <Grid item xs={12}>
                                            <Typography variant="h3" component="h2" textAlign="center" className={classes.colors}>
                                                Enterprise Motor Accident Report Form
                                            </Typography>
                                            <br />
                                            <Typography variant="h5" component="h4" textAlign="left">
                                                Notice to policyholders:
                                            </Typography>
                                            <Box display="flex" justifyContent="space-between">
                                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                                    <Typography variant="subtitle2" component="div">
                                                        Our solicitors have advised that in the event of an injury to a Third Party or
                                                        damage to his property, you should supply the information set out below so as to
                                                        enable us and our Solicitors to give advise thereon and conduct any litigaton which
                                                        may ensue. It is necessary that great care should be taken in completing this form
                                                        and the information given therein should be strictly accurate, irrespective of
                                                        whether it is in your favour or otherwise. You should not make any payment, offer or
                                                        promise of any payment or admit liability in anyway, as by so doing you may
                                                        prejudice your position and make settlement a difficult matter.
                                                    </Typography>
                                                </Grid>
                                            </Box>
                                            <br />
                                            <Typography variant="h5" component="h4" textAlign="right">
                                                K. Ofori
                                            </Typography>
                                            <Typography variant="h5" component="h4" textAlign="right">
                                                Executive Director
                                            </Typography>
                                            <br />
                                            <form onSubmit={handleSubmit}>
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <TextField
                                                                autoComplete="off"
                                                                name="policyNo"
                                                                variant="outlined"
                                                                required
                                                                id="policyNo"
                                                                label="Policy Number"
                                                                value={policyNo}
                                                                onChange={(e) => setPolicyNo(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={185}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DesktopDatePicker
                                                                    label="Renewal Date"
                                                                    inputFormat="MM/dd/yyyy"
                                                                    value={renewalDate}
                                                                    onChange={handleOrderDateChange}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                />
                                                            </LocalizationProvider>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <TextField
                                                                autoComplete="off"
                                                                name="nameofInsured"
                                                                variant="outlined"
                                                                required
                                                                id="nameofInsured"
                                                                label="Name of Insured"
                                                                value={nameofInsured}
                                                                onChange={(e) => setNameOfInsured(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <TextField
                                                                autoComplete="off"
                                                                name="address"
                                                                variant="outlined"
                                                                required
                                                                id="address"
                                                                label="Address"
                                                                value={address}
                                                                onChange={(e) => setAddress(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <TextField
                                                                autoComplete="off"
                                                                name="occupation"
                                                                variant="outlined"
                                                                required
                                                                id="occupation"
                                                                label="Occupation"
                                                                value={occupation}
                                                                onChange={(e) => setOccupation(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <TextField
                                                                autoComplete="off"
                                                                name="telephone"
                                                                variant="outlined"
                                                                required
                                                                id="telephone"
                                                                label="Telephone Number"
                                                                value={telephone}
                                                                onChange={(e) => setTelephone(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Typography variant="h5" component="h2" className={classes.colors2}>
                                                    Particulars of Motor Vehicle Concerned
                                                </Typography>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <TextField
                                                                autoComplete="off"
                                                                name="registrationNo"
                                                                variant="outlined"
                                                                required
                                                                id="registrationNo"
                                                                label="Registration Number"
                                                                value={registrationNo}
                                                                onChange={(e) => setRegistrationNo(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <TextField
                                                                autoComplete="off"
                                                                name="make"
                                                                variant="outlined"
                                                                required
                                                                id="make"
                                                                label="Make"
                                                                value={make}
                                                                onChange={(e) => setMake(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <TextField
                                                                autoComplete="off"
                                                                name="model"
                                                                variant="outlined"
                                                                required
                                                                id="model"
                                                                label="Model"
                                                                value={model}
                                                                onChange={(e) => setModel(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <TextField
                                                                autoComplete="off"
                                                                name="year"
                                                                variant="outlined"
                                                                required
                                                                id="year"
                                                                label="Year of Make"
                                                                value={year}
                                                                onChange={(e) => setYear(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={5}>
                                                        <Box width={450}>
                                                            <FormLabel id="demo-row-radio-buttons-group-label">
                                                                Is the vehicle the subject to hire purchase or loan agreement?
                                                            </FormLabel>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                                        <Box width={350}>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group"
                                                            >
                                                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                                            </RadioGroup>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={6}>
                                                        <FormLabel id="demo-row-radio-buttons-group-label">
                                                            If so, state name of finance company or lending organization
                                                        </FormLabel>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={6}>
                                                        <Box width={360}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="financeCompany"
                                                                variant="outlined"
                                                                required
                                                                id="financeCompany"
                                                                label=""
                                                                value={financeCompany}
                                                                onChange={(e) => setFinanceCompany(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                                    <FormLabel id="demo-row-radio-buttons-group-label">
                                                        If claim is under a Motor Trade Policy, give name and address of owner of vehicle
                                                    </FormLabel>
                                                </Grid>
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={800}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="motorTrade"
                                                                variant="outlined"
                                                                required
                                                                id="motorTrade"
                                                                label=""
                                                                value={motorTrade}
                                                                onChange={(e) => setMotorTrade(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                                    <FormLabel id="demo-row-radio-buttons-group-label">
                                                        State fully the purpose for which the vehicle was being used (It is not sufcient to
                                                        state ‘Business’ or ‘Private’)
                                                    </FormLabel>
                                                </Grid>
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={800}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="purpose"
                                                                variant="outlined"
                                                                required
                                                                id="purpose"
                                                                label=""
                                                                multiline
                                                                rows={3}
                                                                value={purpose}
                                                                onChange={(e) => setPurpose(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={8}>
                                                        <Box width={450}>
                                                            <FormLabel id="demo-row-radio-buttons-group-label">
                                                                Was the vehicle being used with your consent?
                                                            </FormLabel>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group"
                                                                value={consent}
                                                                onChange={(e) => setConsent(e.target.value)}
                                                            >
                                                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                                            </RadioGroup>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Typography variant="h5" component="h2" className={classes.colors2}>
                                                    Particulars of Person Driving at the Time of Accident
                                                </Typography>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <TextField
                                                                autoComplete="off"
                                                                name="pFullName"
                                                                variant="outlined"
                                                                required
                                                                id="pFullName"
                                                                label="Full Name"
                                                                value={pFullName}
                                                                onChange={(e) => setpFullName(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <TextField
                                                                autoComplete="off"
                                                                name="pAddress"
                                                                variant="outlined"
                                                                required
                                                                id="pAddress"
                                                                label="Address"
                                                                value={pAddress}
                                                                onChange={(e) => setPAddress(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <TextField
                                                                autoComplete="off"
                                                                type="number"
                                                                name="pAge"
                                                                variant="outlined"
                                                                required
                                                                id="pAge"
                                                                label="Age"
                                                                value={pAge}
                                                                onChange={(e) => setpAge(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <TextField
                                                                autoComplete="off"
                                                                name="pOccupation"
                                                                variant="outlined"
                                                                required
                                                                id="pOccupation"
                                                                label="Occupation"
                                                                value={pOccupation}
                                                                onChange={(e) => setPOccupation(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <TextField
                                                                autoComplete="off"
                                                                type="number"
                                                                name="pTelephone"
                                                                variant="outlined"
                                                                required
                                                                id="pTelephone"
                                                                label="Telephone Number"
                                                                value={pTelephone}
                                                                onChange={(e) => setpTelephone(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <TextField
                                                                autoComplete="off"
                                                                name="pDrivingLicensense"
                                                                variant="outlined"
                                                                required
                                                                id="pDrivingLicensense"
                                                                label="Driving License Number"
                                                                value={pDrivingLicensense}
                                                                onChange={(e) => setpDrivingLicense(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={185}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DesktopDatePicker
                                                                    label="Date of Issue"
                                                                    inputFormat="MM/dd/yyyy"
                                                                    value={pDateofIssue}
                                                                    onChange={handlepDateofIssueChange}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                />
                                                            </LocalizationProvider>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={8}>
                                                        <FormLabel id="demo-row-radio-buttons-group-label">
                                                            For what group of vehicles has the license been issued
                                                        </FormLabel>
                                                        <Box width={500}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="pgroupOfpeople"
                                                                variant="outlined"
                                                                required
                                                                id="pgroupOfpeople"
                                                                label=""
                                                                value={pgroupOfpeople}
                                                                onChange={(e) => setpgroupofpeople(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={8}>
                                                        <Box width={450}>
                                                            <FormLabel id="demo-row-radio-buttons-group-label">
                                                                Has the driver ever been convicted of any motoring offense?
                                                            </FormLabel>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group"
                                                                value={pconvicted}
                                                                onChange={(e) => setpConvicted(e.target.value)}
                                                            >
                                                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                                            </RadioGroup>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={450}>
                                                            <FormLabel id="demo-row-radio-buttons-group-label">
                                                                If so, give details
                                                            </FormLabel>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={8}>
                                                        <Box width={500}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="pgiveDetails"
                                                                variant="outlined"
                                                                required
                                                                id="pgiveDetails"
                                                                label=""
                                                                value={pgiveDetails}
                                                                onChange={(e) => setpgiveDetails(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={6}>
                                                        <Box width={450}>
                                                            <FormLabel id="demo-row-radio-buttons-group-label">
                                                                State whether the person driving at the time of accident was
                                                            </FormLabel>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={6}>
                                                        <Box width={550}>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group"
                                                                value={pcarAccOwner}
                                                                onChange={(e) => setpCarAccOwner(e.target.value)}
                                                            >
                                                                <FormControlLabel value="TheOwner" control={<Radio />} label="The Owner" />
                                                                <FormControlLabel
                                                                    value="AnEmployee"
                                                                    control={<Radio />}
                                                                    label="An employee"
                                                                />
                                                                <FormControlLabel
                                                                    value="RelativeOrFriend"
                                                                    control={<Radio />}
                                                                    label="Relative or friend"
                                                                />
                                                            </RadioGroup>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={6}>
                                                        <Box width={450}>
                                                            <FormLabel id="demo-row-radio-buttons-group-label">
                                                                If an employee, how long has he been in your employment as a driver?
                                                            </FormLabel>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={6}>
                                                        <Box width={350}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="pemployeeDriver"
                                                                variant="outlined"
                                                                required
                                                                id="pemployeeDriver"
                                                                label=""
                                                                value={pemployeeDriver}
                                                                onChange={(e) => setpemployeeDriver(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={8}>
                                                        <Box width={500}>
                                                            <FormLabel id="demo-row-radio-buttons-group-label">
                                                                If owner was not driving, state whether the person driving owns a vehicle
                                                                himself
                                                            </FormLabel>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group"
                                                                value={ppersonDriving}
                                                                onChange={(e) => setppersonDriving(e.target.value)}
                                                            >
                                                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                                            </RadioGroup>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                                    <FormLabel id="demo-row-radio-buttons-group-label">
                                                        If so, state the name and address of the Insurer of the person driving and number of
                                                        policy held by him/her
                                                    </FormLabel>
                                                </Grid>
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={800}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="pInsurer"
                                                                variant="outlined"
                                                                required
                                                                id="pInsurer"
                                                                label=""
                                                                value={pInsurer}
                                                                onChange={(e) => setpInsurer(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Typography variant="h5" component="h2" className={classes.colors2}>
                                                    Circumstances of Accident
                                                </Typography>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={185}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DesktopDatePicker
                                                                    label="Date"
                                                                    inputFormat="MM/dd/yyyy"
                                                                    value={cDate}
                                                                    onChange={handleCDateChange}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                />
                                                            </LocalizationProvider>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <MobileTimePicker
                                                                    label="Time"
                                                                    value={cTime}
                                                                    onChange={(newValue) => {
                                                                        setcTime(newValue);
                                                                    }}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                />
                                                            </LocalizationProvider>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <TextField
                                                                autoComplete="off"
                                                                name="nameofInsured"
                                                                variant="outlined"
                                                                required
                                                                id="nameofInsured"
                                                                label="Exact location of incident"
                                                                value={cexactLocation}
                                                                onChange={(e) => setcexactLocation(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <TextField
                                                                autoComplete="off"
                                                                name="cspeedVehicle"
                                                                variant="outlined"
                                                                required
                                                                id="cspeedVehicle"
                                                                label="Speed of vehicle"
                                                                value={cspeedVehicle}
                                                                onChange={(e) => setcspeedVehicle(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={8}>
                                                        <Box width={500}>
                                                            <FormLabel id="demo-row-radio-buttons-group-label">
                                                                If after lighting up time what lights were lit on your vehicle
                                                            </FormLabel>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="clighting"
                                                                variant="outlined"
                                                                required
                                                                id="clighting"
                                                                label=""
                                                                value={clighting}
                                                                onChange={(e) => setclighting(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={6}>
                                                        <Box width={400}>
                                                            <FormLabel id="demo-row-radio-buttons-group-label">
                                                                How many persons were in your vehicle at the time of the accident?
                                                            </FormLabel>
                                                            <TextField
                                                                autoComplete="off"
                                                                name="cnumberofpersons"
                                                                fullWidth
                                                                variant="outlined"
                                                                required
                                                                id="cnumberofpersons"
                                                                label=""
                                                                value={cnumberofpersons}
                                                                onChange={(e) => setcNumberofpersons(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={6}>
                                                        <Box width={400}>
                                                            <FormLabel id="demo-row-radio-buttons-group-label">
                                                                If you were not in the vehicle, when was accident reported to you?
                                                            </FormLabel>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="cwhenaccreport"
                                                                variant="outlined"
                                                                required
                                                                id="cwhenaccreport"
                                                                label=""
                                                                value={cwhenaccreport}
                                                                onChange={(e) => setcwhenaccreport(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                                    <FormLabel id="demo-row-radio-buttons-group-label">
                                                        Give full description of how the accident happened
                                                    </FormLabel>
                                                </Grid>
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={800}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="chowacchappened"
                                                                variant="outlined"
                                                                required
                                                                id="chowacchappened"
                                                                label=""
                                                                multiline
                                                                rows={3}
                                                                value={chowacchappened}
                                                                onChange={(e) => setchowaccHappened(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <br />
                                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                                    <FormLabel id="demo-row-radio-buttons-group-label">
                                                        In your opinion was the accident caused by your driver? If not, by whom?
                                                    </FormLabel>
                                                </Grid>
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={800}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="cacccausedbydriver"
                                                                variant="outlined"
                                                                required
                                                                id="cacccausedbydriver"
                                                                label=""
                                                                multiline
                                                                rows={2}
                                                                value={cacccausedbydriver}
                                                                onChange={(e) => setcaccusedbydriver(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <br />
                                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                                    <FormLabel id="demo-row-radio-buttons-group-label">Damage to your vehicle</FormLabel>
                                                </Grid>
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={800}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="cdamagetodriver"
                                                                variant="outlined"
                                                                required
                                                                id="cdamagetodriver"
                                                                label=""
                                                                multiline
                                                                rows={3}
                                                                value={cdamagetodriver}
                                                                onChange={(e) => setcdamagetodriver(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <br />
                                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                                    <FormLabel id="demo-row-radio-buttons-group-label">
                                                        Where can the vehicle be seen?
                                                    </FormLabel>
                                                </Grid>
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={800}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="cwhenaccreport"
                                                                variant="outlined"
                                                                required
                                                                id="cwhenaccreport"
                                                                label=""
                                                                multiline
                                                                rows={3}
                                                                value={cwhenaccreport}
                                                                onChange={(e) => setcwhenaccreport(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <br />
                                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                                    <FormLabel id="demo-row-radio-buttons-group-label">
                                                        Name and address of nearest Repairers
                                                    </FormLabel>
                                                </Grid>
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={800}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="cnamerepair"
                                                                variant="outlined"
                                                                required
                                                                id="cnamerepair"
                                                                label=""
                                                                multiline
                                                                rows={2}
                                                                value={cnamerepair}
                                                                onChange={(e) => setcnamerepair(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <br />
                                                <Typography variant="h5" component="h2" className={classes.colors2}>
                                                    Third Parties involved in Accident
                                                </Typography>
                                                <br />
                                                <Typography variant="h5" component="h2">
                                                    Names and addresses of persons injured and the extent of the injuries
                                                </Typography>
                                                <br />
                                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                                    <FormLabel id="demo-row-radio-buttons-group-label">
                                                        Injured persons in your vehicle
                                                    </FormLabel>
                                                </Grid>
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={800}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="tpartiesnamesandaddress"
                                                                variant="outlined"
                                                                id="tpartiesnamesandaddress"
                                                                label="Name and Address"
                                                                multiline
                                                                rows={3}
                                                                value={tpartiesnamesandaddress}
                                                                onChange={(e) => settpartiesnamesandaddress(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={800}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="textentofinjurer"
                                                                variant="outlined"
                                                                id="textentofinjurer"
                                                                label="Extent of Injury"
                                                                multiline
                                                                rows={3}
                                                                value={textentofinjurer}
                                                                onChange={(e) => settextentofinjurer(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                                    <FormLabel id="demo-row-radio-buttons-group-label">
                                                        Injured persons in other vehicle
                                                    </FormLabel>
                                                </Grid>
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={800}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="tinjuredpersonsnamesaddressother"
                                                                variant="outlined"
                                                                id="tinjuredpersonsnamesaddressother"
                                                                label="Name and Address"
                                                                multiline
                                                                rows={3}
                                                                value={tinjuredpersonsnamesaddressother}
                                                                onChange={(e) => settinjuredpersonsnamesaddressother(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={800}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="tinjuredextentofinjurer"
                                                                variant="outlined"
                                                                id="tinjuredextentofinjurer"
                                                                label="Extent of Injury"
                                                                multiline
                                                                rows={3}
                                                                value={tinjuredextentofinjurer}
                                                                onChange={(e) => settinjuredextentofinjurer(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <br />
                                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                                    <FormLabel id="demo-row-radio-buttons-group-label">Injured pedestrians</FormLabel>
                                                </Grid>
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={800}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="tinjuredpedestrianspersonsnamesaddressother"
                                                                variant="outlined"
                                                                id="tinjuredpedestrianspersonsnamesaddressother"
                                                                label="Name and Address"
                                                                multiline
                                                                rows={3}
                                                                value={tinjuredpedestrianspersonsnamesaddressother}
                                                                onChange={(e) =>
                                                                    settinjuredpedestrianspersonsnamesaddressother(e.target.value)
                                                                }
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={800}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="tinjuredpedestriansextentofinjurer"
                                                                variant="outlined"
                                                                id="tinjuredpedestriansextentofinjurer"
                                                                label="Extent of Injury"
                                                                multiline
                                                                rows={3}
                                                                value={tinjuredpedestriansextentofinjurer}
                                                                onChange={(e) => settinjurpedestriansedextentofinjurer(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Typography variant="h5" component="h2">
                                                    State details of other vehicle involved
                                                </Typography>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <TextField
                                                                autoComplete="off"
                                                                name="vehicleRegNo"
                                                                variant="outlined"
                                                                required
                                                                id="vehicleRegNo"
                                                                label="Registration Number"
                                                                value={vehicleRegNo}
                                                                onChange={(e) => setVehicleRegNo(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <TextField
                                                                autoComplete="off"
                                                                name="vehicleMake"
                                                                variant="outlined"
                                                                required
                                                                id="vehicleMake"
                                                                label="Vehicle Make"
                                                                value={vehicleMake}
                                                                onChange={(e) => setVehicleMake(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <TextField
                                                                autoComplete="off"
                                                                name="vehicleModel"
                                                                variant="outlined"
                                                                required
                                                                id="vehicleModel"
                                                                label="Model"
                                                                value={vehicleModel}
                                                                onChange={(e) => setVehicleModel(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Typography variant="h5" component="h2">
                                                    State name and address of the driver of this vehicle
                                                </Typography>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={750}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="nameDriver"
                                                                variant="outlined"
                                                                required
                                                                id="nameDriver"
                                                                label=""
                                                                value={nameDriver}
                                                                onChange={(e) => setNameDriver(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Typography variant="h5" component="h2">
                                                    State name and address of the owner of this vehicle
                                                </Typography>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={750}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="ownerVehicle"
                                                                variant="outlined"
                                                                required
                                                                id="ownerVehicle"
                                                                label=""
                                                                value={ownerVehicle}
                                                                onChange={(e) => setOwnerVehicle(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Typography variant="h5" component="h2">
                                                    State name and address of Insurer of this vehicle and policy number
                                                </Typography>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={750}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="nameOfInsurer"
                                                                variant="outlined"
                                                                required
                                                                id="nameOfInsurer"
                                                                label=""
                                                                value={nameOfInsurer}
                                                                onChange={(e) => setNameInsurer(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Typography variant="h5" component="h2">
                                                    Details of damage to this vehicle
                                                </Typography>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={750}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="damageVehicle"
                                                                variant="outlined"
                                                                required
                                                                id="damageVehicle"
                                                                label=""
                                                                value={damageVehicle}
                                                                onChange={(e) => setDamageVehicle(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Typography variant="h5" component="h2">
                                                    Has any claim been made upon you
                                                </Typography>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={750}>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group"
                                                                value={claimMade}
                                                                onChange={(e) => setClaimMade(e.target.value)}
                                                            >
                                                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                                            </RadioGroup>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Typography variant="h5" component="h2">
                                                    If so, state particulars below and note that any letter or communication received by you
                                                    must be forwarded immediately unanswered, to this company
                                                </Typography>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={750}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="stateParticulars"
                                                                variant="outlined"
                                                                required
                                                                id="stateParticulars"
                                                                label=""
                                                                value={stateParticulars}
                                                                onChange={(e) => setStateParticulars(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Typography variant="h5" component="h2">
                                                    Has any person involved in the accident been given a notice of intended prosecution by
                                                    the policy?
                                                </Typography>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={750}>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group"
                                                                value={personInvolved}
                                                                onChange={(e) => setPersonInvolved(e.target.value)}
                                                            >
                                                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                                            </RadioGroup>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Typography variant="h5" component="h2">
                                                    If so, state details Witnesses
                                                </Typography>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={750}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="witness"
                                                                variant="outlined"
                                                                required
                                                                id="witness"
                                                                label=""
                                                                value={witness}
                                                                onChange={(e) => setWitness(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Typography variant="h5" component="h2">
                                                    State name and addresses of any independent witnesses
                                                </Typography>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={750}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="addressWitness"
                                                                variant="outlined"
                                                                required
                                                                id="addressWitness"
                                                                label=""
                                                                value={addressWitness}
                                                                onChange={(e) => setAddressWitness(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Typography variant="h5" component="h2">
                                                    Was the accident reported to the Police?
                                                </Typography>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={750}>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group"
                                                                value={reportPolice}
                                                                onChange={(e) => setReportPolice(e.target.value)}
                                                            >
                                                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                                            </RadioGroup>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Typography variant="h5" component="h2">
                                                    If so, state reported and at which Police Station
                                                </Typography>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={750}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="policeStation"
                                                                variant="outlined"
                                                                required
                                                                id="policeStation"
                                                                label=""
                                                                value={policeStation}
                                                                onChange={(e) => setPoliceStation(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Typography variant="h5" component="h2">
                                                    Name of Police Constable who took particulars
                                                </Typography>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={750}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="policeConstable"
                                                                variant="outlined"
                                                                required
                                                                id="policeConstable"
                                                                label=""
                                                                value={policeConstable}
                                                                onChange={(e) => setPoliceConstable(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Typography variant="h5" component="h2">
                                                    Do you hold more than one policy indemnifying you in this accident?
                                                </Typography>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <Box width={750}>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group"
                                                                value={policyIndemnify}
                                                                onChange={(e) => setpolicyIndemnify(e.target.value)}
                                                            >
                                                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                                            </RadioGroup>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Typography variant="h5" component="h2">
                                                    I/We declare that the above statements is true in all respects to the best of my/our
                                                    knowledge and belief and I/We hereby leave in the hands of the Company accordance with
                                                    the Conditions of the Policy the conduct of all claims and litigation arising out of
                                                    this accident and to which the policy applies, to deal with, to prosecute and/or settle
                                                    as they think fit without further reference to me/us and I/We undertake to give all such
                                                    information and assistance as the Company may require.
                                                </Typography>
                                                <br />
                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={185}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DesktopDatePicker
                                                                    label="Date"
                                                                    inputFormat="MM/dd/yyyy"
                                                                    value={policyDateIssue}
                                                                    onChange={handlePolicyDateChange}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                />
                                                            </LocalizationProvider>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                                        <Box width={350}>
                                                            <TextField
                                                                autoComplete="off"
                                                                fullWidth
                                                                name="signaturePolicy"
                                                                variant="outlined"
                                                                required
                                                                id="signaturePolicy"
                                                                label="Signature of Policyholder"
                                                                value={signaturePolicy}
                                                                onChange={(e) => setSignaturePolicy(e.target.value)}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Typography variant="h5" component="h2">
                                                    The company does not admit liability by the issue of this form
                                                </Typography>
                                                <br /> <br />
                                                <Typography variant="h5" component="h2" className={classes.colors2}>
                                                    Sketch
                                                </Typography>
                                                <br />
                                                <Typography variant="h5" component="h2">
                                                    Please make a sketch showing position of Vehicles and persons concerned both before and
                                                    after the Accident and showing the direction in which they were travelling.
                                                </Typography>
                                                <br />
                                                <br />
                                                <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                                    <Button type="submit" variant="contained" color="secondary" disabled={!policyNo}>
                                                        {loading ? 'Submitting ..' : 'Submit'}
                                                    </Button>
                                                </Box>
                                            </form>
                                        </Grid>
                                    </Grid>
                                </MainCard>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AuthWrapper1>
        </>
    );
};

export default EnterpriseMotorAccident;
