import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';

const EditRole = () => {
    const [name, setName] = useState('');
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useNavigate();
    const scriptedRef = useScriptRef();

    const { id } = useParams();

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        // setLoading(true);
        fetch(ApiUrl.ViewRoleUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setName(result?.data?.name ?? '');
                    setStatus(result?.data?.status ?? '');
                } else {
                    toast.error(result.data);
                    // setLoading(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    const handleChange = (event) => {
        setStatus(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');

        const data = {
            id,
            name,
            status
        };
        setLoading(true);
        fetch(ApiUrl.UpdateRoleUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    // window.location.href = '/roles';
                    toast.success(result.message);
                    setLoading(false);
                    setTimeout(() => {
                        if (scriptedRef.current) {
                            history('/roles');
                        }
                    }, 3000);
                } else {
                    toast.error(result.data);
                    // console.log(result);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <br />
                    <PageHeader title="Roles" subTitle="Home | Configuration | User Management | Roles" />
                    <br />
                    <MainCard title="Edit Role">
                        <form onSubmit={handleSubmit}>
                            <TextField
                                autoComplete="off"
                                name="name"
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                label="Role Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                autoFocus
                            />
                            <br />
                            <br />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={status}
                                    label="Status"
                                    onChange={handleChange}
                                >
                                    <MenuItem value="Active">Active</MenuItem>
                                    <MenuItem value="InActive">InActive</MenuItem>
                                </Select>
                            </FormControl>
                            <br />
                            <br />
                            <Box display="flex" justifyContent="space-between">
                                <Button component={Link} to="/roles" type="submit" variant="contained" color="error">
                                    Reset
                                </Button>

                                <Button type="submit" variant="contained" color="secondary">
                                    {loading ? 'Updating....' : 'Update'}
                                </Button>
                            </Box>
                        </form>
                    </MainCard>
                </Grid>
            </Grid>
        </>
    );
};

export default EditRole;
