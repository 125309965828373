import { MdModeEdit } from 'react-icons/md';
import React, { useState } from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AiFillFilePdf } from 'react-icons/ai';
import CardViewList from './CardViewList';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import LeadTableWrapper from 'assets/Wrappers/LeadTableWrapper';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import Modal from 'react-bootstrap/Modal';
import { Button as MuiButton } from '@mui/material';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import { SiMicrosoftexcel } from 'react-icons/si';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TablePaginationActions from 'components/TablePaginationActions';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { authFetch } from 'utils/axios-config';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { makeStyles } from '@mui/styles';
// import Tooltip from '@mui/material/Tooltip';
import styled from 'styled-components';
import { useAppContext } from 'context/appContext';
import { useBatchContext } from 'context/order-batching/batchContext';

dayjs.extend(localizedFormat);

const ModalWrapper = styled.div`
    .modal-actions {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 30px;
    }

    .title {
        color: #ff740f;
        font-weight: 700;
        text-align: center;
        padding: 1rem 0;
    }

    .cancel-btn {
        background-color: #193855;
        margin-left: -3rem;
    }

    .save-btn {
        background-color: #ff740f;
        margin-right: -2rem;
    }

    .content {
        padding: 1rem 2rem;
        margin-bottom: 50px;
    }
`;

const BatchTableData = ({ th1, th2, th3, th4, th5, th6, rows }) => {
    const { userRole } = useAppContext();
    const {
        batchStatuses,
        todayOrders,
        handlePdfPreview,
        handleExcelDataExport,
        deleteBatcthOrderById,
        updateOrderBatch,
        view,
        orderType
    } = useBatchContext();

    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const useStyles = makeStyles(() => ({
        tableHeaderCell: {
            fontWeight: 'bolder'
        }
    }));
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // eslint-disable-next-line no-unused-vars
    const [singleBatch, setSingleBatch] = useState({});
    const [id, setId] = useState('');
    const [orderInfo, setOrderInfo] = React.useState([]);
    const [batchDate, setBatchDate] = React.useState(new Date());
    const [batchStatusId, setBatchStatusId] = React.useState('');

    const handleBatchEdit = async (id) => {
        handleShow();
        try {
            const { data } = await authFetch.get(`/orderbatch/show/${id}?orderType=${orderType}`);
            const currentBatch = data.data;
            setSingleBatch(currentBatch);
            setBatchDate(currentBatch?.batchDate ?? '');
            setId(currentBatch?._id);
            const prevOrderInfo = currentBatch?.orderInfo.map((item) => item.orderId);
            setOrderInfo(prevOrderInfo);
            setBatchStatusId(currentBatch?.batchStatusId._id ?? '');
        } catch (error) {
            console.log(error);
        }
    };

    const batchToUpdate = {
        id,
        orderInfo,
        batchDate,
        batchStatusId,
        orderType
    };

    const handleBatchUpdate = () => {
        handleClose();
        updateOrderBatch(batchToUpdate);
    };

    return (
        <>
            <LeadTableWrapper>
                {view !== 'card' && (
                    <Paper sx={{ width: '100%', overflow: 'hidden' }} className="lead__main-table" elevation={1}>
                        <TableContainer sx={{ maxHeight: 400 }}>
                            <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th6}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th1}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th2}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th3}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th4}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th5}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map(
                                        (row, index = 1) => {
                                            const { _id, batchDate, batchStatusId, orderInfo, batchNo } = row;
                                            return (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align="left" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell align="left" scope="row">
                                                        {batchNo ?? ''}
                                                    </TableCell>
                                                    <TableCell align="left" scope="row">
                                                        {dayjs(batchDate).format('l')}
                                                    </TableCell>
                                                    <TableCell align="left" scope="row">
                                                        {orderInfo.map((item) => `${item?.orderNo ?? ''}, `)}
                                                    </TableCell>
                                                    <TableCell align="left" scope="row">
                                                        {batchStatusId?.name ?? ''}
                                                    </TableCell>
                                                    <TableCell align="left" scope="row">
                                                        {userRole !== 'CustomerSuccess' && (
                                                            <Stack direction="row" spacing={0}>
                                                                {/* <Tooltip title="Preview"> */}
                                                                <IconButton
                                                                    aria-label="preview"
                                                                    className="icon-preview"
                                                                    href={`/order-batches/${_id}`}
                                                                    title="Preview"
                                                                >
                                                                    <VisibilityIcon />
                                                                </IconButton>
                                                                {/* </Tooltip> */}
                                                                {/* <Tooltip title="Edit batch"> */}
                                                                <IconButton
                                                                    aria-label="call lead phone"
                                                                    onClick={() => handleBatchEdit(_id)}
                                                                    title="Edit batch"
                                                                >
                                                                    <a href="#!" className="icon-phone">
                                                                        <MdModeEdit />
                                                                    </a>
                                                                </IconButton>
                                                                {/* </Tooltip> */}
                                                                {/* <Tooltip title="Generate Pdf"> */}
                                                                <IconButton
                                                                    aria-label="call lead phone"
                                                                    onClick={() => handlePdfPreview(row)}
                                                                    title="Generate Pdf"
                                                                >
                                                                    <a href="#!" className="icon-phone">
                                                                        <AiFillFilePdf />
                                                                    </a>
                                                                </IconButton>
                                                                {/* </Tooltip> */}
                                                                {/* <Tooltip title="Generate Excel"> */}
                                                                <IconButton
                                                                    aria-label="call lead phone"
                                                                    onClick={() => handleExcelDataExport(row.orderCompressed)}
                                                                    title="Generate Excel"
                                                                >
                                                                    <a href="#!" className="icon-phone">
                                                                        <SiMicrosoftexcel />
                                                                    </a>
                                                                </IconButton>
                                                                {/* </Tooltip> */}
                                                                {/* <Tooltip title="Delete batch"> */}
                                                                {/* <IconButton
                                                                    aria-label="call lead phone"
                                                                    onClick={() => deleteBatcthOrderById(_id)}
                                                                    title="Delete batch"
                                                                >
                                                                    <a href="#!" className="icon-phone">
                                                                        <MdDelete />
                                                                    </a>
                                                                </IconButton> */}
                                                                {/* </Tooltip> */}
                                                            </Stack>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }
                                    )}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            component="div"
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page'
                                },
                                native: true
                            }}
                            className="custom-paginator"
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </Paper>
                )}
                <Modal show={show} onHide={handleClose} centered>
                    <ModalWrapper>
                        <Modal.Title>
                            <h5 className="title">Edit Batch</h5>
                            <hr />
                        </Modal.Title>
                        <Modal.Body>
                            <div className="content">
                                {/* Pick date */}
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Date"
                                        inputFormat="MM/dd/yyyy"
                                        value={batchDate}
                                        onChange={(value) => setBatchDate(value)}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                </LocalizationProvider>
                                <br /> <br />
                                <br />
                                {/* Select multiple orders */}
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Orders</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={orderInfo}
                                        label="Orders"
                                        onChange={(e) => setOrderInfo(e.target.value)}
                                        multiple
                                    >
                                        {todayOrders.map((item) => (
                                            <MenuItem value={item._id} key={item._id}>
                                                {item.fulOrderId}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <br />
                                <br /> <br />
                                {/* Batch status */}
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select2-label">Batch Status</InputLabel>
                                    <Select
                                        defaultValue=""
                                        labelId="demo-simple-select2-label"
                                        id="demo-simple-select2"
                                        value={batchStatusId}
                                        label="Orders"
                                        onChange={(e) => setBatchStatusId(e.target.value)}
                                    >
                                        {batchStatuses.map((status) => (
                                            <MenuItem value={status._id} key={status._id}>
                                                {status.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </Modal.Body>
                        <div className="modal-actions">
                            <MuiButton variant="contained" onClick={handleClose} className="cancel-btn">
                                Cancel
                            </MuiButton>
                            <MuiButton variant="contained" onClick={handleBatchUpdate} className="save-btn">
                                Update
                            </MuiButton>
                        </div>
                    </ModalWrapper>
                </Modal>
                {view === 'card' && (
                    <CardViewList
                        rows={rows}
                        show={show}
                        handleClose={handleClose}
                        handlePdfPreview={handlePdfPreview}
                        handleExcelDataExport={handleExcelDataExport}
                        deleteBatcthOrderById={deleteBatcthOrderById}
                        handleBatchEdit={handleBatchEdit}
                    />
                )}
            </LeadTableWrapper>
        </>
    );
};

export default BatchTableData;
