import { useCallback, useEffect, useState } from 'react';
import { BarChart, Bar, Cell, XAxis, Tooltip, CartesianGrid } from 'recharts';
import './chart.css';

export default function CountChart({ color, month, trailColor, title, activeIndex, setActiveIndex, setShowTotal, setAmount }) {
    const handleClick = useCallback(
        (entry, index) => {
            setActiveIndex(index);
            setShowTotal(true);
        },
        [setActiveIndex]
    );

    const handleMouseLeave = useCallback(() => {
        setShowTotal(false);
    }, [setActiveIndex]);

    const CustomTooltip = ({ active, payload, label }) => {
        useEffect(() => {
            setAmount(payload[0]?.value);
        }, [activeIndex]);
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <span className="label">{`${label} `}</span>
                    <span className="label">{` ${payload[0].value}`}</span>
                </div>
            );
        }

        return null;
    };

    return (
        <>
            <BarChart
                width={300}
                height={100}
                data={month}
                margin={{
                    top: 10,
                    right: 0,
                    left: 0,
                    bottom: -10
                }}
                onMouseLeave={handleMouseLeave}
            >
                <XAxis dataKey="month" />
                <Tooltip content={<CustomTooltip />} />

                {title === 'Total GMV' ? (
                    // bussines stats
                    <Bar dataKey="totalGmv" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Total Orders No.' ? (
                    <Bar dataKey="totalOrderNo" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Regular GMV' ? (
                    <Bar dataKey="regularGmv" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Total Regular Orders' ? (
                    <Bar dataKey="totalRegularOrderNo" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Proforma GMV' ? (
                    <Bar dataKey="proformaGmv" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Proforma Orders No.' ? (
                    <Bar dataKey="totalProformaOrderNo" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Dark Store GMV' ? (
                    <Bar dataKey="darkstoreGmv" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Total Dark Store Orders' ? (
                    <Bar dataKey="totalDarkStoreOrderNo" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Retention Drive GMV' ? (
                    <Bar dataKey="retentionDriveGmv" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Total Retention Drive Orders No.' ? (
                    <Bar dataKey="totalRetentionDriveOrderNo" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Sales Drive GMV' ? (
                    <Bar dataKey="salesDriveGmv" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Total No. of Sales Drive' ? (
                    <Bar dataKey="totalSalesDriveOrderNo" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Pre Sold' ? (
                    <Bar dataKey="totalPreSoldDriveOrderNo" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Conversion Drive' ? (
                    <Bar dataKey="conversionDriveGmv" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Conversion Orders' ? (
                    <Bar dataKey="totalConversionDriveOrderNo" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : // finance stats
                title === 'Total Average Retail Cost' ? (
                    <Bar dataKey="totalAverageRetailCost" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Seller Payouts' ? (
                    <Bar dataKey="totalSellerPayout" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Total Net Revenues' ? (
                    <Bar dataKey="totalNetRevenue" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Total Outstanding' ? (
                    <Bar dataKey="totalCashOutstanding" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Total Payments' ? (
                    <Bar dataKey="totalPayment" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Total Net Revenues' ? (
                    <Bar dataKey="totalNetRevenue" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Total Retail Discount' ? (
                    <Bar dataKey="totalRetailDiscount" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Total Retail Sales (No.)' ? (
                    <Bar dataKey="totalOrderNo" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Total Retail GMV' ? (
                    <Bar dataKey="totalGmv" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === '1st Time Retailer Purchases' ? (
                    <Bar dataKey="totalFirstTimePurchaseNo" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Total Leads' ? (
                    <Bar dataKey="totalLeadNo" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Total Male Retailers' ? (
                    <Bar dataKey="totalMaleNo" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Total Female Retailers' ? (
                    <Bar dataKey="totalFeMaleNo" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Unkwown Retailers' ? (
                    <Bar dataKey="totalUnknownNo" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Did Not Answer ' ? (
                    <Bar dataKey="totalUnknownNo" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Total Leads' ? (
                    <Bar dataKey="totalLeadNo" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === '1st Time Retailer Purchases.' ? (
                    <Bar dataKey="totalFirstTimePurchaseNo" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : // cc stats
                title === 'Total Prospects' ? (
                    <Bar dataKey="totalProspectNo" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Total Onboarding In Progress' ? (
                    <Bar dataKey="totalonboardBegunNo" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Total Onboarding In Progress' ? (
                    <Bar dataKey="totalonboardBegunNo" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : title === 'Total Onboards Completed' ? (
                    <Bar dataKey="totalOnboardNo" fill={color} onMouseEnter={handleClick}>
                        <>
                            {month?.map((entry, index) => (
                                <Cell cursor="pointer" fill={index === activeIndex ? `${color}` : trailColor} key={`cell-${index}`} />
                            ))}
                        </>
                    </Bar>
                ) : (
                    ''
                )}
            </BarChart>
        </>
    );
}
