// import dashboard from './dashboard';

import saleslead from './SalesLead';
import other from './other';
// import salesreport from './salesreport';

// ==============================|| MENU ITEMS dashboard ||============================== //

const salesLeadItems = {
    items: [saleslead, other]
};

export default salesLeadItems;
