// import { lazy } from 'react';

import AuthForgetPassword from 'views/pages/authentication/authentication3/ForgetPassword';
import AuthGarageForgetPassword from 'views/pages/authentication/authentication3/GarageForgetPassword';
import AuthGarageLogin from 'views/pages/authentication/authentication3/garageLogin';
import AuthGarageRegister from 'views/pages/authentication/authentication3/garageRegister';
import OtpVerification from 'views/pages/authentication/authentication3/otpVerification';
// login option 3 routing
import AuthLogin3 from 'views/pages/authentication/authentication3/Login3';
// import AuthRegister3 from 'views/pages/authentication/authentication3/Register3';
import AuthResetPassword from 'views/pages/authentication/authentication3/ResetPassword';
import AuthVerifyEmail from 'views/pages/authentication/authentication3/VerifyEmail';
// import AuthWebSiteRegister from 'views/pages/authentication/authentication3/WebRegister';
import ExternalSignup from 'views/pages/authentication/ExternalSignup';
// project imports
// import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import NotFound from 'views/Error/404';

// const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
// const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
// const AuthForgetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgetPassword')));
// const AuthResetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ResetPassword')));
// const AuthGarageRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/garageRegister')));
// const AuthGarageLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/garageLogin')));
// const AuthGarageForgetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/GarageForgetPassword')));
// const AuthVerifyEmail = Loadable(lazy(() => import('views/pages/authentication/authentication3/VerifyEmail')));
// const AuthWebSiteRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/WebRegister')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <AuthLogin3 />
        },
        {
            path: '/otpverification',
            element: <OtpVerification />
        },
        // {
        //     path: '/register',
        //     element: <AuthRegister3 />
        // },
        // {
        //     path: '/webregister',
        //     element: <AuthWebSiteRegister />
        // },
        {
            path: '/garageregister',
            element: <AuthGarageRegister />
        },
        {
            path: '/garagelogin',
            element: <AuthGarageLogin />
        },
        {
            path: '/forgetpassword',
            element: <AuthForgetPassword />
        },
        {
            path: '/garageforgetpassword',
            element: <AuthGarageForgetPassword />
        },
        {
            path: '/resetpassword/:id',
            element: <AuthResetPassword />
        },
        {
            path: '/verifyemail/:id',
            element: <AuthVerifyEmail />
        },
        {
            path: '/auth/register',
            element: <ExternalSignup />
        },
        // please keep this route at the end of all other routes
        {
            path: '*',
            element: <NotFound />
        }
    ]
};

export default AuthenticationRoutes;
