import { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import PageHeader from '../../components/PageHeader';
import MainCard from 'ui-component/cards/MainCard';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Modal from '@mui/material/Modal';
import DatePicker from '@mui/lab/DatePicker';
// import TimePicker from '@mui/lab/TimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import { toast } from 'react-toastify';

import ApiUrl from 'network';

const { BaseUrl } = ApiUrl;

const AddNewOrder = () => {
    // const history = useNavigate();
    const [orderDate, setOrderDate] = useState(new Date());
    const [channel, setChannel] = useState('');
    const [channelPartner, setChannelPartner] = useState('');
    // const [channelPartnerNumber, setChannelPartnerNumber] = useState('');
    const [orderValue, setOrderValue] = useState(0);
    // const [orderType, setOrderType] = useState('');
    const [dropOffLocation, setDropOffLocation] = useState('');
    // const [deliveryDate, setDeliveryDate] = useState(new Date());
    const [orderStatus, setOrderStatus] = useState('');
    const [expectedCashCollected, setExpectedCashCollected] = useState(0);
    const [actualCashCollected, setActualCashCollected] = useState(0);
    const [cashOutstanding, setCashOutstanding] = useState(0);
    const [percentCashOutstanding, setPercentCashOutstanding] = useState(0);
    const [margin, setMargin] = useState(0);
    const [paymentDueDate, setPaymentDueDate] = useState(new Date());
    const [daysPastDue, setDaysPastDue] = useState(0);
    const [paymentStatus, setPaymentStatus] = useState('');
    const [partnerContact, setPartnerContact] = useState('');
    const [percentSold, setPercentSold] = useState(0);
    const [creditRating, setCreditRating] = useState('');
    const [carrier, setCarrier] = useState('');
    const [carrierRating, setCarrierRating] = useState('');
    const [carrierContactNumber, setCarrierContactNumber] = useState('');
    const [carrierNPS, setCarrierNPS] = useState('');
    const [orderPickupTime, setOrderPickupTime] = useState('');
    const [pickupType, setPickupType] = useState('');
    const [pickupLocation, setPickupLocation] = useState('');
    const [dropOffType, setDropOffType] = useState('');
    // const [carrierDropOffLocation, setCarrierDropOffLocation] = useState('');
    const [partsArr, setPartsArr] = useState([]);
    const [sku, setSku] = useState('');
    // const [part, setPart] = useState('');
    const [qty, setQty] = useState(0);
    // const [netQty, setNetQty] = useState(0);
    // const [qtySold, setQtySold] = useState(0);
    // const [brand, setBrand] = useState('');
    // const [purchasePrice, setPurchasePrice] = useState(0);
    // const [garageRetailPrice, setGarageRetailPrice] = useState(0);
    // const [applicableVins, setApplicableVins] = useState('');
    // const [orderItems, setOrderItems] = useState([]);
    // const [notes, setNotes] = useState('');

    // const [orderError, setOrderError] = useState('');
    const [openOrder, setOpenOrder] = useState(false);
    const handleOpenOrder = () => {
        setOpenOrder(!openOrder);
    };

    const addOrder = () => {
        //
    };

    // const submitForm = async (e) => {
    //     const obj = {
    //         orderDate,
    //         channel,
    //         channelPartner,
    //         channelPartnerNumber,
    //         orderValue,
    //         orderType,
    //         dropOffLocation,
    //         deliveryDate,
    //         orderStatus,
    //         expectedCashCollected,
    //         actualCashCollected,
    //         cashOutstanding,
    //         percentCashOutstanding,
    //         margin,
    //         paymentDueDate,
    //         daysPastDue,
    //         paymentStatus,
    //         percentSold,
    //         creditRating,
    //         carrier,
    //         carrierRating,
    //         carrierContactNumber,
    //         carrierNPS,
    //         orderPickupTime,
    //         pickupType,
    //         pickupLocation,
    //         dropOffType,
    //         carrierDropOffLocation,
    //         orderItems,
    //         notes
    //     };

    //     const userInfo = localStorage.getItem('userToken');
    //     const config = {
    //         headers: {
    //             'auth-token': userInfo,
    //             'Content-Type': 'application/json'
    //         },
    //         method: 'POST',
    //         body: JSON.stringify(obj)
    //     };

    //     try {
    //         const url = `${BaseUrl}/neworder`;
    //         const result = await (await fetch(url, config)).json();
    //         const { status, message } = result;

    //         if (status) {
    //             toast.success(message);
    //             setTimeout(() => window.location.reload(), 5500);
    //         } else {
    //             toast.err(message);
    //         }
    //     } catch (err) {
    //         toast.error('Network error');
    //     }
    // };

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        async function getParts() {
            try {
                const result = await (await fetch(`${BaseUrl}/part/partsactive`, config)).json();
                setPartsArr(result.data);
            } catch (e) {
                setPartsArr([]);
            }
        }
        getParts();
    }, []);

    return (
        <>
            <PageHeader title="New Order" subTitle="Home | New Order | Add New Order" />

            <MainCard title="Add New Order">
                <div className="bold-text garage-grey-text">Main Information</div>
                <br />
                <div className="row">
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Order Date"
                                    value={orderDate}
                                    onChange={(newValue) => {
                                        setOrderDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </div>

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField onChange={(e) => setChannel(e.target.value)} value={channel} label="Channel" variant="outlined" />
                        </FormControl>
                    </div>

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                onChange={(e) => setChannelPartner(e.target.value)}
                                value={channelPartner}
                                label="Channel Partner"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                type="number"
                                onChange={(e) => setOrderValue(e.target.value)}
                                value={orderValue}
                                label="Order Value"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                onChange={(e) => setDropOffLocation(e.target.value)}
                                value={dropOffLocation}
                                label="Drop off location"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>

                    {/* <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Delivery Date"
                                    value={deliveryDate}
                                    onChange={(newValue) => {
                                        setOrderDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </div> */}

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <InputLabel id="claim">Order Status</InputLabel>
                            <Select
                                labelId="claim-simple-select-label"
                                id="order-status"
                                value={orderStatus}
                                label="Order Status"
                                onChange={(e) => setOrderStatus(e.target.value)}
                            >
                                <MenuItem value="">Select an option</MenuItem>
                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="delivered">Delivered</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <br />

                <div className="bold-text garage-grey-text">Payment Information</div>
                <br />
                <div className="row">
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                type="number"
                                onChange={(e) => setExpectedCashCollected(e.target.value)}
                                value={expectedCashCollected}
                                label="Expected Cash Collected"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                type="number"
                                onChange={(e) => setActualCashCollected(e.target.value)}
                                value={actualCashCollected}
                                label="Actual Cash Collected"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                type="number"
                                onChange={(e) => setCashOutstanding(e.target.value)}
                                value={cashOutstanding}
                                label="Cash Outstanding"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                onChange={(e) => setPercentCashOutstanding(e.target.value)}
                                value={percentCashOutstanding}
                                label="% Cash Outstanding"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField onChange={(e) => setMargin(e.target.value)} value={margin} label="Margin" variant="outlined" />
                        </FormControl>
                    </div>

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Pmt Due Date"
                                    value={paymentDueDate}
                                    onChange={(newValue) => {
                                        setPaymentDueDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </div>

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                type="number"
                                onChange={(e) => setDaysPastDue(e.target.value)}
                                value={daysPastDue}
                                label="Days Past Due"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <InputLabel id="payment_status">Payment Status</InputLabel>
                            <Select
                                labelId="claim-simple-select-label"
                                id="payment-status"
                                value={paymentStatus}
                                label="Payment Status"
                                onChange={(e) => setPaymentStatus(e.target.value)}
                            >
                                <MenuItem value="">Select an option</MenuItem>
                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="paid">Paid</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <br />

                <div className="bold-text garage-grey-text">Channel Partner Information</div>
                <br />
                <div className="row">
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField value={channelPartner} label="Channel Partner" variant="outlined" />
                        </FormControl>
                    </div>

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                onChange={(e) => setPartnerContact(e.target.value)}
                                value={partnerContact}
                                label="Partner Contact Info"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                value={percentSold}
                                onChange={(e) => setPercentSold(e.target.value)}
                                label="% Sold"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                onChange={(e) => setCreditRating(e.target.value)}
                                value={creditRating}
                                label="Credit rating"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>
                </div>
                <br />

                <div className="bold-text garage-grey-text">Carrier Information</div>
                <br />
                <div className="row">
                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField onChange={(e) => setCarrier(e.target.value)} value={carrier} label="Carrier" variant="outlined" />
                        </FormControl>
                    </div>

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                onChange={(e) => setCarrierRating(e.target.value)}
                                value={carrierRating}
                                label="Carrier Rating"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                onChange={(e) => setCarrierContactNumber(e.target.value)}
                                value={carrierContactNumber}
                                label="Carrier Contact Info"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                onChange={(e) => setCarrierNPS(e.target.value)}
                                value={carrierNPS}
                                label="Carrier NPS"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                onChange={(e) => setOrderPickupTime(e.target.value)}
                                value={orderPickupTime}
                                label="Order Pickup Time"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                onChange={(e) => setPickupType(e.target.value)}
                                value={pickupType}
                                label="Pickup Type"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                onChange={(e) => setPickupLocation(e.target.value)}
                                value={pickupLocation}
                                label="Pickup Location"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                onChange={(e) => setDropOffType(e.target.value)}
                                value={dropOffType}
                                label="Drop Off Type"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>

                    <div className="col-3 col-lx-4 col-l-6 col-m-12 col-s-12 padding-all-10">
                        <FormControl fullWidth>
                            <TextField
                                onChange={(e) => setDropOffLocation(e.target.value)}
                                value={dropOffLocation}
                                label="Drop Off Location"
                                variant="outlined"
                            />
                        </FormControl>
                    </div>
                </div>
                <br />

                <div className="bold-text garage-grey-text">Order Items</div>
                <div className="flex-row-reverse" style={{ marginBottom: '3px' }}>
                    <button type="button" onClick={() => handleOpenOrder()} className="compare-sm-btn garage-blue-bg white-text">
                        Add Item
                    </button>
                </div>
            </MainCard>
            <div />

            <Modal
                open={openOrder}
                onClose={handleOpenOrder}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="width-25 width-lx-35 width-l-45 width-m-70 width-s-85 height-100 flex-column justify-content-center align-items-center margin-auto">
                    <div className="width-100 height-5" />
                    <div className="width-90 height-90 padding-all-10 white-bg overflow-auto" style={{ borderRadius: '10px' }}>
                        <div className="black-text bold-text">Add Order Item</div>
                        <br />
                        <form>
                            <div>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="sku">SKU</InputLabel>
                                        <Select
                                            labelId="claim-simple-select-label"
                                            id="sku-id"
                                            value={sku}
                                            label="SKU"
                                            onChange={(e) => setSku(e.target.value)}
                                        >
                                            <MenuItem value="">Select an option</MenuItem>
                                            {partsArr.map(({ _id, sku }, index) => (
                                                <MenuItem key={index} value={_id}>
                                                    {sku}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <br />
                            <div>
                                <FormControl fullWidth>
                                    <TextField
                                        type="number"
                                        value={qty}
                                        onChange={(e) => setQty(e.target.value)}
                                        id="outlined-basic"
                                        label="QTY"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </div>
                            <br />
                            <div>
                                {/* <FormControl fullWidth>
                                    <TextField
                                        type="number"
                                        onChange={(e) => setAmountQuoted(e.target.value)}
                                        id="outlined-basic"
                                        label="Amount Quoted"
                                        variant="outlined"
                                    />
                                </FormControl> */}
                            </div>
                            <br />
                            <div>
                                {/* <FormControl fullWidth>
                                    <TextField
                                        onChange={(e) => setServiceProvider(e.target.value)}
                                        id="outlined-basic"
                                        label="Service Provider"
                                        variant="outlined"
                                    />
                                </FormControl> */}
                            </div>
                            <br />
                            <div>
                                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Due Date"
                                        value={dueDate}
                                        onChange={(newValue) => {
                                            setDueDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider> */}
                            </div>
                            <br />
                            <div>
                                {/* <FormControl fullWidth>
                                    <TextField
                                        onChange={(e) => setCityCountry(e.target.value)}
                                        id="outlined-basic"
                                        label="City/Country"
                                        variant="outlined"
                                    />
                                </FormControl> */}
                            </div>
                            <br />
                            {/* <div>{orderError}</div> */}
                            <div className="padding-all-5" />
                            <div className="row justify-content-space-between">
                                <div>
                                    <button
                                        type="button"
                                        className="compare-submit-btn red-bg white-text"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleOpenOrder();
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                                <div>
                                    <button type="button" className="compare-submit-btn garage-blue-bg white-text" onClick={addOrder}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div className="padding-all-20" />
                    </div>
                    <div className="width-100 height-5" />
                </div>
            </Modal>
        </>
    );
};

export default AddNewOrder;
