/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import useScriptRef from 'hooks/useScriptRef';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import EditIcon from '@mui/icons-material/Edit';
// import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Form from 'react-bootstrap/Form';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const UpdateJobOrder = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [jobDate, setjobDate] = useState(new Date());
    const [carRegistrationNo, setcarRegistrationNo] = useState('');
    const [year, setyear] = useState('');
    const [mileage, setmileage] = useState('');
    const [jobdatetimeIn, setjobdatetimeIn] = useState(new Date());
    const [jobdatetimeOut, setjobdatetimeOut] = useState(new Date());
    const [car, setcar] = useState('');
    // const [carName, setcarName] = useState('');
    // const [carmodel, setcarmodel] = useState('');
    const [carmodelName, setcarmodelName] = useState('');
    // const [customer, setcustomer] = useState('');
    const [customerName, setcustomerName] = useState('');
    const [customerContact, setcustomerContact] = useState('');
    const [engineNo, setengineNo] = useState('');
    const [vinNo, setvinNo] = useState('');
    const [status, setstatus] = useState('');

    const [vehbodyreportcomment, setvehbodyreportcomment] = useState('');
    const [technicianName, settechnicianName] = useState('');
    const [technicianEmail, settechnicianEmail] = useState('');
    const [techDate, settechDate] = useState(new Date());
    const [carData, setCarData] = useState([]);
    const [carmodelData, setCarModelData] = useState([]);
    const [customerData, setcustomerData] = useState([]);
    const [CusComplaint, setCusComplaint] = useState([]);
    const [IssueDiagnosedData, setIssueDiagnosedData] = useState([]);
    const [partType, setpartType] = useState([]);
    const [supplier, setSupplier] = useState('');
    // const [part, setPart] = useState([]);
    const [qty, setQty] = useState('');
    const [partid, setPartId] = useState('');
    const [skuId, setSkuId] = useState('');
    const [brand, setBrand] = useState('');
    const [wholeSalePrice, setwholeSalePrice] = useState('');
    const [garagePrice, setgaragePrice] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [indexValue, setIndexValue] = useState('');
    // const [indexValueCusComplaint, setIndexValueCusComplaint] = useState('');
    // const [indexValueissuesDiagnosed, setIndexValueissuesDiagnosed] = useState('');
    // const [indexValuecompleteAction, setIndexValuecompleteAction] = useState('');
    // const [indexValuepartnotinsystem, setIndexValuepartnotinsystem] = useState('');
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [openaddcomplaint, setOpenaddcomplaint] = useState(false);
    // const [openeditcomplaint, setOpeneditcomplaint] = useState(false);
    const [openaddissuesDiagnosed, setOpenaddissuesDiagnosed] = useState(false);
    // const [openeditissuesDiagnosed, setOpeneditissuesDiagnosed] = useState(false);
    // const [openeditcompleteAction, setOpeneditcompleteAction] = useState(false);
    const [openaddpartnotinsystem, setOpenaddpartnotinsystem] = useState(false);
    // const [openeditpartnotinsystem, setOpeneditpartnotinsystem] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [parts, setItems] = useState([]);
    const [customerCompliant, setcustomerCompliantItems] = useState([]);
    const [issuesDiagnosed, setissuesDiagnosedItems] = useState([]);
    const [completedAction, setcompletedAction] = useState([]);
    const [partsNotInSystem, setpartsNotInSystem] = useState([]);

    const [complaintId, setcomplaintId] = useState('');
    const [cusdescription, setcusdescription] = useState('');
    const [issueId, setissueId] = useState('');
    const [issuesdescription, setissuesdescription] = useState('');
    const [actionId, setactionId] = useState('');
    const [actdescription, setactdescription] = useState('');
    // const [unit, setunit] = useState('');
    // const [cost, setcost] = useState('');
    // const [actstatus, setactstatus] = useState('');
    const [partName, setpartName] = useState('');
    const [partqty, setpartqty] = useState('');
    const [partTypesystem, setpartTypesystem] = useState('');
    const [partsCost, setpartsCost] = useState('');
    const [labourCost, setlabourCost] = useState('');
    const [wholesaleCost, setwholesaleCost] = useState('');
    const [perMargin, setperMargin] = useState('');
    const [supervisedName, setsupervisedName] = useState('');
    const [company, setcompany] = useState('');
    // const [totalCost, settotalCost] = useState('');
    const { id } = useParams();

    const retrieveAllPart = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.ActivePartUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        // setPart(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllCar = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllCar, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCarData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllCarModel = async (id) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(ApiUrl.AllCarModelsDetailsUrl + id, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCarModelData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllFleet = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.FleetPartnerUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setcustomerData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllCustomerComplaint = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.ActiveCustomerComplaintUrls, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCusComplaint(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllActiveIssueDiagnosed = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.IssueDiagnosedUrls, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setIssueDiagnosedData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        retrieveAllPart();
        retrieveAllCar();
        retrieveAllFleet();
        retrieveAllCustomerComplaint();
        retrieveAllActiveIssueDiagnosed();
        // const firstname = localStorage.getItem('userFirstName');
        // const lastname = localStorage.getItem('userLastName');
        // const fullname = `${firstname} ${lastname}`;
        // const email = localStorage.getItem('userEmail');
        // const company = localStorage.getItem('company');
        // settechnicianName(fullname);
        // settechnicianEmail(email);
        // setcompany(company);

        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        fetch(ApiUrl.ViewJobCardsUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    // console.log('result', result.data);
                    setjobDate(result?.data?.jobDate ?? '');
                    setcarRegistrationNo(result?.data?.carRegistrationNo ?? '');
                    setyear(result?.data?.year ?? '');
                    setmileage(result?.data?.mileage ?? '');
                    setjobdatetimeIn(result?.data?.jobdatetimeIn ?? '');
                    setjobdatetimeOut(result?.data?.jobdatetimeOut ?? '');
                    setcar(result?.data?.car ?? '');
                    // setcarName(result?.data?.carName ?? '');
                    // setcarmodel(result?.data?.carmodel ?? '');
                    setcarmodelName(result?.data?.carmodelName ?? '');
                    // setcustomer(result?.data?.customer ?? '');
                    setcustomerName(result?.data?.customerName ?? '');
                    setcustomerContact(result?.data?.customerContact ?? '');
                    setengineNo(result?.data?.engineNo ?? '');
                    setvinNo(result?.data?.vinNo ?? '');
                    setstatus(result?.data?.status ?? '');
                    setpartsCost(result?.data?.partsCost ?? '');
                    setlabourCost(result?.data?.labourCost ?? '');
                    settechnicianName(result?.data?.technicianName ?? '');
                    setsupervisedName(result?.data?.supervisedName ?? '');
                    settechnicianEmail(result?.data?.technicianEmail ?? '');
                    settechDate(result?.data?.techDate ?? '');
                    // settotalCost(result?.data?.totalCost ?? '');
                    setvehbodyreportcomment(result?.data?.vehbodyreportcomment ?? '');
                    setperMargin(result?.data?.perMargin ?? '');
                    setwholesaleCost(result?.data?.wholesaleCost ?? '');
                    setcompany(result?.data?.company ?? '');
                    setcompletedAction(result?.data?.completedAction ?? []);
                    setcustomerCompliantItems(result?.data?.customerCompliant ?? []);
                    setissuesDiagnosedItems(result?.data?.issuesDiagnosed ?? []);
                    setItems(result?.data?.parts ?? []);
                    setpartsNotInSystem(result?.data?.partsNotInSystem ?? []);
                    setLoading(false);
                } else {
                    toast.error(result.data);
                    setLoading(false);
                    // setLoading(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading2(false);
            });
    }, [id]);

    const handleJobDateChange = (newValue) => {
        setjobDate(newValue);
    };

    const handleTechDateChange = (newValue) => {
        settechDate(newValue);
    };

    const handleJobDateTimeIn = (newValue) => {
        setjobdatetimeIn(newValue.target.value);
    };

    const handleJobDateTimeOut = (newValue) => {
        setjobdatetimeOut(newValue.target.value);
    };

    const handlePartType = (event) => {
        setpartType(event.target.value);
    };

    const handlePartTypeSystem = (event) => {
        setpartTypesystem(event.target.value);
    };

    const handleStatusJob = (event) => {
        setstatus(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    // const handleClickOpenAddComplaint = () => {
    //     setOpenaddcomplaint(true);
    // };

    const handleCloseAddCompliant = () => {
        setOpenaddcomplaint(false);
    };

    // const handleClickOpenEditComplaint = () => {
    //     setOpeneditcomplaint(true);
    // };
    // const handleCloseEditComplaint = () => {
    //     setOpeneditcomplaint(false);
    // };

    // const handleClickOpenAddIssDiagnosed = () => {
    //     setOpenaddissuesDiagnosed(true);
    // };

    const handleCloseAddIssDiagnosed = () => {
        setOpenaddissuesDiagnosed(false);
    };

    // const handleClickOpenEditIssDiagnosed = () => {
    //     setOpeneditissuesDiagnosed(true);
    // };

    // const handleCloseEditIssDiagnosed = () => {
    //     setOpeneditissuesDiagnosed(false);
    // };

    // const handleClickOpenEditCompleteAction = () => {
    //     setOpeneditcompleteAction(true);
    // };

    // const handleCloseEditCompleteAction = () => {
    //     setOpeneditcompleteAction(false);
    // };

    // const handleClickOpenAddPartNotInSystem = () => {
    //     setOpenaddpartnotinsystem(true);
    // };

    const handleCloseAddPartNotInSystem = () => {
        setOpenaddpartnotinsystem(false);
    };

    // const handleClickOpenEditPartNotInSystem = () => {
    //     setOpeneditpartnotinsystem(true);
    // };

    // const handleCloseEditPartNotInSystem = () => {
    //     setOpeneditpartnotinsystem(false);
    // };

    const handleCarMakeChange = (event) => {
        setcar(event.target.value);
        retrieveAllCarModel(event.target.value);

        const newFilter = carData.filter((value) => (value._id || '').toLowerCase().includes((event.target.value || '').toLowerCase()));
        newFilter.forEach(() => {
            // setcarName(option.name);
        });
    };

    // const onSubmitPartNotInSystemEdit = (event) => {
    //     event.preventDefault();
    //     const newList = Object.assign([], partsNotInSystem);
    //     const data = { ...newList[indexValuepartnotinsystem] };
    //     data.partName = partName;
    //     data.partqty = partqty;
    //     data.partTypesystem = partTypesystem;
    //     newList[indexValuepartnotinsystem] = data;
    //     setpartsNotInSystem(newList);
    //     handleCloseEditPartNotInSystem();
    // };

    const onSubmitPartEdit = (event) => {
        event.preventDefault();
        const newList = Object.assign([], parts);
        const data = { ...newList[indexValue] };
        data.garagePrice = garagePrice;
        data.qty = qty;
        data.partid = partid;
        data.partType = partType;
        data.skuId = skuId;
        data.brand = brand;
        data.wholeSalePrice = wholeSalePrice;
        data.supplier = supplier ?? '';
        newList[indexValue] = data;
        setItems(newList);
        handleClose2();

        let sum = 0;
        let sum2 = 0;
        setpartsCost(sum);
        setwholesaleCost(sum2);
        newList.forEach((row) => {
            sum += Number(row.garagePrice) * Number(row.qty);
            // console.log('row data', row); expected cash
        });

        newList.forEach((row) => {
            sum2 += Number(row.wholeSalePrice) * Number(row.qty);
            // console.log('row data', row); order value
        });

        setpartsCost(sum);
        setwholesaleCost(sum2);

        const marginRate = (Number(sum) - Number(sum2)) / Number(sum2);
        const value = isFinite(marginRate) ? marginRate : 0;
        const margin = Number(value).toFixed(2);
        setperMargin(margin);
    };

    // const onSubmitEditCustomerComplaint = (event) => {
    //     event.preventDefault();
    //     const newList = Object.assign([], customerCompliant);
    //     // console.log('newlist', newList);
    //     const data = { ...newList[indexValueCusComplaint] };
    //     // console.log('data complaintId', data.complaintId);
    //     // console.log('data cusdescription', data.cusdescription);

    //     const newList2 = Object.assign([], completedAction);
    //     // newList.splice(index, 1);
    //     // console.log('newList2', newList2);
    //     // eslint-disable-next-line array-callback-return
    //     newList2.findIndex((datas, index) => {
    //         // console.log('index', index);
    //         // console.log('datas', datas);
    //         // console.log('data.complaintId', data.complaintId);
    //         if (datas.actionId === data.complaintId) {
    //             // console.log('index new', index);
    //             const data2 = { ...newList2[index] };
    //             // console.log('data2', data2);
    //             data2.actionId = actionId;
    //             data2.actdescription = actdescription;
    //             newList2[index] = data2;
    //             setcompletedAction(newList2);
    //         }
    //     });

    //     data.complaintId = complaintId;
    //     data.cusdescription = cusdescription;
    //     newList[indexValueCusComplaint] = data;
    //     setcustomerCompliantItems(newList);
    //     handleCloseEditComplaint();
    // };

    // const onSubmitEditCompleteAction = (event) => {
    //     event.preventDefault();
    //     const newList = Object.assign([], completedAction);
    //     const data = { ...newList[indexValuecompleteAction] };
    //     data.actionId = actionId;
    //     data.actdescription = actdescription;
    //     data.unit = unit;
    //     data.cost = cost;
    //     data.actstatus = actstatus;
    //     newList[indexValuecompleteAction] = data;
    //     setcompletedAction(newList);
    //     handleCloseEditCompleteAction();
    //     let sum = 0;
    //     setlabourCost(sum);
    //     newList.forEach((row) => {
    //         sum += Number(row?.cost ?? 0);
    //     });

    //     setlabourCost(sum);
    // };

    // const onSubmitEditIssueDiagnosed = (event) => {
    //     event.preventDefault();
    //     const newList = Object.assign([], issuesDiagnosed);
    //     const data = { ...newList[indexValueissuesDiagnosed] };

    //     const newList2 = Object.assign([], completedAction);

    //     // eslint-disable-next-line array-callback-return
    //     newList2.findIndex((datas, index) => {
    //         // console.log('index', index);
    //         // console.log('datas', datas);
    //         // console.log('data.complaintId', data.complaintId);
    //         if (datas.actionId === data.issueId) {
    //             // console.log('index new', index);
    //             const data2 = { ...newList2[index] };
    //             // console.log('data2', data2);
    //             data2.actionId = actionId;
    //             data2.actdescription = actdescription;
    //             newList2[index] = data2;
    //             setcompletedAction(newList2);
    //         }
    //     });

    //     data.issueId = issueId;
    //     data.issuesdescription = issuesdescription;
    //     newList[indexValueissuesDiagnosed] = data;
    //     setissuesDiagnosedItems(newList);

    //     // const idx = completedAction.findIndex((data) => data.actionId === issueId);
    //     // const data2 = { ...newList2[idx] };
    //     // completedAction.filter((item) => item.actionId === issueId);
    //     // data2.actionId = actionId;
    //     // data2.actdescription = actdescription;
    //     // newList2[idx] = data2;

    //     // setcompletedAction(newList2);
    //     handleCloseEditIssDiagnosed();
    // };

    const onSubmitPartNotInSystem = (event) => {
        event.preventDefault();
        setpartsNotInSystem([
            ...partsNotInSystem,
            {
                partName,
                partqty,
                partTypesystem
            }
        ]);

        handleCloseAddPartNotInSystem();
    };

    const onSubmitPart = (event) => {
        event.preventDefault();
        // items, setItems
        setItems([
            ...parts,
            {
                skuId,
                partid,
                qty,
                brand,
                partType,
                wholeSalePrice,
                garagePrice,
                supplier
            }
        ]);

        handleClose();
        let sum = 0;
        let sum2 = 0;
        setpartsCost(sum);
        setwholesaleCost(sum2);
        const newList = Object.assign([], parts);
        newList.forEach((row) => {
            sum += Number(row.garagePrice) * Number(row.qty);
            // console.log('row data', row); expected cash
        });

        newList.forEach((row) => {
            sum2 += Number(row.wholeSalePrice) * Number(row.qty);
            // console.log('row data', row); order value
        });

        sum += Number(garagePrice) * Number(qty);
        sum2 += Number(wholeSalePrice) * Number(qty);

        setpartsCost(sum);
        setwholesaleCost(sum2);

        const marginRate = (Number(sum) - Number(sum2)) / Number(sum2);
        const value = isFinite(marginRate) ? marginRate : 0;
        const margin = Number(value).toFixed(2);
        setperMargin(margin);
    };

    const onSubmitCustomerComplaint = (event) => {
        event.preventDefault();
        // customer complaint
        setcustomerCompliantItems([
            ...customerCompliant,
            {
                complaintId,
                cusdescription
            }
        ]);

        setcompletedAction([
            ...completedAction,
            {
                actionId,
                actdescription
            }
        ]);

        handleCloseAddCompliant();
    };

    const onSubmitIssueDiagnosed = (event) => {
        event.preventDefault();
        // issueid, issue description
        setissuesDiagnosedItems([
            ...issuesDiagnosed,
            {
                issueId,
                issuesdescription
            }
        ]);

        setcompletedAction([
            ...completedAction,
            {
                actionId,
                actdescription
            }
        ]);

        handleCloseAddIssDiagnosed();
    };

    // Submit Order
    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        const totalCost = Number(partsCost) + Number(labourCost);
        const data = {
            id,
            jobDate,
            carRegistrationNo,
            year,
            mileage,
            jobdatetimeIn,
            jobdatetimeOut,
            // car,
            // carName,
            // carmodel,
            // carmodelName,
            // customer,
            // customerName,
            // customerContact,
            engineNo,
            vinNo,
            status,
            vehbodyreportcomment,
            technicianName,
            company,
            technicianEmail,
            techDate,
            wholesaleCost,
            partsCost,
            labourCost,
            perMargin,
            totalCost,
            parts,
            customerCompliant,
            issuesDiagnosed,
            completedAction,
            partsNotInSystem,
            supervisedName
        };

        // console.log('body', data);

        fetch(ApiUrl.UpdateJobCardsUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading2(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/JobOrder');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                }
            })
            .finally(() => {
                setLoading2(false);
            });
    };

    const filterCarModel = (event) => {
        const newFilter = carmodelData.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        newFilter.forEach(() => {
            // setcarmodel(option._id);
        });
    };

    const filterCustomer = (event) => {
        const newFilter = customerData.filter((value) => (value.partnerName || '').toLowerCase().includes((event || '').toLowerCase()));
        newFilter.forEach((option) => {
            console.log('customer', option);
            // setcustomer(option._id);
            setcustomerContact(option?.partnerContact ?? '');
        });
    };

    // const filterPart = (event) => {
    //     const newFilter = part.filter((value) => (value.sku || '').toLowerCase().includes((event || '').toLowerCase()));
    //     // console.log('newFilter', newFilter);
    //     newFilter.forEach((option) => {
    //         setSkuId(option._id ?? '');
    //         setBrand(option.brand ?? '');
    //         setwholeSalePrice(option.wholeSalePrice ?? '');
    //         setgaragePrice(option.garagePrice ?? '');

    //         if (option?.stock) {
    //             setSupplier(option?.stock);
    //         } else if (option?.distributor) {
    //             setSupplier(option?.distributor?.partnerName);
    //         } else {
    //             setSupplier('Third Party');
    //         }
    //     });
    // };

    const filterCustomerComplaint = (event) => {
        const newFilter = CusComplaint.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        newFilter.forEach((option) => {
            setcomplaintId(option._id);
            setactionId(option._id);
        });
    };

    const filterIssueDiagnosed = (event) => {
        const newFilter = IssueDiagnosedData.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        newFilter.forEach((option) => {
            setissueId(option._id);
            setactionId(option._id);
        });
    };

    // const handleActStatus = (event) => {
    //     setactstatus(event.target.value);
    // };

    const handleDeleteClick = (row, index) => {
        const newList = Object.assign([], parts);
        newList.splice(index, 1);
        setItems(newList);

        let sum = 0;
        let sum2 = 0;
        setpartsCost(sum);
        setwholesaleCost(sum2);
        newList.forEach((row) => {
            sum += Number(row.garagePrice) * Number(row.qty);
            // console.log('row data', row); expected cash
        });

        newList.forEach((row) => {
            sum2 += Number(row.wholeSalePrice) * Number(row.qty);
            // console.log('row data', row); order value
        });

        setpartsCost(sum);
        setwholesaleCost(sum2);

        const marginRate = (Number(sum) - Number(sum2)) / Number(sum2);
        const value = isFinite(marginRate) ? marginRate : 0;
        const margin = Number(value).toFixed(2);
        setperMargin(margin);
    };

    // const handleDeletePartNotInSystemClick = (row, index) => {
    //     const newList = Object.assign([], partsNotInSystem);
    //     newList.splice(index, 1);
    //     setpartsNotInSystem(newList);
    // };

    // const handleDeleteCompleteActionClick = (row, index) => {
    //     const newList = Object.assign([], completedAction);
    //     newList.splice(index, 1);
    //     setcompletedAction(newList);
    //     let sum = 0;
    //     setlabourCost(sum);
    //     newList.forEach((row) => {
    //         sum += Number(row?.cost ?? 0);
    //     });

    //     setlabourCost(sum);

    //     const newList2 = customerCompliant.filter((item) => item.complaintId !== row.actionId);
    //     setcustomerCompliantItems(newList2);

    //     const newList3 = issuesDiagnosed.filter((item) => item.issueId !== row.actionId);
    //     setissuesDiagnosedItems(newList3);
    // };

    // const handleDeleteCusComplaintClick = (row, index) => {
    //     const newList = Object.assign([], customerCompliant);
    //     newList.splice(index, 1);
    //     setcustomerCompliantItems(newList);
    //     const newList2 = completedAction.filter((item) => item.actionId !== row.complaintId);
    //     setcompletedAction(newList2);

    //     let sum = 0;
    //     setlabourCost(sum);
    //     newList2.forEach((row) => {
    //         sum += Number(row?.cost ?? 0);
    //     });

    //     setlabourCost(sum);
    // };

    // const handleDeleteIssueDiagnosedClick = (row, index) => {
    //     const newList = Object.assign([], issuesDiagnosed);
    //     newList.splice(index, 1);
    //     setissuesDiagnosedItems(newList);
    //     const newList2 = completedAction.filter((item) => item.actionId !== row.issueId);
    //     setcompletedAction(newList2);

    //     let sum = 0;
    //     setlabourCost(sum);
    //     newList2.forEach((row) => {
    //         sum += Number(row?.cost ?? 0);
    //     });

    //     setlabourCost(sum);
    // };

    // const handleEditCusComplaintClick = (row, index) => {
    //     setcomplaintId(row.complaintId ?? '');
    //     setcusdescription(row.cusdescription ?? '');
    //     setactionId(row.complaintId ?? '');
    //     setactdescription(row.cusdescription ?? '');
    //     handleClickOpenEditComplaint();

    //     setIndexValueCusComplaint(index);
    // };

    // const handleEditIssueDiagnosedClick = (row, index) => {
    //     setissueId(row?.issueId ?? '');
    //     setissuesdescription(row?.issuesdescription ?? '');
    //     setactionId(row?.issueId ?? '');
    //     setactdescription(row?.issuesdescription ?? '');
    //     handleClickOpenEditIssDiagnosed();

    //     setIndexValueissuesDiagnosed(index);
    // };

    // const handleEditCompleteActionClick = (row, index) => {
    //     setactionId(row?.actionId ?? '');
    //     setactdescription(row?.actdescription ?? '');
    //     setunit(row?.unit ?? '');
    //     setcost(row?.cost ?? '');
    //     setactstatus(row?.actstatus ?? 'Open');

    //     handleClickOpenEditCompleteAction();

    //     setIndexValuecompleteAction(index);
    // };

    // const handleEditPartNotInSystemClick = (row, index) => {
    //     setpartName(row?.partName ?? '');
    //     setpartqty(row?.partqty ?? '');
    //     setpartTypesystem(row?.partTypesystem ?? '');

    //     handleClickOpenEditPartNotInSystem();
    //     setIndexValuepartnotinsystem(index);
    // };

    const handleEditClick = (row, index) => {
        // console.log('row', row);
        setPartId(row.partid ?? '');
        setQty(row.qty ?? '');
        setpartType(row?.partType ?? '');
        setgaragePrice(row.garagePrice ?? '');
        setSkuId(row.skuId ?? '');
        setBrand(row.brand ?? '');
        setwholeSalePrice(row.wholeSalePrice ?? '');
        setSupplier(row?.supplier ?? '');
        handleClickOpen2();

        setIndexValue(index);
    };

    return (
        <>
            <PageHeader title="Job Order" subTitle="Home | View Job Order" />

            {loading ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <MainCard title="">
                        <Typography variant="subtitle2" component="div">
                            Vehicle Job Order
                        </Typography>
                        <br />
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            {/* <Stack spacing={1}> */}
                                            <DesktopDatePicker
                                                label="Job Date"
                                                inputFormat="MM/dd/yyyy"
                                                value={jobDate}
                                                disabled
                                                onChange={handleJobDateChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            {/* </Stack> */}
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="carRegistrationNo"
                                            variant="outlined"
                                            id="carRegistrationNo"
                                            disabled
                                            label="Car Registration No."
                                            value={carRegistrationNo}
                                            onChange={(e) => setcarRegistrationNo(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="year"
                                            variant="outlined"
                                            id="year"
                                            disabled
                                            label="Year"
                                            value={year}
                                            onChange={(e) => setyear(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="mileage"
                                            variant="outlined"
                                            disabled
                                            id="mileage"
                                            label="Mileage"
                                            value={mileage}
                                            onChange={(e) => setmileage(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            id="datetime-local"
                                            label="Date/Time In"
                                            type="datetime-local"
                                            disabled
                                            defaultValue={jobdatetimeIn}
                                            onChange={handleJobDateTimeIn}
                                            sx={{ width: 180 }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            id="datetime-local"
                                            label="Date/Time Out"
                                            type="datetime-local"
                                            disabled
                                            defaultValue={jobdatetimeOut}
                                            onChange={handleJobDateTimeOut}
                                            sx={{ width: 180 }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Car Make *</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={car}
                                                disabled
                                                required
                                                label="Car Make *"
                                                onChange={handleCarMakeChange}
                                            >
                                                {carData.map((cars) => (
                                                    <MenuItem key={cars._id} value={cars._id}>
                                                        {cars.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={carmodelName}
                                            disabled
                                            onChange={(event, newValue) => {
                                                filterCarModel(newValue);
                                                setcarmodelName(newValue);
                                            }}
                                            options={carmodelData.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Car Model *" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={customerName}
                                            disabled
                                            onChange={(event, newValue) => {
                                                setcustomerName(newValue);
                                                filterCustomer(newValue);
                                            }}
                                            options={customerData.map((option) => option.partnerName)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Customer Name *" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="customerContact"
                                            variant="outlined"
                                            id="customerContact"
                                            disabled
                                            label="Customer Contact No."
                                            value={customerContact}
                                            onChange={(e) => {
                                                setcustomerContact(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="engineNo"
                                            variant="outlined"
                                            disabled
                                            id="engineNo"
                                            label="Engine No."
                                            value={engineNo}
                                            onChange={(e) => {
                                                setengineNo(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="vinNo"
                                            variant="outlined"
                                            disabled
                                            id="vinNo"
                                            label="VIN No."
                                            value={vinNo}
                                            onChange={(e) => {
                                                setvinNo(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Status *</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={status}
                                                required
                                                label="Status"
                                                onChange={handleStatusJob}
                                            >
                                                <MenuItem value="">Select an option</MenuItem>
                                                <MenuItem value="job card opened">Job Card Opened</MenuItem>
                                                <MenuItem value="parts request">Parts Request</MenuItem>
                                                <MenuItem value="parts sourced">Parts Sourced</MenuItem>
                                                <MenuItem value="diagnostics complete">Diagnostics Complete</MenuItem>
                                                <MenuItem value="mechanic assigned">Mechanic Assigned</MenuItem>
                                                <MenuItem value="cars at workshop">Cars At Workshop</MenuItem>
                                                <MenuItem value="repair in progress">Repair In Progress</MenuItem>
                                                <MenuItem value="repair complete">Repair Complete</MenuItem>
                                                <MenuItem value="payment processed">Payment Processed</MenuItem>
                                                <MenuItem value="cancelled">Cancelled</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="partsCost"
                                            variant="outlined"
                                            disabled
                                            id="partsCost"
                                            label="Parts Cost"
                                            value={partsCost}
                                            onChange={(e) => {
                                                setpartsCost(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="labourCost"
                                            variant="outlined"
                                            disabled
                                            id="labourCost"
                                            label="Labour Cost"
                                            value={labourCost}
                                            onChange={(e) => {
                                                setlabourCost(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="technicianName"
                                            variant="outlined"
                                            disabled
                                            id="technicianName"
                                            label="Technician Name"
                                            value={technicianName}
                                            onChange={(e) => {
                                                settechnicianName(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="supervisedName"
                                            variant="outlined"
                                            id="supervisedName"
                                            disabled
                                            label="Supervised Name"
                                            value={supervisedName}
                                            onChange={(e) => {
                                                setsupervisedName(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            {/* <Stack spacing={1}> */}
                                            <DesktopDatePicker
                                                label="Date"
                                                inputFormat="MM/dd/yyyy"
                                                value={techDate}
                                                disabled
                                                onChange={handleTechDateChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            {/* </Stack> */}
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />

                            <Typography variant="subtitle2" component="div">
                                Customer Complaint
                            </Typography>
                            <br />
                            {/* <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                <Button variant="contained" onClick={handleClickOpenAddComplaint} size="sm" className={classes.item}>
                                    Add
                                </Button>
                            </Box>
                            <br /> */}
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Complaint ID
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Problem Description
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {customerCompliant.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.complaintId ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.cusdescription ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {/* <IconButton
                                                                aria-label="edit"
                                                                color="primary"
                                                                onClick={() => handleEditCusComplaintClick(row, index)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                onClick={() => handleDeleteCusComplaintClick(row, index)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton> */}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <br />
                            <Typography variant="subtitle2" component="div">
                                Issues Diagnosed
                            </Typography>
                            <br />
                            {/* <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                <Button variant="contained" onClick={handleClickOpenAddIssDiagnosed} size="sm" className={classes.item}>
                                    Add
                                </Button>
                            </Box>
                            <br /> */}
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Issue ID
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Problem Description
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {issuesDiagnosed.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.issueId ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.issuesdescription ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {/* <IconButton
                                                                aria-label="edit"
                                                                color="primary"
                                                                onClick={() => handleEditIssueDiagnosedClick(row, index)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                onClick={() => handleDeleteIssueDiagnosedClick(row, index)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton> */}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <br />

                            <Typography variant="subtitle2" component="div">
                                Completed Actions
                            </Typography>
                            <br />

                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Action ID
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Problem Description
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Units
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Labour
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Status
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {completedAction.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.actionId ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.actdescription ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.unit ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.cost ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.actstatus ?? 'Open'}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {/* <IconButton
                                                                aria-label="edit"
                                                                color="primary"
                                                                onClick={() => handleEditCompleteActionClick(row, index)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                onClick={() => handleDeleteCompleteActionClick(row, index)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton> */}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>

                            <br />
                            <Typography variant="subtitle2" component="div">
                                Part in System
                            </Typography>
                            <br />
                            <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                <Button variant="contained" onClick={handleClickOpen} size="sm" className={classes.item}>
                                    Add item
                                </Button>
                            </Box>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        SKU
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Part
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        QTY
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Size
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Brand
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Garage Price
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {parts.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.skuId}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.partid}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.qty}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.partType}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.brand}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row.garagePrice}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                                aria-label="edit"
                                                                color="primary"
                                                                onClick={() => handleEditClick(row, index)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                onClick={() => handleDeleteClick(row, index)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <br />
                            <Typography variant="subtitle2" component="div">
                                Part not in System
                            </Typography>
                            <br />
                            {/* <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                <Button variant="contained" onClick={handleClickOpenAddPartNotInSystem} size="sm" className={classes.item}>
                                    Add
                                </Button>
                            </Box>
                            <br /> */}
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Part
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        QTY
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Size
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {partsNotInSystem.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row?.partName ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.partqty ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.partTypesystem ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {/* <IconButton
                                                                aria-label="edit"
                                                                color="primary"
                                                                onClick={() => handleEditPartNotInSystemClick(row, index)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                onClick={() => handleDeletePartNotInSystemClick(row, index)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton> */}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>

                            <br />
                            <Typography variant="subtitle2" component="div">
                                Vehicle Body Report Comments
                            </Typography>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TextField
                                        autoComplete="off"
                                        name="vehbodyreportcomment"
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        fullWidth
                                        disabled
                                        value={vehbodyreportcomment}
                                        id="vehbodyreportcomment"
                                        label="Vehicle Body Report Comments"
                                        onChange={(e) => setvehbodyreportcomment(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <br />
                            <br />
                            <Box display="flex" justifyContent="space-between">
                                <Button component={Link} to="/JobOrder" type="submit" variant="contained" color="error">
                                    Back
                                </Button>
                                <Button type="submit" variant="contained" color="secondary">
                                    {loading2 ? 'Updating ..' : 'Update Job'}
                                </Button>
                            </Box>
                        </form>

                        {/* edit complete action */}

                        {/* edit complete action */}

                        {/* add issue diagnosed */}

                        <Dialog
                            fullScreen={fullScreen}
                            open={openaddissuesDiagnosed}
                            onClose={handleCloseAddIssDiagnosed}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">Add Issue Diagnosed</DialogTitle>
                            <Form onSubmit={onSubmitIssueDiagnosed}>
                                <DialogContent>
                                    {/* <DialogContentText> */}

                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Box width={300}>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                value={issuesdescription}
                                                onChange={(event, newValue) => {
                                                    setissuesdescription(newValue);
                                                    setactdescription(newValue);
                                                    filterIssueDiagnosed(newValue);
                                                }}
                                                options={IssueDiagnosedData.map((option) => option.name)}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                renderInput={(params) => <TextField {...params} label="Issue Diagnosed" />}
                                            />
                                        </Box>
                                    </Form.Group>

                                    {/* </DialogContentText> */}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="sm"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleCloseAddIssDiagnosed}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        type="submit"
                                        size="sm"
                                        className={classes.item}
                                        disabled={!issueId || !issuesdescription}
                                    >
                                        Add
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>
                        {/* add issue diagnosed */}

                        {/* edit issue diagnosed */}

                        {/* edit issue diagnosed */}

                        {/* add customer complaint */}

                        <Dialog
                            fullScreen={fullScreen}
                            open={openaddcomplaint}
                            onClose={handleCloseAddCompliant}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">Add Customer Complaint</DialogTitle>
                            <Form onSubmit={onSubmitCustomerComplaint}>
                                <DialogContent>
                                    {/* <DialogContentText> */}

                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Box width={300}>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                value={cusdescription}
                                                onChange={(event, newValue) => {
                                                    setcusdescription(newValue);
                                                    setactdescription(newValue);
                                                    filterCustomerComplaint(newValue);
                                                }}
                                                options={CusComplaint.map((option) => option.name)}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                renderInput={(params) => <TextField {...params} label="Customer Complaint" />}
                                            />
                                        </Box>
                                    </Form.Group>

                                    {/* </DialogContentText> */}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="sm"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleCloseAddCompliant}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        type="submit"
                                        size="sm"
                                        className={classes.item}
                                        disabled={!complaintId || !cusdescription}
                                    >
                                        Add
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>
                        {/* add customer complaint */}

                        {/* edit customer complaint */}

                        {/* edit customer complaint */}

                        {/* add part not in the system */}
                        <Dialog
                            fullScreen={fullScreen}
                            open={openaddpartnotinsystem}
                            onClose={handleCloseAddPartNotInSystem}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">Add Part Not In System</DialogTitle>
                            <Form onSubmit={onSubmitPartNotInSystem}>
                                <DialogContent>
                                    {/* <DialogContentText> */}

                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="partName"
                                                variant="outlined"
                                                required
                                                id="partName"
                                                label="Part Name"
                                                value={partName}
                                                onChange={(e) => setpartName(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="partqty"
                                                variant="outlined"
                                                required
                                                id="partqty"
                                                label="Qty"
                                                value={partqty}
                                                onChange={(e) => setpartqty(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Part Size</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={partTypesystem}
                                                    label="Status"
                                                    onChange={handlePartTypeSystem}
                                                >
                                                    <MenuItem value="Body">Select an option</MenuItem>
                                                    <MenuItem value="Boxes">Boxes</MenuItem>
                                                    <MenuItem value="Pieces">Pieces</MenuItem>
                                                    <MenuItem value="Gallons">Gallons</MenuItem>
                                                    <MenuItem value="Drums">Drums</MenuItem>
                                                    <MenuItem value="Rolls">Roll(s)</MenuItem>
                                                    <MenuItem value="Bucket">Bucket</MenuItem>
                                                    <MenuItem value="Packs">Pack(s)</MenuItem>
                                                    <MenuItem value="Litre">Litre(s)</MenuItem>
                                                    <MenuItem value="Pair">Pair(s)</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Form.Group>

                                    {/* </DialogContentText> */}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="sm"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleCloseAddPartNotInSystem}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        type="submit"
                                        size="sm"
                                        className={classes.item}
                                        disabled={!partName || !partqty || !partTypesystem}
                                    >
                                        Add
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>
                        {/* add part not in the system */}

                        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                            <DialogTitle id="responsive-dialog-title">Add Part</DialogTitle>
                            <Form onSubmit={onSubmitPart}>
                                <DialogContent>
                                    {/* <DialogContentText> */}

                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="partid"
                                                variant="outlined"
                                                id="partid"
                                                label="Sku"
                                                value={partid}
                                                onChange={(e) => setPartId(e.target.value)}
                                            />
                                            {/* <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                value={partid}
                                                onChange={(event, newValue) => {
                                                    setPartId(newValue);
                                                    filterPart(newValue);
                                                }}
                                                options={part.map((option) => option.sku)}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                renderInput={(params) => <TextField {...params} label="Sku" />}
                                            /> */}
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="wholeSalePrice"
                                                variant="outlined"
                                                id="wholeSalePrice"
                                                label="WholeSale Price"
                                                value={wholeSalePrice}
                                                onChange={(e) => setwholeSalePrice(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="garagePrice"
                                                variant="outlined"
                                                id="garagePrice"
                                                label="Garage Price"
                                                value={garagePrice}
                                                onChange={(e) => setgaragePrice(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="qty"
                                                variant="outlined"
                                                required
                                                id="qty"
                                                label="Qty"
                                                value={qty}
                                                onChange={(e) => setQty(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Part Size</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={partType}
                                                    label="Status"
                                                    onChange={handlePartType}
                                                >
                                                    <MenuItem value="Body">Select an option</MenuItem>
                                                    <MenuItem value="Boxes">Boxes</MenuItem>
                                                    <MenuItem value="Pieces">Pieces</MenuItem>
                                                    <MenuItem value="Gallons">Gallons</MenuItem>
                                                    <MenuItem value="Drums">Drums</MenuItem>
                                                    <MenuItem value="Rolls">Roll(s)</MenuItem>
                                                    <MenuItem value="Bucket">Bucket</MenuItem>
                                                    <MenuItem value="Packs">Pack(s)</MenuItem>
                                                    <MenuItem value="Litre">Litre(s)</MenuItem>
                                                    <MenuItem value="Pair">Pair(s)</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Form.Group>

                                    {/* </DialogContentText> */}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="sm"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleClose}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        type="submit"
                                        size="sm"
                                        className={classes.item}
                                        disabled={!partid || !qty || !partType}
                                    >
                                        Add
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>

                        {/* Edit Parts  */}

                        <Dialog fullScreen={fullScreen} open={open2} onClose={handleClose2} aria-labelledby="responsive-dialog-title">
                            <DialogTitle id="responsive-dialog-title">Edit Part</DialogTitle>
                            <Form onSubmit={onSubmitPartEdit}>
                                <DialogContent>
                                    {/* <DialogContentText> */}

                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                name="partid"
                                                variant="outlined"
                                                id="partid"
                                                label="Sku"
                                                value={partid}
                                                onChange={(e) => setPartId(e.target.value)}
                                            />
                                            {/* <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                value={partid}
                                                onChange={(event, newValue) => {
                                                    setPartId(newValue);
                                                }}
                                                options={part.map((option) => option.sku)}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                renderInput={(params) => <TextField {...params} label="Sku" />}
                                            /> */}
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="wholeSalePrice"
                                                variant="outlined"
                                                id="wholeSalePrice"
                                                label="WholeSale Price"
                                                value={wholeSalePrice}
                                                onChange={(e) => setwholeSalePrice(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="garagePrice"
                                                variant="outlined"
                                                id="garagePrice"
                                                label="Garage Price"
                                                value={garagePrice}
                                                onChange={(e) => setgaragePrice(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="qty"
                                                variant="outlined"
                                                required
                                                id="qty"
                                                label="Qty"
                                                value={qty}
                                                onChange={(e) => setQty(e.target.value)}
                                            />
                                        </Box>
                                        <br /> <br />
                                        <Box width={200}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Part Size</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={partType}
                                                    label="Status"
                                                    onChange={handlePartType}
                                                >
                                                    <MenuItem value="Body">Select an option</MenuItem>
                                                    <MenuItem value="Boxes">Boxes</MenuItem>
                                                    <MenuItem value="Pieces">Pieces</MenuItem>
                                                    <MenuItem value="Gallons">Gallons</MenuItem>
                                                    <MenuItem value="Drums">Drums</MenuItem>
                                                    <MenuItem value="Rolls">Roll(s)</MenuItem>
                                                    <MenuItem value="Bucket">Bucket</MenuItem>
                                                    <MenuItem value="Packs">Pack(s)</MenuItem>
                                                    <MenuItem value="Litre">Litre(s)</MenuItem>
                                                    <MenuItem value="Pair">Pair(s)</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Form.Group>

                                    {/* </DialogContentText> */}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        size="sm"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleClose2}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        autoFocus
                                        variant="contained"
                                        type="submit"
                                        size="sm"
                                        className={classes.item}
                                        disabled={!partid || !garagePrice || !qty || !partType}
                                    >
                                        Update
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Dialog>
                    </MainCard>
                </>
            )}
        </>
    );
};
export default UpdateJobOrder;
