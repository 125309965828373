// assets
import { IconKey, IconSnowflake, IconTruckDelivery } from '@tabler/icons';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import CarCrashIcon from '@mui/icons-material/CarRental';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
// constant
const icons = {
    IconKey,
    IconTruckDelivery,
    IconSnowflake,
    DashboardIcon,
    CarRepairIcon,
    CarCrashIcon,
    DisplaySettingsIcon,
    MarkEmailReadIcon,
    PersonAddAltIcon
};

// ==============================|| EXTRA FLEET MENU ITEMS ||============================== //
const userRole = localStorage.getItem('userRole');

const fleetdriver = {
    id: 'fleet',
    title: 'Fleet Driver',
    type: userRole,
    children: [
        {
            id: 'home',
            title: 'Dashboard',
            type: 'item',
            icon: icons.DashboardIcon,
            url: '/'
        },

        {
            id: 'fleetdriverorder',
            title: 'Order Parts',
            type: 'item',
            icon: icons.CarCrashIcon,
            url: '/fleetdriverorderparts'
        },
        {
            id: 'fleetdriverrequestservice',
            title: 'Request Service',
            type: 'item',
            icon: icons.CarRepairIcon,
            url: '/fleetdriverrequestservice'
        }
    ]
};

export default fleetdriver;
