import { Button, Divider, Button as MuiButton, Paper } from '@mui/material';
import { Col, Row } from 'react-grid-system';
import { errorToast, successToast } from 'utils/toast';
import { useEffect, useState } from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Autocomplete from '@mui/material/Autocomplete';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import Modal from 'react-bootstrap/Modal';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { authFetch } from 'utils/axios-config';
import placeholder from '../../assets/images/placeholder.png';
import styled from 'styled-components';
import { useAppContext } from 'context/appContext';

const orderStatus = [
    {
        _id: '620d76d63a9c8148935c696b',
        name: 'Order Generated'
    },
    {
        _id: '625311abbd74072935e58592',
        name: 'Proforma Invoice Request'
    },
    {
        _id: '6334bab7afa1158e678a87ad',
        name: 'Sales Drive'
    },
    {
        _id: '6466771d8328e2d254fa6ac3',
        name: 'Retention Drive'
    }
];

const ShopCart = ({ cart, setCart, handleChange, setShow }) => {
    const { userCurrency } = useAppContext();
    const [orderDate, setOrderDate] = useState(new Date());
    const [price, setPrice] = useState(0);
    const [salesPersonnels, setSalesPersonnels] = useState([]);
    const [salesTeamId, setSalesTeamId] = useState('');
    const [newOrderStatusId, setnewOrderStatusId] = useState('');
    const [orderStatusId, setorderStatusId] = useState('');
    const [orderNotes, setorderNotes] = useState('');
    const [channels, setChannels] = useState([]);
    const [channelId, setChannelId] = useState('');
    const [newChannelId, setNewChannelId] = useState('');
    const [channelpartners, setChannelPartners] = useState([]);
    const [channelPartnerId, setChannelPartnerId] = useState('');
    const [newChannelPartnerId, setNewChannelPartnerId] = useState('');
    const [dropOffLocation, setdropOffLocation] = useState('');
    const [dropOffLocation2, setdropOffLocation2] = useState('');
    const [partnerContact, setpartnerContact] = useState('');
    const [retailerStatus, setretailerStatus] = useState('');
    const [shopname, setshopname] = useState('');
    const [supplier2, setSupplier2] = useState('');

    // Setting of part types
    const [partType, setpartType] = useState([]);

    // Parts not in system states
    const [partsdata, setpartsdata] = useState([]);
    const [partname, setpartname] = useState('');
    const [partqty, setpartqty] = useState('');
    const [partsize, setpartsize] = useState('');

    const firstname = localStorage.getItem('userFirstName');
    const lastname = localStorage.getItem('userLastName');
    const salesname = `${firstname} ${lastname}`;
    const salesemail = localStorage.getItem('userEmail');
    const [isLoading, setIsLoading] = useState(false);

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const handleShow = () => setOpen(true);

    const handleRemove = (id) => {
        const arr = cart.filter((item) => item._id !== id);
        setCart(arr);
    };

    const handlePrice = () => {
        let ans = 0;
        cart.map((item) => (ans += item.garagePrice * item.qty));
        setPrice(ans);
    };

    const getAllSalesPersonnel = async () => {
        try {
            const { data } = await authFetch('/salesteam/salesteamactivesales');
            setSalesPersonnels(data.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getAllChannels = async () => {
        try {
            const { data } = await authFetch('/channel/channelsactivesales');
            setChannels(data.data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleOrderStatusChange = (event) => {
        setnewOrderStatusId(event.target.value);
        const newFilter = orderStatus.filter((value) => (value._id || '').toLowerCase().includes((event.target.value || '').toLowerCase()));
        newFilter.forEach((option) => {
            setorderStatusId(option.name);
        });
    };

    const handleChannelChange = (event) => {
        setChannelId(event.target.value);
        const newFilter = channels.filter((value) => value._id.includes(event.target.value));
        newFilter.forEach((option) => {
            setNewChannelId(option.name);
        });
    };

    const retrieveAllChannelPartner = async (id) => {
        try {
            const { data } = await authFetch.get(`/channelpartner/channelpartnersales/${id}`);
            setChannelPartners(data.data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSalesChange = (e) => {
        setSalesTeamId(e.target.value);
        retrieveAllChannelPartner(e.target.value);
    };

    const retailerHistory = async (id) => {
        let rstatus;
        try {
            const { data } = await authFetch(`order/retailerorderfulfilledhistorydata/${id}`);
            if (data.data?.length > 0) {
                rstatus = 'Old';
                setretailerStatus(rstatus);
            }
            rstatus = 'New';
            setretailerStatus(rstatus);
        } catch (error) {
            console.log(error);
        }
    };

    const filterOptions = (event) => {
        const newFilter = channelpartners.filter((value) => (value.partnerName || '').toLowerCase().includes((event || '').toLowerCase()));

        newFilter.forEach((option) => {
            setNewChannelPartnerId(option._id);
            retailerHistory(option._id);
            const location = `${option.locationLat} ${option.locationLng}`;
            setdropOffLocation(location);
            setpartnerContact(option.partnerContact);
            setdropOffLocation2(option.location);
        });
    };

    const handlePartTypeSize = (event) => {
        setpartsize(event.target.value);
        setSupplier2('Third Party');
    };

    const handlePartsNotInSystem = () => {
        if (partname === '' || partqty === '' || partsize === '') {
            errorToast('All  fields are required');
            return;
        }
        const newPart = {
            partname,
            partqty,
            partsize,
            supplier2
        };
        setpartsdata([...partsdata, newPart]);
        setpartname('');
        setpartqty('');
        setpartsize('');
        handleClose();
        successToast('Part added successfully');
    };

    const handlePartType = (e, item, index) => {
        let size = e.target.value;

        if (size === 'Boxes' || size === 'Rolls' || size === 'Packs') {
            size = 'Pieces';
            cart[index].partType = size;
            setpartType([...partType, size]);

            const toPieces = Number(item?.qty) * Number(item?.partQtyInPieces);
            cart[index].qty = toPieces;
            successToast('Part converted to pieces...👏');
        } else {
            cart[index].partType = e.target.value;
            setpartType([...partType, e.target.value]);
        }
    };

    const items = cart.map((item) => {
        return {
            skuId: item?._id,
            partid: item?.sku,
            qty: item?.qty,
            partType: item?.partType,
            brand: item?.brand,
            wholeSalePrice: item?.wholeSalePrice,
            garagePrice: item?.garagePrice,
            applicableVins: item?.applicableVins,
            supplier: item?.supplier,
            partQtyInPieces: item?.partQtyInPieces,
            partimage: item?.partsImage
        };
    });

    // Final submission should happen here
    const data = {
        items,
        orderDate,
        salesTeamId,
        salesname,
        salesemail,
        newOrderStatusId,
        cash: 'Cash',
        orderNotes,
        channelId,
        newChannelId,
        channelPartnerId,
        newChannelPartnerId,
        dropOffLocation,
        dropOffLocation2,
        partnerContact,
        orderStatusId,
        retailerStatus,
        shopname,
        // this is an array of parts not in system
        partsdata
    };

    const handlePartRemoval = (index) => {
        const data = partsdata.filter((part, i) => i !== index);
        setpartsdata(data);
    };

    const handleFinalOrderRequest = async () => {
        setIsLoading(true);
        try {
            await authFetch.post('/order/orderRequest', data);
            setIsLoading(false);
            successToast('Order submitted successfully');
            cart.length = 0;
            setTimeout(() => {
                setShow(true);
            }, 2000);
        } catch (error) {
            if (error.response.status === 401) return;
            errorToast(error.response.data.message);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handlePrice();
        getAllSalesPersonnel();
        getAllChannels();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [price, cart, partType]);

    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden' }} elevation={2} style={{ borderRadius: '0' }}>
                <Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="table-responsive" style={{ padding: '1rem 2rem' }}>
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Product Image</th>
                                        <th>Product Name</th>
                                        <th>Quantity</th>
                                        <th>Unit Price({userCurrency})</th>
                                        <th>Part Type</th>
                                        <th>Sub-Total({userCurrency})</th>
                                        <th>Remove from cart</th>
                                    </tr>
                                    {cart.map((item, index) => {
                                        const subTotal = item?.qty * item?.garagePrice;
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <img src={item?.partsImage || placeholder} width={150} height={100} alt="" />
                                                </td>
                                                <td>
                                                    <p>{item?.sku}</p>
                                                </td>
                                                <td>
                                                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                                        <button
                                                            type="button"
                                                            onClick={() => handleChange(item, -1)}
                                                            style={{ background: '#ccc' }}
                                                        >
                                                            -
                                                        </button>
                                                        <button type="button" style={{ background: '#ff740f' }}>
                                                            {item?.qty}
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={() => handleChange(item, 1)}
                                                            style={{ background: '#ccc' }}
                                                        >
                                                            +
                                                        </button>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p>{item?.garagePrice}</p>
                                                </td>
                                                <td>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Part Size</InputLabel>
                                                        <Select
                                                            style={{ width: '150px' }}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={cart[index]?.partType}
                                                            required
                                                            label="Status"
                                                            onChange={(e) => handlePartType(e, item, index)}
                                                        >
                                                            <MenuItem value="Body">Select an option</MenuItem>
                                                            <MenuItem value="Boxes">Boxe(s)</MenuItem>
                                                            <MenuItem value="Pieces">Pieces</MenuItem>
                                                            <MenuItem value="Gallons">Gallons</MenuItem>
                                                            <MenuItem value="Drums">Drums</MenuItem>
                                                            <MenuItem value="Rolls">Roll(s)</MenuItem>
                                                            <MenuItem value="Bucket">Bucket</MenuItem>
                                                            <MenuItem value="Packs">Pack(s)</MenuItem>
                                                            <MenuItem value="Litre">Litre(s)</MenuItem>
                                                            <MenuItem value="Pair">Pair(s)</MenuItem>
                                                            <MenuItem value="Carton">Carton(s)</MenuItem>
                                                            <MenuItem value="Bag">Bag(s)</MenuItem>
                                                            <MenuItem value="Set">Set(s)</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </td>
                                                <td>
                                                    <strong>{subTotal.toFixed(2)}</strong>
                                                </td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        onClick={() => handleRemove(item._id)}
                                                        style={{ background: '#eee' }}
                                                    >
                                                        <RiDeleteBin6Line style={{ color: '#D2001A', fontSize: '1.2rem' }} />
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td colSpan={4} style={{ fontSize: '1.3rem', fontWeight: '900' }}>
                                            Total
                                        </td>
                                        <td colSpan={4} style={{ fontSize: '1rem', fontWeight: '800' }}>
                                            GH<>&#8373;</> - {price.toFixed(2)}
                                        </td>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </Col>
                </Row>
                <div style={{ padding: '1rem 2rem' }}>
                    <Row>
                        <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                            <div className="mb-4">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Date"
                                        inputFormat="MM/dd/yyyy"
                                        value={orderDate}
                                        onChange={(value) => setOrderDate(value)}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="mb-4">
                                <TextField
                                    autoComplete="off"
                                    name="orderNotes"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                    fullWidth
                                    value={orderNotes}
                                    id="orderNotes"
                                    label="Additional Notes......."
                                    onChange={(e) => setorderNotes(e.target.value)}
                                />
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                            <div className="mb-4">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Sales / Ops Focal Name</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={salesTeamId}
                                        required
                                        label="Sales Team"
                                        onChange={handleSalesChange}
                                    >
                                        {salesPersonnels.map((sales) => (
                                            <MenuItem key={sales._id} value={sales._id}>
                                                {sales?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="mb-4">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Channel Name</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={channelId}
                                        required
                                        label="Channel Name"
                                        onChange={handleChannelChange}
                                    >
                                        {channels.map((channel) => (
                                            <MenuItem key={channel._id} value={channel._id}>
                                                {channel.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                            <div className="mb-4">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Order Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={newOrderStatusId}
                                        required
                                        label="Status"
                                        onChange={handleOrderStatusChange}
                                    >
                                        <MenuItem value="Body">Select an option</MenuItem>
                                        <MenuItem value="620d76d63a9c8148935c696b">Order Generated</MenuItem>
                                        <MenuItem value="625311abbd74072935e58592">Proforma Invoice Request</MenuItem>
                                        <MenuItem value="6334bab7afa1158e678a87ad">Sales Drive</MenuItem>
                                        <MenuItem value="6342dc27f627d58acac98e0f">Dark Store</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="mb-4">
                                <FormControl fullWidth>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        value={channelPartnerId}
                                        onChange={(event, newValue) => {
                                            setChannelPartnerId(newValue);
                                            filterOptions(newValue);
                                        }}
                                        options={channelpartners.map((option) => option.partnerName)}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => <TextField {...params} label="Channel Partner Name" />}
                                    />
                                </FormControl>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="parts-not">
                    <Divider>
                        <Button variant="contained" size="large" style={{ background: '#193C55' }} onClick={handleShow}>
                            Add Parts not in system
                        </Button>
                    </Divider>
                    {partsdata.length > 0 && (
                        <div className="mt-5 table-responsive" style={{ padding: '1rem 2rem' }}>
                            <table className="table table-striped table-hoverable">
                                <thead>
                                    <tr>
                                        <th>Part </th>
                                        <th>Quantity</th>
                                        <th>Part Type</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {partsdata.map((part, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{part?.partname}</td>
                                                <td>{part?.partqty}</td>
                                                <td>{part?.partsize}</td>
                                                <td>
                                                    <IconButton
                                                        aria-label="Remove part from note"
                                                        title="Remove part from note"
                                                        onClick={() => handlePartRemoval(index)}
                                                    >
                                                        <a href="#!" style={{ color: '#E64848' }}>
                                                            <RiDeleteBin6Line />
                                                        </a>
                                                    </IconButton>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', margin: '1rem' }}>
                    <LoadingButton
                        variant="contained"
                        loading={isLoading}
                        size="large"
                        onClick={handleFinalOrderRequest}
                        style={{ background: '#ff740f' }}
                    >
                        Submit Order
                    </LoadingButton>
                </div>
            </Paper>
            {/* Parts not in system modal */}
            <Modal show={open} onHide={handleClose} centered>
                <ModalWrapper>
                    <Modal.Title>
                        <h5 className="title">Add parts not in system</h5>
                        <hr />
                    </Modal.Title>
                    <Modal.Body>
                        <div className="content">
                            <div className="mb-4">
                                <TextField
                                    autoComplete="off"
                                    name="partname"
                                    variant="outlined"
                                    type="text"
                                    required
                                    id="partname"
                                    label="Part Name"
                                    value={partname}
                                    onChange={(e) => setpartname(e.target.value)}
                                    fullWidth
                                />
                            </div>
                            <div className="mb-4">
                                <TextField
                                    autoComplete="off"
                                    name="partqty"
                                    variant="outlined"
                                    required
                                    type="number"
                                    id="partqty"
                                    label="Qty"
                                    value={partqty}
                                    onChange={(e) => setpartqty(e.target.value)}
                                    fullWidth
                                />
                            </div>
                            <div className="mb-4">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Part Size</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={partsize}
                                        required
                                        label="Status"
                                        onChange={handlePartTypeSize}
                                    >
                                        <MenuItem value="Body">Select an option</MenuItem>
                                        <MenuItem value="Boxes">Boxe(s)</MenuItem>
                                        <MenuItem value="Pieces">Pieces</MenuItem>
                                        <MenuItem value="Gallons">Gallons</MenuItem>
                                        <MenuItem value="Drums">Drums</MenuItem>
                                        <MenuItem value="Rolls">Roll(s)</MenuItem>
                                        <MenuItem value="Bucket">Bucket</MenuItem>
                                        <MenuItem value="Packs">Pack(s)</MenuItem>
                                        <MenuItem value="Litre">Litre(s)</MenuItem>
                                        <MenuItem value="Pair">Pair(s)</MenuItem>
                                        <MenuItem value="Carton">Carton(s)</MenuItem>
                                        <MenuItem value="Bag">Bag(s)</MenuItem>
                                        <MenuItem value="Set">Set(s)</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </Modal.Body>
                    <div className="modal-actions">
                        <MuiButton variant="contained" onClick={handleClose} className="cancel-btn">
                            Cancel
                        </MuiButton>
                        <LoadingButton onClick={handlePartsNotInSystem} loading={isLoading} variant="contained" className="save-btn">
                            Save
                        </LoadingButton>
                    </div>
                </ModalWrapper>
            </Modal>
        </>
    );
};

export default ShopCart;

const ModalWrapper = styled.div`
    .modal-actions {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 30px;
    }

    .title {
        color: #ff740f;
        font-weight: 700;
        text-align: center;
        padding: 1rem 0;
    }

    .cancel-btn {
        background-color: #193855;
        margin-left: -3rem;
    }

    .save-btn {
        background-color: #ff740f;
        margin-right: -2rem;
    }

    .content {
        padding: 1rem 2rem;
        margin-bottom: 50px;
    }
`;
