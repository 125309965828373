import { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
import ApiUrl from 'network';
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Divider, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, Stack, Typography } from '@mui/material';

// third party  useMediaQuery
// import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from '@mui/styles';
// import Google from 'assets/images/icons/social-google.svg';

// ============================|| - LOGIN ||============================ //
const useStyles = makeStyles(() => ({
    color: {
        color: '#FF740F'
    },
    backgroundColor: {
        backgroundColor: '#FF740F'
    }
}));
const ForgetPassword = () => {
    const theme = useTheme();
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [formErrors, setFormErrors] = useState({});
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && loading) {
            // console.log(email, password);
        }
    }, [formErrors, loading]);
    const validated = (email) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!email) {
            errors.email = 'Email Address is required!';
        } else if (!regex.test(email)) {
            errors.email = 'Please enter a valid email address';
        }

        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setFormErrors(validated(email));
        setLoading(true);
        const data = {
            email
        };
        fetch(ApiUrl.ForgetPasswordUrl, {
            method: 'POST',
            headers: {
                Accept: 'application/form-data',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status) {
                    toast.success(result.message);
                    setLoading(false);
                    setTimeout(() => {
                        if (scriptedRef.current) {
                            history('/login');
                        }
                    }, 3000);
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {/* Same as */}
                <ToastContainer />

                <Grid item xs={12}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />

                        <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
                    </Box>
                </Grid>
            </Grid>

            <Formik>
                {({ errors, handleBlur, touched }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-login">Email Address</InputLabel>
                            <OutlinedInput
                                autoComplete="off"
                                id="outlined-adornment-email-login"
                                type="email"
                                name="email"
                                value={email}
                                onBlur={handleBlur}
                                onChange={(e) => setEmail(e.target.value)}
                                label="Enter your email address"
                                inputProps={{}}
                            />

                            <FormHelperText error id="standard-weight-helper-text-email-login">
                                {formErrors.email}
                            </FormHelperText>
                        </FormControl>

                        <Stack direction="row" flexDirection="row-reverse" alignItems="center" justifyContent="space-between" spacing={1}>
                            <Typography
                                component={Link}
                                to="/login"
                                variant="subtitle1"
                                className={classes.color}
                                sx={{ textDecoration: 'none', cursor: 'pointer' }}
                            >
                                Sign In
                            </Typography>
                        </Stack>
                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    // disabled={!email || !password || loading}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className={classes.backgroundColor}
                                >
                                    {loading ? 'Sending ..' : 'Send'}
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default ForgetPassword;
