// assets
import { IconSettings, IconHelp } from '@tabler/icons';
import SettingsIcon from '@mui/icons-material/Settings';

// constant
const icons = { IconSettings, IconHelp, SettingsIcon };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //
const userRole = localStorage.getItem('userRole');
const other = {
    id: 'fleetsettings',
    type: userRole,
    children: [
        {
            id: 'fleetsettings',
            title: 'Settings',
            type: 'item',
            url: '/fleetsettings',
            icon: icons.SettingsIcon,
            breadcrumbs: false
        }
    ]
};

export default other;
