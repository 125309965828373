/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import * as XLSX from 'xlsx';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';
// import Controls from '../../components/controls/Controls';
// import { Search } from '@mui/icons-material';
// import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import ViewIcon from '@mui/icons-material/ViewAgenda';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
// import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
// import axios from 'axios';
// import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Avatar from '@mui/material/Avatar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import FormControl from '@mui/material/FormControl';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
// import NoteAltIcon from '@mui/icons-material/NoteAlt';
// import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
// import SortIcon from '@mui/icons-material/Sort';
// import * as blobUtil from 'blob-util';
// import { Camera } from 'react-camera-pro';
import { useTheme } from '@mui/material/styles';
// import { saveAs } from 'file-saver';
// import axios from 'axios';
import { Col } from 'react-grid-system';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    }
}));

const SalesDriveBulk = () => {
    const classes = useStyles();
    const history = useNavigate();
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const [orderInfo, setOrder] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [startDate, setstartDate] = useState(new Date());
    // const [endDate, setendDate] = useState(new Date());
    // const [loading2, setLoading2] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searched, setSearched] = useState('');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleShowClick = (row) => {
        if (scriptedRef.current) {
            // console.log(row); preview
            history(`/salesdrivebulkshow/${row._id}`);
        }
    };

    const handleUpdateClick = (row) => {
        if (scriptedRef.current) {
            // console.log(row); preview
            history(`/salesdrivebulksupdate/${row._id}`);
        }
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderInfo.length) : 0;

    const retrieveAllOrders = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.SalesDrivePodListUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        // console.log('data', data);
                        setOrder(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        retrieveAllOrders();
    }, []);

    const filteredRows = orderInfo.filter((row) => (row?.salesDrivePodId || '').toLowerCase().includes(searched.toLowerCase()));
    const isPlural = filteredRows && filteredRows.length > 1 ? 's' : '';
    const searchCount = searched ? `${filteredRows.length} result${isPlural} found` : 'No Results';

    // const handleDeleteClick = (row) => {
    //     swal({
    //         title: 'Are you sure?',
    //         text: `Once deleted, you will not be able to recover this Order !(${row._id})`,
    //         icon: 'warning',
    //         buttons: true,
    //         dangerMode: true
    //     }).then((willDelete) => {
    //         if (willDelete) {
    //             swal('Poof! The Order has been deleted!', {
    //                 icon: 'success'
    //             });

    //             const data = {
    //                 id: row._id
    //             };
    //             const userInfo = localStorage.getItem('userToken');

    //             fetch(ApiUrl.DeleteSalesOrderRequest, {
    //                 method: 'DELETE',
    //                 headers: {
    //                     'auth-token': userInfo,
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify(data)
    //             })
    //                 .then((res) => res.json())
    //                 .then((result) => {
    //                     if (result.status === true) {
    //                         if (scriptedRef.current) {
    //                             retrieveAllOrders();
    //                         }
    //                     } else {
    //                         toast.error(result.data);
    //                     }
    //                 });
    //         } else {
    //             swal('The  Order is safe!');
    //         }
    //     });
    // };
    const handlePdfClick = (row) => {
        // console.log('row', row);
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(row?.items);
        XLSX.utils.book_append_sheet(wb, ws, 'SalesDriveBulk');
        XLSX.writeFile(wb, 'SalesDriveBulk.xlsx');
    };

    return (
        <>
            <PageHeader title="My Sales Drive Bulk Orders" subTitle="Sales Drive | Bulk Order " />

            <MainCard title="My Sales Drive Bulk List">
                <h4 style={{ color: '#ff740f', fontWeight: '700' }}>{searched ? searchCount : `Total Orders: ${orderInfo.length}`}</h4>
                <br />
                <Box display="flex" justifyContent="flex-start">
                    {/* <Controls.Input
                        label="Search Partner Name"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearch}
                    /> */}
                    <Col xs={12} xl={3} lg={3} md={6} sm={6}>
                        <FormControl fullWidth style={{ marginBottom: '10px' }}>
                            <TextField
                                id="outlined-basic"
                                label="Search Pod Name"
                                variant="outlined"
                                onChange={(e) => setSearched(e.target.value)}
                            />
                        </FormControl>
                    </Col>
                </Box>
                <br />

                <Box width={20} />
                <Box display="flex" justifyContent="flex-start">
                    <Box width={10} />
                    <Button component={Link} to="/addsalesdrivebulk" variant="contained" size="sm" className={classes.item}>
                        Add
                    </Button>
                </Box>
                <br />

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order Date
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Pod Name
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order No.
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Country
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Status
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : filteredRows
                            ).map((row, index) => (
                                <TableRow key={row._id}>
                                    <TableCell component="th" scope="row">
                                        {filteredRows.length - index}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {toTimestamp(row?.salesDriveDate)}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.salesDrivePodId}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.orderNo}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.country}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.status === 'Batched' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.success.light,
                                                    color: theme.palette.success.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row?.status ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row?.status === 'Received' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.primary.light,
                                                    color: theme.palette.primary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.status ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        <IconButton aria-label="view" color="warning" onClick={() => handleShowClick(row)}>
                                            preview
                                        </IconButton>
                                        {row?.status === 'Received' ? (
                                            <IconButton aria-label="view" color="primary" onClick={() => handleUpdateClick(row)}>
                                                update
                                            </IconButton>
                                        ) : (
                                            ''
                                        )}
                                        <IconButton aria-label="view" color="success" onClick={() => handlePdfClick(row)}>
                                            Excel data
                                        </IconButton>
                                        <IconButton
                                            aria-label="delete"
                                            color="success"
                                            sx={{
                                                fontSize: '18px',
                                                padding: '5px',
                                                borderRadius: '0px ',
                                                fontWeight: '600'
                                                // width: '100%'
                                            }}
                                            onClick={() => (window.location.href = `/addissue/${row?._id}`)}
                                        >
                                            Issue
                                        </IconButton>
                                        <IconButton
                                            aria-label="delete"
                                            color="success"
                                            sx={{
                                                fontSize: '18px',
                                                padding: '5px',
                                                borderRadius: '0px ',
                                                fontWeight: '600'
                                                // width: '100%'
                                            }}
                                            onClick={() => (window.location.href = `/epl/cc-rma-request-add/${row._id}`)}
                                        >
                                            Epl Request
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[20, 30, 50, { label: 'All', value: -1 }]}
                    component="div"
                    count={orderInfo.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default SalesDriveBulk;
