/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import Button from '@mui/material/Button';
import { Link, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Avatar from '@mui/material/Avatar';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';

import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

// import styled from 'styled-components';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const ViewDeposited = () => {
    // page states

    const classes = useStyles();
    const [partsInfo, setParts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - partsInfo.length) : 0;

    const { id } = useParams();

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };
        setLoading(true);
        fetch(ApiUrl.ViewOrdersUrl + id, config)
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setParts(data.data.collectiondatas);
                } else {
                    toast.error(data.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    return (
        <>
            <Grid item xs={12} sm={12}>
                <MainCard title="View Deposit">
                    <br />
                    <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                        <Button component={Link} to="/depositedorderlist" type="submit" variant="contained" color="error">
                            Back
                        </Button>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table aria-label="caption table">
                            <TableHead>
                                <TableRow>
                                    {loading ? (
                                        <TableCell component="th" colSpan="4" align="center">
                                            {' '}
                                            <CircularProgress size={20} thickness={4} />
                                            {'LOADING...... '}
                                        </TableCell>
                                    ) : (
                                        <>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                #
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Collected Date
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Collected Amount
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Channel Partner
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Sales Person
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Deposited Date
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Deposited Amount
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Status
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Approved Date
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Approved Amount
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Appproved User
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Order ID
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Reference Note
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Image
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0 ? partsInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : partsInfo).map(
                                    (row, index) => (
                                        <TableRow key={row._id}>
                                            <TableCell component="th" scope="row">
                                                {index + 1}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {row?.collectedDate ? toTimestamp(row?.collectedDate) : ''}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {Number.isFinite(row?.collectedAmt) ? Number(row?.collectedAmt).toFixed(2) : 0}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {row?.channelPartnerId ?? ''}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row?.salesname ?? ''}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row?.depositeDate ? toTimestamp(row?.depositeDate) : ''}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {Number.isFinite(row?.depositeAmt) ? Number(row?.depositeAmt).toFixed(2) : 0}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {row?.approveStatus ?? ''}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {row?.approveDate ? toTimestamp(row?.approveDate) : ''}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {row?.approveAmt ?? ''}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {row?.approveUser ?? ''}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {row?.id ?? ''}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {row?.referenceNote ?? ''}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                <Avatar variant="rounded" src={row?.depositeImage} sx={{ width: 80, height: 50 }} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        component="div"
                        count={partsInfo.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </MainCard>
            </Grid>
        </>
    );
};

export default ViewDeposited;
