/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
import ApiUrl from 'network';
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography
} from '@mui/material';

// third party  useMediaQuery
// import * as Yup from 'yup';
import { Formik } from 'formik';
import { makeStyles } from '@mui/styles';
// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Radio } from 'antd';

// import Google from 'assets/images/icons/social-google.svg';

// ============================|| - LOGIN ||============================ //
const useStyles = makeStyles(() => ({
    color: {
        color: '#FF740F'
    },
    backgroundColor: {
        backgroundColor: '#FF740F'
    }
}));

const Login = () => {
    const theme = useTheme();
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [checked, setChecked] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [rolePicker, setRolePicker] = useState(false);
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);
    const [userData, setUserData] = useState({});
    const [token, setToken] = useState(null);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        // console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && loading) {
            // console.log(email, password);
        }
    }, [formErrors, loading]);

    const validated = (email, password) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!email) {
            errors.email = 'Email Address is required!';
        } else if (!regex.test(email)) {
            errors.email = 'Please enter a valid email address';
        }
        if (!password) {
            errors.password = 'Password is required!';
        } else if (password.length < 6) {
            errors.password = 'Password must be more than 6 characters!';
        }

        return errors;
    };

    const retrieveProfile = async (userInfo) => {
        try {
            // const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.UserProfile, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        // console.log(data.data);
                        if (!localStorage.getItem('userRole')) {
                            localStorage.setItem('userRole', data.data.roleId?.name ?? '');
                        }
                        localStorage.setItem('userFirstName', data.data.firstName);
                        localStorage.setItem('userLastName', data.data.lastName);
                        localStorage.setItem('userEmail', data.data.email);
                        localStorage.setItem('channelpartnerId', data?.data?.channelPartnerId ?? '');
                        localStorage.setItem('userId', data?.data?._id ?? '');
                        localStorage.setItem('photo', data?.data?.photo ?? '');
                        localStorage.setItem('userCurrency', data?.data?.currency ?? '');
                        localStorage.setItem('company', data?.data?.company ?? '');
                        localStorage.setItem('country', data?.data?.country ?? '');
                        localStorage.setItem('key', data?.data?.key ?? '');
                        localStorage.setItem('iv', data?.data?.iv ?? '');
                        localStorage.setItem('currentOrderType', 'Regular');
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setFormErrors(validated(email, password));
        setLoading(true);
        const data = {
            email,
            password
        };
        fetch(ApiUrl.LoginUrl, {
            method: 'POST',
            headers: {
                Accept: 'application/form-data',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status) {
                    if (result.roles.length > 1) {
                        console.log(result.roles);
                        setRoles(result.roles);
                        localStorage.setItem('userRoles', JSON.stringify(result.roles));
                        setToken(result.token);
                        setRolePicker(true);
                    } else {
                        toast.success(result.message);
                        setLoading(false);
                        localStorage.setItem('userToken', result.token);
                        retrieveProfile(result.token);
                        console.log('result', result);
                        setTimeout(() => {
                            if (scriptedRef.current) {
                                const userInfo = localStorage.getItem('userToken');
                                if (userInfo) {
                                    history('/');
                                    // eslint-disable-next-line no-restricted-globals
                                    location.reload();
                                }
                            }
                        }, 5000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                    if (result?.message === 'otp') {
                        setTimeout(() => {
                            if (scriptedRef.current) {
                                history('/otpverification');
                            }
                        }, 3000);
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const setRolehandler = () => {
        if (selectedRole) {
            toast.success('Role selected successfully');
            localStorage.setItem('userToken', token);
            console.log('token', token);
            localStorage.setItem('userRole', selectedRole.roleName);
            console.log('role', selectedRole);
            retrieveProfile(token);
            setTimeout(() => {
                if (scriptedRef.current) {
                    const userInfo = localStorage.getItem('userToken');
                    if (userInfo) {
                        history('/');
                        // eslint-disable-next-line no-restricted-globals
                        location.reload();
                    }
                }
            }, 2000);
        }
    };

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {/* Same as */}
                <ToastContainer />
                {/* <Grid item xs={12}>
                    <AnimateButton>
                        <Button
                            disableElevation
                            fullWidth
                            onClick={googleHandler}
                            size="large"
                            variant="outlined"
                            sx={{
                                color: 'grey.700',
                                backgroundColor: theme.palette.grey[50],
                                borderColor: theme.palette.grey[100]
                            }}
                        >
                            <Box sx={{ mr: { xs: 1, sm: 2, width: 20 } }}>
                                <img src={Google} alt="google" width={16} height={16} style={{ marginRight: matchDownSM ? 8 : 16 }} />
                            </Box>
                            Sign in with Google
                        </Button>
                    </AnimateButton>
                </Grid> */}
                <Grid item xs={12}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />

                        {/* <Button
                            variant="outlined"
                            sx={{
                                cursor: 'unset',
                                m: 2,
                                py: 0.5,
                                px: 7,
                                borderColor: `${theme.palette.grey[100]} !important`,
                                color: `${theme.palette.grey[900]}!important`,
                                fontWeight: 500,
                                borderRadius: `${customization.borderRadius}px`
                            }}
                            disableRipple
                            disabled
                        >
                            OR
                        </Button> */}

                        <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
                    </Box>
                </Grid>
                {/* <Grid item xs={12} container alignItems="center" justifyContent="center">
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">Sign in with Email address</Typography>
                    </Box>
                </Grid> */}
            </Grid>

            <Formik
            // initialValues={{
            //     email: 'info@garagemobility.com',
            //     password: '123456',
            //     submit: null
            // }}

            // onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            //     try {
            //         if (scriptedRef.current) {
            //             setStatus({ success: true });
            //             setSubmitting(false);
            //         }
            //     } catch (err) {
            //         console.error(err);
            //         if (scriptedRef.current) {
            //             setStatus({ success: false });
            //             setErrors({ submit: err.message });
            //             setSubmitting(false); handleChange, handleSubmit,values
            //         }
            //     }
            // }}
            >
                {({ errors, handleBlur, touched }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-login">Email Address</InputLabel>
                            <OutlinedInput
                                autoComplete="off"
                                id="outlined-adornment-email-login"
                                type="email"
                                name="email"
                                value={email}
                                onBlur={handleBlur}
                                onChange={(e) => setEmail(e.target.value)}
                                label="Email Address"
                                inputProps={{}}
                            />

                            <FormHelperText error id="standard-weight-helper-text-email-login">
                                {formErrors.email}
                            </FormHelperText>
                        </FormControl>

                        <FormControl
                            fullWidth
                            error={Boolean(touched.password && errors.password)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-password-login">Password</InputLabel>
                            <OutlinedInput
                                autoComplete="off"
                                id="outlined-adornment-password-login"
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                value={password}
                                onBlur={handleBlur}
                                onChange={(e) => setPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                inputProps={{}}
                            />

                            <FormHelperText error id="standard-weight-helper-text-password-login">
                                {formErrors.password}
                            </FormHelperText>
                        </FormControl>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={(event) => setChecked(event.target.checked)}
                                        name="checked"
                                        className={classes.color}
                                    />
                                }
                                label="Remember me"
                            />
                            <Typography
                                component={Link}
                                to="/forgetpassword"
                                variant="subtitle1"
                                className={classes.color}
                                sx={{ textDecoration: 'none', cursor: 'pointer' }}
                            >
                                Forgot Password?
                            </Typography>
                        </Stack>
                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    // disabled={!email || !password || loading}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className={classes.backgroundColor}
                                >
                                    {loading ? 'Submitting ..' : 'Sign In'}
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
            <Modal
                open={rolePicker}
                onCancel={() => {
                    setRolePicker(false);
                    setEmail('');
                    setPassword('');
                    setLoading(false);
                }}
                footer={null}
                width={500}
            >
                <div>
                    <p style={{ textAlign: 'center', fontSize: '18px', marginTop: '20px', color: '#434A5B', fontWeight: 'bold' }}>
                        You have more than one role. <br /> Select a role to continue
                    </p>
                    <hr style={{ border: '0.5px dotted #E7EAEB', marginBottom: '10px', marginTop: '10px' }} />

                    <div style={{ marginTop: '20px', borderRadius: '10px', border: '1px solid #E7EAEB' }}>
                        <Radio.Group
                            onChange={(e) => setSelectedRole(roles.find((role) => role.roleName === e.target.value))}
                            value={selectedRole ? selectedRole.roleName : ''}
                            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                        >
                            {roles?.map((role, index) => (
                                <div key={index}>
                                    <Radio key={index} value={role.roleName} style={{ padding: '20px', width: '100%' }}>
                                        {role.roleName}
                                    </Radio>
                                    {index !== roles.length - 1 && (
                                        <hr style={{ border: 'none', backgroundColor: '#E7EAEB', padding: '0.5px' }} />
                                    )}
                                </div>
                            ))}
                        </Radio.Group>
                    </div>

                    {selectedRole && (
                        <Button
                            onClick={setRolehandler}
                            loading={loading}
                            style={{
                                backgroundColor: '#FF740F',
                                color: '#fff',
                                borderRadius: '5px',
                                marginRight: '10px',
                                marginTop: '20px',
                                width: '100%',
                                height: '40px'
                            }}
                        >
                            {' '}
                            {loading ? 'Submitting ..' : 'Continue'}{' '}
                        </Button>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default Login;
