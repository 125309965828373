import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

const EditTag = () => {
    const [name, setName] = useState('');
    const [attribute, setattribute] = useState('');
    const [attributeData, setAttributeData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const history = useNavigate();
    const scriptedRef = useScriptRef();

    const { id } = useParams();

    const retrieveAllAttribute = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AttributeUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setAttributeData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handleAttributeChange = (event) => {
        setattribute(event.target.value);
    };

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        retrieveAllAttribute();
        fetch(ApiUrl.ViewTagUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setName(result.data.name);
                    setattribute(result?.data?.attribute?._id);
                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading2(false);
            });
    }, [id]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');

        const data = {
            id,
            name,
            attribute
        };
        setLoading(true);
        fetch(ApiUrl.UpdateTagUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    // window.location.href = '/roles';
                    toast.success(result.message);
                    setLoading(false);
                    setTimeout(() => {
                        if (scriptedRef.current) {
                            history('/tags');
                        }
                    }, 3000);
                } else {
                    toast.error(result.data);
                    // console.log(result);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <br />
                            <PageHeader title="Tag" subTitle="Home | Configuration | Setup | Tag" />
                            <br />
                            <MainCard title="Edit Tag">
                                <form onSubmit={handleSubmit}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Choose a Attribute</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={attribute}
                                            label="Attribute"
                                            onChange={handleAttributeChange}
                                        >
                                            {attributeData.map((attr) => (
                                                <MenuItem key={attr._id} value={attr._id}>
                                                    {attr?.name ?? ''}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="name"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="name"
                                        label="Tag Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        autoFocus
                                    />

                                    <br />
                                    <br />
                                    <Box display="flex" justifyContent="space-between">
                                        <Button component={Link} to="/tags" type="submit" variant="contained" color="error">
                                            Reset
                                        </Button>

                                        <Button type="submit" variant="contained" color="secondary">
                                            {loading ? 'Updating....' : 'Update'}
                                        </Button>
                                    </Box>
                                </form>
                            </MainCard>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default EditTag;
