import { Paper } from '@mui/material';
import GenericPageHeader from 'components/GenericPageHeader';
import Loader from 'components/Loader';
import { useAppContext } from 'context/appContext';
import moment from 'moment/moment';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { SiMicrosoftexcel } from 'react-icons/si';
import IconButton from '@mui/material/IconButton';

const Wrapper = styled.div`
    .orders {
        padding: 2rem 4rem;
        border-radius: 0;

        tr td {
            padding: 1rem;
        }

        .excel-generator {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .batch-time {
            padding: 1rem;
            p {
                font-size: 1.2rem;
                font-weight: 800;
                color: #ff740f;
            }
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
    }
`;

const BatchOrders = () => {
    const { isLoading, orderedParts, currentBatchDate, currentBatchNo, getSupplierBatchContentById, handleExcelDataExport } =
        useAppContext();
    const { id } = useParams();
    const excelName = `orders-${currentBatchDate}`;

    useEffect(() => {
        getSupplierBatchContentById(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <GenericPageHeader title="Order Batches" breadcrumbs="Home | Dashboard | Orders" />
            <Wrapper>
                <Paper elevation={1} className="orders">
                    <div className="excel-generator">
                        <IconButton
                            aria-label="Generate Excel"
                            style={{ color: '#76BA99', fontSize: '30px' }}
                            title="Excel Generator"
                            onClick={() => handleExcelDataExport(orderedParts, excelName)}
                        >
                            <SiMicrosoftexcel />
                        </IconButton>
                    </div>
                    <div className="batch-time">
                        <p>Batch No: {currentBatchNo} </p>
                        <p>Sent: {moment(currentBatchDate).fromNow()}</p>
                    </div>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <div className="table-responsive">
                            <table className="table  table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Part Name</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Part Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderedParts.map((el, i) => {
                                        const { partid, qty, partType } = el;
                                        return (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{partid}</td>
                                                <td>{qty}</td>
                                                <td>{partType}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}
                </Paper>
            </Wrapper>
        </>
    );
};

export default BatchOrders;
