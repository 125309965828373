import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { Link } from 'react-router-dom';
// import useScriptRef from 'hooks/useScriptRef';
import ApiUrl from 'network';
import { toast } from 'react-toastify';

const VinSearch = () => {
    const [vin, setVin] = useState('');
    const [vindata, setVindata] = useState({});
    const [loading, setloading] = useState(true);
    // const history = useNavigate();
    // const scriptedRef = useScriptRef();

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        console.log(userInfo);

        const data = {
            vin
        };
        setloading(true);
        fetch(ApiUrl.VinSearchUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setloading(false);
                    setVindata(result.data);
                    console.log(result.data);
                } else {
                    toast.error(result.data);
                    setloading(true);
                }
            })
            .finally(() => {
                // setloading(true);
            });
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <br />
                    <PageHeader title="Vin Search" subTitle="Home | VIN Search" />
                    <br />
                    <MainCard title="Vin Search">
                        <form onSubmit={handleSubmit}>
                            <TextField
                                name="vin"
                                variant="outlined"
                                required
                                fullWidth
                                value={vin}
                                id="vin"
                                label="VIN"
                                onChange={(e) => setVin(e.target.value)}
                                autoFocus
                            />
                            <br />
                            <br />

                            <Box display="flex" justifyContent="space-between">
                                <Link to="/garagevinsearch">
                                    <Button type="submit" variant="contained" color="error">
                                        Reset
                                    </Button>
                                </Link>

                                <Button type="submit" variant="contained" color="secondary">
                                    Search
                                </Button>
                            </Box>
                            <br />
                            <br />
                            {loading ? (
                                ''
                            ) : (
                                <>
                                    <TextField variant="outlined" fullWidth disabled value={vindata.vin} label="VIN" />
                                    <br /> <br />
                                    <TextField variant="outlined" fullWidth disabled value={vindata.year} label="YEAR" />
                                    <br /> <br />
                                    <TextField variant="outlined" fullWidth disabled value={vindata.make} label="MAKE" />
                                    <br /> <br />
                                    <TextField variant="outlined" fullWidth disabled value={vindata.model} label="MODEL" />
                                    <br /> <br />
                                    <TextField variant="outlined" fullWidth disabled value={vindata.trim_level} label="TRIM LEVEL" />
                                    <br /> <br />
                                    <TextField variant="outlined" fullWidth disabled value={vindata.engine} label="ENGINE" />
                                    <br /> <br />
                                    <TextField variant="outlined" fullWidth disabled value={vindata.style} label="STYLE" />
                                    <br /> <br />
                                    <TextField variant="outlined" fullWidth disabled value={vindata.made_in} label="MADE IN" />
                                    <br /> <br />
                                    <TextField variant="outlined" fullWidth disabled value={vindata.steering_type} label="STEERING TYPE" />
                                    <br /> <br />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        value={vindata.anti_brake_system}
                                        label="ANTI BRAKE SYSTEM"
                                    />
                                    <br /> <br />
                                    <TextField variant="outlined" fullWidth disabled value={vindata.tank_size} label="TANK SIZE" />
                                    <br /> <br />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        value={vindata.overall_height}
                                        label="OVERALL HEIGHT"
                                    />
                                    <br /> <br />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        value={vindata.overall_length}
                                        label="OVERALL LENGTH"
                                    />
                                    <br /> <br />
                                    <TextField variant="outlined" fullWidth disabled value={vindata.overall_width} label="OVERALL WIDTH" />
                                    <br /> <br />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        value={vindata.standard_seating}
                                        label="STANDARD SEATING"
                                    />
                                    <br /> <br />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        value={vindata.optional_seating}
                                        label="OPTIONAL SEATING"
                                    />
                                    <br /> <br />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        value={vindata.highway_mileage}
                                        label="HIGHWAY MILEAGE"
                                    />
                                    <br /> <br />
                                    <TextField variant="outlined" fullWidth disabled value={vindata.city_mileage} label="CITY MILEAGE" />
                                    <br /> <br />
                                </>
                            )}
                        </form>
                    </MainCard>
                </Grid>
            </Grid>
        </>
    );
};

export default VinSearch;
