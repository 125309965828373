import React from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import ReplyMessage from './ReplyMessage';
import MediaContent from './MediaContent';
import DeliveryStatusIcon from './DeliveryStatusIcon';
import dayjs from 'dayjs';

const MessageItem = React.forwardRef(({ message, loggedInUserFullName, allMessages, actionContent, scrollToMessage }, ref) => {
    const repliedMessage = allMessages.find((msg) => {
        return message.replyTo && (msg.whatsapp_id === message.replyTo || msg.whatsapp_id === message.whatsapp_reply);
    });

    return (
        <div className={message.isSent ? 'chat-box-message-send' : 'chat-box-message-recieve'} ref={ref}>
            <div>
                {message?.user && message.user !== loggedInUserFullName && message.agent !== 'mira' && message.agent !== 'staff' && (
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2px' }}>
                        <div className="sender-avatar-chat">{message?.user?.slice(0, 2).toUpperCase()}</div>
                        <small style={{ color: '#0A2938', fontSize: '12px', marginBottom: '5px', fontWeight: 'bold' }}>
                            {message.user}
                        </small>
                    </div>
                )}

                <div className="message">
                    <span className="d-flex justify-content-end align-items-center">
                        <Dropdown overlay={actionContent(message)} trigger={['click']} placement="top">
                            <Button
                                size="small"
                                style={{
                                    backgroundColor: 'transparent',
                                    width: 'fit-content',
                                    fontSize: '12px',
                                    padding: '0',
                                    height: 'fit-content'
                                }}
                            >
                                <MoreOutlined />
                            </Button>
                        </Dropdown>
                    </span>
                    {/* Render replied message if available */}
                    {repliedMessage && <ReplyMessage repliedMessage={repliedMessage} scrollToMessage={scrollToMessage} />}

                    {/* Render media and content */}
                    <MediaContent message={message} />

                    {/* Render the message timestamp and delivery status icon */}
                    <small
                        style={{
                            color: '#0A2938',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '10px'
                        }}
                    >
                        <small className="mr-1"> {dayjs(message.createdAt).format('hh:mm A')}</small>
                        <DeliveryStatusIcon message={message} />
                    </small>
                </div>
            </div>
        </div>
    );
});

export default MessageItem;
