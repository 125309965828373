import styled from 'styled-components';
import { AiFillFilePdf } from 'react-icons/ai';
import IconButton from '@mui/material/IconButton';
import { useDeliveryContext } from 'context/delivery-notes/delivery-context';

const Wrapper = styled.div`
    padding: 1rem 2rem;

    .customer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media screen and (max-width: 500px) {
        .customer {
            flex-direction: column;
            justify-content: center;
        }

        .note-id {
            text-align: left;
        }
    }

    .info {
        border-left: 5px solid #ffbe36;
        padding-left: 0.625rem;
    }

    .note-id {
        p {
            text-align: left;
        }
    }
`;

const NoteHeaderInfo = ({ data }) => {
    const { handleNotePdfDownload } = useDeliveryContext();
    const { retailerId, salesPersonId, noteNo, deliverynoteDate } = data;
    return (
        <Wrapper>
            <div className="customer">
                <div className="info">
                    <p>Custormer Name: {retailerId?.partnerName}</p>
                    <p>Customer Phone #: {retailerId?.partnerContact}</p>
                    <p>Address: {retailerId?.location}</p>
                    <p>
                        Our Contact Person: {salesPersonId?.firstName} {salesPersonId?.lastName}
                    </p>
                </div>
                <div className="note-id">
                    <IconButton aria-label="call lead phone" title="Generate Pdf" onClick={() => handleNotePdfDownload(data)}>
                        <a href="#!" style={{ color: '#FF1E00' }}>
                            <AiFillFilePdf />
                        </a>
                    </IconButton>
                    <p>Delivery note ID: {noteNo}</p>
                    <p>Data Production: {new Date(deliverynoteDate).toDateString()}</p>
                </div>
            </div>
        </Wrapper>
    );
};
export default NoteHeaderInfo;
