import { Button, Collapse, Input, Select, Table, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ApiUrl from '../../../network';
import { toast } from 'react-toastify';
import { decrypt } from '../../../utils/encrypt';
import debounce from 'lodash/debounce';
import * as XLSX from 'xlsx';
import { ArrowLeftOutlined, ReloadOutlined, RightOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [parts, setParts] = useState([]);
    const [filters, setFilters] = useState({
        partId: id,
        location: '',
        classification: ''
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(0);
    const [partsFamily, setPartsFamily] = useState([]);
    const [cities, setCities] = useState([]);
    const [driveLocationList, setDriveLocationList] = useState([]);

    const fetchParts = useCallback(async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const urlParams = new URLSearchParams({
                ...filters,
                limit: perPage,
                page: currentPage
            });
            const url = `${ApiUrl.BaseUrl}/pricing/location/pricinglocation?${urlParams.toString()}`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                console.log(decryptedData, 'decryptedData');
                setParts(decryptedData);
                setCurrentPage(result.currentPage);
                setPerPage(result.perPage);
            } else {
                toast.error(result.message);
            }
        } catch (e) {
            toast(e.message);
        } finally {
            setLoading(false);
        }
    }, [filters, currentPage, perPage]);
    const getDriveLocationHandler = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/salesplanning/drivelocationslist`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setLoading(false);
                    const driveLocationList = decrypt(data.data, keyResult, ivResult);
                    setDriveLocationList(JSON.parse(driveLocationList));
                } else {
                    setLoading(false);
                    toast.error(data.message);
                }
            });
    };
    const debouncedFetchParts = useCallback(debounce(fetchParts, 300), [fetchParts]);

    useEffect(() => {
        debouncedFetchParts();
        return () => {
            debouncedFetchParts.cancel();
        };
    }, [filters, currentPage, perPage, debouncedFetchParts]);

    const fetchFilteredParts = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/parts/v2/partsfilterdata`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                setPartsFamily(decryptedData.productfamily);
                setCities(decryptedData.city);
                setLoading(false);
            } else {
                toast.error(result.message);
                setLoading(false);
            }
        } catch (e) {
            toast(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchParts();
        getDriveLocationHandler();
        fetchFilteredParts();
    }, []);

    const partSource = parts.map((part) => {
        return {
            key: part._id,
            product_name: {
                name: part.sku,
                image: part?.partIdgh?.partsImage
            },
            status: part.status,
            location: part.location,
            price: `${part.currency.symbol} ${part.price}`,
            latitude: part.lat,
            longitude: part.lng,
            qnty_in_pieces: part?.partIdgh?.partQtyInPieces,
            classification: part.classification
        };
    });
    const cityOptions = cities.map((city) => {
        return {
            value: city._id,
            label: city.city
        };
    });
    const productFamilyOptions = partsFamily.map((family) => {
        return {
            value: family.name,
            label: family.name
        };
    });
    const locationOptions = driveLocationList?.map((location) => {
        return {
            label: location.location,
            value: location.location
        };
    });

    const columns = [
        {
            title: 'Product name',
            dataIndex: 'product_name',
            key: 'product_name',
            // eslint-disable-next-line camelcase
            render: (product_name) => (
                <div className="d-flex align-items-center">
                    <img
                        src={product_name.image}
                        onError={(e) => {
                            e.target.src = 'https://via.placeholder.com/150';
                        }}
                        alt={product_name.name}
                        className="mr-2"
                        style={{
                            width: 50,
                            height: 50,
                            objectFit: 'cover',
                            borderRadius: '10px',
                            border: '1px solid #f0f0f0'
                        }}
                    />
                    <span>{product_name.name}</span>
                </div>
            )
        },
        {
            title: 'Classification',
            dataIndex: 'classification',
            key: 'classification'
        },
        {
            title: 'Latitude',
            dataIndex: 'latitude',
            key: 'latitude'
        },
        {
            title: 'Longitude',
            dataIndex: 'longitude',
            key: 'longitude'
        },
        {
            title: 'Location ',
            dataIndex: 'location',
            key: 'location'
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price'
        }
    ];

    const resetFilters = () => {
        setFilters({
            partId: id,
            location: '',
            classification: ''
        });
        setCurrentPage(1);
        setPerPage(0);
    };

    const exportDataExcel = async () => {
        const data = partSource.map((part) => {
            return {
                'Product name': part.product_name.name,
                'Product family': part.product_family,
                'Qnty in pieces': part.qnty_in_pieces,
                'Wholesale price': part.wholesale_price,
                'Garage price': part.selling_price,
                City: part.city,
                'City price': part.city_price,
                Status: part.status,
                State: part.state
            };
        });

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Parts');
        XLSX.writeFile(wb, 'Parts.xlsx');
    };

    return (
        <div>
            <div className="m-header">
                <div>
                    <Link to="/pricing-parts">
                        <h1 style={{ color: '#0A2938', display: 'flex', alignItems: 'center' }}>
                            <ArrowLeftOutlined className="mr-2" /> Product prices
                        </h1>
                    </Link>
                    <p>View product prices based on location</p>
                </div>
                <div className="d-flex">
                    <Button
                        onClick={() => (window.location.href = `/product-pricing/edit/${id}`)}
                        className="w-fit d-flex justify-content-between align-items-center"
                        style={{
                            backgroundColor: '#FFF',
                            border: '1px solid #FF740F',
                            color: '#FF740F'
                        }}
                    >
                        Edit price <RightOutlined />
                    </Button>
                    <Button
                        onClick={() => (window.location.href = `/product-pricing/add/${id}`)}
                        className="w-fit d-flex justify-content-between align-items-center"
                        style={{
                            backgroundColor: '#FF740F',
                            border: '1px solid #FF740F',
                            color: '#fff',
                            padding: '0 10px',
                            marginLeft: '10px'
                        }}
                    >
                        Add price <RightOutlined />
                    </Button>
                </div>
            </div>
            <div className="m-content mt-4">
                <Collapse accordion>
                    <Collapse.Panel key={1} header="Hide filters" className="mb-4">
                        <div className="batchFilters">
                            <div className="mr-2">
                                <Input
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            debouncedFetchParts();
                                        }
                                    }}
                                    className="filterInput"
                                    style={{
                                        width: 300
                                    }}
                                    placeholder="Search products"
                                    value={filters.partname || undefined}
                                    onChange={(e) => {
                                        setFilters({
                                            ...filters,
                                            partname: e.target.value
                                        });
                                    }}
                                />
                            </div>
                            <div className="mr-2">
                                <Select
                                    className="filterInput"
                                    style={{
                                        width: 300
                                    }}
                                    placeholder="Location"
                                    filterOption={(inputValue, option) => {
                                        if (typeof option.label === 'string') {
                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }

                                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }}
                                    showSearch
                                    allowClear
                                    options={locationOptions}
                                    value={filters.location || undefined}
                                    onChange={(value) => {
                                        setFilters({
                                            ...filters,
                                            location: value
                                        });
                                    }}
                                />
                            </div>
                            <div className="mr-2">
                                <Select
                                    className="filterInput"
                                    style={{
                                        width: 300
                                    }}
                                    placeholder="classification"
                                    filterOption={(inputValue, option) => {
                                        if (typeof option.label === 'string') {
                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }

                                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }}
                                    showSearch
                                    allowClear
                                    options={[
                                        {
                                            label: 'Low take rate',
                                            value: 'Low take rate'
                                        },
                                        {
                                            label: 'High take rate',
                                            value: 'High take rate'
                                        }
                                    ]}
                                    value={filters.classification || undefined}
                                    onChange={(value) => {
                                        setFilters({
                                            ...filters,
                                            classification: value
                                        });
                                    }}
                                />
                            </div>
                            <div className="d-flex align-items-center">
                                {filters.location !== '' || filters.classification !== '' ? (
                                    <Button
                                        style={{
                                            backgroundColor: '#FF740F',
                                            color: '#FFF'
                                        }}
                                        onClick={fetchParts}
                                    >
                                        Apply filter
                                    </Button>
                                ) : null}
                                {filters.location !== '' || filters.classification !== '' ? (
                                    <Button
                                        style={{
                                            backgroundColor: '#FFF',
                                            color: '#000',
                                            border: '1px solid #D9DBDE',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                        className="ml-2"
                                        onClick={resetFilters}
                                    >
                                        <ReloadOutlined />
                                    </Button>
                                ) : null}
                            </div>
                        </div>
                    </Collapse.Panel>
                </Collapse>
                <div className="table-responsive">
                    <Table columns={columns} dataSource={partSource} loading={loading} />
                </div>
            </div>
        </div>
    );
};

export default Index;
