/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';
import Controls from '../../components/controls/Controls';
import { Search } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import swal from 'sweetalert';
// import * as XLSX from 'xlsx';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import ViewIcon from '@mui/icons-material/ViewAgenda';
import IconButton from '@mui/material/IconButton';
// import { Link, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
// import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
// import { saveAs } from 'file-saver';
import axios from 'axios';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Form from 'react-bootstrap/Form';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    colors: {
        color: `#ED7C1B`
    }
}));

const CssOrders = () => {
    const classes = useStyles();
    // const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [orderInfo, setOrder] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [loading2, setLoading2] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [orderStatus, setOrderStatus] = useState([]);
    const [orderStatusId, setorderStatusId] = useState('');
    const [fulOrderId, setfulOrderId] = useState('');
    const [id, setid] = useState('');
    const [indexValue, setIndexValue] = useState('');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState(false);
    const userRole = localStorage.getItem('userRole');

    // const handleEditClick = (row) => {
    //     if (scriptedRef.current) {
    //         // console.log(row);
    //         history(`/viewOrder/${row._id}`);
    //     }
    // };

    // const handleShowClick = (row) => {
    //     if (scriptedRef.current) {
    //         // console.log(row);
    //         history(`/showOrder/${row._id}`);
    //     }
    // };

    const handlePdfClick = (row) => {
        // console.log('row', row);
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };
        // setLoading2(true);
        axios.post(ApiUrl.DownloadPdfOrderPreviewsUrl, row, config).then((res) => {
            window.open(res.data.data, '_blank');

            // setLoading2(false);
        });
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 30));
        setPage(0);
    };

    function toTimestamp(strDate) {
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderInfo.length) : 0;

    const retrieveAllOrders = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllCssOrdersUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrder(data.data);
                        setOrderData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllActiveOrderStatus = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllActiveOrderStatus, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrderStatus(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const onUpdateOrderNumber = (event) => {
        event.preventDefault();
        const data = {
            id,
            fulOrderId
        };
        const userInfo = localStorage.getItem('userToken');
        fetch(ApiUrl.UpdateCorrectOrderUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    if (scriptedRef.current) {
                        toast.success(result.message);
                        setOpen(false);
                        if (scriptedRef) {
                            setTimeout(() => {
                                retrieveAllOrders();
                            }, 3000);
                        }
                    }
                } else {
                    toast.error(result.data);
                }
            });
    };

    useEffect(() => {
        retrieveAllOrders();
        retrieveAllActiveOrderStatus();
    }, []);

    // const handledataexport = () => {
    //     const wb = XLSX.utils.book_new();
    //     const ws = XLSX.utils.json_to_sheet(orderInfo);
    //     XLSX.utils.book_append_sheet(wb, ws, 'Orders');
    //     XLSX.writeFile(wb, 'Orders.xlsx');
    // };

    // const handleDeleteClick = (row) => {
    //     swal({
    //         title: 'Are you sure?',
    //         text: `Once deleted, you will not be able to recover this Order !(${row._id})`,
    //         icon: 'warning',
    //         buttons: true,
    //         dangerMode: true
    //     }).then((willDelete) => {
    //         if (willDelete) {
    //             swal('Poof! The Order has been deleted!', {
    //                 icon: 'success'
    //             });

    //             // const data = {
    //             //     id: row._id
    //             // };
    //             // const userInfo = localStorage.getItem('userToken');

    //             // fetch(ApiUrl.DeleteChannelPartnerUrl, {
    //             //     method: 'DELETE',
    //             //     headers: {
    //             //         Authorization: userInfo,
    //             //         'Content-Type': 'application/json'
    //             //     },
    //             //     body: JSON.stringify(data)
    //             // })
    //             //     .then((res) => res.json())
    //             //     .then((result) => {
    //             //         if (result.status === true) {
    //             //             if (scriptedRef.current) {
    //             //                 retrieveAllOrders();
    //             //                 // window.location.href = '/stage';
    //             //                 // history('/stage');
    //             //             }
    //             //         } else {
    //             //             toast.error(result.data);
    //             //         }
    //             //     });
    //         } else {
    //             swal('The  Order is safe!');
    //         }
    //     });
    // };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleEditClick = (row, index) => {
        setfulOrderId(row?.fulOrderId ?? '');
        setid(row?._id ?? '');
        handleClickOpen();
        setIndexValue(index);
        console.log(indexValue);
    };

    const handleDeleteClick = (row) => {
        console.log(row._id);
        swal({
            title: 'Are you sure?',
            text: `Once deleted, you will not be able to recover this Order`,
            icon: 'warning',
            buttons: true,
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                swal('Poof! The Order has been deleted!', {
                    icon: 'success'
                });

                const data = {
                    id: row._id
                };
                const userInfo = localStorage.getItem('userToken');

                fetch(ApiUrl.DeleteSalesOrderRequest, {
                    method: 'DELETE',
                    headers: {
                        'auth-token': userInfo,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                })
                    .then((res) => res.json())
                    .then((result) => {
                        if (result.status === true) {
                            if (scriptedRef.current) {
                                retrieveAllOrders();
                                // window.location.href = '/stage';
                                // history('/stage');
                            }
                        } else {
                            toast.error(result.data);
                        }
                    });
            } else {
                swal('The  Order is safe!');
            }
        });
    };

    const handledateChange = (event, picker) => {
        // console.log('startDate', picker.startDate._d);
        // console.log('endDate', picker.endDate._d);
        setstartDate(picker.startDate._d);
        setendDate(picker.endDate._d);
        // FilterOrdersByDateUrl
        const userInfo = localStorage.getItem('userToken');
        const from = picker.startDate._d;
        const to = picker.endDate._d;
        setLoading(true);
        const data = {
            from,
            to,
            orderStatusId
        };
        fetch(ApiUrl.FilterOrdersByDateUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);

                    setLoading(false);
                    setOrder(result.data);
                    setOrderData(result.data);
                    // if (scriptedRef) {
                    //     setTimeout(() => {
                    //         history('/order');
                    //     }, 3000);
                    // }
                } else {
                    toast.error(result.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleOrderStatus = (event) => {
        // console.log('order status', event.target.value);
        setorderStatusId(event.target.value);
        // FilterOrderByOrderStatusUrl
        const userInfo = localStorage.getItem('userToken');
        const orderStatusId = event.target.value;

        setLoading(true);
        const data = {
            startDate,
            endDate,
            orderStatusId
        };

        fetch(ApiUrl.FilterOrderByOrderStatusUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);

                    setLoading(false);
                    setOrder(result.data);
                    setOrderData(result.data);
                    // if (scriptedRef) {
                    //     setTimeout(() => {
                    //         history('/order');
                    //     }, 3000);
                    // }
                } else {
                    toast.error(result.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSearch = (event) => {
        const searchWord = event.target.value;

        const newFilter = orderInfo.filter((value) =>
            (value.channelPartnerId || '').toLowerCase().includes((searchWord || '').toLowerCase())
        );

        if (searchWord === '') {
            setOrder(orderData);
        } else {
            setOrder(newFilter);
        }
    };

    return (
        <>
            <br />
            <PageHeader title="Orders" subTitle="Home | Order " />
            <br />
            <br />
            <MainCard title="Order List">
                <Grid container spacing={2} justify="flex-start">
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography variant="h3" component="div" className={classes.colors}>
                            Total Orders {orderInfo.length}
                        </Typography>
                    </Grid>
                </Grid>
                <br />
                <br />
                <Box display="flex" justifyContent="flex-start">
                    <Controls.Input
                        label="Search Partner Name"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearch}
                    />
                    <Box width={20} />

                    <br />
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Box width={180}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Filter By Order Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="orderStatusId"
                                    value={orderStatusId}
                                    label="Order Status"
                                    onChange={handleOrderStatus}
                                >
                                    <MenuItem value="All">All</MenuItem>
                                    {orderStatus.map((order) => (
                                        <MenuItem key={order._id} value={order._id}>
                                            {order.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Box width={20} />
                    <DateRangePicker initialSettings={{ startDate, endDate }} onApply={handledateChange}>
                        <Button variant="contained" size="sm" className={classes.item}>
                            filter by Date
                        </Button>
                    </DateRangePicker>

                    {/* <Box width={20} />
                    <Button component={Link} to="/addOrder" variant="contained" size="sm" className={classes.item}>
                        New
                    </Button> */}

                    {/* 
                    <Box width={10} />
                    <Button onClick={handledataexport} variant="contained" size="sm" className={classes.item} color="info">
                        Download Excel
                    </Button> */}
                </Box>
                <br />

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>
                                        {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order ID
                                        </TableCell> */}
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order Date
                                        </TableCell>
                                        {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Channel
                                        </TableCell> */}

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Channel Partner
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Sales Person
                                        </TableCell>

                                        {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order Type
                                        </TableCell> */}

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order Value
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Exp.Cash
                                        </TableCell>

                                        {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Cash Collected
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Cash Balance
                                        </TableCell> */}

                                        {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            % sold
                                        </TableCell> */}

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order Status
                                        </TableCell>
                                        {/* 
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Pmt Status
                                        </TableCell> */}

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 ? orderInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : orderInfo).map(
                                (row, index) => (
                                    <TableRow key={row._id}>
                                        <TableCell component="th" scope="row">
                                            {row?.fulOrderId}
                                        </TableCell>
                                        {/* <TableCell component="th" scope="row">
                                            {row._id}
                                        </TableCell> */}
                                        <TableCell component="th" scope="row">
                                            {toTimestamp(row?.orderDate)}
                                        </TableCell>
                                        {/* <TableCell component="th" scope="row">
                                            {row?.newChannelId?.name ?? ''}
                                        </TableCell> */}

                                        <TableCell component="th" scope="row">
                                            {row?.customerName?.partnerName ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.salesTeamId ?? ''}
                                        </TableCell>

                                        {/* <TableCell component="th" scope="row">
                                            {row.orderTypId}
                                        </TableCell> */}

                                        <TableCell component="th" scope="row">
                                            {Number(row?.costValue).toFixed(2)}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {Number(row?.salesValue).toFixed(2)}
                                        </TableCell>
                                        {/* <TableCell component="th" scope="row">
                                            {Number.isFinite(row?.actCashCollected) ? Number(row?.actCashCollected).toFixed(2) : 0}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {Number.isFinite(row?.cashOutStanding)
                                                ? Number(row?.cashOutStanding).toFixed(2)
                                                : Number(row?.expCashCollected).toFixed(2)}
                                        </TableCell> */}
                                        {/* percent sold channel partner */}
                                        {/* <TableCell component="th" scope="row">
                                            {row?.newChannelPartnerId?.percentOrderSold ?? 'N/A'}
                                        </TableCell> */}

                                        <TableCell component="th" scope="row">
                                            {row.orderStatus}
                                        </TableCell>
                                        {/*
                                        <TableCell component="th" scope="row">
                                            {row?.newPaymentStatusId?.name ?? ''}
                                        </TableCell> */}

                                        <TableCell component="th" scope="row">
                                            {/* {row?.newPaymentStatusId?._id === '620d76a4ee512a471bf98c3e' ? (
                                                ''
                                            ) : (
                                                <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(row)}>
                                                    update
                                                </IconButton>
                                            )} */}
                                            <IconButton aria-label="delete" color="success" onClick={() => handlePdfClick(row)}>
                                                pdf
                                            </IconButton>

                                            {/* <IconButton aria-label="view" color="warning" onClick={() => handleShowClick(row)}>
                                                preview
                                            </IconButton> */}
                                            <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(row, index)}>
                                                update
                                            </IconButton>
                                            <>
                                                {userRole === 'Admin' ? (
                                                    <IconButton aria-label="view" color="warning" onClick={() => handleDeleteClick(row)}>
                                                        delete
                                                    </IconButton>
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 150, 250, { label: 'All', value: -1 }]}
                    component="div"
                    count={orderInfo.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">Update Order Number</DialogTitle>
                    <Form onSubmit={onUpdateOrderNumber}>
                        <DialogContent>
                            {/* <DialogContentText> */}

                            <Form.Group controlId="formFile" className="mb-3">
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        name="fulOrderId"
                                        variant="outlined"
                                        id="fulOrderId"
                                        label="Order Number"
                                        value={fulOrderId}
                                        onChange={(e) => setfulOrderId(e.target.value)}
                                    />
                                </Box>
                            </Form.Group>

                            {/* </DialogContentText> */}
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" size="sm" className={classes.item} autoFocus onClick={handleClose} color="error">
                                Cancel
                            </Button>
                            <Button autoFocus variant="contained" type="submit" size="sm" className={classes.item}>
                                Update
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </MainCard>
        </>
    );
};

export default CssOrders;
