import React, { useEffect, useRef, useState } from 'react';
import { Table, Divider, Paper, Space, Button, Select, Modal, Checkbox, Alert, Input, DatePicker, notification } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import ApiUrl from '../../network';
import { ToastContainer, toast } from 'react-toastify';
// import PaymentAlert from './PaymentAlert';
import { encrypt, decrypt } from '../../utils/encrypt';
import './rmaTable.css';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import RmaReceiptConfirmModal from './RmaReceiptConfirmModal';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Webcam from 'react-webcam';

const RmaReceiptUpdateModal = ({
    handleReceiptUpdateCancel,
    handleReceiptUpdateOk,
    isReceiptUpdateOpen,
    collections,
    confirmedList,
    getOrderId,
    getOrderNo,
    setUpdatReceiptSuccess,
    updateReceiptSuccess
}) => {
    const [amountDeposited, setAmountDeposited] = useState('');
    const [paymentType, setPaymentType] = useState('');
    const fileInputRef = useRef(null);
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [receiptUpdateConfirmOpen, setReceiptUpdateConfirmOpen] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [openCamera, setOpenCamera] = useState(false);
    const webcamRef = useRef(null);

    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');

    const [api, contextHolder] = notification.useNotification();

    const openNotification = (placement) => {
        api.open({
            description: 'Payment has been updated!',
            placement,
            style: {
                width: 267,
                height: 70,
                backgroundColor: '#0A2938',
                borderRadius: '12px',
                textAlign: 'center',
                color: '#fff',
                fontWeight: 500,
                fontSize: '18px'
            },
            closeIcon: false
        });
    };

    const collection = collections?.find((collection) => {
        return collection?.country === 'Ghana' ? collection?.orderIdGh?._id === getOrderId : collection?.orderIdNg?._id === getOrderId;
    });

    const confirmed = confirmedList?.find((collection) => {
        return collection?.country === 'Ghana' ? collection?.orderIdGh?._id === getOrderId : collection?.orderIdNg?._id === getOrderId;
    });

    // capture image
    const capture = () => {
        const imageSrc = webcamRef.current.getScreenshot();

        if (imageSrc) {
            setSelectedFiles((prevFiles) => [...prevFiles, imageSrc]);
        }
    };

    // open device camera
    const handleOpenCamera = () => {
        setOpenCamera((prevOpenCamera) => !prevOpenCamera);
    };

    // Helper function to read a file as Data URL using FileReader
    const readFileAsDataURL = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();

            reader.onload = (e) => {
                resolve(e.target.result);
            };

            // Read the current file as Data URL
            reader.readAsDataURL(file);
        });
    };

    const handleFileChange = async (event) => {
        const files = event.target.files;

        // Use Promise.all to asynchronously read each file as a Data URL
        const dataURLs = await Promise.all(Array.from(files).map((file) => readFileAsDataURL(file)));

        // Update the state to store an array of selected files as Data URLs
        setSelectedFiles((prevFiles) => [...prevFiles, ...dataURLs]);
    };

    const handleUploadButtonClick = () => {
        // Trigger the file input click when the button is clicked
        fileInputRef.current.click();
    };

    const handlePaymentType = (value) => {
        setPaymentType(value);
    };

    // Update Collection
    const updateCollection = async (event) => {
        const userInfo = localStorage.getItem('userToken');
        setPaymentLoading(true);

        const data = {
            id: collection?._id || confirmed?._id,
            paymentType: paymentType || collection?.paymentType || confirmed?.paymentType,
            amount: amountDeposited || collection?.amount || confirmed?.amount,
            image:
                selectedFiles.length > 0
                    ? selectedFiles?.map((url) => ({ image: url }))
                    : collection?.image?.map((url) => ({ image: url?.image })) || confirmed?.image?.map((url) => ({ image: url?.image })),
            orderId: getOrderId,
            orderNo: getOrderNo
        };

        if (data?.image?.length === 0) {
            toast.error(`Add a receipt image `);
            setPaymentLoading(false);
            return;
        }

        const obj = JSON.stringify(data);
        const result = encrypt(obj, keyResult, ivResult);

        await fetch(`${ApiUrl.BaseUrl}/collection/updatermacollection`, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ payload: result })
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    // const error = decrypt(result.data, keyResult, ivResult);
                    // const answer = JSON.parse(error);

                    openNotification('bottom');
                    handleReceiptUpdateOk();
                    setUpdatReceiptSuccess(!updateReceiptSuccess);
                } else if (result.status === false) {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                    handleReceiptUpdateCancel();
                } else {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                    handleReceiptUpdateCancel();
                }
            })
            .finally(() => {
                setPaymentLoading(false);
                setAmountDeposited('');
                setPaymentType('');
                setSelectedFiles([]);
            });
    };

    return (
        <div>
            {contextHolder}
            <Modal
                open={isReceiptUpdateOpen}
                onOk={() => {
                    handleReceiptUpdateOk();
                    setAmountDeposited('');
                    setPaymentType('');
                    setSelectedFiles([]);
                }}
                centered
                width={321}
                height={490}
                onCancel={() => {
                    handleReceiptUpdateCancel();
                    setAmountDeposited('');
                    setPaymentType('');
                    setSelectedFiles([]);
                }}
                // zIndex={4400}
                footer={
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                            style={{
                                backgroundColor: '#FFFFFF',
                                borderRadius: '8px',
                                border: '1px solid #FF740F',
                                height: '48px',
                                fontSize: '18px',
                                fontWeight: '700',
                                color: '#FF740F ',
                                width: '154px',
                                textAlign: 'center'
                            }}
                            onClick={() => {
                                handleReceiptUpdateCancel();
                                setAmountDeposited('');
                                setPaymentType('');
                                setSelectedFiles([]);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{
                                backgroundColor: '#FF740F',
                                borderRadius: '8px',
                                height: '48px',
                                fontSize: '18px',
                                fontWeight: '700',
                                color: '#FFFFFF',
                                width: '154px',
                                textAlign: 'center'
                            }}
                            onClick={() => {
                                updateCollection();
                                handleReceiptUpdateOk();
                                toast.success('Processing...');
                            }}
                        >
                            Update
                        </Button>
                    </Box>
                }
            >
                <div className="">
                    <Typography
                        sx={{
                            fontSize: '20px',
                            color: '#546974',
                            padding: '0',
                            fontWeight: '500',
                            // marginBottom: '40px',
                            textAlign: 'center'
                        }}
                        onClick={() => {
                            setReceiptUpdateConfirmOpen(true);
                        }}
                    >
                        Edit payment
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            // backgroundColor: '#f6f6f6',
                            // borderRadius: '12px',
                            // border: '1px solid #e7eaeb',
                            height: '400px',
                            overflowY: 'scroll',
                            overflowX: 'hidden'
                            // paddingBottom: '20px'
                        }}
                        className="collection-modals-y"
                    >
                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '80px 0 0 0px'
                            }}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '40px 0 0px 0px' }}>
                                <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974', margin: '0px 0 10px 0px' }}>
                                    Receipt
                                </Typography>
                                {!openCamera ? (
                                    <Typography
                                        onClick={handleOpenCamera}
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            color: '#4B6EFF',
                                            margin: '0px 0 10px 0px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <CameraAltIcon />
                                        capture
                                    </Typography>
                                ) : null}

                                {selectedFiles ? (
                                    <Typography
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            color: '#4B6EFF',
                                            margin: '0px 0 10px 0px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={handleUploadButtonClick}
                                    >
                                        Upload receipt
                                    </Typography>
                                ) : (
                                    ''
                                )}
                            </Box>
                            {selectedFiles.length > 0 ? (
                                <Box
                                    style={{
                                        display: 'flex',
                                        overflowX: 'scroll',
                                        scrollSnapType: 'x mandatory',
                                        whiteSpace: 'nowrap',
                                        width: '321px'
                                        // height: '100px'
                                    }}
                                    className="collection-modals-x"
                                >
                                    {selectedFiles.map((file, index) => (
                                        <Box
                                            sx={{
                                                width: ' 100%',
                                                height: '200px',
                                                backgroundColor: '#E7EAEB',
                                                borderRadius: '12px',
                                                cursor: 'pointer',
                                                overflow: 'hidden',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flex: '0 0 250px',
                                                scrollSnapAlign: 'center',
                                                marginRight: '5px'
                                            }}
                                            key={index}
                                            onClick={handleUploadButtonClick}
                                        >
                                            {/* <img src={URL.createObjectURL(file)} multiple className="receipt-image" alt="Selected" /> */}
                                            <img src={file} multiple className="receipt-image" alt="Selected" />
                                        </Box>
                                    ))}
                                    <input
                                        type="file"
                                        accept="image/*"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                </Box>
                            ) : (
                                <Box
                                    style={{
                                        display: 'flex',
                                        overflowX: 'scroll',
                                        scrollSnapType: 'x mandatory',
                                        whiteSpace: 'nowrap',
                                        width: '321px'
                                        // height: '100px'
                                    }}
                                    className="collection-modals-x"
                                >
                                    {/* {collection?.image?.map((file, index) => (
                                        <Box
                                            sx={{
                                                width: ' 100%',
                                                height: '200px',
                                                backgroundColor: '#E7EAEB',
                                                borderRadius: '12px',
                                                cursor: 'pointer',
                                                overflow: 'hidden',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flex: '0 0 250px',
                                                scrollSnapAlign: 'center',
                                                marginRight: '5px'
                                            }}
                                            key={index}
                                            onClick={handleUploadButtonClick}
                                        >
                                            <img src={file.image} multiple className="receipt-image" alt="Selected" />
                                        </Box>
                                    ))} */}
                                    {confirmed?.image?.map((file, index) => (
                                        <Box
                                            sx={{
                                                width: ' 100%',
                                                height: '200px',
                                                backgroundColor: '#E7EAEB',
                                                borderRadius: '12px',
                                                cursor: 'pointer',
                                                overflow: 'hidden',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flex: '0 0 270px',
                                                scrollSnapAlign: 'center',
                                                marginRight: '5px'
                                            }}
                                            key={index}
                                            onClick={handleUploadButtonClick}
                                        >
                                            <img src={file.image} multiple className="receipt-image" alt="Selected" />
                                        </Box>
                                    ))}
                                    <input
                                        type="file"
                                        accept="image/*"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                </Box>
                            )}
                            {openCamera ? (
                                <>
                                    <Button
                                        size="medium"
                                        type="primary"
                                        onClick={() => {
                                            setOpenCamera(!openCamera);
                                        }}
                                        style={{ backgroundColor: '#fff', color: '#FF740F', border: '1px solid #FF740F', margin: '20px 0' }}
                                    >
                                        Close Camera
                                    </Button>
                                </>
                            ) : null}

                            {openCamera ? (
                                <Box
                                    style={{
                                        display: 'flex',
                                        overflowX: 'scroll',
                                        scrollSnapType: 'x mandatory',
                                        whiteSpace: 'nowrap',
                                        width: '321px'
                                        // height: '100px'
                                    }}
                                    className="collection-modals-x"
                                >
                                    <Box
                                        sx={{
                                            width: ' 100%',
                                            height: '200px',
                                            backgroundColor: '#E7EAEB',
                                            borderRadius: '12px',
                                            cursor: 'pointer',
                                            overflow: 'hidden',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flex: '0 0 270px',
                                            scrollSnapAlign: 'center',
                                            marginRight: '5px'
                                        }}
                                        onClick={handleUploadButtonClick}
                                    >
                                        {openCamera ? (
                                            <>
                                                <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
                                            </>
                                        ) : (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <UploadFileIcon
                                                    sx={{
                                                        color: '#9747FF'
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        color: '#4B6EFF',
                                                        margin: '10px 0 0 0'
                                                    }}
                                                >
                                                    Click here to upload receipt
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>

                                    <input
                                        type="file"
                                        accept="image/*"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                </Box>
                            ) : (
                                <Box
                                    style={{
                                        display: 'flex',
                                        overflowX: 'scroll',
                                        scrollSnapType: 'x mandatory',
                                        whiteSpace: 'nowrap',
                                        width: '321px'
                                        // height: '100px'
                                    }}
                                    className="collection-modals-x"
                                >
                                    <Box onClick={handleUploadButtonClick} />

                                    <input
                                        type="file"
                                        accept="image/*"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '24px 0 0 0px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            {openCamera ? (
                                <>
                                    <Button
                                        size="medium"
                                        type="primary"
                                        onClick={() => {
                                            capture();
                                            setOpenCamera((prevOpenCamera) => !prevOpenCamera);
                                        }}
                                        style={{ backgroundColor: '#FF740F', color: '#fff', border: '1px solid #FF740F' }}
                                    >
                                        Capture Photo
                                    </Button>
                                </>
                            ) : null}
                            {selectedFiles.length > 0 ? (
                                <>
                                    <Button
                                        size="medium"
                                        type="primary"
                                        onClick={() => {
                                            setSelectedFiles([]);
                                        }}
                                        style={{ backgroundColor: '#fff', color: '#FF740F', border: '1px solid #FF740F' }}
                                    >
                                        Clear Photo
                                    </Button>
                                </>
                            ) : null}
                        </Box>

                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '24px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974', margin: '0px 0 10px 0px' }}>
                                Amount Deposited
                            </Typography>
                            <Input
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                placeholder="Amount"
                                value={amountDeposited || collection?.amount || confirmed?.amount}
                                onChange={(e) => setAmountDeposited(e.target.value)}
                                type="number"
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 100%',
                                margin: '24px 0 30px 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#546974', margin: '0px 0 10px 0px' }}>
                                Payment Type
                            </Typography>
                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                allowClear
                                placeholder="Order Status"
                                optionFilterProp="children"
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                options={[
                                    {
                                        value: 'Cash',
                                        label: 'Cash'
                                    },
                                    {
                                        value: 'Cheque',
                                        label: 'Cheque'
                                    },
                                    {
                                        value: 'Bank account',
                                        label: 'Bank account'
                                    },
                                    {
                                        value: 'Momo',
                                        label: 'Momo'
                                    }
                                ]}
                                value={paymentType || collection?.paymentType || confirmed?.paymentType}
                                onChange={handlePaymentType}
                            />
                        </Box>
                    </Box>
                </div>
            </Modal>
        </div>
    );
};

export default RmaReceiptUpdateModal;
