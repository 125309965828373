import { RETAILER_BEGIN, RETAILER_SUCCESS, HANDLE_CHANGE, LOYALTY_BEGIN, LOYALTY_ERROR, LOYALTY_SUCCESS } from './loyalty-actions';

const loyaltyReducer = (state, action) => {
    switch (action.type) {
        case RETAILER_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case RETAILER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                retailers: action.payload.retailers
            };
        case HANDLE_CHANGE:
            return {
                ...state,
                currentRetailerId: action.payload.id,
                currentRetailerName: action.payload.value
            };
        case LOYALTY_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case LOYALTY_SUCCESS:
            return {
                ...state,
                customerName: action.payload.customerName,
                customerContact: action.payload.customerContact,
                customerLat: action.payload.customerLat,
                customerLng: action.payload.customerLng,
                date: action.payload.date,
                totalSales: action.payload.totalSales,
                currentLoyalty: action.payload.currentLoyalty,
                currentTier: action.payload.currentTier,
                currentReward: action.payload.currentReward,
                currentNoOfOrders: action.payload.currentNoOfOrders,
                amountToSpendMore: action.payload.amountToSpendMore,
                noOfMoreOrders: action.payload.noOfMoreOrders,
                percentage: action.payload.percentage,
                salesPerson: action.payload.salesPerson,
                nextTier: action.payload.nextTier,
                ordersLefts: action.payload.ordersLefts,
                loyalty: [],
                partsSold: action.payload.partsSold
            };
        case LOYALTY_ERROR:
            return {
                ...state,
                isLoading: false
            };
        default:
            throw new Error(`No such action ${action.type}`);
    }
};

export default loyaltyReducer;
