import React, { useRef } from 'react';
import dayjs from 'dayjs';
import MessageItem from './MessageItem';
import DateSeparator from './DateSeparator';

const loggedInFirstName = localStorage.getItem('userFirstName');
const loggedInLastName = localStorage.getItem('userLastName');

const ChatBox = ({ messages, phoneNumber, actionContent, chatBoxRef, handleScroll }) => {
    const messageRefs = useRef({});

    const loggedInUserFullName = `${loggedInFirstName} ${loggedInLastName}`;

    // Scroll to the replied message
    const scrollToMessage = (messageId) => {
        const targetMessage = messageRefs.current[messageId];
        if (targetMessage) {
            targetMessage.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div id="chat-box-message" className="chat-box-message" ref={chatBoxRef} onScroll={handleScroll}>
            {messages
                .filter((message) => message.phone === phoneNumber.phone)
                .reduce((acc, message, index, array) => {
                    const messageDate = dayjs(message.createdAt);
                    const prevMessage = array[index - 1];
                    const prevMessageDate = prevMessage ? dayjs(prevMessage.createdAt) : null;

                    // Add Date Separator if needed
                    if (!prevMessageDate || !messageDate.isSame(prevMessageDate, 'day')) {
                        acc.push(<DateSeparator key={`date-${message._id}`} date={messageDate} />);
                    }

                    // Store message ref
                    const messageRef = (el) => {
                        if (el) {
                            messageRefs.current[message._id] = el;
                        }
                    };

                    // Render Message Item
                    acc.push(
                        <MessageItem
                            key={message._id}
                            message={message}
                            ref={messageRef}
                            loggedInUserFullName={loggedInUserFullName}
                            allMessages={messages}
                            scrollToMessage={scrollToMessage}
                            actionContent={actionContent}
                        />
                    );

                    return acc;
                }, [])}
        </div>
    );
};

export default ChatBox;
