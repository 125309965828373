// import dashboard from './dashboard';

import partSourcingNigeria from './partsSourceNigeria';
import other from './other';

// ==============================|| MENU ITEMS dashboard ||============================== //

const partsnigeriaItems = {
    items: [partSourcingNigeria, other]
};

export default partsnigeriaItems;
