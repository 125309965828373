import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ProductImage from 'assets/images/shell_oil-removebg-preview1.png';
import { DatePicker, Select, Space, Checkbox, Table, Spin, Input, Button } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CartModal from './CartModal';
import ApiUrl from 'network';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { useNavigate } from 'react-router';
import axios from 'axios';

const OrderSummary = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [cartItems, setCartItems] = useState([]);
    const [submittng, setSubmitting] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [failedMsg, setFailedMsg] = useState('');
    const navigate = useNavigate();
    const phone = localStorage.getItem('retailerNo');

    const url = window.location.href;
    const currentDomain = new URL(url).origin;

    // modals
    const showModal = () => {
        setIsOpen(true);
    };

    const handleOk = () => {
        setIsOpen(false);
        setTimeout(() => {
            return currentDomain === 'https://web.garagemobility.com'
                ? window.location.replace(`https://wa.me/+233557100098`)
                : window.location.replace(`https://wa.me/+15550992795`);
        }, 1000);
    };

    const handleCancel = () => {
        setIsOpen(false);
        setTimeout(() => {
            return currentDomain === 'https://web.garagemobility.com'
                ? window.location.replace(`https://wa.me/+233557100098`)
                : window.location.replace(`https://wa.me/+15550992795`);
        }, 1000);
    };

    const storedCartItems = localStorage.getItem('cartItems');

    useEffect(() => {
        if (storedCartItems) {
            setCartItems(JSON.parse(storedCartItems));
        }
    }, [storedCartItems, phone]);

    const totalPrice = localStorage.getItem('totalPrice');

    const currency = localStorage.getItem('partCurrency');

    const submit = async () => {
        setSubmitting(true);

        try {
            const response = await axios.post(`${ApiUrl.BaseUrl}/whatsapp/submit-cart`, {
                phone,
                items: cartItems.map((item) => ({ _id: item?._id, quantity: item?.quantity, price: item.garagePrice }))
            });

            setSuccessMsg(response.data.message);
            showModal();
            localStorage.removeItem('totalPrice');
            localStorage.removeItem('cartItems');
            if (currentDomain === 'https://web.garagemobility.com') {
                window.location.replace(`https://wa.me/+233557100098`);
            } else {
                window.location.replace(`https://wa.me/+15550992795`);
            }
        } catch (error) {
            setFailedMsg('Error occured, please try again');
            showModal();
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div id="summarypage">
            <h1 className="cart-heading">
                <span> Selected products</span>
                <span>Cart</span>
            </h1>
            <div className="summary-page">
                <h1>Order Summary</h1>
                {cartItems.map((part) => (
                    <div className="summary-item" key={part?._id}>
                        <div className="summary-container-image">
                            {part?.partsImage ? (
                                <img className="summary-img" src={part?.partsImage} alt="product" />
                            ) : (
                                <BrokenImageIcon
                                    className="product-img"
                                    sx={{
                                        color: '#999'
                                    }}
                                />
                            )}
                        </div>
                        <div className="summary-img-details">
                            <p>{part.sku} </p>
                            <p>
                                {' '}
                                {JSON.parse(currency)} {part.garagePrice * part.quantity}
                            </p>
                            <p className="summary-quantity">Quantity: {part.quantity}</p>
                        </div>
                    </div>
                ))}

                <div className="total-order">
                    Total order value:{' '}
                    <span>
                        {' '}
                        {JSON.parse(currency)} {totalPrice}
                    </span>
                </div>
                <Button
                    type="primary"
                    size="large"
                    loading={submittng}
                    style={{
                        backgroundColor: '#FF740F',
                        borderRadius: '8px',
                        fontSize: '14px',
                        fontWeight: '500',
                        color: '#fff',
                        width: '90%',
                        height: '48px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'fixed',
                        bottom: '10px',
                        zIndex: '2000'
                    }}
                    className=" hover-secondary-button-orange"
                    onClick={() => submit()}
                >
                    Confirm order ~ {JSON.parse(currency)} {totalPrice}
                </Button>
            </div>
            <CartModal handleCancel={handleCancel} handleOk={handleOk} isOpen={isOpen} successMsg={successMsg} failedMsg={failedMsg} />
        </div>
    );
};

export default OrderSummary;
