/* eslint-disable import/no-extraneous-dependencies */
import { Tabs as AntTab, Button, DatePicker, Form, Image, Input, Progress, Select, Space, Table, Tag } from 'antd';
import { Col, Row } from 'react-grid-system';
import {
    FcApproval,
    FcAssistant,
    FcAutomotive,
    FcBullish,
    FcBusinessman,
    FcClearFilters,
    FcCloseUpMode,
    FcComboChart,
    FcConferenceCall,
    FcCurrencyExchange,
    FcCustomerSupport,
    FcDebt,
    FcDonate,
    FcHighPriority,
    FcInTransit,
    FcLink,
    FcManager,
    FcMoneyTransfer,
    FcNightPortrait,
    FcNumericalSorting12,
    FcOvertime,
    FcPaid,
    FcPositiveDynamic,
    FcProcess,
    FcRightDown,
    FcRightDown2,
    FcShipped,
    FcSportsMode,
    FcSupport
} from 'react-icons/fc';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import Collapse from '@mui/material/Collapse';

import { useEffect, useRef, useState } from 'react';

import DataContainer from 'components/DataExtPortal/DataContainer';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ExportTableButton } from 'ant-table-extensions';
import GarageLogo from '../assets/images/garage_logo.png';
// eslint-disable-next-line import/no-extraneous-dependencies
import Highlighter from 'react-highlight-words';
// import GenericPageHeader from 'components/GenericPageHeader'; 'react-highlight-words'; 'ant-table-extensions';
import { Box, Paper, Typography, Divider, List, ListItem } from '@mui/material';
import { SearchOutlined } from '@ant-design/icons';
import { SiMicrosoftexcel } from 'react-icons/si';
import dayjs from 'dayjs';
import { detectCurrencyType } from 'utils/functions';
// import moment from 'moment/moment';
import styled from 'styled-components';
import { useAppContext } from 'context/appContext';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { height } from '@mui/system';
import { Opacity } from '@mui/icons-material';
// import CheckIn from 'components/CheckIn/CheckIn';

const userFirstName = localStorage.getItem('userFirstName');
const userRole = localStorage.getItem('userRole');

const { RangePicker } = DatePicker;

const Wrapper = styled.div`
    font-family: 'Roboto';
    margin: 2rem 1rem;

    .ant-table {
        width: 100%;
        font-family: 'Roboto';
    }

    .download {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1rem;
    }
`;

export default function DataPortal() {
    const {
        /** CC Values */
        getCustomerCareMetrics,
        ccOrderNumber,
        ccOrderData,
        prospectNumber,
        prospectData,
        onboardscompleteNumber,
        onboardscompleteData,
        onboardsbegunNumber,
        onboardsbegunData,
        partlistcollectedNumber,
        partlistcollectedData,
        partlistdeliveredNumber,
        partlistdeliveredData,
        notreadytopartnerNumber,
        notreadytopartnerData,
        retailerGender,
        totalProspectMonthly,
        totalOnboardBegunonthly,
        totalonboardscompleteMonthly,

        /** Default Query Values */
        getDataQueryParamsDefaults,
        orderStatuses,
        paymentStatuses,
        countries,
        cities,
        customers,
        relationshipManagers,

        /** GMV Data */
        getGMVDataMetrics,
        getFinancialDataMetrics,
        regularGMV,
        reqularOrdersNumber,
        reguarlarordersData,
        darkstoreGMV,
        darkstoreOrdersNumber,
        darkstoreordersData,
        salesDriveGMV,
        salesDriveOrdersNumber,
        salesDriveordersData,
        proformaGMV,
        proformaOrdersNumber,
        proformaordersData,
        noRetailers,
        noRetailersData,
        issuesNumber,
        issuesData,
        noNewRetailers,
        noNewRetailersData,
        leadsNumber,
        leadsData,
        partsoldNumber,
        partsSoldData,
        totalOrdersNumber,
        totalordersData,
        totalGMV,
        retentionGMV,
        retentionOrdersNumber,
        retentionordersData,
        webSrcNumber,
        mobileSrcNumber,
        totalGMVMonth,
        darkstoreGMVMonth,
        proformaGMVMonth,
        regularGMVMonth,
        retentionGMVMonth,
        salesDriveGMVMonth,
        totalMobileMonth,
        totalWebMonth,
        totalFirstTimePurchaseMonthly,
        totalLeadsMonthly,
        preSoldGMV,
        preSoldOrdersNumber,
        preSoldordersData,
        preSoldordersDataGMVMonth,
        conversionGMV,
        conversionGMVMonth,
        conversionOrdersNumber,
        conversionordersData,

        /** Fulfilment Metrics */
        getFulfillmentMetrics,
        top20skus,
        loyaltyRetailers,
        loyaltyRetailersData,

        /** Finance Data */
        totalRetailCost,
        totalRetailSales,
        totalRetailSalesNumber,
        totalRetailSalesData,
        totalRetailDiscount,
        totalRetailDiscountNumber,
        totalRetailDiscountData,
        totalpayments,
        totalpaymentsData,
        totaloutstanding,
        totaloutstandingData,
        totalProfit,
        totalProfitPercentage,
        totalAverageRetailCost,
        postVatTakeRate,
        postVatTakeRatePercentage,
        postVatTakeRateData,
        dispatch,
        isLoading,
        salesperson,
        customer,
        orderstatus,
        paymentstatus,
        countryId,
        city,
        startdate,
        enddate,
        clearDataMtricsFilters,
        totalFinanceMonthly
    } = useAppContext();

    useEffect(() => {
        getGMVDataMetrics();
        getFinancialDataMetrics();
        getCustomerCareMetrics();
        getFulfillmentMetrics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startdate, enddate, salesperson, customer, orderstatus, paymentstatus, countryId, city]);

    useEffect(() => {
        getDataQueryParamsDefaults(countryId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryId]);

    const gmVStats = [
        {
            title: 'Total Orders No.',
            count: totalOrdersNumber,
            icon: <FcProcess />,
            color: '#FFBE37',
            data: totalordersData,
            month: totalGMVMonth,
            trailColor: 'rgba(255, 249, 235, 1)'
        },
        {
            title: 'Total GMV',
            count: detectCurrencyType(totalGMV),
            icon: <FcComboChart />,
            color: '#647acb',
            data: reguarlarordersData,
            month: totalGMVMonth,
            trailColor: 'rgba(219, 226, 255, 1)'
        },
        {
            title: 'Total Issues',
            count: issuesNumber,
            icon: <FcCustomerSupport />,
            color: '#CD5888',
            data: issuesData,
            month: [],
            trailColor: 'rgba(219, 226, 255, 1)'
        },
        {
            title: 'Regular GMV',
            count: detectCurrencyType(regularGMV),
            icon: <FcComboChart />,
            color: '#00AA55',
            data: reguarlarordersData,
            month: regularGMVMonth,
            trailColor: 'rgba(230, 247, 238, 1)'
        },
        {
            title: 'Total Regular Orders',
            count: reqularOrdersNumber,
            icon: <FcDebt />,
            color: '#647acb',
            data: reguarlarordersData,
            month: regularGMVMonth,
            trailColor: 'rgba(219, 226, 255, 1)'
        },
        {
            title: 'Total Orders from Mobile.',
            count: mobileSrcNumber,
            icon: <FcInTransit />,
            color: '#497321',
            data: [],
            month: totalMobileMonth,
            trailColor: 'rgba(219, 226, 255, 1)'
        },
        {
            title: 'Dark Store GMV',
            count: detectCurrencyType(darkstoreGMV),
            icon: <FcPaid />,
            color: '#d66a6a',
            data: darkstoreordersData,
            month: darkstoreGMVMonth,
            trailColor: 'rgba(219, 226, 255, 1)'
        },
        {
            title: 'Total Dark Store Orders',
            count: darkstoreOrdersNumber,
            icon: <FcNumericalSorting12 />,
            color: '#123b56',
            data: darkstoreordersData,
            month: darkstoreGMVMonth,
            trailColor: 'rgba(219, 226, 255, 1)'
        },
        {
            title: 'Total Orders from Web.',
            count: webSrcNumber,
            icon: <FcInTransit />,
            color: '#497321',
            data: [],
            month: totalWebMonth,
            trailColor: 'rgba(219, 226, 255, 1)'
        },
        {
            title: 'Sales Drive GMV',
            count: detectCurrencyType(salesDriveGMV),
            icon: <FcAutomotive />,
            color: '#f7788b',
            data: salesDriveordersData,
            month: salesDriveGMVMonth,
            trailColor: 'rgba(219, 226, 255, 1)'
        },
        {
            title: 'Total No. of Sales Drive',
            count: salesDriveOrdersNumber,
            icon: <FcInTransit />,
            color: '#ff740f',
            data: salesDriveordersData,
            month: salesDriveGMVMonth,
            trailColor: 'rgba(219, 226, 255, 1)'
        },
        {
            title: 'Unique Retailer Purchases',
            count: noRetailers,
            icon: <FcBusinessman />,
            color: '#5D3891',
            data: noRetailersData,
            month: [],
            trailColor: 'rgba(219, 226, 255, 1)'
        },
        {
            title: 'Retention Drive GMV',
            count: detectCurrencyType(retentionGMV),
            icon: <FcAutomotive />,
            color: '#FFBE37',
            data: retentionordersData,
            month: retentionGMVMonth,
            trailColor: 'rgba(255, 249, 235, 1)'
        },

        {
            title: 'Total Retention Drive Orders No.',
            count: retentionOrdersNumber,
            icon: <FcInTransit />,
            color: '#4B6EFF',
            data: retentionordersData,
            month: retentionGMVMonth,
            trailColor: 'rgba(237, 241, 255, 1)'
        },
        {
            title: 'Total Parts Sold',
            count: partsoldNumber,
            icon: <FcShipped />,
            color: '#F2921D',
            data: partsSoldData,
            month: [],
            trailColor: 'rgba(219, 226, 255, 1)'
        },
        {
            title: 'Proforma GMV',
            count: detectCurrencyType(proformaGMV),
            icon: <FcCurrencyExchange />,
            color: '#fe6ebb',
            data: proformaordersData,
            month: proformaGMVMonth,
            trailColor: 'rgba(219, 226, 255, 1)'
        },
        {
            title: 'Proforma Orders No.',
            count: proformaOrdersNumber,
            icon: <FcCloseUpMode />,
            color: '#497321',
            data: proformaordersData,
            month: proformaGMVMonth,
            trailColor: 'rgba(219, 226, 255, 1)'
        },

        {
            title: '1st Time Retailer Purchases',
            count: noNewRetailers,
            icon: <FcNightPortrait />,
            color: '#20262E',
            data: noNewRetailersData,
            month: totalFirstTimePurchaseMonthly,
            trailColor: 'rgba(219, 226, 255, 1)'
        },
        {
            title: 'Total Leads',
            count: leadsNumber,
            icon: <FcManager />,
            color: '#D61355',
            data: leadsData,
            month: totalLeadsMonthly,
            trailColor: 'rgba(219, 226, 255, 1)'
        },
        {
            title: 'Pre Sold',
            count: preSoldOrdersNumber,
            icon: <FcPaid />,
            color: '#d66a6a',
            data: preSoldordersData,
            month: preSoldordersDataGMVMonth,
            trailColor: 'rgba(219, 226, 255, 1)'
        },
        {
            title: 'Conversion Drive',
            count: detectCurrencyType(conversionGMV),
            icon: <FcPaid />,
            color: '#c6d673',
            data: conversionordersData,
            month: conversionGMVMonth,
            trailColor: 'rgba(198, 214, 115, 1)'
        },
        {
            title: 'Conversion Orders',
            count: conversionOrdersNumber,
            icon: <FcPaid />,
            color: '#F2921D',
            data: conversionordersData,
            month: conversionGMVMonth,
            trailColor: 'rgba(219, 226, 255, 1)'
        }
    ];

    const financeStats = [
        {
            title: 'Seller Payouts',
            count: detectCurrencyType(+totalRetailCost),
            icon: <FcMoneyTransfer />,
            color: '#FFBE37',
            data: totalRetailSalesData,
            month: totalFinanceMonthly,
            trailColor: 'rgba(255, 249, 235, 1)'
        },
        {
            title: 'Total Retail GMV',
            count: detectCurrencyType(+totalRetailSales),
            icon: <FcMoneyTransfer />,
            color: '#4B6EFF',
            data: totalRetailSalesData,
            month: totalFinanceMonthly,
            trailColor: 'rgba(237, 241, 255, 1)'
        },

        {
            title: 'Total Retail Discount No.',
            count: totalRetailDiscountNumber,
            icon: <FcRightDown />,
            color: '#00AA55',
            data: totalRetailDiscountData,
            month: totalFinanceMonthly,
            trailColor: 'rgba(230, 247, 238, 1)'
        },
        {
            title: 'Total Retail Discount',
            count: detectCurrencyType(+totalRetailDiscount),
            icon: <FcRightDown2 />,
            color: '#1AA3FE',
            data: totalRetailDiscountData,
            month: totalFinanceMonthly,
            trailColor: 'rgba(232, 246, 255, 1)'
        },

        {
            title: 'Total Retail Sales (No.)',
            count: +totalRetailSalesNumber,
            icon: <FcNumericalSorting12 />,
            color: '#FF7B54',
            data: totalRetailSalesData,
            month: totalFinanceMonthly,
            trailColor: 'rgba(219, 226, 255, 1)'
        },

        {
            title: 'Marketplace Take Rate',
            count: `${totalProfitPercentage}%`,
            icon: <FcLink />,
            color: '#658864',
            month: totalFinanceMonthly,
            trailColor: 'rgba(219, 226, 255, 1)'
        },
        {
            title: 'Total Payments',
            count: detectCurrencyType(+totalpayments),
            icon: <FcApproval />,
            color: '#FFB26B',
            data: totalpaymentsData,
            month: totalFinanceMonthly,
            trailColor: 'rgba(219, 226, 255, 1)'
        },
        {
            title: 'Total Outstanding',
            count: detectCurrencyType(+totaloutstanding),
            icon: <FcSportsMode />,
            color: '#FF0032',
            data: totaloutstandingData,
            month: totalFinanceMonthly,
            trailColor: 'rgba(219, 226, 255, 1)'
        },
        {
            title: 'Total Net Revenues',
            count: detectCurrencyType(totalProfit),
            icon: <FcBullish />,
            color: '#ABC270',
            month: totalFinanceMonthly,
            trailColor: 'rgba(219, 226, 255, 1)'
        },

        {
            title: 'Total Average Retail Cost',
            count: detectCurrencyType(totalAverageRetailCost),
            icon: <FcPositiveDynamic />,
            color: '#FEC868',
            month: totalFinanceMonthly,
            trailColor: 'rgba(219, 226, 255, 1)'
        }
        // {
        //     title: 'Post VAT Take Rate',
        //     count: postVatTakeRate,
        //     icon: <FcDonate />,
        //     color: '#FEC868',
        //     data: postVatTakeRateData
        // },
        // {
        //     title: 'Post VAT Take Rate %',
        //     count: `${postVatTakeRatePercentage}%`,
        //     icon: <FcDonate />,
        //     color: '#FEC868',
        //     data: postVatTakeRateData
        // }
    ];

    const ccStats = [
        {
            title: '1st Time Retailer Purchases.',
            count: noNewRetailers,
            icon: <FcConferenceCall />,
            color: '#4B6EFF',
            data: noNewRetailersData,
            month: totalFirstTimePurchaseMonthly,
            trailColor: 'rgba(237, 241, 255, 1)'
        },
        {
            title: 'Total Leads',
            count: leadsNumber,
            icon: <FcManager />,
            color: '#1AA3FE',
            data: leadsData,
            month: totalLeadsMonthly,
            trailColor: 'rgba(232, 246, 255, 1)'
        },
        {
            title: 'Customer Care Order No.',
            count: ccOrderNumber,
            icon: <FcAssistant />,
            color: '#FFF9EB',
            data: ccOrderData,
            month: [],
            trailColor: 'rgba(255, 249, 235, 1)'
        },
        {
            title: 'Total Parts Collected',
            count: partlistcollectedNumber,
            icon: <FcSupport />,
            color: '#00AA55',
            data: partlistcollectedData,
            month: [],
            trailColor: 'rgba(230, 247, 238, 1)'
        },

        {
            title: 'Total Prospects',
            count: prospectNumber,
            icon: <FcNightPortrait />,
            color: '#86bc8c',
            data: prospectData,
            month: totalProspectMonthly,
            trailColor: 'rgba(219, 226, 255, 1)'
        },
        {
            title: 'Total Parts Delivered',
            count: partlistdeliveredNumber,
            icon: <FcShipped />,
            color: '#44C662',
            data: partlistdeliveredData,
            month: [],
            trailColor: 'rgba(219, 226, 255, 1)'
        },
        {
            title: 'Total Onboarding In Progress',
            count: onboardsbegunNumber,
            icon: <FcProcess />,
            color: '#5F547F',
            data: onboardsbegunData,
            month: totalOnboardBegunonthly,
            trailColor: 'rgba(219, 226, 255, 1)'
        },
        {
            title: 'Total Onboards Completed',
            count: onboardscompleteNumber,
            icon: <FcOvertime />,
            color: '#36c0a0',
            data: onboardscompleteData,
            month: totalonboardscompleteMonthly,
            trailColor: 'rgba(219, 226, 255, 1)'
        },

        {
            title: 'Partners not Ready to partner',
            count: notreadytopartnerNumber,
            icon: <FcHighPriority />,
            color: '#DA1212',
            data: notreadytopartnerData,
            month: [],
            trailColor: 'rgba(219, 226, 255, 1)'
        }
    ];

    const loyaltyStats = [
        {
            title: 'Qualified Loyalty Retailers',
            count: loyaltyRetailers,
            icon: <FcHighPriority />,
            color: '#DA1212',
            data: loyaltyRetailersData
        }
    ];

    const [date, setDate] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState([]);
    const [selectCountry, setSelectCountry] = useState(countryId);

    const handleRelalationShipManager = (value) => {
        dispatch({ type: 'SET_SALES_PERSON', payload: { salesperson: value } });
    };

    const handleCustomer = (value) => {
        dispatch({ type: 'SET_CUSTOMER', payload: { customer: value } });
    };

    const handleCountry = (value) => {
        setSelectCountry((prev) => (prev = value));
        // dispatch({ type: 'SET_COUNTRY', payload: { country: value } });
    };

    const handleCountryClick = () => {
        dispatch({ type: 'SET_COUNTRY', payload: { country: selectCountry } });
    };

    const handleCity = (value) => {
        dispatch({ type: 'SET_CITY', payload: { city: value } });
    };

    // payment status
    const paymentOptions = paymentStatuses.map((result) => ({
        value: result._id,
        label: result.name
    }));

    const paymentOption = paymentOptions.map((item, index) => {
        return { label: item.label, value: item.value };
    });

    const handlePaymentStatus = (value) => {
        setPaymentStatus(value);
        // dispatch({ type: 'SET_PAYMENT_STATUS', payload: { paymentstatus: value } });
    };

    const handlePaymentClick = () => {
        dispatch({ type: 'SET_PAYMENT_STATUS', payload: { paymentstatus: paymentStatus } });
    };

    const handleOrderStatus = (value) => {
        dispatch({ type: 'SET_ORDER_STATUS', payload: { orderstatus: value } });
    };

    const handleDateRange = (dates) => {
        setDate(dates);
        // dispatch({
        //     type: 'SET_DATE_RANGE',
        //     payload: { startdate: dayjs(dates?.[0]).format('YYYY-MM-DD'), enddate: dayjs(dates?.[1]).format('YYYY-MM-DD') }
        // });
    };

    const handleDateClick = () => {
        dispatch({
            type: 'SET_DATE_RANGE',
            payload: { startdate: dayjs(date?.[0]).format('YYYY-MM-DD'), enddate: dayjs(date?.[1]).format('YYYY-MM-DD') }
        });
    };

    if (countryId) {
        localStorage.setItem('countryFilter', countryId);
    }

    // function getWindowSize() {
    //     const { innerWidth, innerHeight } = window;
    //     return { innerWidth, innerHeight };
    // }

    // const [windowSize, setWindowSize] = React.useState(getWindowSize());

    // useEffect(() => {
    //     function handleWindowResize() {
    //         setWindowSize(getWindowSize());
    //     }

    //     window.addEventListener('resize', handleWindowResize);

    //     return () => {
    //         window.removeEventListener('resize', handleWindowResize);
    //     };
    // }, []);

    /** Handle Column Search */
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
                style={{
                    padding: 8
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block'
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    });

    /** */

    const columns = [
        {
            title: 'Product',
            dataIndex: 'image',
            key: 'image',
            render: (url) => <Image width={80} height={60} src={url} fallback={GarageLogo} key="image" alt="Product Identity" />
        },
        {
            title: 'Sku',
            dataIndex: 'sku',
            key: 'sku',
            ...getColumnSearchProps('sku')
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty',
            sorter: (a, b) => a.qty - b.qty
        },
        {
            title: 'Cost Price',
            dataIndex: 'costPrice',
            key: 'costPrice',
            render: (text) => detectCurrencyType(text),
            sorter: (a, b) => a.costPrice - b.costPrice
        },
        {
            title: 'Sale Price',
            dataIndex: 'salesPrice',
            key: 'salesPrice',
            render: (text) => detectCurrencyType(text),
            sorter: (a, b) => a.salesPrice - b.salesPrice
        },
        {
            title: 'Unit Take Rate',
            dataIndex: 'unitTakeRate',
            key: 'unitTakeRate',
            sorter: (a, b) => a.unitTakeRate - b.unitTakeRate
        },
        {
            title: 'Take Rate %',
            dataIndex: 'unitTakeRatePercentage',
            key: 'unitTakeRatePercentage',
            render: (text) => `${text}%`,
            sorter: (a, b) => a.unitTakeRatePercentage - b.unitTakeRatePercentage
        },
        {
            title: 'Cost Value',
            dataIndex: 'costValue',
            key: 'costValue',
            render: (amt) => detectCurrencyType(amt),
            sorter: (a, b) => a.costValue - b.costValue
        },
        {
            title: 'GMV Value',
            dataIndex: 'gmv',
            key: 'gmv',
            render: (amt) => detectCurrencyType(amt),
            sorter: (a, b) => a.gmv - b.gmv
        },
        {
            title: 'Agg. TakeRate',
            dataIndex: 'aggTakeRate',
            key: 'aggTakeRate',
            render: (amt) => detectCurrencyType(amt),
            sorter: (a, b) => a.aggTakeRate - b.aggTakeRate
        },
        {
            title: 'Agg. TakeRate %',
            dataIndex: 'aggTakeRatePercentage',
            key: 'aggTakeRatePercentage',
            render: (amt) => `${amt}%`,
            sorter: (a, b) => a.aggTakeRatePercentage - b.aggTakeRatePercentage
        }
    ];

    const [male, female, unknown, decline] = retailerGender;

    const genderChart = [
        {
            title: 'Total Male Retailers',
            dataIndex: 'males',
            key: 'males',
            gender: male?.value,
            percentage: male?.percentage?.toFixed(1),
            progressColor: '#4B6EFF',
            trailColor: 'rgba(237, 241, 255, 1)'
        },
        {
            title: 'Total Female Retailers',
            dataIndex: 'females',
            key: 'females',
            gender: female?.value,
            percentage: female?.percentage?.toFixed(1),
            progressColor: '#FFBE37',
            trailColor: 'rgba(255, 249, 235, 1)'
        },
        {
            title: 'Unkwown Retailers',
            dataIndex: 'unknown',
            key: 'unknown',
            gender: unknown?.value,
            percentage: unknown?.percentage?.toFixed(1),
            progressColor: '#FB5B6B',
            trailColor: 'rgba(255, 235, 237, 1)'
        },
        {
            title: '  Did Not Answer ',
            dataIndex: 'decline',
            key: 'decline',
            gender: decline?.value,
            percentage: decline?.percentage?.toFixed(1),
            progressColor: '#1AA3FE',
            trailColor: 'rgba(236, 237, 239, 1)'
        }
    ];

    const loyaltyCustomers = loyaltyRetailersData.map((el, index) => {
        return { ...el, id: index + 1 };
    });

    const loyaltyColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Date',
            dataIndex: 'loyaltydate',
            key: 'loyaltydate'
        },
        {
            title: 'Partner Name',
            dataIndex: 'partnerName',
            key: 'partnerName',
            ...getColumnSearchProps('partnerName')
        },
        {
            title: 'Relationship Manager',
            dataIndex: 'relationshipManager',
            key: 'relationshipManager'
        },
        {
            title: 'Current Loyalty ',
            dataIndex: 'loyaltytitle',
            key: 'loyaltytitle'
        },
        {
            title: 'Current Tier',
            dataIndex: 'loyaltytitle2',
            key: 'loyaltytitle2'
        },
        {
            title: 'Loyalty Reward',
            dataIndex: 'loyaltyreward',
            key: 'loyaltyreward',
            render: (amt) => detectCurrencyType(amt),
            sorter: (a, b) => a.loyaltyreward - b.loyaltyreward
        },
        {
            title: 'Loyalty Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => <Tag color={text === 'Redeemed' ? '#5D9C59' : '#FC2947'}>{text}</Tag>
        }
    ];

    const [selectTitle, setSelectedTitle] = useState([]);
    const [selectCity, setSelectedCity] = useState([]);
    const [selectStatus, setSelectStatus] = useState([]);
    const [selectRMA, setSelectRMA] = useState([]);
    const [selectPartnerName, setSelectPartnerName] = useState([]);
    const [showclear, setShowClear] = useState(false);

    const useDebounce = (value, delay = 5000) => {
        const [debouncedValue, setDebouncedValue] = useState(value);

        useEffect(() => {
            const timeout = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            return () => clearTimeout(timeout);
        }, [value, delay]);

        return debouncedValue;
    };

    // const [gmvCard, setGmvCard] = useState([]);
    // const [showCard, setShowCard] = useState(true);

    // // fields for card search
    // const card = gmVStats.map((result) => ({
    //     value: result.title,
    //     label: result.title
    // }));

    // // card Name
    // const gmvName = card.filter((card) => {
    //     return card.value === selectTitle;
    // });

    // // select card
    // const handleTitleChange = (value) => {
    //     setSelectedTitle((prev) => (prev = value));
    //     setShowCard(false);
    // };

    // // card from field selected value
    // const selectGmv = gmvName.map((item) => item.label);

    // //  card data
    // const gmvSelection = gmVStats.filter((item) => {
    //     return item.title === selectGmv.toString() ? item : null;
    // });

    // // re render component when selected card changes
    // useEffect(() => {
    //     setGmvCard(gmvSelection);
    // }, [selectTitle]);

    // hide or show search fields

    const [open, setOpen] = useState(true);

    const toggleOpenClose = () => {
        setOpen(!open);
    };

    // city
    const cityOptions = cities.map((result) => ({
        value: result._id,
        label: result.city
    }));

    const cityName = cityOptions.filter((city) => {
        return city.value === selectCity;
    });

    const selectACity = cityName.map((item) => item.label);

    const citySelection = cities.filter((item) => {
        return item.title === selectACity.toString() ? item : null;
    });

    const options = cityOptions.map((item, index) => {
        return { label: item.label, value: item.value };
    });

    const handleMultipleCityChange = (value, label) => {
        setSelectedCity([...value]);
        // dispatch({ type: 'SET_CITY', payload: { city: [...value] } });
    };

    const handleMultipleCityClick = () => {
        dispatch({ type: 'SET_CITY', payload: { city: [...selectCity] } });
    };

    // Order Status
    const statusOptions = orderStatuses.map((result) => ({
        value: result.name,
        label: result.name
    }));

    const optionStatus = statusOptions.map((item, index) => {
        return { label: item.label, value: item.value };
    });

    const handleMultipleOrderChange = (value, label) => {
        setSelectStatus([...value]);
        // dispatch({ type: 'SET_ORDER_STATUS', payload: { orderstatus: [...value] } });
    };

    const handleMultipleOrderClick = () => {
        dispatch({ type: 'SET_ORDER_STATUS', payload: { orderstatus: [...selectStatus] } });
    };

    // Relationship Managers
    const rmaOptions = relationshipManagers.map((result) => ({
        value: result._id,
        label: result.name
    }));

    const rmaOption = rmaOptions.map((item, index) => {
        return { label: item.label, value: item.value };
    });

    const handleMultipleRMAChange = (value, label) => {
        setSelectRMA([...value]);
        // dispatch({ type: 'SET_SALES_PERSON', payload: { salesperson: [...value] } });
    };

    const handleMultipleRMAClick = () => {
        dispatch({ type: 'SET_SALES_PERSON', payload: { salesperson: [...selectRMA] } });
    };

    // Customers
    const customerOptions = customers.map((result) => ({
        value: result._id,
        label: result.partnerName
    }));

    const customersOption = customerOptions.map((item, index) => {
        return { label: item.label, value: item.value };
    });

    const handleMultipleCustomerChange = (value, label) => {
        setSelectPartnerName([...value]);
        // dispatch({ type: 'SET_CUSTOMER', payload: { customer: [...value] } });
    };

    const handleMultipleCustomerClick = () => {
        dispatch({ type: 'SET_CUSTOMER', payload: { customer: [...selectPartnerName] } });
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleMultipleOrderClick();
            handleMultipleCityClick();
            handleMultipleCustomerClick();
            handleMultipleRMAClick();
            handleDateClick();
            handlePaymentClick();
            handleCountryClick();
            setShowClear(true);
        }
    };

    // useEffect(() => {
    //     document.addEventListener('keyup', handleKeyPress);

    //     // Cleanup the event listener on component unmount
    //     return () => {
    //         document.removeEventListener('keyup', handleKeyPress);
    //     };
    // }, [selectCountry, selectCity, selectPartnerName, paymentStatus, selectRMA, date, selectStatus]);

    return (
        <>
            <Wrapper style={{ margin: '0 5px' }}>
                <List
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '0 0 16px 0',
                        borderBottom: '1px dashed #E7EAEB',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        margin: '0px auto 15px'
                    }}
                >
                    {' '}
                    <ListItem
                        sx={{ fontWeight: 500, fontSize: '1.875rem', color: '#0A2938', padding: 0 }}
                    >{`Hello, ${userFirstName}  👋🏽`}</ListItem>
                    <ListItem sx={{ fontSize: '0.875rem', color: '#565C6B', padding: 0 }}>How are you doing today?</ListItem>
                </List>

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '32px 0 8px',
                        cursor: 'pointer'
                    }}
                    onClick={toggleOpenClose}
                >
                    {' '}
                    <Typography component="span" variant="h4" sx={{ fontWeight: 400, fontSize: '1.125rem', color: '#0A2938' }}>
                        {open ? 'Hide filters' : 'Show filters'}
                    </Typography>
                    {open ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                </Box>
                {/* <GenericPageHeader title="Data Extraction Portal" breadcrumbs="Home | Data Analytics" /> */}
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <div
                        className="page-filters"
                        style={{
                            padding: '0 0 16px 0',
                            borderBottom: '1px dashed #E7EAEB'
                        }}
                    >
                        <Row>
                            <Col lg={3} xl={3} sm={6} md={6} xs={12}>
                                <Select
                                    placeholder="Country"
                                    loading={isLoading}
                                    showSearch
                                    style={{
                                        width: '100%',
                                        margin: '0.625rem 0'
                                    }}
                                    size="large"
                                    fieldNames={{ label: 'name', value: 'name' }}
                                    allowClear
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.name.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                                    }
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={[...countries]}
                                    value={selectCountry}
                                    onChange={handleCountry}
                                    onKeyUp={handleKeyPress}
                                />
                            </Col>
                            {/* <Col lg={3} xl={3} sm={6} md={6} xs={12}>
                                <Select
                                    placeholder="City"
                                    loading={isLoading}
                                    showSearch
                                    style={{
                                        width: '100%',
                                        margin: '0.625rem 0'
                                    }}
                                    size="large"
                                    fieldNames={{ label: 'city', value: '_id' }}
                                    allowClear
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.city.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                                    }
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.city ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={[...cities]}
                                    value={city}
                                    onChange={handleCity}
                                    disabled={!countryId}
                                />
                            </Col> */}
                            <Col lg={3} xl={3} sm={6} md={6} xs={12}>
                                <Select
                                    size="large"
                                    mode="multiple"
                                    loading={isLoading}
                                    showSearch
                                    maxTagCount={2}
                                    allowClear
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    value={selectCity}
                                    style={{
                                        width: '100%',
                                        margin: '0.625rem 0'
                                    }}
                                    placeholder="City"
                                    onChange={handleMultipleCityChange}
                                    options={options}
                                    onKeyUp={handleKeyPress}
                                />
                            </Col>
                            {/* <Col lg={3} xl={3} sm={6} md={6} xs={12}>
                                <Select
                                    placeholder="Relationship Manager"
                                    loading={isLoading}
                                    showSearch
                                    style={{
                                        width: '100%',
                                        margin: '0.625rem 0'
                                    }}
                                    size="large"
                                    fieldNames={{ label: 'name', value: '_id' }}
                                    allowClear
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.name.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                                    }
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={[...relationshipManagers]}
                                    value={salesperson}
                                    onChange={handleRelalationShipManager}
                                />
                            </Col> */}
                            <Col lg={3} xl={3} sm={6} md={6} xs={12}>
                                <Select
                                    size="large"
                                    mode="multiple"
                                    loading={isLoading}
                                    showSearch
                                    maxTagCount={2}
                                    allowClear
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    style={{
                                        width: '100%',
                                        margin: '0.625rem 0'
                                    }}
                                    value={selectRMA}
                                    placeholder="Relationship Manager"
                                    onChange={handleMultipleRMAChange}
                                    options={rmaOption}
                                    onKeyUp={handleKeyPress}
                                />
                            </Col>
                            {/* <Col lg={3} xl={3} sm={6} md={6} xs={12}>
                                <Select
                                    placeholder="Partner Name"
                                    loading={isLoading}
                                    showSearch
                                    style={{
                                        width: '100%',
                                        margin: '0.625rem 0'
                                    }}
                                    size="large"
                                    fieldNames={{ label: 'partnerName', value: '_id' }}
                                    allowClear
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.partnerName.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                                    }
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.partnerName ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={[...customers]}
                                    value={customer}
                                    onChange={handleCustomer}
                                />
                            </Col> */}
                            <Col lg={3} xl={3} sm={6} md={6} xs={12}>
                                <Select
                                    size="large"
                                    mode="multiple"
                                    loading={isLoading}
                                    maxTagCount={2}
                                    showSearch
                                    allowClear
                                    style={{
                                        width: '100%',
                                        margin: '0.625rem 0'
                                    }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    value={selectPartnerName}
                                    placeholder="Partner Name"
                                    onChange={handleMultipleCustomerChange}
                                    options={[...customersOption]}
                                    onKeyUp={handleKeyPress}
                                />
                            </Col>
                            <Col lg={3} xl={3} sm={6} md={6} xs={12}>
                                <Select
                                    size="large"
                                    loading={isLoading}
                                    showSearch
                                    allowClear
                                    style={{
                                        width: '100%',
                                        margin: '0.625rem 0'
                                    }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    value={paymentStatus}
                                    placeholder="Payment Status"
                                    onChange={handlePaymentStatus}
                                    options={[...paymentOption]}
                                    onKeyUp={handleKeyPress}
                                />
                            </Col>
                            {/* <Col lg={3} xl={3} sm={6} md={6} xs={12}>
                                <Select
                                    placeholder="Payment Status"
                                    loading={isLoading}
                                    showSearch
                                    style={{
                                        width: '100%',
                                        margin: '0.625rem 0'
                                    }}
                                    size="large"
                                    fieldNames={{ label: 'name', value: '_id' }}
                                    allowClear
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.name.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                                    }
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={[...paymentStatuses]}
                                    value={paymentstatus}
                                    onChange={handlePaymentStatus}
                                />
                            </Col> */}
                            {/* <Col lg={3} xl={3} sm={6} md={6} xs={12}>
                                <Select
                                    placeholder="Order Status"
                                    loading={isLoading}
                                    showSearch
                                    style={{
                                        width: '100%',
                                        margin: '0.625rem 0'
                                    }}
                                    size="large"
                                    fieldNames={{ label: 'name', value: 'name' }}
                                    allowClear
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.name.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                                    }
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={[...orderStatuses]}
                                    value={orderstatus}
                                    onChange={handleOrderStatus}
                                />
                            </Col> */}
                            <Col lg={3} xl={3} sm={6} md={6} xs={12}>
                                <Select
                                    size="large"
                                    mode="multiple"
                                    showSearch
                                    loading={isLoading}
                                    maxTagCount={2}
                                    allowClear
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    style={{
                                        width: '100%',
                                        margin: '0.625rem 0'
                                    }}
                                    value={selectStatus}
                                    placeholder="Order Status"
                                    onChange={handleMultipleOrderChange}
                                    options={optionStatus}
                                    onKeyUp={handleKeyPress}
                                />
                            </Col>

                            <Col lg={3} xl={3} sm={6} md={6} xs={12}>
                                <div className="mt-2 mb-2">
                                    <Form
                                        initialValues={{
                                            range_picker: [dayjs('01/01/2022', 'YYYY-MM-DD'), dayjs('02/03/2022', 'YYYY-MM-DD')]
                                        }}
                                        size="large"
                                    >
                                        <RangePicker
                                            style={{ width: '100%' }}
                                            onChange={handleDateRange}
                                            allowClear
                                            // value={dates || value}
                                            value={date}
                                            onKeyUp={handleKeyPress}
                                        />
                                    </Form>
                                </div>
                            </Col>
                            {/* <Col lg={3} xl={3} sm={6} md={6} xs={12}>
                                <Select
                                    showSearch
                                    allowClear
                                    size="large"
                                    style={{
                                        width: '100%',
                                        margin: '0.625rem 0'
                                    }}
                                    placeholder="Search here"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={card}
                                    onChange={handleTitleChange}
                                />
                            </Col> */}
                            {/* <Col lg={3} xl={3} sm={6} md={6} xs={12}>
                                <Button
                                    type="dashed"
                                    icon={<FcClearFilters />}
                                    className="mt-2 mb-2"
                                    onClick={clearDataMtricsFilters}
                                    title="Clear all filters"
                                >
                                    &nbsp;Clear Filters
                                </Button>
                            </Col> */}

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    width: '300px',
                                    margin: '0 0.625rem '
                                }}
                            >
                                <Button
                                    className="mt-2 mb-2 d-flex align-items-center"
                                    onClick={() => {
                                        handleMultipleOrderClick();
                                        handleMultipleCityClick();
                                        handleMultipleCustomerClick();
                                        handleMultipleRMAClick();
                                        handleDateClick();
                                        handlePaymentClick();
                                        handleCountryClick();
                                        setShowClear(true);
                                    }}
                                    title="Apply filters"
                                    style={{
                                        backgroundColor: '#F5880E',
                                        borderRadius: '3.875rem',
                                        height: '40px',
                                        padding: '0',
                                        width: '9rem',
                                        margin: '0 5px 0 0 '
                                    }}
                                >
                                    <Typography sx={{ margin: '0 14px ', color: '#fff' }}>&nbsp;Apply filters</Typography>
                                    <QueryStatsIcon sx={{ fontSize: '1rem', color: '#ffff' }} />
                                </Button>

                                {showclear ? (
                                    <Button
                                        className="mt-2 mb-2 d-flex align-items-center"
                                        onClick={() => {
                                            clearDataMtricsFilters();
                                            setSelectedCity([]);
                                            setSelectStatus([]);
                                            setSelectRMA([]);
                                            setSelectPartnerName([]);
                                            setDate([]);
                                            setPaymentStatus([]);
                                            setSelectCountry(countryId);
                                            setShowClear(false);
                                        }}
                                        title="Clear all filters"
                                        style={{
                                            backgroundColor: '#0A2938',
                                            borderRadius: '3.875rem',
                                            height: '40px',
                                            padding: '0',
                                            width: '9rem'
                                        }}
                                    >
                                        <Typography sx={{ margin: '0 14px ', color: '#fff' }}>&nbsp;Clear Filters</Typography>
                                        <HighlightOffIcon sx={{ fontSize: '1rem', color: '#ffff' }} />
                                    </Button>
                                ) : (
                                    ''
                                )}
                            </Box>
                        </Row>
                    </div>
                </Collapse>
                <Paper elevation={1} style={{ borderRadius: 'none', padding: '0', background: 'transparent', boxShadow: 'none' }}>
                    {/* //TODO: Fix tab warning deprecation notice */}
                    <AntTab
                        defaultActiveKey="tab1"
                        tabPosition="top"
                        tabBarStyle={{
                            color: '#0A2938',
                            fontSize: '1rem',
                            background: 'transparent',
                            position: 'sticky',
                            top: '88px',
                            zIndex: '300',
                            backgroundColor: 'rgba(246, 246, 246, 1)'
                        }}
                        size="middle"
                        type="card"
                        tabBarGutter={15}
                    >
                        <AntTab.TabPane tab="Business Analysis" key="tab1">
                            <DataContainer
                                stats={gmVStats}
                                // gmvSelection={gmvSelection}
                                // showCard={showCard}
                            />
                        </AntTab.TabPane>
                        <AntTab.TabPane tab="Finance Metrics" key="tab2">
                            <DataContainer stats={financeStats} />
                        </AntTab.TabPane>
                        <AntTab.TabPane tab="Customer Care" key="tab3">
                            <DataContainer stats={ccStats} />
                        </AntTab.TabPane>
                        <AntTab.TabPane tab="Fulfilment" key="tab4">
                            <div className="py-2 download">
                                <ExportTableButton
                                    fileName="top_20_products_sold"
                                    dataSource={top20skus}
                                    columns={columns}
                                    showColumnPicker
                                    btnProps={{
                                        icon: <SiMicrosoftexcel />,
                                        children: <span className="p-1">&nbsp; Export to CSV &nbsp;</span>,
                                        type: 'primary'
                                    }}
                                />
                            </div>

                            <div className="table-responsive">
                                <Table
                                    columns={columns}
                                    dataSource={top20skus}
                                    loading={isLoading}
                                    title={() => <h4 className="text-muted">Top 20 Products (sku) Sold</h4>}
                                    scroll={{
                                        x: 700
                                    }}
                                    size="small"
                                    pagination={false}
                                />
                            </div>
                        </AntTab.TabPane>
                        {/* <AntTab.TabPane tab="Retailer Gender" key="tab5"> */}
                        {/* <DataContainer stats={genderChart} /> */}

                        {/* <Row>
                                <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                                    <div className="my-5 mx-5 p-3">
                                        <h6 className="py-3 text-muted">
                                            Total Males (<strong>{male?.value}</strong>)
                                        </h6>
                                        <Progress type="circle" percent={male?.percentage?.toFixed(1)} strokeColor="#21BF73" />
                                    </div>
                                </Col>
                                <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                                    <div className="my-5 mx-5 p-3 text-muted">
                                        <h6 className="py-3">
                                            Total Females (<strong>{female?.value}</strong>)
                                        </h6>
                                        <Progress type="circle" percent={female?.percentage?.toFixed(1)} strokeColor="#FF6600" />
                                    </div>
                                </Col>
                                <Col xl={3} lg={3} md={6} sm={6} xs={12}>
                                    <div className="my-5 mx-5 p-3 text-muted">
                                        <h6 className="py-3">
                                            Unkwown (<strong>{unknown?.value}</strong>)
                                        </h6>
                                        <Progress type="circle" percent={unknown?.percentage?.toFixed(1)} strokeColor="#540E33" />
                                    </div>
                                </Col>
                                <Col xl={3} lg={4} md={6} sm={6} xs={12}>
                                    <div className="my-5 mx-5 p-3 text-muted">
                                        <h6 className="py-3">
                                            Did Not Answer (<strong>{decline?.value}</strong>)
                                        </h6>
                                        <Progress type="circle" percent={decline?.percentage?.toFixed(1)} strokeColor="#2B3467" />
                                    </div>
                                </Col>
                            </Row> */}
                        {/* </AntTab.TabPane> */}
                        <AntTab.TabPane tab="Loyalty Customers" key="tab6">
                            {/* <div className="py-2 download">
                                <ExportTableButton
                                    fileName="loyalty-customers"
                                    dataSource={loyaltyCustomers}
                                    columns={loyaltyColumns}
                                    showColumnPicker
                                    btnProps={{
                                        icon: <SiMicrosoftexcel />,
                                        children: <span className="p-1">&nbsp; Export to CSV &nbsp;</span>,
                                        type: 'primary'
                                    }}
                                />
                            </div> */}
                            <Table
                                columns={loyaltyColumns}
                                dataSource={loyaltyCustomers}
                                loading={isLoading}
                                title={() => <h4 className="text-muted">Qualified Loyalty Customers ({loyaltyRetailers})</h4>}
                                scroll={{
                                    x: 700
                                }}
                                size="large"
                                pagination={false}
                            />
                        </AntTab.TabPane>
                    </AntTab>
                </Paper>
            </Wrapper>
        </>
    );
}
