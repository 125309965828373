// assets
import { IconKey, IconTruckDelivery } from '@tabler/icons';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Person from '@mui/icons-material/Person';
import CarCrashIcon from '@mui/icons-material/CarRental';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import Scale from '@mui/icons-material/Scale';
import Shop from '@mui/icons-material/Shop2';
import Trans from '@mui/icons-material/Money';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import BugReportIcon from '@mui/icons-material/BugReport';
import FactCheckIcon from '@mui/icons-material/FactCheck';

// constant
const icons = {
    IconKey,
    IconTruckDelivery,
    DashboardIcon,
    Scale,
    Shop,
    Person,
    DisplaySettingsIcon,
    Trans,
    CarCrashIcon,
    InsertChartIcon,
    BugReportIcon,
    FactCheckIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //
const userRole = localStorage.getItem('userRole');
const driver = {
    id: 'driverrider',
    title: 'Driver/Rider',
    type: userRole,
    children: [
        {
            id: 'home',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: icons.DashboardIcon,
            breadcrumbs: false
        },

        {
            id: 'attendance',
            title: 'Attendance',
            type: 'collapse',
            icon: icons.FactCheckIcon,
            children: [
                // {
                //     id: 'imageUpload',
                //     title: 'Image Upload',
                //     type: 'item',
                //     url: '/image-upload',
                //     breadcrumbs: false
                // },

                {
                    id: 'checkIn',
                    title: 'check In',
                    type: 'item',
                    url: '/check-in',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'driverorder',
            title: 'Orders',
            type: 'item',
            url: '/retrieveOrderdriver',
            icon: icons.InsertChartIcon,
            breadcrumbs: false
        }
    ]
};

export default driver;
