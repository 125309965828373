/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import useScriptRef from 'hooks/useScriptRef';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
// import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Form from 'react-bootstrap/Form';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 4
    }
}));

const ViewPartsGarage = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [cars, setCars] = useState([]);
    const [carmake, setcarmake] = useState('');
    const [model, setmodel] = useState('');
    const [year, setyear] = useState('');
    const [vin, setvin] = useState('');
    const [issues, setissues] = useState([]);
    const [action, setaction] = useState('');
    const [issue, setissue] = useState('');
    const [part, setpart] = useState('');
    const [price, setprice] = useState('');

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [opens, setOpens] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openServiceEdit, setOpenServiceEdit] = useState(false);
    const [indexValue, setIndexValue] = useState('');
    const [indexServiceValue, setIndexServiceValue] = useState('');
    const theme = useTheme();
    const [loading2, setLoading2] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    console.log(loading2);
    // const userCurrency = localStorage.getItem('userCurrency');
    const { id } = useParams();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenitems = () => {
        setOpens(true);
    };

    const handleCloseitems = () => {
        setOpens(false);
    };
    const handleClickOpenEdit = () => {
        setOpenEdit(true);
    };
    const handleCloseEdit = () => {
        setOpenEdit(false);
    };
    const handleClickServiceOpenEdit = () => {
        setOpenServiceEdit(true);
    };

    const handleCloseServiceEdit = () => {
        setOpenServiceEdit(false);
    };

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.ViewGarageModelUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setcarmake(result?.data?.carmake ?? '');
                    setCars(result?.data?.cars ?? '');
                    setissues(result?.data?.issues ?? '');
                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading2(false);
            });
    }, [id]);

    const onSubmitCarEdit = (event) => {
        event.preventDefault();
        const newList = Object.assign([], cars);
        const data = { ...newList[indexValue] };
        data.model = model;
        data.year = year;
        data.vin = vin;
        newList[indexValue] = data;
        setCars(newList);
        handleCloseEdit();
    };

    const onSubmitCar = (event) => {
        event.preventDefault();
        // cars, setCars
        setCars([
            ...cars,
            {
                model,
                year,
                vin
            }
        ]);

        handleClose();
    };

    const onSubmitItems = (event) => {
        event.preventDefault();
        // items, setItems

        setissues([
            ...issues,
            {
                issue,
                action,
                part,
                price
            }
        ]);

        handleCloseitems();
    };

    const onSubmitEditServices = (event) => {
        event.preventDefault();
        const newList = Object.assign([], issues);
        const data = { ...newList[indexServiceValue] };
        data.issue = issue;
        data.action = action;
        data.part = part;
        data.price = price;
        newList[indexServiceValue] = data;
        setissues(newList);
        handleCloseServiceEdit();
    };

    // Submit Order
    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        const data = {
            id,
            carmake,
            issues,
            cars
        };

        fetch(ApiUrl.UpdateGarageModelUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/partsgarage');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handleEditClick = (row, index) => {
        setmodel(row?.model ?? '');
        setvin(row?.vin ?? '');
        setyear(row?.year ?? '');
        handleClickOpenEdit();

        setIndexValue(index);
    };

    const handleDeleteClick = (row, index) => {
        const newList = Object.assign([], cars);
        newList.splice(index, 1);
        setCars(newList);
    };
    const handleDeleteClickitems = (row, index) => {
        const newList = Object.assign([], issues);
        newList.splice(index, 1);
        setissues(newList);
    };

    const handleEditServiceClick = (row, index) => {
        setissue(row?.issue ?? '');
        setaction(row?.action ?? '');
        setpart(row?.part ?? '');
        setprice(row?.price ?? '');
        handleClickServiceOpenEdit();
        setIndexServiceValue(index);
    };

    return (
        <>
            <PageHeader title="Vin Parts" subTitle="Home | Configuration | Parts | Vin Parts" />
            <MainCard title="">
                <Typography variant="subtitle2" component="div">
                    Make Information
                </Typography>
                <br />
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={200}>
                                <TextField
                                    autoComplete="off"
                                    name="carmake"
                                    variant="outlined"
                                    required
                                    id="carmake"
                                    label="Make"
                                    value={carmake}
                                    onChange={(e) => setcarmake(e.target.value)}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <br />
                    <Typography variant="subtitle2" component="div">
                        Model Information
                    </Typography>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={10}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                #
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Car Model
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Year
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                VIN
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Actions
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {cars.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.model}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.year}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    {row.vin}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <IconButton
                                                        aria-label="edit"
                                                        color="primary"
                                                        onClick={() => handleEditClick(row, index)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label="delete"
                                                        color="error"
                                                        onClick={() => handleDeleteClick(row, index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <Button variant="contained" onClick={handleClickOpen} size="sm" className={classes.item}>
                                Add model
                            </Button>
                        </Grid>
                    </Grid>

                    <br />
                    <Typography variant="subtitle2" component="div">
                        Service Information
                    </Typography>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={10}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                #
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Issue
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Service
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Part
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Part Cost
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Actions
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {issues.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.issue}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.action}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.part}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.price}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    <IconButton
                                                        aria-label="edit"
                                                        color="primary"
                                                        onClick={() => handleEditServiceClick(row, index)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label="delete"
                                                        color="error"
                                                        onClick={() => handleDeleteClickitems(row, index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <Button variant="contained" onClick={handleClickOpenitems} size="sm" className={classes.item}>
                                Add Issue
                            </Button>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <br />

                    <br />
                    <Box display="flex" justifyContent="space-between">
                        <Button component={Link} to="/partsgarage" type="submit" variant="contained" color="error">
                            Reset
                        </Button>
                        <Button type="submit" variant="contained" color="secondary" disabled={!cars || !issues || !carmake}>
                            {loading ? 'Updating ..' : 'Update'}
                        </Button>
                    </Box>
                </form>

                <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">Add Model</DialogTitle>
                    <Form onSubmit={onSubmitCar}>
                        <DialogContent>
                            <Form.Group controlId="formFile" className="mb-3">
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        name="model"
                                        variant="outlined"
                                        required
                                        id="model"
                                        label="Model"
                                        value={model}
                                        onChange={(e) => setmodel(e.target.value)}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        name="year"
                                        variant="outlined"
                                        required
                                        id="year"
                                        label="Year"
                                        value={year}
                                        onChange={(e) => setyear(e.target.value)}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        name="vin"
                                        variant="outlined"
                                        required
                                        id="vin"
                                        label="VIN"
                                        value={vin}
                                        onChange={(e) => setvin(e.target.value)}
                                    />
                                </Box>
                            </Form.Group>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" size="sm" className={classes.item} autoFocus onClick={handleClose} color="error">
                                Cancel
                            </Button>
                            <Button
                                autoFocus
                                variant="contained"
                                type="submit"
                                size="sm"
                                className={classes.item}
                                disabled={!vin || !model || !year}
                            >
                                Add
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>

                {/* Edit cars */}

                <Dialog fullScreen={fullScreen} open={openEdit} onClose={handleCloseEdit} aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">Edit Model</DialogTitle>
                    <Form onSubmit={onSubmitCarEdit}>
                        <DialogContent>
                            <Form.Group controlId="formFile" className="mb-3">
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        name="model"
                                        variant="outlined"
                                        required
                                        id="model"
                                        label="Model"
                                        value={model}
                                        onChange={(e) => setmodel(e.target.value)}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        name="year"
                                        variant="outlined"
                                        required
                                        id="year"
                                        label="Year"
                                        value={year}
                                        onChange={(e) => setyear(e.target.value)}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        name="vin"
                                        variant="outlined"
                                        required
                                        id="vin"
                                        label="VIN"
                                        value={vin}
                                        onChange={(e) => setvin(e.target.value)}
                                    />
                                </Box>
                            </Form.Group>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                size="sm"
                                className={classes.item}
                                autoFocus
                                onClick={handleCloseEdit}
                                color="error"
                            >
                                Cancel
                            </Button>
                            <Button autoFocus variant="contained" type="submit" size="sm" className={classes.item}>
                                Update
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>

                <Dialog fullScreen={fullScreen} open={opens} onClose={handleCloseitems} aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">Add Service</DialogTitle>
                    <Form onSubmit={onSubmitItems}>
                        <DialogContent>
                            <Form.Group controlId="formFile" className="mb-3">
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        name="issue"
                                        variant="outlined"
                                        required
                                        id="issue"
                                        label="Issue"
                                        value={issue}
                                        onChange={(e) => {
                                            setissue(e.target.value);
                                        }}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        name="action"
                                        variant="outlined"
                                        required
                                        id="action"
                                        label="Action"
                                        value={action}
                                        onChange={(e) => {
                                            setaction(e.target.value);
                                        }}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        name="part"
                                        variant="outlined"
                                        required
                                        id="part"
                                        label="Part"
                                        value={part}
                                        onChange={(e) => {
                                            setpart(e.target.value);
                                        }}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        name="price"
                                        variant="outlined"
                                        required
                                        id="price"
                                        label="Price"
                                        value={price}
                                        onChange={(e) => {
                                            setprice(e.target.value);
                                        }}
                                    />
                                </Box>
                            </Form.Group>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                size="sm"
                                className={classes.item}
                                autoFocus
                                onClick={handleCloseitems}
                                color="error"
                            >
                                Cancel
                            </Button>
                            <Button
                                autoFocus
                                variant="contained"
                                type="submit"
                                size="sm"
                                className={classes.item}
                                disabled={!issue || !action || !part || !price}
                            >
                                Add
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>

                {/* Edit Service */}
                <Dialog
                    fullScreen={fullScreen}
                    open={openServiceEdit}
                    onClose={handleClickServiceOpenEdit}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">Edit Service</DialogTitle>
                    <Form onSubmit={onSubmitEditServices}>
                        <DialogContent>
                            <Form.Group controlId="formFile" className="mb-3">
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        name="issue"
                                        variant="outlined"
                                        required
                                        id="issue"
                                        label="Issue"
                                        value={issue}
                                        onChange={(e) => {
                                            setissue(e.target.value);
                                        }}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        name="action"
                                        variant="outlined"
                                        required
                                        id="action"
                                        label="Action"
                                        value={action}
                                        onChange={(e) => {
                                            setaction(e.target.value);
                                        }}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        name="part"
                                        variant="outlined"
                                        required
                                        id="part"
                                        label="Part"
                                        value={part}
                                        onChange={(e) => {
                                            setpart(e.target.value);
                                        }}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        name="price"
                                        variant="outlined"
                                        required
                                        id="price"
                                        label="Price"
                                        value={price}
                                        onChange={(e) => {
                                            setprice(e.target.value);
                                        }}
                                    />
                                </Box>
                            </Form.Group>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                size="sm"
                                className={classes.item}
                                autoFocus
                                onClick={handleCloseitems}
                                color="error"
                            >
                                Cancel
                            </Button>
                            <Button autoFocus variant="contained" type="submit" size="sm" className={classes.item}>
                                Update
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </MainCard>
        </>
    );
};
export default ViewPartsGarage;
