import { Button } from 'antd';
import { AudioOutlined, FileImageOutlined, FilePdfOutlined, VideoCameraAddOutlined } from '@ant-design/icons';
import React from 'react';

const mediaOptions = [
    { label: 'Document', icon: <FilePdfOutlined />, accept: 'application/pdf', type: 'document' },
    { label: 'Image', icon: <FileImageOutlined />, accept: 'image/*', type: 'image' },
    { label: 'Audio', icon: <AudioOutlined />, action: 'audio' },
    { label: 'Video', icon: <VideoCameraAddOutlined />, accept: 'application/video', type: 'video' }
];

const MediaOptions = ({ setAccept, setMessageType, setImageModal, closePopover, startRecording }) => {
    const handleOptionClick = (option) => {
        if (option.action === 'audio') {
            startRecording(); // Starts audio recording
        } else {
            setAccept(option.accept);
            setMessageType(option.type);
            setImageModal(true);
            closePopover();
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'left',
                alignItems: 'left',
                textAlign: 'left',
                backgroundColor: '#f9f9f9',
                padding: '10px',
                borderRadius: '10px',
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                width: '150px'
            }}
        >
            {mediaOptions.map((option) => (
                <Button
                    key={option.label}
                    style={{
                        marginBottom: '5px',
                        backgroundColor: 'transparent',
                        border: 'none',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                    type="button"
                    onClick={() => handleOptionClick(option)}
                    aria-label={option.label}
                >
                    {option.label} {option.icon}
                </Button>
            ))}
        </div>
    );
};

export default MediaOptions;
