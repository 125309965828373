/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '..../../components/PageHeader';
import Box from '@mui/material/Box';
import Controls from '..../../components/controls/Controls';
import { Search } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import ViewIcon from '@mui/icons-material/ViewAgenda';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
// import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
// import { saveAs } from 'file-saver';
// import axios from 'axios';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const FleetRequestService = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [requestInfo, setRequestService] = useState([]);
    const [requestserviceData, setRequestServiceData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const userCurrency = localStorage.getItem('userCurrency');

    const handleEditClick = (row) => {
        if (scriptedRef.current) {
            history(`/viewfleetrequestservice/${row._id}`);
        }
    };

    const handleShowClick = (row) => {
        if (scriptedRef.current) {
            // console.log(row);
            history(`/showfleetrequestservice/${row._id}`);
        }
    };

    // const handlePdfClick = (row) => {
    //     const userInfo = localStorage.getItem('userToken');
    //     const config = {
    //         headers: {
    //             'auth-token': userInfo,
    //             'Content-Type': 'application/json'
    //         }
    //     };

    //     axios.post(ApiUrl.DownloadInsurerOrderPart, row, config).then((res) => {
    //         window.open(res.data.data, '_blank');
    //     });
    // };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requestInfo.length) : 0;

    const retrieveAllRequestService = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.FleetRequestServiceUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        // console.log(data.data);
                        setRequestService(data.data);
                        setRequestServiceData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        retrieveAllRequestService();
    }, []);

    const handleSearch = (event) => {
        const searchWord = event.target.value;

        const newFilter = requestInfo.filter((value) => value._id.toLowerCase().includes(searchWord.toLowerCase()));

        if (searchWord === '') {
            setRequestService(requestserviceData);
        } else {
            setRequestService(newFilter);
        }
    };

    return (
        <>
            <PageHeader title="Request Servicing" subTitle="Home |Fleet |Request Servicing" />

            <MainCard title="Request Servicing List">
                <Box display="flex" justifyContent="space-between">
                    <Controls.Input
                        label="Search Request Service Id"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearch}
                    />
                    <Box width={10} />
                    <Button component={Link} to="/addfleetrequestservice" variant="contained" size="sm" className={classes.item}>
                        New
                    </Button>
                </Box>
                <br />

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Request Service ID
                                        </TableCell>
                                        {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Fleet Service
                                        </TableCell> */}

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Customer
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Estimate Value {userCurrency}
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Date
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 ? requestInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : requestInfo).map(
                                (row, index) => (
                                    <TableRow key={row._id}>
                                        <TableCell component="th" scope="row">
                                            {requestInfo.length - index}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row._id}
                                        </TableCell>
                                        {/* <TableCell component="th" scope="row">
                                            {row.fleetserviceId}
                                        </TableCell> */}

                                        <TableCell component="th" scope="row">
                                            {row.customer}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row.totalCostEstimate}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {toTimestamp(row?.createdAt)}
                                        </TableCell>

                                        {/* percent sold channel partner */}

                                        <TableCell component="th" scope="row">
                                            <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(row)}>
                                                Update
                                            </IconButton>
                                            {/* <IconButton aria-label="delete" color="success" onClick={() => handlePdfClick(row)}>
                                            pdf
                                        </IconButton> */}

                                            <IconButton aria-label="view" color="warning" onClick={() => handleShowClick(row)}>
                                                Show
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    component="div"
                    count={requestInfo.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default FleetRequestService;
