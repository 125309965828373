import React, { useEffect, useState } from 'react';
import { decrypt, encrypt } from '../../../utils/encrypt';
import { toast } from 'react-toastify';
import { Button, Input, Modal, Skeleton, Table, Upload, message } from 'antd';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import SaveIcon from '@mui/icons-material/Save';
import { Typography } from '@mui/material';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import ApiUrl from '../../../network';

export const Index = () => {
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [driveLocation, setDriveLocation] = useState('');
    const [id, setId] = useState('');
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [driveLocationList, setDriveLocationList] = useState([]);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [actionType, setActionType] = useState('Add Drive Location');
    const [isExcelModalVisible, setIsExcelModalVisible] = useState(false);
    const [excelData, setExcelData] = useState(null);
    const [rowsData, setRowsData] = useState([]);

    const getDriveLocationHandler = async () => {
        setLoading(true);
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/salesplanning/drivelocationslist`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setLoading(false);
                    const driveLocationList = decrypt(data.data, keyResult, ivResult);
                    setDriveLocationList(JSON.parse(driveLocationList));
                } else {
                    setLoading(false);
                    toast.error(data.message);
                }
            });
    };
    const addDriveLocationHandler = async () => {
        setLoading(true);
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        const datas = {
            location: driveLocation,
            latitude,
            longitude
        };
        const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);

        await fetch(`${ApiUrl.BaseUrl}/salesplanning/adddrivelocations`, {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify({ payload })
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setLoading(false);
                    toast.success(data.message);
                    setIsModalVisible(false);
                    getDriveLocationHandler();
                } else {
                    setLoading(false);
                    toast.error(data.message);
                }
            });
    };
    const editDriveLocationHandler = async () => {
        setLoading(true);
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        const datas = {
            location: driveLocation,
            id,
            latitude,
            longitude
        };
        const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);

        await fetch(`${ApiUrl.BaseUrl}/salesplanning/updatedrivelocations`, {
            method: 'PUT',
            headers: config.headers,
            body: JSON.stringify({ payload })
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setLoading(false);
                    toast.success(data.message);
                    setIsModalVisible(false);
                    getDriveLocationHandler();
                } else {
                    setLoading(false);
                    toast.error(data.message);
                }
            });
    };

    useEffect(() => {
        getDriveLocationHandler();
    }, []);

    const driveTypeTableData = driveLocationList.map((item) => {
        return {
            key: item._id,
            country: item.country,
            driveLocation: item.location,
            latitude: item.latitude,
            longitude: item.longitude,
            createdBy: `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}`
        };
    });
    const columns = [
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country'
        },
        {
            title: 'Drive Location',
            dataIndex: 'driveLocation',
            key: 'driveLocation'
        },
        {
            title: 'Latitude',
            dataIndex: 'latitude',
            key: 'latitude'
        },
        {
            title: 'Longitude',
            dataIndex: 'longitude',
            key: 'longitude'
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            key: 'createdBy'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Button
                        onClick={() => {
                            setDriveLocation(record.driveLocation);
                            setLatitude(record.latitude);
                            setLongitude(record.longitude);
                            setActionType('Edit Drive Location');
                            setId(record.key);
                            setIsModalVisible(true);
                        }}
                        style={{ backgroundColor: '#FFF', color: '#FF740F', border: '1px solid #FF740F', width: '40px', height: '40px' }}
                        className="mt-2 mb-2 d-flex align-items-center justify-content-center"
                    >
                        <EditLocationAltIcon sx={{ fontSize: '16px', color: '#FF740F' }} />
                    </Button>
                </div>
            )
        }
    ];
    const handleExcelUpload = (info) => {
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0]; // Assuming only one sheet
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                setExcelData(jsonData);
                setIsExcelModalVisible(true);
            };
            reader.readAsBinaryString(info.file.originFileObj);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };
    useEffect(() => {
        if (excelData && excelData.length > 1) {
            // Skip the header row and map the rest of the rows to objects
            const dataObjects = excelData.slice(1).map((row, rowIndex) => ({
                key: rowIndex,
                location: row[0] || '',
                latitude: row[1] || null,
                longitude: row[2] || null
            }));
            setRowsData(dataObjects);
        }
    }, [excelData]);

    const handleInputChange = (key, rowIndex, value) => {
        // Update the corresponding field in the state
        const updatedRows = rowsData.map((row) => (row.key === rowIndex ? { ...row, [key]: value } : row));
        setRowsData(updatedRows);
    };
    const handleDelete = async (rowIndex) => {
        const updatedRows = rowsData.filter((row) => row.key !== rowIndex);
        setRowsData(updatedRows);
    };
    const addExcelDataHandler = async () => {
        setLoading(true);
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        const locations = rowsData.map((item) => item.location);
        const latitudes = rowsData.map((item) => item.latitude);
        const longitudes = rowsData.map((item) => item.longitude);

        // Create data object for backend submission
        const data = {
            uploadData: locations.map((location, index) => ({
                location,
                latitude: latitudes[index],
                longitude: longitudes[index]
            }))
        };
        const payload = encrypt(JSON.stringify(data), keyResult, ivResult);
        await fetch(
            `${ApiUrl.BaseUrl}/salesplanning/uploadlocations`,
            {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify({ payload })
            },
            config
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.status === true) {
                    setLoading(false);
                    toast.success(data.message);
                    setIsExcelModalVisible(false);
                    getDriveLocationHandler();
                } else {
                    setLoading(false);
                    toast.error(data.message);
                }
            });
    };
    const downloadExcelSampleHandler = () => {
        // Actual location data for Ghana
        const data = [
            ['Location', 'Latitude', 'Longitude'],
            ['Accra', '5.5600', '-0.2050'],
            ['Kumasi', '6.6885', '-1.6244'],
            ['Takoradi', '4.8981', '-1.7606'],
            ['Tamale', '9.4008', '-0.8393']
        ];

        // Convert the data to a worksheet
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
        XLSX.writeFile(wb, 'drive location sample.xlsx');
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Link to="/salesplanning" className="createScheduleLink">
                    <p style={{ fontSize: '25px', marginLeft: '10px' }}>
                        <ArrowBackIcon sx={{ fontSize: '25px', color: '#000' }} /> Drive Location
                    </p>
                </Link>
                <div className="d-flex align-items-center ">
                    <Upload
                        name="file"
                        action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                        headers={{ authorization: 'authorization-text' }}
                        onChange={handleExcelUpload}
                    >
                        <Button className="btnCreateSchedule" icon={<UploadOutlined />}>
                            Click to Upload
                        </Button>
                    </Upload>
                    <Button
                        className="ml-2 mr-2 btnCreateSchedule"
                        style={{ backgroundColor: '#969696', color: '#fff' }}
                        onClick={downloadExcelSampleHandler}
                    >
                        Download Sample
                    </Button>
                    <Button
                        onClick={() => setIsModalVisible(true)}
                        style={{ backgroundColor: '#FF740F', color: '#fff' }}
                        className="btnCreateSchedule ml-2"
                        title="Apply Filters"
                    >
                        <SaveIcon sx={{ fontSize: '16px', color: '#fff' }} />
                        <Typography sx={{ margin: '0 14px ', color: '#fff' }}>Add Drive Location</Typography>
                    </Button>
                </div>
            </div>
            <div>
                {loading ? (
                    <div>
                        <Skeleton style={{ padding: '10px 0' }} active />
                    </div>
                ) : (
                    <Table columns={columns} scroll={{ x: 'calc(700px + 50%)', y: 480 }} dataSource={driveTypeTableData} />
                )}
            </div>
            <Modal width={400} title={actionType} open={isModalVisible} onCancel={() => setIsModalVisible(false)} footer={null}>
                <div className="mb-2">
                    <Input placeholder="Drive Location" value={driveLocation} onChange={(e) => setDriveLocation(e.target.value)} />
                </div>
                <div className="mb-2">
                    <Input placeholder="Drive Location Latitude" value={latitude} onChange={(e) => setLatitude(e.target.value)} />
                </div>
                <div className="mb-2">
                    <Input placeholder="Drive Location Longitude" value={longitude} onChange={(e) => setLongitude(e.target.value)} />
                </div>
                <div className="mt-2">
                    <Button
                        style={{ backgroundColor: '#FF740F', color: '#fff', width: '100%', marginTop: '10px' }}
                        className="btnCreateSchedule"
                        title="Apply Filters"
                        loading={loading}
                        onClick={actionType === 'Add Drive Location' ? addDriveLocationHandler : editDriveLocationHandler}
                    >
                        {actionType}
                    </Button>
                </div>
            </Modal>
            <Modal width={900} title="Excel Upload" open={isExcelModalVisible} onCancel={() => setIsExcelModalVisible(false)} footer={null}>
                <div>
                    <div className="excelContainer">
                        {rowsData.map((row) => (
                            <div key={row.key} className="mb-2">
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>
                                        <small className="mb-2">Location</small>
                                        <Input
                                            placeholder="Drive Location"
                                            value={row.location}
                                            onChange={(e) => handleInputChange('location', row.key, e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <small className="mb-2">Latitude</small>
                                        <Input
                                            placeholder="Drive Location Latitude"
                                            value={row.latitude !== null ? row.latitude : ''}
                                            onChange={(e) => handleInputChange('latitude', row.key, e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <small className="mb-2">Longitude</small>
                                        <Input
                                            placeholder="Drive Location Longitude"
                                            value={row.longitude !== null ? row.longitude : ''}
                                            onChange={(e) => handleInputChange('longitude', row.key, e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <Button danger onClick={() => handleDelete(row.key)}>
                                            <DeleteOutlined />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="mt-2">
                        <Button
                            style={{ backgroundColor: '#FF740F', color: '#fff', width: '100%', marginTop: '10px' }}
                            className="btnCreateSchedule"
                            title="Apply Filters"
                            onClick={addExcelDataHandler}
                            loading={loading}
                        >
                            Add Drive Location
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default Index;
