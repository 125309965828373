import { useAppContext } from 'context/appContext';
import { IconDownload } from '@tabler/icons';
import AreaChart from 'components/charts/AreaChart';
import 'react-circular-progressbar/dist/styles.css';
import './cards.css';

function SubMaxCard({ count, title, icon, color, bcg, data, gender, percentage, progressColor, trailColor, month }) {
    const { handleExcelDataExport } = useAppContext();
    const downloadExcel = () => {
        if (data?.length === 0) return;
        handleExcelDataExport(data, title);
    };

    return (
        <div className="medium-cards">
            <div className="count-heading">
                <span className="total">{title}</span>
                {data?.length > 0 ? (
                    <a
                        role="button"
                        tabIndex={-1}
                        onClick={downloadExcel}
                        onKeyDown={downloadExcel}
                        title={data?.length > 0 ? 'Click to download excel' : ''}
                    >
                        <span>Download</span>
                        <IconDownload size="1rem" />
                    </a>
                ) : (
                    ''
                )}
            </div>
            <div className="sub-card">
                <div className="sub-container">
                    <span className="title">Today</span>
                    <span className="total">{count}</span>
                </div>
                <AreaChart month={month} title={title} />
            </div>
        </div>
    );
}

export default SubMaxCard;
