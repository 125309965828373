import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import useScriptRef from 'hooks/useScriptRef';
import Autocomplete from '@mui/material/Autocomplete';
import { FormHelperText } from '@mui/material';

const AddContactOrder = () => {
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [email, setEmail] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState('');
    const [loading, setLoading] = useState(false);

    const retrieveAllActiveCountry = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            // setLoading(true);
            fetch(ApiUrl.ActiveCountryUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        retrieveAllActiveCountry();
        // console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && loading) {
            // console.log(email, password);
        }
    }, [formErrors, loading]);
    const validated = (email) => {
        const errors = {};

        if (!email) {
            errors.email = 'Email is required!';
        }

        return errors;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        // console.log(userInfo);
        setFormErrors(validated(email));
        setLoading(true);

        const data = {
            email,
            country
        };

        fetch(ApiUrl.AddContactOrder, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/contactorder');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <br />
                    <PageHeader title="Order Emails" subTitle="Home | Configuration | Setups |  Order Email" />
                    <br />
                    <MainCard title="Add  Order Email">
                        <form onSubmit={handleSubmit}>
                            <TextField
                                autoComplete="off"
                                type="email"
                                name="email"
                                variant="outlined"
                                fullWidth
                                required
                                id="email"
                                label="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoFocus
                            />
                            <FormHelperText error id="standard-weight-helper-text-email-login">
                                {formErrors.email}
                            </FormHelperText>
                            <br />

                            <Autocomplete
                                id="country-select-demo"
                                autoHighlight
                                value={country}
                                onChange={(event, newValue) => {
                                    setCountry(newValue);
                                }}
                                options={countries.map((option) => option.name)}
                                isOptionEqualToValue={(option, value) => option.code === value.code}
                                renderInput={(params) => <TextField {...params} label="Choose a country" />}
                            />
                            <br />
                            <br />
                            <Box display="flex" justifyContent="space-between">
                                <Button component={Link} to="/contactorder" type="submit" variant="contained" color="error">
                                    Reset
                                </Button>

                                <Button type="submit" variant="contained" color="secondary">
                                    {loading ? 'Submitting ..' : 'Submit'}
                                </Button>
                            </Box>
                        </form>
                    </MainCard>
                </Grid>
            </Grid>
        </>
    );
};

export default AddContactOrder;
