import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ProductImage from 'assets/images/shell_oil-removebg-preview1.png';
import { DatePicker, Select, Space, Checkbox, Table, Spin, Input, Button, notification } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate } from 'react-router';
import CartModal from './CartModal';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { EmptyState } from 'components/EmptyState/EmptyState';

const Cart = ({ isOpenDelete, setIsOpenDelete }) => {
    const [isDelete, setIsDelete] = useState(false);
    const [getId, setGetId] = useState('');
    const navigate = useNavigate();
    const [cartItems, setCartItems] = useState([]);
    const [api, contextHolder] = notification.useNotification();

    const openNotification = (placement) => {
        api.open({
            description: 'Item removed from cart.',
            placement,
            style: {
                width: 267,
                height: 70,
                backgroundColor: '#0A2938',
                borderRadius: '12px',
                textAlign: 'center',
                color: '#fff',
                fontWeight: 500,
                fontSize: '18px',
                zIndex: 3000
            },
            closeIcon: false
        });
    };

    useEffect(() => {
        const storedCartItems = localStorage.getItem('cartItems');
        if (storedCartItems) {
            setCartItems(JSON.parse(storedCartItems));
        }
    }, []);

    const removeFromCart = (id) => {
        // Find the index of the item with the matching id
        const indexToRemove = cartItems.findIndex((item) => item._id === id);

        if (indexToRemove !== -1) {
            // Create a copy of cartItems array without the item to remove
            const updatedCartItems = cartItems.filter((item, index) => index !== indexToRemove);

            // Update the cart items state
            setCartItems(updatedCartItems);

            // Update local storage with the updated cart items
            localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));

            openNotification('top');
        }
    };

    // modals
    const showDeleteModal = () => {
        setIsOpenDelete(true);
    };

    const handleDeleteOk = () => {
        setIsOpenDelete(false);
    };

    const handleDeleteCancel = () => {
        setIsOpenDelete(false);
    };

    const handleIncreaseQuantity = (index) => {
        const updatedCartItems = [...cartItems];
        updatedCartItems[index].quantity += 1;
        setCartItems(updatedCartItems);

        localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    };

    const handleDecreaseQuantity = (index) => {
        const updatedCartItems = [...cartItems];
        if (updatedCartItems[index].quantity > 1) {
            updatedCartItems[index].quantity -= 1;
            setCartItems(updatedCartItems);

            localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
        }
    };

    const totalPrice = cartItems.reduce((total, item) => {
        return total + item.garagePrice * item.quantity;
    }, 0);

    localStorage.setItem('totalPrice', JSON.stringify(totalPrice));

    const currency = localStorage.getItem('partCurrency');

    return (
        <>
            {contextHolder}
            {cartItems.length > 0 ? (
                <div className="cart-page">
                    <h1>Today</h1>
                    {cartItems.map((part, index) => (
                        <div className="cart-item-wrapper" key={part?._id}>
                            <div
                                className="delete-item"
                                onClick={() => {
                                    showDeleteModal();
                                    setIsDelete(true);
                                    setGetId(part?._id);
                                }}
                                onKeyDown={() => {}}
                                role="button"
                                tabIndex={-1}
                            >
                                <DeleteIcon
                                    sx={{
                                        color: '#FA3246',
                                        fontSize: '16px'
                                    }}
                                />
                                <span>Delete item</span>
                            </div>
                            <div className="cart-item">
                                <div className="cart-container-image">
                                    {part?.partsImage ? (
                                        <img className="cart-img" src={part?.partsImage} alt="product" />
                                    ) : (
                                        <BrokenImageIcon
                                            className="product-img"
                                            sx={{
                                                color: '#999'
                                            }}
                                        />
                                    )}
                                </div>
                                <div className="cart-img-details">
                                    <p>{part?.sku}</p>
                                    <p>
                                        {JSON.parse(currency)} {part?.garagePrice * part.quantity}
                                    </p>
                                    <div className="cart-quantity">
                                        <p>Quantity</p>
                                        <div className="cart-quantity-control">
                                            <Box
                                                sx={{
                                                    width: '48px',
                                                    height: '48px',
                                                    borderRadius: '8px',
                                                    fontSize: '14px',
                                                    backgroundColor: '#A1A5AD',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                                onClick={() => handleDecreaseQuantity(index)}
                                            >
                                                <RemoveIcon
                                                    sx={{
                                                        fontSize: '24px',
                                                        color: '#fff'
                                                    }}
                                                />
                                            </Box>

                                            <span className="cart-quantity-number"> {part?.quantity}</span>
                                            <Box
                                                sx={{
                                                    width: '48px',
                                                    height: '48px',
                                                    borderRadius: '8px',
                                                    fontSize: '14px',
                                                    backgroundColor: '#193B56',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                                onClick={() => handleIncreaseQuantity(index)}
                                            >
                                                <AddIcon
                                                    sx={{
                                                        fontSize: '24px',
                                                        color: '#fff'
                                                    }}
                                                />
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <Button
                        type="primary"
                        size="large"
                        style={{
                            backgroundColor: '#FF740F',
                            borderRadius: '8px',
                            fontSize: '14px',
                            fontWeight: '500',
                            color: '#fff',
                            width: '90%',
                            height: '48px',
                            margin: '90px 16px 16px 16px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        className=" hover-secondary-button-orange"
                        onClick={() => navigate('/whatsapporder/product/summary')}
                    >
                        Order now ~ {JSON.parse(currency)} {totalPrice}
                    </Button>
                    <CartModal
                        isOpen={isOpenDelete}
                        handleCancel={handleDeleteCancel}
                        handleOk={handleDeleteOk}
                        isDelete={isDelete}
                        removeFromCart={removeFromCart}
                        getId={getId}
                    />
                </div>
            ) : (
                <Box
                    style={{
                        // margin: '100px auto 0',
                        position: 'relative',
                        backgroundColor: '#FFFFFF',
                        height: '100vh',
                        borderRadius: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <EmptyState height="204px" padding="5px" text="Your cart is empty" />
                </Box>
            )}
        </>
    );
};

export default Cart;
