import { LoadingOutlined } from '@ant-design/icons';
import WestIcon from '@mui/icons-material/West';
import { Button, Input, Select, Spin, Table, notification } from 'antd';
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { decrypt } from '../../utils/encrypt';
import './retailer.css';
import { dateConverter, twoDecimalPlaces } from 'utils/functions';
import { Box, List, ListItem, Typography } from '@mui/material';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const RmaRetailerDashboard = () => {
    const [myRetailers, setMyRetailers] = useState([]);
    const [isCommentOpen, setIsCommentOpen] = useState(false);
    const [commentSuccess, setCommentSuccess] = useState(false);
    const [isOrderDetailsOpen, setIsOrderDetailsOpenOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [containerWidth, setContainerWidth] = useState('false');
    const [profile, setProfile] = useState([]);
    const [profileId, setProfileId] = useState('');
    const [orders, setOrders] = useState([]);
    const [mainRetailer, setMainRetailer] = useState([]);
    const [getOrderId, setGetOrderId] = useState('');
    const [comment, setComment] = useState('');
    const [analytics, setAnalytics] = useState([]);
    const [previousLoyalty, setPreviousLoyalty] = useState([]);
    const [currentsLoyalty, setCurrentsLoyalty] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [accumulatedFilters, setAccumulatedFilters] = useState({});
    const { TextArea } = Input;
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const navigate = useNavigate();
    const country = localStorage.getItem('country');
    const [api, contextHolder] = notification.useNotification();

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54
            }}
            spin
        />
    );

    const userFirstName = localStorage.getItem('userFirstName');
    const [open, setOpen] = useState(true);

    const toggleOpenClose = () => {
        setOpen(!open);
    };

    const openNotification = (placement) => {
        api.open({
            description: 'Comment has been added.',
            placement,
            style: {
                width: 267,
                height: 70,
                backgroundColor: '#0A2938',
                borderRadius: '12px',
                textAlign: 'center',
                color: '#fff',
                fontWeight: 500,
                fontSize: '18px'
            },
            closeIcon: false
        });
    };

    useEffect(() => {
        const handleResize = () => {
            // Adjust the column width based on the window width
            const newWidth = window.innerWidth < 1200 ? 'true' : 'false';
            setContainerWidth(newWidth);
        };

        // Initial setup
        handleResize();

        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // get query data
    const getQueryData = async () => {
        try {
            // setLoading(true);
            const url = `${ApiUrl.BaseUrl}/task/myretailers`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setMainRetailer(answer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const getRetailersData = async () => {
        try {
            setLoading(true);

            const url = `${ApiUrl.BaseUrl}/task/myretailers`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);

                        const filteredRetailer = answer?.filter((name) => {
                            return name?._id === accumulatedFilters?.partner;
                        });

                        if (filteredRetailer.length > 0) {
                            setMyRetailers(filteredRetailer);
                        } else {
                            setMyRetailers(answer);
                        }
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retailerOptions = mainRetailer?.map((item) => {
        return { label: item?.partnerName, value: item?._id };
    });

    useEffect(() => {
        getQueryData();
    }, []);

    useEffect(() => {
        getRetailersData();
    }, [accumulatedFilters]);

    // filter retailer
    const filterRetailer = (value) => {
        const data = {
            partner: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    const columns = [
        {
            title: 'Retailer Name',
            dataIndex: 'customer',
            key: 'customer'
        },

        {
            title: 'Contact',
            dataIndex: 'contact',
            key: 'contact'
        },

        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'Sign up date',
            dataIndex: 'date',
            key: 'date'
        },

        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: '150px'
        }
    ];

    const retailersData = myRetailers?.map((result) => ({
        key: result?._id,
        date: result?.signupDate ? dateConverter(result?.signupDate) : '',
        customer: result?.partnerName,
        contact: result?.contact,
        rma: result?.rma,
        salesvalue: twoDecimalPlaces(result?.salesValue),
        status: result?.status,

        action: (
            <div className="edit-logistics-button">
                <Typography
                    onClick={() => {
                        navigate(`/retailer/profile/${result?._id}`);
                    }}
                    sx={{
                        cursor: 'pointer',
                        margin: '2px'
                    }}
                    className="action-text"
                >
                    View Details <KeyboardArrowRight sx={{ width: '16px' }} />
                </Typography>
            </div>
        )
    }));

    return (
        <div>
            {' '}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '1px dashed #E7EAEB',
                    alignItems: 'flex-start',
                    margin: '0px auto 15px',
                    flexWrap: 'wrap'
                }}
            >
                {' '}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '0 0 16px 0',
                        alignItems: 'flex-start'
                        // flexDirection: 'column'
                        // margin: '0px auto 15px'
                    }}
                >
                    <WestIcon
                        sx={{
                            fontSize: '25px',
                            cursor: 'pointer',
                            margin: '12px 10px 0 0',
                            '&:hover': {
                                color: '#546974'
                            },
                            '&:focus': {
                                color: '#9DA9AF'
                            }
                        }}
                        onClick={() => navigate(-1)}
                    />{' '}
                    <List
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            // padding: '0 0 16px 0',
                            alignItems: 'flex-start',
                            flexDirection: 'column'
                            // margin: '0px auto 15px'
                        }}
                    >
                        <ListItem sx={{ fontWeight: 500, fontSize: '1.75rem', color: '#0A2938', padding: 0 }}>
                            Retailer’s profile{' '}
                            <span role="img" aria-label="👋🏽">
                                👋🏽
                            </span>
                        </ListItem>
                        <ListItem sx={{ fontSize: '0.75rem', color: '#565C6B', padding: 0 }}>
                            View information about your retailers
                        </ListItem>
                    </List>
                </Box>
                <Box
                    sx={{
                        // margin: '20px 0',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        padding: '0 0 16px 0'
                    }}
                >
                    <Box
                        sx={{
                            width: ' 277px',
                            margin: '24px 5px 0 5px',
                            flexGrow: '1'
                            // flexBasis: '200px'
                        }}
                    >
                        <Select
                            style={{
                                width: '100%'
                            }}
                            size="large"
                            options={retailerOptions}
                            placeholder="Select retailer"
                            onChange={(value) => filterRetailer(value)}
                            filterOption={(inputValue, option) => {
                                if (typeof option.label === 'string') {
                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }

                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }}
                            showSearch
                        />
                    </Box>
                    <Button
                        type="primary"
                        size="large"
                        style={{
                            backgroundColor: 'transparent',
                            borderRadius: '8px',
                            fontSize: '16px',
                            fontWeight: '400',
                            color: '#FF740F',
                            width: '161px',
                            border: '1px solid #FF740F',
                            margin: '24px 5px 0 5px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '38px',
                            flexGrow: '1 '
                        }}
                        onClick={() => navigate(`/retailers/reminders/${myRetailers[0]?._id}`)}
                    >
                        View Reminders
                    </Button>
                    <Button
                        onClick={() => navigate(`/retailers/analytics/${myRetailers[0]?._id}`)}
                        type="primary"
                        size="large"
                        style={{
                            backgroundColor: '#0A2938',
                            borderRadius: '8px',
                            fontSize: '16px',
                            fontWeight: '400',
                            color: '#fff',
                            width: '161px',
                            border: '1px solid #0A2938',
                            margin: '24px 5px 0 5px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '38px',
                            flexGrow: '1 '
                        }}
                        className="hover-secondary-button-dark"
                    >
                        View analytics
                    </Button>
                </Box>
            </Box>
            <Table
                loading={loading ? { indicator: <Spin indicator={antIcon} />, spinning: true / false } : ''}
                columns={columns}
                dataSource={retailersData}
                pagination={{
                    // pageSize: limit,
                    hideOnSinglePage: true
                }}
                size="middle"
                scroll={{
                    x: 'calc(700px + 50%)',
                    y: 580
                }}
            />
        </div>
    );
};

export default RmaRetailerDashboard;
