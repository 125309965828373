/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../../components/PageHeader';
import Box from '@mui/material/Box';
// import Controls from '../../../components/controls/Controls';
// import { Search } from '@mui/icons-material';
// import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// import ClearIcon from '@mui/icons-material/Clear';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { Avatar, Typography } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
// import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import Grid from '@mui/material/Grid';
// import axios from 'axios';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    color: {
        color: '#FF740F'
    },
    backgroundColor: {
        backgroundColor: '#FF740F'
    }
}));

const AllRequestServicingFleet = () => {
    const classes = useStyles();
    const history = useNavigate();
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const [jobcardInfo, setJobCard] = useState([]);
    const [jobcardData, setJobCardData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [status, setstatus] = useState('');
    const [searched, setSearched] = useState('');
    console.log(jobcardData);

    const handleEditClick = (row) => {
        if (scriptedRef.current) {
            history(`/updateallrequestservicingfleet/${row._id}`);
        }
    };

    const handleShowClick = (row) => {
        if (scriptedRef.current) {
            // console.log(row);
            history(`/allviewingrequestservicingfleet/${row._id}`);
        }
    };

    // const handlePdfClick = (row) => {
    //     // console.log('row', row);
    //     const userInfo = localStorage.getItem('userToken');
    //     const config = {
    //         headers: {
    //             'auth-token': userInfo,
    //             'Content-Type': 'application/json'
    //         }
    //     };
    //     // setLoading2(true);
    //     axios.post(ApiUrl.DownloadPdfJobCardsUrl, row, config).then((res) => {
    //         window.open(res.data.data, '_blank');

    //         // setLoading2(false);
    //     });
    // };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 30));
        setPage(0);
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - jobcardInfo.length) : 0;

    const retrieveAllCar = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllAdminRequestServicingFleetUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setJobCard(data.data);
                        setJobCardData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        // retrieve all customer complaint;
        retrieveAllCar();
    }, []);

    // const handleDeleteClick = (row) => {
    //     swal({
    //         title: 'Are you sure?',
    //         text: `Once deleted, you will not be able to recover this request service!(${row.carRegistrationNo})`,
    //         icon: 'warning',
    //         buttons: true,
    //         dangerMode: true
    //     }).then((willDelete) => {
    //         if (willDelete) {
    //             swal('Poof! The Request Service has been deleted!', {
    //                 icon: 'success'
    //             });

    //             const data = {
    //                 id: row._id
    //             };
    //             const userInfo = localStorage.getItem('userToken');

    //             fetch(ApiUrl.DeleteUrlsRequestServicingFleets, {
    //                 method: 'DELETE',
    //                 headers: {
    //                     'auth-token': userInfo,
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify(data)
    //             })
    //                 .then((res) => res.json())
    //                 .then((result) => {
    //                     if (result.status === true) {
    //                         if (scriptedRef.current) {
    //                             retrieveAllCar();
    //                         }
    //                     } else {
    //                         toast.error(result.data);
    //                     }
    //                 });
    //         } else {
    //             swal('The Request Service is safe!');
    //         }
    //     });
    // };

    const handleApprovalstatus = (event) => {
        setstatus(event.target.value);
        const userInfo = localStorage.getItem('userToken');
        const status = event.target.value;
        setLoading(true);
        const data = {
            startDate,
            endDate,
            status
        };

        fetch(ApiUrl.FilterRequestServicingStatusUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);

                    setLoading(false);
                    setJobCard(result.data);

                    setJobCardData(result.data);
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handledateChange = (event, picker) => {
        // console.log('startDate', picker.startDate._d);
        // console.log('endDate', picker.endDate._d);
        setstartDate(picker.startDate._d);
        setendDate(picker.endDate._d);
        // FilterOrdersByDateUrl
        const userInfo = localStorage.getItem('userToken');
        const from = picker.startDate._d;
        const to = picker.endDate._d;
        setLoading(true);
        const data = {
            from,
            to,
            status
        };
        fetch(ApiUrl.FilterAdminRequestServicingDateFleetUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);

                    setLoading(false);
                    setJobCard(result.data);
                    setJobCardData(result.data);
                    // if (scriptedRef) {
                    //     setTimeout(() => {
                    //         history('/order');
                    //     }, 3000);
                    // }
                } else {
                    toast.error(result.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const filteredRows = jobcardInfo.filter((row) => row.firstname.toLowerCase().includes(searched.toLowerCase()));
    const isPlural = filteredRows && filteredRows.length > 1 ? 's' : '';
    const searchCount = searched ? `${filteredRows.length} result${isPlural} found` : 'No Results';

    // const handleSearch = (event) => {
    //     const searchWord = event.target.value;

    //     const newFilter = jobcardInfo.filter((value) => value.firstname.toLowerCase().includes(searchWord.toLowerCase()));

    //     if (searchWord === '') {
    //         setJobCard(jobcardData);
    //     } else {
    //         setJobCard(newFilter);
    //     }
    // };

    return (
        <>
            <PageHeader title="Home | Fleet Management | Request for Servicing" subTitle="Home | Request for Servicing" />

            <MainCard title="Request for Servicing">
                <h4 style={{ color: '#ff740f', fontWeight: '700' }}>
                    {searched ? searchCount : `Total Request for Servicing: ${jobcardInfo.length}`}
                </h4>
                <br />
                <Box display="flex" justifyContent="flex-start">
                    <Box width={180}>
                        <FormControl fullWidth>
                            <TextField
                                id="outlined-basic"
                                label="Search Customer"
                                variant="outlined"
                                onChange={(e) => setSearched(e.target.value)}
                            />
                        </FormControl>
                    </Box>
                    <Box width={20} />
                    <Box width={200}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Filter By Approval Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="status"
                                value={status}
                                label="Approval Status"
                                onChange={handleApprovalstatus}
                            >
                                <MenuItem value="All">All</MenuItem>
                                <MenuItem value="Awaiting Approval">Awaiting Approval</MenuItem>
                                <MenuItem value="Approved">Approved</MenuItem>
                                <MenuItem value="Denied">Denied</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box width={20} />
                    <DateRangePicker initialSettings={{ startDate, endDate }} onApply={handledateChange}>
                        <Button variant="contained" size="sm" className={classes.item}>
                            filter by Date
                        </Button>
                    </DateRangePicker>
                </Box>
                <br />

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Appointment Date
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Vehicle Make
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Vehicle Model
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Registration No.
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Company Name
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Customer Name
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Status
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : filteredRows
                            ).map((row, index) => (
                                <TableRow key={row._id}>
                                    <TableCell component="th" scope="row">
                                        {filteredRows.length - index}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {toTimestamp(row?.preferredAppointmentDate)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.carName ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.carmodelName ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.carRegistrationNo ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.channelPartnerId?.partnerName ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {`${row?.firstname}${row?.lastname}` ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row.status === 'Approved' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.success.light,
                                                    color: theme.palette.success.dark,
                                                    width: 150,
                                                    height: 20
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" />
                                                <Box width={10} /> <Typography variant="h5">{row.status}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row.status === 'Done' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.success.light,
                                                    color: theme.palette.success.dark,
                                                    width: 150,
                                                    height: 20
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" />
                                                <Box width={10} /> <Typography variant="h5">{row.status}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row.status === 'Denied' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.error.light,
                                                    color: theme.palette.error.dark,
                                                    width: 150,
                                                    height: 20
                                                }}
                                            >
                                                <DangerousRoundedIcon fontSize="small" /> <Box width={10} />
                                                <Typography variant="h5">{row.status}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row.status === 'Awaiting Approval' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.warning.light,
                                                    color: theme.palette.warning.dark,
                                                    width: 150,
                                                    height: 20
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" /> <Box width={10} />
                                                <Typography variant="h5">{row.status}</Typography>{' '}
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(row)}>
                                            approve
                                        </IconButton>

                                        {/* <IconButton aria-label="delete" color="success" onClick={() => handlePdfClick(row)}>
                                                receipt
                                            </IconButton> */}

                                        <IconButton aria-label="view" color="warning" onClick={() => handleShowClick(row)}>
                                            view
                                        </IconButton>

                                        {/* <IconButton aria-label="delete" color="error" onClick={() => handleDeleteClick(row)}>
                                            <DeleteIcon />
                                        </IconButton> */}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 70, 150, { label: 'All', value: -1 }]}
                    component="div"
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default AllRequestServicingFleet;
