/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
// import { notification } from 'antd';
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import useScriptRef from 'hooks/useScriptRef';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import Form from 'react-bootstrap/Form';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const ViewFleetService = () => {
    const classes = useStyles();
    const [name, setName] = useState('');
    const [cars, setCars] = useState([]);
    const [carbulk, setCarBulk] = useState([]);
    const [carId, setcarId] = useState('');
    const [carname, setcarname] = useState('');
    const [lowcost, setlowcost] = useState('');
    const [highcost, sethighcost] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [status, setStatus] = useState('');
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [open, setOpen] = useState(false);
    // const [opens, setOpens] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleChange = (event) => {
        setStatus(event.target.value);
    };
    const { id } = useParams();

    const retrieveAllCar = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllActiveCar, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCarBulk(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.ViewFleetServiceUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setName(result?.data?.name ?? '');
                    setStatus(result?.data?.status ?? '');
                    setCars(result?.data?.cars ?? []);
                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                }
            })
            .finally(() => {
                setLoading2(false);
            });
        retrieveAllCar();
    }, [id]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const filterCar = (event) => {
        const newFilter = carbulk.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        newFilter.forEach((option) => {
            setcarId(option._id);
            setcarname(option.name);
        });
    };

    const handleDeleteClick = (row, index) => {
        // const newList = items.filter((item) => item.skuId !== row.skuId);

        // setItems(newList);
        const newList = Object.assign([], cars);
        newList.splice(index, 1);
        setCars(newList);
    };

    const onSubmitCar = (event) => {
        event.preventDefault();
        // cars, setCars
        setCars([
            ...cars,
            {
                carId,
                carname,
                lowcost,
                highcost
            }
        ]);

        handleClose();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');

        const data = {
            id,
            name,
            cars,
            status
        };
        setLoading(true);
        fetch(ApiUrl.UpdateFleetServiceUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading(false);
                    setTimeout(() => {
                        if (scriptedRef.current) {
                            history('/fleetservice');
                        }
                    }, 3000);
                } else {
                    toast.error(result.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid item xs={12} sm={8}>
                        <PageHeader title="Fleet Service" subTitle="Home | Fleet Service | Setup Management  | Fleet Service" />
                        <MainCard title="Edit Fleet Service">
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    autoComplete="off"
                                    name="name"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="name"
                                    value={name}
                                    label="Name"
                                    onChange={(e) => setName(e.target.value)}
                                    autoFocus
                                />
                                <br />
                                <br />
                                <br />
                                <Typography variant="subtitle2" component="div">
                                    Vehicle Information
                                </Typography>
                                <br />
                                <Grid container spacing={2} justify="flex-start">
                                    <Grid item xs={12} sm={6} md={4} lg={10}>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                            Car Make
                                                        </TableCell>

                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                            Whole Sale Price (Low)
                                                        </TableCell>
                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                            Whole Sale Price (high)
                                                        </TableCell>

                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                            Actions
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {cars.map((row, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell component="th" scope="row">
                                                                {row.carname}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {row.lowcost}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {row.highcost}
                                                            </TableCell>

                                                            <TableCell component="th" scope="row">
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    color="error"
                                                                    onClick={() => handleDeleteClick(row, index)}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={2}>
                                        <Button variant="contained" onClick={handleClickOpen} size="sm" className={classes.item}>
                                            Add vehicle
                                        </Button>
                                    </Grid>
                                </Grid>
                                <br />

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={status}
                                        label="Status"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Active">Active</MenuItem>
                                        <MenuItem value="InActive">InActive</MenuItem>
                                    </Select>
                                </FormControl>
                                <br />
                                <br />
                                <Box display="flex" justifyContent="space-between">
                                    <Button component={Link} to="/fleetservice" type="submit" variant="contained" color="error">
                                        Reset
                                    </Button>

                                    <Button type="submit" variant="contained" color="secondary" disabled={!name}>
                                        {loading ? 'Updating ..' : 'Update'}
                                    </Button>
                                </Box>
                            </form>
                            <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                                <DialogTitle id="responsive-dialog-title">Add Vehicle</DialogTitle>
                                <Form onSubmit={onSubmitCar}>
                                    <DialogContent>
                                        {/* <DialogContentText> */}

                                        <Form.Group controlId="formFile" className="mb-3">
                                            <Box width={200}>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    value={carname}
                                                    onChange={(event, newValue) => {
                                                        setcarname(newValue);
                                                        filterCar(newValue);
                                                    }}
                                                    options={carbulk.map((option) => option.name)}
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    renderInput={(params) => <TextField {...params} label="Car Make" />}
                                                />
                                            </Box>
                                            <br /> <br />
                                            <Box width={200}>
                                                <TextField
                                                    autoComplete="off"
                                                    type="number"
                                                    name="lowcost"
                                                    variant="outlined"
                                                    required
                                                    id="lowcost"
                                                    label="Whole Sale Price (LOW)"
                                                    value={lowcost}
                                                    onChange={(e) => setlowcost(e.target.value)}
                                                />
                                            </Box>
                                            <br /> <br />
                                            <Box width={200}>
                                                <TextField
                                                    autoComplete="off"
                                                    type="number"
                                                    name="highcost"
                                                    variant="outlined"
                                                    required
                                                    id="highcost"
                                                    label="Whole Sale Price (High)"
                                                    value={highcost}
                                                    onChange={(e) => sethighcost(e.target.value)}
                                                />
                                            </Box>
                                            <br />
                                        </Form.Group>

                                        {/* </DialogContentText> */}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            variant="contained"
                                            size="sm"
                                            className={classes.item}
                                            autoFocus
                                            onClick={handleClose}
                                            color="error"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            autoFocus
                                            variant="contained"
                                            type="submit"
                                            size="sm"
                                            className={classes.item}
                                            disabled={!carname || !lowcost || !highcost}
                                        >
                                            Add
                                        </Button>
                                    </DialogActions>
                                </Form>
                            </Dialog>
                        </MainCard>
                    </Grid>
                </>
            )}
        </>
    );
};
export default ViewFleetService;
