import { useEffect, useRef, useState } from 'react';
import './attendance.css';
import { Button, Space } from 'antd';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Webcam from 'react-webcam';
import Divider from '@mui/material/Divider';

const ImageUpload = () => {
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [openCamera, setOpenCamera] = useState(null);
    const webcamRef = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        console.log(file);
    };

    const handleUpload = () => {};
    const handleUploadButtonClick = () => {
        // Trigger the file input click when the button is clicked
        fileInputRef.current.click();
        setOpenCamera(false);
    };

    const capture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImageSrc(imageSrc);
    };

    const handleOpenCamera = () => {
        setOpenCamera(!openCamera);
    };

    return (
        <>
            <div className="image-container">
                <p>Upload Image</p>
                <CloudUploadIcon />
                <input type="file" accept="image/*" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
                <Space wrap>
                    <Button size="large" type="primary" onClick={handleUploadButtonClick}>
                        Browse files
                    </Button>
                    <Divider variant="middle" />
                    <Button size="large" type="primary" onClick={handleOpenCamera}>
                        Open Camera
                    </Button>
                </Space>
                {selectedFile && <img src={URL.createObjectURL(selectedFile)} alt="Selected" />}
            </div>
            <div>
                {openCamera && (
                    <>
                        <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
                        <Button size="large" type="primary" onClick={capture}>
                            Capture Photo
                        </Button>
                        {imageSrc && <img src={imageSrc} alt="Captured" />}
                    </>
                )}
            </div>
        </>
    );
};

export default ImageUpload;
