import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, List, ListItem, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { DatePicker, Select, Space, Checkbox, Button, Input, Popover, Dropdown, notification, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import WestIcon from '@mui/icons-material/West';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { toast } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';
import ApiUrl from 'network';
import { encrypt, decrypt } from '../../utils/encrypt';
import { useNavigate } from 'react-router';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { dateConverter } from 'utils/functions';

const EditTask = ({
    openEditTask,
    showEditTaskModal,
    handleEditTaskOk,
    handleEditTaskCancel,
    getTaskId,
    mainRma,
    rmaOptions,
    setTaskSuccess,
    taskSuccess
}) => {
    const [getEditRma, setGetEditRma] = useState('');
    const [openEditAssignRma, setOpenEditAssignRma] = useState(false);
    const [openEditDueDate, setOpenEditDueDate] = useState(false);
    const [isEditingTask, setIsEditingTask] = useState(false);
    const [task, setTask] = useState([]);
    const [ediTtitle, setEditTitle] = useState('');
    const [editAssignedRmas, setEditAssignedRmas] = useState([]);
    const [finaleditAssignedRmas, setFinalEditAssignedRmas] = useState([]);
    const [editDueDate, setEditDueDate] = useState('');
    const [editDescription, setEditDescription] = useState('');
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const country = localStorage.getItem('country');
    const [api, contextHolder] = notification.useNotification();

    const navigate = useNavigate();
    const { TextArea } = Input;

    const openNotification = (placement) => {
        api.open({
            description: 'Task has been updated.',
            placement,
            style: {
                width: 267,
                height: 70,
                backgroundColor: '#0A2938',
                borderRadius: '12px',
                textAlign: 'center',
                color: '#fff',
                fontWeight: 500,
                fontSize: '18px'
            },
            closeIcon: false
        });
    };

    const getSingleTask = async (id) => {
        try {
            const abortController = new AbortController();

            const url = `${ApiUrl.BaseUrl}/task/viewtask/${id}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                },
                signal: abortController.signal // Pass the signal to the fetch request
            };

            const response = await fetch(url, config);

            if (!abortController.signal.aborted) {
                const data = await response.json();
                if (data.status === true) {
                    const result = decrypt(data.data, keyResult, ivResult);
                    const answer = JSON.parse(result);
                    setEditTitle(answer.title);
                    setEditDescription(answer.description);
                    setEditAssignedRmas(answer.assignedUsers);
                    setEditDueDate(answer.dueDate);
                } else {
                    const error = decrypt(data.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const abortController = new AbortController();
        getSingleTask(getTaskId);

        return () => abortController.abort();
    }, [getTaskId]);

    const handleSelectRmas = (value) => {
        const rma = mainRma.find((rma) => rma?._id === value);

        if (editAssignedRmas.includes(rma?.name)) {
            toast.error(`${rma.name} has already been selected!!`);
        } else if (editAssignedRmas.includes(rma?._id)) {
            toast.error(`${rma.name} has already been selected!!`);
        } else if (editAssignedRmas.includes(rma?.userId)) {
            toast.error(`${rma.name} has already been selected!!`);
            return;
        }

        if (!editAssignedRmas.includes(value)) {
            setGetEditRma([...getEditRma, rma.name]);
            setEditAssignedRmas([...editAssignedRmas, rma]);
        }
    };

    // remove rma
    const handleRemoveRma = (valueToRemove) => {
        const finalUpdatedRmas = editAssignedRmas.map((rma) => {
            if (rma.userId === valueToRemove) {
                return { ...rma, status: 'removed' };
            }
            return rma;
        });

        setEditAssignedRmas(finalUpdatedRmas);
        setFinalEditAssignedRmas(...editAssignedRmas, finalUpdatedRmas);
    };

    const filteredRma = editAssignedRmas.filter((item) => item?.status !== 'removed');

    const onDateChange = (date, dateString) => {
        setEditDueDate(dateString);
    };

    const selectRmas = (
        <>
            <Box
                sx={{
                    width: ' 100%'
                }}
            >
                <Select
                    style={{
                        width: '250px',
                        margin: '10px 5px'
                    }}
                    showSearch
                    size="large"
                    allowClear
                    placeholder="Select Rma/cc"
                    optionFilterProp="children"
                    filterOption={(inputValue, option) => {
                        if (typeof option.label === 'string') {
                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        }

                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                    }}
                    options={rmaOptions}
                    value={null}
                    onChange={handleSelectRmas}
                />
            </Box>
        </>
    );

    const selectDueDate = (
        <>
            <Box
                sx={{
                    width: ' 100%'
                }}
            >
                <DatePicker
                    style={{
                        width: '250px',
                        margin: '10px 5px'
                    }}
                    showSearch
                    size="large"
                    allowClear
                    onChange={onDateChange}
                />
            </Box>
        </>
    );

    const editTask = async () => {
        const userInfo = localStorage.getItem('userToken');
        setIsEditingTask(true);

        const data = {
            id: getTaskId,
            title: ediTtitle,
            description: editDescription,
            dueDate: editDueDate,
            status: 'InProgress',
            assignedUsers: editAssignedRmas?.map((rma) => ({
                userId: rma?.userId ? rma.userId : rma._id,
                name: rma.name,
                status: rma?.status
            }))
        };

        const obj = JSON.stringify(data);
        const result = encrypt(obj, keyResult, ivResult);

        await fetch(`${ApiUrl.BaseUrl}/task/updatetask`, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ payload: result })
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    openNotification('bottom');
                    setTaskSuccess(!taskSuccess);
                    handleEditTaskOk();
                } else if (result.status === false) {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                } else {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                }
            })
            .finally(() => {
                setIsEditingTask(false);
            });
    };

    return (
        <div>
            {contextHolder}
            <Modal
                open={openEditTask}
                onOk={handleEditTaskOk}
                centered
                width={691}
                // zIndex={4400}
                // height={490}
                onCancel={handleEditTaskCancel}
                footer={<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />}
                mask={false}
            >
                <Box
                    style={{
                        backgroundColor: '#FFFFFF',
                        borderRadius: '16px',
                        border: '1px solid #FFFFFF',
                        margin: '5px',
                        display: 'flex',
                        padding: '18px 20px',
                        flexDirection: 'column'
                    }}
                >
                    {' '}
                    <Input
                        placeholder="Title here"
                        onChange={(e) => {
                            setEditTitle(e.target.value);
                        }}
                        // onKeyUp={handleKeyPress}
                        value={ediTtitle}
                        style={{
                            backgroundColor: '#ECEDEF',
                            borderRadius: '12px',
                            margin: '5px',
                            border: 'none',
                            height: '60px',
                            width: '100%'
                        }}
                    />
                    <TextArea
                        rows={4}
                        placeholder="Description"
                        onChange={(e) => {
                            setEditDescription(e.target.value);
                        }}
                        value={editDescription}
                        style={{
                            backgroundColor: '#ECEDEF',
                            borderRadius: '12px',
                            margin: '5px',
                            border: 'none',
                            height: '100px',
                            width: '100%'
                        }}
                        autoSize={{
                            minRows: 5,
                            maxRows: 6
                        }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                margin: '10px 0 4px 5px'
                            }}
                        >
                            {filteredRma.length > 0 ? (
                                <Typography
                                    sx={{
                                        color: '#85949C',
                                        fontSize: '14px'
                                    }}
                                >
                                    Assigned to{' '}
                                    {filteredRma.slice(0, 2).map((user, index) => (
                                        <React.Fragment key={user?.userId ? user?.userId : user?._id}>
                                            {index > 0 ? ', ' : ''} {user.name}
                                            {index === 1 && filteredRma.length > 2 ? `, and ${filteredRma.length - 2} more` : ''}
                                        </React.Fragment>
                                    ))}
                                </Typography>
                            ) : null}
                            {/* {editAssignedRmas.length > 0 ? (
                                <Typography
                                    sx={{
                                        color: '#85949C',
                                        fontSize: '14px'
                                    }}
                                >
                                    Assigned to {editAssignedRmas.map((user) => user.name)}
                                </Typography>
                            ) : null} */}
                            {editDueDate.length > 0 ? (
                                <Typography
                                    sx={{
                                        color: '#85949C',
                                        fontSize: '14px'
                                    }}
                                >
                                    Due date: {dateConverter(editDueDate)}
                                </Typography>
                            ) : null}
                        </Box>

                        <Box>
                            {filteredRma?.length > 0 ? (
                                <Box
                                    sx={{
                                        width: ' 100%',
                                        margin: '8px 0 0 0px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        // justifyContent: 'space-between',
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    {filteredRma?.map((rma, index) => (
                                        <Box
                                            key={rma?.userId ? rma?.userId : rma?._id}
                                            sx={{
                                                // width: ' 124px',
                                                margin: '8px 0 0 0',
                                                marginLeft: `${index !== 0 ? '3px' : '0px'}`,
                                                height: '44px',
                                                borderRadius: '8px',
                                                padding: '8px',
                                                border: '1px solid #E9ECED',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#546974',
                                                    fontSize: '16px'
                                                }}
                                            >
                                                {rma?.name}
                                            </Typography>

                                            <CloseIcon
                                                sx={{
                                                    color: '#434A5B99',
                                                    fontSize: '16px',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                    handleRemoveRma(rma?.userId);
                                                }}
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexWrap: 'wrap'
                            }}
                        >
                            {' '}
                            <Typography
                                sx={{
                                    border: '1px solid #FFD5B7',
                                    height: '40px',
                                    width: '110px',
                                    padding: '10px 12px',
                                    borderRadius: '8px',
                                    color: '#FF740F',
                                    fontWight: '500',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    margin: '10px 5px '
                                }}
                                onClick={() => setOpenEditDueDate(!openEditDueDate)}
                            >
                                Add due date
                            </Typography>
                            <Typography
                                sx={{
                                    border: '1px solid #FFD5B7',
                                    height: '40px',
                                    width: '130px',
                                    padding: '10px 12px',
                                    borderRadius: '8px',
                                    color: '#FF740F',
                                    fontWight: '500',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    margin: '10px 5px'
                                }}
                                onClick={() => setOpenEditAssignRma(!openEditAssignRma)}
                            >
                                Assign RMA/CC
                            </Typography>
                        </Box>
                        <Box>
                            <Button
                                type="primary"
                                size="large"
                                loading={isEditingTask}
                                style={{
                                    backgroundColor: '#FF740F',
                                    borderRadius: '12px',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    color: '#fff',
                                    width: '122px',

                                    margin: '10px 5px ',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '38px',
                                    flexGrow: '1 '
                                    // flexBasis: '200px'
                                }}
                                onClick={() => {
                                    editTask();
                                }}
                            >
                                Save Changes
                            </Button>
                        </Box>
                    </Box>
                    {openEditAssignRma ? selectRmas : null}
                    {openEditDueDate ? selectDueDate : null}
                </Box>
            </Modal>
        </div>
    );
};

export default EditTask;
