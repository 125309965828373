/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';
// import Controls from '../../components/controls/Controls';
// import { Search } from '@mui/icons-material';
// import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import { Col } from 'react-grid-system';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
// import swal from 'sweetalert';
// import * as XLSX from 'xlsx';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import ViewIcon from '@mui/icons-material/ViewAgenda';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
// import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
// import { saveAs } from 'file-saver';
// import axios from 'axios';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { cryptoUUID } from '../../utils/functions';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import Grid from '@mui/material/Grid';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    colors: {
        color: `#ED7C1B`
    },
    backgroundColor: {
        backgroundColor: '#2CB23B'
    }
}));

const AllMoMoCollections = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [orderInfo, setOrder] = useState([]);

    const [loading, setLoading] = useState(false);
    // const [loading2, setLoading2] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    // const [orderStatus, setOrderStatus] = useState([]);
    const [searched, setSearched] = useState('');
    const userCountry = localStorage.getItem('country');

    // const [orderStatusId, setorderStatusId] = useState('');

    const handleEditClick = (row) => {
        if (scriptedRef.current) {
            // console.log(row);
            history(`/previewmomocollection/${row._id}`);
        }
    };
    const handleMoMoPayClick = () => {
        if (scriptedRef.current) {
            const ids = cryptoUUID();
            console.log('ids', ids);
            // console.log(row);
            history(`/addmomocollection/${ids}`);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 30));
        setPage(0);
    };

    function toTimestamp(strDate) {
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderInfo.length) : 0;

    const retrieveAllMoMoCollection = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllMoMoCollectionsUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrder(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        retrieveAllMoMoCollection();
    }, []);

    const handledateChange = (event, picker) => {
        setstartDate(picker.startDate._d);
        setendDate(picker.endDate._d);
        const userInfo = localStorage.getItem('userToken');
        const from = picker.startDate._d;
        const to = picker.endDate._d;
        setLoading(true);
        const data = {
            from,
            to
        };
        fetch(ApiUrl.AllFilterMoMoCollectionsUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);

                    setLoading(false);
                    setOrder(result.data);
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const filteredRows = orderInfo.filter((row) => row?.momoNumber.toLowerCase().includes(searched.toLowerCase()));
    const isPlural = filteredRows && filteredRows.length > 1 ? 's' : '';
    const searchCount = searched ? `${filteredRows.length} result${isPlural} found` : 'No Results';

    return (
        <>
            <br />
            <PageHeader title="MoMoCollection" subTitle="Home | MoMo Collections " />
            <br />
            <br />
            <MainCard title="MoMo Collection List">
                <h4 style={{ color: '#ff740f', fontWeight: '700' }}>
                    {searched ? searchCount : `Total MoMo Collections: ${orderInfo.length}`}
                </h4>
                <br />

                <Box display="flex" justifyContent="flex-start">
                    <Box width={180}>
                        <FormControl fullWidth style={{ marginBottom: '10px' }}>
                            <TextField
                                id="outlined-basic"
                                label="Search MoMo Number"
                                variant="outlined"
                                onChange={(e) => setSearched(e.target.value)}
                            />
                        </FormControl>
                    </Box>

                    <Box width={20} />
                    <DateRangePicker initialSettings={{ startDate, endDate }} onApply={handledateChange}>
                        <Button variant="contained" size="sm" className={classes.item}>
                            filter by Date
                        </Button>
                    </DateRangePicker>
                    <Box width={10} />
                    {userCountry === 'Ghana' ? (
                        <Button onClick={() => handleMoMoPayClick()} variant="contained" size="sm" className={classes.backgroundColor}>
                            MOMO PAY
                        </Button>
                    ) : (
                        ''
                    )}
                </Box>
                <br />

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            MoMo Date
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            MoMo Number
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            User
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Reference
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Amount
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Network
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Status
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : filteredRows
                            ).map((row, index) => (
                                <TableRow key={row._id}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {toTimestamp(row?.createdAt)}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.momoNumber ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.userId ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.momoReference}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {Number(row?.momoAmt).toFixed(2)}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.momoNetwork}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.momoStatus ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        <IconButton aria-label="edit" color="warning" onClick={() => handleEditClick(row)}>
                                            Preview
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 150, 250, { label: 'All', value: -1 }]}
                    component="div"
                    count={orderInfo.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default AllMoMoCollections;
