/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';
// import Controls from '../../components/controls/Controls';
// import { Search } from '@mui/icons-material';
// import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { Col } from 'react-grid-system';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import ViewIcon from '@mui/icons-material/ViewAgenda';
// import IconButton from '@mui/material/IconButton';
// import { useNavigate } from 'react-router-dom';
// import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import { Avatar } from '@mui/material';
import Typography from '@mui/material/Typography';
// import swal from 'sweetalert';
// import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
// import axios from 'axios';
// import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
// import Avatar from '@mui/material/Avatar';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
// import NoteAltIcon from '@mui/icons-material/NoteAlt';
// import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
// import SortIcon from '@mui/icons-material/Sort';
// import * as blobUtil from 'blob-util';
// import { Camera } from 'react-camera-pro';
// import { useTheme } from '@mui/material/styles';
// import { saveAs } from 'file-saver';
// import axios from 'axios';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    }
}));

const CSPartsPriceList = () => {
    const classes = useStyles();
    // const history = useNavigate();
    // const theme = useTheme();
    // const scriptedRef = useScriptRef();
    const [orderInfo, setOrder] = useState([]);
    // const [orderData, setOrderData] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [startDate, setstartDate] = useState(new Date());
    // const [endDate, setendDate] = useState(new Date());
    // const [loading2, setLoading2] = useState(false);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [count, setCount] = useState(50);
    const [searched, setSearched] = useState('');
    const [localSearch, setlocalSearch] = useState('');
    // console.log(orderData);

    const handleChangePage = (event, newPage) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);

            fetch(`${ApiUrl.MAINCSPartsPriceListDataUrl}?page=${newPage}`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrder(data.data ?? []);
                        setPage(data?.currentPage);
                        setCount(data?.totalParts);
                        setRowsPerPage(data?.perPage);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);

            fetch(`${ApiUrl.MAINCSPartsPriceListDataUrl}?limit=${event.target.value}`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrder(data.data ?? []);
                        setPage(data?.currentPage);
                        setCount(data?.totalParts);
                        setRowsPerPage(data?.perPage);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderInfo.length) : 0;

    const retrieveAllOrders = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);

            let url = `${ApiUrl.MAINCSPartsPriceListDataUrl}`;
            if (searched) {
                url += `?product=${searched}`;
            }

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrder(data.data);
                        setPage(data?.currentPage);
                        setCount(data?.totalParts);
                        setRowsPerPage(data?.perPage);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const debounce = () => {
        let timeoutID;
        return (e) => {
            setlocalSearch(e.target.value);
            clearTimeout(timeoutID);
            timeoutID = setTimeout(() => {
                setSearched(e.target.value);
            }, 500);
        };
    };

    const handleSearch = useMemo(() => debounce(), []);

    useEffect(() => {
        retrieveAllOrders();
        // eslint-disable-next-line
    }, [searched]);

    // const filteredRows = orderInfo.filter((row) => row.sku.toLowerCase().includes(searched.toLowerCase()));
    // const isPlural = filteredRows && filteredRows.length > 1 ? 's' : '';
    // const searchCount = searched ? `${filteredRows.length} result${isPlural} found` : 'No Results';

    // const handleSearch = (event) => {
    //     const searchWord = event.target.value;

    //     const newFilter = orderInfo.filter((value) => (value.sku || '').toLowerCase().includes((searchWord || '').toLowerCase()));

    //     if (searchWord === '') {
    //         setOrder(orderData);
    //     } else {
    //         setOrder(newFilter);
    //     }
    // };
    const handleReset = () => {
        retrieveAllOrders();
    };

    return (
        <>
            <PageHeader title="Price List" subTitle="CS | Price List " />

            <MainCard title="CS Price List">
                <h4 style={{ color: '#ff740f', fontWeight: '700' }}>{`Total Parts: ${count}`}</h4>
                <br />
                <Box display="flex" justifyContent="flex-start">
                    {/* <Controls.Input
                        label="Search Part Name"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearch}
                    /> */}
                    <Col xs={12} xl={3} lg={3} md={6} sm={6}>
                        <FormControl fullWidth style={{ marginBottom: '10px' }}>
                            <TextField
                                id="outlined-basic"
                                label="Search Product"
                                variant="outlined"
                                value={localSearch}
                                onChange={handleSearch}
                            />
                        </FormControl>
                    </Col>
                    <Box width={20} />

                    <Button variant="contained" onClick={handleReset} size="sm" className={classes.item} color="error">
                        Reset Filter
                    </Button>
                </Box>
                <br />

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Product
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Category
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Qty In Pieces
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            CS Price
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Image
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Last Modified
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orderInfo.map((row, index) => (
                                <TableRow key={row._id}>
                                    <TableCell component="th" scope="row">
                                        {orderInfo.length - index}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.sku ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.productFamily ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.partQtyInPieces ?? ''}
                                    </TableCell>
                                    {/* <TableCell component="th" scope="row">
                                        {row?.partListPrice ?? ''}
                                    </TableCell> */}
                                    <TableCell component="th" scope="row">
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Typography variant="subtitle1">
                                                {row?.discountedSellingPrice > 0 ? row?.discountedSellingPrice : row?.garagePrice}
                                            </Typography>{' '}
                                            {row?.discountedSellingPrice !== 0 ? (
                                                <Typography
                                                    variant="subtitle1"
                                                    sx={{
                                                        textDecoration: 'line-through',
                                                        textDecorationColor: 'red',
                                                        textDecorationThickness: '2px',
                                                        paddingLeft: '8px'
                                                    }}
                                                >
                                                    {row?.garagePrice}
                                                </Typography>
                                            ) : (
                                                ''
                                            )}
                                        </Box>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Avatar variant="rounded" src={row?.partsImage} sx={{ width: 150, height: 150 }} />
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {toTimestamp(row?.updatedAt)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[100, 200, 300, count]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={!count || count <= 0 ? 0 : page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default CSPartsPriceList;
