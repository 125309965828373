import React, { useState } from 'react';
import Navigation from './Navigation';
import Cart from './Cart';

const CartPage = () => {
    const [isOpenDelete, setIsOpenDelete] = useState(false);

    return (
        <div id="homepage">
            <h1 className="cart-heading">
                <span> Selected products</span>
                <span>Cart</span>
            </h1>

            <Cart isOpenDelete={isOpenDelete} setIsOpenDelete={setIsOpenDelete} />

            <Navigation isOpenDelete={isOpenDelete} />
        </div>
    );
};

export default CartPage;
