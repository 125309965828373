/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
import { styled } from '@mui/material/styles';
// import { notification } from 'antd';
import { toast } from 'react-toastify';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';
import useScriptRef from 'hooks/useScriptRef';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import nologo from 'assets/images/nologo.png';

const Input = styled('input')({
    display: 'none'
});

const AddPartFleet = () => {
    const [sku, setSku] = useState('');
    const [productFamily, setproductFamily] = useState('');
    const [brand, setbrand] = useState('');
    const [wholeSalePrice, setwholeSalePrice] = useState('');
    const [garagePrice, setgarageRetailPrice] = useState('');
    const [distributor, setdistributor] = useState('');
    const [partimage, setimage] = useState('');
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [distributorInfo, setDistributors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);

    const selectedFile = (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        setLoading3(true);
        const userInfo = localStorage.getItem('userToken');
        axios
            .post(ApiUrl.UploadCollectionImageUrl, formData, {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => {
                if (result.data.status === true) {
                    setLoading3(false);
                    setimage(result?.data?.data ?? '');
                } else {
                    toast.error(result.data);
                    setLoading3(false);
                }
            })
            .catch((err) => {
                toast.error(err);
                setLoading3(false);
            });
    };

    const retrieveAllDistributor = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.DistributorUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setDistributors(data.data);
                        setLoading2(false);
                    } else {
                        toast.error(data.data);
                        setLoading2(false);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        retrieveAllDistributor();
    }, []);

    const handlePFamilyChange = (event) => {
        setproductFamily(event.target.value);
    };

    const handleDistributorChange = (event) => {
        setdistributor(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        console.log(userInfo);

        const data = {
            sku,
            productFamily,
            partimage,
            brand,
            wholeSalePrice,
            garagePrice,
            distributor
        };

        console.log('data', data);

        fetch(ApiUrl.AddPartFleetUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/allfleetpart');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid item xs={12} sm={6}>
                        <PageHeader title="Parts" subTitle="Home | Parts " />
                        <MainCard title="Add Parts">
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    autoComplete="off"
                                    name="sku"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="sku"
                                    value={sku}
                                    label="Product Name"
                                    onChange={(e) => setSku(e.target.value)}
                                    autoFocus
                                />
                                <br />
                                <br />
                                <Box width={250}>
                                    <InputLabel id="demo-simple-select-label">Attach Product Image</InputLabel>
                                    <Stack direction="row" alignItems="center" spacing={4}>
                                        <Avatar variant="rounded" src={partimage ?? nologo} sx={{ width: 100, height: 70 }} />

                                        <label htmlFor="contained-button-file">
                                            <Input
                                                accept="image/*"
                                                id="contained-button-file"
                                                multiple
                                                type="file"
                                                onChange={selectedFile}
                                            />
                                            <Button variant="contained" component="span" disabled={loading3}>
                                                {loading3 ? 'Uploading ...' : 'Upload'}
                                            </Button>
                                        </label>
                                    </Stack>
                                </Box>
                                <br />
                                <br />
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Product Family</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={productFamily}
                                        label="Status"
                                        onChange={handlePFamilyChange}
                                    >
                                        <MenuItem value="Filters">Filters</MenuItem>
                                        <MenuItem value="Body">Body</MenuItem>
                                        <MenuItem value="Mechanical">Mechanical</MenuItem>
                                        <MenuItem value="consumables">consumables</MenuItem>
                                        <MenuItem value="Electrical">Electrical</MenuItem>
                                    </Select>
                                </FormControl>
                                <br />
                                <br />

                                <TextField
                                    autoComplete="off"
                                    name="brand"
                                    variant="outlined"
                                    fullWidth
                                    id="brand"
                                    value={brand}
                                    label="Brand"
                                    onChange={(e) => setbrand(e.target.value)}
                                />
                                <br />
                                <br />
                                <TextField
                                    autoComplete="off"
                                    name="wholeSalePrice"
                                    type="number"
                                    variant="outlined"
                                    fullWidth
                                    id="wholeSalePrice"
                                    value={wholeSalePrice}
                                    label="Wholesale Price"
                                    onChange={(e) => setwholeSalePrice(e.target.value)}
                                />
                                <br />
                                <br />
                                <TextField
                                    autoComplete="off"
                                    name="garagePrice"
                                    type="number"
                                    variant="outlined"
                                    fullWidth
                                    id="garagePrice"
                                    value={garagePrice}
                                    label="Garage Price"
                                    onChange={(e) => setgarageRetailPrice(e.target.value)}
                                />

                                <br />
                                <br />

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Distributor(s)</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={distributor}
                                        label="Status"
                                        onChange={handleDistributorChange}
                                    >
                                        {distributorInfo.map((distributors) => (
                                            <MenuItem key={distributors._id} value={distributors._id}>
                                                {distributors.partnerName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <br />
                                <br />

                                <Box display="flex" justifyContent="space-between">
                                    <Button component={Link} to="/allfleetpart" type="submit" variant="contained" color="error">
                                        Reset
                                    </Button>

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        disabled={!sku || !wholeSalePrice || !garagePrice || !distributor}
                                    >
                                        {loading ? 'Submitting ..' : 'Submit'}
                                    </Button>
                                </Box>
                            </form>
                        </MainCard>
                    </Grid>
                </>
            )}
        </>
    );
};

export default AddPartFleet;
