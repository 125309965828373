import { Link } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Button, Card, Collapse, Divider, Input, Select, Spin, DatePicker } from 'antd';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ApiUrl from '../../../../network';
import { decrypt } from '../../../../utils/encrypt';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallIcon from '@mui/icons-material/Call';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import dayjs from 'dayjs';
import { EmptyState } from '../../../../components/EmptyState/EmptyState';
import DebounceSelect from '../../../../components/debouceSelect/Index';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const { RangePicker } = DatePicker;
    const [loading, setLoading] = useState(false);
    const [routeData, setRouteData] = useState({});
    const [retailers, setRetailers] = useState([]);
    const [retailer, setRetailer] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [value, setValue] = useState(null);

    // eslint-disable-next-line consistent-return
    const fetchRoutes = async (data) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/routerreport/rmaccroutedashboard`;

            if (data) {
                if (data.startDate) urlParams.append('startDate', data.startDate);
                if (data.endDate) urlParams.append('endDate', data.endDate);
                if (data.retailer) urlParams.append('customer', data.retailer);
            }

            url = `${url}?${urlParams.toString()}`;

            const response = await fetch(url, config);

            await response.json().then((data) => {
                if (data.status === false) {
                    toast.error(data.message);
                    setLoading(false);
                } else {
                    const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                    setRouteData(result);
                    setLoading(false);
                }
            });
        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchRoutes();
        return () => {
            // cleanup function
            setLoading(false);
        };
    }, []);

    // eslint-disable-next-line consistent-return

    const fetchUserList = async (username) => {
        const userInfo = localStorage.getItem('userToken');
        if (!userInfo) {
            toast.error('User not authenticated');
            return false;
        }
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };
        return fetch(
            `
        ${ApiUrl.BaseUrl}/loyaltymanager/main?retailer=${username}`,
            config
        )
            .then((response) => response.json())
            .then((body) =>
                JSON.parse(decrypt(body.data, keyResult, ivResult)).map((user) => ({
                    label: user.retailerName,
                    value: user._id
                }))
            );
    };

    // eslint-disable-next-line consistent-return
    const fetchAllRetailers = async (filters) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/loyaltymanager/main`;

            if (filters && filters.search) {
                urlParams.set('retailer', filters.search);
            }
            if (urlParams.toString()) url += `?${urlParams.toString()}`;

            const response = await fetch(url, config);
            const body = await response.json();
            const results = JSON.parse(decrypt(body.data, keyResult, ivResult));
            const options = results.map((user) => ({
                label: user.retailerName,
                value: user.retailerName
            }));
            return options;
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };

    // eslint-disable-next-line consistent-return
    const fetchRetailer = async (id) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(`${ApiUrl.BaseUrl}/loyaltymanager/viewdetails/${id}`, config);
            await response.json().then((data) => {
                if (data.status === false) {
                    toast.error(data.message);
                    setLoading(false);
                } else {
                    setRetailer(JSON.parse(decrypt(data.data, keyResult, ivResult)));
                    setLoading(false);
                }
            });
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchAllRetailers();
        return () => {
            // cleanup function
            setLoading(false);
        };
    }, []);

    const handleDateChange = (date, dateString) => {
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
    };

    const appleyFilter = () => {
        const filterData = {
            retailer: value,
            startDate,
            endDate
        };
        fetchRoutes(filterData);
    };

    return (
        <div className="leadCrmContainer">
            <div
                className="d-flex justify-content-space-between align-items-center salesCardHolder mb-4"
                style={{ borderBottom: '1px dotted #E7EAEB' }}
            >
                <div style={{ color: '#0A2938' }}>
                    <p style={{ fontSize: '30px' }}>Dashboard</p>
                </div>
                <div className="d-flex justify-content-space-between align-items-center leadHeaders">
                    <Button
                        onClick={() => (window.location.href = '/rma-route-schedule-report')}
                        style={{
                            backgroundColor: '#FF740F',
                            color: '#fff'
                        }}
                    >
                        View report
                    </Button>
                </div>
            </div>
            <Collapse accordion>
                <Collapse.Panel key={1} header="Hide filters" className="mb-4">
                    <div className="batchFilters">
                        <div className="mr-2">
                            <DebounceSelect
                                allowClear
                                showSearch
                                value={value || null}
                                placeholder="Search retailer"
                                fetchOptions={fetchUserList}
                                onChange={(newValue) => {
                                    setValue(newValue.value);
                                }}
                                style={{
                                    width: '300px'
                                }}
                            />
                        </div>
                        <div className="mt-2">
                            <RangePicker
                                onChange={(date, dateString) => {
                                    handleDateChange(date, dateString);
                                }}
                                value={startDate && endDate ? [dayjs(startDate, 'YYYY-MM-DD'), dayjs(endDate, 'YYYY-MM-DD')] : null}
                                format="YYYY-MM-DD"
                                className="datePicker filterInput"
                            />
                        </div>
                        <div className="d-flex align-items-center">
                            <Button
                                style={{
                                    marginLeft: '10px',
                                    color: '#FF740F',
                                    border: '1px solid #FF740F'
                                }}
                                loading={loading}
                                onClick={() => appleyFilter()}
                            >
                                Apply filter
                            </Button>
                            {(startDate && endDate) || value ? (
                                <Button
                                    onClick={() => {
                                        setStartDate('');
                                        setEndDate('');
                                        setValue('');
                                        fetchRoutes();
                                    }}
                                    style={{
                                        color: '#FF740F',
                                        border: '1px solid #FF740F'
                                    }}
                                    className="ml-2"
                                >
                                    Reset filter
                                </Button>
                            ) : null}
                        </div>
                    </div>
                </Collapse.Panel>
            </Collapse>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 44
                                }}
                                spin
                            />
                        }
                    />
                </div>
            ) : null}
            <div>
                <div className="d-flex align-items-center justify-content-space-between salesCardHolder">
                    <Card
                        className="salesCard d-flex align-items-center justify-content-center"
                        style={{ borderRadius: '20px', backgroundColor: '#304F67', height: '148px' }}
                    >
                        <div className="d-flex align-items-center justify-content-center">
                            <span
                                style={{
                                    borderRight: '1px dotted #8C9DAB',
                                    padding: '10px',
                                    marginRight: '10px'
                                }}
                            >
                                <p className="mb-2" style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px', color: '#fff' }}>
                                    Target revenue
                                </p>
                                <h4 style={{ color: '#fff', fontSize: '20px' }}>
                                    {routeData.targetrevenue} {routeData?.currency}
                                </h4>
                            </span>
                            <span className="ml-2">
                                <p className="mb-2" style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px', color: '#fff' }}>
                                    Achieved revenue
                                </p>
                                <h4 style={{ color: '#fff', fontSize: '20px' }}>
                                    {routeData.achievedrevenue} {routeData?.currency}
                                </h4>
                            </span>
                        </div>
                    </Card>
                    <Card
                        className="salesCard d-flex align-items-center justify-content-center"
                        style={{ borderRadius: '20px', backgroundColor: '#00AA55', height: '148px' }}
                    >
                        <div className="d-flex align-items-center justify-content-center">
                            <span
                                style={{
                                    borderRight: '1px dotted #80D5AA',
                                    padding: '10px',
                                    marginRight: '10px'
                                }}
                            >
                                <p className="mb-2" style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px', color: '#fff' }}>
                                    Target GMV
                                </p>
                                <h4 style={{ color: '#fff', fontSize: '20px' }}>
                                    {routeData.targetgmv} {routeData?.currency}
                                </h4>
                            </span>
                            <span className="ml-2">
                                <p className="mb-2" style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px', color: '#fff' }}>
                                    Achieved GMV
                                </p>
                                <h4 style={{ color: '#fff', fontSize: '20px' }}>
                                    {routeData.achievedgmv} {routeData?.currency}
                                </h4>
                            </span>
                        </div>
                    </Card>
                    <Card
                        className="salesCard d-flex align-items-center justify-content-center"
                        style={{ borderRadius: '20px', backgroundColor: '#4B6EFF', height: '148px' }}
                    >
                        <div className="d-flex align-items-center justify-content-center">
                            <span
                                style={{
                                    borderRight: '1px dotted #80D5AA',
                                    padding: '10px',
                                    marginRight: '10px'
                                }}
                            >
                                <p className="mb-2" style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px', color: '#fff' }}>
                                    Target retailers
                                </p>
                                <h4 style={{ color: '#fff', fontSize: '20px' }}>{routeData.targetretailers}</h4>
                            </span>
                            <span className="ml-2">
                                <p className="mb-2" style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '0px', color: '#fff' }}>
                                    Achieved retailers
                                </p>
                                <h4 style={{ color: '#fff', fontSize: '20px' }}>{routeData.achieveretailers}</h4>
                            </span>
                        </div>
                    </Card>
                </div>
                <div style={{ marginTop: '40px' }}>
                    <div className="d-flex align-items-center justify-content-space-between salesCardHolder">
                        <Card className="salesCard" style={{ borderRadius: '20px', backgroundColor: '#fff', height: '448px' }}>
                            <div className="d-flex justify-content-space-between align-items-center">
                                <p className="mb-2" style={{ color: '#546974', fontSize: '18px', fontWeight: '500', lineHeight: '28px' }}>
                                    Routes
                                </p>
                                <p style={{ color: '#FF740F', fontSize: '18px', fontWeight: '500', lineHeight: '28px' }}>
                                    {startDate !== '' || endDate !== '' ? (
                                        <span style={{ color: '#FF740F', fontSize: '14px', fontWeight: '400' }}>
                                            {startDate}-{endDate}
                                        </span>
                                    ) : (
                                        <span>{dayjs(routeData?.reportDate).format('YYYY-MM-DD')}</span>
                                    )}
                                </p>
                            </div>
                            <div className="scroll-routes">
                                {routeData?.routes && Object.keys(routeData.routes).length > 0 ? (
                                    Object.keys(routeData.routes).map((routeKey) => (
                                        <React.Fragment key={routeKey + Math.random()}>
                                            <h4 className="mb-4 mt-4" style={{ fontSize: '14px' }}>
                                                {routeKey}
                                            </h4>
                                            {routeData.routes[routeKey].map((route) => (
                                                <li
                                                    key={route._id + Math.random()}
                                                    className="mt-3 mb-2 d-flex justify-content-space-between "
                                                >
                                                    <div style={{ marginTop: '4px' }}>
                                                        <span
                                                            style={{
                                                                backgroundColor: '#C7C9CE',
                                                                padding: '7px',
                                                                borderRadius: '50%',
                                                                color: '#fff',
                                                                fontSize: '10px',
                                                                fontWeight: 'bold'
                                                            }}
                                                        >
                                                            <CallMadeIcon
                                                                style={{
                                                                    fontSize: '12px',
                                                                    color: '#fff'
                                                                }}
                                                            />
                                                        </span>
                                                        <span
                                                            style={{
                                                                marginLeft: '5px',
                                                                color: '#3B5460',
                                                                fontSize: '14px',
                                                                fontWeight: '400'
                                                            }}
                                                        >
                                                            {route.location}
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <Button
                                                            onClick={() => window.open(`/retailer/profile/${route._id}`, '_blank')}
                                                            style={{
                                                                color: `${
                                                                    route?.purchasedInfo.toLowerCase() === 'purchased'
                                                                        ? '#00AC00'
                                                                        : '#FA3246'
                                                                }`,
                                                                border: 'none',
                                                                outline: 'none',
                                                                boxShadow: 'none',
                                                                padding: '0px',
                                                                width: 'fit-content'
                                                            }}
                                                        >
                                                            {route.partnerName}
                                                        </Button>
                                                        <Button
                                                            className="ml-2"
                                                            onClick={() => window.open(`/route-order-request-form/${route._id}`, '_blank')}
                                                            style={{
                                                                color: '#FF740F',
                                                                backgroundColor: 'transparent',
                                                                border: '1px solid #FF740F',
                                                                outline: 'none',
                                                                boxShadow: 'none',
                                                                width: 'fit-content'
                                                            }}
                                                        >
                                                            Place order
                                                        </Button>
                                                    </div>
                                                </li>
                                            ))}
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <EmptyState text="No data found" width="300px" padding="15px" />
                                )}
                            </div>
                        </Card>
                        <Card className="salesCard" style={{ borderRadius: '20px', backgroundColor: '#fff', height: '448px' }}>
                            <div>
                                <div className="d-flex justify-content-space-between align-items-center ">
                                    <p style={{ color: '#546974', fontSize: '18px', fontWeight: '500', marginBottom: '0px' }}>Retailers</p>
                                    {routeData?.retailers?.length > 2 ? (
                                        <Link
                                            to="/rma-route-schedule-retailers"
                                            style={{ color: '#FF740F', fontSize: '18px', fontWeight: '500' }}
                                        >
                                            See all
                                        </Link>
                                    ) : null}
                                </div>

                                {routeData?.retailers && routeData.retailers.length > 0 ? (
                                    routeData?.retailers?.slice(0, 2).map((retailer) => (
                                        <div
                                            key={retailer._id + Math.random()}
                                            className="mb-3 mt-3"
                                            style={{ border: '1px solid #E7EAEB', padding: '10px', borderRadius: '20px' }}
                                        >
                                            <div className="d-flex align-items-center mb-2">
                                                <span
                                                    style={{
                                                        backgroundColor: '#F6F6F6',
                                                        padding: '5px',
                                                        borderRadius: '50%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '50px',
                                                        height: '50px',
                                                        color: '#8C9DAB',
                                                        fontSize: '1.1rem',
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    <UserOutlined />
                                                </span>
                                                <span style={{ marginLeft: '8px' }}>
                                                    <p
                                                        style={{
                                                            fontSize: '1.2rem',
                                                            fontWeight: 'bold',
                                                            marginBottom: '0px',
                                                            color: `${
                                                                retailer?.purchasedInfo.toLowerCase() === 'purchased'
                                                                    ? '#00AC00'
                                                                    : '#FA3246'
                                                            }`
                                                        }}
                                                    >
                                                        {' '}
                                                        {retailer.partnerName}
                                                    </p>
                                                    <small style={{ color: '#3B5460', fontSize: '14px' }}>Retailer’s name</small>
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center mb-2">
                                                <span
                                                    style={{
                                                        backgroundColor: '#F6F6F6',
                                                        padding: '10px',
                                                        borderRadius: '50%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '40px',
                                                        height: '40px',
                                                        color: '#8C9DAB',
                                                        fontSize: '0.9rem',
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    <CallIcon />
                                                </span>
                                                <span className="ml-2">
                                                    <p style={{ fontSize: '1rem', marginBottom: '0px', color: '#3B5460' }}>
                                                        {' '}
                                                        {retailer.partnerContact}
                                                    </p>
                                                </span>
                                            </div>
                                            <Button
                                                onClick={() => (window.location.href = `/retailer/profile/${retailer._id}`)}
                                                style={{
                                                    backgroundColor: 'transparent',
                                                    border: 'none',
                                                    outline: 'none',
                                                    boxShadow: 'none',
                                                    padding: '0px'
                                                }}
                                            >
                                                <div className="d-flex align-items-center ">
                                                    <span
                                                        style={{
                                                            backgroundColor: '#F6F6F6',
                                                            padding: '10px',
                                                            borderRadius: '50%',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '40px',
                                                            height: '40px',
                                                            color: '#FF740F',
                                                            fontSize: '0.9rem',
                                                            fontWeight: 'bold'
                                                        }}
                                                    >
                                                        <NorthEastIcon />
                                                    </span>
                                                    <span className="ml-2">
                                                        <p style={{ fontSize: '1rem', marginBottom: '0px', color: '#FF740F' }}>
                                                            View profile
                                                        </p>
                                                    </span>
                                                </div>
                                            </Button>
                                        </div>
                                    ))
                                ) : (
                                    <EmptyState text="No data found" width="300px" padding="15px" />
                                )}
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Index;
