/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import Button from '@mui/material/Button';
import { Link, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';

import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

// import styled from 'styled-components';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    }
}));

const ShowDistributorInventory = () => {
    // page states

    const classes = useStyles();
    const [partsInfo, setParts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - partsInfo.length) : 0;

    const { id } = useParams();

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };
        setLoading(true);
        fetch(ApiUrl.InventoryMoveUrl + id, config)
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    // console.log('inventory', data.data);
                    setParts(data.data);
                } else {
                    toast.error(data.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    return (
        <>
            <Grid item xs={12} sm={12}>
                <MainCard title="Inventory History">
                    <br />
                    <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                        <Button component={Link} to="/distributorInventory" type="submit" variant="contained" color="error">
                            Back
                        </Button>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table aria-label="caption table">
                            <TableHead>
                                <TableRow>
                                    {loading ? (
                                        <TableCell component="th" colSpan="4" align="center">
                                            {' '}
                                            <CircularProgress size={20} thickness={4} />
                                            {'LOADING...... '}
                                        </TableCell>
                                    ) : (
                                        <>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Sku No.
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Sku Name
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Qty
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Date
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Description
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Wholesale Price
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Garage Price
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Order Id
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Buyer
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0 ? partsInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : partsInfo).map(
                                    (row) => (
                                        <TableRow key={row._id}>
                                            <TableCell component="th" scope="row">
                                                {row?.partId?._id ?? ''}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row?.partId?.sku ?? ''}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {Number.isFinite(row?.qty) ? Number(row?.qty).toFixed() : 0}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {toTimestamp(row.createdAt)}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {row?.description ?? ''}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {Number.isFinite(row?.wholeSalePrice) ? Number(row?.wholeSalePrice).toFixed(2) : 0}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {Number.isFinite(row?.garagePrice) ? Number(row?.garagePrice).toFixed(2) : 0}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row?.orderId ?? ''}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row?.channelPartnerId?.partnerName ?? ''}
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        component="div"
                        count={partsInfo.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </MainCard>
            </Grid>
        </>
    );
};

export default ShowDistributorInventory;
