import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useEffect, useState } from 'react';

const paga = '../../imagesPage.png';
const bank = '../../bank2.png';
const money = '../../money-send.png';
const id = localStorage.getItem('paymentId-orderId');

export const Index = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const [option, setOption] = useState({});
    const location = useLocation();
    const paymentData = location.state?.paymentData;
    const navigate = useNavigate();

    useEffect(() => {
        let isMounted = true;
        return () => {
            isMounted = false;
        };
    }, [paymentData]);

    const handleOptionChange = (selectedOption) => {
        setSelectedOption(selectedOption.name);
        setOption(selectedOption);
    };

    const handleProceed = () => {
        const routes = {
            BANK_TRANSFER: '/bank/payment-summary',
            MONEY_TRANSFER: '/bank/payment-summary',
            AGENT_DEPOSIT: '/bank/payment-summary',
            REQUEST_MONEY: '/money/payment-summary',
            FUNDING_USSD: '/ussd/payment-summary'
        };

        const route = routes[selectedOption];
        if (route) {
            navigate(route, { state: { option } });
        }
    };

    return (
        <div>
            <div className="m-header">
                <Link to={`/payment-initiation/${id}`}>
                    <h1 style={{ color: '#0A2938', display: 'flex', alignItems: 'center' }}>
                        <ArrowLeftOutlined className="mr-2" /> Select payment options
                    </h1>
                </Link>
            </div>
            <div className="m-payment-option-container">
                <ul>
                    {paymentData &&
                        paymentData?.payment_methods?.map((option, index) => (
                            <li key={index} className={selectedOption === option.name ? 'selected' : ''}>
                                <label htmlFor={option.name}>
                                    <input
                                        type="radio"
                                        name="paymentOption"
                                        value={option.name}
                                        onChange={() => handleOptionChange(option)}
                                    />
                                    <img
                                        src={option.name === 'BANK_TRANSFER' ? bank : option.name === 'MONEY_TRANSFER' ? money : paga}
                                        alt={option.name}
                                    />
                                    {option.name}
                                </label>
                            </li>
                        ))}
                </ul>
                <div className="m-payment-option-button">
                    <Button
                        className="m-payment-option-button"
                        disabled={!selectedOption}
                        style={{
                            backgroundColor: selectedOption ? '#FF740F' : '#E0E0E0',
                            color: '#fff',
                            border: 'none'
                        }}
                        onClick={handleProceed}
                    >
                        Proceed
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Index;
