/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import PageHeader from '../../../components/PageHeader';
import Box from '@mui/material/Box';
import Controls from '../../../components/controls/Controls';
import { Search } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
// import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import nologo from 'assets/images/nologo.png';

// import styled from 'styled-components';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 3
    },
    backgroundColor: {
        backgroundColor: '#FF740F'
    }
}));

const PartsOrderedFleet = () => {
    // page states

    const classes = useStyles();
    // const history = useNavigate();
    // const scriptedRef = useScriptRef();
    const [partsInfo, setParts] = useState([]);
    const [partsData, setpartsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const commonStyles = {
        bgcolor: 'background.paper',
        m: 2,
        border: 4
    };

    const retrieveAllPartsCatalog = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.PartsCatalogUrlFleet, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        console.log('parts', data.data);
                        setParts(data.data);
                        setpartsData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        retrieveAllPartsCatalog();
    }, []);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - partsInfo.length) : 0;

    const handleSearch = (event) => {
        const searchWord = event.target.value;

        const newFilter = partsInfo.filter((value) => value.partid.toLowerCase().includes(searchWord.toLowerCase()));

        if (searchWord === '') {
            setParts(partsData);
        } else {
            setParts(newFilter);
        }
    };

    return (
        <>
            <PageHeader title="Fleet Management| Parts Catalog" subTitle="Home | Fleet Management | Parts Catalog" />

            <Box display="flex" justifyContent="space-between">
                <Grid>
                    <Controls.Input
                        label="Search Product"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearch}
                    />
                </Grid>
                <Box width={10} />

                <Button component={Link} to="/addpartorderedfleet" variant="contained" size="sm" className={classes.backgroundColor}>
                    Order new part
                </Button>
            </Box>
            <br />
            <Grid container spacing={2} justify="flex-start">
                {loading ? (
                    <TableCell component="th" colSpan="4" align="center">
                        {' '}
                        <CircularProgress size={20} thickness={4} />
                        {'LOADING...... '}
                    </TableCell>
                ) : (
                    <>
                        {(rowsPerPage > 0 ? partsInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : partsInfo).map(
                            (row) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={row._id}>
                                    <Box sx={{ ...commonStyles, borderColor: '#FF740F' }}>
                                        <Card align="center">
                                            {/* <CardMedia component="img" height="194" image={row.partimage ?? nologo} alt={row.partid} /> */}
                                            <Avatar variant="rounded" src={row.partimage ?? nologo} sx={{ width: 120, height: 100 }} />

                                            <br />
                                            <Grid item sm container direction="column">
                                                <Typography variant="subtitle1" component="div">
                                                    {row.partid}
                                                </Typography>
                                                <Typography variant="body2" gutterBottom>
                                                    Quantity: {row.qty}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Status: {row.paymentStatusId}
                                                </Typography>
                                            </Grid>
                                        </Card>
                                    </Box>
                                </Grid>
                            )
                        )}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                        <Grid item xs={12} sm={6} md={4} lg={12}>
                            <TablePagination
                                rowsPerPageOptions={[50, 70, 150, { label: 'All', value: -1 }]}
                                component="div"
                                count={partsInfo.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
};

export default PartsOrderedFleet;
