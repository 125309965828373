// import dashboard from './dashboard';

import customerSuccessIndex from './customersuccess';
import other from './other';
// import ordersreporting from './ordersreport';

// ==============================|| MENU ITEMS dashboard ||============================== //

const customersuccessItems = {
    items: [customerSuccessIndex, other]
};

export default customersuccessItems;
