import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Button, Select, Progress, Input } from 'antd';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { decrypt } from '../../../utils/encrypt';
import { toast } from 'react-toastify';
import 'react-circular-progressbar/dist/styles.css';
import { Link, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ApiUrl from '../../../network';

const actionHandler = (record) => {
    window.location.href = `/salesplan/edit/${record.key}`;
};

export const Index = () => {
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [order, setOrder] = useState({});
    const { id } = useParams();
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    const fetchOrder = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/waybill/viewbulkorderdetails/${id}`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setOrder(result);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        toast.error(error.message);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        if (id) {
            fetchOrder();
        } else {
            window.location.href = '/waybill';
            toast.error('No order found');
        }
    }, []);

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Link to="/waybill/orders" className="createScheduleLink">
                    <p style={{ fontSize: '25px', marginLeft: '10px' }}>
                        <ArrowBackIcon sx={{ fontSize: '25px', color: '#000' }} /> Order Details
                    </p>
                </Link>
            </div>
            <div className="bg-white p-4" style={{ textAlign: 'center' }}>
                <div className="batchFlex">
                    <div>
                        <div className="mb-4">
                            <Input value={`Order No: ${order?.orderNo}`} disabled placeholder="orderNo" className="p-2 inputViewKpi" />
                        </div>
                        <div className="mb-4">
                            <Input
                                value={`Name: ${order?.placedBy?.firstName} ${order?.placedBy?.lastName}`}
                                disabled
                                placeholder="Name"
                                className="p-2 inputViewKpi"
                            />
                        </div>
                        <div className="mb-4">
                            <Input value={`Country: ${order?.country}`} disabled placeholder="Country" className="p-2 inputViewKpi" />
                        </div>
                        <div className="mb-4">
                            <Input value={`Pod: ${order?.pod}`} disabled placeholder="Pod" className="p-2 inputViewKpi" />
                        </div>
                        <div className="mb-4">
                            <Input value={`Status: ${order?.status}`} disabled placeholder="POD" className="p-2 inputViewKpi" />
                        </div>
                    </div>
                    <div className="orderContainer" style={{ borderRadius: '10px' }}>
                        <div className="d-flex justify-content-between align-items-center">
                            <p style={{ fontSize: '14px', color: '#546974' }}>Product Name</p>
                            <p style={{ fontSize: '14px', color: '#546974' }}>Quantity</p>
                        </div>
                        <div className="productsContainer">
                            {order?.items?.map((product, index) => (
                                <div key={product._id} className="d-flex justify-content-between align-items-center mb-2">
                                    <div>
                                        <img
                                            className="mr-2"
                                            src={product.partimage}
                                            alt=""
                                            style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '10px' }}
                                        />
                                        <span>{product.brand}</span>
                                    </div>
                                    <div>
                                        <span>{product.qty}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Index;
