import CustomPageHeader from 'components/CustomPageHeader';
import { useAppContext } from 'context/appContext';
import CollectionAccordion from './Collections/CollectionAccordion';
import DepositsAccordion from './Deposits/DepositsAccordion';
import LeadAccordion from './Leads/LeadAccordion';
import RetailerAccordion from './Retailers/RetailerAccordion';
import SalesAccordion from './Sales/SalesAccordion';
import { CloseCircleOutlined, ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, message, Progress, Select, DatePicker, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import { decrypt } from '../../utils/encrypt';
import { toast } from 'react-toastify';
import ApiUrl from 'network';

const Accordion = () => {
    const { RangePicker } = DatePicker;
    const { salesAssociate, ranks } = useAppContext();
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [data, setData] = useState([]);
    const [clearnceStatus, setClearnceStatus] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoScroll: true
    };

    const fetchData = async (data) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const urlParams = new URLSearchParams(window.location.search);
            let url = 'https://api.garagemobility.com/api/mobiledashboard/dashboard';

            if (data) {
                if (data.startDate) urlParams.append('startDate', data.startDate);
                if (data.endDate) urlParams.append('endDate', data.endDate);
            }
            url = `${url}?${urlParams.toString()}`;
            await fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setLoading(false);
                        setData(data);
                    } else {
                        setLoading(false);
                        const error = decrypt(data.data, keyResult, ivResult);
                        toast.error(error);
                    }
                });
        } catch (e) {
            setLoading(false);
            toast.error(e.message);
        }
    };

    // get all cleared orders
    const getClearedListOrders = async (data) => {
        try {
            setLoading(true);
            const url = `${ApiUrl.BaseUrl}/collection/clearanceSheet`;
            const userId = localStorage.getItem('userId');
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        const filteredRma = answer?.filter((rma) => {
                            return rma?._id === userId;
                        });
                        setClearnceStatus(filteredRma[0]?.balStatus);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchData();
        getClearedListOrders();
    }, []);

    const handleFilter = async () => {
        const startDate = dateRange[0]?.format('YYYY-MM-DD');
        const endDate = dateRange[1]?.format('YYYY-MM-DD');
        const data = {
            startDate,
            endDate
        };
        fetchData(data);
    };
    const clearFilterHandler = async () => {
        setDateRange([null, null]);
        fetchData();
    };

    return (
        <>
            <CustomPageHeader
                title="Relationship Management | Dashboard"
                subtitle="Home | Relationship Management | Dashboard"
                currentUser={salesAssociate}
                rank={ranks}
                clearnceStatus={clearnceStatus}
            />
            <div className="d-flex justify-content-between align-items-center filterHeaders">
                <div className="d-flex filterHeaders">
                    <RangePicker
                        value={dateRange}
                        onChange={(date) => setDateRange(date)}
                        className=" mr-2 rangePicker"
                        placeholder={['Start date', 'End date']}
                        style={{ width: '300px' }}
                    />
                    <Button
                        className="mr-2 d-flex align-items-center btn"
                        style={{ backgroundColor: '#FF740F', color: '#fff' }}
                        type="primary"
                        onClick={() => handleFilter()}
                        loading={loading}
                    >
                        Apply filters <SearchOutlined />
                    </Button>
                    {dateRange && dateRange[0] && dateRange[1] && (
                        <Button
                            style={{ backgroundColor: '#fff', color: '#FF740F', borderColor: '#FF740F' }}
                            className="d-flex align-items-center"
                            type="primary"
                            onClick={() => clearFilterHandler()}
                        >
                            Clear filters <CloseCircleOutlined />
                        </Button>
                    )}
                </div>
                {data?.top5parts?.length > 0 ? (
                    <Button
                        style={{
                            color: '#FF740F',
                            fontWeight: 'bold',
                            backgroundColor: 'transparent',
                            border: 'none',
                            outline: 'none',
                            textDecorationLine: 'underline'
                        }}
                        onClick={() => setVisible(true)}
                        className="w-25"
                    >
                        View Top Selling Products
                    </Button>
                ) : null}
            </div>
            <div className="mt-4 mb-4 overviewData">
                <div
                    className="flex-grow-1 cardData"
                    style={{ backgroundColor: '#FFECC2', borderRadius: '10px', padding: '20px', width: '100%' }}
                >
                    <div className="text-left">
                        <p className="ml-2" style={{ fontSize: '24px', fontWeight: 'bold', color: '#546974' }}>
                            {data?.totaltransactiontoday}
                        </p>
                        <p className="ml-2" style={{ fontSize: '20px', color: '#546974' }}>
                            Total Today Transactions
                        </p>
                    </div>
                </div>
                <div
                    className="flex-grow-1 ml-2 cardData"
                    style={{ backgroundColor: '#C8D3FE', borderRadius: '10px', padding: '20px', width: '100%' }}
                >
                    <div className="text-left">
                        <p className="ml-2" style={{ fontSize: '24px', fontWeight: 'bold', color: '#546974' }}>
                            {localStorage.getItem('userCurrency')} {data?.todaygmv?.toFixed(2)}
                        </p>
                        <p className="ml-2" style={{ fontSize: '20px', color: '#546974' }}>
                            Total Today GMV
                        </p>
                    </div>
                </div>
                <div
                    className="flex-grow-1 ml-2 cardData"
                    style={{ backgroundColor: '#9ee1ff', borderRadius: '10px', padding: '20px', width: '100%' }}
                >
                    <div className="text-left">
                        <p className="ml-2" style={{ fontSize: '24px', fontWeight: 'bold', color: '#546974' }}>
                            {localStorage.getItem('userCurrency')} {data?.todayrevenue?.toFixed(2)}
                        </p>
                        <p className="ml-2" style={{ fontSize: '20px', color: '#546974' }}>
                            Total Today Revenue
                        </p>
                    </div>
                </div>
            </div>
            <LeadAccordion />
            <RetailerAccordion />
            <SalesAccordion />
            <CollectionAccordion />
            <DepositsAccordion />
            <Modal width={600} title="Top Selling Products" open={visible} onCancel={() => setVisible(false)} footer={null}>
                <div className="topProducts">
                    {data?.top5parts?.map((item, index) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    backgroundColor: '#ffffff',
                                    border: '#546974',
                                    borderRadius: '10px',
                                    padding: '20px'
                                }}
                            >
                                <img
                                    src={item?.partimage}
                                    alt=""
                                    style={{ width: '150px', height: '100px', objectFit: 'cover', borderRadius: '10px' }}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src =
                                            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARMAAAC3CAMAAAAGjUrGAAAAMFBMVEXx8/XCy9K/yND09vfw8vTP1tzp7O/i5ure4+fO1dvJ0dfT2d/EzNPt7/Lb4OXo6+4FeM7UAAAFL0lEQVR4nO2c24KrIAxFLdha7///t0dxOlWDSiAKztnrbR4G6SoJBKHZA6zJYncgQeCEAicUOKHACQVOKHBCgRMKnFDghAInFDihwAkFTihwQoETCpxQ4IQCJxQ4ocAJBU4ocEKBEwqcUOCEAicUOKHACQVOKHBCgRMKnFDghAInFDihwAkFTihwQoETCpxQ4IQCJxQ4ocAJBU4ot3Oi1KMq64FnWTVq+EueWzlRquqKVn/J+/ezEfdyHydKPYtc62yF1m1Xymq5ixPVdDnx8eslf1eCVu7hRFXFppAfLW39kNJyByeqOTJirGTvRsbKDZyozsHIpKUQsZK8E1Vu55GTrKTuRL0ZRoyVLviZaTtRVctUMuaVOnCoJO1E1WwjxsorbGZO2Qk7br5WuhApKTvpfZWMy5WAoZKuk6b1NhI4VJJ10uRBSsas0ng+OlUnVaARw9NvqCTqRERJpt9eUtJ0IqPEN36SdNIIKRnIPeafFJ0Ep9c5mr+qTdFJ2CRMpLAn5fScqJeokrFWZkoRdaImwtpw2T9iSnnxuiDoRFXda6hK28JzWTA14ryBxKFlTT9iTlT1W57o3Lta96yED8krRieknCw/DDuEP1TnKBlgzMlCTtZDXr+8pIjOwitK5x7JOKFD3mukiE85ix45S5FxYll46prdiv8ekpsU19wv4kS9LV1ouQPlrPzKliIzTuw9YDYiVfgFSxFx8rR+wcyMomSX9HYpTjlFwonqrB3gBc/JyYQjRcRJYe8Ay4l9rMlLcVi8iTjp7Y/nOBHcMjngWEoi4+TUlcmKw9rnxHzCWMqeU/ltkB9JEZl3SusnYmwQn1fm2GgPeiOzZrM9WZfu/3/BNDznYATLOLENffep+JppeMZBMSZUF9N6ljFM7KF3qpTduBZyQj4W53XTiRsEm1L2dr2k9k9W9Rtjq2BrJj9Zyk7pI7bP9lw8kfH+4KIFLGF77Sa3R90Un0POvHNCcYzsLVMk9+2buni1bd9xjMSJHMPmjCz7zov/fidW5GQ7OS/2e8BoRrLtrBfXScTIMVLsk09cJxEjZ8I6+cR1EmG1tsRaDsZ0EjlyDL0leuxOpulD4JTALtfXORRbnqVO1LDOePdtpoclWPsqulL+wt0P0SNnxFKrrp2opmuXl+5OuHA3PSmByDGQ9ezSydYdM+ELd4YUIsdANnoWTva2RSUv3JlnJRE5I2RbY+6kee1+dTrrhC7cPTZeMUdivZnydaIc3tdqqWuI6USOYZlSfp0oxzVlJxNByUSOYZlSPk6cDzqEXy17JDTn/LBMKRlTSRZ4X2giep2zZnEwZHLiGjifFt6BTtKKHMMspUxO2BkvDzoDm1jkGGa7bsaJx0t9XfgrOfuMlhezwsc48RrKufvhyiXXHatg8T2Zkm0eHzluxO8W4pXHKljkXycBt3h9blFdeqyCx2fPOguLbn6qTWsBu+Czxs/CopsdP4kmkx+mcZ8FRrfuWUqSTSYT005keDucW4iXnzRhMg17iYacC6A0VyZzzIQs0pBrUrn22JoXY4Us0pDjaZMzb+dIMX6/Qi0dHSU0XHySz48heqSaOs60vsvlq2mtpzj9OCh/Trgjew7afgLar63d6ec2SmTZm37+UyV7048K+Gmkm7O10A/8aaSbY7sEr8rYvYoNnX4Sr3EuYJVpVc35Ccu/innZbryMJ1n4v9f4N9FZ39XPZ931GYzMGH9VPHYfAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADp8Q9+nG9anuOrfAAAAABJRU5ErkJggg==';
                                    }}
                                />
                                <p className="mt-2" style={{ fontSize: '14px', fontWeight: 'bold', color: '#546974' }}>
                                    {item?.part}
                                </p>
                                <p className="mt-2" style={{ fontSize: '14px', color: '#546974' }}>
                                    Qty: {item?.totalqty} - {localStorage.getItem('userCurrency')} {item?.totalSalesAmount}
                                </p>
                            </div>
                        );
                    })}
                </div>
            </Modal>
        </>
    );
};
export default Accordion;
