import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import Autocomplete from '@mui/material/Autocomplete';
import useScriptRef from 'hooks/useScriptRef';

const AddCarrier = () => {
    const [name, setName] = useState('');
    const [carrierRating, setRating] = useState('');
    const [carrierContact, setContact] = useState('');
    const [countries, setCountries] = useState([]);
    const [carrierNPS, setNPS] = useState('');
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [country, setCountry] = useState('');
    const [loading, setLoading] = useState(false);

    // const [status, setStatus] = useState('');

    const retrieveAllActiveCountry = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            // setLoading(true);
            fetch(ApiUrl.ActiveCountryUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        retrieveAllActiveCountry();
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        const data = {
            name,
            carrierRating,
            carrierContact,
            carrierNPS,
            country
        };

        fetch(ApiUrl.AddCarrierUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    if (scriptedRef.current) {
                        setTimeout(() => {
                            history('/carrier');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <br />
                    <PageHeader title="Carriers" subTitle="Home | Configuration | Carrier" />
                    <br />
                    <MainCard title="Add Carrier">
                        <form onSubmit={handleSubmit}>
                            <TextField
                                autoComplete="off"
                                name="name"
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                label="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                autoFocus
                            />
                            <br />
                            <br />
                            <TextField
                                autoComplete="off"
                                name="carrierContact"
                                variant="outlined"
                                required
                                fullWidth
                                id="carrierContact"
                                label="Contact"
                                value={carrierContact}
                                onChange={(e) => setContact(e.target.value)}
                            />
                            <br />
                            <br />
                            <TextField
                                autoComplete="off"
                                name="carrierRating"
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                label="Rating"
                                value={carrierRating}
                                onChange={(e) => setRating(e.target.value)}
                            />
                            <br />
                            <br />
                            <TextField
                                autoComplete="off"
                                name="carrierNPS"
                                variant="outlined"
                                required
                                fullWidth
                                id="carrierNPS"
                                label="NPS"
                                value={carrierNPS}
                                onChange={(e) => setNPS(e.target.value)}
                            />
                            <br />
                            <br />

                            <Autocomplete
                                id="country-select-demo"
                                autoHighlight
                                value={country}
                                onChange={(event, newValue) => {
                                    setCountry(newValue);
                                }}
                                options={countries.map((option) => option.name)}
                                isOptionEqualToValue={(option, value) => option.code === value.code}
                                renderInput={(params) => <TextField {...params} label="Choose a country" />}
                            />
                            <br />
                            <br />
                            <Box display="flex" justifyContent="space-between">
                                <Button component={Link} to="/carrier" type="submit" variant="contained" color="error">
                                    Reset
                                </Button>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    disabled={!name || !carrierContact || !carrierRating || !carrierNPS || loading}
                                >
                                    {loading ? 'Submitting ..' : 'Submit'}
                                </Button>
                            </Box>
                        </form>
                    </MainCard>
                </Grid>
            </Grid>
        </>
    );
};

export default AddCarrier;
