/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
// import Controls from '../../components/controls/Controls';
// import { Search } from '@mui/icons-material';
// import InputAdornment from '@mui/material/InputAdornment';
// import Grid from '@mui/material/Grid';
import * as XLSX from 'xlsx';
import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import ViewIcon from '@mui/icons-material/ViewAgenda';
// import IconButton from '@mui/material/IconButton';
// import { Link, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
// import swal from 'sweetalert';
// import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
// import axios from 'axios';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Avatar from '@mui/material/Avatar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';

// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
// import NoteAltIcon from '@mui/icons-material/NoteAlt';
// import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
// import SortIcon from '@mui/icons-material/Sort';
// import * as blobUtil from 'blob-util';
// import { Camera } from 'react-camera-pro';
import { useTheme } from '@mui/material/styles';
// import { saveAs } from 'file-saver';
// import axios from 'axios';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    colors: {
        color: `#ED7C1B`
    }
}));

const NewRetailer = () => {
    const classes = useStyles();
    const theme = useTheme();

    const [orderInfo, setOrder] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    // const [startDate, setstartDate] = useState(new Date());
    // const [endDate, setendDate] = useState(new Date());
    // const [loading2, setLoading2] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searched, setSearched] = useState('');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    console.log(orderData);

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderInfo.length) : 0;

    const retrieveAllOrders = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.NewRetailerInfoUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrder(data.data);
                        setOrderData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handledateChange = (event, picker) => {
        // console.log('startDate', picker.startDate._d);
        // console.log('endDate', picker.endDate._d);
        setstartDate(picker.startDate._d);
        setendDate(picker.endDate._d);
        // FilterOrdersByDateUrl
        const userInfo = localStorage.getItem('userToken');
        const from = picker.startDate._d;
        const to = picker.endDate._d;
        setLoading(true);
        const data = {
            from,
            to
        };

        fetch(ApiUrl.FilterNewRetailerUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);

                    setLoading(false);
                    setOrder(result.data);
                    setOrderData(result.data);
                    // if (scriptedRef) {
                    //     setTimeout(() => {
                    //         history('/order');
                    //     }, 3000);
                    // }
                } else {
                    toast.error(result.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handledataexport = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(orderInfo);
        XLSX.utils.book_append_sheet(wb, ws, 'NewRetailers');
        XLSX.writeFile(wb, 'NewRetailers.xlsx');
    };

    useEffect(() => {
        retrieveAllOrders();
    }, []);

    const filteredRows = orderInfo.filter((row) => row.channelPartnerId.toLowerCase().includes(searched.toLowerCase()));
    const isPlural = filteredRows && filteredRows.length > 1 ? 's' : '';
    const searchCount = searched ? `${filteredRows.length} result${isPlural} found` : 'No Results';

    return (
        <>
            <PageHeader title="New Retailers" subTitle="Dashboard | New Retailers " />

            <MainCard title="New Retailers List">
                <h4 style={{ color: '#ff740f', fontWeight: '700' }}>
                    {searched ? searchCount : `Total New Retailers: ${orderInfo.length}`}
                </h4>
                <br />

                <Box display="flex" justifyContent="flex-start">
                    <Box width={180}>
                        <FormControl fullWidth>
                            <TextField
                                id="outlined-basic"
                                label="Search Partner Name"
                                variant="outlined"
                                onChange={(e) => setSearched(e.target.value)}
                            />
                        </FormControl>
                    </Box>
                    <Box width={20} />
                    <Box display="flex" justifyContent="flex-start">
                        <DateRangePicker initialSettings={{ startDate, endDate }} onApply={handledateChange}>
                            <Button variant="contained" size="sm" className={classes.item}>
                                filter by Date
                            </Button>
                        </DateRangePicker>
                    </Box>
                    <Box width={10} />
                    <Button onClick={handledataexport} variant="contained" size="sm" className={classes.backgroundColor}>
                        Download Excel
                    </Button>
                </Box>
                <br />

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order Date
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Channel Partner
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            RM
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Exp.Cash
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Cash Collected
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Cash Outstanding
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order Status
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Receipt Status
                                        </TableCell>
                                        {/* 
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell> */}
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : filteredRows
                            ).map((row, index) => (
                                <TableRow key={row._id}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {toTimestamp(row?.orderDate)}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row.channelPartnerId}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row.salesname}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {Number.isFinite(row?.expCashCollected) ? Number(row?.expCashCollected).toFixed(2) : 0}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {Number.isFinite(row?.actCashCollected) ? Number(row?.actCashCollected).toFixed(2) : 0}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {Number.isFinite(row?.cashOutStanding)
                                            ? Number(row?.cashOutStanding).toFixed(2)
                                            : Number(row?.expCashCollected).toFixed(2)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.fulStatus === 'Delivered' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.success.light,
                                                    color: theme.palette.success.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row?.fulStatus === 'Cancelled' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.error.light,
                                                    color: theme.palette.error.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <DangerousRoundedIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row?.fulStatus === 'Received' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.primary.light,
                                                    color: theme.palette.primary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row?.fulStatus === 'Dispatched' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.warning.light,
                                                    color: theme.palette.warning.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row?.fulStatus === 'Processing' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.warning.light,
                                                    color: theme.palette.warning.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row?.fulStatus === 'ProformaInvoice' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row?.fulStatus === 'SalesDrive' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row?.fulStatus === 'OnHold' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row?.fulStatus === 'NextDay' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row.newOrderStatusId._id === '6228632de5b218f4be037d70' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.success.light,
                                                    color: theme.palette.success.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row.newOrderStatusId._id === '622b9bf0f3bcbc9eb0c203ea' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.error.light,
                                                    color: theme.palette.error.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <DangerousRoundedIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row.newOrderStatusId._id === '620d76d63a9c8148935c696b' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.primary.light,
                                                    color: theme.palette.primary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row.newOrderStatusId._id === '625311abbd74072935e58592' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row.newOrderStatusId._id === '625739679a0af3ae9771daef' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.error.light,
                                                    color: theme.palette.error.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <DangerousRoundedIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row.newOrderStatusId._id === '622b9bcdf3bcbc9eb0c203e2' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.warning.light,
                                                    color: theme.palette.warning.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row.newOrderStatusId._id === '6334bab7afa1158e678a87ad' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row.newOrderStatusId._id === '6342dc27f627d58acac98e0f' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row.newOrderStatusId._id === '636c1abb26ac75c9880bddd6' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row.newOrderStatusId._id === '636c1ab126ac75c9880bddd2' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[20, 30, 50, { label: 'All', value: -1 }]}
                    component="div"
                    count={orderInfo.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default NewRetailer;
