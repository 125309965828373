import { Link, useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ApiUrl from '../../../../network';
import { decrypt, encrypt } from '../../../../utils/encrypt';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [partsDiscount, setPartsDiscount] = useState([
        {
            id: '1',
            discountid: '',
            partid: '',
            sku: '',
            qty: '',
            discount: '',
            status: '',
            price: ''
        }
    ]);

    const fetchPartDiscount = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/parts/v2/viewpartsdiscount/${id}`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                const updatedPartsDiscount = decryptedData.map((item) => ({
                    id: item._id,
                    discountid: item._id,
                    partid: item.partIdgh._id,
                    sku: item.sku,
                    qty: item.qty,
                    discount: item.discount,
                    status: item.status,
                    price: item.price
                }));
                setPartsDiscount(updatedPartsDiscount);
                setLoading(false);
            } else {
                toast.error(result.message);
                setLoading(false);
            }
        } catch (e) {
            toast(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPartDiscount();
    }, [id]);

    const handleInputChange = (index, field, value) => {
        const values = [...partsDiscount];
        values[index][field] = value;
        setPartsDiscount(values);
    };

    const updateDiscountHandler = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const datas = {
                discounts: partsDiscount.map((part) => ({
                    discountid: part.discountid,
                    partid: part.partid,
                    sku: part.sku,
                    qty: part.qty,
                    discount: part.discount,
                    status: part.status,
                    price: part.price
                }))
            };
            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            const url = `${ApiUrl.BaseUrl}/parts/v2/updatediscounts`;
            const response = await fetch(url, {
                method: 'PUT',
                headers: config.headers,
                body: JSON.stringify({ payload })
            });
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                toast.success(result.message);
                fetchPartDiscount();
                setLoading(false);
            } else {
                toast.error(result.message);
                setLoading(false);
            }
        } catch (e) {
            toast.error(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="m-header">
                <div>
                    <Link to={`/parts/discount/${id}`}>
                        <h1 style={{ color: '#0A2938', display: 'flex', alignItems: 'center' }}>
                            <ArrowLeftOutlined className="mr-2" /> Edit Discount
                        </h1>
                    </Link>
                </div>
                <div>
                    <Button
                        style={{
                            backgroundColor: 'transparent',
                            color: '#FF740F'
                        }}
                        onClick={() => (window.location.href = `/parts/discount/${id}`)}
                        className="w-fit border-primary-m"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={partsDiscount.length === 0}
                        onClick={updateDiscountHandler}
                        loading={loading}
                        className="w-fit primary-bg  text-white ml-2"
                    >
                        Save changes
                    </Button>
                </div>
            </div>
            <div className="m-content m-discount mt-4">
                {partsDiscount.length > 0 ? (
                    partsDiscount.map((item, index) => (
                        <div key={item.id} className="mb-4 discount-content">
                            <div className="d-flex">
                                <div className="form-group">
                                    <p>SKU</p>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={item.sku}
                                        onChange={(e) => handleInputChange(index, 'sku', e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <p>Quantity</p>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={item.qty}
                                        onChange={(e) => handleInputChange(index, 'qty', e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="form-group">
                                    <p>Discount</p>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={item.discount}
                                        onChange={(e) => handleInputChange(index, 'discount', e.target.value)}
                                        disabled={item.price ? item.price !== '' : false}
                                    />
                                </div>
                                <div className="form-group">
                                    <p>Price</p>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={item.price}
                                        onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                                        disabled={item.discount ? item.discount !== '' : false}
                                    />
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="form-group">
                                    <p>Status</p>
                                    <Select
                                        value={item.status}
                                        style={{ width: '100%' }}
                                        onChange={(value) => handleInputChange(index, 'status', value)}
                                        options={[
                                            { value: 'Active', label: 'Active' },
                                            { value: 'InActive', label: 'InActive' }
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="text-center">
                        <h3>
                            No discount available for this part.{' '}
                            <Link style={{ color: '#FF740F' }} to={`/parts/discount/${id}`}>
                                Go back
                            </Link>
                        </h3>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Index;
