import { useAppContext } from 'context/appContext';
import { useRetailerContext } from 'context/retailer-shop/retailer-context';
import styled from 'styled-components';

const Wrapper = styled.div`
    background: #fff;
    color: #222;
    padding: 1rem 2rem;
    border-radius: 10px;
    font-family: 'Roboto';
    margin-bottom: 30px;

    h4 {
        font-size: 1.1rem;
        color: #8b8b8b;
        margin-bottom: 30px;
    }
    tr td {
        padding: 1rem;
    }
`;

const TopPartsTable = () => {
    const { userCurrency } = useAppContext();
    const { topfiveparts } = useRetailerContext();
    return (
        <Wrapper className="shadow-sm">
            <h4>Top 5 parts ordered of all time </h4>
            <div className="table-responsive">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Part Name</th>
                            <th>Number of Quantity</th>
                            <th>Order Value ({userCurrency})</th>
                        </tr>
                    </thead>
                    <tbody>
                        {topfiveparts.map((item, index) => {
                            const { partName, orderValueAmount, totalQty } = item;
                            return (
                                <tr key={index}>
                                    <td>{partName}</td>
                                    <td>{totalQty}</td>
                                    <td>{orderValueAmount.toFixed(2)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </Wrapper>
    );
};
export default TopPartsTable;
