// assets
import { IconKey, IconTruckDelivery } from '@tabler/icons';
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import InventoryIcon from '@mui/icons-material/Inventory';
// import ReportIcon from '@mui/icons-material/Report';
// import CarRepairIcon from '@mui/icons-material/CarRepair';
import Person from '@mui/icons-material/Person';
import CarCrashIcon from '@mui/icons-material/CarRental';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import InventoryIcon from '@mui/icons-material/Inventory';
// import Scale from '@mui/icons-material/Scale';
// import Shop from '@mui/icons-material/Shop2';
// import Trans from '@mui/icons-material/Money';
// import PriceCheckIcon from '@mui/icons-material/PriceCheck';
// constant
const icons = {
    IconKey,
    IconTruckDelivery,
    DisplaySettingsIcon,
    Person,
    CarCrashIcon,
    InventoryIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //
const userRole = localStorage.getItem('userRole');
const pages = {
    id: 'pages',
    title: 'Configuration',
    type: userRole,
    children: [
        {
            id: 'user',
            title: 'User Management',
            type: 'collapse',
            icon: icons.DisplaySettingsIcon,

            children: [
                {
                    id: 'role',
                    title: 'Roles',
                    type: 'item',
                    url: '/roles'
                },
                {
                    id: 'users',
                    title: 'User',
                    type: 'item',
                    url: '/users'
                }
            ]
        },
        {
            id: 'channelpartners',
            title: 'Channel Partners',
            type: 'collapse',
            icon: icons.Person,

            children: [
                {
                    id: 'retailers',
                    title: 'Retail',
                    type: 'item',
                    url: '/retail'
                },
                // {
                //     id: 'distributor',
                //     title: 'Distributor',
                //     type: 'item',
                //     url: '/distributor'
                // },
                {
                    id: 'mechanic',
                    title: 'Mechanic',
                    type: 'item',
                    url: '/mechanic'
                },
                {
                    id: 'fleetpartner',
                    title: 'Fleet',
                    type: 'item',
                    url: '/fleetpartner'
                },
                {
                    id: 'channel',
                    title: 'Channel',
                    type: 'item',
                    url: '/channel'
                }
            ]
        },
        {
            id: 'salesplanner',
            title: 'Sales Planner',
            type: 'collapse',
            icon: icons.DisplaySettingsIcon,

            children: [
                {
                    id: 'targets',
                    title: 'Target',
                    type: 'item',
                    url: '/targetlist'
                },
                {
                    id: 'todolist',
                    title: 'TodoList',
                    type: 'item',
                    url: '/todolist'
                }
            ]
        },
        {
            id: 'team',
            title: 'Team',
            type: 'collapse',
            icon: icons.Person,

            children: [
                {
                    id: 'salesteam',
                    title: 'Sales Team',
                    type: 'item',
                    url: '/salesteam'
                },
                {
                    id: 'customersuccessteam',
                    title: 'Customer Success Team',
                    type: 'item',
                    url: '/customersuccessteam'
                },
                {
                    id: 'carrier',
                    title: 'Fulfillment Team',
                    type: 'item',
                    url: '/carrier',
                    icon: icons.IconKey
                }
            ]
        },
        {
            id: 'statuses',
            title: 'Setups',
            type: 'collapse',
            icon: icons.DisplaySettingsIcon,

            children: [
                {
                    id: 'orderStatus',
                    title: 'Order Statuses',
                    type: 'item',
                    url: '/orderStatus'
                },
                {
                    id: 'attribute',
                    title: 'Attribute',
                    type: 'item',
                    url: '/attribute'
                },
                // {
                //     id: 'tags',
                //     title: 'Tag',
                //     type: 'item',
                //     url: '/tags'
                // },
                {
                    id: 'loyaltylist',
                    title: 'Loyalty',
                    type: 'item',
                    url: '/loyaltylist'
                },
                {
                    id: 'servicestatus',
                    title: 'Service Statuses',
                    type: 'item',
                    url: '/servicestatus'
                },
                {
                    id: 'stagingarea',
                    title: 'Staging Area',
                    type: 'item',
                    url: '/stagingarea'
                },
                // {
                //     id: 'darkstorearea',
                //     title: 'Dark Store Area',
                //     type: 'item',
                //     url: '/darkstorearea'
                // },
                {
                    id: 'paymentStatus',
                    title: 'Payment Status',
                    type: 'item',
                    url: '/paymentStatus'
                },
                {
                    id: 'stage',
                    title: 'Stage',
                    type: 'item',
                    url: '/stage'
                },
                {
                    id: 'batchautomation',
                    title: 'Batch Automation',
                    type: 'item',
                    url: '/batchautomation'
                },
                {
                    id: 'priorityautomation',
                    title: 'Priority Automation',
                    type: 'item',
                    url: '/priorityautomation'
                },
                {
                    id: 'batchstatus',
                    title: 'Batch Status',
                    type: 'item',
                    url: '/batchstatus'
                },
                {
                    id: 'ordertype',
                    title: 'OrderType',
                    type: 'item',
                    url: '/ordertype'
                },
                {
                    id: 'pickdrop',
                    title: 'Pickup / Drop off Type',
                    type: 'item',
                    url: '/pickdrop'
                },
                {
                    id: 'contactorder',
                    title: 'Order Emails',
                    type: 'item',
                    url: '/contactorder'
                },
                {
                    id: 'leademail',
                    title: 'Lead Emails',
                    type: 'item',
                    url: '/leademail'
                },
                {
                    id: 'collectionemail',
                    title: 'Collection Email',
                    type: 'item',
                    url: '/collectionemail'
                },
                {
                    id: 'fleetemailorder',
                    title: 'Fleet Email',
                    type: 'item',
                    url: '/fleetemailorder'
                },
                {
                    id: 'country',
                    title: 'Country',
                    type: 'item',
                    url: '/country'
                },
                {
                    id: 'city',
                    title: 'City',
                    type: 'item',
                    url: '/city'
                },

                {
                    id: 'warehouse',
                    title: 'Warehouse/Inventory',
                    type: 'item',
                    url: '/warehouse',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'partzones',
            title: 'Part Zones',
            type: 'collapse',
            icon: icons.CarCrashIcon,

            children: [
                {
                    id: 'partlistname',
                    title: 'Part List Name',
                    type: 'item',
                    url: '/partlistnames'
                },
                {
                    id: 'partzones',
                    title: 'Parts Zones',
                    type: 'item',
                    url: '/partzones'
                }
            ]
        },

        {
            id: 'parts',
            title: 'Parts',
            type: 'collapse',
            icon: icons.CarCrashIcon,

            children: [
                {
                    id: 'masterdb',
                    title: 'Master Database',
                    type: 'item',
                    url: '/parts'
                },
                // {
                //     id: 'fulpartlist',
                //     title: 'UnApproved Parts',
                //     type: 'item',
                //     url: '/fulpartlist'
                // },
                {
                    id: 'garageparts',
                    title: 'Vin Parts',
                    type: 'item',
                    url: '/partsgarage'
                },
                {
                    id: 'fleetpart',
                    title: 'Part (Fleet)',
                    type: 'item',
                    url: '/allfleetpart'
                }
            ]
        },
        {
            id: 'salesdrive',
            title: 'Sales Pods',
            type: 'collapse',
            icon: icons.InventoryIcon,
            children: [
                {
                    id: 'salespod',
                    title: 'Sales Pod',
                    type: 'item',
                    url: '/salespod',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default pages;
