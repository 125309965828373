import { useEffect } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';

const ViewNewOrder = () => {
    // const history = useNavigate();
    // const { id } = useParams();

    useEffect(() => {}, []);

    return (
        <>
            <div />
        </>
    );
};

export default ViewNewOrder;
