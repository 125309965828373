import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import RiseLoader from 'react-spinners/RiseLoader';
import styled from 'styled-components';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const Wrapper = styled.div`
    font-family: 'Roboto', 'sans-serif';
    h5 {
        text-align: center;
        font-weight: 500;
        text-transform: capitalize;
    }

    .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .description {
            font-weight: 600;
            padding: 0 0.875rem;
            line-height: 1.5;
        }
    }

    @media screen and (max-width: 768px) {
        .info p {
            font-size: 0.875rem;
        }
    }

    .spinner-location {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }
`;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #eee',
    boxShadow: 24,
    p: 4,
    color: '#333',
    maxWidth: '100%'
};

const DepositPreviewModal = ({ handleOpen, handleClose, record, loading }) => {
    const { partnerName, partnerContact, location, gender, rating, signupDate } = record;
    return (
        <Modal open={handleOpen} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Wrapper>
                    <header id="modal-modal-title">
                        <Divider>
                            <h5>{loading ? 'Loading...' : 'Profile Information'}</h5>
                        </Divider>
                        <br />
                    </header>
                    {loading ? (
                        <div className="spinner-location">
                            <RiseLoader color="#ff740f" />
                        </div>
                    ) : (
                        <div className="content" id="modal-modal-description">
                            <div className="info">
                                <p>Retailer Name</p>
                                <p className="description">{partnerName}</p>
                            </div>
                            <div className="info">
                                <p>Contact Number</p>
                                <p className="description">{partnerContact}</p>
                            </div>
                            <div className="info">
                                <p>Location</p>
                                <p className="description">{location}</p>
                            </div>
                            <div className="info">
                                <p>Gender</p>
                                <p className="description">{gender}</p>
                            </div>
                            <div className="info">
                                <p>Sign up date</p>
                                <p className="description"> {dayjs(signupDate).fromNow()}</p>
                            </div>
                            <div className="info">
                                <p>Rating</p>
                                <p className="description">{rating}</p>
                            </div>
                        </div>
                    )}
                </Wrapper>
            </Box>
        </Modal>
    );
};
export default DepositPreviewModal;
