import { Button, Collapse, Select, Table, DatePicker } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import ApiUrl from '../../../../network';
import { toast } from 'react-toastify';
import { decrypt } from '../../../../utils/encrypt';
import dayjs from 'dayjs';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const { RangePicker } = DatePicker;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [rmacc, setRmacc] = useState('');
    const [customer, setCustomer] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [rmaccOptions, setRmaccOptions] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);

    const [totalepl, setTotalEpl] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 25; // Fixed page size as per API

    // eslint-disable-next-line consistent-return
    const fetchAllData = async (data, page = 1) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            // Fetch Credit Data
            const urlParams = new URLSearchParams(window.location.search);
            if (data) {
                if (data.startDate) urlParams.append('startDate', startDate);
                if (data.endDate) urlParams.append('endDate', endDate);
                if (data.customer) urlParams.append('customer', customer);
            }

            urlParams.append('page', page);
            urlParams.append('pageSize', pageSize);

            const creditUrl = `${ApiUrl.BaseUrl}/epl/rmaccepldata?${urlParams.toString()}`;
            const creditResponse = await fetch(creditUrl, config);
            const creditData = await creditResponse.json();
            if (creditData.message === 'success') {
                const decryptedCreditData = JSON.parse(decrypt(creditData.data, keyResult, ivResult));

                setTotalEpl(creditData.totalepl);
                setData(decryptedCreditData);
            } else {
                toast.error(creditData.message);
            }

            // Fetch RMACC Data
            // don't fetch if there is data or already fetched
            if (rmaccOptions.length > 0 && customerOptions.length > 0) {
                return;
            }
            const rmaccUrl = `${ApiUrl.BaseUrl}/epl/eplcustomersrmacc`;
            const rmaccResponse = await fetch(rmaccUrl, config);
            const rmaccData = await rmaccResponse.json();
            if (rmaccData.message === 'Success') {
                const decryptedRmaccData = JSON.parse(decrypt(rmaccData.data, keyResult, ivResult));
                setRmaccOptions(decryptedRmaccData.rmcc);
                setCustomerOptions(decryptedRmaccData.customer);
            } else {
                toast.error(rmaccData.message);
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        fetchAllData();
    }, []);

    // options for select
    const customerOptionHandler = useMemo(() => {
        return customerOptions.map((item) => ({
            label: item.partnerName,
            value: item._id
        }));
    }, [customerOptions]);

    const handleDateChange = (date, dateString) => {
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
    };

    const applyFilter = () => {
        const data = {
            startDate,
            endDate,
            customer,
            rmacc
        };
        // eslint-disable-next-line no-unused-expressions
        fetchAllData(data);
    };

    const resetFilter = () => {
        setStartDate('');
        setEndDate('');
        setRmacc('');
        setCustomer('');
        // eslint-disable-next-line no-unused-expressions
        fetchAllData();
    };

    // eslint-disable-next-line array-callback-return
    const creditTrackerSource = data.map((item) => {
        // format date
        const dueDate = dayjs(item.actualDueDate).format('D MMMM YYYY');
        const maxDueDate = dayjs(item.maxDueDate).format('D MMMM YYYY');
        const creditdate = dayjs(item.creditdate).format('D MMMM YYYY');

        return {
            key: item._id,
            creditDate: creditdate,
            creditno: item.creditno,
            customer_name: `${item?.customer?.partnerName} ${item?.customer?.partnerContact}`,
            tier: item.tier,
            amountBalance: `${item.amountBalance}`,
            amountPaid: item.amountPaid,
            amountValueDue: item.amountValueDue,
            recon_amt: `${item.currency?.name} ${item.reconAmt}`,
            principalAmt: item.principalAmt,
            due_date: dueDate,
            rma_cc: `${item?.rmacc?.firstName} ${item?.rmacc?.lastName}`,
            max_due_date: maxDueDate,
            fees: item.fees,
            defaultstatus: item.defaultStatusInfo
        };
    });

    const trackercolumns = [
        {
            title: 'Credit Date',
            dataIndex: 'creditDate',
            key: 'creditDate'
        },
        {
            title: 'Credit no:',
            dataIndex: 'creditno',
            key: 'creditno'
        },
        {
            title: 'Customer name',
            dataIndex: 'customer_name',
            key: 'customer_name'
        },
        {
            title: 'Tier',
            dataIndex: 'tier',
            key: 'Tier'
        },
        {
            title: 'Principal Amount',
            dataIndex: 'principalAmt',
            key: 'principalAmt'
        },
        {
            title: 'Amount Value Due',
            dataIndex: 'amountValueDue',
            key: 'amountValueDue'
        },
        {
            title: 'Amount Paid',
            dataIndex: 'amountPaid',
            key: 'amountPaid'
        },
        {
            title: 'Amount Balance',
            dataIndex: 'amountBalance',
            key: 'amountBalance'
        },
        {
            title: 'Reconciled amount',
            dataIndex: 'recon_amt',
            key: 'recon_amt'
        },
        {
            title: 'Fees',
            dataIndex: 'fees',
            key: 'fees'
        },
        {
            title: 'RMA/CC',
            dataIndex: 'rma_cc',
            key: 'rma_cc'
        },
        {
            title: 'Max due date',
            dataIndex: 'max_due_date',
            key: 'max_due_date'
        },
        {
            title: 'Default status',
            dataIndex: 'defaultstatus',
            key: 'defaultstatus'
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <div>
                    <Button
                        size="small"
                        onClick={() => {
                            window.location.href = `/epl/cc_rma/add-payment/${record.key}`;
                        }}
                        style={{
                            backgroundColor: 'transparent',
                            color: '#FF740F',
                            border: '1px solid #FF740F'
                        }}
                    >
                        Add Payment
                    </Button>
                </div>
            )
        }
    ];

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchAllData({}, page); // Pass any required filters in place of {}
    };

    return (
        <div className="easy-pay-container">
            <div className="header">
                <div>
                    <h1 className="font-30">Easy pay later</h1>
                    <p>Keep track of all credit transactions</p>
                </div>
            </div>
            <hr className="divider" />
            <div className="content">
                <div>
                    <Collapse accordion>
                        <Collapse.Panel key={1} header="Hide filters" className="mb-4">
                            <div className="batchFilters">
                                <div className="mr-2">
                                    <Select
                                        style={{
                                            width: 300
                                        }}
                                        className="filterInput"
                                        placeholder="Search Customers"
                                        options={customerOptionHandler}
                                        onChange={(value) => setCustomer(value)}
                                        value={customer || null}
                                        filterOption={(inputValue, option) => {
                                            if (typeof option.label === 'string') {
                                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }

                                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }}
                                        showSearch
                                        allowClear
                                    />
                                </div>
                                <div>
                                    <RangePicker
                                        onChange={(date, dateString) => {
                                            handleDateChange(date, dateString);
                                        }}
                                        value={startDate && endDate ? [dayjs(startDate, 'YYYY-MM-DD'), dayjs(endDate, 'YYYY-MM-DD')] : null}
                                        format="YYYY-MM-DD"
                                        className="datePicker filterInput"
                                    />
                                </div>
                                <div className="d-flex align-items-center  mb-2">
                                    <Button
                                        style={{
                                            color: '#FF740F',
                                            border: '1px solid #FF740F'
                                        }}
                                        loading={loading}
                                        onClick={() => applyFilter()}
                                    >
                                        Apply filter
                                    </Button>
                                    {(startDate && endDate) || rmacc || customer ? (
                                        <Button
                                            onClick={() => {
                                                resetFilter();
                                            }}
                                            style={{
                                                color: '#FF740F',
                                                border: '1px solid #FF740F'
                                            }}
                                            className="ml-2"
                                        >
                                            Reset filter
                                        </Button>
                                    ) : null}
                                </div>
                            </div>
                        </Collapse.Panel>
                    </Collapse>
                    <div className="table-responsive">
                        <Table
                            className="table"
                            loading={loading}
                            columns={trackercolumns}
                            dataSource={creditTrackerSource}
                            scroll={{ x: true }}
                            pagination={{
                                current: currentPage,
                                pageSize,
                                total: totalepl,
                                showSizeChanger: false,
                                showTotal: (total) => `Total ${total} requests`,
                                onChange: handlePageChange
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
