import { Link, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Input, Select, Spin } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import { decrypt, encrypt } from '../../../utils/encrypt';
import ApiUrl from '../../../network';
import { toast } from 'react-toastify';

const baseUrl = 'https://apistaging.garagemobility.com/api';
const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const [loading, setLoading] = useState(true);
    const [banks, setBanks] = useState([]);
    const [phone, setPhone] = useState('');
    const [amount, setAmount] = useState('');
    const [bank, setBank] = useState('');
    const [requesting, setRequesting] = useState(false);
    const [customer, setCustomer] = useState('');
    const [fulOrderId, setFullOrderId] = useState('');
    const { id } = useParams();

    const history = useNavigate();

    // Fetch bank options from an API
    const fetchBanks = useCallback(async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(`${baseUrl}/order/v2/payments/paga/banks?country=${localStorage.getItem('country')}`, config);
            const data = await response.json();
            const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));
            setBanks(decryptedData); // Assuming the banks are in data.banks
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchOrderDetails = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            await fetch(`${ApiUrl.BaseUrl}/order/v2/vieworders/${id}`, {
                method: 'GET',
                headers: config.headers
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        // 667ee3694dd577d1bb439ecb
                        const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        localStorage.setItem('paymentId-orderId', id);
                        setCustomer(decryptedData?.newChannelPartnerId?.partnerName);
                        setFullOrderId(id);
                    } else {
                        toast.error(data.message);
                    }
                });
        } catch (error) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        fetchBanks().then((r) => r);
        if (id) {
            fetchOrderDetails().then((r) => r);
        }
    }, []);

    // Handle input changes
    const handlePhoneChange = (e) => setPhone(e.target.value);
    const handleAmountChange = (e) => setAmount(e.target.value);
    const handleBankChange = (value) => setBank(value);

    const makeRequest = useCallback(async () => {
        setRequesting(true);
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        const datas = {
            amount: parseFloat(amount),
            full_name: customer.trim(),
            phoneNumber: phone,
            customerBankId: bank || '',
            orderId: id,
            description: `Payment request for ${customer.trim()} with order ID: ${fulOrderId}`
        };

        const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);

        try {
            await fetch(`${baseUrl}/order/v2/payments/paga/request`, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify({ payload })
            })
                .then((data) => data.json())
                .then((data) => {
                    const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));
                    toast.success(data.message);

                    // eslint-disable-next-line camelcase
                    const { reference, payment_methods } = decryptedData;

                    decryptedData.payment_methods = payment_methods.map((method) => ({
                        ...method,
                        reference,
                        amount: parseFloat(amount)
                    }));

                    history('/payment-options', { state: { paymentData: decryptedData } });
                });
        } catch (error) {
            const errorMessage = error.message || 'An error occurred while making the payment request';
            toast.error(errorMessage);
            // Handle error accordingly
        } finally {
            setRequesting(false);
        }
    }, [phone, amount, bank, history]);

    return (
        <div>
            <div className="m-header">
                <div>
                    <Link to="/payment-transactions">
                        <h1 style={{ color: '#0A2938', display: 'flex', alignItems: 'center' }}>
                            <ArrowLeftOutlined className="mr-2" /> Payment request
                        </h1>
                    </Link>
                </div>
            </div>
            <div className="m-body">
                {loading ? (
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                ) : (
                    <div className="momo">
                        <div className="mb-4">
                            <p style={{ fontSize: '16px', color: '#546974', marginBottom: '4px' }}>Bank name (Optional)</p>
                            <Select
                                placeholder="Bank name"
                                size="large"
                                showSearch
                                style={{ width: '100%' }}
                                options={banks?.map((bank) => ({ value: bank.code, label: bank.name }))}
                                onChange={handleBankChange}
                                value={bank}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                            />
                        </div>
                        <div className="mb-4">
                            <p style={{ fontSize: '16px', color: '#546974', marginBottom: '4px' }}>Phone number</p>
                            <Input
                                type="number"
                                placeholder="Phone number"
                                size="large"
                                style={{ width: '100%' }}
                                value={phone}
                                onChange={handlePhoneChange}
                            />
                        </div>
                        <div className="mb-4">
                            <p style={{ fontSize: '16px', color: '#546974', marginBottom: '4px' }}>Amount</p>
                            <Input
                                type="number"
                                placeholder="Enter amount"
                                size="large"
                                style={{ width: '100%' }}
                                value={amount}
                                onChange={handleAmountChange}
                            />
                        </div>
                        <div>
                            <Button
                                type="primary"
                                size="large"
                                style={{
                                    width: '100%',
                                    marginTop: '20px',
                                    backgroundColor: `${!phone || !amount || requesting ? '#B0B0B0' : '#FF740F'}`,
                                    color: '#fff'
                                }}
                                onClick={makeRequest}
                                disabled={!phone || !amount || requesting} // Disable button if fields are empty or request is in progress
                                loading={requesting} // Show loading indicator on the button
                            >
                                Make request
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Index;
