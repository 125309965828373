/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ApiUrl from 'network';
import Checkbox from '@mui/material/Checkbox';
// import { notification } from 'antd';
import { toast } from 'react-toastify';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import Stack from '@mui/material/Stack';
import useScriptRef from 'hooks/useScriptRef';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const EditUser = () => {
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [email, setemail] = useState('');
    // const [password, setpassword] = useState('');
    // const [yrExp, setyearsOfExperience] = useState('');
    const [roleId, setroleId] = useState('');
    // const [dob, setdob] = useState(new Date());
    const [contactPhone, setcontactPhone] = useState('');
    const [location, setlocation] = useState('');
    // const [locationLat, setlocationLat] = useState('');
    const [temporaryBlock, settemporaryBlock] = useState('');
    const [kycApproval, setkycApproval] = useState('');
    const [country, setCountry] = useState('');
    const [countries, setCountries] = useState([]);
    const [currency, setcurrency] = useState('');
    const [cityId, setcityId] = useState('');
    const [cityData, setCityData] = useState([]);
    const [rolesd, setroles] = useState([]);
    // const [languagesSpoken, setlanguagesSpoken] = useState([]);
    const [identityVerified, setidentityVerified] = useState('');
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [roleInfo, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    // const languesNames = ['English', 'French', 'Hausa', 'Twi', 'Ga', 'Ewe'];

    const retrieveAllRoles = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            // setLoading2(true);
            fetch(ApiUrl.AllRolesUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setRoles(data.data);
                    } else {
                        toast.error(data.data);
                        // setLoading2(false);
                    }
                })
                .finally(() => {
                    // setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    const retrieveAllCityInfo = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.CityUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCityData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllCity = async (id) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.CityFilterUrl + id, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCityData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    const filterCountry = (event) => {
        const newFilter = countries.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        newFilter.forEach((option) => {
            setcurrency(option.currency);
            retrieveAllCity(option?._id);
        });
    };
    const retrieveAllActiveCountry = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.ActiveCountryUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    const handleCityChange = (event) => {
        setcityId(event.target.value);
    };

    const { id } = useParams();

    useEffect(() => {
        retrieveAllRoles();
        retrieveAllActiveCountry();
        retrieveAllCityInfo();
        setLoading2(true);
        const userInfo = localStorage.getItem('userToken');
        fetch(ApiUrl.ShowUserUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setfirstName(result?.data?.firstName ?? '');
                    setlastName(result?.data?.lastName ?? '');
                    setemail(result?.data?.email ?? '');
                    setidentityVerified(result?.data?.identityVerified ?? false);
                    // setdob(result?.data?.dob ?? '');
                    setroleId(result?.data.roleId?._id ?? '');
                    const dataRoles = [];
                    // eslint-disable-next-line array-callback-return
                    result?.data?.roles.map((arr) => {
                        dataRoles.push({
                            _id: arr?.roleId,
                            name: arr?.roleName
                        });
                    });
                    // console.log('dataRoles', dataRoles);

                    setroles(dataRoles ?? []);
                    setcontactPhone(result?.data?.contactPhone ?? '');
                    setlocation(result?.data?.location ?? '');
                    setCountry(result?.data?.country ?? '');
                    setcurrency(result?.data?.currency ?? '');
                    setcityId(result?.data?.cityId?._id ?? '');
                    settemporaryBlock(result?.data?.temporaryBlock ? 'true' : 'false');
                    setkycApproval(result?.data?.kycApproval ? 'true' : 'false');

                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                    // setLoading(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading2(false);
            });
    }, [id]);

    // const handleSignupChange = (newValue) => {
    //     setdob(newValue);
    // };
    const handleDistributorChange = (event) => {
        setroles(event);
    };
    const handleDistributorrolesChange = (event) => {
        setroleId(event.target.value);
    };
    const handleDisableChange = (event) => {
        if (event.target.value === 'true') {
            const result = true;
            settemporaryBlock(result);
        } else if (event.target.value === 'false') {
            const result = false;
            settemporaryBlock(result);
        }
    };

    const handleApproveChange = (event) => {
        if (event.target.value === 'true') {
            const result = true;
            setkycApproval(result);
        } else if (event.target.value === 'false') {
            const result = false;
            setkycApproval(result);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        const identityVerified = true;
        const roles = [];
        // eslint-disable-next-line array-callback-return
        rolesd.map((arr) => {
            roles.push({
                roleId: arr?._id,
                roleName: arr?.name
            });
        });

        const data = {
            id,
            firstName,
            lastName,
            email,
            roleId,
            // dob,
            contactPhone,
            location,
            country,
            currency,
            identityVerified,
            temporaryBlock,
            kycApproval,
            cityId,
            roles
        };
        setLoading(true);
        fetch(ApiUrl.UpdateUserUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/users');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <br />
                            <PageHeader title="Users" subTitle="Home | Configuration | User Management | Users" />
                            <br />
                            <MainCard title="Edit User">
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        autoComplete="off"
                                        name="firstName"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="firstName"
                                        value={firstName}
                                        label="First Name"
                                        onChange={(e) => setfirstName(e.target.value)}
                                        autoFocus
                                    />
                                    <br />
                                    <br />

                                    <TextField
                                        autoComplete="off"
                                        name="lastName"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="lastName"
                                        value={lastName}
                                        label="Last Name"
                                        onChange={(e) => setlastName(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="email"
                                        type="email"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="email"
                                        value={email}
                                        label="Email"
                                        onChange={(e) => setemail(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="identityVerified"
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        id="identityVerified"
                                        value={identityVerified}
                                        label="Email Verified"
                                        onChange={(e) => setidentityVerified(e.target.value)}
                                    />

                                    <br />
                                    <br />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Temporary Disable User</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={temporaryBlock}
                                            required
                                            label="Status"
                                            onChange={handleDisableChange}
                                        >
                                            <MenuItem value="">Select an option</MenuItem>
                                            <MenuItem value="true">Disable</MenuItem>
                                            <MenuItem value="false">Active</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <br />
                                    <br />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Approve User</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={kycApproval}
                                            required
                                            label="Status"
                                            onChange={handleApproveChange}
                                        >
                                            <MenuItem value="">Select an option</MenuItem>
                                            <MenuItem value="true">Not Approved</MenuItem>
                                            <MenuItem value="false">Approved</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <br />
                                    <br />

                                    <TextField
                                        autoComplete="off"
                                        name="contactPhone"
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        id="contactPhone"
                                        value={contactPhone}
                                        label="Contact Phone"
                                        onChange={(e) => setcontactPhone(e.target.value)}
                                    />
                                    <br />
                                    <br />

                                    <TextField
                                        autoComplete="off"
                                        name="location"
                                        variant="outlined"
                                        fullWidth
                                        id="location"
                                        value={location}
                                        label="Location"
                                        onChange={(e) => setlocation(e.target.value)}
                                    />
                                    {/* <br />
                                    <br /> */}

                                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Stack spacing={3}>
                                            <DesktopDatePicker
                                                label="Date Of Birth"
                                                inputFormat="MM/dd/yyyy"
                                                value={dob}
                                                onChange={handleSignupChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Stack>
                                    </LocalizationProvider> */}
                                    <br />
                                    <br />
                                    <Autocomplete
                                        id="country-select-demo"
                                        autoHighlight
                                        value={country}
                                        onChange={(event, newValue) => {
                                            setCountry(newValue);
                                            filterCountry(newValue);
                                        }}
                                        options={countries.map((option) => option.name)}
                                        isOptionEqualToValue={(option, value) => option.code === value.code}
                                        renderInput={(params) => <TextField {...params} label="Choose a country" />}
                                    />
                                    <br />
                                    <br />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Choose a City</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={cityId}
                                            label="City"
                                            onChange={handleCityChange}
                                        >
                                            {cityData.map((attr) => (
                                                <MenuItem key={attr._id} value={attr._id}>
                                                    {attr?.city ?? ''}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <br />
                                    <br />

                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={roleId}
                                            label="Status"
                                            onChange={handleDistributorrolesChange}
                                        >
                                            {roleInfo.map((role) => (
                                                <MenuItem key={role._id} value={role._id}>
                                                    {role.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {/* <Autocomplete
                                        multiple
                                        id="role-select-demo"
                                        autoHighlight
                                        value={roles}
                                        onChange={(event, newValue) => {
                                            console.log('newValue', newValue);
                                            handleDistributorChange(newValue);
                                            // setCountry(newValue);
                                        }}
                                        options={roleInfo.map((option) => option?.name)}
                                        isOptionEqualToValue={(option, value) => option._id === value._id}
                                        renderInput={(params) => <TextField {...params} label="Choose Roles" />}
                                    /> */}

                                    <Autocomplete
                                        multiple
                                        fullWidth
                                        id="checkboxes-tags-demo"
                                        value={rolesd}
                                        options={roleInfo}
                                        onChange={(event, newValue) => {
                                            handleDistributorChange(newValue);
                                        }}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </li>
                                        )}
                                        renderInput={(params) => <TextField {...params} label="Choose Roles" placeholder="Select Roles" />}
                                    />
                                    <br />
                                    <br />

                                    <Box display="flex" justifyContent="space-between">
                                        <Button component={Link} to="/users" type="submit" variant="contained" color="error">
                                            Reset
                                        </Button>

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            disabled={!firstName || !lastName || !email || !roleId || !cityId}
                                        >
                                            {loading ? 'Submitting ..' : 'Submit'}
                                        </Button>
                                    </Box>
                                </form>
                            </MainCard>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default EditUser;
