/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ApiUrl from 'network';
import { styled } from '@mui/material/styles';
// import { notification } from 'antd';
import { toast } from 'react-toastify';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';
import useScriptRef from 'hooks/useScriptRef';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import nologo from 'assets/images/nologo.png';
import Compressor from 'compressorjs';

const Input = styled('input')({
    display: 'none'
});

const EditInActiveParts = () => {
    const [sku, setSku] = useState('');
    const [productFamily, setproductFamily] = useState('');
    const [brand, setbrand] = useState('');
    const [wholeSalePrice, setwholeSalePrice] = useState('');
    const [garagePrice, setgarageRetailPrice] = useState('');
    // const [retailPrice, setretailPrice] = useState('');
    const [distributor, setdistributor] = useState('');
    const [signupDate, setsignupDate] = useState(new Date());
    // const [applicableVins, setapplicableVins] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [status, setStatus] = useState('');
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [distributorInfo, setDistributors] = useState([]);
    const [partQtyInPieces, setpartQtyInPieces] = useState('');
    const [loading3, setLoading3] = useState(false);
    const [partsImage, setpartsImage] = useState('');
    const [partListAPrice, setpartListAPrice] = useState('');
    const [partListBPrice, setpartListBPrice] = useState('');
    const [partListCPrice, setpartListCPrice] = useState('');
    const [partListCSPrice, setpartListCSPrice] = useState('');
    const [partWeight, setpartWeight] = useState('');

    const retrieveAllDistributor = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(ApiUrl.DistributorUrl, config)
                .then((data) => data.json())
                .then((data) => setDistributors(data.data));
        } catch (e) {
            console.log(e);
        }
    };

    const handleChange = (event) => {
        setStatus(event.target.value);
    };
    const { id } = useParams();

    useEffect(() => {
        retrieveAllDistributor();
        setLoading2(true);
        const userInfo = localStorage.getItem('userToken');
        fetch(ApiUrl.ShowPartsUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    // console.log('data', result?.data);
                    setSku(result?.data?.sku ?? '');
                    setproductFamily(result?.data?.productFamily ?? '');
                    setbrand(result?.data?.brand ?? '');
                    setwholeSalePrice(result?.data?.wholeSalePrice ?? '');
                    setgarageRetailPrice(result?.data?.garagePrice ?? '');
                    // setretailPrice(result?.data?.retailPrice ?? '');
                    setsignupDate(result?.data?.signupDate ?? '');
                    // setapplicableVins(result?.data?.applicableVins ?? '');
                    setStatus(result?.data?.status ?? '');
                    setdistributor(result?.data?.distributor?._id ?? '');
                    setpartQtyInPieces(result?.data?.partQtyInPieces ?? '');
                    setpartsImage(result?.data?.partsImage ?? '');
                    setpartListAPrice(result?.data?.partListAPrice ?? '');
                    setpartListBPrice(result?.data?.partListBPrice ?? '');
                    setpartListCPrice(result?.data?.partListCPrice ?? '');
                    setpartListCSPrice(result?.data?.partListCSPrice ?? '');
                    setpartWeight(result?.data?.partWeight ?? '');
                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                    // setLoading(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading2(false);
            });
    }, [id]);

    const handlePFamilyChange = (event) => {
        setproductFamily(event.target.value);
    };
    const handleSignupChange = (newValue) => {
        setsignupDate(newValue);
    };
    const handleDistributorChange = (event) => {
        setdistributor(event.target.value);
    };

    const selectedFile = (e) => {
        const image = e.target.files[0];
        setLoading3(true);
        const userInfo = localStorage.getItem('userToken');
        // eslint-disable-next-line no-new
        new Compressor(image, {
            quality: 0.8,
            height: '400px',
            width: '400px',
            resize: 'contain',
            success: (compressedResult) => {
                console.log('compressedResult', compressedResult);
                const formData = new FormData();
                formData.append('file', compressedResult);
                axios
                    .post(ApiUrl.UploadIDCardPartsUrl, formData, {
                        headers: {
                            'auth-token': userInfo,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((result) => {
                        if (result.data.status === true) {
                            setLoading3(false);
                            setpartsImage(result?.data?.data ?? '');
                            toast.success('success');
                        } else {
                            toast.error('failed');
                            setLoading3(false);
                        }
                    })
                    .catch((err) => {
                        toast.error('failed');
                        setLoading3(false);
                        console.log(err);
                    })
                    .finally(() => {
                        setLoading3(false);
                    });
            }
        });
        // axios
        //     .post(ApiUrl.UploadIDCardPartsUrl, formData, {
        //         headers: {
        //             'auth-token': userInfo,
        //             'Content-Type': 'multipart/form-data'
        //         }
        //     })
        //     .then((result) => {
        //         if (result.data.status === true) {
        //             setLoading3(false);
        //             setpartsImage(result?.data?.data ?? '');
        //         } else {
        //             toast.error(result.data);
        //             setLoading3(false);
        //         }
        //     })
        //     .catch((err) => {
        //         toast.error(err);
        //         setLoading3(false);
        //     });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');

        const data = {
            id,
            sku,
            productFamily,
            brand,
            wholeSalePrice,
            garagePrice,
            signupDate,
            distributor,
            partListAPrice,
            partListBPrice,
            partListCPrice,
            partListCSPrice,
            status,
            partQtyInPieces,
            partsImage,
            partWeight
        };
        setLoading(true);
        fetch(ApiUrl.UpdatePartsUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status) {
                    setLoading(false);
                    toast.success(result.message);
                    setTimeout(() => {
                        if (scriptedRef.current) {
                            history('/fulpartlist');
                        }
                    }, 3000);
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <br />
                            <PageHeader title="Parts" subTitle="Home | Configuration | Parts  | Master Database" />
                            <br />
                            <MainCard title="Edit Parts">
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        autoComplete="off"
                                        name="sku"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="sku"
                                        value={sku}
                                        label="Product Name"
                                        onChange={(e) => setSku(e.target.value)}
                                        autoFocus
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="partWeight"
                                        variant="outlined"
                                        fullWidth
                                        id="partWeight"
                                        value={partWeight}
                                        label="Weight In KG"
                                        onChange={(e) => setpartWeight(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Product Family</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={productFamily}
                                            label="Status"
                                            onChange={handlePFamilyChange}
                                        >
                                            <MenuItem value="Body">Body</MenuItem>
                                            <MenuItem value="Filters">Filters</MenuItem>
                                            <MenuItem value="Mechanical">Mechanical</MenuItem>
                                            <MenuItem value="consumables">consumables</MenuItem>
                                            <MenuItem value="Electrical">Electrical</MenuItem>
                                            <MenuItem value="Battery">Battery</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="partQtyInPieces"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="partQtyInPieces"
                                        value={partQtyInPieces}
                                        label="Quantity For Pieces In Box or Roll or Packs"
                                        onChange={(e) => setpartQtyInPieces(e.target.value)}
                                    />

                                    <br />
                                    <br />

                                    <TextField
                                        autoComplete="off"
                                        name="brand"
                                        variant="outlined"
                                        fullWidth
                                        id="brand"
                                        value={brand}
                                        label="Brand"
                                        onChange={(e) => setbrand(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="wholeSalePrice"
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        id="wholeSalePrice"
                                        value={wholeSalePrice}
                                        label="Wholesale Price"
                                        onChange={(e) => setwholeSalePrice(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="garagePrice"
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        id="garagePrice"
                                        value={garagePrice}
                                        label="Garage Price"
                                        onChange={(e) => setgarageRetailPrice(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="partListAPrice"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        id="partListAPrice"
                                        value={partListAPrice}
                                        label="Price List A"
                                        onChange={(e) => setpartListAPrice(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="partListBPrice"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        id="partListBPrice"
                                        value={partListBPrice}
                                        label="Price List B"
                                        onChange={(e) => setpartListBPrice(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="partListCPrice"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        id="partListCPrice"
                                        value={partListCPrice}
                                        label="Price List C"
                                        onChange={(e) => setpartListCPrice(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="partListCSPrice"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        id="partListCSPrice"
                                        value={partListCSPrice}
                                        label="Price List CS"
                                        onChange={(e) => setpartListCSPrice(e.target.value)}
                                    />
                                    <br />
                                    <br />

                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Distributor(s)</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={distributor}
                                            label="Distributor"
                                            onChange={handleDistributorChange}
                                        >
                                            {distributorInfo.map((distributors) => (
                                                <MenuItem key={distributors._id} value={distributors._id}>
                                                    {distributors.partnerName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <br />
                                    <br />
                                    <Grid item xs={12} sm={6} md={4} lg={6}>
                                        <Box width={200}>
                                            <InputLabel id="demo-simple-select-label">Attach Part Image </InputLabel>
                                            <Stack direction="row" alignItems="center" spacing={4}>
                                                <Avatar variant="rounded" src={partsImage ?? nologo} sx={{ width: 150, height: 100 }} />

                                                <label htmlFor="contained-button-file">
                                                    <Input
                                                        accept="image/*"
                                                        id="contained-button-file"
                                                        multiple
                                                        type="file"
                                                        onChange={selectedFile}
                                                    />
                                                    <Button variant="contained" component="span" disabled={loading3}>
                                                        {loading3 ? 'Uploading ...' : 'Upload'}
                                                    </Button>
                                                </label>
                                            </Stack>
                                        </Box>
                                    </Grid>
                                    <br />
                                    <br />
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Stack spacing={3}>
                                            <DesktopDatePicker
                                                label="Date"
                                                inputFormat="MM/dd/yyyy"
                                                value={signupDate}
                                                onChange={handleSignupChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Stack>
                                    </LocalizationProvider>

                                    {/* <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="applicableVins"
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        fullWidth
                                        value={applicableVins}
                                        id="applicableVins"
                                        label="Applicable VINs"
                                        onChange={(e) => setapplicableVins(e.target.value)}
                                    /> */}
                                    <br />
                                    <br />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={status}
                                            label="Status"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="Active">Active</MenuItem>
                                            <MenuItem value="InActive">InActive</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <br />
                                    <br />
                                    <Box display="flex" justifyContent="space-between">
                                        <Button component={Link} to="/fulpartlist" type="submit" variant="contained" color="error">
                                            Reset
                                        </Button>

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            disabled={!sku || !partQtyInPieces || !signupDate}
                                        >
                                            {loading ? 'Updating ..' : 'Update'}
                                        </Button>
                                    </Box>
                                </form>
                            </MainCard>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default EditInActiveParts;
