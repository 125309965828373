// assets
import { IconKey, IconTruckDelivery } from '@tabler/icons';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Person from '@mui/icons-material/Person';
import CarCrashIcon from '@mui/icons-material/CarRental';
import InventoryIcon from '@mui/icons-material/Inventory';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import Scale from '@mui/icons-material/Scale';
import vinSearch from '@mui/icons-material/Search';
// import Shop from '@mui/icons-material/Shop2';
import Trans from '@mui/icons-material/Money';
// constant
const icons = {
    IconKey,
    IconTruckDelivery,
    InventoryIcon,
    DashboardIcon,
    Scale,
    Person,
    DisplaySettingsIcon,
    CarCrashIcon,
    Trans,
    vinSearch
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //
const userRole = localStorage.getItem('userRole');
const partsSourceNigeria = {
    id: 'partsSourceNigeria',
    title: 'Part Sourcing',
    type: userRole,
    children: [
        {
            id: 'home',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: icons.DashboardIcon,
            breadcrumbs: false
        },
        // {
        //     id: 'inventory',
        //     title: 'Inventory',
        //     type: 'item',
        //     url: '/inventory',
        //     icon: icons.InventoryIcon,
        //     breadcrumbs: false
        // },

        // {
        //     id: 'orders',
        //     title: 'Order Status',
        //     type: 'item',
        //     url: '/ordertablestatus',
        //     icon: icons.Scale,
        //     breadcrumbs: false
        // },
        {
            id: 'garagevinsearch',
            title: 'Vin Search',
            type: 'item',
            url: '/garagevinsearch',
            icon: icons.vinSearch,
            breadcrumbs: false
        },
        {
            id: 'channelpartners',
            title: 'Channel Partners',
            type: 'collapse',
            icon: icons.Person,

            children: [
                // {
                //     id: 'retailers',
                //     title: 'Retail',
                //     type: 'item',
                //     url: '/retail'
                // },
                {
                    id: 'distributorng',
                    title: 'Distributor',
                    type: 'item',
                    url: '/distributorng'
                }
                // {
                //     id: 'mechanic',
                //     title: 'Mechanic',
                //     type: 'item',
                //     url: '/mechanic'
                // },
                // {
                //     id: 'fleetpartner',
                //     title: 'Fleet',
                //     type: 'item',
                //     url: '/fleetpartner'
                // },
                // {
                //     id: 'channel',
                //     title: 'Channel',
                //     type: 'item',
                //     url: '/channel'
                // }
            ]
        },
        // {
        //     id: 'statuses',
        //     title: 'Setups',
        //     type: 'collapse',
        //     icon: icons.DisplaySettingsIcon,

        //     children: [
        //         {
        //             id: 'orderStatus',
        //             title: 'Order Statuses',
        //             type: 'item',
        //             url: '/orderStatus'
        //         },
        //         {
        //             id: 'paymentStatus',
        //             title: 'Payment Status',
        //             type: 'item',
        //             url: '/paymentStatus'
        //         },
        //         {
        //             id: 'stage',
        //             title: 'Stage',
        //             type: 'item',
        //             url: '/stage'
        //         }
        //     ]
        // },

        {
            id: 'parts',
            title: 'Parts',
            type: 'collapse',
            icon: icons.CarCrashIcon,

            children: [
                {
                    id: 'masterdb',
                    title: 'Master Database',
                    type: 'item',
                    url: '/partsng'
                }
            ]
        }
    ]
};

export default partsSourceNigeria;
