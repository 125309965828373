import { Button, Paper } from '@mui/material';
import styled from 'styled-components';

const HeaderWrapper = styled.div`
    .custom-paper {
        font-family: 'Roboto', 'sans-serif';
        border-radius: 0;
        padding: 0.625rem 0.625rem;
        color: #444;
        margin: 20px 0;

        header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            .retailer-btn {
                background: #ff740f;
            }
        }

        @media screen and (max-width: 500px) {
            header {
                flex-direction: column;
                justify-content: center;
            }
        }
        h4,
        h5 {
            font-size: 0.875rem;
            font-weight: 400;
        }

        h4 {
            font-weight: 800;
        }

        h5 {
            color: #8b8b8b;
        }
    }
`;

const PageHeader = ({ title, breadcrumbs }) => {
    return (
        <HeaderWrapper>
            <Paper elevation={0} className="custom-paper">
                <header>
                    <div>
                        <h4>{title}</h4>
                        <h5>{breadcrumbs}</h5>
                    </div>
                    <div>
                        <Button variant="contained" size="contained" className="retailer-btn">
                            Retailer Shop
                        </Button>
                    </div>
                </header>
            </Paper>
        </HeaderWrapper>
    );
};
export default PageHeader;
