// assets
import { IconKey, IconSnowflake, IconTruckDelivery } from '@tabler/icons';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import CarCrashIcon from '@mui/icons-material/CarRental';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import Scale from '@mui/icons-material/Scale';
import AssessmentIcon from '@mui/icons-material/Assessment';
// constant
const icons = {
    IconKey,
    IconTruckDelivery,
    IconSnowflake,
    CarRepairIcon,
    CarCrashIcon,
    DisplaySettingsIcon,
    DashboardIcon,
    Scale,
    AssessmentIcon
};

// ==============================|| EXTRA FLEET MENU ITEMS ||============================== //
const userRole = localStorage.getItem('userRole');

const ordersreporting = {
    id: 'report',
    title: 'Reports',
    type: userRole,
    children: [
        {
            id: 'orderreport',
            title: 'Orders By Sales Associate',
            type: 'item',
            icon: icons.AssessmentIcon,
            url: '/orderreport'
        },
        {
            id: 'allsalesorders',
            title: 'Total Sales(Orders)',
            type: 'item',
            icon: icons.AssessmentIcon,
            url: '/allsalesorders'
        },
        {
            id: 'allretailerordersdata',
            title: 'Total Sales(Retailers)',
            type: 'item',
            icon: icons.AssessmentIcon,
            url: '/allretailerordersdata'
        },

        {
            id: 'allsalespersonsales',
            title: 'Total Sales (SalesPerson)',
            type: 'item',
            icon: icons.AssessmentIcon,
            url: '/allsalespersonsales'
        }

        // {
        //     id: 'orderspartall',
        //     title: 'Parts Orders',
        //     type: 'item',
        //     icon: icons.AssessmentIcon,
        //     url: '/orderspartall'
        // },

        // {
        //     id: 'allpartssold',
        //     title: 'Parts Sold',
        //     type: 'item',
        //     icon: icons.AssessmentIcon,
        //     url: '/allpartssold'
        // },
        // {
        //     id: 'partsrmsold',
        //     title: 'Parts Sold by RM',
        //     type: 'item',
        //     icon: icons.AssessmentIcon,
        //     url: '/partsrmsold'
        // }

        // {
        //     id: 'setup',
        //     title: 'Setup Management',
        //     type: 'collapse',
        //     icon: icons.DisplaySettingsIcon,
        //     children: [
        //         {
        //             id: 'car',
        //             title: 'Cars',
        //             type: 'item',
        //             url: '/car'
        //         },
        //         {
        //             id: 'carmodel',
        //             title: 'Model',
        //             type: 'item',
        //             url: '/carmodel'
        //         },

        //         {
        //             id: 'fleetservices',
        //             title: 'Fleet Service',
        //             type: 'item',
        //             url: '/fleetservice'
        //         },
        //         {
        //             id: 'fleetservicefee',
        //             title: 'Fleet Service Fee',
        //             type: 'item',
        //             url: '/fleetservicefee'
        //         }
        //     ]
        // }
    ]
};

export default ordersreporting;
