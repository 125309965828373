// import dashboard from './dashboard';

import customerSuccessIndex from './customersuccesslead';
import other from './other';
// import ordersreporting from './ordersreport';

// ==============================|| MENU ITEMS dashboard ||============================== //

const customersuccessLeadItems = {
    items: [customerSuccessIndex, other]
};

export default customersuccessLeadItems;
