import { Link } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Card, Divider, Spin } from 'antd';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ApiUrl from '../../../network';
import { decrypt } from '../../../utils/encrypt';
import { LoadingOutlined } from '@ant-design/icons';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const [loading, setLoading] = useState(false);
    const [analysisData, setAnalysisData] = useState({});
    useEffect(() => {
        // eslint-disable-next-line consistent-return
        const fetchCrmAnalysis = async () => {
            try {
                setLoading(true);
                const userInfo = localStorage.getItem('userToken');
                if (!userInfo) {
                    toast.error('User not authenticated');
                    return false;
                }
                const config = {
                    headers: {
                        'auth-token': userInfo,
                        'Content-Type': 'application/json'
                    }
                };

                const response = await fetch(`${ApiUrl.BaseUrl}/crmanalytics/main`, config);

                await response.json().then((data) => {
                    if (data.status === false) {
                        toast.error(data.message);
                        setLoading(false);
                    } else {
                        const result = {};
                        console.log(JSON.parse(decrypt(data.data, keyResult, ivResult)), 'data');
                        setAnalysisData(JSON.parse(decrypt(data.data, keyResult, ivResult)));
                        setLoading(false);
                    }
                });
            } catch (error) {
                toast.error(error.message);
                setLoading(false);
            }
        };
        fetchCrmAnalysis();

        return () => {
            // cleanup function
            setLoading(false);
        };
    }, []);

    return (
        <div className="leadCrmContainer">
            <div className="d-flex justify-content-space-between align-items-center salesCardHolder">
                <Link to="/salescrm" style={{ color: '#0A2938' }}>
                    <p style={{ fontSize: '30px' }}>
                        {' '}
                        <KeyboardBackspaceIcon style={{ marginRight: '5px' }} /> Analytics
                    </p>
                </Link>
            </div>
            <Divider style={{ margin: '20px 0', border: '1px dash #e8e8e8' }} />
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 44
                                }}
                                spin
                            />
                        }
                    />
                </div>
            ) : (
                <div>
                    <div className="d-flex align-items-center justify-content-space-between salesCardHolder">
                        <Card
                            className="salesCard d-flex align-items-center justify-content-center"
                            style={{ borderRadius: '20px', backgroundColor: '#304F67', height: '148px' }}
                        >
                            <div className="d-flex align-items-center mb-2">
                                <span
                                    style={{
                                        backgroundColor: '#476278',
                                        padding: '10px',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '50px',
                                        height: '50px',
                                        color: '#fff',
                                        fontSize: '1.1rem',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    <PeopleAltOutlinedIcon />
                                </span>
                                <span className="ml-2">
                                    <p style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '0px', color: '#fff' }}>
                                        {analysisData?.totalLeads}
                                    </p>
                                    <small style={{ color: '#fff', fontSize: '14px' }}>Total number of leads</small>
                                </span>
                            </div>
                        </Card>
                        <Card
                            className="salesCard d-flex align-items-center justify-content-center"
                            style={{ borderRadius: '20px', backgroundColor: '#00AA55', height: '148px' }}
                        >
                            <div className="d-flex align-items-center mb-2">
                                <span
                                    style={{
                                        backgroundColor: '#33BB77',
                                        padding: '10px',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '50px',
                                        height: '50px',
                                        color: '#fff',
                                        fontSize: '1.1rem',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    <PeopleAltOutlinedIcon />
                                </span>
                                <span className="ml-2">
                                    <p style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '0px', color: '#fff' }}>
                                        {analysisData?.leadsContacted}
                                    </p>
                                    <small style={{ color: '#fff', fontSize: '14px' }}>Leads contacted today</small>
                                </span>
                            </div>
                        </Card>
                        <Card
                            className="salesCard d-flex align-items-center justify-content-center"
                            style={{ borderRadius: '20px', backgroundColor: '#4B6EFF', height: '148px' }}
                        >
                            <div className="d-flex align-items-center mb-2">
                                <span
                                    style={{
                                        backgroundColor: '#819AFF',
                                        padding: '10px',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '50px',
                                        height: '50px',
                                        color: '#fff',
                                        fontSize: '1.1rem',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    <PeopleAltOutlinedIcon />
                                </span>
                                <span className="ml-2">
                                    <p style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '0px', color: '#fff' }}>
                                        {analysisData?.leadsConverted}
                                    </p>
                                    <small style={{ color: '#fff', fontSize: '14px' }}>Total number of converted</small>
                                </span>
                            </div>
                        </Card>
                    </div>
                    <div style={{ marginTop: '40px' }}>
                        <p className="ml-2" style={{ fontSize: '18px', color: '#233E4C', marginBottom: '2px', padding: '0px' }}>
                            Average priority timeline
                        </p>
                        <p className="ml-2" style={{ fontSize: '12px', color: '#546974' }}>
                            {' '}
                            Average time it takes our leads to move from one priority level to another
                        </p>
                        <div className="d-flex align-items-center justify-content-space-between salesCardHolder">
                            <Card
                                className="salesCard d-flex align-items-center justify-content-center"
                                style={{ borderRadius: '20px', backgroundColor: '#E7EAEB', height: '148px' }}
                            >
                                <div className="d-flex align-items-center mb-2">
                                    <span className="text-center">
                                        <p style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '5px', color: '#0A2938' }}>
                                            {analysisData?.leadsLowPriority} days
                                        </p>
                                        <small style={{ color: '#3B5460', fontSize: '14px' }}>Low to medium priority</small>
                                    </span>
                                </div>
                            </Card>
                            <Card
                                className="salesCard d-flex align-items-center justify-content-center"
                                style={{ borderRadius: '20px', backgroundColor: '#DBE2FF', height: '148px' }}
                            >
                                <div className="d-flex align-items-center mb-2">
                                    <span className="text-center">
                                        <p style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '5px', color: '#0A2938' }}>
                                            {analysisData?.leadsMediumPriority} days
                                        </p>
                                        <small style={{ color: '#3B5460', fontSize: '14px' }}>Medium to high priority</small>
                                    </span>
                                </div>
                            </Card>
                            <Card
                                className="salesCard d-flex align-items-center justify-content-center"
                                style={{ borderRadius: '20px', backgroundColor: '#CCEEDD', height: '148px' }}
                            >
                                <div className="d-flex align-items-center mb-2">
                                    <span className="text-center">
                                        <p style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '5px', color: '#0A2938' }}>
                                            {analysisData?.leadsHighPriority} days
                                        </p>
                                        <small style={{ color: '#3B5460', fontSize: '14px' }}>Low to high priority</small>
                                    </span>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div style={{ marginTop: '40px' }}>
                        <p className="ml-2" style={{ fontSize: '18px', color: '#233E4C' }}>
                            No. of leads in priority levels
                        </p>
                        <div className="d-flex align-items-center justify-content-space-between salesCardHolder">
                            <Card
                                className="salesCard d-flex align-items-center justify-content-center"
                                style={{ borderRadius: '20px', backgroundColor: '#fff', height: '148px' }}
                            >
                                <div className="d-flex align-items-center mb-2">
                                    <span
                                        style={{
                                            backgroundColor: '#FFEBED',
                                            padding: '10px',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '50px',
                                            height: '50px',
                                            color: '#FB5B6B',
                                            fontSize: '1.1rem',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        <PeopleAltOutlinedIcon />
                                    </span>
                                    <span className="ml-2">
                                        <p style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '0px', color: '#0A2938' }}>
                                            {analysisData?.leadsLowPriority}
                                        </p>
                                        <small style={{ color: '#3B5460', fontSize: '14px' }}>Leads in low priority</small>
                                    </span>
                                </div>
                            </Card>
                            <Card
                                className="salesCard d-flex align-items-center justify-content-center"
                                style={{ borderRadius: '20px', backgroundColor: '#fff', height: '148px' }}
                            >
                                <div className="d-flex align-items-center mb-2">
                                    <span
                                        style={{
                                            backgroundColor: '#EDF1FF',
                                            padding: '10px',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '50px',
                                            height: '50px',
                                            color: '#6F8BFF',
                                            fontSize: '1.1rem',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        <PeopleAltOutlinedIcon />
                                    </span>
                                    <span className="ml-2">
                                        <p style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '0px', color: '#0A2938' }}>
                                            {analysisData?.leadsMediumPriority}
                                        </p>
                                        <small style={{ color: '#3B5460', fontSize: '14px' }}>Leads in medium priority</small>
                                    </span>
                                </div>
                            </Card>
                            <Card
                                className="salesCard d-flex align-items-center justify-content-center"
                                style={{ borderRadius: '20px', backgroundColor: '#fff', height: '148px' }}
                            >
                                <div className="d-flex align-items-center mb-2">
                                    <span
                                        style={{
                                            backgroundColor: '#E6F7EE',
                                            padding: '10px',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '50px',
                                            height: '50px',
                                            color: '#33BB77',
                                            fontSize: '1.1rem',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        <PeopleAltOutlinedIcon />
                                    </span>
                                    <span className="ml-2">
                                        <p style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '0px', color: '#0A2938' }}>
                                            {analysisData?.leadsHighPriority}
                                        </p>
                                        <small style={{ color: '#3B5460', fontSize: '14px' }}>Leads in high priority</small>
                                    </span>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default Index;
