import { Input, Button, Table, Select, DatePicker, Skeleton, Collapse, Tag, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { EditOutlined, ExclamationCircleOutlined, EyeInvisibleOutlined, SearchOutlined } from '@ant-design/icons';
import { decrypt } from '../../../utils/encrypt';
import { toast } from 'react-toastify';
import ApiUrl from '../../../network';

export const Index = () => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const userRole = localStorage.getItem('userRole');
    const userFirstName = localStorage.getItem('userFirstName');
    const userLastName = localStorage.getItem('userLastName');
    const [batches, setBatches] = useState([]);
    const [search, setSearch] = useState('');
    const [date, setDate] = useState('');
    const { RangePicker } = DatePicker;
    const [loading, setLoading] = useState(false);
    const [batchStatus, setBatchStatus] = useState([]);
    const [countries, setCountries] = useState([]);
    const [filteredBatches, setFilteredBatches] = useState([]);

    const fetchOrders = async (data) => {
        setLoading(true);
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/waybill/allbatchorders`;

            if (data) {
                if (data.startDate) urlParams.append('startDate', data.startDate);
                if (data.endDate) urlParams.append('endDate', data.endDate);
                if (data.orderType) urlParams.append('orderType', data.orderType);
                if (data.country) urlParams.append('country', data.country);
                if (data.batchNo) urlParams.append('batchNo', data.batchNo);
                if (data.orderNo) urlParams.append('orderNo', data.orderNo);
                if (data.batchStatus) urlParams.append('batchStatus', data.batchStatus);
                if (data.limit) urlParams.append('limit', data.limit);
            }
            url = `${url}?${urlParams.toString()}`;

            await fetch(url, {
                method: 'GET',
                headers: config.headers
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const batchs = decrypt(JSON.stringify(data.data), keyResult, ivResult);
                        setBatches(JSON.parse(batchs));
                        setLoading(false);
                    } else {
                        toast.error(data.message);
                        setLoading(false);
                    }
                });
        } catch (e) {
            toast.error(e.message);
            setLoading(false);
        }
    };
    const getBatchStatus = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/orderbatch/batchstatuses`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setBatchStatus(data.data);
                } else {
                    toast.error(data.message);
                }
            });
    };
    const fetchCountries = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(`${ApiUrl.BaseUrl}/country/activecountry`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error('Something went wrong');
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        fetchOrders();
        getBatchStatus();
        fetchCountries();
    }, []);
    useEffect(() => {
        if (Array.isArray(batches)) {
            const filteredData = batches.filter((batch) => {
                const searchLowerCase = search.toLowerCase();
                return (
                    batch.batchNo.includes(searchLowerCase) ||
                    batch.orderInfo.includes(searchLowerCase) ||
                    batch.country.includes(searchLowerCase) ||
                    batch.ordersDetails.includes(searchLowerCase) ||
                    batch.orderCompressed.includes(searchLowerCase) ||
                    batch.batchStatusId.name.toLowerCase().includes(searchLowerCase) ||
                    `${batch.userId.firstName} ${batch.userId.lastName}`.toLowerCase().includes(searchLowerCase) ||
                    new Date(batch.batchDate).toLocaleDateString('en-US', options).includes(searchLowerCase)
                );
            });

            const orderList = filteredData?.map((order) => {
                return {
                    key: order._id,
                    batchNo: order.batchNo,
                    orderNo: order.orderNo,
                    orderType: order.orderType,
                    country: order.country,
                    ordersDetails: order.ordersDetails,
                    orderCompressed: order.orderCompressed,
                    createdBy: `${order?.createdBy?.firstName.trim()} ${order?.createdBy?.lastName.trim()}`,
                    userId: order?.createdBy?._id,
                    rmaName: order?.rmaName,
                    batchDate: new Date(order.batchDate).toLocaleDateString('en-US', options),
                    rejectedKey: order.rejectedKey,
                    rejectedReason: order.rejectedReason,
                    rejectedBy: `${order?.rejectedBy?.firstName} ${order?.rejectedBy?.lastName}`,
                    verifyKey: order.verifyKey,
                    verifiedBy: `${order?.verifiedBy?.firstName} ${order?.verifiedBy?.lastName}`
                };
            });

            setFilteredBatches(orderList);
        }
    }, [batches, search]);

    const batchStatusOptions = batchStatus.map((item) => {
        return {
            label: item.name,
            value: item._id
        };
    });
    const countryOption = countries?.map((result) => ({
        value: result.name,
        label: result.name
    }));

    // eslint-disable-next-line consistent-return
    const checkCreatedBy = (record) => {
        try {
            // Ensure the record and createdBy properties are available
            if (!record || !record.createdBy) {
                toast.error('Something went wrong');
            }

            const createdByFullName = record?.createdBy.trim();
            const loggedInUserFullName = `${userFirstName.trim()} ${userLastName.trim()}`;

            // Check if the user who created the batch is the same as the logged-in user
            if (createdByFullName === loggedInUserFullName || record?.userId === localStorage.getItem('userId')) {
                // eslint-disable-next-line react/no-unescaped-entities
                return (
                    <Tooltip title="You can't verify a batch you generated">
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        <Tag color="grey">Can't verify</Tag>
                    </Tooltip>
                );
            }

            if (record.rejectedKey === true) {
                return (
                    <div className="d-flex">
                        <Tooltip title={record.rejectedReason}>
                            <Tag color="red">Declined</Tag>
                        </Tooltip>
                        {createdByFullName === loggedInUserFullName && (
                            <Tag
                                color="#FF740F"
                                style={{ cursor: 'pointer', border: '1px solid #FF740F' }}
                                onClick={() => (window.location.href = `/edit-batch/${record.key}?orderType=${record.orderType}`)}
                            >
                                Edit Batch
                            </Tag>
                        )}
                    </div>
                );
            }

            if (record.verifyKey === true) {
                return <Tag color="green">Verified</Tag>;
            }

            const buttonStyles = {
                color: '#FF740F',
                border: '1px solid #FF740F'
            };

            return (
                <Button
                    onClick={() => (window.location.href = `/waybill/batch/${record.key}?orderType=${record.orderType}`)}
                    text="More Details"
                    className="mr-2"
                    style={buttonStyles}
                >
                    Verify
                </Button>
            );
        } catch (error) {
            // Handle the error appropriately (e.g., return an error message)
            return <span className="text-danger">Error</span>;
        }
    };

    const columns = [
        {
            title: 'Batch date',
            dataIndex: 'batchDate',
            key: 'batchDate'
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country'
        },
        {
            title: 'Order no',
            dataIndex: 'orderNo',
            key: 'orderNo'
        },
        {
            title: 'Order Type',
            dataIndex: 'orderType',
            key: 'orderType'
        },
        {
            title: 'RMA',
            dataIndex: 'rmaName',
            key: 'rmaName'
        },
        {
            title: 'Batch by',
            dataIndex: 'createdBy',
            key: 'createdBy'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => checkCreatedBy(record)
        }
    ];
    const dateHanlder = (date, dateString) => {
        setDate(date);
        const data = {
            startDate: dateString[0],
            endDate: dateString[1]
        };
        fetchOrders(data);
    };

    return (
        <div>
            <div>
                <h1 style={{ fontSize: '30px' }}>Hello, Inventory Manager</h1>
                <p className="d-flex">
                    <ExclamationCircleOutlined style={{ color: '#4B6EFF', marginRight: '5px' }} /> You can generate a waybill after you have
                    verified a batched order
                </p>
            </div>
            <Collapse accordion>
                <Collapse.Panel key={1} header="Hide filters" className="mb-4">
                    <div className="batchFilters p-2 mb-2">
                        <RangePicker
                            size="large"
                            format="YYYY-MM-DD"
                            className="datePicker filterInput"
                            onChange={(date) => {
                                fetchOrders({ startDate: date[0], endDate: date[1] });
                            }}
                        />
                        <div>
                            <Select
                                showSearch
                                allowClear
                                size="large"
                                placeholder="Filter By Country"
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                className=" p-2 filterInput"
                                options={countryOption}
                                onChange={(value) => {
                                    fetchOrders({ country: value });
                                }}
                            />
                        </div>
                        <div>
                            <Select
                                showSearch
                                allowClear
                                size="large"
                                placeholder="Filter By Status"
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                className="p-2 filterInput"
                                options={batchStatusOptions}
                                onChange={(value) => {
                                    fetchOrders({ batchStatus: value });
                                }}
                            />
                        </div>
                        <div>
                            <Select
                                showSearch
                                allowClear
                                size="large"
                                placeholder="Filter By Order Type"
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                className="p-2 filterInput"
                                options={[
                                    {
                                        label: 'Sales Drive',
                                        value: 'SalesDrive'
                                    },
                                    {
                                        label: 'Regular',
                                        value: 'Regular'
                                    }
                                ]}
                                onChange={(value) => {
                                    fetchOrders({ orderType: value });
                                }}
                            />
                        </div>
                        <div>
                            <Select
                                showSearch
                                allowClear
                                size="large"
                                placeholder="Filter By Order Limit"
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                className=" p-2 filterInput"
                                options={[
                                    {
                                        label: '100',
                                        value: '100'
                                    },
                                    {
                                        label: '200',
                                        value: '200'
                                    },
                                    {
                                        label: '300',
                                        value: '300'
                                    },
                                    {
                                        label: '400',
                                        value: '400'
                                    },
                                    {
                                        label: '500',
                                        value: '500'
                                    }
                                ]}
                                onChange={(value) => {
                                    fetchOrders({ limit: value });
                                }}
                            />
                        </div>
                    </div>
                </Collapse.Panel>
            </Collapse>
            <div className="bg-white p-2">
                {loading ? (
                    <div>
                        <Skeleton style={{ padding: '10px 0' }} active />
                    </div>
                ) : (
                    <Table columns={columns} scroll={{ x: 'calc(700px + 50%)', y: 480 }} dataSource={filteredBatches} />
                )}
            </div>
        </div>
    );
};

export default Index;
