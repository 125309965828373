/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import Button from '@mui/material/Button';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import PageHeader from '../../components/PageHeader';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';

import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import useScriptRef from 'hooks/useScriptRef';

// import styled from 'styled-components';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const RetailerOrderHistory = () => {
    // page states

    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [orderInfo, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const handleShowClick = (row) => {
        if (scriptedRef.current) {
            // console.log(row);
            history(`/viewRetailerOrderHistory/${row._id}`);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderInfo.length) : 0;

    const { id } = useParams();

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };
        setLoading(true);
        fetch(ApiUrl.RetailerOrderHistoryUrl + id, config)
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    console.log('order', data.data);
                    setOrders(data.data);
                } else {
                    toast.error(data.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    return (
        <>
            <Grid item xs={12} sm={12}>
                <br />
                <PageHeader title="Order History" subTitle="Home | Configuration | Channel Partners | Retail | Order History" />
                <br />
                <MainCard title="">
                    <br />
                    <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                        <Button component={Link} to="/retail" type="submit" variant="contained" color="error">
                            Back
                        </Button>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table aria-label="caption table">
                            <TableHead>
                                <TableRow>
                                    {loading ? (
                                        <TableCell component="th" colSpan="4" align="center">
                                            {' '}
                                            <CircularProgress size={20} thickness={4} />
                                            {'LOADING...... '}
                                        </TableCell>
                                    ) : (
                                        <>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                #
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Order Date
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Channel
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Channel Partner
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Sales Person
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Order Value
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Exp.Cash
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Order Status
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Payment Status
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Actions
                                            </TableCell>
                                        </>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0 ? orderInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : orderInfo).map(
                                    (row, index) => (
                                        <TableRow key={row._id}>
                                            <TableCell component="th" scope="row">
                                                {index + 1}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {toTimestamp(row?.orderDate)}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row?.newChannelId?.name ?? ''}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {row?.newChannelPartnerId?.partnerName ?? ''}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row?.salesTeamId?.name ?? ''}
                                                {row?.salesTeamId?.firstName ?? ''}
                                                {row?.salesTeamId?.lastName ?? ''}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {Number(row?.orderValue).toFixed(2)}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {Number(row?.expCashCollected).toFixed(2)}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {row.orderStatusId}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row?.newPaymentStatusId?.name ?? ''}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <IconButton aria-label="view" color="warning" onClick={() => handleShowClick(row)}>
                                                    preview
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        component="div"
                        count={orderInfo.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </MainCard>
            </Grid>
        </>
    );
};

export default RetailerOrderHistory;
