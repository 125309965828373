/* eslint-disable no-bitwise */
/* eslint-disable jsx-a11y/label-has-associated-control */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import ApiUrl from 'network';
import { toast } from 'react-toastify';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import axios from 'axios';

import useScriptRef from 'hooks/useScriptRef';
import Autocomplete from '@mui/material/Autocomplete';

import nologo from 'assets/images/nologo.png';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// import S3 from 'react-aws-s3';

const Input = styled('input')({
    display: 'none'
});

const SamplePage = () => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const history = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [roleId, setRole] = useState('');
    const [strength, setStrength] = useState(0);
    const [level, setLevel] = useState();
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [countries, setCountries] = useState([]);
    const [currency, setcurrency] = useState('');
    const [cityId, setcityId] = useState('');
    const [cityData, setCityData] = useState([]);
    const [country, setCountry] = useState('');

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const changePassword = (value) => {
        const temp = strengthIndicator(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };
    const retrieveAllActiveCountry = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.ActiveCountryUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllCityInfo = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.CityUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCityData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const photo = localStorage.getItem('photo');

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        const userId = localStorage.getItem('userId');
        setLoading2(true);
        fetch(ApiUrl.ShowUserUrl + userId, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setRole(result?.data?.roleId?.name ?? '');
                    setfirstName(result?.data?.firstName ?? '');
                    setlastName(result?.data?.lastName ?? '');
                    setEmail(result?.data?.email ?? '');
                    setcurrency(result?.data?.currency ?? '');
                    setCountry(result?.data?.country ?? '');
                    setcityId(result?.data?.cityId?._id ?? '');
                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                }
            })
            .finally(() => {
                setLoading2(false);
            });
        changePassword('123456');
        retrieveAllActiveCountry();
        retrieveAllCityInfo();
    }, []);

    const selectedFile = (e) => {
        // setFile(e.target.files[0]);
        // console.log('file', e.target.files[0]);
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        setLoading3(true);
        const userInfo = localStorage.getItem('userToken');
        axios
            .post(ApiUrl.UploadPhotoUrl, formData, {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => {
                // console.log('result', result.data.data);
                if (result.data.status === true) {
                    toast.success(result.data.message);
                    if (scriptedRef.current) {
                        // console.log('data', result.data.data);
                        localStorage.setItem('photo', result?.data?.data ?? '');
                        setLoading3(false);
                        setTimeout(() => {
                            history('/sample-page');
                            // eslint-disable-next-line no-restricted-globals
                            location.reload();
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading3(false);
                }
            })
            .catch((err) => {
                toast.error(err);
                setLoading3(false);
            });
    };

    const retrieveAllCity = async (id) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.CityFilterUrl + id, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCityData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const filterCountry = (event) => {
        const newFilter = countries.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        newFilter.forEach((option) => {
            setcurrency(option.currency);
            retrieveAllCity(option?._id);
        });
    };
    const handleCityChange = (event) => {
        setcityId(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const userInfo = localStorage.getItem('userToken');
        // const userCurrency = localStorage.getItem('userCurrency');

        const data = {
            firstName,
            lastName,
            email,
            password,
            currency,
            country,
            cityId
        };

        fetch(ApiUrl.UpdateProfileUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                Accept: 'application/form-data',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                // console.log('result', result);
                if (result.status === true) {
                    toast.success(result.message);
                    if (scriptedRef.current) {
                        localStorage.setItem('userFirstName', result.data.firstName);
                        localStorage.setItem('userLastName', result.data.lastName);
                        localStorage.setItem('userEmail', result.data.email);
                        localStorage.setItem('userCurrency', result.data.currency);
                        setTimeout(() => {
                            history('/');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                    console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <br />
                            <PageHeader title="Settings" subTitle="Home | Settings | Edit Profile" />
                            <br />
                            <MainCard title="Edit Profile">
                                <Stack direction="row" alignItems="center" spacing={4}>
                                    <Avatar variant="rounded" src={photo ?? nologo} sx={{ width: 86, height: 86 }} />
                                    <label htmlFor="contained-button-file">
                                        <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={selectedFile} />
                                        <Button variant="contained" component="span" disabled={loading3}>
                                            {loading3 ? 'Uploading ...' : 'Upload'}
                                        </Button>
                                    </label>
                                </Stack>
                                <br />

                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        fullWidth
                                        label="Account Type"
                                        disabled
                                        margin="normal"
                                        name="channelId"
                                        type="text"
                                        value={roleId}
                                        onChange={(e) => setRole(e.target.value)}
                                    />
                                    <br />
                                    <TextField
                                        fullWidth
                                        label="Default Currency"
                                        disabled
                                        margin="normal"
                                        name="currency"
                                        type="text"
                                        value={currency}
                                        onChange={(e) => setcurrency(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <Autocomplete
                                        id="country-select-demo"
                                        autoHighlight
                                        value={country}
                                        onChange={(event, newValue) => {
                                            setCountry(newValue);
                                            filterCountry(newValue);
                                        }}
                                        options={countries.map((option) => option.name)}
                                        isOptionEqualToValue={(option, value) => option.code === value.code}
                                        renderInput={(params) => <TextField {...params} label="Choose a country" />}
                                    />
                                    <br />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Choose a City</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={cityId}
                                            label="City"
                                            onChange={handleCityChange}
                                        >
                                            {cityData.map((attr) => (
                                                <MenuItem key={attr._id} value={attr._id}>
                                                    {attr?.city ?? ''}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <br />

                                    <TextField
                                        fullWidth
                                        label="First Name"
                                        margin="normal"
                                        name="firstName"
                                        type="text"
                                        value={firstName}
                                        onChange={(e) => setfirstName(e.target.value)}
                                    />
                                    <br />

                                    <TextField
                                        fullWidth
                                        label="Last Name"
                                        margin="normal"
                                        name="lastName"
                                        type="text"
                                        value={lastName}
                                        onChange={(e) => setlastName(e.target.value)}
                                    />

                                    <br />

                                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                        <InputLabel htmlFor="outlined-adornment-email-register">Email Address </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-email-register"
                                            type="email"
                                            value={email}
                                            // value={values.email}
                                            name="email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            inputProps={{}}
                                        />
                                    </FormControl>

                                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                        <InputLabel htmlFor="outlined-adornment-password-register">New Password</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password-register"
                                            type={showPassword ? 'text' : 'password'}
                                            value={password}
                                            // value={values.password}
                                            name="password"
                                            label="Password"
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                                changePassword(e.target.value);
                                            }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        size="large"
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            inputProps={{}}
                                        />
                                    </FormControl>

                                    {strength !== 0 && (
                                        <FormControl fullWidth>
                                            <Box sx={{ mb: 2 }}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item>
                                                        <Box
                                                            style={{ backgroundColor: level?.color }}
                                                            sx={{ width: 85, height: 8, borderRadius: '7px' }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="subtitle1" fontSize="0.75rem">
                                                            {level?.label}
                                                        </Typography>
                                                        <Typography variant="subtitle1" fontSize="0.75rem">
                                                            (Min. 8 characters with a letter, number and a special characters)
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </FormControl>
                                    )}

                                    <Box display="flex" justifyContent="space-between">
                                        <Button component={Link} to="/" type="submit" variant="contained" color="error">
                                            Reset
                                        </Button>

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            disabled={!firstName || !lastName || !email || !password || loading}
                                        >
                                            {loading ? 'Submitting ..' : 'Submit'}
                                        </Button>
                                    </Box>
                                </form>
                            </MainCard>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default SamplePage;
