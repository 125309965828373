import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

const TodoList = ({ todos }) => {
    return (
        <div className="todo-dropdown">
            {todos.map((todo) => {
                const { _id, name, status } = todo;
                return (
                    <FormGroup key={_id}>
                        <FormControlLabel control={<Checkbox defaultChecked={status} />} label={name} />
                    </FormGroup>
                );
            })}
        </div>
    );
};
export default TodoList;
