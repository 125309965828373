// import dashboard from './dashboard';

import partSourcing from './partSourcing';
import other from './other';
import ordersreporting from './ordersreport';

// ==============================|| MENU ITEMS dashboard ||============================== //

const partsItems = {
    items: [partSourcing, ordersreporting, other]
};

export default partsItems;
