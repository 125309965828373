// import dashboard from './dashboard';

import podlead from './podLead';
import other from './other';
// import salesreport from './salesreport';

// ==============================|| MENU ITEMS dashboard ||============================== //

const podleadItems = {
    items: [podlead, other]
};

export default podleadItems;
