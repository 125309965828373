import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button as MuiButton } from '@mui/material';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import LoadingButton from '@mui/lab/LoadingButton';
import { usePriorityContext } from 'context/order-priority/priority-context';

const ModalWrapper = styled.div`
    .modal-actions {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 20px;
    }

    .title {
        color: #ff740f;
        font-weight: 800;
        font-size: 1.5rem;
        text-align: center;
        padding: 1rem 0;
    }

    .cancel-btn {
        background-color: #193855;
        margin-left: -3rem;
    }

    .save-btn {
        background-color: #ff740f;
        margin-right: -2rem;
    }

    .content {
        padding: 1rem 2rem;
        margin-bottom: 50px;
    }

    .form-align {
        margin-bottom: 20px;
    }
`;

const AddPriorityModal = ({ handleClose, show }) => {
    const { getBatchStagingData, batchdata, stagingarea, addOrderPriority, isLoading } = usePriorityContext();
    const [priorityDate, setpriorityDate] = React.useState(new Date());
    const [stagingAreaId, setStagingAreaId] = React.useState('');
    const [batchId, setBatchId] = React.useState('');

    React.useEffect(() => {
        getBatchStagingData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const priorityObject = {
        priorityDate,
        stagingAreaId,
        batchId
    };

    const handlePriorityCreate = () => {
        addOrderPriority(priorityObject);
        setStagingAreaId('');
        setBatchId('');
        setTimeout(() => {
            handleClose();
        }, 2000);
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} style={{ marginTop: '90px' }}>
                <ModalWrapper>
                    <Modal.Title>
                        <h5 className="title">Create Priority</h5>
                        <hr />
                    </Modal.Title>
                    <Modal.Body>
                        <div className="content">
                            {/* Pick date */}
                            <div className="form-align">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Date"
                                        inputFormat="MM/dd/yyyy"
                                        value={priorityDate}
                                        onChange={(value) => setpriorityDate(value)}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="form-align">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select2-label">Batch No.</InputLabel>
                                    <Select
                                        labelId="demo-simple-select2-label"
                                        id="demo-simple-select2"
                                        value={batchId}
                                        label="Staging area"
                                        onChange={(e) => setBatchId(e.target.value)}
                                    >
                                        {batchdata.map((item, index) => (
                                            <MenuItem key={index} value={item._id}>
                                                {item.batchNo}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="form-align">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select2-label">Staging Area</InputLabel>
                                    <Select
                                        labelId="demo-simple-select2-label"
                                        id="demo-simple-select2"
                                        value={stagingAreaId}
                                        label="Staging area"
                                        onChange={(e) => setStagingAreaId(e.target.value)}
                                    >
                                        {stagingarea.map((item, index) => (
                                            <MenuItem key={index} value={item._id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </Modal.Body>
                    <div className="modal-actions">
                        <MuiButton variant="contained" onClick={handleClose} className="cancel-btn">
                            Cancel
                        </MuiButton>
                        <LoadingButton onClick={handlePriorityCreate} loading={isLoading} variant="contained" className="save-btn">
                            Prioritize
                        </LoadingButton>
                    </div>
                </ModalWrapper>
            </Modal>
        </>
    );
};
export default AddPriorityModal;
