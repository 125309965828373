// assets
import { IconKey, IconTruckDelivery } from '@tabler/icons';
import DashboardIcon from '@mui/icons-material/Dashboard';
// import Person from '@mui/icons-material/Person';
import CarCrashIcon from '@mui/icons-material/CarRental';
// import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import Scale from '@mui/icons-material/Scale';
// import Shop from '@mui/icons-material/Shop2';
import Trans from '@mui/icons-material/Money';
import CollectionsIcon from '@mui/icons-material/Collections';
import BugReportIcon from '@mui/icons-material/BugReport';
import FactCheckIcon from '@mui/icons-material/FactCheck';
// import { SiSimpleanalytics } from 'react-icons/si';
// constant
const icons = {
    IconKey,
    IconTruckDelivery,
    DashboardIcon,
    Scale,
    Trans,
    CarCrashIcon,
    CollectionsIcon,
    BugReportIcon,
    FactCheckIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //
const userRole = localStorage.getItem('userRole');
const Hr = {
    id: 'hrmanager',
    title: 'HR Manager',
    type: userRole,
    children: [
        {
            id: 'home',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: icons.DashboardIcon,
            breadcrumbs: false
        },
        {
            id: 'checkinlist',
            title: 'Check In List',
            type: 'item',
            icon: icons.FactCheckIcon,
            url: '/checkins'
        },
        {
            id: 'attendance',
            title: 'Attendance',
            type: 'collapse',
            icon: icons.FactCheckIcon,
            children: [
                // {
                //     id: 'imageUpload',
                //     title: 'Image Upload',
                //     type: 'item',
                //     url: '/image-upload',
                //     breadcrumbs: false
                // },

                {
                    id: 'checkIn',
                    title: 'check In',
                    type: 'item',
                    url: '/check-in',
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'orders',
            title: 'Orders',
            type: 'collapse',
            icon: icons.Scale,
            children: [
                {
                    id: 'orders',
                    title: 'Orders',
                    type: 'item',
                    url: '/order',
                    icon: icons.Scale,
                    breadcrumbs: false
                },
                {
                    id: 'proformacssorders',
                    title: 'Proforma Orders',
                    type: 'item',
                    url: '/proformacssorders',
                    icon: icons.Trans,
                    breadcrumbs: false
                },
                {
                    id: 'presoldorders',
                    title: 'PreSold Orders',
                    type: 'item',
                    url: '/presoldorders',
                    icon: icons.Trans,
                    breadcrumbs: false
                },
                // {
                //     id: 'darkstores',
                //     title: 'Dark Stores Orders',
                //     type: 'item',
                //     url: '/darkstores',
                //     icon: icons.Trans,
                //     breadcrumbs: false
                // },
                {
                    id: 'csssalesdriveorders',
                    title: 'Sales Drive Orders',
                    type: 'item',
                    icon: icons.Scale,
                    url: '/csssalesdriveorders'
                },
                {
                    id: 'retentionorders',
                    title: 'Retention Drive Orders',
                    type: 'item',
                    icon: icons.Scale,
                    url: '/retentionorders',
                    breadcrumbs: false
                },

                {
                    id: 'conversionorders',
                    title: 'Conversion Drive Orders',
                    type: 'item',
                    icon: icons.Scale,
                    url: '/conversionorders',
                    breadcrumbs: false
                },
                {
                    id: 'icons',
                    title: 'Order Issues',
                    type: 'collapse',
                    icon: icons.BugReportIcon,
                    children: [
                        {
                            id: 'orderissues',
                            title: 'Order Issues',
                            type: 'item',
                            url: '/orderissues',
                            breadcrumbs: false
                        },
                        {
                            id: 'resolvedIssue',
                            title: 'Resolved Order Issues',
                            type: 'item',
                            url: '/resolvedIssue',
                            breadcrumbs: false
                        }
                    ]
                }
            ]
        }
    ]
};

export default Hr;
