import React, { useEffect, useState } from 'react';

import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
// import Box from '@mui/material/Box';
// import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
// import TablePagination from '@mui/material/TablePagination';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Controls from '../../components/controls/Controls';
import { Search } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
// import ApiUrl from 'network';

// const { BaseUrl } = ApiUrl;

const OrderList = () => {
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState([]);
    // const [orderStatus, setOrderStatus] = useState('');
    const [list, setList] = useState([]);
    const [limit, setLimit] = useState(5);
    const margin = 5;

    const runList = (array) => {
        if (array.length > 0) {
            const arr = [];
            let len = 0;
            if (array.length === limit) {
                len = limit;
            } else if (array.length > limit) {
                len = limit;
            } else {
                len = array.length;
            }
            array.forEach((item, index) => {
                if (index < len) {
                    arr.push(item);
                }
            });

            setList(arr);
            setOrder(array);
        }
    };

    useEffect(() => {
        async function getOrders() {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const url = 'http://localhost:3000/api/orderlist';
            // const url = 'https://api.garagemobility.com/api/orderlist';
            try {
                const result = await (await fetch(url, config)).json();
                const { message, data } = result;
                // console.log(data);

                if (message === 'success') {
                    runList(data);
                }
                setLoading(false);
            } catch (e) {
                setLoading(false);
                console.log(e);
            }
        }
        getOrders();
    }, [limit]);

    const goBack = () => {
        if (limit > margin) {
            const val = limit - margin;
            setLimit(val);
        } else {
            setLimit(margin);
        }
    };

    const goForward = () => {
        if (order.length > limit) {
            const val = limit + margin;
            setLimit(val);
        } else {
            setLimit(limit);
        }
    };

    const filterList = (text) => {
        if (text !== '') {
            const arr = [];
            list.forEach((item) => {
                const name = item.name.toLowerCase();
                if (name.indexOf(text.toLowerCase()) > -1) {
                    arr.push(item);
                }
            });
            setList(arr);
        } else {
            runList(order);
        }
    };

    const theadTitle = [
        '#',
        'Order Date',
        'Channel',
        'Channel Partner',
        'Sales Person',
        'Order Value',
        'Exp. Cash',
        'Order Status',
        'Pmt Status',
        'Actions'
    ];

    const tHead = theadTitle.map((item, index) => (
        <React.Fragment key={index}>
            <th className="font-15">{item}</th>
        </React.Fragment>
    ));

    const tBody =
        list.length === 0 ? (
            <tr>
                <td colSpan="10" className="center-text">
                    Empty list
                </td>
            </tr>
        ) : (
            list.map((item, index) => (
                <React.Fragment key={index}>
                    <tr className="font-13 font-roboto">
                        <td>{index + 1}</td>
                        <td>{new Date(item.orderDate).toDateString()}</td>
                        <td>{item.channel}</td>
                        <td>{item.name}</td>
                        <td>{item?.sales ?? ''}</td>
                        <td>{item.orderValue.toFixed(2)}</td>
                        <td>{item.expCash}</td>
                        <td>{item.orderStatus}</td>
                        <td>{item.paymentStatus}</td>
                        <td>Pdf</td>
                    </tr>
                </React.Fragment>
            ))
        );

    return (
        <>
            <PageHeader title="Order List" subTitle="Home | Order List" />

            <MainCard title={`(${order.length}) Order List`}>
                <div className="row">
                    <div className="col-3 padding-all-5">
                        <Controls.Input
                            label="Search Partner name"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                )
                            }}
                            onChange={(e) => filterList(e.target.value)}
                        />
                    </div>
                </div>
                <br />

                <TableContainer component={Paper}>
                    <Table className="garage-table font-roboto" sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead className="black-text">
                            <TableRow>{tHead}</TableRow>
                        </TableHead>
                        {loading ? (
                            <TableBody>
                                <tr>
                                    <td colSpan="7">
                                        <div className="flex-column justify-content-center align-items-center">
                                            <CircularProgress />
                                        </div>
                                    </td>
                                </tr>
                            </TableBody>
                        ) : (
                            <TableBody>{tBody}</TableBody>
                        )}
                    </Table>
                </TableContainer>
                <br />
                <br />

                <div className="flex-row-reverse">
                    <div className="width-20 width-lx-25 width-l-30 width-m-40 width-s-60">
                        <div className="row align-items-center">
                            <div className="col-6 padding-all-5">
                                <Select
                                    label="Page"
                                    labelId="select-label"
                                    id="select-page"
                                    value={limit}
                                    onChange={(e) => setLimit(e.target.value)}
                                    className="gar-select"
                                >
                                    <MenuItem value={0}>Page</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={order.length}>All</MenuItem>
                                </Select>
                            </div>
                            <div className="col-3 padding-all-5">
                                <button onClick={() => goBack()} type="button" className="none">
                                    <ChevronLeftIcon className="font-20" />
                                </button>
                            </div>
                            <div className="col-3 padding-all-5">
                                <button onClick={() => goForward()} type="button" className="none">
                                    <ChevronRightIcon className="font-20" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </MainCard>
        </>
    );
};

export default OrderList;
