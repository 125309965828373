import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { encrypt, decrypt } from '../../utils/encrypt';
import ApiUrl from 'network';

import ClearanceTable from 'components/ClearanceTable/ClearanceTable';
import { Box, List, ListItem, Typography } from '@mui/material';

const Index = () => {
    const [confirmedList, setConfirmedList] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [countries, setCountries] = useState([]);
    const [clearedRma, setClearedRma] = useState([]);
    const [accumulatedFilters, setAccumulatedFilters] = useState({});
    const [orderStatus, setOrderStatus] = useState([]);
    const [limit, setLimit] = useState(10);
    const [loading, setLoading] = useState(false);
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const country = localStorage.getItem('country');

    const limitNumber = 100;

    const limitOptions = [
        {
            label: '100',
            value: limitNumber * 1
        },
        {
            label: '200',
            value: limitNumber * 2
        },
        {
            label: '300',
            value: limitNumber * 3
        },
        {
            label: '400',
            value: limitNumber * 4
        },
        {
            label: '500',
            value: limitNumber * 5
        },
        {
            label: '600',
            value: limitNumber * 6
        },
        {
            label: '700',
            value: limitNumber * 7
        },
        {
            label: '800',
            value: limitNumber * 8
        },
        {
            label: '900',
            value: limitNumber * 9
        },
        {
            label: '1000',
            value: limitNumber * 10
        }
    ];

    // get query data
    const getQueryData = async (data) => {
        try {
            setLoading(true);
            const url = `${ApiUrl.BaseUrl}/mainorders/querydata?country=${accumulatedFilters?.country || country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setCountries(answer.country);
                        setClearedRma(answer.relationshipManagers);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const countryOptions = countries?.map((item) => {
        return { label: item?.name, value: item?.name };
    });

    const rmaOptions = clearedRma?.map((item) => {
        return { label: item?.name, value: item?.name };
    });

    // get all cleared orders
    const getClearedListOrders = async (data) => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/collection/clearanceSheet`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            // Append accumulated filters to URL
            Object.entries(accumulatedFilters).forEach(([key, value]) => {
                if (value) {
                    urlParams.append(key, value);
                }
            });

            url = `${url}?${urlParams.toString()}`;

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        const filteredRma = answer?.filter((name) => {
                            return name?.name === accumulatedFilters?.rmcc;
                        });

                        const filteredClearanceStatus = answer?.filter((status) => {
                            return status?.balStatus === accumulatedFilters?.clearanceStatus;
                        });

                        // filter by clearance status
                        if (filteredClearanceStatus.length > 0) {
                            setConfirmedList(filteredClearanceStatus);
                        } // filter by rma or cc name
                        else if (filteredRma.length > 0) {
                            setConfirmedList(filteredRma);
                        } else {
                            setConfirmedList(answer);
                        }
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getClearedListOrders();
        getQueryData();
    }, [accumulatedFilters]);

    // filter country
    const filterCountry = (value) => {
        const data = {
            country: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter RMA
    const filterRmaPerson = (value) => {
        const data = {
            rmcc: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    const handleLimit = (value) => {
        setLimit(value);
        const data = {
            limit: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter date
    const filterDate = (value, dateString) => {
        setSelectedDate(value);
        const data = {
            startDate: dateString[0],
            endDate: dateString[1]
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter Clearance Status
    const filterClearanceStatus = (value) => {
        const data = {
            clearanceStatus: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    padding: '0 0 16px 0',
                    borderBottom: '1px dashed #E7EAEB',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    margin: '32px auto 24px'
                }}
            >
                <Typography sx={{ fontWeight: 500, fontSize: '1.875rem', color: '#0A2938', padding: 0 }}>Clearance sheet</Typography>
            </Box>
            <ClearanceTable
                confirmedList={confirmedList}
                countryOptions={countryOptions}
                filterCountry={filterCountry}
                rmaOptions={rmaOptions}
                loading={loading}
                filterRmaPerson={filterRmaPerson}
                selectedDate={selectedDate}
                filterDate={filterDate}
                handleLimit={handleLimit}
                limitOptions={limitOptions}
                orderStatus={orderStatus}
                filterClearanceStatus={filterClearanceStatus}
            />
        </div>
    );
};

export default Index;
