/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
// import * as XLSX from 'xlsx';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../../components/PageHeader';
import Box from '@mui/material/Box';
import Controls from '../../../components/controls/Controls';
import { Search } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
// import ViewIcon from '@mui/icons-material/Preview';
// import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
// import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// // import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// // import InputLabel from '@mui/material/InputLabel';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
// import Form from 'react-bootstrap/Form';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import nologo from 'assets/images/nologo.png';

// import styled from 'styled-components';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 3
    }
}));

const Parts = () => {
    // page states

    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [partsInfo, setParts] = useState([]);
    const [partsData, setpartsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    // const [open, setOpen] = useState(false);
    // const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    // const [file, setFile] = useState('');
    // const [uploadedfile, setUploadedFile] = useState({});

    const retrieveAllParts = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.PartFleetAllUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        console.log(data.data);
                        setParts(data.data);
                        setpartsData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    // const selectedFile = (e) => {
    //     setFile(e.target.files[0]);
    // };

    // const onSubmit = async (e) => {
    //     e.preventDefault();
    //     // console.log('e', e);
    //     const formData = new FormData();
    //     formData.append('file', file);
    //     // console.log('file', file);
    //     // console.log('file type', file.type);
    //     const userInfo = localStorage.getItem('userToken');
    //     if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    //         try {
    //             const data = await file.arrayBuffer();
    //             const workbook = XLSX.read(data);
    //             const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    //             const jsonData = XLSX.utils.sheet_to_json(worksheet);
    //             // console.log('workbook', JSON.stringify(jsonData));
    //             fetch(ApiUrl.UploadPartsUrl, {
    //                 method: 'POST',
    //                 headers: {
    //                     'auth-token': userInfo,
    //                     'Content-Type': 'application/json'
    //                 },

    //                 body: JSON.stringify(jsonData)
    //             })
    //                 .then((res) => res.json())
    //                 .then((result) => {
    //                     if (result.status) {
    //                         toast.success(result.message);
    //                         setLoading(false);
    //                         if (scriptedRef) {
    //                             handleClose();
    //                             setTimeout(() => {
    //                                 retrieveAllParts();
    //                             }, 3000);
    //                         }
    //                     } else {
    //                         toast.error(result.data);
    //                         setLoading(false);
    //                     }
    //                 })
    //                 .finally(() => {
    //                     setLoading(false);
    //                 });
    //         } catch (e) {
    //             console.log(e);
    //         }
    //     } else {
    //         toast.error('File Type not supported, kindly upload excel');
    //     }
    // };

    const handleEditClick = (row) => {
        if (scriptedRef.current) {
            history(`/viewfleetparts/${row._id}`);
        }
    };

    // const handlePriceChangesClick = (row) => {
    //     if (scriptedRef.current) {
    //         history(`/pricechanges/${row._id}`);
    //     }
    // };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // const handledataexport = () => {
    //     const wb = XLSX.utils.book_new();
    //     const ws = XLSX.utils.json_to_sheet(partsInfo);
    //     XLSX.utils.book_append_sheet(wb, ws, 'GaragePart');
    //     XLSX.writeFile(wb, 'Parts.xlsx');
    // };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - partsInfo.length) : 0;

    useEffect(() => {
        retrieveAllParts();
    }, []);

    // const handleDeleteClick = (row) => {
    //     swal({
    //         title: 'Are you sure?',
    //         text: `Once deleted, you will not be able to recover this Part!(${row.sku})`,
    //         icon: 'warning',
    //         buttons: true,
    //         dangerMode: true
    //     }).then((willDelete) => {
    //         if (willDelete) {
    //             swal('Poof! The Part has been deleted!', {
    //                 icon: 'success'
    //             });

    //             const data = {
    //                 id: row._id
    //             };
    //             const userInfo = localStorage.getItem('userToken');

    //             fetch(ApiUrl.RemovePartFleetUrl, {
    //                 method: 'DELETE',
    //                 headers: {
    //                     'auth-token': userInfo,
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify(data)
    //             })
    //                 .then((res) => res.json())
    //                 .then((result) => {
    //                     if (result.status === true) {
    //                         if (scriptedRef.current) {
    //                             retrieveAllParts();
    //                             // window.location.href = '/stage';
    //                             // history('/stage');
    //                         }
    //                     } else {
    //                         toast.error(result.data);
    //                     }
    //                 });
    //         } else {
    //             swal('The Part is safe!');
    //         }
    //     });
    // };
    const handleSearch = (event) => {
        const searchWord = event.target.value;

        const newFilter = partsInfo.filter((value) => value.sku.toLowerCase().includes(searchWord.toLowerCase()));

        if (searchWord === '') {
            setParts(partsData);
        } else {
            setParts(newFilter);
        }
    };

    return (
        <>
            <PageHeader title="Parts" subTitle="Home | Parts For Fleet" />
            <MainCard title="Parts List">
                <Box display="flex" justifyContent="flex-start">
                    <Grid>
                        <Controls.Input
                            label="Search Product"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                        />
                    </Grid>
                    <Box width={10} />

                    {/* <Button variant="contained" onClick={handleClickOpen} size="sm" className={classes.item} color="secondary">
                        Upload
                    </Button>

                    <Box width={10} /> */}

                    <Button component={Link} to="/addfleetpart" variant="contained" size="sm" className={classes.item}>
                        Add Part
                    </Button>
                    {/* <Box width={10} />
                    <Button onClick={handledataexport} variant="contained" size="sm" className={classes.item} color="info">
                        Download Excel
                    </Button> */}

                    {/* <Box width={10} />
                    <Button onClick={handledataproductsexport} variant="contained" size="sm" className={classes.item} color="error">
                        Download Products Excel
                    </Button> */}
                </Box>
                <br />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Product Image
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Product Name
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Product Family
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Brand
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Wholesale Price
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Garage Price
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Distributor
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Date
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 ? partsInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : partsInfo).map(
                                (row, index) => (
                                    <TableRow key={row._id}>
                                        <TableCell component="th" scope="row">
                                            {partsInfo.length - index}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Avatar variant="rounded" src={row.partimage ?? nologo} sx={{ width: 80, height: 50 }} />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.sku}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.productFamily}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.brand}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.wholeSalePrice}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.garagePrice}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row.distributor?.partnerName ?? ''}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {toTimestamp(row.createdAt)}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(row)}>
                                                <EditIcon />
                                            </IconButton>
                                            {/* <IconButton aria-label="delete" color="error" onClick={() => handleDeleteClick(row)}>
                                                <DeleteIcon />
                                            </IconButton> */}
                                            {/* <Tooltip title="Price Changes">
                                                <IconButton aria-label="view" color="success" onClick={() => handlePriceChangesClick(row)}>
                                                    <ViewIcon />
                                                </IconButton>
                                            </Tooltip> */}
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    component="div"
                    count={partsInfo.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

                {/* <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">Upload Parts</DialogTitle>
                    <Form onSubmit={onSubmit}>
                        <DialogContent>
                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Control
                                    type="file"
                                    onChange={selectedFile}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                />
                                <br /> <br />
                                <Form.Label>Expected columns</Form.Label>
                                <br /> <br />
                                (Note: distributor should be an ID from the Distributor Partner Page)
                                <br />
                                <br />
                                <Form.Label>
                                    sku, brand, distributor, productFamily, wholeSalePrice, retailPrice, garagePrice, applicableVins
                                </Form.Label>
                            </Form.Group>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" size="sm" className={classes.item} autoFocus onClick={handleClose} color="error">
                                Cancel
                            </Button>
                            <Button autoFocus variant="contained" type="submit" size="sm" className={classes.item}>
                                Upload
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog> */}
            </MainCard>
        </>
    );
};

export default Parts;
