/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Link, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import useScriptRef from 'hooks/useScriptRef';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import EditIcon from '@mui/icons-material/Edit';
// import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Form from 'react-bootstrap/Form';
import nologo from 'assets/images/nologo.png';
import Avatar from '@mui/material/Avatar';
import { cryptoUUID } from '../../utils/functions';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const AddOrder = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [orderDate, setorderDate] = useState(new Date());
    const [deliveryDate, setDeliveryDate] = useState(new Date());
    const [payDueDate, setPayDueDate] = useState(new Date());
    const [orderTypId, setOrderTypeRecord] = useState('');
    const [paymentStatusId, setpaymentStatusRecord] = useState('');
    const [partQtyInPieces, setpartQtyInPieces] = useState('');
    const [orderStatusId, setorderStatusRecord] = useState('');
    const [channelId, setChannelRecord] = useState('');
    const [channelPartnerId, setChannelPartnerRecord] = useState('');
    const [salesname, setsalesname] = useState('');
    const [dropOffLocation, setdropOffLocation] = useState('');
    const [expCashCollected, setExpectedCash] = useState('');
    const [actCashCollected, setActualCash] = useState('');
    const [cashOutStanding, setCashOutstanding] = useState('');
    const [perCashOutStanding, setperCashOut] = useState('');
    const [perMargin, setMargin] = useState('');
    const [daysPastDue, setdaysPastDue] = useState('');
    const [partnerContact, setPartnerContact] = useState('');
    const [partnerPercentSold, setpartnerPercentSold] = useState('0');
    const [creditrating, setCreditRating] = useState('');
    const [orderType, setOrderType] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState([]);
    const [orderStatus, setOrderStatus] = useState([]);
    const [channel, setChannel] = useState([]);
    const [channelpartner, setChannelPartner] = useState([]);
    const [carrier, setCarrier] = useState([]);
    const [part, setPart] = useState([]);
    const [pickdroptype, setPickDropType] = useState([]);
    const [carrierId, setCarrierId] = useState('');
    const [orderValue, setorderValue] = useState('');
    const [pickUpLocation, setPickupLocation] = useState('');
    const [carrierContact, setCarrierContact] = useState('');
    const [carrierNPS, setCarrierNPS] = useState('');
    const [carrierRating, setCarrierRating] = useState('');
    const [orderPickUpTime, setOrderPickupTime] = useState('');
    const [qty, setQty] = useState('');
    const [partid, setPartId] = useState('');
    const [skuId, setSkuId] = useState('');
    const [brand, setBrand] = useState('');
    const [wholeSalePrice, setwholeSalePrice] = useState('');
    const [garagePrice, setgaragePrice] = useState('');
    const [applicableVins, setapplicableVins] = useState('');
    const [pickUpTypeId, setPickupType] = useState('');
    const [dropOffTypeId, setDropoffType] = useState('');
    const [orderNotes, setOrderNotes] = useState('');
    // const [channelIds, setchannelId] = useState('');
    const [newChannelId, setNewChannelId] = useState('');
    const [newChannelPartnerId, setNewChannelPartnerId] = useState('');
    const [newOrdertypeId, setNewOrderTypeId] = useState('');
    const [newOrderStatusId, setNewOrderStatusId] = useState('');
    const [newPaymentStatusId, setNewPaymentStatusId] = useState('');
    const [newCarrierId, setnewCarrierId] = useState('');
    const [newPickupTypeId, setNewPickUpTypeId] = useState('');
    const [newDropoffTypeId, setNewDropoffTypeId] = useState('');
    // const [salesname, setSalesName] = useState('');
    // const [salesemail, setSalesEmail] = useState('');
    const [cash, setCash] = useState('');
    const [salesTeamId, setsalesTeamId] = useState('');
    const [indexValue, setIndexValue] = useState('');
    const [open2, setOpen2] = useState(false);

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [items, setItems] = useState([]);
    const [salesTeamInfo, setSalesTeam] = useState([]);
    const [partType, setpartType] = useState([]);
    const [supplier, setSupplier] = useState('');
    const [partimage, setpartimage] = useState('');
    const [state, setstate] = useState('');
    const [localId, setlocalId] = useState('');
    const handleCashChange = (event) => {
        setCash(event.target.value);
    };

    const retrieveAllPickDropType = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllActivePickDropType, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setPickDropType(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllPart = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.ActivePartUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setPart(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllCarrier = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.ActiveCarrierUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCarrier(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllChannelPartner = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllChannelPartnersOrders, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setChannelPartner(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllActiveChannel = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllActiveChannel, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setChannel(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllActiveOrderStatus = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllActiveOrderStatus, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrderStatus(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllActivePaymentStatus = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllActivePaymentStatus, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setPaymentStatus(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllOrderType = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllActiveOrderTypes, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrderType(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAlLsalesTeam = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.SalesTeamActiveSalesPage, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setSalesTeam(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // useEffect(() => {
    //     retrieveAllChannel();
    // }, []);

    useEffect(() => {
        retrieveAllActiveChannel();
        retrieveAllOrderType();
        retrieveAllActivePaymentStatus();
        retrieveAllActiveOrderStatus();
        retrieveAllChannelPartner();
        retrieveAllCarrier();
        retrieveAllPart();
        retrieveAllPickDropType();
        retrieveAlLsalesTeam();
        const ids = cryptoUUID();
        setlocalId(ids);
    }, []);

    const handleOrderDateChange = (newValue) => {
        setorderDate(newValue);
    };
    const handleDeliveryDateChange = (newValue) => {
        setDeliveryDate(newValue);
    };
    const handlePartType = (event) => {
        if (event.target.value === 'Boxes') {
            // console.log('qty size', partQtyInPieces);
            // console.log('qty', qty);
            if (partQtyInPieces === 0) {
                setpartType(event.target.value);
            } else {
                const size = 'Pieces';
                setpartType(size);
                const piece = Number(qty) * Number(partQtyInPieces);
                setQty(piece);
            }
        } else if (event.target.value === 'Rolls') {
            // console.log('qty size', partQtyInPieces);
            if (partQtyInPieces === 0) {
                setpartType(event.target.value);
            } else {
                const size = 'Pieces';
                setpartType(size);
                const piece = Number(qty) * Number(partQtyInPieces);
                setQty(piece);
            }
        } else if (event.target.value === 'Packs') {
            // console.log('qty size', partQtyInPieces);
            if (partQtyInPieces === 0) {
                setpartType(event.target.value);
            } else {
                const size = 'Pieces';
                setpartType(size);
                const piece = Number(qty) * Number(partQtyInPieces);
                setQty(piece);
            }
        } else if (event.target.value === 'Carton') {
            if (partQtyInPieces === 0) {
                setpartType(event.target.value);
            } else {
                const size = 'Pieces';
                setpartType(size);
                const piece = Number(qty) * Number(partQtyInPieces);
                setQty(piece);
            }
        } else {
            setpartType(event.target.value);
        }
    };

    const handlePayDueDateChange = (newValue) => {
        setPayDueDate(newValue);
        const DifferenceInTime = new Date().getTime() - new Date(newValue).getTime();
        // To calculate the no. of days between two dates
        const DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);
        const days = Math.round(DifferenceInDays);
        const newdays = days < 0 ? 0 : days;

        setdaysPastDue(newdays);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const handleSalesChange = (event) => {
        setsalesTeamId(event.target.value);
        const newFilter = salesTeamInfo.filter((value) => value._id.includes(event.target.value));
        newFilter.forEach((option) => {
            if (option?.country === 'Nigeria') {
                const fullname = `${option.firstName} ${option.lastName}`;
                setsalesname(fullname);
            } else if (option?.country === 'Cameroon') {
                const fullname = `${option.firstName} ${option.lastName}`;
                setsalesname(fullname);
            } else {
                setsalesname(option.name);
            }
        });
    };

    const onSubmitPartEdit = (event) => {
        event.preventDefault();
        const newList = Object.assign([], items);
        const data = { ...newList[indexValue] };
        data.garagePrice = garagePrice;
        data.qty = qty;
        data.partid = partid;
        data.partType = partType;
        data.skuId = skuId;
        data.applicableVins = applicableVins;
        data.brand = brand;
        data.wholeSalePrice = wholeSalePrice;
        data.supplier = supplier ?? '';
        data.partQtyInPieces = partQtyInPieces ?? 0;
        data.partimage = partimage ?? '';
        data.state = state ?? '';
        newList[indexValue] = data;
        setItems(newList);
        handleClose2();
        let sum = 0;
        let sum2 = 0;
        setorderValue(sum2);
        setExpectedCash(sum);
        newList.forEach((row) => {
            sum += Number(row.garagePrice) * Number(row.qty);
            // console.log('row data', row); expected cash
        });
        newList.forEach((row) => {
            sum2 += Number(row.wholeSalePrice) * Number(row.qty);
            // console.log('row data', row); order value
        });

        setorderValue(sum2);
        setExpectedCash(sum);
        const marginRate = (Number(sum) - Number(sum2)) / Number(sum2);
        const value = isFinite(marginRate) ? marginRate : 0;
        const margin = Number(value).toFixed(2);

        setMargin(margin);
    };

    const onSubmitPart = (event) => {
        event.preventDefault();
        // items, setItems
        setItems([
            ...items,
            {
                skuId,
                partid,
                qty,
                brand,
                partType,
                wholeSalePrice,
                garagePrice,
                applicableVins,
                supplier,
                partQtyInPieces,
                partimage,
                state
            }
        ]);

        handleClose();
        let sum = 0;
        let sum2 = 0;
        setorderValue(sum2);
        setExpectedCash(sum);
        const newList = Object.assign([], items);
        newList.forEach((row) => {
            sum += Number(row.garagePrice) * Number(row.qty);
            // console.log('row data', row); expected cash
        });
        newList.forEach((row) => {
            sum2 += Number(row.wholeSalePrice) * Number(row.qty);
            // console.log('row data', row); order value
        });

        sum += Number(garagePrice) * Number(qty);
        sum2 += Number(wholeSalePrice) * Number(qty);
        setorderValue(sum2);
        setExpectedCash(sum);
        const marginRate = (Number(sum) - Number(sum2)) / Number(sum2);
        const value = isFinite(marginRate) ? marginRate : 0;
        const margin = Number(value).toFixed(2);

        setMargin(margin);
    };

    // Submit Order
    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        // eslint-disable-next-line camelcase
        const local_id = localId;

        const data = {
            orderDate,
            channelId,
            newChannelId,
            channelPartnerId,
            newChannelPartnerId,
            orderValue,
            orderTypId,
            newOrdertypeId,
            dropOffLocation,
            deliveryDate,
            orderStatusId,
            newOrderStatusId,
            expCashCollected,
            actCashCollected,
            cashOutStanding,
            perCashOutStanding,
            perMargin,
            payDueDate,
            daysPastDue,
            paymentStatusId,
            newPaymentStatusId,
            carrierId,
            newCarrierId,
            orderPickUpTime,
            pickUpTypeId,
            newPickupTypeId,
            pickUpLocation,
            dropOffTypeId,
            newDropoffTypeId,
            orderNotes,
            items,
            salesTeamId,
            cash,
            salesname,
            local_id
        };

        fetch(ApiUrl.AddOrdersUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    // setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/order');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const filterCash = (event) => {
        const cashOut = expCashCollected - event;

        setCashOutstanding(cashOut);
        const percent = (cashOut / expCashCollected) * 100;

        setperCashOut(percent.toFixed(2));

        if (Number(expCashCollected) === Number(event)) {
            setpaymentStatusRecord('Paid');
            setNewPaymentStatusId('620d76a4ee512a471bf98c3e');
        } else if (cashOut < 0) {
            setpaymentStatusRecord('Paid');
            setNewPaymentStatusId('620d76a4ee512a471bf98c3e');
        } else if (cashOut > 0) {
            setpaymentStatusRecord('Pending');
            setNewPaymentStatusId('620d7693448d1be3b4ab1b08');
        }
    };

    const filterMargin = (event) => {
        const marginRate = (Number(event) - Number(orderValue)) / Number(orderValue);
        const value = isFinite(marginRate) ? marginRate : 0;
        const margin = Number(value).toFixed(2);

        setMargin(margin);

        const cashOut = Number(event) - Number(actCashCollected);

        setCashOutstanding(cashOut);
        const percent = (cashOut / Number(event)) * 100;

        setperCashOut(percent.toFixed(2));
        setActualCash(0);
    };

    const filterOptions = (event) => {
        const newFilter = channelpartner.filter((value) => (value.partnerName || '').toLowerCase().includes((event || '').toLowerCase()));

        newFilter.forEach((option) => {
            if (option.partnerName === event) {
                setPartnerContact(option.partnerContact);
                setpartnerPercentSold(option.percentOrderSold);
                setCreditRating(option.creditRating);
                setNewChannelPartnerId(option._id);
            }
        });
    };

    const filterCarrier = (event) => {
        const newFilter = carrier.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));

        newFilter.forEach((option) => {
            if (option.name === event) {
                setCarrierContact(option.carrierContact);
                setCarrierNPS(option.carrierNPS);
                setCarrierRating(option.carrierRating);
                setnewCarrierId(option._id);
            }
        });
    };

    const filterPart = (event) => {
        const newFilter = part.filter((value) => (value.sku || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('newFilter', newFilter);
        newFilter.forEach((option) => {
            if (option.sku === event) {
                setSkuId(option._id ?? '');
                setBrand(option.brand ?? '');
                setwholeSalePrice(option.wholeSalePrice ?? '');
                setgaragePrice(option.garagePrice ?? '');
                setapplicableVins(option.applicableVins ?? '');
                setpartQtyInPieces(option?.partQtyInPieces ?? 0);
                setpartimage(option?.partsImage ?? '');
                setstate(option?.imsstatus?.name);
                if (option?.stock) {
                    setSupplier(option?.stock);
                } else if (option?.distributor) {
                    setSupplier(option?.distributor?.partnerName);
                } else {
                    setSupplier('Third Party');
                }
            }
        });
    };

    const filterchannelId = (event) => {
        const newFilter = channel.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            if (option.name === event) {
                setNewChannelId(option._id);
            }
        });
    };
    const filterOrderType = (event) => {
        const newFilter = orderType.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            if (option.name === event) {
                setNewOrderTypeId(option._id);
            }
        });
    };

    const filterOrderStatus = (event) => {
        const newFilter = orderStatus.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            if (option.name === event) {
                setNewOrderStatusId(option._id);
            }
        });
    };

    const filterPaymentStatus = (event) => {
        const newFilter = paymentStatus.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            if (option.name === event) {
                setNewPaymentStatusId(option._id);
            }
        });
    };

    const filterPickType = (event) => {
        const newFilter = pickdroptype.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            if (option.name === event) {
                setNewPickUpTypeId(option._id);
            }
        });
    };

    const filterDropoffType = (event) => {
        const newFilter = pickdroptype.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            if (option.name === event) {
                setNewDropoffTypeId(option._id);
            }
        });
    };

    const handleDeleteClick = (row, index) => {
        // const newList = items.filter((item) => item.skuId !== row.skuId);

        // setItems(newList);

        const newList = Object.assign([], items);
        newList.splice(index, 1);
        setItems(newList);
        let sum = 0;
        let sum2 = 0;
        setorderValue(sum2);
        setExpectedCash(sum);

        newList.forEach((row) => {
            sum += Number(row.garagePrice) * Number(row.qty);
            // console.log('row data', row); expected cash
        });

        newList.forEach((row) => {
            sum2 += Number(row.wholeSalePrice) * Number(row.qty);
            // console.log('row data', row); order value
        });

        setorderValue(sum2);
        setExpectedCash(sum);
        // (expected cash - order value) / order value
        const marginRate = (Number(sum) - Number(sum2)) / Number(sum2);
        const value = isFinite(marginRate) ? marginRate : 0;
        const margin = Number(value).toFixed(2);
        setMargin(margin);
    };

    const handleEditClick = (row, index) => {
        // console.log('row', row);
        setPartId(row.partid ?? '');
        setQty(row.qty ?? '');
        setpartType(row?.partType ?? '');
        setgaragePrice(row.garagePrice ?? '');
        setSkuId(row.skuId ?? '');
        setapplicableVins(row.applicableVins ?? '');
        setBrand(row.brand ?? '');
        setwholeSalePrice(row.wholeSalePrice ?? '');
        setSupplier(row?.supplier ?? '');
        setpartQtyInPieces(row?.partQtyInPieces ?? 0);
        setpartimage(row?.partimage ?? '');
        setstate(row?.state ?? '');
        handleClickOpen2();
        // const newList = Object.assign([], items);
        // const idx = newList.findIndex((data) => data._id === row._id);
        // console.log('indexf', index);
        setIndexValue(index);
    };

    return (
        <>
            <PageHeader title="Order" subTitle="Home | New Order" />
            <MainCard title="">
                <Typography variant="subtitle2" component="div">
                    Main Information
                </Typography>
                <br />
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    {/* <Stack spacing={1}> */}
                                    <DesktopDatePicker
                                        label="Order date *"
                                        inputFormat="MM/dd/yyyy"
                                        value={orderDate}
                                        onChange={handleOrderDateChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    {/* </Stack> */}
                                </LocalizationProvider>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    value={channelId}
                                    onChange={(event, newValue) => {
                                        setChannelRecord(newValue);
                                        filterchannelId(newValue);
                                    }}
                                    options={channel.map((option) => option.name)}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <TextField {...params} label="Channel *" />}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    value={channelPartnerId}
                                    onChange={(event, newValue) => {
                                        setChannelPartnerRecord(newValue);
                                        filterOptions(newValue);
                                    }}
                                    options={channelpartner.map((option) => option.partnerName)}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <TextField {...params} label="Channel Partner *" />}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <TextField
                                    autoComplete="off"
                                    type="number"
                                    name="name"
                                    variant="outlined"
                                    required
                                    disabled
                                    id="name"
                                    label="Order Value GHC "
                                    value={orderValue}
                                    onChange={(e) => setorderValue(e.target.value)}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    value={orderTypId}
                                    onChange={(event, newValue) => {
                                        setOrderTypeRecord(newValue);
                                        filterOrderType(newValue);
                                    }}
                                    options={orderType.map((option) => option.name)}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <TextField {...params} label="Order Type *" />}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <TextField
                                    autoComplete="off"
                                    name="dropOffLocation"
                                    variant="outlined"
                                    required
                                    id="dropOffLocation"
                                    label="Drop off location "
                                    value={dropOffLocation}
                                    onChange={(e) => setdropOffLocation(e.target.value)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    {/* <Stack spacing={1}> */}
                                    <DesktopDatePicker
                                        label="Fulfilled Date *"
                                        inputFormat="MM/dd/yyyy"
                                        value={deliveryDate}
                                        onChange={handleDeliveryDateChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    {/* </Stack> */}
                                </LocalizationProvider>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    value={orderStatusId}
                                    onChange={(event, newValue) => {
                                        setorderStatusRecord(newValue);
                                        filterOrderStatus(newValue);
                                    }}
                                    options={orderStatus.map((option) => option.name)}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <TextField {...params} label="Order Status *" />}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <br />
                    <Typography variant="subtitle2" component="div">
                        Payment Information
                    </Typography>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <TextField
                                    autoComplete="off"
                                    type="number"
                                    disabled
                                    name="expCashCollected"
                                    variant="outlined"
                                    required
                                    id="expCashCollected"
                                    label="Expected Cash Collected "
                                    value={expCashCollected}
                                    onChange={(e) => {
                                        setExpectedCash(e.target.value);
                                        filterMargin(e.target.value);
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <TextField
                                    autoComplete="off"
                                    type="number"
                                    name="actCashCollected"
                                    variant="outlined"
                                    required
                                    id="actCashCollected"
                                    label="Actual Cash Collected "
                                    value={actCashCollected}
                                    onChange={(e) => {
                                        setActualCash(e.target.value);
                                        filterCash(e.target.value);
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <TextField
                                    autoComplete="off"
                                    type="number"
                                    name="cashOutStanding"
                                    variant="outlined"
                                    id="cashOutStanding"
                                    label="Cash Outstanding *"
                                    value={cashOutStanding}
                                    onChange={(e) => setCashOutstanding(e.target.value)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <TextField
                                    autoComplete="off"
                                    type="number"
                                    name="perCashOutStanding"
                                    variant="outlined"
                                    id="perCashOutStanding"
                                    label="% Cash Outstanding *"
                                    value={perCashOutStanding}
                                    onChange={(e) => setperCashOut(e.target.value)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <TextField
                                    autoComplete="off"
                                    type="number"
                                    name="perMargin"
                                    variant="outlined"
                                    id="perMargin"
                                    label="% Margin *"
                                    value={perMargin}
                                    onChange={(e) => setMargin(e.target.value)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    {/* <Stack spacing={1}> */}
                                    <DesktopDatePicker
                                        label="Pmt Due date *"
                                        inputFormat="MM/dd/yyyy"
                                        value={payDueDate}
                                        onChange={handlePayDueDateChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    {/* </Stack> */}
                                </LocalizationProvider>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <TextField
                                    autoComplete="off"
                                    type="number"
                                    name="daysPastDue"
                                    variant="outlined"
                                    required
                                    id="daysPastDue"
                                    label="Days Past Due"
                                    value={daysPastDue}
                                    onChange={(e) => setdaysPastDue(e.target.value)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    value={paymentStatusId}
                                    onChange={(event, newValue) => {
                                        setpaymentStatusRecord(newValue);
                                        filterPaymentStatus(newValue);
                                    }}
                                    options={paymentStatus.map((option) => option.name)}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <TextField {...params} label="Payment Status *" />}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <br />
                    <Typography variant="subtitle2" component="div">
                        Channel Partner Information
                    </Typography>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <Autocomplete
                                    disablePortal
                                    disabled
                                    id="combo-box-demo"
                                    value={channelPartnerId}
                                    onChange={() => {
                                        // setchannelId(newValue);
                                    }}
                                    options={channelpartner.map((option) => option.partnerName)}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <TextField {...params} label="Channel Partner *" />}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <TextField
                                    autoComplete="off"
                                    disabled
                                    type="number"
                                    name="partnerContact"
                                    variant="outlined"
                                    required
                                    id="partnerContact"
                                    label="Partner Contact Info"
                                    value={partnerContact}
                                    onChange={(e) => setPartnerContact(e.target.value)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <TextField
                                    autoComplete="off"
                                    disabled
                                    name="partnerPercentSold"
                                    variant="outlined"
                                    required
                                    id="partnerPercentSold"
                                    label="% Sold "
                                    value={partnerPercentSold}
                                    onChange={(e) => setpartnerPercentSold(e.target.value)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <TextField
                                    autoComplete="off"
                                    disabled
                                    name="creditrating"
                                    variant="outlined"
                                    required
                                    id="creditrating"
                                    label="Credit rating "
                                    value={creditrating}
                                    onChange={(e) => setCreditRating(e.target.value)}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <br />
                    <Typography variant="subtitle2" component="div">
                        Carrier Information
                    </Typography>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    value={carrierId}
                                    onChange={(event, newValue) => {
                                        setCarrierId(newValue);
                                        filterCarrier(newValue);
                                    }}
                                    options={carrier.map((option) => option.name)}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <TextField {...params} label="Carrier *" />}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <TextField
                                    autoComplete="off"
                                    disabled
                                    name="carrierRating"
                                    variant="outlined"
                                    // id="carrierRating"
                                    label="Carrier Rating *"
                                    value={carrierRating}
                                    onChange={(e) => setCarrierRating(e.target.value)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <TextField
                                    autoComplete="off"
                                    disabled
                                    name="carrierContact"
                                    variant="outlined"
                                    id="carrierContact"
                                    label="Carrier Contact Info *"
                                    value={carrierContact}
                                    onChange={(e) => setCarrierContact(e.target.value)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <TextField
                                    autoComplete="off"
                                    disabled
                                    name="carrierNPS"
                                    variant="outlined"
                                    id="carrierNPS"
                                    label="Carrier NPS *"
                                    value={carrierNPS}
                                    onChange={(e) => setCarrierNPS(e.target.value)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <TextField
                                    autoComplete="off"
                                    name="orderPickUpTime"
                                    variant="outlined"
                                    required
                                    id="orderPickUpTime"
                                    label="Order Pickup Time "
                                    value={orderPickUpTime}
                                    onChange={(e) => setOrderPickupTime(e.target.value)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    value={pickUpTypeId}
                                    onChange={(event, newValue) => {
                                        setPickupType(newValue);
                                        filterPickType(newValue);
                                    }}
                                    options={pickdroptype.map((option) => option.name)}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <TextField {...params} label="Pickup Type *" />}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <TextField
                                    autoComplete="off"
                                    name="pickUpLocation"
                                    variant="outlined"
                                    required
                                    id="pickUpLocation"
                                    label="Pickup Location "
                                    value={pickUpLocation}
                                    onChange={(e) => setPickupLocation(e.target.value)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    value={dropOffTypeId}
                                    onChange={(event, newValue) => {
                                        setDropoffType(newValue);
                                        filterDropoffType(newValue);
                                    }}
                                    options={pickdroptype.map((option) => option.name)}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <TextField {...params} label="Drop off Type *" />}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <TextField
                                    autoComplete="off"
                                    disabled
                                    name="dropOffLocation"
                                    variant="outlined"
                                    id="dropOffLocation"
                                    label="Drop off Location *"
                                    value={dropOffLocation}
                                    onChange={(e) => setdropOffLocation(e.target.value)}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">RM *</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={salesTeamId}
                                        label="Sales Team "
                                        onChange={handleSalesChange}
                                    >
                                        {salesTeamInfo.map((sales) => (
                                            <MenuItem key={sales._id} value={sales._id}>
                                                {sales?.name ?? ''} {sales?.firstName ?? ''} {sales?.lastName ?? ''}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box width={180}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Cash or Credit *</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={cash}
                                        label="Status"
                                        onChange={handleCashChange}
                                    >
                                        <MenuItem value="Body">Select an option</MenuItem>
                                        <MenuItem value="Cash">Cash</MenuItem>
                                        <MenuItem value="Credit">Credit</MenuItem>
                                        <MenuItem value="Credit">Cheque</MenuItem>
                                        <MenuItem value="CashCredit">Cash and Credit</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                    <br />
                    <Typography variant="subtitle2" component="div">
                        Order Items
                    </Typography>
                    <br />
                    <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                        <Button variant="contained" onClick={handleClickOpen} size="sm" className={classes.item}>
                            Add item
                        </Button>
                    </Box>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                #
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                SKU
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Part
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                QTY
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Size
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Brand
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Purchase Price
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Garage Retail Price
                                            </TableCell>

                                            {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                                Applicable VINs
                                            </TableCell> */}
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Supplier
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Image
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Actions
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.skuId}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.partid}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.qty}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.partType}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.brand}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.wholeSalePrice}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.garagePrice}
                                                </TableCell>

                                                {/* <TableCell component="th" scope="row">
                                                    {row.applicableVins}
                                                </TableCell> */}
                                                <TableCell component="th" scope="row">
                                                    {row?.supplier ?? ''}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Avatar
                                                        variant="rounded"
                                                        src={partimage ?? nologo}
                                                        style={{ border: 0, objectFit: 'cover' }}
                                                        sx={{ width: 100, height: 100 }}
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <IconButton
                                                        aria-label="edit"
                                                        color="primary"
                                                        onClick={() => handleEditClick(row, index)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label="delete"
                                                        color="error"
                                                        onClick={() => handleDeleteClick(row, index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>

                    <br />
                    <Typography variant="subtitle2" component="div">
                        Order Notes *
                    </Typography>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={12}>
                            <TextField
                                autoComplete="off"
                                name="orderNotes"
                                variant="outlined"
                                multiline
                                rows={4}
                                fullWidth
                                value={orderNotes}
                                id="orderNotes"
                                label="Order Notes"
                                onChange={(e) => setOrderNotes(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <Box display="flex" justifyContent="space-between">
                        <Button component={Link} to="/order" type="submit" variant="contained" color="error">
                            Back
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            disabled={
                                !channelId ||
                                !channelPartnerId ||
                                !orderValue ||
                                !orderTypId ||
                                !dropOffLocation ||
                                !deliveryDate ||
                                !orderStatusId ||
                                !expCashCollected ||
                                !actCashCollected ||
                                !cashOutStanding ||
                                !perCashOutStanding ||
                                !perMargin ||
                                !payDueDate ||
                                !daysPastDue ||
                                !paymentStatusId ||
                                !carrierId ||
                                !orderPickUpTime ||
                                !pickUpTypeId ||
                                !pickUpLocation ||
                                !dropOffTypeId ||
                                !orderNotes ||
                                !items ||
                                !salesTeamId ||
                                !cash
                            }
                        >
                            {loading ? 'Submitting ..' : 'Submit Order'}
                        </Button>
                    </Box>
                </form>

                <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title" fullWidth>
                    <div style={{ padding: '1rem 2rem' }}>
                        <DialogTitle id="responsive-dialog-title" style={{ fontSize: '1rem' }}>
                            Add Part
                        </DialogTitle>
                        <Form onSubmit={onSubmitPart}>
                            <DialogContent>
                                {/* <DialogContentText> */}

                                <Form.Group controlId="formFile" className="mb-3">
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        value={partid}
                                        onChange={(event, newValue) => {
                                            setPartId(newValue);
                                            filterPart(newValue);
                                        }}
                                        options={part.map((option) => option.sku)}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => <TextField {...params} label="Sku" />}
                                        fullWidth
                                    />
                                    <br /> <br />
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        name="wholeSalePrice"
                                        variant="outlined"
                                        required
                                        id="wholeSalePrice"
                                        label="WholeSale Price"
                                        value={wholeSalePrice}
                                        onChange={(e) => setwholeSalePrice(e.target.value)}
                                        fullWidth
                                    />
                                    <br /> <br />
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        name="garagePrice"
                                        variant="outlined"
                                        required
                                        id="garagePrice"
                                        label="Garage Price"
                                        value={garagePrice}
                                        onChange={(e) => setgaragePrice(e.target.value)}
                                        fullWidth
                                    />
                                    <br /> <br />
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        name="qty"
                                        variant="outlined"
                                        required
                                        id="qty"
                                        label="Qty"
                                        value={qty}
                                        onChange={(e) => setQty(e.target.value)}
                                        fullWidth
                                    />
                                    <br /> <br />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Part Size</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={partType}
                                            label="Status"
                                            onChange={handlePartType}
                                            fullWidth
                                        >
                                            <MenuItem value="Body">Select an option</MenuItem>
                                            <MenuItem value="Boxes">Boxes</MenuItem>
                                            <MenuItem value="Pieces">Pieces</MenuItem>
                                            <MenuItem value="Gallons">Gallons</MenuItem>
                                            <MenuItem value="Drums">Drums</MenuItem>
                                            <MenuItem value="Rolls">Roll(s)</MenuItem>
                                            <MenuItem value="Bucket">Bucket</MenuItem>
                                            <MenuItem value="Packs">Pack(s)</MenuItem>
                                            <MenuItem value="Litre">Litre(s)</MenuItem>
                                            <MenuItem value="Pair">Pair(s)</MenuItem>
                                            <MenuItem value="Carton">Carton(s)</MenuItem>
                                            <MenuItem value="Bag">Bag(s)</MenuItem>
                                            <MenuItem value="Set">Set(s)</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <br /> <br />
                                    <Avatar
                                        variant="rounded"
                                        src={partimage ?? nologo}
                                        style={{ border: 0, objectFit: 'cover' }}
                                        sx={{ width: '100%', height: '400px' }}
                                    />
                                </Form.Group>

                                {/* </DialogContentText> */}
                            </DialogContent>
                            <DialogActions style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Button
                                    variant="contained"
                                    size="sm"
                                    className={classes.item}
                                    autoFocus
                                    onClick={handleClose}
                                    color="error"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    autoFocus
                                    variant="contained"
                                    type="submit"
                                    size="sm"
                                    className={classes.item}
                                    disabled={!partid || !garagePrice || !qty || !partType}
                                >
                                    Add
                                </Button>
                            </DialogActions>
                        </Form>
                    </div>
                </Dialog>

                {/* Edit Parts  */}

                <Dialog fullScreen={fullScreen} open={open2} onClose={handleClose2} aria-labelledby="responsive-dialog-title" fullWidth>
                    <div style={{ padding: '1rem 2rem' }}>
                        <DialogTitle id="responsive-dialog-title" style={{ fontSize: '1rem' }}>
                            Edit Part
                        </DialogTitle>
                        <Form onSubmit={onSubmitPartEdit}>
                            <DialogContent>
                                {/* <DialogContentText> */}

                                <Form.Group controlId="formFile" className="mb-3">
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        value={partid}
                                        onChange={(event, newValue) => {
                                            setPartId(newValue);
                                        }}
                                        options={part.map((option) => option.sku)}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => <TextField {...params} label="Sku" />}
                                        fullWidth
                                    />
                                    <br /> <br />
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        name="wholeSalePrice"
                                        variant="outlined"
                                        required
                                        id="wholeSalePrice"
                                        label="WholeSale Price"
                                        value={wholeSalePrice}
                                        onChange={(e) => setwholeSalePrice(e.target.value)}
                                        fullWidth
                                    />
                                    <br /> <br />
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        name="garagePrice"
                                        variant="outlined"
                                        required
                                        id="garagePrice"
                                        label="Garage Price"
                                        value={garagePrice}
                                        onChange={(e) => setgaragePrice(e.target.value)}
                                        fullWidth
                                    />
                                    <br /> <br />
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        name="qty"
                                        variant="outlined"
                                        required
                                        id="qty"
                                        label="Qty"
                                        value={qty}
                                        onChange={(e) => setQty(e.target.value)}
                                        fullWidth
                                    />
                                    <br /> <br />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Part Size</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={partType}
                                            label="Status"
                                            onChange={handlePartType}
                                            fullWidth
                                        >
                                            <MenuItem value="Body">Select an option</MenuItem>
                                            <MenuItem value="Boxes">Boxes</MenuItem>
                                            <MenuItem value="Pieces">Pieces</MenuItem>
                                            <MenuItem value="Gallons">Gallons</MenuItem>
                                            <MenuItem value="Drums">Drums</MenuItem>
                                            <MenuItem value="Rolls">Roll(s)</MenuItem>
                                            <MenuItem value="Bucket">Bucket</MenuItem>
                                            <MenuItem value="Packs">Pack(s)</MenuItem>
                                            <MenuItem value="Litre">Litre(s)</MenuItem>
                                            <MenuItem value="Pair">Pair(s)</MenuItem>
                                            <MenuItem value="Carton">Carton(s)</MenuItem>
                                            <MenuItem value="Bag">Bag(s)</MenuItem>
                                            <MenuItem value="Set">Set(s)</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <br /> <br />
                                    <Avatar
                                        variant="rounded"
                                        src={partimage ?? nologo}
                                        style={{ border: 0, objectFit: 'cover' }}
                                        sx={{ width: '100%', height: '400px' }}
                                    />
                                </Form.Group>

                                {/* </DialogContentText> */}
                            </DialogContent>
                            <DialogActions style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Button
                                    variant="contained"
                                    size="sm"
                                    className={classes.item}
                                    autoFocus
                                    onClick={handleClose2}
                                    color="error"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    autoFocus
                                    variant="contained"
                                    type="submit"
                                    size="sm"
                                    className={classes.item}
                                    disabled={!partid || !garagePrice || !qty || !partType}
                                >
                                    Update
                                </Button>
                            </DialogActions>
                        </Form>
                    </div>
                </Dialog>
            </MainCard>
        </>
    );
};
export default AddOrder;
