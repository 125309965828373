/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
import Autocomplete from '@mui/material/Autocomplete';
// import { notification } from 'antd';
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { FormControl, InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import useScriptRef from 'hooks/useScriptRef';

const AddPriorityAutomation = () => {
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState('');
    const [countries, setCountries] = useState([]);
    const [timegmt, settimegmt] = useState('');
    const [time, settime] = useState('');
    const [minute, setminute] = useState('');
    const [city, setcity] = useState('');
    const [cityData, setCityData] = useState([]);

    const history = useNavigate();
    const scriptedRef = useScriptRef();

    const retrieveAllActiveCountry = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.ActiveCountryUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    const retrieveAllCity = async (id) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.CityFilterUrl + id, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCityData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    const filterCountry = (event) => {
        const newFilter = countries.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        newFilter.forEach((option) => {
            retrieveAllCity(option?._id);
        });
    };
    const handleCityChange = (event) => {
        setcity(event.target.value);
    };

    useEffect(() => {
        // retrieve all sales associate;
        retrieveAllActiveCountry();
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const userInfo = localStorage.getItem('userToken');

        const data = {
            timegmt,
            country,
            time,
            city,
            minute
        };

        fetch(ApiUrl.AddPriorityAutomationUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    if (scriptedRef.current) {
                        setTimeout(() => {
                            history('/priorityautomation');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                    console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <br />
                    <PageHeader
                        title="Configuration | Priority Automation Setup"
                        subTitle="Home | Configuration |  Priority Automation | Add"
                    />
                    <br />
                    <MainCard title="Add  Priority Automation">
                        <form onSubmit={handleSubmit}>
                            <TextField
                                autoComplete="off"
                                name="time"
                                variant="outlined"
                                required
                                fullWidth
                                id="time"
                                value={time}
                                label="Time"
                                onChange={(e) => settime(e.target.value)}
                            />
                            <br />
                            <br />

                            <TextField
                                autoComplete="off"
                                name="minute"
                                variant="outlined"
                                required
                                fullWidth
                                id="minute"
                                value={minute}
                                label="Minute"
                                onChange={(e) => setminute(e.target.value)}
                            />
                            <br />
                            <br />
                            <TextField
                                autoComplete="off"
                                name="timegmt"
                                variant="outlined"
                                required
                                fullWidth
                                id="timegmt"
                                value={timegmt}
                                label="GMT"
                                onChange={(e) => settimegmt(e.target.value)}
                            />
                            <br />
                            <br />
                            <Autocomplete
                                id="country-select-demo"
                                autoHighlight
                                value={country}
                                onChange={(event, newValue) => {
                                    setCountry(newValue);
                                    filterCountry(newValue);
                                }}
                                options={countries.map((option) => option.name)}
                                isOptionEqualToValue={(option, value) => option.code === value.code}
                                renderInput={(params) => <TextField {...params} label="Choose a country *" />}
                            />
                            <br />
                            <br />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Choose a City *</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={city}
                                    label="City"
                                    onChange={handleCityChange}
                                >
                                    {cityData.map((attr) => (
                                        <MenuItem key={attr.city} value={attr.city}>
                                            {attr?.city ?? ''}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <br />
                            <br />

                            <Box display="flex" justifyContent="space-between">
                                <Button component={Link} to="/priorityautomation" type="submit" variant="contained" color="error">
                                    Reset
                                </Button>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    disabled={!timegmt || !country || !city || !time || loading}
                                >
                                    {loading ? 'Submitting ..' : 'Submit'}
                                </Button>
                            </Box>
                        </form>
                    </MainCard>
                </Grid>
            </Grid>
        </>
    );
};

export default AddPriorityAutomation;
