import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, List, ListItem, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { DatePicker, Select, Space, Checkbox, Button, Input, Popover, Dropdown, antIcon, notification, Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import WestIcon from '@mui/icons-material/West';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { toast } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';
import ApiUrl from 'network';
import { encrypt, decrypt } from '../../utils/encrypt';
import { useNavigate } from 'react-router';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditTask from './EditTask';
import { dateConverter } from 'utils/functions';
import { EmptyState } from 'components/EmptyState/EmptyState';

const AssignTasks = () => {
    const [openAddTask, setOpenAddTask] = useState(false);
    const [loadingTask, setLoadingTask] = useState(false);
    const [taskStatus, setTaskStatus] = useState('InProgress');
    const [loadingCompletedTask, setLoadingCompletedTask] = useState(false);
    const [loadingInProgressTasks, setLoadingInProgressTasks] = useState(false);
    const [openAssignRma, setOpenAssignRma] = useState(false);
    const [openDueDate, setOpenDueDate] = useState(false);
    const [isAddingTask, setIsAddingTask] = useState(false);
    const [taskSuccess, setTaskSuccess] = useState(false);
    const [containerWidth, setContainerWidth] = useState('false');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [getRma, setGetRma] = useState('');
    const [getAssignedRma, setGetAssignedrma] = useState([]);
    const [getDueDate, setGetDueDate] = useState('');
    const [mainRma, setMainRma] = useState([]);
    const [allTasks, setAllTasks] = useState([]);
    const [inProgressTasks, setInProgressAllTasks] = useState([]);
    const [completedTasks, setCompletedTasks] = useState([]);
    const [isEditTaskOpen, setIsEditTaskOpen] = useState(false);
    const [getTaskId, setGetTaskId] = useState('');
    const [isHovered, setIsHovered] = useState(false);
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [api, contextHolder] = notification.useNotification();
    const country = localStorage.getItem('country');
    const [toggle, setToggle] = useState(1);

    const toggleFilter = (index) => {
        setToggle(index);
    };

    const navigate = useNavigate();
    const { TextArea } = Input;

    // modals
    const showEditTaskModal = () => {
        setIsEditTaskOpen(true);
    };

    const handleEditTaskOk = () => {
        setIsEditTaskOpen(false);
    };

    const handleEditTaskCancel = () => {
        setIsEditTaskOpen(false);
    };

    const openNotification = (placement) => {
        api.open({
            description: 'Task has been added.',
            placement,
            style: {
                width: 267,
                height: 70,
                backgroundColor: '#0A2938',
                borderRadius: '12px',
                textAlign: 'center',
                color: '#fff',
                fontWeight: 500,
                fontSize: '18px'
            },
            closeIcon: false
        });
    };

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54
            }}
            spin
        />
    );

    const getQueryData = async () => {
        try {
            // setLoading(true);
            const url = `${ApiUrl.BaseUrl}/task/querydata?country=${country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        // console.error(answer);
                        setTaskStatus(answer?.taskstatus);
                        setMainRma(answer.relationshipManagers);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const getAllTodoTask = async () => {
        try {
            setLoadingTask(true);
            const url = `${ApiUrl.BaseUrl}/task/alltodotask?country=${country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setAllTasks(answer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoadingTask(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const getAllInProgressTask = async () => {
        try {
            setLoadingInProgressTasks(true);
            const url = `${ApiUrl.BaseUrl}/task/allinprogresstask?country=${country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setInProgressAllTasks(answer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoadingInProgressTasks(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const getAllCompletedTask = async () => {
        try {
            setLoadingCompletedTask(true);
            const url = `${ApiUrl.BaseUrl}/task/allcompletedtask?country=${country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setCompletedTasks(answer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoadingCompletedTask(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const rmaOptions = mainRma?.map((item) => {
        return { label: item?.name, value: item?._id };
    });

    useEffect(() => {
        const handleResize = () => {
            // Adjust the column width based on the window width
            const newWidth = window.innerWidth < 1200 ? 'true' : 'false';
            setContainerWidth(newWidth);
        };

        // Initial setup
        handleResize();

        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        getQueryData();
    }, []);

    useEffect(() => {
        getAllTodoTask();
        getAllInProgressTask();
        getAllCompletedTask();
    }, [taskSuccess]);

    // select rma to assign task
    const handleSelectRmas = (value) => {
        const rma = mainRma.find((rma) => rma._id === value);

        if (getRma.includes(rma.name)) {
            toast.error(`${rma.name} has already been selected!!`);
            return;
        }

        if (!getRma.includes(value)) {
            setGetRma([...getRma, rma.name]);
            setGetAssignedrma([...getAssignedRma, rma]);
        }
    };

    // remove rma
    const handleRemoveRma = (valueToRemove) => {
        const updatedRmas = getRma.filter((rma) => rma !== valueToRemove);

        setGetRma(updatedRmas);
    };

    const onDateChange = (date, dateString) => {
        setGetDueDate(dateString);
    };

    const selectRmas = (
        <>
            <Box
                sx={{
                    width: ' 100%'
                }}
            >
                <Select
                    style={{
                        width: '250px',
                        margin: '10px 5px'
                    }}
                    showSearch
                    size="large"
                    allowClear
                    placeholder="Select Rma/cc"
                    optionFilterProp="children"
                    filterOption={(inputValue, option) => {
                        if (typeof option.label === 'string') {
                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        }

                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                    }}
                    options={rmaOptions}
                    value={null}
                    onChange={handleSelectRmas}
                />
            </Box>
        </>
    );
    const selectDueDate = (
        <>
            <Box
                sx={{
                    width: ' 100%'
                }}
            >
                <DatePicker
                    style={{
                        width: '250px',
                        margin: '10px 5px'
                    }}
                    showSearch
                    size="large"
                    allowClear
                    onChange={onDateChange}
                />
            </Box>
        </>
    );

    const AddNewTask = async (event) => {
        const userInfo = localStorage.getItem('userToken');
        setIsAddingTask(true);

        const data = {
            title,
            description,
            dueDate: getDueDate,
            status: 'InProgress',
            assignedUsers: getAssignedRma?.map((rma) => ({ userId: rma._id, name: rma.name }))
        };

        const obj = JSON.stringify(data);
        const result = encrypt(obj, keyResult, ivResult);

        await fetch(`${ApiUrl.BaseUrl}/task/addtask`, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ payload: result })
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    openNotification('bottom');
                    setTaskSuccess(!taskSuccess);
                    setTitle('');
                    setDescription('');
                    setGetRma('');
                    setGetDueDate('');
                    setGetAssignedrma([]);
                } else if (result.status === false) {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                } else {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                }
            })
            .finally(() => {
                setIsAddingTask(false);
                setTitle('');
                setDescription('');
                setGetRma('');
                setGetDueDate('');
                setGetAssignedrma([]);
            });
    };

    // task card
    const TaskCard = ({ description, title, name, dueDate, assignedUsers, _id }) => {
        return (
            <Box
                sx={
                    {
                        // flexGrow: '1',
                        // width: containerWidth === 'true' ? '100%' : '40%',
                        // margin: containerWidth === 'true' ? '0 0 0 0' : '0 30px 0 0'
                    }
                }
            >
                <Box
                    style={{
                        backgroundColor: '#ECEDEF',
                        borderRadius: '16px',
                        margin: '5px',
                        display: 'flex',
                        // alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        // height: '140px',
                        width: '100%',
                        padding: '15px',
                        overflow: 'hidden'
                    }}
                >
                    {/* {profile?.notes?.length > 0 ? ( */}

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            margin: '8px 0 '
                        }}
                    >
                        <Box
                            sx={{
                                textAlign: 'start',
                                color: '#546974',
                                fontSize: '18px'
                            }}
                        >
                            {title}
                        </Box>
                        {localStorage.getItem('userRole') === 'CustomerSuccessLead' ? (
                            <Popover
                                placement="leftTop"
                                trigger="click"
                                arrow="Hide"
                                content={
                                    <Box
                                        sx={{
                                            width: '170px',
                                            height: '48px',
                                            backgroundColor: '#0A2938',
                                            borderRadius: '5px',
                                            color: '#fff',
                                            fontWight: '500',
                                            padding: '0 12px',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            fontSize: '16px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                        className="hover-secondary-button-dark"
                                        onClick={() => {
                                            setGetTaskId((prev) => (prev = _id));
                                            showEditTaskModal();
                                        }}
                                    >
                                        Edit task
                                    </Box>
                                }
                            >
                                <MoreVertIcon
                                    sx={{
                                        fontSize: '18px',
                                        cursor: 'pointer',
                                        color: '#FF740F'
                                    }}
                                />
                            </Popover>
                        ) : null}
                    </Box>

                    <Box
                        sx={{
                            textAlign: 'start',
                            color: '#546974',
                            fontSize: '14px',
                            height: '70px',
                            overflowY: 'scroll',
                            overflowX: 'hidden'
                        }}
                        className="order-history-modals-y"
                    >
                        {description}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            flexWrap: 'wrap'
                        }}
                    >
                        {assignedUsers.length > 0 ? (
                            <Typography
                                sx={{
                                    color: '#85949C',
                                    fontSize: '14px'
                                }}
                            >
                                Assigned to:{' '}
                                {assignedUsers.slice(0, 2).map((user, index) => (
                                    <React.Fragment key={index}>
                                        {index > 0 ? ', ' : ''} {user.name}
                                        {index === 1 && assignedUsers.length > 2 ? `, and ${assignedUsers.length - 2} more` : ''}
                                    </React.Fragment>
                                ))}
                            </Typography>
                        ) : null}
                        {/* <Box
                            sx={{
                                color: '#85949C',
                                fontSize: '12px'
                            }}
                        >
                            Assigned to: {assignedUsers.map((user) => user.name)}
                        </Box> */}
                        <Box
                            sx={{
                                display: 'flex',
                                // justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#85949C',
                                    fontSize: '12px',
                                    margin: ' 0 10px 0 0'
                                }}
                            >
                                Due date:
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#85949C',
                                    fontSize: '12px'
                                }}
                            >
                                {dateConverter(dueDate)}
                            </Typography>
                        </Box>
                    </Box>

                    {/* ) : ( */}
                </Box>
            </Box>
        );
    };

    return (
        <div>
            {contextHolder}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '1px dashed #E7EAEB',
                    alignItems: 'flex-end',
                    margin: '0px auto 15px',
                    flexWrap: 'wrap'
                }}
            >
                {' '}
                <List
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '0 0 16px 0',
                        alignItems: 'flex-start'
                    }}
                >
                    <WestIcon
                        sx={{
                            fontSize: '32px',
                            cursor: 'pointer',
                            margin: '0 10px 0 0',
                            '&:hover': {
                                color: '#546974'
                            },
                            '&:focus': {
                                color: '#9DA9AF'
                            }
                        }}
                        onClick={() => navigate(-1)}
                    />{' '}
                    <ListItem sx={{ fontWeight: 500, fontSize: '1.875rem', color: '#0A2938', padding: 0 }}>Assign tasks</ListItem>
                </List>
                <Box
                    sx={{
                        // margin: '20px 0',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        padding: '0 0 16px 0'
                    }}
                >
                    <Button
                        size="large"
                        style={{
                            backgroundColor: toggle === 1 ? '#FFFFFF' : 'transparent',
                            borderRadius: '12px',
                            fontSize: '16px',
                            fontWeight: '400',
                            color: '#FF740F',
                            width: '122px',
                            margin: '24px 5px 0 5px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '48px',
                            flexGrow: '1 '
                        }}
                        onClick={() => toggleFilter(1)}
                    >
                        In progress
                    </Button>

                    <Button
                        size="large"
                        style={{
                            backgroundColor: toggle === 2 ? '#FFFFFF' : 'transparent',
                            borderRadius: '12px',
                            fontSize: '16px',
                            fontWeight: '400',
                            color: '#FF740F',
                            width: '122px',
                            margin: '24px 5px 0 5px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '48px',
                            flexGrow: '1 '
                        }}
                        onClick={() => toggleFilter(2)}
                    >
                        Complete
                    </Button>
                    {localStorage.getItem('userRole') === 'CustomerSuccessLead' ? (
                        <Button
                            size="large"
                            style={{
                                backgroundColor: '#F5880E',
                                borderRadius: '12px',
                                fontSize: '16px',
                                fontWeight: '500',
                                color: '#fff',
                                width: '104px',
                                margin: '24px 5px 0 5px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '48px',
                                flexGrow: '1 '
                            }}
                            onClick={() => {
                                setOpenAddTask(!openAddTask);
                            }}
                            className="hover-secondary-button-orange"
                        >
                            Add task
                        </Button>
                    ) : null}
                </Box>
            </Box>
            <Box
                sx={{
                    margin: '24px 0px 0 0px',
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                    // flexWrap: containerWidth === 'true' ? 'wrap' : 'nowrap'
                }}
            >
                {openAddTask && isAddingTask === false ? (
                    <Box
                        style={{
                            backgroundColor: '#FFFFFF',
                            borderRadius: '16px',
                            // flexGrow: '1 ',
                            border: '1px solid #FFFFFF',
                            margin: '5px',
                            // display: 'flex',
                            flexBasis: '671px',
                            padding: '18px 20px',
                            flexDirection: 'column',
                            display: 'grid',
                            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                            gap: '10px'
                        }}
                    >
                        {' '}
                        <Input
                            placeholder="Title here"
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                            // onKeyUp={handleKeyPress}
                            style={{
                                backgroundColor: '#ECEDEF',
                                borderRadius: '12px',
                                margin: '5px',
                                border: 'none',
                                height: '60px',
                                width: '100%'
                            }}
                        />
                        <TextArea
                            rows={4}
                            placeholder="Description"
                            onChange={(e) => {
                                setDescription(e.target.value);
                            }}
                            style={{
                                backgroundColor: '#ECEDEF',
                                borderRadius: '12px',
                                margin: '5px',
                                border: 'none',
                                height: '100px',
                                width: '100%'
                            }}
                            autoSize={{
                                minRows: 5,
                                maxRows: 6
                            }}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    margin: '10px 0 4px 5px'
                                }}
                            >
                                {getRma.length > 0 ? (
                                    <Typography
                                        sx={{
                                            color: '#85949C',
                                            fontSize: '16px'
                                        }}
                                    >
                                        Assigned to
                                    </Typography>
                                ) : null}
                                {getDueDate.length > 0 ? (
                                    <Typography
                                        sx={{
                                            color: '#85949C',
                                            fontSize: '16px'
                                        }}
                                    >
                                        Due date: {getDueDate}
                                    </Typography>
                                ) : null}
                            </Box>

                            <Box>
                                {getRma.length > 0 ? (
                                    <Box
                                        sx={{
                                            width: ' 100%',
                                            margin: '8px 0 0 0px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            // justifyContent: 'space-between',
                                            flexWrap: 'wrap'
                                        }}
                                    >
                                        {getRma.map((value, index) => (
                                            <Box
                                                key={value}
                                                sx={{
                                                    // width: ' 124px',
                                                    margin: '8px 0 0 0',
                                                    marginLeft: `${index !== 0 ? '3px' : '0px'}`,
                                                    height: '44px',
                                                    borderRadius: '8px',
                                                    padding: '8px',
                                                    border: '1px solid #E9ECED',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: '#546974',
                                                        fontSize: '16px'
                                                    }}
                                                >
                                                    {value}
                                                </Typography>

                                                <CloseIcon
                                                    sx={{
                                                        color: '#434A5B99',
                                                        fontSize: '16px',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => {
                                                        handleRemoveRma(value);
                                                    }}
                                                />
                                            </Box>
                                        ))}
                                    </Box>
                                ) : null}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                {' '}
                                <Typography
                                    sx={{
                                        border: '1px solid #FFD5B7',
                                        height: '40px',
                                        width: '110px',
                                        padding: '10px 12px',
                                        borderRadius: '8px',
                                        color: '#FF740F',
                                        fontWight: '500',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        margin: '10px 5px '
                                    }}
                                    onClick={() => setOpenDueDate(!openDueDate)}
                                >
                                    Add due date
                                </Typography>
                                <Typography
                                    sx={{
                                        border: '1px solid #FFD5B7',
                                        height: '40px',
                                        width: '130px',
                                        padding: '10px 12px',
                                        borderRadius: '8px',
                                        color: '#FF740F',
                                        fontWight: '500',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        margin: '10px 5px'
                                    }}
                                    onClick={() => setOpenAssignRma(!openAssignRma)}
                                >
                                    Assign RMA/CC
                                </Typography>
                            </Box>
                            {localStorage.getItem('userRole') === 'CustomerSuccessLead' ? (
                                <Box>
                                    <Button
                                        type="primary"
                                        size="large"
                                        loading={isAddingTask}
                                        style={{
                                            backgroundColor: '#FF740F',
                                            borderRadius: '12px',
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: '#fff',
                                            width: '122px',

                                            margin: '10px 5px ',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '38px',
                                            flexGrow: '1 '
                                            // flexBasis: '200px'
                                        }}
                                        onClick={() => {
                                            AddNewTask();
                                        }}
                                        className="hover-secondary-button-orange"
                                    >
                                        Add task
                                    </Button>
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                ) : null}
                {openAssignRma && isAddingTask === false ? selectRmas : null}
                {openDueDate && isAddingTask === false ? selectDueDate : null}
                <Box
                    style={{
                        flexGrow: '1 ',
                        flexBasis: '591px',
                        padding: '18px 20px'
                    }}
                />
            </Box>
            {/* {loadingTask ? (
                <Box
                    sx={{
                        display: 'grid',
                        placeItems: 'center'
                    }}
                    className={toggle === 1 ? 'active-task ' : 'disable-task'}
                >
                    {antIcon}
                </Box>
            ) : allTasks.length > 0 ? (
                <Box
                    sx={{
                        margin: '24px 0px 0 0px',
                        alignItems: 'center',
                        display: 'grid',
                        gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                        gap: '10px'
                    }}
                    className={toggle === 1 ? 'active-task ' : 'disable-task'}
                >
                    {allTasks.map((task) => (
                        <TaskCard key={task?._id} {...task} />
                    ))}

                    <EditTask
                        openEditTask={isEditTaskOpen}
                        showEditTaskModal={showEditTaskModal}
                        handleEditTaskOk={handleEditTaskOk}
                        handleEditTaskCancel={handleEditTaskCancel}
                        getTaskId={getTaskId}
                        rmaOptions={rmaOptions}
                        mainRma={mainRma}
                        openNotification={openNotification}
                        setTaskSuccess={setTaskSuccess}
                        taskSuccess={taskSuccess}
                    />
                </Box>
            ) : (
                <Empty
                    style={{
                        margin: '0 auto'
                    }}
                    description={<span>No tasks</span>}
                    className={toggle === 1 ? 'active-task ' : 'disable-task'}
                />
            )} */}

            {loadingInProgressTasks ? (
                <Box
                    sx={{
                        display: 'grid',
                        placeItems: 'center'
                    }}
                    className={toggle === 1 ? 'active-task ' : 'disable-task'}
                >
                    {antIcon}
                </Box>
            ) : inProgressTasks.length > 0 ? (
                <Box
                    sx={{
                        margin: '24px 0px 0 0px',
                        // display: 'flex',
                        alignItems: 'center',
                        // flexWrap: 'wrap',
                        display: 'grid',
                        gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                        gap: '10px'
                    }}
                    className={toggle === 1 ? 'active-task ' : 'disable-task'}
                >
                    {inProgressTasks.map((task) => (
                        <TaskCard key={task?._id} {...task} />
                    ))}

                    <EditTask
                        openEditTask={isEditTaskOpen}
                        showEditTaskModal={showEditTaskModal}
                        handleEditTaskOk={handleEditTaskOk}
                        handleEditTaskCancel={handleEditTaskCancel}
                        getTaskId={getTaskId}
                        rmaOptions={rmaOptions}
                        mainRma={mainRma}
                        openNotification={openNotification}
                        setTaskSuccess={setTaskSuccess}
                        taskSuccess={taskSuccess}
                    />
                </Box>
            ) : (
                <Box
                    style={{
                        margin: '10px auto 0',
                        position: 'relative',
                        backgroundColor: '#FFFFFF',
                        height: '496px',
                        borderRadius: '16px'
                    }}
                    className={toggle === 1 ? 'active-task ' : 'disable-task'}
                >
                    <EmptyState height="204px" width="70%" text="No results yet." />
                </Box>
            )}

            {loadingCompletedTask ? (
                <Box
                    sx={{
                        display: 'grid',
                        placeItems: 'center'
                    }}
                    className={toggle === 2 ? 'active-task ' : 'disable-task'}
                >
                    {antIcon}
                </Box>
            ) : completedTasks.length > 0 ? (
                <Box
                    sx={{
                        margin: '24px 0px 0 0px',
                        // display: 'flex',
                        alignItems: 'center',
                        // flexWrap: 'wrap',
                        display: 'grid',
                        gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                        gap: '10px'
                    }}
                    className={toggle === 2 ? 'active-task ' : 'disable-task'}
                >
                    {completedTasks.map((task) => (
                        <TaskCard key={task?._id} {...task} />
                    ))}

                    <EditTask
                        openEditTask={isEditTaskOpen}
                        showEditTaskModal={showEditTaskModal}
                        handleEditTaskOk={handleEditTaskOk}
                        handleEditTaskCancel={handleEditTaskCancel}
                        getTaskId={getTaskId}
                        rmaOptions={rmaOptions}
                        mainRma={mainRma}
                        openNotification={openNotification}
                        setTaskSuccess={setTaskSuccess}
                        taskSuccess={taskSuccess}
                    />
                </Box>
            ) : (
                <Box
                    style={{
                        margin: '10px auto 0',
                        position: 'relative',
                        backgroundColor: '#FFFFFF',
                        height: '496px',
                        borderRadius: '16px'
                    }}
                    className={toggle === 2 ? 'active-task ' : 'disable-task'}
                >
                    <EmptyState height="204px" width="70%" text="No results yet." />
                </Box>
            )}
        </div>
    );
};

export default AssignTasks;
