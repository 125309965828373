/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import useScriptRef from 'hooks/useScriptRef';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import EditIcon from '@mui/icons-material/Edit';
import BugReportIcon from '@mui/icons-material/BugReport';
// import swal from 'sweetalert';
// import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Form from 'react-bootstrap/Form';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
// import ViewIcon from '@mui/icons-material/Preview';
// import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import nologo from 'assets/images/nologo.png';
import swal from 'sweetalert';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const ViewOrder = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [orderDate, setorderDate] = useState(new Date());
    const [deliveryDate, setDeliveryDate] = useState(new Date());
    const [payDueDate, setPayDueDate] = useState(new Date());
    const [podId, setpodId] = useState('');
    const [orderTypId, setOrderTypeRecord] = useState('');
    const [paymentStatusId, setpaymentStatusRecord] = useState('');
    const [orderStatusId, setorderStatusRecord] = useState('');
    const [channelId, setChannelRecord] = useState('');
    const [channelPartnerId, setChannelPartnerRecord] = useState('');
    const [dropOffLocation, setdropOffLocation] = useState('');
    const [dropOffLocatLng, setdropOffLocatLng] = useState('');
    const [dropOffLocation2, setdropOffLocation2] = useState('');
    const [expCashCollected, setExpectedCash] = useState('');
    const [actCashCollected, setActualCash] = useState('');
    const [cashOutStanding, setCashOutstanding] = useState('');
    const [perCashOutStanding, setperCashOut] = useState('');
    const [perMargin, setMargin] = useState('');
    const [daysPastDue, setdaysPastDue] = useState('');
    const [partnerContact, setPartnerContact] = useState('');
    const [partnerPercentSold, setpartnerPercentSold] = useState('0');
    const [creditrating, setCreditRating] = useState('');
    const [orderType, setOrderType] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState([]);
    const [orderStatus, setOrderStatus] = useState([]);
    const [channel, setChannel] = useState([]);
    const [channelpartner, setChannelPartner] = useState([]);
    const [carrier, setCarrier] = useState([]);
    const [part, setPart] = useState([]);
    const [pickdroptype, setPickDropType] = useState([]);
    const [carrierId, setCarrierId] = useState('');
    const [orderValue, setorderValue] = useState('');
    const [pickUpLocation, setPickupLocation] = useState('');
    const [carrierContact, setCarrierContact] = useState('');
    const [carrierNPS, setCarrierNPS] = useState('');
    const [carrierRating, setCarrierRating] = useState('');
    const [orderPickUpTime, setOrderPickupTime] = useState('');
    const [qty, setQty] = useState('');
    const [partQtyInPieces, setpartQtyInPieces] = useState('');
    const [partid, setPartId] = useState('');
    const [skuId, setSkuId] = useState('');
    const [brand, setBrand] = useState('');
    const [wholeSalePrice, setwholeSalePrice] = useState('');
    const [garagePrice, setgaragePrice] = useState('');
    const [applicableVins, setapplicableVins] = useState('');
    const [pickUpTypeId, setPickupType] = useState('');
    const [dropOffTypeId, setDropoffType] = useState('');
    const [orderNotes, setOrderNotes] = useState('');
    const [channelIds, setchannelId] = useState('');
    const [newChannelId, setNewChannelId] = useState('');
    const [newChannelPartnerId, setNewChannelPartnerId] = useState('');
    const [newOrdertypeId, setNewOrderTypeId] = useState('');
    const [newOrderStatusId, setNewOrderStatusId] = useState('');
    const [newPaymentStatusId, setNewPaymentStatusId] = useState('');
    const [newCarrierId, setnewCarrierId] = useState('');
    const [newPickupTypeId, setNewPickUpTypeId] = useState('');
    const [newDropoffTypeId, setNewDropoffTypeId] = useState('');
    const [cash, setCash] = useState('');
    const [salesTeamId, setsalesTeamId] = useState('');
    const [parts, setparts] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [items, setItems] = useState([]);
    const [salesTeamInfo, setSalesTeam] = useState([]);
    const [partType, setpartType] = useState([]);
    const [partsdata, setpartsdata] = useState([]);
    const [indexValue, setIndexValue] = useState('');
    const [indexValue2, setIndexValue2] = useState('');
    const [supplier, setSupplier] = useState('');
    const [collectiondatas, setCollectiondatas] = useState([]);
    const [depositeDate, setdepositeDate] = useState(new Date());
    const [referenceNote, setreferenceNote] = useState('');
    const [depositeAmt, setdepositAmt] = useState('');
    const [depositeImage, setdepositeImage] = useState('');
    const [paymentType, setpaymentType] = useState('');
    const [approveUser, setapproveUser] = useState('');
    const [approveDate, setapproveDate] = useState(new Date());
    const [approveAmt, setapproveAmt] = useState('');
    const [approveStatus, setapproveStatus] = useState('');
    const [salesname, setsalesname] = useState('');
    const [salesemail, setsalesemail] = useState('');
    const [collectedDate, setcollectedDate] = useState(new Date());
    const [collectedAmt, setcollectedAmt] = useState('');
    const [issuesdata, setissuesdata] = useState([]);
    const [sizepart, setsizepart] = useState(0);
    const [partimage, setpartimage] = useState('');
    const [deliveryNoteData, setdeliveryNoteData] = useState([]);
    const [dispatchImageData, setdispatchImageData] = useState([]);
    const [packsortImageData, setpacksortImageData] = useState([]);
    const [fulOrderId, setfulOrderId] = useState('');
    const [deliveryNote, setdeliveryNote] = useState([]);
    const [driverImageNoteData, setdriverImageNoteData] = useState([]);
    const [discount, setdiscount] = useState('');
    const [driverOrderStatus, setdriverOrderStatus] = useState('');
    const [driverCashCollected, setdriverCashCollected] = useState('');
    const [retailerCredit, setretailerCredit] = useState('');
    const [retailerCreditHolder, setretailerCreditHolder] = useState('');
    const [retailerDebit, setretailerDebit] = useState('');
    const [discountAppliedBy, setdiscountAppliedBy] = useState('');
    const [fulStatus, setfulStatus] = useState('');
    const [fulStatusRecived, setfulStatusRecived] = useState('');
    const [fulviewit, setfulviewit] = useState('');
    const [adminNotes, setadminNotes] = useState('');
    const [placeBy, setplaceBy] = useState('');
    const [partsRemovedDeliveryNote, setpartsRemovedDeliveryNote] = useState([]);
    const [state, setstate] = useState('');
    const [originalExpectedCash, setoriginalExpectedCash] = useState('');
    const [originalGaragePrice, setoriginalGaragePrice] = useState('');

    // console.log(channelIds);
    // console.log(newCarrierId);
    // console.log(parts);

    const { id, rma } = useParams();

    const handleCashChange = (event) => {
        setCash(event.target.value);
    };

    const retrieveAllDeliveryNoteData = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.DeliveryNoteDataOrderUrl + id, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setdeliveryNote(data?.data?.parts ?? []);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAlLsalesTeam = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            const url = `${ApiUrl.BaseUrl}/salesdrivepod/podinfo`;
            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setSalesTeam(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
            // fetch(ApiUrl.SalesTeamActiveSalesPage, config)
            //     .then((data) => data.json())
            //     .then((data) => {
            //         if (data.status === true) {
            //             setSalesTeam(data.data);
            //         } else {
            //             toast.error(data.data);
            //         }
            //     })
            //     .finally(() => {
            //         setLoading(false);
            //     });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllPickDropType = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllActivePickDropType, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setPickDropType(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllPart = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.PartlistnewUrlInfo + rma, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setPart(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllCarrier = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.ActiveCarrierUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCarrier(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllChannelPartner = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllChannelPartnersOrders, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setChannelPartner(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllActiveChannel = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllActiveChannel, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setChannel(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllActiveOrderStatus = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllActiveOrderStatus, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrderStatus(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllActivePaymentStatus = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllActivePaymentStatus, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setPaymentStatus(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllOrderType = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllActiveOrderTypes, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrderType(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // useEffect(() => {
    //     retrieveAllChannel();
    // }, []);

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.ViewOrdersUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    // console.log('result', result.data);
                    setorderDate(result?.data?.orderDate ?? '');
                    // let names = `${result?.data?.userId?.firstName} ${result?.data?.userId?.lastName}`;
                    // console.log('names', names);

                    setChannelRecord(result?.data?.newChannelId?.name ?? '');
                    setNewChannelId(result?.data?.newChannelId?._id ?? '');
                    setChannelPartnerRecord(result?.data?.newChannelPartnerId?.partnerName ?? '');
                    setNewChannelPartnerId(result?.data?.newChannelPartnerId?._id ?? '');
                    setorderValue(Number(result?.data?.orderValue).toFixed(2) ?? '');
                    setOrderTypeRecord(result?.data?.newOrdertypeId?.name ?? '');
                    setNewOrderTypeId(result?.data?.newOrdertypeId?._id ?? '');
                    setdropOffLocation(result?.data?.dropOffLocation ?? '');
                    setdropOffLocatLng(result?.data?.dropOffLocatLng);
                    setdropOffLocation2(result?.data?.dropOffLocation2);
                    setDeliveryDate(result?.data?.deliveryDate ?? '');
                    setorderStatusRecord(result?.data?.newOrderStatusId?.name ?? '');
                    setNewOrderStatusId(result?.data?.newOrderStatusId?._id ?? '');
                    setExpectedCash(Number(result?.data?.expCashCollected).toFixed(2) ?? '');
                    setoriginalExpectedCash(
                        Number(result?.data?.originalExpectedCash).toFixed(2) ?? Number(result?.data?.expCashCollected).toFixed(2)
                    );
                    setActualCash(result?.data?.actCashCollected ?? 0);
                    setCashOutstanding(result?.data?.cashOutStanding ?? '');
                    setperCashOut(result?.data?.perCashOutStanding ?? '');
                    setMargin(result?.data?.perMargin ?? '');
                    setPayDueDate(result?.data?.payDueDate ?? '');
                    setdaysPastDue(result?.data?.daysPastDue ?? '');
                    setpaymentStatusRecord(result?.data?.newPaymentStatusId?.name ?? '');
                    setNewPaymentStatusId(result?.data?.newPaymentStatusId?._id ?? '');
                    setchannelId(result?.data?.newChannelPartnerId?.partnerName ?? '');
                    setPartnerContact(result?.data?.newChannelPartnerId?.partnerContact ?? '');
                    setpartnerPercentSold(result?.data?.newChannelPartnerId?.percentOrderSold ?? '');
                    setCreditRating(result?.data?.newChannelPartnerId?.creditRating ?? '');
                    setCarrierId(result?.data?.newCarrierId?.name ?? '');
                    setnewCarrierId(result?.data?.newCarrierId?._id ?? '');
                    setCarrierRating(result?.data?.newCarrierId?.carrierRating ?? '');
                    setCarrierContact(result?.data?.newCarrierId?.carrierContact ?? '');
                    setCarrierNPS(result?.data?.newCarrierId?.carrierNPS ?? '');
                    setOrderPickupTime(result?.data?.orderPickUpTime ?? '');
                    setPickupType(result?.data?.newPickupTypeId?.name ?? '');
                    setNewPickUpTypeId(result?.data?.newPickupTypeId?._id ?? '');
                    setPickupLocation(result?.data?.pickUpLocation ?? '');
                    setDropoffType(result?.data?.newDropoffTypeId?.name ?? '');
                    setNewDropoffTypeId(result?.data?.newDropoffTypeId?._id ?? '');
                    setItems(result?.data?.items ?? '');
                    setOrderNotes(result?.data?.orderNotes ?? '');
                    setadminNotes(result?.data?.adminNotes ?? '');
                    setsalesTeamId(result?.data?.salesTeamId?._id ?? '');
                    setCash(result?.data?.cash ?? '');
                    setparts(result?.data?.parts ?? '');
                    setpartsdata(result?.data?.partsdata ?? []);
                    setCollectiondatas(result?.data?.collectiondatas ?? []);
                    setsalesname(
                        result?.data?.salesTeamId?.name ?? `${result?.data?.salesTeamId?.firstName}${result?.data?.salesTeamId?.lastName}`
                    );
                    setplaceBy(`${result?.data?.userId?.firstName} ${result?.data?.userId?.lastName}`);
                    setsalesemail(result?.data?.salesemail ?? '');
                    setissuesdata(result?.data?.issuesdata ?? []);
                    setsizepart(result?.data?.sizepart ?? 0);
                    setdeliveryNoteData(result?.data?.deliveryNoteData ?? []);
                    setdispatchImageData(result?.data?.dispatchImageData ?? []);
                    setpacksortImageData(result?.data?.packsortImageData ?? []);
                    setdriverImageNoteData(result?.data?.driverImageNoteData ?? []);
                    setfulOrderId(result?.data?.fulOrderId ?? '');
                    setdiscount(result?.data?.discount ?? 0);
                    setdriverOrderStatus(result?.data?.driverOrderStatus ?? '');
                    setdriverCashCollected(result?.data?.driverCashCollected ?? '');
                    setretailerCredit(result?.data?.retailerCredit ?? 0);
                    setretailerCreditHolder(result?.data?.retailerCreditHolder ?? 0);
                    setretailerDebit(result?.data?.retailerDebit ?? 0);
                    setdiscountAppliedBy(result?.data?.discountAppliedBy ?? '');
                    setfulStatus(result?.data?.fulStatus ?? '');
                    setfulStatusRecived(result?.data?.fulStatusRecived ?? '');
                    setfulviewit(result?.data?.fulviewit ?? '');
                    setpartsRemovedDeliveryNote(result?.data?.partsRemovedDeliveryNote ?? []);
                    setpodId(result?.data?.podId ?? '');

                    // retailerCredit:{ type: Number },
                    // retailerCreditHolder:{ type: Number },
                    // retailerDebit: { type: Number },

                    // setName(result.data.name);
                    // setStatus(result.data.status);
                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                    // setLoading(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading2(false);
            });
        retrieveAllActiveChannel();
        retrieveAllOrderType();
        retrieveAllActivePaymentStatus();
        retrieveAllActiveOrderStatus();
        retrieveAllChannelPartner();
        retrieveAllCarrier();
        retrieveAllPart();
        retrieveAllPickDropType();
        retrieveAlLsalesTeam();
        retrieveAllDeliveryNoteData();
    }, [id, rma]);

    const handleOrderDateChange = (newValue) => {
        setorderDate(newValue);
    };
    const handleDeliveryDateChange = (newValue) => {
        setDeliveryDate(newValue);
    };
    const handlePartType = (event) => {
        if (event.target.value === 'Boxes') {
            // console.log('qty size', partQtyInPieces);
            // console.log('qty', qty);
            if (partQtyInPieces === 0) {
                setpartType(event.target.value);
            } else {
                const size = 'Pieces';
                setpartType(size);
                const piece = Number(qty) * Number(partQtyInPieces);
                setQty(piece);
            }
        } else if (event.target.value === 'Rolls') {
            // console.log('qty size', partQtyInPieces);
            if (partQtyInPieces === 0) {
                setpartType(event.target.value);
            } else {
                const size = 'Pieces';
                setpartType(size);
                const piece = Number(qty) * Number(partQtyInPieces);
                setQty(piece);
            }
        } else if (event.target.value === 'Packs') {
            // console.log('qty size', partQtyInPieces);
            if (partQtyInPieces === 0) {
                setpartType(event.target.value);
            } else {
                const size = 'Pieces';
                setpartType(size);
                const piece = Number(qty) * Number(partQtyInPieces);
                setQty(piece);
            }
        } else if (event.target.value === 'Carton') {
            if (partQtyInPieces === 0) {
                setpartType(event.target.value);
            } else {
                const size = 'Pieces';
                setpartType(size);
                const piece = Number(qty) * Number(partQtyInPieces);
                setQty(piece);
            }
        } else {
            setpartType(event.target.value);
        }
    };

    const handleApproveStatus = (event) => {
        setapproveStatus(event.target.value);

        const firstname = localStorage.getItem('userFirstName');
        const lastname = localStorage.getItem('userLastName');
        const fullname = firstname + lastname;
        const approveDate = new Date();

        setapproveUser(fullname);
        setapproveDate(approveDate);
    };

    const handlePayDueDateChange = (newValue) => {
        setPayDueDate(newValue);
        const DifferenceInTime = new Date().getTime() - new Date(newValue).getTime();
        // To calculate the no. of days between two dates
        const DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);
        const days = Math.round(DifferenceInDays);
        const newdays = days < 0 ? 0 : days;

        setdaysPastDue(newdays);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };
    const handleSalesChange = (event) => {
        setpodId(event.target.value);
        // setsalesTeamId(event.target.value);
        // const newFilter = salesTeamInfo.filter((value) => value._id.includes(event.target.value));
        // newFilter.forEach((option) => {
        //     if (option?.country === 'Nigeria') {
        //         const fullname = `${option.firstName} ${option.lastName}`;
        //         setsalesname(fullname);
        //     } else {
        //         setsalesname(option.name);
        //     }
        // });
    };

    const onSubmitPartEdit = (event) => {
        event.preventDefault();
        const newList = Object.assign([], items);
        const data = { ...newList[indexValue] };
        data.garagePrice = garagePrice;
        data.originalGaragePrice = garagePrice;
        data.qty = qty;
        data.partid = partid;
        data.skuId = skuId;
        data.partType = partType;
        data.skuId = skuId;
        data.applicableVins = applicableVins;
        data.brand = brand;
        data.wholeSalePrice = wholeSalePrice;
        data.supplier = supplier ?? '';
        data.partQtyInPieces = partQtyInPieces ?? 0;
        data.partimage = partimage ?? '';
        data.state = state ?? '';
        newList[indexValue] = data;
        setItems(newList);
        handleClose2();
        let sum = 0;
        let sum2 = 0;
        let sum3 = 0;
        setorderValue(sum2);
        setExpectedCash(sum);
        setoriginalExpectedCash(sum);

        setsizepart(sum3);
        newList.forEach((row) => {
            sum += Number(row.garagePrice) * Number(row.qty);
            // console.log('row data', row); expected cash
        });

        newList.forEach((row) => {
            sum2 += Number(row.wholeSalePrice) * Number(row.qty);
            // console.log('row data', row); order value
        });

        newList.forEach((row) => {
            sum3 += Number(row.qty);
        });

        setorderValue(sum2);
        setExpectedCash(sum);
        setoriginalExpectedCash(sum);
        // console.log('sum', sum3);
        setsizepart(sum3);
        const marginRate = (Number(sum) - Number(sum2)) / Number(sum);
        const value = isFinite(marginRate) ? marginRate : 0;
        const margin = Number(value).toFixed(2);

        setMargin(margin);

        const cashOut = Number(sum) - Number(actCashCollected) - Number(discount) - Number(retailerCredit);
        const value2 = isFinite(cashOut) ? cashOut : 0;
        const cashOutresult = Number(value2).toFixed(2);

        setCashOutstanding(cashOutresult);

        const actualMoney = Number(actCashCollected) + Number(discount) + Number(retailerCredit);

        const percent = (value2 / sum) * 100;

        setperCashOut(percent.toFixed(2));

        if (Number(sum) === Number(actualMoney)) {
            setpaymentStatusRecord('Paid');
            setNewPaymentStatusId('620d76a4ee512a471bf98c3e');
            // setorderStatusRecord('Order Receipt Generated');
            // setNewOrderStatusId('6228632de5b218f4be037d70');
            // setfulStatus('Delivered');
            // setfulStatusRecived('Delivered');
            setfulviewit('true');
        } else if (Number(actualMoney) > Number(sum)) {
            setpaymentStatusRecord('Paid');
            setNewPaymentStatusId('620d76a4ee512a471bf98c3e');
            // setorderStatusRecord('Order Receipt Generated');
            // setNewOrderStatusId('6228632de5b218f4be037d70');
            // setfulStatus('Delivered');
            // setfulStatusRecived('Delivered');
            setfulviewit('true');
        }
    };

    const onSubmitPart = (event) => {
        event.preventDefault();
        // items, setItems
        setItems([
            ...items,
            {
                skuId,
                partid,
                qty,
                brand,
                partType,
                wholeSalePrice,
                garagePrice,
                originalGaragePrice,
                applicableVins,
                supplier,
                partQtyInPieces,
                partimage,
                state
            }
        ]);

        handleClose();
        // console.log('row qty', qty);
        // console.log('row garagePrice', garagePrice);
        let sum = 0;
        let sum2 = 0;
        let sum3 = 0;
        setorderValue(sum2);
        setExpectedCash(sum);
        setoriginalExpectedCash(sum);
        setsizepart(sum3);
        const newList = Object.assign([], items);
        newList.forEach((row) => {
            sum += Number(row.garagePrice) * Number(row.qty);
            // console.log('row data', row); expected cash
        });

        newList.forEach((row) => {
            sum2 += Number(row.wholeSalePrice) * Number(row.qty);
            // console.log('row data', row); order value
        });

        newList.forEach((row) => {
            sum3 += Number(row.qty);
        });

        sum += Number(garagePrice) * Number(qty);
        sum2 += Number(wholeSalePrice) * Number(qty);
        sum3 += Number(qty);
        setorderValue(sum2);
        setExpectedCash(sum);
        setoriginalExpectedCash(sum);
        setsizepart(sum3);
        const marginRate = (Number(sum) - Number(sum2)) / Number(sum);
        const value = isFinite(marginRate) ? marginRate : 0;
        const margin = Number(value).toFixed(2);

        setMargin(margin);

        const cashOut = Number(sum) - Number(actCashCollected) - Number(discount) - Number(retailerCredit);
        const value2 = isFinite(cashOut) ? cashOut : 0;
        const cashOutresult = Number(value2).toFixed(2);
        const actualMoney = Number(actCashCollected) + Number(discount) + Number(retailerCredit);

        setCashOutstanding(cashOutresult);

        const percent = (value2 / sum) * 100;

        setperCashOut(percent.toFixed(2));

        if (Number(sum) === Number(actualMoney)) {
            setpaymentStatusRecord('Paid');
            setNewPaymentStatusId('620d76a4ee512a471bf98c3e');
            setorderStatusRecord('Order Receipt Generated');
            setNewOrderStatusId('6228632de5b218f4be037d70');
            setfulStatus('Delivered');
            setfulStatusRecived('Delivered');
            setfulviewit('true');
        } else if (Number(actualMoney) > Number(sum)) {
            setpaymentStatusRecord('Paid');
            setNewPaymentStatusId('620d76a4ee512a471bf98c3e');
            setorderStatusRecord('Order Receipt Generated');
            setNewOrderStatusId('6228632de5b218f4be037d70');
            setfulStatus('Delivered');
            setfulStatusRecived('Delivered');
            setfulviewit('true');
        }
    };

    // Submit Order  carrierId,
    // newCarrierId,
    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        setLoading(true);

        const data = {
            id,
            orderDate,
            channelId,
            newChannelId,
            channelPartnerId,
            newChannelPartnerId,
            orderValue,
            orderTypId,
            newOrdertypeId,
            dropOffLocation,
            deliveryDate,
            orderStatusId,
            newOrderStatusId,
            expCashCollected,
            originalExpectedCash,
            actCashCollected,
            cashOutStanding,
            perCashOutStanding,
            perMargin,
            payDueDate,
            daysPastDue,
            paymentStatusId,
            newPaymentStatusId,
            orderPickUpTime,
            pickUpTypeId,
            newPickupTypeId,
            pickUpLocation,
            dropOffTypeId,
            newDropoffTypeId,
            orderNotes,
            adminNotes,
            items,
            // salesTeamId,
            cash,
            collectiondatas,
            approveStatus,
            approveDate,
            approveAmt,
            paymentType,
            referenceNote,
            salesname,
            salesemail,
            collectedAmt,
            collectedDate,
            partsdata,
            issuesdata,
            sizepart,
            discount,
            retailerCredit,
            retailerCreditHolder,
            discountAppliedBy,
            fulStatus,
            fulStatusRecived,
            fulviewit,
            dropOffLocatLng,
            dropOffLocation2,
            fulOrderId,
            podId
        };

        fetch(ApiUrl.UpdateOrdersUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/order');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const filterDiscount = (event) => {
        const cashOut = expCashCollected - actCashCollected - event - retailerCredit;
        // console.log('cashOut', cashOut);
        setCashOutstanding(cashOut);
        const percent = (cashOut / expCashCollected) * 100;
        setperCashOut(percent.toFixed(2));
        const actualMoney = Number(actCashCollected) + Number(event) + Number(retailerCredit);
        const userFirstName = localStorage.getItem('userFirstName');
        const userLastName = localStorage.getItem('userLastName');
        const name = `${userFirstName}${userLastName}`;
        setdiscountAppliedBy(name);
        // const tt = Number(actualMoney);
        // setActualCash(tt);
        // console.log('actualmoney', actualMoney);
        if (Number(expCashCollected) === Number(actualMoney)) {
            setpaymentStatusRecord('Paid');
            setNewPaymentStatusId('620d76a4ee512a471bf98c3e');
            setorderStatusRecord('Order Receipt Generated');
            setNewOrderStatusId('6228632de5b218f4be037d70');
            setfulStatus('Delivered');
            setfulStatusRecived('Delivered');
            setfulviewit('true');
        } else if (cashOut < 0) {
            setpaymentStatusRecord('Paid');
            setNewPaymentStatusId('620d76a4ee512a471bf98c3e');
        } else if (cashOut > 0) {
            setpaymentStatusRecord('Pending');
            setNewPaymentStatusId('620d7693448d1be3b4ab1b08');
            setorderStatusRecord('Order Receipt Generated');
            setNewOrderStatusId('6228632de5b218f4be037d70');
        }
    };

    const filterRetailerCredit = (event) => {
        const cashOut = expCashCollected - actCashCollected - event - discount;
        // console.log('cashOut', cashOut);
        setCashOutstanding(cashOut);
        const percent = (cashOut / expCashCollected) * 100;
        setperCashOut(percent.toFixed(2));
        const actualMoney = Number(actCashCollected) + Number(event) + Number(discount);
        // const tt = Number(actualMoney);
        // setActualCash(tt);
        // console.log('actualmoney', actualMoney);
        if (Number(expCashCollected) === Number(actualMoney)) {
            setpaymentStatusRecord('Paid');
            setNewPaymentStatusId('620d76a4ee512a471bf98c3e');
            setorderStatusRecord('Order Receipt Generated');
            setNewOrderStatusId('6228632de5b218f4be037d70');
            setfulStatus('Delivered');
            setfulStatusRecived('Delivered');
            setfulviewit('true');
        } else if (cashOut < 0) {
            setpaymentStatusRecord('Paid');
            setNewPaymentStatusId('620d76a4ee512a471bf98c3e');
            setorderStatusRecord('Order Receipt Generated');
            setNewOrderStatusId('6228632de5b218f4be037d70');
            setfulStatus('Delivered');
            setfulStatusRecived('Delivered');
            setfulviewit('true');
        } else if (cashOut > 0) {
            setpaymentStatusRecord('Pending');
            setNewPaymentStatusId('620d7693448d1be3b4ab1b08');
        }
    };
    const filterCash = (event) => {
        const cashOut = expCashCollected - event - discount - retailerCredit;

        setCashOutstanding(cashOut);
        const percent = (cashOut / expCashCollected) * 100;

        setperCashOut(percent.toFixed(2));
        const actualMoney = Number(discount) + Number(event) + Number(retailerCredit);
        // setActualCash(actualMoney);
        // console.log('actualmoney', actualMoney);
        if (Number(expCashCollected) === Number(actualMoney)) {
            setpaymentStatusRecord('Paid');
            setNewPaymentStatusId('620d76a4ee512a471bf98c3e');
            setorderStatusRecord('Order Receipt Generated');
            setNewOrderStatusId('6228632de5b218f4be037d70');
            setfulStatus('Delivered');
            setfulStatusRecived('Delivered');
            setfulviewit('true');
        } else if (cashOut < 0) {
            setpaymentStatusRecord('Paid');
            setNewPaymentStatusId('620d76a4ee512a471bf98c3e');
            setorderStatusRecord('Order Receipt Generated');
            setNewOrderStatusId('6228632de5b218f4be037d70');
            setfulStatus('Delivered');
            setfulStatusRecived('Delivered');
            setfulviewit('true');
        } else if (cashOut > 0) {
            setpaymentStatusRecord('Pending');
            setNewPaymentStatusId('620d7693448d1be3b4ab1b08');
        }
    };
    const filterMargin = (event) => {
        const marginRate = (Number(event) - Number(orderValue)) / Number(event);
        const value = isFinite(marginRate) ? marginRate : 0;
        const margin = Number(value).toFixed(2);

        setMargin(margin);

        const cashOut = Number(event) - Number(actCashCollected) - Number(discount) - Number(retailerCredit);

        setCashOutstanding(cashOut);
        const percent = (cashOut / Number(event)) * 100;

        setperCashOut(percent.toFixed(2));
        setActualCash(0);
    };
    const filterOptions = (event) => {
        const newFilter = channelpartner.filter((value) => (value.partnerName || '').toLowerCase().includes((event || '').toLowerCase()));

        newFilter.forEach((option) => {
            if (option.partnerName === event) {
                setPartnerContact(option.partnerContact);
                setpartnerPercentSold(option.percentOrderSold);
                setCreditRating(option.creditRating);
                setNewChannelPartnerId(option._id);
                setdropOffLocation2(option?.location);
                setdropOffLocation(option.locationLat);
                setdropOffLocatLng(option.locationLng);
                setsalesTeamId(option?.salesTeamId);
            }
        });
    };

    const filterCarrier = (event) => {
        const newFilter = carrier.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));

        newFilter.forEach((option) => {
            if (option.name === event) {
                setCarrierContact(option.carrierContact);
                setCarrierNPS(option.carrierNPS);
                setCarrierRating(option.carrierRating);
                setnewCarrierId(option._id);
            }
        });
    };

    const filterPart = (event) => {
        const newFilter = part.filter((value) => (value.sku || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('newFilter', newFilter);
        newFilter.forEach((option) => {
            if (option.sku === event) {
                setSkuId(option._id ?? '');
                setBrand(option.brand ?? '');
                setwholeSalePrice(option.wholeSalePrice ?? '');
                setgaragePrice(option.garagePrice ?? '');
                setoriginalGaragePrice(option?.garagePrice ?? '');
                setapplicableVins(option.applicableVins ?? '');
                setpartQtyInPieces(option?.partQtyInPieces ?? 0);
                setpartimage(option?.partsImage ?? '');
                setstate(option?.imsstatus?.name);
                if (option?.stock) {
                    setSupplier(option?.stock);
                } else if (option?.distributor) {
                    setSupplier(option?.distributor?.partnerName);
                } else {
                    setSupplier('Third Party');
                }
            }
        });
    };

    const filterchannelId = (event) => {
        const newFilter = channel.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            if (option.name === event) {
                setNewChannelId(option._id);
            }
        });
    };
    const filterOrderType = (event) => {
        const newFilter = orderType.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            if (option.name === event) {
                setNewOrderTypeId(option._id);
            }
        });
    };

    const filterOrderStatus = (event) => {
        const newFilter = orderStatus.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            if (option.name === event) {
                setNewOrderStatusId(option._id);
            }
        });
    };

    const filterPaymentStatus = (event) => {
        const newFilter = paymentStatus.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            if (option.name === event) {
                setNewPaymentStatusId(option._id);
            }
        });
    };

    const filterPickType = (event) => {
        const newFilter = pickdroptype.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            if (option.name === event) {
                setNewPickUpTypeId(option._id);
            }
        });
    };

    const filterDropoffType = (event) => {
        const newFilter = pickdroptype.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            if (option.name === event) {
                setNewDropoffTypeId(option._id);
            }
        });
    };
    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    const handleDepositeDateChange = (newValue) => {
        setdepositeDate(newValue);
    };
    const handleCollectedDateChange = (newValue) => {
        setcollectedDate(newValue);
    };

    const handlePaymentTypeChange = (event) => {
        setpaymentType(event.target.value);
    };

    const handleClickOpen3 = () => {
        setOpen3(true);
    };

    const handleClose3 = () => {
        setOpen3(false);
    };

    const handleDeleteCollectionClick = (row, index) => {
        const newList = Object.assign([], collectiondatas);
        newList.splice(index, 1);
        setCollectiondatas(newList);
    };

    const handleDeleteClick = (row, index) => {
        // const newList = items.filter((item) => item.skuId !== row.skuId);
        // setItems(newList);
        const newList = Object.assign([], items);
        newList.splice(index, 1);
        setItems(newList);
        let sum = 0;
        let sum2 = 0;
        let sum3 = 0;
        setorderValue(sum2);
        setExpectedCash(sum);
        setoriginalExpectedCash(sum);
        setsizepart(sum3);

        newList.forEach((row) => {
            sum += Number(row.garagePrice) * Number(row.qty);
            // console.log('row data', row);
        });

        newList.forEach((row) => {
            sum2 += Number(row.wholeSalePrice) * Number(row.qty);
            // console.log('row data', row);
        });
        newList.forEach((row) => {
            sum3 += Number(row.qty);
        });

        setorderValue(sum2);
        setExpectedCash(sum);
        setoriginalExpectedCash(sum);
        setsizepart(sum3);
        const marginRate = (Number(sum) - Number(sum2)) / Number(sum);
        const value = isFinite(marginRate) ? marginRate : 0;
        const margin = Number(value).toFixed(2);
        setMargin(margin);

        const cashOut = Number(sum) - Number(actCashCollected) - Number(discount) - Number(retailerCredit);
        const value2 = isFinite(cashOut) ? cashOut : 0;
        const cashOutresult = Number(value2).toFixed(2);

        setCashOutstanding(cashOutresult);

        const percent = (value2 / sum) * 100;

        setperCashOut(percent.toFixed(2));
        const actualMoney = Number(actCashCollected) + Number(discount) + Number(retailerCredit);

        if (Number(sum) === Number(actualMoney)) {
            setpaymentStatusRecord('Paid');
            setNewPaymentStatusId('620d76a4ee512a471bf98c3e');
            setorderStatusRecord('Order Receipt Generated');
            setNewOrderStatusId('6228632de5b218f4be037d70');
            setfulStatus('Delivered');
            setfulStatusRecived('Delivered');
            setfulviewit('true');
        } else if (Number(actualMoney) > Number(sum)) {
            setpaymentStatusRecord('Paid');
            setNewPaymentStatusId('620d76a4ee512a471bf98c3e');
            setorderStatusRecord('Order Receipt Generated');
            setNewOrderStatusId('6228632de5b218f4be037d70');
            setfulStatus('Delivered');
            setfulStatusRecived('Delivered');
            setfulviewit('true');
        }
    };

    const handleEditClick = (row, index) => {
        // console.log('row', row);
        setPartId(row.partid ?? '');
        setQty(row.qty ?? '');
        setpartType(row?.partType ?? '');
        setgaragePrice(row.garagePrice ?? '');
        setoriginalGaragePrice(row?.originalGaragePrice ?? row.garagePrice);
        setSkuId(row.skuId ?? '');
        setapplicableVins(row.applicableVins ?? '');
        setBrand(row.brand ?? '');
        setwholeSalePrice(row.wholeSalePrice ?? '');
        setSupplier(row?.supplier ?? '');
        setpartQtyInPieces(row?.partQtyInPieces ?? 0);
        setpartimage(row?.partsImage ?? '');
        handleClickOpen2();
        // const newList = Object.assign([], items);
        // const idx = newList.findIndex((data) => data._id === row._id);
        // console.log('indexf', index);
        setIndexValue(index);
    };

    const handleEditIssueResolvedClick = (row, index) => {
        // console.log('row', row);
        const newList = Object.assign([], issuesdata);
        const data = { ...newList[index] };
        data.id = row.id ?? '';
        data.dateoforder = row.dateoforder ?? '';
        data.dateopened = row.dateopened ?? '';
        data.resolveddate = new Date() ?? '';
        data.orderno = row.orderno ?? '';
        data.clientname = row.clientname ?? '';
        data.salesname = row.salesname ?? '';
        data.description = row.description ?? '';
        data.status = 'resolved' ?? '';
        data.salesemail = row.salesemail ?? '';
        newList[index] = data;
        // console.log('data', data);
        setissuesdata(newList);
    };

    const handleEditApproveClick = (row, index) => {
        const firstname = localStorage.getItem('userFirstName');
        const lastname = localStorage.getItem('userLastName');
        const fullname = firstname + lastname;
        const approveDate = new Date();

        setdepositeDate(row?.depositeDate ?? '');
        setreferenceNote(row?.referenceNote ?? '');
        setdepositAmt(row?.depositeAmt ?? '');
        setdepositeImage(row?.depositeImage ?? '');
        setpaymentType(row?.paymentType ?? '');
        setsalesemail(row?.salesemail ?? '');
        setapproveUser(fullname);
        setapproveDate(approveDate);
        setapproveAmt(row?.depositeAmt ?? '');
        setorderDate(row?.orderDate ?? '');
        setcollectedAmt(row?.collectedAmt ?? '');
        setcollectedDate(row?.collectedDate ?? '');
        // setapproveStatus('approved');

        handleClickOpen3();
        // console.log('indexf', index);
        setIndexValue2(index);
    };

    const handleFulStatus = (event) => {
        setfulStatus(event.target.value);
    };

    const onSubmitCollection = (event) => {
        event.preventDefault();
        // console.log('----------------------------heeeerreee');
        const newList = Object.assign([], collectiondatas);
        const data = { ...newList[indexValue2] };
        data.id = id ?? '';
        data.channelPartnerId = channelPartnerId ?? '';
        data.salesname = salesname ?? '';
        data.depositeDate = depositeDate ?? '';
        data.paymentType = paymentType ?? '';
        data.depositeAmt = depositeAmt ?? '';
        data.referenceNote = referenceNote ?? '';
        data.depositeImage = depositeImage ?? '';
        data.approveUser = approveUser ?? '';
        data.approveDate = approveDate ?? '';
        data.approveAmt = approveAmt ?? '';
        data.approveStatus = approveStatus ?? '';
        data.salesemail = salesemail ?? '';
        data.collectedDate = collectedDate ?? '';
        data.collectedAmt = collectedAmt ?? '';
        data.orderDate = orderDate ?? '';
        newList[indexValue2] = data;
        setCollectiondatas(newList);
        handleClose3();

        const cashactual = Number(actCashCollected) + Number(approveAmt) + Number(discount) + Number(retailerCredit);

        // flag cash collections with -1 cedis

        const cashOut = Number(expCashCollected) - Number(cashactual);
        const cashOutresult = Number(cashOut).toFixed(2);
        const actualMoney = Number(actCashCollected) + Number(discount) + Number(retailerCredit);
        // console.log('cashOutresult', cashOutresult);
        if (cashOutresult < -1) {
            // console.log('----heeeeeerrrreee------');
            swal({
                icon: 'error',
                title: 'Oops Approval Flag...',
                text: `The approval amount ${Number(approveAmt).toFixed(2)}, when added to cash collected ${Number(actualMoney).toFixed(
                    2
                )} exceed the expected cash by !(${cashOutresult})`,
                footer: '<a href="">Kindly alert sales person to make the necessary correction</a>'
            });

            setapproveStatus('pending');
        } else {
            // console.log('-----worked------');
            setCashOutstanding(cashOutresult);
            const percent = (cashOut / expCashCollected) * 100;
            setperCashOut(percent.toFixed(2));
            setActualCash(cashactual);
            if (Number(expCashCollected) === Number(cashactual)) {
                setpaymentStatusRecord('Paid');
                setNewPaymentStatusId('620d76a4ee512a471bf98c3e');
                setorderStatusRecord('Order Receipt Generated');
                setNewOrderStatusId('6228632de5b218f4be037d70');
                setfulStatus('Delivered');
                setfulStatusRecived('Delivered');
                setfulviewit('true');
            } else if (Number(cashactual) > Number(expCashCollected)) {
                setpaymentStatusRecord('Paid');
                setNewPaymentStatusId('620d76a4ee512a471bf98c3e');
                setorderStatusRecord('Order Receipt Generated');
                setNewOrderStatusId('6228632de5b218f4be037d70');
                setfulStatus('Delivered');
                setfulStatusRecived('Delivered');
                setfulviewit('true');
            }
        }
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <br />
                    <PageHeader title="Order" subTitle="Home | View Order" />
                    <br />
                    <MainCard title="">
                        <Typography variant="h3" component="h2">
                            Main Information::Order No: {fulOrderId}
                        </Typography>
                        <br />
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            {/* <Stack spacing={1}> */}
                                            <DesktopDatePicker
                                                label="Order date"
                                                inputFormat="MM/dd/yyyy"
                                                value={orderDate}
                                                onChange={handleOrderDateChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            {/* </Stack> */}
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={channelId}
                                            onChange={(event, newValue) => {
                                                setChannelRecord(newValue);
                                                filterchannelId(newValue);
                                            }}
                                            options={channel.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Channel" />}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={channelPartnerId}
                                            onChange={(event, newValue) => {
                                                setChannelPartnerRecord(newValue);
                                                filterOptions(newValue);
                                            }}
                                            options={channelpartner.map((option) => option.partnerName)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Customer Name" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            name="name"
                                            variant="outlined"
                                            required
                                            id="name"
                                            label="Cost Value GHC"
                                            disabled
                                            value={orderValue}
                                            onChange={(e) => setorderValue(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={orderTypId}
                                            onChange={(event, newValue) => {
                                                setOrderTypeRecord(newValue);
                                                filterOrderType(newValue);
                                            }}
                                            options={orderType.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Order Type" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="dropOffLocation"
                                            variant="outlined"
                                            id="dropOffLocation"
                                            label="Drop off location"
                                            value={dropOffLocation}
                                            onChange={(e) => setdropOffLocation2(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            {/* <Stack spacing={1}> */}
                                            <DesktopDatePicker
                                                label="Fulfilled Date"
                                                inputFormat="MM/dd/yyyy"
                                                value={deliveryDate}
                                                onChange={handleDeliveryDateChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            {/* </Stack> */}
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={orderStatusId}
                                            onChange={(event, newValue) => {
                                                setorderStatusRecord(newValue);
                                                filterOrderStatus(newValue);
                                            }}
                                            options={orderStatus.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Receipt Status" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Order Status</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={fulStatus}
                                                label="Status"
                                                onChange={handleFulStatus}
                                                className="mb-3"
                                            >
                                                <MenuItem value="Body">Select an option</MenuItem>
                                                <MenuItem value="Received">Received</MenuItem>
                                                <MenuItem value="Processing">Processing</MenuItem>
                                                <MenuItem value="Dispatched">Dispatched</MenuItem>
                                                <MenuItem value="Assigned">Assigned</MenuItem>
                                                <MenuItem value="Delivered">Delivered</MenuItem>
                                                <MenuItem value="Cancelled">Cancelled</MenuItem>
                                                <MenuItem value="OnHold">OnHold</MenuItem>
                                                <MenuItem value="NextDay">NextDay</MenuItem>
                                                <MenuItem value="OutOfStock">OutOfStock</MenuItem>
                                                <MenuItem value="ProformaInvoice">Proforma Invoice</MenuItem>
                                                {/* <MenuItem value="PreSold">PreSold</MenuItem> */}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="fulOrderId"
                                            variant="outlined"
                                            required
                                            id="fulOrderId"
                                            label="Order No"
                                            value={fulOrderId}
                                            onChange={(e) => setfulOrderId(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            type="text"
                                            name="placeBy"
                                            variant="outlined"
                                            disabled
                                            id="placeBy"
                                            label="Place By"
                                            value={placeBy}
                                            onChange={(e) => setplaceBy(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />
                            <Typography variant="h3" component="h2">
                                Payment Information
                            </Typography>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            type="number"
                                            name="expCashCollected"
                                            variant="outlined"
                                            id="expCashCollected"
                                            label="Sales Value"
                                            value={expCashCollected}
                                            onChange={(e) => {
                                                setExpectedCash(e.target.value);
                                                filterMargin(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            name="actCashCollected"
                                            variant="outlined"
                                            id="actCashCollected"
                                            label="Cash Collected+Discount"
                                            value={actCashCollected}
                                            onChange={(e) => {
                                                setActualCash(e.target.value);
                                                filterCash(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            name="cashOutStanding"
                                            variant="outlined"
                                            id="cashOutStanding"
                                            label="Cash Outstanding"
                                            value={cashOutStanding}
                                            onChange={(e) => setCashOutstanding(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            name="perCashOutStanding"
                                            variant="outlined"
                                            id="perCashOutStanding"
                                            label="% Cash Outstanding"
                                            value={perCashOutStanding}
                                            onChange={(e) => setperCashOut(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            name="perMargin"
                                            variant="outlined"
                                            id="perMargin"
                                            label="% Margin"
                                            value={perMargin}
                                            onChange={(e) => setMargin(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            {/* <Stack spacing={1}> */}
                                            <DesktopDatePicker
                                                label="Pmt Due date"
                                                inputFormat="MM/dd/yyyy"
                                                value={payDueDate}
                                                onChange={handlePayDueDateChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            {/* </Stack> */}
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            name="daysPastDue"
                                            variant="outlined"
                                            id="daysPastDue"
                                            label="Days Past Due"
                                            value={daysPastDue}
                                            onChange={(e) => setdaysPastDue(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={paymentStatusId}
                                            onChange={(event, newValue) => {
                                                setpaymentStatusRecord(newValue);
                                                filterPaymentStatus(newValue);
                                            }}
                                            options={paymentStatus.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Payment Status" />}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            name="discount"
                                            variant="outlined"
                                            id="discount"
                                            label="Discount Amount"
                                            value={discount}
                                            onChange={(e) => {
                                                setdiscount(e.target.value);
                                                filterDiscount(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            type="text"
                                            name="discountAppliedBy"
                                            variant="outlined"
                                            id="discountAppliedBy"
                                            label="Discount Entered By"
                                            disabled
                                            value={discountAppliedBy}
                                            onChange={(e) => {
                                                setdiscountAppliedBy(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            name="retailerCredit"
                                            variant="outlined"
                                            id="retailerCredit"
                                            label="Credit Amount Value"
                                            value={retailerCredit}
                                            onChange={(e) => {
                                                setretailerCredit(e.target.value);
                                                filterRetailerCredit(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            name="retailerCreditHolder"
                                            variant="outlined"
                                            disabled
                                            id="retailerCreditHolder"
                                            label="Credit(amount garage owes)"
                                            value={retailerCreditHolder}
                                            onChange={(e) => {
                                                setretailerCreditHolder(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            name="retailerDebit"
                                            variant="outlined"
                                            disabled
                                            id="retailerDebit"
                                            label="Cash Outstanding Amount"
                                            value={retailerDebit}
                                            onChange={(e) => {
                                                setretailerDebit(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            disabled
                                            name="driverCashCollected"
                                            variant="outlined"
                                            id="driverCashCollected"
                                            label="Driver Cash Collected"
                                            value={driverCashCollected}
                                            onChange={(e) => {
                                                setdriverCashCollected(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            disabled
                                            name="driverOrderStatus"
                                            variant="outlined"
                                            id="driverOrderStatus"
                                            label="Driver Order Status"
                                            value={driverOrderStatus}
                                            onChange={(e) => {
                                                setdriverOrderStatus(e.target.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />
                            <Typography variant="h3" component="h2">
                                Channel Partner Information
                            </Typography>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            disabled
                                            id="combo-box-demo"
                                            value={channelPartnerId}
                                            onChange={(event, newValue) => {
                                                setchannelId(newValue);
                                            }}
                                            options={channelpartner.map((option) => option.partnerName)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Customer Name" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            name="partnerContact"
                                            variant="outlined"
                                            id="partnerContact"
                                            label="Customer Contact Info"
                                            value={partnerContact}
                                            onChange={(e) => setPartnerContact(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            name="partnerPercentSold"
                                            variant="outlined"
                                            id="partnerPercentSold"
                                            label="% Sold"
                                            value={partnerPercentSold}
                                            onChange={(e) => setpartnerPercentSold(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            name="creditrating"
                                            variant="outlined"
                                            id="creditrating"
                                            label="Credit rating"
                                            value={creditrating}
                                            onChange={(e) => setCreditRating(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                            <br />
                            <Typography variant="h3" component="h2">
                                Fulfillment Information
                            </Typography>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            disabled
                                            id="combo-box-demo"
                                            value={carrierId}
                                            onChange={(event, newValue) => {
                                                setCarrierId(newValue);
                                                filterCarrier(newValue);
                                            }}
                                            options={carrier.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Carrier" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            name="carrierRating"
                                            variant="outlined"
                                            // id="carrierRating"
                                            label="Fulfillment Rating"
                                            value={carrierRating}
                                            onChange={(e) => setCarrierRating(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            name="carrierContact"
                                            variant="outlined"
                                            id="carrierContact"
                                            label="Fulfillment Contact Info"
                                            value={carrierContact}
                                            onChange={(e) => setCarrierContact(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            name="carrierNPS"
                                            variant="outlined"
                                            id="carrierNPS"
                                            label="Fulfillment NPS"
                                            value={carrierNPS}
                                            onChange={(e) => setCarrierNPS(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="orderPickUpTime"
                                            variant="outlined"
                                            id="orderPickUpTime"
                                            label="Order Pickup Time"
                                            value={orderPickUpTime}
                                            onChange={(e) => setOrderPickupTime(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={pickUpTypeId}
                                            onChange={(event, newValue) => {
                                                setPickupType(newValue);
                                                filterPickType(newValue);
                                            }}
                                            options={pickdroptype.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Pickup Type" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="pickUpLocation"
                                            variant="outlined"
                                            id="pickUpLocation"
                                            label="Pickup Location"
                                            value={pickUpLocation}
                                            onChange={(e) => setPickupLocation(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={dropOffTypeId}
                                            onChange={(event, newValue) => {
                                                setDropoffType(newValue);
                                                filterDropoffType(newValue);
                                            }}
                                            options={pickdroptype.map((option) => option.name)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => <TextField {...params} label="Drop off Type" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            disabled
                                            name="dropOffLocation"
                                            variant="outlined"
                                            id="dropOffLocation"
                                            label="Drop off Location"
                                            value={dropOffLocation}
                                            onChange={(e) => setdropOffLocation2(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Pod Name</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={podId}
                                                label="POD"
                                                onChange={handleSalesChange}
                                            >
                                                {salesTeamInfo.map((sales) => (
                                                    <MenuItem key={sales._id} value={sales._id}>
                                                        {sales?.name ?? ''}
                                                        {/* {sales?.firstName ?? ''}
                                                        {sales?.lastName ?? ''} */}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Cash or Credit</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={cash}
                                                label="Status"
                                                onChange={handleCashChange}
                                            >
                                                <MenuItem value="Body">Select an option</MenuItem>
                                                <MenuItem value="Cash">Cash</MenuItem>
                                                <MenuItem value="Credit">Credit</MenuItem>
                                                <MenuItem value="Credit">Cheque</MenuItem>
                                                <MenuItem value="CashCredit">Cash and Credit</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />
                            <br />
                            <Typography variant="h3" component="h2">
                                Delivery Note Generated
                            </Typography>

                            <br />

                            <Grid container spacing={4} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={8}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Part
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        QTY
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Size
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Garage Price
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Image
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {deliveryNote.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.partid ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.qty ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row?.partType ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.garagePrice ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Avatar
                                                                variant="rounded"
                                                                src={row?.partimage ?? nologo}
                                                                style={{ border: 0, objectFit: 'cover' }}
                                                                sx={{ width: 100, height: 100 }}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>

                            <br />

                            <br />
                            <Typography variant="h3" component="h2">
                                Order Items
                            </Typography>
                            <br />

                            <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                <Button variant="contained" onClick={handleClickOpen} size="sm" className={classes.item}>
                                    Add item
                                </Button>
                            </Box>

                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        SKU
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Part
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        QTY
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Size
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Purchase Price
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Garage Retail Price
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Unit Amount
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Supplier
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Image
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {items.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.skuId}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.partid}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.qty}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.partType}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row.wholeSalePrice}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.garagePrice}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row" className={classes.tableHeaderCell}>
                                                            {Number(row.qty * row.garagePrice).toFixed(2)}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row?.supplier ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Avatar
                                                                variant="rounded"
                                                                src={row?.partimage ?? nologo}
                                                                style={{ border: 0, objectFit: 'cover' }}
                                                                sx={{ width: 100, height: 100 }}
                                                            />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                                aria-label="edit"
                                                                color="primary"
                                                                onClick={() => handleEditClick(row, index)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                onClick={() => handleDeleteClick(row, index)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <br />
                            <br />
                            <Typography variant="h3" component="h2">
                                Part Not Delivered
                            </Typography>

                            <br />

                            <Grid container spacing={4} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={8}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Part
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        QTY
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Size
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Garage Price
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Image
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {partsRemovedDeliveryNote.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.partid ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.qty ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row?.partType ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.garagePrice ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Avatar
                                                                variant="rounded"
                                                                src={row?.partimage ?? nologo}
                                                                style={{ border: 0, objectFit: 'cover' }}
                                                                sx={{ width: 100, height: 100 }}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <br />
                            <br />

                            <Typography variant="h3" component="h2">
                                Parts not in the system.
                            </Typography>

                            <br />

                            <Grid container spacing={4} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={8}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Part
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        QTY
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Size
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Supplier
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {partsdata.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.partname}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.partqty}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row.partsize}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.supplier2 ?? ''}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>

                            <br />
                            <Typography variant="h3" component="h2">
                                Collections
                            </Typography>
                            <br />

                            <Grid container spacing={4} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Collected Date
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Desposited Date
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Payment Type
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Collected Amount
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Deposited Amount
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Image
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Notes
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Approved Status
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {collectiondatas.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {toTimestamp(row?.collectedDate) ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {toTimestamp(row?.depositeDate) ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.paymentType ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row?.collectedAmt ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {Number.isFinite(row?.approveAmt)
                                                                ? Number(row?.approveAmt).toFixed(2)
                                                                : Number(row?.depositeAmt).toFixed(2)}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Avatar
                                                                variant="rounded"
                                                                src={row?.depositeImage}
                                                                sx={{ width: 80, height: 50 }}
                                                            />
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row?.referenceNote ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row?.approveStatus ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                                aria-label="edit"
                                                                color="primary"
                                                                onClick={() => handleEditApproveClick(row, index)}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>

                                                            <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                onClick={() => handleDeleteCollectionClick(row, index)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <br />
                            <Typography variant="h3" component="h2">
                                Delivery Note Image
                            </Typography>
                            <br />

                            <Grid container spacing={4} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Date
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        User
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Image
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {deliveryNoteData.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.capturedDate ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.capturedUser ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <Avatar variant="rounded" src={row?.imgFile} sx={{ width: 100, height: 100 }} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>

                            <br />
                            <Typography variant="h3" component="h2">
                                Dispatch Image
                            </Typography>
                            <br />

                            <Grid container spacing={4} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Date
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        User
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Image
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {dispatchImageData.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.capturedDate ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.capturedUser ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <Avatar variant="rounded" src={row?.imgFile} sx={{ width: 100, height: 100 }} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>

                            <br />
                            <Typography variant="h3" component="h2">
                                Pack and Sort Image
                            </Typography>
                            <br />

                            <Grid container spacing={4} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Date
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        User
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Image
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {packsortImageData.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.capturedDate ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.capturedUser ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <Avatar variant="rounded" src={row?.imgFile} sx={{ width: 100, height: 100 }} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>

                            <br />
                            <br />

                            <Typography variant="h3" component="h2">
                                Driver Delivery Note Image
                            </Typography>
                            <br />

                            <Grid container spacing={4} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Date
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        User
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Image
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {driverImageNoteData.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.capturedDate ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.capturedUser ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <Avatar variant="rounded" src={row?.imgFile} sx={{ width: 100, height: 100 }} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>

                            <br />
                            <br />

                            <br />
                            <Typography variant="h3" component="h2">
                                Issues
                            </Typography>
                            <br />

                            <Grid container spacing={4} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        #
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Issue Date
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Order Date
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Order Id
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Order No
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Retailer
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Sales Person
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Image
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Description
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Status
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Actions
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {issuesdata.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {toTimestamp(row?.dateopened) ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {toTimestamp(row?.dateoforder) ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.id ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row?.orderno ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row?.clientname ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row?.salesname ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Avatar
                                                                variant="rounded"
                                                                src={row?.issueImage}
                                                                sx={{ width: 80, height: 50 }}
                                                            />
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row?.description ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row.status === 'resolved' ? (
                                                                <Avatar
                                                                    variant="rounded"
                                                                    sx={{
                                                                        ...theme.typography.commonAvatar,
                                                                        ...theme.typography.largeAvatar,
                                                                        backgroundColor: theme.palette.success.light,
                                                                        color: theme.palette.success.dark,
                                                                        width: 85,
                                                                        height: 20
                                                                    }}
                                                                >
                                                                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                                    <Typography variant="h5">{row.status}</Typography>
                                                                </Avatar>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {row.status === 'open' ? (
                                                                <Avatar
                                                                    variant="rounded"
                                                                    sx={{
                                                                        ...theme.typography.commonAvatar,
                                                                        ...theme.typography.largeAvatar,
                                                                        backgroundColor: theme.palette.error.light,
                                                                        color: theme.palette.error.dark,
                                                                        width: 85,
                                                                        height: 20
                                                                    }}
                                                                >
                                                                    <DangerousRoundedIcon fontSize="small" />
                                                                    <Box width={5} /> <Typography variant="h5">{row.status}</Typography>
                                                                </Avatar>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                                aria-label="edit"
                                                                color="primary"
                                                                onClick={() => handleEditIssueResolvedClick(row, index)}
                                                            >
                                                                <BugReportIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>

                            <br />
                            <br />
                            <Typography variant="h3" component="h2">
                                Order Notes
                            </Typography>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TextField
                                        autoComplete="off"
                                        name="orderNotes"
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        fullWidth
                                        value={orderNotes}
                                        id="orderNotes"
                                        label="Order Notes"
                                        onChange={(e) => setOrderNotes(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <br />
                            <br />
                            <Typography variant="h3" component="h2">
                                Admin Comment/Notes
                            </Typography>
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TextField
                                        autoComplete="off"
                                        name="adminNotes"
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        fullWidth
                                        value={adminNotes}
                                        id="adminNotes"
                                        label="Admin Comment/Notes"
                                        onChange={(e) => setadminNotes(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <br />
                            <br />
                            <Box display="flex" justifyContent="space-between">
                                <Button component={Link} to="/order" type="submit" variant="contained" color="error">
                                    Back
                                </Button>
                                <Button type="submit" variant="contained" color="secondary">
                                    {loading ? 'Updating....' : 'Update Order'}
                                </Button>
                            </Box>
                        </form>

                        <Dialog
                            fullScreen={fullScreen}
                            open={open3}
                            onClose={handleClose3}
                            aria-labelledby="responsive-dialog-title"
                            fullWidth
                        >
                            <div style={{ padding: '1rem 2rem' }}>
                                <DialogTitle id="responsive-dialog-title" style={{ fontSize: '1rem' }}>
                                    Edit Collection
                                </DialogTitle>
                                <Form onSubmit={onSubmitCollection}>
                                    <DialogContent>
                                        <Form.Group controlId="formFile" className="mb-3">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <Stack spacing={3}>
                                                    <DesktopDatePicker
                                                        label="Collected Date"
                                                        inputFormat="MM/dd/yyyy"
                                                        value={collectedDate}
                                                        onChange={handleCollectedDateChange}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        fullWidth
                                                    />
                                                </Stack>
                                            </LocalizationProvider>
                                            <br /> <br />
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <Stack spacing={3}>
                                                    <DesktopDatePicker
                                                        label="Deposited Date"
                                                        inputFormat="MM/dd/yyyy"
                                                        value={depositeDate}
                                                        onChange={handleDepositeDateChange}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        fullWidth
                                                    />
                                                </Stack>
                                            </LocalizationProvider>
                                            <br /> <br />
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Payment Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={paymentType}
                                                    disabled
                                                    label="Status"
                                                    onChange={handlePaymentTypeChange}
                                                    fullWidth
                                                >
                                                    <MenuItem value="Body">Select an option</MenuItem>
                                                    <MenuItem value="MoMo">MoMo</MenuItem>
                                                    <MenuItem value="Bank">Bank</MenuItem>
                                                    <MenuItem value="Cash">Cash</MenuItem>
                                                    <MenuItem value="Cheque">Cheque</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <br /> <br />
                                            <TextField
                                                autoComplete="off"
                                                name="collectedAmt"
                                                variant="outlined"
                                                value={collectedAmt}
                                                id="collectedAmt"
                                                label="Collected Amount"
                                                onChange={(e) => {
                                                    setcollectedAmt(e.target.value);
                                                }}
                                                fullWidth
                                            />
                                            <br /> <br />
                                            <TextField
                                                autoComplete="off"
                                                name="depositeAmt"
                                                variant="outlined"
                                                value={depositeAmt}
                                                id="depositeAmt"
                                                label="Deposited Amount"
                                                onChange={(e) => {
                                                    setdepositAmt(e.target.value);
                                                    setapproveAmt(e.target.value);
                                                }}
                                                fullWidth
                                            />
                                            <br /> <br />
                                            <TextField
                                                autoComplete="off"
                                                name="referenceNote"
                                                variant="outlined"
                                                multiline
                                                rows={3}
                                                value={referenceNote}
                                                disabled
                                                id="referenceNote"
                                                label="Reference Notes......."
                                                onChange={(e) => setreferenceNote(e.target.value)}
                                                fullWidth
                                            />
                                            <br />
                                            <br />
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Approve Status</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={approveStatus}
                                                    label="Status"
                                                    onChange={handleApproveStatus}
                                                    fullWidth
                                                >
                                                    <MenuItem value="Body">Select an option</MenuItem>
                                                    <MenuItem value="approved">Approve</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Form.Group>
                                    </DialogContent>
                                    <DialogActions style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Button
                                            variant="contained"
                                            size="sm"
                                            className={classes.item}
                                            autoFocus
                                            onClick={handleClose3}
                                            color="error"
                                        >
                                            Cancel
                                        </Button>
                                        <Button autoFocus variant="contained" type="submit" size="sm" className={classes.item}>
                                            Add
                                        </Button>
                                    </DialogActions>
                                </Form>
                            </div>
                        </Dialog>

                        <Dialog
                            fullScreen={fullScreen}
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="responsive-dialog-title"
                            fullWidth
                        >
                            <div style={{ padding: '1rem 2rem' }}>
                                <DialogTitle id="responsive-dialog-title" style={{ fontSize: '1rem' }}>
                                    Add Part
                                </DialogTitle>
                                <Form onSubmit={onSubmitPart}>
                                    <DialogContent>
                                        {/* <DialogContentText> */}

                                        <Form.Group controlId="formFile" className="mb-3">
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                value={partid}
                                                onChange={(event, newValue) => {
                                                    setPartId(newValue);
                                                    filterPart(newValue);
                                                }}
                                                options={part.map((option) => option.sku)}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                renderInput={(params) => <TextField {...params} label="Sku" />}
                                                fullWidth
                                            />
                                            <br /> <br />
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="wholeSalePrice"
                                                variant="outlined"
                                                required
                                                id="wholeSalePrice"
                                                label="WholeSale Price"
                                                value={wholeSalePrice}
                                                onChange={(e) => setwholeSalePrice(e.target.value)}
                                                fullWidth
                                            />
                                            <br /> <br />
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="garagePrice"
                                                variant="outlined"
                                                required
                                                id="garagePrice"
                                                label="Garage Price"
                                                value={garagePrice}
                                                onChange={(e) => setgaragePrice(e.target.value)}
                                                fullWidth
                                            />
                                            <br /> <br />
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="qty"
                                                variant="outlined"
                                                required
                                                id="qty"
                                                label="Qty"
                                                value={qty}
                                                onChange={(e) => {
                                                    setQty(e.target.value);
                                                }}
                                                fullWidth
                                            />
                                            <br /> <br />
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Part Size</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={partType}
                                                    label="Status"
                                                    onChange={handlePartType}
                                                    fullWidth
                                                >
                                                    <MenuItem value="Body">Select an option</MenuItem>
                                                    <MenuItem value="Boxes">Boxes</MenuItem>
                                                    <MenuItem value="Pieces">Pieces</MenuItem>
                                                    <MenuItem value="Gallons">Gallons</MenuItem>
                                                    <MenuItem value="Drums">Drums</MenuItem>
                                                    <MenuItem value="Rolls">Roll(s)</MenuItem>
                                                    <MenuItem value="Bucket">Bucket</MenuItem>
                                                    <MenuItem value="Packs">Pack(s)</MenuItem>
                                                    <MenuItem value="Litre">Litre(s)</MenuItem>
                                                    <MenuItem value="Pair">Pair(s)</MenuItem>
                                                    <MenuItem value="Carton">Carton(s)</MenuItem>
                                                    <MenuItem value="Bag">Bag(s)</MenuItem>
                                                    <MenuItem value="Set">Set(s)</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <br /> <br />
                                            <Avatar
                                                variant="rounded"
                                                fullWidth
                                                src={partimage ?? nologo}
                                                style={{ border: 0, objectFit: 'cover' }}
                                                sx={{ width: '100%', height: '400px' }}
                                            />
                                        </Form.Group>

                                        {/* </DialogContentText> */}
                                    </DialogContent>
                                    <DialogActions style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Button
                                            variant="contained"
                                            size="sm"
                                            className={classes.item}
                                            autoFocus
                                            onClick={handleClose}
                                            color="error"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            autoFocus
                                            variant="contained"
                                            type="submit"
                                            size="sm"
                                            className={classes.item}
                                            disabled={!partid || !garagePrice || !qty}
                                        >
                                            Add
                                        </Button>
                                    </DialogActions>
                                </Form>
                            </div>
                        </Dialog>

                        {/* Edit Parts  */}

                        <Dialog
                            fullScreen={fullScreen}
                            open={open2}
                            onClose={handleClose2}
                            aria-labelledby="responsive-dialog-title"
                            fullWidth
                        >
                            <div style={{ padding: '1rem 2rem' }}>
                                <DialogTitle id="responsive-dialog-title" style={{ fontSize: '1rem' }}>
                                    Edit Part
                                </DialogTitle>
                                <Form onSubmit={onSubmitPartEdit}>
                                    <DialogContent>
                                        {/* <DialogContentText> */}

                                        <Form.Group controlId="formFile" className="mb-3">
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                value={partid}
                                                onChange={(event, newValue) => {
                                                    setPartId(newValue);
                                                    filterPart(newValue);
                                                }}
                                                options={part.map((option) => option.sku)}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                renderInput={(params) => <TextField {...params} label="Sku" />}
                                                fullWidth
                                            />
                                            <br /> <br />
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="wholeSalePrice"
                                                variant="outlined"
                                                required
                                                id="wholeSalePrice"
                                                label="WholeSale Price"
                                                value={wholeSalePrice}
                                                onChange={(e) => setwholeSalePrice(e.target.value)}
                                                fullWidth
                                            />
                                            <br /> <br />
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="garagePrice"
                                                variant="outlined"
                                                required
                                                id="garagePrice"
                                                label="Garage Price"
                                                value={garagePrice}
                                                onChange={(e) => setgaragePrice(e.target.value)}
                                                fullWidth
                                            />
                                            <br /> <br />
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                name="qty"
                                                variant="outlined"
                                                required
                                                id="qty"
                                                label="Qty"
                                                value={qty}
                                                onChange={(e) => setQty(e.target.value)}
                                                fullWidth
                                            />
                                            <br /> <br />
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Part Size</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={partType}
                                                    label="Status"
                                                    onChange={handlePartType}
                                                    fullWidth
                                                >
                                                    <MenuItem value="Body">Select an option</MenuItem>
                                                    <MenuItem value="Boxes">Boxes</MenuItem>
                                                    <MenuItem value="Pieces">Pieces</MenuItem>
                                                    <MenuItem value="Gallons">Gallons</MenuItem>
                                                    <MenuItem value="Drums">Drums</MenuItem>
                                                    <MenuItem value="Rolls">Roll(s)</MenuItem>
                                                    <MenuItem value="Bucket">Bucket</MenuItem>
                                                    <MenuItem value="Packs">Pack(s)</MenuItem>
                                                    <MenuItem value="Litre">Litre(s)</MenuItem>
                                                    <MenuItem value="Pair">Pair(s)</MenuItem>
                                                    <MenuItem value="Carton">Carton(s)</MenuItem>
                                                    <MenuItem value="Bag">Bag(s)</MenuItem>
                                                    <MenuItem value="Set">Set(s)</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <br /> <br />
                                            <Avatar
                                                variant="rounded"
                                                src={partimage ?? nologo}
                                                style={{ border: 0, objectFit: 'cover' }}
                                                sx={{ width: '100%', height: '400px' }}
                                            />
                                        </Form.Group>

                                        {/* </DialogContentText> */}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            variant="contained"
                                            size="sm"
                                            className={classes.item}
                                            autoFocus
                                            onClick={handleClose2}
                                            color="error"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            autoFocus
                                            variant="contained"
                                            type="submit"
                                            size="sm"
                                            className={classes.item}
                                            disabled={!partid || !garagePrice || !qty}
                                        >
                                            Add
                                        </Button>
                                    </DialogActions>
                                </Form>
                            </div>
                        </Dialog>
                    </MainCard>
                </>
            )}
        </>
    );
};
export default ViewOrder;
