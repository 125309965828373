/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';
// import Controls from '../../components/controls/Controls';
// import { Search } from '@mui/icons-material';
// import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import ViewIcon from '@mui/icons-material/ViewAgenda';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
// import swal from 'sweetalert';
// import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
import axios from 'axios';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Avatar from '@mui/material/Avatar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import FormControl from '@mui/material/FormControl';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
// import NoteAltIcon from '@mui/icons-material/NoteAlt';
// import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
// import SortIcon from '@mui/icons-material/Sort';
// import * as blobUtil from 'blob-util';
// import { Camera } from 'react-camera-pro';
import { useTheme } from '@mui/material/styles';
// import { saveAs } from 'file-saver';
// import axios from 'axios';
import { Col } from 'react-grid-system';
import TextField from '@mui/material/TextField';
// import { cryptoUUID } from '../../utils/functions';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    }
}));

const PreSoldMyOrder = () => {
    const classes = useStyles();
    const history = useNavigate();
    const theme = useTheme();
    // const scriptedRef = useScriptRef();
    const [orderInfo, setOrder] = useState([]);
    // const [orderData, setOrderData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    // const [loading2, setLoading2] = useState(false);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [count, setCount] = useState(50);
    const [searched, setSearched] = useState('');
    const [localSearch, setlocalSearch] = useState('');
    // const userCountry = localStorage.getItem('country');

    // const handleMoMoPayClick = (row) => {
    //     if (scriptedRef.current) {
    //         const ids = cryptoUUID();
    //         console.log('ids', ids);
    //         // console.log(row);
    //         history(`/payorder/${row._id}/${ids}`);
    //     }
    // };

    const handleEditClick = (row) => {
        history(`/order-request-form-pre-sold/${row._id}`);
        // const date = new Date();

        // const FIFETEEB_MIN = 15 * 60 * 1000;

        // if (date - new Date(row?.orderDate) > FIFETEEB_MIN && row?.newOrderStatusId?._id !== '651da6eb9d048db5123bc205') {
        //     swal({
        //         icon: 'error',
        //         title: 'Oops...',
        //         text: 'Update Delayed by more than 15 minutes. Kindly contact your Pod Portfolio',
        //         footer: '<a href="">Why do I have this issue?</a>'
        //     });
        // } else if (scriptedRef.current) {
        //     history(`/viewmysalesorders/${row._id}`);
        // }
    };

    // const handleShowClick = (row) => {
    //     if (scriptedRef.current) {
    //         // console.log(row); preview
    //         history(`/previewmysalesorders/${row._id}`);
    //     }
    // };

    // const handleIssueClick = (row) => {
    //     if (scriptedRef.current) {
    //         // console.log(row); preview
    //         history(`/addissue/${row._id}`);
    //     }
    // };

    // const handleCollectionClick = (row) => {
    //     if (scriptedRef.current) {
    //         // console.log(row); preview
    //         history(`/addcollections/${row._id}`);
    //     }
    // };

    const handleChangePage = (event, newPage) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);

            fetch(`${ApiUrl.RmaPreSoldOrderUrl}?page=${newPage}`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrder(data.data ?? []);
                        setPage(data?.currentPage);
                        setCount(data?.totalOrders);
                        setRowsPerPage(data?.perPage);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);

            fetch(`${ApiUrl.RmaPreSoldOrderUrl}?limit=${event.target.value}`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrder(data.data ?? []);
                        setPage(data?.currentPage);
                        setCount(data?.totalOrders);
                        setRowsPerPage(data?.perPage);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderInfo.length) : 0;

    const retrieveAllOrders = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            let url = `${ApiUrl.RmaPreSoldOrderUrl}`;
            if (searched) {
                url += `?partner=${searched}`;
            }

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setOrder(data.data ?? []);
                        setPage(data?.currentPage);
                        setCount(data?.totalOrders);
                        setRowsPerPage(data?.perPage);
                        // setOrderData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handledateChange = (event, picker) => {
        setstartDate(picker.startDate._d);
        setendDate(picker.endDate._d);
        const from = picker.startDate._d;
        const to = picker.endDate._d;
        setLoading(true);
        const data = {
            from,
            to
        };
        localStorage.setItem('PreSoldMyOrder', JSON.stringify(data));
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);

            let url = `${ApiUrl.RmaPreSoldOrderUrl}`;
            if (from && to) {
                url += `?from=${from}&to=${to}`;
            }

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        toast.success(data.message);
                        setOrder(data.data ?? []);
                        setPage(data?.currentPage);
                        setCount(data?.totalOrders);
                        setRowsPerPage(data?.perPage);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handledateChangeNew = () => {
        setLoading(true);

        const order = localStorage.getItem('PreSoldMyOrder');
        const data = JSON.parse(order);
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);

            let url = `${ApiUrl.RmaPreSoldOrderUrl}`;
            if (data?.from && data?.to) {
                url += `?from=${data?.from}&to=${data?.to}`;
            }

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        toast.success(data.message);
                        setOrder(data.data ?? []);
                        setPage(data?.currentPage);
                        setCount(data?.totalOrders);
                        setRowsPerPage(data?.perPage);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const debounce = () => {
        let timeoutID;
        return (e) => {
            setlocalSearch(e.target.value);
            clearTimeout(timeoutID);
            timeoutID = setTimeout(() => {
                setSearched(e.target.value);
            }, 500);
        };
    };

    const handleSearch = useMemo(() => debounce(), []);

    useEffect(() => {
        const order = localStorage.getItem('PreSoldMyOrder');
        const datas = JSON.parse(order);
        if (datas?.from && datas?.to) {
            handledateChangeNew();
            console.log('run date here');
        } else {
            retrieveAllOrders();
        }
        // eslint-disable-next-line
    }, [searched]);

    const handleReset = () => {
        localStorage.removeItem('PreSoldMyOrder');
        retrieveAllOrders();
    };
    // const filteredRows = orderInfo.filter((row) => (row?.channelPartnerId || '').toLowerCase().includes(searched.toLowerCase()));
    // const isPlural = filteredRows && filteredRows.length > 1 ? 's' : '';
    // const searchCount = searched ? `${filteredRows.length} result${isPlural} found` : 'No Results';

    // const handleDeleteClick = (row) => {
    //     swal({
    //         title: 'Are you sure?',
    //         text: `Once deleted, you will not be able to recover this Order !(${row._id})`,
    //         icon: 'warning',
    //         buttons: true,
    //         dangerMode: true
    //     }).then((willDelete) => {
    //         if (willDelete) {
    //             swal('Poof! The Order has been deleted!', {
    //                 icon: 'success'
    //             });

    //             const data = {
    //                 id: row._id
    //             };
    //             const userInfo = localStorage.getItem('userToken');

    //             fetch(ApiUrl.DeleteSalesOrderRequest, {
    //                 method: 'DELETE',
    //                 headers: {
    //                     'auth-token': userInfo,
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify(data)
    //             })
    //                 .then((res) => res.json())
    //                 .then((result) => {
    //                     if (result.status === true) {
    //                         if (scriptedRef.current) {
    //                             retrieveAllOrders();
    //                         }
    //                     } else {
    //                         toast.error(result.data);
    //                     }
    //                 });
    //         } else {
    //             swal('The  Order is safe!');
    //         }
    //     });
    // };
    const handlePdfClick = (row) => {
        // console.log('row', row);
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };
        // setLoading2(true);
        axios.post(ApiUrl.DownloadPdfOrdersUrl, row, config).then((res) => {
            window.open(res.data.data, '_blank');

            // setLoading2(false);
        });
    };

    // const handleSearch = (event) => {
    //     const searchWord = event.target.value;

    //     const newFilter = orderInfo.filter((value) =>
    //         (value?.channelPartnerId || '').toLowerCase().includes((searchWord || '').toLowerCase())
    //     );

    //     if (searchWord === '') {
    //         setOrder(orderData);
    //     } else {
    //         setOrder(newFilter);
    //     }
    // };

    return (
        <>
            <PageHeader title="PreSold Orders" subTitle="Sales | PreSold Order " />

            <MainCard title="PreSold Order List">
                <h4 style={{ color: '#ff740f', fontWeight: '700' }}>{`Total PreSold Orders: ${count}`}</h4>
                <br />
                <Box display="flex" justifyContent="flex-start">
                    <Col xs={12} xl={3} lg={3} md={6} sm={6}>
                        <FormControl fullWidth style={{ marginBottom: '10px' }}>
                            <TextField
                                id="outlined-basic"
                                label="Search Partner Name"
                                variant="outlined"
                                value={localSearch}
                                onChange={handleSearch}
                            />
                        </FormControl>
                    </Col>
                </Box>
                <br />

                <Box width={20} />
                <Box display="flex" justifyContent="flex-start">
                    <DateRangePicker initialSettings={{ startDate, endDate }} onApply={handledateChange}>
                        <Button variant="contained" size="sm" className={classes.item}>
                            filter by Date
                        </Button>
                    </DateRangePicker>
                    <Box width={10} />

                    <Button variant="contained" onClick={handleReset} size="sm" className={classes.item} color="error">
                        Clear Filter
                    </Button>
                    <Box width={10} />
                    <Button component={Link} to="/order-request-form" variant="contained" size="sm" className={classes.item}>
                        New
                    </Button>
                </Box>
                <br />

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>
                                        {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order ID
                                        </TableCell> */}

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order Date
                                        </TableCell>
                                        {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Channel
                                        </TableCell> */}

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Customer Name
                                        </TableCell>

                                        {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order Type
                                        </TableCell> */}

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Sales Value
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Cash Collected
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Cash Outstanding
                                        </TableCell>

                                        {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Delivery Date
                                        </TableCell> */}

                                        {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            % sold
                                        </TableCell> */}

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order Status
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Receipt Status
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orderInfo.map((row, index) => (
                                <TableRow key={row._id}>
                                    <TableCell component="th" scope="row">
                                        {orderInfo.length - index}
                                    </TableCell>
                                    {/* <TableCell component="th" scope="row">
                                            {row._id}
                                        </TableCell> */}
                                    <TableCell component="th" scope="row">
                                        {toTimestamp(row?.orderDate)}
                                    </TableCell>
                                    {/* <TableCell component="th" scope="row">
                                            {row.channelId}
                                        </TableCell> */}

                                    <TableCell component="th" scope="row">
                                        {row?.channelPartnerId}
                                    </TableCell>

                                    {/* <TableCell component="th" scope="row">
                                            {row.orderTypId}
                                        </TableCell> */}

                                    <TableCell component="th" scope="row">
                                        {Number.isFinite(row?.expCashCollected) ? Number(row?.expCashCollected).toFixed(2) : 0}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {Number.isFinite(row?.actCashCollected) ? Number(row?.actCashCollected).toFixed(2) : 0}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {Number.isFinite(row?.cashOutStanding)
                                            ? Number(row?.cashOutStanding).toFixed(2)
                                            : Number(row?.expCashCollected).toFixed(2)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.fulStatus === 'Delivered' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.success.light,
                                                    color: theme.palette.success.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row?.fulStatus === 'Cancelled' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.error.light,
                                                    color: theme.palette.error.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <DangerousRoundedIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row?.fulStatus === 'Received' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.primary.light,
                                                    color: theme.palette.primary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row?.fulStatus === 'Dispatched' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.warning.light,
                                                    color: theme.palette.warning.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row?.fulStatus === 'Processing' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.warning.light,
                                                    color: theme.palette.warning.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row?.fulStatus === 'ProformaInvoice' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row?.fulStatus === 'SalesDrive' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row?.fulStatus === 'OnHold' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row?.fulStatus === 'NextDay' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row?.fulStatus === 'PreSold' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row?.fulStatus ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row.newOrderStatusId._id === '6228632de5b218f4be037d70' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.success.light,
                                                    color: theme.palette.success.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row.newOrderStatusId._id === '622b9bf0f3bcbc9eb0c203ea' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.error.light,
                                                    color: theme.palette.error.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <DangerousRoundedIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row.newOrderStatusId._id === '620d76d63a9c8148935c696b' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.primary.light,
                                                    color: theme.palette.primary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row.newOrderStatusId._id === '625311abbd74072935e58592' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row.newOrderStatusId._id === '625739679a0af3ae9771daef' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.error.light,
                                                    color: theme.palette.error.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <DangerousRoundedIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row.newOrderStatusId._id === '622b9bcdf3bcbc9eb0c203e2' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.warning.light,
                                                    color: theme.palette.warning.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row.newOrderStatusId._id === '6334bab7afa1158e678a87ad' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <RemoveCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row.newOrderStatusId._id === '6342dc27f627d58acac98e0f' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row.newOrderStatusId._id === '636c1abb26ac75c9880bddd6' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                        {row.newOrderStatusId._id === '636c1ab126ac75c9880bddd2' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row.newOrderStatusId._id === '6466771d8328e2d254fa6ac3' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row.newOrderStatusId._id === '651da6eb9d048db5123bc205' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row.newOrderStatusId._id === '6527fe1dba6c46cb61943f5c' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.secondary.light,
                                                    color: theme.palette.secondary.dark,
                                                    width: 155,
                                                    height: 40
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                <Typography variant="h5">{row.newOrderStatusId?.name ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                    </TableCell>
                                    {/* <TableCell component="th" scope="row">
                                            {row?.newPaymentStatusId?.name ?? ''}
                                        </TableCell> */}

                                    <TableCell component="th" scope="row">
                                        {row?.newOrderStatusId?._id === '6228632de5b218f4be037d70' ||
                                        row?.newOrderStatusId?._id === '6342dc27f627d58acac98e0f' ||
                                        row?.newOrderStatusId?._id === '6334bab7afa1158e678a87ad' ||
                                        row?.cityId === '63597eea49544ce239ed13af' ? (
                                            <>
                                                <IconButton aria-label="delete" color="success" onClick={() => handlePdfClick(row)}>
                                                    Receipt
                                                </IconButton>
                                            </>
                                        ) : (
                                            ''
                                        )}

                                        {row?.newOrderStatusId?._id === '622b9bcdf3bcbc9eb0c203e2' ||
                                        row?.newOrderStatusId?._id === '622b9bf0f3bcbc9eb0c203ea' ||
                                        row?.newOrderStatusId?._id === '6228632de5b218f4be037d70' ||
                                        row?.newOrderStatusId?._id === '622b9be0f3bcbc9eb0c203e6' ? (
                                            ''
                                        ) : (
                                            <>
                                                <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(row)}>
                                                    Update
                                                </IconButton>
                                            </>
                                        )}
                                        {/* 
                                        {row?.newPaymentStatusId?._id === '620d76a4ee512a471bf98c3e' ? (
                                            ''
                                        ) : (
                                            <>
                                                <IconButton aria-label="view" color="success" onClick={() => handleCollectionClick(row)}>
                                                    Collection
                                                </IconButton>
                                            </>
                                        )} */}
                                        {/* 
                                        <IconButton aria-label="view" color="error" onClick={() => handleIssueClick(row)}>
                                            Issue
                                        </IconButton>

                                        <IconButton aria-label="view" color="warning" onClick={() => handleShowClick(row)}>
                                            Preview
                                        </IconButton> */}
                                        {/* {userCountry === 'Ghana' ? (
                                            <>
                                                <IconButton aria-label="delete" color="error" onClick={() => handleMoMoPayClick(row)}>
                                                    MoMoPay
                                                </IconButton>
                                            </>
                                        ) : (
                                            ''
                                        )} */}

                                        {/* <IconButton aria-label="view" color="warning" onClick={() => handleDeleteClick(row)}>
                                            delete
                                        </IconButton> */}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[100, 200, 300, count]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={!count || count <= 0 ? 0 : page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default PreSoldMyOrder;
