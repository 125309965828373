/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';
// import Controls from '../../components/controls/Controls';
// import { Search } from '@mui/icons-material';
// import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
// import { Avatar } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
// import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const Users = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [userInfos, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searched, setSearched] = useState('');

    const handleEditClick = (row) => {
        if (scriptedRef.current) {
            history(`/editUser/${row._id}`);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userInfos.length) : 0;

    const retrieveAllUsers = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllUsersUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        // console.log(data.data);
                        setUsers(data.data);
                        // console.log(data.data, 'data');
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handledataexport = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(userInfos);
        XLSX.utils.book_append_sheet(wb, ws, 'Users');
        XLSX.writeFile(wb, 'Users.xlsx');
    };

    // const handleDeleteClick = (row) => {
    //     swal({
    //         title: 'Are you sure?',
    //         text: `Once deleted, you will not be able to recover this User!(${row.firstName})`,
    //         icon: 'warning',
    //         buttons: true,
    //         dangerMode: true
    //     }).then((willDelete) => {
    //         if (willDelete) {
    //             swal('Poof! The User has been deleted!', {
    //                 icon: 'success'
    //             });

    //             const data = {
    //                 id: row._id
    //             };
    //             const userInfo = localStorage.getItem('userToken');

    //             fetch(ApiUrl.DeleteUserUrl, {
    //                 method: 'DELETE',
    //                 headers: {
    //                     'auth-token': userInfo,
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify(data)
    //             })
    //                 .then((res) => res.json())
    //                 .then((result) => {
    //                     if (result.status === true) {
    //                         if (scriptedRef.current) {
    //                             retrieveAllUsers();
    //                             // window.location.href = '/stage';
    //                             // history('/stage');
    //                         }
    //                     } else {
    //                         toast.error(result.data);
    //                     }
    //                 });
    //         } else {
    //             swal('The User is safe!');
    //         }
    //     });
    // };

    useEffect(() => {
        // retrieve all users;
        retrieveAllUsers();
    }, []);

    // const handleSearch = (event) => {
    //     const searchWord = event.target.value;

    //     const newFilter = userInfos.filter((value) => value.firstName.toLowerCase().includes(searchWord.toLowerCase()));

    //     if (searchWord === '') {
    //         setUsers(userData);
    //     } else {
    //         setUsers(newFilter);
    //     }
    // };

    const filteredRows = userInfos.filter(
        (row) =>
            (row?.firstName && row?.firstName.toLowerCase().includes(searched.toLowerCase())) ||
            (row?.lastName && row?.lastName.toLowerCase().includes(searched.toLowerCase())) ||
            (row?.email && row?.email.toLowerCase().includes(searched.toLowerCase())) ||
            (row?.roles && row?.roles[0]?.roleName.toLowerCase().includes(searched.toLowerCase())) ||
            (row?.temporaryBlock === false
                ? 'Active'.toLowerCase().includes(searched.toLowerCase())
                : 'Temporary Disabled'.toLowerCase().includes(searched.toLowerCase())) ||
            (row?.country && row?.country.toLowerCase().includes(searched.toLowerCase())) ||
            (row?.cityId?.city && row?.cityId?.city.toLowerCase().includes(searched.toLowerCase())) ||
            (row?.contactPhone && row?.contactPhone.toLowerCase().includes(searched.toLowerCase())) ||
            (row?.createdAt && row?.createdAt.toLowerCase().includes(searched.toLowerCase()))
    );
    const isPlural = filteredRows && filteredRows.length > 1 ? 's' : '';
    const searchCount = searched ? `${filteredRows.length} result${isPlural} found` : 'No Results';

    return (
        <>
            <br />
            <PageHeader title="Users" subTitle="Home | Configuration | User Management | Users" />
            <br />
            <MainCard title="User List">
                <h4 style={{ color: '#ff740f', fontWeight: '700' }}>{searched ? searchCount : `Total Users: ${filteredRows.length}`}</h4>
                <br />
                <Box display="flex" justifyContent="space-between">
                    <Grid>
                        <FormControl fullWidth style={{ marginBottom: '10px' }}>
                            <TextField
                                id="outlined-basic"
                                label="Search Name"
                                variant="outlined"
                                onChange={(e) => setSearched(e.target.value)}
                            />
                        </FormControl>
                        {/* <Controls.Input
                            label="Search User"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                        /> */}
                    </Grid>
                    <Box width={10} />

                    <Button component={Link} to="/addUser" variant="contained" size="sm" className={classes.item}>
                        Add User
                    </Button>

                    <Box width={10} />
                    {/* <Button onClick={handledataexport} variant="contained" size="sm" className={classes.item} color="success">
                        Download Excel
                    </Button> */}
                </Box>
                <br />

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress />{' '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            First Name
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Last Name
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Email
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Role
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Status
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Country
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            City
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            PhoneNumber
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Date
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : filteredRows
                            ).map((row, index) => (
                                <TableRow key={row._id}>
                                    <TableCell component="th" scope="row">
                                        {filteredRows.length - index}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.firstName}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.lastName}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.email}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.roles?.map((role) => (
                                            <p key={role._id}>{role?.roleName ?? ''}</p>
                                        ))}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.temporaryBlock === false ? 'Active' : 'Temporary Disabled'}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.country ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.cityId?.city ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row.contactPhone}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {toTimestamp(row.createdAt)}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        <Tooltip title="Update/Block User">
                                            <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(row)}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>

                                        {/* <Tooltip title="Delete User">
                                            <IconButton aria-label="delete" color="error" onClick={() => handleDeleteClick(row)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip> */}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    component="div"
                    count={userInfos.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default Users;
