import { Button, DatePicker, Select, Skeleton, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { decrypt } from '../../utils/encrypt';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import ApiUrl from '../../network';

export const Index = () => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    const initialState = {
        startDate: '',
        endDate: '',
        orderType: '',
        country: '',
        batchNo: '',
        orderNo: '',
        batchStatus: '',
        limit: ''
    };
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const { RangePicker } = DatePicker;
    const [orders, setOrders] = useState([]);
    const [batchStatus, setBatchStatus] = useState([]);
    const [countries, setCountries] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState(initialState);
    const [salesPlan, setSalesPlan] = useState([]);

    const fetchOrders = async (data) => {
        setLoading(true);
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/waybill/allbulkorders`;

            if (data) {
                if (data.startDate && data.endDate) {
                    urlParams.set('startDate', data.startDate);
                    urlParams.set('endDate', data.endDate);
                }
                if (data.orderType) {
                    urlParams.set('orderType', data.orderType);
                }
                if (data.country) {
                    urlParams.set('country', data.country);
                }
                if (data.batchStatus) {
                    urlParams.set('batchStatus', data.batchStatus);
                }
                if (data.limit) {
                    urlParams.set('limit', data.limit);
                }
                if (data.rma) {
                    urlParams.set('rma', data.rma);
                }
            } else {
                const savedParamsString = localStorage.getItem('saveParams');
                let savedParams;

                try {
                    savedParams = savedParamsString ? JSON.parse(savedParamsString) : {};
                } catch (error) {
                    savedParams = {};
                }

                if (savedParams.startDate && savedParams.endDate) {
                    urlParams.set('startDate', savedParams.startDate);
                    urlParams.set('endDate', savedParams.endDate);
                }
                if (savedParams.orderType) {
                    urlParams.set('orderType', savedParams.orderType);
                }
                if (savedParams.country) {
                    urlParams.set('country', savedParams.country);
                }
                if (savedParams.batchStatus) {
                    urlParams.set('batchStatus', savedParams.batchStatus);
                }
                if (savedParams.limit) {
                    urlParams.set('limit', savedParams.limit);
                }
                if (savedParams.rma) {
                    urlParams.set('rma', savedParams.rma);
                }
            }
            if (urlParams.toString()) url += `?${urlParams.toString()}`;

            const response = await fetch(url, {
                method: 'GET',
                headers: config.headers
            });

            const responseData = await response.json();

            if (responseData.status === true) {
                const orders = decrypt(JSON.stringify(responseData.data), keyResult, ivResult);
                setOrders(JSON.parse(orders));
                setLoading(false);
            } else {
                toast.error(responseData.message);
                setLoading(false);
            }
        } catch (e) {
            toast.error(e.message);
            setLoading(false);
        }
    };

    const getBatchStatus = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/orderbatch/batchstatuses`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setBatchStatus(data.data);
                } else {
                    toast.error(data.message);
                }
            });
    };
    const fetchCountries = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(`${ApiUrl.BaseUrl}/country/activecountry`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error('Something went wrong');
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    const fetchRmas = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/salesdrivepod/salesdrivepod`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        const allSalespersons = [];
                        result.forEach((pod) => {
                            pod.salesperson.forEach((salesperson) => {
                                // Check if the salesperson with the same userId already exists
                                const existingSalesperson = allSalespersons.find((sp) => sp.userId === salesperson.userId);

                                if (!existingSalesperson) {
                                    // If not found, add the salesperson to the array
                                    allSalespersons.push(salesperson);
                                } else {
                                    // If found, you may want to merge or update the existing salesperson data
                                    existingSalesperson.sales += salesperson.sales;
                                }
                            });
                        });
                        setSalesPlan(allSalespersons);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        fetchOrders();
        getBatchStatus();
        fetchCountries();
        fetchRmas();
    }, []);

    const orderList = orders?.map((order) => {
        return {
            key: order._id,
            orderNo: order.orderNo,
            country: order.country,
            pod: order.pod,
            rma: order.rma,
            orders: order.items,
            status: order.status,
            batched: order.batched,
            waybillgenerated: order.waybillgenerated,
            placedBy: `${order?.placedBy?.firstName} ${order?.placedBy?.lastName}`,
            createdAt: new Date(order.createdAt).toLocaleDateString('en-US', options)
        };
    });
    const batchStatusOptions = batchStatus.map((item) => {
        return {
            label: item.name,
            value: item._id
        };
    });
    const countryOption = countries?.map((result) => ({
        value: result.name,
        label: result.name
    }));
    const salesPersonOptions = salesPlan?.map((item) => {
        return {
            label: `${item?.firstName} - ${item?.lastName}`,
            value: `${item?.firstName} - ${item?.lastName}`
        };
    });

    const checkOrderStatus = (record) => {
        if (record.batched === false && record.waybillgenerated === false) {
            return (
                <div>
                    <Button
                        onClick={() => (window.location.href = `/waybill/generate-waybill/${record.key}`)}
                        text="More Details"
                        className="mr-2"
                        style={{
                            color: '#FF740F',
                            border: '1px solid #FF740F'
                        }}
                    >
                        Batch Order
                    </Button>
                </div>
            );
        }

        if (record.batched === true && record.waybillgenerated === false) {
            return (
                <div>
                    <Tag color="orange">Batched and Waiting Verification</Tag>
                </div>
            );
        }

        if (record.batched === true && record.waybillgenerated === true) {
            return <Tag color="green">Batched and Verified</Tag>;
        }

        return null;
    };

    const displayEditHandler = (record) => {
        if (record.batched === true && record.waybillgenerated === true) {
            return (
                <Button
                    onClick={() => (window.location.href = `/edit-waybill/${record.key}`)}
                    className="mr-2"
                    style={{ color: '#FF740F' }}
                >
                    <EditOutlined />
                </Button>
            );
        }

        return null;
    };

    const columns = [
        {
            title: 'Order No',
            dataIndex: 'orderNo',
            key: 'orderNo'
        },
        {
            title: 'RMA',
            dataIndex: 'placedBy',
            key: 'placedBy'
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country'
        },
        {
            title: 'POD',
            dataIndex: 'pod',
            key: 'pod'
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => checkOrderStatus(record)
        }
    ];

    const saveFilterToLocalStorage = (filterData) => {
        const savedParamsString = localStorage.getItem('saveParams');
        let savedParams;

        try {
            savedParams = savedParamsString ? JSON.parse(savedParamsString) : {};
        } catch (error) {
            savedParams = {};
        }

        const updatedParams = { ...savedParams, ...filterData };
        localStorage.setItem('saveParams', JSON.stringify(updatedParams));
    };

    const handleDateChange = (date, dateString) => {
        setFilter((prevFilter) => ({ ...prevFilter, startDate: dateString[0], endDate: dateString[1] }));
        saveFilterToLocalStorage({ startDate: dateString[0], endDate: dateString[1] });
    };

    const handleCountryChange = (value) => {
        setFilter((prevFilter) => ({ ...prevFilter, country: value }));
        saveFilterToLocalStorage({ country: value });
    };

    const handleStatusChange = (value) => {
        setFilter((prevFilter) => ({ ...prevFilter, batchStatus: value }));
        saveFilterToLocalStorage({ batchStatus: value });
    };

    const handleOrderTypeChange = (value) => {
        setFilter((prevFilter) => ({ ...prevFilter, orderType: value }));
        saveFilterToLocalStorage({ orderType: value });
    };

    const handleLimitChange = (value) => {
        setFilter((prevFilter) => ({ ...prevFilter, limit: value }));
        saveFilterToLocalStorage({ limit: value });
    };

    const handlePersonChange = (value) => {
        setFilter((prevFilter) => ({ ...prevFilter, salesPerson: value }));
        saveFilterToLocalStorage({ salesPerson: value });
    };

    return (
        <div>
            <div className="d-flex justify-content-between">
                <div>
                    <h1 style={{ fontSize: '30px' }}>Hello, Inventory Manager</h1>
                    <p className="d-flex">
                        <ExclamationCircleOutlined style={{ color: '#4B6EFF', marginRight: '5px' }} /> You can generate a waybill after you
                        have batched an order
                    </p>
                </div>
            </div>
            <div className="batchFilters bg-white p-2 mb-2">
                <RangePicker
                    size="large"
                    format="YYYY-MM-DD"
                    className="datePicker filterInput"
                    onChange={handleDateChange}
                    value={
                        filter.startDate && filter.endDate
                            ? [dayjs(filter.startDate, 'YYYY-MM-DD'), dayjs(filter.endDate, 'YYYY-MM-DD')]
                            : localStorage.getItem('saveParams') &&
                              JSON.parse(localStorage.getItem('saveParams')).startDate &&
                              JSON.parse(localStorage.getItem('saveParams')).endDate
                            ? [
                                  dayjs(JSON.parse(localStorage.getItem('saveParams')).startDate, 'YYYY-MM-DD'),
                                  dayjs(JSON.parse(localStorage.getItem('saveParams')).endDate, 'YYYY-MM-DD')
                              ]
                            : null
                    }
                />

                <div>
                    <Select
                        showSearch
                        allowClear
                        size="large"
                        placeholder="Filter By Country"
                        filterOption={(inputValue, option) => {
                            if (typeof option.label === 'string') {
                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }

                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        }}
                        className=" p-2 filterInput"
                        options={countryOption}
                        onChange={handleCountryChange}
                        value={
                            filter.country
                                ? filter.country
                                : localStorage.getItem('saveParams')
                                ? JSON.parse(localStorage.getItem('saveParams')).country
                                : null
                        }
                    />
                </div>
                <div>
                    <Select
                        showSearch
                        allowClear
                        size="large"
                        placeholder="Filter By Status"
                        filterOption={(inputValue, option) => {
                            if (typeof option.label === 'string') {
                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }

                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        }}
                        className="p-2 filterInput"
                        options={batchStatusOptions}
                        onChange={handleStatusChange}
                        value={
                            filter.batchStatus
                                ? filter.batchStatus
                                : localStorage.getItem('saveParams')
                                ? JSON.parse(localStorage.getItem('saveParams')).batchStatus
                                : null
                        }
                    />
                </div>
                <div>
                    <Select
                        showSearch
                        allowClear
                        size="large"
                        placeholder="Filter By Order Type"
                        filterOption={(inputValue, option) => {
                            if (typeof option.label === 'string') {
                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }

                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        }}
                        className="p-2 filterInput"
                        options={[
                            {
                                label: 'Sales Drive',
                                value: 'SalesDrive'
                            },
                            {
                                label: 'Regular',
                                value: 'Regular'
                            }
                        ]}
                        onChange={handleOrderTypeChange}
                        value={
                            filter.orderType
                                ? filter.orderType
                                : localStorage.getItem('saveParams')
                                ? JSON.parse(localStorage.getItem('saveParams')).orderType
                                : null
                        }
                    />
                </div>
                <div>
                    <Select
                        showSearch
                        allowClear
                        size="large"
                        placeholder="Filter By Order Limit"
                        filterOption={(inputValue, option) => {
                            if (typeof option.label === 'string') {
                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }

                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        }}
                        className=" p-2 filterInput"
                        options={[
                            {
                                label: '100',
                                value: '100'
                            },
                            {
                                label: '200',
                                value: '200'
                            },
                            {
                                label: '300',
                                value: '300'
                            },
                            {
                                label: '400',
                                value: '400'
                            },
                            {
                                label: '500',
                                value: '500'
                            }
                        ]}
                        onChange={handleLimitChange}
                        value={
                            filter.limit
                                ? filter.limit
                                : localStorage.getItem('saveParams')
                                ? JSON.parse(localStorage.getItem('saveParams')).limit
                                : null
                        }
                    />
                </div>
                <div>
                    <Select
                        showSearch
                        allowClear
                        size="large"
                        placeholder="Filter By Person"
                        options={salesPersonOptions}
                        value={
                            filter.salesPerson
                                ? filter.salesPerson
                                : localStorage.getItem('saveParams')
                                ? JSON.parse(localStorage.getItem('saveParams')).salesPerson
                                : null
                        }
                        onChange={handlePersonChange}
                        filterOption={(inputValue, option) => {
                            if (typeof option.label === 'string') {
                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }

                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        }}
                        className=" p-2 filterInput"
                    />
                </div>
                <div className="d-flex">
                    <Button
                        size="large"
                        className="mr-2"
                        style={{
                            color: '#fff',
                            border: '1px solid #FF740F',
                            backgroundColor: '#FF740F',
                            width: '150px'
                        }}
                        onClick={() => fetchOrders(filter)}
                    >
                        Search
                    </Button>
                    {localStorage.getItem('saveParams') && (
                        <Button
                            onClick={() => {
                                localStorage.removeItem('saveParams');
                                setFilter(initialState);
                                fetchOrders();
                            }}
                            className="filterInput"
                            size="large"
                            style={{
                                color: '#FF740F',
                                border: '1px solid #FF740F',
                                width: '150px'
                            }}
                        >
                            Clear Filters
                        </Button>
                    )}
                </div>
            </div>
            <div className="bg-white p-2">
                {loading ? (
                    <div>
                        <Skeleton style={{ padding: '10px 0' }} active />
                    </div>
                ) : (
                    <Table columns={columns} scroll={{ x: 'calc(700px + 50%)', y: 480 }} dataSource={orderList} />
                )}
            </div>
        </div>
    );
};

export default Index;
