// assets
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PaymentsIcon from '@mui/icons-material/Payments';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import GroupIcon from '@mui/icons-material/Group';
import CachedIcon from '@mui/icons-material/Cached';
import WifiFindIcon from '@mui/icons-material/WifiFind';
import { IconTruckDelivery } from '@tabler/icons';
// import { SiSimpleanalytics } from 'react-icons/si';

// constant
const icons = {
    DashboardIcon,
    AccessAlarmIcon,
    FormatListBulletedIcon,
    AttachMoneyIcon,
    PaymentsIcon,
    SupportAgentIcon,
    ContentPasteIcon,
    GroupIcon,
    CachedIcon,
    WifiFindIcon,
    IconTruckDelivery
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //
const userRole = localStorage.getItem('userRole');
const customersuccesslead = {
    id: 'customersuccesslead',
    title: 'Customer Care Lead',
    type: userRole,
    children: [
        {
            id: 'home',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: icons.DashboardIcon,
            breadcrumbs: false
        },
        {
            id: 'checkIn',
            title: 'Check in ',
            type: 'item',
            url: '/check-in',
            icon: icons.AccessAlarmIcon,
            breadcrumbs: false
        },
        {
            id: 'orders',
            title: 'Orders',
            type: 'collapse',
            icon: icons.FormatListBulletedIcon,
            children: [
                {
                    id: 'cssplacedorders',
                    title: 'My Orders',
                    type: 'item',
                    url: '/cssplacedorders',
                    breadcrumbs: false
                },

                {
                    id: 'cssorderrequestform',
                    title: 'Place Order',
                    type: 'item',
                    url: '/order-request-form',
                    breadcrumbs: false
                },
                {
                    id: 'cssOrders',
                    title: 'Regular Orders',
                    type: 'item',
                    url: '/cssOrders',
                    breadcrumbs: false
                },
                {
                    id: 'proformacssorders',
                    title: 'Proforma Orders',
                    type: 'item',
                    url: '/proformacssorders',
                    breadcrumbs: false
                },
                {
                    id: 'presoldorders',
                    title: 'PreSold Orders',
                    type: 'item',
                    url: '/presoldorders',
                    breadcrumbs: false
                },
                {
                    id: 'presoldsales',
                    title: 'Add PreSold Orders',
                    type: 'item',
                    url: '/order-request-form',
                    breadcrumbs: false
                },
                {
                    id: 'csssalesdriveorders',
                    title: 'Sales Drive Orders',
                    type: 'item',
                    url: '/csssalesdriveorders',
                    breadcrumbs: false
                },
                {
                    id: 'retentionorders',
                    title: 'Retention Drive Orders',
                    type: 'item',
                    url: '/retentionorders',
                    breadcrumbs: false
                },
                {
                    id: 'conversionorders',
                    title: 'Conversion Drive Orders',
                    type: 'item',
                    url: '/conversionorders',
                    breadcrumbs: false
                },
                {
                    id: 'salesdrivebulk',
                    title: 'Place Sales Drive Bulk Order',
                    type: 'item',
                    url: '/salesdrivebulk',
                    breadcrumbs: false
                }
                // {
                //     id: 'orders',
                //     title: 'Order Status',
                //     type: 'item',
                //     url: '/ordertablestatus',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'batchespage',
                //     title: 'Batch Orders',
                //     type: 'item',
                //     url: '/batchespage',
                //     breadcrumbs: false
                // }
                // {
                //     id: 'cssorderissues',
                //     title: 'Order Issues',
                //     type: 'item',
                //     url: '/cssorderissues'
                // },
                // {
                //     id: 'cssresolvedIssue',
                //     title: 'Resolved Order Issues',
                //     type: 'item',
                //     url: '/cssresolvedIssue'
                // }
            ]
        },
        {
            id: 'Sales operation',
            title: 'Sales Operation',
            type: 'collapse',
            icon: icons.AttachMoneyIcon,
            children: [
                {
                    id: 'routeschedule',
                    title: 'Route Schedule',
                    type: 'item',
                    url: '/rma-route-schedule'
                },
                {
                    id: 'orderswaybill',
                    title: 'Waybill',
                    type: 'item',
                    url: '/generated/waybill'
                },
                {
                    id: 'salespod',
                    title: 'Sales Pod',
                    type: 'item',
                    url: '/salespod',
                    breadcrumbs: false
                },
                {
                    id: 'parts-catalog',
                    title: 'Parts Catalog',
                    type: 'item',
                    url: '/parts-catalog',
                    breadcrumbs: false
                },
                {
                    id: 'part-discount',
                    title: 'Check Part Discount',
                    type: 'item',
                    url: '/part-discount',
                    breadcrumbs: false
                },
                {
                    id: 'pricing',
                    title: 'Pricing',
                    type: 'item',
                    url: '/cc_rma/product-pricing'
                }
            ]
        },
        {
            id: 'finance-operation',
            title: 'Finance Operation',
            type: 'collapse',
            icon: icons.PaymentsIcon,
            children: [
                {
                    id: 'cspricelist',
                    title: 'Price List',
                    type: 'item',
                    url: '/cspricelist'
                }
            ]
        },
        {
            id: 'Supply-chain-operation',
            title: 'Supply chain',
            type: 'collapse',
            icon: icons.ContentPasteIcon,
            children: [
                {
                    id: 'deliverynoteimages',
                    title: 'Delivery Notes',
                    type: 'item',
                    url: '/deliverynoteimages',
                    breadcrumbs: false
                },
                {
                    id: 'dispatchimages',
                    title: 'Dispatch Images',
                    type: 'item',
                    url: '/dispatchimages',
                    breadcrumbs: false
                },
                {
                    id: 'packsortimages',
                    title: 'Pack Sort Images',
                    type: 'item',
                    url: '/packsortimages',
                    breadcrumbs: false
                },
                // {
                //     id: 'fleet',
                //     title: 'Fleet',
                //     type: 'collapse',

                //     children: [
                //         {
                //             id: 'fleetpartner',
                //             title: 'Fleet (Company)',
                //             type: 'item',
                //             url: '/fleetpartner'
                //         },
                //         {
                //             id: 'fleetpartner',
                //             title: 'Fleet (Individual)',
                //             type: 'item',
                //             url: '/fleetindividualpartner'
                //         },
                //         {
                //             id: 'approvevehiclerequest',
                //             title: 'Approve Vehicle Requests',
                //             type: 'item',
                //             url: '/ApproveFleetVehicleRequest'
                //         },
                //         {
                //             id: 'allrequestservicingfleet',
                //             title: 'Request Servicing Approval',
                //             type: 'item',
                //             url: '/allrequestservicingfleet'
                //         },
                //         { id: 'jobcardfleet', title: 'Job Card', type: 'item', url: '/jobcardfleet' },
                //         {
                //             id: 'allfleetmanagerreminder',
                //             title: 'Fleet Manager Reminders',
                //             type: 'item',
                //             url: '/allfleetmanagerreminder'
                //         },
                //         {
                //             id: 'allgaragereminder',
                //             title: 'Garage Manager Reminders',
                //             type: 'item',
                //             url: '/allgaragereminder'
                //         },
                //         {
                //             id: 'garagecalender',
                //             title: 'Calendar',
                //             type: 'item',
                //             url: '/garagecalender'
                //         },
                //         {
                //             id: 'customercomplaint',
                //             title: 'Customer Complaint',
                //             type: 'item',
                //             url: '/customercomplaint'
                //         },
                //         {
                //             id: 'issuesdiagnosed',
                //             title: 'Issues Diagnosed',
                //             type: 'item',
                //             url: '/issuesdiagnosed'
                //         },
                //         {
                //             id: 'partsordersfleet',
                //             title: 'Part Orders',
                //             type: 'item',
                //             url: '/partsordersfleet'
                //         },
                //         {
                //             id: 'fleetpart',
                //             title: 'Part',
                //             type: 'item',
                //             url: '/allfleetpart'
                //         }
                //     ]
                // },
                {
                    id: 'presold-orders',
                    title: 'pre-sold batch',
                    type: 'collapse',
                    children: [
                        {
                            id: 'orders',
                            title: 'PreSold Orders',
                            type: 'item',
                            url: '/pre-sold-orders',
                            breadcrumbs: false
                        },
                        {
                            id: 'pre-sold-orders-batch',
                            title: 'PreSold Batches',
                            type: 'item',
                            url: '/pre-sold-orders/batch',
                            breadcrumbs: false
                        }
                    ]
                },
                {
                    id: 'logistics',
                    title: 'Logistics',
                    type: 'item',
                    url: '/logistics',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'Customer management',
            title: 'Customer Management',
            type: 'collapse',
            icon: icons.SupportAgentIcon,
            children: [
                {
                    id: 'ccnewlead',
                    title: 'New Lead',
                    type: 'item',
                    url: '/ccnewlead',
                    breadcrumbs: false
                },
                {
                    id: 'retailerProfile',
                    title: 'Retailer Profile',
                    type: 'item',
                    url: '/retailers/profile'
                },
                {
                    id: 'loyalty',
                    title: 'Retailer Loyalty',
                    type: 'item',
                    url: '/retailer-loyalty'
                },
                {
                    id: 'team',
                    title: 'Team',
                    type: 'collapse',
                    children: [
                        {
                            id: 'customersuccessteam',
                            title: 'Customer Success Team',
                            type: 'item',
                            url: '/customersuccessteam'
                        },
                        {
                            id: 'salesteam',
                            title: 'Sales Team',
                            type: 'item',
                            url: '/salesteam'
                        }
                        // {
                        //     id: 'carrier',
                        //     title: 'Fulfillment Team',
                        //     type: 'item',
                        //     url: '/carrier'
                        // }
                    ]
                },
                {
                    id: 'messages',
                    title: 'Send Message',
                    type: 'collapse',
                    children: [
                        {
                            id: 'allmessages',
                            title: 'Send Message',
                            type: 'item',
                            url: '/allmessages',
                            breadcrumbs: false
                        },

                        {
                            id: 'messagegroups',
                            title: 'Groups',
                            type: 'item',
                            url: '/messagegroups',
                            breadcrumbs: false
                        }
                    ]
                },
                {
                    id: 'channelpartners',
                    title: 'Channel Partners',
                    type: 'collapse',

                    children: [
                        {
                            id: 'retailers',
                            title: 'Retail',
                            type: 'item',
                            url: '/retail'
                        },
                        {
                            id: 'distributor',
                            title: 'Distributor',
                            type: 'item',
                            url: '/distributor'
                        },
                        {
                            id: 'mechanic',
                            title: 'Mechanic',
                            type: 'item',
                            url: '/mechanic'
                        }
                        // {
                        //     id: 'fleetpartner',
                        //     title: 'Fleet',
                        //     type: 'item',
                        //     url: '/fleetpartner'
                        // }
                    ]
                }
            ]
        }
    ]
};

export default customersuccesslead;
