import { toast } from 'react-toastify';
import { decrypt, encrypt } from '../../../utils/encrypt';
import ApiUrl from '../../../network';
import { fetchWithAuth } from './apiService';

const miraURL = ApiUrl.MiraUrl;
const baseUrl = `${miraURL}/api/v1/whatsapp`;

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

// eslint-disable-next-line consistent-return
export const getAllTasks = async (filters) => {
    try {
        // Construct query string from filters, ignoring empty ones
        const params = new URLSearchParams();

        // Loop through filters, only appending those with non-empty, non-null, and defined values
        Object.entries(filters).forEach(([key, value]) => {
            if (value !== '' && value !== undefined && value !== null) {
                params.append(key, value);
            }
        });

        // Append query string to base URL if there are any valid filters
        const url = `${baseUrl}/mira-tasks?${params.toString()}`;

        const response = await fetchWithAuth(url, 'GET');
        return response;
    } catch (error) {
        toast.error(error.message, {
            position: 'bottom-right'
        });
    }
};

// eslint-disable-next-line consistent-return
export const getTask = async (id) => {
    try {
        const response = await fetchWithAuth(`${baseUrl}/view-mira-task/${id}`, 'GET');
        return response;
    } catch (error) {
        toast.error(error.message);
    }
};

// eslint-disable-next-line consistent-return
export const createTask = async (task) => {
    try {
        const response = await fetchWithAuth(`${baseUrl}/create-mira-task`, 'POST', task);
        return response;
    } catch (error) {
        toast.error(error.message, {
            position: 'bottom-right'
        });
    }
};

// eslint-disable-next-line consistent-return
export const updateTask = async (task) => {
    try {
        const response = await fetchWithAuth(`${baseUrl}/update-mira-task`, 'PUT', task);
        return response;
    } catch (error) {
        toast.error(error.message, {
            position: 'bottom-right'
        });
    }
};

// eslint-disable-next-line consistent-return
export const moveTask = async (id, status) => {
    try {
        const response = await fetchWithAuth(`${baseUrl}/tasks/move`, 'PUT', {
            id,
            status
        });
        return response;
    } catch (error) {
        toast.error(error.message, {
            position: 'bottom-right'
        });
    }
};
