import GenericPageHeader from 'components/GenericPageHeader';
import BatchTable from 'components/supplier/BatchTable';

const SupplierBatch = () => {
    return (
        <>
            <GenericPageHeader title="Supplier Order Batches" breadcrumbs="Home | Dashboard | Batches" />
            <BatchTable th1="#" th2="Batch Date" th3="Batch Number" th4="Batch Status" th5="Actions" />
        </>
    );
};

export default SupplierBatch;
