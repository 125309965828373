import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { Button, Input, Select, DatePicker, Modal, Divider } from 'antd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SaveIcon from '@mui/icons-material/Save';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { decrypt, encrypt } from '../../../utils/encrypt';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { trim } from 'lodash';
import ApiUrl from '../../../network';

export const Index = () => {
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [pods, setPods] = useState([]);
    const [rmas, setRmas] = useState([]);
    const [salesPersons, setSalesPersons] = useState([]);
    const [pod, setPod] = useState('');
    const [retentiontarget, setRetentiontarget] = useState('');
    const [gmvtarget, setGmvtarget] = useState('');
    const [conversiontarget, setConversiontarget] = useState('');
    const [revenuetarget, setRevenuetarget] = useState('');
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    const fetchSingleKpi = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/kpiplan/viewkpidetails?id=${id}`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        const firstName = result?.userId?.firstName.trim();
                        const lastName = result?.userId?.lastName.trim();
                        setStartDate(result.startDate);
                        setEndDate(result.endDate);
                        setPod(result.pod.name);
                        setRetentiontarget(result.retentiontarget);
                        setGmvtarget(result.gmvtarget);
                        setConversiontarget(result.conversiontarget);
                        setRevenuetarget(result.revenuetarget);
                        setName(`${firstName} ${lastName}`);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        toast.error(error.message);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };
    const fetchPodsData = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/salesdrivepod/salesdrivepod`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setPods(result); // Set the pods directly without double decryption

                        // Extract and set all salespersons with trimmed names
                        const allSalespersons = result.reduce((salespersons, pod) => {
                            pod.salesperson.forEach((salesperson) => {
                                const trimmedFirstName = salesperson.firstName.trim();
                                const trimmedLastName = salesperson.lastName.trim();
                                salespersons.push({
                                    ...salesperson,
                                    firstName: trimmedFirstName,
                                    lastName: trimmedLastName
                                });
                            });
                            return salespersons;
                        }, []);
                        setSalesPersons(allSalespersons);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        toast.error(error.message);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        if (id) {
            fetchSingleKpi();
            fetchPodsData();
        } else {
            window.location.href = '/salesplanning';
            toast.error('Please select sales plan to edit');
        }
    }, []);

    const podOptions = pods?.map((pod) => {
        return {
            label: pod.name,
            value: pod.name
        };
    });
    const rmsOptions = rmas?.map((rma) => {
        const firstName = rma?.firstName.trim();
        const lastName = rma?.lastName.trim();

        return {
            label: `${rma?.firstName} ${rma?.lastName}`,
            value: `${firstName} ${lastName}`
        };
    });

    const updateKpiHandler = async () => {
        setLoading(true);
        const podID = pods.find((podFound) => podFound.name === pod)?._id;

        const salesPerson = salesPersons.find((salesPerson) => {
            const nameFound = `${salesPerson?.firstName} ${salesPerson?.lastName}`;
            return nameFound === name.trim();
        });

        const salesPersonID = salesPerson?.userId;
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        const datas = {
            id,
            startDate,
            endDate,
            pod: podID,
            userId: salesPersonID,
            gmvtarget,
            conversiontarget,
            retentiontarget,
            revenuetarget
        };
        const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);

        await fetch(`${ApiUrl.BaseUrl}/kpiplan/updatekpiplanning`, {
            method: 'PUT',
            headers: config.headers,
            body: JSON.stringify({ payload })
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setLoading(false);
                    toast.success(data.message);
                    setStartDate(null);
                    setEndDate(null);
                    setPod(null);
                    setRetentiontarget('');
                    setGmvtarget('');
                    setConversiontarget('');
                    setName('');
                    fetchSingleKpi();
                } else {
                    setLoading(false);
                    toast.error(data.message);
                }
            });
    };

    useEffect(() => {
        if (pod) {
            const selectedPod = pods.find((podFound) => podFound.name === pod);
            if (selectedPod) {
                setRmas(selectedPod?.salesperson);
            }
        }
    }, [pods, pod]);
    const podSelectHandler = (value) => {
        setPod(value);
        const selectedPod = pods.find((pod) => pod.name === value);
        if (selectedPod) {
            setRmas(selectedPod?.salesperson);
        }
    };

    const cancelHanlder = () => {
        window.location.href = '/kpi';
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Link to="/kpi" className="createScheduleLink">
                    <p style={{ fontSize: '25px', marginLeft: '10px' }}>
                        <ArrowBackIcon sx={{ fontSize: '25px', color: '#000' }} /> Edit KPI
                    </p>
                </Link>
                <Button
                    onClick={updateKpiHandler}
                    style={{ backgroundColor: '#FF740F', color: '#fff' }}
                    className="btnCreateSchedule"
                    title="Apply Filters"
                    loading={loading}
                >
                    <SaveIcon sx={{ fontSize: '16px', color: '#fff' }} />
                    <Typography sx={{ margin: '0 14px ', color: '#fff' }}>Update Schedule</Typography>
                </Button>
            </div>
            <div className="addPlanContainer">
                <div className="inputHolder">
                    <div>
                        <div className="addSalePlanInput">
                            <div className="mr-2">
                                <p style={{ fontSize: '16px', color: '#546974' }}>POD</p>
                                <Select
                                    placeholder="POD"
                                    showSearch
                                    allowClear
                                    size="large"
                                    style={{ width: '300px' }}
                                    value={pod}
                                    onChange={(value) => podSelectHandler(value)}
                                    filterOption={(input, option) =>
                                        (option?.name.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                                    }
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={podOptions}
                                />
                            </div>
                            <div>
                                <p style={{ fontSize: '16px', color: '#546974' }}>Name</p>
                                <Select
                                    placeholder="Name"
                                    allowClear
                                    size="large"
                                    style={{ width: '300px' }}
                                    value={name}
                                    options={rmsOptions}
                                    onChange={(value) => setName(value)}
                                />
                            </div>
                        </div>
                        <div>
                            <p style={{ fontSize: '16px', color: '#546974', marginTop: '10px' }}>Target Duration </p>
                            <div className="addSalePlanInput">
                                <div className="mr-2">
                                    <DatePicker
                                        placeholder="Start Date"
                                        format="DD/MM/YYYY"
                                        size="large"
                                        style={{
                                            width: '300px'
                                        }}
                                        value={startDate ? dayjs(startDate) : null}
                                        onChange={(value) => setStartDate(value ? value.format('YYYY-MM-DD') : null)}
                                    />
                                </div>
                                <div>
                                    <DatePicker
                                        placeholder="End Date"
                                        format="DD/MM/YYYY"
                                        size="large"
                                        style={{
                                            width: '300px'
                                        }}
                                        value={endDate ? dayjs(endDate) : null}
                                        onChange={(value) => setEndDate(value ? value.format('YYYY-MM-DD') : null)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="addSalePlanInput">
                            <div className="flex-grow-1">
                                <p style={{ fontSize: '16px', color: '#546974' }}>GMV Target</p>
                                <Input
                                    type="number"
                                    placeholder="GMV Target"
                                    size="large"
                                    style={{ width: '100%' }}
                                    value={gmvtarget}
                                    onChange={(e) => {
                                        setGmvtarget(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="flex-grow-1 ml-2">
                                <p style={{ fontSize: '16px', color: '#546974' }}>Conversion Target </p>
                                <Input
                                    type="number"
                                    placeholder="Conversion Target "
                                    size="large"
                                    style={{ width: '100%' }}
                                    value={conversiontarget}
                                    onChange={(e) => {
                                        setConversiontarget(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="addSalePlanInput">
                            <div className="flex-grow-1">
                                <p style={{ fontSize: '16px', color: '#546974' }}>Retention Target </p>
                                <Input
                                    type="number"
                                    placeholder="Retention Target "
                                    size="large"
                                    style={{ width: '100%' }}
                                    value={retentiontarget}
                                    onChange={(e) => {
                                        setRetentiontarget(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="flex-grow-1 ml-2">
                                <p style={{ fontSize: '16px', color: '#546974' }}>Revenue Target</p>
                                <Input
                                    type="number"
                                    placeholder="Revenue Target"
                                    size="large"
                                    style={{ width: '100%' }}
                                    value={revenuetarget}
                                    onChange={(e) => {
                                        setRevenuetarget(e.target.value);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="addSalePlanInput margin-top-20">
                            <Button
                                onClick={() => cancelHanlder()}
                                style={{ border: '1px solid #CED4D7', backgroundColor: '#fff', color: '#000' }}
                                className="mt-2 mb-2 d-flex align-items-center justify-content-cente btnCreateSchedule"
                                title="Apply Filters"
                            >
                                <Typography sx={{ margin: '0 14px ', color: '#000' }}>Cancel</Typography>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
