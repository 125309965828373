/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import CircularProgress from '@mui/material/CircularProgress';

const EditMechanic = () => {
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [partnerName, setpartnerName] = useState('');
    const [partnerContact, setpartnerContact] = useState('');
    const [stageId, setstageId] = useState('');
    const [salesTeamId, setsalesTeamId] = useState('');
    const [customerSuccessTeamId, setcustomerSuccessTeamId] = useState('');
    const [locationLat, setlocationLat] = useState('');
    const [locationLng, setlocationLng] = useState('');
    const [dateOnboarded, setdateOnboarded] = useState(new Date());
    const [partnerType, setpartnerType] = useState('');
    const [partListedCompleted, setpartListedCompleted] = useState('');
    const [datefirstPartReceived, setdatefirstPartReceived] = useState(new Date());
    const [kycCompleted, setkycCompleted] = useState('');
    const [signupDate, setsignupDate] = useState(new Date());
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [stageInfo, setStage] = useState([]);
    const [salesTeamInfo, setSalesTeam] = useState([]);
    const [customerSuccessTeam, setCustomerSuccessTeam] = useState([]);
    const [notes, setnotes] = useState('');

    const retrieveAllStage = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(ApiUrl.AllStageUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setStage(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllCustomerSuccessTeam = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(ApiUrl.AllActiveCustomerSuccess, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCustomerSuccessTeam(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {});
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAlLsalesTeam = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(ApiUrl.AllActiveSalesTeam, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setSalesTeam(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {});
        } catch (e) {
            console.log(e);
        }
    };

    const { id } = useParams();

    useEffect(() => {
        retrieveAllStage();
        retrieveAlLsalesTeam();
        retrieveAllCustomerSuccessTeam();
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.ViewAllChannelPartnerURL + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setpartnerName(result?.data?.partnerName ?? '');
                    setpartnerContact(result?.data?.partnerContact ?? '');
                    setstageId(result?.data?.stageId ?? '');
                    setsignupDate(result?.data?.signupDate ?? '');
                    setStatus(result?.data?.status ?? '');
                    setsalesTeamId(result?.data?.salesTeamId ?? '');
                    setcustomerSuccessTeamId(result?.data?.customerSuccessTeamId ?? '');
                    setlocationLat(result?.data?.locationLat ?? '');
                    setlocationLng(result?.data?.locationLng ?? '');
                    setdateOnboarded(result?.data?.dateOnboarded ?? '');
                    setpartnerType(result?.data?.partnerType ?? '');
                    setpartListedCompleted(result?.data?.partListedCompleted ?? '');
                    setdatefirstPartReceived(result?.data?.datefirstPartReceived ?? '');
                    setkycCompleted(result?.data?.kycCompleted ?? '');
                    setnotes(result?.data?.notes ?? '');
                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading2(false);
            });
    }, [id]);

    const handleChange = (event) => {
        setStatus(event.target.value);
    };
    const handleSignupChange = (newValue) => {
        setsignupDate(newValue);
    };
    const handleStageChange = (event) => {
        setstageId(event.target.value);
    };
    const handleFirstPartReceivedChange = (newValue) => {
        setdatefirstPartReceived(newValue);
    };
    const handleCustomerSuccessTeamChange = (event) => {
        setcustomerSuccessTeamId(event.target.value);
    };
    const handleSalesChange = (event) => {
        setsalesTeamId(event.target.value);
    };
    const handlePartnerTypeChange = (event) => {
        setpartnerType(event.target.value);
    };

    const handlePartListCollectedChange = (event) => {
        setpartListedCompleted(event.target.value);
    };
    const handleKycCompletedChange = (event) => {
        setkycCompleted(event.target.value);
    };

    const handleDateOnBoardedChange = (newValue) => {
        setdateOnboarded(newValue);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');

        const data = {
            id,
            partnerName,
            partnerContact,
            locationLat,
            locationLng,
            stageId,
            dateOnboarded,
            partnerType,
            partListedCompleted,
            datefirstPartReceived,
            kycCompleted,
            salesTeamId,
            customerSuccessTeamId,
            signupDate,
            status,
            notes
        };
        setLoading(true);
        fetch(ApiUrl.UpdateChannelPartnerURL, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    // window.location.href = '/roles';
                    toast.success(result.message);
                    setLoading(false);
                    setTimeout(() => {
                        if (scriptedRef.current) {
                            history('/mechanic');
                        }
                    }, 3000);
                } else {
                    toast.error(result.data);
                    // console.log(result);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid item xs={12} sm={6}>
                        <PageHeader title="Mechanic Partners" subTitle="Home | Configuration | Channel Partners | Mechanic" />
                        <MainCard title="Edit Mechanic">
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    autoComplete="off"
                                    name="partnerName"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    id="partnerName"
                                    label="Partner Name"
                                    value={partnerName}
                                    onChange={(e) => setpartnerName(e.target.value)}
                                    autoFocus
                                />
                                {/* <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {formErrors.partnerName}
                                </FormHelperText> */}
                                <br />
                                <br />
                                <br />
                                <TextField
                                    autoComplete="off"
                                    type="number"
                                    name="partnerContact"
                                    variant="outlined"
                                    fullWidth
                                    id="partnerContact"
                                    label="Phone"
                                    value={partnerContact}
                                    onChange={(e) => setpartnerContact(e.target.value)}
                                />
                                {/* <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {formErrors.partnerContact}
                                </FormHelperText> */}

                                <br />
                                <br />
                                <br />
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Sales Focal</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={salesTeamId}
                                        label="Sales Team"
                                        onChange={handleSalesChange}
                                    >
                                        {salesTeamInfo.map((sales) => (
                                            <MenuItem key={sales._id} value={sales._id}>
                                                {sales.name} {sales?.firstName ?? ''} {sales?.lastName ?? ''}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <br />
                                <br />
                                <br />
                                <TextField
                                    autoComplete="off"
                                    type="number"
                                    name="locationLat"
                                    variant="outlined"
                                    fullWidth
                                    id="locationLat"
                                    value={locationLat}
                                    label="Latitude"
                                    onChange={(e) => setlocationLat(e.target.value)}
                                />
                                <br />
                                <br />
                                <br />
                                <TextField
                                    autoComplete="off"
                                    type="number"
                                    name="locationLng"
                                    variant="outlined"
                                    fullWidth
                                    id="locationLng"
                                    value={locationLng}
                                    label="longitude"
                                    onChange={(e) => setlocationLng(e.target.value)}
                                />

                                <br />
                                <br />
                                <br />
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Customer Success Team</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={customerSuccessTeamId}
                                        label="Status"
                                        onChange={handleCustomerSuccessTeamChange}
                                    >
                                        {customerSuccessTeam.map((customer) => (
                                            <MenuItem key={customer._id} value={customer._id}>
                                                {customer.name} {customer?.firstName ?? ''} {customer?.lastName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <br />
                                <br />
                                <br />
                                {/* <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {formErrors.location}
                                </FormHelperText> */}

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Stage</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={stageId}
                                        label="Status"
                                        onChange={handleStageChange}
                                    >
                                        {stageInfo.map((stage) => (
                                            <MenuItem key={stage._id} value={stage._id}>
                                                {stage.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {/* <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {formErrors.stageId}
                                </FormHelperText> */}
                                <br />
                                <br />
                                <br />
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Partner Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={partnerType}
                                        label="Status"
                                        onChange={handlePartnerTypeChange}
                                    >
                                        <MenuItem value="Cash">Cash</MenuItem>
                                        <MenuItem value="Credit">Credit</MenuItem>
                                        <MenuItem value="CashCredit">Cash and Credit</MenuItem>
                                    </Select>
                                </FormControl>

                                <br />
                                <br />
                                <br />
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Part List Collected</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={partListedCompleted}
                                        label="Status"
                                        onChange={handlePartListCollectedChange}
                                    >
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                </FormControl>
                                <br />
                                <br />
                                <br />
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack spacing={3}>
                                        <DesktopDatePicker
                                            label="Date Ist Part Received"
                                            inputFormat="MM/dd/yyyy"
                                            value={datefirstPartReceived}
                                            onChange={handleFirstPartReceivedChange}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                                <br />
                                <br />
                                <br />
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">KYC Completed</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={kycCompleted}
                                        label="Status"
                                        onChange={handleKycCompletedChange}
                                    >
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                </FormControl>
                                <br />
                                <br />
                                <br />
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack spacing={3}>
                                        <DesktopDatePicker
                                            label="Date Contacted"
                                            inputFormat="MM/dd/yyyy"
                                            value={signupDate}
                                            onChange={handleSignupChange}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                                <br />
                                <br />
                                <br />
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack spacing={3}>
                                        <DesktopDatePicker
                                            label="Date Onboarded"
                                            inputFormat="MM/dd/yyyy"
                                            value={dateOnboarded}
                                            onChange={handleDateOnBoardedChange}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Stack>
                                </LocalizationProvider>

                                <br />
                                <br />
                                <TextField
                                    autoComplete="off"
                                    name="notes"
                                    variant="outlined"
                                    multiline
                                    rows={8}
                                    fullWidth
                                    id="notes"
                                    label="Notes"
                                    onChange={(e) => setnotes(e.target.value)}
                                />

                                <br />
                                <br />
                                <br />
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={status}
                                        label="Status"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Active">Active</MenuItem>
                                        <MenuItem value="InActive">InActive</MenuItem>
                                    </Select>
                                </FormControl>
                                <br />
                                <br />
                                <Box display="flex" justifyContent="space-between">
                                    <Button component={Link} to="/mechanic" type="submit" variant="contained" color="error">
                                        Reset
                                    </Button>

                                    <Button type="submit" variant="contained" color="secondary">
                                        {loading ? 'Updating....' : 'Update'}
                                    </Button>
                                </Box>
                            </form>
                        </MainCard>
                    </Grid>
                </>
            )}
        </>
    );
};

export default EditMechanic;
