import {
    PRIRIOTY_BEGIN,
    PRIRIOTY_SUCCESS,
    BATCH_STAGING_SUCCESS,
    CREATE_PRIRIOTY_BEGIN,
    CREATE_PRIRIOTY_SUCCESS,
    CREATE_PRIRIOTY_ERROR,
    SINGLE_PRIRIOTY_BEGIN,
    SINGLE_PRIRIOTY_SUCCESS,
    UPDATE_PRIORITY_BEGIN,
    UPDATE_PRIORITY_SUCCESS,
    UPDATE_PRIORITY_ERROR,
    FILTER_PRIORITY_BEGIN,
    FILTER_PRIORITY_SUCCESS,
    FILTER_PRIORITY_ERROR
} from './priority-actions';

const priorityReducer = (state, action) => {
    switch (action.type) {
        case PRIRIOTY_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case PRIRIOTY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                todaypriority: action.payload.todaypriority,
                avgdistancetoday: action.payload.avgdistancetoday,
                size: action.payload.size,
                location: action.payload.location,
                priority: action.payload.priority
            };
        case BATCH_STAGING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                batchdata: action.payload.batchdata,
                stagingarea: action.payload.stagingarea
            };
        case CREATE_PRIRIOTY_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case CREATE_PRIRIOTY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            };
        case CREATE_PRIRIOTY_ERROR:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            };
        case SINGLE_PRIRIOTY_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case SINGLE_PRIRIOTY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                singlePriority: action.payload.singlePriority
            };
        case UPDATE_PRIORITY_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case UPDATE_PRIORITY_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case UPDATE_PRIORITY_ERROR:
            return {
                ...state,
                isLoading: false
            };
        case FILTER_PRIORITY_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case FILTER_PRIORITY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                todaypriority: action.payload.todaypriority,
                avgdistancetoday: action.payload.avgdistancetoday,
                size: action.payload.size,
                location: action.payload.location,
                priority: action.payload.priority
            };
        case FILTER_PRIORITY_ERROR:
            return {
                ...state,
                isLoading: false
            };
        default:
            throw new Error(`No such action ${action.type}`);
    }
};

export default priorityReducer;
