import '../task.css';
import { Button, DatePicker, Input, Select, Drawer, Dropdown, Pagination, Spin } from 'antd';

import React, { useEffect, useState } from 'react';
import { styles } from '../styles/index';
import { LoadingOutlined, MoreOutlined, RedoOutlined, SearchOutlined } from '@ant-design/icons';

import { getAllTasks, moveTask, updateTask, createTask, getTask } from '../services/taskService';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

export const Index = () => {
    const [tab, setTab] = useState('todo');
    const [open, setOpen] = useState(false);
    const [taskAction, setTaskAction] = useState('add');
    const [isLoading, setIsLoading] = useState(false);
    const [isFiltering, setIsFiltering] = useState(false);
    const [taskList, setTaskList] = useState([]);
    const [task, setTask] = useState({
        title: '',
        customerName: '',
        customerPhone: '',
        priority: '',
        description: ''
    });
    const [filters, setFilters] = useState({
        customerName: '',
        customerPhone: '',
        startDate: '',
        endDate: '',
        priority: '',
        status: '',
        country: '',
        limit: 10
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalDocuments, setTotalDocuments] = useState(0);
    const fetchTasks = async (page = 1) => {
        try {
            setIsFiltering(true);
            toast.loading('Fetching tasks...', {
                position: 'bottom-right'
            });
            const response = await getAllTasks(filters);
            setIsFiltering(false);
            toast.dismiss();
            setTaskList(response?.data?.miratask);
            setCurrentPage(response?.data?.next_pages);
            setTotalDocuments(response?.data?.total_documents);
        } catch (error) {
            setIsFiltering(false);
            toast.dismiss();
            toast.error(error.message, {
                position: 'bottom-right'
            });
        }
    };

    const fetchTask = async (id) => {
        try {
            const response = await getTask(id);
        } catch (error) {
            // console.error(error);
        }
    };

    const addTask = async () => {
        try {
            setIsLoading(true);
            const response = await createTask(task);
            setIsLoading(false);
            toast.success(response?.message, {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
            setTask({
                title: '',
                customerName: '',
                customerPhone: '',
                priority: '',
                description: ''
            });
            setOpen(false);
            await fetchTasks();
        } catch (error) {
            console.error(error);
            toast.error(error.message, {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }
    };

    const editTask = async () => {
        try {
            setIsLoading(true);
            const response = await updateTask(task);
            setIsLoading(false);
            toast.success(response?.message, {
                position: 'bottom-right',
                autoClose: 5000
            });
            setTask({
                title: '',
                customerName: '',
                customerPhone: '',
                priority: '',
                description: ''
            });
            setOpen(false);
            fetchTasks();
        } catch (error) {
            toast.error(error.message, {
                position: 'bottom-right',
                autoClose: 5000
            });
        }
    };

    useEffect(() => {
        fetchTasks(currentPage).then((r) => r);
    }, [currentPage]);

    const handleMenuClick = (e, id, task) => {
        const { key } = e;

        switch (key) {
            case '1':
                // Add logic to view task details here
                setOpen(true);
                setTask(task);
                break;
            case '2':
                // Add logic to edit task here
                setTaskAction('edit');
                const newTask = {
                    title: task.title,
                    customerName: task.customerName,
                    customerPhone: task.customerPhone,
                    priority: task.priority,
                    description: task.description,
                    id: task._id
                };
                setOpen(true);
                setTask(newTask);
                break;
            case '3':
                let status = '';
                if (tab === 'todo') {
                    status = 'inProgress';
                } else if (tab === 'inprogress') {
                    status = 'completed';
                }

                if (status) {
                    moveTask(id, status)
                        .then((response) => {
                            toast.success(response?.message || `Task moved to ${status}`, {
                                position: 'bottom-right',
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined
                            });
                            return fetchTasks(); // Ensure tasks are refetched after task move
                        })
                        .catch((error) => {
                            toast.error('Failed to move task');
                        });
                }
                break;
            default:
            // console.log('No action');
        }
    };

    const items = [
        {
            key: '1',
            label: 'View Task details'
        },
        {
            key: '2',
            label: 'Edit Task details'
        },
        {
            key: '3',
            label: tab === 'todo' ? 'Move to in progress' : tab === 'inprogress' ? 'Move to completed' : ''
        }
    ];

    const groupedTasks = taskList?.reduce((acc, task) => {
        const { status } = task;

        // Check if the accumulator already has a key for the current task's status
        if (!acc[status]) {
            acc[status] = [];
        }

        // Push the task into the appropriate status array
        acc[status].push(task);

        return acc;
    }, {});

    const todoTasks = groupedTasks?.todo || [];
    const inProgressTasks = groupedTasks?.inProgress || [];
    const completedTasks = groupedTasks?.completed || [];

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchTasks(page); // Fetch tasks for the new page
    };

    return (
        <div className="task-container">
            <div style={styles.header}>
                <div className="header-content">
                    <h1 style={styles.headerContent}>View task</h1>
                </div>
                <Button style={styles.addButton} onClick={() => setOpen(true)}>
                    Create task
                </Button>
            </div>
            <div>
                <div style={styles.tabButtonContainer} className="tab-button">
                    <Button style={tab === 'todo' ? styles.todo : styles.default} onClick={() => setTab('todo')}>
                        <svg
                            style={tab === 'todo' ? { marginRight: '10px', fill: '#FF740F' } : { marginRight: '10px' }}
                            width="25"
                            height="26"
                            viewBox="0 0 25 26"
                            fill={tab === 'todo' ? '#FF740F' : '#B6BFC3'}
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="12.5" cy="13" r="12.5" fill={tab === 'todo' ? '#FF740F' : '#B6BFC3'} />
                        </svg>
                        <span
                            style={
                                tab === 'todo'
                                    ? { marginRight: '10px', color: '#0A2938' }
                                    : {
                                          marginRight: '10px',
                                          color: '#B6BFC3'
                                      }
                            }
                        >
                            Todo
                        </span>
                    </Button>
                    <Button style={tab === 'inprogress' ? styles.inprogress : styles.default} onClick={() => setTab('inprogress')}>
                        <svg
                            style={
                                tab === 'inprogress'
                                    ? {
                                          marginRight: '10px',
                                          fill: '#4B6EFF'
                                      }
                                    : { marginRight: '10px' }
                            }
                            width="25"
                            height="26"
                            viewBox="0 0 25 26"
                            fill={tab === 'inprogress' ? '#4B6EFF' : '#B6BFC3'}
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="12.5" cy="13" r="12.5" fill={tab === 'inprogress' ? '#4B6EFF' : '#B6BFC3'} />
                        </svg>
                        <span
                            style={
                                tab === 'inprogress'
                                    ? { marginRight: '10px', color: '#0A2938' }
                                    : {
                                          marginRight: '10px',
                                          color: '#B6BFC3'
                                      }
                            }
                        >
                            In progress
                        </span>
                    </Button>
                    <Button style={tab === 'complete' ? styles.complete : styles.default} onClick={() => setTab('complete')}>
                        <svg
                            style={tab === 'complete' ? { marginRight: '10px', fill: '#00AA55' } : { marginRight: '10px' }}
                            width="25"
                            height="26"
                            viewBox="0 0 25 26"
                            fill={tab === 'complete' ? '#00AA55' : '#B6BFC3'}
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="12.5" cy="13" r="12.5" fill={tab === 'complete' ? '#00AA55' : '#B6BFC3'} />
                        </svg>
                        <span
                            style={
                                tab === 'complete'
                                    ? { marginRight: '10px', color: '#0A2938' }
                                    : {
                                          marginRight: '10px',
                                          color: '#B6BFC3'
                                      }
                            }
                        >
                            Completed
                        </span>
                    </Button>
                </div>
                <div className="filter-container-task">
                    <Input
                        value={filters.customerName || undefined}
                        onChange={(e) => setFilters({ ...filters, customerName: e.target.value })}
                        size="small"
                        style={styles.input}
                        type="text"
                        placeholder="Search retailer"
                        className="task-input"
                    />
                    <Input
                        value={filters.customerPhone || undefined}
                        onKeyPress={(e) => {
                            // Allow only numeric characters
                            if (!/[0-9]/.test(e.key)) {
                                e.preventDefault(); // Prevent the default action (typing the character)
                            }
                        }}
                        onChange={(e) => {
                            setFilters({ ...filters, customerPhone: e.target.value });
                        }}
                        size="small"
                        style={styles.input}
                        type="text"
                        placeholder="Search phone number"
                        className="task-input"
                    />
                    <DatePicker.RangePicker
                        value={[filters.startDate ? dayjs(filters.startDate) : null, filters.endDate ? dayjs(filters.endDate) : null]}
                        onChange={(dates) => {
                            const [startDate, endDate] = dates || [null, null];
                            setFilters({
                                ...filters,
                                startDate: startDate ? startDate.toISOString() : null,
                                endDate: endDate ? endDate.toISOString() : null
                            });
                        }}
                        size="small"
                        style={styles.datePicker}
                        className="task-input"
                    />
                    <Select
                        value={filters.priority || undefined}
                        onChange={(value) => setFilters({ ...filters, priority: value })}
                        options={[
                            { value: 'Low', label: 'Low priority' },
                            { value: 'Medium', label: 'Medium priority' },
                            { value: 'High', label: 'High priority' }
                        ]}
                        size="large"
                        style={styles.select}
                        placeholder="Priority"
                        className="task-input"
                    />
                    <Button style={styles.searchButton} onClick={fetchTasks}>
                        <SearchOutlined />
                    </Button>
                    {(filters.customerName || filters.startDate || filters.priority || filters.customerPhone) && (
                        <Button
                            style={styles.clearButton}
                            onClick={() => {
                                setFilters({
                                    customerName: '',
                                    startDate: '',
                                    endDate: '',
                                    priority: '',
                                    status: '',
                                    country: '',
                                    limit: 10,
                                    customerPhone: ''
                                });
                                fetchTasks(filters);
                            }}
                        >
                            <RedoOutlined />
                        </Button>
                    )}
                </div>
                <div className="task-list">
                    {tab === 'todo' && (
                        <div>
                            {isFiltering && (
                                <Spin
                                    size="large"
                                    tip="Fetching tasks..."
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '20px'
                                    }}
                                    indicator={<LoadingOutlined spin />}
                                />
                            )}
                            {todoTasks?.length > 0 ? (
                                todoTasks?.map((task) => {
                                    return (
                                        <div className="task-item" key={task?._id}>
                                            <div className="task-item-content">
                                                <div className="task-item-title">
                                                    <span>
                                                        {task?.title} - {task?.customerName} - {task?.customerPhone}
                                                    </span>

                                                    <Dropdown
                                                        trigger={['click']}
                                                        menu={{
                                                            items,
                                                            onClick: (e) => handleMenuClick(e, task?._id, task)
                                                        }}
                                                    >
                                                        <Button
                                                            style={{
                                                                background: 'transparent',
                                                                border: 'none',
                                                                width: 'fit-content'
                                                            }}
                                                        >
                                                            <MoreOutlined />
                                                        </Button>
                                                    </Dropdown>
                                                </div>
                                                <div className="task-item-description">
                                                    <p>{task?.description}</p>
                                                </div>
                                            </div>
                                            <div className="task-item-status">
                                                <span className="priority-date">
                                                    <svg
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M5.83333 9.16699H7.5V10.8337H5.83333V9.16699ZM17.5 5.00033V16.667C17.5 17.5837 16.75 18.3337 15.8333 18.3337H4.16667C3.24167 18.3337 2.5 17.5837 2.5 16.667L2.50833 5.00033C2.50833 4.08366 3.24167 3.33366 4.16667 3.33366H5V1.66699H6.66667V3.33366H13.3333V1.66699H15V3.33366H15.8333C16.75 3.33366 17.5 4.08366 17.5 5.00033ZM4.16667 6.66699H15.8333V5.00033H4.16667V6.66699ZM15.8333 16.667V8.33366H4.16667V16.667H15.8333ZM12.5 10.8337H14.1667V9.16699H12.5V10.8337ZM9.16667 10.8337H10.8333V9.16699H9.16667V10.8337Z"
                                                            fill="#7B808C"
                                                        />
                                                    </svg>
                                                    {dayjs(task?.createdAt).format('DD MMM, YY hh:mm A')}
                                                </span>
                                                <span
                                                    className="priority"
                                                    style={{
                                                        backgroundColor: `${
                                                            task?.priority === 'Medium'
                                                                ? '#FFBE37'
                                                                : task?.priority === 'High'
                                                                ? '#00AA55'
                                                                : '#FA3246'
                                                        }`
                                                    }}
                                                >
                                                    {task?.priority}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div>
                                    <div className="no-task-found">
                                        <h2>NO TASK ASSIGNED YET</h2>
                                        <p>Kindly assign a task by clicking the button below</p>

                                        <Button style={styles.addButton} onClick={() => setOpen(true)}>
                                            Assign a task
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {todoTasks?.length > 0 && (
                                <div className="pagination">
                                    <Pagination
                                        current={currentPage}
                                        total={totalDocuments}
                                        onChange={handlePageChange}
                                        style={{ marginTop: '20px' }}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    {tab === 'inprogress' && (
                        <div>
                            {isFiltering && (
                                <Spin
                                    size="large"
                                    tip="Fetching tasks..."
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                    indicator={<LoadingOutlined spin />}
                                />
                            )}
                            {inProgressTasks?.length > 0 ? (
                                inProgressTasks?.map((taskProgress) => {
                                    return (
                                        <div className="task-item" key={taskProgress?._id}>
                                            <div className="task-item-content">
                                                <div className="task-item-title">
                                                    <span>
                                                        {taskProgress?.title} - {taskProgress?.customerName} - {taskProgress?.customerPhone}
                                                    </span>

                                                    <Dropdown
                                                        trigger={['click']}
                                                        menu={{
                                                            items,
                                                            onClick: (e) => handleMenuClick(e, taskProgress?._id, taskProgress)
                                                        }}
                                                    >
                                                        <Button
                                                            style={{
                                                                background: 'transparent',
                                                                border: 'none',
                                                                width: 'fit-content'
                                                            }}
                                                        >
                                                            <MoreOutlined />
                                                        </Button>
                                                    </Dropdown>
                                                </div>
                                                <div className="task-item-description">
                                                    <p>{taskProgress?.description}</p>
                                                </div>
                                            </div>
                                            <div className="task-item-status">
                                                <span className="priority-date">
                                                    <svg
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M5.83333 9.16699H7.5V10.8337H5.83333V9.16699ZM17.5 5.00033V16.667C17.5 17.5837 16.75 18.3337 15.8333 18.3337H4.16667C3.24167 18.3337 2.5 17.5837 2.5 16.667L2.50833 5.00033C2.50833 4.08366 3.24167 3.33366 4.16667 3.33366H5V1.66699H6.66667V3.33366H13.3333V1.66699H15V3.33366H15.8333C16.75 3.33366 17.5 4.08366 17.5 5.00033ZM4.16667 6.66699H15.8333V5.00033H4.16667V6.66699ZM15.8333 16.667V8.33366H4.16667V16.667H15.8333ZM12.5 10.8337H14.1667V9.16699H12.5V10.8337ZM9.16667 10.8337H10.8333V9.16699H9.16667V10.8337Z"
                                                            fill="#7B808C"
                                                        />
                                                    </svg>
                                                    {dayjs(taskProgress?.createdAt).format('DD MMM, YY hh:mm A')}
                                                </span>
                                                <span
                                                    className="priority"
                                                    style={{
                                                        backgroundColor: `${
                                                            taskProgress?.priority === 'Medium'
                                                                ? '#FFBE37'
                                                                : taskProgress?.priority === 'High'
                                                                ? '#00AA55'
                                                                : '#FA3246'
                                                        }`
                                                    }}
                                                >
                                                    {taskProgress?.priority}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="no-task-found">
                                    <h2>NO TASK ASSIGNED YET</h2>
                                    <p>Kindly assign a task by clicking the button below</p>

                                    <Button style={styles.addButton} onClick={() => setOpen(true)}>
                                        Assign a task
                                    </Button>
                                </div>
                            )}
                            {inProgressTasks.length > 0 && (
                                <div className="pagination">
                                    <Pagination
                                        current={currentPage}
                                        total={totalDocuments}
                                        onChange={handlePageChange}
                                        style={{ marginTop: '20px' }}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    {tab === 'complete' && (
                        <div>
                            {isFiltering && (
                                <Spin
                                    size="large"
                                    tip="Fetching tasks..."
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                    indicator={<LoadingOutlined spin />}
                                />
                            )}
                            {completedTasks?.length > 0 ? (
                                completedTasks?.map((task) => {
                                    return (
                                        <div className="task-item" key={task?._id}>
                                            <div className="task-item-content">
                                                <div className="task-item-title">
                                                    <span>
                                                        {task?.title} - {task?.customerName} - {task?.customerPhone}
                                                    </span>

                                                    <Dropdown
                                                        trigger={['click']}
                                                        menu={{
                                                            items,
                                                            onClick: (e) => handleMenuClick(e, task?._id, task)
                                                        }}
                                                    >
                                                        <Button
                                                            style={{
                                                                background: 'transparent',
                                                                border: 'none',
                                                                width: 'fit-content'
                                                            }}
                                                        >
                                                            <MoreOutlined />
                                                        </Button>
                                                    </Dropdown>
                                                </div>
                                                <div className="task-item-description">
                                                    <p>{task?.description}</p>
                                                </div>
                                            </div>
                                            <div className="task-item-status">
                                                <span className="priority-date">
                                                    <svg
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M5.83333 9.16699H7.5V10.8337H5.83333V9.16699ZM17.5 5.00033V16.667C17.5 17.5837 16.75 18.3337 15.8333 18.3337H4.16667C3.24167 18.3337 2.5 17.5837 2.5 16.667L2.50833 5.00033C2.50833 4.08366 3.24167 3.33366 4.16667 3.33366H5V1.66699H6.66667V3.33366H13.3333V1.66699H15V3.33366H15.8333C16.75 3.33366 17.5 4.08366 17.5 5.00033ZM4.16667 6.66699H15.8333V5.00033H4.16667V6.66699ZM15.8333 16.667V8.33366H4.16667V16.667H15.8333ZM12.5 10.8337H14.1667V9.16699H12.5V10.8337ZM9.16667 10.8337H10.8333V9.16699H9.16667V10.8337Z"
                                                            fill="#7B808C"
                                                        />
                                                    </svg>
                                                    {dayjs(task?.createdAt).format('DD MMM, YY hh:mm A')}
                                                </span>
                                                <span
                                                    className="priority"
                                                    style={{ backgroundColor: `${tab === 'todo' ? '#FF740F' : '#B6BFC3'}` }}
                                                >
                                                    {task?.priority}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="no-task-found">
                                    <h2>NO TASK ASSIGNED YET</h2>
                                    <p>Kindly assign a task by clicking the button below</p>

                                    <Button style={styles.addButton} onClick={() => setOpen(true)}>
                                        Assign a task
                                    </Button>
                                </div>
                            )}
                            {completedTasks.length > 0 && (
                                <div className="pagination">
                                    <Pagination
                                        current={currentPage}
                                        total={totalDocuments}
                                        onChange={handlePageChange}
                                        style={{ marginTop: '20px' }}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <Drawer
                    title={taskAction === 'add' ? 'Add task' : 'Edit task'}
                    placement="right"
                    closable={false}
                    onClose={() => setOpen(false)}
                    open={open}
                    key="right"
                    zIndex={1100}
                >
                    <div className="assign-task">
                        <div>
                            <p>Task name</p>
                            <Input
                                placeholder="Enter task name"
                                value={task.title}
                                onChange={(e) => setTask({ ...task, title: e.target.value })}
                            />
                        </div>
                        <div>
                            <p>Retailer’s name</p>
                            <Input
                                placeholder="Enter retailer’s name"
                                value={task.customerName}
                                onChange={(e) => setTask({ ...task, customerName: e.target.value })}
                            />
                        </div>
                        <div>
                            <p>Retailer’s phone number</p>
                            <Input
                                placeholder="Enter retailer’s phone number"
                                value={task.customerPhone}
                                onChange={(e) => setTask({ ...task, customerPhone: e.target.value })}
                            />
                        </div>
                        <div>
                            <p>Task priority</p>
                            <Select
                                placeholder="Select priority"
                                size="large"
                                options={[
                                    { value: 'Low', label: 'Low priority' },
                                    { value: 'Medium', label: 'Medium priority' },
                                    { value: 'High', label: 'High priority' }
                                ]}
                                style={{ width: '100%' }}
                                onChange={(value) => setTask({ ...task, priority: value })}
                                value={task.priority}
                            />
                        </div>
                        <div>
                            <p>Task description (Optional)</p>
                            <Input.TextArea
                                placeholder="Enter description"
                                maxLength={250}
                                style={{
                                    resize: 'none',
                                    height: '100px'
                                }}
                                value={task.description}
                                onChange={(e) => setTask({ ...task, description: e.target.value })}
                            />
                        </div>
                        <Button
                            loading={isLoading}
                            style={styles.addButton}
                            className="AssignTask"
                            onClick={taskAction === 'add' ? addTask : editTask}
                        >
                            {taskAction === 'add' ? 'Assign task' : 'Update task'}
                        </Button>
                    </div>
                </Drawer>
            </div>
        </div>
    );
};

export default Index;
