import { Paper, Card, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#fdfdff',
        padding: '10px'
    },
    pageHeader: {
        padding: '1px',
        // theme.spacing(1),
        display: 'flex',
        marginBottom: '4px',
        // theme.spacing(4),
        color: '#000'
    },
    pageIcon: {
        display: 'inline-block',
        padding: '2px',
        // theme.spacing(2),
        color: '#3c44b1'
    },
    pageTitle: {
        paddingLeft: '1px',
        // theme.spacing(1),
        '& .MuiTypography-subtitle2': {
            opacity: '1'
        }
    }
}));

export default function PageHeader(props) {
    const classes = useStyles();
    // const theme = useTheme();
    const { title, subTitle, icon } = props;

    return (
        <Paper elevation={0} square className={classes.root}>
            <div className={classes.pageHeader}>
                <Card className={classes.pageIcon}>{icon}</Card>
                <div className={classes.pageTitle}>
                    <Typography variant="h6" component="div">
                        {title}
                    </Typography>
                    <Typography variant="subtitle2" component="div">
                        {subTitle}
                    </Typography>
                </div>
            </div>
        </Paper>
    );
}

// import { Paper } from '@mui/material';
// import { Container, Row, Col } from 'react-grid-system';
// import HeaderWrapper from 'assets/Wrappers/HeaderWrapper';

// const PageHeader = ({ title, subTitle, currentUser, rank }) => {
//     return (
//         <HeaderWrapper>
//             <Paper elevation={0} className="custom-paper">
//                 <Container>
//                     <Row>
//                         <Col xs={12} sm={6} md={6} lg={8}>
//                             <header>
//                                 <h3>{title}</h3>
//                                 <h4>{subTitle}</h4>
//                             </header>
//                         </Col>
//                         {/* <Col xs={12} sm={6} md={6} lg={4}>
//                             <div className="sales-person">
//                                 <h3 className="associate-title">Sales Associate: {currentUser}</h3>
//                                 <h4 className="rank">Rank: {rank}</h4>
//                             </div>
//                         </Col> */}
//                     </Row>
//                 </Container>
//             </Paper>
//         </HeaderWrapper>
//     );
// };
// export default PageHeader;
