import { Button, Collapse, Input, Modal, Select, Spin, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import {
    ArrowLeftOutlined,
    DeleteOutlined,
    LoadingOutlined,
    PlusOutlined,
    PlusSquareOutlined,
    ReloadOutlined,
    RightOutlined,
    UploadOutlined
} from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';

import ApiUrl from '../../../network';
import { toast } from 'react-toastify';
import { decrypt, encrypt } from '../../../utils/encrypt';
import normFile from '../../../imageToBucket';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import debounce from 'lodash/debounce';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [isloading, setIsLoading] = useState(false);
    const [partsFamily, setPartsFamily] = useState([]);
    const [distributorInfo, setDistributors] = useState([]);
    const [sku, setSku] = useState('');
    const [image, setImage] = useState('');
    const [part, setPart] = useState([
        {
            id: '',
            partid: '',
            sku: '',
            location: '',
            price: null,
            classification: '',
            status: '',
            pricingId: ''
        }
    ]);
    const [productModal, setProductModal] = useState(false);

    const [driveLocationList, setDriveLocationList] = useState([]);

    const fetchFilteredParts = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/parts/v2/partsfilterdata`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                setPartsFamily(decryptedData.productfamily);
                setLoading(false);
            } else {
                toast.error(result.message);
                setLoading(false);
            }
        } catch (e) {
            toast(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const fetchPartDetails = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/pricing/location/pricinglocation?partId=${id}`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                setSku(decryptedData[0].sku);
                setImage(decryptedData[0].partIdgh.partsImage);
                setPart(
                    decryptedData.map((item) => ({
                        id: item._id,
                        partid: item?.partIdgh?._id,
                        sku: item.sku,
                        location: item.location,
                        price: item.price,
                        classification: item.classification,
                        status: item.status,
                        pricingId: item._id
                    }))
                );
                setLoading(false);
            } else {
                toast.error(result.message);
                setLoading(false);
            }
        } catch (e) {
            toast(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const getDriveLocationHandler = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/salesplanning/drivelocationslist`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setLoading(false);
                    const driveLocationList = decrypt(data.data, keyResult, ivResult);
                    setDriveLocationList(JSON.parse(driveLocationList));
                } else {
                    setLoading(false);
                    toast.error(data.message);
                }
            });
    };

    useEffect(() => {
        fetchPartDetails();
        fetchFilteredParts();
        getDriveLocationHandler();
    }, [id]);

    const handleInputChange = (index, field, value) => {
        const values = [...part];
        values[index][field] = value;
        setPart(values);
    };

    const updatePriceHandler = async () => {
        try {
            setIsLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            // Find the location ID for each part
            const priceLocation = part.map((part) => {
                const locationID = driveLocationList.find((location) => location.location === part.location);
                return {
                    partid: id,
                    sku,
                    location: part.location,
                    lat: locationID ? locationID.latitude : 0, // Add lat if found
                    lng: locationID ? locationID.longitude : 0, // Add lng if found
                    price: part.price,
                    classification: part.classification,
                    pricingId: part.pricingId,
                    status: part.status
                };
            });

            const datas = { priceLocation };

            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            const url = `${ApiUrl.BaseUrl}/pricing/location/updatepricinglocations`;
            const response = await fetch(url, {
                method: 'PUT',
                headers: config.headers,
                body: JSON.stringify({ payload })
            });
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                toast.success(result.message);
                await fetchPartDetails();
                setIsLoading(false);
            } else {
                toast.error(result.message);
                setIsLoading(false);
            }
        } catch (e) {
            toast.error(e.message);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    const locationOptions = driveLocationList?.map((location) => {
        return {
            label: location.location,
            value: location.location
        };
    });

    const addNewPrice = () => {
        setPart([
            ...part,
            {
                id: part.length + 1,
                partid: '',
                location: '',
                price: ''
            }
        ]);
    };

    const removeFieldPrice = () => {
        const values = [...part];
        values.pop();
        setPart(values);
    };

    return (
        <div>
            <div className="m-header">
                <div>
                    <Link to={`/product-pricing/${id}`}>
                        <h1 style={{ color: '#0A2938', display: 'flex', alignItems: 'center' }}>
                            <ArrowLeftOutlined className="mr-2" /> Edit pricing
                        </h1>
                    </Link>
                </div>
                <div>
                    <Button
                        style={{
                            backgroundColor: 'transparent',
                            color: '#FF740F'
                        }}
                        onClick={() => (window.location.href = '/parts')}
                        className="w-fit border-primary-m"
                    >
                        Cancel
                    </Button>
                    <Button loading={isloading} onClick={() => updatePriceHandler()} className="w-fit primary-bg  text-white ml-2">
                        Save changes
                    </Button>
                </div>
            </div>
            <div className="m-content m-add-page-content mt-4 bg-white" style={{ borderRadius: '10px' }}>
                <div className="flex-grow-1 text-center" style={{ width: '100%' }}>
                    <p style={{ fontSize: '20px' }}>{part[0]?.sku}</p>
                    <div>
                        <img
                            className="m-product-img"
                            src={image || 'https://via.placeholder.com/150'}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = 'https://via.placeholder.com/150';
                            }}
                            alt=""
                        />
                    </div>
                </div>
                <div className="ml-2 flex-grow-1 w-fit" style={{ width: '100%' }}>
                    {part.map((item, index) => (
                        <div key={item._id} className="mb-4 w-fit gray-color p-4" style={{ borderRadius: '10px', width: '100%' }}>
                            <div className="d-flex">
                                <div className="form-group flex-grow-1">
                                    <p>Location</p>
                                    <Select
                                        type="text"
                                        value={item.location}
                                        onChange={(e) => handleInputChange(index, 'location', e)}
                                        style={{ width: '100%' }}
                                        options={locationOptions}
                                        filterOption={(inputValue, option) => {
                                            if (typeof option.label === 'string') {
                                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }

                                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }}
                                        showSearch
                                        allowClear
                                    />
                                </div>
                                <div className="form-group flex-grow-1">
                                    <p>Price</p>
                                    <Input
                                        type="number"
                                        value={item.price}
                                        onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                                        style={{ width: '100%' }}
                                        placeholder="Price"
                                    />
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="form-group flex-grow-1">
                                    <p>Classification</p>
                                    <Select
                                        type="text"
                                        value={item.classification}
                                        onChange={(e) => handleInputChange(index, 'classification', e)}
                                        style={{ width: '100%' }}
                                        options={[
                                            { label: 'Low take rate', value: 'Low take rate' },
                                            { label: 'High take rate', value: 'High take rate' }
                                        ]}
                                        filterOption={(inputValue, option) => {
                                            if (typeof option.label === 'string') {
                                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }

                                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }}
                                        showSearch
                                        allowClear
                                        placeholder="Select classification"
                                    />
                                </div>
                                <div className="form-group flex-grow-1">
                                    <p>Status</p>
                                    <Select
                                        type="text"
                                        value={item.status}
                                        onChange={(e) => handleInputChange(index, 'status', e)}
                                        style={{ width: '100%' }}
                                        options={[
                                            { label: 'Active', value: 'Active' },
                                            { label: 'InActive', value: 'InActive' }
                                        ]}
                                        filterOption={(inputValue, option) => {
                                            if (typeof option.label === 'string') {
                                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }

                                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }}
                                        showSearch
                                        allowClear
                                        placeholder="Select status"
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-start align-items-center">
                                {part.length > 1 && (
                                    <Button
                                        className="w-fit d-flex align-items-center justify-content-center"
                                        style={{
                                            color: 'red',
                                            fontSize: '16px',
                                            backgroundColor: 'transparent'
                                        }}
                                        onClick={removeFieldPrice}
                                    >
                                        Delete <DeleteOutlined />
                                    </Button>
                                )}
                            </div>
                        </div>
                    ))}
                    {part.length === 0 && (
                        <div className="d-flex justify-content-center align-items-center">
                            <Button
                                className="w-fit d-flex align-items-center justify-content-center"
                                style={{
                                    backgroundColor: 'transparent',
                                    color: '#FF740F'
                                }}
                                onClick={() => {
                                    setPart([{ id: 1, location: '', price: '' }]);
                                }}
                            >
                                Add price & location <PlusOutlined />
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <Modal
                style={{
                    opacity: 1
                }}
                width={500}
                open={loading}
                footer={null}
                centered
            >
                <div className="text-center">
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 68,
                                    color: '#FF740F',
                                    marginTop: '20%',
                                    marginBottom: '20%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                                spin
                            />
                        }
                    />
                </div>
            </Modal>
        </div>
    );
};

export default Index;
