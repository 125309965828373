/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from 'assets/images/garage.png';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import AuthCardWrapper from '../pages/authentication/AuthCardWrapper';
import AuthWrapper1 from '../pages/authentication/AuthWrapper1';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ApiUrl from 'network';
import useScriptRef from 'hooks/useScriptRef';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Form from 'react-bootstrap/Form';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import IconButton from '@mui/material/IconButton';
// import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    }
}));

const PreViewMyOrder = () => {
    const classes = useStyles();
    const history = useNavigate();
    const [orderDate, setorderDate] = useState(new Date());
    const [cash, setCash] = useState('');
    // const [parts, setParts] = useState('');
    const [salesTeamId, setsalesTeamId] = useState('');
    const [channelId, setChannelId] = useState('');
    const [newChannelId, setNewChannelId] = useState('');
    const [channelPartnerId, setChannelPartnerRecord] = useState('');
    const [newChannelPartnerId, setNewChannelPartnerId] = useState('');
    const [qty, setQty] = useState('');
    const [partid, setPartId] = useState('');
    const [skuId, setSkuId] = useState('');
    const [brand, setBrand] = useState('');
    const [wholeSalePrice, setwholeSalePrice] = useState('');
    const [garagePrice, setgaragePrice] = useState('');
    const [applicableVins, setapplicableVins] = useState('');
    const [dropOffLocation, setdropOffLocation] = useState('');
    const [partnerContact, setpartnerContact] = useState('');
    const [salesname, setsalesname] = useState('');
    // const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [salesTeamInfo, setSalesTeam] = useState([]);
    const [channel, setChannel] = useState([]);
    const [channelpartner, setChannelPartner] = useState([]);
    const [part, setPart] = useState([]);
    const [open, setOpen] = useState(false);
    // const [open2, setOpen2] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [items, setItems] = useState([]);
    const [partType, setpartType] = useState([]);
    const [partsdata, setpartsdata] = useState([]);
    const [orderNotes, setorderNotes] = useState('');
    // const [partname, setpartname] = useState('');
    // const [partsize, setpartsize] = useState('');
    // const [partqty, setpartqty] = useState('');
    const [orderStatusId, setorderStatusId] = useState('');
    const [newOrderStatusId, setnewOrderStatusId] = useState('');
    // const [orderStatus, setOrderStatus] = useState([]);
    const [collectiondatas, setCollectiondatas] = useState([]);
    const { id } = useParams();

    const retrieveAllPart = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.AllPartsActiveSales, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setPart(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllChannel = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.ChannelActiveSalesPage, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setChannel(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAlLsalesTeam = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.SalesTeamActiveSalesPage, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setSalesTeam(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllChannelPartner = async (id) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            // setLoading2(true);
            fetch(ApiUrl.AllChannelPartnersSales + id, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setChannelPartner(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    // setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // const retrieveAllActiveOrderStatus = async () => {
    //     try {
    //         const userInfo = localStorage.getItem('userToken');
    //         const config = {
    //             headers: {
    //                 'auth-token': userInfo,
    //                 'Content-Type': 'application/json'
    //             }
    //         };
    //         setLoading2(true);
    //         fetch(ApiUrl.AllSalesOrderStatus, config)
    //             .then((data) => data.json())
    //             .then((data) => {
    //                 if (data.status === true) {
    //                     setOrderStatus(data.data);
    //                 } else {
    //                     toast.error(data.data);
    //                 }
    //             })
    //             .finally(() => {
    //                 setLoading2(false);
    //             });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    const scriptedRef = useScriptRef();

    const handleOrderDateChange = (newValue) => {
        setorderDate(newValue);
    };

    const handlePartType = (event) => {
        setpartType(event.target.value);
    };

    const handleCashChange = (event) => {
        setCash(event.target.value);
    };
    const handleSalesChange = (event) => {
        setsalesTeamId(event.target.value);
        const newFilter = salesTeamInfo.filter((value) => value._id.includes(event.target.value));
        newFilter.forEach((option) => {
            setsalesname(option.name);
        });
    };

    const handleChannelChange = (event) => {
        // console.log('channelid', event.target.value);
        setChannelId(event.target.value);
        retrieveAllChannelPartner(event.target.value);
        const newFilter = channel.filter((value) => value._id.includes(event.target.value));
        newFilter.forEach((option) => {
            setNewChannelId(option.name);
        });
    };
    const filterOptions = (event) => {
        const newFilter = channelpartner.filter((value) => (value.partnerName || '').toLowerCase().includes((event || '').toLowerCase()));

        newFilter.forEach((option) => {
            setNewChannelPartnerId(option._id);
            const location = `${option.locationLat} ${option.locationLng}`;
            setdropOffLocation(location);
            setpartnerContact(option.partnerContact);
        });
    };

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.ViewOrdersUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setorderDate(result?.data?.orderDate ?? '');
                    setChannelId(result?.data?.newChannelId?._id ?? '');
                    setNewChannelId(result?.data?.newChannelId?._id ?? '');
                    setChannelPartnerRecord(result?.data?.newChannelPartnerId?.partnerName ?? '');
                    setNewChannelPartnerId(result?.data?.newChannelPartnerId?._id ?? '');
                    setdropOffLocation(result?.data?.dropOffLocation ?? '');
                    setItems(result?.data?.items ?? '');
                    setsalesTeamId(result?.data?.salesTeamId?._id ?? '');
                    setCash(result?.data?.cash ?? '');
                    // setParts(result?.data?.parts ?? '');
                    setpartsdata(result?.data?.partsdata ?? []);
                    setorderNotes(result?.data?.orderNotes ?? '');
                    setorderStatusId(result?.data?.newOrderStatusId?.name ?? '');
                    setnewOrderStatusId(result?.data?.newOrderStatusId?._id ?? '');
                    setCollectiondatas(result?.data?.collectiondatas ?? []);
                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                    // setLoading(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading2(false);
            });
        retrieveAlLsalesTeam();
        retrieveAllChannel();
        // retrieveAllActiveOrderStatus();

        retrieveAllPart();
    }, [id]);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    const handleClose = () => {
        setOpen(false);
    };
    // const handleClickOpen2 = () => {
    //     setOpen2(true);
    // };

    // const handleClose2 = () => {
    //     setOpen2(false);
    // };

    // const handleOrderStatusChange = (event) => {
    //     setnewOrderStatusId(event.target.value);
    //     const newFilter = orderStatus.filter((value) => (value._id || '').toLowerCase().includes((event.target.value || '').toLowerCase()));
    //     // console.log('channel', newFilter);
    //     newFilter.forEach((option) => {
    //         // console.log('option', option._id);
    //         setorderStatusId(option.name);
    //     });
    // };

    // const onSubmitPartData = (event) => {
    //     event.preventDefault();
    //     // partsdata,setpartsdata
    //     setpartsdata([
    //         ...partsdata,
    //         {
    //             partname,
    //             partsize,
    //             partqty
    //         }
    //     ]);

    //     handleClose2();
    //     // console.log(partsdata);
    // };

    const onSubmitPart = (event) => {
        event.preventDefault();
        // items, setItems
        setItems([
            ...items,
            {
                skuId,
                partid,
                qty,
                partType,
                brand,
                wholeSalePrice,
                garagePrice,
                applicableVins
            }
        ]);

        handleClose();
    };

    const filterPart = (event) => {
        const newFilter = part.filter((value) => (value.sku || '').toLowerCase().includes((event || '').toLowerCase()));
        // console.log('newFilter', newFilter);
        newFilter.forEach((option) => {
            setSkuId(option._id);
            setBrand(option.brand);
            setwholeSalePrice(option.wholeSalePrice);
            setgaragePrice(option.garagePrice);
            setapplicableVins(option.applicableVins);
        });
    };
    // const handleDeleteClick = (row, index) => {
    //     // const newList = items.filter((item) => item.skuId !== row.skuId);

    //     // setItems(newList);

    //     const newList = Object.assign([], items);
    //     newList.splice(index, 1);
    //     setItems(newList);
    // };

    // const handleDelete2Click = (row, index) => {
    //     const newList = Object.assign([], partsdata);
    //     newList.splice(index, 1);
    //     setpartsdata(newList);
    // };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            id,
            orderDate,
            salesTeamId,
            channelId,
            newChannelId,
            channelPartnerId,
            newChannelPartnerId,
            cash,
            items,
            dropOffLocation,
            partnerContact,
            salesname,
            orderNotes,
            orderStatusId,
            newOrderStatusId,
            partsdata
        };
        const userInfo = localStorage.getItem('userToken');
        fetch(ApiUrl.UpdateOrdersUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status) {
                    toast.success(result.message);
                    // setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            // window.location.reload();
                            history('/sales');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    // setLoading(false);
                }
            })
            .finally(() => {
                // setLoading(false);
            });
    };
    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <AuthWrapper1>
                        <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                            <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                                    <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                                        <AuthCardWrapper>
                                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                                <Grid item sx={{ mb: 3 }}>
                                                    <Link to="#">
                                                        <img src={logo} alt="logo" height={50} />
                                                    </Link>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container justifyContent="space-between">
                                                        <Typography variant="h4" component="h2" textAlign="center">
                                                            ---Only use this button to add new leads -----
                                                        </Typography>
                                                        <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                                            <Button
                                                                component={Link}
                                                                to="/newlead"
                                                                type="submit"
                                                                variant="contained"
                                                                color="info"
                                                            >
                                                                New Lead
                                                            </Button>
                                                        </Box>
                                                    </Grid>
                                                    <br />
                                                    <Typography variant="h3" component="h2" textAlign="center">
                                                        Order Parts Request Form
                                                    </Typography>
                                                    <br />

                                                    <Typography variant="h5" component="h2" textAlign="center">
                                                        Use this form to input parts requests from channel partners
                                                    </Typography>
                                                    <br />

                                                    <br />
                                                    <form onSubmit={handleSubmit}>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <Stack spacing={3}>
                                                                <DesktopDatePicker
                                                                    label="Date"
                                                                    disabled
                                                                    inputFormat="MM/dd/yyyy"
                                                                    value={orderDate}
                                                                    onChange={handleOrderDateChange}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                />
                                                            </Stack>
                                                        </LocalizationProvider>

                                                        <br />
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">Sales / Ops Focal Name</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={salesTeamId}
                                                                label="Sales Team"
                                                                disabled
                                                                onChange={handleSalesChange}
                                                            >
                                                                {salesTeamInfo.map((sales) => (
                                                                    <MenuItem key={sales._id} value={sales._id}>
                                                                        {sales.name} {sales?.firstName}
                                                                        {sales?.lastName}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>

                                                        <br />
                                                        <br />

                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">Channel Name</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={channelId}
                                                                disabled
                                                                label="Channel Name"
                                                                onChange={handleChannelChange}
                                                            >
                                                                {channel.map((channels) => (
                                                                    <MenuItem key={channels._id} value={channels._id}>
                                                                        {channels.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>

                                                        <br />
                                                        <br />
                                                        <Autocomplete
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            disabled
                                                            value={channelPartnerId}
                                                            onChange={(event, newValue) => {
                                                                setChannelPartnerRecord(newValue);
                                                                filterOptions(newValue);
                                                            }}
                                                            options={channelpartner.map((option) => option.partnerName)}
                                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                                            renderInput={(params) => <TextField {...params} label="Channel Partner Name" />}
                                                        />

                                                        <br />
                                                        <br />
                                                        <TextField
                                                            autoComplete="off"
                                                            name="orderStatusId"
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled
                                                            id="orderStatusId"
                                                            label="OrderStatus"
                                                            value={orderStatusId}
                                                            onChange={(e) => setnewOrderStatusId(e.target.value)}
                                                        />

                                                        <br />
                                                        <br />
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">Cash or Credit</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={cash}
                                                                disabled
                                                                label="Status"
                                                                onChange={handleCashChange}
                                                            >
                                                                <MenuItem value="Body">Select an option</MenuItem>
                                                                <MenuItem value="Cash">Cash</MenuItem>
                                                                <MenuItem value="Credit">Credit</MenuItem>
                                                                <MenuItem value="Cheque">Cheque</MenuItem>
                                                                <MenuItem value="CashCredit">Cash and Credit</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <Typography variant="h5" component="h2">
                                                            Parts needed: select name, quantity e.g. [Grease 500g 5, Abro 12]
                                                        </Typography>
                                                        <br />
                                                        {/* 
                                                        <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                                            <Button
                                                                variant="contained"
                                                                onClick={handleClickOpen}
                                                                size="sm"
                                                                className={classes.item}
                                                            >
                                                                Add Part
                                                            </Button>
                                                        </Box> */}
                                                        <br />

                                                        <Grid container spacing={2} justify="flex-start">
                                                            <Grid item xs={12} sm={6} md={4} lg={12}>
                                                                <TableContainer component={Paper}>
                                                                    <Table aria-label="caption table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    Part
                                                                                </TableCell>

                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    QTY
                                                                                </TableCell>
                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    Size
                                                                                </TableCell>
                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    Price
                                                                                </TableCell>

                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    Action
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {items.map((row, index) => (
                                                                                <TableRow key={index}>
                                                                                    <TableCell component="th" scope="row">
                                                                                        {row.partid}
                                                                                    </TableCell>

                                                                                    <TableCell component="th" scope="row">
                                                                                        {row.qty}
                                                                                    </TableCell>

                                                                                    <TableCell component="th" scope="row">
                                                                                        {row.partType}
                                                                                    </TableCell>

                                                                                    <TableCell component="th" scope="row">
                                                                                        {row.garagePrice}
                                                                                    </TableCell>

                                                                                    {/* <TableCell component="th" scope="row">
                                                                                        <IconButton
                                                                                            aria-label="delete"
                                                                                            color="error"
                                                                                            onClick={() => handleDeleteClick(row, index)}
                                                                                        >
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </TableCell> */}
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Grid>
                                                        </Grid>
                                                        <br />
                                                        <br />

                                                        <Typography variant="h5" component="h2">
                                                            Only use this Button when the parts is not in the system.
                                                            <br /> <br />
                                                            Parts needed: include name, quantity and size.eg: [Grease 500g 12 pieces]
                                                        </Typography>

                                                        {/* <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                                            <Button
                                                                variant="contained"
                                                                onClick={handleClickOpen2}
                                                                size="sm"
                                                                className={classes.item}
                                                                color="success"
                                                            >
                                                                Add Sales Part
                                                            </Button>
                                                        </Box> */}
                                                        <br />

                                                        <Grid container spacing={4} justify="flex-start">
                                                            <Grid item xs={12} sm={6} md={4} lg={12}>
                                                                <TableContainer component={Paper}>
                                                                    <Table aria-label="caption table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    Part
                                                                                </TableCell>

                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    QTY
                                                                                </TableCell>
                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    Size
                                                                                </TableCell>

                                                                                {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    Action
                                                                                </TableCell> */}
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {partsdata.map((row, index) => (
                                                                                <TableRow key={index}>
                                                                                    <TableCell component="th" scope="row">
                                                                                        {row.partname}
                                                                                    </TableCell>
                                                                                    <TableCell component="th" scope="row">
                                                                                        {row.partqty}
                                                                                    </TableCell>

                                                                                    <TableCell component="th" scope="row">
                                                                                        {row.partsize}
                                                                                    </TableCell>

                                                                                    {/* <TableCell component="th" scope="row">
                                                                                        <IconButton
                                                                                            aria-label="delete"
                                                                                            color="error"
                                                                                            onClick={() => handleDelete2Click(row, index)}
                                                                                        >
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </TableCell> */}
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Grid>
                                                        </Grid>

                                                        <br />
                                                        <br />

                                                        <TextField
                                                            autoComplete="off"
                                                            name="orderNotes"
                                                            variant="outlined"
                                                            multiline
                                                            rows={3}
                                                            disabled
                                                            fullWidth
                                                            value={orderNotes}
                                                            id="orderNotes"
                                                            label="Order Notes......."
                                                            onChange={(e) => setorderNotes(e.target.value)}
                                                        />

                                                        <br />
                                                        <br />
                                                        <Typography variant="h5" component="h2">
                                                            Collections
                                                        </Typography>

                                                        <br />

                                                        <Grid container spacing={2} justify="flex-start">
                                                            <Grid item xs={12} sm={6} md={4} lg={12}>
                                                                <TableContainer component={Paper}>
                                                                    <Table aria-label="caption table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    Deposite Date
                                                                                </TableCell>

                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    Payment Type
                                                                                </TableCell>
                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    Approve Amount
                                                                                </TableCell>
                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    Approve Status
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {collectiondatas.map((row, index) => (
                                                                                <TableRow key={index}>
                                                                                    <TableCell component="th" scope="row">
                                                                                        {toTimestamp(row?.depositeDate)}
                                                                                    </TableCell>

                                                                                    <TableCell component="th" scope="row">
                                                                                        {row?.paymentType}
                                                                                    </TableCell>

                                                                                    <TableCell component="th" scope="row">
                                                                                        {Number.isFinite(row?.approveAmt)
                                                                                            ? Number(row?.approveAmt).toFixed(2)
                                                                                            : Number(row?.depositeAmt).toFixed(2)}
                                                                                    </TableCell>

                                                                                    <TableCell component="th" scope="row">
                                                                                        {row?.approveStatus}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Grid>
                                                        </Grid>

                                                        <br />
                                                        <br />
                                                        <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                                            <Button
                                                                component={Link}
                                                                to="/mysalesorder"
                                                                type="submit"
                                                                variant="contained"
                                                                color="error"
                                                            >
                                                                Reset
                                                            </Button>
                                                            {/* <Button
                                                                type="submit"
                                                                variant="contained"
                                                                color="secondary"
                                                                disabled={
                                                                    !orderDate ||
                                                                    !salesTeamId ||
                                                                    !channelId ||
                                                                    !channelPartnerId ||
                                                                    !cash ||
                                                                    !items
                                                                }
                                                            >
                                                                {loading ? 'Updating ..' : 'Update'}
                                                            </Button> */}
                                                        </Box>
                                                    </form>
                                                </Grid>
                                            </Grid>
                                        </AuthCardWrapper>
                                    </Grid>
                                </Grid>

                                <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                                    <DialogTitle id="responsive-dialog-title">Add Part</DialogTitle>
                                    <Form onSubmit={onSubmitPart}>
                                        <DialogContent>
                                            <Form.Group controlId="formFile" className="mb-3">
                                                <Box width={200}>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        value={partid}
                                                        onChange={(event, newValue) => {
                                                            setPartId(newValue);
                                                            filterPart(newValue);
                                                        }}
                                                        options={part.map((option) => option.sku)}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        renderInput={(params) => <TextField {...params} label="Sku" />}
                                                    />
                                                </Box>
                                                <br /> <br />
                                                <Box width={200}>
                                                    <TextField
                                                        autoComplete="off"
                                                        disabled
                                                        type="number"
                                                        name="garagePrice"
                                                        variant="outlined"
                                                        required
                                                        id="garagePrice"
                                                        label="Garage Price"
                                                        value={garagePrice}
                                                        onChange={(e) => setgaragePrice(e.target.value)}
                                                    />
                                                </Box>
                                                <br /> <br />
                                                <Box width={200}>
                                                    <TextField
                                                        autoComplete="off"
                                                        type="number"
                                                        name="qty"
                                                        variant="outlined"
                                                        required
                                                        id="qty"
                                                        label="Qty"
                                                        value={qty}
                                                        onChange={(e) => setQty(e.target.value)}
                                                    />
                                                </Box>
                                                <br /> <br />
                                                <Box width={200}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Part Size</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={partType}
                                                            label="Status"
                                                            onChange={handlePartType}
                                                        >
                                                            <MenuItem value="Body">Select an option</MenuItem>
                                                            <MenuItem value="Boxes">Boxe(s)</MenuItem>
                                                            <MenuItem value="Pieces">Pieces</MenuItem>
                                                            <MenuItem value="Gallons">Gallons</MenuItem>
                                                            <MenuItem value="Drums">Drums</MenuItem>
                                                            <MenuItem value="Rolls">Roll(s)</MenuItem>
                                                            <MenuItem value="Bucket">Bucket</MenuItem>
                                                            <MenuItem value="Packs">Pack(s)</MenuItem>
                                                            <MenuItem value="Litre">Litre(s)</MenuItem>
                                                            <MenuItem value="Bag">Bag(s)</MenuItem>
                                                            <MenuItem value="Set">Set(s)</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </Form.Group>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                variant="contained"
                                                size="sm"
                                                className={classes.item}
                                                autoFocus
                                                onClick={handleClose}
                                                color="error"
                                            >
                                                Cancel
                                            </Button>
                                            <Button autoFocus variant="contained" type="submit" size="sm" className={classes.item}>
                                                Add
                                            </Button>
                                        </DialogActions>
                                    </Form>
                                </Dialog>
                            </Grid>
                        </Grid>
                    </AuthWrapper1>
                </>
            )}
        </>
    );
};

export default PreViewMyOrder;
