/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState, useMemo } from 'react';
// import debounce from 'lodash.debounce';
import * as XLSX from 'xlsx';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';

// import Controls from '../../components/controls/Controls';
// import { Search } from '@mui/icons-material';
// // import InputAdornment from '@mui/material/InputAdornment';
// import { Col } from 'react-grid-system';
import Button from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@mui/icons-material/Preview';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
// import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import { Avatar } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Form from 'react-bootstrap/Form';
// import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// import styled from 'styled-components';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 3
    }
}));

const Parts = () => {
    // page states

    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [partsInfo, setParts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [count, setCount] = useState(50);
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [file, setFile] = useState('');
    const [status, setStatus] = useState([]);
    const [searched, setSearched] = useState('');
    const [localSearch, setlocalSearch] = useState('');
    const country = localStorage.getItem('country');
    const roles = localStorage.getItem('userRole');
    // const [retailer, setRetailer] = useState([]);

    // console.log(partsData);
    // const [passengersCount, setPassengersCount] = useState(0);
    // const [controller, setController] = useState({
    //     page: 0,
    //     rowsPerPage: 10
    // });

    const retrieveAllParts = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);

            let url = `${ApiUrl.PartMainUrlList}`;

            if (searched && status) {
                url += `?product=${searched}&status=${status}`;
            } else if (searched) {
                url += `?product=${searched}`;
            } else if (status) {
                url += `?status=${status}`;
            }

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setParts(data.data);
                        setPage(data?.currentPage);
                        setCount(data?.totalParts);
                        setRowsPerPage(data?.perPage);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const selectedFile = (e) => {
        setFile(e.target.files[0]);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        // console.log('e', e);
        const formData = new FormData();
        formData.append('file', file);
        // console.log('file', file);
        // console.log('file type', file.type);
        const userInfo = localStorage.getItem('userToken');
        if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            try {
                const data = await file.arrayBuffer();
                const workbook = XLSX.read(data);
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                // console.log('workbook', JSON.stringify(jsonData));
                fetch(ApiUrl.UploadPartsUrl, {
                    method: 'POST',
                    headers: {
                        'auth-token': userInfo,
                        'Content-Type': 'application/json'
                    },

                    body: JSON.stringify(jsonData)
                })
                    .then((res) => res.json())
                    .then((result) => {
                        if (result.status) {
                            toast.success(result.message);
                            setLoading(false);
                            // setRetailer(result.data);

                            if (scriptedRef) {
                                handleClose();
                                setTimeout(() => {
                                    retrieveAllParts();
                                }, 3000);
                            }
                        } else {
                            toast.error(result.data);
                            setLoading(false);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } catch (e) {
                console.log(e);
            }
        } else {
            toast.error('File Type not supported, kindly upload excel');
        }
    };

    const handleEditClick = (row) => {
        if (scriptedRef.current) {
            history(`/editPart/${row._id}`);
        }
    };

    const handlePriceChangesClick = (row) => {
        if (scriptedRef.current) {
            history(`/pricechanges/${row._id}`);
        }
    };

    const handleChangePage = (event, newPage) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);

            let url = `${ApiUrl.PartMainUrlList}`;
            if (status) {
                url += `?status=${status}&page=${newPage}`;
            } else {
                url += `?page=${newPage}`;
            }

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setParts(data.data ?? []);
                        setPage(data?.currentPage);
                        setCount(data?.totalParts);
                        setRowsPerPage(data?.perPage);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        // setRowsPerPage(parseInt(event.target.value, 30));
        // setPage(0);

        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);

            let url = `${ApiUrl.PartMainUrlList}`;
            if (status) {
                url += `?status=${status}&limit=${event.target.value}`;
            } else {
                url += `?limit=${event.target.value}`;
            }

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setParts(data.data ?? []);
                        setPage(data?.currentPage);
                        setCount(data?.totalParts);
                        setRowsPerPage(data?.perPage);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handledataexport = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(partsInfo);
        XLSX.utils.book_append_sheet(wb, ws, 'GaragePart');
        XLSX.writeFile(wb, 'GaragePart.xlsx');
    };

    const handlePartStatus = (event) => {
        setStatus(event.target.value);
        const userInfo = localStorage.getItem('userToken');
        const status = event.target.value;
        setLoading(true);
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        let url = `${ApiUrl.PartMainUrlList}`;
        if (status) {
            url += `?status=${status}`;
        }

        fetch(url, config)
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    toast.success(data.message);
                    // console.log('body', result.data);
                    setParts(data.data ?? []);
                    setPage(data?.currentPage);
                    setCount(data?.totalParts);
                    setRowsPerPage(data?.perPage);

                    setLoading(false);
                } else {
                    toast.error(data.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // const handledataproductsexport = () => {
    //     try {
    //         const userInfo = localStorage.getItem('userToken');
    //         const config = {
    //             headers: {
    //                 'auth-token': userInfo,
    //                 'Content-Type': 'application/json'
    //             }
    //         };
    //         setLoading(true);

    //         fetch('http://localhost:3000/api/authsignups/loyaltydata', config)
    //             .then((data) => data.json())
    //             .then((data) => {
    //                 // if (data.status === true) {
    //                 const wb = XLSX.utils.book_new();
    //                 const ws = XLSX.utils.json_to_sheet(data.data);
    //                 XLSX.utils.book_append_sheet(wb, ws, 'Retailerloyalty');
    //                 XLSX.writeFile(wb, 'Retailerloyalty.xlsx');
    //                 // } else {
    //                 //     toast.error(data.data);
    //                 // }
    //             })
    //             .finally(() => {
    //                 setLoading(false);
    //             });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    // function toTimestamp(strDate) {
    //     const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    //     const today = strDate ? new Date(strDate) : new Date();
    //     return today.toLocaleDateString('en-gh', options);
    // }

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - partsInfo.length) : 0;

    const debounce = () => {
        let timeoutID;
        return (e) => {
            setlocalSearch(e.target.value);
            clearTimeout(timeoutID);
            timeoutID = setTimeout(() => {
                setSearched(e.target.value);
            }, 500);
        };
    };

    const handleSearch = useMemo(() => debounce(), []);

    useEffect(() => {
        retrieveAllParts();

        // eslint-disable-next-line
    }, [searched]);

    // const filteredRows = partsInfo.filter((row) => row.sku.toLowerCase().includes(searched.toLowerCase()));
    // const isPlural = filteredRows && filteredRows.length > 1 ? 's' : '';
    // const searchCount = searched ? `${filteredRows.length} result${isPlural} found` : 'No Results';

    // const handleDeleteClick = (row) => {
    //     swal({
    //         title: 'Are you sure?',
    //         text: `Once deleted, you will not be able to recover this Part!(${row.sku})`,
    //         icon: 'warning',
    //         buttons: true,
    //         dangerMode: true
    //     }).then((willDelete) => {
    //         if (willDelete) {
    //             swal('Poof! The Part has been deleted!', {
    //                 icon: 'success'
    //             });

    //             const data = {
    //                 id: row._id
    //             };
    //             const userInfo = localStorage.getItem('userToken');

    //             fetch(ApiUrl.DeletePartsUrl, {
    //                 method: 'DELETE',
    //                 headers: {
    //                     'auth-token': userInfo,
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify(data)
    //             })
    //                 .then((res) => res.json())
    //                 .then((result) => {
    //                     if (result.status === true) {
    //                         if (scriptedRef.current) {
    //                             retrieveAllParts();
    //                             // window.location.href = '/stage';
    //                             // history('/stage');
    //                         }
    //                     } else {
    //                         toast.error(result.data);
    //                     }
    //                 });
    //         } else {
    //             swal('The Part is safe!');
    //         }
    //     });
    // };
    // const handleSearch = (event) => {
    //     setSearched(event.target.value);
    //     const searchWord = event.target.value;

    //     const newFilter = partsInfo.filter((value) => value.sku.toLowerCase().includes(searchWord.toLowerCase()));

    //     if (searchWord === '') {
    //         setParts(partsData);
    //     } else {
    //         setParts(newFilter);
    //     }
    // };

    // const requestSearch = (searchedVal) => {
    //     const filteredRows = partsInfo.filter((row) => {
    //         return row.sku.toLowerCase().includes(searchedVal.toLowerCase());
    //     });
    //     setParts(filteredRows);
    // };

    // const cancelSearch = () => {
    //     setSearched('');
    //     requestSearch(searched);
    // };

    return (
        <>
            <br />
            <PageHeader title="Parts" subTitle="Home | Configuration | Parts | Master Database" />
            <br />

            <MainCard title="Master Parts List">
                <h4 style={{ color: '#ff740f', fontWeight: '700' }}>{`Total Parts: ${count}`}</h4>
                <br />
                <Box display="flex" justifyContent="flex-start">
                    {/* <Grid>
                        <Controls.Input
                            label="Search Product"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                )
                            }}
                            value={searched}
                            onChange={(searchVal) => handleSearch(searchVal)}
                        />
                        
                    </Grid> */}

                    <Box width={180}>
                        <FormControl fullWidth style={{ marginBottom: '10px' }}>
                            <TextField
                                id="outlined-basic"
                                label="Search Product"
                                variant="outlined"
                                value={localSearch}
                                onChange={handleSearch}
                            />
                        </FormControl>
                    </Box>
                    <Box width={10} />

                    <Box width={180}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Filter By Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="status"
                                value={status}
                                label="Status"
                                onChange={handlePartStatus}
                            >
                                {/* <MenuItem value="All">All</MenuItem> */}
                                <MenuItem value="Active">Active</MenuItem>
                                <MenuItem value="InActive">InActive</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box width={10} />

                    <Button variant="contained" onClick={handleClickOpen} size="sm" className={classes.item} color="secondary">
                        Upload
                    </Button>

                    <Box width={10} />

                    <Button component={Link} to="/addPart" variant="contained" size="sm" className={classes.item}>
                        Add Part
                    </Button>
                    <Box width={10} />
                    {roles === 'Finance Manager' ? (
                        <Button onClick={handledataexport} variant="contained" size="sm" className={classes.item} color="info">
                            Download Excel
                        </Button>
                    ) : (
                        ''
                    )}

                    {/* <Box width={10} />

                    <Button onClick={handledataproductsexport} variant="contained" size="sm" className={classes.item} color="error">
                        Download Products Excel
                    </Button> */}
                </Box>
                <br />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Product Name
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Product Family
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Weight In KG
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Status
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Qty In Pieces
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Wholesale Price
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Main Price
                                        </TableCell>
                                        {country === 'Ghana' ? (
                                            <>
                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    Takoradi Price
                                                </TableCell>
                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    Kumasi Price
                                                </TableCell>
                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    Tak SleepOver Price
                                                </TableCell>
                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    Kumasi SleepOver Price
                                                </TableCell>
                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    Accra SleepOver Price
                                                </TableCell>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        {country === 'Nigeria' ? (
                                            <>
                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                    lagos SleepOver Price
                                                </TableCell>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            State
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Part Image
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {partsInfo.map((row, index) => (
                                <TableRow key={row._id}>
                                    <TableCell component="th" scope="row">
                                        {partsInfo.length - index}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.sku}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.productFamily}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.partWeight}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.status === 'InActive' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.error.light,
                                                    color: theme.palette.error.dark,
                                                    width: 80,
                                                    height: 30
                                                }}
                                            >
                                                <DangerousRoundedIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row.status ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row.status === 'Active' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.success.light,
                                                    color: theme.palette.success.dark,
                                                    width: 80,
                                                    height: 30
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row.status ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.partQtyInPieces ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.wholeSalePrice}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.garagePrice}
                                    </TableCell>

                                    {country === 'Ghana' ? (
                                        <>
                                            <TableCell component="th" scope="row">
                                                {row.takGaragePrice}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.kumasiGaragePrice}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row?.takSleepOverSellingPrice}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row?.kumasiSleepOverSellingPrice}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row?.accraSleepOverSellingPrice}
                                            </TableCell>
                                        </>
                                    ) : (
                                        ''
                                    )}

                                    {country === 'Nigeria' ? (
                                        <>
                                            <TableCell component="th" scope="row">
                                                {row?.lagosSleepOverSellingPrice}
                                            </TableCell>
                                        </>
                                    ) : (
                                        ''
                                    )}

                                    <TableCell component="th" scope="row">
                                        {row?.imsstatus?.name}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        <Avatar variant="rounded" src={row?.partsImage} sx={{ width: 150, height: 150 }} />
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(row)}>
                                            <EditIcon />
                                        </IconButton>
                                        {/* <IconButton aria-label="delete" color="error" onClick={() => handleDeleteClick(row)}>
                                            <DeleteIcon />
                                        </IconButton> */}
                                        <Tooltip title="Price Changes">
                                            <IconButton aria-label="view" color="success" onClick={() => handlePriceChangesClick(row)}>
                                                <ViewIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[100, 200, 300, count]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={!count || count <= 0 ? 0 : page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

                <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">Upload Parts</DialogTitle>
                    <Form onSubmit={onSubmit}>
                        <DialogContent>
                            {/* <DialogContentText> */}

                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Control
                                    type="file"
                                    onChange={selectedFile}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                />
                                <br /> <br />
                                <Form.Label>Expected columns</Form.Label>
                                <br /> <br />
                                (Note: distributor should be an ID from the Distributor Partner Page)
                                <br />
                                <br />
                                <Form.Label>
                                    sku, brand, distributor, productFamily, pieces, wholeSalePrice, retailPrice, garagePrice, applicableVins
                                </Form.Label>
                            </Form.Group>

                            {/* </DialogContentText> */}
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" size="sm" className={classes.item} autoFocus onClick={handleClose} color="error">
                                Cancel
                            </Button>
                            <Button autoFocus variant="contained" type="submit" size="sm" className={classes.item}>
                                Upload
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </MainCard>

            {/* </Section> */}
        </>
    );
};

export default Parts;
// const Section = styled.section`
//     .overlay {
//         left: 0;
//         top: 0;
//         width: 100%;
//         height: 100%;
//         position: fixed;
//         background: #222;
//         opacity: 0.7;
//         z-index: 250;
//     }

//     .overlay__inner {
//         left: 0;
//         top: 0;
//         width: 100%;
//         height: 100%;
//         position: absolute;
//     }

//     .overlay__content {
//         left: 50%;
//         position: absolute;
//         top: 50%;
//         transform: translate(-50%, -50%);
//     }

//     .spinner {
//         width: 75px;
//         height: 75px;
//         display: inline-block;
//         border-width: 2px;
//         border-color: rgba(255, 255, 255, 0.05);
//         border-top-color: #fff;
//         animation: spin 1s infinite linear;
//         border-radius: 100%;
//         border-style: solid;
//     }

//     @keyframes spin {
//         100% {
//             transform: rotate(360deg);
//         }
//     }
// `;
