import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/style.scss';

import * as serviceWorker from 'serviceWorker';
import App from 'App';
import AppContextProvider from 'context';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { store } from 'store';

ReactDOM.render(
    <Provider store={store}>
        <AppContextProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </AppContextProvider>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
