// assets
import { IconKey, IconSnowflake, IconTruckDelivery } from '@tabler/icons';
// import DashboardIcon from '@mui/icons-material/Dashboard';
import InventoryIcon from '@mui/icons-material/Inventory';
// import ReportIcon from '@mui/icons-material/Report';
// import CarRepairIcon from '@mui/icons-material/CarRepair';
// import Person from '@mui/icons-material/Person';
import CarCrashIcon from '@mui/icons-material/CarRental';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import Scale from '@mui/icons-material/Scale';
// import Shop from '@mui/icons-material/Shop2';
// import Trans from '@mui/icons-material/Money';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
// constant
const icons = {
    IconKey,
    IconTruckDelivery,
    IconSnowflake,
    Scale,
    CarCrashIcon,
    PriceCheckIcon,
    DisplaySettingsIcon,
    InventoryIcon
};

// ==============================|| EXTRA INSURER MENU ITEMS ||============================== //
const userRole = localStorage.getItem('userRole');

const insurer = {
    id: 'insurer',
    // title: 'Insurer',
    // type: userRole,
    children: [
        // {
        //     id: 'orderparts',
        //     title: 'Order Parts',
        //     type: 'item',
        //     icon: icons.Scale,
        //     url: '/orderparts'
        // },
        // {
        //     id: 'requestservice',
        //     title: 'Request Servicing',
        //     type: 'item',
        //     icon: icons.CarCrashIcon,
        //     url: '/requestservice'
        // },
        // // {
        // //     id: 'lotdetails',
        // //     title: 'Lot Details',
        // //     type: 'item',
        // //     icon: icons.IconKey,
        // //     url: '/lotdetails'
        // // },
        // {
        //     id: 'comparePrice',
        //     title: 'Compare Price',
        //     type: 'item',
        //     icon: icons.PriceCheckIcon,
        //     url: '/comparePrice'
        // },
        // {
        //     id: 'setup',
        //     title: 'Setup Management',
        //     type: 'collapse',
        //     icon: icons.DisplaySettingsIcon,
        //     children: [
        //         {
        //             id: 'car',
        //             title: 'Cars',
        //             type: 'item',
        //             url: '/car'
        //         },
        //         {
        //             id: 'carmodel',
        //             title: 'Model',
        //             type: 'item',
        //             url: '/carmodel'
        //         },
        //         {
        //             id: 'policyid',
        //             title: 'Policy Id',
        //             type: 'item',
        //             url: '/policyid'
        //         },
        //         {
        //             id: 'service',
        //             title: 'Service',
        //             type: 'item',
        //             url: '/service'
        //         },
        //         {
        //             id: 'servicefee',
        //             title: 'Service Fee',
        //             type: 'item',
        //             url: '/servicefee'
        //         },
        //         {
        //             id: 'claimId',
        //             title: 'Claim Id',
        //             type: 'item',
        //             url: '/claimId'
        //         },
        //         {
        //             id: 'claimTypes',
        //             title: 'Claim Type',
        //             type: 'item',
        //             url: '/claimTypes'
        //         }
        //     ]
        // },
        // {
        //     id: 'insurerforms',
        //     title: 'Insurance Forms',
        //     type: 'collapse',
        //     icon: icons.InventoryIcon,
        //     children: [
        //         {
        //             id: 'enterprise',
        //             title: 'Enterprise Motor Accident Form',
        //             type: 'item',
        //             url: '/enterpriseform'
        //         }
        //     ]
        // }
    ]
};

export default insurer;
