import { useState, useEffect } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import logo from 'assets/images/garage_logo.png';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
// import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';

import { useNavigate, useParams } from 'react-router-dom';

import ApiUrl from 'network';

const { BaseUrl } = ApiUrl;

const ViewInspectionReport = () => {
    const { id } = useParams();
    const history = useNavigate();
    const [customerName, setCustomerName] = useState('');
    const [vehicleRegNumber, setVehicleRegNumber] = useState('');

    // Vehicle interior
    const [driverSeatInterior, setDriverSeatInterior] = useState({ inspected: false, damage: false, dirt: false });
    const [personalBelongings, setPersonalBelongings] = useState({ inspected: false, belongings: '' });
    const [fuelGauge, setFuelGauge] = useState({ inspected: false, gauge: 0 });
    const [horn, setHorn] = useState({ inspected: false, malfunction: false });
    const [warningLight, setWarningLight] = useState({ inspected: false, burnout: false });
    const [powerWindow, setPowerWindow] = useState({ inspected: false, malfunction: false });

    // Front Left of Vehicle
    const [wiperBlade, setWiperBlade] = useState({ inspected: false, deterioration: false, deformation: false });
    const [windShield, setWindShield] = useState({ inspected: false, scratch: false, crack: false });
    const [tireWheel, setTireWheel] = useState({ inspected: false, wear: false, damage: false, lowPressure: false });
    const [driveShaft, setDriveShaft] = useState({ inspected: false, damage: false, greaseLeak: false });
    const [shockAbsorber, setShockAbsorber] = useState({ inspected: false, oilLeak: false });
    const [doorFender, setDoorFender] = useState({ inspected: false, scratch: false, dent: false });

    // Front of vehicle
    const [hoodBumper, setHoodBumper] = useState({ inspected: false, scratch: false, dent: false });
    const [engineOilPan, setEngineOilPan] = useState({ inspected: false, oilLeak: false, damage: false });
    const [engineOilCondition, setEngineOilCondition] = useState({ inspected: false, low: false, needChange: false });
    const [brakeFluid, setBrakeFluid] = useState({ inspected: false, low: false, needChange: false });
    const [windowWasher, setWindowWasher] = useState({ inspected: false, low: false });
    const [battery, setBattery] = useState({ inspected: false, low: false, needReplace: false });

    // Front Right of Vehicle
    const [wiperBlade1, setWiperBlade1] = useState({ inspected: false, deterioration: false, deformation: false });
    const [windShield1, setWindShield1] = useState({ inspected: false, scratch: false, crack: false });
    const [tireWheel1, setTireWheel1] = useState({ inspected: false, wear: false, damage: false, lowPressure: false });
    const [driveShaft1, setDriveShaft1] = useState({ inspected: false, damage: false, greaseLeak: false });
    const [shockAbsorber1, setShockAbsorber1] = useState({ inspected: false, oilLeak: false });
    const [doorFender1, setDoorFender1] = useState({ inspected: false, scratch: false, dent: false });

    // Rear right of vehicle
    const [tireWheel2, setTireWheel2] = useState({ inspected: false, wear: false, damage: false, lowPressure: false });
    const [driveShaft2, setDriveShaft2] = useState({ inspected: false, damage: false, greaseLeak: false });
    const [shockAbsorber2, setShockAbsorber2] = useState({ inspected: false, oilLeak: false });
    const [doorFender2, setDoorFender2] = useState({ inspected: false, scratch: false, dent: false });

    // Rear of Vehicle
    const [personalBelongings1, setPersonalBelongings1] = useState({ inspected: false, belongings: '' });
    const [toolSpare, setToolSpare] = useState({ inspected: false, scratch: false });
    const [trunkBumper, setTrunkBumper] = useState({ inspected: false, scratch: false, dent: false });
    const [exhaustMuffler, setExhaustMuffler] = useState({ inspected: false, damage: false });
    const [axleSuspension, setAxleSuspension] = useState({ inspected: false, damage: false, oilLeak: false });
    const [fuelTank, setFuelTank] = useState({ inspected: false, damage: false });

    // Rear left of Vehicle
    const [doorFender3, setDoorFender3] = useState({ inspected: false, scratch: false, dent: false });
    const [tireWheel3, setTireWheel3] = useState({ inspected: false, wear: false, damage: false, lowPressure: false });
    const [driveShaft3, setDriveShaft3] = useState({ inspected: false, damage: false, greaseLeak: false });
    const [shockAbsorber3, setShockAbsorber3] = useState({ inspected: false, oilLeak: false });

    const [notes, setNotes] = useState('');
    const [advisorName, setAdvisorName] = useState('');
    const [advisorSignature, setAdvisorSignature] = useState('');
    const [customerSignature, setCustomerSignature] = useState('');
    const [date, setDate] = useState(new Date().toLocaleDateString());
    const [submitLoading, setSubmitLoading] = useState(false);
    // const [formError, setFormError] = useState('');
    // const [formSuccess, setFormSuccess] = useState('');

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };
        async function getSingleReport() {
            try {
                const result = await (await fetch(`${BaseUrl}/autolab/show/${id}`, config)).json();
                const { data } = result;
                const {
                    customerName,
                    vehicleRegNumber,
                    vehicleInterior,
                    frontLeftOfVehicle,
                    frontOfVehicle,
                    frontRightOfVehicle,
                    rearRightOfVehicle,
                    rearOfVehicle,
                    rearLeftOfVehicle,
                    notes,
                    advisorName,
                    advisorSignature,
                    customerSignature,
                    date
                } = data;
                setCustomerName(customerName);
                setVehicleRegNumber(vehicleRegNumber);

                // Vehicle interior
                setDriverSeatInterior(vehicleInterior.driverSeatInterior);
                setPersonalBelongings(vehicleInterior.personalBelongings);
                setFuelGauge(vehicleInterior.fuelGauge);
                setHorn(vehicleInterior.horn);
                setWarningLight(vehicleInterior.warningLight);
                setPowerWindow(powerWindow);

                // Front Left of Vehicle
                setWiperBlade(frontLeftOfVehicle.wiperBlade);
                setWindShield(frontLeftOfVehicle.windShield);
                setTireWheel(frontLeftOfVehicle.tireWheel);
                setDriveShaft(frontLeftOfVehicle.driveShaft);
                setShockAbsorber(frontLeftOfVehicle.shockAbsorber);
                setDoorFender(frontLeftOfVehicle.doorFender);

                // Front of vehicle
                setHoodBumper(frontOfVehicle.hoodBumper);
                setEngineOilPan(frontOfVehicle.engineOilPan);
                setEngineOilCondition(frontOfVehicle.engineOilCondition);
                setBrakeFluid(frontOfVehicle.brakeFluid);
                setWindowWasher(frontOfVehicle.windowWasher);
                setBattery(frontOfVehicle.battery);

                // Front Right of Vehicle
                setWiperBlade1(frontRightOfVehicle.wiperBlade);
                setWindShield1(frontRightOfVehicle.windShield);
                setTireWheel1(frontRightOfVehicle.tireWheel);
                setDriveShaft1(frontRightOfVehicle.driveShaft);
                setShockAbsorber1(frontRightOfVehicle.shockAbsorber);
                setDoorFender1(frontRightOfVehicle.doorFender);

                // Rear right of vehicle
                setTireWheel2(rearRightOfVehicle.tireWheel);
                setDriveShaft2(rearRightOfVehicle.driveShaft);
                setShockAbsorber2(rearRightOfVehicle.shockAbsorber);
                setDoorFender2(rearRightOfVehicle.doorFender);

                // Rear of Vehicle
                setPersonalBelongings1(rearOfVehicle.personalBelongings);
                setToolSpare(toolSpare);
                setTrunkBumper(rearOfVehicle.trunkBumper);
                setExhaustMuffler(rearOfVehicle.exhaustMuffler);
                setAxleSuspension(rearOfVehicle.axleSuspension);
                setFuelTank(rearOfVehicle.fuelTank);

                // Rear left of Vehicle
                setDoorFender3(rearLeftOfVehicle.doorFender);
                setTireWheel3(rearLeftOfVehicle.tireWheel);
                setDriveShaft3(rearLeftOfVehicle.driveShaft);
                setShockAbsorber3(rearLeftOfVehicle.shockAbsorber);

                setNotes(notes);
                setAdvisorName(advisorName);
                setAdvisorSignature(advisorSignature);
                setCustomerSignature(customerSignature);
                setDate(date);
            } catch (e) {
                console.log(e);
            }
        }
        getSingleReport();
    }, []);

    const submitForm = async (e) => {
        e.preventDefault();
        setSubmitLoading(true);
        // work on the url...
        const url = `${BaseUrl}/autolab/update`;

        if (customerName && vehicleRegNumber && advisorName && advisorSignature && customerSignature && date) {
            const body = {
                id,
                customerName,
                vehicleRegNumber,
                vehicleInterior: {
                    driverSeatInterior,
                    personalBelongings,
                    fuelGauge,
                    horn,
                    warningLight,
                    powerWindow
                },
                frontLeftOfVehicle: {
                    wiperBlade,
                    windShield,
                    tireWheel,
                    driveShaft,
                    shockAbsorber,
                    doorFender
                },
                frontOfVehicle: {
                    hoodBumper,
                    engineOilPan,
                    engineOilCondition,
                    brakeFluid,
                    windowWasher,
                    battery
                },
                frontRightOfVehicle: {
                    wiperBlade: wiperBlade1,
                    windShield: windShield1,
                    tireWheel: tireWheel1,
                    driveShaft: driveShaft1,
                    shockAbsorber: shockAbsorber1,
                    doorFender: doorFender1
                },
                rearRightOfVehicle: {
                    tireWheel: tireWheel2,
                    driveShaft: driveShaft2,
                    shockAbsorber: shockAbsorber2,
                    doorFender: doorFender2
                },
                rearOfVehicle: {
                    personalBelongings: personalBelongings1,
                    toolSpare,
                    trunkBumper,
                    exhaustMuffler,
                    axleSuspension,
                    fuelTank
                },
                rearLeftOfVehicle: {
                    doorFender: doorFender3,
                    tireWheel: tireWheel3,
                    driveShaft: driveShaft3,
                    shockAbsorber: shockAbsorber3
                },
                notes,
                advisorName,
                advisorSignature,
                customerSignature,
                date
            };
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                },
                method: 'PUT',
                body: JSON.stringify(body)
            };

            try {
                // console.log(body);
                const result = await (await fetch(url, config)).json();
                const { status, message } = result;
                console.log('add-server-response', result);
                if (status && message === 'success') {
                    setSubmitLoading(false);
                    toast.success('Record successfully updated');
                    // setFormSuccess('Record successfully submitted');
                    // setTimeout(() => setFormSuccess(''), 3000);
                    setTimeout(() => history('/inspectionreport'), 5500);
                } else {
                    setSubmitLoading(false);
                    toast.error(message);
                    // setFormError('Error in operation');
                    // setTimeout(() => setFormError(''), 5000);
                }
            } catch (e) {
                console.log(e);
                setSubmitLoading(false);
                toast.error('Network error');
                // setFormError('Network error. Try again');
                // setTimeout(() => setFormError(''), 5000);
            }
        } else {
            setSubmitLoading(false);
            toast.error('Please fill out the required fields to continue');
        }
    };

    return (
        <>
            <div className="report-form">
                <MainCard>
                    <div className="width-10 width-l-201 width-m-201 width-s-201 margin-auto">
                        <img src={logo} alt="" className="max-img" />
                    </div>
                    <div className="center-text font-20 black-text uppercase bold-text">Walk-around inspection report</div>
                    <br />
                    <br />
                    <div>
                        <form>
                            <div className="row">
                                <div className="col-6 col-m-121 col-s-121 padding-all-10">
                                    <FormControl fullWidth>
                                        <TextField
                                            id="outlined-basic"
                                            onChange={(e) => setCustomerName(e.target.value)}
                                            value={customerName}
                                            label="Customer Name"
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </div>
                                <div className="col-6 col-m-121 col-s-121 padding-all-10">
                                    <FormControl fullWidth>
                                        <TextField
                                            id="outlined-basic"
                                            onChange={(e) => setVehicleRegNumber(e.target.value)}
                                            value={vehicleRegNumber}
                                            label="Vehicle Registration Number"
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <br />
                            <div>We performed the following free inspections on your vehicle</div>
                            <br />
                            <div>
                                <ol className="autolab-list">
                                    <li>
                                        <p className="black-text bold-text">Vehicle interior</p>

                                        <div className="row">
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={driverSeatInterior.inspected}
                                                            onChange={(e) => {
                                                                setDriverSeatInterior({
                                                                    ...driverSeatInterior,
                                                                    inspected: e.target.checked
                                                                });
                                                            }}
                                                        />
                                                    }
                                                    label="Drivers Sear Interior"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={driverSeatInterior.damage}
                                                            onChange={(e) => {
                                                                setDriverSeatInterior({ ...driverSeatInterior, damage: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Damage"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={driverSeatInterior.dirt}
                                                            onChange={(e) => {
                                                                setDriverSeatInterior({ ...driverSeatInterior, dirt: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Dirt"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={personalBelongings.inspected}
                                                            onChange={(e) => {
                                                                setPersonalBelongings({
                                                                    ...personalBelongings,
                                                                    inspected: e.target.checked
                                                                });
                                                            }}
                                                        />
                                                    }
                                                    label="Personal Belongings"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControl fullWidth>
                                                    <TextField
                                                        value={personalBelongings.belongings}
                                                        onChange={(e) => {
                                                            setPersonalBelongings({ ...personalBelongings, belongings: e.target.value });
                                                        }}
                                                        id="outlined-basic"
                                                        variant="outlined"
                                                        label="Enter personal belongings here...."
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={fuelGauge.inspected}
                                                            onChange={(e) => {
                                                                setFuelGauge({ ...fuelGauge, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Fuel gauge"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <div className="row justify-content-space-between align-items-center">
                                                    <Box sx={{ width: '85%' }}>
                                                        <Slider
                                                            value={fuelGauge.gauge}
                                                            onChange={(e) => {
                                                                setFuelGauge({ ...fuelGauge, gauge: e.target.value });
                                                            }}
                                                        />
                                                    </Box>
                                                    <div className="blue-text">{fuelGauge.gauge}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    checked={horn.inspected}
                                                    control={
                                                        <Checkbox
                                                            onChange={(e) => {
                                                                setHorn({ ...horn, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Horn"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={horn.malfunction}
                                                            onChange={(e) => {
                                                                setHorn({ ...horn, malfunction: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Malfunction"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={warningLight.inspected}
                                                            onChange={(e) => {
                                                                setWarningLight({ ...warningLight, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Warning/Indicator lights"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={warningLight.burnout}
                                                            onChange={(e) => {
                                                                setWarningLight({ ...warningLight, burnout: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Burnout"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={powerWindow.inspected}
                                                            onChange={(e) => {
                                                                setPowerWindow({ ...powerWindow, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Power window/seat/sunroof/door mirror"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={powerWindow.malfunction}
                                                            onChange={(e) => {
                                                                setPowerWindow({ ...powerWindow, malfunction: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Malfunction"
                                                />
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <p className="black-text bold-text">Front Left of Vehicle</p>
                                        <div className="row">
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={wiperBlade.inspected}
                                                            onChange={(e) => {
                                                                setWiperBlade({ ...wiperBlade, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Wiper blade"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={wiperBlade.deterioration}
                                                            onChange={(e) => {
                                                                setWiperBlade({ ...wiperBlade, deterioration: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Deterioration"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={wiperBlade.deformation}
                                                            onChange={(e) => {
                                                                setWiperBlade({ ...wiperBlade, deformation: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Deformation"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={windShield.inspected}
                                                            onChange={(e) => {
                                                                setWindShield({ ...windShield, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Windshield"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={windShield.scratch}
                                                            onChange={(e) => setWindShield({ ...windShield, scratch: e.target.checked })}
                                                        />
                                                    }
                                                    label="Scratch"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={windShield.crack}
                                                            onChange={(e) => {
                                                                setWindShield({ ...windShield, crack: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Crack"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={tireWheel.inspected}
                                                            onChange={(e) => {
                                                                setTireWheel({ ...tireWheel, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Tire & Wheel"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={tireWheel.wear}
                                                            onChange={(e) => {
                                                                setTireWheel({ ...tireWheel, wear: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Wear"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={tireWheel.damage}
                                                            onChange={(e) => {
                                                                setTireWheel({ ...tireWheel, damage: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Damage"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={tireWheel.lowPressure}
                                                            onChange={(e) => {
                                                                setTireWheel({ ...tireWheel, lowPressure: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Low Pressure"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={driveShaft.inspected}
                                                            onChange={(e) => {
                                                                setDriveShaft({ ...driveShaft, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Drive shaft boot, Suspension ball joint "
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={driveShaft.damage}
                                                            onChange={(e) => {
                                                                setDriveShaft({ ...driveShaft, damage: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Damage"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={driveShaft.greaseLeak}
                                                            onChange={(e) => {
                                                                setDriveShaft({ ...driveShaft, greaseLeak: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Grease Leak"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={shockAbsorber.inspected}
                                                            onChange={(e) => {
                                                                setShockAbsorber({ ...shockAbsorber, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Shock absorber "
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={shockAbsorber.oilLeak}
                                                            onChange={(e) => {
                                                                setShockAbsorber({ ...shockAbsorber, oilLeak: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Oil Leak"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={doorFender.inspected}
                                                            onChange={(e) => {
                                                                setDoorFender({ ...doorFender, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Door & Fender"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={doorFender.scratch}
                                                            onChange={(e) => {
                                                                setDoorFender({ ...doorFender, scratch: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Scratch"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={doorFender.dent}
                                                            onChange={(e) => {
                                                                setDoorFender({ ...doorFender, dent: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Dent"
                                                />
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <p className="black-text bold-text">Front of vehicle</p>

                                        <div className="row">
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={hoodBumper.inspected}
                                                            onChange={(e) => {
                                                                setHoodBumper({ ...hoodBumper, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Hood & bumper"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={hoodBumper.scratch}
                                                            onChange={(e) => {
                                                                setHoodBumper({ ...hoodBumper, scratch: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Scratch"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={hoodBumper.dent}
                                                            onChange={(e) => {
                                                                setHoodBumper({ ...hoodBumper, dent: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Dent"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={engineOilPan.inspected}
                                                            onChange={(e) => {
                                                                setEngineOilPan({ ...engineOilPan, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Engine oil pan & transmission"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={engineOilPan.oilLeak}
                                                            onChange={(e) => {
                                                                setEngineOilPan({ ...engineOilPan, oilLeak: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Oil/Coolant Leak"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={engineOilPan.damage}
                                                            onChange={(e) => {
                                                                setEngineOilPan({ ...engineOilPan, damage: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Damage"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={engineOilCondition.inspected}
                                                            onChange={(e) => {
                                                                setEngineOilCondition({
                                                                    ...engineOilCondition,
                                                                    inspected: e.target.checked
                                                                });
                                                            }}
                                                        />
                                                    }
                                                    label="Engine oil condition & level"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={engineOilCondition.low}
                                                            onChange={(e) => {
                                                                setEngineOilCondition({ ...engineOilCondition, low: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Low"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={engineOilCondition.needChange}
                                                            onChange={(e) => {
                                                                setEngineOilCondition({
                                                                    ...engineOilCondition,
                                                                    needChange: e.target.checked
                                                                });
                                                            }}
                                                        />
                                                    }
                                                    label="Need Change"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={brakeFluid.inspected}
                                                            onChange={(e) => {
                                                                setBrakeFluid({ ...brakeFluid, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Brake fluid level / clutch fluid level"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={brakeFluid.low}
                                                            onChange={(e) => {
                                                                setBrakeFluid({ ...brakeFluid, low: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Low"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={brakeFluid.needChange}
                                                            onChange={(e) => {
                                                                setBrakeFluid({ ...brakeFluid, needChange: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Need Change"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={windowWasher.inspected}
                                                            onChange={(e) => {
                                                                setWindowWasher({ ...windowWasher, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Window washer tank level"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={windowWasher.low}
                                                            onChange={(e) => {
                                                                setWindowWasher({ ...windowWasher, low: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Low"
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={battery.inspected}
                                                            onChange={(e) => {
                                                                setBattery({ ...battery, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Battery electrolyte"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={battery.low}
                                                            onChange={(e) => {
                                                                setBattery({ ...battery, low: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Low"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={battery.needReplace}
                                                            onChange={(e) => {
                                                                setBattery({ ...battery, needReplace: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Need replace"
                                                />
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <p className="black-text bold-text">Front Right of Vehicle</p>
                                        <div className="row">
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={doorFender1.inspected}
                                                            onChange={(e) => {
                                                                setDoorFender1({ ...doorFender1, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Door & Fender"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={doorFender1.scratch}
                                                            onChange={(e) => {
                                                                setDoorFender1({ ...doorFender1, scratch: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Scratch"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={doorFender1.dent}
                                                            onChange={(e) => {
                                                                setDoorFender1({ ...doorFender1, dent: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Dent"
                                                />
                                            </div>
                                            <div className="col-12" />

                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={wiperBlade1.inspected}
                                                            onChange={(e) => {
                                                                setWiperBlade1({ ...wiperBlade1, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Wiper blade"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={wiperBlade1.deterioration}
                                                            onChange={(e) => {
                                                                setWiperBlade1({ ...wiperBlade1, deterioration: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Deterioration"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={wiperBlade1.deformation}
                                                            onChange={(e) => {
                                                                setWiperBlade1({ ...wiperBlade1, deformation: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Deformation"
                                                />
                                            </div>
                                            <div className="col-12" />

                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={windShield1.inspected}
                                                            onChange={(e) => {
                                                                setWindShield1({ ...windShield1, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Windshield"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={windShield1.scratch}
                                                            onChange={(e) => {
                                                                setWindShield1({ ...windShield1, scratch: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Scratch"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={windShield1.crack}
                                                            onChange={(e) => {
                                                                setWindShield1({ ...windShield1, crack: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Crack"
                                                />
                                            </div>
                                            <div className="col-12" />

                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={tireWheel1.inspected}
                                                            onChange={(e) => {
                                                                setTireWheel1({ ...tireWheel1, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Tire & Wheel"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={tireWheel1.wear}
                                                            onChange={(e) => {
                                                                setTireWheel1({ ...tireWheel1, wear: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Wear"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={tireWheel1.damage}
                                                            onChange={(e) => {
                                                                setTireWheel1({ ...tireWheel1, damage: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Damage"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={tireWheel1.lowPressure}
                                                            onChange={(e) => {
                                                                setTireWheel1({ ...tireWheel1, lowPressure: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Low Pressure"
                                                />
                                            </div>
                                            <div className="col-12" />

                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={driveShaft1.inspected}
                                                            onChange={(e) => {
                                                                setDriveShaft1({ ...driveShaft1, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Drive shaft boot, Suspension ball joint "
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={driveShaft1.damage}
                                                            onChange={(e) => {
                                                                setDriveShaft1({ ...driveShaft1, damage: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Damage"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={driveShaft1.greaseLeak}
                                                            onChange={(e) => {
                                                                setDriveShaft1({ ...driveShaft1, greaseLeak: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Grease Leak"
                                                />
                                            </div>
                                            <div className="col-12" />

                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={shockAbsorber1.inspected}
                                                            onChange={(e) => {
                                                                setShockAbsorber1({ ...shockAbsorber1, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Shock absorber "
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={shockAbsorber1.oilLeak}
                                                            onChange={(e) => {
                                                                setShockAbsorber1({ ...shockAbsorber1, oilLeak: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Oil Leak"
                                                />
                                            </div>
                                            <div className="col-12" />
                                        </div>
                                    </li>

                                    <li>
                                        <p className="black-text bold-text">Rear right of vehicle</p>
                                        <div className="row">
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={tireWheel2.inspected}
                                                            onChange={(e) => {
                                                                setTireWheel2({ ...tireWheel2, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Tire & Wheel"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={tireWheel2.wear}
                                                            onChange={(e) => {
                                                                setTireWheel2({ ...tireWheel2, wear: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Wear"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={tireWheel2.damage}
                                                            onChange={(e) => {
                                                                setTireWheel2({ ...tireWheel2, damage: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Damage"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={tireWheel2.lowPressure}
                                                            onChange={(e) => {
                                                                setTireWheel2({ ...tireWheel2, lowPressure: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Low Pressure"
                                                />
                                            </div>
                                            <div className="col-12" />

                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={driveShaft2.inspected}
                                                            onChange={(e) => {
                                                                setDriveShaft2({ ...driveShaft2, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Drive shaft boot, Suspension ball joint "
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={driveShaft2.damage}
                                                            onChange={(e) => {
                                                                setDriveShaft2({ ...driveShaft2, damage: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Damage"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={driveShaft2.greaseLeak}
                                                            onChange={(e) => {
                                                                setDriveShaft2({ ...driveShaft2, greaseLeak: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Grease Leak"
                                                />
                                            </div>
                                            <div className="col-12" />

                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={shockAbsorber2.inspected}
                                                            onChange={(e) => {
                                                                setShockAbsorber2({ ...shockAbsorber2, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Shock absorber "
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={shockAbsorber2.oilLeak}
                                                            onChange={(e) => {
                                                                setShockAbsorber2({ ...shockAbsorber2, oilLeak: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Oil Leak"
                                                />
                                            </div>
                                            <div className="col-12" />

                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={doorFender2.inspected}
                                                            onChange={(e) => {
                                                                setDoorFender2({ ...doorFender2, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Door & Fender"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={doorFender2.scratch}
                                                            onChange={(e) => {
                                                                setDoorFender2({ ...doorFender2, scratch: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Scratch"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={doorFender2.dent}
                                                            onChange={(e) => {
                                                                setDoorFender2({ ...doorFender2, dent: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Dent"
                                                />
                                            </div>
                                            <div className="col-12" />
                                        </div>
                                    </li>

                                    <li>
                                        <p className="black-text bold-text">Rear of Vehicle</p>
                                        <div className="row">
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={personalBelongings1.inspected}
                                                            onChange={(e) => {
                                                                setPersonalBelongings1({
                                                                    ...personalBelongings1,
                                                                    inspected: e.target.checked
                                                                });
                                                            }}
                                                        />
                                                    }
                                                    label="Personal Belongings"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControl fullWidth>
                                                    <TextField
                                                        value={personalBelongings1.belongings}
                                                        onChange={(e) =>
                                                            setPersonalBelongings1({ ...personalBelongings1, belongings: e.target.value })
                                                        }
                                                        id="outlined-basic"
                                                        variant="outlined"
                                                        label="Enter personal belongings here...."
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className="col-12" />

                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={toolSpare.inspected}
                                                            onChange={(e) => {
                                                                setToolSpare({ ...toolSpare, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Tool, Spare tire"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={toolSpare.scratch}
                                                            onChange={(e) => {
                                                                setToolSpare({ ...toolSpare, scratch: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Scratch"
                                                />
                                            </div>
                                            <div className="col-12" />

                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={trunkBumper.inspected}
                                                            onChange={(e) => {
                                                                setTrunkBumper({ ...trunkBumper, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Trunk & bumper"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={trunkBumper.scratch}
                                                            onChange={(e) => {
                                                                setTrunkBumper({ ...trunkBumper, scratch: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Scratch"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={trunkBumper.dent}
                                                            onChange={(e) => {
                                                                setTrunkBumper({ ...trunkBumper, dent: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Dent"
                                                />
                                            </div>
                                            <div className="col-12" />

                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={exhaustMuffler.inspected}
                                                            onChange={(e) => {
                                                                setExhaustMuffler({ ...exhaustMuffler, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Exhaust muffler"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={exhaustMuffler.damage}
                                                            onChange={(e) => {
                                                                setExhaustMuffler({ ...exhaustMuffler, damage: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Damage"
                                                />
                                            </div>
                                            <div className="col-12" />

                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={axleSuspension.inspected}
                                                            onChange={(e) => {
                                                                setAxleSuspension({ ...axleSuspension, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Axle & suspension parts"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={axleSuspension.damage}
                                                            onChange={(e) => {
                                                                setAxleSuspension({ ...axleSuspension, damage: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Damage"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={axleSuspension.oilLeak}
                                                            onChange={(e) => {
                                                                setAxleSuspension({ ...axleSuspension, oilLeak: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Oil/Grease Leak"
                                                />
                                            </div>
                                            <div className="col-12" />

                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={fuelTank.inspected}
                                                            onChange={(e) => {
                                                                setFuelTank({ ...fuelTank, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Fuel tank"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={fuelTank.damage}
                                                            onChange={(e) => {
                                                                setFuelTank({ ...fuelTank, damage: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Damage"
                                                />
                                            </div>
                                            <div className="col-12" />
                                        </div>
                                    </li>

                                    <li>
                                        <p className="black-text bold-text">Rear left of Vehicle</p>
                                        <div className="row">
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={doorFender3.inspected}
                                                            onChange={(e) => {
                                                                setDoorFender3({ ...doorFender3, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Door & Fender"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={doorFender3.scratch}
                                                            onChange={(e) => {
                                                                setDoorFender3({ ...doorFender3, scratch: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Scratch"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={doorFender3.dent}
                                                            onChange={(e) => {
                                                                setDoorFender3({ ...doorFender3, dent: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Dent"
                                                />
                                            </div>
                                            <div className="col-12" />

                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={tireWheel3.inspected}
                                                            onChange={(e) => {
                                                                setTireWheel3({ ...tireWheel3, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Tire & Wheel"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={tireWheel3.wear}
                                                            onChange={(e) => {
                                                                setTireWheel3({ ...tireWheel3, wear: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Wear"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={tireWheel3.damage}
                                                            onChange={(e) => {
                                                                setTireWheel3({ ...tireWheel3, damage: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Damage"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={tireWheel3.lowPressure}
                                                            onChange={(e) => {
                                                                setTireWheel3({ ...tireWheel3, lowPressure: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Low Pressure"
                                                />
                                            </div>
                                            <div className="col-12" />

                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={driveShaft3.inspected}
                                                            onChange={(e) => {
                                                                setDriveShaft3({ ...driveShaft3, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Drive shaft boot, Suspension ball joint "
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={driveShaft3.damage}
                                                            onChange={(e) => {
                                                                setDriveShaft3({ ...driveShaft3, damage: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Damage"
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={driveShaft3.greaseLeak}
                                                            onChange={(e) => {
                                                                setDriveShaft3({ ...driveShaft3, greaseLeak: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Grease Leak"
                                                />
                                            </div>
                                            <div className="col-12" />

                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={shockAbsorber3.inspected}
                                                            onChange={(e) => {
                                                                setShockAbsorber3({ ...shockAbsorber3, inspected: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Shock absorber "
                                                />
                                            </div>
                                            <div className="col-3 col-l-61 col-m-121 col-s-121 padding-all-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={shockAbsorber3.oilLeak}
                                                            onChange={(e) => {
                                                                setShockAbsorber3({ ...shockAbsorber3, oilLeak: e.target.checked });
                                                            }}
                                                        />
                                                    }
                                                    label="Oil Leak"
                                                />
                                            </div>
                                            <div className="col-12" />
                                        </div>
                                    </li>
                                </ol>
                            </div>
                            <br />
                            <div className="padding-all-10">
                                <TextField
                                    onChange={(e) => setNotes(e.target.value)}
                                    value={notes}
                                    id="outlined-multiline-static"
                                    fullWidth
                                    label="Notes"
                                    multiline
                                    rows={4}
                                />
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-6 col-m-121 col-s-121 padding-all-10">
                                    <div>
                                        <TextField
                                            fullWidth
                                            onChange={(e) => setAdvisorName(e.target.value)}
                                            value={advisorName}
                                            label="Service Advisor Name"
                                        />
                                    </div>
                                    <br />
                                    <div>
                                        <TextField
                                            fullWidth
                                            onChange={(e) => setAdvisorSignature(e.target.value)}
                                            value={advisorSignature}
                                            label="Service Advisor Signature"
                                        />
                                    </div>
                                </div>
                                <div className="col-6 col-m-121 col-s-121 padding-all-10">
                                    <div>
                                        <TextField
                                            fullWidth
                                            onChange={(e) => setCustomerSignature(e.target.value)}
                                            value={customerSignature}
                                            label="Customer Signature"
                                        />
                                    </div>
                                </div>
                                <div className="col-12" />
                                <div className="col-6 col-m-121 col-s-121 padding-all-10">
                                    <div>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="Date"
                                                    value={date}
                                                    onChange={(newValue) => {
                                                        setDate(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="col-12">
                                    {submitLoading ? (
                                        <div className="flex-column justify-content-center align-items-center">
                                            <CircularProgress />
                                        </div>
                                    ) : (
                                        <div />
                                    )}
                                    {/* <div>{formError ? <Alert severity="error">{formError}</Alert> : ''}</div>
                                    <div>{formSuccess ? <Alert severity="success">{formSuccess}</Alert> : ''}</div> */}
                                    <br />
                                    <div className="row justify-content-space-between padding-all-10">
                                        <div>
                                            <button
                                                type="button"
                                                onClick={() => history('/inspectionreport')}
                                                className="normal-btn red-bg white-text"
                                            >
                                                Reset
                                            </button>
                                        </div>
                                        <div>
                                            <button type="button" onClick={submitForm} className="garage-submit-btn">
                                                {submitLoading ? 'Updating...' : 'Update'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <br />
                    <div className="center-text black-text">Thank you for trusting us</div>
                    <br />
                    <div className="center-text black-text">www.garagemobility.com</div>
                    <br />
                </MainCard>
            </div>
        </>
    );
};

export default ViewInspectionReport;
