import React from 'react';
import ChatFilter from './ChatFilter';
import UserItem from './UserItem';
import LoadingSpinner from './LoadingSpinner';
import { UsergroupAddOutlined } from '@ant-design/icons';

const SenderList = ({
    loading,
    filteredUsers,
    chatFilter,
    setChatFilter,
    searchTerm,
    setSearchTerm,
    messages,
    setSelectedUser,
    setPhoneNumber,
    fetchUserMessages,
    hideSenderList,
    selectedUser
}) => {
    return (
        <div className="senderList">
            <ChatFilter chatFilter={chatFilter} setChatFilter={setChatFilter} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            {loading ? (
                <LoadingSpinner />
            ) : (
                <div>
                    {filteredUsers.length > 0 ? (
                        filteredUsers.map((user, index) => (
                            <UserItem
                                key={user._id}
                                user={user}
                                messages={messages}
                                index={index}
                                setSelectedUser={setSelectedUser}
                                setPhoneNumber={setPhoneNumber}
                                fetchUserMessages={fetchUserMessages}
                                hideSenderList={hideSenderList}
                                selectedUser={selectedUser}
                            />
                        ))
                    ) : (
                        <div className="no-user-found">
                            <UsergroupAddOutlined
                                style={{
                                    fontSize: '40px',
                                    marginBottom: '20px'
                                }}
                            />{' '}
                            No user found
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SenderList;
