/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

// material-ui
import { styled } from '@mui/material/styles';
import { Box, List, Typography } from '@mui/material';
import * as XLSX from 'xlsx';
import { makeStyles } from '@mui/styles';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import TotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

// styles
const CardWrapper = styled(MainCard)(() => ({
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        // background: `linear-gradient(210.04deg, ${theme.palette.warning.dark} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -180
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        // background: `linear-gradient(140.9deg, ${theme.palette.warning.dark} -14.02%, rgba(144, 202, 249, 0) 70.50%)`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
}));

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 3
    },
    colors: {
        color: `#ED7C1B`
    },
    colors2: {
        color: '#000000',
        fontWeight: 'bold'
    },
    colors3: {
        color: '#000000',
        fontWeight: 'bold'
    },
    backgroundColor: {
        backgroundColor: '#2CB23B'
    }
}));

// ==============================|| DASHBOARD - TOTAL ORDERS ||============================== //

const TotalDarkStoreOrdersCard = ({ isLoading, totaldarkstoresorders, title, date }) => {
    // const theme = useTheme();
    const classes = useStyles();

    const handledataexport = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(totaldarkstoresorders);
        XLSX.utils.book_append_sheet(wb, ws, 'TotalDarkStoreOrders');
        XLSX.writeFile(wb, 'TotalDarkStoreOrders.xlsx');
    };

    function totalcount() {
        return totaldarkstoresorders.map(({ count }) => count).reduce((sum, i) => sum + i, 0);
    }
    function totalordervalue() {
        return totaldarkstoresorders.map(({ totalordervalue }) => totalordervalue).reduce((sum, i) => sum + i, 0);
    }

    function totalexpectedcash() {
        return totaldarkstoresorders.map(({ totalexpectedcash }) => totalexpectedcash).reduce((sum, i) => sum + i, 0);
    }

    function totalcashoutstanding() {
        return totaldarkstoresorders.map(({ totalcashoutstanding }) => totalcashoutstanding).reduce((sum, i) => sum + i, 0);
    }
    const sumtotalcount = totalcount();
    const sumtotalordervalue = Number(totalordervalue()).toFixed(2);
    const sumtotalexpectedcash = Number(totalexpectedcash()).toFixed(2);
    const sumtotalcashoutstanding = Number(totalcashoutstanding()).toFixed(2);

    return (
        <>
            {isLoading ? (
                <TotalIncomeCard />
            ) : (
                <CardWrapper border={false} content={false}>
                    <Box sx={{ p: 1 }}>
                        <List sx={{ py: 0 }}>
                            <Typography variant="h3" component="div" textAlign="center" className={classes.colors}>
                                {title}
                                <br />
                                {date}
                            </Typography>
                            <br />
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>..</Grid>
                                <Grid item>
                                    <Button onClick={handledataexport} variant="contained" size="sm" className={classes.backgroundColor}>
                                        Excel
                                    </Button>
                                </Grid>
                            </Grid>

                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    {isLoading ? (
                                                        <TableCell component="th" colSpan="4" align="center">
                                                            {' '}
                                                            <CircularProgress />{' '}
                                                        </TableCell>
                                                    ) : (
                                                        <>
                                                            <TableCell align="left">#</TableCell>
                                                            <TableCell align="left">RM</TableCell>
                                                            <TableCell align="left">Number of Orders</TableCell>
                                                            <TableCell align="left">Total Cost Value</TableCell>
                                                            <TableCell align="left">Total Sales Value</TableCell>
                                                            <TableCell align="left">Total Cash Outstanding</TableCell>
                                                        </>
                                                    )}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {totaldarkstoresorders.map((row, index) => (
                                                    <TableRow key={row._id}>
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row?.salesPerson ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row.count ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {Number(row.totalordervalue).toFixed(2) ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {Number(row.totalexpectedcash).toFixed(2) ?? ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {Number(row.totalcashoutstanding).toFixed(2) ?? ''}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow>
                                                    <TableCell rowSpan={1} />
                                                    <TableCell component="th" scope="row" className={classes.colors2} colSpan={1}>
                                                        SUM
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" className={classes.colors3}>
                                                        {sumtotalcount}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" className={classes.colors3}>
                                                        {sumtotalordervalue}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" className={classes.colors3}>
                                                        {sumtotalexpectedcash}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" className={classes.colors3}>
                                                        {sumtotalcashoutstanding}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </List>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

TotalDarkStoreOrdersCard.propTypes = {
    isLoading: PropTypes.bool
};

export default TotalDarkStoreOrdersCard;
