// new icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PaymentsIcon from '@mui/icons-material/Payments';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

// constant
const icons = {
    DashboardIcon,
    AccessAlarmIcon,
    FormatListBulletedIcon,
    AttachMoneyIcon,
    PaymentsIcon,
    SupportAgentIcon
};

// ==============================|| EXTRA FLEET MENU ITEMS ||============================== //
const userRole = localStorage.getItem('userRole');

const podLead = {
    id: 'podLead',
    title: 'Pod Lead',
    type: userRole,
    children: [
        {
            id: 'home',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: icons.DashboardIcon,
            breadcrumbs: false
        },
        {
            id: 'checkIn',
            title: 'Check in',
            type: 'item',
            url: '/check-in',
            breadcrumbs: false,
            icon: icons.AccessAlarmIcon
        },
        {
            id: 'orders',
            title: 'Orders',
            type: 'collapse',
            icon: icons.FormatListBulletedIcon,
            children: [
                {
                    id: 'myorders',
                    title: 'My Orders',
                    type: 'item',
                    url: '/mysalesorder',
                    breadcrumbs: false
                },
                {
                    id: 'sales',
                    title: 'Order Request Form',
                    type: 'item',
                    url: '/sales'
                },
                {
                    id: 'salesdrivebulk',
                    title: 'Sales Drive Bulk Order',
                    type: 'item',
                    url: '/salesdrivebulk'
                },
                {
                    id: 'presoldmysalesorder',
                    title: 'My PreSold Order',
                    type: 'item',
                    url: '/presoldmysalesorder',
                    breadcrumbs: false
                },

                {
                    id: 'presoldsales',
                    title: 'Add PreSold Orders',
                    type: 'item',
                    url: '/presoldsales',
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'poddata',
            title: 'Pod Orders',
            type: 'collapse',
            icon: icons.FormatListBulletedIcon,
            children: [
                {
                    id: 'podorders',
                    title: 'Presold Pod Orders',
                    type: 'item',
                    url: '/presoldpodorder',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'Sales operation',
            title: 'Sales Operation',
            type: 'collapse',
            icon: icons.AttachMoneyIcon,
            children: [
                {
                    id: 'kpiplanning',
                    title: 'KPI',
                    type: 'item',
                    url: '/kpi'
                },
                {
                    id: 'salesdrive',
                    title: 'Sales Drive Planning',
                    type: 'item',
                    url: '/rma/salesplanning'
                },
                {
                    id: 'orderswaybill',
                    title: 'Waybill',
                    type: 'item',
                    url: '/generated/waybill'
                }
            ]
        },
        {
            id: 'finance-operation',
            title: 'Finance Operation',
            type: 'collapse',
            icon: icons.PaymentsIcon,
            children: [
                {
                    id: 'paymentstatus',
                    title: 'Payment Status',
                    type: 'item',
                    url: '/collection/paymentstatus'
                },
                {
                    id: 'pricelists',
                    title: 'Price List',
                    type: 'item',
                    url: '/pricelists'
                }
            ]
        },
        {
            id: 'Customer management',
            title: 'Customer Management',
            type: 'collapse',
            icon: icons.SupportAgentIcon,
            children: [
                {
                    id: 'newlead',
                    title: 'New Lead',
                    type: 'item',
                    url: '/newlead'
                },
                {
                    id: 'salesperson',
                    title: 'Retail Partners',
                    type: 'item',
                    url: '/salespartner'
                }
            ]
        }
    ]
};

export default podLead;
