/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// import { notification } from 'antd';
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import useScriptRef from 'hooks/useScriptRef';

const AddTodoList = () => {
    const [loading, setLoading] = useState(false);

    const [type, settype] = useState('');
    const [name, setname] = useState('');

    // const [status, setStatus] = useState('');
    const history = useNavigate();
    const scriptedRef = useScriptRef();

    const handleTypeChange = (event) => {
        settype(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const userInfo = localStorage.getItem('userToken');

        const data = {
            type,
            name
        };

        fetch(ApiUrl.AddTodoListUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                Accept: 'application/form-data',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    if (scriptedRef.current) {
                        setTimeout(() => {
                            history('/todolist');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                    console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <br />
                    <PageHeader title="Configuration | Sales Planner" subTitle="Home | Configuration | Sales Planner | Add Todo" />
                    <br />
                    <MainCard title="Add Todo">
                        <form onSubmit={handleSubmit}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={type}
                                    required
                                    label="Type"
                                    onChange={handleTypeChange}
                                >
                                    <MenuItem value="Lead">Lead</MenuItem>
                                    <MenuItem value="Sales">Sales</MenuItem>
                                    <MenuItem value="Collection">Collection</MenuItem>
                                    <MenuItem value="Deposit">Deposit</MenuItem>
                                    <MenuItem value="NewRetailer">NewRetailer</MenuItem>
                                </Select>
                            </FormControl>
                            <br />
                            <br />
                            <TextField
                                autoComplete="off"
                                name="name"
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                value={name}
                                label="Description"
                                onChange={(e) => setname(e.target.value)}
                            />

                            <br />
                            <br />

                            <Box display="flex" justifyContent="space-between">
                                <Button component={Link} to="/todolist" type="submit" variant="contained" color="error">
                                    Reset
                                </Button>

                                <Button type="submit" variant="contained" color="secondary" disabled={!type || !name || loading}>
                                    {loading ? 'Submitting ..' : 'Submit'}
                                </Button>
                            </Box>
                        </form>
                    </MainCard>
                </Grid>
            </Grid>
        </>
    );
};

export default AddTodoList;
