// assets
import { IconKey, IconTruckDelivery } from '@tabler/icons';
import DashboardIcon from '@mui/icons-material/Dashboard';
// import Person from '@mui/icons-material/Person';
import CarCrashIcon from '@mui/icons-material/CarRental';
// import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import Scale from '@mui/icons-material/Scale';
// import Shop from '@mui/icons-material/Shop2';
import Trans from '@mui/icons-material/Money';
import CollectionsIcon from '@mui/icons-material/Collections';
import BugReportIcon from '@mui/icons-material/BugReport';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PaymentsIcon from '@mui/icons-material/Payments';

// import { SiSimpleanalytics } from 'react-icons/si';
// constant
const icons = {
    IconKey,
    IconTruckDelivery,
    DashboardIcon,
    Scale,
    Trans,
    CarCrashIcon,
    CollectionsIcon,
    BugReportIcon,
    FactCheckIcon,
    BorderColorIcon,
    AttachMoneyIcon,
    PaymentsIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //
const userRole = localStorage.getItem('userRole');
const userID = localStorage.getItem('userId');

// List of user IDs that should see the EPL section
const specialUserIDs = ['6373a251866ea91e49fbf98f', '6448edeb7781d1e7806b1020', '643d5c00d5cf938ad877a300'];

const FinanceManager = {
    id: 'financemanager',
    title: 'Finance Manager',
    type: userRole,
    children: [
        {
            id: 'home',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: icons.DashboardIcon,
            breadcrumbs: false
        },
        {
            id: 'attendance',
            title: 'Attendance',
            type: 'collapse',
            icon: icons.FactCheckIcon,
            children: [
                // {
                //     id: 'imageUpload',
                //     title: 'Image Upload',
                //     type: 'item',
                //     url: '/image-upload',
                //     breadcrumbs: false
                // },

                {
                    id: 'checkIn',
                    title: 'check In',
                    type: 'item',
                    url: '/check-in',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'orders',
            title: 'All Orders',
            type: 'item',
            url: '/order',
            icon: icons.Scale,
            breadcrumbs: false
        },
        {
            id: 'proformacssorders',
            title: 'Proforma Orders',
            type: 'item',
            url: '/proformacssorders',
            icon: icons.Trans,
            breadcrumbs: false
        },
        {
            id: 'presoldorders',
            title: 'PreSold Orders',
            type: 'item',
            url: '/presoldorders',
            icon: icons.Trans,
            breadcrumbs: false
        },
        // {
        //     id: 'pricing',
        //     title: 'Pricing',
        //     type: 'collapse',
        //     children: [
        //         {
        //             id: 'dashboard',
        //             title: 'Dashboard',
        //             type: 'item',
        //             url: '/pricing-dashboard',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'product-prices',
        //             title: 'Product prices',
        //             type: 'item',
        //             url: '/pricing-parts',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        // {
        //     id: 'darkstores',
        //     title: 'Dark Store Orders',
        //     type: 'item',
        //     url: '/darkstores',
        //     icon: icons.Trans,
        //     breadcrumbs: false
        // },

        {
            id: 'csssalesdriveorders',
            title: 'Sales Drive Orders',
            type: 'item',
            icon: icons.Scale,
            url: '/csssalesdriveorders',
            breadcrumbs: false
        },
        // {
        //     id: 'retailerdebittracker',
        //     title: 'Retailer Debit/Credit Tracker',
        //     type: 'item',
        //     url: '/retailerdebittracker',
        //     icon: icons.Trans,
        //     breadcrumbs: false
        // },
        {
            id: 'payment-list',
            title: 'Order Payments',
            type: 'item',
            url: '/payment-transactions',
            icon: icons.PaymentsIcon
        },
        {
            id: 'clearancesheet',
            title: 'Clearance Sheet',
            type: 'item',
            url: '/clearancesheet',
            icon: icons.BorderColorIcon,
            breadcrumbs: false
        },
        // {
        //     id: 'batchespage',
        //     title: 'Batch Orders',
        //     type: 'item',
        //     url: '/batchespage',
        //     icon: icons.Trans,
        //     breadcrumbs: false
        // },

        // {
        //     id: 'joborders',
        //     title: 'Job Orders',
        //     type: 'item',
        //     url: '/JobOrder',
        //     icon: icons.Scale,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'collectionorders',
        //     title: 'Collections',
        //     type: 'item',
        //     url: '/collectionorders',
        //     icon: icons.CollectionsIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'depositedorderlist',
        //     title: 'Deposit',
        //     type: 'item',
        //     url: '/depositedorderlist',
        //     icon: icons.CollectionsIcon,
        //     breadcrumbs: false
        // },
        {
            id: 'orderissues',
            title: 'Order Issue',
            type: 'item',
            url: '/orderissues',
            icon: icons.BugReportIcon,
            breadcrumbs: false
        },
        {
            id: 'parts',
            title: 'Parts',
            type: 'collapse',
            icon: icons.CarCrashIcon,

            children: [
                {
                    id: 'masterdb',
                    title: 'Master Database',
                    type: 'item',
                    url: '/parts'
                },
                {
                    id: 'parts-catalog',
                    title: 'Parts Catalog',
                    type: 'item',
                    url: '/parts-catalog',
                    breadcrumbs: false
                },
                {
                    id: 'part-discount',
                    title: 'Check Part Discount',
                    type: 'item',
                    url: '/part-discount',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

// eslint-disable-next-line no-constant-condition
// Add Easy Pay Later section if user ID matches special user IDs
if (specialUserIDs.includes(userID)) {
    FinanceManager.children.push({
        id: 'epl',
        title: 'Easy Pay Later',
        type: 'collapse',
        icon: icons.AttachMoneyIcon,
        children: [
            {
                id: 'configuration',
                title: 'EPL configuration',
                type: 'item',
                url: '/epl/configuration'
            },
            {
                id: 'tracker',
                title: 'EPL Tracker/Payment',
                type: 'item',
                url: '/epl/easy-pay-later'
            },
            {
                id: 'summary',
                title: 'EPL summary',
                type: 'item',
                url: '/epl/easy-pay-summary'
            },
            {
                id: 'eplrequest',
                title: 'EPL Request',
                type: 'item',
                url: '/epl/easy-pay-request'
            },
            {
                id: 'creditstatus',
                title: 'Credit Status',
                type: 'item',
                url: '/epl/credit_status'
            }
        ]
    });
}

export default FinanceManager;
