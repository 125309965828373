import { Button, Paper } from '@mui/material';
import styled from 'styled-components';
import Cart from './OrderRequest/Cart';

const HeaderWrapper = styled.div`
    .custom-paper {
        font-family: 'Roboto', 'sans-serif';
        border-radius: 0;
        padding: 0.625rem 0.625rem;
        color: #444;
        margin: 20px 0;

        h4,
        h5 {
            font-size: 0.875rem;
            font-weight: 400;
        }

        h4 {
            font-weight: 800;
        }

        h5 {
            color: #8b8b8b;
        }
    }
`;

const GenericPageHeader = ({ title, breadcrumbs, hasCart, cart, setShow }) => {
    return (
        <HeaderWrapper>
            <Paper elevation={0} className="custom-paper">
                {hasCart ? (
                    <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {/* <div>
                            <h4>{title}</h4>
                            <h5>{breadcrumbs}</h5>
                        </div> */}
                        <Button variant="link" size="large" onClick={() => setShow(true)}>
                            My Shopping
                        </Button>

                        <div className="shopping-cart">
                            <Cart cart={cart} setShow={setShow} />
                        </div>
                    </header>
                ) : (
                    <header>
                        <h4>{title}</h4>
                        <h5>{breadcrumbs}</h5>
                    </header>
                )}
            </Paper>
        </HeaderWrapper>
    );
};
export default GenericPageHeader;
