import Input from './Input';
import RadioGroup from './RadioGroup';
import Select from './Select';
import Checkbox from './Checkbox';

import Button from './Button';

const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,

    Button
};

export default Controls;
