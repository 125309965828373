// import dashboard from './dashboard';
import hrmanager from './hr';
import other from './other';
// import reporting from './reporting';

// ==============================|| MENU ITEMS dashboard ||============================== //

const hrManagerItems = {
    items: [hrmanager, other]
};

export default hrManagerItems;
