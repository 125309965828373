// import dashboard from './dashboard';
import seniormechanic from './SeniorMechanic';
import MechanicSettings from './mechanicSetting';

// ==============================|| MENU ITEMS dashboard ||============================== //

const seniormechanicItems = {
    items: [seniormechanic, MechanicSettings]
};

export default seniormechanicItems;
