import React from 'react';
import { SendOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { Popover } from 'antd';

const ChatBoxFooter = ({
    message,
    setMessage,
    sendMessage,
    chatMenuVisible,
    setChatMenuVisible,
    chatMenuVisibleMobile,
    setChatMenuVisibleMobile,
    mediaContent
}) => {
    return (
        <div className={`chat-box-footer ${chatMenuVisibleMobile ? 'footer-up' : ''}`}>
            <input
                type="text"
                placeholder="Send message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
            />
            <button className="ml-5" type="button" onClick={sendMessage}>
                <SendOutlined />
            </button>
            <Popover content={mediaContent} trigger="click" open={chatMenuVisible} onOpenChange={setChatMenuVisible} placement="topLeft">
                <button
                    className="ml-5 mira-mobile-hide"
                    style={chatMenuVisible ? { backgroundColor: '#F6F6F6', color: '#7B808C' } : { backgroundColor: '#4B6EFF' }}
                    type="button"
                    onClick={() => setChatMenuVisible(!chatMenuVisible)}
                >
                    {chatMenuVisible ? <CloseOutlined /> : <PlusOutlined />}
                </button>
            </Popover>
            <button
                className="ml-5 mira-desktop-hide"
                style={chatMenuVisibleMobile ? { backgroundColor: '#F6F6F6', color: '#7B808C' } : { backgroundColor: '#4B6EFF' }}
                type="button"
                onClick={() => setChatMenuVisibleMobile(!chatMenuVisibleMobile)}
            >
                {chatMenuVisibleMobile ? <CloseOutlined /> : <PlusOutlined />}
            </button>
        </div>
    );
};

export default ChatBoxFooter;
