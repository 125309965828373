import { Button } from '@mui/material';

const CompressedTable = ({ orderCompressed }) => {
    return (
        <>
            <div className="table-responsive">
                <table className="table  table-hover">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Item</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Size</th>
                            <th scope="col">Supplier</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderCompressed &&
                            orderCompressed?.map((order, index) => {
                                const { partid, qty, supplier, partType } = order;
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{partid}</td>
                                        <td>{qty}</td>
                                        <td>{partType}</td>
                                        <td>{supplier}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
            <br />
            <div className="go-back" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Button variant="contained" href="/" style={{ backgroundColor: ' #ff740f' }}>
                    &larr; Go back
                </Button>
            </div>
        </>
    );
};
export default CompressedTable;
