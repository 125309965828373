// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import partsItems from 'menu-items/partsIndex';
import insurerItems from 'menu-items/insurerIndex';
import fleetItems from 'menu-items/mychannelpartnerfleetIndex';
import salesItems from 'menu-items/salesIndex';
import fulfillmentItems from 'menu-items/fullmentIndex';
import dataentryItems from 'menu-items/dataentryIndex';
import customersuccessItems from 'menu-items/customersuccessIndex';
import customersuccessLeadItems from 'menu-items/customersucessleadIndex';
import fleetdriverItems from 'menu-items/fleetdriverIndex';
import distributorItems from 'menu-items/distributorIndex';
import mechanicItems from 'menu-items/mechanicIndex';
import partssourceNgItems from 'menu-items/partsSourceNigeriaIndex';
import juniormechanicItems from 'menu-items/JuniorMechanicIndex';
import seniormechanicItems from 'menu-items/SeniorMechanicIndex';
import partsuploadItems from 'menu-items/partsuploadIndex';
import driverriderItems from 'menu-items/driverIndex';
import darkstoreIndexItems from 'menu-items/darkstoreIndex';
import financeManagerItems from 'menu-items/FinanceManagerIndex';
import salesleaditems from 'menu-items/SalesLeadIndex';
import hrManagerItems from 'menu-items/hrIndex';
import podleadItems from 'menu-items/podLeadIndex';
import logisticsItems from 'menu-items/logisticsIndex';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const userRole = localStorage.getItem('userRole');
    // console.log('role', userRole);
    if (userRole === 'Admin') {
        const navItems = menuItem.items.map((item) => {
            switch (item.type) {
                case 'Admin':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{navItems}</>;
    }
    if (userRole === 'Price Sourcing') {
        const partsItem = partsItems.items.map((item) => {
            switch (item.type) {
                case 'Price Sourcing':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{partsItem}</>;
    }

    if (userRole === 'Insurer') {
        const insurerItem = insurerItems.items.map((item) => {
            switch (item.type) {
                case 'Insurer':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{insurerItem}</>;
    }

    if (userRole === 'Fleet') {
        const fleetItem = fleetItems.items.map((item) => {
            switch (item.type) {
                case 'Fleet':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{fleetItem}</>;
    }

    if (userRole === 'Sales') {
        const salesitems = salesItems.items.map((item) => {
            switch (item.type) {
                case 'Sales':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{salesitems}</>;
    }

    if (userRole === 'fulfillment') {
        const fulfillmentitems = fulfillmentItems.items.map((item) => {
            switch (item.type) {
                case 'fulfillment':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{fulfillmentitems}</>;
    }

    if (userRole === 'DataEntry') {
        const dataentryitems = dataentryItems.items.map((item) => {
            switch (item.type) {
                case 'DataEntry':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{dataentryitems}</>;
    }

    if (userRole === 'CustomerSuccess') {
        const customersuccessitems = customersuccessItems.items.map((item) => {
            switch (item.type) {
                case 'CustomerSuccess':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{customersuccessitems}</>;
    }

    if (userRole === 'CustomerSuccessLead') {
        const customersuccessleadItems = customersuccessLeadItems.items.map((item) => {
            switch (item.type) {
                case 'CustomerSuccessLead':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{customersuccessleadItems}</>;
    }

    if (userRole === 'Fleet Driver') {
        const fleetdriver = fleetdriverItems.items.map((item) => {
            switch (item.type) {
                case 'Fleet Driver':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{fleetdriver}</>;
    }

    if (userRole === 'Distributor') {
        const distributor = distributorItems.items.map((item) => {
            switch (item.type) {
                case 'Distributor':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{distributor}</>;
    }

    if (userRole === 'Mechanic') {
        const mechanic = mechanicItems.items.map((item) => {
            switch (item.type) {
                case 'Mechanic':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{mechanic}</>;
    }

    if (userRole === 'PartSourcingNg') {
        const partsnegeria = partssourceNgItems.items.map((item) => {
            switch (item.type) {
                case 'PartSourcingNg':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{partsnegeria}</>;
    }

    if (userRole === 'Senior Mechanic') {
        const seniormechanic = seniormechanicItems.items.map((item) => {
            switch (item.type) {
                case 'Senior Mechanic':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{seniormechanic}</>;
    }

    if (userRole === 'Junior Mechanic') {
        const juniormechanic = juniormechanicItems.items.map((item) => {
            switch (item.type) {
                case 'Junior Mechanic':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{juniormechanic}</>;
    }

    if (userRole === 'partsupload') {
        const partuploadsdata = partsuploadItems.items.map((item) => {
            switch (item.type) {
                case 'partsupload':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{partuploadsdata}</>;
    }

    if (userRole === 'DriverRider') {
        const driverriderdata = driverriderItems.items.map((item) => {
            switch (item.type) {
                case 'DriverRider':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{driverriderdata}</>;
    }

    if (userRole === 'DarkStore Associate') {
        const darkstoredata = darkstoreIndexItems.items.map((item) => {
            switch (item.type) {
                case 'DarkStore Associate':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{darkstoredata}</>;
    }

    if (userRole === 'Finance Manager') {
        const financemanagerdata = financeManagerItems.items.map((item) => {
            switch (item.type) {
                case 'Finance Manager':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{financemanagerdata}</>;
    }

    if (userRole === 'SalesLead') {
        const salesleaddata = salesleaditems.items.map((item) => {
            switch (item.type) {
                case 'SalesLead':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{salesleaddata}</>;
    }

    if (userRole === 'HR') {
        const hrdata = hrManagerItems.items.map((item) => {
            switch (item.type) {
                case 'HR':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{hrdata}</>;
    }

    if (userRole === 'PodLead') {
        const podleaddata = podleadItems.items.map((item) => {
            switch (item.type) {
                case 'PodLead':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{podleaddata}</>;
    }

    if (userRole === 'Logistics') {
        const logisticsdata = logisticsItems.items.map((item) => {
            switch (item.type) {
                case 'Logistics':
                    return <NavGroup key={item.id} item={item} />;

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            {/* Menu Items Error */}
                        </Typography>
                    );
            }
        });
        return <>{logisticsdata}</>;
    }

    return null;
};

export default MenuList;
