/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
// import debounce from 'lodash.debounce';
import * as XLSX from 'xlsx';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';
// import Controls from '../../components/controls/Controls';
import { Col } from 'react-grid-system';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
// import { Search } from '@mui/icons-material';
// import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
// import ViewIcon from '@mui/icons-material/Preview';
// import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
// import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
// import Dialog from '@mui/material/Dialog';
import { Avatar } from '@mui/material';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
// import Form from 'react-bootstrap/Form';
// import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';

// import styled from 'styled-components';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 3
    }
}));

const UnApprovedParts = () => {
    // page states

    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [partsInfo, setParts] = useState([]);
    // const [partsData, setpartsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);

    const theme = useTheme();

    const [searched, setSearched] = useState('');

    const retrieveAllParts = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);

            fetch(ApiUrl.UnApprovedPartListUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setParts(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handleEditClick = (row) => {
        if (scriptedRef.current) {
            history(`/editinactiveparts/${row._id}`);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 30));
        setPage(0);
    };

    const handledataexport = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(partsInfo);
        XLSX.utils.book_append_sheet(wb, ws, 'UnApprovedGaragePart');
        XLSX.writeFile(wb, 'UnApprovedGaragePart.xlsx');
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - partsInfo.length) : 0;

    useEffect(() => {
        retrieveAllParts();
    }, []);

    // const handleDeleteClick = (row) => {
    //     swal({
    //         title: 'Are you sure?',
    //         text: `Once deleted, you will not be able to recover this Part!(${row.sku})`,
    //         icon: 'warning',
    //         buttons: true,
    //         dangerMode: true
    //     }).then((willDelete) => {
    //         if (willDelete) {
    //             swal('Poof! The Part has been deleted!', {
    //                 icon: 'success'
    //             });

    //             const data = {
    //                 id: row._id
    //             };
    //             const userInfo = localStorage.getItem('userToken');

    //             fetch(ApiUrl.DeletePartsUrl, {
    //                 method: 'DELETE',
    //                 headers: {
    //                     'auth-token': userInfo,
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify(data)
    //             })
    //                 .then((res) => res.json())
    //                 .then((result) => {
    //                     if (result.status === true) {
    //                         if (scriptedRef.current) {
    //                             retrieveAllParts();
    //                             // window.location.href = '/stage';
    //                             // history('/stage');
    //                         }
    //                     } else {
    //                         toast.error(result.data);
    //                     }
    //                 });
    //         } else {
    //             swal('The Part is safe!');
    //         }
    //     });
    // };

    const filteredRows = partsInfo.filter((row) => row.sku.toLowerCase().includes(searched.toLowerCase()));
    const isPlural = filteredRows && filteredRows.length > 1 ? 's' : '';
    const searchCount = searched ? `${filteredRows.length} result${isPlural} found` : 'No Results';

    // const handleSearch = (event) => {
    //     setSearched(event.target.value);
    //     const searchWord = event.target.value;

    //     const newFilter = partsInfo.filter((value) => value.sku.toLowerCase().includes(searchWord.toLowerCase()));

    //     if (searchWord === '') {
    //         setParts(partsData);
    //     } else {
    //         setParts(newFilter);
    //     }
    // };

    return (
        <>
            <br />
            <PageHeader title="Parts" subTitle="Home | Configuration | Parts | UnApproved Parts" />
            <br />
            <MainCard title="UnApproved Parts List">
                <h4 style={{ color: '#ff740f', fontWeight: '700' }}>
                    {searched ? searchCount : `Total UnApproved Parts: ${partsInfo.length}`}
                </h4>
                <br />
                <Box display="flex" justifyContent="flex-start">
                    {/* <Grid>
                        <Controls.Input
                            label="Search Product"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                )
                            }}
                            value={searched}
                            onChange={(searchVal) => handleSearch(searchVal)}
                        />
                    </Grid> */}
                    <Col xs={12} xl={3} lg={3} md={6} sm={6}>
                        <FormControl fullWidth style={{ marginBottom: '10px' }}>
                            <TextField
                                id="outlined-basic"
                                label="Search Product"
                                variant="outlined"
                                onChange={(e) => setSearched(e.target.value)}
                            />
                        </FormControl>
                    </Col>
                    <Box width={10} />

                    <Box width={10} />

                    <Button onClick={handledataexport} variant="contained" size="sm" className={classes.item} color="info">
                        Download Excel
                    </Button>
                </Box>
                <br />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Product Name
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Product Family
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Weight In KG
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Status
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Qty In Pieces
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Wholesale Price
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Main Price
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Price A
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Price B
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Price C
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            CS Price
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Part Image
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : filteredRows
                            ).map((row, index) => (
                                <TableRow key={row._id}>
                                    <TableCell component="th" scope="row">
                                        {filteredRows.length - index}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.sku}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.productFamily}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.partWeight}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.status === 'InActive' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.error.light,
                                                    color: theme.palette.error.dark,
                                                    width: 80,
                                                    height: 30
                                                }}
                                            >
                                                <DangerousRoundedIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row.status ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}

                                        {row.status === 'Active' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    ...theme.typography.commonAvatar,
                                                    ...theme.typography.largeAvatar,
                                                    backgroundColor: theme.palette.success.light,
                                                    color: theme.palette.success.dark,
                                                    width: 80,
                                                    height: 30
                                                }}
                                            >
                                                <CheckCircleIcon fontSize="small" />
                                                <Box width={5} /> <Typography variant="h5">{row.status ?? ''}</Typography>
                                            </Avatar>
                                        ) : (
                                            ''
                                        )}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.partQtyInPieces ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.wholeSalePrice}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.garagePrice}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.partListAPrice ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.partListBPrice ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.partListCPrice ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.partListCSPrice ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        <Avatar variant="rounded" src={row?.partsImage} sx={{ width: 150, height: 150 }} />
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(row)}>
                                            <EditIcon />
                                        </IconButton>
                                        {/* <IconButton aria-label="delete" color="error" onClick={() => handleDeleteClick(row)}>
                                            <DeleteIcon />
                                        </IconButton> */}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[100, 200, 300, { label: 'All', value: -1 }]}
                    component="div"
                    count={partsInfo.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>

            {/* </Section> */}
        </>
    );
};

export default UnApprovedParts;
