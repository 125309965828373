import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, List, ListItem, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { DatePicker, Select, Space, Pagination } from 'antd';
import React, { useEffect, useState } from 'react';
import WestIcon from '@mui/icons-material/West';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { toast } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';
import ApiUrl from 'network';
import { encrypt, decrypt } from '../../utils/encrypt';
import { useNavigate } from 'react-router';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditTask from './EditTask';
import { dateConverter } from 'utils/functions';
import { EmptyState } from 'components/EmptyState/EmptyState';

const AllRetailerNotes = () => {
    const [loading, setLoading] = useState(false);
    const [mainRma, setMainRma] = useState([]);
    const [sources, setSources] = useState([]);
    const [allNotes, setAllNotes] = useState([]);
    const [pods, setPods] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const country = localStorage.getItem('country');
    const [accumulatedFilters, setAccumulatedFilters] = useState({});
    const userRole = localStorage.getItem('userRole');
    const [open, setOpen] = useState(true);
    const [current, setCurrent] = useState(1);
    const [totalDocuments, setTotalDocuments] = useState(0);

    const { RangePicker } = DatePicker;

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54
            }}
            spin
        />
    );

    const toggleFilter = () => {
        setOpen(!open);
    };

    const navigate = useNavigate();

    // get query data
    const getQueryData = async () => {
        try {
            // setLoading(true);
            const url = `${ApiUrl.BaseUrl}/mainorders/querydata?country=${country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setMainRma(answer.relationshipManagers);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    // get pod data
    const getPodData = async () => {
        try {
            // setLoading(true);
            const url = `${ApiUrl.BaseUrl}/salesdrivepod/salesdrivepod`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setPods(answer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const getSourceData = async () => {
        try {
            // setLoading(true);
            const url = `${ApiUrl.BaseUrl}/crm/get-contact-sources`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setSources(answer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const sourceOptions = sources?.map((item) => {
        return { label: item?.name, value: item?._id };
    });

    const rmaOptions = mainRma?.map((item) => {
        return { label: item?.name, value: item?._id };
    });

    const podsOptions = pods?.map((pod) => {
        return {
            label: pod?.name,
            value: pod?._id
        };
    });

    const getAllNotes = async () => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/crm/fetch-all-notes`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            // Append accumulated filters to URL
            Object.entries(accumulatedFilters).forEach(([key, value]) => {
                if (value) {
                    urlParams.append(key, value);
                }
            });

            url = `${url}?${urlParams.toString()}`;

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        // console.log(answer);
                        // if (accumulatedFilters.source === 'Messaging') {
                        //     setAllNotes(answer.data.filter((item) => item.source === ' Messaging' || item.source === 'Messaging'));
                        // } else if (accumulatedFilters.source === 'Phone Call') {
                        //     setAllNotes(answer.data.filter((item) => item.source === 'Phone call' || item.source === 'Phone Call'));
                        // } else {
                        //     setAllNotes(answer.data);
                        // }
                        setAllNotes(answer.data);
                        setTotalDocuments(answer.total_documents);

                        // console.log(answer.data.filter((item) => item.source === 'In-person'));
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getQueryData();
    }, []);

    useEffect(() => {
        getSourceData();
        getAllNotes();
        getPodData();
    }, [accumulatedFilters]);

    // filter date
    const filterDate = (value, dateString) => {
        setSelectedDate(value);
        const data = {
            start_date: dateString[0],
            end_date: dateString[1]
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter RMA
    const filterRmaPerson = (value) => {
        const data = {
            rma_id: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter Source
    const filterSource = (value) => {
        const data = {
            source: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    // filter Source
    const filterPod = (value) => {
        const data = {
            pod_id: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    const onChange = (page, pageSize) => {
        const data = {
            page,
            limit: pageSize
        };
        setCurrent(page);
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    const NotesCard = ({ source, createdBy, note, createdAt, retailerName }) => {
        return (
            <Box
                sx={{
                    flexGrow: '1',
                    margin: '5px',
                    flexBasis: '510px'
                }}
            >
                <Box
                    style={{
                        backgroundColor: '#fff',
                        borderRadius: '16px',
                        margin: '5px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        width: '100%',
                        padding: '15px',
                        overflow: 'hidden'
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'start',
                            color: '#233E4C',
                            fontSize: '18px',
                            height: '100px',
                            overflowY: 'scroll',
                            overflowX: 'hidden'
                        }}
                        className="order-history-modals-y"
                    >
                        {note}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                            margin: '16px 0 0 0',
                            width: '100%',
                            flexWrap: 'wrap'
                        }}
                    >
                        <Box
                            sx={{
                                color: '#85949C',
                                fontSize: '12px',
                                margin: '5px 0 0 0'
                            }}
                        >
                            Source: {source}
                        </Box>

                        <Box
                            sx={{
                                color: '#85949C',
                                fontSize: '12px',
                                margin: ' 5px 10px 0 0'
                            }}
                        >
                            Added by: {createdBy?.firstName} {createdBy?.lastName}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            flexWrap: 'wrap'
                        }}
                    >
                        <Box
                            sx={{
                                color: '#85949C',
                                fontSize: '12px',
                                margin: '5px 0 0 0'
                            }}
                        >
                            Retailer: {retailerName}
                        </Box>

                        <Box
                            sx={{
                                color: '#85949C',
                                fontSize: '12px',
                                margin: '5px 0 0 0'
                            }}
                        >
                            {new Date(createdAt).toLocaleDateString()}
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    };
    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '1px dashed #E7EAEB',
                    alignItems: 'flex-end',
                    margin: '0px auto 15px',
                    flexWrap: 'wrap'
                }}
            >
                {' '}
                <List
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '0 0 16px 0',
                        alignItems: 'flex-start'
                        // margin: '0px auto 15px'
                    }}
                >
                    <WestIcon
                        sx={{
                            fontSize: '32px',
                            cursor: 'pointer',
                            '&:hover': {
                                color: '#546974'
                            },
                            '&:focus': {
                                color: '#9DA9AF'
                            }
                        }}
                        onClick={() => navigate(-1)}
                    />{' '}
                    <ListItem sx={{ fontWeight: 500, fontSize: '1.875rem', color: '#0A2938', padding: 0, marginLeft: '24px' }}>
                        All notes{' '}
                    </ListItem>
                </List>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    margin: '32px 0 0 0'
                }}
                onClick={toggleFilter}
            >
                <Typography
                    style={{
                        fontSize: '18px',
                        fontWeight: '500',
                        color: '#0A2938'
                    }}
                >
                    {open ? `Hide filters` : `Show filters`}
                </Typography>

                {open ? (
                    <KeyboardArrowUpIcon
                        style={{
                            margin: '0 0 0 2px',
                            color: '#0A2938'
                        }}
                    />
                ) : (
                    <ExpandMoreIcon
                        style={{
                            margin: '0 0 0 2px',
                            color: '#0A2938'
                        }}
                    />
                )}
            </Box>
            <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                sx={{
                    margin: '0 0 8px 0'
                }}
            >
                <>
                    <Space
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                width: ' 239px',
                                margin: '8px 0 0 0px'
                            }}
                        >
                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Search RMA/CC "
                                options={rmaOptions}
                                onChange={(value) => filterRmaPerson(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                                // loading={loading}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 239px',
                                margin: '8px 0 0 0px'
                            }}
                        >
                            <RangePicker
                                format="YYYY-MM-DD"
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                value={selectedDate}
                                onChange={filterDate}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 224px',
                                margin: '8px 0 0 0px'
                            }}
                        >
                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Source"
                                options={sourceOptions}
                                onChange={(value) => filterSource(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                                // loading={loading}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 224px',
                                margin: '8px 0 0 0px'
                            }}
                        >
                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Pods"
                                options={podsOptions}
                                onChange={(value) => filterPod(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                                // loading={loading}
                            />
                        </Box>
                    </Space>
                </>
            </Collapse>
            {loading ? (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '4000'
                    }}
                >
                    {antIcon}
                </Box>
            ) : allNotes.length > 0 ? (
                <>
                    <Box
                        sx={{
                            margin: '24px 0px 24px 0px',
                            alignItems: 'center',
                            display: 'grid',
                            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                            gap: '10px'
                        }}
                    >
                        {allNotes.map((note) => (
                            <NotesCard key={note?._id} {...note} retailerName={note?.retailer_name} />
                        ))}
                    </Box>
                    <Pagination current={current} onChange={onChange} total={totalDocuments} />
                </>
            ) : (
                <Box
                    style={{
                        margin: '100px auto 0',
                        position: 'relative',
                        backgroundColor: '#FFFFFF',
                        height: '496px',
                        borderRadius: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <EmptyState height="204px" padding="5px" text="No results yet" />
                </Box>
            )}
            {/* {allNotes.length > 0 ? <Pagination current={current} onChange={onChange} total={totalDocuments} /> : null} */}
        </div>
    );
};

export default AllRetailerNotes;
