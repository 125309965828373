import styled from 'styled-components';

const Wrapper = styled.div`
    font-family: 'Roboto', 'sans-serif';
    background: #ffff;
    padding: 1rem 2rem;
    border-radius: 0.625rem;
    color: #222;
    margin-bottom: 15px;
    h4 {
        font-size: 1rem;
        color: #8b8b8b;
    }

    h5 {
        color: #000;
        font-weight: 800;
        font-size: 1.5rem;
    }

    .neg-percentile {
        background: #ffe0e0;
        color: red;
        font-weight: 600;
        padding: 0.625rem;
        border-radius: 0.3125rem;
    }

    .pos-percentile {
        background: #e0ffe5;
        padding: 0.625rem;
        color: green;
        font-weight: 600;
        border-radius: 0.3125rem;
    }

    .stats-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        padding: 0.625rem 0;
    }

    .stats-footer p {
        color: #8b8b8b;
    }
`;

const StatsCard = ({ title, todayCount, prevCount, perChange }) => {
    const perChangeStatus = perChange > 0 ? 'pos-percentile' : 'neg-percentile';
    const perChangeArrow = perChange > 0 ? <span>&uarr;</span> : <span>&darr;</span>;
    return (
        <Wrapper className="shadow-sm">
            <h4>{title}</h4>
            <div className="stats-body">
                <h5>{todayCount}</h5>
                <p className={perChangeStatus}>
                    {perChange.toFixed(2)}%<span>{todayCount !== prevCount && perChangeArrow}</span>
                </p>
            </div>
            <div className="stats-footer">
                <p>Last week: {prevCount}</p>
            </div>
        </Wrapper>
    );
};
export default StatsCard;
