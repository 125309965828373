import styled from 'styled-components';

const LeadCardWrapper = styled.div`
    @media screen and (max-width: 500px) {
        .lead-card {
            width: 100%;
            margin: 0 auto;
        }
    }
    .lead-card {
        border: 5px solid #ff740f;
        padding: 10px 20px;
        border-radius: 5px;
        margin-bottom: 40px;
        max-width: 18.75rem;

        @media screen and (max-width: 768px) {
            margin-top: 30px;
        }

        .card-title {
            color: #989898;
            margin-bottom: 10px;
            font-size: 1.2rem;
        }

        .lead-count {
            font-weight: 900;
            font-size: 1.5rem;
        }
    }
`;

export default LeadCardWrapper;
