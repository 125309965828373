import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
import useScriptRef from 'hooks/useScriptRef';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import { toast } from 'react-toastify';

const EditCarrier = () => {
    const [name, setName] = useState('');
    const [carrierRating, setRating] = useState('');
    const [carrierContact, setContact] = useState('');
    const [carrierNPS, setNPS] = useState('');
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState('');
    const history = useNavigate();
    const scriptedRef = useScriptRef();

    const { id } = useParams();

    const retrieveAllActiveCountry = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            // setLoading(true);
            fetch(ApiUrl.ActiveCountryUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        retrieveAllActiveCountry();
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.ViewCarrierUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setName(result?.data?.name ?? '');
                    setRating(result?.data?.carrierRating ?? '');
                    setContact(result?.data?.carrierContact ?? '');
                    setStatus(result?.data?.status ?? '');
                    setNPS(result?.data?.carrierNPS ?? '');
                    setCountry(result?.data?.country ?? '');
                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                    // setLoading(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading2(false);
            });
    }, [id]);

    const handleChange = (event) => {
        setStatus(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');

        const data = {
            id,
            name,
            status,
            carrierRating,
            carrierContact,
            carrierNPS,
            country
        };
        setLoading(true);
        fetch(ApiUrl.UpdateCarrierUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setLoading(false);
                    toast.success(result.message);
                    setTimeout(() => {
                        if (scriptedRef.current) {
                            history('/carrier');
                        }
                    }, 3000);
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <br />
                            <PageHeader title="Carriers" subTitle="Home | Configuration | Carrier" />
                            <br />
                            <MainCard title="Edit Carrier">
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        autoComplete="off"
                                        name="name"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="name"
                                        label=" Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        autoFocus
                                    />
                                    <br />
                                    <br />

                                    <TextField
                                        autoComplete="off"
                                        name="carrierContact"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="carrierContact"
                                        label="Contact"
                                        value={carrierContact}
                                        onChange={(e) => setContact(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="carrierRating"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="name"
                                        label="Rating"
                                        value={carrierRating}
                                        onChange={(e) => setRating(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <TextField
                                        autoComplete="off"
                                        name="carrierNPS"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="carrierNPS"
                                        label="NPS"
                                        value={carrierNPS}
                                        onChange={(e) => setNPS(e.target.value)}
                                    />
                                    <br />
                                    <br />

                                    <Autocomplete
                                        id="country-select-demo"
                                        autoHighlight
                                        value={country}
                                        onChange={(event, newValue) => {
                                            setCountry(newValue);
                                        }}
                                        options={countries.map((option) => option.name)}
                                        isOptionEqualToValue={(option, value) => option.code === value.code}
                                        renderInput={(params) => <TextField {...params} label="Choose a country" />}
                                    />
                                    <br />
                                    <br />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={status}
                                            label="Status"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="Active">Active</MenuItem>
                                            <MenuItem value="InActive">InActive</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <br />
                                    <br />
                                    <Box display="flex" justifyContent="space-between">
                                        <Button component={Link} to="/carrier" type="submit" variant="contained" color="error">
                                            Reset
                                        </Button>

                                        <Button type="submit" variant="contained" color="secondary">
                                            {loading ? 'Updating ..' : 'Update'}
                                        </Button>
                                    </Box>
                                </form>
                            </MainCard>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default EditCarrier;
