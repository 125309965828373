/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';
import Controls from '../../components/controls/Controls';
import { Search } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';

import { toast } from 'react-toastify';
// import Typography from '@mui/material/Typography';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useTheme } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import { Avatar, Typography } from '@mui/material';

// import { saveAs } from 'file-saver';
// import axios from 'axios';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    colors: {
        color: `#ED7C1B`
    },
    backgroundColor: {
        backgroundColor: '#2CB23B'
    }
}));

const ResolvedIssues = () => {
    const classes = useStyles();

    const theme = useTheme();
    const [orderInfo, setOrder] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    // const [totalapproved, setTotalapproved] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderInfo.length) : 0;

    const retrieveAllOrders = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.ResolvedOrderIssuesUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        // console.log(data.data);
                        setOrder(data.data);
                        setOrderData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        retrieveAllOrders();
    }, []);

    const handleSearch = (event) => {
        const searchWord = event.target.value;

        const newFilter = orderInfo.filter((value) => (value?.salesname || '').toLowerCase().includes((searchWord || '').toLowerCase()));

        if (searchWord === '') {
            setOrder(orderData);
        } else {
            setOrder(newFilter);
        }
    };

    return (
        <>
            <br />
            <PageHeader title="Resolved Issues" subTitle="Home | Orders |  Resolved Issues " />
            <br />

            <MainCard title="Resolved Issues List">
                <br />
                <br />

                <Box display="flex" justifyContent="flex-start">
                    <Controls.Input
                        label="Search Sales Person"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearch}
                    />
                </Box>

                <br />

                <TableContainer component={Paper}>
                    <Table aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Issue Date
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order Date
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Order No.
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Retailer
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Sales Person
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Image
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Description
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Status
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 ? orderInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : orderInfo).map(
                                (row, index) => (
                                    <TableRow key={row._id}>
                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.dateopened ? toTimestamp(row?.dateopened) : ''}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row?.dateoforder ? toTimestamp(row?.dateoforder) : ''}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row?.orderno ?? ''}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row?.clientname ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.salesname ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Avatar variant="rounded" src={row?.issueImage} sx={{ width: 80, height: 50 }} />
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row?.description ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.status === 'resolved' ? (
                                                <Avatar
                                                    variant="rounded"
                                                    sx={{
                                                        ...theme.typography.commonAvatar,
                                                        ...theme.typography.largeAvatar,
                                                        backgroundColor: theme.palette.success.light,
                                                        color: theme.palette.success.dark,
                                                        width: 85,
                                                        height: 20
                                                    }}
                                                >
                                                    <CheckCircleIcon fontSize="small" /> <Box width={5} />
                                                    <Typography variant="h5">{row.status}</Typography>
                                                </Avatar>
                                            ) : (
                                                ''
                                            )}
                                            {row.status === 'open' ? (
                                                <Avatar
                                                    variant="rounded"
                                                    sx={{
                                                        ...theme.typography.commonAvatar,
                                                        ...theme.typography.largeAvatar,
                                                        backgroundColor: theme.palette.error.light,
                                                        color: theme.palette.error.dark,
                                                        width: 85,
                                                        height: 20
                                                    }}
                                                >
                                                    <DangerousRoundedIcon fontSize="small" />
                                                    <Box width={5} /> <Typography variant="h5">{row.status}</Typography>
                                                </Avatar>
                                            ) : (
                                                ''
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 100, 150, { label: 'All', value: -1 }]}
                    component="div"
                    count={orderInfo.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default ResolvedIssues;
