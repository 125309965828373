import { useState, useEffect } from 'react';
import { Collapse, message, Select, DatePicker, Button, Progress, Switch, Spin, Tag } from 'antd';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import CohortGraph from 'react-cohort-graph';
import { CohortBasic } from 'cohort-graph-react';
import { toast } from 'react-toastify';
import { decrypt } from '../../utils/encrypt';
import dayjs from 'dayjs';
import { format } from 'date-fns';
import { interpolateRgb } from 'd3-interpolate';
import ApiUrl from '../../network';

function parsePercentage(value) {
    if (typeof value[0] === 'string' && value[0].endsWith('%')) {
        return parseFloat(value[0].slice(0, -1));
    }
    // If the value is not a string with "%" symbol, try parsing it as a number
    const parsedValue = parseFloat(value[0]);
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(parsedValue)) {
        return parsedValue;
    }
    return value;
}

function generateHeatmapColor(value, maxValue) {
    // Define your color gradients here
    const greenGradient = ['#FFFFFF', '#E6F7E6', '#E6F7E6', '#D0E7D8', '#96D3A9', '#9BD4AB', '#80D980', '#54B56E', '#44B062', '#34A854'];

    // Parse percentage value if it's a string with "%" symbol
    value = parsePercentage(value);

    // Check if maxValue is finite and greater than 0
    // eslint-disable-next-line no-restricted-globals
    if (!isFinite(maxValue) || maxValue <= 0) {
        return greenGradient[0]; // Return the first color from the green gradient if maxValue is invalid
    }

    // Calculate the index of the color in the gradient based on the value
    const index = Math.min(Math.floor((value / maxValue) * greenGradient.length), greenGradient.length - 1);

    // Interpolate between green and blue gradients based on the value's position in the range
    const interpolatedColor = interpolateRgb(greenGradient[index])(value / maxValue);

    return interpolatedColor;
}

export const CohortAnalysis = () => {
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const initialState = {
        start_date: '',
        end_date: '',
        country: '',
        metric: '',
        time_frame: ''
    };
    const { RangePicker } = DatePicker;
    const items = [{ key: '1', label: 'Filters' }];
    const [cohortData, setCohortData] = useState({});
    const [countries, setCountries] = useState([]);
    const [filter, setFilter] = useState(initialState);
    const [gvmAverage, setGvmAverage] = useState(0);
    const [error, setError] = useState('');
    const retentionAverage = gvmAverage;

    const [loading, setLoading] = useState(false);

    const fetchCohortData = async (data) => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            let url = 'https://analysis.garagemobility.com/cohort_retention_analysis';

            if (data) {
                if (data.start_date && data.end_date) {
                    urlParams.set('start_date', data.start_date);
                    urlParams.set('end_date', data.end_date);
                }
                if (data.time_frame) urlParams.set('time_frame', data.time_frame);
                if (data.metric) urlParams.set('metric', data.metric);
                if (data.country) urlParams.set('country', data.country);
            } else {
                const savedParamsString = localStorage.getItem('saveParamsCohort');
                let savedParams;

                try {
                    savedParams = savedParamsString ? JSON.parse(savedParamsString) : {};
                } catch (error) {
                    savedParams = {};
                }

                if (savedParams.start_date && savedParams.end_date) {
                    urlParams.set('start_date', savedParams.start_date);
                    urlParams.set('end_date', savedParams.end_date);
                }
                if (savedParams.time_frame) urlParams.set('time_frame', savedParams.time_frame);
                if (savedParams.metric) urlParams.set('metric', savedParams.metric);
                if (savedParams.country) urlParams.set('country', savedParams.country);
            }
            if (urlParams.toString()) url = `https://analysis.garagemobility.com/cohort_retention_analysis?${urlParams.toString()}`;

            const response = await fetch(url, {
                method: 'GET'
            });

            const responseData = await response.json();
            setLoading(false);

            if (responseData?.data && responseData?.data[0]?.error) {
                setError(responseData?.data[0]?.error);
                setLoading(false);
                return { success: false, error: responseData?.data[0].error };
            }

            // Destructure data and create a new object for months and their percentages
            if (responseData?.data && responseData.data.length > 0) {
                const {
                    data: [
                        {
                            data: { ...monthlyData }
                        }
                    ]
                } = responseData;

                // Extract "Average" values from each "monthly" section
                // Extract "Average" values from each "monthly" section and convert to numeric values
                const numericMeanValues = Object.keys(monthlyData).reduce((acc, key) => {
                    const averagePercentage = monthlyData[key].Average;

                    if (typeof averagePercentage === 'string') {
                        // Remove '%' sign and convert to a numeric value
                        acc[key] = parseFloat(averagePercentage.replace('%', ''));
                    } else {
                        acc[key] = null;
                    }

                    return acc;
                }, {});

                // Filter out null values
                const validNumericMeanValues = Object.keys(numericMeanValues)
                    .filter((key) => numericMeanValues[key] !== null)
                    .map((key) => numericMeanValues[key]);

                // Calculate the mean of the valid numeric "Average" values
                const meanOfAverages =
                    validNumericMeanValues.length > 0
                        ? validNumericMeanValues.reduce((sum, value) => sum + value, 0) / validNumericMeanValues.length
                        : null;

                setGvmAverage(meanOfAverages?.toFixed(2));

                const months = {};
                Object.keys(monthlyData).forEach((key) => {
                    months[key] = Object.keys(monthlyData[key]).reduce((acc, month) => {
                        if (monthlyData[key][month] !== null) {
                            acc[month] = [monthlyData[key][month]];
                        }
                        return acc;
                    }, {});
                });
                setLoading(false);
                setCohortData(months);
                return { success: true };
            }
            setLoading(false);
            return { success: false };
        } catch (e) {
            setError(e.message);
            setLoading(false);
        }
        return { success: false };
    };
    const fetchCountries = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(`${ApiUrl.BaseUrl}/country/activecountry`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error('Something went wrong');
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        fetchCohortData();
        fetchCountries();
    }, []);

    const countryOption = countries?.map((result) => ({
        value: result.name,
        label: result.name
    }));

    const saveFilterToLocalStorage = (filterData) => {
        const savedParamsString = localStorage.getItem('saveParamsCohort');
        let savedParams;

        try {
            savedParams = savedParamsString ? JSON.parse(savedParamsString) : {};
        } catch (error) {
            savedParams = {};
        }

        const updatedParams = { ...savedParams, ...filterData };
        localStorage.setItem('saveParamsCohort', JSON.stringify(updatedParams));
    };

    const handleDateChange = (date, dateString) => {
        setFilter((prevFilter) => ({ ...prevFilter, start_date: dateString[0], end_date: dateString[1] }));
        saveFilterToLocalStorage({ start_date: dateString[0], end_date: dateString[1] });
    };
    const handleCountryChange = (value) => {
        setFilter((prevFilter) => ({ ...prevFilter, country: value }));
        saveFilterToLocalStorage({ country: value });
    };

    const handleCategoryChange = (value) => {
        setFilter((prevFilter) => ({ ...prevFilter, metric: value }));
        saveFilterToLocalStorage({ metric: value });
    };
    const handleCohortTimeFrameChange = (value) => {
        setFilter((prevFilter) => ({ ...prevFilter, time_frame: value }));
        saveFilterToLocalStorage({ time_frame: value });
    };

    let maxValue = 0;
    if (Object.keys(cohortData).length > 0) {
        const allValues = Object.values(cohortData).flatMap((cohort) => Object.values(cohort).flatMap((value) => parsePercentage(value)));

        // Filter valid percentage values
        // eslint-disable-next-line no-restricted-globals
        const percentageValues = allValues.filter((value) => !isNaN(value) && value >= 0 && value <= 100);

        // Determine the maximum value
        if (percentageValues.length > 0) {
            maxValue = Math.max(...percentageValues);
        }
    }

    return (
        <div>
            <div className="header">
                <h1>Cohort analysis tracker</h1>
                <p>Access information from all cohorts in one place</p>
            </div>
            <div className="content">
                <Collapse accordion>
                    {items.map((item) => (
                        <Collapse.Panel header={item.label} key={item.key}>
                            <div>
                                <div className="d-flex justify-content-between filterHeaders">
                                    <Select
                                        className="flex-grow-1 ml-2 mr-2 mb-2 rangePicker"
                                        showSearch
                                        allowClear
                                        placeholder="Select time period"
                                        options={[
                                            { label: 'weekly', value: 'weekly' },
                                            { label: 'biweekly', value: 'biweekly' },
                                            { label: 'monthly', value: 'monthly' },
                                            { label: 'quarterly', value: 'quarterly' },
                                            { label: ' Last 3 months ', value: 'last_3_months' },
                                            { label: ' Last 6 months ', value: 'last_6_months' },
                                            { label: ' Last 12 months ', value: 'last_12_months' }
                                        ]}
                                        style={{ width: '100%' }}
                                        onChange={handleCohortTimeFrameChange}
                                        value={
                                            filter.time_frame
                                                ? filter.time_frame
                                                : localStorage.getItem('saveParamsCohort')
                                                ? JSON.parse(localStorage.getItem('saveParamsCohort')).time_frame
                                                : null
                                        }
                                    />
                                    <Select
                                        className="flex-grow-1 ml-2 mr-2 mb-2 rangePicker"
                                        showSearch
                                        allowClear
                                        placeholder="Select a metric"
                                        options={[
                                            { label: ' Retention ', value: 'retention' },
                                            { label: ' GMV  ', value: 'gmv' },
                                            { label: ' Revenue  ', value: 'revenue' }
                                        ]}
                                        style={{ width: '100%' }}
                                        onChange={handleCategoryChange}
                                        value={
                                            filter.metric
                                                ? filter.metric
                                                : localStorage.getItem('saveParamsCohort')
                                                ? JSON.parse(localStorage.getItem('saveParamsCohort')).metric
                                                : null
                                        }
                                    />
                                    <RangePicker
                                        className="flex-grow-1 ml-2 mr-2 mb-2 rangePicker"
                                        placeholder={['Start date', 'End date']}
                                        style={{ width: '100%' }}
                                        format="YYYY-MM-DD"
                                        picker="date"
                                        presets={[
                                            {
                                                label: 'Last 7 days',
                                                value: [dayjs().subtract(7, 'day'), dayjs()]
                                            },
                                            {
                                                label: 'Last 30 days',
                                                value: [dayjs().subtract(30, 'day'), dayjs()]
                                            },
                                            {
                                                label: 'Last 90 days',
                                                value: [dayjs().subtract(90, 'day'), dayjs()]
                                            },
                                            {
                                                label: 'Last 180 days',
                                                value: [dayjs().subtract(180, 'day'), dayjs()]
                                            }
                                        ]}
                                        onChange={handleDateChange}
                                        value={
                                            filter.start_date && filter.end_date
                                                ? [dayjs(filter.start_date, 'YYYY-MM-DD'), dayjs(filter.end_date, 'YYYY-MM-DD')]
                                                : localStorage.getItem('saveParamsCohort') &&
                                                  JSON.parse(localStorage.getItem('saveParamsCohort')).start_date &&
                                                  JSON.parse(localStorage.getItem('saveParamsCohort')).end_date
                                                ? [
                                                      dayjs(JSON.parse(localStorage.getItem('saveParamsCohort')).start_date, 'YYYY-MM-DD'),
                                                      dayjs(JSON.parse(localStorage.getItem('saveParamsCohort')).end_date, 'YYYY-MM-DD')
                                                  ]
                                                : null
                                        }
                                    />
                                    <Select
                                        className="flex-grow-1 ml-2 mr-2 mb-2 rangePicker"
                                        showSearch
                                        allowClear
                                        placeholder="Select a country"
                                        style={{ width: '100%' }}
                                        options={countryOption}
                                        onChange={handleCountryChange}
                                        value={
                                            filter.country
                                                ? filter.country
                                                : localStorage.getItem('saveParamsCohort')
                                                ? JSON.parse(localStorage.getItem('saveParamsCohort')).country
                                                : null
                                        }
                                    />
                                </div>
                                <div className="d-flex ml-2 filterHeadersBtn">
                                    <Button
                                        loading={loading}
                                        className="mr-2 d-flex align-items-center mb-2 btn"
                                        style={{ backgroundColor: '#FF740F', color: '#fff' }}
                                        type="primary"
                                        onClick={() => fetchCohortData(filter)}
                                    >
                                        Apply filters
                                    </Button>
                                    <Button
                                        style={{ backgroundColor: '#fff', color: '#FF740F', borderColor: '#FF740F' }}
                                        className="d-flex align-items-center mb-2 btn"
                                        type="primary"
                                        onClick={() => {
                                            localStorage.removeItem('saveParamsCohort');
                                            setFilter({});
                                            saveFilterToLocalStorage({});
                                            fetchCohortData();
                                        }}
                                    >
                                        Clear filters
                                    </Button>
                                </div>
                            </div>
                        </Collapse.Panel>
                    ))}
                </Collapse>

                <div className="mt-4 mb-4 overviewData">
                    <div
                        className="flex-grow-1 mb-2"
                        style={{ backgroundColor: '#EDF1FF', borderRadius: '10px', padding: '20px', width: '100%', height: '150px' }}
                    >
                        <div className="inforHeader">
                            <span className="noteIcon">
                                <ExclamationCircleOutlined />
                            </span>{' '}
                            <p className="ml-2" style={{ fontSize: '16px', fontWeight: 'bold', color: '#546974' }}>
                                Please note
                            </p>
                        </div>
                        <p className="mt-4" style={{ fontSize: '12px' }}>
                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                            Each column represents a month following the cohort's creation. The values represents the volume of
                            <span>
                                {(filter && filter.metric) ||
                                    (localStorage.getItem('saveParamsCohort') &&
                                        JSON.parse(localStorage.getItem('saveParamsCohort')).metric)}
                            </span>
                            for that month
                        </p>
                    </div>
                    {(filter && filter.metric === 'retention') ||
                    (localStorage.getItem('saveParamsCohort') &&
                        JSON.parse(localStorage.getItem('saveParamsCohort')).metric === 'retention') ? (
                        <div
                            className="flex-grow-1 mr-2 mb-2 cardGmv"
                            style={{ backgroundColor: '#FFEBED', borderRadius: '10px', padding: '20px', width: '100%', height: '150px' }}
                        >
                            <div className="d-flex align-items-center">
                                <Progress type="circle" percent={retentionAverage} size={30} />
                                <p className="ml-2" style={{ fontSize: '16px', fontWeight: 'bold', color: '#546974' }}>
                                    Average retention {gvmAverage}
                                </p>
                            </div>
                        </div>
                    ) : null}
                    {(filter && (filter.metric === 'gmv' || filter.metric === 'revenue')) ||
                    (localStorage.getItem('saveParamsCohort') &&
                        (JSON.parse(localStorage.getItem('saveParamsCohort')).metric === 'gmv' ||
                            JSON.parse(localStorage.getItem('saveParamsCohort')).metric === 'revenue')) ? (
                        <div
                            className="flex-grow-1 d-flex justify-content-center align-items-center mb-2 ml-2 cardGmv"
                            style={{ backgroundColor: '#FEF3E7', borderRadius: '10px', padding: '20px', width: '100%', height: '150px' }}
                        >
                            <div className="text-center">
                                <p className="ml-2" style={{ fontSize: '16px' }}>
                                    Average{' '}
                                    {(filter && filter.metric) ||
                                        (localStorage.getItem('saveParamsCohort') &&
                                            JSON.parse(localStorage.getItem('saveParamsCohort')).metric)}{' '}
                                    retention
                                </p>
                                <p className="ml-2" style={{ fontSize: '24px', fontWeight: 'bold', color: '#546974' }}>
                                    {gvmAverage === 0 || gvmAverage === null || gvmAverage === undefined ? (
                                        <span>0</span>
                                    ) : (
                                        <span>{gvmAverage}</span>
                                    )}
                                </p>
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="mt-4" style={{ backgroundColor: '#fff', borderRadius: '10px' }}>
                    {Object.keys(cohortData).length > 0 && (
                        <div className="d-flex justify-content-between align-items-center p-4">
                            <p className="text-center flex-grow-1" style={{ color: '#546974', fontSize: '18px' }}>
                                Number of Returned Customers over Subsequent Months
                            </p>
                            <div className="d-flex align-items-center">
                                <span className="mr-2">View in %</span>
                                <Switch checked disabled={filter.metric === 'retention'} />
                            </div>
                        </div>
                    )}

                    <div className="d-flex justify-content-center align-items-center">
                        {loading ? (
                            <div style={{ padding: '50px' }}>
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> <span>Loading... Please wait</span>
                            </div>
                        ) : Object.keys(cohortData).length > 0 ? (
                            <div className="table-responsive">
                                <table className="cohort-table" style={{ height: '300px' }}>
                                    <thead>
                                        <tr>
                                            <th className="th">Date</th>
                                            {Object.keys(cohortData).map((cohort) => (
                                                <th className="th" key={cohort + Math.random() * 1000}>
                                                    {cohort}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(cohortData).length > 0 &&
                                            Object.keys(Object.values(cohortData)[0]).map((month, Total) => (
                                                <tr key={month + Math.random() * 1000} className="tr">
                                                    <td key={month + Math.random() * 1000} className="monthCol">
                                                        {month}
                                                    </td>
                                                    {Object.values(cohortData).map((cohort) => (
                                                        <td
                                                            className="col"
                                                            key={cohort + Math.random() * 1000}
                                                            style={{
                                                                backgroundColor: generateHeatmapColor(cohort[month] ?? 0, maxValue)
                                                            }}
                                                        >
                                                            {cohort[month] ?? 0}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div style={{ padding: '50px' }}>
                                <Tag color="red" style={{ fontSize: '18px' }}>
                                    {error ? <div>{error}</div> : <span>No cohort data available.</span>}
                                </Tag>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CohortAnalysis;
