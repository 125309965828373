import { Input, Button, Table, Select, DatePicker, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { EditOutlined, EyeInvisibleOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { decrypt } from '../../../utils/encrypt';
import { toast } from 'react-toastify';
import ApiUrl from '../../../network';

export const Index = () => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const userRole = localStorage.getItem('userRole');
    const [batches, setBatches] = useState([]);
    const [search, setSearch] = useState('');
    const [date, setDate] = useState('');
    const { RangePicker } = DatePicker;
    const [loading, setLoading] = useState(false);
    const [batchStatus, setBatchStatus] = useState([]);
    const [countries, setCountries] = useState([]);
    const [filteredBatches, setFilteredBatches] = useState([]);

    const fetchOrders = async (data) => {
        setLoading(true);
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const urlParams = new URLSearchParams(window.location.search);
            let url = '';

            if (userRole === 'Sales' || userRole === 'CustomerSuccess') {
                url = `${ApiUrl.BaseUrl}/waybill/userwaybill`;
            } else {
                url = `${ApiUrl.BaseUrl}/waybill/allwaybill`;
            }

            if (data) {
                if (data.startDate) urlParams.append('startDate', data.startDate);
                if (data.endDate) urlParams.append('endDate', data.endDate);
                if (data.orderType) urlParams.append('orderType', data.orderType);
                if (data.country) urlParams.append('country', data.country);
                if (data.batchNo) urlParams.append('batchNo', data.batchNo);
                if (data.orderNo) urlParams.append('orderNo', data.orderNo);
                if (data.batchStatus) urlParams.append('batchStatus', data.batchStatus);
                if (data.limit) urlParams.append('limit', data.limit);
            }
            url = `${url}?${urlParams.toString()}`;

            await fetch(url, {
                method: 'GET',
                headers: config.headers
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const batchs = decrypt(JSON.stringify(data.data), keyResult, ivResult);
                        setBatches(JSON.parse(batchs));
                        setLoading(false);
                    } else {
                        toast.error(data.message);
                        setLoading(false);
                    }
                });
        } catch (e) {
            toast.error(e.message);
            setLoading(false);
        }
    };
    const getBatchStatus = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/orderbatch/batchstatuses`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setBatchStatus(data.data);
                } else {
                    toast.error(data.message);
                }
            });
    };
    const fetchCountries = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(`${ApiUrl.BaseUrl}/country/activecountry`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error('Something went wrong');
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        fetchOrders();
        getBatchStatus();
        fetchCountries();
    }, []);
    useEffect(() => {
        if (Array.isArray(batches)) {
            const filteredData = batches.filter((batch) => {
                const searchLowerCase = search.toLowerCase();
                return (
                    batch.orderInfo?.includes(searchLowerCase) ||
                    batch.country?.includes(searchLowerCase) ||
                    batch.orderCompressed?.includes(searchLowerCase) ||
                    batch.batchStatusId?.name?.toLowerCase().includes(searchLowerCase) ||
                    `${batch.userId.firstName} ${batch.userId.lastName}`.toLowerCase().includes(searchLowerCase) ||
                    new Date(batch.batchDate).toLocaleDateString('en-US', options).includes(searchLowerCase)
                );
            });

            const orderList = filteredData?.map((order) => {
                return {
                    key: order._id,
                    batchNo: order.batchNo,
                    orderInfo: order.orderInfo,
                    orderNo: order.orderNo,
                    orderType: order.orderType,
                    country: order.country,
                    orderCompressed: order.orderCompressed,
                    status: order.batchStatusId,
                    user: `${order?.userId?.firstName} ${order?.userId?.lastName}`,
                    rmaName: order.rmaName,
                    batchDate: new Date(order.batchDate).toLocaleDateString('en-US', options)
                };
            });

            setFilteredBatches(orderList);
        }
    }, [batches, search]);

    const batchStatusOptions = batchStatus.map((item) => {
        return {
            label: item.name,
            value: item._id
        };
    });
    const countryOption = countries?.map((result) => ({
        value: result.name,
        label: result.name
    }));
    const downloadWaybill = async (record) => {
        console.log(record, 'record');
    };

    const checkActionHandler = (record) => {
        return (
            <div>
                <Button
                    onClick={() => (window.location.href = `/waybill/print/${record.key}`)}
                    text="More Details"
                    className="mb-2"
                    style={{
                        color: '#FF740F',
                        border: '1px solid #FF740F'
                    }}
                >
                    Download
                </Button>
                <Button
                    onClick={() => (window.location.href = `/waybill/waybill-details/${record.key}`)}
                    text="More Details"
                    style={{
                        color: '#FF740F',
                        border: '1px solid #FF740F'
                    }}
                >
                    View details
                </Button>
                {userRole === 'Admin' || userRole === 'fulfillment' || userRole === 'DataEntry' || userRole === 'Inventory' ? (
                    <Button
                        onClick={() => (window.location.href = `/edit-waybill/${record.key}`)}
                        className="mt-2"
                        style={{
                            color: '#FF740F',
                            border: '1px solid #FF740F'
                        }}
                    >
                        Edit Waybill
                    </Button>
                ) : null}
            </div>
        );
    };

    const columns = [
        {
            title: 'Batch Date',
            dataIndex: 'batchDate',
            key: 'batchDate'
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country'
        },
        {
            title: 'Order No',
            dataIndex: 'orderNo',
            key: 'orderNo'
        },
        {
            title: 'RMA',
            dataIndex: 'rmaName',
            key: 'rmaName'
        },
        {
            title: 'Order Type',
            dataIndex: 'orderType',
            key: 'orderType'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => checkActionHandler(record)
        }
    ];
    const dateHanlder = (date, dateString) => {
        setDate(date);
        const data = {
            startDate: dateString[0],
            endDate: dateString[1]
        };
        fetchOrders(data);
    };

    return (
        <div>
            <h1 style={{ fontSize: '30px' }}>{userRole !== 'Admin' ? `Hello, ${userRole}` : 'Hello, Inventory Manager'}</h1>
            <p>How are you doing today?</p>
            <div className="batchFilters bg-white p-2 mb-2">
                <RangePicker
                    size="large"
                    format="YYYY-MM-DD"
                    className="datePicker filterInput"
                    onChange={(date) => {
                        fetchOrders({ startDate: date[0], endDate: date[1] });
                    }}
                />
                {userRole === 'Admin' && (
                    <div className="batchFilters">
                        <div>
                            <Select
                                showSearch
                                allowClear
                                size="large"
                                placeholder="Filter By Country"
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                className=" p-2 filterInput"
                                options={countryOption}
                                onChange={(value) => {
                                    fetchOrders({ country: value });
                                }}
                            />
                        </div>
                        <div>
                            <Select
                                showSearch
                                allowClear
                                size="large"
                                placeholder="Filter By Status"
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                className="p-2 filterInput"
                                options={batchStatusOptions}
                                onChange={(value) => {
                                    fetchOrders({ batchStatus: value });
                                }}
                            />
                        </div>
                        <div>
                            <Select
                                showSearch
                                allowClear
                                size="large"
                                placeholder="Filter By Order Type"
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                className="p-2 filterInput"
                                options={[
                                    {
                                        label: 'Sales Drive',
                                        value: 'SalesDrive'
                                    },
                                    {
                                        label: 'Regular',
                                        value: 'Regular'
                                    }
                                ]}
                                onChange={(value) => {
                                    fetchOrders({ orderType: value });
                                }}
                            />
                        </div>
                        <div>
                            <Select
                                showSearch
                                allowClear
                                size="large"
                                placeholder="Filter By Order Limit"
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                className=" p-2 filterInput"
                                options={[
                                    {
                                        label: '100',
                                        value: '100'
                                    },
                                    {
                                        label: '200',
                                        value: '200'
                                    },
                                    {
                                        label: '300',
                                        value: '300'
                                    },
                                    {
                                        label: '400',
                                        value: '400'
                                    },
                                    {
                                        label: '500',
                                        value: '500'
                                    }
                                ]}
                                onChange={(value) => {
                                    fetchOrders({ limit: value });
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="bg-white p-2">
                {loading ? (
                    <div>
                        <Skeleton style={{ padding: '10px 0' }} active />
                    </div>
                ) : (
                    <Table columns={columns} scroll={{ x: 'calc(700px + 50%)', y: 480 }} dataSource={filteredBatches} />
                )}
            </div>
        </div>
    );
};

export default Index;
