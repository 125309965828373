import React, { useEffect, useState } from 'react';
import ApiUrl from 'network';
import { decrypt } from '../../utils/encrypt';
import { DatePicker, Select, Space, Checkbox, Table, Spin, Input, antIcon } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import Search from 'assets/images/search-normal.svg';
import ProductImage from 'assets/images/shell_oil-removebg-preview1.png';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { LoadingOutlined } from '@ant-design/icons';
import { EmptyState } from 'components/EmptyState/EmptyState';

const Products = () => {
    const [loading, setLoading] = useState(false);
    const [parts, setParts] = useState([]);
    const [allParts, setAllParts] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [accumulatedFilters, setAccumulatedFilters] = useState({});
    const phone = JSON.parse(localStorage.getItem('retailerNo'));

    console.log(phone);

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54
            }}
            spin
        />
    );

    const queryParts = async () => {
        try {
            setLoading(true);
            await fetch(`${ApiUrl.BaseUrl}/whatsapp/parts?phone=${phone}`)
                .then((data) => data.json())
                .then((data) => {
                    setAllParts(data.parts);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const partOptions = allParts?.map((item) => {
        return { label: item?.sku, value: item?._id };
    });

    const getParts = async () => {
        try {
            setLoading(true);
            await fetch(`${ApiUrl.BaseUrl}/whatsapp/parts?phone=${phone}`)
                .then((data) => data.json())
                .then((data) => {
                    setCurrency(data.currency);
                    const filteredpart = data.parts?.filter((sku) => {
                        return sku?._id === accumulatedFilters?.part;
                    });

                    if (filteredpart.length > 0) {
                        setParts(filteredpart);
                    } else {
                        setParts(data.parts);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getParts();
        queryParts();
    }, [accumulatedFilters]);

    // filter part
    const filterParts = (value) => {
        const data = {
            part: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
    };

    localStorage.setItem('partCurrency', JSON.stringify(currency));

    return (
        <div className="products-page">
            <Box
                sx={{
                    width: ' 90%',
                    margin: '8px 0 0 0px'
                }}
            >
                <Select
                    style={{
                        width: '100%',
                        borderRadius: '12px'
                    }}
                    size="large"
                    variant="filled"
                    placeholder="Search here"
                    options={partOptions}
                    onChange={(value) => filterParts(value)}
                    filterOption={(inputValue, option) => {
                        if (typeof option.label === 'string') {
                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        }

                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                    }}
                    showSearch
                    allowClear
                />
            </Box>

            {loading ? (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '4000',
                        color: '#0a2938'
                    }}
                >
                    {antIcon}
                </Box>
            ) : parts.length > 0 ? (
                <Box
                    sx={{
                        width: ' 100%',
                        backgroundColor: '#fff',
                        margin: '8px 0 0 0px',
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gap: '10px',
                        padding: '4px 16px 50px'
                    }}
                    className="product-display"
                >
                    {parts.map((part) => (
                        <Link to={`/whatsapporder/product/${part?._id}`} className="product-container" key={part?._id}>
                            <div className="product-container-image">
                                {part?.partsImage ? (
                                    <img className="product-img" src={part?.partsImage} alt="product" />
                                ) : (
                                    <BrokenImageIcon
                                        className="product-img"
                                        sx={{
                                            color: '#999'
                                        }}
                                    />
                                )}
                            </div>
                            <div className="product-img-details">
                                <p>{part?.sku}</p>
                                <p>
                                    {currency} {part?.garagePrice}
                                </p>
                            </div>
                        </Link>
                    ))}
                </Box>
            ) : (
                <Box
                    style={{
                        margin: '100px auto 0',
                        position: 'relative',
                        backgroundColor: '#FFFFFF',
                        height: '496px',
                        borderRadius: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <EmptyState height="204px" padding="5px" text="No results " />
                </Box>
            )}
        </div>
    );
};

export default Products;
