import styled from 'styled-components';

const Wrapper = styled.div`
    font-family: 'Roboto';
    .thank-you {
        margin: 2rem;
        text-align: center;

        p {
            line-height: 1.5;
            font-size: 1.2rem;
            font-weight: 900;
            color: #222;
            font-style: italic;
        }
    }
`;

const FootNote = () => {
    return (
        <Wrapper>
            <div className="thank-you">
                <p>Thank you for doing business with us</p>
                <p>Garage: Your Preferred and Reliable Distributing Auto Parts Partner</p>
            </div>
        </Wrapper>
    );
};
export default FootNote;
