/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import ApiUrl from 'network';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import nologo from 'assets/images/nologo.png';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import { FormHelperText } from '@mui/material';

const Input = styled('input')({
    display: 'none'
});

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    }
}));

const EditRetailer = () => {
    const history = useNavigate();
    const classes = useStyles();
    const scriptedRef = useScriptRef();
    const [partnerName, setpartnerName] = useState('');
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [shopName, setshopName] = useState('');
    const [partnerContact, setpartnerContact] = useState('');
    const [partnerContact2, setpartnerContact2] = useState('');
    const [partnerContact3, setpartnerContact3] = useState('');
    const [stageId, setstageId] = useState('');
    const [salesTeamId, setsalesTeamId] = useState('');
    const [customerSuccessTeamId, setcustomerSuccessTeamId] = useState('');
    const [locationLat, setlocationLat] = useState('');
    const [locationLng, setlocationLng] = useState('');
    const [dateOnboarded, setdateOnboarded] = useState(new Date());
    const [partnerType, setpartnerType] = useState('');
    const [partListedCompleted, setpartListedCompleted] = useState('');
    const [datefirstPartReceived, setdatefirstPartReceived] = useState(new Date());
    const [kycCompleted, setkycCompleted] = useState('');
    const [signupDate, setsignupDate] = useState(new Date());
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [rating, setrating] = useState('');
    const [stageInfo, setStage] = useState([]);
    const [salesTeamInfo, setSalesTeam] = useState([]);
    const [customerSuccessTeam, setCustomerSuccessTeam] = useState([]);
    const [location, setlocation] = useState('');
    const [notes, setnotes] = useState('');
    const [gender, setgender] = useState('');
    const [salesnotes, setsalesnotes] = useState('');
    const [loading3, setLoading3] = useState(false);
    const [idCardAttached, setidCardAttached] = useState(false);
    const [openingTime, setopeningTime] = useState('');
    const [closingTime, setclosingTime] = useState('');
    const [creditType, setcreditType] = useState('');
    const [shopsdata, setshopsdata] = useState([]);
    const [route, setroute] = useState('');
    const [routeLatitude, setrouteLatitude] = useState('');
    const [routeLongitude, setrouteLongitude] = useState('');
    const [pod, setpod] = useState('');
    const [poddata, setpoddata] = useState([]);

    const typeData = [
        {
            _id: 1,
            name: 'EPL'
        },
        {
            _id: 1,
            name: 'NonEPL'
        }
    ];

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    const retrieveAlPods = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            const url = `${ApiUrl.BaseUrl}/salesdrivepod/podinfo`;
            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setpoddata(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAllStage = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            // setLoading(true);
            fetch(ApiUrl.AllStageUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setStage(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    const retrieveAllCustomerSuccessTeam = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(ApiUrl.AllActiveCustomerSuccess, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCustomerSuccessTeam(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {});
        } catch (e) {
            console.log(e);
        }
    };

    const retrieveAlLsalesTeam = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(ApiUrl.AllActiveSalesTeam, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setSalesTeam(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {});
        } catch (e) {
            console.log(e);
        }
    };

    const handleRatingChange = (event) => {
        setrating(event.target.value);
    };

    const handleGenderChange = (event) => {
        setgender(event.target.value);
    };

    const { id } = useParams();

    useEffect(() => {
        retrieveAllStage();
        retrieveAlLsalesTeam();
        retrieveAlPods();
        retrieveAllCustomerSuccessTeam();
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.ViewAllChannelPartnerURL + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setpartnerName(result?.data?.partnerName ?? '');
                    setfirstName(result?.data?.firstName ?? '');
                    setlastName(result?.data?.lastName ?? '');
                    setshopName(result?.data?.shopName ?? '');
                    setpartnerContact(result?.data?.partnerContact ?? '');
                    setstageId(result?.data?.stageId ?? '');
                    setsignupDate(result?.data?.signupDate ?? '');
                    setStatus(result?.data?.status ?? '');
                    setsalesTeamId(result?.data?.salesTeamId ?? '');
                    setcustomerSuccessTeamId(result?.data?.customerSuccessTeamId ?? '');
                    setlocationLat(result?.data?.locationLat ?? '');
                    setlocationLng(result?.data?.locationLng ?? '');
                    setdateOnboarded(result?.data?.dateOnboarded ?? '');
                    setpartnerType(result?.data?.partnerType ?? '');
                    setpartListedCompleted(result?.data?.partListedCompleted ?? '');
                    setdatefirstPartReceived(result?.data?.datefirstPartReceived ?? '');
                    setkycCompleted(result?.data?.kycCompleted ?? '');
                    setnotes(result?.data?.notes ?? '');
                    setrating(result?.data?.rating ?? '');
                    setgender(result?.data?.gender ?? '');
                    setlocation(result?.data?.location);
                    setidCardAttached(result?.data?.idCardAttached ?? '');
                    setsalesnotes(result?.data?.salesnotes ?? '');
                    setopeningTime(result?.data?.openingTime ?? '');
                    setclosingTime(result?.data?.closingTime ?? '');
                    setshopsdata(result?.data?.shopsdata ?? []);
                    setpartnerContact2(result?.data?.partnerContact2 ?? '');
                    setpartnerContact3(result?.data?.partnerContact3 ?? '');
                    setcreditType(result?.data?.creditType ?? '');
                    setpod(result?.data?.pod ?? '');
                    setroute(result?.data?.route ?? '');
                    setrouteLatitude(result?.data?.routeLatitude ?? '');
                    setrouteLongitude(result?.data?.routeLongitude ?? '');

                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                    // setLoading(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading2(false);
            });
    }, [id]);

    const handleChange = (event) => {
        setStatus(event.target.value);
    };
    const handleTypeChange = (event) => {
        setcreditType(event.target.value);
    };
    const handleSignupChange = (newValue) => {
        setsignupDate(newValue);
    };
    const handleStageChange = (event) => {
        setstageId(event.target.value);
    };
    const handleFirstPartReceivedChange = (newValue) => {
        setdatefirstPartReceived(newValue);
    };
    const handleCustomerSuccessTeamChange = (event) => {
        setcustomerSuccessTeamId(event.target.value);
    };
    const handleSalesChange = (event) => {
        setsalesTeamId(event.target.value);
    };
    const handlePodChange = (event) => {
        setpod(event.target.value);
    };
    const handlePartnerTypeChange = (event) => {
        setpartnerType(event.target.value);
    };

    const handlePartListCollectedChange = (event) => {
        setpartListedCompleted(event.target.value);
    };
    const handleKycCompletedChange = (event) => {
        setkycCompleted(event.target.value);
    };

    const handleDateOnBoardedChange = (newValue) => {
        setdateOnboarded(newValue);
    };

    const selectedFile = (e) => {
        // setFile(e.target.files[0]);
        // console.log('file', e.target.files[0]);
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        formData.append('id', id);
        setLoading3(true);
        const userInfo = localStorage.getItem('userToken');
        axios
            .post(ApiUrl.UploadIDCardUrl, formData, {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => {
                // console.log('result', result.data.data);
                if (result.data.status === true) {
                    // toast.success(result.data.message);
                    // if (scriptedRef.current) {
                    // console.log('data', result.data.data);
                    setLoading3(false);
                    setidCardAttached(result?.data?.data ?? '');
                    // localStorage.setItem('photo', result?.data?.data ?? '');
                    // setTimeout(() => {
                    //     history('/fleetsettings');
                    //     // eslint-disable-next-line no-restricted-globals
                    //     location.reload();
                    // }, 3000);
                    // }
                } else {
                    toast.error(result.data);
                    setLoading3(false);
                }
            })
            .catch((err) => {
                toast.error(err);
                setLoading3(false);
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');

        const data = {
            id,
            firstName,
            lastName,
            shopName,
            partnerContact,
            locationLat,
            locationLng,
            stageId,
            dateOnboarded,
            partnerType,
            partListedCompleted,
            datefirstPartReceived,
            kycCompleted,
            salesTeamId,
            customerSuccessTeamId,
            signupDate,
            status,
            rating,
            notes,
            location,
            gender,
            salesnotes,
            openingTime,
            closingTime,
            partnerContact2,
            partnerContact3,
            creditType,
            pod,
            route,
            routeLatitude,
            routeLongitude
        };
        setLoading(true);
        fetch(ApiUrl.UpdateChannelPartnerURL, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    // window.location.href = '/roles';
                    toast.success(result.message);
                    setLoading(false);
                    setTimeout(() => {
                        if (scriptedRef.current) {
                            history('/retail');
                        }
                    }, 3000);
                } else {
                    toast.error(result.data);
                    // console.log(result);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <br />
                    <PageHeader title="Retail Partners" subTitle="Home | Configuration | Channel Partners | Retail" />
                    <br />
                    <MainCard title="Edit Retail">
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="partnerName"
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                            required
                                            id="partnerName"
                                            label="Partner Name"
                                            value={partnerName}
                                            onChange={(e) => setpartnerName(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="firstName"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            id="firstName"
                                            label="First Name"
                                            value={firstName}
                                            onChange={(e) => setfirstName(e.target.value)}
                                            autoFocus
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="lastName"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="lastName"
                                            label="Last Name"
                                            value={lastName}
                                            onChange={(e) => setlastName(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="shopName"
                                            variant="outlined"
                                            fullWidth
                                            id="shopName"
                                            label="Shop Name"
                                            value={shopName}
                                            onChange={(e) => setshopName(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="partnerContact"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            id="partnerContact"
                                            label="Phone"
                                            value={partnerContact}
                                            onChange={(e) => setpartnerContact(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="partnerContact2"
                                            variant="outlined"
                                            fullWidth
                                            id="partnerContact2"
                                            label="Phone 2"
                                            value={partnerContact2}
                                            onChange={(e) => setpartnerContact2(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="partnerContact3"
                                            variant="outlined"
                                            fullWidth
                                            id="partnerContact3"
                                            label="Phone"
                                            value={partnerContact3}
                                            onChange={(e) => setpartnerContact3(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={gender}
                                                label="Status"
                                                onChange={handleGenderChange}
                                            >
                                                <MenuItem value="Body">Select an option</MenuItem>
                                                <MenuItem value="Male">Male</MenuItem>
                                                <MenuItem value="Female">Female</MenuItem>
                                                <MenuItem value="Unknown">Unknown</MenuItem>
                                                <MenuItem value="PreferNotToAnswer">Prefer not to answer</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Choose Credit Type</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={creditType}
                                                label="Credit Type"
                                                onChange={handleTypeChange}
                                            >
                                                {typeData.map((attr) => (
                                                    <MenuItem key={attr.name} value={attr.name}>
                                                        {attr?.name ?? ''}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Sales Focal *</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={salesTeamId}
                                                required
                                                label="Sales Team"
                                                onChange={handleSalesChange}
                                            >
                                                {salesTeamInfo.map((sales) => (
                                                    <MenuItem key={sales._id} value={sales._id}>
                                                        {sales.name}
                                                        {sales?.firstName}
                                                        {sales?.lastName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Pod Name</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={pod}
                                                required
                                                label="Pod Name"
                                                onChange={handlePodChange}
                                            >
                                                {poddata.map((sales) => (
                                                    <MenuItem key={sales._id} value={sales._id}>
                                                        {sales?.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="route"
                                            variant="outlined"
                                            fullWidth
                                            id="route"
                                            label="Route Name"
                                            value={route}
                                            onChange={(e) => setroute(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />
                            <br />

                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="locationLat"
                                            variant="outlined"
                                            fullWidth
                                            id="locationLat"
                                            value={locationLat}
                                            label="Latitude"
                                            onChange={(e) => setlocationLat(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="locationLng"
                                            variant="outlined"
                                            fullWidth
                                            id="locationLng"
                                            value={locationLng}
                                            label="longitude"
                                            onChange={(e) => setlocationLng(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="location"
                                            variant="outlined"
                                            fullWidth
                                            id="location"
                                            value={location}
                                            label="Location"
                                            onChange={(e) => setlocation(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Customer Success Team *</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={customerSuccessTeamId}
                                                required
                                                label="Status"
                                                onChange={handleCustomerSuccessTeamChange}
                                            >
                                                {customerSuccessTeam.map((customer) => (
                                                    <MenuItem key={customer._id} value={customer._id}>
                                                        {customer?.name ?? ''}
                                                        {customer?.firstName ?? ''}
                                                        {customer?.lastName ?? ''}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />
                            <br />

                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Stage *</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={stageId}
                                                required
                                                label="Status"
                                                onChange={handleStageChange}
                                            >
                                                {stageInfo.map((stage) => (
                                                    <MenuItem key={stage._id} value={stage._id}>
                                                        {stage.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Rating</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={rating}
                                                label="Status"
                                                onChange={handleRatingChange}
                                            >
                                                <MenuItem value="Body">Select an option</MenuItem>
                                                <MenuItem value="High">High</MenuItem>
                                                <MenuItem value="Medium">Medium</MenuItem>
                                                <MenuItem value="Low">Low</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Partner Type</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={partnerType}
                                                label="Status"
                                                onChange={handlePartnerTypeChange}
                                            >
                                                <MenuItem value="Cash">Cash</MenuItem>
                                                <MenuItem value="Credit">Credit</MenuItem>
                                                <MenuItem value="CashCredit">Cash and Credit</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="routeLatitude"
                                            variant="outlined"
                                            fullWidth
                                            id="routeLatitude"
                                            label="Route Latitude"
                                            value={routeLatitude}
                                            onChange={(e) => setrouteLatitude(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="routeLongitude"
                                            variant="outlined"
                                            fullWidth
                                            id="routeLongitude"
                                            label="Route Longitude"
                                            value={routeLongitude}
                                            onChange={(e) => setrouteLongitude(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />
                            <br />

                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Part List Collected</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={partListedCompleted}
                                                label="Status"
                                                onChange={handlePartListCollectedChange}
                                            >
                                                <MenuItem value="Yes">Yes</MenuItem>
                                                <MenuItem value="No">No</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={3}>
                                                <DesktopDatePicker
                                                    label="Date Ist Part Received"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={datefirstPartReceived}
                                                    onChange={handleFirstPartReceivedChange}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">KYC Completed</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={kycCompleted}
                                                label="Status"
                                                onChange={handleKycCompletedChange}
                                            >
                                                <MenuItem value="Yes">Yes</MenuItem>
                                                <MenuItem value="No">No</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={3}>
                                                <DesktopDatePicker
                                                    label="Date Contacted"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={signupDate}
                                                    onChange={handleSignupChange}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />
                            <br />

                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={3}>
                                                <DesktopDatePicker
                                                    label="Date Onboarded"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={dateOnboarded}
                                                    onChange={handleDateOnBoardedChange}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={status}
                                                label="Status"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value="Active">Active</MenuItem>
                                                <MenuItem value="InActive">InActive</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={6}>
                                    <Box width={200}>
                                        <InputLabel id="demo-simple-select-label">Attach ID Card</InputLabel>
                                        <Stack direction="row" alignItems="center" spacing={4}>
                                            <Avatar variant="rounded" src={idCardAttached ?? nologo} sx={{ width: 100, height: 70 }} />

                                            <label htmlFor="contained-button-file">
                                                <Input
                                                    accept="image/*"
                                                    id="contained-button-file"
                                                    multiple
                                                    type="file"
                                                    onChange={selectedFile}
                                                />
                                                <Button variant="contained" component="span" disabled={loading3}>
                                                    {loading3 ? 'Uploading ...' : 'Upload'}
                                                </Button>
                                            </label>
                                        </Stack>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="openingTime"
                                            variant="outlined"
                                            fullWidth
                                            id="openingTime"
                                            label="Opening Time"
                                            value={openingTime}
                                            onChange={(e) => setopeningTime(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box width={180}>
                                        <TextField
                                            autoComplete="off"
                                            name="closingTime"
                                            variant="outlined"
                                            fullWidth
                                            id="closingTime"
                                            label="Closing Time"
                                            value={closingTime}
                                            onChange={(e) => setclosingTime(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Date
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Shop Name
                                                    </TableCell>

                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Location
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Latitude
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.tableHeaderCell}>
                                                        Longitude
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {shopsdata.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {toTimestamp(row?.shopdate)}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row?.shopname ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row?.shoplocation ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row?.shoplat ?? ''}
                                                        </TableCell>

                                                        <TableCell component="th" scope="row">
                                                            {row?.shoplng ?? ''}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <br />
                            <br />

                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TextField
                                        autoComplete="off"
                                        name="salesnotes"
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        value={salesnotes}
                                        fullWidth
                                        id="salesnotes"
                                        label="Sales Comment"
                                        onChange={(e) => setsalesnotes(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <br />
                            <br />
                            <Grid container spacing={2} justify="flex-start">
                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                    <TextField
                                        autoComplete="off"
                                        name="notes"
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        value={notes}
                                        fullWidth
                                        id="notes"
                                        label="Comment"
                                        onChange={(e) => setnotes(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <br />
                            <br />
                            <Box display="flex" justifyContent="space-between">
                                <Button component={Link} to="/retail" type="submit" variant="contained" color="error">
                                    Reset
                                </Button>

                                <Button type="submit" variant="contained" color="secondary">
                                    {loading ? 'Updating....' : 'Update'}
                                </Button>
                            </Box>
                        </form>
                    </MainCard>
                </>
            )}
        </>
    );
};

export default EditRetailer;
