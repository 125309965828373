import { Button, Result } from 'antd';

import { Link } from 'react-router-dom';

const NotFound = () => (
    <div className="mt-5">
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
                <Link to="/">
                    <Button variant="contained" style={{ background: '#ff740f', cursor: 'pointer', color: '#fff' }}>
                        Back home
                    </Button>
                </Link>
            }
        />
    </div>
);
export default NotFound;
