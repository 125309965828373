import React, { useEffect, useState } from 'react';
import { Button, Modal, message, Table, Tag, Upload, Select } from 'antd';
import { toast } from 'react-toastify';
import ApiUrl from '../../../network';
import { decrypt, encrypt } from '../../../utils/encrypt';
import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import normFile from '../../../imageToBucket';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const [loading, setLoading] = useState(false);
    const [bannerData, setBannerData] = useState([]);
    const [addBannerModal, setAddBannerModal] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [fileList, setFileList] = useState({});
    const { Dragger } = Upload;
    const [action, setAction] = useState('add');
    const [id, setId] = useState('');
    const [status, setStatus] = useState('');

    const fetchBannerData = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/pricing/location/pricingbanner`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                setBannerData(decryptedData);
                setLoading(false);
            } else {
                toast.error(result.message);
                setLoading(false);
            }
        } catch (e) {
            toast(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBannerData();
    }, []);

    const bannerSource = bannerData.map((item, index) => {
        return {
            key: item._id,
            image: item.image,
            country: item.country,
            status: item.status,
            createdBy: `${item.createdBy.firstName} ${item.createdBy.lastName}`
        };
    });

    const columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            // eslint-disable-next-line camelcase
            render: (image) => (
                <div className="d-flex align-items-center">
                    <img
                        src={image}
                        onError={(e) => {
                            e.target.src = 'https://via.placeholder.com/150';
                        }}
                        alt={image}
                        className="mr-2"
                        style={{
                            width: 50,
                            height: 50,
                            objectFit: 'cover',
                            borderRadius: '10px',
                            border: '1px solid #f0f0f0'
                        }}
                    />
                </div>
            )
        },
        {
            title: 'country',
            dataIndex: 'country',
            key: 'country'
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            key: 'createdBy'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                let color = 'green';
                if (status === 'inactive') {
                    color = 'red';
                }
                return (
                    <Tag color={color} key={status}>
                        {status.toUpperCase()}
                    </Tag>
                );
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div>
                    <Button
                        style={{
                            backgroundColor: '#FF740F',
                            color: '#FFF',
                            width: 'fit-content'
                        }}
                        onClick={() => {
                            setAction('edit');
                            setFileList({ url: record.image });
                            setId(record.key);
                            setAddBannerModal(true);
                        }}
                    >
                        Edit
                    </Button>
                </div>
            )
        }
    ];

    const imageUploadRequest = async (file) => {
        try {
            const token = localStorage.getItem('userToken');
            const rootURL = ApiUrl.BaseUrl;
            const compressedImage = await normFile(file, token, rootURL);
            if (compressedImage) {
                setFileList({ uid: file.uid, url: compressedImage, name: file.name });
                setLoading(false);
                toast.success('Image uploaded successfully');
            }
        } catch (error) {
            setLoading(false);
            message.error(error);
            toast.error(error.message);
        }
    };

    const handleDelete = (file) => {
        setFileList({});
    };

    const props = {
        name: 'file',
        multiple: false,
        accept: 'image/*',
        customRequest: ({ file, onSuccess, onError }) => {
            imageUploadRequest(file).then(onSuccess).catch(onError);
        },
        onChange(info) {
            const { status, uid } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            } else if (status === 'removed') {
                handleDelete(info.file);
            }
        },
        onRemove: (file) => {
            handleDelete(file);
        }
    };

    const addBanner = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const data = {
                image: fileList.url
            };

            const datas = encrypt(JSON.stringify(data), keyResult, ivResult);

            const url = `${ApiUrl.BaseUrl}/pricing/location/addbanner`;
            const response = await fetch(url, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify({ payload: datas })
            });
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                toast.success(result.message);
                setAddBannerModal(false);
                setLoading(false);
                setFileList({});
                await fetchBannerData();
            } else {
                toast.error(result.message);
                setLoading(false);
            }
        } catch (e) {
            toast(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const updateBanner = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const data = {
                image: fileList.url,
                id,
                status
            };

            const datas = encrypt(JSON.stringify(data), keyResult, ivResult);

            const url = `${ApiUrl.BaseUrl}/pricing/location/updatepricingbanner`;
            const response = await fetch(url, {
                method: 'PUT',
                headers: config.headers,
                body: JSON.stringify({ payload: datas })
            });
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                toast.success(result.message);
                setAddBannerModal(false);
                setLoading(false);
                setFileList({});
                await fetchBannerData();
            } else {
                toast.error(result.message);
                setLoading(false);
            }
        } catch (e) {
            toast(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="m-header">
                <div>
                    <h1>Pricing Banner Settings</h1>
                    <p>Set up your pricing banner here</p>
                </div>
                <div>
                    <Button
                        style={{
                            backgroundColor: 'transparent',
                            color: '#FF740F',
                            border: '1px solid #FF740F',
                            width: 'fit-content'
                        }}
                        onClick={() => {
                            setAction('add');
                            setFileList({});
                            setAddBannerModal(true);
                        }}
                    >
                        Add image
                    </Button>
                </div>
            </div>
            <div className="m-content mt-4">
                <div className="table-responsive">
                    <Table columns={columns} dataSource={bannerSource} loading={loading} />
                </div>
            </div>

            <Modal
                title={action === 'add' ? 'Add Banner' : 'Update Banner'}
                open={addBannerModal}
                onCancel={() => setAddBannerModal(false)}
                footer={null}
            >
                <div>
                    <Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.
                        </p>
                        <div className="d-flex justify-content-center align-items-center mt-4">
                            {fileList.url && (
                                <img
                                    src={fileList.url}
                                    onError={(e) => {
                                        e.target.src = 'https://via.placeholder.com/150';
                                    }}
                                    alt={fileList.name}
                                    className="mr-2"
                                    style={{
                                        width: 100,
                                        height: 50,
                                        objectFit: 'cover',
                                        borderRadius: '10px',
                                        border: '1px solid #f0f0f0',
                                        padding: '5px'
                                    }}
                                />
                            )}
                        </div>
                    </Dragger>
                    {action === 'edit' && (
                        <div className="mt-4">
                            <p style={{ fontSize: '12px' }}>Status</p>
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Select Status"
                                onChange={(value) => setStatus(value)}
                                options={[
                                    { label: 'Active', value: 'Active' },
                                    { label: 'InActive', value: 'InActive' }
                                ]}
                            />
                        </div>
                    )}
                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <Button
                            className="flex-grow-1"
                            style={{
                                backgroundColor: 'transparent',
                                color: '#FF740F',
                                border: '1px solid #FF740F',
                                width: 'fit-content'
                            }}
                            onClick={() => setAddBannerModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            loading={loading}
                            className="flex-grow-1 ml-2"
                            style={{
                                backgroundColor: '#FF740F',
                                color: '#FFF',
                                width: 'fit-content'
                            }}
                            onClick={action === 'add' ? addBanner : updateBanner}
                            disabled={action === 'edit' && (!fileList.url || !status) ? true : !!(action === 'add' && !fileList.url)}
                        >
                            {action === 'add' ? 'Add' : 'Update'}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Index;
