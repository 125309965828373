import { Divider } from '@mui/material';
import { useRetailerContext } from 'context/retailer-shop/retailer-context';
import styled from 'styled-components';
import placeholder from 'assets/images/placeholder.png';

const Wrapper = styled.div`
    background: #fff;
    color: #222;
    padding: 1rem 2rem;
    border-radius: 10px;
    font-family: 'Roboto';
    margin-bottom: 30px;

    h5 {
        font-size: 1.2rem;
        color: #ff740f;
        margin-bottom: 20px;
    }
    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .product {
        max-width: 40%;
        padding: 1rem;
    }
    .qty-sold {
        color: #ff740f;
        font-weight: 800;
    }
`;

const BestSeller = () => {
    const { todaybestseller } = useRetailerContext();
    return (
        <Wrapper>
            <h5>Today Best Seller</h5>
            <Divider />
            <div className="content">
                <div>
                    <img src={todaybestseller?.partimage || placeholder} alt="Oil filter" className="product" />
                    <p className="item-desc">{todaybestseller?.partName}</p>
                    <p className="qty-sold">Sold {todaybestseller?.totalSold?.toFixed(2)}</p>
                </div>
            </div>
        </Wrapper>
    );
};
export default BestSeller;
