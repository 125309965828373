import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';

const EditAttribute = () => {
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const history = useNavigate();
    const scriptedRef = useScriptRef();

    const { id } = useParams();

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.ViewAttributeUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setName(result.data.name);
                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading2(false);
            });
    }, [id]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');

        const data = {
            id,
            name
        };
        setLoading(true);
        fetch(ApiUrl.UpdateAttributeUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    // window.location.href = '/roles';
                    toast.success(result.message);
                    setLoading(false);
                    setTimeout(() => {
                        if (scriptedRef.current) {
                            history('/attribute');
                        }
                    }, 3000);
                } else {
                    toast.error(result.data);
                    // console.log(result);
                }
                // console.log(result);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <br />
                            <PageHeader title="Attribute" subTitle="Home | Configuration | Setup | Attribute" />
                            <br />
                            <MainCard title="Edit Attribute">
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        autoComplete="off"
                                        name="name"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="name"
                                        label="Attribute Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        autoFocus
                                    />

                                    <br />
                                    <br />
                                    <Box display="flex" justifyContent="space-between">
                                        <Button component={Link} to="/attribute" type="submit" variant="contained" color="error">
                                            Reset
                                        </Button>

                                        <Button type="submit" variant="contained" color="secondary">
                                            {loading ? 'Updating....' : 'Update'}
                                        </Button>
                                    </Box>
                                </form>
                            </MainCard>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default EditAttribute;
