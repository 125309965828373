import { Divider, MenuItem, Button as MuiButton } from '@mui/material';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import Modal from 'react-bootstrap/Modal';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import { useDarkStoreContext } from 'context/darkstore-inventory/darkstore-context';
import { useParams } from 'react-router';
import { useState } from 'react';

const ModalWrapper = styled.div`
    .modal-actions {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 30px;
    }

    .title {
        color: #ff740f;
        font-weight: 700;
        text-align: center;
        padding: 1rem 0;
    }

    .cancel-btn {
        background-color: #193855;
        margin-left: -3rem;
    }

    .save-btn {
        background-color: #ff740f;
        margin-right: -2rem;
    }

    .content {
        padding: 1rem 2rem;
        margin-bottom: 50px;
    }

    .h-title {
        font-weight: 600;
        color: #444;
        text-transform: uppercase;
    }
`;

const EditPartModal = ({ handleClose, show }) => {
    const { editId, garagePrice, wholeSalePrice, partId, qty, handleChange, isLoading, handleStockUpdate, product, dispatch } =
        useDarkStoreContext();
    const [stockType, setStockType] = useState('');

    const { id: darkstoreAreaId } = useParams();

    const handInputChange = (e) => {
        const { name, value } = e.target;
        handleChange(name, value);
    };

    const payload = {
        id: editId,
        darkstoreAreaId,
        garagePrice,
        wholeSalePrice,
        partId,
        qty,
        description: stockType
    };

    const updateProductStock = () => {
        handleStockUpdate(payload);
        dispatch({ type: 'CLEAR_UPDATE_VALUES' });
        setTimeout(() => {
            handleClose();
        }, 2000);
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} centered className="mt-5">
                <ModalWrapper>
                    <Modal.Body>
                        <div className="content">
                            <Divider>
                                <h5 className="h-title">Adjust Stock Quantity</h5>
                            </Divider>
                            <br />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select2-label">Select adjustment type</InputLabel>
                                <Select
                                    labelId="demo-simple-select2-label"
                                    id="demo-simple-select2"
                                    value={stockType}
                                    label="Select adjustment"
                                    onChange={(e) => {
                                        setStockType(e.target.value);
                                    }}
                                >
                                    <MenuItem value="stockadjustmentin">stockadjustmentin</MenuItem>
                                    <MenuItem value="stockadjustmentout">stockadjustmentout</MenuItem>
                                </Select>
                            </FormControl>
                            <br /> <br />
                            <FormControl fullWidth>
                                <TextField
                                    id="outlined-basic"
                                    label="Input New Quantity*"
                                    variant="outlined"
                                    type="number"
                                    value={qty}
                                    name="qty"
                                    onChange={handInputChange}
                                    helperText="This varies the stock"
                                />
                            </FormControl>
                            <br />
                            <br />
                            <FormControl fullWidth>
                                <TextField
                                    id="outlined-basic"
                                    label="Product"
                                    variant="outlined"
                                    disabled
                                    type="product"
                                    value={product}
                                    name="product"
                                    onChange={handInputChange}
                                />
                            </FormControl>
                            <br />
                            <br />
                            <FormControl fullWidth>
                                <TextField
                                    id="outlined-basic"
                                    label="Garage Price"
                                    variant="outlined"
                                    type="number"
                                    name="garagePrice"
                                    value={garagePrice}
                                    disabled
                                    onChange={handInputChange}
                                />
                            </FormControl>
                            <br /> <br />
                            <FormControl fullWidth>
                                <TextField
                                    id="outlined-basic"
                                    label="WholeSale Price"
                                    variant="outlined"
                                    type="number"
                                    name="wholeSalePrice"
                                    disabled
                                    value={wholeSalePrice}
                                    onChange={handInputChange}
                                />
                            </FormControl>
                        </div>
                    </Modal.Body>
                    <div className="modal-actions">
                        <MuiButton variant="contained" onClick={handleClose} className="cancel-btn">
                            Cancel
                        </MuiButton>
                        <LoadingButton loading={isLoading} variant="contained" className="save-btn" onClick={updateProductStock}>
                            Update
                        </LoadingButton>
                    </div>
                </ModalWrapper>
            </Modal>
        </>
    );
};
export default EditPartModal;
