import { Button, Modal, Input, Select, Spin, DatePicker, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import ApiUrl from '../../../network';
import { decrypt, encrypt } from '../../../utils/encrypt';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const { RangePicker } = DatePicker;
    const [editModal, setEditModal] = useState(false);
    const [actionType, setActionType] = useState('');
    const [configId, setConfigId] = useState('');
    const [reason, setReason] = useState('');
    const [config, setConfig] = useState([]);
    const [loading, setLoading] = useState(false);
    const { TextArea } = Input;
    const [rmacc, setRmacc] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [rmaccOptions, setRmaccOptions] = useState([]);
    const [driveTypeList, setDriveTypeList] = useState([]);
    const [status, setStatus] = useState('');
    const [descriptionModal, setDescriptionModal] = useState(false);
    const [requestPick, setRequestPick] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(25);
    const [totalPages, setTotalPages] = useState(1);

    // eslint-disable-next-line consistent-return
    const fetchRequestData = async (data, page = 1) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const urlParams = new URLSearchParams(window.location.search);
            if (data) {
                if (data.startDate) urlParams.append('startDate', startDate);
                if (data.endDate) urlParams.append('endDate', endDate);
                if (data.status) urlParams.append('status', status);
                if (data.rmacc) urlParams.append('rmacc', rmacc);
            }
            urlParams.append('page', page);
            urlParams.append('limit', itemsPerPage);

            const url = `${ApiUrl.BaseUrl}/epl/eplrequestfinance?${urlParams.toString()}`;

            const response = await fetch(url, config);
            if (!response.ok) {
                toast.error('Failed to fetch retailers');
            }
            const apiData = await response.json();
            const result = JSON.parse(decrypt(apiData.data, keyResult, ivResult));
            result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            result.sort((a, b) => {
                if (a.status.toLowerCase() === 'pending') {
                    return -1;
                }
                return 1;
            });
            setConfig(result);
            setTotalPages(Math.ceil(apiData?.totalepl / itemsPerPage));
            setLoading(false);
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };

    const fetchAllData = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const rmaccUrl = `${ApiUrl.BaseUrl}/epl/eplcustomersrmacc`;
            const rmaccResponse = await fetch(rmaccUrl, config);
            const rmaccData = await rmaccResponse.json();
            if (rmaccData.message === 'Success') {
                const decryptedRmaccData = JSON.parse(decrypt(rmaccData.data, keyResult, ivResult));
                setRmaccOptions(decryptedRmaccData.rmcc);
            } else {
                toast.error(rmaccData.message);
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const getDriveTypeHandler = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/epl/epldrivetyeps`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    const driveTypeList = decrypt(JSON.stringify(data.data), keyResult, ivResult);
                    setDriveTypeList(JSON.parse(driveTypeList).driveType);
                } else {
                    toast.error(data.message);
                }
            });
    };

    useEffect(() => {
        fetchRequestData();
        fetchAllData();
        getDriveTypeHandler();
    }, [itemsPerPage]);

    const rmaccOptionHandler = useMemo(() => {
        return rmaccOptions.map((item) => ({
            label: `${item.firstName} ${item.lastName}`,
            value: item._id
        }));
    }, [rmaccOptions]);

    const driveTypeOptions = driveTypeList.map((driveType) => {
        return {
            value: driveType.name,
            label: driveType.name
        };
    });

    // eslint-disable-next-line consistent-return
    const rejectRequestHandler = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const datas = {
                id: configId,
                reason,
                status: 'rejected'
            };
            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            const response = await fetch(`${ApiUrl.BaseUrl}/epl/rejecteplrequest`, {
                method: 'PUT',
                headers: config.headers,
                body: JSON.stringify({ payload })
            });
            if (!response.ok) {
                toast.error('Failed to add configuration');
            }
            const result = await response.json();
            if (result.message === 'success') {
                setEditModal(false);
                setLoading(false);
                fetchRequestData();
            } else {
                toast.error('Failed to add configuration');
                setLoading(false);
            }
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };

    // eslint-disable-next-line consistent-return
    const approveRequestHandler = async (id) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const datas = {
                id,
                reason: 'Approved',
                status: 'Approved'
            };
            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            const response = await fetch(`${ApiUrl.BaseUrl}/epl/approvedeplrequest`, {
                method: 'PUT',
                headers: config.headers,
                body: JSON.stringify({ payload })
            });
            if (!response.ok) {
                toast.error('Failed to add configuration');
            }
            const result = await response.json();
            if (result.message === 'success') {
                setEditModal(false);
                setLoading(false);
                fetchRequestData();
            } else {
                toast.error('Failed to add configuration');
                setLoading(false);
            }
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };

    const handleDateChange = (date, dateString) => {
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
    };

    const applyFilter = () => {
        const data = {
            startDate,
            endDate,
            rmacc,
            status
        };
        fetchRequestData(data);
    };

    // Get the items for the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // Change page
    // const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchRequestData({}, page);
    };

    return (
        <div className="config-container epl-request-finance-home">
            <div className="header">
                <Link className="d-flex" to="/epl/easy-pay-later" style={{ color: '#0A2938' }}>
                    <div className="ml-2">
                        <h1 className="font-30">EPL request</h1>
                        <p style={{ color: '#565C6B', fontWeight: 'normal' }}>View and manage all EPL request </p>
                    </div>
                </Link>
                <div className="header-actions">
                    <Select
                        style={{
                            margin: '0.625rem'
                        }}
                        placeholder="Request status"
                        options={[
                            {
                                label: 'Approved',
                                value: 'Approved'
                            },
                            {
                                label: 'Pending',
                                value: 'Pending'
                            },
                            {
                                label: 'Rejected',
                                value: 'Rejected'
                            }
                        ]}
                        onChange={(value) => setStatus(value)}
                        value={status || null}
                        filterOption={(inputValue, option) => {
                            if (typeof option.label === 'string') {
                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }

                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        }}
                        showSearch
                        allowClear
                    />
                    <Select
                        style={{
                            margin: '0.625rem'
                        }}
                        placeholder="Search RMA/CC"
                        options={rmaccOptionHandler}
                        onChange={(value) => setRmacc(value)}
                        value={rmacc || null}
                        filterOption={(inputValue, option) => {
                            if (typeof option.label === 'string') {
                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }

                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        }}
                        showSearch
                        allowClear
                    />
                    <RangePicker
                        onChange={(date, dateString) => {
                            handleDateChange(date, dateString);
                        }}
                        value={startDate && endDate ? [dayjs(startDate, 'YYYY-MM-DD'), dayjs(endDate, 'YYYY-MM-DD')] : null}
                        format="YYYY-MM-DD"
                        className="datePicker filterInput"
                        style={{ width: 'fit-content' }}
                    />
                    <Button
                        className="mt-3"
                        style={{
                            color: '#FF740F',
                            border: '1px solid #FF740F',
                            width: 'fit-content'
                        }}
                        loading={loading}
                        onClick={() => applyFilter()}
                    >
                        Apply filter
                    </Button>
                    {(startDate && endDate) || rmacc || status ? (
                        <Button
                            onClick={() => {
                                setStartDate('');
                                setEndDate('');
                                setRmacc('');
                                setStatus('');
                                fetchRequestData();
                            }}
                            style={{
                                color: '#FF740F',
                                border: '1px solid #FF740F',
                                width: 'fit-content'
                            }}
                            className="ml-2 mt-3"
                        >
                            Reset filter
                        </Button>
                    ) : null}
                </div>
            </div>
            {loading ? (
                <div className="spinner-container">
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 34 }} spin />} />
                </div>
            ) : (
                <div>
                    {config.length > 0 ? (
                        <div className="cards-container">
                            {config.map((item) => (
                                <div className="card border-0 p-4" style={{ borderRadius: '20px' }} key={item._id}>
                                    <div className="card-content">
                                        <div className="d-flex flex-row-reverse mb-4">
                                            {item.status.toLowerCase() === 'rejected' ? (
                                                <Tag
                                                    size="small"
                                                    color="#FFEBED"
                                                    style={{
                                                        borderRadius: '5px',
                                                        fontSize: '12px',
                                                        padding: '5px 10px',
                                                        marginBottom: '10px',
                                                        color: '#FA3246'
                                                    }}
                                                >
                                                    {item.status}
                                                </Tag>
                                            ) : item.status.toLowerCase() === 'approved' ? (
                                                <Tag
                                                    size="small"
                                                    color="#E6F7EE"
                                                    style={{
                                                        borderRadius: '5px',
                                                        fontSize: '12px',
                                                        padding: '5px 10px',
                                                        marginBottom: '10px',
                                                        color: '#00A86B'
                                                    }}
                                                >
                                                    Approved
                                                </Tag>
                                            ) : null}
                                        </div>
                                        <span className="info-row">
                                            <p style={{ color: '#546974', fontSize: '15px' }}>Date</p>
                                            <p style={{ color: '#0A2938' }}>
                                                {new Date(item.date).toLocaleDateString('us-US', {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true,
                                                    timeZone: 'Africa/Lagos'
                                                })}
                                            </p>
                                        </span>
                                        <span className="info-row">
                                            <p style={{ color: '#546974', fontSize: '15px' }}>Retailer</p>
                                            <p style={{ color: '#0A2938' }}>{item?.customergh?.partnerName}</p>
                                        </span>
                                        <span className="info-row">
                                            <p style={{ color: '#546974', fontSize: '15px' }}>RMA/CC</p>
                                            <p style={{ color: '#0A2938' }}>
                                                {item?.rmacc?.firstName} {item?.rmacc?.lastName}
                                            </p>
                                        </span>
                                        <span className="info-row">
                                            <p style={{ color: '#546974', fontSize: '15px' }}>Order value</p>
                                            <p style={{ color: '#0A2938' }}>
                                                {item?.currency?.name} {item.orderValue}
                                            </p>
                                        </span>
                                        <span className="info-row">
                                            <p style={{ color: '#546974', fontSize: '15px' }}>Cash at hand</p>
                                            <p style={{ color: '#0A2938' }}>
                                                {item?.currency?.name} {item.cashathand}
                                            </p>
                                        </span>
                                        <span className="info-row">
                                            <p style={{ color: '#546974', fontSize: '15px' }}>Epl Balance</p>
                                            <p style={{ color: '#0A2938' }}>
                                                {(item?.orderValue - item?.cashathand).toFixed(2)}
                                                {item?.currency?.name}
                                            </p>
                                        </span>
                                        <span className="info-row">
                                            <p style={{ color: '#546974', fontSize: '15px' }}>Drive type</p>
                                            <p style={{ color: '#0A2938' }}>{item?.drivetype}</p>
                                        </span>
                                        <span className="info-row">
                                            <p style={{ color: '#546974', fontSize: '15px' }}>Description</p>
                                            <Button
                                                style={{
                                                    color: '#FF8227',
                                                    border: 'none',
                                                    outline: 'none',
                                                    width: 'fit-content'
                                                }}
                                                onClick={() => {
                                                    setDescriptionModal(true);
                                                    setRequestPick(item);
                                                }}
                                            >
                                                view
                                            </Button>
                                        </span>
                                        {item.status.toLowerCase() !== 'rejected' && item.status.toLowerCase() !== 'approved' ? (
                                            <div>
                                                <Button
                                                    className="mb-3"
                                                    onClick={() => {
                                                        approveRequestHandler(item._id);
                                                    }}
                                                    style={{ backgroundColor: '#FF740F', color: '#fff', border: 'none', width: '100%' }}
                                                >
                                                    Accept
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        setActionType('reject');
                                                        setConfigId(item._id);
                                                        setEditModal(true);
                                                    }}
                                                    style={{ backgroundColor: '#FFEBED', color: '#FA3246', border: 'none', width: '100%' }}
                                                >
                                                    Reject request
                                                </Button>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div
                            className="card border-0 p-4 d-flex justify-content-center align-items-center not-found"
                            style={{
                                borderRadius: '20px',
                                width: '50%',
                                marginTop: '10vh',
                                margin: '0 auto',
                                height: '40vh'
                            }}
                        >
                            <p style={{ fontSize: '16', fontWeight: 'bold' }}>No EPL request available</p>
                        </div>
                    )}
                    <div className="custom-pagination">
                        {[...Array(totalPages).keys()].map((number) => (
                            <button
                                type="button"
                                key={number}
                                onClick={() => {
                                    handlePageChange(number + 1);
                                }}
                                className={`custom-pagination-btn page-link ${currentPage === number + 1 ? 'active' : ''}`}
                            >
                                {number + 1}
                            </button>
                        ))}
                    </div>
                </div>
            )}

            <Modal
                title={actionType === 'approve' ? 'State reason for approval' : actionType === 'reject' ? 'State reason for rejection' : ''}
                open={editModal}
                onCancel={() => {
                    setEditModal(false);
                }}
                footer={null}
            >
                <div className="p-2 mt-4 mb-4 modal-Content">
                    <TextArea
                        placeholder="Enter reason for rejection"
                        value={reason}
                        onChange={(e) => {
                            setReason(e.target.value);
                        }}
                        style={{
                            height: '100px',
                            resize: 'none',
                            backgroundColor: '#F5F5F5'
                        }}
                    />
                </div>
                <div className="modal-Footer">
                    <Button
                        onClick={() => {
                            setEditModal(false);
                        }}
                        style={{ backgroundColor: '#FFF', borderColor: '#FF740F' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{
                            backgroundColor: '#FF740F',
                            borderColor: '#FF740F',
                            color: '#FFF'
                        }}
                        loading={loading}
                        onClick={() => {
                            if (actionType === 'approve') {
                                approveRequestHandler();
                            } else if (actionType === 'reject') {
                                rejectRequestHandler();
                            }
                        }}
                    >
                        {actionType === 'approve' ? 'Approve' : actionType === 'reject' ? 'Reject' : ''}
                    </Button>
                </div>
            </Modal>
            <Modal
                title="Request description"
                open={descriptionModal}
                onCancel={() => {
                    setDescriptionModal(false);
                }}
                footer={null}
            >
                <div>
                    <div className="p-2 mt-4 mb-4 modal-Content">
                        <p>{requestPick.description}</p>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Index;
