import React, { useState, useEffect } from 'react';
import { Table, Divider, Paper, Space, Button, Select, Modal, Checkbox, Alert } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Link, useNavigate, useParams, useHistory } from 'react-router-dom';
import './logistics.css';
import Image from '../../assets/images/brakefluid.png';
import ApiUrl from '../../network';
import { encrypt, decrypt } from '../../utils/encrypt';
import { ToastContainer, toast } from 'react-toastify';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const OrderSumbition = ({ isDriverOpen, handleDriverOk, handleDriverCancel, orders, showAssignOrderButton }) => {
    const [isSuccessOpen, setIsModalSuccessOpen] = useState(false);
    const [allDrivers, setAllDrivers] = useState([]);
    const [assignOrderButton, setAssignOrderButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [orderName, setOrderName] = useState([]);
    const [selectDriver, setSelectDriver] = useState('');
    const [driverName, setDriverName] = useState('');
    const [successAlert, setSuccessAlert] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    // success alert modals
    const showSuccessModal = () => {
        setIsModalSuccessOpen(true);
    };

    const handleSuccessOk = () => {
        setIsModalSuccessOpen(false);
    };

    const handleSuccessCancel = () => {
        setIsModalSuccessOpen(false);
    };

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };

    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };

    const getAllDrivers = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(`${ApiUrl.BaseUrl}/driver/alldrivers`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);

                        const available = answer.filter((item) => {
                            return item.driverAvailability === true;
                        });

                        setAllDrivers(available);

                        setLoading(false);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                        toast.error(answer);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        getAllDrivers();
    }, []);

    // Assign an order to a driver
    const AssignOrder = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');

        let data = {
            driverId: selectDriver,
            orders: Array.isArray(orders) ? orders?.map((orderId) => ({ _id: orderId })) : [{ _id: orders._id }]
        };

        console.log(data);

        const info = {
            driverId: selectDriver,
            orders: `Order: ${orders.orderNo}`
        };

        setOrderName(info.orders);

        let url = `${ApiUrl.BaseUrl}/driver/dispatchorder`;

        if (showAssignOrderButton === true) {
            url = `${ApiUrl.BaseUrl}/driver/changedriverorder`;

            data = { driverId: selectDriver, orderId: orders._id };
        } else if (showAssignOrderButton === false) {
            url = `${ApiUrl.BaseUrl}/driver/dispatchorder`;

            data = {
                driverId: selectDriver,
                orders: Array.isArray(orders) ? orders?.map((orderId) => ({ _id: orderId })) : [{ _id: orders._id }]
            };
        }

        const obj = JSON.stringify(data);
        const result = encrypt(obj, keyResult, ivResult);
        fetch(url, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ payload: result })
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    showSuccessModal();
                    handleDriverCancel(true);
                } else if (result.status === false) {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                    setSuccessAlert(true);
                    handleDriverCancel(true);
                } else {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                    handleDriverCancel(true);
                }
            })
            .finally(() => {
                // setLoading(false);
            });
    };

    // select driver
    const handleSelectDriver = (value, name) => {
        setSelectDriver(value);
        setDriverName(name);
        setAssignOrderButton(true);
    };

    return (
        <div>
            {' '}
            <Modal
                open={isDriverOpen}
                onOk={handleDriverOk}
                centered
                onCancel={handleDriverCancel}
                zIndex={2050}
                footer={
                    <div className="driver-footer">
                        {assignOrderButton ? (
                            <Button
                                key="link"
                                // onClick={() => {
                                //     showProductModal();
                                //     setIsDriverOpen(false);
                                // }}
                                onClick={AssignOrder}
                                style={{
                                    backgroundColor: '#FF740F',
                                    borderRadius: '12px',
                                    height: '56px',
                                    fontSize: '18px',
                                    fontWeight: '700',
                                    color: '#FFFFFF',
                                    width: '100%',
                                    textAlign: 'center'
                                }}
                            >
                                {showAssignOrderButton ? `Update` : `Assign`}
                            </Button>
                        ) : (
                            <Button
                                disabled
                                key="link"
                                style={{
                                    backgroundColor: '#FFC79F',
                                    borderRadius: '12px',
                                    height: '56px',
                                    fontSize: '18px',
                                    fontWeight: '700',
                                    color: '#FFFFFF',
                                    width: '100%',
                                    textAlign: 'center'
                                }}
                            >
                                {showAssignOrderButton ? `Update` : `Assign`}
                            </Button>
                        )}
                    </div>
                }
            >
                <div className="drivers-available">
                    <h1>Drivers Available</h1>

                    {allDrivers.length > 0 ? (
                        <section className="driver-list">
                            {allDrivers?.map((driver) => (
                                <div
                                    className="drivers"
                                    key={driver._id}
                                    onClick={() => {
                                        handleSelectDriver(driver._id, driver.firstName);
                                        toggleCheckbox();
                                    }}
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={() => {}}
                                >
                                    <span className="driver">
                                        {driver.firstName} {driver.lastName}
                                    </span>
                                </div>
                            ))}
                        </section>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <HourglassEmptyIcon sx={{ fontSize: '35px' }} />
                            <Typography sx={{ fontSize: '35px' }}>No Drivers Available</Typography>
                        </Box>
                    )}
                </div>
            </Modal>
            <Modal
                open={isSuccessOpen}
                onOk={handleSuccessOk}
                onCancel={handleSuccessCancel}
                width={506}
                centered
                okButtonProps={{
                    disabled: true,
                    style: { display: 'none' }
                }}
                cancelButtonProps={{
                    disabled: true,
                    style: { display: 'none' }
                }}
            >
                <Space>
                    <Box
                        sx={{
                            height: '253px',
                            width: '100%',
                            backgroundColor: '#FFFFFF',
                            borderRadius: '10px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}
                    >
                        {/* <div
                            style={{
                                borderBottom: '1px dashed #B6BFC3',
                                height: '1px',
                                width: '100%',
                                position: 'absolute',
                                top: '45px'
                            }}
                        /> */}
                        <Box>
                            <CheckCircleOutlineIcon
                                sx={{
                                    backgroundColor: '#CCEEDD',
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '100%',
                                    padding: '10px',
                                    color: '#00AA55',
                                    margin: '0 12px 0 16px'
                                }}
                            />
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                height: '136px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: '500'
                                }}
                            >
                                Order Successfully Assigned
                            </Typography>
                            <Typography sx={{ color: '#696E7C', lineHeight: '24px', fontSize: '16px' }}>
                                {orderName} has been successfully assigned to the Driver:{' '}
                                <span style={{ color: '#444' }}>{driverName}</span>
                            </Typography>
                        </Box>
                        {/* <div
                            style={{ borderTop: '1px dashed #B6BFC3', height: '1px', width: '100%', position: 'absolute', bottom: '45px' }}
                        /> */}
                    </Box>
                </Space>
            </Modal>
        </div>
    );
};

export default OrderSumbition;
