// assets
import { IconSettings, IconHelp } from '@tabler/icons';
import SettingsIcon from '@mui/icons-material/Settings';

// constant
const icons = { IconSettings, IconHelp, SettingsIcon };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //
const userRole = localStorage.getItem('userRole');
const other = {
    id: 'sample-docs-roadmap',
    type: userRole,
    children: [
        {
            id: 'sample-page',
            title: 'Settings',
            type: 'item',
            url: '/sample-page',
            icon: icons.SettingsIcon,
            breadcrumbs: false
        }
        // {
        //     id: 'help',
        //     title: 'Help',
        //     type: 'item',
        //     url: '/',
        //     icon: icons.IconHelp
        // }
    ]
};

export default other;
