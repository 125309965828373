import { Button, Input, Select } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { decrypt, encrypt } from '../../../../utils/encrypt';
import ApiUrl from '../../../../network';
import { toast } from 'react-toastify';

export const Add = () => {
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [customer, setCustomer] = useState('');
    const [orderValue, setOrderValue] = useState('');
    const [cashathand, setCashAtHand] = useState('');
    const [drivetype, setDriveType] = useState('');
    const [description, setDescription] = useState('');
    const [orderId, setOrderId] = useState('');
    const [placedfor, setPlacedFor] = useState('');
    const [customerID, setCustomerID] = useState('');
    const [customerData, setCustomerData] = useState([]);
    const [rmacc, setRmacc] = useState([]);
    const [driveTypeList, setDriveTypeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const { TextArea } = Input;

    const fetchRmaCc = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            await fetch(`${ApiUrl.BaseUrl}/epl/eplcustomersrmacc`, {
                method: 'GET',
                headers: config.headers
            })
                .then((data) => data.json())
                .then((rmaccData) => {
                    if (rmaccData.message === 'Success') {
                        const decryptedRmaccData = JSON.parse(decrypt(rmaccData.data, keyResult, ivResult));
                        setCustomerData(decryptedRmaccData.customer);
                        setRmacc(decryptedRmaccData.rmcc);
                    } else {
                        toast.error(rmaccData.data);
                    }
                });
        } catch (error) {
            toast.error('Error occurred while fetching RMA');
        }
    };

    const fetchRequestDetails = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            await fetch(`${ApiUrl.BaseUrl}/epl/vieweplrequest/${id}`, {
                method: 'GET',
                headers: config.headers
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setCustomer(decryptedData.customergh?.partnerName);
                        setOrderValue(decryptedData.orderValue);
                        setCashAtHand(decryptedData.cashathand);
                        setDescription(decryptedData.description);
                        setDriveType(decryptedData.drivetype);
                        setOrderId(decryptedData?.ordergh?._id);
                        setCustomerID(decryptedData?.customercard);
                        setPlacedFor(`${decryptedData?.placedfor?.firstName} ${decryptedData?.placedfor?.lastName}`);
                    } else {
                        toast.error(data.message);
                    }
                });
        } catch (error) {
            toast.error('Error occurred while fetching request details');
        }
    };

    const getDriveTypeHandler = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/epl/epldrivetyeps`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    const driveTypeList = decrypt(JSON.stringify(data.data), keyResult, ivResult);
                    setDriveTypeList(JSON.parse(driveTypeList).driveType);
                } else {
                    toast.error(data.message);
                }
            });
    };

    useEffect(() => {
        fetchRequestDetails();
        fetchRmaCc();
        getDriveTypeHandler();
    }, []);

    const customerOptions = customerData.map((customer) => {
        return {
            value: customer._id,
            label: customer.partnerName
        };
    });

    const placedForOptions = rmacc.map((rmcc) => {
        return {
            value: rmcc._id,
            label: `${rmcc.firstName} ${rmcc.lastName} `
        };
    });

    const driveTypeOptions = driveTypeList.map((driveType) => {
        return {
            value: driveType.name,
            label: driveType.name
        };
    });

    const updateRequestHandler = async () => {
        try {
            if (!customer || !orderValue || !description || !cashathand || !drivetype) {
                toast.error('All fields are required');
                return;
            }
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const checkCustomerID = customerData.find((item) => item.partnerName === customer);
            const checkPlacedFor = rmacc.find((item) => `${item.firstName} ${item.lastName}` === placedfor);

            const datas = {
                id,
                customer: checkCustomerID._id || customer,
                orderValue,
                cashathand,
                description,
                drivetype,
                customercard: customerID,
                placedfor: checkPlacedFor._id || placedfor,
                orderId
            };

            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            await fetch(`${ApiUrl.BaseUrl}/epl/updateeplrequest`, {
                method: 'PUT',
                headers: config.headers,
                body: JSON.stringify({ payload })
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setLoading(false);
                        toast.success(data.message);
                        fetchRequestDetails();
                    } else {
                        setLoading(false);
                        toast.error(data.message);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    };

    return (
        <div className="easy-pay-container">
            <div className="header">
                <Link className="d-flex align-items-center" to="/epl/cc-rma-request" style={{ color: '#0A2938' }}>
                    <ArrowLeftOutlined className="font-20" />
                    <h1 className="font-30 ml-2 mt-2">Edit Request </h1>
                </Link>
            </div>
            <div className="content bg-white">
                <div className="epl-request-content">
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="form-group">
                            <span>Customer</span>
                            <Select
                                value={customer}
                                onChange={(e) => setCustomer(e)}
                                size="middle"
                                className="mt-2"
                                showSearch
                                allowClear
                                options={customerOptions}
                                defaultValue="Select option"
                                style={{ width: '100%' }}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                            />
                        </div>
                        <div className="form-group ml-2">
                            <span>Order value</span>
                            <Input
                                disabled
                                value={orderValue}
                                onChange={(e) => setOrderValue(e.target.value)}
                                type="number"
                                className="form-control mt-2"
                                id="amount"
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="form-group">
                            <span>Drive type</span>
                            <Select
                                value={drivetype}
                                onChange={(e) => setDriveType(e)}
                                size="middle"
                                className="mt-2"
                                showSearch
                                allowClear
                                options={driveTypeOptions}
                                defaultValue="Select option"
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="form-group ml-2">
                            <span>Cash at hand</span>
                            <Input
                                value={cashathand}
                                onChange={(e) => setCashAtHand(e.target.value)}
                                type="number"
                                className="form-control mt-2"
                                id="amount"
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="form-group">
                            <span>Customer ID</span>
                            <Select
                                value={customerID}
                                onChange={(e) => setCustomerID(e)}
                                size="middle"
                                className="mt-2"
                                showSearch
                                allowClear
                                options={[
                                    { value: 'Yes', label: 'Yes' },
                                    { value: 'No', label: 'No' }
                                ]}
                                defaultValue="Select option"
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="form-group ml-2">
                            <span>Placed for</span>
                            <Select
                                value={placedfor}
                                onChange={(e) => setPlacedFor(e)}
                                size="middle"
                                className="mt-2"
                                showSearch
                                allowClear
                                options={placedForOptions}
                                defaultValue="Select option"
                                style={{ width: '100%' }}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <span>Description</span>
                        <TextArea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="form-control mt-2"
                            id="description"
                            rows={4}
                        />
                    </div>
                    <div className="d-flex align-items-center justify-content-center mt-4">
                        <Button
                            onClick={() => {
                                window.history.back();
                            }}
                            style={{
                                backgroundColor: '#fff',
                                color: '#FF740F',
                                border: '1px solid #FF740F'
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="ml-2"
                            loading={loading}
                            onClick={() => {
                                updateRequestHandler();
                            }}
                            style={{
                                backgroundColor: `${
                                    customer && orderValue && cashathand && drivetype && description ? '#FF740F' : '#B4B7BD'
                                }`,
                                color: '#fff'
                            }}
                        >
                            Save Request
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Add;
