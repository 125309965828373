import React, { useEffect, useState } from 'react';
import { Button, Select, Table, DatePicker, Skeleton, Tag, Dropdown, Space, Modal } from 'antd';
import { decrypt, encrypt } from '../../../utils/encrypt';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { DownOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import './batch.css';
import ApiUrl from 'network';

export const Index = () => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    const initialState = {
        startDate: '',
        endDate: '',
        country: '',
        limit: '',
        rma: ''
    };
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [filter, setFilter] = useState(initialState);
    const { RangePicker } = DatePicker;
    const [countries, setCountries] = useState([]);
    const [limitOptions, setLimitOptions] = useState([
        {
            label: '100',
            value: '100'
        },
        {
            label: '200',
            value: '200'
        },
        {
            label: '300',
            value: '300'
        },
        {
            label: '400',
            value: '400'
        },
        {
            label: '400',
            value: '400'
        },
        {
            label: '500',
            value: '500'
        }
    ]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selected, setSelected] = useState({});
    const [batchModal, setBatchModal] = useState(false);
    const [batchModalDetail, setBatchModalDetail] = useState(false);
    const [toggle, setToggle] = useState(1);
    const [mainRma, setMainRma] = useState([]);
    const batchedParams = JSON.parse(localStorage.getItem('saveBatchParams'));
    const country = localStorage.getItem('country');

    const toggleFilter = (index) => {
        setToggle(index);
    };

    const fetchOrders = async (data) => {
        setLoading(true);
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/waybill/presoldallbatchorders`;

            if (data) {
                if (data.startDate && data.endDate) {
                    urlParams.set('startDate', data.startDate);
                    urlParams.set('endDate', data.endDate);
                }
                if (data.country) {
                    urlParams.set('country', data.country);
                }
                if (data.rma) {
                    urlParams.set('rma', data.rma);
                }
            } else {
                const savedParamsString = localStorage.getItem('saveBatchParams');
                let savedParams;

                try {
                    savedParams = savedParamsString ? JSON.parse(savedParamsString) : {};
                } catch (error) {
                    savedParams = {};
                }

                if (savedParams.startDate && savedParams.endDate) {
                    urlParams.set('startDate', savedParams.startDate);
                    urlParams.set('endDate', savedParams.endDate);
                }
                if (savedParams.country) {
                    urlParams.set('country', savedParams.country);
                }
                if (savedParams.rma) {
                    urlParams.set('rma', savedParams.rma);
                }
            }
            if (urlParams.toString()) url += `?${urlParams.toString()}`;

            const response = await fetch(url, {
                method: 'GET',
                headers: config.headers
            });

            const responseData = await response.json();

            if (responseData.status === true) {
                const orders = decrypt(JSON.stringify(responseData.data), keyResult, ivResult);
                setOrders(JSON.parse(orders));
                setLoading(false);
            } else {
                toast.error(responseData.message);
                setLoading(false);
            }
        } catch (e) {
            toast.error(e.message);
            setLoading(false);
        }
    };

    const fetchCountries = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            fetch(`${ApiUrl.BaseUrl}/country/activecountry`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error('Something went wrong');
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    // get query data
    const getQueryData = async () => {
        try {
            // setLoading(true);
            const url = `${ApiUrl.BaseUrl}/mainorders/querydata?country=${batchedParams?.country?.toString() || country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setMainRma(answer.relationshipManagers);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const rmaOptions = mainRma?.map((item) => {
        return { label: item?.name, value: item?._id };
    });

    useEffect(() => {
        getQueryData();
    }, [batchedParams?.country]);

    useEffect(() => {
        fetchOrders();
        fetchCountries();
    }, []);

    const orderList = orders?.map((order) => {
        return {
            key: order._id,
            orderInfo: order.orderInfo,
            batchStatus: order.batchStatus,
            userId: `${order?.userId?.firstName} ${order?.userId?.lastName}`,
            country: order.country,
            ordersDetails: order.ordersDetails,
            orderCompressed: order.orderCompressed,
            batchDate: new Date(order.batchDate).toLocaleDateString('en-US', options)
        };
    });

    const countryOption = countries?.map((result) => ({
        value: result.name,
        label: result.name
    }));

    const checkOrderStatus = (record) => {
        if (record.batchStatus === 'PreSold') {
            return <Tag color="orange">{record.orderStatus}</Tag>;
        }
        if (record.batchStatus === 'Batched') {
            return <Tag color="green">{record.batchStatus}</Tag>;
        }
        return <Tag color="gray">{record.batchStatus}</Tag>;
    };
    const viewOrderNo = (record) => {
        return (
            <div>
                {record.orderInfo?.map((order, index) => (
                    <React.Fragment key={index}>
                        <span>{order.orderNo}</span>
                        {index < record.orderInfo.length - 1 && <span style={{ color: 'orange' }}> -- </span>}
                    </React.Fragment>
                ))}
            </div>
        );
    };

    const columns = [
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country'
        },
        {
            title: 'Order Date',
            dataIndex: 'batchDate',
            key: 'batchDate'
        },
        {
            title: 'Order No',
            dataIndex: 'orderInfo',
            key: 'orderInfo',
            render: (_, record) => viewOrderNo(record)
        },
        {
            title: 'Batched By',
            dataIndex: 'userId',
            key: 'userId'
        },
        {
            title: 'Status',
            dataIndex: 'batchStatus',
            key: 'batchStatus',
            render: (_, record) => checkOrderStatus(record)
        }
    ];

    const orderCompressedColumns = [
        {
            title: 'Sku ID',
            dataIndex: 'skuId',
            key: 'skuId'
        },
        {
            title: 'Part Name',
            dataIndex: 'partid',
            key: 'partid'
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty'
        }
    ];

    const orderDetailsColumns = [
        {
            title: 'Order No',
            dataIndex: 'orderNo',
            key: 'orderNo'
        },
        {
            title: 'Part Name',
            dataIndex: 'partid',
            key: 'partid'
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty'
        },
        {
            title: 'Cost Price',
            dataIndex: 'costPrice',
            key: 'costPrice'
        },
        {
            title: 'Sales Price',
            dataIndex: 'salesPrice',
            key: 'salesPrice'
        },
        {
            title: 'Total Cost',
            dataIndex: 'totalcost',
            key: 'totalcost'
        },
        {
            title: 'Total Sales',
            dataIndex: 'totalsales',
            key: 'totalsales'
        },
        {
            title: 'Sales Person',
            dataIndex: 'salesperson',
            key: 'salesperson'
        },
        {
            title: 'Placed By',
            dataIndex: 'placedBy',
            key: 'placedBy'
        },
        {
            title: 'Placed For',
            dataIndex: 'placedFor',
            key: 'placedFor'
        }
    ];

    const onSelectChange = (SelectedRowKeysNew, selectedRows) => {
        setSelectedRowKeys(SelectedRowKeysNew);
        setSelected(selectedRows[0]);
    };
    const rowSelection = {
        type: 'radio',
        selectedRowKeys,
        onChange: onSelectChange
    };
    const hasSelected = selectedRowKeys.length > 0;

    const saveFilterToLocalStorage = (filterData) => {
        const savedParamsString = localStorage.getItem('saveBatchParams');
        let savedParams;

        try {
            savedParams = savedParamsString ? JSON.parse(savedParamsString) : {};
        } catch (error) {
            savedParams = {};
        }

        const updatedParams = { ...savedParams, ...filterData };
        localStorage.setItem('saveBatchParams', JSON.stringify(updatedParams));
    };

    const handleDateChange = (date, dateString) => {
        setFilter((prevFilter) => ({ ...prevFilter, startDate: dateString[0], endDate: dateString[1] }));
        saveFilterToLocalStorage({ startDate: dateString[0], endDate: dateString[1] });
    };

    const handleCountryChange = (value) => {
        setFilter((prevFilter) => ({ ...prevFilter, country: value }));
        saveFilterToLocalStorage({ country: value });
    };

    const handleLimitChange = (value) => {
        setFilter((prevFilter) => ({ ...prevFilter, limit: value }));
        saveFilterToLocalStorage({ limit: value });
    };

    const handleRmaChange = (value) => {
        setFilter((prevFilter) => ({ ...prevFilter, rma: value }));
        saveFilterToLocalStorage({ rma: value });
    };

    const exportDetailedOrdersHandler = () => {
        const ordersDetails = selected?.ordersDetails;
        if (ordersDetails && ordersDetails.length > 0) {
            // Create a new workbook
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(ordersDetails);
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, 'DetailedOrders.xlsx');
        } else {
            toast.error('No Detailed Orders found');
        }
    };

    const exportCompressedOrdersHandler = () => {
        const orderCompressed = selected?.orderCompressed;
        if (orderCompressed && orderCompressed.length > 0) {
            // Create a new workbook
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(orderCompressed);
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, 'CompressedOrders.xlsx');
        } else {
            toast.error('No Compressed Orders found');
        }
    };

    const handleMenuClick = (e) => {
        if (e.key === '1') {
            exportDetailedOrdersHandler();
        } else {
            exportCompressedOrdersHandler();
        }
    };

    const items = [
        {
            label: 'Download  Detailed Orders',
            key: '1'
        },
        {
            label: 'Download  Compressed Orders',
            key: '2'
        }
    ];

    const menuProps = {
        items,
        onClick: handleMenuClick
    };

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center batchFilters">
                <div>
                    <h1 style={{ fontSize: '30px' }}>PreSold Batch Orders</h1>
                    <p>
                        <strong>PreSold Batch Orders</strong> - List of all PreSold Batch Orders
                    </p>
                </div>
                <div style={{ marginBottom: 16 }} className="pt-4">
                    <div className="d-flex align-items-center flex-row-reverse ">
                        {hasSelected && (
                            <div className="d-flex batchFilters">
                                <Button
                                    disabled={!hasSelected}
                                    onClick={() => {
                                        toggleFilter(1);
                                        setBatchModal(true);
                                    }}
                                    type="primary"
                                    className="mr-2 mb-2"
                                    // style={{
                                    //     backgroundColor: !hasSelected ? '#ECECEC' : '#fff',
                                    //     border: `1px solid ${!hasSelected ? '#ECECEC' : '#FF740F'}`,
                                    //     color: !hasSelected ? '#000' : '#FF740F'
                                    // }}
                                    style={{
                                        backgroundColor: !hasSelected ? '#ECECEC' : '#fff',
                                        border: toggle === 1 ? `1px solid ${!hasSelected ? '#ECECEC' : '#FF740F'}` : 'none',
                                        color: !hasSelected ? '#000' : '#FF740F'
                                    }}
                                >
                                    View Order
                                </Button>
                                <Button
                                    disabled={!hasSelected}
                                    onClick={() => {
                                        toggleFilter(2);
                                        setBatchModal(true);
                                    }}
                                    type="primary"
                                    className="mr-2 mb-2"
                                    style={{
                                        backgroundColor: !hasSelected ? '#ECECEC' : '#fff',
                                        border: toggle === 2 ? `1px solid ${!hasSelected ? '#ECECEC' : '#FF740F'}` : 'none',
                                        color: !hasSelected ? '#000' : '#FF740F'
                                    }}
                                >
                                    View Detail Order
                                </Button>
                                <Button
                                    className="mb-2"
                                    disabled={!hasSelected}
                                    onClick={() => setBatchModalDetail(true)}
                                    type="primary"
                                    style={{
                                        backgroundColor: !hasSelected ? '#ECECEC' : '#fff',
                                        border: `1px solid ${!hasSelected ? '#ECECEC' : '#FF740F'}`,
                                        color: !hasSelected ? '#000' : '#FF740F'
                                    }}
                                >
                                    View Compressed Order
                                </Button>
                            </div>
                        )}

                        {hasSelected && (
                            <Dropdown menu={menuProps}>
                                <Button
                                    type="primary"
                                    style={{
                                        marginLeft: 8
                                    }}
                                    className="mr-2 mb-2"
                                >
                                    <Space>
                                        Export Excel
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        )}
                    </div>
                </div>
            </div>
            <div className="batchFilters bg-white p-2 mb-4">
                <RangePicker
                    format="YYYY-MM-DD"
                    className="datePicker filterInput"
                    onChange={handleDateChange}
                    value={
                        filter.startDate && filter.endDate
                            ? [dayjs(filter.startDate, 'YYYY-MM-DD'), dayjs(filter.endDate, 'YYYY-MM-DD')]
                            : localStorage.getItem('saveBatchParams') &&
                              JSON.parse(localStorage.getItem('saveBatchParams')).startDate &&
                              JSON.parse(localStorage.getItem('saveBatchParams')).endDate
                            ? [
                                  dayjs(JSON.parse(localStorage.getItem('saveBatchParams')).startDate, 'YYYY-MM-DD'),
                                  dayjs(JSON.parse(localStorage.getItem('saveBatchParams')).endDate, 'YYYY-MM-DD')
                              ]
                            : null
                    }
                />

                <div>
                    <Select
                        showSearch
                        allowClear
                        placeholder="Filter By Country"
                        filterOption={(inputValue, option) => {
                            if (typeof option.label === 'string') {
                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }

                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        }}
                        className=" p-2 filterInput"
                        options={countryOption}
                        onChange={handleCountryChange}
                        value={
                            filter.country
                                ? filter.country
                                : localStorage.getItem('saveBatchParams')
                                ? JSON.parse(localStorage.getItem('saveBatchParams')).country
                                : null
                        }
                    />
                </div>

                <div>
                    <Select
                        showSearch
                        allowClear
                        placeholder="Filter By Rma"
                        filterOption={(inputValue, option) => {
                            if (typeof option.label === 'string') {
                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }

                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        }}
                        className=" p-2 filterInput"
                        options={rmaOptions}
                        onChange={handleRmaChange}
                        value={
                            filter.rma
                                ? filter.rma
                                : localStorage.getItem('saveBatchParams')
                                ? JSON.parse(localStorage.getItem('saveBatchParams')).rma
                                : null
                        }
                    />
                </div>
                <div>
                    <Select
                        className="p-2 filterInput"
                        placeholder="Select Limit"
                        options={limitOptions}
                        value={
                            filter.limit
                                ? filter.limit
                                : localStorage.getItem('saveBatchParams')
                                ? JSON.parse(localStorage.getItem('saveBatchParams')).limit
                                : null
                        }
                        onChange={handleLimitChange}
                    />
                </div>
                <div className="d-flex">
                    <Button
                        className="mr-2"
                        style={{
                            color: '#fff',
                            border: '1px solid #FF740F',
                            backgroundColor: '#FF740F',
                            width: '150px'
                        }}
                        onClick={() => fetchOrders(filter)}
                    >
                        Search
                    </Button>
                    {localStorage.getItem('saveBatchParams') && (
                        <Button
                            onClick={() => {
                                localStorage.removeItem('saveBatchParams');
                                setFilter(initialState);
                                fetchOrders();
                            }}
                            className="filterInput"
                            style={{
                                color: '#FF740F',
                                border: '1px solid #FF740F',
                                width: '150px'
                            }}
                        >
                            Clear Filters
                        </Button>
                    )}
                </div>
            </div>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                    <Skeleton />
                </div>
            ) : (
                <div className={toggle === 1 ? 'active-assigned-content table-responsive' : 'disable-assigned-content'}>
                    <Table
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={orderList}
                        style={{
                            width: '100%'
                        }}
                    />
                </div>
            )}
            <Modal
                width={800}
                title="Compressed Orders View"
                open={batchModalDetail}
                onCancel={() => {
                    setBatchModalDetail(false);
                }}
                footer={null}
            >
                <div>
                    <div className="productsContainer orderListContainer">
                        <div className="table-responsive">
                            <Table
                                rowKey={(record) => record._id}
                                columns={orderCompressedColumns}
                                dataSource={selected?.orderCompressed}
                                pagination={false}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                    <Skeleton />
                </div>
            ) : (
                <div className={toggle === 2 ? 'active-assigned-content table-responsive' : 'disable-assigned-content'}>
                    {/* <div className="productsContainer orderListContainer"> */}
                    {/* <div className="table-responsive"> */}
                    <Table
                        rowKey={(record) => record._id}
                        columns={orderDetailsColumns}
                        dataSource={selected?.ordersDetails}
                        pagination={false}
                        style={{
                            width: '100%'
                        }}
                    />
                    {/* </div> */}
                    {/* </div> */}
                </div>
            )}
            {/* <Modal
                width={900}
                title="Detail Orders View"
                open={batchModal}
                onCancel={() => {
                    setBatchModal(false);
                }}
                footer={null}
            >
                <div>
                    <div className="productsContainer orderListContainer">
                        <div className="table-responsive">
                            <Table
                                rowKey={(record) => record._id}
                                columns={orderDetailsColumns}
                                dataSource={selected?.ordersDetails}
                                pagination={false}
                            />
                        </div>
                    </div>
                </div>
            </Modal> */}
        </div>
    );
};
export default Index;
