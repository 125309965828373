/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import logo from 'assets/images/garage.png';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import MainCard from 'ui-component/cards/MainCard';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ApiUrl from 'network';
import useScriptRef from 'hooks/useScriptRef';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import EditIcon from '@mui/icons-material/Edit';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import Form from 'react-bootstrap/Form';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import nologo from 'assets/images/nologo.png';

const Input = styled('input')({
    display: 'none'
});

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    }
}));

const ViewMyOrder = () => {
    const classes = useStyles();
    const history = useNavigate();
    const [depositeDate, setdepositeDate] = useState(new Date());
    const [referenceNote, setreferenceNote] = useState('');
    const [depositeAmt, setdepositAmt] = useState('');
    const [depositeImage, setdepositeImage] = useState('');
    const [paymentType, setpaymentType] = useState('');
    const [loading3, setLoading3] = useState(false);
    const [channelPartnerId, setChannelPartnerRecord] = useState('');
    const [salesname, setsalesname] = useState('');
    const [salesemail, setsalesemail] = useState('');
    const [approveStatus, setapproveStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [collectiondatas, setCollectiondatas] = useState([]);
    const [collectedDate, setcollectedDate] = useState(new Date());
    const [orderDate, setorderDate] = useState(new Date());
    const [collectedAmt, setcollectedAmt] = useState('');
    const [open, setOpen] = useState(false);
    const [indexValue, setIndexValue] = useState('');
    const [open2, setOpen2] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { id } = useParams();

    const scriptedRef = useScriptRef();

    const selectedFile = (e) => {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        setLoading3(true);
        const userInfo = localStorage.getItem('userToken');
        axios
            .post(ApiUrl.UploadCollectionImageUrl, formData, {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => {
                if (result.data.status === true) {
                    setLoading3(false);
                    setdepositeImage(result?.data?.data ?? '');
                } else {
                    toast.error(result.data);
                    setLoading3(false);
                }
            })
            .catch((err) => {
                toast.error(err);
                setLoading3(false);
            });
    };

    const handlePaymentTypeChange = (event) => {
        setpaymentType(event.target.value);
        const useremail = localStorage.getItem('userEmail');
        setsalesemail(useremail);
    };

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.ViewOrdersUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setChannelPartnerRecord(result?.data?.newChannelPartnerId?.partnerName ?? '');
                    const fullname = `${result?.data?.salesTeamId?.firstName}${result?.data?.salesTeamId?.firstName}`;
                    setsalesname(result?.data?.salesTeamId?.name ?? fullname);
                    setorderDate(result?.data?.orderDate ?? '');
                    setCollectiondatas(result?.data?.collectiondatas ?? []);

                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                    // setLoading(false);
                }
            })
            .finally(() => {
                setLoading2(false);
            });
    }, [id]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    const onSubmitCollection = (event) => {
        event.preventDefault();
        // items, setItems
        setCollectiondatas([
            ...collectiondatas,
            {
                id,
                channelPartnerId,
                salesname,
                orderDate,
                depositeDate,
                collectedDate,
                paymentType,
                collectedAmt,
                depositeAmt,
                referenceNote,
                depositeImage,
                approveStatus,
                salesemail
            }
        ]);

        handleClose();
    };

    const onSubmitEditCollection = (event) => {
        event.preventDefault();
        const newList = Object.assign([], collectiondatas);
        const data = { ...newList[indexValue] };
        data.collectedDate = collectedDate;
        data.collectedAmt = collectedAmt;
        data.depositeDate = depositeDate;
        data.depositeAmt = depositeAmt;
        data.paymentType = paymentType;
        data.approveStatus = approveStatus;
        data.depositeImage = depositeImage;
        data.salesname = salesname;
        data.salesemail = salesemail;
        data.channelPartnerId = channelPartnerId;
        data.referenceNote = referenceNote;
        data.orderDate = orderDate;
        newList[indexValue] = data;
        setCollectiondatas(newList);
        handleClose2();
    };

    const handleDepositeDateChange = (newValue) => {
        setdepositeDate(newValue);
    };

    const handleCollectedDateChange = (newValue) => {
        setcollectedDate(newValue);
    };

    const handleDeleteClick = (row, index) => {
        const newList = Object.assign([], collectiondatas);
        newList.splice(index, 1);
        setCollectiondatas(newList);
    };

    const handleEditClick = (row, index) => {
        // console.log('row', row);
        setcollectedDate(row.collectedDate ?? '');
        setcollectedAmt(row.collectedAmt ?? '');
        setdepositeDate(row?.depositeDate ?? '');
        setdepositAmt(row?.depositeAmt ?? '');
        setpaymentType(row?.paymentType ?? '');
        setapproveStatus(row?.approveStatus ?? '');
        setdepositeImage(row?.depositeImage ?? '');
        setsalesname(row?.salesname ?? '');
        setsalesemail(row?.salesemail ?? '');
        setChannelPartnerRecord(row?.channelPartnerId ?? '');
        setreferenceNote(row?.referenceNote ?? '');
        setorderDate(row?.orderDate ?? '');
        handleClickOpen2();
        setIndexValue(index);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const useremail = localStorage.getItem('userEmail');

        const data = {
            id,
            collectiondatas,
            channelPartnerId,
            salesname,
            orderDate,
            collectedDate,
            depositeDate,
            paymentType,
            collectedAmt,
            depositeAmt,
            referenceNote,
            depositeImage,
            approveStatus,
            useremail
        };

        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        fetch(ApiUrl.AddCollectionDataUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            // window.location.reload();
                            history('/mysalesorder');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    {/* <AuthWrapper1> */}
                    <MainCard title="">
                        <Grid container justify="flex-start">
                            <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                            <Grid item xs={12}>
                                {/* <Grid container> */}
                                <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                                    {/* <AuthCardWrapper> */}
                                    <Grid container spacing={2}>
                                        {/* <Grid item sx={{ mb: 3 }} justifyContent="center">
                                            <Link to="#">
                                                <img src={logo} alt="logo" height={50} />
                                            </Link>
                                        </Grid> */}

                                        <Grid item xs={12}>
                                            <Typography variant="h3" component="h2" textAlign="center">
                                                Add Collection Form
                                            </Typography>
                                            <br />

                                            <Typography variant="h5" component="h2" textAlign="center">
                                                Use this form to input collection from
                                            </Typography>

                                            <Typography variant="h3" component="h2" textAlign="center">
                                                {channelPartnerId}{' '}
                                            </Typography>

                                            <Typography variant="h5" component="h4" textAlign="center">
                                                for this order
                                            </Typography>

                                            <br />
                                            <form onSubmit={handleSubmit}>
                                                <br />

                                                <Box display="flex" flexDirection="row-reverse">
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleClickOpen}
                                                        size="sm"
                                                        className={classes.item}
                                                    >
                                                        Add
                                                    </Button>
                                                </Box>
                                                <br />

                                                <Grid container spacing={2} justify="flex-start">
                                                    <Grid item xs={12} sm={6} md={4} lg={12}>
                                                        <TableContainer component={Paper}>
                                                            <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                                            Collected Date
                                                                        </TableCell>
                                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                                            Deposite Date
                                                                        </TableCell>

                                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                                            Payment Type
                                                                        </TableCell>
                                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                                            Collected Amount
                                                                        </TableCell>
                                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                                            Deposited Amount
                                                                        </TableCell>
                                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                                            Status
                                                                        </TableCell>

                                                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                                                            Action
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {collectiondatas.map((row, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell component="th" scope="row">
                                                                                {toTimestamp(row?.collectedDate)}
                                                                            </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {toTimestamp(row?.depositeDate)}
                                                                            </TableCell>

                                                                            <TableCell component="th" scope="row">
                                                                                {row?.paymentType ?? ''}
                                                                            </TableCell>

                                                                            <TableCell component="th" scope="row">
                                                                                {row?.collectedAmt ?? ''}
                                                                            </TableCell>

                                                                            <TableCell component="th" scope="row">
                                                                                {row?.depositeAmt ?? ''}
                                                                            </TableCell>

                                                                            <TableCell component="th" scope="row">
                                                                                {row?.approveStatus ?? ''}
                                                                            </TableCell>

                                                                            <TableCell component="th" scope="row">
                                                                                <IconButton
                                                                                    aria-label="edit"
                                                                                    color="primary"
                                                                                    onClick={() => handleEditClick(row, index)}
                                                                                >
                                                                                    <EditIcon />
                                                                                </IconButton>
                                                                                {row?.approveStatus === 'approved' ? (
                                                                                    ''
                                                                                ) : (
                                                                                    <IconButton
                                                                                        aria-label="delete"
                                                                                        color="error"
                                                                                        onClick={() => handleDeleteClick(row, index)}
                                                                                    >
                                                                                        <DeleteIcon />
                                                                                    </IconButton>
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                </Grid>

                                                <br />
                                                <br />
                                                <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                                    <Button type="submit" variant="contained" color="secondary">
                                                        {loading ? 'Saving...' : 'Save'}
                                                    </Button>
                                                    <Button
                                                        component={Link}
                                                        to="/mysalesorder"
                                                        type="submit"
                                                        variant="contained"
                                                        color="error"
                                                    >
                                                        Reset
                                                    </Button>
                                                </Box>
                                            </form>
                                        </Grid>
                                    </Grid>
                                    {/* </AuthCardWrapper> */}
                                </Grid>
                                {/* </Grid> */}

                                <Dialog
                                    fullScreen={fullScreen}
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="responsive-dialog-title"
                                    fullWidth
                                >
                                    <div style={{ padding: '1rem 2rem' }}>
                                        <DialogTitle id="responsive-dialog-title" style={{ fontSize: '1rem' }}>
                                            Add Collection
                                        </DialogTitle>
                                        <Form onSubmit={onSubmitCollection}>
                                            <DialogContent>
                                                <Form.Group controlId="formFile" className="mb-3">
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <Stack spacing={3}>
                                                            <DesktopDatePicker
                                                                fullWidth
                                                                label="Collected Date"
                                                                inputFormat="MM/dd/yyyy"
                                                                value={collectedDate}
                                                                onChange={handleCollectedDateChange}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </Stack>
                                                    </LocalizationProvider>
                                                    <br /> <br />
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <Stack spacing={3}>
                                                            <DesktopDatePicker
                                                                fullWidth
                                                                label="Deposit Date"
                                                                inputFormat="MM/dd/yyyy"
                                                                value={depositeDate}
                                                                onChange={handleDepositeDateChange}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </Stack>
                                                    </LocalizationProvider>
                                                    <br /> <br />
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Payment Type</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={paymentType}
                                                            fullWidth
                                                            label="Status"
                                                            onChange={handlePaymentTypeChange}
                                                        >
                                                            <MenuItem value="Body">Select an option</MenuItem>
                                                            <MenuItem value="MoMo">MoMo</MenuItem>
                                                            <MenuItem value="Bank">Bank</MenuItem>
                                                            <MenuItem value="Cash">Cash</MenuItem>
                                                            <MenuItem value="Cheque">Cheque</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <br /> <br />
                                                    <TextField
                                                        autoComplete="off"
                                                        fullWidth
                                                        name="collectedAmt"
                                                        variant="outlined"
                                                        required
                                                        value={collectedAmt}
                                                        id="collectedAmt"
                                                        label="Collected Amount"
                                                        onChange={(e) => {
                                                            setcollectedAmt(e.target.value);
                                                            setapproveStatus('pending');
                                                        }}
                                                    />
                                                    <br /> <br />
                                                    <TextField
                                                        autoComplete="off"
                                                        name="depositeAmt"
                                                        variant="outlined"
                                                        value={depositeAmt}
                                                        fullWidth
                                                        id="depositeAmt"
                                                        label="Deposit Amount"
                                                        onChange={(e) => {
                                                            setdepositAmt(e.target.value);
                                                            setapproveStatus('pending');
                                                            const date = new Date();

                                                            setdepositeDate(date);
                                                            console.log('data', date);
                                                        }}
                                                    />
                                                    <br /> <br />
                                                    <TextField
                                                        autoComplete="off"
                                                        name="referenceNote"
                                                        fullWidth
                                                        variant="outlined"
                                                        multiline
                                                        rows={3}
                                                        value={referenceNote}
                                                        id="referenceNote"
                                                        label="Reference Notes......."
                                                        onChange={(e) => setreferenceNote(e.target.value)}
                                                    />
                                                    <br />
                                                    <br />
                                                    <InputLabel id="demo-simple-select-label">Attach Image (Optional)</InputLabel>
                                                    <label htmlFor="contained-button-file">
                                                        <Input
                                                            accept="image/*"
                                                            id="contained-button-file"
                                                            multiple
                                                            type="file"
                                                            onChange={selectedFile}
                                                        />
                                                        <br />
                                                        <Button variant="contained" component="span" disabled={loading3}>
                                                            {loading3 ? 'Uploading ...' : 'Upload'}
                                                        </Button>
                                                    </label>
                                                    <br />
                                                    <br />
                                                    <Avatar
                                                        variant="rounded"
                                                        src={depositeImage ?? nologo}
                                                        style={{ border: 0, objectFit: 'cover' }}
                                                        sx={{ width: '100%', height: '400px' }}
                                                    />
                                                </Form.Group>
                                            </DialogContent>
                                            <DialogActions
                                                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    size="large"
                                                    className={classes.item}
                                                    autoFocus
                                                    onClick={handleClose}
                                                    color="error"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button autoFocus variant="contained" type="submit" size="large" className={classes.item}>
                                                    Add
                                                </Button>
                                            </DialogActions>
                                        </Form>
                                    </div>
                                </Dialog>

                                {/* Edit Collections */}
                                <Dialog
                                    fullScreen={fullScreen}
                                    open={open2}
                                    onClose={handleClose2}
                                    aria-labelledby="responsive-dialog-title"
                                    fullWidth
                                >
                                    <div style={{ padding: '1rem 2rem' }}>
                                        <DialogTitle id="responsive-dialog-title" style={{ fontSize: '1rem' }}>
                                            Edit Collection
                                        </DialogTitle>
                                        <Form onSubmit={onSubmitEditCollection}>
                                            <DialogContent>
                                                <Form.Group controlId="formFile" className="mb-3">
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <Stack spacing={3}>
                                                            <DesktopDatePicker
                                                                fullWidth
                                                                label="Collected Date"
                                                                inputFormat="MM/dd/yyyy"
                                                                value={collectedDate}
                                                                onChange={handleCollectedDateChange}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </Stack>
                                                    </LocalizationProvider>
                                                    <br /> <br />
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <Stack spacing={3}>
                                                            <DesktopDatePicker
                                                                fullWidth
                                                                label="Deposit Date"
                                                                inputFormat="MM/dd/yyyy"
                                                                value={depositeDate}
                                                                onChange={handleDepositeDateChange}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </Stack>
                                                    </LocalizationProvider>
                                                    <br /> <br />
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Payment Type</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={paymentType}
                                                            label="Status"
                                                            onChange={handlePaymentTypeChange}
                                                            fullWidth
                                                        >
                                                            <MenuItem value="Body">Select an option</MenuItem>
                                                            <MenuItem value="MoMo">MoMo</MenuItem>
                                                            <MenuItem value="Bank">Bank</MenuItem>
                                                            <MenuItem value="Cash">Cash</MenuItem>
                                                            <MenuItem value="Cheque">Cheque</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <br /> <br />
                                                    <TextField
                                                        autoComplete="off"
                                                        name="collectedAmt"
                                                        fullWidth
                                                        variant="outlined"
                                                        required
                                                        value={collectedAmt}
                                                        id="collectedAmt"
                                                        label="Collected Amount"
                                                        onChange={(e) => {
                                                            setcollectedAmt(e.target.value);
                                                            setapproveStatus('pending');
                                                        }}
                                                    />
                                                    <br /> <br />
                                                    <TextField
                                                        autoComplete="off"
                                                        name="depositeAmt"
                                                        fullWidth
                                                        variant="outlined"
                                                        value={depositeAmt}
                                                        id="depositeAmt"
                                                        label="Deposit Amount"
                                                        onChange={(e) => {
                                                            setdepositAmt(e.target.value);
                                                            setapproveStatus('pending');
                                                            const date = new Date();

                                                            setdepositeDate(date);
                                                            console.log('data', date);
                                                        }}
                                                    />
                                                    <br /> <br />
                                                    <TextField
                                                        autoComplete="off"
                                                        name="referenceNote"
                                                        variant="outlined"
                                                        fullWidth
                                                        multiline
                                                        rows={3}
                                                        value={referenceNote}
                                                        id="referenceNote"
                                                        label="Reference Notes......."
                                                        onChange={(e) => setreferenceNote(e.target.value)}
                                                    />
                                                    <br />
                                                    <br />
                                                    <InputLabel id="demo-simple-select-label">Attach Image (Optional)</InputLabel>
                                                    <label htmlFor="contained-button-file">
                                                        <Input
                                                            accept="image/*"
                                                            id="contained-button-file"
                                                            multiple
                                                            type="file"
                                                            onChange={selectedFile}
                                                        />
                                                        <br />
                                                        <Button variant="contained" component="span" disabled={loading3}>
                                                            {loading3 ? 'Uploading ...' : 'Upload'}
                                                        </Button>
                                                    </label>
                                                    <br />
                                                    <br />
                                                    <Stack direction="row" alignItems="center" spacing={4}>
                                                        <Avatar
                                                            variant="rounded"
                                                            src={depositeImage ?? nologo}
                                                            style={{ border: 0, objectFit: 'cover' }}
                                                            sx={{ width: '100%', height: '400px' }}
                                                        />
                                                    </Stack>
                                                </Form.Group>
                                            </DialogContent>
                                            <DialogActions
                                                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    size="sm"
                                                    className={classes.item}
                                                    autoFocus
                                                    onClick={handleClose2}
                                                    color="error"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button autoFocus variant="contained" type="submit" size="sm" className={classes.item}>
                                                    Update
                                                </Button>
                                            </DialogActions>
                                        </Form>
                                    </div>
                                </Dialog>
                            </Grid>
                        </Grid>
                    </MainCard>
                    {/* </AuthWrapper1> */}
                </>
            )}
        </>
    );
};

export default ViewMyOrder;
