import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Search from '@mui/icons-material/Search';
import { Row } from 'react-grid-system';
import { InputAdornment, TextField } from '@mui/material';
import { MdEdit } from 'react-icons/md';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import TablePaginationActions from 'components/TablePaginationActions';
import styled from 'styled-components';
import { useDarkStoreContext } from 'context/darkstore-inventory/darkstore-context';
import { useParams } from 'react-router';
import NotFound from '../../assets/images/garage.jpg';
import Loader from 'components/Loader';
import EditPartModal from './EditPartModal';

const Wrapper = styled.div`
    padding: 1rem 2rem;

    @media screen and (min-width: 768px) {
        .search {
            width: 25rem;
        }
    }
`;

const StockTable = ({ th1, th2, th3, th4, th5, th6, th7 }) => {
    const { storeInventory, getInventoryById, isLoading, dispatch } = useDarkStoreContext();

    const { id } = useParams();

    useEffect(() => {
        getInventoryById(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const useStyles = makeStyles(() => ({
        tableHeaderCell: {
            fontWeight: 'bolder'
        }
    }));
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - storeInventory.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [store, setStore] = useState('');
    const filteredStores = storeInventory.filter((el) => el?.partId?.sku?.toLowerCase().includes(store.toLowerCase()));

    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className="lead__main-table" elevation={0}>
                <Row>
                    <Wrapper>
                        <TextField
                            label="Search Part Inventory"
                            type="search"
                            className="search"
                            onChange={(e) => setStore(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton>
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Wrapper>
                </Row>
                <>
                    <TableContainer sx={{ maxHeight: 800 }}>
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th1}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th2}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th3}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th4}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th5}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th6}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            {th7}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? filteredStores.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : filteredStores
                                    ).map((row, index = 1) => {
                                        const {
                                            _id,
                                            expectedCash,
                                            garagePrice,
                                            wholeSalePrice,
                                            qty,
                                            darkstoreAreaId,
                                            partId: { _id: productId, sku, partsImage }
                                        } = row;

                                        return (
                                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell align="left" scope="row">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    <img src={partsImage || NotFound} alt="Product" width="160" height="80" />
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    {sku}
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    {garagePrice}
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    {qty}
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    {expectedCash.toFixed(2)}
                                                </TableCell>
                                                <TableCell align="left" scope="row">
                                                    <Stack direction="row" spacing={1}>
                                                        <IconButton
                                                            aria-label="preview"
                                                            style={{ color: '#ff740f' }}
                                                            title="Edit Stock"
                                                            onClick={() => {
                                                                handleShow();
                                                                dispatch({
                                                                    type: 'UPDATE_INVENTORY_STOCK',
                                                                    payload: {
                                                                        wholeSalePrice,
                                                                        garagePrice,
                                                                        editId: _id,
                                                                        partId: productId,
                                                                        qty,
                                                                        product: sku
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            <MdEdit />
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="preview"
                                                            href={`/inventory/darkstores/history/${productId}?&darkstore=${darkstoreAreaId?._id}`}
                                                            style={{ color: '#76BA99' }}
                                                            title="Preview stock history"
                                                        >
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        )}
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={storeInventory.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        component="div"
                        SelectProps={{
                            inputProps: {
                                'aria-label': 'rows per page'
                            },
                            native: true
                        }}
                        className="custom-paginator"
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                    <EditPartModal handleClose={handleClose} show={show} />
                </>
            </Paper>
        </>
    );
};

export default StockTable;
