import { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
import ApiUrl from 'network';
import { useNavigate, useParams } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from '@mui/material';

// third party  useMediaQuery
// import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import Google from 'assets/images/icons/social-google.svg';

// ============================|| - LOGIN ||============================ //

const ResetPassword = () => {
    const theme = useTheme();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    // const [checked, setChecked] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const { id } = useParams();

    useEffect(() => {
        // console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && loading) {
            // console.log(email, password);
        }
    }, [formErrors, loading]);
    const validated = (password) => {
        const errors = {};
        if (!password) {
            errors.password = 'Password is required!';
        } else if (password.length < 6) {
            errors.password = 'Password must be more than 6 characters!';
        }

        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setFormErrors(validated(password));
        setLoading(true);
        const data = {
            password
        };
        fetch(ApiUrl.NewPasswordUrl, {
            method: 'POST',
            headers: {
                'auth-token': id,
                Accept: 'application/form-data',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status) {
                    toast.success(result.message);
                    setLoading(false);

                    setTimeout(() => {
                        if (scriptedRef.current) {
                            history('/login');
                        }
                    }, 3000);
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {/* Same as */}
                <ToastContainer />
                {/* <Grid item xs={12}>
                    <AnimateButton>
                        <Button
                            disableElevation
                            fullWidth
                            onClick={googleHandler}
                            size="large"
                            variant="outlined"
                            sx={{
                                color: 'grey.700',
                                backgroundColor: theme.palette.grey[50],
                                borderColor: theme.palette.grey[100]
                            }}
                        >
                            <Box sx={{ mr: { xs: 1, sm: 2, width: 20 } }}>
                                <img src={Google} alt="google" width={16} height={16} style={{ marginRight: matchDownSM ? 8 : 16 }} />
                            </Box>
                            Sign in with Google
                        </Button>
                    </AnimateButton>
                </Grid> */}
                <Grid item xs={12}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />

                        {/* <Button
                            variant="outlined"
                            sx={{
                                cursor: 'unset',
                                m: 2,
                                py: 0.5,
                                px: 7,
                                borderColor: `${theme.palette.grey[100]} !important`,
                                color: `${theme.palette.grey[900]}!important`,
                                fontWeight: 500,
                                borderRadius: `${customization.borderRadius}px`
                            }}
                            disableRipple
                            disabled
                        >
                            OR
                        </Button> */}

                        <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
                    </Box>
                </Grid>
                {/* <Grid item xs={12} container alignItems="center" justifyContent="center">
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">Sign in with Email address</Typography>
                    </Box>
                </Grid> */}
            </Grid>

            <Formik
            // initialValues={{
            //     email: 'info@garagemobility.com',
            //     password: '123456',
            //     submit: null
            // }}

            // onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            //     try {
            //         if (scriptedRef.current) {
            //             setStatus({ success: true });
            //             setSubmitting(false);
            //         }
            //     } catch (err) {
            //         console.error(err);
            //         if (scriptedRef.current) {
            //             setStatus({ success: false });
            //             setErrors({ submit: err.message });
            //             setSubmitting(false); handleChange, handleSubmit,values
            //         }
            //     }
            // }}
            >
                {({ errors, handleBlur, touched }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.password && errors.password)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-password-login">New Password</InputLabel>
                            <OutlinedInput
                                autoComplete="off"
                                id="outlined-adornment-password-login"
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                value={password}
                                onBlur={handleBlur}
                                onChange={(e) => setPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="New Password"
                                inputProps={{}}
                            />

                            <FormHelperText error id="standard-weight-helper-text-password-login">
                                {formErrors.password}
                            </FormHelperText>
                        </FormControl>

                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    // disabled={!email || !password || loading}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                >
                                    {loading ? 'Submitting ..' : 'Submit'}
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default ResetPassword;
