import React, { useEffect, useState } from 'react';

import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useNavigate, useLocation } from 'react-router';

const Navigation = ({ isOpenDelete }) => {
    const navigate = useNavigate();

    const location = useLocation();
    const currentUrl = location.pathname;

    const [cartItems, setCartItems] = useState([]);
    const storedCartItems = localStorage.getItem('cartItems');

    useEffect(() => {
        if (storedCartItems) {
            setCartItems(JSON.parse(storedCartItems));
        }
    }, [storedCartItems]);

    return (
        <div className="bottom-navigation">
            <div className="bottom-nav-container">
                <div
                    className="shop-button"
                    style={{
                        backgroundColor: currentUrl === '/whatsapporder/homepage' ? '#f5880e' : 'transparent',
                        color: currentUrl === '/whatsapporder/homepage' ? '#fff' : '#0A2938'
                    }}
                    role="button"
                    onClick={() => {
                        navigate('/whatsapporder/homepage');
                    }}
                    tabIndex={-1}
                    onKeyDown={() => {}}
                >
                    <HomeIcon
                        sx={{
                            fontSize: '22px'
                        }}
                    />
                    <span>Shop</span>
                </div>
                <div
                    className="cart-button"
                    style={{
                        backgroundColor: currentUrl === '/whatsapporder/product/cart' ? '#f5880e' : 'transparent',
                        color: currentUrl === '/whatsapporder/product/cart' ? '#fff' : '#0A2938',
                        paddingRight: cartItems.length === 0 ? '8px' : '25px'
                    }}
                    role="button"
                    onClick={() => {
                        navigate('/whatsapporder/product/cart');
                    }}
                    tabIndex={-1}
                    onKeyDown={() => {}}
                >
                    <ShoppingCartIcon
                        sx={{
                            fontSize: '22px'
                        }}
                    />
                    <span>Cart </span>
                    {cartItems.length > 0 ? (
                        <span
                            className={
                                currentUrl === '/whatsapporder/product/cart'
                                    ? 'cart-button-quantity-active'
                                    : 'cart-button-quantity-disabled'
                            }
                        >
                            {cartItems.length}
                        </span>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default Navigation;
