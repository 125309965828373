/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';
import Controls from '../../components/controls/Controls';
import { Search } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import * as XLSX from 'xlsx';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
// import ViewIcon from '@mui/icons-material/ViewAgenda';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
// import swal from 'sweetalert';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import ViewIcon from '@mui/icons-material/Preview';
import Tooltip from '@mui/material/Tooltip';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

// import { saveAs } from 'file-saver';
// import axios from 'axios';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    colors: {
        color: `#ED7C1B`
    },
    backgroundColor: {
        backgroundColor: '#2CB23B'
    }
}));

const Collection = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [orderInfo, setOrder] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    // const [loading2, setLoading2] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalcollection, setTotalcollection] = useState(0);
    // const [totalapproved, setTotalapproved] = useState(0);
    const userCurrency = localStorage.getItem('userCurrency');

    const handlePriceChangesClick = (row) => {
        if (scriptedRef.current) {
            history(`/viewcollectionorders/${row.id}`);
        }
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 30));
        setPage(0);
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderInfo.length) : 0;

    const retrieveAllOrders = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.CollectionDataUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        // console.log(data.data);
                        setOrder(data.data);
                        setOrderData(data.data);

                        let collectedsum = 0;
                        // let approvedsum = 0;
                        data.data.forEach((element) => {
                            collectedsum += Number.isFinite(element?.collectedAmt) ? Number(element?.collectedAmt) : 0;
                        });
                        setTotalcollection(Number(collectedsum).toFixed(2));

                        // data.data.forEach((element) => {
                        //     approvedsum += Number.isFinite(element?.depositeAmt) ? Number(element?.depositeAmt) : 0;
                        // });
                        // setTotalapproved(Number(approvedsum).toFixed(2));
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        retrieveAllOrders();
    }, []);

    // const handleDeleteClick = (row) => {
    //     swal({
    //         title: 'Are you sure?',
    //         text: `Once deleted, you will not be able to recover this Order !(${row._id})`,
    //         icon: 'warning',
    //         buttons: true,
    //         dangerMode: true
    //     }).then((willDelete) => {
    //         if (willDelete) {
    //             swal('Poof! The Order has been deleted!', {
    //                 icon: 'success'
    //             });

    //             // const data = {
    //             //     id: row._id
    //             // };
    //             // const userInfo = localStorage.getItem('userToken');

    //             // fetch(ApiUrl.DeleteChannelPartnerUrl, {
    //             //     method: 'DELETE',
    //             //     headers: {
    //             //         Authorization: userInfo,
    //             //         'Content-Type': 'application/json'
    //             //     },
    //             //     body: JSON.stringify(data)
    //             // })
    //             //     .then((res) => res.json())
    //             //     .then((result) => {
    //             //         if (result.status === true) {
    //             //             if (scriptedRef.current) {
    //             //                 retrieveAllOrders();
    //             //                 // window.location.href = '/stage';
    //             //                 // history('/stage');
    //             //             }
    //             //         } else {
    //             //             toast.error(result.data);
    //             //         }
    //             //     });
    //         } else {
    //             swal('The  Order is safe!');
    //         }
    //     });
    // };

    const setChange = (event, picker) => {
        console.log('startDate', picker.startDate._d);
        console.log('endDate', picker.endDate._d);
        setstartDate(picker.startDate._d);
        setendDate(picker.endDate._d);
        const userInfo = localStorage.getItem('userToken');
        const from = picker.startDate._d;
        const to = picker.endDate._d;
        setLoading(true);
        const data = {
            from,
            to
        };
        fetch(ApiUrl.FilteredCollectionDataUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading(false);
                    setOrder(result.data);
                    setOrderData(result.data);
                    let collectedsum = 0;
                    // let approvedsum = 0;
                    result.data.forEach((element) => {
                        collectedsum += Number.isFinite(element?.collectedAmt) ? Number(element?.collectedAmt) : 0;
                    });
                    setTotalcollection(Number(collectedsum).toFixed(2));

                    // result.data.forEach((element) => {
                    //     approvedsum += Number.isFinite(element?.depositeAmt) ? Number(element?.depositeAmt) : 0;
                    // });
                    // setTotalapproved(Number(approvedsum).toFixed(2));
                } else {
                    toast.error(result.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleReset = () => {
        retrieveAllOrders();
    };

    const handledataexport = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(orderInfo);
        XLSX.utils.book_append_sheet(wb, ws, 'Collections');
        XLSX.writeFile(wb, 'Collections.xlsx');
    };

    const handleSearch = (event) => {
        const searchWord = event.target.value;

        const newFilter = orderInfo.filter((value) => (value?.salesname || '').toLowerCase().includes((searchWord || '').toLowerCase()));

        if (searchWord === '') {
            setOrder(orderData);
        } else {
            setOrder(newFilter);
        }
    };

    return (
        <>
            <br />
            <PageHeader title="Collections" subTitle="Home | Collections " />
            <br />

            <MainCard title="Collection List">
                <Grid container spacing={2} justify="flex-start">
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography variant="h3" component="div" className={classes.colors}>
                            {totalcollection} {userCurrency}
                        </Typography>
                    </Grid>

                    {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography variant="h3" component="div" className={classes.colors}>
                            {totalapproved} GHC
                        </Typography>
                    </Grid> */}
                </Grid>
                <br />

                <Grid container spacing={2} justify="flex-start">
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography variant="subtitle2" component="div">
                            Total Collection Value
                        </Typography>
                    </Grid>

                    {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography variant="subtitle2" component="div">
                            Total Deposited Value
                        </Typography>
                    </Grid> */}
                </Grid>
                <br />
                <br />

                <Box display="flex" justifyContent="flex-start">
                    <Controls.Input
                        label="Search Sales Person"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearch}
                    />
                    <Box width={20} />

                    <br />
                    <DateRangePicker initialSettings={{ startDate, endDate }} onApply={setChange}>
                        <Button variant="contained" size="sm" className={classes.item}>
                            filter by Date
                        </Button>
                    </DateRangePicker>
                    <Box width={20} />
                    <Button variant="contained" onClick={handleReset} size="sm" className={classes.item} color="error">
                        Reset Filter
                    </Button>

                    <Box width={20} />
                    <Button onClick={handledataexport} variant="contained" size="sm" className={classes.backgroundColor}>
                        Download Excel
                    </Button>
                </Box>

                <br />

                <TableContainer component={Paper}>
                    <Table aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress size={20} thickness={4} />
                                        {'LOADING...... '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Collected Date
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Collected Amount
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Channel Partner
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Sales Person
                                        </TableCell>

                                        {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Deposited Date
                                        </TableCell> */}

                                        {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Deposited Amount
                                        </TableCell> */}

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Status
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 ? orderInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : orderInfo).map(
                                (row, index) => (
                                    <TableRow key={row._id}>
                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row?.collectedDate ? toTimestamp(row?.collectedDate) : ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {Number.isFinite(row?.collectedAmt) ? Number(row?.collectedAmt).toFixed(2) : 0}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row?.channelPartnerId ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.salesname ?? ''}
                                        </TableCell>
                                        {/* <TableCell component="th" scope="row">
                                            {row?.depositeDate ? toTimestamp(row?.depositeDate) : ''}
                                        </TableCell> */}

                                        {/* <TableCell component="th" scope="row">
                                            {Number.isFinite(row?.depositeAmt) ? Number(row?.depositeAmt).toFixed(2) : 0}
                                        </TableCell> */}

                                        <TableCell component="th" scope="row">
                                            {row?.approveStatus ?? ''}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            <Tooltip title="View Collection Details">
                                                <IconButton aria-label="view" color="success" onClick={() => handlePriceChangesClick(row)}>
                                                    <ViewIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 150, 250, { label: 'All', value: -1 }]}
                    component="div"
                    count={orderInfo.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default Collection;
