import { Button, Table, DatePicker, Select } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import ApiUrl from '../../../network';
import { toast } from 'react-toastify';
import { decrypt } from '../../../utils/encrypt';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const { RangePicker } = DatePicker;
    const [summaryData, setSummaryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [tiers, setTiers] = useState('');
    const [tierType, setTierType] = useState([]);

    // eslint-disable-next-line consistent-return
    const getSummaryData = async (data) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const urlParams = new URLSearchParams(window.location.search);
            if (data) {
                if (data.startDate) urlParams.append('startDate', startDate);
                if (data.endDate) urlParams.append('endDate', endDate);
                if (data.tiers) urlParams.append('tiers', tiers);
            }

            const url = `${ApiUrl.BaseUrl}/epl/eplsummary?${urlParams.toString()}`;

            const response = await fetch(url, config);
            if (!response.ok) {
                toast.error('Failed to fetch retailers');
            }
            const apiData = await response.json();
            const result = JSON.parse(decrypt(apiData.data, keyResult, ivResult));
            result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setSummaryData(result);
            setLoading(false);
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };

    // eslint-disable-next-line consistent-return
    const fetchEplTiersType = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(`${ApiUrl.BaseUrl}/epl/eplloyaltytypes`, config);
            if (!response.ok) {
                toast.error('Failed to fetch retailers');
            }
            const data = await response.json();
            const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
            setTierType(result);
            setLoading(false);
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        getSummaryData();
        fetchEplTiersType();
    }, []);

    const summaryTableData = summaryData.map((data) => {
        return {
            key: Math.random() * 1000,
            customers: data.customers,
            tier: data.tier,
            no_of_loans: data.noofloans,
            avg_value_of_loans: data.averageofloans,
            avg_repayment_time: data.averagerepaymenttime,
            total_fees: data.totalfees,
            total_value_of_loans: data.totalvalueofloans,
            percent_of_gmv: data.gmvpercent
        };
    });
    const tierTypeOptions = tierType.map((item) => {
        return {
            value: item.name,
            label: item.name
        };
    });

    const columns = [
        {
            title: 'Customers',
            dataIndex: 'customers',
            key: 'customers'
        },
        {
            title: 'Tier',
            dataIndex: 'tier',
            key: 'tier'
        },
        {
            title: 'No. of Loans',
            dataIndex: 'no_of_loans',
            key: 'no_of_loans'
        },
        {
            title: 'Avg Value of Loans',
            dataIndex: 'avg_value_of_loans',
            key: 'avg_value_of_loans'
        },
        {
            title: 'Avg repayment time',
            dataIndex: 'avg_repayment_time',
            key: 'avg_repayment_time'
        },
        {
            title: 'Total fees',
            dataIndex: 'total_fees',
            key: 'total_fees'
        },
        {
            title: 'Total Value of Loans',
            dataIndex: 'total_value_of_loans',
            key: 'total_value_of_loans'
        },
        {
            title: '% of gmv ',
            dataIndex: 'percent_of_gmv',
            key: 'percent_of_gmv'
        }
    ];

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((FileSaver) => {
            const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], { type: EXCEL_TYPE });
            FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
        });
    };

    const exportToExcel = (data, fileName) => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, fileName);
        });
    };

    const applyFilter = () => {
        const data = {
            startDate,
            endDate,
            tiers
        };
        getSummaryData(data);
    };

    return (
        <div className="easy-pay-container">
            <div className="header">
                <Link className="d-flex align-items-center" to="/epl/easy-pay-later" style={{ color: '#0A2938' }}>
                    <ArrowLeftOutlined className="font-20" />
                    <h1 className="font-30 ml-2 mt-2">EPL Summary </h1>
                </Link>
                <div className="header-actions mt-2">
                    <Select
                        placeholder="Select Tier"
                        className="filterInput"
                        value={tiers || undefined}
                        options={tierTypeOptions}
                        onChange={(value) => {
                            setTiers(value);
                        }}
                        showSearch
                        allowClear
                        style={{ width: '200px' }}
                    />
                    <RangePicker
                        onChange={(date, dateString) => {
                            setStartDate(dateString[0]);
                            setEndDate(dateString[1]);
                        }}
                        value={[startDate ? dayjs(startDate) : '', endDate ? dayjs(endDate) : '']}
                        format="YYYY-MM-DD"
                        className="datePicker filterInput"
                        style={{ width: '200px' }}
                    />
                    <Button
                        onClick={() => applyFilter()}
                        className="btn-sm mb-2"
                        style={{
                            backgroundColor: '#fff',
                            color: '#FF740F',
                            border: '1px solid #FF740F',
                            width: '100px'
                        }}
                    >
                        Apply filter
                    </Button>
                    {(startDate || endDate || tiers) && (
                        <Button
                            onClick={() => {
                                setStartDate('');
                                setEndDate('');
                                setTiers('');
                                getSummaryData();
                            }}
                            className="btn-sm mb-2"
                            style={{
                                backgroundColor: '#fff',
                                color: '#FF740F',
                                border: '1px solid #FF740F',
                                width: '100px'
                            }}
                        >
                            Clear filter
                        </Button>
                    )}
                    <Button
                        onClick={() => exportToExcel(summaryTableData, 'EPL Summary')}
                        className="btn-sm mb-2"
                        style={{ backgroundColor: '#FF740F', color: '#fff', width: '100px' }}
                    >
                        Export data
                    </Button>
                </div>
            </div>
            <hr className="divider" />
            <div className="content">
                <Table columns={columns} dataSource={summaryTableData} loading={loading} scroll={{ x: true }} />
            </div>
        </div>
    );
};

export default Index;
