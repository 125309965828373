import { Link } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Button, Card, Collapse, Divider, Input, Select, Spin, DatePicker } from 'antd';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ApiUrl from '../../../../network';
import { decrypt } from '../../../../utils/encrypt';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallIcon from '@mui/icons-material/Call';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import dayjs from 'dayjs';
import { EmptyState } from '../../../../components/EmptyState/EmptyState';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const { RangePicker } = DatePicker;
    const [loading, setLoading] = useState(false);
    const [routeData, setRouteData] = useState({});

    // eslint-disable-next-line consistent-return
    const fetchCrmAnalysis = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(`${ApiUrl.BaseUrl}/routerreport/rmaccroutedashboard`, config);

            await response.json().then((data) => {
                if (data.status === false) {
                    toast.error(data.message);
                    setLoading(false);
                } else {
                    const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                    setRouteData(result);
                    setLoading(false);
                }
            });
        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchCrmAnalysis();
        return () => {
            // cleanup function
            setLoading(false);
        };
    }, []);

    return (
        <div className="leadCrmContainer">
            <div
                className="d-flex justify-content-space-between align-items-center salesCardHolder mb-4"
                style={{ borderBottom: '1px dotted #E7EAEB' }}
            >
                <Link to="/rma-route-schedule" style={{ color: '#0A2938' }}>
                    <p style={{ fontSize: '30px' }}>
                        {' '}
                        <KeyboardBackspaceIcon style={{ marginRight: '5px' }} /> Retailers
                    </p>
                </Link>
            </div>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 44
                                }}
                                spin
                            />
                        }
                    />
                </div>
            ) : (
                <div>
                    <div style={{ marginTop: '40px' }}>
                        <div className="d-flex align-items-center justify-content-space-between salesCardHolder">
                            <Card style={{ borderRadius: '20px', backgroundColor: '#fff', width: '100%' }}>
                                <div>
                                    {routeData?.retailers && routeData?.retailers?.length > 0 ? (
                                        <div className="d-flex justify-content-space-between align-items-center flex-wrap">
                                            {routeData?.retailers?.map((retailer) => (
                                                <div
                                                    key={retailer._id}
                                                    className="mb-3 mt-3 flex-grow-1 mr-3 ml-3"
                                                    style={{
                                                        padding: '10px',
                                                        borderRadius: '20px',
                                                        marginLeft: '10px',
                                                        backgroundColor: '#FAFAFA',
                                                        width: '300px',
                                                        height: '200px',
                                                        border: '1px solid #E7EAEB'
                                                    }}
                                                >
                                                    <div className="d-flex align-items-center mb-2">
                                                        <span
                                                            style={{
                                                                backgroundColor: '#F6F6F6',
                                                                padding: '10px',
                                                                borderRadius: '50%',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '50px',
                                                                height: '50px',
                                                                color: '#8C9DAB',
                                                                fontSize: '1.1rem',
                                                                fontWeight: 'bold'
                                                            }}
                                                        >
                                                            <UserOutlined />
                                                        </span>
                                                        <span className="ml-2">
                                                            <p
                                                                style={{
                                                                    fontSize: '1.2rem',
                                                                    fontWeight: 'bold',
                                                                    marginBottom: '0px',
                                                                    color: `${
                                                                        retailer.purchasedInfo.toLowerCase() === 'purchased'
                                                                            ? '#00AC00'
                                                                            : '#FA3246'
                                                                    }`
                                                                }}
                                                            >
                                                                {' '}
                                                                {retailer.partnerName}
                                                            </p>
                                                            <small style={{ color: '#3B5460', fontSize: '14px' }}>Retailer’s name</small>
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center mb-2">
                                                        <span
                                                            style={{
                                                                backgroundColor: '#F6F6F6',
                                                                padding: '10px',
                                                                borderRadius: '50%',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '40px',
                                                                height: '40px',
                                                                color: '#8C9DAB',
                                                                fontSize: '0.9rem',
                                                                fontWeight: 'bold'
                                                            }}
                                                        >
                                                            <CallIcon />
                                                        </span>
                                                        <span className="ml-2">
                                                            <p style={{ fontSize: '1rem', marginBottom: '0px', color: '#3B5460' }}>
                                                                {' '}
                                                                {retailer.partnerContact}
                                                            </p>
                                                        </span>
                                                    </div>
                                                    <Button
                                                        onClick={() => (window.location.href = `/retailer/profile/${retailer._id}`)}
                                                        style={{
                                                            backgroundColor: 'transparent',
                                                            border: 'none',
                                                            outline: 'none',
                                                            boxShadow: 'none',
                                                            padding: '0px'
                                                        }}
                                                    >
                                                        <div className="d-flex align-items-center ">
                                                            <span
                                                                style={{
                                                                    backgroundColor: '#F6F6F6',
                                                                    padding: '10px',
                                                                    borderRadius: '50%',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    width: '40px',
                                                                    height: '40px',
                                                                    color: '#FF740F',
                                                                    fontSize: '0.9rem',
                                                                    fontWeight: 'bold'
                                                                }}
                                                            >
                                                                <NorthEastIcon />
                                                            </span>
                                                            <span className="ml-2">
                                                                <p style={{ fontSize: '1rem', marginBottom: '0px', color: '#FF740F' }}>
                                                                    View profile
                                                                </p>
                                                            </span>
                                                        </div>
                                                    </Button>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="d-flex align-items-center justify-content-center">
                                            <EmptyState text="No data found" width="300px" padding="15px" />
                                        </div>
                                    )}
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default Index;
