import { Link } from 'react-router-dom';

// material-ui
// import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';

// project imports Divider
import AuthWrapper1 from '../AuthWrapper1';
import AuthCardWrapper from '../AuthCardWrapper';
import AuthVerifyEmail from '../auth-forms/AuthVerifyEmail';
// import Logo from 'ui-component/Logo';
// import AuthFooter from 'ui-component/cards/AuthFooter';
import logo from 'assets/images/garage.png';

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const VerifyEmail = () => {
    // const theme = useTheme();
    // const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <AuthWrapper1>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 3 }}>
                                        <Link to="#">
                                            <img src={logo} alt="logo" height={50} />
                                            {/* <Logo /> */}
                                        </Link>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <AuthVerifyEmail />
                                    </Grid>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AuthWrapper1>
    );
};

export default VerifyEmail;
