/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';
import { Col } from 'react-grid-system';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
// import Controls from '../../components/controls/Controls';
// import { Search } from '@mui/icons-material';
// import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
// import Grid from '@mui/material/Grid';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const SalesPartner = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [mechanicInfo, setMechanic] = useState([]);
    // const [mechanicData, setMechanicData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [count, setCount] = useState(50);
    const [searched, setSearched] = useState('');
    const [localSearch, setlocalSearch] = useState('');
    // console.log(mechanicData);

    const handleEditClick = (row) => {
        if (scriptedRef.current) {
            history(`/viewsalespartner/${row._id}`);
        }
    };

    const handleAddShopClick = (row) => {
        if (scriptedRef.current) {
            history(`/addshop/${row._id}`);
        }
    };

    const handlePreviewClick = (row) => {
        if (scriptedRef.current) {
            history(`/previewsalespartner/${row._id}`);
        }
    };

    const handleChangePage = (event, newPage) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);

            fetch(`${ApiUrl.MainSalesPartnerUrl}?page=${newPage}`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setMechanic(data.data ?? []);
                        setPage(data?.currentPage);
                        setCount(data?.totalRetailers);
                        setRowsPerPage(data?.perPage);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        // setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);

            fetch(`${ApiUrl.MainSalesPartnerUrl}?limit=${event.target.value}`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setMechanic(data.data ?? []);
                        setPage(data?.currentPage);
                        setCount(data?.totalRetailers);
                        setRowsPerPage(data?.perPage);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - mechanicInfo.length) : 0;

    const retrieveAllSalesPartner = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);

            let url = `${ApiUrl.MainSalesPartnerUrl}`;
            if (searched) {
                url += `?partner=${searched}`;
            }

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        // console.log('data', data.data);
                        setMechanic(data.data ?? []);
                        setPage(data?.currentPage);
                        setCount(data?.totalRetailers);
                        setRowsPerPage(data?.perPage);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const debounce = () => {
        let timeoutID;
        return (e) => {
            setlocalSearch(e.target.value);
            clearTimeout(timeoutID);
            timeoutID = setTimeout(() => {
                setSearched(e.target.value);
            }, 500);
        };
    };

    const handleSearch = useMemo(() => debounce(), []);

    useEffect(() => {
        retrieveAllSalesPartner();
        // eslint-disable-next-line
    }, [searched]);
    // const filteredRows = mechanicInfo.filter((row) => row.partnerName.toLowerCase().includes(searched.toLowerCase()));
    // const isPlural = filteredRows && filteredRows.length > 1 ? 's' : '';
    // const searchCount = searched ? `${filteredRows.length} result${isPlural} found` : 'No Results';

    // const handleSearch = (event) => {
    //     const searchWord = event.target.value;

    //     const newFilter = mechanicInfo.filter((value) => value.partnerName.toLowerCase().includes(searchWord.toLowerCase()));

    //     if (searchWord === '') {
    //         setMechanic(mechanicData);
    //     } else {
    //         setMechanic(newFilter);
    //     }
    // };

    return (
        <>
            <PageHeader title="Retail Partners" subTitle="Home | Sales | Retail Partner" />

            <MainCard title="Retail Partners">
                <h4 style={{ color: '#ff740f', fontWeight: '700' }}>{`Total Retailers: ${count}`}</h4>
                <br />
                <Box display="flex" justifyContent="flex-start">
                    {/* <Grid>
                        <Controls.Input
                            label="Search Partner Name"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                        />
                    </Grid> */}
                    <Col xs={12} xl={3} lg={3} md={6} sm={6}>
                        <FormControl fullWidth style={{ marginBottom: '10px' }}>
                            <TextField
                                id="outlined-basic"
                                label="Search Partner Name"
                                variant="outlined"
                                value={localSearch}
                                onChange={handleSearch}
                            />
                        </FormControl>
                    </Col>

                    <Box width={10} />

                    <Button component={Link} to="/newlead" variant="contained" size="sm" className={classes.item}>
                        Add
                    </Button>
                </Box>
                <br />

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress />{' '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Channel
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Partner Name
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Contact
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            GPS Coordinates
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Stage
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Sign up Date
                                        </TableCell>

                                        {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                            Credit Rating
                                        </TableCell> */}
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {mechanicInfo.map((row, index) => (
                                <TableRow key={row._id}>
                                    <TableCell component="th" scope="row">
                                        {mechanicInfo.length - index}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.channelName ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.partnerName ?? ''}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.partnerContact ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.locationLat ?? ''} , {row?.locationLng ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row?.stageId?.name ?? ''}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {toTimestamp(row?.signupDate ?? '')}
                                    </TableCell>

                                    {/* <TableCell component="th" scope="row">
                                        {row?.creditRating ?? ''}
                                    </TableCell> */}

                                    <TableCell component="th" scope="row">
                                        <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(row)}>
                                            update
                                        </IconButton>
                                        <IconButton aria-label="edit" color="success" onClick={() => handleAddShopClick(row)}>
                                            add shop
                                        </IconButton>
                                        <IconButton aria-label="eye" color="error" onClick={() => handlePreviewClick(row)}>
                                            preview
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[100, 200, 300, count]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={!count || count <= 0 ? 0 : page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default SalesPartner;
