import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button, Input, Select, DatePicker, Modal, Checkbox, Divider, Table, Radio, Tag, Tooltip } from 'antd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { decrypt, encrypt } from '../../../../utils/encrypt';
import { toast } from 'react-toastify';
import { CheckCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import ApiUrl from '../../../../network';

export const Index = () => {
    const date = new Date();
    const today = dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [order, setOrder] = useState({});
    const { id } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const orderType = searchParams.get('orderType');
    const { RangePicker } = DatePicker;
    const [loading, setLoading] = useState(false);
    const [driverName, setDriverName] = useState('');
    const [batchDate, setBatchDate] = useState(today);
    const [batchStatus, setBatchStatus] = useState([]);
    const [driverList, setDriverList] = useState([]);
    const [carList, setCarList] = useState([]);
    const [checkedOrders, setCheckedOrders] = useState([]);
    const [products, setProducts] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [declineModal, setDeclineModal] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [orderNo, setOrderNo] = useState('');
    const [batchData, setBatchData] = useState({});
    const [declineReason, setDeclineReason] = useState('');
    const [thirdPartyDriver, setThirdPartyDriver] = useState('');

    const columns = [
        {
            title: 'Product name',
            dataIndex: 'partid',
            key: 'partid'
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand'
        },
        {
            title: 'Part type',
            dataIndex: 'partType',
            key: 'partType'
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty'
        }
    ];

    const fetchOrder = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/waybill/vieworderbatch/${id}?orderType=${orderType}`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setOrder(result);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        toast.error(error.message);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    const getBatchStatus = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/orderbatch/batchstatuses`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setBatchStatus(data.data);
                } else {
                    toast.error(data.message);
                }
            });
    };

    const getDrivers = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/driver/availabledrivers`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    const drivers = decrypt(JSON.stringify(data.data), keyResult, ivResult);
                    const driverList = JSON.parse(drivers);
                    driverList.push({
                        _id: '3pl',
                        firstName: '3PL',
                        lastName: 'Driver'
                    });
                    setDriverList(driverList);
                } else {
                    toast.error(data.message);
                }
            });
    };

    const getCars = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/salesplanning/garagevehicleslist`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    const cars = decrypt(JSON.stringify(data.data), keyResult, ivResult);
                    setCarList(JSON.parse(cars));
                } else {
                    toast.error(data.message);
                }
            });
    };
    const fetchAllProducts = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/partzone/salespartspricelist`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = data.data;
                        setProducts(result);
                    } else {
                        toast.error(data.message);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        fetchOrder();
        getBatchStatus();
        getDrivers();
        getCars();
        fetchAllProducts();
    }, []);
    const driverOptions = driverList.map((item) => {
        return {
            label: `${item.firstName} ${item.lastName}`,
            value: `${item.firstName} ${item.lastName}`
        };
    });

    const verifyBatch = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const datas = {
                batchid: order._id
            };
            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            await fetch(`${ApiUrl.BaseUrl}/waybill/verifybatchorder`, {
                method: 'PUT',
                headers: config.headers,
                body: JSON.stringify({ payload })
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        toast.success(data.message);
                        setLoading(false);
                        setModalVisible(true);
                    } else {
                        toast.error(data.message);
                        setLoading(false);
                    }
                });
        } catch (e) {
            toast.error(e.message);
            setLoading(false);
        }
    };
    const declineBatch = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const datas = {
                batchid: order._id,
                rejectedReason: declineReason
            };
            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            await fetch(`${ApiUrl.BaseUrl}/waybill/rejectbatchorder`, {
                method: 'PUT',
                headers: config.headers,
                body: JSON.stringify({ payload })
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        toast.success(data.message);
                        setLoading(false);
                        setModalVisible(false);
                        setDeclineModal(false);
                        fetchOrder();
                    } else {
                        toast.error(data.message);
                        setLoading(false);
                    }
                });
        } catch (e) {
            toast.error(e.message);
            setLoading(false);
        }
    };
    // eslint-disable-next-line consistent-return
    const handleGenerateWaybill = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const datas = {
                batchid: order._id,
                batchDate: order.batchDate,
                batchStatusId: order.batchStatusId,
                rmaName: order.rmaName,
                orderNo: order.orderNo,
                orderId: order.orderId,
                orderType: order.orderType,
                ordersDetails: order?.orderItems,
                driverName: driverName === '3PL Driver' ? thirdPartyDriver : driverName
            };

            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);
            await fetch(`${ApiUrl.BaseUrl}/waybill/generatewaybill`, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify({ payload })
            })
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        toast.success(data.message);
                        setLoading(false);
                        setModalVisible(false);
                        setSelectedOrderId(null);
                        fetchOrder();
                        setTimeout(() => {
                            window.location.href = '/generated/waybill';
                        }, 3000);
                    } else {
                        const error = decrypt(JSON.stringify(data.data), keyResult, ivResult);
                        toast.error(error);
                        setLoading(false);
                    }
                });
        } catch (e) {
            toast.error(e.message);
            setLoading(false);
        }
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Link to="/waybill/batch" className="createScheduleLink">
                    <p style={{ fontSize: '25px', marginLeft: '10px' }}>
                        <ArrowBackIcon sx={{ fontSize: '25px', color: '#000' }} /> Generate waybill
                    </p>
                </Link>
                {order?.verifyKey === true && !order?.rejectedKey ? (
                    <Tag color="green" className="d-flex align-items-center">
                        Batch Verified and Waybill Generated <CheckCircleOutlined className="ml-2" />
                    </Tag>
                ) : order?.rejectedKey === true ? (
                    <Tooltip title={order?.rejectedReason}>
                        <Tag color="red" className="d-flex align-items-center">
                            Batch Rejected
                        </Tag>
                    </Tooltip>
                ) : (
                    <div className="d-flex justify-content-between align-items-center">
                        <Button
                            style={{ backgroundColor: '#0A2938', color: '#fff' }}
                            className="mt-2 mb-2 mr-2 d-flex align-items-center justify-content-center btnCreateSchedule"
                            title="Apply Filters"
                            loading={loading}
                            onClick={() => setDeclineModal(true)}
                        >
                            Reject
                        </Button>
                        <Button
                            style={{ backgroundColor: '#FF740F', color: '#fff', width: '300px' }}
                            className="mt-2 mb-2 d-flex align-items-center justify-content-center btnCreateSchedule"
                            title="Apply Filters"
                            loading={loading}
                            onClick={verifyBatch}
                        >
                            Verify and generate waybill
                        </Button>
                    </div>
                )}
            </div>
            <div className="batchContainer">
                <div className="p-4">
                    <div className="addSalePlanInput">
                        <div className="mr-2">
                            <div>
                                <p style={{ fontSize: '16px', color: '#546974' }}>Enter Driver’s Name</p>
                                <Select
                                    size="large"
                                    style={{
                                        width: '300px'
                                    }}
                                    showSearch
                                    placeholder="Driver Name"
                                    onChange={(e) => setDriverName(e)}
                                    value={driverName}
                                    options={driverOptions}
                                    filterOption={(inputValue, option) => {
                                        if (typeof option.label === 'string') {
                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }

                                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }}
                                />
                            </div>
                            <div className="mt-4">
                                {driverName === '3PL Driver' ? (
                                    <div>
                                        <p style={{ fontSize: '16px', color: '#546974' }}>3PL Driver Name</p>
                                        <Input
                                            size="medium"
                                            style={{
                                                width: 300
                                            }}
                                            className="mt-1"
                                            placeholder="3PL Drive Name"
                                            value={thirdPartyDriver}
                                            onChange={(e) => setThirdPartyDriver(e.target.value)}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div className="addSalePlanInput">
                        <div className="mr-2">
                            <p style={{ fontSize: '16px', color: '#546974' }}>Batch Date</p>
                            <Input
                                size="large"
                                style={{
                                    width: '300px'
                                }}
                                placeholder="Batch Date"
                                onChange={(e) => setBatchDate(e.target.value)}
                                value={dayjs(batchData?.batchDate).format('YYYY-MM-DD')}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="addSalePlanInput">
                        <div className="mr-2">
                            <p style={{ fontSize: '16px', color: '#546974' }}>Order Type</p>
                            <Input
                                placeholder="Order Type"
                                type="text"
                                size="large"
                                style={{
                                    width: '300px'
                                }}
                                value={order.orderType}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="addSalePlanInput">
                        <div>
                            <p style={{ fontSize: '16px', color: '#546974' }}>RMA</p>
                            <Input
                                placeholder="Batch Status"
                                type="text"
                                size="large"
                                style={{
                                    width: '300px'
                                }}
                                value={order.rmaName}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="addSalePlanInput">
                        <div>
                            <p style={{ fontSize: '16px', color: '#546974' }}>Order No</p>
                            <Input
                                placeholder="Order No"
                                type="text"
                                size="large"
                                style={{
                                    width: '300px'
                                }}
                                value={order.orderNo}
                                onChange={(e) => setOrderNo(e.target.value ?? '')}
                                disabled
                            />
                            {checkedOrders && checkedOrders.length > 0 && (
                                <div>
                                    <div className="mt-2">
                                        {checkedOrders.map((checkedOrder, index) => (
                                            <p key={index}>
                                                Order: {checkedOrder.orderNo} - {checkedOrder.rma}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="p-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <p style={{ fontSize: '16px', color: '#546974' }}>Products in this order ({order?.orderItems?.length})</p>
                    </div>
                    <div className="productsContainer orderListContainer">
                        <Table rowKey={(record) => record._id} columns={columns} dataSource={order?.orderItems} pagination={false} />
                    </div>
                </div>
            </div>
            <Modal footer={null} open={modalVisible} width={400}>
                <div className="text-center">
                    <h5>Verification successful</h5>
                    <p className="mb-4 mt-2" style={{ fontSize: '14px' }}>
                        You have successfully verified this order, <br /> proceed to generate a waybill
                    </p>
                    <div className="d-flex align-items-center">
                        <Button
                            style={{
                                color: '#FF740F',
                                borderColor: '#E7EAEB',
                                width: '100%'
                            }}
                            disabled={loading}
                            onClick={handleGenerateWaybill}
                        >
                            Generate waybill
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                footer={null}
                open={declineModal}
                onCancel={() => {
                    setDeclineModal(false);
                }}
                width={400}
            >
                <div className="text-center">
                    <h5>Reject order</h5>
                    <p className="mb-4 mt-2" style={{ fontSize: '14px' }}>
                        Please briefly state why you are rejecting this order.
                    </p>
                    <div className="mb-4 mt-2">
                        <Input.TextArea
                            type="Type here"
                            rows={8}
                            value={declineReason}
                            onChange={(e) => setDeclineReason(e.target.value)}
                            style={{
                                width: '100%',
                                backgroundColor: '#F7F8F8',
                                resize: 'none'
                            }}
                        />
                    </div>
                    <div className="d-flex align-items-center">
                        <Button
                            style={{
                                color: '#E7EAEB',
                                backgroundColor: '#FF740F',
                                width: '100%'
                            }}
                            disabled={loading}
                            onClick={declineBatch}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Index;
