import PersonIcon from '@mui/icons-material/Person';
import { LoadingOutlined } from '@ant-design/icons';
import PhoneIcon from '@mui/icons-material/Phone';
import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
import WestIcon from '@mui/icons-material/West';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Button, Select } from 'antd';
import html2pdf from 'html2pdf.js';
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { dateConverter, twoDecimalPlaces } from 'utils/functions';
import { decrypt } from '../../utils/encrypt';
import reportLogo from '../../assets/images/GarageLogo.svg';

const RetailerReport = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState([]);
    const [orders, setOrders] = useState([]);
    const [mainRetailer, setMainRetailer] = useState([]);
    const [previousLoyalty, setPreviousLoyalty] = useState([]);
    const [currentsLoyalty, setCurrentsLoyalty] = useState([]);
    const [analytics, setAnalytics] = useState([]);
    const [profileId, setProfileId] = useState('');
    const [filteredProfileId, setFilteredProfileId] = useState('');
    const [dates, setDates] = useState({});

    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const country = localStorage.getItem('country');

    const location = useLocation();

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54
            }}
            spin
        />
    );

    // get query data
    const getQueryData = async () => {
        try {
            setLoading(true);
            const url = `${ApiUrl.BaseUrl}/mainorders/querydata?country=${country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);

                        setMainRetailer(answer.customer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retailerOptions = mainRetailer?.map((item) => {
        return { label: item?.partnerName, value: item?._id };
    });

    // get retailer profile
    const getRetailerProfile = async (data) => {
        try {
            setLoading(true);
            // const urlParams = new URLSearchParams(window.location.search);
            const searchParams = new URLSearchParams(location.search);
            const startDate = searchParams.get('startDate');
            const endDate = searchParams.get('endDate');
            let url = `${ApiUrl.BaseUrl}/retailerprofiles/retailerprofile?retailerId=${data || params?.id}`;
            if (filteredProfileId || startDate === 'undefined' || startDate === null) {
                url = `${ApiUrl.BaseUrl}/retailerprofiles/retailerprofile?retailerId=${data || params?.id}`;
            } else {
                url = `${ApiUrl.BaseUrl}/retailerprofiles/retailerprofile?retailerId=${
                    data || params?.id
                }&startDate=${startDate}&endDate=${endDate}`;
            }

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const info = decrypt(data.encrypted, keyResult, ivResult);
                        const infoResult = JSON.parse(info);
                        const order = decrypt(data.encrypteds, keyResult, ivResult);
                        const orderResult = JSON.parse(order);
                        setProfile(infoResult);
                        setOrders(orderResult);
                        const analytics = decrypt(data.analyticsData, keyResult, ivResult);
                        const analyticResult = JSON.parse(analytics);
                        const currentsLoyalty = decrypt(data.currentsLoyalty, keyResult, ivResult);
                        const currentsLoyaltyResult = JSON.parse(currentsLoyalty);
                        const previousLoyalty = decrypt(data.previousLoyalty, keyResult, ivResult);
                        const previousLoyaltyResult = JSON.parse(previousLoyalty);
                        setCurrentsLoyalty(currentsLoyaltyResult);
                        setPreviousLoyalty(previousLoyaltyResult);
                        setAnalytics(analyticResult);
                        // console.log(previousLoyaltyResult);
                        // console.log(currentsLoyaltyResult);
                        // console.log(analyticResult);
                        // console.log(infoResult);
                        // console.log(orderResult);
                        setProfileId(infoResult._id);
                    } else {
                        // const error = decrypt(data.data, keyResult, ivResult);
                        // const answer = JSON.parse(error);
                        console.error(data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getQueryData();
    }, []);

    useEffect(() => {
        getRetailerProfile();
        const searchParams = new URLSearchParams(location.search);
        const startDate = searchParams.get('startDate');
        const endDate = searchParams.get('endDate');
        setDates({ startDate, endDate });
    }, [params?.id, location]);

    // filter retailer
    const filterRetailer = (value) => {
        getRetailerProfile(value);
        setFilteredProfileId((prev) => (prev = value));
    };

    const generatePDF = () => {
        const element = document.getElementById('printContainer');

        html2pdf(element, {
            margin: -1,
            filename: 'retailer.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { dpi: 192, letterRendering: true },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        });
    };

    return (
        <div>
            {loading ? (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '4000'
                    }}
                >
                    {antIcon}
                </Box>
            ) : null}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '1px dashed #E7EAEB',
                    alignItems: 'flex-end',
                    margin: '0px auto 15px',
                    flexWrap: 'wrap'
                }}
            >
                {' '}
                <List
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '0 0 16px 0',
                        alignItems: 'flex-start'
                        // margin: '0px auto 15px'
                    }}
                >
                    <WestIcon
                        sx={{
                            fontSize: '32px',
                            cursor: 'pointer',
                            '&:hover': {
                                color: '#546974'
                            },
                            '&:focus': {
                                color: '#9DA9AF'
                            }
                        }}
                        onClick={() => navigate(-1)}
                    />{' '}
                    <ListItem sx={{ fontWeight: 500, fontSize: '1.875rem', color: '#0A2938', padding: 0, marginLeft: '24px' }}>
                        Report{' '}
                    </ListItem>
                </List>
                <Box
                    sx={{
                        // margin: '20px 0',
                        display: 'flex',
                        justifyContent: 'space-between',

                        alignItems: 'center',
                        flexWrap: 'wrap',
                        padding: '0 0 16px 0'
                    }}
                >
                    <Box
                        sx={{
                            width: ' 277px',
                            margin: '24px 5px 0 5px',
                            flexGrow: '1 '
                        }}
                    >
                        <Select
                            style={{
                                width: '100%'
                            }}
                            size="large"
                            placeholder=""
                            options={retailerOptions}
                            onChange={(value) => filterRetailer(value)}
                            value={profileId || 'Search retailer'}
                            filterOption={(inputValue, option) => {
                                if (typeof option.label === 'string') {
                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }

                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }}
                            showSearch
                        />
                    </Box>

                    <Button
                        type="primary"
                        size="large"
                        style={{
                            backgroundColor: '#FF740F',
                            borderRadius: '12px',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#fff',
                            width: '161px',
                            margin: '24px 5px 0 5px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '38px',
                            flexGrow: '1 '
                        }}
                        onClick={generatePDF}
                        className="hover-secondary-button-orange"
                    >
                        Download report
                    </Button>
                </Box>
            </Box>
            <div>
                <Box
                    sx={{
                        margin: '24px 0px 0 0px',
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap'
                    }}
                >
                    <Box
                        style={{
                            backgroundColor: '#FFFFFF',
                            borderRadius: '16px',

                            display: 'flex',

                            flexDirection: 'column',
                            margin: '5px auto',
                            overflow: 'hidden',
                            flexBasis: '751px'
                        }}
                        id="printContainer"
                    >
                        <Box
                            style={{
                                height: '100%',
                                backgroundColor: '#FFECC3',
                                padding: '24px 20px 25px 20px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '112px',
                                    flexDirection: 'column',
                                    margin: '0 0 16px 0'
                                }}
                            >
                                <img src={reportLogo} alt="" className="report-image" />
                            </Box>

                            <Typography
                                sx={{
                                    fontSize: '24px',
                                    fontWeight: '500',
                                    color: '#0A2938',
                                    textAlign: 'center'
                                }}
                            >
                                Customer Report
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                // alignItems: 'flex-start',
                                padding: '23px 32px 0 32px'
                                // margin: '0px auto 15px'
                            }}
                        >
                            {' '}
                            <Typography sx={{ display: 'flex', fontSize: '20px', color: '#85949C' }}> Report date:</Typography>
                            <Typography sx={{ display: 'flex', fontSize: '18px', color: '#0A2938' }}>
                                {/* {new Date().toLocaleDateString().replace(/\//g, '-')} */}
                                {dates.startDate === 'undefined' ||
                                dates.endDate === 'undefined' ||
                                dates.endDate === null ||
                                dates.startDate === null
                                    ? new Date().toLocaleDateString().replace(/\//g, '-')
                                    : `${dates.startDate} - ${dates.endDate}`}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                // alignItems: 'flex-start',
                                padding: '23px 32px 0 32px'
                                // margin: '0px auto 15px'
                            }}
                        >
                            {' '}
                            <Typography sx={{ display: 'flex', fontSize: '20px', color: '#85949C' }}>Retailer’s details</Typography>
                        </Box>
                        <Box
                            style={{
                                height: '100%',
                                padding: '32px'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                {' '}
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box
                                        sx={{
                                            borderRadius: '100%',
                                            backgroundColor: '#6F8BFF',
                                            height: '25px',
                                            width: '25px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <PersonIcon
                                            sx={{
                                                fontSize: '19px',
                                                color: '#FFFFFF'
                                            }}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: '18px',
                                                color: '#0A2938',
                                                margin: '0 0 0 5px'
                                            }}
                                        >
                                            Customer’s name:
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            margin: '0px 0 0 5px'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '18px',
                                                color: '#0A2938'
                                            }}
                                        >
                                            {profile?.retailerName}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>{' '}
                            <Box sx={{ display: 'flex', alignItems: 'center', margin: '16px 0 0 0px' }}>
                                <Box
                                    sx={{
                                        borderRadius: '100%',
                                        backgroundColor: '#6F8BFF',
                                        height: '25px',
                                        width: '25px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <StorefrontRoundedIcon
                                        sx={{
                                            fontSize: '16px',
                                            color: '#FFFFFF'
                                        }}
                                    />
                                </Box>

                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: '18px',
                                            fontWeight: '400',
                                            color: '#0A2938',
                                            margin: '0 0 0 5px'
                                        }}
                                    >
                                        Shop name: {profile?.shopName}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    // justifyContent: 'flex-start',
                                    // alignItems: 'flex-start',
                                    flexDirection: 'column'
                                    // margin: '0px auto 15px'
                                }}
                            >
                                {' '}
                                <Box sx={{ display: 'flex', alignItems: 'center', margin: '16px 0 0 0px' }}>
                                    <Box
                                        sx={{
                                            borderRadius: '100%',
                                            backgroundColor: '#6F8BFF',
                                            height: '25px',
                                            width: '25px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <PhoneIcon
                                            sx={{
                                                fontSize: '16px',
                                                color: '#FFFFFF'
                                            }}
                                        />
                                    </Box>

                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: '18px',
                                                fontWeight: '400',
                                                color: '#0A2938',
                                                margin: '0 0 0 5px'
                                            }}
                                        >
                                            Contact: {profile?.contactNumber1}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                padding: '32px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    color: '#85949C',
                                    margin: '0 0 12px 0'
                                }}
                            >
                                Analytics
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        margin: ' 0 0 16px 0'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            color: '#0A2938'
                                        }}
                                    >
                                        Total Sales
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            color: '#0A2938'
                                        }}
                                    >
                                        {!Number(analytics?.totalOrders) ? 0 : twoDecimalPlaces(analytics?.totalSales)}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        margin: ' 0 0 16px 0'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            color: '#0A2938'
                                        }}
                                    >
                                        Total orders
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            color: '#0A2938'
                                        }}
                                    >
                                        {!Number(analytics?.totalOrders) ? 0 : analytics?.totalOrders}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        margin: ' 0 0 16px 0'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            color: '#0A2938'
                                        }}
                                    >
                                        Average order value
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            color: '#0A2938'
                                        }}
                                    >
                                        {!Number(analytics?.totalOrders) ? 0.0 : twoDecimalPlaces(analytics?.averageValue)}
                                    </Typography>
                                </Box>
                                {/* <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        margin: ' 0 0 16px 0'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            color: '#0A2938'
                                        }}
                                    >
                                        Total revenue
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            color: '#0A2938'
                                        }}
                                    >
                                        {!Number(analytics?.totalOrders) ? 0.0 : twoDecimalPlaces(analytics?.totalRevenue)}
                                    </Typography>
                                </Box> */}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                padding: '0 32px 32px 32px '
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    color: '#85949C',
                                    margin: '0 0 12px 0'
                                }}
                            >
                                Top 5 parts ordered
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%'
                                }}
                            >
                                {analytics?.top5Parts?.map((item) => (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            margin: ' 0 0 16px 0'
                                        }}
                                        key={item?._id}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '16px',
                                                color: '#0A2938'
                                            }}
                                        >
                                            {item?.sku}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '16px',
                                                color: '#0A2938'
                                            }}
                                        >
                                            {twoDecimalPlaces(item?.totalGMV)}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                padding: '0 32px 32px 32px '
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    color: '#85949C',
                                    margin: '0 0 12px 0'
                                }}
                            >
                                Total monthly sales
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%'
                                }}
                            >
                                {analytics?.monthlySales?.map((item, index) => (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            margin: ' 0 0 16px 0'
                                        }}
                                        key={index}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '16px',
                                                color: '#0A2938'
                                            }}
                                        >
                                            {item?.month} {item?.year}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '16px',
                                                color: '#0A2938'
                                            }}
                                        >
                                            {twoDecimalPlaces(item?.totalSales)}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                padding: '0 32px 32px 32px '
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    color: '#85949C',
                                    margin: '0 0 12px 0'
                                }}
                            >
                                Loyalty and discounts
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%'
                                }}
                            >
                                {/* {analytics?.monthlySales?.map((item, index) => ( */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        margin: ' 0 0 16px 0'
                                    }}
                                    // key={index}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            color: '#0A2938'
                                        }}
                                    >
                                        Loyalty tier
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            color: '#0A2938'
                                        }}
                                    >
                                        {currentsLoyalty.title}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        margin: ' 0 0 16px 0'
                                    }}
                                    // key={index}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            color: '#0A2938'
                                        }}
                                    >
                                        Discount
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            color: '#0A2938'
                                        }}
                                    >
                                        {!Number(+currentsLoyalty.reward) ? 0.0 : twoDecimalPlaces(+currentsLoyalty.reward)}
                                    </Typography>
                                </Box>
                                {/* ))} */}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                padding: '0 32px 45px 32px '
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    color: '#85949C',
                                    margin: '0 0 12px 0'
                                }}
                            >
                                Greetings
                            </Typography>
                            <Box
                                sx={{
                                    wordBreak: 'break-word',
                                    maxWidth: '336px',
                                    fontSize: '16px',
                                    color: '#0A2938'
                                }}
                            >
                                Thank you for trusting Garage mobility. You mean a lot to us.
                            </Box>
                            <Box
                                sx={{
                                    wordBreak: 'break-word',
                                    maxWidth: '336px',
                                    fontSize: '18px',
                                    color: '#FF740F',
                                    fontWeight: '500',
                                    margin: '12px 0 0 0'
                                }}
                            >
                                Garage cares.
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        </div>
    );
};

export default RetailerReport;
