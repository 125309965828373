import React, { useState, useEffect } from 'react';
import TrackingMap from './TrackingMap';
import WestIcon from '@mui/icons-material/West';
import { Link, useNavigate, useParams } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CircleIcon from '@mui/icons-material/Circle';
import './logistics.css';
import { Typography, Box } from '@mui/material';
import DriverMenuList from './DriverMenuList';
import { encrypt, decrypt } from '../../utils/encrypt';
import ApiUrl from 'network';
import { ToastContainer, toast } from 'react-toastify';

const TrackinAllDrivers = () => {
    const [availableDrivers, setAvailableDrivers] = useState([]);
    const [unAvailableDrivers, setUnAvailableDrivers] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [currentDriverId, setCurrentDriverId] = useState(null);
    const [loading, setLoading] = useState(false);
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');

    const country = localStorage.getItem('country');
    const navigate = useNavigate();
    const mapCenter = country === 'Ghana' ? { lat: 6.5, lng: -1.1 } : { lat: 6.465422, lng: 3.406448 };

    const ITEM_HEIGHT = 48;

    const [anchorEl, setAnchorEl] = useState(null);
    const [availableList, setAvailableList] = useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const openlist = Boolean(availableList);

    const handleAvailableClick = (event) => {
        setAvailableList(event.currentTarget);
    };

    const handleAvailableClose = () => {
        setAvailableList(null);
    };

    // get Available Drivers
    const getAllDrivers = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/driver/alldrivers`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);

                        const available = answer.filter((item) => {
                            return item.driverAvailability === true;
                        });
                        setAvailableDrivers(available);

                        const unavailable = answer.filter((item) => {
                            return item.driverAvailability === false;
                        });
                        setUnAvailableDrivers(unavailable);

                        const regex = /^-?\d+\.\d+$/;

                        // orders with validate coordinates
                        const validCoordinates = answer.filter((coordinate) => {
                            return regex.test(coordinate.locationLat) && regex.test(coordinate.locationLng);
                        });

                        setDrivers(validCoordinates);

                        setLoading(false);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                        toast.error(answer);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        getAllDrivers();
    }, []);

    const handleMarkerClick = (id) => {
        setCurrentDriverId(id);
    };

    return (
        <>
            <div className="track-heading">
                <WestIcon sx={{ fontSize: '32px', marginLeft: '25px', cursor: 'pointer' }} onClick={() => navigate(-1)} />
                <Typography
                    sx={{
                        fontSize: '30px',
                        fontWeight: '700',
                        lineHeight: '38px',
                        margin: '0 0 0 24px'
                    }}
                >
                    Track Drivers
                </Typography>
            </div>
            <div className="tracking-driver-wrapper">
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 0 16px 0', flexWrap: 'wrap' }}>
                    <Box
                        sx={{
                            width: '187px',
                            padding: '8px 12px',
                            borderRadius: '100px',
                            backgroundColor: '#e7eaeb',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            margin: '24px 0 0 24px',
                            cursor: 'pointer',
                            flexWrap: 'wrap'
                        }}
                        aria-controls={openlist ? 'long-menu' : undefined}
                        aria-expanded={openlist ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleAvailableClick}
                    >
                        <CircleIcon sx={{ width: '20px', height: '20px', bordeRadius: '100%', color: '#00aa55' }} />
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: '500',
                                lineHeight: '20px'
                            }}
                        >
                            Available driver
                        </Typography>
                        <KeyboardArrowDownIcon
                            sx={{
                                fontSize: '16px',
                                color: '#0A2938'
                            }}
                        />
                    </Box>
                    <DriverMenuList
                        options={availableDrivers}
                        handleClose={handleAvailableClose}
                        ITEM_HEIGHT={ITEM_HEIGHT}
                        open={openlist}
                        anchorEl={availableList}
                    />
                    <Box
                        sx={{
                            width: '187px',
                            padding: '8px 12px',
                            borderRadius: '100px',
                            backgroundColor: '#e7eaeb',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            margin: '24px 0 0 24px',
                            cursor: 'pointer'
                        }}
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <CircleIcon sx={{ width: '20px', height: '20px', bordeRadius: '100%', color: '#9B35FF' }} />
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: '500',
                                lineHeight: '20px'
                            }}
                        >
                            Unvailable driver
                        </Typography>
                        <KeyboardArrowDownIcon
                            sx={{
                                fontSize: '16px',
                                color: '#0A2938'
                            }}
                        />
                    </Box>
                    <DriverMenuList
                        options={unAvailableDrivers}
                        handleClose={handleClose}
                        ITEM_HEIGHT={ITEM_HEIGHT}
                        open={open}
                        anchorEl={anchorEl}
                    />
                </Box>
                <div className="tracking-all-map">
                    <TrackingMap
                        position={{ lat: -34.397, lng: 150.644 }}
                        mapCenter={mapCenter}
                        handleMarkerClick={handleMarkerClick}
                        setCurrentDriverId={setCurrentDriverId}
                        currentDriverId={currentDriverId}
                        drivers={drivers}
                    />
                </div>
            </div>
        </>
    );
};

export default TrackinAllDrivers;
