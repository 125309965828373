import { Col, Container, Row } from 'react-grid-system';

import GenericPageHeader from 'components/GenericPageHeader';
import Loader from 'components/Loader';
import StatsCard from 'components/RetailerShop/StatsCard';
import Top5Brands from 'components/supplier/Top5BrandsTable';
import Top5PartsTable from 'components/supplier/Top5PartsTable';
import TotalSalesTable from 'components/supplier/TotalSalesTable';
import { formatCurrency } from 'utils/functions';
import { useAppContext } from 'context/appContext';
import { useEffect } from 'react';

const Supplier = () => {
    const {
        isLoading,
        // userCurrency,
        getSupplierInfo,
        totalPartsSoldToday,
        totalPartsSoldYesterday,
        partSoldPercentage,
        totalNoPartsToday,
        totalNoPartsYesterday,
        noPartsPercentage,
        averageAmountMadeToday,
        averageAmountMadeesterday,
        avgAmountPercentage
    } = useAppContext();
    useEffect(() => {
        getSupplierInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <GenericPageHeader title="Supplier Portal" breadcrumbs="Home | Dashboard | Supplier" />
            {isLoading ? (
                <Loader />
            ) : (
                <Container>
                    <Row>
                        <Col>
                            <StatsCard
                                title="Total Parts Sold (This week)"
                                todayCount={formatCurrency(totalPartsSoldToday)}
                                prevCount={formatCurrency(totalPartsSoldYesterday)}
                                perChange={+partSoldPercentage}
                            />
                        </Col>
                        <Col>
                            <StatsCard
                                title="Total Sales (This week)"
                                todayCount={formatCurrency(totalNoPartsToday)}
                                prevCount={formatCurrency(totalNoPartsYesterday)}
                                perChange={+noPartsPercentage}
                            />
                        </Col>
                        <Col>
                            <StatsCard
                                title="Avg. Amount made Working with Garage (This week)"
                                todayCount={formatCurrency(averageAmountMadeToday)}
                                prevCount={formatCurrency(averageAmountMadeesterday)}
                                perChange={+avgAmountPercentage}
                            />
                        </Col>
                    </Row>
                    <div className="mt-3">
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Top5PartsTable />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Top5Brands />
                            </Col>
                        </Row>
                    </div>
                    <div className="mt-3">
                        <TotalSalesTable />
                    </div>
                </Container>
            )}
        </>
    );
};
export default Supplier;
