import { createContext, useReducer, useContext } from 'react';
import retailerReducer from './retailer-reducer';
import {
    RETAILER_BEGIN,
    RETAILER_SUCCESS,
    RETAILER_INFO_BEGIN,
    RETAILER_INFO_SUCCESS,
    RETAILER_INFO_ERROR,
    FILTER_RETAILER_INFO_BEGIN,
    FILTER_RETAILER_INFO_SUCCESS,
    FILTER_RETAILER_INFO_ERROR,
    RETAILER_CHART_BEGIN,
    RETAILER_CHART_SUCCESS
} from './retailer-actions';
import { authFetch, removeUserFromLocalStorage } from 'utils/axios-config';
import { errorToast, successToast } from 'utils/toast';

const initialState = {
    isLoading: false,
    retailers: [],
    currentRetailerId: '622f930947ac34029865ba8b',
    currentRetailerName: 'Anthony Abanga',
    cwtotalnumberoforders: 0,
    pwtotalnumberoforders: 0,
    ratetotalnumberoforders: 0,
    dateRangeTitle: '',
    cwtotalsales: 0,
    pwtotalsales: 0,
    ratesalespercentage: '',
    cwaverageordervalue: 0,
    pwaverageordervalue: 0,
    rateorderaveragepercentage: '',
    topfiveparts: [],
    inventory: [],
    todaybestseller: {},
    mostrecentorder: {},
    chartData: []
};

const RetailerContext = createContext({ ...initialState });

const RetailerProvider = ({ children }) => {
    const [state, dispatch] = useReducer(retailerReducer, initialState);

    const getAllRetailers = async () => {
        dispatch({ type: RETAILER_BEGIN });
        try {
            const { data } = await authFetch('/retailersummary/retailerdata');
            dispatch({ type: RETAILER_SUCCESS, payload: { retailers: data.data } });
        } catch (error) {
            console.log(error);
            removeUserFromLocalStorage();
        }
    };

    const getRetailerSalesById = async (currentRetailerId) => {
        dispatch({ type: RETAILER_INFO_BEGIN });
        try {
            const { data } = await authFetch.post('/retailersummary/retailershop/', currentRetailerId);
            const {
                cwtotalnumberoforders,
                pwtotalnumberoforders,
                ratetotalnumberoforders,
                dateRangeTitle,
                cwtotalsales,
                pwtotalsales,
                ratesalespercentage,
                cwaverageordervalue,
                pwaverageordervalue,
                rateorderaveragepercentage,
                topfiveparts,
                inventory,
                todaybestseller,
                mostrecentorder
            } = data;
            dispatch({
                type: RETAILER_INFO_SUCCESS,
                payload: {
                    cwtotalnumberoforders,
                    pwtotalnumberoforders,
                    ratetotalnumberoforders,
                    dateRangeTitle,
                    cwtotalsales,
                    pwtotalsales,
                    ratesalespercentage,
                    cwaverageordervalue,
                    pwaverageordervalue,
                    rateorderaveragepercentage,
                    topfiveparts,
                    inventory,
                    todaybestseller,
                    mostrecentorder
                }
            });
            successToast('Data filtered successfully');
        } catch (error) {
            dispatch({ type: RETAILER_INFO_ERROR });
            console.log(error);
        }
    };

    const handleRetailerFilter = async (filterOptions) => {
        dispatch({ type: FILTER_RETAILER_INFO_BEGIN });
        try {
            const { data } = await authFetch.post('/retailersummary/retailershop', filterOptions);
            const {
                cwtotalnumberoforders,
                pwtotalnumberoforders,
                ratetotalnumberoforders,
                dateRangeTitle,
                cwtotalsales,
                pwtotalsales,
                ratesalespercentage,
                cwaverageordervalue,
                pwaverageordervalue,
                rateorderaveragepercentage,
                topfiveparts,
                inventory,
                todaybestseller,
                mostrecentorder
            } = data;

            dispatch({
                type: FILTER_RETAILER_INFO_SUCCESS,
                payload: {
                    cwtotalnumberoforders,
                    pwtotalnumberoforders,
                    ratetotalnumberoforders,
                    dateRangeTitle,
                    cwtotalsales,
                    pwtotalsales,
                    ratesalespercentage,
                    cwaverageordervalue,
                    pwaverageordervalue,
                    rateorderaveragepercentage,
                    topfiveparts,
                    inventory,
                    todaybestseller,
                    mostrecentorder
                }
            });
            successToast('Date processed successfully...');
        } catch (error) {
            if (error.response.status !== 401) return;
            dispatch({ type: FILTER_RETAILER_INFO_ERROR });
            errorToast('Error processing date request');
        }
    };

    const handleRetailerSalesChart = async (currentObj) => {
        dispatch({ type: RETAILER_CHART_BEGIN });
        try {
            const { data } = await authFetch.post('/retailersummary/barchartsales', currentObj);
            const { sales } = data;
            dispatch({ type: RETAILER_CHART_SUCCESS, payload: { sales } });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <RetailerContext.Provider
            value={{ ...state, getAllRetailers, dispatch, getRetailerSalesById, handleRetailerFilter, handleRetailerSalesChart }}
        >
            {children}
        </RetailerContext.Provider>
    );
};

const useRetailerContext = () => {
    return useContext(RetailerContext);
};

export { useRetailerContext, RetailerProvider, initialState };
