import { Avatar, Button, Input, Modal, Select, Spin, Table, Tag, Upload } from 'antd';

import React, { useEffect, useState } from 'react';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { decrypt } from '../../utils/encrypt';
import { toast } from 'react-toastify';
import ApiUrl from '../../network';
import * as XLSX from 'xlsx';
import normFile from '../../imageToBucket';

const miraURL = ApiUrl.MiraUrl;
const baseUrl = `${miraURL}/api/v1`;

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');
const country = localStorage.getItem('country');

const Index = () => {
    const [loading, setLoading] = useState(false);
    const [imageloader, setImageLoader] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [senderName, setSenderName] = useState('');
    const [messagePayload, setMessagePayload] = useState('');
    const [finalMessage, setFinalMessage] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    // eslint-disable-next-line camelcase
    const [template_id, setTemplate_id] = useState('');
    const [variables, setVariables] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [params, setParams] = useState({});
    const numberSrc = contacts.map((contact, index) => ({
        key: contact.Name + index,
        name: contact?.Name,
        email: contact?.Email,
        phone: contact?.Phone
    }));

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name', // Change this based on your Excel header
            key: 'name'
        },
        {
            title: 'Email',
            dataIndex: 'email', // Change this based on your Excel header
            key: 'email'
        },
        {
            title: 'Phone',
            dataIndex: 'phone', // Change this based on your Excel header
            key: 'phone'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status, record) =>
                loading ? (
                    <Tag color="blue">Processing</Tag>
                ) : (
                    <Tag color={status?.toLowerCase() === 'received' ? 'green' : 'red'}>{status || 'Pending'}</Tag>
                )
        }
    ];

    const styles = {
        header: {
            backgroundColor: '#0A2938',
            padding: 20,
            borderRadius: 10
        },
        headerContent: {
            fontFamily: 'Poppins',
            fontSize: 30,
            fontWeight: 500,
            lineHeight: '38px',
            textAlign: 'left',
            color: '#0A2938'
        },
        contentHeaderParagraph: {
            fontFamily: 'Poppins',
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '20px',
            textAlign: 'left',
            color: '#0A2938'
        },
        content: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left',
            color: '#696E7C'
        },
        filters: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left',
            color: '#696E7C'
        },
        filterButton: {
            width: '140px',
            borderRadius: '8px',
            backgroundColor: '#FF740F',
            color: '#fff',
            border: 'none',
            marginRight: '10px'
        },
        resetButton: {
            width: 'fit-content',
            borderRadius: '8px',
            backgroundColor: '#fff',
            color: '#000',
            border: '1px solid #D9DBDE'
        },
        table: {
            borderRadius: '16px',
            backgroundColor: '#fff',
            border: '1px solid #E5E5E5'
        },
        actionButton: {
            backgroundColor: 'transparent',
            color: '#FF740F',
            border: '1px solid #FF740F',
            borderRadius: '8px',
            fontFamily: 'Poppins',
            fontSize: 14,
            lineHeight: '20px',
            fontWeight: 500,
            width: 'fit-content'
        },
        modalHeader: {
            fontFamily: 'Poppins',
            fontSize: 24,
            fontWeight: 500,
            lineHeight: '32px',
            textAlign: 'left',
            color: '#E7EAEB'
        },
        modalParagraph: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left',
            color: '#E7EAEB'
        },
        saveButton: {
            width: 'fit-content',
            borderRadius: '8px',
            backgroundColor: '#FF740F',
            color: '#fff',
            border: 'none'
        },
        modalHeaderContainer: {
            backgroundColor: '#233E4C',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: '#E7EAEB',
            padding: 20,
            borderRadius: 10
        }
    };

    const handleExcelUpload = (info) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0]; // Assuming only one sheet
            const sheet = workbook.Sheets[sheetName];

            // Get data as an array of arrays
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            // Filter out empty rows
            const filteredData = jsonData.filter((row) => row.length > 0);

            // Check if there are at least 2 rows (header + 1 data row)
            if (filteredData.length < 2) {
                toast.error('No data found in the excel sheet');
                return;
            }

            // Extract headers from the first row
            const headers = filteredData[0];

            // Map the remaining rows to JSON objects
            const contactsJson = filteredData.slice(1).map((row) => {
                return headers.reduce((acc, header, index) => {
                    acc[header] = row[index]; // Create key-value pairs
                    return acc;
                }, {});
            });

            // Set the contacts state to the structured JSON
            setContacts(contactsJson);
        };

        // Read the file as a binary string
        reader.readAsBinaryString(info.file.originFileObj);
    };

    const sendMessage = async () => {
        // Logic to send the message goes here
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const payload = {
                phone_numbers: contacts.map((contact) => contact.Phone),
                message: params?.parameters?.length === 0 ? messagePayload : finalMessage,
                link: imageUrl,
                country,
                template_id,
                parameters: variables.map((item) => {
                    return {
                        text: item
                    };
                })
            };

            const url = `${baseUrl}/whatsapp/send-bulk-messages`;
            const response = await fetch(url, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify(payload)
            });
            const result = await response.json();

            if (result.status === true) {
                toast.success(result.message);
                setLoading(false);
                setImageUrl('');
                setFinalMessage('');
                setContacts([]);
                setVariables([]);
                setIsVisible(false);
            } else {
                const error = JSON.parse(decrypt(result.data, keyResult, ivResult));

                toast.error(error);
                setLoading(false);
            }
        } catch (e) {
            toast(e.message);
            setLoading(false);
        }
    };

    const fetchTemplates = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const response = await fetch(`${baseUrl}/whatsapp/templates`, config);
            const data = await response.json();
            setTemplates(data);
        } catch (error) {
            //  console.log(error);
        }
    };

    const handleCancel = () => {
        setIsVisible(false);
    };

    const idUploadRequest = async (file) => {
        try {
            setImageLoader(true);
            const token = localStorage.getItem('userToken');
            const rootURL = ApiUrl.BaseUrl;
            const compressedImage = await normFile(file, token, rootURL);

            if (compressedImage) {
                setImageLoader(false);
                setImageUrl(compressedImage);
                return {
                    status: 'done',
                    response: compressedImage
                };
            }
            // Return a default value if compressedImage is falsy
            return {
                status: 'error',
                response: 'Failed to compress image'
            };
        } catch (error) {
            setImageLoader(false);
            return {
                status: 'error'
            };
        }
    };

    const props = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        customRequest: ({ file, onSuccess, onError }) => {
            idUploadRequest(file, 'photo')
                .then((response) => {
                    if (response.status === 'done') {
                        onSuccess(response);
                    } else {
                        onError(response);
                    }
                })
                .catch((error) => {
                    onError(error);
                });
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                // message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                // message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files);
        }
    };

    const getMessageWithVariables = (message) => {
        // Replace {{index}} with corresponding variable value from the array
        return message.replace(/{{(\d+)}}/g, (_, index) => {
            const idx = parseInt(index, 10); // Parse index as an integer
            return variables[idx] || ''; // Return empty string if variable is not defined
        });
    };

    // useEffect(() => {
    //     const updatedMessage = getMessageWithVariables(messagePayload);
    //     setFinalMessage(updatedMessage);
    // }, [messagePayload, variables]);

    const handleTextAreaChange = (e) => {
        const updatedMessagePayload = e.target.value;
        setMessagePayload(updatedMessagePayload);
    };

    return (
        <div className="verification">
            <div className="header">
                <div className="header-content">
                    <h1 style={styles.headerContent}>Bulk Whatsapp messaging</h1>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Upload onChange={handleExcelUpload} accept=".xlsx, .xls" showUploadList={false} multiple={false}>
                        <Button
                            size="small"
                            style={{
                                backgroundColor: 'transparent',
                                color: '#0A2938',
                                borderColor: '#7e7e7e',
                                width: 'fit-content',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                            icon={<DownloadOutlined />}
                        >
                            Import
                        </Button>
                    </Upload>
                    {contacts.length > 0 && (
                        <Button
                            size="small"
                            style={{
                                backgroundColor: '#FF740F',
                                color: '#fff',
                                width: 'fit-content',
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '10px'
                            }}
                            onClick={() => {
                                fetchTemplates().then((r) => r);
                                setIsVisible(true);
                            }}
                        >
                            Send message
                        </Button>
                    )}
                </div>
            </div>
            <div className="content">
                <div className="content-body">
                    <div className="table-responsive" style={styles.table}>
                        <Table
                            rowKey={(record) => record.key || record.Name} // Assuming 'Name' is unique
                            columns={columns}
                            dataSource={numberSrc}
                            loading={loading}
                            scroll={{ x: 1000 }}
                        />
                    </div>
                </div>
            </div>
            <Modal title="Send Whatsapp Message" open={isVisible} onCancel={handleCancel} footer={null} width={600}>
                <div>
                    <p>To:</p>
                    <div>
                        <Avatar.Group>
                            {contacts.slice(0, 3).map((contact, index) => (
                                <Avatar
                                    key={index}
                                    style={{
                                        backgroundColor: '#FF740F',
                                        color: '#fff',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <small style={{ fontSize: '12px' }}>
                                        {contact.Phone && contact.Phone.length >= 3
                                            ? contact.Phone.slice(-3) // Extract last 3 digits
                                            : 'N/A'}
                                    </small>
                                </Avatar>
                            ))}

                            {/* Conditionally show "many more" if there are more than 3 contacts */}
                            {contacts.length > 3 && (
                                <Avatar
                                    style={{
                                        backgroundColor: '#FF740F',
                                        color: '#fff',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    +{contacts.length - 3} more
                                </Avatar>
                            )}
                        </Avatar.Group>
                    </div>
                </div>
                <div className="mt-3">
                    <p style={{ marginBottom: '5px' }}>Template:</p>
                    <small style={{ marginBottom: '5px', color: '#9b9b9b' }}>Template {senderName}</small>
                    <Select
                        className="mt-2"
                        placeholder="Must not be more than 11 characters"
                        options={templates.map((template) => ({
                            label: template.title, // Display the template name
                            value: template.name // Use the template name as the value
                        }))}
                        value={senderName || ''} // Display the name of the selected template
                        onChange={(value) => {
                            // Find the selected template object
                            const templateFound = templates.find((template) => template.name === value);
                            setTemplate_id(templateFound?._id);
                            setMessagePayload(templateFound?.message || '');

                            // Set the sender name
                            setSenderName(value);

                            // If the template has parameters, update the params state
                            if (templateFound) {
                                setParams(templateFound);
                            }
                        }}
                        style={{ width: '100%' }}
                    />
                </div>
                <div className="mt-3">
                    {params?.parameters?.map((param, index) => (
                        <div key={index} className="mt-2">
                            <p>{param.title}</p>

                            {/* Render input if param.type is 'text' */}
                            {param.type === 'text' && (
                                <input
                                    type="text"
                                    className="w-full p-2 border rounded"
                                    placeholder={param.example}
                                    value={typeof variables[index] === 'string' ? variables[index] : ''}
                                    onChange={(e) => {
                                        setVariables((prevParams) => {
                                            const newVariables = [...prevParams];
                                            newVariables[index] = e.target.value; // Update text
                                            return newVariables;
                                        });
                                    }}
                                    onBlur={() => {
                                        setFinalMessage(() => messagePayload);
                                        variables.forEach((item, i) => {
                                            setFinalMessage((prevState) => prevState.replace(`{{${i + 1}}}`, item));
                                        });
                                    }}
                                />
                            )}

                            {/* Render textarea if param.type is 'list' */}
                            {param.type === 'list' && (
                                <textarea
                                    className="w-full p-2 border rounded"
                                    placeholder={param.example}
                                    value={Array.isArray(variables[index]) ? variables[index].join('\n') : ''}
                                    onChange={(e) => {
                                        setVariables((prevParams) => {
                                            const newVariables = [...prevParams];
                                            newVariables[index] = e.target.value.split('\n'); // Update list (split by new line)
                                            return newVariables;
                                        });
                                    }}
                                    onBlur={() => {
                                        setFinalMessage(() => messagePayload);
                                        variables.forEach((item, i) => {
                                            const value = Array.isArray(item) ? item.join(', ') : item;
                                            setFinalMessage((prevState) => prevState.replace(`{{${i + 1}}}`, value));
                                        });
                                    }}
                                />
                            )}
                        </div>
                    ))}
                </div>

                <div className="mt-3 mb-3">
                    <p style={{ marginBottom: '5px' }}>Message:</p>
                    <small style={{ marginBottom: '5px', color: '#9b9b9b' }}>
                        Each message part is 160 characters long. Use abbreviations to keep messages short.
                    </small>
                    <Input.TextArea
                        className="mt-2"
                        placeholder="Eg. we are running discount promo this week"
                        value={params?.parameters?.length === 0 ? messagePayload : finalMessage}
                        onChange={handleTextAreaChange}
                        rows={4}
                        style={{ resize: 'none' }}
                    />
                </div>
                {params?.has_header === true && (
                    <div>
                        <Upload {...props}>
                            <Button
                                size="small"
                                style={{
                                    backgroundColor: 'transparent',
                                    color: '#0A2938',
                                    borderColor: '#7e7e7e',
                                    width: 'fit-content',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                                icon={<DownloadOutlined />}
                            >
                                Add media
                            </Button>
                        </Upload>
                        <div className="imagePreviews mt-4">
                            {imageloader && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <p className="mr-2">Image loading...... </p> <Spin indicator={<LoadingOutlined spin />} size="small" />
                                </div>
                            )}
                            {imageUrl && (
                                <img
                                    src={imageUrl}
                                    alt="Preview"
                                    style={{
                                        width: '80px',
                                        height: '80px',
                                        objectFit: 'cover',
                                        borderRadius: '8px',
                                        marginRight: '5px',
                                        border: '1px solid #ddd'
                                    }}
                                />
                            )}
                        </div>
                    </div>
                )}

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            onClick={() => {
                                setMessagePayload('');
                                setIsVisible(false);
                            }}
                            style={{
                                backgroundColor: 'transparent',
                                color: '#FF740F',
                                borderColor: '#FF740F',
                                width: 'fit-content',
                                marginRight: '10px'
                            }}
                        >
                            Discard
                        </Button>
                        <Button
                            loading={loading}
                            onClick={sendMessage}
                            style={{ backgroundColor: '#FF740F', color: '#FFF', width: 'fit-content' }}
                            disabled={contacts.length === 0 || !messagePayload}
                        >
                            Send Whatsapp Message
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default Index;
