// new icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PaymentsIcon from '@mui/icons-material/Payments';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

// constant
const icons = {
    DashboardIcon,
    AccessAlarmIcon,
    FormatListBulletedIcon,
    AttachMoneyIcon,
    PaymentsIcon,
    SupportAgentIcon
};

// ==============================|| EXTRA FLEET MENU ITEMS ||============================== //
const userRole = localStorage.getItem('userRole');

const sales = {
    id: 'sales',
    title: 'Relationship Management',
    type: userRole,
    children: [
        {
            id: 'home',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: icons.DashboardIcon,
            breadcrumbs: false
        },
        {
            id: 'checkIn',
            title: 'Check in',
            type: 'item',
            url: '/check-in',
            breadcrumbs: false,
            icon: icons.AccessAlarmIcon
        },
        {
            id: 'orders',
            title: 'Orders',
            type: 'collapse',
            icon: icons.FormatListBulletedIcon,
            children: [
                {
                    id: 'myorders',
                    title: 'My Orders',
                    type: 'item',
                    url: '/mysalesorder',
                    breadcrumbs: false
                },
                {
                    id: 'sales',
                    title: 'Order Request Form',
                    type: 'item',
                    url: '/order-request-form'
                },
                {
                    id: 'salesdrivebulk',
                    title: 'Sales Drive Bulk Order',
                    type: 'item',
                    url: '/salesdrivebulk'
                },
                {
                    id: 'presoldmysalesorder',
                    title: 'My PreSold Order',
                    type: 'item',
                    url: '/presoldmysalesorder',
                    breadcrumbs: false
                },

                {
                    id: 'presoldsales',
                    title: 'Add PreSold Orders',
                    type: 'item',
                    url: '/order-request-form',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'Sales operation',
            title: 'Sales Operation',
            type: 'collapse',
            icon: icons.AttachMoneyIcon,
            children: [
                {
                    id: 'routeschedule',
                    title: 'Route Schedule',
                    type: 'item',
                    url: '/rma-route-schedule'
                },
                {
                    id: 'kpiplanning',
                    title: 'KPI',
                    type: 'item',
                    url: '/kpi'
                },
                {
                    id: 'salesdrive',
                    title: 'Sales Drive Planning',
                    type: 'item',
                    url: '/rma/salesplanning'
                },
                {
                    id: 'orderswaybill',
                    title: 'Waybill',
                    type: 'item',
                    url: '/generated/waybill'
                },
                {
                    id: 'parts-catalog',
                    title: 'Parts Catalog',
                    type: 'item',
                    url: '/parts-catalog',
                    breadcrumbs: false
                },
                {
                    id: 'part-discount',
                    title: 'Check Part Discount',
                    type: 'item',
                    url: '/part-discount',
                    breadcrumbs: false
                },
                {
                    id: 'pricing',
                    title: 'Pricing',
                    type: 'item',
                    url: '/cc_rma/product-pricing'
                }
            ]
        },
        {
            id: 'finance-operation',
            title: 'Finance Operation',
            type: 'collapse',
            icon: icons.PaymentsIcon,
            children: [
                {
                    id: 'paymentstatus',
                    title: 'Payment Status',
                    type: 'item',
                    url: '/collection/paymentstatus'
                },
                {
                    id: 'pricelists',
                    title: 'Price List',
                    type: 'item',
                    url: '/pricelists'
                },
                {
                    id: 'epl',
                    title: 'Easy Pay',
                    type: 'collapse',
                    children: [
                        {
                            id: 'epl',
                            title: 'EPL',
                            type: 'item',
                            url: '/epl/cc-rma-request'
                        },
                        {
                            id: 'epl-status',
                            title: 'Retailer Epl Status',
                            type: 'item',
                            url: '/epl/cc-rma-credit-status'
                        },
                        {
                            id: 'payments',
                            title: 'Payments',
                            type: 'item',
                            url: '/epl/cc_rma/payments'
                        }
                    ]
                },
                {
                    id: 'payment-list',
                    title: 'Order Payments',
                    type: 'item',
                    url: '/payment-transactions'
                }
            ]
        },
        {
            id: 'Customer management',
            title: 'Customer Management',
            type: 'collapse',
            icon: icons.SupportAgentIcon,
            children: [
                {
                    id: 'newlead',
                    title: 'New Lead',
                    type: 'item',
                    url: '/newlead'
                },
                {
                    id: 'salesperson',
                    title: 'Retail Partners',
                    type: 'item',
                    url: '/salespartner'
                },
                // {
                //     id: 'myretailers',
                //     title: 'Retailer Profile',
                //     type: 'item',
                //     url: '/retailers/myretailers'
                // },
                {
                    id: 'retailerProfile',
                    title: 'Retailer Profile',
                    type: 'item',
                    url: '/retailers/profile'
                },
                {
                    id: 'mytasks',
                    title: 'Tasks',
                    type: 'item',
                    url: '/tasks/mytasks'
                }
            ]
        }
    ]
};

export default sales;
