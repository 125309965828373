import { Space, Modal, Button } from 'antd';
import { Box, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const FleetAlert = ({ isSuccessOpen, handleSuccessOk, handleSuccessCancel, assingLoading, setAssignLoading }) => {
    return (
        <div>
            <Modal
                open={isSuccessOpen}
                onOk={handleSuccessOk}
                onCancel={handleSuccessCancel}
                width={404}
                centered
                okButtonProps={{
                    disabled: true,
                    style: { display: 'none' }
                }}
                cancelButtonProps={{
                    disabled: true,
                    style: { display: 'none' }
                }}
            >
                <Box
                    sx={{
                        height: '236px',
                        width: '100%',
                        backgroundColor: '#FFFFFF',
                        borderRadius: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            height: '136px',
                            width: '100%'
                        }}
                    >
                        {assingLoading ? (
                            <div className="feet-loader " />
                        ) : (
                            <Typography
                                sx={{ color: '#696E7C', lineHeight: '24px', fontSize: '18px', margin: '8px 0 24px 0', fontWeight: '600' }}
                            >
                                Fleet Assigned
                            </Typography>
                        )}
                        {assingLoading ? (
                            <Typography
                                sx={{
                                    color: '#696E7C',
                                    lineHeight: '24px',
                                    fontSize: '18px',
                                    margin: '8px 0 24px 0',
                                    fontWeight: '600'
                                }}
                            >
                                Assigning Fleet ...
                            </Typography>
                        ) : (
                            <Button
                                type="primary"
                                style={{
                                    backgroundColor: '#FFE3CF',
                                    borderRadius: '12px',
                                    height: '56px',
                                    width: '247px',
                                    color: '#0A2938',
                                    textAlign: 'center',
                                    margin: '0.625rem',
                                    fontWeight: '400',
                                    fontSize: '16px',
                                    padding: '10px 24px'
                                }}
                                onClick={handleSuccessCancel}
                            >
                                Okay, Thanks.
                            </Button>
                        )}
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default FleetAlert;
