import React, { useEffect, useState } from 'react';
import { Space, Modal, Button, Spin, Input, Select, notification, antIcon } from 'antd';
import { Box, Typography, List, ListItem } from '@mui/material';
import { dateConverter, twoDecimalPlaces } from 'utils/functions';
import { LoadingOutlined } from '@ant-design/icons';
import { center } from '@turf/turf';

import { EmptyState } from 'components/EmptyState/EmptyState';

const RetailerProfileModal = ({
    isOpen,
    handleOk,
    handleCancel,
    content,
    heading,
    centered,
    height,
    isRetailerOpen,
    orders,
    getOrderId,
    subheading,
    insightLoading
}) => {
    const [date, setDate] = useState('');

    const itemDate = orders?.filter((order) => order._id === getOrderId).map((order) => order?.orderDate);

    useEffect(() => {
        setDate(itemDate);
    }, [getOrderId]);

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54
            }}
            spin
        />
    );

    return (
        <>
            {' '}
            <Modal
                open={isOpen}
                onOk={handleOk}
                top
                centered={centered}
                width={515}
                zIndex={2050}
                onCancel={handleCancel}
                footer={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            bottom: '0',
                            width: '100%'
                        }}
                    />
                }
            >
                <Box>
                    <Box
                        sx={{
                            color: '#0A2938',
                            display: 'flex',
                            fontSize: '20px',
                            fontWeight: '400',
                            margin: `0px 0 0 ${heading === 'Retailers Information' ? '10px' : '0'}`
                        }}
                    >
                        {orders ? `${dateConverter(date)} / Order details` : heading}
                    </Box>
                    {subheading ? (
                        <Box
                            sx={{
                                color: '#546974',
                                fontSize: '14px',
                                lineHeight: '20px',
                                margin: '10px 0'
                            }}
                        >
                            {subheading}
                        </Box>
                    ) : null}

                    {insightLoading ? (
                        <Box
                            sx={{
                                height: '295px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            {antIcon}
                        </Box>
                    ) : content ? (
                        <Box
                            sx={{
                                height
                            }}
                        >
                            {content}
                        </Box>
                    ) : (
                        <Box
                            style={{
                                position: 'relative',
                                backgroundColor: '#FFFFFF',
                                height: '396px',
                                borderRadius: '16px'
                            }}
                        >
                            <EmptyState height="204px" width="80%" padding="5px" text="No results yet" />
                        </Box>
                    )}
                </Box>
            </Modal>
        </>
    );
};

export default RetailerProfileModal;
