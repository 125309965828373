import { useEffect, useState } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow, Polygon } from 'react-google-maps';
import { DrawingManager } from 'react-google-maps/lib/components/drawing/DrawingManager';
import * as turf from '@turf/turf';
import { Typography, List, ListItem } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';

const MyComponent = withScriptjs(
    withGoogleMap((props) => (
        <GoogleMap defaultZoom={10} defaultCenter={props.center}>
            {/* <DrawingManager
                defaultDrawingMode={window.google.maps.drawing.OverlayType.POLYGON}
                defaultOptions={{
                    drawingControl: true,
                    drawingControlOptions: {
                        position: window.google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: [window.google.maps.drawing.OverlayType.POLYGON]
                    },

                    polygonOptions: {
                        strokeWeight: 5,
                        clickable: false,
                        editable: true,
                        zIndex: 1
                    }
                }}
            /> */}
            {/* 
            <Polygon
                paths={
                    props.country === 'Ghana'
                        ? [
                              { lat: 11.0465, lng: 1.0002 },
                              { lat: 11.0232, lng: -2.9465 },
                              { lat: 4.8548, lng: -2.9232 },
                              { lat: 4.6548, lng: -1.9232 },
                              { lat: 5.1948, lng: -1.0000001 },
                              { lat: 6.0432, lng: 1.2232 }
                          ]
                        : [
                              { lat: 15.0465, lng: 13.01038 },
                              { lat: 14.0232, lng: 4.9465 },
                              { lat: 6.3648, lng: 2.60232 },
                              { lat: 6.4232, lng: 9.6232 }
                          ]
                }
                editable
                draggable
                onClick={() => {
                    props.handlePolygonClick();
                }}
            /> */}

            {props.locations.map(({ color, lat, lng, name }, index) => (
                <Marker key={index} position={{ lat, lng }}>
                    {color ? (
                        <InfoWindow>
                            <div>{name}</div>
                        </InfoWindow>
                    ) : (
                        <div />
                    )}
                </Marker>
            ))}

            {/* {props.selectedPolygon !== null && (
                <InfoWindow onCloseClick={() => props.setSelectedPolygon(null)} position={{ lat: 7.0465, lng: 1.01038 }}>
                    <List
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            padding: '10px',
                            alignItems: 'flex-start',
                            flexDirection: 'column'
                        }}
                    >
                        {' '}
                        <ListItem sx={{ fontWeight: 500, color: '#0A2938', padding: 1 }}>
                            <GroupIcon />
                            <Typography sx={{ fontSize: 18, paddingLeft: 1 }}>
                                {' '}
                                Retailers: {parseFloat(props.locations?.length.toFixed(2)).toLocaleString()}
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ fontWeight: 500, fontSize: '1rem', color: '#0A2938', padding: 1 }}>
                            <AssuredWorkloadIcon />
                            <Typography sx={{ fontSize: 18, paddingLeft: 1 }}>
                                Retailers Inside Fence:
                                {parseFloat(props.markersInsidePolygon.toFixed(2)).toLocaleString()}
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ fontWeight: 500, fontSize: '1rem', color: '#0A2938', padding: 1 }}>
                            <AssuredWorkloadIcon />
                            <Typography sx={{ fontSize: 18, paddingLeft: 1 }}>
                                Outside Fence:
                                {parseFloat((props.locations?.length - props.markersInsidePolygon).toFixed(2)).toLocaleString()}
                            </Typography>
                        </ListItem>
                    </List>
                </InfoWindow>
            )} */}
        </GoogleMap>
    ))
);

const RetailMap = ({ mapCenter, locations, country }) => {
    // const [center, setCenter] = useState({});
    // const zoom = 11;
    const key = 'AIzaSyBZqHjOL-7tPYGJfRKkROaOMxlHfWkou_0';
    // const [markersInsidePolygon, setMarkersInsidePolygon] = useState([]);
    // const [selectedPolygon, setSelectedPolygon] = useState(null);

    // useEffect(() => {
    //     // console.log(locations);
    //     // setCenter(mapCenter);
    // }, []);

    // const regex = /^-?\d+\.\d+$/;

    // const validCoordinates = locations.filter((coordinate) => {
    //     return regex.test(coordinate.lat) && regex.test(coordinate.lng);
    // });

    // const latlong = validCoordinates?.map((result) => ({
    //     lat: result.lat,
    //     lng: result.lng
    // }));

    // const polyaths1 = latlong?.map((marker) => [marker?.lat, marker?.lng]);

    // const points = turf.points(polyaths1);

    // const searchWithin =
    //     country === 'Ghana'
    //         ? turf.polygon([
    //               [
    //                   [11.0465, 1.0002],
    //                   [11.0232, -3.9465],
    //                   [4.8548, -2.9232],
    //                   [4.6548, -1.9232],
    //                   [5.1948, -1.0000001],
    //                   [6.0432, 1.2232],
    //                   [11.0465, 1.0002]
    //               ]
    //           ])
    //         : turf.polygon([
    //               [
    //                   [15.0465, 13.01038],
    //                   [14.0232, 4.9465],
    //                   [6.3648, 2.60232],
    //                   [6.4232, 9.6232],
    //                   [15.0465, 13.01038]
    //               ]
    //           ]);

    // const ptsWithin = turf.pointsWithinPolygon(points, searchWithin);

    // useEffect(() => {
    //     setMarkersInsidePolygon(ptsWithin.features.length);
    // }, [selectedPolygon]);

    // const handlePolygonClick = () => {
    //     return selectedPolygon ? setSelectedPolygon(null) : setSelectedPolygon(markersInsidePolygon);
    // };

    return (
        <>
            <MyComponent
                center={mapCenter}
                locations={locations}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div className="partner-map" />}
                mapElement={<div style={{ height: `100%` }} />}
                // markersInsidePolygon={markersInsidePolygon}
                // selectedPolygon={selectedPolygon}
                // handlePolygonClick={handlePolygonClick}
                // ptsWithin={ptsWithin}
                // setSelectedPolygon={setSelectedPolygon}
                // country={country}
            />
        </>
    );
};

export default RetailMap;
