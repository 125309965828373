import CartPage from 'views/whatAppPlaceOrder/CartPage';
import Home from 'views/whatAppPlaceOrder/Home';
import OrderSummary from 'views/whatAppPlaceOrder/OrderSummary';
import SingleProduct from 'views/whatAppPlaceOrder/SingleProduct';

// ==============================|| UNAUTHENTICATED ROUTING ||============================== //

const UnAuthenticatedRoutes = {
    path: '/',
    children: [
        {
            path: '/whatsapporder/homepage',
            element: <Home />
        },
        {
            path: '/whatsapporder/product/:id',
            element: <SingleProduct />
        },
        {
            path: '/whatsapporder/product/summary',
            element: <OrderSummary />
        },
        {
            path: '/whatsapporder/product/cart',
            element: <CartPage />
        }
    ]
};

export default UnAuthenticatedRoutes;
