/* eslint-disable no-bitwise */
const removeTags = (str) => {
    if (str === null || str === '') {
        return false;
    }
    str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, '');
};

const formatCurrency = (amount) => {
    let currency;
    const userCountry = localStorage.getItem('country');
    if (userCountry === 'Ghana') currency = 'GHS';
    if (userCountry === 'Nigeria') currency = 'NGN';

    return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(amount);
};

const detectCurrencyType = (amount) => {
    const userCurrency = localStorage.getItem('userCurrency');
    const countryFilter = localStorage.getItem('countryFilter');
    let currency = userCurrency;
    if (countryFilter === 'Ghana') currency = 'GHS';
    if (countryFilter === 'Nigeria') currency = 'NGN';
    if (countryFilter === 'Cameroon') currency = 'CFA';

    return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(amount);
};

const wait = (seconds) => {
    return new Promise((resolve) => {
        setTimeout(resolve, seconds * 1000);
    });
};

const cryptoUUID = () => {
    let d = new Date().getTime();
    // Timestamp
    let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0;
    // Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = Math.random() * 16;
        // random number between 0 and 16
        if (d > 0) {
            // Use timestamp until depleted
            // eslint-disable-next-line no-bitwise
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            // Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
};

// 2 decimal places
const twoDecimalPlaces = (value) => {
    return parseFloat(value?.toFixed(2)).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
};

const dateConverter = (value) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
        // weekday: 'long'
        // hour: '2-digit',
        // minute: '2-digit',
        // second: '2-digit'
    };

    return new Date(value).toLocaleDateString('en-US', options);
};

export { removeTags, formatCurrency, detectCurrencyType, wait, cryptoUUID, twoDecimalPlaces, dateConverter };
