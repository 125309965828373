import GenericPageHeader from 'components/GenericPageHeader';
import SaleDeliveryTable from './SaleDeliveryTable';

const SalePersonDelivery = () => {
    return (
        <>
            <GenericPageHeader title="All Delivery Notes" breadcrumbs="Home | All Delivery Notes" />
            <SaleDeliveryTable
                th1="#"
                th2="Delivery Note Date"
                th3="Delivery Note Id"
                th4="Sales Person"
                th5="Order Number"
                th6="Status"
                th7="Notes"
                th8="Actions"
            />
        </>
    );
};
export default SalePersonDelivery;
