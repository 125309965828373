import styled from 'styled-components';
import Logo from '../../assets/images/garage_logo.png';

const NoteWrapper = styled.div`
    .page-header {
        padding: 1rem 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media screen and (max-width: 500px) {
        .page-header {
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }
    }

    h5 {
        font-weight: 700;
        color: #444;
    }

    h6 {
        font-size: 1.3rem;
        color: #ff740f;
        font-weight: 800;
        text-transform: uppercase;
    }
`;

const NoteHeader = () => {
    return (
        <NoteWrapper>
            <div className="page-header">
                <div className="logo">
                    <img src={Logo} alt="Garage identity" width={200} />
                </div>
                <div className="identity">
                    <h5>Garage Mobility Limited</h5>
                    <p>https://web.garagemobility.com/</p>
                    <h6>Delivery Note</h6>
                </div>
            </div>
        </NoteWrapper>
    );
};
export default NoteHeader;
