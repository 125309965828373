import { Button, Collapse, Input, Select, Table, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ApiUrl from '../../network';
import { toast } from 'react-toastify';
import { decrypt } from '../../utils/encrypt';
import debounce from 'lodash/debounce';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

export const Index = () => {
    const [loading, setLoading] = useState(false);
    const [parts, setParts] = useState([]);
    const [filters, setFilters] = useState({
        partname: '',
        subCategory: '',
        category: '',
        brand: ''
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(0);
    const [partsFamily, setPartsFamily] = useState([]);
    const [cities, setCities] = useState([]);

    const fetchParts = useCallback(async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const urlParams = new URLSearchParams({
                ...filters,
                limit: perPage,
                page: currentPage
            });
            const url = `${ApiUrl.BaseUrl}/parts/v2/rmaccpartcatalogindex?${urlParams.toString()}`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                setParts(decryptedData);
                setCurrentPage(result.currentPage);
                setPerPage(result.perPage);
            } else {
                toast.error(result.message);
            }
        } catch (e) {
            toast(e.message);
        } finally {
            setLoading(false);
        }
    }, [filters, currentPage, perPage]);
    const debouncedFetchParts = useCallback(debounce(fetchParts, 300), [fetchParts]);

    useEffect(() => {
        debouncedFetchParts();
        return () => {
            debouncedFetchParts.cancel();
        };
    }, [filters, currentPage, perPage, debouncedFetchParts]);

    const fetchFilteredParts = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const url = `${ApiUrl.BaseUrl}/parts/v2/partsfilterdata`;
            const response = await fetch(url, config);
            const result = await response.json();
            if (result.message.toLowerCase() === 'success') {
                const decryptedData = JSON.parse(decrypt(result.data, keyResult, ivResult));
                setPartsFamily(decryptedData.productfamily);
                setCities(decryptedData.city);
                setLoading(false);
            } else {
                toast.error(result.message);
                setLoading(false);
            }
        } catch (e) {
            toast(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchParts();
        fetchFilteredParts();
    }, []);

    const partSource = parts.map((part) => {
        return {
            key: part._id,
            product_name: {
                name: part.sku,
                image: part.partsImage
            },
            product_image: part.partsImage,
            status: part.status,
            product_family: part.productFamily,
            qnty_in_pieces: part.partQtyInPieces,
            wholesale_price: part.wholeSalePrice,
            selling_price: part.garagePrice,
            city_price: part.cityprice,
            city: part.city,
            state: part?.imsstatus?.name
        };
    });
    const cityOptions = cities.map((city) => {
        return {
            value: city._id,
            label: city.city
        };
    });
    const productFamilyOptions = partsFamily.map((family) => {
        return {
            value: family.name,
            label: family.name
        };
    });

    const columns = [
        {
            title: 'Product name',
            dataIndex: 'product_name',
            key: 'product_name',
            // eslint-disable-next-line camelcase
            render: (product_name) => (
                <div className="d-flex align-items-center">
                    <img
                        src={product_name.image}
                        onError={(e) => {
                            e.target.src = 'https://via.placeholder.com/150';
                        }}
                        alt={product_name.name}
                        className="mr-2"
                        style={{
                            width: 50,
                            height: 50,
                            objectFit: 'cover',
                            borderRadius: '10px',
                            border: '1px solid #f0f0f0'
                        }}
                    />
                    <span>{product_name.name}</span>
                </div>
            )
        },
        {
            title: 'Product family',
            dataIndex: 'product_family',
            key: 'product_family'
        },
        {
            title: 'Qnty in pieces',
            dataIndex: 'qnty_in_pieces',
            key: 'qnty_in_pieces'
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city'
        },
        {
            title: 'City price',
            dataIndex: 'city_price',
            key: 'city_price'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => (
                <div className="d-flex">
                    <Button
                        onClick={() => (window.location.href = `/parts-catalog/${record.key}`)}
                        size="small"
                        className="w-fit secondary-bg text-white ml-2"
                    >
                        View
                    </Button>
                </div>
            )
        }
    ];

    const resetFilters = () => {
        setFilters({
            partname: '',
            status: '',
            category: '',
            brand: ''
        });
        setCurrentPage(1);
        setPerPage(0);
    };

    return (
        <div>
            <div className="m-header">
                <div>
                    <h1>Part catalog</h1>
                    <p>View all parts and their details</p>
                </div>
            </div>
            <div className="m-content mt-4">
                <Collapse accordion>
                    <Collapse.Panel key={1} header="Hide filters" className="mb-4">
                        <div className="batchFilters">
                            <div className="mr-2">
                                <Input
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            debouncedFetchParts();
                                        }
                                    }}
                                    className="filterInput"
                                    style={{
                                        width: 300
                                    }}
                                    placeholder="Search products"
                                    value={filters.partname || undefined}
                                    onChange={(e) => {
                                        setFilters({
                                            ...filters,
                                            partname: e.target.value
                                        });
                                    }}
                                />
                            </div>
                            <div className="mr-2">
                                <Input
                                    className="filterInput"
                                    style={{
                                        width: 300
                                    }}
                                    placeholder="Brand"
                                    value={filters.brand || undefined}
                                    onChange={(e) => {
                                        setFilters({
                                            ...filters,
                                            brand: e.target.value
                                        });
                                    }}
                                />
                            </div>
                            <div className="mr-2">
                                <Select
                                    className="filterInput"
                                    style={{
                                        width: 300
                                    }}
                                    placeholder="Category"
                                    filterOption={(inputValue, option) => {
                                        if (typeof option.label === 'string') {
                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }

                                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }}
                                    showSearch
                                    allowClear
                                    options={productFamilyOptions}
                                    value={filters.productfamily || undefined}
                                    onChange={(value) => {
                                        setFilters({
                                            ...filters,
                                            category: value
                                        });
                                    }}
                                />
                            </div>
                            <div className="mr-2">
                                <Input
                                    className="filterInput"
                                    style={{
                                        width: 300
                                    }}
                                    placeholder="Sub category"
                                    value={filters.subCategory || undefined}
                                    onChange={(e) => {
                                        setFilters({
                                            ...filters,
                                            subCategory: e.target.value
                                        });
                                    }}
                                />
                            </div>
                            <div className="d-flex align-items-center">
                                {filters.partname || filters.category || filters.productfamily || filters.subCategory ? (
                                    <Button
                                        style={{
                                            color: '#FF740F',
                                            border: '1px solid #FF740F'
                                        }}
                                        onClick={fetchParts}
                                    >
                                        Apply filter
                                    </Button>
                                ) : null}
                                {filters.partname || filters.category || filters.productfamily || filters.subCategory ? (
                                    <Button
                                        style={{
                                            color: '#FF740F',
                                            border: '1px solid #FF740F'
                                        }}
                                        className="ml-2"
                                        onClick={resetFilters}
                                    >
                                        Reset filter
                                    </Button>
                                ) : null}
                            </div>
                        </div>
                    </Collapse.Panel>
                </Collapse>
                <div className="table-responsive">
                    <Table columns={columns} dataSource={partSource} loading={loading} />
                </div>
            </div>
        </div>
    );
};

export default Index;
