import React, { useState, useEffect } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PieChart from './PieChart';
import BarChart from './BarChart';

// Pseudo data source
import data from './data';

// import ApiUrl from 'network';

// const { BaseUrl } = ApiUrl;

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const Emissions = () => {
    const classes = useStyles();
    const [emissions, setEmissions] = useState([]);
    const [totalEmissionsGraph, setTotalEmissionsGraph] = useState(null);
    const [weeklyGraphData, setWeeklyGraphData] = useState(null);
    const [weeklyCarGraphData, setWeeklyCarGraphData] = useState(null);
    const [list, setList] = useState([]);
    const [limit, setLimit] = useState(5);
    const [loading, setLoading] = useState(true);

    const margin = 5;

    useEffect(() => {
        setTimeout(() => {
            setEmissions(data);
            const arr = [];
            let len = 0;
            if (data.length === limit) {
                len = limit;
            } else if (data.length > limit) {
                len = limit;
            } else {
                len = data.length;
            }
            data.forEach((item, index) => {
                if (index < len) {
                    arr.push(item);
                }
            });
            setList(arr);

            // Total emission analysis goes here
            const r = Math.floor(Math.random() * 255);
            const g = Math.floor(Math.random() * 255);
            const b = Math.floor(Math.random() * 255);
            const totalEGraphData = {
                labels: ['Total Emissions'],
                datasets: [
                    {
                        label: 'Total Car Emissions',
                        data: [230],
                        backgroundColor: [`rgba(${r}, ${g}, ${b}, 0.4)`],
                        borderColor: `rgba(${r}, ${g}, ${b}, 1)`,
                        borderWidth: 1
                    }
                ]
            };
            setTotalEmissionsGraph(totalEGraphData);

            // Week graph analysis goes here
            const weekly = [2000, 3534.56, 6000, 1000, 7000];
            const weeklyBgColor = [];
            const weeklyBorderColor = [];

            weekly.forEach(() => {
                const r = Math.floor(Math.random() * 255);
                const g = Math.floor(Math.random() * 255);
                const b = Math.floor(Math.random() * 255);
                const color = `rgba(${r}, ${g}, ${b}, 0.4)`;
                const borderColor = `rgba(${r}, ${g}, ${b}, 0.4)`;
                weeklyBgColor.push(color);
                weeklyBorderColor.push(borderColor);
            });

            const weeklyGData = {
                labels: ['22/1/2022', '29/1/2022', '5/2/2022', '12/2/2022'],
                datasets: [
                    {
                        label: 'Weekly CO2 Emissions for cars measured',
                        data: weekly,
                        backgroundColor: weeklyBgColor,
                        borderColor: weeklyBorderColor,
                        borderWidth: 1
                    }
                ]
            };
            setWeeklyGraphData(weeklyGData);

            // Weekly car analysis goes here
            const weeklyCar = [2000, 3534.56, 6000, 1000, 7000];
            const weeklyCarBgColor = [];
            const weeklyCarBorderColor = [];

            weeklyCar.forEach(() => {
                const r = Math.floor(Math.random() * 255);
                const g = Math.floor(Math.random() * 255);
                const b = Math.floor(Math.random() * 255);
                const color = `rgba(${r}, ${g}, ${b}, 0.4)`;
                const borderColor = `rgba(${r}, ${g}, ${b}, 0.4)`;
                weeklyCarBgColor.push(color);
                weeklyCarBorderColor.push(borderColor);
            });

            const weeklyCarGData = {
                labels: ['22/1/2022', '29/1/2022', '5/2/2022', '12/2/2022'],
                datasets: [
                    {
                        label: 'Weekly CO2 Emissions for cars measured',
                        data: weeklyCar,
                        backgroundColor: weeklyCarBgColor,
                        borderColor: weeklyCarBorderColor,
                        borderWidth: 1
                    }
                ]
            };
            setWeeklyCarGraphData(weeklyCarGData);

            setLoading(false);
        }, 2000);
    }, [limit]);

    const goBack = () => {
        if (limit > margin) {
            const val = limit - margin;
            setLimit(val);
        } else {
            setLimit(margin);
        }
    };

    const goForward = () => {
        if (emissions.length > limit) {
            const val = limit + margin;
            setLimit(val);
        } else {
            setLimit(limit);
        }
    };

    const tHeadArr = ['WhatsApp', 'License Plate', 'Car Make', 'Car Year', 'Car Model', 'Fuel Type', 'Emission', 'Milleage'];
    const tHead = tHeadArr.map((item, index) => (
        <React.Fragment key={index}>
            <TableCell align="left" className={classes.tableHeaderCell}>
                {item}
            </TableCell>
        </React.Fragment>
    ));

    const tBody = list.map((item, index) => (
        <React.Fragment key={index}>
            <tr>
                <td>{item.contact}</td>
                <td>{item.license}</td>
                <td>{item.carMake}</td>
                <td>{item.carYear}</td>
                <td>{item.carModel}</td>
                <td>{item.fuelType}</td>
                <td>{item.emissions}</td>
                <td>{item.milleage}</td>
            </tr>
        </React.Fragment>
    ));

    return (
        <>
            <PageHeader title="Emissions Dashboard" subTitle="Emissions" />
            <div className="row">
                <div className="col-8 col-lx-12 col-l-12 col-m-12 col-s-12 padding-all-5">
                    <MainCard title="Emissions">
                        {loading ? (
                            <div className="flex-column justify-content-center align-items-center">
                                <CircularProgress />
                            </div>
                        ) : (
                            <TableContainer component={Paper}>
                                <Table className="standard-table" sx={{ minWidth: 450 }} aria-label="caption table">
                                    <TableHead>
                                        <TableRow>{tHead}</TableRow>
                                    </TableHead>
                                    <TableBody>{tBody}</TableBody>
                                </Table>
                            </TableContainer>
                        )}
                        <br />
                        <div className="flex-row-reverse">
                            <div className="width-20 width-lx-25 width-l-30 width-m-40 width-s-60">
                                <div className="row align-items-center">
                                    <div className="col-6 padding-all-5">
                                        <Select
                                            label="Page"
                                            labelId="select-label"
                                            id="select-page"
                                            value={limit}
                                            onChange={(e) => setLimit(e.target.value)}
                                            className="gar-select"
                                        >
                                            <MenuItem value={0}>Page</MenuItem>
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={25}>25</MenuItem>
                                            <MenuItem value={emissions.length}>All</MenuItem>
                                        </Select>
                                    </div>
                                    <div className="col-3 padding-all-5">
                                        <button onClick={() => goBack()} type="button" className="none">
                                            <ChevronLeftIcon className="font-20" />
                                        </button>
                                    </div>
                                    <div className="col-3 padding-all-5">
                                        <button onClick={() => goForward()} type="button" className="none">
                                            <ChevronRightIcon className="font-20" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MainCard>
                </div>
                <div className="col-4 col-lx-12 col-l-12 col-m-12 col-s-12 padding-all-5">
                    <MainCard title="Emissions summary metrics">
                        <div className="row justify-content-space-around">
                            <div className="col-12 col-lx-8 col-s-12 padding-all-5">
                                <PieChart data={totalEmissionsGraph} />
                            </div>
                            <div className="col-12 col-lx-8 col-s-12 padding-all-5">
                                <div className="padding-all-10" />
                                <BarChart data={weeklyGraphData} />
                            </div>

                            <div className="col-12 col-lx-8 col-s-12 padding-all-5">
                                <div className="padding-all-10" />
                                <BarChart data={weeklyCarGraphData} />
                            </div>
                        </div>
                    </MainCard>
                </div>
            </div>
        </>
    );
};

export default Emissions;
