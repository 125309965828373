import { Col, Container, Row } from 'react-grid-system';

import BatchCard from './BatchCard';
import BatchTableData from './BatchTableData';
import MoonLoader from 'react-spinners/MoonLoader';
import { useBatchContext } from 'context/order-batching/batchContext';
import { useEffect } from 'react';

const BatchCardList = () => {
    const {
        batchdatatoday,
        createdbatchtoday,
        fullysorcedbatchtoday,
        partiallysourcedbatchtoday,
        intransitbatchtoday,
        deliveredbatchtoday,
        verifiedbatchtoday,
        getAllBatchOrders,
        isLoading,
        orderType
    } = useBatchContext();

    useEffect(() => {
        getAllBatchOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderType]);

    return (
        <div>
            <Container>
                <Row gutterWidth={16}>
                    <Col xl={2} lg={3} md={4} sm={6} xs={12}>
                        <BatchCard title="Batch Created" count={createdbatchtoday} />
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={6} xs={12}>
                        <BatchCard title="Batch fully sourced" count={fullysorcedbatchtoday} />
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={6} xs={12}>
                        <BatchCard title="Batch partially sourced" count={partiallysourcedbatchtoday} />
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={6} xs={12}>
                        <BatchCard title="Batch in transit" count={intransitbatchtoday} />
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={6} xs={12}>
                        <BatchCard title="Batch delivered" count={deliveredbatchtoday} />
                    </Col>
                    <Col xl={2} lg={3} md={4} sm={6} xs={12}>
                        <BatchCard title="Batch verified" count={verifiedbatchtoday} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {isLoading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px' }}>
                                <MoonLoader color="#ff740f" />
                            </div>
                        ) : (
                            <BatchTableData
                                rows={batchdatatoday}
                                th1="Batch Number"
                                th2="Date"
                                th3="Order Numbers"
                                th4="Batch Status"
                                th5="Actions"
                                th6="#"
                            />
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default BatchCardList;
