import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Button, Input, Select, DatePicker, Modal, Divider } from 'antd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { decrypt, encrypt } from '../../../utils/encrypt';
import { toast } from 'react-toastify';
import ApiUrl from '../../../network';

export const Index = () => {
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [pods, setPods] = useState([]);
    const [rmas, setRmas] = useState([]);
    const [salesPersons, setSalesPersons] = useState([]);
    const [loading, setLoading] = useState(false);

    const [fields, setFields] = useState([
        {
            id: Date.now(),
            pod: '',
            name: '',
            gmvtarget: null,
            conversiontarget: null,
            retentiontarget: null,
            startDate: '',
            endDate: '',
            revenuetarget: null
        }
    ]);

    const fetchPodsData = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/salesdrivepod/salesdrivepod`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setPods(JSON.parse(decrypt(data.data, keyResult, ivResult)));

                        // Extract and set all salespersons
                        const allSalespersons = [];
                        result.forEach((pod) => {
                            pod.salesperson.forEach((salesperson) => {
                                allSalespersons.push(salesperson);
                            });
                        });
                        setSalesPersons(allSalespersons);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        toast.error(error.message);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        fetchPodsData();
    }, []);

    const podOptions = pods?.map((pod) => {
        return {
            label: pod.name,
            value: pod.name
        };
    });
    const rmsOptions = rmas?.map((rma) => {
        return {
            label: `${rma?.firstName} ${rma?.lastName}`,
            value: rma?.firstName
        };
    });

    const addField = () => {
        const newField = {
            id: Date.now(),
            rma: '',
            pod: '',
            location: '',
            target: '',
            driveDate: ''
        };

        setFields((prevFields) => [...prevFields, newField]);
    };

    const removeField = (id) => {
        if (id) {
            const newFields = fields.filter((field) => field.id !== id);
            setFields(newFields);
        }
    };

    const updateFieldValue = (id, field, value) => {
        setFields((prevFields) => prevFields.map((prevField) => (prevField.id === id ? { ...prevField, [field]: value } : prevField)));
    };

    const addKpiHandler = async () => {
        setLoading(true);
        const items = fields.map((field) => {
            if (
                !field.pod ||
                !field.name ||
                !field.startDate ||
                !field.endDate ||
                !field.gmvtarget ||
                !field.conversiontarget ||
                !field.retentiontarget
            ) {
                toast.error('Please fill all the fields');
                return null;
            }

            const podID = pods.find((pod) => pod.name === field.pod)?._id;
            const salesPersonID = salesPersons.find((salesPerson) => salesPerson.firstName === field.name)?.userId;

            return {
                pod: podID,
                userId: salesPersonID,
                gmvtarget: field.gmvtarget,
                conversiontarget: field.conversiontarget,
                retentiontarget: field.retentiontarget,
                revenuetarget: field.revenuetarget,
                startDate: field.startDate.format('YYYY-MM-DD'),
                endDate: field.endDate.format('YYYY-MM-DD')
            };
        });

        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        const datas = {
            kpiplanner: items
        };

        const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);

        await fetch(`${ApiUrl.BaseUrl}/kpiplan/addkpiplanning`, {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify({ payload })
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setLoading(false);
                    toast.success(data.message);
                    setFields([
                        {
                            id: Date.now(),
                            pod: '',
                            name: '',
                            gmvtarget: null,
                            conversiontarget: null,
                            retentiontarget: null,
                            startDate: '',
                            endDate: ''
                        }
                    ]);
                } else {
                    setLoading(false);
                    toast.error(data.message);
                }
            });
    };

    const podSelectHandler = (value, field) => {
        updateFieldValue(field.id, 'pod', value);
        const selectedPod = pods.find((pod) => pod.name === value);
        if (selectedPod) {
            setRmas(selectedPod?.salesperson);
        } else {
            updateFieldValue(field.id, 'rma', null);
        }
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Link to="/kpi" className="createScheduleLink">
                    <p style={{ fontSize: '25px', marginLeft: '10px' }}>
                        <ArrowBackIcon sx={{ fontSize: '25px', color: '#000' }} /> Set target
                    </p>
                </Link>
                <Button
                    onClick={addKpiHandler}
                    style={{ backgroundColor: '#FF740F', color: '#fff' }}
                    className="btnCreateSchedule"
                    title="Apply Filters"
                    loading={loading}
                >
                    <TrackChangesIcon sx={{ fontSize: '16px', color: '#fff' }} />
                    <Typography sx={{ margin: '0 14px ', color: '#fff' }}>Set Target</Typography>
                </Button>
            </div>
            <div className="addPlanContainer">
                <div className="inputHolder">
                    {fields.length > 1 && (
                        <p style={{ fontSize: '10px', color: '#546974', marginBottom: '10px' }}>{fields.length} Set Target Form Field </p>
                    )}
                    {fields.map((field, id) => (
                        <div key={field.id}>
                            <div className="addSalePlanInput">
                                <div className="mr-2">
                                    <p style={{ fontSize: '16px', color: '#546974' }}>POD</p>
                                    <Select
                                        placeholder="POD"
                                        showSearch
                                        allowClear
                                        size="large"
                                        style={{ width: '300px' }}
                                        value={field.pod}
                                        onChange={(value) => podSelectHandler(value, field)}
                                        filterOption={(inputValue, option) => {
                                            if (typeof option.label === 'string') {
                                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }

                                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }}
                                        options={podOptions}
                                    />
                                </div>
                                <div>
                                    <p style={{ fontSize: '16px', color: '#546974' }}>Name</p>
                                    <Select
                                        placeholder="Name"
                                        allowClear
                                        size="large"
                                        style={{ width: '300px' }}
                                        value={field.name}
                                        options={rmsOptions}
                                        onChange={(value) => updateFieldValue(field.id, 'name', value)}
                                    />
                                </div>
                            </div>
                            <div>
                                <p style={{ fontSize: '16px', color: '#546974', marginTop: '10px' }}>Target Duration </p>
                                <div className="addSalePlanInput">
                                    <div className="mr-2">
                                        <DatePicker
                                            placeholder="Start Date"
                                            format="DD/MM/YYYY"
                                            size="large"
                                            style={{
                                                width: '300px'
                                            }}
                                            value={field.startDate}
                                            onChange={(value) => updateFieldValue(field.id, 'startDate', value)}
                                        />
                                    </div>
                                    <div>
                                        <DatePicker
                                            placeholder="End Date"
                                            format="DD/MM/YYYY"
                                            size="large"
                                            style={{
                                                width: '300px'
                                            }}
                                            value={field.endDate}
                                            onChange={(value) => updateFieldValue(field.id, 'endDate', value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="addSalePlanInput">
                                <div className="flex-grow-1">
                                    <p style={{ fontSize: '16px', color: '#546974' }}>GMV Target</p>
                                    <Input
                                        type="number"
                                        placeholder="GMV Target"
                                        size="large"
                                        style={{ width: '100%' }}
                                        value={field.gmvtarget}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const parsedValue = inputValue !== '' ? parseFloat(inputValue) : null;
                                            updateFieldValue(field.id, 'gmvtarget', parsedValue);
                                        }}
                                    />
                                </div>
                                <div className="flex-grow-1 ml-2">
                                    <p style={{ fontSize: '16px', color: '#546974' }}>Conversion Target </p>
                                    <Input
                                        type="number"
                                        placeholder="Conversion Target "
                                        size="large"
                                        style={{ width: '100%' }}
                                        value={field.conversiontarget}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const parsedValue = inputValue !== '' ? parseFloat(inputValue) : null;
                                            updateFieldValue(field.id, 'conversiontarget', parsedValue);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="addSalePlanInput">
                                <div className="flex-grow-1">
                                    <p style={{ fontSize: '16px', color: '#546974' }}>Retention Target </p>
                                    <Input
                                        type="number"
                                        placeholder="Retention Target "
                                        size="large"
                                        style={{ width: '100%' }}
                                        value={field.retentiontarget}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const parsedValue = inputValue !== '' ? parseFloat(inputValue) : null;
                                            updateFieldValue(field.id, 'retentiontarget', parsedValue);
                                        }}
                                    />
                                </div>
                                <div className="flex-grow-1 ml-2">
                                    <p style={{ fontSize: '16px', color: '#546974' }}>Revenue Target</p>
                                    <Input
                                        type="number"
                                        placeholder="Revenue Target"
                                        size="large"
                                        style={{ width: '100%' }}
                                        value={field.revenuetarget}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const parsedValue = inputValue !== '' ? parseFloat(inputValue) : null;
                                            updateFieldValue(field.id, 'revenuetarget', parsedValue);
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="addSalePlanInput margin-top-20">
                                <Button
                                    onClick={() => removeField(field.id)}
                                    style={{ border: '1px solid #CED4D7', backgroundColor: '#fff', color: '#000' }}
                                    className="mt-2 mb-2 d-flex align-items-center justify-content-cente btnCreateSchedule"
                                    title="Apply Filters"
                                >
                                    <Typography sx={{ margin: '0 14px ', color: '#000' }}>Cancel</Typography>
                                </Button>
                                <Button
                                    onClick={addField}
                                    style={{ border: '1px solid #FF740F', backgroundColor: '#fff', color: '#fff' }}
                                    className="btnCreateSchedule"
                                    title="Apply Filters"
                                >
                                    <AddBoxIcon sx={{ fontSize: '16px', color: '#FF740F' }} />
                                    <Typography sx={{ margin: '0 14px ', color: '#FF740F' }}>Add Target</Typography>
                                </Button>
                            </div>
                            {id !== fields.length - 1 && <Divider />}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Index;
