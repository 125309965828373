import { Button, Card, Divider, Input, Select, Spin, Checkbox, Popover, Tag, Modal } from 'antd';
import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    CalendarOutlined,
    DownOutlined,
    LoadingOutlined,
    SearchOutlined,
    ShopOutlined,
    UserOutlined
} from '@ant-design/icons';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CallIcon from '@mui/icons-material/Call';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ApiUrl from '../../../network';
import { decrypt, encrypt } from '../../../utils/encrypt';
import { useParams, Link } from 'react-router-dom';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');
export const Index = () => {
    const [loading, setLoading] = useState(false);
    const [retailer, setRetailer] = useState({});
    const [source, setSource] = useState('');
    const [note, setNote] = useState('');
    const [country, setCountry] = useState('');
    const [notes, setNotes] = useState([]);
    const [sourceOptions, setSourceOptions] = useState([]);
    const [retailers, setRetailers] = useState([]);
    const { id } = useParams();
    const [leadProfileModal, setLeadProfileModal] = useState(false);
    const [summaryModal, setSummaryModal] = useState(false);
    // eslint-disable-next-line consistent-return
    const fetchRetailer = async (id) => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(`${ApiUrl.BaseUrl}/crm/get-channel-partner?retailer_id=${id}`, config);
            await response.json().then((data) => {
                if (data.status === false) {
                    toast.error(data.message);
                    setLoading(false);
                } else {
                    setRetailer(JSON.parse(decrypt(data.data, keyResult, ivResult)));
                    setLoading(false);
                }
            });
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };
    // eslint-disable-next-line consistent-return
    const fetchUserNotes = async (id) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(`${ApiUrl.BaseUrl}/crm/fetch-partner-notes?retailer_id=${id}`, config);
            const data = await response.json().then((data) => {
                if (data.status === false) {
                    toast.error(data.message);
                } else {
                    setNotes(JSON.parse(decrypt(data.data, keyResult, ivResult)));
                }
            });
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
                setLoading(false);
            } else {
                toast.error('An error occurred');
                setLoading(false);
            }
        }
    };
    useEffect(() => {
        // eslint-disable-next-line consistent-return
        const getSource = async () => {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(`${ApiUrl.BaseUrl}/crm/get-contact-sources`, config);
            if (!response.ok) {
                toast.error('Failed to fetch source');
            }
            const data = await response.json();
            setSourceOptions(JSON.parse(decrypt(data.data, keyResult, ivResult)));
        };

        // eslint-disable-next-line consistent-return
        const fetchAllRetailers = async () => {
            try {
                const userInfo = localStorage.getItem('userToken');
                if (!userInfo) {
                    toast.error('User not authenticated');
                    return false;
                }
                const config = {
                    headers: {
                        'auth-token': userInfo,
                        'Content-Type': 'application/json'
                    }
                };

                const response = await fetch(`${ApiUrl.BaseUrl}/crm/get-channel-partners`, config);
                await response.json().then((data) => {
                    if (data.status === false) {
                        toast.error(data.message);
                        setLoading(false);
                    } else {
                        setRetailers(JSON.parse(decrypt(data.data, keyResult, ivResult)));
                        setLoading(false);
                    }
                });
            } catch (error) {
                if (error instanceof TypeError) {
                    toast.error('Network error occurred');
                    setLoading(false);
                } else {
                    toast.error('An error occurred');
                    setLoading(false);
                }
            }
        };

        if (id) {
            fetchRetailer(id);
            fetchUserNotes(id);
            getSource();
            fetchAllRetailers();
        }

        return () => {
            // cleanup function
            setLoading(false);
        };
    }, [id]);

    const retailersOptions = retailers.map((retailer) => {
        return { value: retailer._id, label: retailer.partnerName };
    });

    // eslint-disable-next-line consistent-return
    const handleAddNote = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return false;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const datas = {
                retailer_id: id,
                note,
                source,
                country: localStorage.getItem('country')
            };
            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);

            const response = await fetch(`${ApiUrl.BaseUrl}/crm/store-note`, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify({ payload })
            });
            await response.json().then((data) => {
                if (data.status === false) {
                    toast.error(data.message);
                } else {
                    toast.success('Note added successfully');
                    setNote('');
                    setSource('');
                    setCountry('');
                    fetchUserNotes(id);
                }
            });
        } catch (error) {
            if (error instanceof TypeError) {
                toast.error('Network error occurred');
            } else {
                toast.error('An error occurred');
            }
        }
    };

    return (
        <div className="leadCrmContainer">
            <div className="d-flex justify-content-space-between align-items-center salesCardHolder">
                <Link to="/salescrm" style={{ color: '#0A2938' }}>
                    <p style={{ fontSize: '30px' }}>
                        {' '}
                        <KeyboardBackspaceIcon style={{ marginRight: '5px' }} /> Lead’s profile
                    </p>
                </Link>
                <div>
                    <Select
                        style={{ width: 300 }}
                        placeholder="Search retailer"
                        className="mr-2"
                        allowClear
                        showSearch
                        options={retailersOptions}
                        onChange={(value) => {
                            fetchRetailer(value);
                            fetchUserNotes(value);
                        }}
                        optionFilterProp="children"
                        filterOption={(inputValue, option) => {
                            if (typeof option.label === 'string') {
                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }

                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        }}
                    />
                </div>
            </div>
            <Divider style={{ margin: '20px 0', border: '1px dash #e8e8e8' }} />
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 34
                                }}
                                spin
                            />
                        }
                    />
                </div>
            ) : (
                <div>
                    <div className="d-flex align-items-center justify-content-space-between salesCardHolder">
                        <Card className="salesCard" style={{ borderRadius: '20px' }}>
                            <div className="d-flex align-items-center mb-2">
                                <span
                                    style={{
                                        backgroundColor: '#F6F6F6',
                                        padding: '10px',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '50px',
                                        height: '50px',
                                        color: '#8C9DAB',
                                        fontSize: '1.1rem',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    <UserOutlined />
                                </span>
                                <span className="ml-2">
                                    <p style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '0px', color: '#3B5460' }}>
                                        {' '}
                                        {retailer?.partnerName}{' '}
                                    </p>
                                    <small style={{ color: '#3B5460', fontSize: '14px' }}>Retailer’s name</small>
                                </span>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                <span
                                    style={{
                                        backgroundColor: '#F6F6F6',
                                        padding: '10px',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '40px',
                                        height: '40px',
                                        color: '#8C9DAB',
                                        fontSize: '0.9rem',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    <CallIcon />
                                </span>
                                <span className="ml-2">
                                    <p style={{ fontSize: '1rem', marginBottom: '0px', color: '#3B5460' }}> {retailer?.partnerContact} </p>
                                </span>
                            </div>
                            <Button
                                style={{
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    padding: '0px'
                                }}
                                onClick={() => (retailer ? setLeadProfileModal(true) : null)}
                            >
                                <div className="d-flex align-items-center ">
                                    <span
                                        style={{
                                            backgroundColor: '#F6F6F6',
                                            padding: '10px',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '40px',
                                            height: '40px',
                                            color: '#FF740F',
                                            fontSize: '0.9rem',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        <NorthEastIcon />
                                    </span>
                                    <span className="ml-2">
                                        <p style={{ fontSize: '1rem', marginBottom: '0px', color: '#FF740F' }}>View more details</p>
                                    </span>
                                </div>
                            </Button>
                        </Card>
                        <Card className="salesCard" style={{ borderRadius: '20px' }}>
                            <div className="d-flex align-items-center mb-2">
                                <span
                                    style={{
                                        backgroundColor: '#F6F6F6',
                                        padding: '10px',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '50px',
                                        height: '50px',
                                        color: '#8C9DAB',
                                        fontSize: '1.1rem',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    <ShopOutlined />
                                </span>
                                <span className="ml-2">
                                    <p style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '0px', color: '#3B5460' }}>
                                        {' '}
                                        {retailer?.shopName || 'No shop name available'}{' '}
                                    </p>
                                    <small style={{ color: '#3B5460', fontSize: '14px' }}>Shop name</small>
                                </span>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                <span
                                    style={{
                                        backgroundColor: '#F6F6F6',
                                        padding: '10px',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '40px',
                                        height: '40px',
                                        color: '#8C9DAB',
                                        fontSize: '0.9rem',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    <AddLocationIcon />
                                </span>
                                <span className="ml-2">
                                    <p style={{ fontSize: '0.8rem', marginBottom: '0px', color: '#3B5460' }}>
                                        {' '}
                                        {retailer?.location || 'No location added'} - {retailer?.dataCountry}{' '}
                                    </p>
                                </span>
                            </div>
                            <div className="d-flex justify-content-space-between align-items-center ">
                                <span className="ml-2">
                                    <Link to={`/retailers/map/${id}`} style={{ fontSize: '1rem', marginBottom: '0px', color: '#FF740F' }}>
                                        View location on map
                                    </Link>
                                </span>
                                {retailer?.shopsdata?.length > 1 && (
                                    <span
                                        style={{
                                            padding: '10px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '40px',
                                            fontSize: '0.9rem',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        <Button
                                            style={{
                                                backgroundColor: '#FFF1E7',
                                                color: '#FF740F',
                                                padding: '10px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderRadius: '1px'
                                            }}
                                        >
                                            <ArrowLeftOutlined />
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: '#FFF1E7',
                                                color: '#FF740F',
                                                padding: '10px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderRadius: '1px'
                                            }}
                                        >
                                            <ArrowRightOutlined />
                                        </Button>
                                    </span>
                                )}
                            </div>
                        </Card>
                        <Card className="salesCard" style={{ borderRadius: '20px', backgroundColor: '#FFF2D7', textAlign: 'center' }}>
                            <p style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '0px', color: '#3B5460' }}>Current status</p>
                            <p className="mt-2">
                                Retailer is currently on <Tag>{retailer?.status}</Tag> status
                            </p>
                            <Divider style={{ margin: '20px 0', border: '1px dash #e8e8e8' }} />
                            <div
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    padding: '5px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '0 10px',
                                    width: '100%',
                                    height: 'fit-content',
                                    borderRadius: '10px'
                                }}
                            >
                                <span
                                    style={{
                                        backgroundColor: '#F6F6F6',
                                        color: '#FFBE37',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '40px',
                                        height: '40px',
                                        fontSize: '0.8rem',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    <EmojiEventsIcon />
                                </span>
                                <span className="ml-2 mt-2">
                                    <p style={{ fontSize: '12px', textAlign: 'left', marginBottom: '0px', fontWeight: 'bold' }}>
                                        {retailer?.stageId?.name || 'No stage available'}
                                    </p>
                                    <p style={{ fontSize: '10px', textAlign: 'left' }}>
                                        {new Date(retailer?.stageId?.createdAt).toLocaleDateString() || 'No date available'}
                                    </p>
                                </span>
                            </div>
                        </Card>
                    </div>
                    <div className="d-flex justify-content-space-between mt-5 salesCardHolder">
                        <Card
                            className="salesCard"
                            style={{
                                borderRadius: '10px',
                                backgroundColor: `${
                                    retailer?.rating === 'High'
                                        ? '#CCEEDD'
                                        : retailer?.rating === 'Medium'
                                        ? '#DBE2FF'
                                        : retailer?.rating === 'Low'
                                        ? '#FED6DA'
                                        : '#ffeeee'
                                }`,
                                textAlign: 'center',
                                height: '200px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <div>
                                <p
                                    style={{
                                        fontSize: '24px',
                                        color: '#0A2938',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {retailer?.rating || 'No priority level available'}
                                </p>
                                <p>Current priority level</p>
                            </div>
                        </Card>
                        <Card className="salesCard salesCardNotes" style={{ borderRadius: '10px', backgroundColor: '#FFFFFF' }}>
                            <div className="d-flex justify-content-space-between align-items-center">
                                <p style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '0px', color: '#3B5460' }}>
                                    <NoteAltOutlinedIcon style={{ marginRight: '5px' }} />
                                    Notes
                                </p>
                                <Link
                                    to={`/salescrm/all-notes/${id}`}
                                    style={{
                                        fontSize: '0.9rem',
                                        color: '#FF740F',
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        outline: 'none',
                                        boxShadow: 'none',
                                        padding: '0',
                                        width: 'fit-content'
                                    }}
                                >
                                    All notes <CallMadeIcon style={{ marginLeft: '2px' }} />
                                </Link>
                            </div>
                            <div className="noteHolder">
                                <div
                                    style={{
                                        backgroundColor: '#F6F6F6',
                                        padding: '20px',
                                        borderRadius: '10px',
                                        margin: '10px 0',
                                        position: 'relative',
                                        marginBottom: '20px'
                                    }}
                                >
                                    <textarea
                                        placeholder="Type here"
                                        style={{
                                            width: '100%',
                                            height: 'fit-content',
                                            padding: '10px',
                                            borderRadius: '10px',
                                            backgroundColor: '#F6F6F6',
                                            color: '#3B5460',
                                            position: 'relative',
                                            marginBottom: '10px',
                                            border: 'none',
                                            resize: 'none',
                                            outline: 'none'
                                        }}
                                        value={note}
                                        onChange={(e) => setNote(e.target.value)}
                                    />
                                    <Popover
                                        placement="bottomLeft"
                                        content={
                                            <div style={{ padding: '20px' }}>
                                                {sourceOptions.map((option, index) => (
                                                    <div key={option._id}>
                                                        <Checkbox
                                                            onChange={(e) => setSource(e.target.checked ? option.name : '')}
                                                            checked={source === option.name}
                                                            style={{ marginRight: '10px' }}
                                                        >
                                                            {' '}
                                                            {option.name}{' '}
                                                        </Checkbox>
                                                        <Divider style={{ margin: '20px 0', border: '1px dash #e8e8e8' }} />
                                                    </div>
                                                ))}

                                                {source ? (
                                                    <Button
                                                        style={{
                                                            backgroundColor: '#FF740F',
                                                            color: '#fff',
                                                            padding: '20px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '100%'
                                                        }}
                                                        onClick={handleAddNote}
                                                    >
                                                        Save note
                                                    </Button>
                                                ) : null}
                                            </div>
                                        }
                                    >
                                        <Button
                                            style={{
                                                backgroundColor: '#fff',
                                                color: '#FF740F',
                                                border: '1px solid #FF740F',
                                                padding: '20px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                bottom: '0',
                                                right: '20px',
                                                position: 'absolute',
                                                width: '120px',
                                                marginBottom: '10px'
                                            }}
                                        >
                                            Add source <DownOutlined className="ml-2" />
                                        </Button>
                                    </Popover>
                                </div>

                                {notes.map((note, index) => (
                                    <div
                                        key={note._id}
                                        className="mb-4"
                                        style={{
                                            backgroundColor: '#F6F6F6',
                                            padding: '20px',
                                            borderRadius: '10px',
                                            margin: '10px 0',
                                            position: 'relative'
                                        }}
                                    >
                                        <p
                                            style={{
                                                fontSize: '1rem',
                                                marginBottom: '10px',
                                                color: '#3B5460',
                                                position: 'relative'
                                            }}
                                        >
                                            {note.note}
                                        </p>
                                        <div
                                            style={{
                                                color: '#85949C',
                                                fontSize: '10px',
                                                bottom: '10px',
                                                left: '20px',
                                                right: '20px',
                                                position: 'absolute',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginTop: '20px'
                                            }}
                                        >
                                            <span>Source: {note.source}</span>
                                            <div>
                                                <span style={{ marginLeft: '4px', marginRight: '4px' }}>
                                                    Added by: {note.createdBy?.firstName} {note.createdBy?.lastName}
                                                </span>
                                                <span>{new Date(note.createdAt).toLocaleDateString()}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Card>
                    </div>
                </div>
            )}
            <Modal title="Lead’s information" open={leadProfileModal} onCancel={() => setLeadProfileModal(false)} footer={null}>
                <div className="p-4">
                    <div className="d-flex align-items-center mb-2">
                        <span
                            style={{
                                backgroundColor: '#F6F6F6',
                                padding: '10px',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '50px',
                                height: '50px',
                                color: '#8C9DAB',
                                fontSize: '1.1rem',
                                fontWeight: 'bold'
                            }}
                        >
                            <UserOutlined />
                        </span>
                        <span className="ml-2">
                            <p style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '0px', color: '#3B5460' }}>
                                {' '}
                                {retailer?.partnerName}{' '}
                            </p>
                            <small style={{ color: '#3B5460', fontSize: '14px' }}>Retailer’s name</small>
                        </span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                        <span
                            style={{
                                backgroundColor: '#F6F6F6',
                                padding: '10px',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '40px',
                                height: '40px',
                                color: '#8C9DAB',
                                fontSize: '0.9rem',
                                fontWeight: 'bold'
                            }}
                        >
                            <CallIcon />
                        </span>
                        <span className="ml-2">
                            <p style={{ fontSize: '1rem', marginBottom: '0px', color: '#3B5460' }}> {retailer?.partnerContact} </p>
                        </span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                        <span
                            style={{
                                backgroundColor: '#F6F6F6',
                                padding: '10px',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '40px',
                                height: '40px',
                                color: '#8C9DAB',
                                fontSize: '0.9rem',
                                fontWeight: 'bold'
                            }}
                        >
                            <UserOutlined />
                        </span>
                        <span className="ml-2">
                            <p style={{ fontSize: '1rem', marginBottom: '0px', color: '#3B5460' }}> {retailer?.gender} </p>
                        </span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                        <span
                            style={{
                                backgroundColor: '#F6F6F6',
                                padding: '10px',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '40px',
                                height: '40px',
                                color: '#8C9DAB',
                                fontSize: '0.9rem',
                                fontWeight: 'bold'
                            }}
                        >
                            <UserOutlined />
                        </span>
                        <span className="ml-2">
                            <p style={{ fontSize: '1rem', marginBottom: '0px', color: '#3B5460' }}>
                                {' '}
                                Generated by:{' '}
                                {retailer?.createdBy?.firstName
                                    ? `${retailer?.createdBy?.firstName} ${retailer?.createdBy?.lastName}`
                                    : 'N/A'}{' '}
                            </p>
                        </span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                        <span
                            style={{
                                backgroundColor: '#F6F6F6',
                                padding: '10px',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '40px',
                                height: '40px',
                                color: '#8C9DAB',
                                fontSize: '0.9rem',
                                fontWeight: 'bold'
                            }}
                        >
                            <CalendarOutlined />
                        </span>
                        <span className="ml-2">
                            <p style={{ fontSize: '1rem', marginBottom: '0px', color: '#3B5460' }}>
                                {' '}
                                Date of lead generation: {new Date(retailer?.createdAt).toLocaleDateString()}{' '}
                            </p>
                        </span>
                    </div>
                </div>
            </Modal>
            <Modal
                title="Summary"
                submitText="This is a summary of all the notes from “Retailer’s name”"
                open={summaryModal}
                onCancel={() => setSummaryModal(false)}
                footer={null}
            >
                <div className="p-4">
                    <p>Summary will go here, along with any list of follow up actions, etc.</p>
                </div>
            </Modal>
        </div>
    );
};

export default Index;
