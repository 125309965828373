import { LoadingOutlined } from '@ant-design/icons';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CallMadeIcon from '@mui/icons-material/CallMade';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import PhoneIcon from '@mui/icons-material/Phone';
import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
import WestIcon from '@mui/icons-material/West';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Input, Select, notification, Button, Empty } from 'antd';
import ApiUrl from 'network';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import badge from '../../assets/images/badge.png';
import { decrypt, encrypt } from '../../utils/encrypt';
import './retailer.css';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { dateConverter } from 'utils/functions';
import { toast } from 'react-toastify';
import { EmptyState } from '../../components/EmptyState/EmptyState';

const RmaCcTasks = () => {
    const [loadingCompletedTask, setLoadingCompletedTask] = useState(false);
    const [loadingInProgressTasks, setLoadingInProgressTasks] = useState(false);
    const [inProgressTasks, setInProgressAllTasks] = useState([]);
    const [completedTasks, setCompletedTasks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingTask, setLoadingTask] = useState(false);
    const [containerWidth, setContainerWidth] = useState('false');
    const [profile, setProfile] = useState([]);
    const [profileId, setProfileId] = useState('');
    const [orders, setOrders] = useState([]);
    const [mainRetailer, setMainRetailer] = useState([]);
    const [allTasks, setAllTasks] = useState([]);
    const [taskSuccess, setTaskSuccess] = useState(false);
    const [analytics, setAnalytics] = useState([]);
    const [previousLoyalty, setPreviousLoyalty] = useState([]);
    const [currentsLoyalty, setCurrentsLoyalty] = useState([]);
    const [isUpdatingTask, setIsUpdatingTask] = useState(false);
    const { TextArea } = Input;
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const navigate = useNavigate();
    const country = localStorage.getItem('country');
    const [api, contextHolder] = notification.useNotification();
    const params = useParams();
    const [toggle, setToggle] = useState(1);
    const userId = localStorage.getItem('userId');

    const toggleFilter = (index) => {
        setToggle(index);
    };

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54
            }}
            spin
        />
    );

    const openNotification = (placement) => {
        api.open({
            description: 'Task is complete.',
            placement,
            style: {
                width: 267,
                height: 70,
                backgroundColor: '#0A2938',
                borderRadius: '12px',
                textAlign: 'center',
                color: '#fff',
                fontWeight: 500,
                fontSize: '18px'
            },
            closeIcon: false
        });
    };

    useEffect(() => {
        const handleResize = () => {
            // Adjust the column width based on the window width
            const newWidth = window.innerWidth < 1200 ? 'true' : 'false';
            setContainerWidth(newWidth);
        };

        // Initial setup
        handleResize();

        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // get query data
    const getQueryData = async () => {
        try {
            // setLoading(true);
            const url = `${ApiUrl.BaseUrl}/mainorders/querydata?country=${country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setMainRetailer(answer.customer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const retailerOptions = mainRetailer?.map((item) => {
        return { label: item?.partnerName, value: item?._id };
    });

    // get retailer profile
    const getRetailerProfile = async (data) => {
        try {
            setLoading(true);
            const url = `${ApiUrl.BaseUrl}/retailerprofiles/retailerprofile?retailerId=${data}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const info = decrypt(data.encrypted, keyResult, ivResult);
                        const infoResult = JSON.parse(info);
                        const order = decrypt(data.encrypteds, keyResult, ivResult);
                        const orderResult = JSON.parse(order);
                        setProfile(infoResult);
                        setOrders(orderResult);
                        const analytics = decrypt(data.analyticsData, keyResult, ivResult);
                        const analyticResult = JSON.parse(analytics);
                        const currentsLoyalty = decrypt(data.currentsLoyalty, keyResult, ivResult);
                        const currentsLoyaltyResult = JSON.parse(currentsLoyalty);
                        const previousLoyalty = decrypt(data.previousLoyalty, keyResult, ivResult);
                        const previousLoyaltyResult = JSON.parse(previousLoyalty);
                        setCurrentsLoyalty(currentsLoyaltyResult);
                        setPreviousLoyalty(previousLoyaltyResult);
                        setAnalytics(analyticResult);
                        // console.error(previousLoyaltyResult);
                        // console.error(currentsLoyaltyResult);
                        // console.error(analyticResult);
                        // console.error(infoResult);
                        // console.error(orderResult);
                        // console.error(infoResult);
                        // console.error(orderResult);
                        setProfileId(infoResult._id);
                    } else {
                        // const error = decrypt(data.data, keyResult, ivResult);
                        // const answer = JSON.parse(error);
                        // console.error(data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getQueryData();
    }, []);

    useEffect(() => {
        const storedRetailerId = localStorage.getItem('selectedRetailerId');
        if (storedRetailerId) {
            getRetailerProfile(storedRetailerId); // Fetch retailer profile using the ID from localStorage
        } else {
            // If no value is found in localStorage, set default value
            const defaultRetailerId = params?.id; // Use the first option as default
            getRetailerProfile(defaultRetailerId); // Fetch retailer profile using the default ID
        }
    }, [params?.id]);

    // Function to handle profile selection
    const handleProfileSelection = (selectedRetailerId) => {
        // Set the selected profile ID to localStorage
        localStorage.setItem('selectedRetailerId', selectedRetailerId);
        // Fetch and set retailer profile
        getRetailerProfile(selectedRetailerId);
    };

    // filter retailer
    const filterRetailer = (value) => {
        handleProfileSelection(value); // Use handleProfileSelection instead of getRetailerProfile directly
    };

    const getAllTodoTask = async () => {
        try {
            setLoadingTask(true);
            const url = `${ApiUrl.BaseUrl}/task/alltodotask?country=${country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        setAllTasks(answer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoadingTask(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const getAllInProgressTask = async () => {
        try {
            setLoadingInProgressTasks(true);
            const url = `${ApiUrl.BaseUrl}/task/allinprogresstask?country=${country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);

                        const filteredItems = answer.filter((item) => item.assignedUsers.some((user) => user.userId === userId));
                        setInProgressAllTasks(filteredItems);

                        console.log(answer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoadingInProgressTasks(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const getAllCompletedTask = async () => {
        try {
            setLoadingCompletedTask(true);
            const url = `${ApiUrl.BaseUrl}/task/allcompletedtask?country=${country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        const filteredItems = answer.filter((item) => item.assignedUsers.some((user) => user.userId === userId));
                        setCompletedTasks(filteredItems);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoadingCompletedTask(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getAllTodoTask();
        getAllCompletedTask();
        getAllInProgressTask();
    }, [taskSuccess]);

    const completeTask = async (id, title, description, dueDate, assignedUsers) => {
        const userInfo = localStorage.getItem('userToken');
        setIsUpdatingTask(true);

        const data = {
            id,
            title,
            description,
            dueDate,
            status: 'Completed',
            assignedUsers: assignedUsers?.map((rma) => ({
                userId: rma?.userId ? rma.userId : rma._id,
                name: rma.name,
                status: rma?.status
            }))
        };

        const obj = JSON.stringify(data);
        const result = encrypt(obj, keyResult, ivResult);

        await fetch(`${ApiUrl.BaseUrl}/task/updatetask`, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ payload: result })
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    openNotification('bottom');
                    setTaskSuccess(!taskSuccess);
                } else if (result.status === false) {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                } else {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                }
            })
            .finally(() => {
                setIsUpdatingTask(false);
            });
    };

    const TaskCard = ({ description, title, name, dueDate, assignedUsers, _id, status }) => {
        return (
            <Box
                sx={
                    {
                        // flexGrow: '1',
                        // width: containerWidth === 'true' ? '100%' : '40%',
                        // margin: containerWidth === 'true' ? '0 0 0 0' : '0 30px 0 0'
                    }
                }
            >
                <Box
                    style={{
                        backgroundColor: '#fff',
                        borderRadius: '16px',
                        margin: '5px',
                        display: 'flex',
                        // alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        // height: '140px',
                        width: '100%',
                        padding: '15px',
                        overflow: 'hidden'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            margin: '8px 0 '
                        }}
                    >
                        <Box
                            sx={{
                                textAlign: 'start',
                                color: '#233E4C',
                                fontSize: '18px'
                            }}
                        >
                            {title}
                        </Box>

                        <CallMadeIcon
                            sx={{
                                fontSize: '18px',
                                cursor: 'pointer',
                                color: '#FF740F'
                            }}
                        />
                    </Box>

                    <Box
                        sx={{
                            textAlign: 'start',
                            color: '#85949C',
                            fontSize: '14px',
                            height: '70px',
                            overflowY: 'scroll',
                            overflowX: 'hidden'
                        }}
                        className="order-history-modals-y"
                    >
                        {description}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            flexWrap: 'wrap'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            {status === 'Completed' ? (
                                assignedUsers.length > 0 ? (
                                    <Typography
                                        sx={{
                                            color: '#85949C',
                                            fontSize: '14px'
                                        }}
                                    >
                                        Assigned to:{' '}
                                        {assignedUsers.slice(0, 2).map((user, index) => (
                                            <React.Fragment key={index}>
                                                {index > 0 ? ', ' : ''} {user.name}
                                                {index === 1 && assignedUsers.length > 2 ? `, and ${assignedUsers.length - 2} more` : ''}
                                            </React.Fragment>
                                        ))}
                                    </Typography>
                                ) : null
                            ) : (
                                <Button
                                    disabled={isUpdatingTask}
                                    style={{
                                        borderRadius: '12px',
                                        border: '1px solid #FFE3CF',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#FF740F',
                                        width: '159px',
                                        margin: '0 5px 0 5px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '32px'
                                    }}
                                    onClick={() => {
                                        completeTask(_id, title, description, dueDate, assignedUsers);
                                    }}
                                >
                                    Mark as Completed
                                </Button>
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#85949C',
                                    fontSize: '12px',
                                    margin: ' 0 10px 0 0'
                                }}
                            >
                                Due date:
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#85949C',
                                    fontSize: '12px'
                                }}
                            >
                                {dateConverter(dueDate)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    };

    return (
        <div>
            {contextHolder}
            {isUpdatingTask ? (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '4000'
                    }}
                >
                    {antIcon}
                </Box>
            ) : null}

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '1px dashed #E7EAEB',
                    alignItems: 'flex-end',
                    margin: '0px auto 15px',
                    flexWrap: 'wrap'
                }}
            >
                {' '}
                <List
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '0 0 16px 0',
                        alignItems: 'flex-start'
                    }}
                >
                    <WestIcon
                        sx={{
                            fontSize: '32px',
                            cursor: 'pointer',
                            margin: '0 10px 0 0',
                            '&:hover': {
                                color: '#546974'
                            },
                            '&:focus': {
                                color: '#9DA9AF'
                            }
                        }}
                        onClick={() => navigate(-1)}
                    />{' '}
                    <ListItem sx={{ fontWeight: 500, fontSize: '1.875rem', color: '#0A2938', padding: 0 }}>Tasks</ListItem>
                </List>
                <Box
                    sx={{
                        // margin: '20px 0',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        padding: '0 0 16px 0'
                    }}
                >
                    <Box
                        sx={{
                            // margin: '20px 0',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            padding: '0 0 16px 0'
                        }}
                    >
                        <Button
                            size="large"
                            style={{
                                backgroundColor: toggle === 1 ? '#FFFFFF' : 'transparent',
                                borderRadius: '12px',
                                fontSize: '16px',
                                fontWeight: '400',
                                color: '#FF740F',
                                width: '122px',
                                margin: '24px 5px 0 5px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '38px',
                                flexGrow: '1 '
                            }}
                            onClick={() => toggleFilter(1)}
                        >
                            In progress
                        </Button>

                        <Button
                            size="large"
                            style={{
                                backgroundColor: toggle === 2 ? '#FFFFFF' : 'transparent',
                                borderRadius: '12px',
                                fontSize: '16px',
                                fontWeight: '400',
                                color: '#FF740F',
                                width: '122px',
                                margin: '24px 5px 0 5px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '38px',
                                flexGrow: '1 '
                                // flexBasis: '200px'
                            }}
                            onClick={() => toggleFilter(2)}
                        >
                            Complete
                        </Button>
                    </Box>
                    {/* <Box
                        sx={{
                            width: ' 277px',
                            margin: '24px 5px 0 5px'
                            // flexBasis: '200px'
                        }}
                    >
                        <Select
                            style={{
                                width: '100%',
                                flexFlow: '1'
                            }}
                            size="large"
                            placeholder="Search retailer"
                            options={retailerOptions}
                            onChange={(value) => filterRetailer(value)}
                            value={profileId || 'Search retailer'}
                            filterOption={(inputValue, option) => {
                                if (typeof option.label === 'string') {
                                    return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }

                                return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                            }}
                            showSearch
                        />
                    </Box> */}
                </Box>
            </Box>

            <>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    {loadingInProgressTasks ? (
                        <Box
                            sx={{
                                display: 'grid',
                                placeItems: 'center',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%,-50%)',
                                zIndex: '4000'
                            }}
                            className={toggle === 1 ? 'active-task ' : 'disable-task'}
                        >
                            {antIcon}
                        </Box>
                    ) : inProgressTasks.length > 0 ? (
                        <Box
                            sx={{
                                margin: '24px 0px 0 0px',
                                // display: 'flex',
                                alignItems: 'center',
                                // flexWrap: 'wrap',
                                display: 'grid',
                                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                gap: '10px'
                            }}
                            className={toggle === 1 ? 'active-task ' : 'disable-task'}
                        >
                            {inProgressTasks.map((task) => (
                                <TaskCard key={task?._id} {...task} />
                            ))}
                        </Box>
                    ) : (
                        <Box
                            style={{
                                // margin: '10px auto 0',
                                position: 'relative',
                                backgroundColor: '#FFFFFF',
                                height: '496px',
                                borderRadius: '16px'
                            }}
                            className={toggle === 1 ? 'active-task ' : 'disable-task'}
                        >
                            <EmptyState height="204px" width="70%" text="No results yet." />
                        </Box>
                    )}

                    {loadingCompletedTask ? (
                        <Box
                            sx={{
                                display: 'grid',
                                placeItems: 'center',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%,-50%)',
                                zIndex: '4000'
                            }}
                            className={toggle === 2 ? 'active-task ' : 'disable-task'}
                        >
                            {antIcon}
                        </Box>
                    ) : completedTasks.length > 0 ? (
                        <Box
                            sx={{
                                margin: '24px 0px 0 0px',
                                // display: 'flex',
                                alignItems: 'center',
                                // flexWrap: 'wrap',
                                display: 'grid',
                                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                gap: '10px'
                            }}
                            className={toggle === 2 ? 'active-task ' : 'disable-task'}
                        >
                            {completedTasks.map((task) => (
                                <TaskCard key={task?._id} {...task} />
                            ))}
                        </Box>
                    ) : (
                        <Box
                            style={{
                                // margin: '10px auto 0',
                                position: 'relative',
                                backgroundColor: '#FFFFFF',
                                height: '496px',
                                borderRadius: '16px'
                            }}
                            className={toggle === 2 ? 'active-task ' : 'disable-task'}
                        >
                            <EmptyState height="204px" width="70%" text="No results yet." />
                        </Box>
                    )}
                </Box>
            </>
        </div>
    );
};

export default RmaCcTasks;
