import React from 'react';
import Noresultsfoundimage from 'assets/images/Noresultsfound.svg';
import { Box, Typography } from '@mui/material';

export const EmptyState = ({ height, width, padding, text }) => {
    return (
        <div className="d-flex justify-content-center align-items-center">
            <Box
                sx={{
                    height,
                    width,
                    backgroundColor: '#FDF7FD',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding,
                    borderRadius: '12px'
                }}
            >
                <img src={Noresultsfoundimage} alt="not-found" />
                <Typography
                    sx={{
                        fontSize: '16px',
                        margin: '12px 0 0 0',
                        textAlign: 'center',
                        width: '286px',
                        color: '#3B5460',
                        lineHeight: '24px',
                        fontWeight: '400px',
                        padding: '12px'
                    }}
                >
                    {text}
                </Typography>
            </Box>
        </div>
    );
};

export default EmptyState;
