import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const Card = ({ topText, bottomText, value, percent, status }) => (
    <>
        <div className="card">
            <div className="font-15 title bold-text">{topText ?? ''}</div>
            <br />
            <div className="flex-row justify-content-space-between">
                <div className="bold-text font-20">{value ?? ''}</div>
                <div className={status ? 'percent-green font-18 bold-text' : 'percent-red font-18 bold-text'}>
                    {percent ?? ''} {status ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                </div>
            </div>
            <br />
            <div className="font-15 title bold-text">Last week: {bottomText ?? ''}</div>
        </div>
    </>
);

export default Card;
