import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Button, Table, Input, Skeleton, DatePicker, Select, Modal, Collapse, Tag, Popover } from 'antd';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import './salesplan.css';
import { decrypt, encrypt } from '../../utils/encrypt';
import { isArray, debounce } from 'lodash';
import { toast } from 'react-toastify';
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { da } from 'date-fns/locale';
import ApiUrl from '../../network';
import dayjs from 'dayjs';

export const Index = () => {
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const userRole = localStorage.getItem('userRole');
    const [pods, setPods] = useState([]);
    const [salesPlan, setSalesPlan] = useState([]);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState([null, null]);
    const [podID, setPodID] = useState('');
    const [podPick, setPodPick] = useState([]);
    const [podSelectedID, setPodSelectedId] = useState('');
    const [rmas, setRmas] = useState([]);
    const [salesPersons, setSalesPersons] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [record, setRecord] = useState({});
    const [deleteModal, setDeleteModal] = useState(false);
    const [actionType, setActionType] = useState('');
    const [location, setLocation] = useState('');
    const [rma, setRma] = useState('');
    const [status, setStatus] = useState('');
    const [dateSelected, setDateSelected] = useState('');
    const [routeID, setRouteID] = useState('');
    const [driveLocationList, setDriveLocationList] = useState([]);
    const { RangePicker } = DatePicker;
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    const fetchPodsData = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/salesdrivepod/salesdrivepod`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setPods(result);
                        const allSalespersons = [];
                        result.forEach((pod) => {
                            allSalespersons.push(pod);
                            if (podSelectedID === pod._id) {
                                localStorage.setItem('selectedPod', JSON.stringify(pod));
                            }
                        });

                        setSalesPersons(allSalespersons);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };
    const fetchSalesPlanData = async (data) => {
        try {
            const podId = data?.pod ? data.pod : '';
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const urlParams = new URLSearchParams(window.location.search);
            let url = '';

            if (userRole === 'Sales') {
                url = `${ApiUrl.BaseUrl}/salesplanning/podsalesplanner`;
            } else {
                url = `${ApiUrl.BaseUrl}/salesplanning/salesplanninglist`;
            }

            if (data) {
                if (data.startDate) urlParams.append('startDate', data.startDate);
                if (data.endDate) urlParams.append('endDate', data.endDate);
                if (data.pod) urlParams.append('pod', data.pod);
                if (data.rmas) urlParams.append('rmas', data.rmas);
                if (data.location) urlParams.append('location', data.location);
                if (data.status) urlParams.append('driveStatus', data.status);
            }
            url = `${url}?${urlParams.toString()}`;

            await fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setLoading(false);
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setSalesPlan(result);

                        // Find the correct pod based on data.pod
                        salesPersons.map((pod) => {
                            return pod._id === podId ? setRmas(pod.salesperson) : null;
                        });
                    } else {
                        setLoading(false);
                        const error = decrypt(data.data, keyResult, ivResult);
                        toast.error(error.message);
                    }
                });
        } catch (e) {
            setLoading(false);
            toast.error(e.message);
        }
    };

    const getDriveLocationHandler = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/salesplanning/drivelocationslist`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setLoading(false);
                    const driveLocationList = decrypt(data.data, keyResult, ivResult);
                    setDriveLocationList(JSON.parse(driveLocationList));
                } else {
                    setLoading(false);
                    toast.error(data.message);
                }
            });
    };

    const salesTableData = salesPlan?.map((item) => {
        return {
            key: item?._id,
            week: item?.week,
            revenuetarget: item?.revenuetarget,
            driveDate: new Date(item?.driveDate).toLocaleDateString('en-US', options),
            driveType: item?.driveType?.name,
            pod: item?.pod?.name,
            country: item?.country,
            location: item?.locName,
            rmas: item?.rmas,
            rmaId: item?.rmaId,
            target: item?.target,
            createdBy: `${item?.createdBy?.firstName} - ${item?.createdBy?.lastName}`,
            status: item?.driveStatus,
            olddriveDate: item?.olddriveDate,
            reason: item?.reason
        };
    });
    const podsOptions = pods?.map((pod) => {
        return {
            label: pod.name,
            value: pod._id
        };
    });
    const rmasOptions = rmas?.map((rma) => {
        return {
            label: `${rma.firstName} - ${rma.lastName}`,
            value: rma.userId
        };
    });
    const locationOptions = driveLocationList?.map((location) => {
        return {
            label: location.location,
            value: location.location
        };
    });

    useEffect(() => {
        fetchPodsData().then((r) => r);
        getDriveLocationHandler().then((r) => r);
    }, []);

    useEffect(() => {
        const loadSavedFilters = () => {
            const savedFilters = localStorage.getItem('salesPlanFilters');
            const selectedPod = localStorage.getItem('selectedPod');

            if (!savedFilters) return;

            try {
                const parsedFilters = JSON.parse(savedFilters);
                const parsedPod = JSON.parse(selectedPod);
                const salespeople = parsedPod?.salesperson;

                setRmas(salespeople);
                setPodID(parsedFilters?.pod);

                // Find the RMA that matches the saved filter
                const matchedRma = salespeople?.find((rma) => rma.userId === parsedFilters.rmas);

                // Apply the saved filters to state
                if (parsedFilters.startDate && parsedFilters.endDate) {
                    setDate([dayjs(parsedFilters.startDate), dayjs(parsedFilters.endDate)]);
                }
                if (parsedFilters.pod) setPodSelectedId(parsedFilters?.pod);
                if (parsedFilters.rmas) setRma(matchedRma?.userId);
                if (parsedFilters.location) setLocation(parsedFilters?.location);
                if (parsedFilters.status) setStatus(parsedFilters?.status);

                // Fetch data with the saved filters
                fetchSalesPlanData(parsedFilters).then((r) => r);
            } catch (error) {
                //  console.error('Failed to load saved filters:', error);
            }
        };

        loadSavedFilters();

        // Fetch data without filters if no saved filters are found
        if (!localStorage.getItem('salesPlanFilters')) {
            fetchSalesPlanData().then((r) => r);
        }
    }, []);

    const dateHanlder = (date, dateString) => {
        setDate(date);
    };

    const filterPodData = async (podId) => {
        try {
            // Fetch the selected Pod's data
            const selectedPod = pods.find((pod) => pod._id === podId);

            if (selectedPod) {
                // Update the salesperson options based on the selected Pod
                setRmas(selectedPod.salesperson); // Set the new list of salespeople

                // Get the rma from salesPlanFilters in localStorage
                const savedFilters = JSON.parse(localStorage.getItem('salesPlanFilters')) || {};

                const currentRma = savedFilters?.rmas;

                // Check if the current rma is in the selected Pod's salesperson list
                const currentRmaInPod = selectedPod.salesperson.some((salesperson) => salesperson.userId === currentRma);

                // If rma is not found in the new salesperson list, reset rma to an empty string
                if (!currentRmaInPod) {
                    setRma(''); // Resetting the rma in the state
                    setLocation(''); // Resetting the location in the state
                    setStatus(''); // Resetting the status in the state
                    setDate([]); // Resetting the date in the state
                    savedFilters.rmas = ''; // Resetting the rma in the filters
                    savedFilters.location = ''; // Resetting the location in the filters
                    savedFilters.startDate = ''; // Resetting the start date in the filters
                    savedFilters.endDate = ''; // Resetting the end date in the filters
                    savedFilters.status = ''; // Resetting the status in the filters
                    localStorage.setItem('salesPlanFilters', JSON.stringify(savedFilters));
                }
            }

            // Update the selected Pod ID in local storage and state
            localStorage.setItem(
                'salesPlanFilters',
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem('salesPlanFilters')),
                    pod: podId
                })
            );
            setPodID(podId);

            // Fetch the sales plan data with the new Pod selection
            const data = {
                ...JSON.parse(localStorage.getItem('salesPlanFilters')),
                pod: podId
            };
            fetchSalesPlanData(data).then((r) => r);
        } catch (error) {
            toast.error('Failed to update Pod data');
        }
    };

    const editHandler = (record) => {
        window.location.href = `/salesplan/edit/${record.key}`;
    };
    const handleCancel = () => {
        // Clear the selected row keys and close the modal
        setSelectedRowKeys([]);
        setDeleteModal(false);
    };
    const deleteHandler = (record) => {
        const data = {
            id: record.key
        };
        setLoading(true);

        const userInfo = localStorage.getItem('userToken');
        const config = {
            method: 'DELETE',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        fetch(`${ApiUrl.BaseUrl}/salesplanning/removeSalesPlanning?id=${data.id}`, config)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    setLoading(false);
                    toast.success('Sales Plan Deleted Successfully');
                    handleCancel();
                    filterPodData(podSelectedID);
                } else {
                    setLoading(false);
                    const error = decrypt(data.data, keyResult, ivResult);
                    toast.error(error.message);
                }
            })
            .catch((error) => {
                toast.error(error.message);
                setLoading(false);
            });
    };

    const content = (reason) => {
        return (
            <div>
                <p style={{ padding: '10px' }}>{reason}</p>
            </div>
        );
    };

    const rescuduleHandler = (record) => {
        return (
            <div>
                <p style={{ padding: '10px', lineHeight: '21px' }}>
                    This drive has been rescheduled <br />
                    from <span style={{ color: 'red' }}>{new Date(record.olddriveDate).toLocaleDateString('en-US', options)} </span>
                    to <br /> <span style={{ color: 'green' }}>{new Date(record.driveDate).toLocaleDateString('en-US', options)}</span>
                </p>
            </div>
        );
    };

    const columns = [
        {
            title: 'Date',
            dataIndex: 'driveDate',
            key: 'driveDate'
        },
        {
            title: 'RMAs',
            dataIndex: 'rmaId',
            key: 'rmaId',
            render: (_, record) => (
                <div>
                    {record?.rmaId?.firstName} {record?.rmaId?.lastName}
                </div>
            )
        },
        {
            title: 'Pod',
            dataIndex: 'pod',
            key: 'pod'
        },
        {
            title: 'Gmv',
            dataIndex: 'target',
            key: 'target',
            render: (text) => {
                return <div>{text === 1 ? <p>Leads on list</p> : <p> {text} </p>}</div>;
            }
        },
        {
            title: 'Revenue',
            dataIndex: 'revenuetarget',
            key: 'revenuetarget',
            render: (text) => {
                return <div>{text === 1 ? <p>Leads on list</p> : <p> {text} </p>}</div>;
            }
        },
        {
            title: 'Drive Type',
            dataIndex: 'driveType',
            key: 'driveType'
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location'
        },
        {
            title: 'Week',
            dataIndex: 'week',
            key: 'week'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                const status = text.toLowerCase();
                let tagStyle = {};
                let tagText = '';
                switch (status) {
                    case 'active':
                        tagStyle = { color: '#007c3e', backgroundColor: '#bde7d1', border: 'none' };
                        tagText = 'Active';
                        break;
                    case 'cancel':
                        tagStyle = { color: '#FF6347', backgroundColor: '#ffe6e6', border: 'none' };
                        tagText = 'Cancel';
                        break;
                    case 'reschedule':
                        tagStyle = { color: '#FA3246', backgroundColor: '#f5d5d6', border: 'none' };
                        tagText = 'Rescheduled';
                        break;
                    case 'missed':
                        tagStyle = { color: '#FFF', backgroundColor: '#FF6347', border: 'none' };
                        tagText = 'Missed';
                        break;
                    default:
                        tagStyle = { color: '#000', backgroundColor: '#e0e0e0', border: 'none' };
                        tagText = 'Unknown';
                }

                return (
                    <div>
                        <Tag style={tagStyle}>
                            {tagText === 'Cancel' ? (
                                <Popover content={content(record.reason)} trigger="hover" className="d-flex align-items-center p-1">
                                    {tagText} <QuestionCircleOutlined className="ml-1" />
                                </Popover>
                            ) : tagText === 'Rescheduled' ? (
                                <Popover content={rescuduleHandler(record)} trigger="hover" className="d-flex align-items-center p-1">
                                    {tagText} <QuestionCircleOutlined className="ml-1" />
                                </Popover>
                            ) : tagText === 'Missed' ? (
                                <Popover content={content(record.reason)} trigger="hover" className="d-flex align-items-center p-1">
                                    {tagText} <QuestionCircleOutlined className="ml-1" />
                                </Popover>
                            ) : (
                                tagText
                            )}
                        </Tag>
                    </div>
                );
            }
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            key: 'createdBy'
        }
    ];

    if (userRole !== 'Sales') {
        columns.push({
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => (
                <div className="d-flex">
                    <Button
                        size="small"
                        onClick={() => {
                            editHandler(record);
                        }}
                        style={{ backgroundColor: '#FFF', color: '#FF740F', border: '1px solid #FF740F', width: 'fit-content' }}
                        className="mt-2 mb-2 d-flex align-items-center justify-content-center"
                        title="Edit schedule"
                    >
                        <EditOutlined />
                    </Button>
                    <Button
                        size="small"
                        onClick={() => {
                            setRecord(record);
                            setActionType('delete');
                            setDeleteModal(true);
                        }}
                        style={{ backgroundColor: '#FFF', color: '#FA3246', border: '1px solid #FA3246', width: 'fit-content' }}
                        className="mt-2 mb-2 d-flex align-items-center justify-content-center ml-2"
                        title="Delete schedule"
                    >
                        <DeleteOutlined />
                    </Button>
                </div>
            )
        });
    }

    const bulkDeleteHandler = (selectedRowKeys) => {
        setLoading(true);
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        const bulkdeleteData = selectedRowKeys.map((key) => ({ id: key }));

        const datas = {
            bulkdeleteData
        };

        const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);

        fetch(`${ApiUrl.BaseUrl}/salesplanning/bulkdeletesalesplanning`, {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify({ payload })
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    toast.success('Sales Plan Deleted Successfully');
                    handleCancel();
                    filterPodData(podSelectedID);
                    setLoading(false);
                } else {
                    const error = decrypt(data.data, keyResult, ivResult);
                    toast.error(error.message);
                    setLoading(false);
                }
            })
            .catch((error) => {
                toast.error(error.message);
                setLoading(false);
            });
    };

    const applyFilters = () => {
        const data = {
            startDate: date[0]?.format('YYYY-MM-DD'),
            endDate: date[1]?.format('YYYY-MM-DD'),
            pod: podSelectedID,
            rmas: rma,
            location,
            status
        };

        // save filters to local storage

        localStorage.setItem('salesPlanFilters', JSON.stringify(data));

        fetchSalesPlanData(data).then((r) => r);
    };

    const resetFilterHandler = () => {
        setDate([]);
        setRma('');
        setLocation('');
        setStatus('');
        const data = {
            pod: podSelectedID
        };
        localStorage.removeItem('salesPlanFilters');
        fetchSalesPlanData(data).then((r) => r);
    };

    const redirectBulkEditHandler = () => {
        localStorage.setItem('selectedRouteKeys', JSON.stringify(selectedRowKeys));
        window.location.href = `/salesplan/bulk-edit`;
    };

    return (
        <div>
            {userRole === 'Admin' || userRole === 'SalesLead' ? (
                <div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginBottom: '10px',
                            alignItems: 'center'
                        }}
                    >
                        {userRole === 'Admin' || userRole === 'SalesLead' ? (
                            <div>
                                {podID.length > 0 && (
                                    <Button
                                        onClick={() => (window.location.href = `/addsalesplanning/${podID}`)}
                                        className="mobile-hide w-fit"
                                        title="Apply Filters"
                                        style={{
                                            backgroundColor: '#0A2938',
                                            marginRight: '10px',
                                            color: '#fff'
                                        }}
                                    >
                                        Create schedule
                                    </Button>
                                )}
                            </div>
                        ) : null}
                        {selectedRowKeys.length > 0 && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Button
                                    style={{
                                        backgroundColor: 'transparent',
                                        color: '#FF740F',
                                        border: '1px solid #FF740F'
                                    }}
                                    onClick={() => redirectBulkEditHandler()}
                                    className="w-fit mr-2"
                                >
                                    Edit Selected
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor: '#FF740F',
                                        color: '#fff'
                                    }}
                                    onClick={() => {
                                        setActionType('bulkDelete');
                                        setDeleteModal(true);
                                    }}
                                    className="w-fit mr-2"
                                >
                                    Delete Selected
                                </Button>
                            </div>
                        )}
                    </div>
                    <div className="tags mobile-hide" style={{ width: '100%', overflowX: 'auto' }}>
                        {pods
                            ?.sort((a, b) => a.name.localeCompare(b.name))
                            .map((pod) => (
                                <Button
                                    loading={podSelectedID === pod._id && loading}
                                    key={pod._id}
                                    className="mt-2 mb-2 mr-2"
                                    title="Apply Filters"
                                    style={{
                                        backgroundColor: podSelectedID === pod._id ? '#FF740F' : '#E7EAEB',
                                        color: podSelectedID === pod._id ? '#fff' : '#0A2938',
                                        border: '1px solid #E0E0E0',
                                        borderRadius: '3.875rem',
                                        height: '40px',
                                        padding: '0',
                                        marginRight: '10px'
                                    }}
                                    onClick={() => {
                                        filterPodData(pod._id);
                                        setPodSelectedId(pod._id);
                                    }}
                                >
                                    {pod.name}
                                </Button>
                            ))}
                        <Button
                            loading={podSelectedID === '' && loading}
                            className="mt-2 mb-2 mr-2"
                            title="Apply Filters"
                            style={{
                                backgroundColor: '#ffead6',
                                border: 'none',
                                borderRadius: '3.875rem',
                                height: '40px',
                                padding: '0',
                                marginRight: '10px'
                            }}
                            onClick={() => {
                                localStorage.removeItem('salesPlanFilters');
                                fetchSalesPlanData().then((r) => r);
                                setPodID('');
                            }}
                        >
                            Reset
                        </Button>
                    </div>
                    <div className="mobileFileter">
                        <div>
                            <Select
                                placeholder="Filter By Pod"
                                options={podsOptions}
                                onChange={(e) => {
                                    filterPodData(e);
                                }}
                                style={{ width: '200px' }}
                            />
                        </div>
                        <Button
                            className="mt-2 mb-2 mr-2"
                            title="Apply Filters"
                            style={{
                                backgroundColor: '#E7EAEB',
                                border: '1px solid #E0E0E0',
                                borderRadius: '2px',
                                padding: '0',
                                marginLeft: '10px'
                            }}
                            onClick={fetchSalesPlanData}
                        >
                            <Typography sx={{ margin: '0 14px ', color: '#0A2938' }}>Reset</Typography>
                        </Button>
                    </div>
                </div>
            ) : null}
            <div>
                {salesPlan && salesPlan?.length > 0 ? (
                    <div>
                        {podSelectedID && (
                            <Collapse accordion>
                                <Collapse.Panel key={1} header="Hide filters" className="mb-4">
                                    <div className="batchFilters">
                                        <div className="mt-2">
                                            <RangePicker
                                                format="YYYY-MM-DD"
                                                size="medium"
                                                className="datePicker"
                                                value={date || null}
                                                onChange={dateHanlder}
                                                style={{ width: '300px' }}
                                            />
                                        </div>
                                        <div className="mt-2 ml-2">
                                            <Select
                                                placeholder="Filter By Status"
                                                size="medium"
                                                showSearch
                                                allowClear
                                                options={[
                                                    { label: 'Active', value: 'Active' },
                                                    { label: 'Cancel', value: 'Cancel' },
                                                    { label: 'Reschedule', value: 'Reschedule' },
                                                    { label: 'Missed', value: 'Missed' }
                                                ]}
                                                value={status || null}
                                                onChange={(value) => {
                                                    setStatus(value);
                                                }}
                                                style={{ width: '300px' }}
                                                filterOption={(inputValue, option) => {
                                                    if (typeof option.label === 'string') {
                                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                    }

                                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                }}
                                            />
                                        </div>
                                        <div className="mt-2 ml-2">
                                            <Select
                                                placeholder="Filter By location"
                                                size="medium"
                                                showSearch
                                                allowClear
                                                options={locationOptions}
                                                value={location || null}
                                                onChange={(value) => {
                                                    setLocation(value);
                                                }}
                                                style={{ width: '300px' }}
                                                filterOption={(inputValue, option) => {
                                                    if (typeof option.label === 'string') {
                                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                    }

                                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                }}
                                            />
                                        </div>
                                        {userRole === 'Admin' || userRole === 'SalesLead' ? (
                                            <div className="d-flex">
                                                <div className="mt-2">
                                                    <Select
                                                        placeholder="Filter By RMAs"
                                                        size="medium"
                                                        showSearch
                                                        allowClear
                                                        options={rmasOptions}
                                                        value={rma || null}
                                                        onChange={(value) => {
                                                            setRma(value);
                                                        }}
                                                        style={{ width: '300px' }}
                                                        filterOption={(inputValue, option) => {
                                                            if (typeof option.label === 'string') {
                                                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                            }

                                                            return (
                                                                String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ) : null}

                                        <div className="mt-2 d-flex ml-2">
                                            {date.length > 0 || status || rma || location ? (
                                                <Button
                                                    onClick={applyFilters}
                                                    className="mt-2 mb-2 mr-2 w-fit"
                                                    title="Apply Filters"
                                                    style={{
                                                        backgroundColor: 'transparent',
                                                        color: '#FF740F',
                                                        border: '1px solid #FF740F',
                                                        marginRight: '10px',
                                                        width: 'fit-content'
                                                    }}
                                                >
                                                    Apply Filters
                                                </Button>
                                            ) : null}
                                            {date.length > 0 || status || rma || location ? (
                                                <Button
                                                    onClick={() => resetFilterHandler()}
                                                    className="mt-2 mb-2 w-fit"
                                                    title="Apply Filters"
                                                    style={{
                                                        backgroundColor: 'transparent',
                                                        color: '#FF740F',
                                                        border: '1px solid #FF740F',
                                                        marginRight: '10px',
                                                        width: 'fit-content'
                                                    }}
                                                >
                                                    Reset Filters
                                                </Button>
                                            ) : null}
                                        </div>
                                    </div>
                                </Collapse.Panel>
                            </Collapse>
                        )}
                        <div className="salesPlanTable">
                            {loading ? (
                                <div>
                                    <Skeleton style={{ padding: '10px 0' }} active />
                                </div>
                            ) : (
                                <Table
                                    rowSelection={{
                                        type: 'checkbox',
                                        selectedRowKeys,
                                        onChange: (selectedRowKeys) => {
                                            setSelectedRowKeys(selectedRowKeys);
                                        }
                                    }}
                                    rowKey={(record) => record.key}
                                    columns={columns}
                                    scroll={{ x: '100%' }}
                                    dataSource={salesTableData}
                                />
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="createScheduleContainer">
                        {userRole === 'Admin' || userRole === 'SalesLead' ? (
                            <div className="createSchedule">
                                <SearchIcon sx={{ fontSize: '5rem', color: '#546974' }} />
                                <p style={{ fontSize: '18px', marginBottom: '15px', marginTop: '15px' }}>
                                    Hi, it seems nothing has been found
                                </p>
                                {podID.length > 0 && (
                                    <Button
                                        onClick={() => (window.location.href = `/addsalesplanning/${podID}`)}
                                        className="mt-2 mb-2 mr-2"
                                        title="Apply Filters"
                                        style={{
                                            backgroundColor: '#FF740F',
                                            borderRadius: '10px',
                                            height: '40px',
                                            padding: '0',
                                            marginRight: '10px',
                                            marginTop: '20px',
                                            width: '9rem'
                                        }}
                                    >
                                        <Typography sx={{ margin: '0 14px', color: '#fff' }}>Create a schedule</Typography>
                                    </Button>
                                )}
                            </div>
                        ) : (
                            <div className="createSchedule">
                                <SearchIcon sx={{ fontSize: '5rem', color: '#546974' }} />
                                <p style={{ fontSize: '18px', marginBottom: '15px', marginTop: '15px' }}>
                                    Hi, it seems nothing has been found
                                </p>
                                <Button
                                    onClick={() => {
                                        fetchSalesPlanData();
                                        setDate(null);
                                    }}
                                    className="mt-2 mb-2 mr-2"
                                    title="Apply Filters"
                                    style={{
                                        backgroundColor: '#d0d0d0',
                                        borderRadius: '10px',
                                        height: '40px',
                                        padding: '0',
                                        marginRight: '10px',
                                        marginTop: '20px',
                                        width: '9rem'
                                    }}
                                >
                                    <Typography sx={{ margin: '0 14px', color: '#5d5d5d' }}>Reset Filters</Typography>
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <Modal title="Delete Record" open={deleteModal} onCancel={() => setDeleteModal(false)} footer={null}>
                <div className="text-center p-2">
                    <p className="mb-4 mt-2">Are you sure you want to delete this record?</p>
                    <div className="d-flex mt-4">
                        <Button
                            className="flex-grow-1"
                            onClick={handleCancel}
                            style={{ backgroundColor: 'transparent', color: '#FF740F', border: '1px solid #FF740F' }}
                        >
                            No
                        </Button>
                        <Button
                            loading={loading}
                            className="flex-grow-1 ml-2"
                            onClick={() => {
                                // eslint-disable-next-line no-unused-expressions
                                actionType === 'bulkDelete' ? bulkDeleteHandler(selectedRowKeys) : deleteHandler(record);
                            }}
                            style={{ backgroundColor: '#FF740F', color: '#fff' }}
                        >
                            Yes
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default Index;
