import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import useScriptRef from 'hooks/useScriptRef';

const AddCarModel = () => {
    const [name, setName] = useState('');
    const [car, setCar] = useState('');
    const [carmake, setCarMake] = useState([]);
    const [newcarId, setNewCar] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const history = useNavigate();
    const scriptedRef = useScriptRef();

    const filtercar = (event) => {
        const newFilter = carmake.filter((value) => value.name.toLowerCase().includes(event.toLowerCase()));
        // console.log('channel', newFilter);
        newFilter.forEach((option) => {
            // console.log('option', option._id);
            // eslint-disable-next-line no-underscore-dangle
            setNewCar(option._id);
        });
    };

    const retrieveAllCar = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.AllCar, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCarMake(data.data);
                        setLoading2(false);
                    } else {
                        toast.error(data.data);
                        setLoading2(false);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        // retrieve all car;
        retrieveAllCar();
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const userInfo = localStorage.getItem('userToken');

        const data = {
            name,
            car,
            newcarId
        };

        fetch(ApiUrl.AddCarModel, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                Accept: 'application/form-data',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    if (scriptedRef.current) {
                        setTimeout(() => {
                            history('/carmodel');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                    console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <br />
                            <PageHeader title="Model" subTitle="Home | Insurer | Setup Management  | Model" />
                            <br />
                            <MainCard title="Add Model">
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        autoComplete="off"
                                        name="name"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="name"
                                        value={name}
                                        label="Name"
                                        onChange={(e) => setName(e.target.value)}
                                        autoFocus
                                    />
                                    <br />
                                    <br />
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        value={car}
                                        onChange={(event, newValue) => {
                                            setCar(newValue);
                                            filtercar(newValue);
                                        }}
                                        options={carmake.map((option) => option.name)}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => <TextField {...params} label="Car" />}
                                    />

                                    <br />
                                    <br />

                                    <Box display="flex" justifyContent="space-between">
                                        <Button component={Link} to="/carmodel" type="submit" variant="contained" color="error">
                                            Reset
                                        </Button>

                                        <Button type="submit" variant="contained" color="secondary" disabled={!name || !car || loading}>
                                            {loading ? 'Submitting ..' : 'Submit'}
                                        </Button>
                                    </Box>
                                </form>
                            </MainCard>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default AddCarModel;
