import DataContainer from 'components/DataExtPortal/DataContainer';
import { useAppContext } from '../../context/appContext';
import { ListItem } from '@mui/material';
import { List } from 'antd';
import { Box } from '@mui/system';

const Gender = () => {
    const {
        retailerGender,
        totalGenderFeMaleMonthly,
        totalGenderMaleMonthly,
        totalGenderPreferNotAnswerMonthly,
        totalGenderUnknownMonthly
    } = useAppContext();

    const [male, female, unknown, decline] = retailerGender;

    const genderChart = [
        {
            title: 'Total Male Retailers',
            dataIndex: 'males',
            key: 'males',
            gender: male?.value,
            percentage: male?.percentage?.toFixed(1),
            progressColor: '#4B6EFF',
            trailColor: 'rgba(237, 241, 255, 1)',
            color: '#4B6EFF',
            month: totalGenderMaleMonthly
        },
        {
            title: 'Total Female Retailers',
            dataIndex: 'females',
            key: 'females',
            gender: female?.value,
            percentage: female?.percentage?.toFixed(1),
            progressColor: '#FFBE37',
            trailColor: 'rgba(255, 249, 235, 1)',
            color: '#FFBE37',
            month: totalGenderFeMaleMonthly
        },
        {
            title: 'Unkwown Retailers',
            dataIndex: 'unknown',
            key: 'unknown',
            gender: unknown?.value,
            percentage: unknown?.percentage?.toFixed(1),
            progressColor: '#FB5B6B',
            trailColor: 'rgba(255, 235, 237, 1)',
            color: '#FB5B6B',
            month: totalGenderUnknownMonthly
        },
        {
            title: '  Did Not Answer ',
            dataIndex: 'decline',
            key: 'decline',
            gender: decline?.value,
            percentage: decline?.percentage?.toFixed(1),
            progressColor: '#1AA3FE',
            trailColor: 'rgba(236, 237, 239, 1)',
            color: '#1AA3FE',
            month: totalGenderPreferNotAnswerMonthly
        }
    ];
    return (
        <div>
            <>
                <List
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '0 0 16px 0',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        margin: '0px auto 15px'
                    }}
                >
                    {' '}
                    <ListItem
                        sx={{
                            fontWeight: 500,
                            fontSize: '1.875rem',
                            color: '#0A2938',
                            padding: '0px 0px 20px 0px ',
                            borderBottom: '1px dashed #E7EAEB'
                        }}
                    >
                        Retailer Gender
                    </ListItem>
                </List>
                <Box
                    sx={{
                        margin: '32px 0 8px'
                    }}
                >
                    <DataContainer stats={genderChart} />
                </Box>
            </>
        </div>
    );
};

export default Gender;
