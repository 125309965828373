import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import Modal from 'react-bootstrap/Modal';
import { Button as MuiButton } from '@mui/material';
import React from 'react';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import { useBatchContext } from 'context/order-batching/batchContext';

const ModalWrapper = styled.div`
    .modal-actions {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 30px;
    }

    .title {
        color: #ff740f;
        font-weight: 700;
        text-align: center;
        padding: 1rem 0;
    }

    .cancel-btn {
        background-color: #193855;
        margin-left: -3rem;
    }

    .save-btn {
        background-color: #ff740f;
        margin-right: -2rem;
    }

    .content {
        padding: 1rem 2rem;
        margin-bottom: 50px;
    }
`;

const CreateBatchModal = ({ handleClose, show, statuses, orders }) => {
    const [orderInfo, setOrderInfo] = React.useState([]);
    const [batchDate, setBatchDate] = React.useState(new Date());
    const [batchStatusId, setBatchStatusId] = React.useState('');
    const { createOrderBatch, isLoading, orderType } = useBatchContext();

    const batchObject = {
        batchDate,
        batchStatusId,
        orderInfo,
        orderType
    };

    const handleBatchCreate = () => {
        createOrderBatch(batchObject);
        setBatchStatusId('');
        setOrderInfo([]);
        handleClose();
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <ModalWrapper>
                    <Modal.Title>
                        <h5 className="title">Create new batch</h5>
                        <hr />
                    </Modal.Title>
                    <Modal.Body>
                        <div className="content">
                            {/* Pick date */}
                            <div className="m-2">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label="Date"
                                        inputFormat="MM/dd/yyyy"
                                        value={batchDate}
                                        onChange={(value) => setBatchDate(value)}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="m-2">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Orders</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={orderInfo}
                                        label="Orders"
                                        onChange={(e) => setOrderInfo(e.target.value)}
                                        multiple
                                    >
                                        {orders.map((item) => (
                                            <MenuItem value={item._id} key={item._id}>
                                                {item.fulOrderId}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="m-2">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select2-label">Batch Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select2-label"
                                        id="demo-simple-select2"
                                        value={batchStatusId}
                                        label="Orders"
                                        onChange={(e) => setBatchStatusId(e.target.value)}
                                    >
                                        {statuses.map((status) => (
                                            <MenuItem value={status._id} key={status._id}>
                                                {status.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </Modal.Body>
                    <div className="modal-actions">
                        <MuiButton variant="contained" onClick={handleClose} className="cancel-btn">
                            Cancel
                        </MuiButton>
                        <LoadingButton onClick={handleBatchCreate} loading={isLoading} variant="contained" className="save-btn">
                            Save
                        </LoadingButton>
                    </div>
                </ModalWrapper>
            </Modal>
        </>
    );
};
export default CreateBatchModal;
