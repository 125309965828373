import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, List, ListItem, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { DatePicker, Select, Space, Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import { GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import deliveredIcon from '../../assets/images/icons/Vectormap.svg';
import { LoadingOutlined } from '@ant-design/icons';
import ApiUrl from 'network';
import { decrypt } from '../../utils/encrypt';
import PopUpMapProfile from './PopUpMapProfile';

const country = localStorage.getItem('country');

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 54
        }}
        spin
    />
);

const MyComponent = withScriptjs(
    withGoogleMap((props) => (
        <GoogleMap
            defaultZoom={8}
            defaultCenter={props.center}
            options={{
                streetViewControl: false,
                mapTypeControl: false
            }}
            center={
                props.zoom && props.selectCountry === 'Ghana'
                    ? { lat: 6.2593, lng: -1.5 }
                    : props.zoom && props.selectCountry === 'Nigeria'
                    ? { lat: 6.65422, lng: 3.406448 }
                    : props.retailerZoom
                    ? props?.retailerPosition
                    : props.retailerZoom && props.selectCountry === 'Ghana'
                    ? props?.retailerPosition || { lat: 6.2593, lng: -1.5 }
                    : props.retailerZoom && props.selectCountry === 'Nigeria'
                    ? props?.retailerPosition || { lat: 6.65422, lng: 3.406448 }
                    : props.selectCountry === 'Ghana'
                    ? { lat: 6.2593, lng: -1.5 }
                    : { lat: 6.65422, lng: 3.406448 }
            }
            zoom={
                props.zoom && props.selectCountry === 'Ghana'
                    ? 8
                    : props.retailerZoom === true && props.selectCountry === 'Ghana'
                    ? 18
                    : props.zoom && props.selectCountry === 'Nigeria'
                    ? 10
                    : props.retailerZoom === true && props.selectCountry === 'Nigeria'
                    ? 19
                    : country === 'Ghana'
                    ? 8
                    : 10
            }
        >
            {props?.profiles?.map((profile) => (
                <Marker
                    key={profile?._id}
                    position={{ lat: +profile?.locationLat, lng: +profile?.locationLng }}
                    onClick={() => props.handleMarkerClick(profile?._id)}
                    icon={{
                        url: deliveredIcon
                    }}
                >
                    {props.currentPlaceId === profile?._id ? (
                        <InfoWindow
                            onCloseClick={() => {
                                props.setCurrentPlaceId(null);
                            }}
                        >
                            <PopUpMapProfile key={profile?._id} profile={profile} />
                        </InfoWindow>
                    ) : (
                        ''
                    )}
                </Marker>
            ))}
        </GoogleMap>
    ))
);

const Map = ({
    mapCenter,
    profiles,
    setCurrentPlaceId,
    currentPlaceId,
    handleMarkerClick,
    zoom,
    retailerPosition,
    selectCountry,
    retailerZoom
}) => {
    const key = 'AIzaSyBZqHjOL-7tPYGJfRKkROaOMxlHfWkou_0';

    return (
        <MyComponent
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            center={mapCenter}
            setCurrentPlaceId={setCurrentPlaceId}
            handleMarkerClick={handleMarkerClick}
            currentPlaceId={currentPlaceId}
            profiles={profiles}
            zoom={zoom}
            retailerPosition={retailerPosition}
            selectCountry={selectCountry}
            retailerZoom={retailerZoom}
        />
    );
};

const RetailerMap = () => {
    const [open, setOpen] = useState(true);
    const userRole = localStorage.getItem('userRole');
    const [loading, setLoading] = useState(false);
    const [profiles, setProfiles] = useState([]);
    const [profilesList, setProfilesList] = useState([]);
    const [currentPlaceId, setCurrentPlaceId] = useState(null);
    const [selectedDate, setSelectedDate] = useState('');
    const [countries, setCountries] = useState([]);
    const [retailerList, setRetailerList] = useState([]);
    const [retailerPosition, setRetailerPosition] = useState({});
    const [selectCountry, setSelectCountry] = useState(country);
    const [selectedRetailers, setSelectedRetailers] = useState([]);
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [accumulatedFilters, setAccumulatedFilters] = useState({});

    const { RangePicker } = DatePicker;
    const toggleOpenClose = () => {
        setOpen(!open);
    };

    const mapCenter = country === 'Ghana' ? { lat: 6.2593, lng: -1.5 } : { lat: 6.65422, lng: 3.406448 };
    const [zoom, setZoom] = useState(false);
    const [retailerZoom, setRetailerZoom] = useState(false);

    // get query data
    const getQueryData = async () => {
        try {
            // setLoading(true);
            const url = `${ApiUrl.BaseUrl}/mainorders/querydata?country=${country}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);

                        setCountries(answer.country);
                        setProfilesList(answer.customer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    // setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // get retailers
    const getRetailers = async () => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            let url = `${ApiUrl.BaseUrl}/retailerprofiles/retailermap`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            // Append accumulated filters to URL
            Object.entries(accumulatedFilters).forEach(([key, value]) => {
                if (value) {
                    urlParams.append(key, value);
                }
            });

            url = `${url}?${urlParams.toString()}`;

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);
                        const regex = /^-?\d+\.\d+$/;

                        // orders with validate coordinates
                        const validCoordinates = answer.filter((coordinate) => {
                            return regex.test(coordinate?.locationLng) && regex.test(coordinate?.locationLat);
                        });
                        setRetailerList(validCoordinates);
                        setProfiles(validCoordinates);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getQueryData();
    }, []);

    useEffect(() => {
        getRetailers();
    }, [accumulatedFilters]);

    const countryOptions = countries?.map((item) => {
        return { label: item?.name, value: item?.name };
    });

    const retailerOptions = profilesList?.map((item) => {
        return { label: item?.partnerName, value: item?._id };
    });

    // view multiple retailers on map
    const handleViewOnMap = (value) => {
        setProfiles(value);

        setRetailerPosition({ lat: +value[value.length - 1]?.locationLat, lng: +value[value.length - 1]?.locationLng });
    };

    // filter date
    const filterDate = (value, dateString) => {
        setSelectedDate(value);
        const data = {
            startDate: dateString[0],
            endDate: dateString[1]
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
        // setSelectedRetailers([]);
    };

    // filter country
    const filterCountry = (value) => {
        const data = {
            country: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
        if (data.country === 'Ghana') {
            setSelectCountry('Ghana');
            setRetailerPosition({ lat: 6.2593, lng: -1.5 });
            setZoom(true);
        } else if (data.country === 'Nigeria') {
            setSelectCountry('Nigeria');
            setRetailerPosition({ lat: 6.465422, lng: 3.406448 });
            setZoom(true);
        } else if (country === 'Nigeria') {
            setSelectCountry('Nigeria');
            setRetailerPosition({ lat: 6.465422, lng: 3.406448 });
            setZoom(true);
            setRetailerZoom(true);
        } else if (country === 'Ghana') {
            setSelectCountry('Ghana');
            setRetailerPosition({ lat: 6.2593, lng: -1.5 });
            setZoom(true);
            setRetailerZoom(true);
        }

        setSelectedRetailers([]);
    };

    // filter Conversion
    const filterConversion = (value) => {
        const data = {
            conversion: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
        // setSelectedRetailers([]);
    };

    const handleMarkerClick = (id) => {
        setCurrentPlaceId(id);
    };

    // filter retailer
    const filterRetailer = (value) => {
        const data = {
            partner: value
        };
        setAccumulatedFilters((prevFilters) => ({ ...prevFilters, ...data }));
        // setSelectedRetailers([]);
    };

    const handleMultipleRetailer = (value) => {
        // Find the retailer object
        const retailer = retailerList.find((ret) => ret?._id === value);
        setRetailerPosition({ lat: retailer.locationLat, lng: retailer.locationLng });

        setSelectedRetailers((prevSelectedRetailers) => {
            // Check if the retailer is already selected
            const index = prevSelectedRetailers.findIndex((selected) => selected?._id === value);

            if (index === -1) {
                // If not selected, add it to selectedRetailers
                setZoom(false);
                setRetailerZoom(true);
                return [...prevSelectedRetailers, retailer];
            }

            // If already selected, remove it from selectedRetailers
            return prevSelectedRetailers.filter((selected) => selected?._id !== value);
        });
    };

    useEffect(() => {
        handleViewOnMap(selectedRetailers);
        if (selectedRetailers.length === 0) {
            getRetailers();
            setRetailerZoom(false);
        }
    }, [selectedRetailers]);

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '1px dashed #E7EAEB',
                    alignItems: 'flex-end',
                    margin: '0px auto 15px',
                    flexWrap: 'wrap'
                }}
            >
                {' '}
                <List
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '0 0 16px 0',
                        alignItems: 'flex-start',
                        flexDirection: 'column'
                        // margin: '0px auto 15px'
                    }}
                >
                    {' '}
                    <ListItem sx={{ fontWeight: 500, fontSize: '1.875rem', color: '#0A2938', padding: 0 }}>
                        Retailer Map{' '}
                        <span role="img" aria-label="👋🏽">
                            👋🏽
                        </span>
                    </ListItem>
                    <ListItem sx={{ fontSize: '0.875rem', color: '#565C6B', padding: 0 }}>View locations of all our retailers</ListItem>
                </List>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    margin: '32px 0 0 0'
                }}
                onClick={toggleOpenClose}
            >
                <Typography
                    style={{
                        fontSize: '18px',
                        fontWeight: '500',
                        color: '#0A2938'
                    }}
                >
                    {open ? `Hide filters` : `Show filters`}
                </Typography>

                {open ? (
                    <KeyboardArrowUpIcon
                        style={{
                            margin: '0 0 0 2px',
                            color: '#0A2938'
                        }}
                    />
                ) : (
                    <ExpandMoreIcon
                        style={{
                            margin: '0 0 0 2px',
                            color: '#0A2938'
                        }}
                    />
                )}
            </Box>
            <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                sx={{
                    margin: '0 0 8px 0'
                }}
            >
                <>
                    <Space
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            width: '100%'
                        }}
                    >
                        {userRole === 'Admin' ? (
                            <Box
                                sx={{
                                    width: ' 320px',
                                    margin: '8px 0 0 0px'
                                }}
                            >
                                <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Select Country</Typography>

                                <Select
                                    style={{
                                        width: '100%'
                                    }}
                                    size="large"
                                    placeholder="Select Country"
                                    options={countryOptions}
                                    onChange={(value) => filterCountry(value)}
                                    // value={country}
                                    filterOption={(inputValue, option) => {
                                        if (typeof option.label === 'string') {
                                            return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }

                                        return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }}
                                    showSearch
                                    allowClear
                                    // loading={loading}
                                />
                            </Box>
                        ) : null}
                        <Box
                            sx={{
                                width: ' 320px',
                                margin: '8px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Retailer</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Select retailer"
                                onChange={(value) => filterRetailer(value)}
                                options={retailerOptions}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>
                        <Box
                            sx={{
                                width: ' 320px',
                                margin: '8px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Conversion</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Conversion"
                                options={[
                                    {
                                        value: 'High',
                                        label: 'High'
                                    },
                                    {
                                        value: 'Medium',
                                        label: 'Medium'
                                    },
                                    {
                                        value: 'Low',
                                        label: 'Low'
                                    }
                                ]}
                                onChange={(value) => filterConversion(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box>
                        {/* <Box
                            sx={{
                                width: ' 320px',
                                margin: '8px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Select Tier</Typography>

                            <Select
                                style={{
                                    width: '100%'
                                }}
                                size="large"
                                placeholder="Select Tier"
                                // options={timeOptions}
                                // onChange={(value) => filterTime(value)}
                                filterOption={(inputValue, option) => {
                                    if (typeof option.label === 'string') {
                                        return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                    }

                                    return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                }}
                                showSearch
                                allowClear
                            />
                        </Box> */}
                        <Box
                            sx={{
                                width: ' 320px',
                                margin: '8px 0 0 0px'
                            }}
                        >
                            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: '#546974' }}>Date</Typography>
                            <RangePicker
                                format="YYYY-MM-DD"
                                style={{
                                    width: '100%',
                                    border: '1px solid #E7EAEB'
                                }}
                                size="large"
                                value={selectedDate}
                                onChange={filterDate}
                            />
                        </Box>
                    </Space>
                </>
            </Collapse>
            {loading ? (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '4000'
                    }}
                >
                    {antIcon}
                </Box>
            ) : null}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: '1',
                        backgroundColor: '#fff',
                        borderRadius: '8px',
                        padding: '12px 0',
                        height: '735px',
                        overflow: 'hidden',
                        maxWidth: '320px'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '10px 24px',
                            width: '100%'
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#0A2938',
                                fontSize: '16px',
                                fontWeight: '500'
                            }}
                        >
                            Name
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            overflowY: 'scroll',
                            overflowX: 'hidden'
                        }}
                        className="retailer-map-y"
                    >
                        {/* {retailerList.map((retailer) => (
                            <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => {}}
                                key={retailer?._id}
                                onClick={() => {
                                    return handleMultipleRetailer(retailer?._id);
                                }}
                                // onClick={() => {
                                //     return showMultiple ? handleMultipleRetailer(retailer?._id) : handleSelectRetailer(retailer?._id);
                                // }}
                                className={`selected-retailer ${
                                    selectedRetailers.some((selected) => selected?._id === retailer?._id) ? 'selected' : ''
                                }`}
                            >
                                <Typography
                                    sx={{
                                        color: '#0A2938',
                                        fontSize: '14px',
                                        fontWeight: '500'
                                    }}
                                >
                                    {retailer?.retailerName}
                                </Typography>

                                <ArrowForwardIosIcon
                                    sx={{
                                        color: '#FF740F',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        cursor: 'cursor'
                                    }}
                                />
                            </div>
                        ))} */}
                        {retailerList?.map((retailer) => (
                            <div
                                role="button"
                                tabIndex={0}
                                onKeyDown={() => {}}
                                key={retailer?._id}
                                onClick={() => {
                                    return handleMultipleRetailer(retailer?._id);
                                }}
                                className={`selected-retailer ${
                                    selectedRetailers.some((selected) => selected?._id === retailer?._id) ? 'selected' : ''
                                }`}
                            >
                                <input
                                    type="checkbox"
                                    id={retailer?._id}
                                    name={retailer?.retailerName}
                                    value={retailer?._id}
                                    checked={selectedRetailers.some((selected) => selected?._id === retailer?._id)}
                                    className="retailer-checkbox-custom"
                                    onChange={() => {}}
                                />
                                <Typography
                                    sx={{
                                        color: '#0A2938',
                                        fontSize: '14px',
                                        fontWeight: '500'
                                    }}
                                >
                                    {retailer?.retailerName}
                                </Typography>
                            </div>
                        ))}
                    </Box>
                </Box>
                <Box
                    sx={{
                        flexGrow: '1',
                        margin: '5px',
                        flexBasis: '400px'
                    }}
                >
                    <div id="retailer-map">
                        <Map
                            position={{ lat: -34.397, lng: 150.644 }}
                            mapCenter={mapCenter}
                            setCurrentPlaceId={setCurrentPlaceId}
                            handleMarkerClick={handleMarkerClick}
                            currentPlaceId={currentPlaceId}
                            profiles={profiles}
                            zoom={zoom}
                            retailerPosition={retailerPosition}
                            selectCountry={selectCountry}
                            retailerZoom={retailerZoom}
                        />
                    </div>
                </Box>
            </Box>
        </div>
    );
};

export default RetailerMap;
