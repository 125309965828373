import React, { useState, useEffect } from 'react';
import { Table, Divider, Paper, Space, Button, Select, Modal, Checkbox, Alert } from 'antd';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Link, useNavigate, useParams, useHistory, useLocation } from 'react-router-dom';
import WestIcon from '@mui/icons-material/West';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RetailerProfileModal from './retailerProfileModal';
import ApiUrl from '../../network';
import { decrypt } from '../../utils/encrypt';
import { LoadingOutlined, DownloadOutlined, FileAddOutlined } from '@ant-design/icons';
import { dateConverter, twoDecimalPlaces } from 'utils/functions';

import { EmptyState } from 'components/EmptyState/EmptyState';

const RetailersOrderHistory = () => {
    const navigate = useNavigate();
    const [isOrderDetailsOpen, setIsOrderDetailsOpenOpen] = useState(false);
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState([]);
    const [orders, setOrders] = useState([]);
    const [mainRetailer, setMainRetailer] = useState([]);
    const [getOrderId, setGetOrderId] = useState('');

    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const country = localStorage.getItem('country');

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54
            }}
            spin
        />
    );

    const showOderDetailesModal = () => {
        setIsOrderDetailsOpenOpen(true);
    };

    const handleOrderDetailsOk = () => {
        setIsOrderDetailsOpenOpen(false);
    };

    const handleOrderDetailsCancel = () => {
        setIsOrderDetailsOpenOpen(false);
    };

    // get retailer profile
    const getRetailerProfile = async (data) => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            const url = `${ApiUrl.BaseUrl}/retailerprofiles/retailerprofile?retailerId=${data || params?.id}`;

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const info = decrypt(data.encrypted, keyResult, ivResult);
                        const infoResult = JSON.parse(info);
                        const order = decrypt(data.encrypteds, keyResult, ivResult);
                        const orderResult = JSON.parse(order);
                        setProfile(infoResult);
                        setOrders(orderResult);
                    } else {
                        // const error = decrypt(data.data, keyResult, ivResult);
                        // const answer = JSON.parse(error);
                        console.error(data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getRetailerProfile();
    }, [params?.id]);

    const orderDetailsBody = (
        <div
            style={{ height: '500px', width: '100%', overflowY: 'scroll', overflowX: 'hidden', position: 'relative' }}
            className="collection-modals-y"
        >
            <table id="retailer-details-table" style={{ width: '100%', margin: '24px 0 0 0' }}>
                <thead style={{ position: 'sticky', backgroundColor: '#fff', width: '100%', top: '0' }}>
                    <tr>
                        <th>Product Name</th>
                        <th>Quantity</th>
                        <th> Value</th>
                    </tr>
                </thead>

                <tbody>
                    {orders
                        ?.filter((order) => order._id === getOrderId)
                        .map((order) =>
                            order.items?.map((item) => (
                                <tr key={item?._id}>
                                    <td>{item?.partid}</td>
                                    <td>{item?.qty}</td>
                                    <td>{twoDecimalPlaces(item?.qty * item.garagePrice)}</td>
                                </tr>
                            ))
                        )}
                </tbody>
            </table>
        </div>
    );

    return (
        <div>
            <List
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    padding: '0 0 16px 0',
                    borderBottom: '1px dashed #E7EAEB',
                    alignItems: 'flex-start',
                    margin: '0px auto 15px'
                }}
            >
                <WestIcon
                    sx={{
                        fontSize: '32px',
                        cursor: 'pointer',
                        '&:hover': {
                            color: '#546974'
                        },
                        '&:focus': {
                            color: '#9DA9AF'
                        }
                    }}
                    onClick={() => navigate(-1)}
                />

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginLeft: '24px'
                    }}
                >
                    <ListItem
                        sx={{
                            fontSize: '1.875rem',
                            color: '#0A2938',
                            padding: 0,
                            fontWeight: 900,
                            marginBottom: '7px'
                        }}
                    >
                        Order history
                    </ListItem>
                </Box>
            </List>
            {loading ? (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '4000'
                    }}
                >
                    {antIcon}
                </Box>
            ) : (
                <Box>
                    <Box
                        sx={{
                            // height: '508px',
                            width: '893px',
                            backgroundColor: '#fff',
                            borderRadius: '16px',
                            padding: '12px',
                            margin: '0 auto',

                            flexDirection: 'column'
                        }}
                    >
                        {orders.length > 0 ? (
                            <table id="retailer-table" style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Order Value</th>
                                        <th>Drive Type</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders.map((order) => (
                                        <tr key={order?._id}>
                                            <td>{dateConverter(order?.orderDate)}</td>
                                            <td>{twoDecimalPlaces(order?.expCashCollected)}</td>
                                            <td>{order?.orderTypId}</td>
                                            <td>
                                                <Box
                                                    sx={{
                                                        margin: '2px',
                                                        height: '40px',
                                                        padding: '10px 12px',
                                                        borderRadius: ' 8px',
                                                        color: ' #ff740f',
                                                        fontWeight: '500',
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                        fontSize: '14px',
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                    onClick={() => {
                                                        showOderDetailesModal();
                                                        setGetOrderId(order?._id);
                                                    }}
                                                >
                                                    <Typography sx={{ fontSize: '16px', fontWeight: '400' }}>Details</Typography>
                                                    <KeyboardArrowRightIcon sx={{ fontSize: '16px', fontWeight: '900' }} />
                                                </Box>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <Box
                                style={{
                                    margin: '10px auto 0',
                                    position: 'relative',
                                    backgroundColor: '#FFFFFF',
                                    height: '496px',
                                    borderRadius: '16px'
                                }}
                            >
                                <EmptyState height="204px" width="70%" text="No results yet" />
                            </Box>
                        )}
                    </Box>
                </Box>
            )}

            <RetailerProfileModal
                isOpen={isOrderDetailsOpen}
                handleOk={handleOrderDetailsOk}
                handleCancel={handleOrderDetailsCancel}
                content={orderDetailsBody}
                heading="01, Jan, 2024 / Order details"
                centered={false}
                orders={orders}
                getOrderId={getOrderId}
                // height="591px"
            />
        </div>
    );
};

export default RetailersOrderHistory;
