import { SiMicrosoftexcel } from 'react-icons/si';
import Wrapper from '../../assets/Wrappers/StatWrapper';
import { useAppContext } from 'context/appContext';
import { IconDownload } from '@tabler/icons';
import CountChart from 'components/charts/BarChart';
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './cards.css';

function DataCard({ count, title, icon, color, bcg, data, gender, percentage, progressColor, trailColor, month }) {
    const { handleExcelDataExport } = useAppContext();
    const downloadExcel = () => {
        if (data?.length === 0) return;
        handleExcelDataExport(data, title);
    };
    return (
        <div className="small-cards">
            <div className="count-heading">
                <span className="total">{title}</span>
                {/* {data?.length > 0 ? ( */}
                <a
                    role="button"
                    tabIndex={-1}
                    onClick={downloadExcel}
                    onKeyDown={downloadExcel}
                    title={data?.length > 0 ? 'Click to download excel' : ''}
                >
                    <span>Download</span>
                    <IconDownload size="1rem" />
                </a>
                {/* ) : (
                    ''
                )} */}
            </div>
            <div className="subcard">
                <span>Today</span>
                <span>{count}</span>
            </div>
        </div>
    );
}

export default DataCard;
