/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../../components/PageHeader';
import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Link, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import useScriptRef from 'hooks/useScriptRef';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import EditIcon from '@mui/icons-material/Edit';
// import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Form from 'react-bootstrap/Form';
import Avatar from '@mui/material/Avatar';
import nologo from 'assets/images/nologo.png';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const AddPartOrderedFleet = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [perMargin, setMargin] = useState('');
    const [part, setPart] = useState([]);
    const [orderValue, setorderValue] = useState('');
    const [expCashCollected, setExpectedCash] = useState('');
    const [qty, setQty] = useState('');
    const [partid, setPartId] = useState('');
    const [skuId, setSkuId] = useState('');
    const [brand, setBrand] = useState('');
    const [wholeSalePrice, setwholeSalePrice] = useState('');
    const [garagePrice, setgaragePrice] = useState('');
    const [indexValue, setIndexValue] = useState('');
    const [open2, setOpen2] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [items, setItems] = useState([]);
    const [partType, setpartType] = useState([]);
    const [supplier, setSupplier] = useState('');
    const [partimage, setpartimage] = useState('');
    const [channelPartnerId, setchannelPartnerId] = useState('');
    const [companyName, setcompanyName] = useState('');
    const [orderNotes, setOrderNotes] = useState('');

    const retrieveAllPart = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            fetch(ApiUrl.PartFleetAllUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setPart(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {});
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        retrieveAllPart();

        const company = localStorage.getItem('company');
        const channelPartnerId = localStorage.getItem('channelpartnerId');
        setcompanyName(company);
        setchannelPartnerId(channelPartnerId);
    }, []);

    const handlePartType = (event) => {
        setpartType(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const onSubmitPartEdit = (event) => {
        event.preventDefault();
        const newList = Object.assign([], items);
        const data = { ...newList[indexValue] };
        data.garagePrice = garagePrice;
        data.qty = qty;
        data.partid = partid;
        data.partType = partType;
        data.skuId = skuId;
        data.brand = brand;
        data.wholeSalePrice = wholeSalePrice;
        data.supplier = supplier ?? '';
        data.partimage = partimage ?? '';
        newList[indexValue] = data;
        setItems(newList);
        handleClose2();
        let sum = 0;
        let sum2 = 0;
        setorderValue(sum2);
        setExpectedCash(sum);
        newList.forEach((row) => {
            sum += Number(row.garagePrice) * Number(row.qty);
            // console.log('row data', row); expected cash
        });
        newList.forEach((row) => {
            sum2 += Number(row.wholeSalePrice) * Number(row.qty);
            // console.log('row data', row); order value
        });

        setorderValue(sum2);
        setExpectedCash(sum);
        const marginRate = (Number(sum) - Number(sum2)) / Number(sum2);
        const value = isFinite(marginRate) ? marginRate : 0;
        const margin = Number(value).toFixed(2);

        setMargin(margin);
    };

    const onSubmitPart = (event) => {
        event.preventDefault();
        // items, setItems
        setItems([
            ...items,
            {
                skuId,
                partid,
                qty,
                brand,
                partType,
                wholeSalePrice,
                garagePrice,
                supplier,
                partimage
            }
        ]);

        handleClose();
        let sum = 0;
        let sum2 = 0;
        setorderValue(sum2);
        setExpectedCash(sum);
        const newList = Object.assign([], items);
        newList.forEach((row) => {
            sum += Number(row.garagePrice) * Number(row.qty);
            // console.log('row data', row); expected cash
        });
        newList.forEach((row) => {
            sum2 += Number(row.wholeSalePrice) * Number(row.qty);
            // console.log('row data', row); order value
        });

        sum += Number(garagePrice) * Number(qty);
        sum2 += Number(wholeSalePrice) * Number(qty);
        setorderValue(sum2);
        setExpectedCash(sum);
        const marginRate = (Number(sum) - Number(sum2)) / Number(sum2);
        const value = isFinite(marginRate) ? marginRate : 0;
        const margin = Number(value).toFixed(2);

        setMargin(margin);
    };

    // Submit Order
    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');
        setLoading(true);
        const data = {
            orderValue,
            perMargin,
            items,
            expCashCollected,
            channelPartnerId,
            companyName,
            orderNotes
        };

        fetch(ApiUrl.OrderedPartCatalogAddUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            history('/partorderedfleet');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);

                    // console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const filterPart = (event) => {
        const newFilter = part.filter((value) => (value.sku || '').toLowerCase().includes((event || '').toLowerCase()));

        newFilter.forEach((option) => {
            setSkuId(option._id ?? '');
            setBrand(option.brand ?? '');
            setwholeSalePrice(option.wholeSalePrice ?? '');
            setgaragePrice(option.garagePrice ?? '');
            setpartimage(option.partimage ?? '');
            if (option?.stock) {
                setSupplier(option?.stock);
            } else if (option?.distributor) {
                setSupplier(option?.distributor?.partnerName);
            } else {
                setSupplier('Third Party');
            }
        });
    };

    const handleDeleteClick = (row, index) => {
        const newList = Object.assign([], items);
        newList.splice(index, 1);
        setItems(newList);
        let sum = 0;
        let sum2 = 0;
        setorderValue(sum2);
        setExpectedCash(sum);

        newList.forEach((row) => {
            sum += Number(row.garagePrice) * Number(row.qty);
            // console.log('row data', row); expected cash
        });

        newList.forEach((row) => {
            sum2 += Number(row.wholeSalePrice) * Number(row.qty);
            // console.log('row data', row); order value
        });

        setorderValue(sum2);
        setExpectedCash(sum);
        // (expected cash - order value) / order value
        const marginRate = (Number(sum) - Number(sum2)) / Number(sum2);
        const value = isFinite(marginRate) ? marginRate : 0;
        const margin = Number(value).toFixed(2);
        setMargin(margin);
    };

    const handleEditClick = (row, index) => {
        setPartId(row.partid ?? '');
        setQty(row.qty ?? '');
        setpartType(row?.partType ?? '');
        setgaragePrice(row.garagePrice ?? '');
        setSkuId(row.skuId ?? '');
        setBrand(row.brand ?? '');
        setwholeSalePrice(row.wholeSalePrice ?? '');
        setSupplier(row?.supplier ?? '');
        setpartimage(row?.partimage ?? '');
        handleClickOpen2();
        setIndexValue(index);
    };

    return (
        <>
            <PageHeader title="Home | Fleet Management" subTitle="Home | Fleet Management | New Order" />
            <MainCard title="">
                <form onSubmit={handleSubmit}>
                    <Typography variant="subtitle2" component="div">
                        Order Parts
                    </Typography>
                    <br />
                    <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                        <Button variant="contained" onClick={handleClickOpen} size="sm" className={classes.item}>
                            Add part
                        </Button>
                    </Box>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 450 }} aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                #
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                SKU
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Part
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                QTY
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Size
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                Actions
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.skuId}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Avatar
                                                        variant="rounded"
                                                        src={row.partimage ?? nologo}
                                                        sx={{ width: 100, height: 70 }}
                                                    />
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    {row.partid}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.qty}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.partType}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    <IconButton
                                                        aria-label="edit"
                                                        color="primary"
                                                        onClick={() => handleEditClick(row, index)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label="delete"
                                                        color="error"
                                                        onClick={() => handleDeleteClick(row, index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <br />
                    <Typography variant="subtitle2" component="div">
                        Order Notes
                    </Typography>
                    <br />
                    <Grid container spacing={2} justify="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={12}>
                            <TextField
                                autoComplete="off"
                                name="orderNotes"
                                variant="outlined"
                                multiline
                                rows={4}
                                fullWidth
                                value={orderNotes}
                                id="orderNotes"
                                label="Order Notes"
                                onChange={(e) => setOrderNotes(e.target.value)}
                            />
                        </Grid>
                    </Grid>

                    <br />
                    <br />
                    <Box display="flex" justifyContent="space-between">
                        <Button component={Link} to="/partorderedfleet" type="submit" variant="contained" color="error">
                            Back
                        </Button>
                        <Button type="submit" variant="contained" color="secondary">
                            {loading ? 'Submitting ..' : 'Submit Order'}
                        </Button>
                    </Box>
                </form>

                <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">Add Part</DialogTitle>
                    <Form onSubmit={onSubmitPart}>
                        <DialogContent>
                            {/* <DialogContentText> */}

                            <Form.Group controlId="formFile" className="mb-3">
                                <Box width={200}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        value={partid}
                                        onChange={(event, newValue) => {
                                            setPartId(newValue);
                                            filterPart(newValue);
                                        }}
                                        options={part.map((option) => option.sku)}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => <TextField {...params} label="Sku" />}
                                    />
                                </Box>
                                <br />
                                <br />
                                <Box width={200}>
                                    <Avatar variant="rounded" src={partimage ?? nologo} sx={{ width: 100, height: 70 }} />
                                </Box>
                                <br /> <br />
                                {/* <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        name="wholeSalePrice"
                                        variant="outlined"
                                        required
                                        id="wholeSalePrice"
                                        label="WholeSale Price"
                                        value={wholeSalePrice}
                                        onChange={(e) => setwholeSalePrice(e.target.value)}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        name="garagePrice"
                                        variant="outlined"
                                        required
                                        id="garagePrice"
                                        label="Garage Price"
                                        value={garagePrice}
                                        onChange={(e) => setgaragePrice(e.target.value)}
                                    />
                                </Box>
                                <br /> <br /> */}
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        name="qty"
                                        variant="outlined"
                                        required
                                        id="qty"
                                        label="Qty"
                                        value={qty}
                                        onChange={(e) => setQty(e.target.value)}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Part Size</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={partType}
                                            label="Status"
                                            onChange={handlePartType}
                                        >
                                            <MenuItem value="Body">Select an option</MenuItem>
                                            <MenuItem value="Boxes">Boxes</MenuItem>
                                            <MenuItem value="Pieces">Pieces</MenuItem>
                                            <MenuItem value="Gallons">Gallons</MenuItem>
                                            <MenuItem value="Drums">Drums</MenuItem>
                                            <MenuItem value="Rolls">Roll(s)</MenuItem>
                                            <MenuItem value="Bucket">Bucket</MenuItem>
                                            <MenuItem value="Packs">Pack(s)</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Form.Group>

                            {/* </DialogContentText> */}
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" size="sm" className={classes.item} autoFocus onClick={handleClose} color="error">
                                Cancel
                            </Button>
                            <Button
                                autoFocus
                                variant="contained"
                                type="submit"
                                size="sm"
                                className={classes.item}
                                disabled={!partid || !garagePrice || !qty || !partType}
                            >
                                Add
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>

                {/* Edit Parts  */}

                <Dialog fullScreen={fullScreen} open={open2} onClose={handleClose2} aria-labelledby="responsive-dialog-title">
                    <DialogTitle id="responsive-dialog-title">Edit Part</DialogTitle>
                    <Form onSubmit={onSubmitPartEdit}>
                        <DialogContent>
                            {/* <DialogContentText> */}

                            <Form.Group controlId="formFile" className="mb-3">
                                <Box width={200}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        value={partid}
                                        onChange={(event, newValue) => {
                                            setPartId(newValue);
                                        }}
                                        options={part.map((option) => option.sku)}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => <TextField {...params} label="Sku" />}
                                    />
                                </Box>
                                <br />
                                <br />
                                <Box width={200}>
                                    <Avatar variant="rounded" src={partimage ?? nologo} sx={{ width: 100, height: 70 }} />
                                </Box>
                                <br /> <br />
                                {/* <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        name="wholeSalePrice"
                                        variant="outlined"
                                        required
                                        id="wholeSalePrice"
                                        label="WholeSale Price"
                                        value={wholeSalePrice}
                                        onChange={(e) => setwholeSalePrice(e.target.value)}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        name="garagePrice"
                                        variant="outlined"
                                        required
                                        id="garagePrice"
                                        label="Garage Price"
                                        value={garagePrice}
                                        onChange={(e) => setgaragePrice(e.target.value)}
                                    />
                                </Box>
                                <br /> <br /> */}
                                <Box width={200}>
                                    <TextField
                                        autoComplete="off"
                                        type="number"
                                        name="qty"
                                        variant="outlined"
                                        required
                                        id="qty"
                                        label="Qty"
                                        value={qty}
                                        onChange={(e) => setQty(e.target.value)}
                                    />
                                </Box>
                                <br /> <br />
                                <Box width={200}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Part Size</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={partType}
                                            label="Status"
                                            onChange={handlePartType}
                                        >
                                            <MenuItem value="Body">Select an option</MenuItem>
                                            <MenuItem value="Boxes">Boxes</MenuItem>
                                            <MenuItem value="Pieces">Pieces</MenuItem>
                                            <MenuItem value="Gallons">Gallons</MenuItem>
                                            <MenuItem value="Drums">Drums</MenuItem>
                                            <MenuItem value="Rolls">Roll(s)</MenuItem>
                                            <MenuItem value="Bucket">Bucket</MenuItem>
                                            <MenuItem value="Packs">Pack(s)</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Form.Group>

                            {/* </DialogContentText> */}
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" size="sm" className={classes.item} autoFocus onClick={handleClose2} color="error">
                                Cancel
                            </Button>
                            <Button
                                autoFocus
                                variant="contained"
                                type="submit"
                                size="sm"
                                className={classes.item}
                                disabled={!partid || !garagePrice || !qty || !partType}
                            >
                                Update
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </MainCard>
        </>
    );
};
export default AddPartOrderedFleet;
