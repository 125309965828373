/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from 'assets/images/garage.png';
import { Link, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import AuthCardWrapper from '../../pages/authentication/AuthCardWrapper';
import AuthWrapper1 from '../../pages/authentication/AuthWrapper1';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ApiUrl from 'network';
import useScriptRef from 'hooks/useScriptRef';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Form from 'react-bootstrap/Form';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import DateTimePicker from '@mui/lab/DateTimePicker';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const AddFleetDriverRequestService = () => {
    const classes = useStyles();
    const history = useNavigate();
    const [servicetext, setservicetext] = useState('');
    const [licenseplate, setlicenseplate] = useState('');
    const [customerPhone, setcustomerPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [services, setServices] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [serviceid, setserviceid] = useState('');
    const [serviceName, setserviceName] = useState('');
    const [availableTime, setavailableTime] = useState(new Date('2022-03-03T21:11:54'));
    // const userCurrency = localStorage.getItem('userCurrency');

    const retrieveAllService = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading2(true);
            fetch(ApiUrl.ActiveFleetServiceUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setServiceData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading2(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const scriptedRef = useScriptRef();

    const handleAvailableTime = (event) => {
        setavailableTime(event);
    };

    useEffect(() => {
        retrieveAllService();
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const onSubmitItems = (event) => {
        event.preventDefault();
        // items, setItems

        setServices([
            ...services,
            {
                serviceid,
                serviceName
            }
        ]);

        handleClose();
    };

    const filterService = (event) => {
        const newFilter = serviceData.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));

        newFilter.forEach((option) => {
            // console.log('option', option);
            setserviceid(option._id);
        });
    };
    const handleDeleteClick = (row, index) => {
        // const newList = items.filter((item) => item.skuId !== row.skuId);

        // setItems(newList);
        const newList = Object.assign([], services);
        newList.splice(index, 1);
        setServices(newList);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const channelPartnerId = localStorage.getItem('channelpartnerId');

        const data = {
            channelPartnerId,
            licenseplate,
            availableTime,
            customerPhone,
            servicetext,
            services
        };
        const userInfo = localStorage.getItem('userToken');
        fetch(ApiUrl.FleetDriverAddRequestServiceUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status) {
                    toast.success(result.message);
                    setLoading(false);
                    if (scriptedRef) {
                        setTimeout(() => {
                            // window.location.reload();
                            history('/fleetdriverrequestservice');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <AuthWrapper1>
                        <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                            <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                                    <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                                        <AuthCardWrapper>
                                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                                <Grid item sx={{ mb: 3 }}>
                                                    <Link to="#">
                                                        <img src={logo} alt="logo" height={50} />
                                                    </Link>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <br />
                                                    <Typography variant="h3" component="h2" textAlign="center">
                                                        Request Service Form
                                                    </Typography>

                                                    <br />
                                                    <br />
                                                    <form onSubmit={handleSubmit}>
                                                        <TextField
                                                            autoComplete="off"
                                                            fullWidth
                                                            name="licenseplate"
                                                            variant="outlined"
                                                            required
                                                            id="licenseplate"
                                                            label="License Plate"
                                                            value={licenseplate}
                                                            onChange={(e) => setlicenseplate(e.target.value)}
                                                        />
                                                        <br />
                                                        <br />
                                                        <TextField
                                                            autoComplete="off"
                                                            fullWidth
                                                            name="customerPhone"
                                                            type="number"
                                                            variant="outlined"
                                                            required
                                                            id="customerPhone"
                                                            label="Driver Whatsapp Number"
                                                            value={customerPhone}
                                                            onChange={(e) => setcustomerPhone(e.target.value)}
                                                        />

                                                        <br />
                                                        <br />
                                                        {/* <Box width={320}> */}
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DateTimePicker
                                                                fullWidth
                                                                label="Schedule Date/Time"
                                                                value={availableTime}
                                                                onChange={handleAvailableTime}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                        {/* </Box> */}
                                                        <br />
                                                        <br />
                                                        <Typography variant="h5" component="h2">
                                                            Services needed: select name e.g. [Oil Change]
                                                        </Typography>
                                                        <br />

                                                        <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                                            <Button
                                                                variant="contained"
                                                                onClick={handleClickOpen}
                                                                size="sm"
                                                                className={classes.item}
                                                            >
                                                                Add Service
                                                            </Button>
                                                        </Box>
                                                        <br />

                                                        <Grid container spacing={2} justify="flex-start">
                                                            <Grid item xs={12} sm={6} md={4} lg={12}>
                                                                <TableContainer component={Paper}>
                                                                    <Table aria-label="caption table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    Service
                                                                                </TableCell>

                                                                                <TableCell align="left" className={classes.tableHeaderCell}>
                                                                                    Action
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {services.map((row, index) => (
                                                                                <TableRow key={index}>
                                                                                    <TableCell component="th" scope="row">
                                                                                        {row.serviceName}
                                                                                    </TableCell>

                                                                                    <TableCell component="th" scope="row">
                                                                                        <IconButton
                                                                                            aria-label="delete"
                                                                                            color="error"
                                                                                            onClick={() => handleDeleteClick(row, index)}
                                                                                        >
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Grid>
                                                        </Grid>
                                                        <br />
                                                        <br />
                                                        <Typography variant="h5" component="h2">
                                                            Only use this text field when the service is not in the system.
                                                            <br /> <br />
                                                            Service needed: include name e.g., [Oil Change]
                                                        </Typography>
                                                        <br />

                                                        <TextField
                                                            autoComplete="off"
                                                            name="servicetext"
                                                            variant="outlined"
                                                            multiline
                                                            rows={6}
                                                            fullWidth
                                                            value={servicetext}
                                                            id="servicetext"
                                                            label=""
                                                            onChange={(e) => setservicetext(e.target.value)}
                                                        />
                                                        <br />
                                                        <br />
                                                        <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                                            <Button
                                                                type="submit"
                                                                variant="contained"
                                                                color="secondary"
                                                                disabled={!availableTime || !licenseplate || !customerPhone || !services}
                                                            >
                                                                {loading ? 'Submitting ..' : 'Submit'}
                                                            </Button>
                                                        </Box>
                                                    </form>
                                                </Grid>
                                            </Grid>
                                        </AuthCardWrapper>
                                    </Grid>
                                </Grid>

                                <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                                    <DialogTitle id="responsive-dialog-title">Add Service</DialogTitle>
                                    <Form onSubmit={onSubmitItems}>
                                        <DialogContent>
                                            <Form.Group controlId="formFile" className="mb-3">
                                                <Box width={200}>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        value={serviceName}
                                                        onChange={(event, newValue) => {
                                                            setserviceName(newValue);
                                                            filterService(newValue);
                                                        }}
                                                        options={serviceData.map((option) => option.name)}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        renderInput={(params) => <TextField {...params} label="Service" />}
                                                    />
                                                </Box>
                                            </Form.Group>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                variant="contained"
                                                size="sm"
                                                className={classes.item}
                                                autoFocus
                                                onClick={handleClose}
                                                color="error"
                                            >
                                                Cancel
                                            </Button>
                                            <Button autoFocus variant="contained" type="submit" size="sm" className={classes.item}>
                                                Add
                                            </Button>
                                        </DialogActions>
                                    </Form>
                                </Dialog>
                            </Grid>
                        </Grid>
                    </AuthWrapper1>
                </>
            )}
        </>
    );
};

export default AddFleetDriverRequestService;
