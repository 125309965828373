import styled from 'styled-components';
import Chart from 'react-apexcharts';
import { useRetailerContext } from 'context/retailer-shop/retailer-context';
import { useAppContext } from 'context/appContext';
import { useState } from 'react';
import { Button, Stack } from '@mui/material';
import { FcBarChart, FcLineChart } from 'react-icons/fc';

const Wrapper = styled.div`
    background: #fff;
    color: #222;
    padding: 1rem 2rem;
    border-radius: 10px;
    font-family: 'Roboto';
    margin-bottom: 30px;

    .chart {
        font-size: 25px;
    }

    h4 {
        font-size: 1rem;
        color: #8b8b8b;
    }

    .sale-type {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .form-select {
        max-width: 150px;
    }
`;

const SalesChart = () => {
    const { userCurrency } = useAppContext();
    const { chartData } = useRetailerContext();
    const [chartType, setChartType] = useState('bar');

    const chartSeries = chartData.map((item) => {
        return {
            x: item.month,
            y: item.total_sales_month.toFixed(1)
        };
    });

    const config = {
        series: [
            {
                data: [...chartSeries]
            }
        ],
        options: {
            chart: {
                toolbar: {
                    show: true
                }
            },
            yaxis: {
                title: { text: 'Total Monthly Sales' }
            },
            tooltip: {
                enabled: true,
                y: {
                    formatter: undefined,
                    title: {
                        formatter: () => userCurrency
                    }
                }
            },
            dataLabels: {
                enabled: false
            }
        }
    };
    if (chartType === 'line') {
        config.options.markers = {
            size: 5
        };
        config.options.stroke = {
            curve: 'smooth',
            width: 5
        };
    }
    const barActive = chartType === 'bar' && { background: '#193855', color: '#fff' };
    const lineActive = chartType === 'line' && { background: '#193855', color: '#fff' };

    return (
        <Wrapper className="shadow-sm">
            <div className="sale-type">
                <h4>Retailer Purchases</h4>
                {chartData.length > 0 && (
                    <div>
                        <Stack spacing={1} direction="row">
                            <Button variant="outlined" onClick={() => setChartType('bar')} style={{ ...barActive }}>
                                <FcBarChart className="chart" />
                            </Button>
                            <Button variant="outlined" onClick={() => setChartType('line')} style={{ ...lineActive }}>
                                <FcLineChart className="chart" />
                            </Button>
                        </Stack>
                    </div>
                )}
                {/* <select className="form-select" aria-label="select mode">
                    <option value="weekly">Weekly</option>
                    <option value="monthly" defaultValue="Monthly">
                        Monthly
                    </option>
                </select> */}
            </div>
            {chartData.length > 0 ? (
                <Chart options={config.options} series={config.series} type={chartType} width="100%" height={310} />
            ) : (
                <h5 style={{ fontSize: '2rem' }}>No data to display</h5>
            )}
        </Wrapper>
    );
};
export default SalesChart;
