import { createContext, useContext, useReducer } from 'react';
import { authFetch } from 'utils/axios-config';
import { successToast } from 'utils/toast';
import { RETAILER_BEGIN, RETAILER_SUCCESS, HANDLE_CHANGE, LOYALTY_BEGIN, LOYALTY_ERROR, LOYALTY_SUCCESS } from './loyalty-actions';
import loyaltyReducer from './loyalty-reducer';

const userCountry = localStorage.getItem('country');

const initialState = {
    isLoading: false,
    retailers: [],
    currentRetailerId: userCountry === 'Ghana' ? '622f930947ac34029865ba8b' : '646b96d1a93918a852096cbf',
    currentRetailerName: userCountry === 'Ghana' ? 'Anthony Abanga' : 'Mr UDECHUKWU',
    customerName: '',
    customerContact: '',
    customerLat: '',
    customerLng: '',
    date: '',
    salesPerson: '',
    nextTier: '',
    totalSales: 0,
    currentLoyalty: '',
    currentTier: '',
    currentReward: 0,
    currentNoOfOrders: '',
    amountToSpendMore: 1500,
    noOfMoreOrders: '25 or more orders',
    percentage: 60,
    loyalty: [],
    partsSold: [],
    ordersLefts: ''
};

const LoyaltyContext = createContext({ ...initialState });

const LoyaltyProvider = ({ children }) => {
    const [state, dispatch] = useReducer(loyaltyReducer, initialState);

    const getAllRetailers = async () => {
        dispatch({ type: RETAILER_BEGIN });
        try {
            const { data } = await authFetch('/retailersummary/retailerdata');
            dispatch({ type: RETAILER_SUCCESS, payload: { retailers: data.data } });
            successToast('Data synchronized successfully');
        } catch (error) {
            console.log(error);
        }
    };

    const handleRetailerChange = (value, id) => {
        dispatch({ type: HANDLE_CHANGE, payload: { value, id } });
    };

    const getRetailerLoyalty = async (currentRetailerObj) => {
        dispatch({ type: LOYALTY_BEGIN });
        try {
            const { data } = await authFetch.post('/loyalties/retailerloyalty', currentRetailerObj);

            const {
                customerName,
                customerContact,
                customerLat,
                customerLng,
                date,
                totalSales,
                currentLoyalty,
                currentTier,
                currentReward,
                currentNoOfOrders,
                amountToSpendMore,
                noOfMoreOrders,
                percentage,
                loyalty,
                partsSold,
                salesPerson,
                nextTier,
                ordersLefts
            } = data.data;

            // console.log('Customer Name', customerName);

            dispatch({
                type: LOYALTY_SUCCESS,
                payload: {
                    customerName,
                    customerContact,
                    customerLat,
                    customerLng,
                    date,
                    totalSales,
                    currentLoyalty,
                    currentTier,
                    currentReward,
                    currentNoOfOrders,
                    amountToSpendMore,
                    noOfMoreOrders,
                    percentage,
                    loyalty,
                    partsSold,
                    salesPerson,
                    nextTier,
                    ordersLefts
                }
            });
        } catch (error) {
            if (error.response.status === 401) return;
            dispatch({ type: LOYALTY_ERROR });
        }
    };

    return (
        <LoyaltyContext.Provider value={{ ...state, dispatch, getAllRetailers, handleRetailerChange, getRetailerLoyalty }}>
            {children}
        </LoyaltyContext.Provider>
    );
};

const useLoyaltyContext = () => {
    return useContext(LoyaltyContext);
};

export { useLoyaltyContext, LoyaltyProvider };
