/* eslint-disable no-underscore-dangle */
import ApiUrl from 'network';
import { useEffect, useState } from 'react';
// import * as XLSX from 'xlsx';
import { makeStyles } from '@mui/styles';
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import Box from '@mui/material/Box';
import Controls from '../../components/controls/Controls';
import { Search } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import swal from 'sweetalert';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// // import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import InputLabel from '@mui/material/InputLabel';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
import useScriptRef from 'hooks/useScriptRef';
import { toast } from 'react-toastify';
// import Form from 'react-bootstrap/Form';
import Grid from '@mui/material/Grid';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 0
    }
}));

const Customer = () => {
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [fleetInfo, setFleet] = useState([]);
    const [fleetData, setFleetData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    // const [open, setOpen] = useState(false);
    // const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    // const [file, setFile] = useState('');
    // const [filterstage, setfilterstage] = useState('');
    // const [stageInfo, setStage] = useState([]);

    const handleEditClick = (row) => {
        if (scriptedRef.current) {
            history(`/editcustomer/${row._id}`);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    // const selectedFile = (e) => {
    //     setFile(e.target.files[0]);
    // };

    function toTimestamp(strDate) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const today = strDate ? new Date(strDate) : new Date();
        return today.toLocaleDateString('en-gh', options);
    }

    // const filterStageById = async (id) => {
    //     try {
    //         const userInfo = localStorage.getItem('userToken');
    //         const config = {
    //             headers: {
    //                 'auth-token': userInfo,
    //                 'Content-Type': 'application/json'
    //             }
    //         };
    //         setLoading(true);
    //         fetch(ApiUrl.FilterFleetStageUrl + id, config)
    //             .then((data) => data.json())
    //             .then((data) => {
    //                 if (data.status === true) {
    //                     setFleet(data.data);
    //                     setFleetData(data.data);
    //                     setLoading(false);
    //                 } else {
    //                     toast.error(data.data);
    //                     setLoading(false);
    //                 }
    //             })
    //             .finally(() => {
    //                 setLoading(false);
    //             });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    // const retrieveAllStage = async () => {
    //     try {
    //         const userInfo = localStorage.getItem('userToken');
    //         const config = {
    //             headers: {
    //                 'auth-token': userInfo,
    //                 'Content-Type': 'application/json'
    //             }
    //         };
    //         setLoading(true);
    //         fetch(ApiUrl.AllStageUrl, config)
    //             .then((data) => data.json())
    //             .then((data) => {
    //                 if (data.status === true) {
    //                     setStage(data.data);
    //                     setLoading(false);
    //                 } else {
    //                     toast.error(data.data);
    //                     setLoading(false);
    //                 }
    //             })
    //             .finally(() => {
    //                 setLoading(false);
    //             });
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - fleetInfo.length) : 0;

    const retrieveAllFleet = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.FleetPartnerUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setFleet(data.data);
                        setFleetData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // const handleFilterStageChange = (e) => {
    //     console.log('e', e.target.value);
    //     setfilterstage(e.target.value);
    //     const searchWord = e.target.value;
    //     if (searchWord === 'All') {
    //         retrieveAllFleet();
    //     } else {
    //         filterStageById(e.target.value);
    //     }
    // };

    useEffect(() => {
        retrieveAllFleet();
        // retrieveAllStage();
    }, []);

    // const onSubmit = async (e) => {
    //     e.preventDefault();
    //     // console.log('e', e);
    //     const formData = new FormData();
    //     formData.append('file', file);
    //     // console.log('file', file);
    //     // console.log('file type', file.type);
    //     const userInfo = localStorage.getItem('userToken');
    //     if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    //         try {
    //             const data = await file.arrayBuffer();
    //             const workbook = XLSX.read(data);
    //             const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    //             const jsonData = XLSX.utils.sheet_to_json(worksheet);
    //             // console.log('workbook', JSON.stringify(jsonData));
    //             fetch(ApiUrl.UploadRetailsUrl, {
    //                 method: 'POST',
    //                 headers: {
    //                     'auth-token': userInfo,
    //                     'Content-Type': 'application/json'
    //                 },

    //                 body: JSON.stringify(jsonData)
    //             })
    //                 .then((res) => res.json())
    //                 .then((result) => {
    //                     if (result.status) {
    //                         toast.success(result.message);
    //                         setLoading(false);
    //                         if (scriptedRef) {
    //                             handleClose();
    //                             setTimeout(() => {
    //                                 retrieveAllFleet();
    //                             }, 3000);
    //                         }
    //                     } else {
    //                         toast.error(result.data);
    //                         setLoading(false);
    //                     }
    //                 })
    //                 .finally(() => {
    //                     setLoading(false);
    //                 });
    //         } catch (e) {
    //             console.log(e);
    //         }
    //     } else {
    //         toast.error('File Type not supported, kindly upload excel');
    //     }
    // };

    const handleDeleteClick = (row) => {
        swal({
            title: 'Are you sure?',
            text: `Once deleted, you will not be able to recover this Fleet!(${row.partnerName})`,
            icon: 'warning',
            buttons: true,
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                swal('Poof! The Fleet has been deleted!', {
                    icon: 'success'
                });

                const data = {
                    id: row._id
                };
                const userInfo = localStorage.getItem('userToken');

                fetch(ApiUrl.DeleteChannelPartnerUrl, {
                    method: 'DELETE',
                    headers: {
                        'auth-token': userInfo,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                })
                    .then((res) => res.json())
                    .then((result) => {
                        if (result.status === true) {
                            if (scriptedRef.current) {
                                retrieveAllFleet();
                                // window.location.href = '/stage';
                                // history('/stage');
                            }
                        } else {
                            toast.error(result.data);
                        }
                    });
            } else {
                swal('The Fleet Partner is safe!');
            }
        });
    };

    const handleSearch = (event) => {
        const searchWord = event.target.value;

        const newFilter = fleetInfo.filter((value) => value.partnerName.toLowerCase().includes(searchWord.toLowerCase()));

        if (searchWord === '') {
            setFleet(fleetData);
        } else {
            setFleet(newFilter);
        }
    };

    return (
        <>
            <PageHeader title="Customers" subTitle="Home | Customers" />

            <MainCard title="Customers">
                <Box display="flex" justifyContent="flex-start">
                    <Grid>
                        <Controls.Input
                            label="Search Customer"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                        />
                    </Grid>
                    <Box width={10} />

                    <Button component={Link} to="/addcustomer" variant="contained" size="sm" className={classes.item}>
                        Add
                    </Button>
                </Box>
                <br />

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 450 }} aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {loading ? (
                                    <TableCell component="th" colSpan="4" align="center">
                                        {' '}
                                        <CircularProgress />{' '}
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            #
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Channel
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Customer/Company Name
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Telephone No.
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Location
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Sign up Date
                                        </TableCell>

                                        <TableCell align="left" className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 ? fleetInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : fleetInfo).map(
                                (row, index) => (
                                    <TableRow key={row._id}>
                                        <TableCell component="th" scope="row">
                                            {fleetInfo.length - index}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.channelName ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.partnerName ?? ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.partnerContact ?? ''}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row?.location ?? ''}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {toTimestamp(row?.signupDate)}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            <IconButton aria-label="edit" color="primary" onClick={() => handleEditClick(row)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton aria-label="delete" color="error" onClick={() => handleDeleteClick(row)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    component="div"
                    count={fleetInfo.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
        </>
    );
};

export default Customer;
