export const DELIVERY_BEGIN = 'DELIVERY_BEGIN';
export const DELIVERY_SUCCESS = 'DELIVERY_SUCCESS;';

export const FILTER_STATUS_BEGIN = 'FILTER_STATUS_BEGIN';
export const FILTER_STATUS_SUCCESS = 'FILTER_STATUS_SUCCESS';

export const FILTER_ORDERBY_DATE_BEGIN = 'FILTER_ORDERBY_DATE_BEGIN';
export const FILTER_ORDERBY_DATE_SUCCESS = 'FILTER_ORDERBY_DATE_SUCCESS';

export const SINGLE_ORDER_BEGIN = 'SINGLE_ORDER_BEGIN';
export const SINGLE_ORDER_SUCCESS = 'SINGLE_ORDER_SUCCESS';

export const CREATE_NOTE_BEGIN = 'CREATE_NOTE_BEGIN';
export const CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS';
export const CREATE_NOTE_ERROR = 'CREATE_NOTE_ERROR';

export const GET_NOTES_BEGIN = 'GET_NOTES_BEGIN';
export const GET_NOTES_SUCCESS = 'GET_NOTES_SUCCESS;';
