// import dashboard from './dashboard';
import mechanic from './mechanic';
import MechanicSettings from './mechanicSetting';

// ==============================|| MENU ITEMS dashboard ||============================== //

const mechanicItems = {
    items: [mechanic, MechanicSettings]
};

export default mechanicItems;
