import { useEffect, useState } from 'react';
import GenericPageHeader from 'components/GenericPageHeader';
import styled from 'styled-components';
import { Row, Col } from 'react-grid-system';
import { Paper, Button, Divider } from '@mui/material';
import StockTable from './StockTable';
import AddPartModal from './AddPartModal';
import { useDarkStoreContext } from 'context/darkstore-inventory/darkstore-context';
import { useAppContext } from 'context/appContext';
import { useParams } from 'react-router';

const Wrapper = styled.div`
    h3 {
        text-align: center;
        font-weight: 800;
        text-transform: uppercase;
        font-style: italic;
        text-decoration: underline;
        text-shadow: 3px 2px 3px rgba(255, 255, 255, 0.2);
    }
    .inventory {
        padding: 1rem 2rem;
        margin-bottom: 20px;
    }

    .add-item {
        display: flex;
        justify-content: flex-end;
        margin: 1rem;
    }

    .create-batch {
        background-color: #ff740f;
        text-transform: capitalize;
        @media screen and (max-width: 500px) {
            margin-top: 20px;
        }
    }

    .stats {
        .stats-card {
            padding: 2rem 1rem;
            font-family: 'Roboto';
            .item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
            }
        }
    }
`;

const StoreInventory = () => {
    const { userCurrency } = useAppContext();
    const { getAllDarkStores, getDarkStoreStats, totalProduct, todaysells, yesterdaysells, totalsells, stockissues, storeInventory } =
        useDarkStoreContext();

    const { id } = useParams();

    const storeName = storeInventory[0]?.darkstoreAreaId?.name;

    useEffect(() => {
        getAllDarkStores();
        getDarkStoreStats(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <Wrapper>
            <GenericPageHeader title="Inventory Management" breadcrumbs="Home | Dashboard | Inventory" />
            <h3>{storeName}</h3>
            <div className="add-item">
                <Button variant="contained" className="create-batch" onClick={handleShow}>
                    Add new Part
                </Button>
            </div>
            <Row>
                <Col xl={9} lg={9} md={8} sm={12} xs={12}>
                    <div className="mb-5">
                        <StockTable
                            th1="#"
                            th2="Product"
                            th3="Part Name"
                            th4={`Garage Price (${userCurrency})`}
                            th5="Quantity"
                            th6={`Expected Cash (${userCurrency})`}
                            th7="Actions"
                        />
                    </div>
                </Col>
                <Col xl={3} lg={3} md={4} sm={12} xs={12}>
                    <Paper elevation={1} className="stats">
                        <div className="stats-card">
                            <Divider>
                                <h5>Store Statistics</h5>
                            </Divider>
                            <br />
                            <div className="item">
                                <p>
                                    <strong>Total Product</strong>
                                </p>
                                <p>
                                    <em>{totalProduct}</em>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <strong>Today Sales</strong>
                                </p>
                                <p>
                                    <em>
                                        {userCurrency}
                                        {todaysells}
                                    </em>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <strong>Yesterday Sales</strong>
                                </p>
                                <p>
                                    <em>
                                        {userCurrency}
                                        {yesterdaysells}
                                    </em>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <strong>Total Sales</strong>
                                </p>
                                <p>
                                    <em>
                                        {userCurrency}
                                        {totalsells}
                                    </em>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <strong>Stock Issues</strong>
                                </p>
                                <p>
                                    <em>{stockissues}</em>
                                </p>
                            </div>
                        </div>
                    </Paper>
                </Col>
            </Row>
            <AddPartModal handleClose={handleClose} show={show} />
        </Wrapper>
    );
};
export default StoreInventory;
