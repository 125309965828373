import QuickLinks from 'views/quickLinks/QuickLinks';
import { adminRetilerDashboardQuicklinks } from '../../menu-items/quicklinks';
import './retailer.css';

const RetailerProfile = () => {
    return (
        <>
            <QuickLinks quicklinks={adminRetilerDashboardQuicklinks} text="View information about all retailers" />
        </>
    );
};

export default RetailerProfile;
