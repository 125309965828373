import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
// import { notification } from 'antd';
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import useScriptRef from 'hooks/useScriptRef';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';

const ViewBatchStatus = () => {
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [status, setStatus] = useState('');
    const history = useNavigate();
    const scriptedRef = useScriptRef();

    const handleChange = (event) => {
        setStatus(event.target.value);
    };
    const { id } = useParams();

    useEffect(() => {
        const userInfo = localStorage.getItem('userToken');
        setLoading2(true);
        fetch(ApiUrl.ViewBatchStatusUrl + id, {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    setName(result.data.name);
                    setStatus(result.data.status);
                    setLoading2(false);
                } else {
                    toast.error(result.data);
                    setLoading2(false);
                }
            })
            .finally(() => {
                setLoading2(false);
            });
    }, [id]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const userInfo = localStorage.getItem('userToken');

        const data = {
            id,
            name,
            status
        };
        setLoading(true);
        fetch(ApiUrl.UpdateBatchStatusUrl, {
            method: 'PUT',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    setLoading(false);
                    setTimeout(() => {
                        if (scriptedRef.current) {
                            history('/batchstatus');
                        }
                    }, 3000);
                } else {
                    toast.error(result.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading2 ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <br />
                            <PageHeader title="Batch Status" subTitle="Home | Configuration | Setup  | Batch Status" />
                            <br />
                            <MainCard title="Edit Batch Status">
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        autoComplete="off"
                                        name="name"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="name"
                                        value={name}
                                        label="Name"
                                        onChange={(e) => setName(e.target.value)}
                                        autoFocus
                                    />
                                    <br />
                                    <br />

                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={status}
                                            label="Status"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="Active">Active</MenuItem>
                                            <MenuItem value="InActive">InActive</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <br />
                                    <br />
                                    <Box display="flex" justifyContent="space-between">
                                        <Button component={Link} to="/batchstatus" type="submit" variant="contained" color="error">
                                            Reset
                                        </Button>

                                        <Button type="submit" variant="contained" color="secondary" disabled={!name}>
                                            {loading ? 'Updating ..' : 'Update'}
                                        </Button>
                                    </Box>
                                </form>
                            </MainCard>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};
export default ViewBatchStatus;
