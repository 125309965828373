import LoyaltyReceipt from 'components/LoyaltyReceipt/LoyaltyReceipt';

const Loyalty = () => {
    return (
        <>
            <LoyaltyReceipt />
        </>
    );
};
export default Loyalty;
