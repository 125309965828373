/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import ApiUrl from 'network';
import Autocomplete from '@mui/material/Autocomplete';
// import { notification } from 'antd';
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { FormControl, InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Form from 'react-bootstrap/Form';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import IconButton from '@mui/material/IconButton';
// import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useScriptRef from 'hooks/useScriptRef';
import { encrypt, decrypt } from '../../utils/encrypt';

const useStyles = makeStyles(() => ({
    tableHeaderCell: {
        fontWeight: 'bold'
    },
    item: {
        padding: 5
    },
    backgroundColor: {
        backgroundColor: '#2CB23B'
    }
}));

const ViewSalesPod = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState('');
    const [countries, setCountries] = useState([]);
    const [name, setname] = useState('');
    const [cityId, setcityId] = useState('');
    const theme = useTheme();
    const [cityData, setCityData] = useState([]);
    const [salesperson, setsalesperson] = useState([]);
    const [salesTeamInfo, setSalesTeam] = useState([]);
    const [salesTeamId, setsalesTeamId] = useState('');
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [userId, setuserId] = useState('');
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [open, setOpen] = useState(false);
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const { id } = useParams();

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    const handleClose = () => {
        setOpen(false);
    };

    const retrieveAlLsalesTeam = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.RMCCPodUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(result);

                        setSalesTeam(answer);
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        const answer = JSON.parse(error);
                        toast.error(answer);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // const handleDeleteClick = (row, index) => {
    //     const newList = Object.assign([], salesperson);
    //     newList.splice(index, 1);
    //     setsalesperson(newList);
    // };

    const retrieveAllActiveCountry = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.ActiveCountryUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCountries(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };
    const retrieveAllCity = async (id) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.CityFilterUrl + id, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setCityData(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const filterCountry = (event) => {
        const newFilter = countries.filter((value) => (value.name || '').toLowerCase().includes((event || '').toLowerCase()));
        newFilter.forEach((option) => {
            retrieveAllCity(option?._id);
        });
    };
    const handleCityChange = (event) => {
        setcityId(event.target.value);
    };
    const filterName = (event) => {
        const newFilter = salesTeamInfo.filter((value) =>
            (`${value.firstName} ${value.lastName}` || '').toLowerCase().includes((event || '').toLowerCase())
        );

        newFilter.forEach((option) => {
            if (`${option?.firstName} ${option.lastName}` === event) {
                setfirstName(option?.firstName);
                setlastName(option?.lastName);
                setuserId(option?._id);
            }
        });
    };

    const onSubmitPart = (event) => {
        event.preventDefault();

        setsalesperson([
            ...salesperson,
            {
                userId,
                firstName,
                lastName
            }
        ]);

        handleClose();
    };

    useEffect(() => {
        // retrieve all sales associate;
        retrieveAllActiveCountry();
        retrieveAlLsalesTeam();
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };
        setLoading(true);
        // const obj = JSON.stringify(id);
        // const ids = encrypt(obj, keyResult, ivResult);
        // const ids = JSON.stringify({ payload: result });
        fetch(ApiUrl.ViewSalesPodurl + id, config)
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    const result = decrypt(data.data, keyResult, ivResult);
                    const answer = JSON.parse(result);
                    // console.log('answer', answer);
                    setname(answer?.name);
                    setCountry(answer?.country);

                    setsalesperson(answer?.salesperson);
                    retrieveAllCity(answer?.cityId?.country);
                    setcityId(answer?.cityId?._id ?? '');
                } else {
                    const error = decrypt(data.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const userInfo = localStorage.getItem('userToken');

        const data = {
            name,
            country,
            cityId,
            salesperson
        };
        const obj = JSON.stringify(data);
        const result = encrypt(obj, keyResult, ivResult);

        fetch(ApiUrl.AddSalesPodUrl, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ payload: result })
        })
            .then((res) => res.json())
            .then((result) => {
                console.log('result', result);
                if (result.status === true) {
                    toast.success(result.message);
                    if (scriptedRef.current) {
                        setTimeout(() => {
                            history('/salespod');
                        }, 3000);
                    }
                } else {
                    const error = decrypt(result.data, keyResult, ivResult);
                    const answer = JSON.parse(error);
                    toast.error(answer);

                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loading ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={70} thickness={4} />
                    </div>
                </>
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <br />
                            <PageHeader title="Configuration | Sales Pod" subTitle="Home | Configuration | Sales Pod | Add" />
                            <br />
                            <MainCard title="Add Sales Pod">
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        autoComplete="off"
                                        name="name"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        disabled
                                        id="name"
                                        value={name}
                                        label="Sales Pod Name"
                                        onChange={(e) => setname(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                    <Autocomplete
                                        id="country-select-demo"
                                        autoHighlight
                                        value={country}
                                        disabled
                                        onChange={(event, newValue) => {
                                            setCountry(newValue);
                                            filterCountry(newValue);
                                        }}
                                        options={countries.map((option) => option.name)}
                                        isOptionEqualToValue={(option, value) => option.code === value.code}
                                        renderInput={(params) => <TextField {...params} label="Choose a country *" />}
                                    />
                                    <br />
                                    <br />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Choose a City *</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={cityId}
                                            disabled
                                            label="City"
                                            onChange={handleCityChange}
                                        >
                                            {cityData.map((attr) => (
                                                <MenuItem key={attr._id} value={attr._id}>
                                                    {attr?.city ?? ''}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <br />
                                    <br />

                                    {/* <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
                                <Button variant="contained" onClick={handleClickOpen} size="sm" className={classes.item}>
                                    Add RM/CC
                                </Button>
                            </Box> */}
                                    <br />

                                    <Grid container spacing={4} justify="flex-start">
                                        <Grid item xs={12} sm={6} md={4} lg={12}>
                                            <TableContainer component={Paper}>
                                                <Table aria-label="caption table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="left" className={classes.tableHeaderCell}>
                                                                RM / CC
                                                            </TableCell>

                                                            {/* <TableCell align="left" className={classes.tableHeaderCell}>
                                                                Action
                                                            </TableCell> */}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {salesperson.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row">
                                                                    {row?.firstName} {row?.lastName}
                                                                </TableCell>

                                                                {/* <TableCell component="th" scope="row">
                                                            <IconButton
                                                                aria-label="delete"
                                                                color="error"
                                                                onClick={() => handleDeleteClick(row, index)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell> */}
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>

                                    <br />
                                    <br />

                                    <Box display="flex" justifyContent="space-between">
                                        <Button component={Link} to="/salespod" type="submit" variant="contained" color="error">
                                            Reset
                                        </Button>

                                        {/* <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    disabled={!name || !country || !cityId || !salesperson || loading}
                                >
                                    {loading ? 'Submitting ..' : 'Submit'}
                                </Button> */}
                                    </Box>
                                </form>
                            </MainCard>
                        </Grid>
                    </Grid>

                    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title" fullWidth>
                        <div style={{ padding: '1rem 2rem' }}>
                            <DialogTitle id="responsive-dialog-title" style={{ fontSize: '1rem' }}>
                                Add RM / CC
                            </DialogTitle>
                            <Form onSubmit={onSubmitPart}>
                                <DialogContent>
                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Autocomplete
                                            fullWidth
                                            disablePortal
                                            id="combo-box-demo"
                                            value={salesTeamId}
                                            required
                                            onChange={(event, newValue) => {
                                                setsalesTeamId(newValue);
                                                filterName(newValue);
                                            }}
                                            options={salesTeamInfo.map((option) => `${option.firstName} ${option.lastName}`)}
                                            isOptionEqualToValue={(option, value) => option._id === value._id}
                                            renderInput={(params) => <TextField {...params} label="RM/CC" />}
                                        />
                                    </Form.Group>
                                </DialogContent>
                                <DialogActions style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        className={classes.item}
                                        autoFocus
                                        onClick={handleClose}
                                        color="error"
                                    >
                                        Cancel
                                    </Button>
                                    <Button autoFocus variant="contained" type="submit" size="large" className={classes.item}>
                                        Add
                                    </Button>
                                </DialogActions>
                            </Form>
                        </div>
                    </Dialog>
                </>
            )}
        </>
    );
};

export default ViewSalesPod;
