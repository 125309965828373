import { toast } from 'react-toastify';
import { decrypt, encrypt } from '../../../utils/encrypt';
import ApiUrl from '../../../network';
import { fetchWithAuth } from './apiService';

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

const createOrderData = (customerId, customerName, customerPhone, customerLocation, selectedProducts) => {
    const localId = Math.random().toString(36).substring(2, 15);
    const orderData = {
        customerId,
        customerName,
        orderNotes: 'Order placed via chat',
        local_id: localId,
        phoneNumber: customerPhone,
        location: customerLocation,
        src: 'Web',
        items: selectedProducts.map((product) => ({
            skuId: product._id,
            qty: product.qty || 1,
            partType: product.partType
        }))
    };

    // Optional: Add lat and lng if available
    if (customerLocation.lat && customerLocation.lng) {
        orderData.lat = customerLocation.lat;
        orderData.lng = customerLocation.lng;
    }

    return orderData;
};

// eslint-disable-next-line import/prefer-default-export
export const placeOrderFunction = async (
    setPlaceOrderLoading,
    customerId,
    customerName,
    customerPhone,
    customerLocation,
    selectedProducts,
    setOrderNo,
    setTab
    // eslint-disable-next-line consistent-return
) => {
    try {
        setPlaceOrderLoading(true);

        const orderData = createOrderData(customerId, customerName, customerPhone, customerLocation, selectedProducts);
        const payload = encrypt(JSON.stringify(orderData), keyResult, ivResult);

        const url = `${ApiUrl.BaseUrl}/order/v2/placemiraorder`;
        const response = await fetchWithAuth(url, 'POST', { payload });

        if (response.status) {
            setOrderNo(response.data);
            setTab('order-receipt');
            return { order_id: response.data, ...response };
        }
        toast.error(response.message || 'Error placing order');
    } catch (error) {
        console.error('Error in placeOrderFunction:', error);
        toast.error('An error occurred while placing the order.');
    } finally {
        setPlaceOrderLoading(false);
    }
};

// eslint-disable-next-line consistent-return
export const fetchProducts = async () => {
    try {
        const response = await fetchWithAuth(`${ApiUrl.BaseUrl}/order/v2/allparts`);
        return JSON.parse(decrypt(response.data, keyResult, ivResult));
    } catch (error) {
        //  console.log(error);
        toast.error(error.message); // Log the error for debugging
        return null;
    }
};

// eslint-disable-next-line consistent-return
export const fetchPartType = async () => {
    try {
        const response = await fetchWithAuth(`${ApiUrl.BaseUrl}/order/v2/orderstatuspartypes`);
        return JSON.parse(decrypt(response.data, keyResult, ivResult));
    } catch (error) {
        // console.log(error);
        toast.error(error.message); // Log the error for debugging
        return null;
    }
};
