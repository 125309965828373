/* eslint-disable no-underscore-dangle */
import MainCard from 'ui-component/cards/MainCard';
import PageHeader from '../../components/PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApiUrl from 'network';
import { toast } from 'react-toastify';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import useScriptRef from 'hooks/useScriptRef';

const AddSalesTeam = () => {
    const [name, setName] = useState('');
    const [salesUserId, setsalesUserId] = useState('');
    const [salesdata, setsalesdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useNavigate();
    const scriptedRef = useScriptRef();

    const retrieveAlLsalesUsersTeam = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            setLoading(true);
            fetch(ApiUrl.AllSalesUserUrl, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        setsalesdata(data.data);
                    } else {
                        toast.error(data.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        // retrieve all sales team;
        retrieveAlLsalesUsersTeam();
    }, []);

    const handleSalesChange = (event) => {
        setsalesUserId(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const userInfo = localStorage.getItem('userToken');

        const data = {
            name,
            salesUserId
        };

        fetch(ApiUrl.AddSalesTeam, {
            method: 'POST',
            headers: {
                'auth-token': userInfo,
                Accept: 'application/form-data',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status === true) {
                    toast.success(result.message);
                    if (scriptedRef.current) {
                        setTimeout(() => {
                            history('/salesteam');
                        }, 3000);
                    }
                } else {
                    toast.error(result.data);
                    setLoading(false);
                    console.log(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <br />
                    <PageHeader title="Sales Team" subTitle="Home | Configuration | Team | Sales Team" />
                    <br />
                    <MainCard title="Add Sales Team">
                        <form onSubmit={handleSubmit}>
                            <TextField
                                autoComplete="off"
                                name="name"
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                value={name}
                                label="Name"
                                onChange={(e) => setName(e.target.value)}
                                autoFocus
                            />
                            <br />
                            <br />

                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Signed up sales user</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={salesUserId}
                                    required
                                    label="Sales User"
                                    onChange={handleSalesChange}
                                >
                                    {salesdata.map((sales) => (
                                        <MenuItem key={sales._id} value={sales._id}>
                                            {sales.firstName} {sales.lastName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <br />
                            <br />

                            <Box display="flex" justifyContent="space-between">
                                <Button component={Link} to="/salesteam" type="submit" variant="contained" color="error">
                                    Reset
                                </Button>

                                <Button type="submit" variant="contained" color="secondary" disabled={!name || loading}>
                                    {loading ? 'Submitting ..' : 'Submit'}
                                </Button>
                            </Box>
                        </form>
                    </MainCard>
                </Grid>
            </Grid>
        </>
    );
};

export default AddSalesTeam;
