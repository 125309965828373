const checkUserNetworkStatus = () => {
    if (navigator.onLine) {
        if (navigator.connection && navigator.connection.effectiveType) {
            const connectionType = navigator.connection.effectiveType;
            if (connectionType === 'slow-2g' || connectionType === '2g') {
                return 'weak';
            }
        }
        return 'online';
    }
    return 'offline';
};

export default checkUserNetworkStatus;
