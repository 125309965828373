import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { Button, Input, Select, DatePicker, Modal, Divider, Upload, message } from 'antd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import '../salesplan.css';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SaveIcon from '@mui/icons-material/Save';
import { decrypt, encrypt } from '../../../utils/encrypt';
import { toast } from 'react-toastify';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import ApiUrl from '../../../network';
import dayjs from 'dayjs';

// Function to convert Excel serial number to Date object

function excelSerialToDate(serialOrDateString, locale = 'en-US') {
    // Check if the input is defined
    if (typeof serialOrDateString !== 'undefined' && serialOrDateString !== null) {
        // Check if the input is a serial number
        if (!Number.isNaN(Number(serialOrDateString))) {
            const utcDays = Math.floor(serialOrDateString - 25569);
            const utcValue = utcDays * 86400;
            const dateInfo = new Date(utcValue * 1000);

            // Set hours, minutes, and seconds to zero
            dateInfo.setUTCHours(0);
            dateInfo.setUTCMinutes(0);
            dateInfo.setUTCSeconds(0);
            dateInfo.setUTCMilliseconds(0);

            return dateInfo.toLocaleDateString(locale);
        }
        // If the input is a date string, parse it accordingly
        const parts = serialOrDateString.split('/');
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; // Months are zero-based in JavaScript Date objects
        const year = parseInt(parts[2], 10);

        return new Date(year, month, day).toLocaleDateString(locale);
    }
    // Return the current date in the specified locale
    return new Date().toLocaleDateString(locale);
}

export const Index = () => {
    const keyResult = localStorage.getItem('key');
    const ivResult = localStorage.getItem('iv');
    const [date, setDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const { RangePicker } = DatePicker;
    const [pod, setPod] = useState('');
    const [week, setWeek] = useState('');
    const [pods, setPods] = useState([]);
    const [rmas, setRmas] = useState([]);
    const [rmaId, setRmaId] = useState('');
    const [salesPersons, setSalesPersons] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [driveTypeList, setDriveTypeList] = useState([]);
    const [driveLocationList, setDriveLocationList] = useState([]);
    const [loading, setLoading] = useState(false);
    const id = useParams();
    const [fields, setFields] = useState([
        {
            id: Date.now(),
            location: '',
            driveDate: '',
            driveType: '',
            week: '',
            day: ''
        }
    ]);
    const [target, setTarget] = useState('');
    const [revenueTarget, setRevenueTarget] = useState('');
    const [driveDate, setDriveDate] = useState('');
    const [driveType, setDriveType] = useState('');
    const [day, setDay] = useState('');
    const [weekNumber, setWeekNumber] = useState(null);
    const [isExcelModalVisible, setIsExcelModalVisible] = useState(false);
    const [excelData, setExcelData] = useState(null);
    const [rowsData, setRowsData] = useState([]);
    const [podsRmas, setPodsRmas] = useState([]);
    const [errors, setErrors] = useState({});

    const fetchPodsData = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            await fetch(`${ApiUrl.BaseUrl}/salesdrivepod/salesdrivepod`, config)
                .then((data) => data.json())
                .then((data) => {
                    if (data.status === true) {
                        const result = JSON.parse(decrypt(data.data, keyResult, ivResult));
                        setPodsRmas(result);
                        setPods(result);

                        // Extract and set all salespersons
                        const allSalespersons = [];
                        result.forEach((pod) => {
                            pod.salesperson.forEach((salesperson) => {
                                allSalespersons.push(salesperson);
                            });
                        });
                        setSalesPersons(allSalespersons);
                        const selectedPod = result.find((item) => item._id === id.id);
                        if (selectedPod) {
                            setPod(selectedPod.name);
                            setRmas(selectedPod.salesperson);
                        }
                    } else {
                        const error = decrypt(data.data, keyResult, ivResult);
                        toast.error(error.message);
                    }
                });
        } catch (e) {
            toast.error(e.message);
        }
    };
    const getDriveTypeHandler = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/salesplanning/drivetypeslist`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    const driveTypeList = decrypt(data.data, keyResult, ivResult);
                    setDriveTypeList(JSON.parse(driveTypeList));
                } else {
                    toast.error(data.message);
                }
            });
    };
    const getDriveLocationHandler = async () => {
        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        await fetch(`${ApiUrl.BaseUrl}/salesplanning/drivelocationslist`, {
            method: 'GET',
            headers: config.headers
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setLoading(false);
                    const driveLocationList = decrypt(data.data, keyResult, ivResult);
                    setDriveLocationList(JSON.parse(driveLocationList));
                } else {
                    setLoading(false);
                    toast.error(data.message);
                }
            });
    };

    useEffect(() => {
        if (id.id) {
            fetchPodsData();
            getDriveTypeHandler();
            getDriveLocationHandler();
        }
    }, []);

    const podOptions = pods?.map((pod) => {
        return {
            label: pod.name,
            value: pod.name
        };
    });
    const rmsOptions = rmas?.map((rma) => {
        return {
            label: `${rma?.firstName} ${rma?.lastName}`,
            value: rma?.userId
        };
    });
    const driveTypeOptions = driveTypeList?.map((driveType) => {
        return {
            label: driveType.name,
            value: driveType._id
        };
    });
    const locationOptions = driveLocationList?.map((location) => {
        return {
            label: location.location,
            value: location.location
        };
    });

    const addField = () => {
        const newField = {
            id: Date.now(),
            rma: '',
            pod: '',
            location: '',
            target: '',
            driveDate: '',
            driveType: ''
        };

        setFields((prevFields) => [...prevFields, newField]);
    };

    const removeField = (id) => {
        if (id) {
            const newFields = fields.filter((field) => field.id !== id);
            setFields(newFields);
        }
    };

    const updateFieldValue = (id, field, value) => {
        setFields((prevFields) => prevFields.map((prevField) => (prevField.id === id ? { ...prevField, [field]: value } : prevField)));
    };

    const dateHandler = (date, dateString) => {
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
        setDate(date);
    };

    const addSalesPlanHandler = async () => {
        setLoading(true);
        const items = fields.map((field) => {
            if (!field.location) {
                toast.error('Please fill all the fields');
                return;
            }

            // Check if driveLocationList is available and is an array
            if (!Array.isArray(driveLocationList) || driveLocationList.length === 0) {
                toast.error('No location information available');
                // eslint-disable-next-line consistent-return
                return null;
            }

            const locationInfo = driveLocationList.find((locationObj) => locationObj.location === field.location);

            if (!locationInfo) {
                toast.error(`Location ${field.location} not found`);
                // eslint-disable-next-line consistent-return
                return null;
            }

            const { location, latitude, longitude } = locationInfo;

            const formatedDate = driveDate.format('YYYY-MM-DD');
            // eslint-disable-next-line consistent-return
            return {
                driveType,
                name: location,
                latitude,
                longitude,
                driveDate: formatedDate,
                day
            };
        });

        const userInfo = localStorage.getItem('userToken');
        const config = {
            headers: {
                'auth-token': userInfo,
                'Content-Type': 'application/json'
            }
        };

        const datas = {
            pod: id.id,
            week: weekNumber,
            rmaId,
            target,
            revenuetarget: revenueTarget,
            schedules: items
        };

        const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);

        await fetch(`${ApiUrl.BaseUrl}/salesplanning/addsalesplanning`, {
            method: 'POST',
            headers: config.headers,
            body: JSON.stringify({ payload })
        })
            .then((data) => data.json())
            .then((data) => {
                if (data.status === true) {
                    setLoading(false);
                    toast.success(data.message);
                    setStartDate(null);
                    setEndDate(null);
                    setDate(null);
                    setFields([
                        {
                            id: Date.now(),
                            rma: [],
                            pod: '',
                            location: [],
                            target: null,
                            driveDate: ''
                        }
                    ]);
                    setWeekNumber(null);
                    setRmaId(null);
                    setTarget(null);
                    setRevenueTarget(null);
                    setDriveDate('');
                    setDriveType('');
                    setDay('');
                } else {
                    setLoading(false);
                    const error = decrypt(data.data, keyResult, ivResult);
                    toast.error(error);
                }
            });
    };

    const handleExcelUpload = (info) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0]; // Assuming only one sheet
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            // filter out empty rows
            const filteredData = jsonData.filter((row) => row.length > 0);
            if (filteredData.length < 2) {
                toast.error('No data found in the excel sheet');
                return;
            }
            setExcelData(filteredData);
            setIsExcelModalVisible(true);
        };
        reader.readAsBinaryString(info.file.originFileObj);
    };

    useEffect(() => {
        if (excelData && excelData.length > 1) {
            // Skip the header row and map the rest of the rows to objects
            const dataObjects = excelData.slice(1).map((row, rowIndex) => ({
                key: rowIndex,
                routes: row[0],
                activity: row[1],
                status: row[2],
                pod: row[3],
                rma: row[4],
                target: row[5],
                schdate: excelSerialToDate(row[6]),
                week: row[7],
                revenuetarget: row[8],
                day: row[9]
            }));
            setRowsData(dataObjects);
        }
    }, [excelData]);
    const downloadExcelSampleHandler = () => {
        // Actual location data for Ghana
        const data = [
            ['routes', 'activity', 'status', 'pod', 'rma', 'target', 'schdate', 'week', 'revenuetarget', 'day'],
            [
                'Tema Station, Tse Addo, Madina, Kasoa',
                'Sales Drive',
                'Sleep Over',
                'West',
                'Listowell',
                '4000',
                '04/05/2024',
                'week 1',
                '5000',
                'Monday'
            ]
        ];

        // Convert the data to a worksheet
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
        XLSX.writeFile(wb, 'salesschedule.xlsx');
    };

    const splitRoutes = (routeString) => {
        return routeString.split(',').map((route) => route.trim());
    };

    const addExcelDataHandler = async () => {
        try {
            setLoading(true);

            const handleSetErrors = (index, key, errorMessage) => {
                setRowsData((prevRows) => {
                    const updatedRows = [...prevRows];
                    if (updatedRows[index]) {
                        updatedRows[index] = {
                            ...updatedRows[index],
                            error: {
                                ...updatedRows[index].error,
                                [key]: errorMessage
                            }
                        };
                    }
                    return updatedRows;
                });
            };

            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            // eslint-disable-next-line array-callback-return,consistent-return
            const activity = rowsData.map((row, index) => {
                const foundDriveType = driveTypeList.find(
                    (driveType) => driveType.name.trim().toLowerCase() === row.activity.trim().toLowerCase()
                );
                if (foundDriveType) {
                    return foundDriveType._id;
                }
                handleSetErrors(index, 'activity', `Drive type ${row.activity} not found`);
                setLoading(false);
                return null;
            });

            const status = rowsData.map((row) => row.status);

            const rmaIds = rowsData.map((row, index) => {
                const fullName = row.rma.trim().toLowerCase();
                const [firstName, lastName] = fullName.split(' ');

                if (!firstName || !lastName || firstName.trim().length === 0 || lastName.trim().length === 0) {
                    // Display error message if last name is missing
                    handleSetErrors(index, 'rma', `Please enter both first and last name for the rma.`);
                    setLoading(false);
                    return null;
                }

                const foundSalesPerson = salesPersons.find((sp) => {
                    const fullNameMatch = `${sp.firstName.trim().toLowerCase()} ${sp.lastName.trim().toLowerCase()}`;
                    return fullNameMatch === fullName;
                });

                if (foundSalesPerson) {
                    const foundPod = podsRmas.find((pod) => pod.name.trim().toLowerCase() === row.pod.trim().toLowerCase());

                    if (foundPod) {
                        const foundSalesPersonInPod = foundPod.salesperson.find((sp) => {
                            const fullNameMatch = `${sp.firstName.trim().toLowerCase()} ${sp.lastName.trim().toLowerCase()}`;
                            return fullNameMatch === fullName;
                        });

                        if (foundSalesPersonInPod) {
                            return foundSalesPerson.userId;
                        }
                        toast(
                            `Sales person ${foundSalesPerson.firstName} ${foundSalesPerson.lastName} is not associated with selected Pod RMAs`
                        );
                    } else {
                        toast(
                            `Sales person ${foundSalesPerson.firstName} ${foundSalesPerson.lastName} is not associated with selected pod RMAs`
                        );
                    }
                } else {
                    handleSetErrors(index, 'rma', `Sales person ${row.rma.trim()} not found`);
                }

                setLoading(false);
                return null; // Or any appropriate fallback value
            });

            const target = rowsData.map((row) => row.target);
            // eslint-disable-next-line array-callback-return,consistent-return
            const uploadPod = rowsData.map((row, index) => {
                // First, check if the pod in the row matches the selected pod
                if (row.pod.trim().toLowerCase() === pod.trim().toLowerCase()) {
                    const foundPod = pods.find((pod) => pod.name.trim().toLowerCase() === row.pod.trim().toLowerCase());
                    if (foundPod) {
                        return foundPod._id;
                    }
                    handleSetErrors(index, 'pod', `Pod ${row.pod} not found in the system`);
                    setLoading(false);
                    // eslint-disable-next-line consistent-return
                    return null;
                }
                handleSetErrors(index, 'pod', `Pod ${row.pod} does not match selected pod`);
                setLoading(false);
                return null;
            });

            const schdate = rowsData.map((row) => row.schdate);
            const week = rowsData.map((row) => row.week);
            const revenuetarget = rowsData.map((row) => row.revenuetarget);
            const day = rowsData.map((row) => row.day);

            const data = {
                uploadData: rowsData.map((row, index) => {
                    const splitRouteList = splitRoutes(row.routes);
                    const routeDetails = splitRouteList.map((route) => {
                        const foundLocation = driveLocationList.find(
                            (location) => location.location.trim().toLowerCase() === route.trim().toLowerCase()
                        );
                        if (foundLocation) {
                            const { location, latitude, longitude } = foundLocation;
                            return { location, latitude, longitude };
                        }
                        return { location: route, latitude: 0, longitude: 0 }; // Fallback if route is not found
                    });

                    // Create a detailed route object for each route
                    const detailedRoutes = routeDetails.map((rd) => ({
                        route: rd.location,
                        lat: rd.latitude,
                        lng: rd.longitude
                    }));

                    // toast(`Location ${row.routes} not found in the system while uploading`);
                    return {
                        pod: row.pod.trim().toLowerCase() === pod.trim().toLowerCase() ? uploadPod[index] : false,
                        drivetype: activity[index],
                        routes: detailedRoutes, // Store detailed routes with coordinates
                        rmaId: rmaIds[index],
                        gmvtarget: target[index],
                        revenuetarget: revenuetarget[index],
                        status: status[index],
                        schDate: schdate[index],
                        week: week[index],
                        day: day[index]
                    };
                })
            };
            if (
                data.uploadData.some(
                    (row) =>
                        row.pod === false ||
                        row.drivetype === null ||
                        row.routes === null ||
                        row.rmaId === null ||
                        row.gmvtarget === null ||
                        row.revenuetarget === null ||
                        row.status === null ||
                        row.schDate === null ||
                        row.week === null ||
                        row.day === null
                )
            ) {
                setLoading(false);
                return;
            }

            const payload = encrypt(JSON.stringify(data), keyResult, ivResult);
            await fetch(
                `${ApiUrl.BaseUrl}/salesplanning/uploadsalesplanning`,
                {
                    method: 'POST',
                    headers: config.headers,
                    body: JSON.stringify({ payload })
                },
                config
            )
                .then((res) => res.json())
                .then((data) => {
                    if (data.status === true) {
                        if (
                            (!data.flagRms && !data.flagRoutes) || // If neither flagRms nor flagRoutes are present
                            ((!data.flagRoutes || data.flagRoutes.length === 0) && // If flagRoutes is not present or is an empty array
                                (!data.flagRms || data.flagRms.length === 0)) // If flagRms is not present or is an empty array
                        ) {
                            setLoading(false);
                            toast.success(data.message);
                            setIsExcelModalVisible(false);
                            getDriveLocationHandler();
                        } else {
                            setLoading(false);
                            const routesErrorMessage =
                                data.flagRoutes.length > 0
                                    ? `Some of the routes (${data.flagRoutes
                                          .map((route) => route.name)
                                          .join(', ')}) don't exist in the system`
                                    : '';

                            const rmsErrorMessage =
                                data.flagRms.length > 0
                                    ? `Some of the RMs (${data.flagRms
                                          .map((rm) => `${rm.firstName}`)
                                          .join(', ')}) don't exist in the system`
                                    : '';
                            // Determine which error message to display
                            const errorMessageToDisplay = rmsErrorMessage || routesErrorMessage;
                            if (errorMessageToDisplay === rmsErrorMessage) {
                                toast.error(rmsErrorMessage);
                            } else {
                                setIsExcelModalVisible(false);
                                getDriveLocationHandler();
                            }
                        }
                    } else {
                        setLoading(false);
                        toast.error(data.message);
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        } catch (e) {
            toast.error(e.message);
        }
    };

    const handleInputChange = (key, rowIndex, value) => {
        // Update the corresponding field in the state
        const updatedRows = rowsData.map((row) => {
            if (row.key === rowIndex) {
                return { ...row, [key]: value, error: {} }; // Clear error when input changes
            }
            return row;
        });
        setRowsData(updatedRows);
    };

    const handleDelete = async (rowIndex) => {
        const updatedRows = rowsData.filter((row) => row.key !== rowIndex);
        setRowsData(updatedRows);
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Link to="/salesplanning" className="createSchesduleLink">
                    <p style={{ fontSize: '25px', marginLeft: '10px', color: '#000' }}>
                        <ArrowBackIcon sx={{ fontSize: '25px', color: '#000' }} /> Create schedule
                    </p>
                </Link>
                <div className="d-flex align-items-center ">
                    <Upload onChange={handleExcelUpload} accept=".xlsx, .xls" showUploadList={false} multiple={false}>
                        <Button className="btnCreateSchedule" icon={<UploadOutlined />}>
                            Upload Sample
                        </Button>
                    </Upload>

                    <Button
                        className="ml-2 mr-2 btnCreateSchedule"
                        style={{ backgroundColor: '#969696', color: '#fff' }}
                        onClick={downloadExcelSampleHandler}
                    >
                        Download Sample
                    </Button>
                    <Button
                        onClick={addSalesPlanHandler}
                        style={{ backgroundColor: '#FF740F', color: '#fff' }}
                        className="btnCreateSchedule"
                        title="Apply Filters"
                        loading={loading}
                    >
                        <SaveIcon sx={{ fontSize: '16px', color: '#fff' }} />
                        <Typography sx={{ margin: '0 14px ', color: '#fff' }}>Create schedule</Typography>
                    </Button>
                </div>
            </div>
            <div className="addPlanContainer">
                <div className="inputHolder">
                    <div className="addSalePlanInput">
                        <div className="mr-2">
                            <p style={{ fontSize: '18px', fontWeight: 'bold', color: '#3B5460' }}>Weekly sales drive</p>
                        </div>
                    </div>
                    {fields.length > 1 && (
                        <p style={{ fontSize: '10px', color: '#546974', marginBottom: '10px' }}>{fields.length} Schedule Form Field </p>
                    )}
                    {fields.map((field, id) => (
                        <div key={field.id}>
                            <div className="addSalePlanInput">
                                <div className="mr-2">
                                    <p style={{ fontSize: '16px', color: '#546974' }}>POD</p>
                                    <Select
                                        placeholder="POD"
                                        showSearch
                                        allowClear
                                        size="large"
                                        style={{ width: '300px' }}
                                        disabled
                                        value={pod}
                                        onChange={(value) => setPod(value)}
                                        filterOption={(input, option) =>
                                            (option?.name.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                                        }
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={podOptions}
                                    />
                                </div>
                                <div>
                                    <p style={{ fontSize: '16px', color: '#546974' }}>Add locations</p>
                                    <Select
                                        placeholder="Add Location"
                                        mode="single"
                                        allowClear
                                        size="large"
                                        showSearch
                                        style={{ width: '300px' }}
                                        value={field.location || undefined}
                                        onChange={(value) => updateFieldValue(field.id, 'location', value)}
                                        filterOption={(inputValue, option) => {
                                            const label = option.label.toString().toUpperCase();
                                            return label.includes(inputValue.toUpperCase());
                                        }}
                                        options={locationOptions}
                                    />
                                </div>
                            </div>
                            <div className="addSalePlanInput">
                                <div className="mr-2">
                                    <p style={{ fontSize: '16px', color: '#546974' }}>RMA(s)</p>
                                    <Select
                                        placeholder="RMA(s)"
                                        mode="single"
                                        allowClear
                                        showSearch
                                        size="large"
                                        style={{ width: '300px' }}
                                        onChange={(value) => setRmaId(value)}
                                        filterOption={(inputValue, option) => {
                                            if (typeof option.label === 'string') {
                                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }

                                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }}
                                        value={rmaId || undefined}
                                        options={rmsOptions}
                                    />
                                </div>
                                <div>
                                    <p style={{ fontSize: '16px', color: '#546974' }}>Gmv Target</p>
                                    <Input
                                        type="number"
                                        placeholder="Gmv Target"
                                        size="large"
                                        style={{ width: '300px' }}
                                        value={target}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const parsedValue = inputValue !== '' ? parseFloat(inputValue) : null;
                                            setTarget(parsedValue);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="addSalePlanInput">
                                <div className="mr-2">
                                    <p style={{ fontSize: '16px', color: '#546974' }}>Drive Date</p>
                                    <DatePicker
                                        format="DD/MM/YYYY"
                                        size="large"
                                        style={{
                                            width: '300px'
                                        }}
                                        value={driveDate}
                                        onChange={(value) => setDriveDate(value)}
                                    />
                                </div>
                                <div>
                                    <p style={{ fontSize: '16px', color: '#546974' }}>Drive Type</p>
                                    <Select
                                        placeholder="Drive Type"
                                        type="text"
                                        size="large"
                                        showSearch
                                        style={{
                                            width: '300px'
                                        }}
                                        value={driveType || undefined}
                                        onChange={(value) => setDriveType(value)}
                                        filterOption={(inputValue, option) => {
                                            if (typeof option.label === 'string') {
                                                return option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            }

                                            return String(option.label).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        }}
                                        options={driveTypeOptions}
                                    />
                                </div>
                            </div>
                            <div className="addSalePlanInput">
                                <div className="mr-2">
                                    <p style={{ fontSize: '16px', color: '#546974' }}>Week</p>
                                    <Input
                                        size="large"
                                        placeholder="Week"
                                        type="text"
                                        style={{
                                            width: '300px'
                                        }}
                                        value={weekNumber}
                                        onChange={(e) => {
                                            setWeekNumber(e.target.value);
                                        }}
                                    />
                                </div>
                                <div>
                                    <p style={{ fontSize: '16px', color: '#546974' }}>Revenue Target</p>
                                    <Input
                                        type="number"
                                        placeholder="Revenue Target"
                                        size="large"
                                        style={{
                                            width: '300px'
                                        }}
                                        value={revenueTarget}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const parsedValue = inputValue !== '' ? parseFloat(inputValue) : null;
                                            setRevenueTarget(parsedValue);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="addSalePlanInput">
                                <div>
                                    <p style={{ fontSize: '16px', color: '#546974' }}>Day</p>
                                    <Input
                                        size="large"
                                        placeholder="Day"
                                        type="text"
                                        style={{
                                            width: '300px'
                                        }}
                                        value={day}
                                        onChange={(e) => {
                                            setDay(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="addSalePlanInput margin-top-20">
                                <Button
                                    onClick={() => removeField(field.id)}
                                    style={{ border: '1px solid #CED4D7', backgroundColor: '#fff', color: '#000' }}
                                    className="mt-2 mb-2 d-flex align-items-center justify-content-cente btnCreateSchedule"
                                    title="Apply Filters"
                                >
                                    <Typography sx={{ margin: '0 14px ', color: '#000' }}>Cancel</Typography>
                                </Button>
                                <Button
                                    onClick={addField}
                                    style={{ border: '1px solid #FF740F', backgroundColor: '#fff', color: '#fff' }}
                                    className="btnCreateSchedule"
                                    title="Apply Filters"
                                >
                                    <AddBoxIcon sx={{ fontSize: '16px', color: '#FF740F' }} />
                                    <Typography sx={{ margin: '0 14px ', color: '#FF740F' }}>Add schedule</Typography>
                                </Button>
                            </div>
                            {id !== fields.length - 1 && <Divider />}
                        </div>
                    ))}
                </div>
                <Modal width={300} title="Add a date range" footer={null} open={isModalVisible} onCancel={() => setIsModalVisible(false)}>
                    <div>
                        <RangePicker picker="week" format="DD/MM/YYYY" size="large" value={date} onChange={dateHandler} />
                    </div>
                </Modal>
            </div>
            <Modal
                width={800}
                title="Sales Plan Upload"
                open={isExcelModalVisible}
                onCancel={() => {
                    setIsExcelModalVisible(false);
                    setRowsData([]);
                    setExcelData([]);
                }}
                footer={null}
                className="excelModal"
            >
                <div>
                    <div className="excelContainer">
                        {rowsData.map((row) => (
                            <div key={row.key} className="mb-2" style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                                    <div className="mb-2 flex-grow-1">
                                        <p style={{ fontSize: '12px' }} className="mb-2">
                                            Routes
                                        </p>
                                        <Input
                                            style={{ width: '300px' }}
                                            placeholder="Routes"
                                            value={row.routes}
                                            onChange={(e) => handleInputChange('routes', row.key, e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-2 flex-grow-1">
                                        <p style={{ fontSize: '12px' }} className="mb-2">
                                            Activity
                                        </p>
                                        {row.error && row.error.activity && (
                                            <p style={{ color: 'red', fontSize: '12px' }}>{row.error.activity}</p>
                                        )}
                                        <Input
                                            style={{ width: '300px' }}
                                            placeholder="Activity"
                                            value={row.activity !== null ? row.activity : ''}
                                            onChange={(e) => handleInputChange('activity', row.key, e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-2 flex-grow-1">
                                        <p style={{ fontSize: '12px' }} className="mb-2">
                                            Status
                                        </p>
                                        <Input
                                            style={{ width: '300px' }}
                                            placeholder="Status"
                                            value={row.status !== null ? row.status : ''}
                                            onChange={(e) => handleInputChange('status', row.key, e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-2 flex-grow-1">
                                        <p style={{ fontSize: '12px' }} className="mb-2">
                                            Pod
                                        </p>
                                        {row.error && row.error.pod && <p style={{ color: 'red', fontSize: '12px' }}>{row.error.pod}</p>}
                                        <Input
                                            style={{ width: '300px' }}
                                            placeholder="Pod"
                                            value={row.pod !== null ? row.pod : ''}
                                            onChange={(e) => handleInputChange('pod', row.key, e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-2 flex-grow-1">
                                        <p style={{ fontSize: '12px' }} className="mb-2">
                                            RMA
                                        </p>
                                        {row.error && row.error.rma && <p style={{ color: 'red', fontSize: '12px' }}>{row.error.rma}</p>}
                                        <Input
                                            style={{ width: '300px' }}
                                            placeholder="RMA"
                                            value={row.rma !== null ? row.rma : ''}
                                            onChange={(e) => handleInputChange('rma', row.key, e.target.value)}
                                        />
                                    </div>

                                    <div className="mb-2 flex-grow-1">
                                        <p style={{ fontSize: '12px' }} className="mb-2">
                                            Gmv Target
                                        </p>
                                        <Input
                                            style={{ width: '300px' }}
                                            placeholder="Target"
                                            value={row.target !== null ? row.target : ''}
                                            onChange={(e) => handleInputChange('target', row.key, e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-2 flex-grow-1">
                                        <p style={{ fontSize: '12px' }} className="mb-2">
                                            Schedule Date
                                        </p>
                                        <DatePicker
                                            format="DD/MM/YYYY"
                                            style={{ width: '300px' }}
                                            placeholder="Schedule Date"
                                            value={row.schdate ? dayjs(row.schdate) : null}
                                            onChange={(e) => handleInputChange('schdate', row.key, e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-2 flex-grow-1">
                                        <p style={{ fontSize: '12px' }} className="mb-2">
                                            Week
                                        </p>
                                        <Input
                                            style={{ width: '300px' }}
                                            placeholder="Week"
                                            value={row.week !== null ? row.week : ''}
                                            onChange={(e) => handleInputChange('week', row.key, e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-2 flex-grow-1">
                                        <p style={{ fontSize: '12px' }} className="mb-2">
                                            Revenue Target
                                        </p>
                                        <Input
                                            style={{ width: '300px' }}
                                            placeholder="Revenue Target"
                                            value={row.revenuetarget !== null ? row.revenuetarget : ''}
                                            onChange={(e) => handleInputChange('revenuetarget', row.key, e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-2 flex-grow-1">
                                        <p style={{ fontSize: '12px' }} className="mb-2">
                                            Day
                                        </p>
                                        <Input
                                            style={{ width: '300px' }}
                                            placeholder="Day"
                                            value={row.day !== null ? row.day : ''}
                                            onChange={(e) => handleInputChange('day', row.key, e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="mb-2" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                    <Button style={{ width: 'fit-content' }} danger onClick={() => handleDelete(row.key)}>
                                        <DeleteOutlined />
                                    </Button>
                                </div>
                                <Divider />
                            </div>
                        ))}
                    </div>
                    <div className="mt-2">
                        <Button
                            style={{ backgroundColor: '#FF740F', color: '#fff', width: '100%', marginTop: '10px' }}
                            className="btnCreateSchedule"
                            title="Apply Filters"
                            onClick={addExcelDataHandler}
                            loading={loading}
                        >
                            <SaveIcon sx={{ fontSize: '16px', color: '#fff' }} />
                            <Typography sx={{ margin: '0 14px ', color: '#fff' }}>Create schedule</Typography>
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Index;
