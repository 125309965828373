import { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
import ApiUrl from 'network';
import { useNavigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

// material-ui
// import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography, Grid, Divider } from '@mui/material';

// third party  useMediaQuery
// import * as Yup from 'yup';
// import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
// import AnimateButton from 'ui-component/extended/AnimateButton';
import { makeStyles } from '@mui/styles';

// assets
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import Google from 'assets/images/icons/social-google.svg';

// ============================|| - LOGIN ||============================ //

const useStyles = makeStyles(() => ({
    color: {
        color: '#2CB23B'
    },
    color1: {
        color: '#FF0000'
    },
    backgroundColor: {
        backgroundColor: '#FF740F'
    }
}));

const AuthVerifyEmail = () => {
    // const theme = useTheme();
    const classes = useStyles();
    const history = useNavigate();
    const scriptedRef = useScriptRef();
    const [validUrl, setValidUrl] = useState(true);
    const [loading, setLoading] = useState(false);
    // console.log(loading);

    const { id } = useParams();

    const handleClick = () => {
        window.location.replace('https://marketplace.garagemobility.com');
    };

    useEffect(() => {
        setLoading(true);
        const data = {
            id
        };
        fetch(ApiUrl.NewEmailVerificationUrl, {
            method: 'POST',
            headers: {
                'auth-token': id,
                Accept: 'application/form-data',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.status) {
                    toast.success(result.message);
                    setLoading(false);
                    setValidUrl(true);

                    console.log('data', result?.data);
                    if (
                        result?.data === '620e4e28352b6bc29a5e3231' ||
                        result?.data === '623849adf978bc24fe107f5e' ||
                        result?.data === '621d2f646b246b457a0219cb' ||
                        result?.data === '6243ac1eae678df33550c36f'
                    ) {
                        setTimeout(() => {
                            if (scriptedRef.current) {
                                handleClick();
                            }
                        }, 3000);
                    } else {
                        setTimeout(() => {
                            if (scriptedRef.current) {
                                history('/login');
                            }
                        }, 3000);
                    }

                    setTimeout(() => {
                        if (scriptedRef.current) {
                            history('/login');
                        }
                    }, 3000);
                } else {
                    toast.error(result.message);
                    setLoading(false);
                    setValidUrl(false);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

                <ToastContainer />

                <Grid item xs={12}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
                    </Box>
                </Grid>
                {validUrl ? (
                    <Grid item>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <CircularProgress size={70} thickness={4} />
                        </div>
                    </Grid>
                ) : (
                    <Grid item>
                        <Stack alignItems="center" justifyContent="center" spacing={1}>
                            <Typography variant="caption" fontSize="20px" fontWeight="bold" textAlign="center" className={classes.color1}>
                                Email Link Expired
                            </Typography>
                        </Stack>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default AuthVerifyEmail;
