import styled from 'styled-components';

const Wrapper = styled.div`
    font-family: 'Roboto', 'sans-serif';
    background: #ffff;
    padding: 1rem 2rem;
    border-radius: 0.625rem;
    color: #222;
    margin-bottom: 15px;

    h4 {
        font-size: 1rem;
        color: #8b8b8b;
    }

    h5 {
        color: #000;
        font-weight: 800;
        font-size: 1.8rem;
    }

    .stats-body {
        padding: 2.2rem 0;
    }
`;

const BasicCard = () => {
    return (
        <Wrapper className="shadow-sm">
            <h4>Avg.time between orders(days)</h4>
            <div className="stats-body">
                <h5>N/A</h5>
            </div>
        </Wrapper>
    );
};
export default BasicCard;
